import Swal from "sweetalert2";
import services from "../../Services/index";
import {
    GET_ADD_DONOR_DATA_SUCCESS,
    GET_ADD_DONOR_DATA_LOAD,
    GET_ADD_DONOR_DATA_FAILED,
} from "../Contants/Constants";

const getAddBloodDonorLoad = () => ({
    type: GET_ADD_DONOR_DATA_LOAD,
});
const getAddBloodDonorSuccess = (data) => ({
    type: GET_ADD_DONOR_DATA_SUCCESS,
    payload: data,
});
const getAddBloodDonorFailed = (error) => ({
    type: GET_ADD_DONOR_DATA_FAILED,
    err: error,
});

export const getAddBloodDonorData = (donorData) => async (dispatch) => {
  dispatch(getAddBloodDonorLoad());
  try {
    const res = await services.api(
      "POST",
      `/ui-assist/v1/blooddonor`,donorData
    );
    if (res) {
      dispatch(getAddBloodDonorSuccess(res.data));
    }
    if(res.status === 200)
    {
      Swal.fire(
              "Successfully Registered!",
              " ",
              "success"
            );
    }
  } catch (err) {
    dispatch(getAddBloodDonorFailed(err.response));
    Swal.fire("Something went wrong!", `${err?.response?.data}`, "error");
  }
};