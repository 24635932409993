import React, { useState, useEffect } from "react";
import { Grid } from "@material-ui/core";
import uploadIcon from "../../../Assets/Images/collectiveIcons/upload-2-line.svg";
import { useDispatch, useSelector } from "react-redux";
import { getUploadImageData } from "../../../Redux/Actions/UploadImageAction";
import CircularProgress from "@mui/material/CircularProgress";

const CommonImageUpload = ({ disabled, formik, notmandatory }) => {
  const dispatch = useDispatch();
  const fileData = useSelector((state) => state.getUploadImageReducer);

  const [currentFile, setCurrentFile] = useState();

  useEffect(() => {
    if (fileData.getData != "" && currentFile) {
      formik.setFieldValue("thumbnail", fileData.getData?.image);
    }
  }, [fileData.getData]);

  const handleThumbnail = (event) => {
    const formData = new FormData();
    formData.append("media", event.currentTarget.files[0]);
    formData.append("name", event.currentTarget.files[0]?.name);
    setCurrentFile(event.currentTarget.files[0]);
    if (event.currentTarget.files[0]?.name) {
      dispatch(getUploadImageData(formData));
    }
  };

  console.log("selected Image file =====> ", fileData);

  return (
    <Grid container spacing={2} alignItems="top">
      <Grid item xs={12} className="uploadBtnStyle">
        <div className="uploadBtn">
          <label for="videoThumbnail" className="btn uploadBtnLabel">
            {formik?.values?.thumbnail
              ? formik?.values?.thumbnail
              : " Upload thumbnail"}{" "}
            {notmandatory ? "" : <span className="colRed">*</span>}
          </label>

          <img
            for="videoThumbnail"
            src={uploadIcon}
            className="uploadIconStyl"
            alt="Upload Button Icon"
          />
          <input
            id="videoThumbnail"
            style={{
              visibility: "hidden",
              padding: "19px 16px",
              position: "absolute",
            }}
            disabled={disabled}
            type="file"
            accept=".png, .jpg, .jpeg, .svg, .gif"
            name="videoThumbnail"
            onChange={handleThumbnail}
          />
        </div>
        <div>
          {fileData.isLoading && (
            <>
              <CircularProgress
                color="success"
                style={{ width: "20px", height: "20px" }}
              />
              <span className="ms-2">Uploading...</span>
            </>
          )}
        </div>
        <span style={{ color: "#f44336" }}>
          {formik?.errors?.thumbnail && formik.touched.thumbnail
            ? formik.errors.thumbnail
            : ""}
        </span>
      </Grid>
    </Grid>
  );
};

export default CommonImageUpload;
