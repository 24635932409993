/* eslint-disable jsx-a11y/iframe-has-title */
import React from "react";
import "./PdfViewerComponent.css";
import { MobilePDFReader } from "react-read-pdf";
import { baseUrl } from "../../../../Services";

// import { Document, Page } from "react-pdf";

// import { pdfjs } from "react-pdf";
// import "react-pdf/dist/esm/Page/AnnotationLayer.css";

export default function PdfViewerComponent({ link }) {
  // pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`;

  // const [numPages, setNumPages] = React.useState(null);
  // const [pageNumber, setPageNumber] = React.useState(1);

  // function onDocumentLoadSuccess({ numPages }) {
  //   setNumPages(numPages);
  // }

  // console.log("pdf viewer is called");
  return (
    <div className="pdfViewerContainer">
      {/*<MobilePDFReader url={`${link}#view=fitH`} /> */}
      <iframe
        src={`${baseUrl}/ui-assist/v1/download/${link}`}
        width="800"
        height="600"
      ></iframe>
    </div>
    // <div className="pdfViewerContainer">
    //   {/* <object
    //     data={`${link}`}
    //     type="application/pdf"
    //     height="100%"
    //     width="100%"
    //     aria-label="foo"
    //   ></object> */}
    //   <iframe
    //     className="pdfIframe"
    //     title="Show PDF"
    //     src={`${link}#view=fitH`}
    //     height="100%"
    //     width="100%"
    //   ></iframe>
    // </div>
    // <div>
    //   <Document file="/Sample.pdf" onLoadSuccess={onDocumentLoadSuccess}>
    //     <Page pageNumber={pageNumber} />
    //   </Document>
    //   <p>
    //     Page {pageNumber} of {numPages}
    //   </p>
    // </div>
  );
}
