import Swal from "sweetalert2";
import services from "../../Services/index";
import {
    GET_ALL_CONTACT_LOCATION_SUCCESS,
    GET_ALL_CONTACT_LOCATION_LOAD,
    GET_ALL_CONTACT_LOCATION_FAILED,
    PUT_EDIT_CONTACT_LOCATION_SUCCESS,
    PUT_EDIT_CONTACT_LOCATION_LOAD,
    PUT_EDIT_CONTACT_LOCATION_FAILED,
    DELETE_CONTACT_LOCATION_SUCCESS,
    DELETE_CONTACT_LOCATION_LOAD,
    DELETE_CONTACT_LOCATION_FAILED,
    ADD_CONTACT_LOCATION_SUCCESS,
    ADD_CONTACT_LOCATION_LOAD,
    ADD_CONTACT_LOCATION_FAILED,
} from "../Contants/Constants";


//GET ALL EMERGENCY CONTACTS

const getAllContactLocationLoad = () => ({
    type: GET_ALL_CONTACT_LOCATION_LOAD,
});
const getAllContactLocationSuccess = (data) => ({
    type: GET_ALL_CONTACT_LOCATION_SUCCESS,
    payload: data,
});
const getAllContactLocationFailed = (error) => ({
    type: GET_ALL_CONTACT_LOCATION_FAILED,
    err: error,
});

export const getAllContactLocationData = () => async (dispatch) => {
  dispatch(getAllContactLocationLoad());
  try {
    const res = await services.api(
      "GET",
      `/ui-assist/v1/emergency/locations`
    );
    if (res) {
      dispatch(getAllContactLocationSuccess(res.data));
    }
  } catch (err) {
    dispatch(getAllContactLocationFailed(err.response));
    Swal.fire("Something went wrong!", `${err?.response?.data}`, "error");
  }
};


//EDIT EMERGENCY CONTACT

const editContactLocationLoad = () => ({
  type: PUT_EDIT_CONTACT_LOCATION_LOAD,
});
const editContactLocationSuccess = (data) => ({
  type: PUT_EDIT_CONTACT_LOCATION_SUCCESS,
  payload: data,
});
const editContactLocationFailed = (error) => ({
  type: PUT_EDIT_CONTACT_LOCATION_FAILED,
  err: error,
});

export const editContactLocationData = (preLoc,postLoc) => async (dispatch) => {
dispatch(editContactLocationLoad());
try {
  const res = await services.api(
    "PUT",
    `/ui-assist/v1/emergency/location/${preLoc}`,postLoc
  );
  if (res) {
    dispatch(editContactLocationSuccess(res.data));
  }
} catch (err) {
  dispatch(editContactLocationFailed(err.response));
  Swal.fire("Something went wrong!", `${err?.response?.data}`, "error");
}
};



//DELETE EMERGENCY CONTACT

const deleteContactLocationLoad = () => ({
  type: DELETE_CONTACT_LOCATION_LOAD,
});
const deleteContactLocationSuccess = (data) => ({
  type: DELETE_CONTACT_LOCATION_SUCCESS,
  payload: data,
});
const deleteContactLocationFailed = (error) => ({
  type: DELETE_CONTACT_LOCATION_FAILED,
  err: error,
});

export const deleteContactLocationData = (data) => async (dispatch) => {
dispatch(deleteContactLocationLoad());
try {
  const res = await services.api(
    "DELETE",
    `/ui-assist/v1/emergency/location/${data}`
  );
  if (res) {
    dispatch(deleteContactLocationSuccess(res.data));
  }
} catch (err) {
  dispatch(deleteContactLocationFailed(err.response));
  Swal.fire("Something went wrong!", `${err?.response?.data}`, "error");
}
};


//ADD EMERGENCY CONTACT

const addContactLocationLoad = () => ({
  type: ADD_CONTACT_LOCATION_LOAD,
});
const addContactLocationSuccess = (data) => ({
  type: ADD_CONTACT_LOCATION_SUCCESS,
  payload: data,
});
const addContactLocationFailed = (error) => ({
  type: ADD_CONTACT_LOCATION_FAILED,
  err: error,
});

export const addContactLocationData = (data) => async (dispatch) => {
dispatch(addContactLocationLoad());
try {
  const res = await services.api(
    "POST",
    `/ui-assist/v1/emergency/location`,data
  );
  if (res) {
    dispatch(addContactLocationSuccess(res.data));
  }
} catch (err) {
  dispatch(addContactLocationFailed(err.response));
  Swal.fire("Something went wrong!", `${err?.response?.data}`, "error");
}
};