import Swal from "sweetalert2";
import services from "../../Services/index";
import {
  GET_ALL_ORG_DATA_LOAD,
  GET_ALL_ORG_DATA_FAILED,
  GET_ALL_ORG_DATA_SUCCESS,
  GET_SECTOR_ORG_DATA_LOAD,
  GET_SECTOR_ORG_DATA_FAILED,
  GET_SECTOR_ORG_DATA_SUCCESS,
  GET_BUSINESS_UNITS_ORG_DATA_LOAD,
  GET_BUSINESS_UNITS_ORG_DATA_FAILED,
  GET_BUSINESS_UNITS_ORG_DATA_SUCCESS,
  GET_FUNCTION_ORG_DATA_LOAD,
  GET_FUNCTION_ORG_DATA_FAILED,
  GET_FUNCTION_ORG_DATA_SUCCESS,
  GET_ORG_COUNT_LOAD,
  GET_ORG_COUNT_FAILED,
  GET_ORG_COUNT_SUCCESS,
  GET_ALL_ORG_ADMIN_DATA_LOAD,
  GET_ALL_ORG_ADMIN_DATA_FAILED,
  GET_ALL_ORG_ADMIN_DATA_SUCCESS,
  GET_ORG_VIEWS_SUCCESS,
  GET_ORG_VIEWS_LOAD,
  GET_ORG_VIEWS_FAILED,
} from "../Contants/Constants";

export const ORG_PAGE_SIZE = 50;
export const ORG_ADMIN_PAGE_SIZE = 20;

const getOrgDataLoad = () => ({
  type: GET_ALL_ORG_DATA_LOAD,
});
const getOrgDataSuccess = (data, page) => ({
  type: GET_ALL_ORG_DATA_SUCCESS,
  payload: data,
  page: page,
});
const getOrgDataFailed = (error) => ({
  type: GET_ALL_ORG_DATA_FAILED,
  err: error,
});

export const getOrgData =
  (
    pageNo = -1,
    sectorId,
    businessUnit,
    businessfunction,
    selectedYear,
    selectedMonth,
    searchText
  ) =>
  async (dispatch) => {
    dispatch(getOrgDataLoad());
    try {
      let baseUrl = "/ui-assist/v1/filter/organnouncement";

      let payload = getPayload(
        sectorId,
        businessUnit,
        businessfunction,
        selectedYear,
        selectedMonth,
        searchText,
        pageNo,
        ORG_PAGE_SIZE,
        false
      );

      const res = await services.api("POST", baseUrl, payload);
      if (res) {
        // console.log("org list====", res);
        dispatch(getOrgDataSuccess(res.data, pageNo));
      }
    } catch (err) {
      dispatch(getOrgDataFailed(err.response));
      Swal.fire("Something went wrong!", `${err?.response?.data}`, "error");
    }
  };
//get orgdata by sector

const getOrgAdminDataLoad = () => ({
  type: GET_ALL_ORG_ADMIN_DATA_LOAD,
});
const getOrgAdminDataSuccess = (data, page) => ({
  type: GET_ALL_ORG_ADMIN_DATA_SUCCESS,
  payload: data,
  page: page,
});
const getOrgAdminDataFailed = (error) => ({
  type: GET_ALL_ORG_ADMIN_DATA_FAILED,
  err: error,
});
//Get ORGDATA
export const getOrgAdminData =
  (selectedYear, selectedMonth, searchText, page = -1) =>
  async (dispatch) => {
    dispatch(getOrgAdminDataLoad());
    try {
      let baseUrl = "/ui-assist/v1/filter/organnouncement";

      let payload = getPayload(
        [],
        [],
        [],
        selectedYear,
        selectedMonth,
        searchText,
        page,
        ORG_ADMIN_PAGE_SIZE
      );

      console.log("======================");
      console.log("Data => ", payload);

      const res = await services.api("POST", baseUrl, payload);
      if (res) {
        // console.log("org list====", res);
        dispatch(getOrgAdminDataSuccess(res.data, page));
      }
    } catch (err) {
      dispatch(getOrgAdminDataFailed(err.response));
      Swal.fire("Something went wrong!", `${err?.response?.data}`, "error");
    }
  };

//get ORG data Count
const getOrgCountLoad = () => ({
  type: GET_ORG_COUNT_LOAD,
});
const getOrgCountSuccess = (data) => ({
  type: GET_ORG_COUNT_SUCCESS,
  payload: data,
});
const getOrgCountFailed = (error) => ({
  type: GET_ORG_COUNT_FAILED,
  err: error,
});
export const getOrgCount =
  (
    sectorId = [],
    businessUnit = [],
    businessfunction = [],
    selectedYear = "",
    selectedMonth = [],
    searchText = ""
  ) =>
  async (dispatch) => {
    dispatch(getOrgCountLoad());
    try {
      let baseUrl = "/ui-assist/v1/filter/organnouncement/count";

      let payload = getPayload(
        sectorId,
        businessUnit,
        businessfunction,
        selectedYear,
        selectedMonth,
        searchText
      );

      const res = await services.api("POST", baseUrl, payload);
      if (res) {
        dispatch(getOrgCountSuccess(res.data));
      }
    } catch (err) {
      dispatch(getOrgCountFailed(err.response));
    }
  };

function getPayload(
  sectorId,
  businessUnit,
  businessFunction,
  selectedYear,
  selectedMonth,
  searchText,
  pageNo,
  pageSize = ORG_PAGE_SIZE,
  isAdmin = true
) {
  let data = {};

  if (sectorId.length > 0) {
    data["sector"] = sectorId;
  }

  if (businessUnit.length > 0) {
    data["businessUnit"] = businessUnit;
  }

  if (businessFunction.length > 0) {
    data["function"] = businessFunction;
  }

  if (selectedYear) {
    data["publishDate"] = {};
    data["publishDate"]["year"] = selectedYear;

    if (selectedMonth) {
      data["publishDate"]["months"] = selectedMonth.split(",");
    } else {
      data["publishDate"]["months"] = []; //default all months
    }
  }

  if (searchText) {
    data["key"] = searchText;
  }

  if (pageNo != undefined) {
    //   data["page"] = 0;
    //   // data["offset"] = 20;
    // } else {
    data["page"] = pageNo;
  }
  if (pageNo > -1) {
    data["offset"] = pageSize;
  }

  data["isAdmin"] = isAdmin;

  return data;
}

const getOrgDataBySectorLoad = () => ({
  type: GET_SECTOR_ORG_DATA_LOAD,
});
const getOrgDataBySectorSuccess = (data) => ({
  type: GET_SECTOR_ORG_DATA_SUCCESS,
  payload: data,
});
const getOrgDataBySectorFailed = (error) => ({
  type: GET_SECTOR_ORG_DATA_FAILED,
  err: error,
});
export const getOrgDataBySector = (sectorId) => async (dispatch) => {
  dispatch(getOrgDataBySectorLoad());
  try {
    const res = await services.api(
      "GET",
      `/ui-assist/v1/organnouncement/sector?sectorId=${sectorId}`
    );
    if (res) {
      dispatch(getOrgDataBySectorSuccess(res.data));
    }
  } catch (err) {
    dispatch(getOrgDataBySectorFailed(err.response));
  }
};

//get orgdata by business units
const getOrgDataByBusinessUnitsLoad = () => ({
  type: GET_BUSINESS_UNITS_ORG_DATA_LOAD,
});
const getOrgDataByBusinessUnitsSuccess = (data) => ({
  type: GET_BUSINESS_UNITS_ORG_DATA_SUCCESS,
  payload: data,
});
const getOrgDataByBusinessUnitsFailed = (error) => ({
  type: GET_BUSINESS_UNITS_ORG_DATA_FAILED,
  err: error,
});
export const getOrgDataByBusinessUnits =
  (businessUnitsId) => async (dispatch) => {
    dispatch(getOrgDataByBusinessUnitsLoad());
    try {
      const res = await services.api(
        "GET",
        `/ui-assist/v1/organnouncement/businessunit?businessunitId=${businessUnitsId}`,
        {}
      );
      if (res) {
        dispatch(getOrgDataByBusinessUnitsSuccess(res.data));
      }
    } catch (err) {
      dispatch(getOrgDataByBusinessUnitsFailed(err.response));
    }
  };

//get orgdata by function
const getOrgDataByFunctionLoad = () => ({
  type: GET_FUNCTION_ORG_DATA_LOAD,
});
const getOrgDataByFunctionSuccess = (data) => ({
  type: GET_FUNCTION_ORG_DATA_SUCCESS,
  payload: data,
});
const getOrgDataByFunctionFailed = (error) => ({
  type: GET_FUNCTION_ORG_DATA_FAILED,
  err: error,
});
export const getOrgDataByFunction = (functionId) => async (dispatch) => {
  dispatch(getOrgDataByFunctionLoad());
  try {
    const res = await services.api(
      "GET",
      `/ui-assist/v1/organnouncement/function?functionId=${functionId}`,
      {}
    );
    if (res) {
      dispatch(getOrgDataByFunctionSuccess(res.data));
    }
  } catch (err) {
    dispatch(getOrgDataByFunctionFailed(err.response));
  }
};

const getOrgViewsLoad = () => ({
  type: GET_ORG_VIEWS_LOAD,
});
const getOrgViewsSuccess = (data) => ({
  type: GET_ORG_VIEWS_SUCCESS,
  payload: data,
});
const getOrgViewsFailed = (error) => ({
  type: GET_ORG_VIEWS_FAILED,
  err: error,
});

export const getOrgViewsData = (id) => async (dispatch) => {
  dispatch(getOrgViewsLoad());
  try {
    const res = await services.api(
      "PUT",
      `/ui-assist/v1/view/organnouncement?contentId=${id}`
    );
    if (res) {
      dispatch(getOrgViewsSuccess(res.data));
    }
  } catch (err) {
    dispatch(getOrgViewsFailed(err.response));
  }
};
