import { Card, CardMedia } from "@material-ui/core";
import React from "react";
// import ReactPlayer from "react-player/lazy";
import CommonModal from "../CommonModal/CommonModal";
import "./PageTutorialComponent.css";
import editIconRed from "../../../../src/Assets/Images/play-circle-line.svg";

export default function PageTutorialComponent({
  videoLink,
  fromTemplate,
  modelHeadtxt,
}) {
  const [open, setOpen] = React.useState(false);
  const handleClose = () => {
    setOpen(false);
  };

  //   const openVideoTutorial = () => {};

  const openCommonModel = () => {
    return (
      <CommonModal
        open={open}
        handleClose={handleClose}
        header={true}
        headerText={modelHeadtxt}
        noPadding={true}
        footer={false}
      >
        <Card>
          <CardMedia
            component="video"
            image={videoLink}
            height="440px"
            autoPlay
            controls
          />
        </Card>
      </CommonModal>
    );
  };

  return (
    <>
    <div
                    className="sitetutorialpanel"
                    onClick={() => {
                      setOpen(true);
                    }}
                  >
                    <img src={editIconRed} alt="" className="tuticon" />
                    <div>Site tutorial</div>
                  </div>
     
      <div>
        <>{openCommonModel()}</>
      </div>
    </>
  );
}
