import services from "../../Services/index";
import {
  GET_ALL_WHATSNEW_DATA_LOAD,
  GET_ALL_WHATSNEW_DATA_FAILED,
  GET_ALL_WHATSNEW_DATA_SUCCESS,
  GET_WHATS_NEW_SECTORS_LOAD,
  GET_WHATS_NEW_SECTORS_FAILED,
  GET_WHATS_NEW_SECTORS_SUCCESS,
  GET_WHATS_NEW_BY_SECTOR_LOAD,
  GET_WHATS_NEW_BY_SECTOR_FAILED,
  GET_WHATS_NEW_BY_SECTOR_SUCCESS,
} from "../Contants/Constants";

const getWhatsNewDataLoad = () => ({
  type: GET_ALL_WHATSNEW_DATA_LOAD,
});
const getWhatsNewDataSuccess = (data) => ({
  type: GET_ALL_WHATSNEW_DATA_SUCCESS,
  payload: data,
});
const getWhatsNewDataFailed = (error) => ({
  type: GET_ALL_WHATSNEW_DATA_FAILED,
  err: error,
});
export const getWhatsNewData = () => async (dispatch) => {
  dispatch(getWhatsNewDataLoad());
  let res;
  try {
    res = await services.api(
      "GET",
      `/ui-assist/v1/whatsnewsection/employeeid/${localStorage.getItem(
        "token"
      )}`,
      {}
    );
    if (res) {
      dispatch(getWhatsNewDataSuccess(res.data));
    }
  } catch (err) {
    dispatch(getWhatsNewDataFailed(err.response));
  }
  return res.data;
};

const getWhatsNewSectorsLoad = () => ({
  type: GET_WHATS_NEW_SECTORS_LOAD,
});
const getWhatsNewSectorsSuccess = (data) => ({
  type: GET_WHATS_NEW_SECTORS_SUCCESS,
  payload: data,
});
const getWhatsNewSectorsFailed = (error) => ({
  type: GET_WHATS_NEW_SECTORS_FAILED,
  err: error,
});
export const getWhatsNewSectors = () => async (dispatch) => {
  dispatch(getWhatsNewSectorsLoad());
  try {
    const res = await services.api("GET", `/ui-assist/v1/whatsnew/sector`);
    if (res) {
      dispatch(getWhatsNewSectorsSuccess(res.data));
    }
  } catch (err) {
    dispatch(getWhatsNewSectorsFailed(err.response));
  }
};

const getWhatsNewBySectorLoad = () => ({
  type: GET_WHATS_NEW_BY_SECTOR_LOAD,
});
const getWhatsNewBySectorSuccess = (data) => ({
  type: GET_WHATS_NEW_BY_SECTOR_SUCCESS,
  payload: data,
});
const getWhatsNewBySectorFailed = (error) => ({
  type: GET_WHATS_NEW_BY_SECTOR_FAILED,
  err: error,
});
export const getWhatsNewBySector = (sector) => async (dispatch) => {
  dispatch(getWhatsNewBySectorLoad());
  try {
    const res = await services.api(
      "GET",
      `/ui-assist/v1/whatsnewsection/scope/${sector}`,
      {}
    );
    if (res) {
      dispatch(getWhatsNewBySectorSuccess(res.data));
    }
  } catch (err) {
    dispatch(getWhatsNewBySectorFailed(err.response));
  }
};
