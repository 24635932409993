import React from "react";
import SubmitButton from "../../../Common/Button/SubmitButton";

function AuthButton({ label, handleClick }) {
  return (
    <div>
      <SubmitButton
        color="secondary"
        label={label}
        type="submit"
        variant="contained"
        className="fullWidth submitButton commonFontNormalStyle"
        onClick={handleClick}
      />
    </div>
  );
}

export default AuthButton;
