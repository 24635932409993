import React from "react";
import { Grid } from "@material-ui/core";
import SubmitButton from "../../../Common/Button/SubmitButton";
import { baseUrl } from "../../../../Services";

const PolicyPDF = ({ file }) => {
  return (
    <>
      <Grid
        container
        direction="row"
        justifyContent="flex-start"
        alignItems="center"
        spacing={2}
        className="alignCards"
      >
        <Grid item xs={12} sm={12} className="">
          {/* <SubmitButton
            label="Policy Detail"
            className="MCsubmitbutton floatRightAlign"
            onClick={onClick}
          /> */}
          <a
            target="_blank"
            className="pdfButton"
            href={`${baseUrl}/ui-assist/v1/download/${file}`}
          >
            POLICY DETAIL
          </a>
        </Grid>
      </Grid>
    </>
  );
};

export default PolicyPDF;
