import React from "react";
import { Switch } from "react-router-dom";
import NotFound from "../../Components/NotFound/NotFound";
import {
  Support,
  EthicsHelpline,
  LegalSupport,
  RemoteSupportt,
  ITSupportt,
  InfrastructureSupportt,
  RemoteWorkingg,
  RemoteAArticle,
  RemoteArticleDetail,
  RemoteUploadArticle,
  RemoteUploadNewArticle,
} from "../constant";
import EthicsHelpLine from "../../Components/Pages/Support/EthicsHelpLine";
import LegalSupportt from "../../Components/Pages/Support/LegalSupport/LegalSupport";
import RemoteSupport from "../../Components/Pages/Support/RemoteSupport/RemoteSupport";
import ITSupport from "../../Components/Pages/Support/ITSupport/ITSupportPage";
import InfrastructureSupport from "../../Components/Pages/Support/InfrastructureSupport/InfrastructureSupport";
import RemoteWorking from "../../Components/Pages/Remote/RemoteWorking";

// import RemoteArticle from "../../Components/Pages/LiveWireMcArticle2/RemoteArticle";
import UploadArticle from "../../Components/Pages/Remote/UploadArticle/UploadArticleComponent";
import RemoteArticle from "../../Components/Pages/Remote/RemoteArticle";
import { ApmRoute } from "@elastic/apm-rum-react";

function SupportRoutes(props) {
  return (
    <div>
      <Switch>
        <ApmRoute
          path={`${Support}${EthicsHelpline}`}
          component={EthicsHelpLine}
        />
        <ApmRoute
          path={`${Support}${LegalSupport}`}
          component={LegalSupportt}
        />
        <ApmRoute
          path={`${Support}${RemoteSupportt}`}
          component={RemoteSupport}
        />
        <ApmRoute path={`${Support}${ITSupportt}`} component={ITSupport} />
        <ApmRoute
          path={`${Support}${InfrastructureSupportt}`}
          component={InfrastructureSupport}
        />
        {/* <ApmRoute
          path={`${Support}${RemoteArticleDetail}`}
          component={RemoteArticleDetail}
          exact
        /> */}
        <ApmRoute
          path={`${Support}${RemoteWorkingg}`}
          component={RemoteWorking}
        />
        <ApmRoute
          path={`${Support}${RemoteAArticle}`}
          component={RemoteArticle}
          exact
        />
        <ApmRoute
          path={`${Support}${RemoteUploadArticle}`}
          component={UploadArticle}
          exact
        />
        <ApmRoute
          path={`${Support}${RemoteUploadNewArticle}`}
          component={UploadArticle}
          exact
        />

        <ApmRoute component={NotFound} />
      </Switch>
    </div>
  );
}

export default SupportRoutes;
