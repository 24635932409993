import React from "react";
import "./Snackbar.css";

import Snackbar from "@mui/material/Snackbar";
import Error from '../../../Assets/Images/SnackBar/alertError.svg'
import Success from '../../../Assets/Images/SnackBar/greenS.svg'
function SnackbarC({ open, handleClose, action,  autoHideDuration , errorMessage, successMessage, customMessage, align, classname}) {
   const  renderMessage = () => {
    if(errorMessage){
        return (
            <>
            <div style={{ display: "flex", alignItems: "start", width: "400px" }}>
                <img src={Error} alt="Download line" className="sImg" style={{ marginLeft: "16px" }} />
                <div className="contentS1">{errorMessage}</div>
            </div>
            </>
        )
    }else if (successMessage){
        return (<>
            <div style={{ display: "flex", alignItems: "start", width: "400px" }}>
                <img src={Success} alt="Download line" className="sImg" style={{ marginLeft: "16px" }} />
                <div className="contentS1">{successMessage}</div>
            </div>
            </>)
    }else if(customMessage){
        return (<>
            {customMessage}
            </>)
    }
    }
    
    return (
        <div>
            <Snackbar
                open={open}
                anchorOrigin={align}
                autoHideDuration={autoHideDuration}
                onClose={handleClose}
                message={renderMessage()}
                action={action}
               
                className={classname ?"sB bloodBank":"sB"}
            />
        </div>
    );
}


SnackbarC.defaultProps = {    
    autoHideDuration: 2500,
    anchorOrigin: { vertical: "top", horizontal: "right" }
 
}

export default SnackbarC;
