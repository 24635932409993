import React, { useState, useRef } from "react";
import Dropdown from "react-multilevel-dropdown";
import { Icon, makeStyles } from "@material-ui/core";
import "./DropDown.css";
import PropTypes from "prop-types";
import { Link, useLocation, useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";
import { RBoolean } from "../../../Redux/Actions/MicrofeedbackAction";
import { addAnalayticsLog } from "../../../Redux/Actions/Anaylatics";
import platform from "platform";
// import { todayToolTipAction } from "../../../Redux/Actions/TodayToolTipAction";
// import { getProfileDetailsData } from "../../../Redux/Actions/ProfileDetailsAction";

const useStyles = makeStyles({
  customTooltip: {
    backgroundColor: "#fefdfd",

    fontSize: "12px",
    width: "250px",
    textAlign: "center",
    padding: "8px 8px 8px 8px",
    borderRadius: "0px",
    color: "#242424",
    fontWeight: "normal",
    position: "relative",
    top: "23px",
  },
  customArrow: {
    color: "#fefdfd",
  },
});
const useProfileStyles = makeStyles({
  customProfileTooltip: {
    backgroundColor: "#fefdfd",

    fontSize: "12px",
    width: "208px",
    textAlign: "center",
    padding: "8px 24px 8px 24px",
    borderRadius: "0px",
    color: "#242424",
    fontWeight: "normal",
    position: "relative",
    top: "15px",
  },
  customProfileArrow: {
    color: "#fefdfd",
  },
});

function DropDown({
  config,
  isMobile,
  position,
  className,
  hidenavigationdropDown,
  handleNavigationDropDownHide,
  handleNavigationDropDownShow,
  handleMouseEnter,
  handleMouseLeave,
}) {
  // console.log( config,
  //   isMobile,
  //   position,
  //   className,
  //   hidenavigationdropDown,
  //   handleNavigationDropDownHide,
  //   handleNavigationDropDownShow,
  //   handleMouseEnter,
  //   handleMouseLeave, 'dropdown logs')
  const dynamicStyle = (title, options) => {
    if (title === "Corporate social responsibility") {
      return "coSResStyle";
    }

    if (title === "Board") {
      return "lastsubnav";
    }

    if (title === "Role based") {
      const submenulength = options?.length;

      if (submenulength >= 7) {
        //console.log("lengt",options?.length);
        return `ddlRoleBased moredata`;
      }
    }
  };

  const location = useLocation();
  const history = useHistory();
  const classes = useStyles();
  const classesProfile = useProfileStyles();
  const getUrlHead = location.pathname.split("/");
  const [hideToolTip, setHideToolTip] = useState(false);
  const mainDivOfNavigation = useRef(null);
  const dispatch = useDispatch();
  const [hideShowToolTip, setHideShowTooltip] = useState(false);
  const [title, setTitle] = useState("");
  const handleLogout = () => {
    localStorage.clear();
    window.location.href = `/ui-assist/logout`;
    // history.push(`${AUTH}${LOGIN}`);
  };

  // useEffect(() => {
  //   dispatch(getProfileDetailsData());
  // }, []);

  // useEffect(() => {
  //   const checkIfClickedOutside = (e) => {
  //     if (
  //       hideToolTip &&
  //       mainDivOfNavigation.current &&
  //       !mainDivOfNavigation.current.contains(e.target)
  //     ) {
  //       setHideToolTip(false);
  //       dispatch(todayToolTipAction(false));
  //       hideTooltipRightNavigationFalse && hideTooltipRightNavigationFalse();
  //     }
  //   };

  //   document.addEventListener("mousedown", checkIfClickedOutside);

  //   return () => {
  //     // Cleanup the event listener
  //     document.removeEventListener("mousedown", checkIfClickedOutside);
  //   };
  // }, []);

  // const handleToolTipVisibility = useCallback(() => {
  //   setHideToolTip((preState) => !preState);
  //   dispatch(todayToolTipAction(!hideToolTip));
  //   // handleForRightNavigation && handleForRightNavigation(!hideToolTip);
  // }, [hideToolTip]);

  const handleShareFeedback = () => {
    dispatch(RBoolean());
  };

  // const handleToolTip = (title) => {
  //   setHideShowTooltip(!hideShowToolTip);
  //   setTitle(title);
  //   }

  const handleSubmenuClick = (subMenu) => {
    dispatch(
      addAnalayticsLog({
        module: subMenu.title,

        browserDetails: `${platform.name} ${platform.os.architecture} ${platform.os.family} ${platform.os.version}`,

        deviceDetails: platform.product ? platform.product : "Desktop",

        isSearch: false,
      })
    );
    handleNavigationDropDownHide();
  };

  const handleSubOptionsclick = (menu, subOptions) => {
    console.log("i was called and i am suboptions......");
    console.log(subOptions.linkName, "@@@@@@@@@@@@@@@@@@@@@@@@@@");
    dispatch(
      addAnalayticsLog({
        module: `${menu?.title}/${subOptions.linkName}`,

        browserDetails: `${platform.name} ${platform.os.architecture} ${platform.os.family} ${platform.os.version}`,

        deviceDetails: platform.product ? platform.product : "Desktop",

        isSearch: false,
      })
    );
    handleNavigationDropDownHide();
  };

  let css = "";

  return (
    <>
      {!isMobile &&
        config &&
        config.map((result, index) => (
          // <Tooltip
          //   classes={{
          //     tooltip:
          //       result.title.props?.alt === "profile"
          //         ? `${classesProfile.customProfileTooltip}`
          //         : `${classes.customTooltip}`,
          //     arrow: classesProfile.customProfileArrow,
          //   }}
          //   title={hideToolTip ? "" : result.tooltip}
          //   arrow
          //   key={index}
          // >
          <div
            className={`responsiveCheck ${
              result.title.props?.alt === "profile"
                ? "profileDropDownStyle"
                : ""
            }`}
            key={index}
            //onClick={handleToolTipVisibility}
            ref={mainDivOfNavigation}
            // onClick={() => handleToolTip(result.title)}
          >
            <Dropdown
              title={result.title}
              className={`dropDownContainer  ${
                getUrlHead[1] === result.title.props?.children?.props.alt
                  ? "activeRectangle"
                  : ""
              } ${
                getUrlHead[1] === result.title.toString().toLowerCase()
                  ? "activeNavTab"
                  : ""
              } ${className === "fromCenter" && "fromCenter"}`}
              onFocus={handleNavigationDropDownShow}
              onMouseEnter={handleMouseEnter}
              onMouseLeave={handleMouseLeave}
            >
              {result.subMenu &&
                !hidenavigationdropDown &&
                result.subMenu.map((subMenu, i) => (
                  <div key={i} className="submenulist">
                    <Dropdown.Item
                      className={` dropDownItem ${
                        result.title.props ? "rightSubMenuOption" : ""
                      }  ${
                        subMenu.title === "Facilities"
                          ? "specificMenuScroll"
                          : dynamicStyle(subMenu.title, subMenu.subOptions)
                      }`}
                      onClick={() => {
                        handleSubmenuClick(subMenu);
                      }}
                    >
                      {subMenu.logout ? (
                        <Link
                          onClick={handleLogout}
                          to="#"
                          style={{ width: "100%" }}
                          className="DMenuItem"
                        >
                          {subMenu.logout}
                        </Link>
                      ) : subMenu.link ? (
                        <a
                          href={subMenu.link ? subMenu.link : "#"}
                          target={subMenu.link && "_blank"}
                          rel={subMenu.link && "noreferrer"}
                          className="DMenuItem"
                        >
                          {subMenu.title}
                        </a>
                      ) : subMenu.title === "Share feedback" ? (
                        <a onClick={handleShareFeedback} className="DMenuItem">
                          {subMenu.title}
                        </a>
                      ) : (
                        <Link
                          to={subMenu.linkR ? subMenu.linkR : ""}
                          className="DMenuItem"
                          style={{ width: "100%" }}
                        >
                          <div>{subMenu.title}</div>
                        </Link>
                      )}
                      {subMenu.subOptions && (
                        <Dropdown.Submenu position={position}>
                          {subMenu.subOptions
                            ?.filter(
                              (item, index, arr) =>
                                index ===
                                arr.findIndex(
                                  (menuItem) =>
                                    menuItem.linkName === item.linkName
                                )
                            )
                            ?.map((subOptions, index) => (
                              <Dropdown.Item
                                key={index}
                                className="dropDownSubMenuItem"
                                style={
                                  subOptions?.linkName ===
                                    "Internal job portal (Zing HR)" ||
                                  subOptions?.linkName ===
                                    "Career website (M careers)" ||
                                  subOptions?.linkName === "AFS Careers site"
                                    ? { color: "grey !important" }
                                    : {}
                                }
                                //onClick={handleNavigationDropDownHide}
                                onClick={() => {
                                  handleSubOptionsclick(subMenu, subOptions);
                                }}
                              >
                                {subOptions.linkName && !subOptions.linkR ? (
                                  <a
                                    href={
                                      subOptions.link ? subOptions.link : "#"
                                    }
                                    target={subOptions.link && "_blank"}
                                    rel={subOptions.link && "noreferrer"}
                                    className="dSumMenuItem"
                                  >
                                    <span
                                      style={
                                        subOptions?.linkName ===
                                          "Internal job portal (Zing HR)" ||
                                        subOptions?.linkName ===
                                          "Career website (M careers)" ||
                                        subOptions?.linkName ===
                                          "AFS Careers site"
                                          ? {
                                              color: "lightgrey",
                                              fontStyle: "italic",
                                            }
                                          : {}
                                      }
                                    >
                                      {subOptions.linkName}
                                    </span>
                                  </a>
                                ) : (
                                  <Link
                                    className="dSumMenuItem"
                                    to={
                                      subOptions.linkR ? subOptions.linkR : "#"
                                    }
                                  >
                                    {subOptions.linkName}
                                  </Link>
                                )}
                              </Dropdown.Item>
                            ))}
                        </Dropdown.Submenu>
                      )}
                      {subMenu.subOptions && (
                        <Icon className="navigateIcon">navigate_next</Icon>
                      )}
                    </Dropdown.Item>
                    {/* {subMenu.title === "Benefits & Claims" ||
                      (subMenu.title === "Performance" && (
                        <div className="dMdivider"></div>
                      ))} */}
                  </div>
                ))}
            </Dropdown>
            {/* <span className="tooltipdata">{result.title}</span> */}
          </div>
          // </Tooltip>
        ))}
    </>
  );
}

DropDown.propTypes = {
  config: PropTypes.array,
  isMobile: PropTypes.bool,
  position: PropTypes.string,
  className: PropTypes.string,
};

DropDown.defaultProps = {
  config: [],
  isMobile: false,
  position: "right",
  className: "",
};

export default DropDown;
