import Swal from "sweetalert2";
import services from "../../Services/index";
import {
    UPLOAD_PDF_SUCCESS,
    UPLOAD_PDF_LOAD,
    UPLOAD_PDF_FAILED,
} from "../Contants/Constants";

const getUploadPDFLoad = () => ({
    type: UPLOAD_PDF_LOAD,
});
const getUploadPDFSuccess = (data) => ({
    type: UPLOAD_PDF_SUCCESS,
    payload: data,
});
const getUploadPDFFailed = (error) => ({
    type: UPLOAD_PDF_FAILED,
    err: error,
});

export const getUploadPDFData = (data) => async (dispatch) => {
  dispatch(getUploadPDFLoad());
  try {
    const res = await services.api(
      "POST",
      `/ui-assist/v1/upload/document`,data
    );
    if (res) {
      dispatch(getUploadPDFSuccess(res.data));
    }
  } catch (err) {
    dispatch(getUploadPDFFailed(err.response));
    Swal.fire("Something went wrong!", `${err?.response?.data}`, "error");
  }
};