import React, { useState, useEffect } from "react";
import { Grid } from "@material-ui/core";
import CommonPDFUpload from "../../../../Common/CommonPDFUpload/CommonPDFUpload";
import Title from "./Title";
import CharLeftField from "../../../../Common/CharLeftField/CharLeftField";
import CommonLabel from "../../../../Common/ComonLabel/CommonLabel";

const MobileHandset = ({ data, formData, isValid }) => {
  
  const [applyURl, setApplyURl] = useState();
  const [policyPdf, setPolicyPdf] = useState();
  
  useEffect(() => {
    setPolicyPdf(data?.policyPdf);
  }, [data]);

  useEffect(() => {
    const check = applyURl && policyPdf;

    let formdata = new FormData();
    formdata.append("entitlementType", data?.entitlementType);
    formdata.append("applyURL", applyURl);
    formdata.append("policyPdf", policyPdf);

    formData(formdata);
    isValid(check);
  }, [applyURl, policyPdf]);
  
  const handleURL = (e) => {
    setApplyURl(e.target.value);
  };

  // console.log("contact person =====>", contactPerson);
  // console.log("timeline =====>", timelines);
  // console.log("escalationSPOC =====>", escalationSPOC);
  // console.log("policyPdf =====>", policyPdf);

  return (
    <>
      <Title title="Submit Bill URL" />
      <Grid container spacing={2} alignItems="top" className="mb-5">
        <Grid item sm={6} md={4} xs={12}>
          <CharLeftField
            label={<CommonLabel label="url" mandatory />}
            value={applyURl}
            name="applyURl"
            type="text"
            handleChange={handleURL}
          />
        </Grid>
      </Grid>
      <Title title="Policy PDF" />
      <Grid container spacing={2} alignItems="top">
        <Grid item sm={6} md={4} xs={12}>
          <CommonPDFUpload value={data?.policyPdf} setValue={setPolicyPdf} />
        </Grid>
      </Grid>
    </>
  );
};

export default MobileHandset;
