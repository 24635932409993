//Authentication Routes
export const AUTH = "/auth";
export const LOGIN = "/login";
export const FORGOT_PASSWORD = "/forgot-password";
export const OTP = "/otp";
export const RESET_LINK = "/reset-link";

export const NOT_AUTH = "/not-auth";

//Dashboard/Home page Routes
export const PROFILE_PAGE = "/profile";
export const DASHBOARD = "/";

//Universal Search page Routes
export const SEARCH = "/search";

//Live Wire Routes
export const LIVEWIRE = "/connect/livewire";
export const SUBMIT_OFFER_CAMPAIGN = "/submit-offer-campaign/:id?";
export const OFFER_CAMPAIGNS = "/offer-campaigns";
export const MAHINDRA_COLLECTIVE = "/mahindra-collective";
export const LEADERS_SPEAK = "/leader-speak";
export const ORG_ANNOUNCEMENT = "/org-announcement";
export const LIVE_WIRE_ARTICLE2 = "/mc-article2/allarticle";
export const LIVE_WIRE_ARTICLE3 = "/oc-article2/allarticle";
export const UPLOAD_A_STORY = "/upload-story/:id?";
export const UPLOAD_ARTICLE = "/view-article/:id?";
export const OC_REVIEW_ARTICLE = "/oc-review-article";
export const MLDLLIFE = "/lifepost";
export const MLDLLIFE_DETAILS = "/lifepost-details";

//Policies Routes
export const POLICIES = "/connect/policies";
export const POLICIES_SEARCH = "/policies-search";
export const POLICIES_SEARCH_TABLE = "/policies-search-table";
export const POLICIES_EDIT = "/policies-edit";
export const POLICIES_SUBCATEGORY = "/policies-subcategory";
export const POLICYS_EDIT = "/policys-edit";
export const POLICIES_DETAILS = "/policies-details";

//SOP Route
export const SOP = "/connect/sop";
export const SOP_DETAILS = "/sop-details";
export const SOP_SEARCH_TABLE = "/sop-search-table";

//support Routes
export const Support = "/contact/support";
export const EthicsHelpline = "/ethicshelpline";
export const LegalSupport = "/legalsupport";
export const RemoteSupportt = "/remotesupport";
export const ITSupportt = "/ITsupport";
export const InfrastructureSupportt = "/InfrastructureSupport";
export const RemoteWorkingg = "/remoteworking";
export const RemoteAArticle = "/remotearticle/:id?";
export const RemoteUploadNewArticle = "/upload-article/add";
export const RemoteUploadArticle = "/upload-article/:id?";
export const RemoteArticleDetail = "/remotearticle-detail";

//siteMap
export const SITE_MAP = "/site-map";
export const DISCLAIMER = "/legal-disclaimer";
export const PRIVACYPOLICY = "/privacy-policy";

//Approval Routes
export const Approval = "/connect/approval";
export const ApprovalCardd = "/approval-card";
export const ApprovalDetaill = "/approval-detail";

//Live Helpdesk routes
export const HELPDESK = "/helpdesk";

// DashBoardIcon from navgation
export const DASHBOARDICON = "/dashboard-icon";

//RollMatrix
export const WORK = "/work";
export const ROLE_BASED = "/role-based";
export const MANAGE_ACCESS = "/manage-access";
export const FEEDBACK_TABLE = "/feedback-table";
export const ADD_USER_GROUP = "/add-user-group";
export const EDIT_USER_GROUP = "/edit-user-group";
export const ADD_NEW_ADMIN = "/create-new-admin";
export const WALLPAPER = "/wallpaper";
export const POST_WALLPAPER_REQUEST = "/post-wallpaper-request";
export const SSO_TEST = "";
export const ER_DASHBOARD = "/er-dashboard";

//emergency contacts Routes
export const EMERGENCY_CONTACTS = "/emergency-contacts";

//Forex Center Routes
export const FOREX_CENTER = "/forex";

//Downtime  Routes
export const DOWNTIME = "/downtime";

//Entitlement Routes
export const ENTITLEMENT = "/Entitlement";

//Entitlement admin Routes
export const ENTITLEMENTADMIN = "/EntitlementAdmin/grade/:id";
export const ENTITLEMENTBENEFITS = "/EntitlementAdmin/benefit/:id";

//Entitlement Routes
export const CARLEASING = "/cnb/:id?";

//Health Routes
export const BLOOD_BANK = "/blood-bank";

//error page.
export const ERROR_PAGE = "/coming-soon";

//edit-welocme-banner
export const WELCOME = "/welcome";
export const EDIT_WELCOME_BANNER = "/edit-welcome-banner";

//Our Core
export const OUR_CORE = "/connect/our-core";

//Mahindra Experience
export const MAHINDRA_EXPERIENCE = "/experience";
export const MAHINDRA_EXPERIENCE_UPLOAD = "/experience-upload";
export const MAHINDRA_EXPERIENCE_UPDATE = "/experience-modify/:id?";
export const MAHINDRA_EXPERIENCE_VIEW = "/experience-view/:id?";
export const MAHINDRA_EXPERIENCE_DETAIL = "/experience-detail/:id?";

//Terms and condition...
export const TERMS_AND_CONDITION = "/cnc/:id?";

//MLDL
export const MLDL = "/connect/mldl";
