import {
    UPLOAD_FILE_SUCCESS,
    UPLOAD_FILE_LOAD,
    UPLOAD_FILE_FAILED,
} from "../Contants/Constants";

const initialState = {
  getData: "",
  isLoading: false,
  isError: "",
};

const getUploadFileReducer = (state = initialState, action) => {
  switch (action.type) {
    case UPLOAD_FILE_LOAD:
      return {
        ...state,
        isLoading: true,
      };
    case UPLOAD_FILE_SUCCESS:
      return {
        ...state,
        getData: action.payload,
        isLoading: false,
      };
    case UPLOAD_FILE_FAILED:
      return {
        ...state,
        isError: action.payload,
        isLoading: false,
      };
    default:
      return state;
  }
};

export default getUploadFileReducer;
