import React, { useState, useEffect } from "react";
import "./MLDLLifeTabComponent.css";
import { Tabs, Tab, Typography, Box, Grid } from "@material-ui/core";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";
import {
  getPolicySubCategoryData,
  getPolicyTableDataByID,
} from "../../../../Redux/Actions/PolicyCategoryAction";
import MLDLLifePostTableComponent from "../MLDLLifePostsTable/MLDLLifePostTableComponent";
import { useHistory } from "react-router-dom";
import {
  POLICIES,
  POLICIES_SUBCATEGORY,
  POLICIES_EDIT,
} from "../../../../Routes/constant";
import isAdmin from "../../../isAdmin";
import { isAccesible } from "../../../../Assets/helperFunctions/helperFunctions";
import LifePost from "../LifePost";
import { getLifePostTableData } from "../../../../Redux/Actions/MLDLLifepostAction";

export default function LifeTabComponent({ policyCategory, policyID, admin }) {
  const [categoryID, setCategoryID] = useState("");

  let checkAdmin = isAdmin();

  const history = useHistory();
  const dispatch = useDispatch();
  const [value, setValue] = React.useState(0);
  const [currentIndex, setcurrentIndex] = useState(null);

  const [subCategoryID, setSubCategoryID] = useState("");

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const [openModal, setOpenModal] = useState(false);
  const onEditPolicies = () => {
    setOpenModal(true);
  };

  const handleCloseModal = () => {
    setOpenModal(false);
  };
  const [addCardSelectedClass, setaddCardSelectedClass] = React.useState(0);
  const handleCardItemClick = (event, item, index) => {
    setaddCardSelectedClass(index);
    setcurrentIndex(index);
    setSubCategoryID(item?.policySubCategoryId);
  };

  //policies related data.

  useEffect(() => {
    dispatch(getLifePostTableData());
  }, [dispatch]);

  const LifePostTableDataState = useSelector(
    (state) => state.getLifepostTableDataReducer
  );
  const { getData: lifepostTableData } = LifePostTableDataState;

  const handleTabClick = (item, index, e) => {
    setCategoryID(item?.categoryId);
    setSubCategoryID("");
  };

  const handleEditClick = () => {
    setOpenModal(true);
  };

  return (
    <>
      <div className="policiesTabAlign">
        <Grid
          container
          direction="row"
          justifyContent="space-between"
          alignItems="center"
        >
          <div className="totlaPoliciesCount HidePoliciesTableMbl">
            Showing{" "}
            <span className="highlightCount">
              {lifepostTableData?.length}{" "}
              {lifepostTableData?.length > 1 ? "LIFEposts" : "LIFEpost"}
            </span>
          </div>
          {isAccesible("LIFE_POST") ? (
            <div className="editPoliciesStructure" onClick={onEditPolicies}>
              <img
                src={process.env.PUBLIC_URL + "/policies/edit-2-line.svg"}
                alt="Edit"
              />
              <span className="editPolicies">manage</span>
            </div>
          ) : (
            ""
          )}
        </Grid>
      </div>
      <LifePost open={openModal} handleClose={handleCloseModal} />
      <div className="policyCountTable">
        {/* <div className="slidingArrow">
          <span className="iconArrow">
            <img src={arrowRight} alt="Download line" />
          </span>
        </div> */}
        <MLDLLifePostTableComponent
          className="HidePoliciesTableMbl"
          // tableData={policiesData.policiesTableData}
          // hideButton={false}
          policyTableData={lifepostTableData}
        />
      </div>
    </>
  );
}
