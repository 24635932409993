import { Button, makeStyles, Tooltip } from "@material-ui/core";
import React, { useState, useEffect, useRef } from "react";
import { getPolicySubCategoryData } from "../../../../Redux/Actions/PolicyCategoryAction";
import { useDispatch, useSelector } from "react-redux";
import editIcon from "../../../../Assets/Images/edit-line.svg";
import services from "../../../../Services";
import Swal from "sweetalert2";
import SubcategoryTable from "./SubcategoryTable";
import EditSubcategory from "../AddSubCategory/EditSubcategory";
import Subcategory from "../AddSubCategory/Subcategory";
import TextField from "@mui/material/TextField";

const useStyles = makeStyles({
  customTooltip: {
    backgroundColor: "#fefdfd",
    
    fontSize: "12px",
    width: "200px",
    padding: "8px 8px 8px 8px",
    borderRadius: "0px",
    color: "#242424",
    fontWeight: "normal",
    lineHeight: "1.67",
    position: "relative",
    right: "40px",
  },
  customArrow: {
    color: "#fefdfd",
  },
});

function CategoryTabPanel({
  element,
  handleSubmitButton,
  setstate,
  setgetCatID,
  setrefresh,
  refresh,
}) {

  const [cElement, setCelement] = React.useState(element);

  const [open, setopen] = useState(false);
  const [openEditCategory, setopenEditCategory] = useState(false);
  const [disabled, setdisabled] = useState(true);
  const inputRef = useRef();

  React.useEffect(() => {
    setCelement(element);
  }, []);


  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getPolicySubCategoryData(element.categoryId));
  }, []);

  const getPolicySubCategoryDataState = useSelector(
    (state) => state.getPolicySubCategoryDataReducer
  );
  const { getData: subcategoryData } = getPolicySubCategoryDataState;
  const [categoryupdate, setCategoryUpdate] = useState(element.category);
  const [errorText, setErrorText] = useState('')
  //setstate('')
  const handleInputChange = (e) => {
    const {value} = e.target;
    if(value){
      setCategoryUpdate(e.target.value);
      setstate(e.target.value);
      setgetCatID(element.categoryId);
      setErrorText('')
    }else if(value === ''){
      setCategoryUpdate('');
      setgetCatID(element.categoryId);
      setErrorText('this field is required')
    }
  };
  const handleSubCategoryEditClick = () => {};
  const handelAddSubcategory = () => {
    setopen(true);
  };
  
  const handleDelete = (item) => {
    let removeData = { policySubCategoryId: item.policySubCategoryId };
    handleCategoryDeleteApi(removeData);
  };
  
  const handleCategoryDeleteApi = async (data) => {
    Swal.fire({
      title: "Are you sure?",
      text: "All Policies in this category will be deleted",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        services
          .api("DELETE", `/ui-assist/v1/policycategory/${data}`)
          .then((res) => {
            Swal.fire("Deleted!", "Policy has been deleted.", "success");
            setrefresh(!refresh);
          })
          .catch((err) => {
            Swal.fire({
              icon: "error",
              title: "Oops...",
              text: "Something went wrong!",

              footer: `${err.message}`,
            });
          });
      }
    });
  };

  const handleCategoryEdit = () => {
    setdisabled(false);
    inputRef.current.focus();
  };
  const handleCloseModal = () => {
    setopen(false);
  };
  const handleDeleteCategory = () => {
    handleCategoryDeleteApi(element.categoryId);
  };

  

  const useStyles = makeStyles({
    customTooltip: {
      backgroundColor: "#fefdfd",
      
      fontSize: "12px",
      width: "326px",
      padding: "8px 8px 8px 8px",
      borderRadius: "0px",
      color: "#242424",
      fontWeight: "normal",
      lineHeight: "1.67",
      position: "relative",
      right: "40px",
    },
    customArrow: {
      color: "#fefdfd",
    },
  });

  const classes = useStyles();
  const handleBlur = () => {
   // setstate('')
  }
 

  return (
    <>
      <div
        style={{ display: "flex", justifyContent: "space-between" }}
        className="policyCategoryManageCss"
      >
        <div>
          <div
            style={{
              
              fontSize: "12px",
              color: "rgba(0,0,0,0.6)",
              lineHeight: "1.67",
              marginLeft: "18px",
            }}
          >
            Category name
          </div>
          <input
            disabled={disabled}
            value={categoryupdate}
            ref={inputRef}
            type="text"
            onChange={(e) => {
              handleInputChange(e);
            }}
            className="categoryModalInput"
            onBlur={handleBlur}
          />
          {errorText ? <span style = {{color : 'red', fontSize :'12px'}}>this field is requrired</span>: ''}
          </div>
        {/* <TextField
                    id="filled-basic"
                    label="Category name"
                    variant="filled"
                    disabled={disabled}
                    value={categoryupdate}
                    ref={inputRef}
                    type="text"
                    onChange={(e) => {
                        handleInputChange(e);
                    }}
                    className="categoryModalInput"
                /> */}
        <div>
          <Button onClick={handleCategoryEdit}>
            <img
              src={process.env.PUBLIC_URL + "/policies/edit-2-line.svg"}
              alt="Add"
            />

            <div
              style={{
                fontSize: "12px",
                fontWeight: "600",
                color: "#e31837",
                marginLeft: "4px",
                
              }}
            >
              EDIT CATEGORY NAME
            </div>
          </Button>
          <Tooltip
            classes={{
              tooltip: classes.customTooltip,
              arrow: classes.customArrow,
            }}
            title="Category can be deleted when all sub-categories and policies in it are deleted "
          >
            <Button
              onClick={() => {
                handleDeleteCategory();
              }}
            >
              <img
                src={process.env.PUBLIC_URL + "/policies/delete-row.svg"}
                alt="Add"
              />
              <div
                style={{
                  fontSize: "12px",
                  fontWeight: "600",
                  color: "#a7a9ac",
                  marginRight: "16px",
                  marginLeft: "4px",
                  
                }}
              >
                DELETE CATEGORY
              </div>
            </Button>
          </Tooltip>
        </div>
      </div>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          marginLeft: "24px",
          marginRight: "24px",
        }}
      >
        <p style={{ fontSize: "18px", fontWeight: "600",paddingTop:"4px",marginBottom:"8px" }}>Sub-Categories</p>
        <div>
          <img
            src={process.env.PUBLIC_URL + "/policies/add-fill.svg"}
            alt="Add"
            style={{ marginRight: "-6px" }}
          />
          <Button
            onClick={handelAddSubcategory}
            style={{
              
              fontSize: "12px",
              fontWeight: "600",
              color: "#e31837",
              paddingRight: "0px",
            }}
          >
            ADD SUBCATEGORY
          </Button>
        </div>
      </div>

      <SubcategoryTable
        element={element}
        setopenEditCategory={setopenEditCategory}
      />
      <EditSubcategory
        open={open}
        handleClose={handleCloseModal}
        icon={editIcon}
        element={element}
        setopen={setopen}
      />
      <Subcategory
        open={openEditCategory}
        handleClose={handleCloseModal}
        icon={editIcon}
        element={element}
      />
    </>
  );
}

export default CategoryTabPanel;
