import React, { memo, useState, useEffect, useCallback } from "react";
import SubmitButton from "../../Common/Button/SubmitButton";
import { Grid } from "@material-ui/core";
import CharLeftField from "../../Common/CharLeftField/CharLeftField";
import CommonLabel from "../../Common/ComonLabel/CommonLabel";
import NewsPaper from "../../../Assets/Images/newspaper.svg";
import uploadIcon from "../../../Assets/Images/collectiveIcons/upload-2-line.svg";
import DeleteBin from "../../../Assets/Images/delete-bin-line.svg";
import DatePicker from "../../Common/DatePicker/DatePicker";
import CustomMultiSelect from "../../Common/CustomMultiSelect/CustomMultiSelect";
import CommonCheckBox from "../../Common/CommonCheckBox/CommonCheckBox";
import {
  getFilteredBUs,
  getFilteredFunctions,
} from "../../../Assets/helperFunctions/helperFunctions";
import { useDispatch, useSelector } from "react-redux";
import {
  getSectors,
  getBusinessUnits,
  getAllFunctions,
} from "../../../Redux/Actions/MastersAction";
import { orgAnnouncementSchema } from "../../../Assets/formikErrors/SubmitOfferCampaignSchema";
import { useFormik } from "formik";
import moment from "moment";
import Swal from "sweetalert2";
import services from "../../../Services";
import { getTitlesOfDropdown } from "../../../Assets/helperFunctions/helperFunctions";
import { GET_ORG_VIEWS_FAILED } from "../../../Redux/Contants/Constants";
import axios from "axios";

export default memo(function InternalForm({
  index = -1,
  data = undefined,
  needToRefreshUI,
  disableFlagsOnAPIcall,
}) {
  const [isAddLoader, setIsAddLoader] = useState(false);
  const [hastagsLeft, setHastagsLeft] = React.useState(3);

  const [selectedSectors, setSelectedSectors] = useState([]);
  const [selectedBusinessUnits, setSelectedBusinessUnits] = useState([]);
  const [selectedFunctions, setSelectedFunctions] = useState([]);
  const [uploadOrgPdfTitle, setUploadOrgPdfTitle] = useState(undefined);
  const [isNotify, setIsNotify] = useState(false);
  const [confirmNotify, setConfirmNotify] = useState(false);

  const [showBusinessUnit, setShowBusinessUnit] = useState(true);
  const getOptionLabel = (option) => `${option.title}`;
  const getOptionDisabled = (option) => option.value === "foo";

  //UseSelectors
  const allSectorsData = useSelector(
    (state) => state?.getSectorsReducer?.getData
  );
  const allFunctionsData = useSelector(
    (state) => state.getFunctionsReducer.getData
  );

  const allBusinessUnitsDetails = useSelector(
    (state) => state?.getBusinessUnitsReducer?.getData
  );
  const [allBusinessUnitsData, setAllBusinessUnitsData] = useState([]);
  const dispatch = useDispatch();

  //MARK: - Life cycle

  useEffect(() => {
    async function fetchData() {
      if (!allSectorsData) {
        dispatch(getSectors());
      }
      if (!allFunctionsData) {
        dispatch(getAllFunctions());
      }
      if (!allBusinessUnitsData) {
        dispatch(getBusinessUnits([]));
      }
    }
    fetchData();
  }, []);

  useEffect(() => {
    if (allSectorsData) {
      if (!data) {
        setSelectedSectors(allSectorsData);
        // formik.setFieldValue("selectedSectors", allSectorsData);
      } else {
        let sectorsForExistRecord = getTitles(data.sector, allSectorsData);
        setSelectedSectors(sectorsForExistRecord);
        // formik.setFieldValue("selectedSectors", sectorsForExistRecord);
      }
    }
  }, [allSectorsData]);

  useEffect(() => {
    if (allBusinessUnitsDetails) {
      if (!data) {
        setSelectedBusinessUnits(allBusinessUnitsDetails);
        // formik.setFieldValue("selectedBusinessUnits", allBusinessUnitsDetails);
      } else {
        let buForExistRecord = getTitles(
          data.businessUnit,
          allBusinessUnitsDetails
        );
        setSelectedBusinessUnits(buForExistRecord);
        // formik.setFieldValue("selectedBusinessUnits", buForExistRecord);
      }
    }
  }, [allBusinessUnitsDetails]);

  useEffect(() => {
    if (allFunctionsData) {
      if (!data) {
        setSelectedFunctions(allFunctionsData);
        // formik.setFieldValue("selectedFunctions", allBusinessUnitsDetails);
      } else {
        let functionsForExistRecord = getTitles(
          data.function,
          allFunctionsData
        );
        setSelectedFunctions(functionsForExistRecord);
        // formik.setFieldValue("selectedFunctions", functionsForExistRecord);
      }
    }
  }, [allFunctionsData]);

  useEffect(() => {
    if (data) {
      setUploadOrgPdfTitle(
        data?.documentFileName ? data?.documentFileName : ""
      );
      formik.setFieldValue("isFromEdit", true);
      // setIsNotify(data?.notify ? true : false);
      // setConfirmNotify(data?.notify ? true : false);
    }
  }, [data]);

  // useEffect(() => {
  //   if (allBusinessUnitsData) return;
  //   setAllBusinessUnitsData(allBusinessUnitsDetails);
  // }, [allBusinessUnitsDetails]);

  // useEffect(() => {
  //   if (selectedBusinessUnits) return;
  //   if (allBusinessUnitsData && allBusinessUnitsData.length > 0) {
  //     setSelectedBusinessUnits(allBusinessUnitsData);
  //   }
  // }, [allBusinessUnitsData]);
  //MARK: - Life cycle

  //Evenet handlers
  const handleSectorsToggleOption = (selectedOptions) => {
    console.log("selectedSectors", selectedOptions);
    setSelectedSectors(selectedOptions);
    if (selectedOptions.length !== 0) {
      let data = getFilteredBUs(allBusinessUnitsDetails, selectedOptions, [
        "title",
        "value",
      ]);
      setSelectedBusinessUnits(data);
      setSelectedFunctions(allFunctionsData);
    }
  };

  const handleSelectAllSectors = (isSelected) => {
    if (isSelected) {
      setSelectedSectors(allSectorsData);
      let data = getFilteredBUs(allBusinessUnitsDetails, allSectorsData, [
        "title",
        "value",
      ]);
      // let data = getFilteredBUs(allBusinessUnitsDetails, allSectorsData, [
      //   "title",
      //   "value",
      // ]);
      setSelectedBusinessUnits(data);
      setSelectedFunctions(allFunctionsData);
    } else {
      handleClearSectors();
    }
  };

  const handleClearSectors = () => {
    setSelectedSectors([]);
    setSelectedBusinessUnits([]);
    setSelectedFunctions([]);
  };

  const handleBusinessUnitsToggleOption = (selectedOptions) => {
    setSelectedBusinessUnits(selectedOptions);
  };

  const handleSelectAllBusinessUnits = (isSelected) => {
    if (isSelected) {
      setSelectedBusinessUnits(allBusinessUnitsDetails);

      // let data = getFilteredFunctions(allFunctionsData, selectedBusinessUnits, [
      //   "title",
      //   "value",
      // ]);
      setSelectedFunctions(allFunctionsData);
    } else {
      handleClearBusinessUnits();
      handleClearFunctions();
    }
  };
  const handleClearBusinessUnits = () => {
    setSelectedBusinessUnits([]);
    setSelectedFunctions([]);
    //   handleBusinessChange(selectedBusinessUnits);
  };
  const handleFuncitonsToggleOption = (selectedOptions) => {
    setSelectedFunctions(selectedOptions);
    //   handleBusinessChange(selectedBusinessUnits);
  };

  const handleSelectAllFunctions = (isSelected) => {
    if (isSelected) {
      // let data = getFilteredFunctions(allFunctionsData, selectedBusinessUnits, [
      //   "title",
      //   "value",
      // ]);
      setSelectedFunctions(allFunctionsData);
    } else {
      handleClearFunctions();
    }
    //   handleBusinessChange(selectedBusinessUnits);

    // setSelectedFunctions([]);
  };
  const handleClearFunctions = () => {
    setSelectedFunctions([]);
    //   handleBusinessChange(selectedBusinessUnits);
  };

  const handleReplaceFile = () => {
    // setOrgAdminState({ ...orgAdminState, fileName: null });
  };
  const handlePdfChange = (e) => {
    setUploadOrgPdfTitle(e.target.files[0].name);
    // setCheckThumbnail(e.target.files[0]);
    formik.setFieldValue("uploadOrgPdf", e.target.files[0]);
  };

  const DeleteViewButton = ({ fileName }) => {
    return (
      <div
        item
        xs={2}
        className="imgZindex"
        style={{ width: "100%", justifyContent: "flex-end" }}
      >
        <a
          href={`${process.env.REACT_APP_API_BASE_URL}/ui-assist/v1/download/${fileName}`}
          target="_blank"
          rel="noreferrer"
        >
          <img
            src={NewsPaper}
            alt=""
            style={{
              margin: "0px 50px",
              cursor: "pointer",
            }}
          />
        </a>
        <img
          src={DeleteBin}
          alt=""
          style={{ cursor: "pointer" }}
          onClick={handleDeleteOrgApi}
        />
      </div>
    );
  };

  const getIdArray = (arr) => {
    if (arr) {
      return [...new Set(arr.map((item) => item.value))].toString();
    } else {
      return [];
    }
  };
  const getTitles = (list1, list2) => {
    let selectItems = [];
    if (list1 && list2) {
      list1?.map((item1) =>
        list2?.map((item2) =>
          item2.value == item1 ? selectItems.push(item2) : null
        )
      );
    }
    return selectItems;
  };

  const formik = useFormik({
    initialValues: {
      key: data?.key || "",
      name: data?.name || "",
      uploadOrgPdf: "",
      publishDate: data?.publishDate || undefined,
      selectedSectors: data?.sector
        ? getTitlesOfDropdown(data.sector, allSectorsData)
        : [],
      selectedBusinessUnits: [],
      selectedFunctions: [],
      uploadOrgPdfTitle: data?.documentFileName || "",
      isFromEdit: data ? true : false,
    },
    enableReinitialize: true,
    validationSchema: orgAnnouncementSchema,
    onSubmit: (values, { resetForm }) => handleSubmit(values, resetForm),
  });
  const handleSubmit = async (values, resetForm) => {
    const {
      name,
      key,
      uploadOrgPdf,
      publishDate,
      // selectedSectors,
      // selectedBusinessUnits,
      // selectedFunctions,
    } = values;
    setIsAddLoader(true);
    const formData = new FormData();
    formData.append("name", name);
    formData.append("key", key);
    formData.append("employeeId", localStorage.getItem("token"));
    if (uploadOrgPdf) {
      formData.append("documentFile", uploadOrgPdf);
    }

    if (typeof publishDate !== "string") {
      formData.append(
        "publishDate",
        moment(publishDate?.toDate()).format("YYYY-MM-DD")
      );
    } else {
      formData.append("publishDate", moment(publishDate).format("YYYY-MM-DD"));
    }

    formData.append("isNotify", confirmNotify);
    formData.append("sector", getIdArray(selectedSectors));
    formData.append("businessUnit", getIdArray(selectedBusinessUnits));
    formData.append("function", getIdArray(selectedFunctions));

    if (!data) {
      handleOrgDataSubmitApi(formData, resetForm);
    } else {
      formData.append("contentID", data.contentID);

      handleOrgDataUpdateApi(formData, resetForm);
    }
  };
  const handleOrgDataSubmitApi = async (data, resetForm) => {
    disableFlagsOnAPIcall();
    try {
      const res = await services.api(
        "POST",
        `/ui-assist/v1/organnouncement`,
        data
      );

      if (res) {
        const { data: dataFromApi } = res;
        Swal.fire("Data added successfully", ``, "success");

        async function genieoanotification() {
          function decodeIfEncoded(url) {
            try {
              const decodedUrl = decodeURIComponent(url);
              if (url !== decodedUrl) {
                return decodeURIComponent(decodedUrl);
              }
            } catch (e) {
              console.log(e);
            }
            return url;
          }

          function generateBlobUrl(blobUrl) {
            const cleanBlobUrl = decodeIfEncoded(blobUrl.replace(" ", "%20"));
            const url = new URL(cleanBlobUrl);
            url.search = new URLSearchParams({
              ...url.searchParams,
            }).toString();
            return url.toString();
          }

          const url = generateBlobUrl(
            `${process.env.REACT_APP_BLOB_STORAGE_URL}/mrisestorage/${dataFromApi.documentFileName}`
          );

          try {
            const formData = new FormData();
            formData.append("flag", "login");
            formData.append("callback", "https://work.mahindra.com/");
            formData.append("user_id", "api-user-00001");
            formData.append("Name", dataFromApi.name);
            formData.append(
              "Date_org",
              moment(dataFromApi.date).format("DD-MM-YYYY")
            );
            formData.append("Sector", dataFromApi.sector);
            formData.append("Pdf_url_org", url);

            await axios({
              method: "post",
              url: process.env.REACT_APP_OA_GENIE_NOTIFICATION_API_URL,
              data: formData,
              headers: {
                "Content-Type":
                  "multipart/form-data; boundary=<calculated when request is sent>",
              },
            });
          } catch (error) {
            console.error(error);
          }
        }
        genieoanotification();
        setIsAddLoader(false);
        needToRefreshUI();
        resetForm();
      } else {
        setIsAddLoader(false);
      }
    } catch (err) {
      if (err.response) {
        Swal.fire(err.response, ``, "error");
        console.log("erro", err.response);
        setIsAddLoader(false);
      }
    }
  };
  const handleOrgDataUpdateApi = async (data, resetForm) => {
    disableFlagsOnAPIcall();

    try {
      const res = await services.api(
        "PUT",
        `/ui-assist/v1/organnouncement`,
        data
      );
      if (res) {
        Swal.fire("Data updated successfully", ``, "success");
        setIsAddLoader(false);
        needToRefreshUI(true);
        resetForm();
      } else {
        setIsAddLoader(false);
      }
    } catch (err) {
      if (err.response) {
        Swal.fire(err.response, ``, "error");
        console.log("erro", err.response);
        setIsAddLoader(false);
      }
    }
  };
  const handleDeleteOrgApi = async () => {
    disableFlagsOnAPIcall();

    try {
      const res = await services.api(
        "DELETE",
        `/ui-assist/v1/organnouncement/contentId/${data.contentID}`
      );
      if (res) {
        switch (res?.status) {
          case 200:
            needToRefreshUI();
            Swal.fire("Record successfully deleted");
            break;
          case 400:
            Swal.fire("Unable to delete record");
            break;
          default:
            Swal.fire("Something went wrong");
            break;
        }
      } else {
        Swal.fire("Something went wrong..");
      }
    } catch (err) {
      if (err.response) {
        Swal.fire(err.response, ``, "error");
        console.log("erro", err.response);
      } else {
        Swal.fire("Something went wrong...");
      }
    }
  };
  const handleChange = useCallback(
    (e) => {
      // console.log("tesdffsdfs", e.target);
      formik.handleChange(e);
    },
    [formik]
  );

  const announcementName = () => {
    return (
      <Grid item xs={11} sm={6} className="announcementName ">
        <CharLeftField
          label={<CommonLabel label="Announcement name" />}
          handleChange={handleChange}
          handleBlur={formik.handleBlur}
          errorMsg={formik.errors.name ? formik.errors.name : ""}
          value={formik.values.name}
          name="name"
          inputProps={{
            maxLength: 150,
          }}
          type="text"
          // defaultValue="jkjkj"
          mandatory
        />
      </Grid>
    );
  };
  const BuisenssSectorFunction = () => {
    return (
      <div
        style={{
          paddingTop: 20,
          display: "flex",
          flexDirection: "row",
          width: "100%",
          justifyContent: "space-evenly",
        }}
      >
        <Grid container direction="row" className="innerC">
          <Grid
            item
            xs={12}
            sm={3}
            className="businessTypeIcon sector addOrgFeilds mtDrop"
          >
            <CustomMultiSelect
              items={allSectorsData}
              getOptionLabel={getOptionLabel}
              getOptionDisabled={getOptionDisabled}
              selectedValues={selectedSectors}
              label="Sector"
              selectAllLabel="Select all"
              onToggleOption={handleSectorsToggleOption}
              onClearOptions={handleClearSectors}
              onSelectAll={handleSelectAllSectors}
              className="width100 mb-4"
            />
          </Grid>

          <Grid
            item
            xs={12}
            sm={3}
            className="businessTypeIcon sector addOrgFeilds mtDrop"
          >
            <CustomMultiSelect
              items={allBusinessUnitsDetails}
              getOptionLabel={getOptionLabel}
              getOptionDisabled={getOptionDisabled}
              selectedValues={selectedBusinessUnits}
              label="Business Unit"
              selectAllLabel="Select all"
              onToggleOption={handleBusinessUnitsToggleOption}
              onClearOptions={handleClearBusinessUnits}
              onSelectAll={handleSelectAllBusinessUnits}
              style={{ marginTop: "20px" }}
            />
          </Grid>
          <Grid
            item
            xs={12}
            sm={3}
            className="businessTypeIcon sector addOrgFeilds mtDrop"
          >
            <CustomMultiSelect
              items={allFunctionsData}
              getOptionLabel={getOptionLabel}
              getOptionDisabled={getOptionDisabled}
              selectedValues={selectedFunctions}
              label="Select function"
              selectAllLabel="Select all"
              onToggleOption={handleFuncitonsToggleOption}
              onClearOptions={handleClearFunctions}
              onSelectAll={handleSelectAllFunctions}
            />
          </Grid>
        </Grid>
      </div>
    );
  };
  const UploadImage = () => {
    let errorMsg = "";
    errorMsg = data
      ? formik.errors.uploadOrgPdfTitle
      : formik.errors.uploadOrgPdf;

    return (
      <>
        <Grid item sm={3} xs={12} className="uploadBtnStyle ">
          <div className="uploadBtn">
            <label htmlFor="thumb" className="btn uploadBtnLabel">
              {uploadOrgPdfTitle ? uploadOrgPdfTitle : "Select file"}{" "}
              <span className="colRed">*</span>
            </label>
            <img
              htmlFor="thumb"
              src={uploadIcon}
              className="uploadIconStyl"
              alt="Upload Button Icon"
            />
            <input
              id="thumb"
              style={{
                visibility: "hidden",
                padding: "19px 16px",
                position: "absolute",
              }}
              type="file"
              accept=".pdf"
              name="thumbnail"
              onChange={handlePdfChange}
            />
          </div>
          <span style={{ color: "#f44336", fontSize: 12 }}>{errorMsg}</span>
        </Grid>
        <Grid item sm={3} xs={12} className="uploadBtnStyle ">
          <div className="compatiblityTxt">Compatible formats: Pdf.</div>
        </Grid>
      </>
    );
  };
  const AnnoucenementKeys = () => {
    return (
      <Grid item xs={11} sm={6} className="announcementName">
        <CharLeftField
          label={<CommonLabel label="key" />}
          handleChange={(e) => {
            formik.setFieldValue("key", e.target.value);
          }}
          errorMsg={formik.errors.key ? formik.errors.key : ""}
          value={formik.values.key}
          name="key"
          inputProps={{
            maxLength: 150,
          }}
          type="text"
          mandatory
        />
      </Grid>
    );
  };
  const PublishDate = () => {
    return (
      <Grid item sm={3} xs={12} className="announcementName  ">
        <DatePicker
          label={<CommonLabel label="Publish date" />}
          selectedDate={formik.values.publishDate}
          // maxDate={new Date().setDate(new Date().getDate())}
          handleDateChange={(date) => {
            formik.setFieldValue("publishDate", date);
            // setpublishDate(date);
          }}
          name="publishDate"
          errorMsg={formik.errors.publishDate ? formik.errors.publishDate : ""}
          disableToolbarr={true}
        />
      </Grid>
    );
  };
  const AnnouncementButton = () => {
    return (
      <Grid item xs={11} sm={12} className="announcementName mbBtn addTBtn">
        <SubmitButton
          type="submit"
          disabled={isNotify && !confirmNotify}
          label={data ? "SAVE" : "ADD"}
          isLoader={isAddLoader}
          onClick={() => console.log("test hjsdgfjhsdgfjhsdgfjhsgdfjhsgdfhjg")}
        ></SubmitButton>
      </Grid>
    );
  };
  const handleNotificationChange = (event) => {
    if (isNotify) {
      setIsNotify(false);
      setConfirmNotify(false);
    } else {
      setIsNotify(true);
    }
  };
  const handleConfirmNotificationChange = (event) => {
    setConfirmNotify(!confirmNotify);
  };
  const NotificationCheck = () => {
    return (
      <>
        <Grid item xs={2} sm="auto" className="checkPad">
          <CommonCheckBox
            checked={isNotify}
            handleChange={handleNotificationChange}
          />
        </Grid>
        <Grid item xs={10} sm="auto">
          <span
            style={{
              fontSize: "16px",

              fontWeight: "500",
              color: "black",
            }}
          >
            Send notification to all users.
          </span>
        </Grid>
        {isNotify && (
          <Grid container className="NameUpload">
            <Grid item xs={2} sm="auto" className="checkPad1">
              <CommonCheckBox
                checked={confirmNotify}
                handleChange={handleConfirmNotificationChange}
              />
            </Grid>
            <Grid item xs={10} sm="auto">
              <span
                style={{
                  fontSize: "15px",

                  fontWeight: "500",
                  color: "red",
                }}
              >
                I agree to send notification to all users.
              </span>
            </Grid>
          </Grid>
        )}
      </>
    );
  };
  // console.log("check isNotify ====>",isNotify);
  return (
    <>
      <form onSubmit={formik.handleSubmit} key={index}>
        <Grid
          container
          direction="row"
          justifyContent="flex-start"
          alignItems="center"
          className="innerC"
        >
          <Grid container direction="row">
            <Grid sm={10}></Grid>
            <Grid
              item
              xs={12}
              sm={2}
              style={{
                marginTop: "0.5rem",
                paddingBottom: "0.5rem",
                float: "right",
                alignItems: "right",
              }}
            >
              {data && <DeleteViewButton fileName={data?.documentFileName} />}
            </Grid>
          </Grid>
          <Grid container spacing={2} className="NameUpload">
            {announcementName()}
            {UploadImage()}
            {AnnoucenementKeys()}
            {PublishDate()}
          </Grid>
          {BuisenssSectorFunction()}
          {NotificationCheck()}
          {AnnouncementButton()}
        </Grid>
      </form>
    </>
  );
});
