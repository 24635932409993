import React, { useState } from "react";
import "./PriorityTasks.css";
import { Card, Grid, useMediaQuery } from "@material-ui/core";
import group_694 from "../../../../Assets/Images/group694.svg";
import stay_focus from "../../../../Assets/Images/stay_focus.svg";
import dashBoard_2_line from "../../../../Assets/Images/dashboard-2-line.svg";
import CommonModal from "../../../Common/CommonModal/CommonModal";
import DetailsPriorityTasks from "./DetailsPriorityTasks";
import SortByDiv from "../../../Common/SortByDiv/SortByDiv";
import { diffDays } from "../../../../Assets/helperFunctions/helperFunctions";
import moment from "moment";

const tasks = [
  {
    name: "Subash Patahre",
    task: "Create Goal",
    dueDate: "24/06/2021",
  },
  {
    name: "Rahul Bose",
    task: "Create Goal for Stint 1",
    dueDate: "23/06/2021",
    viewDetails: true,
  },
  {
    name: "Suraj Thorad",
    task: "Employee IDAP Creation",
    dueDate: "No Due Date",
  },
  {
    name: "Nivedita Sinha",
    task: "Mid-Year Self Assessment",
    dueDate: "No Due Date",
  },
];

function PriorityTasks({ priorityData, filterData }) {
 
  const isMobile = useMediaQuery("(max-width:768px)");

  const [openPriortyCard, setOpenPriorityCard] = useState(false);
  const [getData, setGetData] = useState({});
  const [detailsPriorityTasks, setDetailsPriorityTasks] = useState(tasks);
  const [downArrowActive, setDownArrowActive] = useState(true);
  const [upArrowActive, setUpArrowActive] = useState(false);
  const [sortAscending, setSortAscending] = useState(false);
  const [headerTextTile, setHeaderTextTile] = useState("");

  const handleClosePriorityCard = () => {
    setOpenPriorityCard(false);
  };

  const handleDaysToGo = (result) => {
    let getDiffDays = diffDays(
      moment(result.date).format("DD/MM/YYYY"),
      moment(result.dueDate).format("DD/MM/YYYY")
    );
    return getDiffDays;
  };

  const handleOpenCard = (result) => {
    if (result.viewUrl) {
      window.open(result.viewUrl, "_blank");
    } else {
      let daysToGo = result.count ? `${result.count}` : handleDaysToGo(result);
      {/*let status = result.count ? "Tasks Pending" : "Days to go"; */}
      let status = result.RiseTextAdtl;
      let data = {
        daysToGo,
        status,
      };

      setOpenPriorityCard(true);
      setGetData(data);
      setHeaderTextTile(result.activity);
    }
  };

  const handleAscending = () => {
    setSortAscending(!sortAscending);
    setDownArrowActive(false);
    setUpArrowActive(true);
    let sortDataAscending = [...detailsPriorityTasks];
    if (sortAscending) {
      sortDataAscending.sort((a, b) => {
        if (a.dueDate < b.dueDate) {
          return -1;
        }
        if (a.dueDate > b.dueDate) {
          return 1;
        }
        return 0;
      });
      setDetailsPriorityTasks(sortDataAscending);
    } else {
      let sortDataAscending = [...detailsPriorityTasks];
      sortDataAscending.sort((a, b) => {
        if (a.dueDate > b.dueDate) {
          return -1;
        }
        if (a.dueDate < b.dueDate) {
          return 1;
        }
        return 0;
      });
      setDetailsPriorityTasks(sortDataAscending);
    }
  };

  // const handleDscending = () => {
  //   setDownArrowActive(true);
  //   setUpArrowActive(false);
  //   let sortDataAscending = [...detailsPriorityTasks];
  //   sortDataAscending.sort((a, b) => {
  //     if (a.dueDate > b.dueDate) {
  //       return -1;
  //     }
  //     if (a.dueDate < b.dueDate) {
  //       return 1;
  //     }
  //     return 0;
  //   });
  //   setDetailsPriorityTasks(sortDataAscending);
  // };

  const renderCard = (priorityData) => {
    if (priorityData.length === 0) {
      return (
        <NoPrioriTyTasks
          data={{
            text: "Yay! you are all caught up",
            subText: "No new tasks that require urgent attention",
            icon: group_694,
          }}
        />
      );
    } else if (filterData) {
      return (
        <Grid container>
          {filterData &&
            filterData.map((result, index) => (
              <Grid
                item
                md={6}
                xs={6}
                key={index}
                onClick={() => handleOpenCard(result)}
                className="hoverAnimation"
              >
                <Card
                  className={
                    index === 1 || index === 2
                      ? `alignTaskCard1 bgColor`
                      : `alignTaskCard0 shadowColor`
                  }
                  style={{
                    padding: "24px 0em 0em 24px",
                  }}
                  key={index}
                >
                  <p className="taskName taskNameMobile">{result.activity}</p>
                  <p className="daysToGo daysToGoMobile">
                    {result.count ? `${result.count}` : handleDaysToGo(result)}
                  </p>
                  <p className="todayStatus statusMobile">
                   {/* {result.count ? "Task pending" : "Days to go"} */}
                  {/*days to go */}
                  
                    {result.RiseTextAdtl}
                  </p>
                </Card>
              </Grid>
            ))}
        </Grid>
      );
    } else if (priorityData.length > 1 && priorityData.length <= 3) {
      return <PrioritDataContainer priorityData={priorityData} />;
    } else if (priorityData.length === 1) {
      return (
        <>
          <PrioritDataContainer priorityData={priorityData} />
        </>
      );
    }
  };

  return (
    <>
      {renderCard(priorityData)}
      <CommonModal
        open={openPriortyCard}
        handleClose={handleClosePriorityCard}
        headerText={headerTextTile}
        icon={dashBoard_2_line}
        modalSize="xl"
        fullWidth
        submitBtnText="DONE"
        onButtonClick={() => setOpenPriorityCard(false)}
      >
        {/* <div className={!isMobile ? "alignModalAlign" : ""}> */}
        <div
          className={!isMobile ? "alignModalAlign" : "alignCommonModalTitle"}
        >
          <div className="filterContentModel">
            <span className="daysToGoFromModal">
              {getData ? getData.daysToGo : ""}
            </span>
            <span className="tasksFromModal">
              {getData ? getData.status : ""}
            </span>
          </div>
          <SortByDiv
            handleAscending={handleAscending}
            // handleDscending={handleDscending}
            upArrowActive={upArrowActive}
            downArrowActive={downArrowActive}
            shortByText="Due Date"
          />
        </div>
        {/* </div> */}
        <DetailsPriorityTasks detailsPriorityTasks={detailsPriorityTasks} />
      </CommonModal>
    </>
  );
}
const handleClickTiles = (url) => {
  window.open(url, "_blank");
};
const handleDaysToGo = (result) => {
  let getDiffDays = diffDays(
    moment(result.date).format("DD/MM/YYYY"),
    moment(result.dueDate).format("DD/MM/YYYY")
  );
  return getDiffDays;
};

const PrioritDataContainer = ({ priorityData }) => {
  return (
    <Card elevation={3} className="outerCard">
      {priorityData.map((result, index) => (
        <Card
          elevation={3}
          className={`priority_data_card priority_data_card_container ${
            priorityData.length <= 2 ? "dynamicHeight" : ""
          }`}
          key={index}
          onClick={() => handleClickTiles(result.viewUrl)}
        >
          <p className="priorityTasks">{result.activity}</p>
          <div>
            <p className="daysToGo">
              {result.count ? `${result.count}` : handleDaysToGo(result)}
            </p>
            <p className="priorityStatus">
              
              {result.RiseTextAdtl}
            </p>
          </div>
        </Card>
      ))}
      {priorityData.length <= 1 && (
        <div className="stay_focused_Card_container">
          <img src={stay_focus} alt="stay_focus" />
          <p
            className="noPriorityTask_head_text"
            style={{ padding: "0px", margin: "0px" }}
          >
            Stay focussed!
          </p>
          <p
            className="noPriorityTask_sub_Text"
            style={{ padding: "0px", margin: "0px" }}
          >
            Clear out your to - do
          </p>
        </div>
      )}
    </Card>
  );
};

const NoPrioriTyTasks = ({ data }) => {
  const { icon, text, subText } = data;
  return (
    <Card className="noTaskCard">
      <div className="notaskContainer">
        <div>
          <img src={icon} alt="no group" />
        </div>
        <div className="noPriorityTask_head_text">
          <p>{text}</p>
        </div>
        <div className="noPriorityTask_sub_Text">
          <span>{subText}</span>
        </div>
      </div>
    </Card>
  );
};

export default PriorityTasks;
