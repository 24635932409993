import React, { useState } from "react";
import "./PreviewComponent.css";
import { Grid, Icon, Card, CardActionArea } from "@material-ui/core";
import moment from "moment";
import pdfIcon from "../../../../Assets/Images/pdf.png";
import CommonModal from "../../../Common/CommonModal/CommonModal";
import PdfViewerComponent from "../../../DashBoard/WhatsNew/PdfViewer/PdfViewerComponent";
import AuthorDetails from "../../LiveWireMcArticle2/AuthorSection/AuthorDetails";
import RemoteModal from "../../Remote/RemoteVideoModal";

export default function PreviewComponent({ previewData }) {
  console.log("dddddd");
  console.log("Preview Data ====> ", previewData);
  const {
    title,
    subTitle,
    story,
    name,
    empPhotoUrl,
    tags,
    expiryDate,
    thumbnail,
    mediaType,
    media,
    editorValue,
    docs,
    description,
    selectFileType,
    file,
    submittedFor,
    radioValues,
    isAuthorPost,
    videoUri,
  } = previewData;

  const [open, setOpen] = useState(false);
  const [contentType, setContentType] = useState(null);
  //For Remote modal
  const modal = () => {
    setOpenSettingssub(!openSettingssub);
  };
  const [openSettingssub, setOpenSettingssub] = useState(false);

  const handleClickOpen = (docs) => {
    setOpen(true);
    setContentType(mediaType === "document" || docs ? "pdf" : null);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const openPDF = (docfLink) => {
    return <PdfViewerComponent link={docfLink}></PdfViewerComponent>;
  };

  const getThumbnail = (getThumb) => {
    if (getThumb?.name) {
      return URL.createObjectURL(getThumb);
    } else {
      return getThumb;
    }
  };

  return (
    <div>
      <div className="publishArticleTitle">
        <Grid container direction="row">
          {tags?.length > 0 &&
            tags.map((result, i) => (
              <div className="tagStyle" key={i}>
                <span className="tagText">{result}</span>
              </div>
            ))}
          {/* <div className="tagStyle">
            <span className="tagText">Remote Work</span>
          </div> */}
        </Grid>
        <Grid
          container
          direction="column"
          justifyContent="flex-start"
          alignItems="flex-start"
        >
          <Grid item xs={12}>
            <p className="article2ContentTitle previewTitle">{title}</p>
            <p className="article2ContentSubTitle">
              {description ? description : subTitle}
            </p>

            {/* <div className="avatarDetailsAlignment">
              <img src={empPhotoUrl} alt="avatar" className="avatar" />
              <p className="articleAvatarName" style={{ marginBottom: "0px" }}>
                {name}
                <br />
                {moment(expiryDate).format("DD/MM/YYYY")}
              </p>
            </div> */}
            </Grid>
            <Grid container className="avatarDetailsAlignment">
              <AuthorDetails
                isAuthorPost={isAuthorPost}
                submittedByName={name}
                submittedByPhoto={empPhotoUrl}
                submittedForName={submittedFor}
              />
            </Grid>
            <Grid
          container
          direction="column"
          justifyContent="flex-start"
          alignItems="flex-start"
        >
            <div className="avatarNameDate">
              {/* <p className="avatarDate">
                  {moment(expiryDate).format("DD/MM/YYYY")}
                </p> */}
            </div>
            {(thumbnail || selectFileType?.value === "image") && (
              <div className="thumbnailDiv" style={{ position: "relative" }}>
                <img
                  className="thumbnailImagePreviewArticle"
                  src={getThumbnail(
                    selectFileType?.value === "image" ? file : thumbnail
                  )}
                  alt="thumbnail"
                />
                {videoUri && (mediaType === "youtube" || mediaType === "video") && (
                  <>
                    <img
                      src={process.env.PUBLIC_URL + "/youtube.png"}
                      alt="Card Logo"
                      className="videoIcon"
                      onClick={modal}
                    />
                  </>
                )}
              </div>
            )}
            {story && (
              <div
                dangerouslySetInnerHTML={{
                  __html:
                    story === "null" || story === null
                      ? ""
                      : editorValue
                      ? editorValue
                      : story,
                }}
              ></div>
            )}
            {mediaType === "document" &&
              (docs || media && (
                <span>
                  <Icon
                    // className="pdfStyle pointer"
                    className="pointer"
                    title="show PDF"
                    onClick={() => handleClickOpen(docs ? docs : media)}
                  >
                    <img
                      className="pointer pdfIcon"
                      src={pdfIcon}
                      alt="no pdf icon"
                    />
                  </Icon>
                </span>
              ))}
            <CommonModal
              open={open}
              handleClose={handleClose}
              header={false}
              footer={false}
              modalSize="lg"
              addClassForVideo={true}
              contentType={contentType}
              submitBtnText="DONE"
            >
              <Card className="videoPlayer">
                <CardActionArea className="videoPlayerCardArea">
                  {contentType === "pdf" &&
                    openPDF(docs ? docs : media ? media : null)}
                </CardActionArea>
              </Card>
            </CommonModal>
          </Grid>
        </Grid>
      </div>
      <RemoteModal
        open={openSettingssub}
        handleClose={() => setOpenSettingssub(false)}
        headerText="Video"
        modalSize="md"
        header={true}
        footer={true}
        fullWidth
        link={videoUri}
      />
    </div>
  );
}
