import React from "react";
import { Grid } from "@material-ui/core";

const ContactComponent = ({ data, title }) => {
  return (
    <>
      <Grid container direction="row" className="border2 py-2 ">
        <Grid item xs={2} sm={2} md={2}>
          <div className="TableContent">{title}</div>
        </Grid>
        <Grid item xs={8} sm={8} md={8}>
          <div className="TableContent fontboldnew">
            {data?.name}
            <span class="emailidfield">{" ( " + data?.email + " )"}</span>
          </div>
        </Grid>
      </Grid>
    </>
  );
};

export default ContactComponent;
