import React from "react";
import "./PreviewArticle.css";
import { Grid } from "@material-ui/core";
import HashTags from "./HashTags";
import parse from "html-react-parser";
import excel from "../../../../Assets/Images/ppt.svg";
import ppt from "../../../../Assets/Images/ppt.svg";

export default function PreviewArticle({ previewData, updatedAt }) {
  const { title, subTitle, editorValue, hasTags, docs } = previewData.values;

  let tags = hasTags.split(",");

  // console.log("Updated At =======> ", updatedAt);
  // var updated = new Date(updatedAt);
  // var updatedDate =
  //   updated.getDate() + "/" + updated.getMonth() + "/" + updated.getFullYear();

  var fileExtension = docs?.split(".")?.pop();

  return (
    <div>
      <div className="publishArticleTitle">
        <HashTags tags={tags} />
        <Grid
          container
          direction="column"
          justifyContent="flex-start"
          alignItems="flex-start"
        >
          <Grid item xs={12}>
            <p className="article2ContentTitle pdB">{title}</p>
            <p className="article2ContentSubTitle">{subTitle}</p>
            {/* <div className="avatarDetailsAlignment">
              <div className="avatarNameDate">
                <p className="avatarDate">{updatedDate}</p>
              </div>
            </div> */}
            <div>{parse("" + editorValue)}</div>
            {docs && (
              <div style={{ marginTop: "40px" }}>
                <div style={{ marginBottom: "12px" }}>
                  <strong>Attachments</strong>
                </div>
                <div>
                  {fileExtension == "pdf" ? (
                    <a
                      className="fileName"
                      href={`${process.env.REACT_APP_API_BASE_URL}/ui-assist/v1/download/true?fileName=${docs}`}
                      target="_blank"
                    >
                      <img
                        src={ppt}
                        alt="avatar"
                        style={{ marginLeft: "12px" }}
                      />
                    </a>
                  ) : (
                    <img src={excel} alt="avatar" />
                  )}
                </div>
              </div>
            )}
          </Grid>
        </Grid>
      </div>
    </div>
  );
}
