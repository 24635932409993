import services from "../../Services/index";
import Swal from "sweetalert2";
import {
    GET_INITIAL_STATUS_DATA_SUCCESS,
    GET_INITIAL_STATUS_DATA_LOAD,
    GET_INITIAL_STATUS_DATA_FAILED,
} from "../Contants/Constants";

const getInitialStatusLoad = () => ({
    type: GET_INITIAL_STATUS_DATA_LOAD,
});
const getInitialStatusSuccess = (data) => ({
    type: GET_INITIAL_STATUS_DATA_SUCCESS,
    payload: data,
});
const getInitialStatusFailed = (error) => ({
    type: GET_INITIAL_STATUS_DATA_FAILED,
    err: error,
});

export const getInitialStatusData = (id) => async (dispatch) => {
  dispatch(getInitialStatusLoad());
  try {
    const res = await services.api(
      "GET",
      `/ui-assist/v1/blooddonorcheck/${id}`
    );
    if (res) {
      dispatch(getInitialStatusSuccess(res.data));
    }
  } catch (err) {
    dispatch(getInitialStatusFailed(err.response));
    Swal.fire("Something went wrong!", `${err?.response?.data}`, "error");
  }
};