import React from "react";
import { makeStyles, Tooltip } from "@material-ui/core";

const useStyles = makeStyles({
  customTooltip: {
    backgroundColor: "#fefdfd",
    
    fontSize: "12px",
    width: "326px",
    padding: "8px 8px 8px 8px",
    borderRadius: "0px",
    color: "#242424",
    fontWeight: "normal",
    lineHeight: "1.67",
    position: "relative",
    right: "40px",
  },
  customArrow: {
    color: "#fefdfd",
  },
});

const CommonToolTip = ({ children, placement , title}) => {
  const classes = useStyles();
  return (
    <Tooltip
      classes={{
        tooltip: classes.customTooltip,
        arrow: classes.customArrow,
      }}
      title={title? title: "This feature is being upgraded and will be released shortly."}
      arrow
      placement={placement ? placement : "top"}
    >
      {children}
    </Tooltip>
  );
};

export default CommonToolTip;
