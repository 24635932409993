import { Tab, Tabs, Grid } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import "./sub.css";
import {
  getPolicyCategoryData,
  getPolicyFirstCategoryIdData,
} from "../../../../Redux/Actions/PolicyCategoryAction";
import services from "../../../../Services";
import CommonModal from "../../../Common/CommonModal/CommonModal";
import CategoryTabPanel from "./CategoryTabPanel";
import "./ManageCategories.css";
import NewCategoryTabPanel from "./NewCategoryTabPanel";
import Swal from "sweetalert2";
import editIcon from "../../../../Assets/Images/Policy/edit-line-fill.svg";

//manages the tabPanel
function TabPanel({ children, value, index }) {
  return <div>{value == index && <p>{children}</p>}</div>;
}

function ManageCategories({ open, handleClose }) {
  const dispatch = useDispatch();
  const [value, setvalue] = useState(0);
  const [refresh, setrefresh] = useState(false);
  const [getCatID, setgetCatID] = useState("");

  useEffect(() => {
    dispatch(getPolicyCategoryData());
  }, [refresh]);

  //get category data
  const policyData = useSelector((state) => state.getPolicyCategoryDataReducer);
  const { getData: categoryData } = policyData;

  const handleTabChange = (e, value) => {
    setvalue(value);
  };

  const handleAddCategoryClick = () => {
    setvalue(categoryData.length);
  };

  const handleEditApi = async (edit_data) => {
    try {
      const res = await services.api(
        "PUT",
        `/ui-assist/v1/policycategory`,
        edit_data
      );
      if (res) {
        // Swal.fire({
        //     position: "center",
        //     icon: "success",
        //     title: "category updated successfully",
        //     showConfirmButton: false,
        //     timer: 1500,
        // });
        setrefresh(!refresh);
        setstate("");
      }
    } catch (err) {
      if (err.response) {
        console.log("erro", err.response);
      }
    }
  };

  const handleSubmitButton = () => {
    let edit_data = {
      categoryId: getCatID,
      category: state,
      employeeId: localStorage.getItem("token"),
    };

    handleEditApi(edit_data);
  };

  const [state, setstate] = useState("");

  return (
    <>
      <CommonModal
        open={open}
        headerText="Manage category"
        modalSize="lg"
        submitBtnText="SAVE CHANGES"
        cancleBtnText="BACK"
        handleClose={handleClose}
        onButtonClick={handleSubmitButton}
        onCancleButtonClick={handleClose}
        className="adminpModal"
        icon={editIcon}
        disabledSubmitButton={!Boolean(state)}
      >
        <div className="policiesCssDiv">
          <div className="policiesCreateModal">
            <Grid
              container
              direction="row"
              justifyContent="space-between"
              alignItems="center"
            >
              <Tabs value={value} onChange={handleTabChange}>
                {categoryData &&
                  categoryData.map((el, index) => (
                    <Tab key={index} index={value} label={el.category} />
                  ))}
                <Tab index={value} label="new category" />
              </Tabs>
              <div className="editPoliciesStructure">
                <img
                  src={process.env.PUBLIC_URL + "/policies/add-fill.svg"}
                  alt="Add"
                />
                <span className="editPolicies" onClick={handleAddCategoryClick}>
                  Add category
                </span>
              </div>
            </Grid>
          </div>
          {categoryData &&
            categoryData.map((el, index) => (
              <TabPanel key={index} value={value} index={index}>
                <CategoryTabPanel
                  element={el}
                  handleSubmitButton={handleSubmitButton}
                  el
                  setstate={setstate}
                  setgetCatID={setgetCatID}
                  setrefresh={setrefresh}
                  refresh={refresh}
                />
              </TabPanel>
            ))}
          <TabPanel value={value} index={categoryData.length}>
            <NewCategoryTabPanel refresh={refresh} setrefresh={setrefresh} />
          </TabPanel>
        </div>
      </CommonModal>
    </>
  );
}

export default ManageCategories;
