import React, { useState, useEffect } from "react";
import "./ITSupportCategoryAdmin.css";
import moment from "moment";
import SubmitButton from "../../../../Common/Button/SubmitButton";
import editIconRed from "../../../../../Assets/Images/edit-line-red.svg";
import delIcon from "../../../../../Assets/Images/delete-bin-6-line-grey.svg";
import CommonModal from "../../../../Common/CommonModal/CommonModal";

import { Grid, Tooltip, makeStyles } from "@material-ui/core";
import CharLeftField from "../../../../Common/CharLeftField/CharLeftField";
import CommonLabel from "../../../../Common/ComonLabel/CommonLabel";
import services from "../../../../../Services";
import Loader from "../../../../Common/Loader/Loader";
import Swal from "sweetalert2";
import { getLastModifiedData } from "../../../../../Redux/Actions/CommonActions";
import { useSelector, useDispatch } from "react-redux";
import { getFormattedDate } from "../../../../../Assets/helperFunctions/helperFunctions";

const useStyles = makeStyles({
  customTooltip: {
    backgroundColor: "#fefdfd",
    
    fontSize: "12px",
    width: "326px",
    padding: "8px 8px 8px 8px",
    borderRadius: "0px",
    color: "#242424",
    fontWeight: "normal",
    lineHeight: "1.67",
  },
  customArrow: {
    color: "#fefdfd",
  },
});
const activeArrowStyle = {
  color: "#e31837",
  backgroundColor: "rgba(219, 35, 58, 0.05)",
};

export default function ITSupportCategoryAdmin({ setrefresh, refresh }) {
  const [modifiedDate, setModifiedDate] = useState(new Date());
  const [open, setOpen] = useState(false);
  const [edit, setEdit] = useState(false);
  const [category, setCategory] = useState("");
  const [categoryList, setCategoryList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [editItemId, setEditItemId] = useState("");
  const classes = useStyles();

  let dispatch = useDispatch();

  const handleAdd = () => {
    setOpen(true);
    setEdit(false);
    setCategory("");
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleEditCategory = (category, id) => {
    setOpen(true);
    setEdit(true);
    setEditItemId(id);
    setCategory(category);
  };

  const handleChange = (e) => {
    const { value } = e.target;
    setCategory(value);
  };

  const handleSubmit = async (edit, id) => {
    let data = edit
      ? {
          name: category,
          id: id,
        }
      : {
          name: category,
        };
    try {
      setLoading(true);
      let res = await services.api(
        `${edit ? "PUT" : "POST"}`,
        `/ui-assist/v1/category`,
        data
      );
      if (res.status === 200) {
        let sucessMsg = edit
          ? "Category updated successfully"
          : "Category added successfully";
        Swal.fire("Successfully added!", `${sucessMsg}`, "success");
        getCategoryList();
        setLoading(false);
        setrefresh(!refresh);
        handleClose();
      }
    } catch (err) {
      setLoading(false);
      console.log("error from add category", err);
    }
  };

  const getCategoryList = async () => {
    try {
      setLoading(true);
      let res = await services.api("GET", `/ui-assist/v1/categorys`);
      if (res.status === 200) {
        setLoading(false);
        let list = res.data.map((result) => {
          return { title: result.name, value: result.name, id: result.id };
        });
        setCategoryList(list);
        handleClose();
      }
    } catch (err) {
      setLoading(false);
      console.log("get error from category:", err);
    }
  };

  const handleDeleteCategory = async (id) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You want to delete this category?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Yes, delete it!",
      cancelButtonText: "No, keep it",
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          setLoading(true);
          let res = await services.api(
            "DELETE",
            `/ui-assist/v1/category/${id}`
          );
          if (res.status === 200) {
            Swal.fire(
              "Successfully deleted!",
              `Category deleted successfully`,
              "success"
            );
            getCategoryList();
            setLoading(false);
            handleClose();
          }
        } catch (err) {
          setLoading(false);
          console.log("get error from category:", err);
        }
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire("Cancelled", "Your category is safe :)", "error");
      }
    });
  };

  const lastModified = useSelector((state) => {
    return state?.getLastModifiedDataReducer?.lastModifiedSupportCategory;
  });

  useEffect(() => {
    getCategoryList();
    dispatch(getLastModifiedData("supportservice"));
  }, []);

  return (
    <>
      <div className="lastmodifiedpanel">
        <Grid container spacing={2}>
          <Grid item sm={2} xs={12}>
            <div className="displayBlockUserDetails mlP alignMCAdmin">
              <p className="detailDivp">Last modified by</p>
              <p className="detailsFontSize">
                {lastModified?.name ? lastModified?.name : "Not Available"}
              </p>
            </div>
          </Grid>
          <Grid item sm={2} xs={12}>
            <div className="displayBlockUserDetails alignMCAdmin">
              <p className="detailDivp">Modified on</p>
              <p className="detailsFontSize">
                {lastModified?.date
                  ? getFormattedDate(lastModified?.date)
                  : "Not Available"}
              </p>
            </div>
          </Grid>
          <Grid item sm={8} xs={12}>
            <div className="uploadBtn">
              <SubmitButton
                onClick={handleAdd}
                label="Add new CATEGORY"
                className="MCsubmitbutton floatRightAlign"
              />
            </div>
          </Grid>
        </Grid>
      </div>
      <div className="Modalcontent">
        {loading ? <Loader /> : null}

        {categoryList.length > 0 &&
          categoryList.map((item, index) => (
            <>
              <Grid container className="secrotCardAdmin">
                <Grid item xs={12} sm={5} className="catCardTitle">
                  {item.title}
                </Grid>
                <Grid item xs={12} sm={3}>
                  <span className="serviceIncluded">
                    <span className="noOfServiceCat">
                      {/* {item.sectorDetail.length}  */}
                    </span>
                    service included
                  </span>
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={2}
                  onClick={() => handleEditCategory(item.title, item.id)}
                >
                  <div className="catActionBtnContainer">
                    <img src={editIconRed} alt="" className="edit-line" />
                    <div className="catActionBtn">EDIT CATEGORY NAME</div>
                  </div>
                </Grid>
                <Tooltip
                  classes={{
                    tooltip: classes.customTooltip,
                    arrow: classes.customArrow,
                  }}
                  title="Category can be deleted when all sub-categories and policies in it are deleted"
                  arrow
                  placement="bottom"
                >
                  <Grid
                    item
                    xs={12}
                    sm={2}
                    onClick={() => handleDeleteCategory(item.id)}
                  >
                    <div className="catActionBtnContainer">
                      <img src={delIcon} alt="" className="edit-line" />
                      <div className="catActionBtn delBtn">DELETE CATEGORY</div>
                    </div>
                  </Grid>
                </Tooltip>
              </Grid>
            </>
          ))}

        <CommonModal
          open={open}
          handleClose={handleClose}
          headerText={edit ? "Edit category" : "Add category"}
          // icon={editIcon}
          modalSize="lg"
          fullWidth
          submitBtnText={edit ? "Edit Category" : "Add"}
          // onButtonClick={() => handleSubmit(edit, item.id)}
          onButtonClick={() => handleSubmit(edit, editItemId)}
          disabledSubmitButton={!category ? true : false}
        >
          <Grid container alignItems="center">
            <Grid item md={12} xs={12} className="contentPadding">
              <span className="Complete-Tax-Declara">Category</span>
            </Grid>
            <Grid item md={6} xs={12} className="contentPadding">
              <CharLeftField
                label={
                  <CommonLabel
                    label={edit ? "Edit category" : "Add category"}
                    mandatory
                  />
                }
                name="category"
                type="text"
                value={category}
                handleChange={handleChange}
              />
            </Grid>
          </Grid>
        </CommonModal>
      </div>
    </>
  );
}
