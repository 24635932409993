import React from "react";
import { Grid } from "@material-ui/core";
import SubmitButton from "../../Common/Button/SubmitButton";
import { useHistory } from "react-router-dom";

const ExperienceFilters = ({path}) => {
  //Required
  const history = useHistory();

  return (
    <div className="liveWireDropDownContainer">
      <Grid container direction="row">
        <Grid
          item
          xs={12}
          sm={12}
          className="zeroPad posRight isMobileHideButton d-flex justify-content-end"
        >
          <SubmitButton
            // disabled={!isAdmin()}
            label="Upload a story"
            className="MCsubmitbutton floatRightAlign"
            onClick={() => history.push(path)}
          />
        </Grid>
      </Grid>
    </div>
  );
};

export default ExperienceFilters;
