import React, { useEffect, useState } from "react";
import "./WhatsNewAdminComponent.css";

import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import DatePicker from "../../../Common/DatePicker/DatePicker";
import CommonLabel from "../../../Common/ComonLabel/CommonLabel";
import { Grid } from "@material-ui/core";
import TextField from "@material-ui/core/TextField";
import SubmitButton from "../../../Common/Button/SubmitButton";

import moment from "moment";

import { useHistory } from "react-router-dom";
import { LIVEWIRE, UPLOAD_A_STORY } from "./../../../../Routes/constant";

import addBoxLine from "./../../../../Assets/Images/WhatsNewIcons/add-box-line.svg";
import CommonAutocomplete from "../../../Common/CommonAutocomplete/CommonAutocomplete";
import SearchBarButton from "../../../Common/SearchBar/SearchBarButton";
import { useDispatch, useSelector } from "react-redux";
import {
  removeWhatsNewItem,
  editWhatsNewItem,
} from "../../../../Redux/Actions/WhatsNewAdminAction";
import {
  getWhatsNewData,
  getWhatsNewSectors,
  getWhatsNewBySector,
} from "../../../../Redux/Actions/WhatsAction";
import { getLastModifiedData } from "../../../../Redux/Actions/CommonActions";
import { getFormattedDate } from "../../../../Assets/helperFunctions/helperFunctions";
import CreatePolicy from "../../../Pages/Policies/AdminPanel/CreatePolicy";
import WhatsnewDeleteModal from "./WhatsnewDeleteModal";

export default function WhatsNewAdminComponent(list) {
  const style = {
    width: 300,
    marginBottom: "1.5em",
  };

  const sectionOption = [
    {
      title: "Title 1",
      value: "Title 1",
    },
    {
      title: "Title 2",
      value: "Title 2",
    },
    {
      title: "Others",
      value: "Others",
    },
  ];

  const posOpt = [
    {
      title: "1",
      value: "1",
    },
    {
      title: "2",
      value: "2",
    },
    {
      title: "3",
      value: "3",
    },
    {
      title: "4",
      value: "4",
    },
    {
      title: "5",
      value: "5",
    },
    {
      title: "6",
      value: "6",
    },
    {
      title: "7",
      value: "7",
    },
    {
      title: "8",
      value: "8",
    },
    {
      title: "9",
      value: "9",
    },
    {
      title: "10",
      value: "10",
    },
  ];

  // const whatsNewdata = list.list.list;
  // const history = useHistory();
  const dispatch = useDispatch();
  // const whatsNewDetails = useSelector((state) => state.getWhatsNewDataReducer);
  // const { getData: whatsNewObj } = whatsNewDetails;

  // const [getWhatsNewObj, setGetWhatsNewObj] = useState(whatsNewObj);

  // const getWhatsNewObj = list.data;
  const [selectedDate, setSelectedDate] = useState({
    expiryDate: null,
  });
  const [openDeleteWhatsnew, setOpenDeleteWhatsnew] = useState(false);
  const [modifiedDate, setModifiedDate] = useState(new Date());

  const [selectedPosition, setSelectedPosition] = useState();
  const [selectedItemForDelete, setSelectedItemForDelete] = useState();
  const [selectedKeys, setSelectedKeys] = useState([]);
  const [positionOptions, setPositionOptions] = useState(posOpt);
  const whatsNewDetails = useSelector((state) => state.getWhatsNewDataReducer);

  const removeData = useSelector((state) => state.removeWhatsNewItemReducer);
  const editData = useSelector((state) => state.editWhatsNewItemReducer);
  const { getData: whatsNewObj } = whatsNewDetails;
  const [getWhatsNewObj, setGetWhatsNewObj] = useState(whatsNewObj);
  const [sectorsList, setSectorsList] = useState([]);
  const [selectedSector, setSelectedSector] = useState();
  console.log(whatsNewDetails);

  //for getting Last modified by
  const lastModified = useSelector((state) => {
    return state?.getLastModifiedDataReducer?.lastModifiedWhatsNew;
  });

  const setPositions = () => {
    if (posOpt.length > getWhatsNewObj.length) {
      let reqOptions = posOpt.slice(0, getWhatsNewObj.length);
      setPositionOptions(reqOptions);
    }
  };

  //for last modified data
  useEffect(() => {
    dispatch(getLastModifiedData("whatsnew"));
  }, []);

  useEffect(() => {
    dispatch(getWhatsNewData());
    dispatch(getWhatsNewSectors());
    setPositions();
  }, []);

  useEffect(() => {
    let data = [];
    whatsNewDetails?.sectors?.map((val) => {
      const a = { title: val?.sectorName, value: val?.sectorCode };
      data = [...data, a];
    });
    setSectorsList([...data]);
    setSelectedSector(data[0]);
  }, [whatsNewDetails?.sectors]);

  useEffect(() => {
    dispatch(getWhatsNewBySector(selectedSector?.value));
  }, [selectedSector, removeData?.getData, editData?.getData]);

  // useEffect(() => {
  //   dispatch(getWhatsNewData());
  // }, []);

  useEffect(() => {
    setGetWhatsNewObj(whatsNewObj);
  }, [whatsNewObj, whatsNewDetails, getWhatsNewObj]);

  const handleDateChange = (name, date) => {
    // console.log(date, "date Data");
    if (name === "expiryDate") {
      setSelectedDate({ ...selectedDate, expiryDate: date });
    }
  };

  const setDetails = (item, index) => {
    setSelectedPosition({ title: index + 1, value: index + 1 });
    setSelectedDate({ ...selectedDate, expiryDate: item.expiry });
    setSelectedKeys(item.contents.tags);
    setSelectedItemForDelete(item);
  };

  const handleRemoveItem = (id) => {
    let data = dispatch(removeWhatsNewItem(id, selectedSector?.value));
    // dispatch(getWhatsNewData());
    setGetWhatsNewObj(data);
  };
  const handleSector = (event, selectedValue) =>
    setSelectedSector(selectedValue);

  const [expanded, setExpanded] = useState(false);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  const handleSelectChange = (event, selectedValue) => {
    setSelectedPosition(selectedValue);
  };

  const handleEditItem = (id, index) => {
    const bodyData = [
      {
        contentId: id,

        expiry: selectedDate.expiryDate,

        position: selectedPosition.value,
        scope: selectedSector?.value,
      },
    ];

    handleChange(`panel${index}`);

    dispatch(editWhatsNewItem(bodyData));

    //temporarily

    setTimeout(() => {
      dispatch(getWhatsNewData());

      // console.log("updated whats new data");

      // setGetWhatsNewObj(whatsNewObj);
    }, 1000);

    //dispatch(getWhatsNewData());

    //setGetWhatsNewObj(whatsNewObj);

    /*setSelectedDate({

      expiryDate: null,

    });*/
  };
  console.log("whats new data by sector ===>", whatsNewDetails?.whatsNewData);
  return (
    <>
      <div className="whatsNewAdminContainer">
        <div className="lastmodifiedpanel">
          <Grid container spacing={2}>
            <Grid item sm={2} xs={12}>
              <div className="displayBlockUserDetails mlP alignMCAdmin">
                <p className="detailDivp">Last modified by</p>
                <p className="detailsFontSize">
                  {lastModified?.name ? lastModified?.name : "Not Available"}
                </p>
              </div>
            </Grid>
            <Grid item sm={2} xs={12}>
              <div className="displayBlockUserDetails alignMCAdmin">
                <p className="detailDivp">Modified on</p>
                <p className="detailsFontSize">
                  {lastModified?.date
                    ? getFormattedDate(lastModified?.date)
                    : "Not Available"}
                </p>
              </div>
            </Grid>
            <Grid item xs={4} sm={2} className="wallpaperDropdown">
              <CommonAutocomplete
                handleSelectChange={handleSector}
                selectedValue={{
                  title: selectedSector?.title,
                  value: selectedSector?.value,
                }}
                name="sector"
                options={sectorsList}
                label={<CommonLabel label="Sectors" />}
              />
            </Grid>
            {/* <Grid item sm={8} xs={12}>
                <div className="uploadBtn">
                  <SubmitButton
                    label="Upload an article"
                    className="MCsubmitbutton floatRightAlign"

                  />
                </div>
              </Grid> */}
          </Grid>
        </div>

        <div className="Modalcontent hidden">
          <Grid container alignItems="flex-end">
            {/* <Grid item md={3} xs={12} className="paddingRight40">
              <CommonAutocomplete
                className="width100"
                // handleSelectChange={handleSelectChange}
                // selectedValue={selectedValue}
                options={sectionOption}
                style={style}
                label={<CommonLabel label="Section" mandatory />}
              />
            </Grid> */}
            {/* <Grid item md={4} xs={12} className="paddingRight40">
              <SearchBarButton placeholder="Search article"></SearchBarButton>
            </Grid> */}
            {/* <Grid item md={2} xs={12} className="paddingRight40">
              <div className="actionTxt mb15">add article</div>
            </Grid> */}
          </Grid>
        </div>
        <div className="Modalcontent">
          <div className="setscroll">
            {whatsNewDetails?.whatsNewData?.whatsNewLists?.length > 0 &&
              whatsNewDetails?.whatsNewData?.whatsNewLists?.map(
                (item, index) => (
                  <>
                    <Accordion
                      expanded={expanded === `panel${index}`}
                      onChange={handleChange(`panel${index}`)}
                      key={index}
                      className="accordionContainer"
                    >
                      <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                        onClick={() => {
                          setDetails(item, index);
                        }}
                      >
                        <div className="mainAccContainer">
                          <div className={"accordionStyle"}>
                            {item.contents?.title}
                          </div>
                          {item.expiry !== null && (
                            <div className={"lableClass"}>
                              Expires on{" "}
                              {moment(item.expiry).format("MMM Do YY")}
                            </div>
                          )}
                        </div>
                      </AccordionSummary>
                      <AccordionDetails>
                        <Grid container alignItems="center">
                          <Grid
                            item
                            md={2}
                            xs={12}
                            className="paddingRight40 alignCommonLabelWhatsNewAdmin"
                          >
                            <CommonAutocomplete
                              className="width100"
                              handleSelectChange={handleSelectChange}
                              defaultValue={{
                                title: "" + (index + 1) + "",
                                value: "" + (index + 1) + "",
                              }}
                              options={positionOptions}
                              style={style}
                              label={<CommonLabel label="Position" />}
                            />
                          </Grid>
                          <Grid
                            item
                            md={3}
                            xs={12}
                            className="paddingRight40 alignCommonLabelWhatsNewAdmin"
                          >
                            <DatePicker
                              label={<CommonLabel label="Expiry date" />}
                              selectedDate={selectedDate.expiryDate}
                              handleDateChange={(date) =>
                                handleDateChange("expiryDate", date)
                              }
                              name="expiryDate"
                            />
                          </Grid>
                          <Grid item md={3} xs={12} className="paddingRight40">
                            <form noValidate autoComplete="off">
                              <TextField
                                id="outlined-basic"
                                label="Tag"
                                variant="outlined"
                                value={item.contents?.tags}
                                className="fullWidth"
                                inputProps={{ readOnly: true }}
                              />
                            </form>
                          </Grid>
                          <Grid item md={2} xs={12} className="paddingRight40">
                            <div
                              className="actionTxt savebtn"
                              onClick={() => {
                                handleEditItem(item.contents?._id, index);
                                setExpanded(!expanded);
                              }}
                            >
                              SAVE CHANGES
                            </div>
                          </Grid>
                          <Grid item md={1} xs={12} className="paddingRight40">
                            <div
                              className="actionTxt"
                              onClick={() => {
                                // handleRemoveItem(item.contents._id);
                                // setExpanded(!expanded);
                                setOpenDeleteWhatsnew(true);
                              }}
                            >
                              REMOVE
                            </div>
                          </Grid>
                        </Grid>
                      </AccordionDetails>
                    </Accordion>
                    {openDeleteWhatsnew && (
                      <WhatsnewDeleteModal
                        x={openDeleteWhatsnew}
                        y={setOpenDeleteWhatsnew}
                        item={selectedItemForDelete}
                        sectors={sectorsList}
                      />
                    )}
                  </>
                )
              )}
          </div>
        </div>
      </div>
    </>
  );
}
