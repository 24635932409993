import services from "../../Services/index";
import {
  GET_ALL_ADDFAV_DATA_SUCCESS,
  GET_ALL_ADDFAV_DATA_LOAD,
  GET_ALL_ADDFAV_DATA_FAILED,
} from "../Contants/Constants";
const getAddFavDataLoad = () => ({
  type: GET_ALL_ADDFAV_DATA_LOAD,
});
const getAddFavDataSuccess = (data) => ({
  type: GET_ALL_ADDFAV_DATA_SUCCESS,
  payload: data,
});
const getAddFavDataFailed = (error) => ({
  type: GET_ALL_ADDFAV_DATA_FAILED,
  err: error,
});
export const getAddFavData = () => async (dispatch) => {
  dispatch(getAddFavDataLoad());
  try {
    const res = await services.api(
      "GET",
      `/ui-assist/v1/tiles/categorywise/${localStorage.getItem("token")}`,
      {}
    );
    if (res) {
      dispatch(getAddFavDataSuccess(res.data));
    }
  } catch (err) {
    dispatch(getAddFavDataFailed(err.response));
  }
};
