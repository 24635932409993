import React, { useEffect, useState } from "react";
import { Grid } from "@material-ui/core";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import CardMedia from "@material-ui/core/CardMedia";
import CardActionArea from "@material-ui/core/CardActionArea";
import background_image from "../../Assets/Images/sectionBackground.png";
import { useLocation } from "react-router-dom";
// import { useDispatch } from "react-redux";
// import { getProfileDetailsData } from "../../Redux/Actions/ProfileDetailsAction";
import "./ProfilePage.css";

export default function ProfilePage() {
  const location = useLocation();
  const [profileData, setProfileData] = useState({});
  // const dispatch = useDispatch();
  // const getProfile = useSelector((state) =>
  //   console.log("===========> from profile page", state.getProfileDetailsData)
  // );

  useEffect(() => {
    setProfileData(location?.state?.list);
    // dispatch(getProfileDetailsData());
  }, []);

  const {
    firstname,
    lastname,
    employeegrade,
    empPhotoUrl,
    orgUnit,
    orgUnitName,
    emailid,
    employeeid,
  } = profileData;
  return (
    <div
      className="bC"
      style={{
        backgroundImage: `url(${background_image})`,
        backgroundSize: "cover",
      }}
    >
      {profileData && (
        <div className="fontFamilySaira ConatinerMBMT">
          <Grid
            container
            direction="row"
            justifyContent="space-evenly"
            alignItems="center"
            className="dFlex"
            spacing={2}
          >
            <Grid item xs={12} className="profileTitle">
              <div className="div1ProfileTitle">Profile</div>
            </Grid>
            <Grid item xs={12} md={2} className="cardProfile">
              <CardActionArea className="cardFlex">
                <Card className="card" variant="outlined">
                  <CardMedia
                    className="imgDimension"
                    // image={process.env.PUBLIC_URL + "/header/profileBig.png"}
                    image={empPhotoUrl}
                    title="profile"
                  />
                  <div className="cardDetails">
                    <CardContent className="displayBlockUserDetails bColor fontFamilySaira cardDetailsHeight">
                      <p className="pMarginLeft1 div1p1">{`${firstname} ${lastname}`}</p>
                      <p className="pMarginLeft2 div1p2">GCO</p>
                    </CardContent>
                  </div>
                </Card>
              </CardActionArea>
            </Grid>
            <Grid item xs={12} md={10} className="detailsDiv">
              <div className="UserDetails bColor">
                <div className="displayBlockUserDetails mlP">
                  <p className="detailDivp">Grade</p>
                  <p className="detailsFontSize">{employeegrade}</p>
                </div>
                <div className="displayBlockUserDetails mlP">
                  <p className="detailDivp">Email</p>
                  <p className="detailsFontSize">{emailid ? emailid : "NA"}</p>
                </div>
                <div className="displayBlockUserDetails mlP">
                  <p className="detailDivp">Emp ID</p>
                  <p className="detailsFontSize">
                    {employeeid ? employeeid : "NA"}
                  </p>
                </div>
                <div className="displayBlockUserDetails mlP">
                  <p className="detailDivp">Phone no</p>
                  <p className="detailsFontSize">9912345678</p>
                </div>
              </div>
              <div className="orgTitle bColor">
                <p className="orgTtileP">Org Details</p>
              </div>
              <div className="orgDetails bColor">
                <div className="displayBlockUserDetails mlP">
                  <p className="detailDivp">Unit</p>
                  <p className="detailsFontSize">{orgUnit ? orgUnit : "NA"}</p>
                </div>
                <div className="displayBlockUserDetails mlP">
                  <p className="detailDivp">Sub-unit</p>
                  <p className="detailsFontSize">
                    {orgUnitName ? orgUnitName : "NA"}
                  </p>
                </div>
                <div className="displayBlockUserDetails mlP">
                  <p className="detailDivp">Project code</p>
                  <p className="detailsFontSize">12345</p>
                </div>
                <div className="displayBlockUserDetails mlP">
                  <p className="detailDivp">Project Code</p>
                  <p className="detailsFontSize">Ravi Shanker</p>
                </div>
              </div>
            </Grid>
          </Grid>
        </div>
      )}
    </div>
  );
}
