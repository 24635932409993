import React, { useEffect, useState } from "react";
import "./WhatsNewComponent.css";
import PlayButton from "./../../../Assets/Images/play_button.svg";
import liveIcon from "./../../../Assets/Images/live_Icon.svg";
import personImg from "./../../../Assets/Images/AnishShah.png";

//redux
import { useSelector, useDispatch } from "react-redux";
import { getWhatsNewData } from "../../../Redux/Actions/WhatsAction";

// Arrow dropdown Icons
import DownArrow from "./../../../Assets/Images/WhatsNewIcons/arrow-drop-down-line.svg";
import UpArrow from "./../../../Assets/Images/WhatsNewIcons/arrow-drop-up-line.svg";
import RightArrow from "./../../../Assets/Images/WhatsNewIcons/arrow-drop-right-fill.svg";

import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import Grid from "@material-ui/core/Grid";
import Card from "@material-ui/core/Card";
import CardActionArea from "@material-ui/core/CardActionArea";

import { Scrollbars } from "react-custom-scrollbars";
import ReactPlayer from "react-player/lazy";

import CommonModal from "../../Common/CommonModal/CommonModal";
import PdfViewerComponent from "./PdfViewer/PdfViewerComponent";
import { Icon } from "@material-ui/core";
import PictureAsPdfOutlinedIcon from "@material-ui/icons/PictureAsPdfOutlined";

import { useHistory } from "react-router-dom";

// import { LIVEWIRE, LIVE_WIRE_ARTICLE2 } from "../../../Routes/constant";

import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import {
  LIVEWIRE,
  LIVE_WIRE_ARTICLE2,
  LIVE_WIRE_ARTICLE3,
} from "../../../Routes/constant";
import Loader from "../../Common/Loader/Loader";
import { AspectRatio } from "react-aspect-ratio";

const theme = createTheme({
  overrides: {
    // Style sheet name
    MuiTouchRipple: {
      // Name of the rule
      child: {
        // Some CSS
        backgroundColor: "#ffffff",
        transform: "ease-in-out",
        opacity: "0.5",
      },
    },
  },
});

export default function WhatsNewComponent(list) {
  const dispatch = useDispatch();
  const history = useHistory();

  /** limiting the list element to show only top 8 element form the actual list */
  const whatsNewListArr = list.list.list.slice(0, 8);

  const [selectedIndex, setSelectedIndex] = React.useState(0);

  // let pdfLink = "/Sample.pdf";
  const [open, setOpen] = React.useState(false);
  const [typeOfContent, setTypeOfContent] = React.useState("");

  const handleListItemClick = (event, item, index) => {
    setSelectedIndex(index);
    setselectedItemObj(item.contents);
  };

  const whatsNewDetails = useSelector((state) => state.getWhatsNewDataReducer);
  const { getData: whatsNewObj, isLoading } = whatsNewDetails;

  const initialImage = whatsNewObj[0]?.contents?.thumbnail;
  const [getWhatsNewObj, setGetWhatsNewObj] = useState(whatsNewObj);
  const [selectedItemObj, setselectedItemObj] = React.useState({
    img: `${initialImage}`,
  });

  const { whatsNewLists: whatsNewData } = whatsNewObj;
  let size = 8;
  let x = whatsNewData?.slice(0, size);

  const handleClickOpen = (type) => {
    setTypeOfContent(type);
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    dispatch(getWhatsNewData());
  }, []);

  useEffect(() => {
    setGetWhatsNewObj(whatsNewObj);
    setselectedItemObj(whatsNewObj?.[0]?.contents || { thumbnail: "" });
  }, [whatsNewDetails, whatsNewObj]);

  const renderWhatsNewType = (selectedItemObj) => {
    if (selectedItemObj.mediaType === "document" || selectedItemObj.videoUri) {
      return (
        <>
          {selectedItemObj.mediaType === "document" && (
            <div className="">
              <Icon
                className="pdfStyle pointer"
                title="show PDF"
                onClick={() => handleClickOpen("pdf")}
              >
                <PictureAsPdfOutlinedIcon></PictureAsPdfOutlinedIcon>
              </Icon>
            </div>
          )}
          {selectedItemObj.videoUri && (
            <div className="mCampaign posAbsolute">
              <img
                className="centerAlign posAbsolute pointer playButton"
                src={PlayButton}
                alt="no group"
                onClick={(event) => handleClickOpen("video")}
              />
            </div>
          )}
        </>
      );
    }
    if (selectedItemObj.campaign) {
      return (
        <div className="mCampaign posAbsolute">
          <div className="campaignWrapper">
            <div className="mySevaLogoContainer">
              <img
                src="/Images/mySeva_Logo/MySeva Logo 2.jpg"
                className="mySevaLogo"
                // srcset="/Images/mySeva_Logo/MySeva Logo 2@2x.jpg 2x,/Images/mySeva_Logo/MySeva Logo 2@3x.jpg 3x"
                alt="My Seva LOGO"
              />
            </div>
            <div className="campaignDetail">
              <div className="campaignHeader">
                SIGN UP TO VOLUNTEER YOUR TIME TODAY.
              </div>
              <div className="campaignLink">
                To know more, visit{" "}
                <a
                  href="http://spiritofservice.mahindra.com"
                  rel="noreferrer"
                  target="_blank"
                >
                  spiritofservice.mahindra.com
                </a>
              </div>
            </div>
          </div>
        </div>
      );
    }

    // if (selectedItemObj.videoUri) {
    //   return (
    //     <div className="mCampaign posAbsolute">
    //       <img
    //         className="centerAlign posAbsolute pointer playButton"
    //         src={PlayButton}
    //         alt="no group"
    //         onClick={(event) => handleClickOpen("video")}
    //       />
    //     </div>
    //   );
    // }
  };

  const videoPlayer = (link) => {
    return (
      <ReactPlayer
        url={link}
        controls
        width="100%"
        height="100%"
        className="playerStyle"
        playing
        onError={(err) => console.log("Error", err)}
      />
    );
  };

  const openPDF = (docfLink) => {
    return <PdfViewerComponent link={docfLink}></PdfViewerComponent>;
  };

  const openCommonModel = (data) => {
    return (
      <CommonModal
        open={open}
        handleClose={handleClose}
        header={false}
        footer={false}
        modalSize="sm"
        addClassForVideo={true}
        contentType={typeOfContent}
        submitBtnText="DONE"
      >
        <Card className="videoPlayer">
          <CardActionArea className="videoPlayerCardArea">
            {typeOfContent === "pdf"
              ? openPDF(data.media)
              : videoPlayer(data.videoUri)}
          </CardActionArea>
        </Card>
      </CommonModal>
    );
  };

  // const openPDF = () => {
  //   return <PdfViewerComponent></PdfViewerComponent>;
  // };

  const goToArticlePage = (item, index) => {
    const nextID = item.contents._id;
    if (item.type === "mahindraCollective") {
      // history.push({
      //   pathname: `${LIVEWIRE}${LIVE_WIRE_ARTICLE2}`,
      //   state: { list: item },
      // });
      history.push({
        pathname: `${LIVEWIRE}${LIVE_WIRE_ARTICLE2}/?id=${nextID}`,
      });
    } else if (item.type === "offerorcampaign") {
      history.push({
        pathname: `${LIVEWIRE}${LIVE_WIRE_ARTICLE3}/?id=${nextID}`,
      });
    }
  };

  return (
    <>
      <div className="whats-new">
        {isLoading ? <Loader /> : null}
        <Grid
          container
          className="posRelative"
          direction="row"
          justifyContent="flex-end"
        >
          <Grid className="listContainer" item xs={12}>
            <Scrollbars className="custome-scrollBar">
              <div className="listNav">
                <ThemeProvider theme={theme}>
                  <List component="nav" aria-label="Waht's new list">
                    {getWhatsNewObj.length > 0 &&
                      getWhatsNewObj.map((item, index) => (
                        <Grid container key={index}>
                          <Grid item xs={12}>
                            <ListItem
                              className="list-item"
                              key={index}
                              button
                              selected={selectedIndex === index}
                              onClick={(event) =>
                                handleListItemClick(event, item, index)
                              }
                            >
                              {/* <ListItemText className="list-item-text" primary={result.value} /> */}
                              <Grid container>
                                <Grid className="listArea" item xs={12}>
                                  <div className="list-tag">
                                    <div className="red-parallelogram"></div>

                                    <div className="list-tag-text">
                                      {item?.contents?.tags?.length > 0
                                        ? item.contents.tags[0].toUpperCase()
                                        : ""}
                                    </div>
                                  </div>
                                  <div className="listDescription">
                                    <div className="list-description-text">
                                      {item?.contents?.title}
                                    </div>

                                    <div
                                      className="arrow"
                                      onClick={() =>
                                        goToArticlePage(item, index)
                                      }
                                    >
                                      READ MORE
                                      {/* <img
                                    src={
                                      process.env.PUBLIC_URL + "/logo/arrow.svg"
                                    }
                                    alt=""
                                  /> */}
                                      {/* <ArrowForwardIcon /> */}
                                    </div>
                                    <div className="expandIcon">
                                      {selectedItemObj.img === item.img &&
                                      selectedIndex === index ? (
                                        <img src={UpArrow} alt="UP_Arrow" />
                                      ) : (
                                        <img src={DownArrow} alt="Down_Arrow" />
                                      )}
                                    </div>
                                  </div>
                                </Grid>
                                {/* <Grid className="arrowImg" item>
                            <img src={arrowImg} alt="" />
                          </Grid> */}
                                <Grid
                                  className="mobileViewContainer"
                                  item
                                  xs={12}
                                >
                                  {selectedItemObj.img === item.img &&
                                  selectedIndex === index ? (
                                    <>
                                      <div className="image-container">
                                        <AspectRatio
                                          ratio="3/4"
                                          style={{ maxWidth: "400px" }}
                                        >
                                          <img
                                            src={
                                              process.env.PUBLIC_URL +
                                              selectedItemObj.thumbnail
                                            }
                                            alt=""
                                          />
                                        </AspectRatio>
                                      </div>
                                      <>{renderWhatsNewType(selectedItemObj)}</>
                                      {/* TODO: handle layer */}
                                      <div className="learnMoreBtn">
                                        <div className="learnMore-txt pointer">
                                          Learn More
                                        </div>
                                        <img
                                          src={RightArrow}
                                          alt="Right_Arrow"
                                          className="pointer"
                                        />
                                      </div>
                                    </>
                                  ) : null}
                                </Grid>
                              </Grid>
                            </ListItem>
                            <div className="borderBottom"></div>
                          </Grid>
                          {/* {selectedItemObj === item.img ? (
                      <Grid className="desktopImgContainer" xs={12} sm={6}>
                        <div className="image-container">
                          <img
                            src={process.env.PUBLIC_URL + selectedItemObj}
                            alt=""
                          />
                        </div>
                      </Grid>
                    ) : null} */}
                        </Grid>
                      ))}
                  </List>
                </ThemeProvider>
              </div>
            </Scrollbars>
            {/* <div className="viewMoreContainer">
              <Button
                className="viewMoreBtn"
                variant="contained"
                onClick={() =>
                  history.push({
                    pathname: `${LIVEWIRE}${MAHINDRA_COLLECTIVE}`,
                    state: { list },
                  })
                }
              >
                VIEW MORE
              </Button>
            </div> */}
          </Grid>
          <Grid className="desktopImgContainer posRelative" item xs={12} sm={6}>
            <div className="image-container compactContainer whats-new-thumbail-container">
              <AspectRatio ratio="4/3" style={{ maxWidth: "600px" }}>
                <img
                  src={process.env.PUBLIC_URL + selectedItemObj.thumbnail}
                  alt="Whatsnew"
                  className="whats-new-thumbail"
                />
              </AspectRatio>
            </div>
            <>{renderWhatsNewType(selectedItemObj)}</>
          </Grid>
        </Grid>
        <div>
          <>{openCommonModel(selectedItemObj)}</>
        </div>
      </div>
    </>
  );
}
