import React, { useState, useEffect } from "react";
import "../../../MahindraCollective/UploadStory/UploadStoryComponent.css";
import { Grid } from "@material-ui/core";
import CharLeftField from "../../../../Common/CharLeftField/CharLeftField";
import CommonLabel from "../../../../Common/ComonLabel/CommonLabel";
import BenefitTitle from "./BenefitTitle";

const CreditCard = ({ data, setData }) => {
  const [limit, setLimit] = useState("");
  const [isAllowed, setIsAllowed] = useState(false);

  useEffect(() => {
    if (data) {
      data?.map((val) => {
        if (val?.entitlementType == "Credit Card") {
          setIsAllowed(true);
          setLimit(val?.values?.availedLimit);
        }
      });
    }
  }, [data]);

  useEffect(() => {
    const val = {
      benefitsId: "62720e2c6bc86099de7d7bb4",
      availedLimit: limit
    };
    const isValid = CheckValidation();
    const data = {
      value: val,
      isAllowed: isAllowed,
      isValid: isValid
    };
    setData(data);
  }, [limit, isAllowed]);
  
  useEffect(() => {
    if (!isAllowed) {
      setLimit("");
    }
  }, [isAllowed]);

  function CheckValidation() {
    if (!isAllowed) {
      return true;
    } else {
        if (limit == "") {
          return false;
        } else {
          return true;
        }
    }
  }

  const handleLimit = (e) => setLimit(e.target.value);

  return (
    <>
      <BenefitTitle title="Credit Card" val={isAllowed} setVal={setIsAllowed} />
      <div className="divContainerOfferCampaign mb-5">
        <Grid container spacing={2} alignItems="top">
          <Grid
            item
            sm={6}
            md={3}
            xs={12}
            className="inputWidthTitle alignInputTextTitle boxwithlimit"
          >
            <CharLeftField
              label={<CommonLabel label="Max Limit ( in Lac )" mandatory />}
              value={limit}
              name="limit"
              type="number"
              disabled={!isAllowed}
              handleChange={handleLimit}
            />
          </Grid>
        </Grid>
      </div>
    </>
  );
};;

export default CreditCard;
