import React, { useState, useEffect } from "react";
import "./LiveWireMcArticle2.css";
import "./commentCss.css";
import {
  Paper,
  Grid,
  Icon,
  Avatar,
  Card,
  CardActions,
  CardActionArea,
} from "@material-ui/core";
import Template from "../../Common/Template/Template";
// import { useMediaQuery } from "@material-ui/core";
import SubmitButton from "../../Common/Button/SubmitButton";
import shareIcon from "../../../Assets/Images/share_icon.svg";
import heartIcon from "../../../Assets/Images/heart_icon.svg";
import heartIconFill from "../../../Assets/Images/heartred-fill.svg";
import arrowIconUp from "../../../Assets/Images/arrow-up-down.svg";
import arrowIcon from "../../../Assets/Images/arrow_icon.svg";
import arrowLeftIcon from "../../../Assets/Images/arrow-left-line.svg";
import chatIcon from "../../../Assets/Images/chat_icon.svg";
import TextareaAutosize from "@material-ui/core/TextareaAutosize";
import wheelWhite from "../../../Assets/Images/WheelWhite.svg";
import deleteIcon from "../../../Assets/Images/delete.svg";

// import LiveWireArticleComment from "./LiveWireArticleComment";
import services from "../../../Services";
import CommonToolTip from "../../Common/CommonToolTip";
import ReactPlayer from "react-player/lazy";
import pdfIcon from "./../../../Assets/Images/pdf.png";
import mailBoxIcon from "../../../Assets/Images/collectiveIcons/mail-line.svg";
import teamsIcon from "../../../Assets/Images/collectiveIcons/teamsIcon.jpg";
import yammerIcon from "../../../Assets/Images/collectiveIcons/Yammer.jpg";
import PdfViewerComponent from "../../../Components/DashBoard/WhatsNew/PdfViewer/PdfViewerComponent";
import Sharefiles from "../../Common/Shareoption/Sharefiles";
import ZoomInIcon from "@material-ui/icons/ZoomIn";
import IconButton from "@material-ui/core/IconButton";
import NotFound from "../../NotFound/NotFound";
// import CommentForm from "./CommentForm";
import {
  getComments as getCommentsApi,
  createComment as createCommentApi,
  updateComment as updateCommentApi,
  deleteComment as deleteCommentApi,
} from "./api";

import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import {
  deleteArticle,
  emptyArticle,
} from "../../../Redux/Actions/CommonActions";
import { getCommentsData } from "../../../Redux/Actions/CommentsActions";

import { useLocation } from "react-router-dom";
import Swal from "sweetalert2";
import Loader from "../../Common/Loader/Loader";

import Comments from "../ArticleComment/Comments";
import CommonModal from "../../Common/CommonModal/CommonModal";
import { ClickAwayListener, Popper } from "@mui/material";
import { likeArticle } from "../../../Redux/Actions/CommonActions";

import { getArticle } from "../../../Redux/Actions/CommonActions";
import {
  LIVEWIRE,
  LIVE_WIRE_ARTICLE2,
  UPLOAD_A_STORY,
} from "../../../Routes/constant";
import { useHistory } from "react-router-dom";
import RemoteModal from "../Remote/RemoteVideoModal";
import {
  getFormattedDate,
  isAccesible,
} from "../../../Assets/helperFunctions/helperFunctions";
import isAdmin from "../../../Components/isAdmin";
import AuthorDetails from "./AuthorSection/AuthorDetails";
import { addAnalayticsLog } from "../../../Redux/Actions/Anaylatics";
import platform from "platform";

export default function LiveWireMcArticle2({
  currentUserId = localStorage.getItem("token"),
}) {
  //fetch id from the url....
  const queryParams = new URLSearchParams(window.location.search);
  const id = queryParams.get("id");

  const pathURL = window.location.pathname;
  const contentCategory = window.location.pathname.split("/")[4];
  // console.log(pathURL.split('/')[4], 'path url..........???????????')
  const [locationKeys, setLocationKeys] = useState([]);
  const [refreshArticle, setRefreshArticle] = useState(false);
  const history = useHistory();
  const [likedBy, setLikedBy] = useState([]);
  // const [detailsData, setDetailsData] = useState([]);
  // const [articleID, setArticleID] = useState("");
  // console.log(detailsData, articleID, 'data from the server');

  //video modal
  const modal = () => {
    setOpenSettingssub(!openSettingssub);
  };
  const [openSettingssub, setOpenSettingssub] = useState(false);

  useEffect(() => {
    dispatch(emptyArticle());
  }, []);

  //logs for mahindra collective....
  //anaylatics log save
  useEffect(() => {
    dispatch(
      addAnalayticsLog({
        module: "mahindra-collective",

        browserDetails: platform.name,

        deviceDetails: platform.product ? platform.product : "Desktop",

        isSearch: false,
      })
    );
    // you know what is this, don't you?
  }, []);

  //get selected article.
  useEffect(() => {
    dispatch(getArticle("mahindracollectivebyid", id, contentCategory));
  }, [refreshArticle, locationKeys]);

  const currentArticle = useSelector((state) => state.getArticleDataReducer);
  const {
    getData: detailsData,
    articleID: articleID,
    previousID: previousID,
    nextID: nextID,
  } = currentArticle;

  //     setDetailsData(presentArticle?.object);
  //      setArticleID(presentArticle?.object?.id);
  const [refreshComment, setRefreshComment] = useState(false);
  const [loading, setLoading] = useState(false);
  const [text, setText] = useState("");
  const [open, setOpen] = useState(false);
  const [contentType, setContentType] = useState("");
  const [mediaData, setMediaData] = useState({
    pdf: null,
    videoUri: null,
  });
  const [totalcommentCount, setTotalcommentCount] = useState(null);
  //const [commentCount, setCommentCount] = useState(0);
  const [refresh, setrefresh] = useState(true);
  const isDisabled = text.length === 0;

  const dispatch = useDispatch();
  const location = useLocation();
  const commentTrue = false;
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [backendComments, setBackendComments] = useState([]);
  const [activeComment, setActiveComment] = useState(null);
  const [isDelete, setIsDelete] = useState(false);

  //For Like/ unlike feature
  const articleType = location?.state?.typeCheckForMahindra || false;
  const [isLiked, setIsLiked] = useState(false);
  const [likesCount, setLikesCount] = useState(0);
  let empId = localStorage.getItem("token");
  //Selector
  const currentStatus = useSelector((state) => {
    return state?.likeDataReducer;
  });

  const deleteStatus = useSelector((state) => {
    return state?.deleteArticleReducer;
  });

  const handleLike = async () => {
    try {
      console.log(articleType, "artivle type.....");
      dispatch(likeArticle("MahindraCollective", articleID));

      if (!isLiked) {
        setLikesCount(likesCount + 1);
      } else {
        if (likesCount > 0) {
          setLikesCount(likesCount - 1);
        }
      }
      setIsLiked(!isLiked);
    } catch (error) {}
  };

  //Life cycle hook
  useEffect(() => {
    setIsLiked(false);
    setLikesCount(0);
    setLikedBy(detailsData?.likeBy === undefined ? [] : detailsData?.likeBy);

    let isExistinLike = false;
    if (detailsData?.likeBy && detailsData?.likeBy.length >= 0) {
      isExistinLike = detailsData?.likeBy.find((obj) => {
        return obj == empId ? true : false;
      });
      console.log(`${isExistinLike}`);
      setIsLiked(isExistinLike ? true : false);
      setLikesCount(detailsData?.likeBy?.length);
    }
    if (detailsData?.isDeleted) {
      history.push(`/not-found`);
    }
  }, [detailsData]);

  useEffect(() => {
    if (deleteStatus.status == "success" && isDelete) {
      Swal.fire("Deleted successfully.", " ", "success");
      history.push("/connect/livewire/mahindra-collective");
      setIsDelete(false);
    } else if (deleteStatus.status == "failed" && !isDelete) {
      Swal.fire("Something went wrong.", " ", "error");
    }
  }, [deleteStatus]);

  useEffect(() => {
    return history.listen((location) => {
      if (history.action === "PUSH") {
        setLocationKeys([location.key]);
      }

      if (history.action === "POP") {
        if (locationKeys[1] === location.key) {
          setLocationKeys(([_, ...keys]) => keys);

          // Handle forward event
        } else {
          setLocationKeys((keys) => [location.key, ...keys]);

          // Handle back event
        }
      }
    });
  }, [locationKeys]);

  const getReplies = (commentId) =>
    backendComments
      .filter((backendComment) => backendComment.parentId === commentId)
      .sort(
        (a, b) =>
          new Date(a.createdAt).getTime() - new Date(b.createdAt).getTime()
      );

  const addComment = (text, parentId) => {
    createCommentApi(text, parentId).then((comment) => {
      setBackendComments([comment, ...backendComments]);
      setActiveComment(null);
    });
  };

  //handle share
  const handleAnchorEl = (event, id) => {
    event.stopPropagation();
    event.preventDefault();
    setAnchorEl(anchorEl ? null : event.currentTarget);
    // setAnchorEl(event.currentTarget);
  };

  const handleClickAway = () => {
    setAnchorEl(false);
  };

  // //get rootComments.
  // useEffect(() => {
  //   dispatch(getCommentsData(articleID));
  // }, [articleID, refresh]);
  // const RootComments = useSelector((state) => state.getCommentDataReducer);

  // useEffect(() => {
  //   getCommentsApi().then((data) => {
  //     setBackendComments(data);
  //   });
  //   window.scrollTo(0, 0);
  // }, []);

  const [header, setHeader] = useState("alignASocialIcon1");

  const listenScrollEvent = (event) => {
    if (window.scrollY < 100) {
      return setHeader("alignASocialIcon1");
    } else if (window.scrollY > 700) {
      return setHeader("alignASocialIcon2");
    }
  };

  useEffect(async () => {
    window.addEventListener("scroll", listenScrollEvent);
    return () => window.removeEventListener("scroll", listenScrollEvent);
  }, []);

  //handle next click function.
  const handleNextClick = () => {
    setRefreshArticle(!refreshArticle);

    history.push({
      pathname: `${LIVEWIRE}${LIVE_WIRE_ARTICLE2}/${contentCategory}/?id=${nextID}`,
    });
  };

  const handlePreviousClick = () => {
    setRefreshArticle(!refreshArticle);

    history.push({
      pathname: `${LIVEWIRE}${LIVE_WIRE_ARTICLE2}/${contentCategory}/?id=${previousID}`,
    });
  };

  window.onhashchange = function () {
    console.log("i was baked");
  };

  const handleCommentClick = () => {
    const data = {
      comment: text,
      type: "comment",
      employeeId: localStorage.getItem("token"),
      contentType: "MahindraCollective",
      contenId: articleID,
    };
    CommentSubmitApi(data);
  };

  const handleCancel = () => {
    setText("");
  };

  const CommentSubmitApi = async (data) => {
    try {
      setLoading(true);
      const res = await services.api("POST", `/ui-assist/v1/comment`, data);
      if (res) {
        setLoading(false);
        Swal.fire({
          position: "center",
          icon: "success",
          title: "comment Added Successfully",
          showConfirmButton: false,
          timer: 1500,
        });

        setrefresh(!refresh);
        setText("");
      }
    } catch (err) {
      setLoading(false);
      if (err.response) {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Something went wrong!",
          footer: '<a href="">Why do I have this issue?</a>',
        });
      }
    }
  };

  const handleClickOpen = (contentType, detailsData) => {
    setOpen(true);
    setContentType(contentType);
    let values = { ...mediaData };
    values.pdf = detailsData.media;
    values.videoUri = detailsData.videoUri;
    setMediaData(values);
  };

  const openPDF = (docfLink) => {
    return <PdfViewerComponent link={docfLink}></PdfViewerComponent>;
  };

  function handleDelete(id) {
    Swal.fire({
      title: "Are you sure?",
      html: `The article will be removed from <b>Livewire Mahindra-collective</b> & <b>What'snew</b> section`,
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Yes, remove it!",
      cancelButtonText: "No, keep it",
    }).then((result) => {
      if (result.isConfirmed) {
        try {
          dispatch(deleteArticle("mahindraCollective", id));
          setIsDelete(true);
        } catch (error) {
          console.log("In delete function, got error");
        }
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire("Cancelled", "The said article is intact!", "error");
      }
    });
  }

  function handleEdit() {
    try {
      console.log("Edit clicked");
      history.push({
        pathname: `${LIVEWIRE}/upload-story/${articleID}`,
        state: {
          details: detailsData,
          scheduleDataFlag: false,
        },
      });
    } catch (error) {
      console.log("Something went wrong");
    }
  }

  const handleClose = () => {
    setOpen(false);
    setContentType("");
    setMediaData({
      pdf: null,
      videoUri: null,
    });
  };
  //imgZoomModal
  const [openZoom, setOpenZoom] = useState(false);
  const handleCloseImgModal = () => setOpenZoom(false);
  const imgModalZoom = () => setOpenZoom(true);

  const videoPlayer = (link) => {
    return (
      <ReactPlayer
        url={link}
        controls
        width="100%"
        height="100%"
        className="playerStyle"
        playing
        onError={(err) => console.log("Error", err)}
      />
    );
  };

  // //get all id's
  // useEffect(() => {
  //   (async () => {
  //     try {
  //       const res = await services.api("GET", `/ui-assist/v1/getallcontentid`);
  //     } catch (error) {}
  //   })();
  // }, []);

  return (
    <div className="fontFamilyArticle2">
      <CommonModal
        open={open}
        handleClose={handleClose}
        header={false}
        footer={false}
        modalSize="lg"
        addClassForVideo={true}
        contentType={contentType}
        submitBtnText="DONE"
      >
        <Card className="videoPlayer">
          <CardActionArea className="videoPlayerCardArea">
            {contentType === "pdf"
              ? openPDF(mediaData.pdf ? mediaData.pdf : null)
              : videoPlayer(mediaData.videoUri ? mediaData.videoUri : null)}
          </CardActionArea>
        </Card>
      </CommonModal>
      <Template pageName={detailsData?.title}>
        {loading ? <Loader /> : null}
        <div className="commonTemplate">
          <Grid container spacing={5} className="alignMCArticals">
            <Grid item sm={12} className="articleRightP">
              <Paper className="landingpageAlign2">
                <Grid
                  container
                  direction="column"
                  // style={{ position: "fixed" }}
                  className="iconWidth leftfixedbox"
                >
                  <Grid
                    item
                    style={{
                      display: "flex",
                      alignItems: "center",
                      marginTop: "8px",
                    }}
                  >
                    <img
                      src={shareIcon}
                      alt="downloadLine"
                      className="alignSocialIcons"
                      onClick={(event) => handleAnchorEl(event)}
                    />
                  </Grid>
                  <Popper
                    className="popperBlock"
                    open={Boolean(anchorEl)}
                    anchorEl={anchorEl}
                  >
                    <ClickAwayListener onClickAway={handleClickAway}>
                      <Popper
                        className="popperBlock"
                        open={Boolean(anchorEl)}
                        anchorEl={anchorEl}
                      >
                        <ClickAwayListener onClickAway={handleClickAway}>
                          <Paper className="papperClass shareoptpanel">
                            <Sharefiles
                              pathURL={pathURL}
                              id={articleID}
                              contentType="MahindraCollective"
                            />
                          </Paper>
                        </ClickAwayListener>
                      </Popper>
                    </ClickAwayListener>
                  </Popper>

                  <Grid
                    item
                    style={{
                      display: "flex",
                      alignItems: "center",
                      marginTop: "8px",
                    }}
                    onClick={handleLike}
                  >
                    {isLiked ? (
                      <img
                        src={heartIconFill}
                        alt="downloadLine"
                        className="alignSocialIcons"
                      />
                    ) : (
                      <img
                        src={heartIcon}
                        alt="downloadLine"
                        className="alignSocialIcons"
                      />
                    )}

                    {likesCount !== 0 && (
                      <span className="articleCount">{likesCount}</span>
                    )}
                  </Grid>

                  {!commentTrue ? (
                    <>
                      <Grid
                        item
                        style={{
                          display: "flex",
                          alignItems: "center",
                          marginTop: "8px",
                        }}
                      >
                        <img
                          src={chatIcon}
                          alt="downloadLine"
                          className="alignSocialIcons"
                        />
                        <span className="articleCount">
                          {totalcommentCount}
                        </span>
                      </Grid>
                    </>
                  ) : (
                    <> </>
                  )}
                  <Grid
                    item
                    style={{
                      display: "flex",
                      alignItems: "center",
                      marginTop: "8px",
                    }}
                  >
                    <img
                      src={arrowIcon}
                      onClick={handleNextClick}
                      alt="downloadLine"
                      className="alignSocialIcons"
                    />
                  </Grid>
                  <Grid
                    item
                    style={{
                      display: "flex",
                      alignItems: "center",
                      marginTop: "8px",
                    }}
                  >
                    <img
                      src={arrowLeftIcon}
                      onClick={handlePreviousClick}
                      alt="downloadLine"
                      className="alignSocialIcons"
                    />
                  </Grid>
                </Grid>
                <Grid
                  container
                  direction="column"
                  justifyContent="flex-start"
                  // alignItems="flex-start"
                >
                  <div className="Amtmb">
                    <Grid container direction="row" className="">
                      {detailsData?.tags?.map((item, index) => (
                        <>
                          <div className="tagStyle" key={index}>
                            <div className="tagText">{item}</div>
                          </div>
                        </>
                      ))}
                      {isAccesible("MAHINDRA_COLLECTIVE") && (
                        <>
                          <Grid item xs="auto" className="ms-auto">
                            <span
                              onClick={handleEdit}
                              style={{
                                fontSize: "14px",
                                color: "#e31837",
                                fontWeight: "600",
                                marginRight: "1rem",
                                cursor: "pointer",
                              }}
                              className="ms-auto"
                            >
                              EDIT
                            </span>
                            <IconButton
                              aria-label="add to favorites"
                              onClick={() => handleDelete(id)}
                            >
                              <img src={deleteIcon} alt="delete" />
                            </IconButton>
                          </Grid>
                        </>
                      )}
                    </Grid>
                  </div>
                  <Grid item className="fullWidth" xs={12}>
                    <div className="article2ContentTitle">
                      {detailsData.title}
                    </div>
                    {!commentTrue ? (
                      <>
                        <div className="article2ContentSubTitle">
                          {detailsData.subTitle}
                        </div>
                      </>
                    ) : (
                      <>
                        <div className="article2ContentSubTitle">
                          {detailsData.subTitle}
                        </div>
                      </>
                    )}
                    {!commentTrue ? (
                      <>
                        <Grid
                          container
                          className="alignArticalTopDetails"
                          direction="row"
                        >
                          {/* <Grid item sm={3}>
                          <div className="avatarDetailsAlignment">
                            {location?.state?.empPhotoUrl ? (
                              <img
                                src={location?.state?.empPhotoUrl}
                                alt=""
                                className="avatar mc2Profle"
                              />
                            ) : (
                              <Avatar></Avatar>
                            )}

                            <div className="avatarNameDate">
                              <p className="articleAvatarName">
                                {detailsData.name}
                              </p>
                              <p className="avatarDate">
                                {moment(detailsData.publishDate).format(
                                  "DD/MM/YYYY"
                                )}
                              </p>
                            </div>
                          </div>{" "}
                        </Grid> */}
                          <AuthorDetails
                            isAuthorPost={detailsData?.isAuthorPost}
                            submittedByName={detailsData?.name}
                            submittedByPhoto={
                              detailsData?.empPhotoUrl
                                ? detailsData?.empPhotoUrl
                                : location?.state?.empPhotoUrl
                            }
                            submittedForName={detailsData?.submittedFor}
                          />

                          <Grid item sm={4} md={3}>
                            <div className="avatarDetailsAlignment">
                              <div className="avatarNameDate">
                                <p className="avatarDate">Published on:</p>
                                <p className="articleAvatarName">
                                  {getFormattedDate(detailsData.publishDate)}
                                </p>
                              </div>
                            </div>{" "}
                          </Grid>
                        </Grid>
                      </>
                    ) : (
                      <>
                        <div className="avatarNameDate">
                          <p className="avatarDate">21st July, 2021</p>
                        </div>{" "}
                      </>
                    )}
                    <div className="contentpanel">
                      <p></p>
                      <div className="articleImgDivContainer">
                        <div className="imgpanelleft">
                          <img
                            src={detailsData.thumbnail}
                            alt="avatar"
                            className="articlePic"
                          />
                          <ZoomInIcon
                            className="articleZoomInIconImg"
                            onClick={imgModalZoom}
                          />
                        </div>
                        <CommonModal
                          handleClose={handleCloseImgModal}
                          className="articleZoomImageModal"
                          modalSize="lg"
                          open={openZoom}
                          header={true}
                          footer={false}
                          headerText="Preview image"
                          icon={wheelWhite}
                        >
                          <div
                            style={{
                              padding: "20px",
                              backgroundColor: "black",
                            }}
                          >
                            <img
                              src={detailsData.thumbnail}
                              alt="articleImmage"
                              style={{ height: "100%", width: "100%" }}
                            />
                          </div>
                        </CommonModal>
                        {detailsData?.videoUri && (
                          <img
                            src={process.env.PUBLIC_URL + "/youtube.png"}
                            alt="Card Logo"
                            style={{
                              position: "absolute",
                              width: "100px",
                              cursor: "pointer",
                              top: "80px",
                              left: "120px",
                            }}
                            onClick={modal}
                          />
                        )}
                      </div>
                      <p>
                        {detailsData.story !== "null" &&
                        detailsData.story !== null ? (
                          <div
                            dangerouslySetInnerHTML={{
                              __html: detailsData.story,
                            }}
                          ></div>
                        ) : (
                          ""
                        )}
                      </p>
                    </div>

                    {detailsData.mediaType === "document" && (
                      <div className="attachmentMB">
                        <div className="attachmentTitle">Attachments</div>
                        <div>
                          <Icon
                            // className="pdfStyle pointer"
                            className="pointer"
                            title="show PDF"
                            onClick={() => handleClickOpen("pdf", detailsData)}
                          >
                            <img
                              className="pointer pdfIcon"
                              src={pdfIcon}
                              alt="no pdf icon"
                            />
                          </Icon>
                        </div>
                      </div>
                    )}
                  </Grid>
                </Grid>

                {!commentTrue ? (
                  <>
                    {" "}
                    <Grid
                      container
                      direction="column"
                      justifyContent="space-evenly"
                    >
                      <Grid item xs={12}>
                        <Paper className="commentPaper">
                          <Grid
                            container
                            direction="column"
                            justifyContent="flex-start"
                          >
                            <Grid item xs={12}>
                              {!commentTrue ? (
                                <Comments
                                  contentType="MahindraCollective"
                                  articleID={articleID}
                                  setRootCommentCount={setTotalcommentCount}
                                  className="textAreaSize sda"
                                  refreshComment={refreshComment}
                                />
                              ) : (
                                <></>
                              )}
                            </Grid>
                          </Grid>
                        </Paper>
                      </Grid>
                    </Grid>
                  </>
                ) : (
                  <> </>
                )}
              </Paper>
            </Grid>
          </Grid>
        </div>
        <RemoteModal
          open={openSettingssub}
          handleClose={() => setOpenSettingssub(false)}
          headerText="Video"
          modalSize="md"
          header={true}
          footer={true}
          fullWidth
          link={detailsData.videoUri}
        />
      </Template>
    </div>
  );
}
