import { FormGroup, Grid, Paper } from "@material-ui/core";
import React, { useState, useEffect } from "react";
import "./BloodBank.css";
import Template from "../../../Common/Template/Template";
import SubmitButton from "../../../Common/Button/SubmitButton";
import CommonCheckBox from "../../../Common/CommonCheckBox/CommonCheckBox";
import SnackbarC from "../../../Common/Snackbar/SnackbarC";
import alert from "../../../../Assets/Images/SnackBar/alertError.svg";
import Swal from "sweetalert2";
import Loader from "../../../Common/Loader/Loader";

import BloodBankCard from "./BloodBankCard";
import { getAddBloodDonorData } from "../../../../Redux/Actions/AddBloodDonorAction";
import { getAddBloodRequestData } from "../../../../Redux/Actions/AddBloodRequestAction";
import { getDisableDonorStatusData } from "../../../../Redux/Actions/DisableDonorStatusAction";
import { getLocationData } from "../../../../Redux/Actions/LocationAction";
import { getInitialStatusData } from "../../../../Redux/Actions/InitialStatusAction";
import { getSendMailData } from "../../../../Redux/Actions/SendMailAction";
import { useDispatch, useSelector } from "react-redux";

//fields
import CommonAutocomplete from "../../../Common/CommonAutocomplete/CommonAutocomplete";
import CommonLabel from "../../../Common/ComonLabel/CommonLabel";
import CharLeftField from "../../../Common/CharLeftField/CharLeftField";
import CustomMultiSelect from "../../../Common/CustomMultiSelect/CustomMultiSelect";
import { useStore } from "react-redux";

export const BloodBank = () => {
  const dispatch = useDispatch();
  const addDonorData = useSelector((state) => state.getAddBloodDonorReducer);
  const addRequestData = useSelector(
    (state) => state.getAddBloodRequestReducer
  );
  const disableDonorData = useSelector(
    (state) => state.getDisableDonorStatusReducer
  );
  const locationData = useSelector((state) => state.getLocationReducer);
  const initialData = useSelector((state) => state.getInitialStatusReducer);

  const sendMailData = useSelector((state) => state.getSendMailReducer);
  const donorsList = addRequestData.getData.bloodDonors;

  const [Bool1, setBool1] = useState(false);
  const [Bool2, setBool2] = useState(true);
  const [titleCharLeft, setTitleCharLeft] = useState(136);

  const [donorBloodGroup, setDonorBloodGroup] = useState("");
  const [requesterBloodGroup, setRequesterBloodGroup] = useState("");
  const [donorSendMailCheck, setDonorSendMailCheck] = useState("notChecked");
  const [isDonor, setIsDonor] = useState(undefined);
  const [isMailSend, setIsMailSend] = useState(false);
  const [disableMailCheck, setDisableMailCheck] = useState("notChecked");
  const [enableMailCheck, setEnableMailCheck] = useState();
  const [unregisterDonorCheck, setUnregisterDonorCheck] =
    useState("notChecked");
  const [unregistered, setUnregistered] = useState(false);
  const [bloodFor, setBloodFor] = useState("");
  const [locationPreference, setLocationPreference] = useState([]);
  const [message, setMessage] = useState("");
  const [displayList, setDisplayList] = useState(false);
  const [sendRequestCheck, setSendRequestCheck] = useState(false);
  const [existingData, setExistingData] = useState("");
  const [selectDonorList, setSelectDonorList] = useState([]);
  const [loader, setLoader] = useState(false);
  const [locationList, setLocationList] = useState([]);
  const [defaultLocation, setDefaultLocation] = useState([]);
  const [submitSuccess, setSubmitSuccess] = useState(false);

  const [open, setOpen] = useState(false);
  const [open1, setOpen1] = useState(false);
  const [sendAllClick, setSendAllClick] = useState(false);

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
    setOpen1(false);
  };
  const autoHideDuration = 3500;
  const errormessage = (
    <>
      <div style={{ display: "flex", alignItems: "start", width: "400px" }}>
        <img
          src={alert}
          alt="Download line"
          className="sImg"
          style={{ marginLeft: "16px" }}
        />
        <div className="contentS1">Please select required fields.</div>
      </div>
    </>
  );
  const errormessage1 = (
    <>
      <div style={{ display: "flex", alignItems: "start", width: "400px" }}>
        <img
          src={alert}
          alt="Download line"
          className="sImg"
          style={{ marginLeft: "16px" }}
        />
        <div className="contentS1">
          Please select Blood Group and Check Box.
        </div>
      </div>
    </>
  );

  useEffect(() => {
    let userProfile = JSON.parse(
      localStorage.getItem("userProfile")
        ? localStorage.getItem("userProfile")
        : null
    );
    setExistingData(userProfile);
    dispatch(getLocationData());
    const id = userProfile.employeeid;
    dispatch(getInitialStatusData(id));
  }, [isDonor]);
  useEffect(() => {
    if (
      addDonorData.isLoading ||
      addRequestData.isLoading ||
      disableDonorData.isLoading ||
      locationData.isLoading ||
      initialData.isLoading ||
      sendMailData.isLoading
    ) {
      setLoader(true);
    } else {
      setLoader(false);
    }
    if (initialData.getData.sendNotificationForBloodDonarRequest) {
      setDisableMailCheck("notChecked");
      setEnableMailCheck(false);
    } else {
      setDisableMailCheck("checked");
      setEnableMailCheck(true);
    }
  }, [
    addDonorData,
    addRequestData,
    disableDonorData,
    locationData,
    initialData,
    sendMailData,
  ]);

  useEffect(() => {
    if (sendMailData.status === "success" && submitSuccess == true) {
      setSubmitSuccess(false);
      setSendAllClick(false);
    }
  }, [sendMailData]);

  const Bool2F = () => {
    setBool2(false);
    setDisplayList(false);
    setSendRequestCheck(false);
    setMessage("");
    setLocationPreference([]);
  };

  const handleChange = (e) => {
    const { value, name } = e.target;
    if (name === "title") {
      if (value.length <= 136) setTitleCharLeft(136 - value.length);
    }
    setMessage(value);
  };

  const options = [
    { title: "Self", value: "Self" },
    { title: "Wife", value: "Wife" },
    { title: "Mother", value: "Mother" },
    { title: "Father", value: "Father" },
    { title: "Son", value: "Son" },
    { title: "Daughter", value: "Daughter" },
    { title: "Brother", value: "Brother" },
    { title: "Sister", value: "Sister" },
  ];

  // let locationList = [];
  useEffect(() => {
    let currentLocation = [];
    let currentLocationId = existingData.employeePa + existingData.employeePsa;
    console.log("location Id =====> ", currentLocationId);
    let locList = [];
    locationData?.getData?.map((data) => {
      let obj = {
        title: data.psaText,
        value: data.psaText,
      };
      locList = [...locList, obj];
      if (currentLocationId == data.locationid) {
        currentLocation = [...currentLocation, obj];
      }
    });
    setLocationList(locList);
    setDefaultLocation(currentLocation);
    // console.log("current location =====> ", currentLocation);
  }, [locationData]);

  useEffect(() => {
    if (defaultLocation) {
      setLocationPreference(defaultLocation);
    }
  }, [defaultLocation]);

  const AddDonorsList = (data) => {
    setSelectDonorList([...selectDonorList, data]);
    console.log(
      "selected Donor list ======> ",
      selectDonorList,
      "current data ===>",
      data
    );
    //  selectDonorList.length > 1 ? setSendAll(true) : setSendAll(false)
  };
  const DeleteDonorsList = (data) => {
    let index = selectDonorList.findIndex((val) => val.employeeId == data);

    if (index !== -1) {
      selectDonorList.splice(index, 1);
    }
  };

  const checkRadio = (event) => {
    setDonorBloodGroup(event.target.value);
  };
  const handleChangeCheckbox = () => {
    donorSendMailCheck == "notChecked"
      ? setDonorSendMailCheck("checked")
      : setDonorSendMailCheck("notChecked");
  };
  const becomeDonor = () => {
    if (donorBloodGroup == "" || donorSendMailCheck == "notChecked") {
      setOpen1(true);
    } else if (donorBloodGroup != "" && donorSendMailCheck != "notChecked") {
      let donorData = {};
      if (initialData.getData.id == null) {
        donorData = {
          employeeId: existingData.employeeid,
          bloodGroupName: donorBloodGroup,
          sendNotificationForBloodDonarRequest: true,
        };
      } else {
        donorData = {
          employeeId: existingData.employeeid,
          bloodGroupName: donorBloodGroup,
          sendNotificationForBloodDonarRequest: true,
          id: initialData.getData.id,
        };
      }
      dispatch(getAddBloodDonorData(donorData));

      setOpen1(false);
      setIsDonor(true);
      setBool1(false);
      setBool2(true);
      setIsMailSend(true);
      setEnableMailCheck(false);
      setDonorSendMailCheck("notChecked");
      setDonorBloodGroup("");
      setMessage("");
      setLocationPreference([]);
    }
  };
  const removeDonor = () => {
    setBool1(true);
    setBool2(false);
    setDisplayList(false);
    setSendRequestCheck(false);
    setMessage("");
    setLocationPreference([]);
  };
  const disableMailCheckbox = () => {
    if (disableMailCheck == "notChecked") {
      setDisableMailCheck("checked");
      setEnableMailCheck(true);
    } else {
      setDisableMailCheck("notChecked");
      setEnableMailCheck(false);
    }
  };
  const unregisterDonorCheckbox = () => {
    if (unregisterDonorCheck == "notChecked") {
      setUnregisterDonorCheck("checked");
      setUnregistered(true);
    } else {
      setUnregisterDonorCheck("notChecked");
      setUnregistered(false);
    }
  };
  const donor = () => {
    if (disableMailCheck == "checked") {
      setIsMailSend(false);
      setEnableMailCheck(true);
      setBool1(false);
      setBool2(true);
      setIsDonor(!isDonor);
    }
    if (disableMailCheck == "notChecked") {
      setIsMailSend(true);
      setEnableMailCheck(false);
      setBool1(false);
      setBool2(true);
      setIsDonor(!isDonor);
    }
    if (unregisterDonorCheck == "checked") {
      setIsDonor(false);
      setIsMailSend(false);
      setEnableMailCheck(true);
      setUnregistered(false);
      setBool2(true);
      setUnregisterDonorCheck("notChecked");
    }
    const formData = new FormData();
    formData.append("sendNotificationForBloodDonarRequest", enableMailCheck);
    formData.append("unregisterdDonor", unregistered);
    dispatch(getDisableDonorStatusData(formData, existingData.employeeid));
  };
  const requestBlood = () => {
    setBool1(false);
    setBool2(true);
    setDonorSendMailCheck("notChecked");
    setDonorBloodGroup("");
    setDisableMailCheck("notChecked");
    setUnregisterDonorCheck("notChecked");
  };
  const checkRadioBloodgroup = (event) =>
    setRequesterBloodGroup(event.target.value);
  const BloodFor = (event, selectedValue) => setBloodFor(selectedValue.value);
  const LocationPreference = (event, selectedValue) =>
    setLocationPreference(selectedValue.value);
  const displayDonorsList = () => {
    if (
      requesterBloodGroup == "" ||
      bloodFor == "" ||
      locationPreference == ""
    ) {
      setOpen(true);
    } else if (
      requesterBloodGroup != "" &&
      bloodFor != "" &&
      locationPreference != ""
    ) {
      let locations = [];
      locationPreference.map((data) => {
        locations = [...locations, data.value];
      });
      let requestData = {
        bloodGroupName: requesterBloodGroup,
        beneficiaryId: existingData.employeeid,
        bloodRelation: bloodFor,
        locationPreference: locations,
        requestMessage: message,
      };
      dispatch(getAddBloodRequestData(requestData));

      setOpen(false);
      setDisplayList(true);
    }
  };
  const selectAll = () => {
    if (sendRequestCheck) {
      setSendRequestCheck(false);
      setSelectDonorList([]);
    } else {
      setSendRequestCheck(true);
      setSelectDonorList(donorsList);
    }
  };
  const SendAll = () => {
    if (selectDonorList?.length > 0) {
      setSubmitSuccess(true);
      dispatch(getSendMailData(selectDonorList));
      setSendAllClick(true);
      setSelectDonorList([]);
    } else {
      Swal.fire("Please select donors to send request.", " ", "error");
    }
    setSelectDonorList([]);
  };

  const getOptionLabel = (option) => `${option.title}`;
  const getOptionDisabled = (option) => option.value === "foo";

  const handleLocationToggleOption = (selectedOptions) => {
    setLocationPreference(selectedOptions);
  };

  const handleClearLocation = () => {
    setLocationPreference([]);
  };

  const handleSelectAllLocations = (isSelected) => {
    if (isSelected) {
      setLocationPreference(locationList);
    } else {
      handleClearLocation();
    }
  };

  return (
    <section className="emergencyContactContainer">
      {loader ? <Loader /> : null}
      <Template>
        <Grid
          container
          direction="row"
          justifyContent="space-between"
          alignItems="center"
        >
          <Grid item xs="auto" sm={10}>
            <p className="bloodHeading">Blood bank</p>
          </Grid>
          <Grid item xs="auto" sm={2}>
            {Bool2 ? (
              <></>
            ) : (
              <div className="requestBloodTxt" onClick={requestBlood}>
                Request for blood
              </div>
            )}
          </Grid>
          {Bool1 && initialData.getData.isDonar ? (
            <>
              <Grid
                container
                direction="row"
                className="mt4"
                style={{ marginBottom: "21vh" }}
              >
                <Grid item xs={12}>
                  <div className="bbContainer">
                    <div className="bbHeaderTxt">
                      Thank you for joining the Mahindra Blood Bank
                    </div>
                    <div className="bbCheckboxContainer P1BB">
                      <FormGroup>
                        <Grid item sm={12}>
                          <CommonCheckBox
                            checked={enableMailCheck}
                            name={disableMailCheck}
                            handleChange={disableMailCheckbox}
                          />
                          <span style={styles.checkboxTitle}>
                            Disable email notifications
                          </span>
                        </Grid>
                        <Grid container>
                          <Grid item xs="auto" sm="auto">
                            <CommonCheckBox
                              name={unregisterDonorCheck}
                              handleChange={unregisterDonorCheckbox}
                            />
                          </Grid>
                          <Grid item xs={10} sm="auto">
                            <span style={styles.checkboxTitle}>
                              Unregister as a donor from The Mahindra Blood Bank
                            </span>
                          </Grid>
                        </Grid>
                      </FormGroup>
                    </div>
                  </div>
                  <div>
                    <div className="Line-15"></div>
                    <div className="bbContainer btnAlignment">
                      <SubmitButton label="Submit" onClick={donor} />
                    </div>
                  </div>
                </Grid>
              </Grid>
            </>
          ) : (
            <>
              {Bool2 ? (
                <>
                  {" "}
                  <Grid container direction="row" className="mt4">
                    <Grid item xs={12}>
                      <Paper elevation={0}>
                        <div className="bbP2HeaderContainer1">
                          <div>
                            <div className="bbHeaderP2Txt">
                              Join our blood bank and help your colleagues when
                              they need help
                            </div>
                            <div className="bbHeaderP2SubTxt">
                              You will receive a email when your blood group
                              matches with a person’s need.
                            </div>
                          </div>
                          {initialData.getData.isDonar ? (
                            <SubmitButton
                              label="Deregister from donor"
                              onClick={removeDonor}
                            />
                          ) : (
                            <SubmitButton
                              label="Become a donor"
                              onClick={Bool2F}
                            />
                          )}
                        </div>
                      </Paper>
                    </Grid>
                  </Grid>
                  <Grid container direction="row" className="mt4">
                    <Grid item xs={12}>
                      <Paper elevation={0}>
                        <div className="bbP2HeaderContainer2">
                          <div>
                            <div className="bbHeaderP2Txt">
                              Request for blood
                            </div>
                            <div className="bbHeaderP2SubTxt">
                              <CommonLabel
                                label="Select your blood group"
                                mandatory
                              />
                            </div>
                          </div>
                          <div className="imgContainer">
                            <label onClick={checkRadioBloodgroup}>
                              <input type="radio" name="test1" value="A+" />
                              <div className="droplet">
                                <div className="bloodgroup">A+</div>
                              </div>
                            </label>
                            <label onClick={checkRadioBloodgroup}>
                              <input type="radio" name="test1" value="B+" />
                              <div className="droplet">
                                <div className="bloodgroup">B+</div>
                              </div>
                            </label>
                            <label onClick={checkRadioBloodgroup}>
                              <input type="radio" name="test1" value="O+" />
                              <div className="droplet">
                                <div className="bloodgroup">O+</div>
                              </div>
                            </label>
                            <label onClick={checkRadioBloodgroup}>
                              <input type="radio" name="test1" value="AB+" />
                              <div className="droplet">
                                <div className="bloodgroup">AB+</div>
                              </div>
                            </label>
                            <label onClick={checkRadioBloodgroup}>
                              <input type="radio" name="test1" value="A-" />
                              <div className="droplet">
                                <div className="bloodgroup">A-</div>
                              </div>
                            </label>
                            <label onClick={checkRadioBloodgroup}>
                              <input type="radio" name="test1" value="B-" />
                              <div className="droplet">
                                <div className="bloodgroup">B-</div>
                              </div>
                            </label>
                            <label onClick={checkRadioBloodgroup}>
                              <input type="radio" name="test1" value="O-" />
                              <div className="droplet">
                                <div className="bloodgroup">O-</div>
                              </div>
                            </label>
                            <label onClick={checkRadioBloodgroup}>
                              <input type="radio" name="test1" value="AB-" />
                              <div className="droplet">
                                <div className="bloodgroup">AB-</div>
                              </div>
                            </label>
                            <label onClick={checkRadioBloodgroup}>
                              <input type="radio" name="test1" value="Other" />
                              <div className="droplet">
                                <div className="bloodgroup">Other</div>
                              </div>
                            </label>
                          </div>
                          <Grid container spacing={3}>
                            <Grid item xs={12} sm={4}>
                              <CommonAutocomplete
                                handleSelectChange={BloodFor}
                                selectedValue={bloodFor?.value}
                                name="bloodFor"
                                options={options}
                                label={
                                  <CommonLabel label="Blood for" mandatory />
                                }
                              />
                            </Grid>
                            <Grid
                              item
                              xs={12}
                              sm={4}
                              className="businessTypeIcon bloodbank addOrgFeilds mtDrop"
                            >
                              <CustomMultiSelect
                                items={locationList}
                                getOptionLabel={getOptionLabel}
                                getOptionDisabled={getOptionDisabled}
                                selectedValues={
                                  locationPreference ? locationPreference : []
                                }
                                label={
                                  <CommonLabel
                                    label="Location preference"
                                    mandatory
                                  />
                                }
                                selectAllLabel="Select all"
                                onToggleOption={handleLocationToggleOption}
                                onClearOptions={handleClearLocation}
                                onSelectAll={handleSelectAllLocations}
                              />
                            </Grid>
                            <Grid item xs={12} sm={4}>
                              <CharLeftField
                                value={message}
                                label={<CommonLabel label="Request message" />}
                                validate
                                charLeft={titleCharLeft}
                                name="title"
                                type="text"
                                inputProps={{
                                  maxLength: 136,
                                }}
                                handleChange={handleChange}
                              />
                            </Grid>
                            <Grid
                              item
                              xs={12}
                              sm={7}
                              style={{
                                display: "flex",
                                justifyContent: "start",
                              }}
                            >
                              {open &&
                              (requesterBloodGroup == "" ||
                                bloodFor == "" ||
                                locationPreference == "") ? (
                                <div className="requestBloodTxt">
                                  Please select required fields.
                                </div>
                              ) : null}
                            </Grid>
                            <Grid
                              item
                              xs={12}
                              sm={5}
                              style={{ display: "flex", justifyContent: "end" }}
                            >
                              <SubmitButton
                                label="Submit"
                                onClick={displayDonorsList}
                              />
                            </Grid>
                          </Grid>
                        </div>
                        {/* <SnackbarC open={open} handleClose={handleClose} message={errormessage} autoHideDuration={autoHideDuration} /> */}
                      </Paper>
                    </Grid>
                  </Grid>
                  {displayList ? (
                    <Grid
                      container
                      direction="row"
                      justifyContent="space-between"
                      alignItems="center"
                      style={{
                        marginBottom: "48px",
                        borderTop: "solid 1px #e3e3e3",
                      }}
                    >
                      {donorsList?.length == 0 ? (
                        <Grid item xs={12}>
                          <Paper elevation={0}>
                            <div className="bbP2HeaderContainer2">
                              <Grid
                                container
                                direction="row"
                                spacing={2}
                                alignItems="center"
                              >
                                <Grid item xs="auto" className="mx-auto">
                                  <p className="bbHeaderP2Txt">
                                    No donors available for blood group{" "}
                                    {addRequestData.getData.bloodGroupName} at
                                    selected location.
                                  </p>
                                </Grid>
                              </Grid>
                            </div>
                          </Paper>
                        </Grid>
                      ) : (
                        <Grid item xs={12}>
                          <Paper elevation={0}>
                            <div className="bbP2HeaderContainer2">
                              <Grid
                                container
                                direction="row"
                                spacing={2}
                                alignItems="center"
                              >
                                <Grid item xs="auto" sm={10}>
                                  <p className="bbHeaderP2Txt">
                                    {addRequestData.getData.bloodGroupName}{" "}
                                    Donors
                                  </p>
                                </Grid>
                                <Grid item xs="auto" sm={2} className="ms-auto">
                                  {sendRequestCheck ? (
                                    <div
                                      className="requestBloodTxt"
                                      onClick={selectAll}
                                    >
                                      DESELECT ALL
                                    </div>
                                  ) : (
                                    <div
                                      className="requestBloodTxt"
                                      onClick={selectAll}
                                    >
                                      SELECT ALL
                                    </div>
                                  )}
                                </Grid>
                              </Grid>
                              <Grid container direction="row" spacing={2}>
                                {donorsList?.map((data) => {
                                  return (
                                    <>
                                      <Grid item xs={12} sm={3}>
                                        <BloodBankCard
                                          data={data}
                                          sendAllClick={sendAllClick}
                                          checked={sendRequestCheck}
                                          AddDonorsList={AddDonorsList}
                                          DeleteDonorsList={DeleteDonorsList}
                                        />
                                      </Grid>
                                    </>
                                  );
                                })}
                              </Grid>
                              <Grid
                                container
                                direction="row"
                                spacing={2}
                                alignItems="center"
                              >
                                <Grid
                                  item
                                  xs={12}
                                  style={{
                                    display: "flex",
                                    justifyContent: "start",
                                  }}
                                >
                                  <SubmitButton
                                    label="SEND ALL"
                                    onClick={SendAll}
                                  />
                                </Grid>
                              </Grid>
                            </div>
                          </Paper>
                        </Grid>
                      )}
                    </Grid>
                  ) : (
                    <Grid container style={{ marginBottom: "48px" }}></Grid>
                  )}
                </>
              ) : (
                <>
                  <Grid container direction="row" className="mt4">
                    <Grid item xs={12}>
                      <div className="paperLineB">
                        <Paper elevation={0}>
                          <div className="bbP2HeaderContainer2">
                            <div>
                              <div className="bbHeaderP2Txt">
                                Become a donor
                              </div>
                              <div className="bbHeaderP2SubTxt">
                                Join our blood bank and help your colleagues
                                when they need help. You will receive a message
                                when your blood group matches with person’s
                                need.
                              </div>
                              <div className="bbHeaderP2SelectGroup">
                                <CommonLabel
                                  label="Select your blood group"
                                  mandatory
                                />
                              </div>
                            </div>
                            <div className="imgContainer">
                              <label onClick={checkRadio}>
                                <input type="radio" name="test1" value="A+" />
                                <div className="droplet">
                                  <div className="bloodgroup">A+</div>
                                </div>
                              </label>
                              <label onClick={checkRadio}>
                                <input type="radio" name="test1" value="B+" />
                                <div className="droplet">
                                  <div className="bloodgroup">B+</div>
                                </div>
                              </label>
                              <label onClick={checkRadio}>
                                <input type="radio" name="test1" value="O+" />
                                <div className="droplet">
                                  <div className="bloodgroup">O+</div>
                                </div>
                              </label>
                              <label onClick={checkRadio}>
                                <input type="radio" name="test1" value="AB+" />
                                <div className="droplet">
                                  <div className="bloodgroup">AB+</div>
                                </div>
                              </label>
                              <label onClick={checkRadio}>
                                <input type="radio" name="test1" value="A-" />
                                <div className="droplet">
                                  <div className="bloodgroup">A-</div>
                                </div>
                              </label>
                              <label onClick={checkRadio}>
                                <input type="radio" name="test1" value="B-" />
                                <div className="droplet">
                                  <div className="bloodgroup">B-</div>
                                </div>
                              </label>
                              <label onClick={checkRadio}>
                                <input type="radio" name="test1" value="O-" />
                                <div className="droplet">
                                  <div className="bloodgroup">O-</div>
                                </div>
                              </label>
                              <label onClick={checkRadio}>
                                <input type="radio" name="test1" value="AB-" />
                                <div className="droplet">
                                  <div className="bloodgroup">AB-</div>
                                </div>
                              </label>
                              <label onClick={checkRadio}>
                                <input
                                  type="radio"
                                  name="test1"
                                  value="Other"
                                />
                                <div className="droplet">
                                  <div className="bloodgroup">Other</div>
                                </div>
                              </label>
                            </div>
                          </div>
                        </Paper>
                      </div>
                      <Paper
                        style={{ height: "auto", marginBottom: "9.5vh" }}
                        elevation={0}
                      >
                        <Grid container>
                          <Grid item xs={12}>
                            <div className="p3OuterContainer">
                              <div className="bbContainerP3">
                                <div className="bbHeaderTxtP3">
                                  Terms and condition{" "}
                                  <span style={{ color: "red" }}> *</span>
                                </div>
                                <div className="bbCheckboxContainer">
                                  <FormGroup>
                                    <Grid container>
                                      <Grid item xs={2} sm="auto">
                                        <CommonCheckBox
                                          name={donorSendMailCheck}
                                          handleChange={handleChangeCheckbox}
                                        />
                                      </Grid>
                                      <Grid item xs={10} sm="auto">
                                        <span style={styles.checkboxTitle}>
                                          I agree to receive email notifications
                                          for blood donation requests
                                        </span>
                                      </Grid>
                                    </Grid>
                                  </FormGroup>
                                </div>
                              </div>
                              <div style={{ paddingRight: "24px" }}>
                                <SubmitButton
                                  label="Submit"
                                  onClick={becomeDonor}
                                />
                              </div>
                            </div>
                          </Grid>
                          <Grid
                            item
                            xs={12}
                            sm="auto"
                            style={{ display: "flex", justifyContent: "start" }}
                          >
                            {open1 &&
                            (donorBloodGroup == "" ||
                              donorSendMailCheck == "notChecked") ? (
                              <div className="requestBloodTxt ps-4">
                                Please select Blood Group and Check Box.
                              </div>
                            ) : null}
                          </Grid>
                        </Grid>
                        {/* <SnackbarC open={open1} handleClose={handleClose} message={errormessage1} autoHideDuration={autoHideDuration} /> */}
                      </Paper>
                    </Grid>
                  </Grid>
                </>
              )}
            </>
          )}
        </Grid>
      </Template>
    </section>
  );
};

const styles = {
  checkboxTitle: {
    fontSize: "16px",
    
    fontWeight: "400",
    lineHeight: "2.5",
    color: "#242424",
  },
};
