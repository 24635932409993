import React, { useEffect, useState } from "react";
import { Grid, Paper, Tabs, Tab, Typography, Box } from "@material-ui/core";
import Template from "../../Common/Template/Template";

import Loader from "../../Common/Loader/Loader";
import settingIcon from "../../../Assets/Images/settings_5_line.svg";
import SubmitButton from "../../Common/Button/SubmitButton";
import { useSelector, useDispatch } from "react-redux";
import isAdmin from "../../isAdmin";
import { isAccesible } from "../../../Assets/helperFunctions/helperFunctions";
import CommonModal from "../../Common/CommonModal/CommonModal";
import CommonFileUpload from "../../Common/CommonFileUpload/CommonFileUpload";
import { useFormik } from "formik";
import Swal from "sweetalert2";
import services from "../../../Services";
import CommonImageUpload from "../../Common/CommonImageUpload/CommonImageUpload";
import {
  Avatar,
  Card,
  CardActions,
  CardContent,
  CardMedia,
  IconButton,
} from "@mui/material";
import { AspectRatio } from "react-aspect-ratio";
import RemoteModal from "../Remote/RemoteVideoModal";
import { getFormattedDate } from "../../../Assets/helperFunctions/helperFunctions";
import DeleteIcon from "../../../Assets/Images/delete.svg";
import CharLeftField from "../../Common/CharLeftField/CharLeftField";
import CommonLabel from "../../Common/ComonLabel/CommonLabel";
import {
  getPodcastData,
  deletePodcastData,
  addPodcastData,
} from "../../../Redux/Actions/PodcastAction";

const MLDL = () => {
  const dispatch = useDispatch();
  const allPodcast = useSelector(
    (state) => state.getAllPodcastReducer
  )?.getAllData;
  const addPodcast = useSelector(
    (state) => state.getAllPodcastReducer
  )?.addData;
  const deletePodcast = useSelector(
    (state) => state.getAllPodcastReducer
  )?.deleteData;

  const [openModal, setOpenModal] = useState(false);
  const [openVideoModal, setOpenVideoModal] = useState(false);
  const [isAPI, setIsAPI] = useState(false);
  const [currentVideo, setCurrentVideo] = useState("");

  useEffect(() => {
    dispatch(getPodcastData());
  }, [addPodcast, deletePodcast]);

  useEffect(() => {
    if (addPodcast && isAPI) {
      setIsAPI(false);
      handleCloseModal();
    }
  }, [addPodcast]);

  useEffect(() => {
    if (deletePodcast && isAPI) {
      setIsAPI(false);
      // Swal.fire("Successfully deleted!", "", "success");
    }
  }, [deletePodcast]);

  const handleCloseModal = () => {
    setOpenModal(false);
    formik.setFieldValue("title", "");
    formik.setFieldValue("thumbnail", "");
    formik.setFieldValue("videoUri", "");
  };

  const formik = useFormik({
    initialValues: {
      thumbnail: "",
      videoUri: "",
      title: "",
    },
    enableReinitialize: true,
    // validationSchema:
    //   getEditValue == true ? uploadStorySchema : editStorySchema,
    // onSubmit: (values, { resetForm }) => handleSubmit(values, resetForm)
  });

  const handleSubmit = async (values) => {
    let formData = new FormData();
    const { videoUri, thumbnail, title } = values;

    formData.append("videoUrl", videoUri);
    formData.append("thumbnail", thumbnail);
    formData.append("title", title);

    try {
      setIsAPI(true);
      dispatch(addPodcastData(formData));
    } catch (error) {
      Swal.fire("Something went wrong!", `${error.response.data}`, "error");
    }
  };

  const handleDelete = (data) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You want to delete it!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Yes, delete it!",
      cancelButtonText: "No, keep it",
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          setIsAPI(true);
          dispatch(deletePodcastData(data?.podcastId));
        } catch (err) {
          console.log("err for delete video", err.response);
        }
      }
    });
  };

  console.log("check podcast data =====>", allPodcast);
  console.log("check added podcast data =====>", addPodcast);
  console.log("check deleted podcast data =====>", deletePodcast);
  return (
    <>
      <Template pageName={"MLDL Podcast"}>
        {/* {loading ? <Loader /> : null} */}
        <div>
          <Grid
            container
            direction="row"
            justifyContent="space-between"
            alignItems="flex-end"
          >
            <Grid item md={7} xs={12}>
              <div className="whatsNewHeader">
                <div className="containerTxt">
                  <div className="section-header-text"> MLDL LIFECast </div>
                  {/* <div
                    className="editIcon"
                    //   onClick={onEditMahindraCollective}
                  >
                    <img
                      src={process.env.PUBLIC_URL + "/iconImages/MC_admin.svg"}
                      alt=""
                      className="edit-line"
                    />
                    <div>Manage</div>
                  </div> */}
                </div>
              </div>
            </Grid>
            <Grid item md={5} xs={12}>
              {/* <SearchBarButton
                placeholder={placeholder}
                searchText={searchText}
                handleSearch={handleSearch}
                handleSearchIcon={handleAPI}
                onKeyPress={onKeyPress}
                name="search"
              /> */}
            </Grid>
          </Grid>
        </div>
        <div className=""></div>
        <Paper style={{ minHeight: "400px" }} className="mb-3">
          <Grid container justifyContent="flex-end" className="p-3">
            {isAccesible("PODCAST") && (
              <Grid item xs="auto">
                <SubmitButton
                  label="Upload Podcast"
                  className="MCsubmitbutton"
                  onClick={() => setOpenModal(true)}
                />
              </Grid>
            )}
          </Grid>
          <Grid container className="p-2">
            {allPodcast?.length > 0 &&
              allPodcast?.map((val) => {
                return (
                  <Grid item className="padding12" sm={6} md={4} lg={3} xs={12}>
                    <Card className="cardShadow">
                      <CardMedia
                        className="card-media"
                        style={{ display: "flex" }}
                      >
                        <AspectRatio ratio="3/4" style={{ maxWidth: "400px" }}>
                          <img
                            src={val?.thumbnail_url}
                            alt="Card Logo"
                            className="McCardImage"
                          />
                        </AspectRatio>
                        {val?.video_url && (
                          <>
                            <img
                              src={process.env.PUBLIC_URL + "/youtube.png"}
                              alt="Card Logo"
                              style={{
                                position: "absolute",
                                width: "40%",
                                cursor: "pointer",
                              }}
                              onClick={(e) => {
                                setOpenVideoModal(true);
                                setCurrentVideo(val?.video_url);
                              }}
                            />
                          </>
                        )}
                      </CardMedia>
                      <CardContent>
                        <p className="McCardDescriptionmldl">{val?.title}</p>
                      </CardContent>
                      <CardActions className="">
                        <div className="cardActionContainer">
                          <div className="avatarContainer">
                            <>
                              <div className="avatarIcon">
                                {val?.empPhotoUrl ? (
                                  <img
                                    src={val?.empPhotoUrl}
                                    alt=""
                                    style={{
                                      height: 35,
                                      width: 35,
                                      borderRadius: "50%",
                                    }}
                                  />
                                ) : (
                                  <Avatar
                                    style={{ height: 35, width: 35 }}
                                  ></Avatar>
                                )}
                              </div>
                              <div className="avatarDetails">
                                <div className="avatarName">{val?.author}</div>
                                <div className="dateContainer">
                                  {getFormattedDate(val?.created_date)}
                                </div>
                              </div>
                            </>
                          </div>
                          <div className="actionItemBtn">
                            {isAccesible("PODCAST") && (
                              <IconButton
                                aria-label="add to favorites"
                                onClick={(e) => handleDelete(val)}
                                style={{
                                  paddingRight: "5px",
                                  marginRight: "10px",
                                }}
                              >
                                <img src={DeleteIcon} alt="delete" />
                              </IconButton>
                            )}
                          </div>
                        </div>
                      </CardActions>
                    </Card>
                  </Grid>
                );
              })}
            {allPodcast?.length == 0 && (
              <Grid item xs="auto" className="mx-auto">
                No data found.
              </Grid>
            )}
          </Grid>
        </Paper>
        {/* <div style={{ margin: "2rem 0", float: "right" }}>
          <div className="paginationAlignFlex">
            <Stack spacing={2}>
              <Pagination
                count={totalPages ? totalPages : 1}
                page={pageNo + 1}
                variant="outlined"
                shape="rounded"
                onChange={handlePageChange}
              />
            </Stack>
          </div>
        </div> */}
      </Template>

      <RemoteModal
        open={openVideoModal}
        handleClose={() => setOpenVideoModal(false)}
        headerText="Video"
        modalSize="lg"
        header={true}
        footer={true}
        fullWidth
        link={currentVideo}
      />

      <CommonModal
        open={openModal}
        handleClose={handleCloseModal}
        headerText="Add Podcast"
        icon={settingIcon}
        modalSize="lg"
        submitBtnText="SAVE"
        fullWidth
        policiesButtonSize
        onButtonClick={(e) => handleSubmit(formik.values)}
        disabledSubmitButton={
          !formik.values.thumbnail ||
          !formik.values.videoUri ||
          !formik.values.title
        }
      >
        <Grid container className="p-3">
          <Grid
            item
            xs={12}
            className="inputWidthTitle alignInputTextTitle boxwithlimit p-1"
          >
            <CharLeftField
              label={<CommonLabel label="Title" mandatory />}
              validate
              handleChange={(e) =>
                formik.setFieldValue("title", e.target.value)
              }
              handleBlur={formik.handleBlur}
              value={formik.values.title}
              charLeft={100 - formik.values.title.length}
              name="title"
              type="text"
              errorMsg={
                formik.errors.title && formik.touched.title
                  ? formik.errors.title
                  : ""
              }
              inputProps={{
                maxLength: 100,
              }}
            />
          </Grid>
          <Grid
            item
            xs={12}
            sm={8}
            className="inputWidthTitle alignInputTextTitle boxwithlimit p-1"
          >
            <CharLeftField
              label={<CommonLabel label="Enter youtube link only" mandatory />}
              validate
              handleChange={(e) =>
                formik.setFieldValue("videoUri", e.target.value)
              }
              handleBlur={formik.handleBlur}
              value={formik.values.videoUri}
              charLeft={150 - formik.values.videoUri.length}
              name="videoUri"
              type="text"
              errorMsg={
                formik.errors.videoUri && formik.touched.videoUri
                  ? formik.errors.videoUri
                  : ""
              }
              inputProps={{
                maxLength: 100,
              }}
            />
          </Grid>
          <Grid item xs={12} sm={4} className="p-1">
            <CommonImageUpload formik={formik} />
          </Grid>
        </Grid>
      </CommonModal>
    </>
  );
};
export default MLDL;
