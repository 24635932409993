import { makeStyles } from "@material-ui/core";

const textFieldStyle = makeStyles((theme) => ({
  root: {
    "& .MuiFormLabel-root": {
      height: "24px",
      
      fontSize: "16px",
      fontWeight: "normal",
      fontStretch: "normal",
      fontStyle: "normal",
      letterspacing: "normal",
      color: "#6d6e71",
    },
    "& .MuiOutlinedInput-root": {
      "&:hover fieldset": {
        borderColor: "1px solid #e4e4e4",
      },
      "&.Mui-focused fieldset": {
        border: " 1px solid #e4e4e4",
      },
    },
  },
}));

export default textFieldStyle;
