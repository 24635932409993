import { Grid, TextField } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import CommonModal from "../../../Common/CommonModal/CommonModal";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import moment from "moment";
import "./sub.css";
import Swal from "sweetalert2";
import services from "../../../../Services";
import { getPolicySubCategoryData } from "../../../../Redux/Actions/PolicyCategoryAction";
import { useDispatch } from "react-redux";
import CharLeftField from "../../../Common/CharLeftField/CharLeftField";
import CommonLabel from "../../../Common/ComonLabel/CommonLabel";
import { getSopSubCategoryData } from "../../../../Redux/Actions/SOPsAction";
function EditSubcategory({ open, handleClose, icon, element, setopen }) {
  const dispatch = useDispatch();

  const [subcategoryName, setsubcategoryName] = useState("");
  const [subcategoryDescription, setsubcategoryDescription] = useState("");
  const [error, setError] = useState({
    subcategoryNameError: "",
    subcategoryDescriptionError: "",
  });
  const [refresh, setrefresh] = useState(false);
  const [isClosed, setisClosed] = useState(false);
  const [openPolicyModel, setopenPolicyModel] = useState(false);
  const [closePolicyModel, setclosePolicyModel] = useState(true);

  useEffect(() => {
    dispatch(getSopSubCategoryData(element.categoryId));
  }, [refresh]);

  const handleAddChange = (e) => {
    const { name, value } = e.target;
    let errorValues = { ...error };
    if (name === "subcategory_name") {
      if (value) {
        errorValues.subcategoryNameError = "";
        setError(errorValues);
        setsubcategoryName(value);
      } else if (value === "") {
        errorValues.subcategoryNameError = "this field is required";
        setError(errorValues);
        setsubcategoryName(value);
      }
    } else if (name === "subcategory_description") {
      if (value) {
        errorValues.subcategoryDescriptionError = "";
        setError(errorValues);
        setsubcategoryDescription(value);
      } else if (value === "") {
        errorValues.subcategoryDescriptionError = "this field is required";
        setError(errorValues);
        setsubcategoryDescription(value);
      }
    }
    //   if(e.target.value === '' ){
    //     let errorValues = {...error}
    //     errorValues.subcategoryNameError ='this field is required'
    // errorValues.subcategoryDescriptionError ='this field is required'
    //     setError(errorValues)

    //   }else {
    //     let errorValues = {...error}
    //     errorValues.subcategoryNameError =''
    //     errorValues.subcategoryDescriptionError =''

    //     if (name === "subcategory_name") {

    //     setsubcategoryName(e.target.value);
    //   }
    //   if (name === "subcategory_description") {
    //     setsubcategoryDescription(e.target.value);
    //   }
    //   setError(errorValues)
    //   }
  };

  const handleSubCategorySubmitApi = async (data) => {
    try {
      const res = await services.api(
        "POST",
        `/ui-assist/v1/sopsubcategory`,
        data
      );
      if (res) {
        Swal.fire({
          position: "center",
          icon: "success",
          title: "subcategory added successfully",
          showConfirmButton: false,
          timer: 1500,
        });

        setrefresh(!refresh);
        setopen(false);
      }
    } catch (err) {
      if (err.response) {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Something went wrong!",

          footer: `${err.message}`,
        });
      }
    }
  };

  const handleSubmit = () => {
    let data = {
      title: subcategoryName,
      description: subcategoryDescription,
      categoryId: element.categoryId,

      employeeId: localStorage.getItem("token"),
    };
    handleSubCategorySubmitApi(data);
  };

  const handleAddPolicy = () => {
    setopenPolicyModel(true);
  };

  const { subcategoryDescriptionError, subcategoryNameError } = error;
  return (
    <>
      <CommonModal
        open={open}
        handleClose={handleClose}
        headerText="Add subcategory"
        icon={icon}
        modalSize="lg"
        submitBtnText="SAVE CHANGES"
        cancleBtnText="BACK"
        disabledSubmitButton={
          !(subcategoryName !== "" && subcategoryDescription !== "")
        }
        isBackBtn={true}
        // header={header}
        // footer={footer}
        fullWidth
        policiesButtonSize
        onButtonClick={() => {
          handleSubmit();
        }}
        onCancleButtonClick={handleClose}
        className="editAddPolicyModal"
      >
        <div style={{ padding: "20px" }}>
          <Grid container spacing={2}>
            <Grid item sm={2}>
              {/* <TextField
                // style = {{padding: '10px'}}
                variant="outlined"
                name="subcategory_name"
                label="Subcategory name"
                onChange={(e) => {
                  handleAddChange(e, "subcategory_name");
                }}
                placeholder="add name"
                errorMsg={categoryNameError? categoryNameError: ''}
              /> */}
              <CharLeftField
                label={<CommonLabel label="Subcategory name" mandatory />}
                handleChange={(e) => {
                  handleAddChange(e, "subcategory_name");
                }}
                //value={formik.values.subTitle}
                // charLeft={subtitleCharLeft}
                name="subcategory_name"
                type="text"
                //charLeft={subtitleCharLeft}
                errorMsg={subcategoryNameError ? subcategoryNameError : ""}
              />
            </Grid>
            <Grid item sm={4}>
              {/*<TextField
                style={{ width: "590px" }}
                variant="outlined"
                name="subcategory_description"
                label="Description"
                onChange={(e, name) => {
                  handleAddChange(e, "subcategory_description");
                }}
                placeholder="add description"
              /> */}
              <CharLeftField
                label={<CommonLabel label="Description" mandatory />}
                handleChange={(e, name) => {
                  handleAddChange(e, "subcategory_description");
                }}
                //value={formik.values.subTitle}
                // charLeft={subtitleCharLeft}
                name="subcategory_description"
                type="text"
                //charLeft={subtitleCharLeft}
                errorMsg={
                  subcategoryDescriptionError ? subcategoryDescriptionError : ""
                }
              />
            </Grid>
          </Grid>
          <div className="AddSubC"></div>
        </div>

        <div
          style={{
            padding: "10px",
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          {/*<p >policies</p>
        <div className="editPoliciesStructure">
        <img
          src={process.env.PUBLIC_URL + "/policies/add-fill.svg"}
          alt="Add"
        />
<<<<<<< Updated upstream
        <span className="editPolicies" onClick = {() => {console.log('aaaaaaaaaaaaaa');}} style={{padding: '10px', cursor: "pointer"}}>Add Policies</span>

        <span className="editPolicies" onClick = {() => {handleAddPolicy()}} style={{padding: '10px', cursor: "pointer"}}>Add Policies</span>
>>>>>>> Stashed changes
       
        </div>*/}
        </div>

        {/*<TableContainer component={Paper}>
      <Table sx={{ minWidth: 650 }} aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell>Sr. No.</TableCell>
            <TableCell align="right">Name</TableCell>
            <TableCell align="right">Description</TableCell>
            <TableCell align="right">Last modified by</TableCell>
            <TableCell align="right">Modified on</TableCell>
            <TableCell align="right">action</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {policyTableData.length > 0 && policyTableData.map((row, index) => (
            <TableRow
              key={row.policyId}
              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            >
              <TableCell component="th" scope="row">
                {row.srNo}
              </TableCell>
              <TableCell align="right">{row.policyName}</TableCell>
              <TableCell align="right">{row.description}</TableCell>
              <TableCell align="right">{row.modifiedBy}</TableCell>
              <TableCell align="right">{moment(row.updatedAt).format('DD/MM/YYYY')}</TableCell>
              <TableCell align="right"><EditIcon /> <DeleteIcon onClick = {console.log('dekete')} /></TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
          </TableContainer> */}
      </CommonModal>
    </>
  );
}

export default EditSubcategory;
