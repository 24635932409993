import {
    Grid,
    Typography,
    Box,
    CircularProgress,
    Tabs,
    Tab,
} from "@material-ui/core";

import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import CommonLabel from "../../Common/ComonLabel/CommonLabel";
import textFieldStyle from "../../Common/Styles/TextFieldStyle";
import "./AddNewUserGroupComponent.css";
import CommonAutocomplete from "../../Common/CommonAutocomplete/CommonAutocomplete";
import Loader from "../../Common/Loader/Loader";
import Swal from "sweetalert2";
import services from "../../../Services";
import CustomMultiSelect from "../../Common/CustomMultiSelect/CustomMultiSelect";
import Template from "../../Common/Template/Template";
import SubmitButton from "../../Common/Button/SubmitButton";
import uploadIcon from "../../../Assets/Images/collectiveIcons/upload-2-line.svg";
import CommonModal from "../../../Components/Common/CommonModal/CommonModal";
import CharLeftField from "../../Common/CharLeftField/CharLeftField";
import {
    WORK,
    ROLE_BASED,
    MANAGE_ACCESS
  } from "../../../Routes/constant";
  import {useHistory} from "react-router-dom";
  import { CSVLink } from "react-csv";
//upload-2-line.svg

//tab handeling functionlity.....
function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box p={3}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}
TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
};

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        "aria-controls": `simple-tabpanel-${index}`,
    };
}


export default function EditUserGroupComponent({ updateUserGroupData, setupdateUserGroupData }) {
    const history = useHistory();
//fetch id from the url....
const queryParams = new URLSearchParams(window.location.search);
const id = queryParams.get("id");
const regex =/%20/gm
const groupNameURL = (window.location.pathname.split('/')[4]).replace(regex, ' ');


//get initial values for the user group data....
useEffect(() => {
    (async() => {
        try {
           let res = await services.api('GET', 
           `/ui-assist/v1/rollmatrix/usergroup/editusergroup/${id}`) 
           if(res.status === 200){
               console.log(res, '@res..')
               setSelectedSectorOptions(res?.data?.sectorCode)
               setSelectedBusinessUnitOptions(res?.data?.buCode)
               setSelectedDivisionOptions(res?.data?.divisionCode)
               setSelectedEmployeeGradeOptions(res?.data?.employeegradeCode)
               setSelectedEmployeeTypeOptions(res?.data?.employeetypeCode)
               setSelectedFunctionOptions(res?.data?.functionId)
               setSelectedLocationOptions(res?.data?.locationId)
               setSelectedPaOptions(res?.data?.paCode)
               setselectedEmployeeOptions(res?.data?.employeeCode)
               setGroupName(groupNameURL)
           }
        } catch (error) {
            console.log(error.message, 'error')
        }
    })();
}, []);

    const [open, setOpen] = useState(false);
    const [isDisabled, setisDisabled] = useState(false);
    const [errorName, seterrorName] = useState('');
    const focusStyle = { color: "#db233a" };
    const classes = textFieldStyle();
    const [value, setValue] = React.useState(0);
    const [focus, setFocus] = React.useState(false);
    const [loading, setLoading] = useState(false);
    const [sectorData, setSectorData] = useState([]);
    const [sectorListData, setSectorListData] = useState([]);
    const [EmployeeListDataCount, setEmployeeListDataCount] = useState("");

    const [selectedSectorData, setSelectedSectorData] = useState(sectorData);
    const [businessUnitData, setBusinessUnitData] = useState([]);
    const [selectedBusinessUnitOptions, setSelectedBusinessUnitOptions] = useState(businessUnitData);
    const [divisionData, setDivisionData] = useState([]);
    const [divisionListData, setDivisionListData] = useState([]);
    const [paListData, setPaListData] = useState([]);
    const [locationListData, setLocationListData] = useState([]);
    const [functionListData, setFunctionListData] = useState([]);

    const [selectedDivisionOptions, setSelectedDivisionOptions] = useState(divisionData);
    const [paData, setPaData] = useState([]);
    const [selectedPaOptions, setSelectedPaOptions] = useState(paData);
    const [locationData, setLocationData] = useState([]);
    const [selectedLocationOptions, setSelectedLocationOptions] = useState(locationData);
    const [functionData, setFunctionData] = useState([]);
    const [selectedFunctionOptions, setSelectedFunctionOptions] = useState([]);

    //HANDLE EMPLOYEE STATES.
    const [employeeTypeData, setEmployeeTypeData] = useState([]);
    const [selectedEmployeeTypeOptions, setSelectedEmployeeTypeOptions] = useState([]);
    const [employeeTypeListData, setEmployeeTypeListData] = useState([]);
    const [employeeListData3, setemployeeListData3] = useState("");
    //HANDLE EMPLOYEE GRADE STATES.
    const [employeeGradeData, setEmployeeGradeData] = useState([]);
    const [selectedEmployeeGradeOptions, setSelectedEmployeeGradeOptions] = useState([]);
    const [employeeGradeListData, setEmployeeGradeListData] = useState([]);

    const [getDivisionList, setGetDivisionList] = useState([]);
    const [buisnessUnitList, setBuisnessUnitList] = useState([]);
    const [employeeListData2, setemployeeListData2] = useState([]);

    const [psaListData, setPsaListData] = useState([]);
    const [gradeListData, setGradeListData] = useState([]);
    const [employeeListData, setEmployeeListData] = useState([]);
    const [selectedEmployeeOptions, setselectedEmployeeOptions] = useState(employeeListData);

    const [innerLoading, setInnerLoading] = useState(false);
    const [totalEmployees, setTotalEmployees] = useState("");
    const [groupName, setGroupName] = useState("");

    //exclusion list data.
    const [sectorListExcluededData, setSectorListExcluededData] = useState([]);
    const [buListExcluededData, setBuListExcluededData] = useState([]);
    const [divsionListExcluededData, setDivsionListExcluededData] = useState([]);
    const [paListExcluededData, setPaListExcluededData] = useState([]);
    const [locationListExcluededData, setLocationListExcluededData] = useState([]);
    const [functionListExcluededData, setFunctionListExcluededData] = useState([]);
    const [employeeTypeListExcluededData, setEmployeeTypeListExcluededData] = useState([]);
    const [employeeGradeListExcluededData, setGradeTypeListExcluededData] = useState([]);
    const [employeeListExcluededData, setEmployeeListExcluededData] = useState([]);

    //categoryType handler.
    const [selectedCategoryType, setselectedCategoryType] = useState([]);
    
    

   
    const [refresh, setrefresh] = useState(false);

    //updating states
    const [employeetypecode, setEmployeetypecode] = useState([]);
    const [employeegradecode, setEmployeegradecode] = useState("");
    const [employeecode, setEmployeecode] = useState("");
    const [functionid, setFunctionid] = useState("");
    const [sectorCode, setSectorCode] = useState("");
    const [buCode, setBuCode] = useState("");
    const [divisionCode, setDivisionCode] = useState("");
    const [compcode, setCompcode] = useState("");
    const [locationid, setLocationid] = useState("");

    // console.log(sectorCode, "sectorCode state");
    // console.log(buCode, "buCode state");
    // console.log(compcode, "compcode state"); //pa, personal area, compcode, are same
    // console.log(locationid, "locationid state");
    // console.log(divisionCode, "divisioncode state");
    // console.log(functionid, "functionCode state");
    // console.log(employeetypecode, "employeetypecode state");
    // console.log(employeegradecode, "employeegradecode state");
    // console.log(employeecode, "employeecode state");

    //handle exclude state.
    const [employeetypecodeExclude, setEmployeetypecodeExclude] = useState("");
    const [employeegradecodeExclude, setEmployeegradecodeExclude] = useState("");
    const [employeecodeExclude, setEmployeecodeExclude] = useState("");
    const [functionidExclude, setFunctionidExclude] = useState("");
    const [sectorCodeExclude, setSectorCodeExclude] = useState("");
    const [buCodeExclude, setBuCodeExclude] = useState("");
    const [divisionCodeExclude, setDivisionCodeExclude] = useState("");
    const [compcodeExclude, setCompcodeExclude] = useState("");
    const [locationidExclude, setLocationidExclude] = useState("");

    //disable options.
    //const [buDisabled, setBuDisabled] = useState(false);

    //export
    // console.log(locationidExclude, "locationid state exclusion");
    // console.log(divisionCodeExclude, "divisioncode state exclusion");
    // console.log(functionidExclude, "functionid state exclusion");
    // console.log(employeecodeExclude, "employeecodeExclude state exclusion");
    // console.log(compcodeExclude, "compcodeExclude state exclusion");
    // console.log(buCodeExclude, "buCodeExclude state exclusion");
    // console.log(sectorCodeExclude, "sectorCodeExclude state exclusion");
    // console.log(
    //   employeegradecodeExclude,
    //   "employeegradecodeExclude state exclusion"
    // );
    // console.log(
    //   employeetypecodeExclude,
    //   "employeetypecodeExclude state exclusion"
    // );

//............................csv file upload................................//
const headCellCsv = [
    { label: "invalid employee id's", key: "invalidIDR" }
   
  ];

  const handleCancel = () => { 
    history.push(`${WORK}${ROLE_BASED}${MANAGE_ACCESS}`);
   }


    //select handler
    const handleMenuSelectChange = (e, selectType) => {
        setselectedCategoryType(selectType.title);
    };

    //default values for sector.
    const [selectedSectorOptions, setSelectedSectorOptions] = useState([]);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };
    //to get selected sector list.
    useEffect(() => {
        let dataSectorList = selectedSectorOptions?.map((el) => {
            return el.value;
        });

        setSectorListData(dataSectorList);
    }, [selectedSectorOptions]);

    //to get selected bu list.
    useEffect(() => {
        let dataBuList = selectedBusinessUnitOptions?.map((el) => {
            return el.value;
        });
        setBuisnessUnitList(dataBuList);
    }, [selectedBusinessUnitOptions]);

    //to get selected division list.
    useEffect(() => {
        let dataDivisionList = selectedDivisionOptions?.map((el) => {
            return el.value;
        });
        setDivisionListData(dataDivisionList);
    }, [selectedDivisionOptions]);

    //to get selected pa list.
    useEffect(() => {
        let datapaList = selectedPaOptions?.map((el) => {
            return el.value;
        });
        setPaListData(datapaList);
    }, [selectedPaOptions]);

    //to get selected location list.
    useEffect(() => {
        let dataLocationList = selectedLocationOptions?.map((el) => {
            return el.value;
        });
        setLocationListData(dataLocationList);
    }, [selectedLocationOptions]);

    //to get selected function list.
    useEffect(() => {
        let dataFunctionList = selectedFunctionOptions?.map((el) => {
            return el.value;
        });
        setFunctionListData(dataFunctionList);
    }, [selectedFunctionOptions]);

    //to get selected grade list.
    useEffect(() => {
        let dataGradeList = selectedEmployeeGradeOptions?.map((el) => {
            return el.value;
        });
        setEmployeeGradeListData(dataGradeList);
    }, [selectedEmployeeGradeOptions]);

    useEffect(() => {
        let dataEmployeeList = selectedEmployeeOptions?.map((el) => {
            return el.value;
        });
        setemployeeListData2(dataEmployeeList);
        setemployeeListData3(dataEmployeeList?.toString())
    }, [selectedEmployeeOptions]);

    // console.log(sectorListData, 'sectorListData...')
    // console.log(buisnessUnitList, 'buisnessUnitList...')
    // console.log(divisionListData, 'divisionListData...')
    // console.log(paListData, 'paListData...')
    // console.log(locationListData, 'locationListData...')
    // console.log(employeeTypeListData, 'employeeTypeListData...')
    // console.log(employeeGradeListData, 'employeeGradeListData...')
    // console.log(functionListData, 'functionListData...')

    //default values for business Unit.
    //category type dropdonw.
    const categoryType = [
        { title: "Inclusion", value: "123" },
        { title: "Exclusion", value: "321" },
        { title: "Add More", value: "999" },
    
        
    ];

    const getSelectedSectorOptionsString = (selectedOptions) => {
        const xyz = selectedOptions.map((el) => {
            return {
                addsectorId: el.value,
            };
        });
        getBusinessUnitBySector(xyz);
        getPaBySector(xyz);
        //console.log(xyz, 'result!!')
    };

    const getOptionLabel = (option) => `${option.title}`;

    const getOptionDisabled = (option) => option.value === "foo";

    //handle toggle for all the dropdowns
    const handleToggleOption = (selectedOptions, name) => {
        if (name === "sector") {
            setSelectedSectorOptions(selectedOptions);
            getSelectedSectorOptionsString(selectedOptions);
        } else if (name === "bu") {
            setSelectedBusinessUnitOptions(selectedOptions);

            let divisionList = selectedOptions.map((result) => {
                return {
                    addBuId: result.value,
                };
            });

            getDivisionByBusinessUnit(divisionList);
        } else if (name === "division") {
            setSelectedDivisionOptions(selectedOptions);
        } else if (name === "pa") {
            setSelectedPaOptions(selectedOptions);
            let persaList = selectedOptions.map((result) => {
                return {
                    addpersa: result.value,
                };
            });
            getLocationByPa(persaList);
        } else if (name === "location") {
            setSelectedLocationOptions(selectedOptions);
        } else if (name === "function") {
            setSelectedFunctionOptions(selectedOptions);
        } else if (name === "employeeType") {
            setSelectedEmployeeTypeOptions(selectedOptions);
            getEmployeeTypeList(selectedOptions);
        } else if (name === "employeeGrade") {
            setSelectedEmployeeGradeOptions(selectedOptions);
        } else if (name === "token") {
            setselectedEmployeeOptions(selectedOptions);
        }
    };

    //handle clear for all the dropdown's
    const handleClearOptions = (isSelected, name) => {
        if (name === "sector") {
            setSelectedSectorOptions([]);
            setSelectedBusinessUnitOptions([]);
            setSelectedDivisionOptions([]);
            setSelectedPaOptions([]);
            setSelectedLocationOptions([]);
            setBusinessUnitData([]);
            setDivisionData([]);
            setPaData([]);
            setLocationData([]);
        } else if (name === "bu") {
            setSelectedBusinessUnitOptions([]);
            //setSelectedSectorOptions([]);
            // setBusinessUnitData([]);
            setSelectedDivisionOptions([]);
            setDivisionData([]);
        } else if (name === "division") {
            // setSelectedBusinessUnitOptions([]);
            // setSelectedSectorOptions([]);
            // setBusinessUnitData([]);
            setSelectedDivisionOptions([]);
            // setDivisionData([]);
        } else if (name === "pa") {
            setSelectedPaOptions([]);
            setSelectedLocationOptions([]);
            setLocationData([]);
        } else if (name === "location") {
            setSelectedLocationOptions([]);
            //setSelectedPaOptions([]);
        } else if (name === "function") {
            setSelectedFunctionOptions([]);
        } else if (name === "employeeType") {
            setSelectedEmployeeTypeOptions([]);
            setEmployeeTypeListData([]);
        } else if (name === "employeeGrade") {
            setSelectedEmployeeGradeOptions([]);
            //setSelectedEmployeeTypeOptions([])
        } else if (name === "token") {
            setselectedEmployeeOptions([]);
        }
    };

    //handle select all for dropdown's.
    const handleSelectAll = (isSelected, name) => {
        if (isSelected) {
            if (name === "sector") {
                setSelectedSectorOptions(sectorData);

                getSelectedSectorOptionsString(sectorData);
            } else if (name === "bu") {
                setSelectedBusinessUnitOptions(businessUnitData);
                let divisionList = businessUnitData.map((result) => {
                    return {
                        addBuId: result.value,
                    };
                });

                getDivisionByBusinessUnit(divisionList);
            } else if (name === "division") {
                setSelectedDivisionOptions(divisionData);
            } else if (name === "pa") {
                setSelectedPaOptions(paData);
                let data = paData.map((el) => {
                    return { addpersa: el.value };
                });
                getLocationByPa(data);
            } else if (name === "location") {
                setSelectedLocationOptions(locationData);
            } else if (name === "function") {
                setSelectedFunctionOptions(functionData);
            } else if (name === "employeeType") {
                setSelectedEmployeeTypeOptions(employeeTypeData);
                getEmployeeTypeList(employeeTypeData);
            } else if (name === "employeeGrade") {
                setSelectedEmployeeGradeOptions(employeeGradeData);
            } else if (name === "token") {
                setselectedEmployeeOptions(employeeListData);
            }
        } else {
            handleClearOptions();
        }
    };

    

 

    //close the modal window
    const handleClose = () => {
        setOpen(false);
        setOpenCsv(false)
    };

    //to get all sectors for inclusion
    useEffect(() => {
        (async () => {
            try {
                setLoading(true);
                const res = await services.api("GET", `/ui-assist/v1/rollmatrix/usergroup/allsector`);
                if (res.status === 200) {
                    setLoading(false);
                    const allSectorItem = res?.data?.map((el) => {
                        return {
                            title: el.sectorName,
                            value: el.id,
                        };
                    });
                    const allSectorListItem = res?.data?.map((el) => {
                        return el.id;
                    });
                    setSectorData(allSectorItem);
                    // setSectorListData(allSectorListItem.join(',').toString())
                }
            } catch (err) {
                setLoading(false);
                Swal.fire("Oops!", `${err.response.data}`, "error");
                setSectorData([]);
            }
        })();
    }, []);

    //get all the emplyoee grade.
    useEffect(() => {
        (async () => {
            try {
                setLoading(true);
                const res = await services.api("GET", `/ui-assist/v1/rollmatrix/usergroup/allemployeegrade`);

                if (res.status === 200) {
                    setLoading(false);
                    const allGradeItem = res?.data?.map((el) => {
                        return {
                            title: el.employeegrade,
                            value: el.employeegradecode,
                        };
                    });

                    setEmployeeGradeData(allGradeItem);
                    // setSectorListData(allSectorListItem.join(',').toString())
                }
            } catch (err) {
                setLoading(false);
                Swal.fire("Oops!", `${err.response.data}`, "error");
                setSectorData([]);
            }
        })();
    }, []);

    //to get all functions for inclusion and exclusion.
    useEffect(() => {
        (async () => {
            try {
                setLoading(true);
                const res = await services.api("GET", `/ui-assist/v1/rollmatrix/usergroup/allfunction`);
                if (res.status === 200) {
                    setLoading(false);
                    const functionItem = res?.data?.map((el) => {
                        return {
                            title: el.businessFunctionName,
                            value: el.id,
                        };
                    });
                    setFunctionData(functionItem);
                }
            } catch (err) {
                setLoading(false);
                Swal.fire("Oops!", `${err.response.data}`, "error");
                setFunctionData([]);
            }
        })();
    }, []);

    //get all the division on dependent params.
    const getDivisionByBusinessUnit = async (data) => {
        try {
            setLoading(true);
            const res = await services.api("POST", `/ui-assist/v1/rollmatrix/usergroup/division`, data);
            if (res.status === 200) {
                setLoading(false);

                let AllDivisionList = res.data.map((el) => {
                    return {
                        title: el.divisionName,
                        value: el.id,
                    };
                });

                setDivisionData(AllDivisionList);
                //setSelectedDivisionOptions(AllDivisionList);
            }
        } catch (err) {
            setLoading(false);
            Swal.fire("Oops!", `${err.response.data}`, "error");
            setDivisionData([]);
        }
    };

    //get location by pa
    const getLocationByPa = async (data) => {
        try {
            setLoading(true);
            const res = await services.api("POST", `/ui-assist/v1/rollmatrix/usergroup/location`, data);
            if (res.status === 200) {
                setLoading(false);

                let AllLocationList = res?.data?.map((el) => {
                    return {
                        title: el.psaText,
                        value: el.locationid,
                    };
                });

                setLocationData(AllLocationList);
                // setSelectedLocationOptions(AllLocationList);
            }
        } catch (err) {
            setLoading(false);
            Swal.fire("Oops!", `${err.response.data}`, "error");
        }
    };

    //to get all employee type for inclusion and exclusion.
    useEffect(() => {
        (async () => {
            try {
                setLoading(true);
                const res = await services.api("GET", `/ui-assist/v1/rollmatrix/usergroup/allemployeetype`);
                if (res.status === 200) {
                    setLoading(false);
                    const EmployeeTypeItem = res?.data?.map((el) => {
                        return {
                            title: el.employeetype,
                            value: el.employeetypecode,
                        };
                    });
                    setEmployeeTypeData(EmployeeTypeItem);
                }
            } catch (err) {
                setLoading(false);
                Swal.fire("Oops!", `${err}`, "error");
                setEmployeeTypeData([]);
            }
        })();
    }, []);

   

    //handle change
    const handleNameChange = (e) => {
        if(e.target.value === ''){
            seterrorName('this field is required!')
            setisDisabled(false)
        }else{
            seterrorName('')
            setisDisabled(true)
        }
        setGroupName(e.target.value);
    };

    //get business unit on the sector params.
    const getBusinessUnitBySector = async (data) => {
        try {
            setLoading(true);
            let res = await services.api("POST", `/ui-assist/v1/rollmatrix/usergroup/businessunit`, data);
            if (res.status === 200) {
                setLoading(false);
                let list = res.data.map((result) => {
                    return {
                        title: result.buName,
                        value: result.id,
                    };
                });
                let divisionList = res.data.map((result) => {
                    return {
                        addBuId: result.id,
                    };
                });
                //setSelectedBusinessUnitOptions(list);
                setBusinessUnitData(list);
                //getDivisionByBusinessUnit(divisionList);
            }
        } catch (err) {
            setLoading(false);
            Swal.fire("Oops!", `${err.response.data}`, "error");
        }
    };

    //get pa by sector.
    const getPaBySector = async (data) => {
        try {
            setLoading(true);
            let res = await services.api("POST", `/ui-assist/v1/rollmatrix/usergroup/pa`, data);
            if (res.status === 200) {
                setLoading(false);
                let list = res?.data?.map((result) => {
                    return {
                        title: result.persaText,
                        value: result.persaId,
                    };
                });

                let persaList = res?.data?.map((result) => {
                    return {
                        addpersa: result.persaId,
                    };
                });

                //getLocationByPa(persaList);

                setPaData(list);
                // setSelectedPaOptions(list);
            }
        } catch (err) {
            setLoading(false);
            // Swal.fire("Oops!", `${err.response.data}`, "error");
            // setPaData([]);
            console.log(err, "error@@");
        }
    };

const [uploadCSV, setUploadCSV] = useState(null);
const [employeeDataForFilter, setEmployeeDataForFilter] = useState('');
const [typeForCsv, setTypeForCsv] = useState("");
const [csvImportedDefaultValues, SetCsvImportedDefaultValues] = useState([]);
const [exportcsvfile, setExportCsvFile] = useState(null);
const [validID, setValidID] = useState([]);
const [invalidID, setInvalidID] = useState([]);

let csvData = invalidID
  //get filtered employee list by csv dowonload...
const getFilteredRecordFromCSV = async(data) => {
try {
  let res = await services.api('POST', '/ui-assist/v1/rollmatrix/usergroup/uploadfile', data);
  if(res.status === 200){
    
    setselectedEmployeeOptions(res.data.employee)

    let obj = res?.data?.invalid_id?.map(el => {
       return {invalidIDR: el}
    })
    setInvalidID(obj)
   
  }
} catch (error) {
  console.log(error, 'error>>>>>>>>>>>>>')
}
  
}

const [filteredEmployeeData, setFilteredEmployeeData] = useState([]);
useEffect(() => {
 let result = employeeListData.map(el => {
    return el.value
  })
  setFilteredEmployeeData(result);
}, [employeeListData]);

const [openCsv, setOpenCsv] = useState(false);
const handleCheck = () => {
  const formdata = new FormData();
  formdata.append("documentFile", exportcsvfile)
  formdata.append("type", selectedCategoryType)
  formdata.append("employeeid", filteredEmployeeData)
  formdata.append('employeeSelected',employeeListData3)
  getFilteredRecordFromCSV(formdata)
  setOpenCsv(true)
}


const handleCsvUploadChange = (e) => {
  console.log(e, 'event inside here')
  
}


//handle csv.
const handleCsv = (e) => {
  setExportCsvFile(e.target.files[0]);
};


const handleTemplateDownload = () => {
    window.open('https://newmrisedev.blob.core.windows.net/mrisestorage/1642677149817_NewEmployeecsv.csv', "_blank");
}


  


    //get EmployeeTypeListDaata
    const getEmployeeTypeList = (params) => {
        let res = params.map((el) => {
            return el.value;
        });

        setEmployeeTypeListData(res);
    };

    //submit user group api.
    const submitUserGroupApi = async (data) => {
        setLoading(true);
        try {
            const res = await services.api("PUT", `/ui-assist/v1/rollmatrix/usergroup/${id}`, data);
            if (res.status === 200) {
                setLoading(false);
                Swal.fire({
                    icon: "success",
                    title: "Success",
                    text: "User Group updated Successfully.",
                });
                //setupdateUserGroupData(!updateUserGroupData);
                history.push(`${WORK}${ROLE_BASED}${MANAGE_ACCESS}`)
            }
        } catch (err) {
            setLoading(false);
            Swal.fire("Oops!", `${err.response.data}`, "error");
        }
    };

    //submit userGroup
    const handleSubmitUserGroup = (params) => {
        let data = {
            employeeId: localStorage.getItem("token"),

            groupName: groupName,

            sectorCode: sectorListData,
            buCode: buisnessUnitList,
            divisionCode: divisionListData,

            paCode: paListData,

            locationId: locationListData,
            functionId: functionListData,
            emptypeCode: employeeTypeListData,
            employeegradeCode: employeeGradeListData,
            employeeCode: employeeListData2,
        };

        submitUserGroupApi(data);
    };

    //call the all employee api.
    const getEmployeeDataValidate = async () => {
        setLoading(true);

        let data = {
            emptypeCode: employeeTypeListData,

            employeegradeCode: employeeGradeListData,

            functionId: functionListData,

            sectorCode: sectorListData,

            buCode: buisnessUnitList,

            divisionCode: divisionListData,

            paCode: paListData,

            locationId: locationListData,
        };

        try {
            const res = await services.api("POST", `/ui-assist/v1/rollmatrix/usergroup/allemployee`, data);
            if (res.status === 200) {
                setLoading(false);
                let list = res.data.map((result) => {
                    return {
                        title: `${result.firstname} ${result.lastname} (${result.employeeid})`,
                        value: result.employeeid,
                    };
                });
                setEmployeeListData(list);
                setEmployeeListDataCount(list.length);
                //setselectedEmployeeOptions(list);
            }
        } catch (err) {
            setLoading(false);
            Swal.fire("Oops!", `${err.response.data}`, "error");
            setEmployeeListData([]);
        }
    };

    //get no of users.
    const getNoOfUsersValidate = async (params) => {
        setInnerLoading(true);
        try {
            let data = {
                emptypeCode: employeeTypeListData,

                employeegradeCode: employeeGradeListData,

                employeeCode: employeeListData2,

                functionId: functionListData,

                sectorCode: sectorListData,

                buCode: buisnessUnitList,

                divisionCode: divisionListData,

                paCode: paListData,

                locationId: locationListData,
            };

            const res = await services.api(
                "POST",
                `/ui-assist/v1/rollmatrix/usergroup/employeetype/noofusers`,
                data
            );
            if (res.status === 200) {
                setInnerLoading(false);
                setTotalEmployees(res?.data.length);
                if(res?.data?.length > 0) {
                    setisDisabled(true);
                }else{
                    setisDisabled(false)
                }
            }
        } catch (error) {
            console.log(error.response);
        }
    };

    const handleValidate = (params) => {
        getEmployeeDataValidate();
        getNoOfUsersValidate();
        
    };

    const handleChangeCsvInside = () => {
        setOpen(true);
    };

    return (
        <Template>
            <Grid container direction="row" justifyContent="space-between" alignItems="center">
                <Grid item md={7} xs={12}>
                    <p className="offCHeaderTitle">Update usergroup</p>
                </Grid>
            </Grid>
            <div>
                <div className="leftRightAlign">
                    {loading ? <Loader /> : null}
                    <Grid container direction="row">
          
                        <Grid item sm={12} xs={12}>
                            <div className="uploadBtn">
                                <div className="displayBlockUserDetails alignMCAdmin floatRightAlign">
                                    <p className="detailDivp">
                                        NO OF PEOPLE:
                                        {totalEmployees ? (
                                            `${totalEmployees}`
                                        ) :"0"}
                                    </p>
                                    <p className="detailsFontSize floatRightAlign">-</p>
                                </div>
                            </div>
                        </Grid>
                    </Grid>
                </div>

                <Grid container direction="row" spacing={3}>
                    <Grid item sm={5} className="rollBasedAutoCompplete">
                        <span className="rollBasedPaperInnnerContentLabel">User group</span>

                   
                        <CharLeftField
                label={<CommonLabel label="User group" mandatory />}
                handleChange={handleNameChange}
                name="user_gruop_name"
                type="text"
                value = {groupName}
                errorMsg={errorName ? errorName : ""}
              />
                    </Grid>
                </Grid>

                <div className=" mt24">
                    <div className="rollMatrixTabContent">
                        <Tabs value={value} onChange={handleChange} aria-label="simple tabs example">
                            <Tab label="Category inclusion/exclusion" {...a11yProps(0)} />
                           {/* <Tab label="Bulk Upload" {...a11yProps(1)} /> */}
                        </Tabs>
                        <hr className="deviderP" />
                        <TabPanel value={value} index={0}>
                            <div className=" pt0">
                                <Grid container direction="row">
                                    <Grid
                                        container
                                        direction="row"
                                        alignItems="center"
                                        spacing={3}
                                        className="pt8"
                                    >
                                        <Grid item sm={4} className="rollBasedAutoCompplete">
                                            <span className="rollBasedPaperInnnerContentLabel">Sector</span>
                                            <CustomMultiSelect
                                                items={sectorData}
                                                getOptionLabel={getOptionLabel}
                                                getOptionDisabled={getOptionDisabled}
                                                selectedValues={selectedSectorOptions}
                                                placeholder="Search sector"
                                                selectAllLabel="Select all"
                                                onToggleOption={(isSelected) => {
                                                    handleToggleOption(isSelected, "sector");
                                                }}
                                                onClearOptions={(isSelected) => {
                                                    handleClearOptions(isSelected, "sector");
                                                }}
                                                onSelectAll={(isSelected) => {
                                                    handleSelectAll(isSelected, "sector");
                                                }}
                                            />
                                        </Grid>
                                        <Grid item sm={4} className="rollBasedAutoCompplete">
                                            <span className="rollBasedPaperInnnerContentLabel">BU</span>
                                            <CustomMultiSelect
                                                items={businessUnitData}
                                                getOptionLabel={getOptionLabel}
                                                getOptionDisabled={getOptionDisabled}
                                                selectedValues={selectedBusinessUnitOptions}
                                                placeholder="Search business unit"
                                                selectAllLabel="Select all"
                                                onToggleOption={(isSelected) => {
                                                    handleToggleOption(isSelected, "bu");
                                                }}
                                                onClearOptions={(isSelected) => {
                                                    handleClearOptions(isSelected, "bu");
                                                }}
                                                onSelectAll={(isSelected) => {
                                                    handleSelectAll(isSelected, "bu");
                                                }}
                                            />
                                        </Grid>
                                        <Grid item sm={4} className="rollBasedAutoCompplete">
                                            <span className="rollBasedPaperInnnerContentLabel">Division</span>
                                            <CustomMultiSelect
                                                items={divisionData}
                                                getOptionLabel={getOptionLabel}
                                                getOptionDisabled={getOptionDisabled}
                                                selectedValues={selectedDivisionOptions}
                                                placeholder="Search division"
                                                selectAllLabel="Select all"
                                                onToggleOption={(isSelected) => {
                                                    handleToggleOption(isSelected, "division");
                                                }}
                                                onClearOptions={(isSelected) => {
                                                    handleClearOptions(isSelected, "division");
                                                }}
                                                onSelectAll={(isSelected) => {
                                                    handleSelectAll(isSelected, "division");
                                                }}
                                            />
                                        </Grid>
                                    </Grid>
                                    <Grid container direction="row" alignItems="center" spacing={3}></Grid>
                                    <Grid
                                        container
                                        direction="row"
                                        alignItems="center"
                                        spacing={3}
                                        className="pt8"
                                    >
                                        <Grid item sm={4} className="rollBasedAutoCompplete">
                                            <span className="rollBasedPaperInnnerContentLabel">
                                                PA(Personal Inclusion)
                                            </span>
                                            <CustomMultiSelect
                                                items={paData}
                                                getOptionLabel={getOptionLabel}
                                                getOptionDisabled={getOptionDisabled}
                                                selectedValues={selectedPaOptions}
                                                placeholder="Search P.A."
                                                selectAllLabel="Select all"
                                                onToggleOption={(isSelected) => {
                                                    handleToggleOption(isSelected, "pa");
                                                }}
                                                onClearOptions={(isSelected) => {
                                                    handleClearOptions(isSelected, "pa");
                                                }}
                                                onSelectAll={(isSelected) => {
                                                    handleSelectAll(isSelected, "pa");
                                                }}
                                            />
                                        </Grid>
                                        <Grid item sm={4} className="rollBasedAutoCompplete">
                                            <span className="rollBasedPaperInnnerContentLabel">
                                                Location(Personal Inclusion)
                                            </span>
                                            <CustomMultiSelect
                                                items={locationData}
                                                getOptionLabel={getOptionLabel}
                                                getOptionDisabled={getOptionDisabled}
                                                selectedValues={selectedLocationOptions}
                                                placeholder="Search P.S.A"
                                                selectAllLabel="Select all"
                                                onToggleOption={(isSelected) => {
                                                    handleToggleOption(isSelected, "location");
                                                }}
                                                onClearOptions={(isSelected) => {
                                                    handleClearOptions(isSelected, "location");
                                                }}
                                                onSelectAll={(isSelected) => {
                                                    handleSelectAll(isSelected, "location");
                                                }}
                                            />
                                        </Grid>
                                        <Grid item sm={4} className="rollBasedAutoCompplete">
                                            <span className="rollBasedPaperInnnerContentLabel">Function</span>
                                            <CustomMultiSelect
                                                items={functionData}
                                                getOptionLabel={getOptionLabel}
                                                getOptionDisabled={getOptionDisabled}
                                                selectedValues={selectedFunctionOptions}
                                                placeholder="Search Function"
                                                selectAllLabel="Select all"
                                                onToggleOption={(isSelected) => {
                                                    handleToggleOption(isSelected, "function");
                                                }}
                                                onClearOptions={(isSelected) => {
                                                    handleClearOptions(isSelected, "function");
                                                }}
                                                onSelectAll={(isSelected) => {
                                                    handleSelectAll(isSelected, "function");
                                                }}
                                            />
                                        </Grid>
                                    </Grid>
                                    <Grid
                                        container
                                        direction="row"
                                        alignItems="center"
                                        spacing={3}
                                        className="pt8"
                                    >
                                        <Grid item sm={4} className="rollBasedAutoCompplete">
                                            <span className="rollBasedPaperInnnerContentLabel">
                                                Employee Type
                                            </span>
                                            <CustomMultiSelect
                                                items={employeeTypeData}
                                                getOptionLabel={getOptionLabel}
                                                getOptionDisabled={getOptionDisabled}
                                                selectedValues={selectedEmployeeTypeOptions}
                                                placeholder="Search Employee type"
                                                selectAllLabel="Select all"
                                                onToggleOption={(isSelected) => {
                                                    handleToggleOption(isSelected, "employeeType");
                                                }}
                                                onClearOptions={(isSelected) => {
                                                    handleClearOptions(isSelected, "employeeType");
                                                }}
                                                onSelectAll={(isSelected) => {
                                                    handleSelectAll(isSelected, "employeeType");
                                                }}
                                            />
                                        </Grid>
                                        <Grid item sm={4} className="rollBasedAutoCompplete">
                                            <span className="rollBasedPaperInnnerContentLabel">Grade</span>
                                            <CustomMultiSelect
                                                items={employeeGradeData}
                                                getOptionLabel={getOptionLabel}
                                                getOptionDisabled={getOptionDisabled}
                                                selectedValues={selectedEmployeeGradeOptions}
                                                placeholder="Search Employee Grade"
                                                selectAllLabel="Select all"
                                                onToggleOption={(isSelected) => {
                                                    handleToggleOption(isSelected, "employeeGrade");
                                                }}
                                                onClearOptions={(isSelected) => {
                                                    handleClearOptions(isSelected, "employeeGrade");
                                                }}
                                                onSelectAll={(isSelected) => {
                                                    handleSelectAll(isSelected, "employeeGrade");
                                                }}
                                            />
                                        </Grid>
                                        <Grid item sm={4}>
                                            <span className="rollBasedPaperInnnerContentLabel">Token</span>
                                            <CustomMultiSelect
                                                items={employeeListData}
                                                getOptionLabel={getOptionLabel}
                                                getOptionDisabled={getOptionDisabled}
                                                selectedValues={selectedEmployeeOptions}
                                                placeholder="Search Employee token/name"
                                                selectAllLabel="Select all"
                                                onToggleOption={(isSelected) => {
                                                    handleToggleOption(isSelected, "token");
                                                }}
                                                onClearOptions={(isSelected) => {
                                                    handleClearOptions(isSelected, "token");
                                                }}
                                                onSelectAll={(isSelected) => {
                                                    handleSelectAll(isSelected, "token");
                                                }}
                                                
                                                
                                            />
                                        </Grid>
                                    </Grid>
                                    <Grid container direction="row" alignItems="center" justifyContent="flex-end">
                                        <Grid item xs={0}  className="rollMatrixcsvBtn" >
                                            <SubmitButton label="csv" onClick={handleChangeCsvInside}/>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </div>
                        </TabPanel>
                        {/*<TabPanel value={value} index={1}>
                            <div className=" pt0">
                                <Grid container direction="row">
                                    <Grid
                                        container
                                        direction="row"
                                        alignItems="center"
                                        spacing={3}
                                        className="pt8"
                                    >
                                        <Grid item sm={4} className="rollBasedAutoCompplete">
                                            <span className="rollBasedPaperInnnerContentLabel">
                                                Category Inclusion/Exlcusion
                                            </span>
                                            <CommonAutocomplete
                                                handleSelectChange={handleMenuSelectChange}
                                                options={categoryType}
                                                label={<CommonLabel label="category type" mandatory />}
                                            />
                                        </Grid>
                                        <Grid item sm={8}>
                                            <Grid
                                                container
                                                direction="row"
                                                alignItems="center"
                                                spacing={2}
                                                className="rollMatrixColumnGap"
                                            >
                                                <SubmitButton label="Download temp" />
                                                <div className="uploadBtn btnEditSub">
                                                    <label htmlFor="thumb" className="btn uploadBtnLabel"></label>
                                                    <img
                                                        for="thumb"
                                                        src={uploadIcon}
                                                        className="uploadIconStyl"
                                                        alt="Upload Button Icon"
                                                    />
                                                    <input
                                                        id="thumb"
                                                        style={{
                                                            visibility: "hidden",
                                                            padding: "19px 16px",
                                                            position: "absolute",
                                                        }}
                                                        type="file"
                                                        accept=".csv"
                                                        name="pdf"
                                                        onClick={(e) => {
                                                            handleCsv(e);
                                                        }}
                                                    />
                                                </div>
                                                <SubmitButton label="check"  />
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </div>
                                                      </TabPanel> */}
                    </div>
                </div>
                <div>
                    <Grid
                        container
                        direction="row"
                        alignItems="center"
                        spacing={3}
                        className="saveAddNewUserGroup"
                    >
                        <div style={{ padding: "5px" }}>
                            <SubmitButton  onClick={handleValidate} label="VALIDATE" />
                        </div>
                        <SubmitButton disabled = {!isDisabled} onClick={handleSubmitUserGroup} label="SAVE" />
                        <div style={{ padding: "5px" }}>
                        <SubmitButton  onClick={handleCancel} label="CANCEL" />
                        </div>
                    </Grid>
                </div>
            </div>
            <CommonModal
                open={open}
                handleClose={handleClose}
                header={true}
                footer={true}
                className="rollMatrixBulkUploadModal"
                headerText="bulk upload"
                //icon={editIcon}
                modalSize="lg"
                fullWidth
                // submitBtnText="SAVE CHANGES"
                submitBtnText="CLOSE"
                // onButtonClick={() => setSubmitForApproval(false)}
                onButtonClick={handleClose}
                // buttonStyling={true}
            >
                <Grid container direction="row" alignItems="center" spacing={3} className="pt8">
                    <Grid item sm={4} className="rollBasedAutoCompplete">
                        <span className="rollBasedPaperInnnerContentLabel">Category Inclusion/Exlcusion</span>
                        <CommonAutocomplete
                            handleSelectChange={handleMenuSelectChange}
                            options={categoryType}
                            label={<CommonLabel label="category type" mandatory />}
                        />
                    </Grid>
                    <Grid item sm={8}>
                        <Grid
                            container
                            direction="row"
                            alignItems="center"
                            spacing={2}
                            className="rollMatrixColumnGap"
                        >
                            <SubmitButton label="Download temp" onClick = {handleTemplateDownload}/>
                            <div className="uploadBtn btnEditSub">
                                <label for="thumb" className="btn uploadBtnLabel rollDownloadUserGtn"></label>
                                <img
                                    for="thumb"
                                    src={uploadIcon}
                                    
                                    className="uploadIconStyl"
                                    alt="Upload Button Icon"
                                />
                                <input
                                    id="thumb"
                                    style={{
                                        visibility: "hidden",
                                        padding: "19px 16px",
                                        position: "absolute",
                                    }}
                                    type="file"
                                    accept=".csv"
                                    name="pdf"
                                    onChange={(e) => {
                                        handleCsv(e);
                                    }}
                                />
                            </div>
                            <SubmitButton label="check" onClick = {handleCheck} />
                            
                        {openCsv ? <CSVLink
                        filename="invalid_employee_id's.csv"
                        data={csvData}
                        headers={headCellCsv}
                        className="csvRollUserG"
                        >
                        INVALID ID
                        </CSVLink> : ""}
                            </Grid>
                             
                            </Grid>
                            
                            
                </Grid>
            </CommonModal>
        </Template>
    );
}
