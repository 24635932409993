import React, { useState, useEffect } from "react";
import { Paper, Grid } from "@material-ui/core";
import Template from "./../../../Common/Template/Template";
import { useLocation, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getBenefitMasterData, editBenefitMasterData } from "../../../../Redux/Actions/EntitlementBenefitMasterAction";
import SubmitButton from "../../../Common/Button/SubmitButton";
import Swal from "sweetalert2";

import ClubMembership from "./BenefitsMaster/ClubMembership";
import ClubMahindra from "./BenefitsMaster/ClubMahindra";
import CTCVehicle from "./BenefitsMaster/CTCVehicle";
import CreditCard from "./BenefitsMaster/CreditCard";
import MobileHandset from "./BenefitsMaster/MobileHandset";

const EntitlementBenefits = () => {
  const location = useLocation();
  const dispatch = useDispatch();

  let id = useParams()?.id;

  let benefitsList = useSelector(
    (state) => state.getEntitlementBenefitReducer?.allBenefits
  );
  let editedList = useSelector(
    (state) => state.getEntitlementBenefitReducer?.editBenefits
  );

  const [benefitData, setBenefitData] = useState();
  const [formData, setFormData] = useState();
  const [isValid, setIsValid] = useState(false);
  const [isEdit, setIsEdit] = useState(false);

  useEffect(() => {
    dispatch(getBenefitMasterData());
  }, [editedList]);

  useEffect(() => {
    if (benefitsList) {
      benefitsList?.map((val, index) => {
        if (val?.id == id) {
          setBenefitData(val);
        }
      });
    }
  }, [benefitsList]);

  useEffect(() => {
    if(editedList && isEdit)
    {
        Swal.fire({
          title: "Benefit edited successfully.",
          timer: 3000,
          icon: "success"
        });
        setIsEdit(false);
    }
  }, [editedList]);

  const onSubmit = () => {
    console.log("form data =====>", formData);
    dispatch(editBenefitMasterData(formData,id));
    setIsEdit(true);
  };

  //   console.log("current benefit data =====>", benefitData);

  return (
    <Template pageName={""}>
      <div className="submitOfferCampaignContainer">
        <div className="whatsNewHeader ">
          <div className="containerTxt">
            <div className="section-header-text">
              {benefitData?.entitlementType}
            </div>
          </div>
        </div>
        <Paper className="paperContainerOfferCampaign">
          <div className="innerContainersubmitOfferCampaign">
            {id == "62720e2c6bc86099de7d7bb1" ? (
              <ClubMembership
                data={benefitData}
                formData={setFormData}
                isValid={setIsValid}
              />
            ) : id == "62720e2c6bc86099de7d7bb2" ? (
              <ClubMahindra
                data={benefitData}
                formData={setFormData}
                isValid={setIsValid}
              />
            ) : id == "62720e2c6bc86099de7d7bb3" ? (
              <CTCVehicle
                data={benefitData}
                formData={setFormData}
                isValid={setIsValid}
              />
            ) : id == "62720e2c6bc86099de7d7bb4" ? (
              <CreditCard
                data={benefitData}
                formData={setFormData}
                isValid={setIsValid}
              />
            ) : (
              id == "62720e2c6bc86099de7d7bb5" && (
                <MobileHandset
                  data={benefitData}
                  formData={setFormData}
                  isValid={setIsValid}
                />
              )
            )}

            <div className="divContainerOfferCampaign">
              <div className="supportPart">
                <Grid container justifyContent="flex-end" alignItems="center">
                  <div className="SubmitForApprovalButtonDiv">
                    <SubmitButton
                      disabled={!isValid}
                      label="Save Changes"
                      type="submit"
                      variant="contained"
                      className={`${
                        !isValid ? "SubmitForApprovalButton" : "enabledButton"
                      } commonFontNormalStyle`}
                      onClick={onSubmit}
                      size="large"
                    />
                  </div>
                </Grid>
              </div>
            </div>
          </div>
        </Paper>
      </div>
    </Template>
  );
};

export default EntitlementBenefits;
