import * as React from "react";
import Paper from "@material-ui/core/Paper";
import TableCell from "@material-ui/core/TableCell";
import { darken, lighten } from "@material-ui/core/styles/colorManipulator";
import { ViewState, EditingState } from "@devexpress/dx-react-scheduler";
import classNames from "clsx";
import { alpha } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import {
  Scheduler,
  MonthView,
  Appointments,
  Toolbar,
  DateNavigator,
  AppointmentTooltip,
  AppointmentForm,
  EditRecurrenceMenu,
  Resources,
} from "@devexpress/dx-react-scheduler-material-ui";
import { withStyles } from "@material-ui/core/styles";
import "./CommonScheduler.css";
import CommonModal from "../CommonModal/CommonModal";

const owners = [
  {
    text: "AS",
    id: 1,
    color: "rgb(100,181,246)",
  },
  {
    text: "FES",
    id: 2,
    color: "#ffc107",
  },
  {
    text: "GCO",
    id: 3,
    color: "#fd7e14",
  },
];
let baseURL = process.env.REACT_APP_API_BASE_URL;

let CurrentDate = new Date().toLocaleDateString();

// const appointments = [
//   {
//     id: 2,
//     title: 'Title 1',
//     startDate: new Date(2021, 12, 13, 9, 0),
//     endDate: new Date(2021, 12, 13, 24, 30),
//     targetSector: ["AS", "FES",],
//     image: "",
//     requesterName: "Akshay Kumar",
//     requesterSector: "AS",
//     requesterId: 123456,
//     requesterLocation: "MUMBAI",
//     requesterDepartment: "Department 1",
//     contactDetails: 9876543210,
//     isHodApproved: true,
//   }, {
//     id: 4,
//     title: 'Title 2',
//     startDate: new Date(2021, 12, 17, 12, 0),
//     endDate: new Date(2021, 12, 17, 13, 35),
//     targetSector: ["AS"],
//     image: "",
//     requesterName: "Akshay Kumar",
//     requesterSector: "FES",
//     requesterId: 123456,
//     requesterLocation: "MUMBAI",
//     requesterDepartment: "Department 2",
//     contactDetails: 9876543210,
//     isHodApproved: false,
//   },
// ];

const resources = [
  {
    fieldName: "ownerId",
    title: "Owners",
    instances: owners,
  },
];

const getBorder = (theme) =>
  `1px solid ${
    theme.palette.type === "light"
      ? lighten(alpha(theme.palette.divider, 1), 0.88)
      : darken(alpha(theme.palette.divider, 1), 0.68)
  }`;

const DayScaleCell = (props) => (
  <MonthView.DayScaleCell
    {...props}
    style={{
      textAlign: "center",
      fontWeight: "bold",
      backgroundColor: "#e3e3e3",
    }}
  />
);

const style = ({ palette }) => ({
  icon: {
    color: palette.action.active,
  },
  textCenter: {
    textAlign: "center",
  },
  header: {
    height: "260px",
    backgroundSize: "cover",
  },
  commandButton: {
    backgroundColor: "rgba(255,255,255,0.65)",
  },
});

const styles = (theme) => ({
  cell: {
    color: "#78909C!important",
    position: "relative",
    userSelect: "none",
    verticalAlign: "top",
    padding: 0,
    height: 80,
    borderLeft: getBorder(theme),
    "&:first-child": {
      borderLeft: "none",
    },
    "&:last-child": {
      paddingRight: 0,
    },
    "tr:last-child &": {
      borderBottom: "none",
    },
    "&:hover": {
      backgroundColor: "white",
    },
    "&:focus": {
      backgroundColor: "white",
      outline: 0,
    },
  },
  content: {
    display: "flex",
    justifyContent: "center",
    width: "100%",
    height: "100%",
    position: "absolute",
    alignItems: "center",
  },
  text: {
    padding: "0.5em",
    textAlign: "center",
  },
  sun: {
    color: "#FFEE58",
  },
  cloud: {
    color: "#90A4AE",
  },
  rain: {
    color: "#4FC3F7",
  },
  sunBack: {
    backgroundColor: "white",
  },
  cloudBack: {
    backgroundColor: "white",
  },
  rainBack: {
    backgroundColor: "white",
  },
  opacity: {
    opacity: "0.5",
  },
  appointment: {
    borderRadius: "0px",
    "&:hover": {
      opacity: 0.6,
    },
  },
  apptContent: {
    "&>div>div": {
      whiteSpace: "normal !important",

      lineHeight: 1.2,
    },
  },
  flexibleSpace: {
    flex: "none",
  },
  flexContainer: {
    display: "flex",
    alignItems: "center",
  },
  tooltipContent: {
    padding: theme.spacing(3, 1),
    paddingTop: 0,
    backgroundColor: theme.palette.background.paper,
    boxSizing: "border-box",
    width: "400px",
  },
  tooltipText: {
    ...theme.typography.body2,

    display: "inline-block",
  },
  title: {
    ...theme.typography.h6,
    color: theme.palette.text.secondary,

    fontWeight: theme.typography.fontWeightBold,
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
  },
  icon: {
    color: theme.palette.action.active,
    verticalAlign: "middle",
  },
  circle: {
    width: theme.spacing(4.5),
    height: theme.spacing(4.5),
    verticalAlign: "super",
  },
  textCenter: {
    textAlign: "center",
  },
  dateAndTitle: {
    lineHeight: 1.1,
  },
  titleContainer: {
    paddingBottom: theme.spacing(2),
  },
  container: {
    paddingBottom: theme.spacing(1.5),
  },
});

// #FOLD_BLOCK
const CellBase = React.memo(
  ({
    classes,
    startDate,
    formatDate,
    otherMonth,
    // #FOLD_BLOCK
  }) => {
    const iconId = Math.abs(Math.floor(Math.sin(startDate.getDate()) * 10) % 3);
    const isFirstMonthDay = startDate.getDate() === 1;
    const formatOptions = isFirstMonthDay
      ? { day: "numeric" }
      : { day: "numeric" };
    return (
      <TableCell
        tabIndex={0}
        className={classNames({
          [classes.cell]: true,
          [classes.rainBack]: iconId === 0,
          [classes.sunBack]: iconId === 1,
          [classes.cloudBack]: iconId === 2,
          [classes.opacity]: otherMonth,
        })}
      >
        <div className={classes.content}>
          {/* <WeatherIcon classes={classes} id={iconId} /> */}
        </div>
        <div className={classes.text}>
          {formatDate(startDate, formatOptions)}
        </div>
      </TableCell>
    );
  }
);

const TimeTableCell = withStyles(styles, { name: "Cell" })(CellBase);

const Appointment = withStyles(styles, { name: "Appointment" })(
  ({ classes, ...restProps }) => (
    <Appointments.Appointment {...restProps} className={classes.appointment} />
  )
);

const AppointmentContent = withStyles(styles, { name: "AppointmentContent" })(
  ({ classes, ...restProps }) => (
    <Appointments.AppointmentContent
      {...restProps}
      className={classes.apptContent}
    />
  )
);

const FlexibleSpace = withStyles(styles, { name: "ToolbarRoot" })(
  ({ classes, ...restProps }) => (
    <Toolbar.FlexibleSpace {...restProps} className={classes.flexibleSpace}>
      <div className={classes.flexContainer}>
        <div
          style={{
            backgroundColor: "rgb(100, 181, 246)",
            borderRadius: "0px",
            height: "20px",
            width: "20px",
            marginLeft: "10px",
          }}
        ></div>
        <div style={{ marginLeft: "5px" }}>AS</div>
        <div
          style={{
            backgroundColor: "#ffc107",
            borderRadius: "0px",
            height: "20px",
            width: "20px",
            marginLeft: "12px",
          }}
        ></div>
        <div style={{ marginLeft: "5px" }}>FES</div>
        <div
          style={{
            backgroundColor: "#fd7e14",
            borderRadius: "0px",
            height: "20px",
            width: "20px",
            marginLeft: "12px",
          }}
        ></div>
        <div style={{ marginLeft: "5px" }}>GCO</div>
      </div>
    </Toolbar.FlexibleSpace>
  )
);

const Content = withStyles(style, { name: "Content" })(
  ({ children, appointmentData, classes, ...restProps }) => (
    <AppointmentTooltip.Content
      {...restProps}
      appointmentData={appointmentData}
    >
      <Grid container alignItems="center">
        {/* <Grid item xs={2} className={classes.textCenter}></Grid> */}
        <Grid
          item
          xs={12}
          style={{ paddingLeft: "24px" }}
          className="schedule mt-3"
        >
          <span className="title">Locations</span>
          <p className="content">{appointmentData.locationName?.join(", ")}</p>
        </Grid>
        <Grid item xs={12} style={{ paddingLeft: "24px" }} className="schedule">
          <span className="title">HOD Approval</span>
          <p className="content mb-0">
            {appointmentData.isHodApproved ? "Submitted" : "Not Required"}
          </p>
          {appointmentData.isHodApproved &&
            (appointmentData.isWallpaperAdmin ||
              appointmentData.isWindowsAdmin) && (
              <p
                className="link"
                onClick={() => {
                  window.open(
                    `${baseURL}/ui-assist/v1/download/true?fileName=${appointmentData.hodApprovalDetails}`,
                    "_self"
                  );
                }}
              >
                HOD Approval Details
              </p>
            )}
        </Grid>
        {(appointmentData.isWallpaperAdmin ||
          appointmentData.isWindowsAdmin) && (
          <Grid
            item
            xs={12}
            style={{ paddingLeft: "24px" }}
            className="schedule mt-3"
          >
            <p
              className="link"
              onClick={() => {
                window.open(
                  `${baseURL}/ui-assist/v1/download/true?fileName=${appointmentData.image}`,
                  "_self"
                );
              }}
            >
              Download Wallpaper Image
            </p>
          </Grid>
        )}
        <Grid
          item
          xs={12}
          style={{ paddingLeft: "24px" }}
          className="schedule my-3"
        >
          <span className="heading">Requester Information :</span>
        </Grid>
        <Grid item xs={12} style={{ paddingLeft: "24px" }} className="schedule">
          <span className="title">Name</span>
          <p className="content">{appointmentData.requesterName}</p>
        </Grid>
        <Grid item xs={12} style={{ paddingLeft: "24px" }} className="schedule">
          <span className="title">Sector</span>
          <p className="content">{appointmentData.requesterSector}</p>
        </Grid>
        <Grid item xs={12} style={{ paddingLeft: "24px" }} className="schedule">
          <span className="title">Location</span>
          <p className="content">{appointmentData.requesterLocation}</p>
        </Grid>
        <Grid item xs={12} style={{ paddingLeft: "24px" }} className="schedule">
          <span className="title">Department</span>
          <p className="content">{appointmentData.requesterDepartment}</p>
        </Grid>
        <Grid item xs={12} style={{ paddingLeft: "24px" }} className="schedule">
          <span className="title">Contact Details</span>
          <p className="content">{appointmentData.contactDetails}</p>
        </Grid>
      </Grid>
      {/* {console.log("hiiiiiiiiiiiiii outside",)}
    <CommonModal
        handleClose={() => false}
        //modalSize="xl"
        open={false}
        header={true}
        footer={false}
        headerText={appointmentData.title}
    >
      <div>
        {console.log("hiiiiiiiiiiiiii inside modal", )}hiiiiii
      </div>
    </CommonModal> */}
    </AppointmentTooltip.Content>
  )
);

export default class Demo extends React.Component {
  // #FOLD_BLOCK
  constructor(props) {
    super(props);
    this.state = { data: this.props.dataList };

    this.commitChanges = this.commitChanges.bind(this);
  }

  // #FOLD_BLOCK
  commitChanges({ added, changed, deleted }) {
    this.setState((state) => {
      let { data } = state;
      if (added) {
        const startingAddedId =
          data.length > 0 ? data[data.length - 1].id + 1 : 0;
        data = [...data, { id: startingAddedId, ...added }];
      }
      if (changed) {
        data = data.map((appointment) =>
          changed[appointment.id]
            ? { ...appointment, ...changed[appointment.id] }
            : appointment
        );
      }
      if (deleted !== undefined) {
        data = data.filter((appointment) => appointment.id !== deleted);
      }
      return { data };
    });
  }

  componentDidUpdate(prevProps) {
    // Typical usage (don't forget to compare props):
    if (this.props.dataList !== prevProps.dataList) {
      this.setState({ data: this.props.dataList });
    }
  }

  render() {
    const { data } = this.state;

    return (
      <Paper>
        <div className="schedule">
          <Scheduler data={data}>
            <EditingState onCommitChanges={this.commitChanges} />
            <ViewState defaultCurrentDate={CurrentDate} />

            <MonthView
              timeTableCellComponent={TimeTableCell}
              dayScaleCellComponent={DayScaleCell}
            />

            <Appointments
              appointmentComponent={Appointment}
              appointmentContentComponent={AppointmentContent}
            />
            <Resources data={resources} />

            <Toolbar flexibleSpaceComponent={FlexibleSpace} />
            <DateNavigator />

            <EditRecurrenceMenu />
            <AppointmentTooltip
              showCloseButton
              // showDeleteButton
              // showOpenButton
              contentComponent={Content}
            />
            <AppointmentForm />
            {/* <DragDropProvider /> */}
          </Scheduler>
        </div>
      </Paper>
    );
  }
}
