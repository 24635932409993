import { Table, TableHead, TableCell, TableRow, makeStyles, TablePagination } from '@material-ui/core'
import React,{useState, useEffect} from 'react'





const useStyles = makeStyles(theme => ({
    table: {
        marginTop: theme.spacing(3),
        '& thead th': {
            fontWeight: '600',
            color: theme.palette.primary.main,
            backgroundColor: theme.palette.primary.light,
        },
        '& tbody td': {
            fontWeight: '300',
        },
        '& tbody tr:hover': {
            backgroundColor: '#fffbf2',
            cursor: 'pointer',
        },
    },
}))

function useTable(records, headCells) {
    const classes = useStyles();
    

    
    const pages = [5, 10, 25]
    const [page, setPage] = useState(0)
    const [rowsPerPage, setRowsPerPage] = useState(pages[page])
    const [order, setOrder] = useState()
    const [orderBy, setOrderBy] = useState()
   


    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    }

    const handleChangeRowsPerPage = event => {
        setRowsPerPage(parseInt(event.target.value, 10))
        setPage(0);
    }
   
    const TblContainer = props => {
        return (
           <Table className={classes.table}>{props.children}</Table>
        )
    }
   
   const TblHead = props =>{
       return (
        <TableHead>
        <TableRow>
        {headCells?.map((item) => (
            <TableCell>{item.label}</TableCell>
        ))}
        </TableRow>
        </TableHead>
       )
   }


   const TblPagination = () => (<TablePagination
    component="div"
    page={page}
    rowsPerPageOptions={pages}
    rowsPerPage={rowsPerPage}
    count={records.length}
    onChangePage={handleChangePage}
    onChangeRowsPerPage={handleChangeRowsPerPage}
/>)
   
    return {TblContainer, TblHead, TblPagination}
}

export default useTable

