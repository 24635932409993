import moment from "moment";
import React, { useEffect, useState } from "react";

export const gradeA7 = {
  gradeId: 1,
  gradeLabel: "L5 - Department Head",
  gradeBenifits: [
    {
      benifitId: 1,
      benifitLabel: "Club Membership",
      isNoLimit: true,
      availedLimit: "",
      timeline: "30-45 days",
      policyPDF: "Lorem_ipsum.pdf_1653472190542.pdf",
      contactPerson: {
        name: "Neisha Desouza",
        email: "214658@mahindra.com",
      },
      escalation: {
        name: "Sandesh Parab",
        email: "22115818@mahindra.com",
      },
    },
    {
      benifitId: 2,
      benifitLabel: "Club Mahindra",
      timeline: "3-4 days",
      noOfBedrooms: 2,
      noOfNights: 4,
      charges: "3000",
      includingGST: false,
      policyPDF: "Lorem_ipsum.pdf_1653472190542.pdf",
      contactPerson: {
        name: "Neisha Desouza",
        email: "214658@mahindra.com",
      },
    },
    {
      benifitId: 3,
      benifitLabel: "CTC Vehicle",
      availedLimit: 65,
      includingGST: true,
      includingRTO: false,
      includingInsurance: false,
      applyURL: "https://google.com",
      policyPDF: "Lorem_ipsum.pdf_1653472190542.pdf",
    },
    {
      benifitId: 4,
      benifitLabel: "Credit Card",
      availedLimit: 4,
      timeline: "10-15 days",
      policyPDF: "Lorem_ipsum.pdf_1653472190542.pdf",
      contactPerson: {
        name: "Nitin Bhosle",
        email: "25002873@mahindra.com",
      },
      escalation: {
        name: "Sandesh Parab",
        email: "22115818@mahindra.com",
      },
    },
    {
      benifitId: 5,
      benifitLabel: "Mobile Handset Reimbusement",
      availedLimit: 100000,
      noOfDevices: 2,
      latestiPhone: true,
      applyURL: "https://google.com",
      policyPDF: "Lorem_ipsum.pdf_1653472190542.pdf",
    },
  ],
};

export const gradeA8 = {
  gradeId: 2,
  gradeLabel: "L6",
  gradeBenifits: [
    {
      benifitId: 1,
      benifitLabel: "Club Membership",
      isNoLimit: false,
      availedLimit: "40",
      timeline: "30-45 days",
      policyPDF: "Lorem_ipsum.pdf_1653472190542.pdf",
      contactPerson: {
        name: "Neisha Desouza",
        email: "214658@mahindra.com",
      },
      escalation: {
        name: "Sandesh Parab",
        email: "22115818@mahindra.com",
      },
    },
    {
      benifitId: 3,
      benifitLabel: "CTC Vehicle",
      availedLimit: 65,
      includingGST: true,
      includingRTO: false,
      includingInsurance: false,
      applyURL: "https://google.com",
      policyPDF: "Lorem_ipsum.pdf_1653472190542.pdf",
    },
    {
      benifitId: 4,
      benifitLabel: "Credit Card",
      availedLimit: 4,
      timeline: "10-15 days",
      policyPDF: "Lorem_ipsum.pdf_1653472190542.pdf",
      contactPerson: {
        name: "Nitin Bhosle",
        email: "25002873@mahindra.com",
      },
      escalation: {
        name: "Sandesh Parab",
        email: "22115818@mahindra.com",
      },
    },
    {
      benifitId: 5,
      benifitLabel: "Mobile Handset Reimbusement",
      availedLimit: 100000,
      noOfDevices: 2,
      latestiPhone: true,
      applyURL: "https://google.com",
      policyPDF: "Lorem_ipsum.pdf_1653472190542.pdf",
    },
  ],
};
