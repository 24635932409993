import React from "react";

const Title = ({ title }) => {
  return (
    <>
      <div className="inlineContent">
        <span className="submitOfferCampaignText commonFontNormalStyle  m0">
          {title}
        </span>
      </div>
    </>
  );
};

export default Title;
