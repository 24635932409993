import React from "react";
import "./SearchBarButton.css";
import TextField from "@material-ui/core/TextField";
import PropTypes from "prop-types";
import searchIcon from "../../../Assets/Images/search_line.svg";
import InputAdornment from "@material-ui/core/InputAdornment";
function SearchBarButton({
  placeholder,
  searchText,
  handleSearch,
  handleSearchIcon,
  name,
  onKeyPress
}) {
  return (
    <div className="commanButton">
      <TextField
        className="SearchBar"
        id="outlined-basic"
        placeholder={placeholder}
        onKeyPress={onKeyPress}
        value={searchText}
        variant="outlined"
        onChange={handleSearch}
        name={name}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start" onClick={handleSearchIcon} style = {{cursor: 'pointer'}}>
              <img src={searchIcon} alt="searchIcon" />
            </InputAdornment>
          ),
        }}
      />
    </div>
  );
}

SearchBarButton.propTypes = {
  placeholder: PropTypes.string,
  dataToBeSearch: PropTypes.string,
};

SearchBarButton.defaultProps = {
  placeholder: "Search banner messages",
  dataToBeSearch: "",
};

export default SearchBarButton;
