import React from "react";
import "./SortByDiv.css";
// import ArrowDownwardIcon from "@material-ui/icons/ArrowDownward";
// import ArrowUpwardIcon from "@material-ui/icons/ArrowUpward";
import PropTypes from "prop-types";
import arrowUpDown from "../../../Assets/Images/arrow-up-down.svg";
// import sortByIcon from "../../../Assets/Images/sort-by.svg";

function SortByDiv({
  upArrowActive,
  downArrowActive,
  handleAscending,
  handleDscending,
  shortByText,
}) {
  return (
    <div className="sortDivAlignment">
      {/* <ArrowUpwardIcon
        className={upArrowActive ? `arrowColor colorActive` : `arrowColor`}
        onClick={handleAscending}
      />
      <ArrowDownwardIcon
        className={downArrowActive ? `arrowColor colorActive` : `arrowColor`}
        onClick={handleDscending}
      /> */}
      <img
        src={arrowUpDown}
        onClick={handleAscending}
        alt="sort_by_icon"
        className="sort_by_icon"
      />
      <p>
        <span className="sortByText commonFontNormalStyle">Sort By: </span>
        {/* <span className="sortSpan">{upArrowActive ? "A - Z " : "Z - A"}</span> */}
        <span className="sortSpan commonFontNormalStyle">{shortByText}</span>
      </p>
    </div>
  );
}

SortByDiv.propTypes = {
  upArrowActive: PropTypes.bool,
  downArrowActive: PropTypes.bool,
  handleAscending: PropTypes.func,
  handleDscending: PropTypes.func,
};

SortByDiv.defaultProps = {
  upArrowActive: false,
  downArrowActive: true,
  handleAscending: () => {},
  handleDscending: () => {},
};

export default SortByDiv;
