import React, { useState, useEffect } from "react";
import { Grid } from "@material-ui/core";
import uploadIcon from "../../../Assets/Images/collectiveIcons/upload-2-line.svg";
import { useDispatch, useSelector } from "react-redux";
import { getUploadFileData } from "../../../Redux/Actions/UploadFileAction";
import CircularProgress from "@mui/material/CircularProgress";

const CommonFileUpload = ({ disabled, formik, module }) => {
  const dispatch = useDispatch();
  const fileData = useSelector((state) => state.getUploadFileReducer);

  const [currentFile, setCurrentFile] = useState();

  useEffect(() => {
    if (fileData.getData != "" && currentFile) {
      formik.setFieldValue("videoUri", fileData.getData);
    }
  }, [fileData.getData]);

  const handleFile = (event) => {
    const formData = new FormData();
    formData.append("media", event.currentTarget.files[0]);
    formData.append("name", event.currentTarget.files[0]?.name);
    setCurrentFile(event.currentTarget.files[0]);
    if (event.currentTarget.files[0]?.name) {
      dispatch(getUploadFileData(formData));
    }
  };

  return (
    <Grid container spacing={2} alignItems="top">
      <Grid item xs={12} className="uploadBtnStyle">
        <div className="uploadBtn">
          <label for="thumb" className="btn uploadBtnLabel">
            {formik?.values?.videoUri
              ? formik?.values?.videoUri
              : module
              ? ` Upload ${module}`
              : " Upload video"}{" "}
            <span className="colRed">*</span>
          </label>
          <img
            for="thumb"
            src={uploadIcon}
            className="uploadIconStyl"
            alt="Upload Button Icon"
          />
          <input
            id="thumb"
            style={{
              visibility: "hidden",
              padding: "19px 16px",
              position: "absolute",
            }}
            disabled={disabled}
            type="file"
            accept="video/mp4,video/x-m4v,video/*"
            name="video"
            onChange={handleFile}
          />
        </div>
        <div>
          {fileData.isLoading && (
            <>
              <CircularProgress
                color="success"
                style={{ width: "20px", height: "20px" }}
              />
              <span className="ms-2">Uploading...</span>
            </>
          )}
        </div>
        <span style={{ color: "#f44336" }}>
          {formik?.errors?.videoUri && formik.touched.videoUri
            ? formik.errors.videoUri
            : ""}
        </span>
      </Grid>
    </Grid>
  );
};

export default CommonFileUpload;
