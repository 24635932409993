import services from "../../Services/index";
import {
  GET_ALL_SECTORS_LOAD,
  GET_ALL_SECTORS_FAILED,
  GET_ALL_SECTORS_SUCCESS,
  GET_ALL_BUSINESS_UNITS_LOAD,
  GET_ALL_BUSINESS_UNITS_FAILED,
  GET_ALL_BUSINESS_UNITS_SUCCESS,
  GET_ALL_FUNCTIONS_LOAD,
  GET_ALL_FUNCTIONS_FAILED,
  GET_ALL_FUNCTIONS_SUCCESS,
} from "../Contants/Constants";

const getSectorsLoad = () => ({
  type: GET_ALL_SECTORS_LOAD,
});
const getSectorsSuccess = (data) => ({
  type: GET_ALL_SECTORS_SUCCESS,
  payload: data,
});
const getSectorsFailed = (error) => ({
  type: GET_ALL_SECTORS_FAILED,
  err: error,
});

export const getSectors = () => async (dispatch) => {
  dispatch(getSectorsLoad());
  try {
    const res = await services.api("GET", `/ui-assist/v1/master/sectors`);
    if (res) {
      let sectors = res.data.map((result) => {
        return {
          title: result.sectorName,
          value: result.sectorCode,
        };
      });
      dispatch(getSectorsSuccess(sectors));
    }
  } catch (err) {
    dispatch(getSectorsFailed(err.response));
  }
};

//get all business units
const getBusinessUnitsLoad = () => ({
  type: GET_ALL_BUSINESS_UNITS_LOAD,
});
const getBusinessUnitsSuccess = (data) => ({
  type: GET_ALL_BUSINESS_UNITS_SUCCESS,
  payload: data,
});
const getBusinessUnitsFailed = (error) => ({
  type: GET_ALL_BUSINESS_UNITS_FAILED,
  err: error,
});

export const getBusinessUnits = (data) => async (dispatch) => {
  dispatch(getBusinessUnitsLoad());
  try {
    const res = await services.api(
      "POST",
      `/ui-assist/v1/master/businessunits`,
      data
    );
    if (res) {
      let list = res.data.map((result) => {
        return {
          title: result.buName,
          value: result.id,
          sectorId: result.sector,
        };
      });
      dispatch(getBusinessUnitsSuccess(list));
    }
  } catch (err) {
    dispatch(getBusinessUnitsFailed(err.response));
  }
};

//get functions
const getAllFunctionsLoad = () => ({
  type: GET_ALL_FUNCTIONS_LOAD,
});
const getAllFunctionsSuccess = (data) => ({
  type: GET_ALL_FUNCTIONS_SUCCESS,
  payload: data,
});
const getAllFunctionsFailed = (error) => ({
  type: GET_ALL_FUNCTIONS_FAILED,
  err: error,
});

export const getAllFunctions = () => async (dispatch) => {
  dispatch(getAllFunctionsLoad());
  try {
    const res = await services.api(
      "GET",
      `/ui-assist/v1/organnouncement/allfunction`
    );
    if (res) {
      let list = res.data.map((result) => {
        return {
          title: result.businessFunctionName,
          value: result.id,
        };
      });
      dispatch(getAllFunctionsSuccess(list));
    }
  } catch (err) {
    dispatch(getAllFunctionsFailed(err.response));
  }
};
