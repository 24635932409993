import React, { useState, useEffect } from "react";
import "./FadeInOutHeaderCard.css";
import CommonModal from "../Common/CommonModal/CommonModal";
import SearchBarButton from "../Common/SearchBar/SearchBarButton";
import SubmitButton from "../Common/Button/SubmitButton";
import { Grid, Paper } from "@material-ui/core";
import editIcon from "../../Assets/Images/editBanner.svg";
// import { EditorState } from "draft-js";
import EditModalUpdate from "./EditModalUpdate";
import { DataGrid } from "@mui/x-data-grid";
// import { randomCreatedDate } from "@mui/x-data-grid-generator";
import Swal from "sweetalert2";
import services from "../../Services";
//redux
import { useDispatch, useSelector } from "react-redux";
import { getUserfeedbackData } from "../../Redux/Actions/FeedbackAction";
import { getAllWelcomeData } from "../../Redux/Actions/WelcomeAction";
//table
import { makeStyles } from "@material-ui/core";
import useTable from "../Common/Form/useTable";
import "../Pages/Table/Table.css";
//table

const EditModal = ({
  open,
  handleClose,
  headerText,
  icon,
  modalSize,
  header,
  footer,
  fullWidth,
  onButtonClick,
}) => {
  const [tab, setTab] = useState(0);
  const dispatch = useDispatch();

  // const [ascending, setAscending] = useState(true);
  const [searchText, setsearchText] = useState("");
  const [searchData, setSearchData] = useState(null);
  const [editRowsModel, setEditRowsModel] = useState({});
  const [x, setX] = useState({});
  const [y, setY] = useState({});
  // console.log('x????????????', x)
  const handleEditRowsModelChange = React.useCallback(async (model) => {
    setEditRowsModel(model);
    // console.log('model', JSON.stringify(model))
    // console.log('key',Object.keys(model));
    // console.log('values', Object.values(model)[0]?.messageText?.value);
    let data = {
      messageId: Object.keys(model)[0],
      messageText: Object.values(model)[0]?.messageText?.value,
    };
    // console.log('data', data)
    setY(data);
    // let sendData = JSON.stringify(data)
    setX(JSON.stringify(data));

    // try {
    //     const res = await services.api("POST", `ui-assist/v1/banner`, sendData);
    //     console.log("res from feedback:", res);
    //   } catch (err) {
    //     if (err.response) {
    //      // setApiFeedbackError(true);
    //      console.log('error?????????', err.response)
    //     }
    //   }
  }, []);
  const [apiFeedbackError, setApiFeedbackError] = useState();
  const handleApi = async () => {
    if (y) {
      try {
        //  console.log('x in try', x)
        const res = await services.api("POST", `/ui-assist/v1/banner`, y);
        //  console.log("res from feedback:", res);
      } catch (err) {
        if (err.response) {
          //    console.log('error............')

          setApiFeedbackError(apiFeedbackError);
        }
      }
    }
  };

  useEffect(() => {
    handleApi();
  }, [y]);

  const handleCloseModal = () => {
    setSearchData(null);
    handleClose();
  };

  const handleClick = () => {
    handleClose();
    Swal.fire({
      title: "Are you sure?",
      text: "You will not be able to recover this messages!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Yes, update it!",
      cancelButtonText: "No, keep it",
    }).then((result) => {
      if (result.isConfirmed) {
        Swal.fire(
          "Successfully updated!",
          "Your messages has been updated.",
          "success"
        );
        // For more information about handling dismissals please visit
        // https://sweetalert2.github.io/#handling-dismissals
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire("Cancelled", "Your messages are safe :)", "error");
      }
    });
  };
  //table
  // const dispatch = useDispatch();
  const feedbackData = useSelector((state) => state.getUserfeedbackReducer);
  const { getData: records } = feedbackData;

  //welcome all data.......
  const welcomeAllData = useSelector((state) => state.getAllWelcomeDataReducer);

  const { getData: allWelcomerecords } = welcomeAllData;

  const addSeriel = () => {
    let x = [];
    let count = 0;
    for (let i = 0; i < allWelcomerecords.length; i++) {
      //console.log(allWelcomerecords[i]['srNo'] = count)
      count++;
      x.push({
        ...allWelcomerecords[i],
        srNo: (allWelcomerecords[i]["srNo"] = count),
        date: allWelcomerecords[i]?.createdAt,
        id: allWelcomerecords[i]?.messageId,
      });
      //obj["key3"] = "value3";
    }
    return x;
  };
  let rows = addSeriel();

  // console.log('new array ', newArray);
  //    const handleEditRowsModelChange = React.useCallback((model) => {
  //     setEditRowsModel(model);
  //   }, []);

  // useEffect(() => {
  //   dispatch(getUserfeedbackData());
  // }, []);

  useEffect(() => {
    dispatch(getAllWelcomeData());
  }, [y]);

  //table
  const useStyles = makeStyles((theme) => ({
    pageContent: {
      margin: theme.spacing(5),
      padding: theme.spacing(3),
    },
    searchInput: {
      width: "75%",
    },
    newButton: {
      position: "absolute",
      right: "9px",
      marginBottom: "10px",
    },
  }));

  const headCells = [
    { id: "employeeId", label: "Employee Id" },
    { id: "employeeName", label: "Employee Name" },
    { id: "page", label: "Page" },
    { id: "rating", label: "Rating" },
    { id: "comment", label: "Comment" },
  ];

  const headCellCsv = [
    { label: "employeeId", key: "employeeId" },
    { label: "employeeName", key: "employeeName" },
    { label: "page", key: "page" },
    { label: "rating", key: "rating" },
    { label: "comment", key: "comment" },
  ];

  const classes = useStyles();
  const [recordForEdit, setRecordForEdit] = useState(null);
  // const [records, setRecords] = useState([]);

  const [filterFn, setFilterFn] = useState({
    fn: (items) => {
      return items;
    },
  });

  const { TblContainer, TblHead, TblPagination, recordsAfterPagingAndSorting } =
    useTable(records, headCells, filterFn);

  const handleSearch = (e) => {
    let target = e.target;
    setFilterFn({
      fn: (items) => {
        if (target.value == "") return items;
        else
          return items.filter((x) =>
            x.fullName.toLowerCase().includes(target.value)
          );
      },
    });
  };
  //material table
  const columns = [
    {
      field: "srNo",
      headerName: "Sr. No",
      type: "string",
      width: 180,
      editable: false,
    },
    { field: "messageText", width: 900, headerName: "Message", editable: true },
    {
      field: "date",
      headerName: "Date",
      type: "date",
      width: 180,
      editable: false,
    },
  ];

  // const rows = [

  //     {
  //         id: 1,
  //         message: 'neeraj fadsfaerer',
  //         srNo: 2,
  //         date: randomCreatedDate(),

  //     },
  //     {
  //         id: 2,
  //         message: 'neeraj hghghgh',
  //         srNo: 3,
  //         date: randomCreatedDate(),

  //     },
  //     {
  //         id: 3,
  //         message: 'neeraj frrer ',
  //         srNo: 4,
  //         date: randomCreatedDate(),

  //     },
  //     {
  //         id: 4,
  //         message: 'neeraj asdfasdf',
  //         srNo: 5,
  //         date: randomCreatedDate(),

  //     },

  // ];
  //table

  //selectexcel
  // const [selectedValue, setSelectedValue] = useState("");
  // const [fileType, setFileType] = useState({
  //   image: null,
  //   pdf: null,
  // });
  // const handleFileChange = (e) => {
  //   const { name, files } = e.target;
  //   if (name === "image") {
  //     setFileType({ ...fileType, image: files[0] });
  //   } else {
  //     setFileType({ ...fileType, pdf: files[0] });
  //   }
  // };
  // const handleReplacePDf = () => {
  //   setFileType({
  //     pdf: null,
  //   });
  // };
  // const handleReplaceImage = () => {
  //   setFileType({
  //     image: null,
  //   });
  // };
  // const onEditorStateChange = (editorState) => {
  //   setEditorState(editorState);
  // };
  // const modal = () => {
  //   setOpenSettingssub(!openSettingssub);
  // };
  // const [editorState, setEditorState] = useState(() =>
  //   EditorState.createEmpty()
  // );
  const [openSettingssub, setOpenSettingssub] = useState(false);
  const [footerBannerCsss, setfooterBannerCsss] = useState(false);
  //  console.log('edit rows', editRowsModel)
  return (
    <>
      
        <div  style = {{marginTop: '93px'}} className="favTabModal">
          
          {/*<div className="headerModify">
            <div className="modifiedName">
              <div className="NameTitle">Last modified by</div>
              <div className="nameT">Gaurima Rai</div>
            </div>
            <div className="modifiedDate">
              <div className="NameTime">Modified on</div>
              <div className="time">27-03-2021</div>
            </div>
  </div> */}
          {/* <div className="excelPdf">
                    <SelectFileTypeComponent
                        selectedValue={selectedValue}
                        handleFileChange={handleFileChange}
                        fileType={fileType}
                        handleReplaceImage={handleReplaceImage}
                        handleReplacePDf={handleReplacePDf}
                        editorState={editorState}
                        onEditorStateChange={onEditorStateChange}
                    />
                </div> */}
          <div className="">
            
            <div className="tableMessage">
              <Grid
                container
                direction="row"
                justifyContent="space-evenly"
                alignItems="flex-start"
              >
                <Grid item sm={12}>
                  {/* <TblContainer>
                                    <TblHead />
                                    <TableBody>
                                        {recordsAfterPagingAndSorting().map((item) => (
                                            <TableRow key={item.id}>
                                                <TableCell data-label="employeeId">
                                                    {item.employeeId}
                                                </TableCell>
                                                <TableCell data-label="employeeName">
                                                    {item.employeeName}
                                                </TableCell>
                                                <TableCell data-label="page">{item.page}</TableCell>
                                                <TableCell data-label="rating">{item.rating}</TableCell>
                                                <TableCell data-label="comment">{item.comment}</TableCell>
                                            </TableRow>
                                        ))}
                                    </TableBody>
                                </TblContainer> */}
                  <div style={{ height: 400, width: "100%" }}>
                    <DataGrid
                      rows={rows}
                      columns={columns}
                      editRowsModel={editRowsModel}
                      onEditRowsModelChange={handleEditRowsModelChange}
                    />
                  </div>
                </Grid>
              </Grid>
            </div>
          </div>
        </div>
        {/*<EditModalUpdate
          open={true}
          handleClose={() => setOpenSettingssub(false)}
          headerText="Edit banner"
          modalSize="md"
          header={true}
          footer={true}
          fullWidth
        /> */}
      
    </>
  );
};

export default EditModal;
