import {
    GET_LOCATION_DATA_SUCCESS,
    GET_LOCATION_DATA_LOAD,
    GET_LOCATION_DATA_FAILED,
} from "../Contants/Constants";

const initialState = {
  getData: [],
  isLoading: false,
  isError: "",
};

const getLocationReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_LOCATION_DATA_LOAD:
      return {
        ...state,
        isLoading: true,
      };
    case GET_LOCATION_DATA_SUCCESS:
      return {
        ...state,
        getData: action.payload,
        isLoading: false,
      };
    case GET_LOCATION_DATA_FAILED:
      return {
        ...state,
        isError: action.payload,
        isLoading: false,
      };
    default:
      return state;
  }
};

export default getLocationReducer;
