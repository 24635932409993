import React, { useState, useEffect } from "react";
import "./AdminModalComponent.css";
import { Tabs, Tab, Typography, Box, Grid } from "@material-ui/core";
import PropTypes from "prop-types";
import AdminCardComponent from "./AdminDetailsCard/AdminCardComponent";
import ScheduleTabCardComponent from "./AdminScheduleTabCard/ScheduleTabCardComponent";
import SubmitButton from "../../../Common/Button/SubmitButton";
import addBoxLine from "./../../../../Assets/Images/WhatsNewIcons/add-box-fill.svg";
import { useHistory } from "react-router-dom";
import services from "../../../../Services";
import { getMahindraCollectivePendingData } from "../../../../Redux/Actions/MahindraCollectiveAction";
import { useSelector, useDispatch } from "react-redux";
import {
  LIVEWIRE,
  UPLOAD_A_STORY,
  SUBMIT_OFFER_CAMPAIGN,
} from "./../../../../Routes/constant";
import { getLastModifiedData } from "../../../../Redux/Actions/CommonActions";
import { getFormattedDate } from "../../../../Assets/helperFunctions/helperFunctions";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}
export default function AdminModalComponent({
  isOfferCampaigns,
  // getManageDataNo,
  // getApprovedData,
  // mahindraCollectiveApprovedData,
  filterdList,
  scheduleData,
}) {
  // const mahindraCollectiveApprovedData = useSelector(
  //   (state) => state.getMahindraCollectivePendingReducer
  // );
  // const { getData: getApprovedData } = mahindraCollectiveApprovedData;

  const [value, setValue] = React.useState(0);
  const history = useHistory();
  // const [filterdList, setFilterdList] = useState(getApprovedData);
  // const [scheduleData, setScheduleData] = useState([]);
  // const [manageDataLength, setManageDataLength] = useState("");

  const dispatch = useDispatch();
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const lastModified = useSelector((state) => {
    if (isOfferCampaigns == true) {
      return state?.getLastModifiedDataReducer?.lastModifiedOfferCampaign;
    } else {
      return state?.getLastModifiedDataReducer?.lastModifiedCollective;
    }
  });

  useEffect(() => {
    let moduleName = "mahindracollective";

    if (isOfferCampaigns == true) {
      moduleName = "offerorcampaign";
    }
    dispatch(getLastModifiedData(moduleName));
  }, []);

  // const getScheduleData = async () => {
  //   try {
  //     const res = await services.api(
  //       "GET",
  //       `/ui-assist/v1/${
  //         isOfferCampaigns ? "offerorcampaign" : "mahindracollective"
  //       }/schedule`
  //     );
  //     if (res.status === 200) {
  //       setScheduleData(res.data);
  //     }
  //   } catch (error) {
  //     Swal.fire("Something went wrong!", `${error.response.data}`, "error");
  //   }
  // };
  // useEffect(() => {
  //   if (isOfferCampaigns) {
  //     dispatch(getMahindraCollectivePendingData(false));
  //   } else {
  //     dispatch(getMahindraCollectivePendingData(true));
  //   }
  //   getScheduleData();
  // }, []);

  // useEffect(() => {
  //   setFilterdList(getApprovedData);
  // }, [mahindraCollectiveApprovedData]);

  // useEffect(() => {
  //   // getManageDataNo(filterdList?.length + scheduleData?.length);
  //   getManageDataNo(filterdList?.length + scheduleData?.length);
  // }, [mahindraCollectiveApprovedData, scheduleData]);
  const scope =
    JSON.parse(localStorage.getItem("scopeDetails"))?.admin === true &&
    JSON.parse(localStorage.getItem("scopeDetails")).adminType === "OTHERS";

  const scopeDetails = JSON.parse(localStorage.getItem("scopeDetails"));

  return (
    <div>
      <div className="lastmodifiedpanel mb-0">
        <Grid container spacing={2}>
          <Grid item sm={2} xs={12}>
            <div className="displayBlockUserDetails mlP alignMCAdmin">
              <p className="detailDivp">Last modified by</p>
              <p className="detailsFontSize">
                {lastModified?.name ? lastModified?.name : "Not Available"}
              </p>
            </div>
          </Grid>
          <Grid item sm={2} xs={12}>
            <div className="displayBlockUserDetails alignMCAdmin">
              <p className="detailDivp">Modified on</p>
              <p className="detailsFontSize">
                {lastModified?.date
                  ? getFormattedDate(lastModified?.date)
                  : "Not Available"}
              </p>
            </div>
          </Grid>
          <Grid item sm={8} xs={12}>
            <div className="uploadBtn">
              <SubmitButton
                label="Upload an article"
                className="MCsubmitbutton floatRightAlign"
                icon={addBoxLine}
                onClick={() => {
                  isOfferCampaigns
                    ? history.push(`${LIVEWIRE}/submit-offer-campaign`)
                    : history.push(`${LIVEWIRE}${UPLOAD_A_STORY}`);
                }}
              />
            </div>
          </Grid>
        </Grid>
      </div>

      <div className="MCAdminTabAlign">
        <div className="MCAdminTabs">
          <Grid
            container
            direction="row"
            justifyContent="space-between"
            alignItems="center"
          >
            <Tabs
              value={value}
              onChange={handleChange}
              aria-label="basic tabs example"
              className="MCalignTabContent"
            >
              <Tab
                label={`Pending approval (${
                  filterdList && filterdList?.length > 0
                    ? scope &&
                      filterdList?.filter((item) =>
                        scopeDetails?.moduleAssigneds
                          ?.map(
                            (moduleItem) =>
                              moduleItem?.module === "MAHINDRA_COLLECTIVE" &&
                              moduleItem?.sectorScope
                          )
                          ?.flat()
                          ?.some((r) => item?.sector?.indexOf(r) >= 0)
                      )?.length
                      ? filterdList?.filter((item) =>
                          scopeDetails?.moduleAssigneds
                            ?.map(
                              (moduleItem) =>
                                moduleItem?.module === "MAHINDRA_COLLECTIVE" &&
                                moduleItem?.sectorScope
                            )
                            ?.flat()
                            ?.some((r) => item?.sector?.indexOf(r) >= 0)
                        )?.length
                      : filterdList?.length
                    : 0
                })`}
              />
              <Tab
                label={`Scheduled (${
                  scheduleData?.length
                    ? scope &&
                      scheduleData?.filter((item) =>
                        scopeDetails?.moduleAssigneds
                          ?.map(
                            (moduleItem) =>
                              moduleItem?.module === "MAHINDRA_COLLECTIVE" &&
                              moduleItem?.sectorScope
                          )
                          ?.flat()
                          ?.some((r) => item?.sector?.indexOf(r) >= 0)
                      )?.length
                      ? scheduleData?.filter((item) =>
                          scopeDetails?.moduleAssigneds
                            ?.map(
                              (moduleItem) =>
                                moduleItem?.module === "MAHINDRA_COLLECTIVE" &&
                                moduleItem?.sectorScope
                            )
                            ?.flat()
                            ?.some((r) => item?.sector?.indexOf(r) >= 0)
                        )?.length
                      : scheduleData?.length
                    : 0
                })`}
              />
            </Tabs>
          </Grid>
        </div>
        <hr className="deviderP" />
        <TabPanel value={value} index={0} className="policiesTab">
          <Grid container direction="row">
            <AdminCardComponent
              isOfferCampaignsView={isOfferCampaigns}
              filterdList={filterdList}
            />
          </Grid>
          {/* <Grid container direction="row">
            <AdminCardComponent isOfferCampaignsView={isOfferCampaigns} />
          </Grid> */}
        </TabPanel>
        <TabPanel value={value} index={1} className="policiesTab">
          <Grid container direction="row">
            <ScheduleTabCardComponent
              scheduleData={scheduleData}
              isOfferCampaignsView={isOfferCampaigns}
            />
          </Grid>
          {/* <Grid container direction="row">
            <ScheduleTabCardComponent />
          </Grid> 
          */}
        </TabPanel>
      </div>
    </div>
  );
}
