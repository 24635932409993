// App.js
import 'date-fns';
import React, { useState, useEffect } from 'react';
import './CommonCalender.css';

import DateFnsUtils from '@date-io/date-fns';
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker
} from '@material-ui/pickers';

function App( {setDate, dLocation, setSelectedStartDate, setSelectedEndDate} ) {

  const [selectedDate, setSelectedDate] = useState(undefined);

  useEffect(() => {
    // console.log("set date in calendar =====>", setDate);
    if(setDate)
    {
      const datelist = setDate?.split("-");
      setSelectedDate(new Date(parseInt(datelist[0]), parseInt(datelist[1])-1, parseInt(datelist[2])));
      // console.log(parseInt(datelist[0]), parseInt(datelist[1]), parseInt(datelist[2]));
    }
  },[setDate]);

  const FormatDate = (date) => {
        var dd = date.getDate();
        var mm = date.getMonth() + 1;
  
        var yyyy = date.getFullYear();
        if (dd < 10) {
            dd = '0' + dd;
        }
        if (mm < 10) {
            mm = '0' + mm;
        }
        var sDate = yyyy + '-' + mm + '-' + dd;
        return sDate;
    };

  const handleDateChange = (date) => {
    console.log(date);
    setSelectedDate(date);
    dLocation == "start" ? setSelectedStartDate(FormatDate(date)) : setSelectedEndDate(FormatDate(date))
  };

  return (
    <div className="editable">

      <MuiPickersUtilsProvider utils={DateFnsUtils}>

        <KeyboardDatePicker
          label=""
          format="dd-MM-yyyy"
          value={selectedDate}
          minDate={new Date().setDate(new Date().getDate())}
          onChange={handleDateChange}
        />
        
      </MuiPickersUtilsProvider>

    </div>
  );
}

export default App;