import { Button, Grid, TableBody, TableCell, TableRow } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import SearchBarButton from "../../../Common/SearchBar/SearchBarButton";
import Template from "../../../Common/Template/Template";
import { Paper } from "@material-ui/core";
import { DataGrid } from "@mui/x-data-grid";
import { useDispatch, useSelector } from "react-redux";
import EditNewPoliciesTable from "../EditNewPolicesTable/EditNewPoliciesTable";
import useTable from "../useTable/useTable";
import { getPolicyCategoryData } from "../../../../Redux/Actions/PolicyCategoryAction";
import { getPolicyTableDataByID } from "../../../../Redux/Actions/PolicyCategoryAction";
// import {getPolicyTableData} from '../../../../Redux/Actions/'
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import services from "../../../../Services/index";
import CommonModal from "../../../Common/CommonModal/CommonModal";
import edit from "../../../../Assets/Images/edit-line-red.svg";
import remove from "../../../../Assets/Images/delete.svg";
//css
import "../../MahindraCollective/MahindraCollective.css";
//material ui
import Box from "@mui/material/Box";
//import Button from '@mui/material/Button';
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import { useHistory } from "react-router-dom";
import { POLICIES, POLICIES_SUBCATEGORY, POLICYS_EDIT } from "../../../../Routes/constant";
import "../../Table/Table.css";
import SubmitButton from "../../../Common/Button/SubmitButton";
import AddCategories from "../AddCategories/AddCategories";
import Swal from "sweetalert2";

//useStyle
// const style = {
//   position: 'absolute',
//   top: '50%',
//   left: '50%',
//   transform: 'translate(-50%, -50%)',
//   width: 400,
//   bgcolor: 'background.paper',
//   border: '2px solid #000',
//   boxShadow: 24,
//   p: 4,
// };

function EditNewPolicies() {
    const [refresh, setrefresh] = useState(false)
    const history = useHistory();
    const dispatch = useDispatch();
    const [open, setOpen] = React.useState(false);
    const [catModelOpen, setcatModelOpen] = useState(false)
    const [subCategoryID, setsubCategoryID] = useState("");
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    const routeHandler = (id) => {
        console.log(id, "id??????????????/");
        history.push(`${POLICIES}${POLICIES_SUBCATEGORY}/${id}`);
    };
    //get all categories
    useEffect(() => {
        
        dispatch(getPolicyCategoryData());
        //dispatch(getPolicyTableData(data));
    }, [refresh]);

    //get subcategory by id:
    useEffect(() => {
        dispatch(getPolicyTableDataByID(subCategoryID));
    }, [subCategoryID]);

    const policyTableDataState = useSelector((state) => state.getPolicyTableDataReducer);
    const { getData: policyTableData } = policyTableDataState;
    console.log(policyTableData, "sub category dta....");

    const headCells = [
        { id: "srNo", label: "Sr No." },
        { id: "category", label: "Category" },
        { id: "subcategory", label: "Subcategory" },
        { id: "action", label: "Actions" },
    ];

    const policyData = useSelector((state) => state.getPolicyCategoryDataReducer);
    const { getData: policyCategory } = policyData;

    console.log(policyCategory, "policy dta............");

    const { TblContainer, TblHead, TblPagination } = useTable(policyCategory, headCells);
    //get sub categories
    const getPolicySubCategoryDataState = useSelector((state) => state.getPolicySubCategoryDataReducer);
    const { getData: policySubCategory } = getPolicySubCategoryDataState;
    console.log("policy subcategory data inside edit...........", policySubCategory);
const [currentItem, setcurrentItem] = useState(
    null
)
const [nullData, setnullData] = useState();
    const handleEdit = (item) => {
        console.log("edit asdfasdf", item);
        setcurrentItem(item)
        //handleOpen()
        setOpen(true);
    };

    const handleCategoryDeleteApi = async (data) => {
        Swal.fire({
          title: "Are you sure?",
          text: "All Policies in this category will be deleted",
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Yes, delete it!",
      }).then((result) => {
          if (result.isConfirmed) {
              services
                  .api("DELETE", `/ui-assist/v1/policycategory/${data.categoryId}`)
                  .then((res) => {
                      Swal.fire("Deleted!", "Policy has been deleted.", "success");
                      setrefresh(!refresh);
                  })
                  .catch((err) => {
                    Swal.fire({
                      icon: 'error',
                      title: 'Oops...',
                      text: 'Something went wrong!',
                      
                      footer: `${err.message}`
                    })
                  });
          }
      });
      };

    const deleteCategory = async (data) => {
        try {
            const res = await services.api("POST", `/ui-assist/v1/feedback`, data);
            if (res) {
                alert("category deleted successfullyasdfasdfasdf.");
            }
        } catch (err) {
            if (err.response) {
                console.log("error..................", err.data);
            }
        }
    };

    const handleDelete = (item) => {
        console.log("delete", item);
        const data = {
            categoryId: item.categoryId,
        };
        handleCategoryDeleteApi(data)
        
    };

    const handleManageClick = (e, item) => {
        console.log(subCategoryID, "sub category id.............");
        console.log("manageClick", e, item);
        setsubCategoryID(item.categoryId);
        routeHandler(item.categoryId);
    };

    return (
        <>
            <Template>
                <Grid container direction="row" justifyContent="space-between" alignItems="center">
                    <Grid item xs={12} sm={7}>
                        <p className="offCHeaderTitle">Add Categories</p>
                        <SubmitButton
                            onClick={(e) => {
                               console.log(' category add')
                               setcatModelOpen(!catModelOpen)
                            }}
                            className="offCHeaderTitle addPolicy"
                            label="ADD"
                        ></SubmitButton>
                    </Grid>
                    <Grid item xs={12} sm={5}>
                        <div className="policiesSearchWidth">
                            <SearchBarButton placeholder="hi mayu" />
                        </div>
                    </Grid>
                </Grid>
                {/*<EditNewPoliciesTable /> */}

                <Paper className="MClandingpageAlign tableGutter policyTableCss">
                    <Grid
                        container
                        direction="row"
                        justifyContent="space-evenly"
                        alignItems="flex-start"
                        spacing={2}
                        className="MCContainer"
                    >
                        <Grid item sm={12}>
                            <div className="MCalignCardsItemstable1 padding16">
                                <TblContainer>
                                    <TblHead />
                                    <TableBody>
                                        {policyCategory &&
                                            policyCategory.map((item) => (
                                                <TableRow key={item.categoryId}>
                                                    <TableCell>{item.srNo}</TableCell>
                                                    <TableCell>{item.category}</TableCell>
                                                    <TableCell>
                                                        <p
                                                            onClick={(e) => {
                                                                handleManageClick(e, item);
                                                            }}
                                                            style={{
                                                                color: "blue",
                                                                textDecoration: "underline",
                                                            }}
                                                        >
                                                            manage categories
                                                        </p>
                                                    </TableCell>
                                                    <TableCell >
                                                      <div style={{display:"flex"}}>
                                                      <div
                                                            onClick={(e) => {
                                                                handleEdit(item);
                                                            }}
                                                        >
                                                            <img src={edit} alt="" />
                                                        </div>{" "}
                                                        <div
                                                            onClick={(e) => {
                                                                handleDelete(item);
                                                            }}
                                                            style={{paddingLeft:"6px"}}
                                                        >
                                                            <img src={remove} alt="" />
                                                        </div>
                                                      </div>
                                                    </TableCell>
                                                </TableRow>
                                            ))}
                                    </TableBody>
                                </TblContainer>
                                <TblPagination />
                            </div>
                        </Grid>
                    </Grid>
                </Paper>
                <AddCategories open  ={catModelOpen} close ={setcatModelOpen} openEdit = {open} closeEdit = {setOpen} data = {currentItem} nullItem = {setcurrentItem}/>
                <CommonModal />
            </Template>
        </>
    );
}

export default EditNewPolicies;
