import React, { useEffect, useState } from "react";
import Template from "../../../Common/Template/Template";
import { useParams } from "react-router-dom";
import { Grid, Paper, TableBody, TableCell, TableRow } from "@material-ui/core";
import SearchBarButton from "../../../Common/SearchBar/SearchBarButton";
import { getPolicySubCategoryData } from "../../../../Redux/Actions/PolicyCategoryAction";
import { useDispatch, useSelector } from "react-redux";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import useTable from "../useTable/useTable";
import { useHistory } from "react-router-dom";
import { POLICYS_EDIT, POLICIES } from "../../../../Routes/constant";
import "../../Table/Table.css";
import edit from "../../../../Assets/Images/edit-line-red.svg";
import remove from "../../../../Assets/Images/delete.svg";
import SubmitButton from "../../../Common/Button/SubmitButton";
import AddSubCategory from "../AddSubCategory/AddSubCategory";
import services from "../../../../Services";
import Swal from "sweetalert2";

function EditSubCategories() {
   
    const [subcategoryOpenModel, setsubcategoryOpenModel] = useState(false)
    const [subcategoryEditModel, setsubcategoryEditModel] = useState(false);
    const [currentItem, setcurrentItem] = useState(null);
    const [refresh, setrefresh] = useState(false)
    const history = useHistory();
    let sub_cat_id = window.location.pathname.split("/")[4];
    // const [catID, setcatID] = useState("");
    // setcatID(cat_id);

    const dispatch = useDispatch();

    useEffect(() => {
        

        dispatch(getPolicySubCategoryData(sub_cat_id));
    }, [refresh]);

    const policySubCategoryData = useSelector((state) => state.getPolicySubCategoryDataReducer);
    const { getData: policySubCategories } = policySubCategoryData;
    console.log("getpolicySubCategoriesdata", policySubCategories);

    const headCells = [
        { id: "srNo", label: "Sr No." },
        { id: "subCategoryTitle", label: "Title" },
        { id: "subcategoryDescription", label: "Description" },
        { id: "manageSubCategories", label: "Manage Subcategories" },
        { id: "action", label: "Actions" },
    ];

    const { TblContainer, TblHead, TblPagination } = useTable(policySubCategories, headCells);

    //handle manage policies click
    const handleManageClick = (e, item) => {
        console.log(item, "item");
        routeHandler(item.policySubCategoryId);
    };

    const handleEdit = (item) => {
        console.log(item, "sub category edit");
        setcurrentItem(item)
        setsubcategoryEditModel(true)
    };

    const handleDelete = (item) => {
        console.log(item, "delete handler");
        let removeData = {policySubCategoryId: item.policySubCategoryId}
        handleCategoryDeleteApi(removeData)
    };

    const routeHandler = (id) => {
        console.log(id, "id??????????????/");
        history.push(`${POLICIES}${POLICYS_EDIT}/${id}`);
    };

    const handleCategoryDeleteApi = async (data) => {
        Swal.fire({
          title: "Are you sure?",
          text: "All Policies in this category will be deleted",
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Yes, delete it!",
      }).then((result) => {
          if (result.isConfirmed) {
              services
                  .api("DELETE", `/ui-assist/v1/policysubcategory/${data.policySubCategoryId}`, data)
                  .then((res) => {
                      Swal.fire("Deleted!", "Policy has been deleted.", "success");
                      setrefresh(!refresh);
                  })
                  .catch((err) => {
                    Swal.fire({
                      icon: 'error',
                      title: 'Oops...',
                      text: 'Something went wrong!',
                      
                      footer: `${err.message}`
                    })
                  });
          }
      });
      };

    return (
        <>
            <Template>
                <Grid container direction="row" justifyContent="space-between" alignItems="center">
                    <Grid item xs={12} sm={7}>
                        <p className="offCHeaderTitle">Add Sub Categories</p>
                        <SubmitButton
                            onClick={(e) => {
                               console.log(' category add')
                               setsubcategoryOpenModel(!subcategoryOpenModel)
                            }}
                            className="offCHeaderTitle addPolicy"
                            label="ADD"
                        ></SubmitButton>
                    </Grid>
                    <Grid item xs={12} sm={5}>
                        <div className="policiesSearchWidth">
                            <SearchBarButton placeholder="hi mayu" />
                        </div>
                    </Grid>
                </Grid>

                <Paper className="MClandingpageAlign tableGutter policyTableCss">
                    <Grid
                        container
                        direction="row"
                        justifyContent="space-evenly"
                        alignItems="flex-start"
                        spacing={2}
                        className="MCContainer"
                    >
                        <Grid item sm={12}>
                            <div className="MCalignCardsItemstable1 padding16">
                                <TblContainer>
                                    <TblHead />
                                    <TableBody>
                                        {policySubCategories &&
                                            policySubCategories.map((item) => (
                                                <TableRow key={item.categoryId}>
                                                    <TableCell>{item.srNo}</TableCell>
                                                    <TableCell>{item.title}</TableCell>
                                                    <TableCell>{item.description}</TableCell>
                                                    <TableCell>
                                                        <p
                                                            onClick={(e) => {
                                                                handleManageClick(e, item);
                                                            }}
                                                            style={{
                                                                color: "blue",
                                                                textDecoration: "underline",
                                                            }}
                                                        >
                                                            manage policies
                                                        </p>
                                                    </TableCell>
                                                    <TableCell >
                                                      <div style={{display:"flex"}}>
                                                      <div
                                                            onClick={(e) => {
                                                                handleEdit(item);
                                                            }}
                                                        >
                                                            <img src={edit} alt="" />
                                                        </div>{" "}
                                                        <div
                                                            onClick={(e) => {
                                                                handleDelete(item);
                                                            }}
                                                            style={{paddingLeft:"6px"}}
                                                        >
                                                            <img src={remove} alt="" />
                                                        </div>
                                                      </div>
                                                    </TableCell>
                                                </TableRow>
                                            ))}
                                    </TableBody>
                                </TblContainer>
                                <TblPagination />
                            </div>
                        </Grid>
                    </Grid>
                </Paper>
                <AddSubCategory open = {subcategoryOpenModel} close = {setsubcategoryOpenModel} openEdit = {subcategoryEditModel} closeEdit = {setsubcategoryEditModel} data = {currentItem} nullItem = {setcurrentItem}/>

            </Template>
        </>
    );
}

export default EditSubCategories;
