import React, { useState, useEffect } from "react";
import "./ITSupport.css";
import { Card, CardContent, Grid } from "@material-ui/core";

import CommonLabel from "../../../Common/ComonLabel/CommonLabel";
import MultiSelect from "../../../Common/MultiSelect/MultiSelect";
import SearchBarButton from "../../../Common/SearchBar/SearchBarButton";
import Template from "../../../Common/Template/Template";
import editIconRed from "../../../../Assets/Images/edit-line-red.svg";
import ChatIcon from "../../../../Assets/Images/chat-smile-3-line.svg";
import InfraBuilding from "../../../../Assets/Images/InfraBuilding.svg";
import InfraPhone from "../../../../Assets/Images/InfraPhone.svg";
import isAdmin from "../../../isAdmin";
import CommonAutocomplete from "../../../Common/CommonAutocomplete/CommonAutocomplete";
import CommonModal from "../../../Common/CommonModal/CommonModal";
import editIcon from "../../../../Assets/Images/edit-line.svg";
import ITSupportAdminComponent from "./ITSupportAdminComponent/ITSupportAdminComponent";
import ITSupportCategoryAdmin from "./ITSupportCategoryAdmin/ITSupportCategoryAdmin";
import services from "../../../../Services";
import Loader from "../../../Common/Loader/Loader";
import COMP_LINE from "../../../../Assets/Images/collectiveIcons/computer-line.svg";
import CONTACT_FILL from "../../../../Assets/Images/collectiveIcons/contacts-fill.svg";
import Contact_book from "../../../../Assets/Images/collectiveIcons/contacts-book-2-line.svg";
import COUPON_LINE from "../../../../Assets/Images/collectiveIcons/coupon-2-line.svg";
import ITcomputer from "../../../../Assets/Images/ITcomputer.svg";
import CustomMultiSelect from "../../../Common/CustomMultiSelect/CustomMultiSelect";
import PageTutorialComponent from "../../../Common/PageTutorial/PageTutorialComponent";
import Swal from "sweetalert2";
import platform from "platform";
import { useDispatch } from "react-redux";
import {
  addAnalayticsLog,
  addInterval,
} from "../../../../Redux/Actions/Anaylatics";
import { isAccesible } from "../../../../Assets/helperFunctions/helperFunctions";

// import services from "../../../../Services";

const style = {
  width: 300,
  marginBottom: "1.5em",
};

const serviceDataArray = [
  {
    sectorName: "General",
    sectorDetail: [
      {
        department: "Genie",
        icon: ChatIcon,
        desc: "Employee support chat bot",
        contactDetails: [],
        linkName: "Chat with genie",
      },
    ],
  },
  {
    sectorName: "IT",
    sectorDetail: [
      {
        department: "IT Support",
        icon: ChatIcon,
        desc: "Employee support chat bot",
        contactDetails: [
          { data: "9004074330" },
          { data: "msolve5544@mahindra.com" },
        ],
        linkName: "Visit here",
      },
    ],
  },
  {
    sectorName: "Health and wellness",
    sectorDetail: [
      {
        department: "Dilsay",
        icon: ChatIcon,
        desc: "",
        contactDetails: [{ data: "SALDANHA.LEEANN @mahindra.com" }],
        linkName: "Book a consultation",
      },
      {
        department: "Health",
        icon: ChatIcon,
        desc: "Support service for Health services - well-equipped gymnasium and multidisciplinary inhouse doctors provided in Mahindra Towers, Worli.",
        contactDetails: [{ data: "SMr. Afzal Khan: 9820366601" }],
        linkName: "View List",
      },
      {
        department: "List of doctors",
        icon: ChatIcon,
        desc: "Support service for any medical related concerns and queries",
        contactDetails: [
          { data: "Domestic: 1800 102 7293 / 1800 209 8424" },
          { data: "International: +91 80 6539 3188 / 80 6539 3189" },
          { data: "askanexpert@optum.in" },
        ],
        linkName: "START ONLINE CHAT",
      },
      {
        department: "mHappy",
        icon: ChatIcon,
        desc: "Free counselling for employees and their family - available in 9 languages including Hindi, Tamil, Telegu, Kannada, Malyalam, Bengali, Gujrati, Marathi.",
        contactDetails: [],
        linkName: "Visit here",
      },
    ],
  },
];

const sectionOption = [
  {
    Address:
      "Mahindra & Mahindra Limited Automotive and Farm Equipment Sectors, AFS Head Office Administration, Mahindra Towers, Akurli Road, Kandivali (East) Mumbai – 400101 Maharashtra",
    //Contact: "022-4916868",
    title: "Kandivali",
    value:
      "https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d15071.498529238277!2d72.8715498!3d19.2006773!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x3bb55b906a0c4129!2sMahindra%20And%20Mahindra%20Ltd!5e0!3m2!1sen!2sin!4v1641907239001!5m2!1sen!2sin",
  },
  {
    Address:
      "Mahindra & Mahindra Limited. Automotive and Farm Equipment Sectors Mahindra Towers, Second Floor, G M Bhonsale Marg. Worli, Mumbai- 400 018 Maharashtra",
    //Contact: "022-4916868",
    title: "Worli",
    value:
      "https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d15089.263947115407!2d72.8217667!3d19.0057928!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x454abbd0f0f44ab7!2sMahindra%20%26%20Mahindra%20Financial%20Services%20Limited!5e0!3m2!1sen!2sin!4v1641907329723!5m2!1sen!2sin",
  },
  {
    Address:
      "Mahindra & Mahindra Limited Automotive Sector, Kandivali Plant, Akurli Road, Kandivali (East) Mumbai – 400101 Maharashtra",
    // Contact: "022-4916868",
    title: "Kandivali",
    value:
      "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3767.815290813312!2d72.86664626490314!3d19.203268287015018!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3be7b73b7435a8a1%3A0x95ea44c910915d5!2sMahindra%20%26%20Mahindra%20Ltd!5e0!3m2!1sen!2sin!4v1642141960081!5m2!1sen!2sin",
  },
  {
    Address:
      "Mahindra & Mahindra Limited Automotive Sector, Nashik Plant, 89 MIDC Satpur Nashik – 422007 Maharashtra",
    // Contact: "022-4916868",
    title: "Nashik",
    value:
      "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3749.1455326236764!2d73.72424141491568!3d20.00240578656319!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bddec66a9cfdcc1%3A0xbdf2782e842dee51!2sMahindra%20Tool%20And%20Die%20Plant!5e0!3m2!1sen!2sin!4v1642142095525!5m2!1sen!2sin",
  },
  {
    Address:
      "Mahindra & Mahindra Ltd. Automotive Sector, Igatpuri Plant Mouze Talegaon, Taluka- Igatpuri, Nashik – 422403 Maharashtra",
    // Contact: "022-4916868",
    title: "Igatpuri",
    value:
      "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3756.3855497809454!2d73.54472321491075!3d19.69620298673521!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bdd8688a5555555%3A0x6756e9fa129cc82d!2sMahindra%20And%20Mahindra%20Engine%20Plant!5e0!3m2!1sen!2sin!4v1642142393534!5m2!1sen!2sin",
  },
  {
    Address:
      "Mahindra & Mahindra Ltd (Corporate Office) Worli Towers, PK Kurne Chowk, Worli, Mumbai - 400018",
    // Contact: "022-4916868",
    title: "Zaheerabad",
    value:
      "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3801.1876611500074!2d77.5777070148806!3d17.688592887898764!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bceb5710d835829%3A0xb8f0696a2977f882!2sMahindra%20%26%20Mahindra%20Ltd%20Automotive%20Division!5e0!3m2!1sen!2sin!4v1642143391876!5m2!1sen!2sin",
  },
  {
    Address:
      "Mahindra & Mahindra Limited Automotive Sector, Haridwar Plant, Sector 5, Integrated Industrial Estate, SIDCUL, BHEL (Ranipur), Haridwar - 249403 Uttarakhand",
    // Contact: "022-4916868",
    title: "Haridwar",
    value:
      "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3456.502934039341!2d78.05325331511324!3d29.964973081911282!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x390949c8ccdd7bdb%3A0xdc6bf6bbfc426181!2sMahindra%20%26%20Mahindra%20Haridwar!5e0!3m2!1sen!2sin!4v1642142862892!5m2!1sen!2sin",
  },
  {
    Address:
      "Mahindra & Mahindra Limited Farm Equipment Sector, Kandivali Plant, Akurli Road, Near Lokhandwala Complex, Kandivali (East) Mumbai – 400101 Maharashtra",
    // Contact: "022-4916868",
    title: "Kandivali",
    value:
      "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3767.808751522234!2d72.86396236490314!3d19.20355378701487!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3be7b73b7435a8a1%3A0x95ea44c910915d5!2sMahindra%20%26%20Mahindra%20Ltd!5e0!3m2!1sen!2sin!4v1642142958482!5m2!1sen!2sin",
  },
  {
    Address:
      "Mahindra & Mahindra Ltd (Corporate Office) Worli Towers, PK Kurne Chowk, Worli, Mumbai - 400018",
    // Contact: "022-4916868",
    title: "Nagpur",
    value:
      "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d59554.405022252984!2d78.9610624852965!3d21.1065726546809!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bd495344df1ae17%3A0x807342d4ab32e305!2sMahindra%20tractor%20company!5e0!3m2!1sen!2sin!4v1642143156942!5m2!1sen!2sin",
  },
  {
    Address:
      "Mahindra & Mahindra Limited, Farm Equipment Sector, Zaheerabad Plant, Mahindra Nagar, Near Bidar T-Junction, Medak Dist, Zaheerabad - 502220 Telangana",
    // Contact: "022-4916868",
    title: "Zaheerabad",
    value:
      "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d243100.72207986168!2d77.37288317456101!3d17.817584861968644!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bceb56d796c3a9b%3A0x78090a44aead7530!2sMahindra%20Tractor%20Plant%20Zaheerabad!5e0!3m2!1sen!2sin!4v1642143280370!5m2!1sen!2sin",
  },
  {
    Address:
      "Mahindra & Mahindra Limited Farm Equipment Sector, Rudrapur Plant, Kicha Road, VPO - Lalpur, Rudrapur. - 263148. Udham Singh Nagar Uttarakhand",
    // Contact: "022-4916868",
    title: "Rudrapur",
    value:
      "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d55848.25826528584!2d79.41154222596631!3d28.972072664830243!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x39a07f3712d5b3a5%3A0x77e3aaf108acb0fd!2sMahindra%20%26%20Mahindra%20Tractor%20plant!5e0!3m2!1sen!2sin!4v1642143656103!5m2!1sen!2sin",
  },
  {
    Address:
      "Mahindra & Mahindra Limited Farm Equipment Sector, Jaipur Plant, On Jaipur-Ajmer Highway Village-Mehlan, Tehsil-Dudu, Jaipur – 303007 Rajasthan, India",
    // Contact: "022-4916868",
    title: "Jaipur",
    value:
      "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d227552.11172479804!2d75.59177311901146!3d26.982673585361606!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x396c4799f772f41f%3A0x40c21e7342ea2e10!2sMahindra%20Tractors%20plant!5e0!3m2!1sen!2sin!4v1642145217052!5m2!1sen!2sin",
  },
  {
    Address:
      "Mahindra Vehicle Manufacturers Ltd. Chakan Plant, Plot No. A-1, Phase IV, Chakan MIDC, Taluka Khed,District Pune – 410501. Maharashtra",
    // Contact: "022-4916868",
    title: "MVML",
    value:
      "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3778.5825474754024!2d73.79525321489582!3d18.72749868728885!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bc2b65613c38e9d%3A0xcb6d5ccd1105cf81!2sMahindra%20Vehicle%20Manufacturers%20Ltd.!5e0!3m2!1sen!2sin!4v1642143970827!5m2!1sen!2sin",
  },
  {
    Address:
      "Mahindra Heavy Engines Pvt Ltd. Chakan Plant Plot no. A-1/1, Chakan Industrial Area, Phase – IV, Village – Nigoje, Chakan, Tal. Khed, Dist. Pune – 410501 Maharashtra",
    // Contact: "022-4916868",
    title: "MHEL",
    value:
      "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d60451.14946146374!2d73.80099462544932!3d18.74474264326511!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bc2c07ae5555555%3A0x536ba79af787701d!2sMahindra%20Powertrain!5e0!3m2!1sen!2sin!4v1642144072700!5m2!1sen!2sin",
  },
  {
    Address:
      "Mahindra & Mahindra Limited Automotive and Farm Equipment Sectors, Mahindra Research Valley Mahindra World City Plot no 41/1 Anjur PO Chengalpattu- 603204 Dist: Kanchipuram Tamilnad",
    // Contact: "022-4916868",
    title: "Chennai R&D",
    value:
      "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d31135.610245996093!2d79.97887410482438!3d12.716601338487557!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3a52f95a0552d161%3A0x656aabfa4452788b!2sMahindra%20Research%20Valley!5e0!3m2!1sen!2sin!4v1642144337876!5m2!1sen!2sin",
  },
  {
    Address:
      "Mahindra Two Wheelers Limited, MIDC-Chinchwad, D-1 Block, (Part) Opposite Garware Wall Ropes Ltd., HK Firodia Road, Chinchwad, Pune-411019 Maharashtra",
    // Contact: "022-4916868",
    title: "Chinchwad",
    value:
      "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3780.199505600426!2d73.79326721489478!3d18.655041287330796!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bc2b9d32564083b%3A0x545c2a583ba5e696!2sMahindra%20%26%20Mahindra%20Ltd.%20Two%20Wheelers%20Division!5e0!3m2!1sen!2sin!4v1642144409956!5m2!1sen!2sin",
  },
  {
    Address:
      "Mahindra Two Wheelers Limited. Pitampur Plant, Industrial Area 1, Plot No. 2, Dist - Dhar, Pithampur, Indore – 454775, Madhya Pradesh",
    // Contact: "022-4916868",
    title: "Pitampur",
    value:
      "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3683.4410274580086!2d75.68997841496004!3d22.600000385168677!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3962563701769343%3A0x53c91a3bcf3b5b46!2sMahindra%20Two%20Wheelers!5e0!3m2!1sen!2sin!4v1642144490072!5m2!1sen!2sin",
  },
  {
    Address:
      "Mahindra Electric Mobility Limited No.: 66 to 69 & 72, Road No.: 3, Bommasandra Phase 4, Industrial Area, Jigani Link Road, Bangalore -560099 Karnataka",
    // Contact: "022-4916868",
    title: "Bangalore",
    value:
      "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3890.5183218639136!2d77.65932221482025!3d12.809747490962213!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bae6c6bd06d3e11%3A0xac928e12cf3c984f!2sMahindra%20Electric%20Mobility%20Limited!5e0!3m2!1sen!2sin!4v1642144599944!5m2!1sen!2sin",
  },
  {
    Address:
      "Mahindra Electric Mobility Limited 8th Floor, Gold Hill Square Software Park, No.690, Hosur Road, Bommanahalli, Bangalore – 560068 Karnataka",
    // Contact: "022-4916868",
    title: "Bangalore",
    value:
      "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3889.0915788654634!2d77.63071401482135!3d12.901832590901618!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bae14957b145173%3A0x58986af366bc89bb!2sMahindra%20Electric%20Corporate%20Office!5e0!3m2!1sen!2sin!4v1642144670278!5m2!1sen!2sin",
  },
  {
    Address:
      "Mahindra & Mahindra Limited Spares Business Unit, 371, Takve Road, Kanhe, Taluka- Vadgaon Maval, Dist Pune- 412 106.",
    // Contact: "022-4916868",
    title: "Kanhe",
    value:
      "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d15111.191930707453!2d73.58758937899813!3d18.762558546294812!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bc2adb2b9e0b5e5%3A0xd72882cbe21c1874!2sMahindra%20%26%20Mahindra%20Ltd%2C%20Spares%20Business%20Unit!5e0!3m2!1sen!2sin!4v1642144753672!5m2!1sen!2sin",
  },
  {
    Address:
      "Mahindra & Mahindra Limited Spares Business Unit Regional Distribution Center, Plot no-DTA-004-009,10 & 004-011, Mahindra World City Village-Tilawas, Tehsil-Sanganer, Jaipur-302037 Rajasthan",
    // Contact: "022-4916868",
    title: "Jaipur",
    value:
      "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d113909.13278447327!2d75.65120649364921!3d26.850751698835296!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x396c35f35da5d761%3A0x4a6bb8ab4be8acad!2sMahindra%20World%20City!5e0!3m2!1sen!2sin!4v1642144843809!5m2!1sen!2sin",
  },
];

const MLDLOption = [
  {
    Address:
      "Professional Chalet No. 42, Paragraph, B Wing, 17th floor, 1702, Mondeal Heights, Nr. Novotel Hotel, S.G. Highway, Ahmedabad, Gujarat 380015, India",
    title: "Ahmedabad",
    value:
      "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d229.50122454344236!2d72.50674412914613!3d23.023052821304354!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x395e9bff71e39bd7%3A0x6bfec031e18874fc!2sParagraph%20-%20Coworking%20Space%20Ahmedabad!5e0!3m2!1sen!2sin!4v1680703162217!5m2!1sen!2sin",
  },
  {
    Address:
      "Mahindra Lifespace Developers Limited 5th Cross, Near Archana Mahal, VGN Royal Enclave Extension, Paruthipattu, Avadi-Chennai-600 071",
    title: "Avadi",
    value:
      "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d1373.947496194603!2d80.11208890346492!3d13.092191299591368!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3a526368e4ff6ca3%3A0x7dd9993fa555b183!2sMahindra%20Happinest%20Avadi!5e0!3m2!1sen!2sin!4v1680613096746!5m2!1sen!2sin",
  },
  {
    Address:
      "Mahindra Lifespace Developers Limited, Sy No. 49 & 50/2, Near Holiday Village Resort ,9th Mile, Kanakapura Rd, Vajarahalli Village, Bengaluru, Karnataka – 5",
    title: "Bengaluru",
    value:
      "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3889.603075308647!2d77.5410657148209!3d12.86889399092331!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bae400ebebfffff%3A0xa75e14492ae7d52c!2sMahindra%20Eden!5e0!3m2!1sen!2sin!4v1680616115622!5m2!1sen!2sin",
  },
  {
    Address:
      "Mahindra World City Developers Limited Administrative Block, Central Avenue, Mahindra World City Nathan Sub (PO) Chengalpet Taluk Kancheapuram, Dist. Tamil Nadu -603 002",
    title: "Chennai",
    value:
      "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d498203.0651662724!2d79.44146775000002!3d12.699617799999993!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3a52fbc8b52c25d5%3A0x9d9fe250ca7f3aa3!2sMahindra%20World%20City!5e0!3m2!1sen!2sin!4v1680614576480!5m2!1sen!2sin",
  },
  {
    Address:
      "Mahindra Lifespace Developers Limited Sector -59, Village Behrampur Opposite IREO Grand Arch Gurugram -Haryana -122 001",
    title: "Delhi",
    value:
      "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3509.3246326901735!2d77.1059768150776!3d28.4094597825063!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x390d21f39a24a4fb%3A0xfce22207092c9d5f!2sMahindra%20Luminare!5e0!3m2!1sen!2sin!4v1680615177068!5m2!1sen!2sin",
  },
  {
    Address:
      "Mahindra World City Limited -Jaipur 411, NeelKanth, 1Bhawani Singh Road, C-Scheme, Jaipur-302 001",
    title: "Jaipur",
    value:
      "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3558.1681535354196!2d75.79962367407538!3d26.898157981462607!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x396db41f80cc6805%3A0xb350385c4c6fbdc3!2sBhawani%20Singh%20Rd%2C%20Jyothi%20Nagar%2C%20Lalkothi%2C%20Jaipur%2C%20Rajasthan%20302007!5e0!3m2!1sen!2sin!4v1680618246664!5m2!1sen!2sin",
  },
  {
    Address:
      "Mahindra Happinest Developers Limited Chokhidhani, Kalyan Bhiwandi Road, Village Ranjnoli, Opposite Hotel K N Park Taluka -Bhivandi – 421 302",
    title: "Mumbai, Kalyan",
    value:
      "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d7532.802389027964!2d73.07270005390626!3d19.264911799999997!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3be7bdf56871c2df%3A0x94051a18e85013de!2sMahindra%20Happinest!5e0!3m2!1sen!2sin!4v1680618881658!5m2!1sen!2sin",
  },
  {
    Address:
      "Mahindra Alcove Off, No1, Saki Vihar Complex Rd, Opposite Saki Vihar Complex Gate, Chandivali, Andheri (E) Mumbai-400072",
    title: "Mumbai, Andheri",
    value:
      "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3769.991256881715!2d72.88773281490175!3d19.108039487069505!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3be7c924f0b71d13%3A0x52e98c38b6844501!2sMahindra%20Alcove%2C%20Chandivali!5e0!3m2!1sen!2sin!4v1680619072281!5m2!1sen!2sin",
  },
  {
    Address:
      "Mahindra Happinest Developers Limited Gut No-158, 183(P) 183(P) / Plot No.1 Baswat Pada Road Village- Nandore, Taluka-Palghar District-Palghar-401404",
    title: "Mumbai, Palghar",
    value:
      "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d15022.052458879867!2d72.77661873932372!3d19.733342600000018!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3be71c657ff2ca25%3A0x8749a19713300fa9!2sShreeyash%20Consultancy!5e0!3m2!1sen!2sin!4v1680619368103!5m2!1sen!2sin",
  },
  {
    Address:
      "Mahindra Lifespace Developers Limited 5th Floor, Mahindra Towers, Comm. P K Kurne Chowk, Dr. G M Bhosale Marg, Worli, Mumbai, Pincode - 400 018",
    title: "Mumbai, Worli",
    value:
      "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d1586.0661086921036!2d72.82139066859429!3d19.005518702798376!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3be7ce941d496efb%3A0x915bcdb12427daa3!2sMahindra%20Lifespace%20Developers%20Limited!5e0!3m2!1sen!2sin!4v1680699289610!5m2!1sen!2sin",
  },
  {
    Address:
      "Mahindra Lifespace Developers Limited Surve No.157/1 (P) Situated Adjacent to MTU India P. Ltd Company Tathawade Taluka Mulshi Pune -411033",
    title: "Pune",
    value:
      "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3780.7743946877363!2d73.74112141489432!3d18.62921448734588!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bc2b996a82dc273%3A0x4bd01fabf83d5cf7!2sMahindra%20Happinest%20Tathawade%20%E2%80%93%20by%20Mahindra%20Lifespaces!5e0!3m2!1sen!2sin!4v1680619589750!5m2!1sen!2sin",
  },
  {
    Address:
      "Mahindra Bloomdale Developers Limited MIHAN SEZ, De-notified Area, Beside D.Y. Patil International School, Khapri, Nagpur-441 108",
    title: "Nagpur",
    value:
      "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3723.758257772817!2d79.03460621493286!3d21.04235658599094!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bd4be1401ede799%3A0xf7b14f5feaaf868!2sMahindra%20Bloomdale!5e0!3m2!1sen!2sin!4v1680619715344!5m2!1sen!2sin",
  },
];

export default function ITSupportPage() {
  const dispatch = useDispatch();

  let userProfile = JSON.parse(localStorage.getItem("userProfile"));
  const SectorId = userProfile?.sector;

  const [supportServices, setSupportServices] = useState([]);
  const [loading, setLoading] = useState(false);

  const [getSectorList, setGetSectorList] = useState([]);
  const [getDivisionList, setGetDivisionList] = useState([]);
  const [mapKey, setMapKey] = useState("");
  const [selectedValue, setSelectedValue] = React.useState(
    SectorId == "52304758" ? MLDLOption[0] : sectionOption[0]
  );

  function handleSelectChange(e, newValue) {
    setSelectedValue(newValue);
  }

  const [open, setOpen] = React.useState(false);
  const [modContent, setModContent] = useState("");
  const [serviceData, setServiceData] = useState([]);
  const [categoryType, setCategoryType] = useState("");
  const [categoryWiseData, setCategoryWiseData] = useState([]);
  const [selectedOptions, setSelectedOptions] = useState({
    sectorList: [],
    buList: [],
  });
  const [searchTerm, setSearchTerm] = useState("");

  const [selectedSectorOptions, setSelectedSectorOptions] = useState([]);
  const [selectedBusinessUnitOptions, setSelectedBusinessUnitOptions] =
    useState([]);
  const [sectorListData, setSectorListData] = useState([]);
  const [buisnessUnitList, setBuisnessUnitList] = useState([]);

  const [slectedBuRev, setSlectedBuRev] = useState([]);
  const [selectedSectorRev, setSelectedSectorRev] = useState([]);

  const [sectorData, setSectorData] = useState([]);
  //const [getDivisionList, setGetDivisionList] = useState([]);

  const [businessUnitData, setBusinessUnitData] = useState([]);
  const [refresh, setrefresh] = useState(false);
  //handle serach.
  const handleSearch = (e) => {
    setSearchTerm(e.target.value);
    if (e.target.value === "") {
      getServices();
    }
  };

  // console.log("user sector ======>", sector);

  //handle search icon for the search.
  const handleSearchIcon = () => {};

  //get log details for support services.

  //get log details for support serives.

  useEffect(() => {
    //anaylatics log save
    dispatch(
      addAnalayticsLog({
        module: "support service",

        browserDetails: `${platform.name} ${platform.os.architecture} ${platform.os.family} ${platform.os.version}`,

        deviceDetails: platform.product ? platform.product : "Desktop",

        isSearch: false,
      })
    );
    // you know what is this, don't you?
  }, []);

  //timer log....
  useEffect(() => {
    const interval = setInterval(() => {
      dispatch(addInterval(localStorage.getItem("logID")));
    }, 60000);
    return () => clearInterval(interval);
  }, []);

  //get all sectors.
  useEffect(() => {
    (async () => {
      try {
        setLoading(true);
        const res = await services.api(
          "GET",
          `/ui-assist/v1/sectorsupportservices`
        );
        if (res.status === 200) {
          setLoading(false);
          const allSectorItem = res?.data?.map((el) => {
            return {
              title: el.sectorName,
              value: el.id,
            };
          });
          setSectorData(allSectorItem);
          setSelectedSectorOptions(allSectorItem);
          getSelectedSectorOptions(allSectorItem);
          //setBuisnessUnitList()
        }
      } catch (err) {
        setLoading(false);
        Swal.fire("Oops!", `${err.response.data}`, "error");
        setSectorData([]);
      }
    })();
  }, []);

  //filter api.

  useEffect(() => {
    (async () => {
      try {
        setLoading(true);
        const res = await services.api(
          "POST",
          "/ui-assist/v1/filter/supportservices",
          {
            sectorList: sectorListData,

            buList: buisnessUnitList,

            key: searchTerm,

            page: 0,

            offset: 1000,

            start: true,
          }
        );
        if (res.status === 200) {
          setLoading(false);
          setSupportServices(res.data);
        }
      } catch (error) {
        setLoading(false);
        console.log(error);
      }
    })();
  }, [mapKey, sectorListData, buisnessUnitList]);

  //to get selected sector list.
  useEffect(() => {
    let dataSectorList = selectedSectorOptions?.map((el) => {
      return el.value;
    });

    let sectorList = selectedSectorOptions?.map((el) => {
      return {
        sectorId: el.value,
        sectorName: el.title,
      };
    });
    let sectorList2 = selectedSectorOptions?.map((el) => el.value);
    setSelectedSectorRev(sectorList);
    setSectorListData(sectorList2);
  }, [selectedSectorOptions]);

  //to get selected bu list.
  useEffect(() => {
    let dataBuList = selectedBusinessUnitOptions?.map((el) => {
      return el.value;
    });
    let buList = selectedBusinessUnitOptions?.map((el) => {
      return {
        buId: el.value,
        buName: el.title,
      };
    });
    setSlectedBuRev(buList);
    setBuisnessUnitList(dataBuList);
  }, [selectedBusinessUnitOptions]);

  //HANDLE DROPDOWN'S
  //handle toggle for all the dropdowns
  const handleToggleOption = (selectedOptions, name) => {
    if (name === "sector") {
      setSelectedSectorOptions(selectedOptions);
      getSelectedSectorOptions(selectedOptions);
    } else if (name === "bu") {
      setSelectedBusinessUnitOptions(selectedOptions);
    }
  };
  console.log(buisnessUnitList, sectorListData, "sector, bu");
  //handle clear for all the dropdown's
  const handleClearOptions = (isSelected, name) => {
    if (name === "sector") {
      setSelectedSectorOptions([]);
      setSelectedBusinessUnitOptions([]);
      setBusinessUnitData([]);
    } else if (name === "bu") {
      setSelectedBusinessUnitOptions([]);
      //setSelectedSectorOptions([]);
      // setBusinessUnitData([]);
    }
  };

  //handle select all for dropdown's.
  const handleSelectAll = (isSelected, name) => {
    if (isSelected) {
      if (name === "sector") {
        setSelectedSectorOptions(sectorData);
        getSelectedSectorOptions(sectorData);
      } else if (name === "bu") {
        setSelectedBusinessUnitOptions(businessUnitData);
      } else {
        handleClearOptions();
      }
    }
  };

  const handleClickOpen = (ev, mod, categoryType, categoryWiseData) => {
    setModContent(mod);
    // setServiceData(data);
    setOpen(true);
    if (categoryType) {
      setCategoryWiseData(categoryWiseData);
      setCategoryType(categoryType);
    }
  };
  const handleClose = () => {
    setOpen(false);
  };

  const openSupportAdminModal = () => {
    return (
      <CommonModal
        open={open}
        handleClose={handleClose}
        header={true}
        footer={false}
        headerText={
          modContent === "category" ? "Edit categories" : `Edit ${categoryType}`
        }
        icon={editIcon}
        modalSize="lg"
        fullWidth
        // submitBtnText="Save Changes"
        onButtonClick={handleClose}
        // buttonStyling={true}
      >
        {modContent === "category" ? (
          <ITSupportCategoryAdmin refresh={refresh} setrefresh={setrefresh} />
        ) : (
          <ITSupportAdminComponent
            categoryType={categoryType}
            categoryWiseData={categoryWiseData}
            getServices={getServices}
            handleClose={handleClose}
          />
        )}
      </CommonModal>
    );
  };

  const getServices = async () => {
    try {
      setLoading(true);
      let res = await services.api(
        "POST",
        "/ui-assist/v1/filter/supportservices",
        {
          sectorList: sectorListData,

          buList: buisnessUnitList,

          key: searchTerm,

          page: 0,

          offset: 1000,

          start: true,
        }
      );
      if (res.status === 200) {
        setLoading(false);
        setSupportServices(res.data);
      }
    } catch (err) {
      setLoading(false);
      console.log("error from support services get api", err);
    }
  };

  useEffect(() => {
    getServices();
  }, [refresh]);

  const handleLink = (link) => {
    window.open(link?.actionUrl ? link.actionUrl : "", "_blank");
  };
  const getIcon = (icon) => {
    if (icon === "Contact - book") {
      return ITcomputer;
    } else if (icon === "Computer - line") {
      return ITcomputer;
    } else if (icon === "Contact - fill") {
      return ITcomputer;
    } else if (icon === "Coupon - line") {
      return ITcomputer;
    }
  };

  const getBuFromSector = async (sector) => {
    const getSectors = sector.map((result) => {
      return {
        addsectorId: result.sectorCode,
      };
    });

    try {
      setLoading(true);
      let res = await services.api(
        "POST",
        `/ui-assist/v1/businessunit`,
        getSectors
      );
      if (res.status === 200) {
        setLoading(false);
        let list = res.data.map((result) => {
          return {
            title: result.buName,
            value: result.buName,
            id: result.id,
          };
        });

        setGetDivisionList(list);
      }
    } catch (err) {
      setLoading(false);
      // Swal.fire("Oops!", `${err.response.data}`, "error");
      setGetDivisionList([]);
    }
  };

  const getFilterByBu = async (newValue) => {
    try {
      setLoading(true);
      let getAllId = newValue.map((result) => {
        return { addBuId: result.id };
      });
      let res = await services.api(
        "POST",
        `/ui-assist/v1/supportservicefilterbu`,
        getAllId
      );
      if (res.status === 200) {
        setLoading(false);
        setSupportServices(res.data);
      }
    } catch (err) {
      setLoading(false);
      setSupportServices([]);
      console.log("error from filter for It support page");
    }
  };

  const getOptionLabel = (option) => `${option.title}`;
  const getOptionDisabled = (option) => option.value === "foo";

  useEffect(async () => {
    let userProfile = JSON.parse(
      localStorage.getItem("userProfile")
        ? localStorage.getItem("userProfile")
        : null
    );
    try {
      setLoading(true);
      let res = await services.api(
        "GET",
        `/ui-assist/v1/sectors?compcode=${userProfile.company}`
      );
      if (res.status === 200) {
        setLoading(false);
        let list = res.data.map((result) => {
          return {
            title: result.sectorName,
            value: result.sectorName,
            sectorCode: result.sectorCode,
          };
        });
        setGetSectorList(list);
      }
    } catch (err) {
      setLoading(false);
      // Swal.fire("Oops!", `${err.response.data}`, "error");
      setGetSectorList([]);
    }
  }, []);

  const getSelectedSectorOptions = (selectedOptions) => {
    const xyz = selectedOptions.map((el) => {
      return {
        addsectorId: el.value,
      };
    });
    getBusinessUnitBySector(xyz);
  };

  //get business unit on the sector params.
  const getBusinessUnitBySector = async (data) => {
    try {
      setLoading(true);
      let res = await services.api("POST", `/ui-assist/v1/businessunit`, data);
      if (res.status === 200) {
        setLoading(false);
        let list = res.data.map((result) => {
          return {
            title: result.buName,
            value: result.id,
          };
        });
        let divisionList = res.data.map((result) => {
          return {
            addBuId: result.id,
          };
        });
        setSelectedBusinessUnitOptions(list);
        setBusinessUnitData(list);
      }
    } catch (err) {
      setLoading(false);
      Swal.fire("Oops!", `${err.response.data}`, "error");
    }
  };

  //handleKeyPress
  const onKeyPress = (e) => {
    if (e.key === "Enter") {
      setMapKey("Enter");
    } else {
      setMapKey("");
    }
  };
  const isMldl =
    JSON.parse(localStorage.getItem("userProfile")).sectorName ===
      "Real Estate Sector" &&
    JSON.parse(localStorage.getItem("userProfile")).sector === "52304758";

  return (
    <section className="supportSectionContainer">
      <Template>
        {loading ? <Loader /> : null}
        <div>
          <Grid
            container
            direction="row"
            justifyContent="space-between"
            alignItems="flex-end"
          >
            <Grid item md={7} xs={12}>
              <div className="whatsNewHeader">
                <div className="containerTxt">
                  <div className="section-header-text">Support services</div>
                  {!isMldl && (
                    <PageTutorialComponent
                      modelHeadtxt={"Livewire Tutorial"}
                      videoLink={
                        "https://mazpmodsa01.blob.core.windows.net/mrisestorage/Core%20Intranet%20V3.mp4_1706088702435.mp4"
                      }
                    />
                  )}

                  {isAccesible("SUPPORT_SERVICES") && (
                    <div
                      className="editIcon"
                      onClick={(event) => handleClickOpen(event, "category")}
                    >
                      <img
                        src={
                          process.env.PUBLIC_URL + "/iconImages/MC_admin.svg"
                        }
                        alt=""
                        className="edit-line"
                      />
                      <div>EDIT CATEGORIES </div>
                    </div>
                  )}
                </div>
              </div>
            </Grid>
          </Grid>
        </div>

        <Grid
          container
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          className="supportContainer mt-3"
        >
          <Grid
            container
            spacing={2}
            justifyContent="space-between"
            xs={12}
            sm={6}
          >
            <Grid
              item
              xs={12}
              sm={6}
              className="buSectorITsupportPage searchFilterMultiSelect supportServiceSector supportServiceDropCss"
            >
              {/* <MultiSelect
                className="width100"
                // handleSelectChange={handleSelectChange}
                // selectedValue={selectedValue}
                options={getSectorList?.length && getSectorList}
                style={style}
                name="sector"
                // handleSelectChange={(e, value) => {
                //   getDivsionBySector(value);
                //   formik.setFieldValue("sector", value);
                // }}
                // handleBlur={formik.handleBlur}
                // errorMsg={
                //   formik.errors.sector && formik.touched.sector
                //     ? formik.errors.sector
                //     : ""
                // }
                placeHolder="Sector"
                label={<CommonLabel mandatory />}
              /> */}
              <CustomMultiSelect
                items={sectorData}
                getOptionLabel={getOptionLabel}
                getOptionDisabled={getOptionDisabled}
                selectedValues={selectedSectorOptions}
                label={<CommonLabel label="Sector" />}
                selectAllLabel="Select all"
                onToggleOption={(isSelected) => {
                  handleToggleOption(isSelected, "sector");
                }}
                onClearOptions={(isSelected) => {
                  handleClearOptions(isSelected, "sector");
                }}
                onSelectAll={(isSelected) => {
                  handleSelectAll(isSelected, "sector");
                }}
              />
            </Grid>
            <Grid
              item
              xs={12}
              sm={6}
              className="buSectorITsupportPage searchFilterMultiSelect supportServiceSector supportServiceDropCss ssMB"
            >
              {/* <MultiSelect
                // disabled={!getDivisionList?.length}
                className="width100"
                // handleSelectChange={handleSelectChange}
                // selectedValue={selectedValue}
                options={getDivisionList?.length && getDivisionList}
                style={style}
                name="division"
                // handleSelectChange={(e, value) => {
                //   formik.setFieldValue("division", value);
                // }}
                // handleBlur={formik.handleBlur}
                // errorMsg={
                //   formik.errors.division && formik.touched.division
                //     ? formik.errors.division
                //     : ""
                // }
                placeHolder="Business unit"
                label={<CommonLabel mandatory />}
              /> */}
              <CustomMultiSelect
                items={businessUnitData}
                getOptionLabel={getOptionLabel}
                getOptionDisabled={getOptionDisabled}
                selectedValues={selectedBusinessUnitOptions}
                label={<CommonLabel label="Businesses" />}
                selectAllLabel="Select all"
                onToggleOption={(isSelected) => {
                  handleToggleOption(isSelected, "bu");
                }}
                onClearOptions={(isSelected) => {
                  handleClearOptions(isSelected, "bu");
                }}
                onSelectAll={(isSelected) => {
                  handleSelectAll(isSelected, "bu");
                }}
              />
            </Grid>
          </Grid>
          <Grid item xs={12} sm={4} className="searchSupportServiceBanner">
            <SearchBarButton
              handleSearch={handleSearch}
              onKeyPress={onKeyPress}
              handleSearchIcon={handleSearchIcon}
            ></SearchBarButton>
          </Grid>
          <Grid
            container
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            className="supportContainer supportMainContainer"
          >
            {supportServices.length == 0 && (
              <div style={{ width: "100%" }}>
                <Grid container direction="row">
                  <Grid item xs={11}>
                    &nbsp;
                    <span
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        height: "20vh",
                        width: "100%",

                        color: "#e31837",
                      }}
                    >
                      No Data Available
                    </span>
                  </Grid>
                  <Grid item xs={1}>
                    {isAccesible("SUPPORT_SERVICES") && (
                      <div
                        className="editIcon"
                        onClick={(event) =>
                          handleClickOpen(event, "editSupport", "", "")
                        }
                      >
                        <img src={editIconRed} alt="" className="edit-line" />
                        <div>ADD</div>
                      </div>
                    )}
                  </Grid>
                </Grid>
              </div>
            )}
            {supportServices &&
              supportServices?.map((item, index) => (
                <div key={index} style={{ width: "100%" }}>
                  <Grid container direction="row">
                    <Grid item xs={11}>
                      <div className="serviceCardHeader">{item.title}</div>
                    </Grid>
                    <Grid item xs={1}>
                      {isAccesible("SUPPORT_SERVICES") && (
                        <div
                          className="editIcon"
                          onClick={(event) =>
                            handleClickOpen(
                              event,
                              "editSupport",
                              item.title,
                              item.childs
                            )
                          }
                        >
                          <img src={editIconRed} alt="" className="edit-line" />
                          <div>EDIT</div>
                        </div>
                      )}
                    </Grid>
                  </Grid>
                  {item?.childs?.map((subItem, subIndex) => (
                    <div key={subIndex} className="serviceCardData">
                      <Grid container direction="row">
                        <Grid item xs={12} sm={3}>
                          <div className="serviceNameContainer">
                            <img
                              src={
                                subItem.icon
                                  ? getIcon(subItem.icon)
                                  : subItem.image
                              }
                              alt=""
                              className="supportServicesIcon"
                            />
                            <div className="dataName">
                              {typeof subItem.category === "string" ||
                              subItem.category instanceof String
                                ? subItem.title
                                : subItem.title}
                            </div>
                          </div>
                        </Grid>
                        <Grid item xs={12} sm={5}>
                          <div
                            className="dataDesc notificationsMsg"
                            title={subItem.description}
                          >
                            {subItem.description}
                          </div>
                        </Grid>
                        <Grid item xs={12} sm={4}>
                          <div className="dataContactDetail">
                            {subItem?.phoneNumbers?.map((el) => {
                              return (
                                <>
                                  <div className="contactData">
                                    {el.name && el.name > 0 && (
                                      <span className="pertext">
                                        {el?.name}
                                      </span>
                                    )}
                                    {el.phoneNumber}
                                  </div>
                                </>
                              );
                            })}

                            <div className="contactData">
                              {subItem?.websites?.map((el) => {
                                return <div className="email">{el}</div>;
                              })}
                            </div>

                            <div className="contactData">
                              {subItem?.emails?.map((el) => {
                                return <div className="email">{el}</div>;
                              })}
                            </div>
                            <div
                              className="linkStyl"
                              onClick={() => handleLink(subItem)}
                            >
                              <span>
                                {subItem?.actionName
                                  ? subItem.actionName
                                  : subItem?.actionUrl}
                              </span>
                            </div>
                          </div>
                        </Grid>
                      </Grid>
                    </div>
                  ))}
                </div>
              ))}
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <div className="EthicsSubTitlte">
            <>Contact us</>
          </div>
        </Grid>
        <section style={{ width: "100%" }}>
          <Grid
            container
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            className="headerDiv"
          >
            <Grid item xs={12} sm={5}>
              <Card variant="outlined" className="EthicsCardHeader">
                <CardContent className="EthicsHeaderCardContent">
                  <div>
                    <Grid
                      container
                      direction="column"
                      className="EthicsCardHeaderDetailinfra"
                    >
                      <Grid item sm={11} className="EthicsHeaderTitle mbLabel">
                        <CommonAutocomplete
                          className="width100"
                          handleSelectChange={handleSelectChange}
                          selectedValue={selectedValue}
                          options={
                            SectorId == "52304758" ? MLDLOption : sectionOption
                          }
                          //onChange={ handleInputChange }
                          style={style}
                          label={<CommonLabel label="Select Location" />}
                        />
                      </Grid>
                      <div className="EthicsHeaderData InfraFlex">
                        <img
                          src={InfraBuilding}
                          alt="Download line"
                          className=" infraImg"
                        />
                        <div>
                          <div className="InfraBuildingData1 fFSaira">
                            {selectedValue.Address}
                          </div>
                        </div>
                      </div>
                      {/* <div className="EthicsHeaderData InfraFlex">
                        <img
                          src={InfraPhone}
                          alt="Download line"
                          className=" infraImg"
                        />
                        <div>
                          <div className="InfraBuildingData1">
                            {selectedValue.Contact}
                          </div>
                        </div>
                      </div> */}
                    </Grid>
                  </div>
                </CardContent>
              </Card>
            </Grid>
            <Grid item xs={12} sm={7} style={{ height: "200px" }}>
              <iframe
                src={selectedValue.value}
                allowfullscreen=""
                loading="lazy"
                className="iframeInfra"
              ></iframe>
            </Grid>
          </Grid>
        </section>
      </Template>
      <>{openSupportAdminModal()}</>
    </section>
  );
}
