import React, { useEffect, useState } from "react";
import "./RemoteEditModal.css";
import CommonModal from "../../Common/CommonModal/CommonModal";
import SearchBarButton from "../../Common/SearchBar/SearchBarButton";
import {
  Grid,
  Paper,
  List,
  ListItem,
  ListItemIcon,
  Button,
} from "@material-ui/core";
import EditIcon from "../../../Assets/Images/edit-line.svg";
import CommonAutocomplete from "../../Common/CommonAutocomplete/CommonAutocomplete";
import CommonMultiAutocomplete from "../../Common/CommonMultiAutocomplete/CommonMultiAutocomplete";
import CommonLabel from "../../Common/ComonLabel/CommonLabel";
import { useDispatch, useSelector } from "react-redux";
import SubmitButton from "../../Common/Button/SubmitButton";
import { useHistory } from "react-router-dom";
import { getRemoteWorkingData } from "../../../Redux/Actions/RemoteWorkingAction";
import {
  getAllYearsWithMonths,
  getFormattedDate,
  months,
} from "../../../Assets/helperFunctions/helperFunctions";
import { RemoteAArticle, Support } from "../../../Routes/constant";
import { getLastModifiedData } from "../../../Redux/Actions/CommonActions";

const RemoteEditModal = ({
  open,
  handleClose,
  headerText,
  icon,
  modalSize,
  header,
  footer,
  fullWidth,
  selectedType,
  dateFromList,
}) => {
  //checkbox
  const focusStyle = { color: "#db233a" };
  const [focus, setFocus] = useState(false);
  const history = useHistory();
  const dispatch = useDispatch();
  const remoteWorkingList = useSelector(
    (state) => state.getRemoteWorkingReducer
  );

  const lastModified = useSelector((state) => {
    return state?.getLastModifiedDataReducer?.lastModifiedRemoteWork;
  });

  //calender
  const calSelectYearOptions = getAllYearsWithMonths();

  //calender
  const [calYearValue, setCalYearValue] = useState("");
  const [monthValue, setMonthValue] = useState("");
  const [accordianState, setAccordianState] = useState(false);
  const [modelSelectedDate, setModelSelectedDate] = useState(dateFromList);

  const [x, setx] = useState(null);
  const keywordsCharLeft = "Write upto 3";

  //MARK:-
  useEffect(() => {
    dispatch(getRemoteWorkingData(selectedType, modelSelectedDate, "desc"));
  }, [modelSelectedDate, dispatch]);

  useEffect(() => {
    dispatch(getLastModifiedData("remoteworking", selectedType));
  }, [selectedType]);

  //picking the month dropdown according to selected year
  const handleCalYearChange = (e, selectType) => {
    setCalYearValue(selectType);
    setModelSelectedDate(`${selectType.year}-${selectType.selectedMonth}-1`);
  };

  const handleCloseModal = () => {
    handleClose();
  };

  const onButtonClick = () => {
    handleClose();
  };
  let path = `${Support}${RemoteAArticle}`;
  return (
    <>
      <CommonModal
        open={open}
        handleClose={handleCloseModal}
        headerText={headerText}
        icon={icon}
        modalSize={modalSize}
        header={header}
        footer={footer}
        fullWidth={fullWidth}
        backgroundColor
        icon={EditIcon}
        label="SAVE CHANGES"
        className="bannerModalEdit"
        submitBtnText="SAVE CHANGES"
        onButtonClick={onButtonClick}
      >
        <div className="favTabModal remoteModalContainer">
          <div className="remoteMHeader">
            <div className="headerModifyOrg" style={{ paddingLeft: "0px" }}>
              <div className="modifiedName">
                <div className="NameTitle">Last modified by</div>
                <div className="nameT">
                  {lastModified?.name ? lastModified?.name : "Not Available"}
                </div>
              </div>
              <div className="modifiedDate">
                <div className="NameTime">Modified on</div>
                <div className="time">
                  {lastModified?.date
                    ? getFormattedDate(lastModified?.date)
                    : "Not Available"}
                </div>
              </div>
            </div>
            <div>
              <SubmitButton
                label="UPLOAD AN ARTICLE"
                onClick={() =>
                  history.push("/connect/support/upload-article/add")
                }
              />
            </div>
          </div>

          <div className="bannerTitleBtnOrg">
            <div className="alignBtnRight liveWireDropDownContainer calenderAlign RemoteModalAdminC">
              {/*<NestedSearchCheckBox item={menuItem} /> */}
              <div className="calenderTypeIcon calendarMaxWidth orgAdminCalender">
                <CommonAutocomplete
                  options={calSelectYearOptions}
                  label={<CommonLabel label="Calender" />}
                  handleSelectChange={handleCalYearChange}
                  selectedValue={remoteWorkingList?.remoteFilterDate}
                  className="McShadowBox"
                />
              </div>

              <div className="bannerSearchButtonOrg">
                <SearchBarButton placeholder="Search articles or videos" />
              </div>
            </div>
          </div>
          <div className="whatsNewAdminListContainer">
            <div className="setscrollRemote">
              <Grid container>
                <Grid item xs={12}>
                  {remoteWorkingList.remoteWorkingData?.map((result, index) => {
                    return (
                      <Paper elevation={0} className="remotePaper">
                        <Grid
                          container
                          justifyContent="space-evenly"
                          alignItems="center"
                          style={{ height: "100%" }}
                        >
                          <Grid item sm={8}>
                            <div className="DetailRemotePaper">
                              {result.title}
                            </div>
                            <div className="subDetailRemotePaper">
                              {result.subTitle}
                            </div>
                          </Grid>
                          <Grid item sm={2}>
                            <div
                              className="viewRemoteSpan"
                              onClick={() =>
                                history.push({
                                  pathname: `/connect/support/upload-article/${result.id}`,
                                  state: { id: result.id },
                                })
                              }
                            >
                              VIEW
                            </div>
                          </Grid>
                        </Grid>
                      </Paper>
                    );
                  })}
                </Grid>
              </Grid>
            </div>
          </div>
        </div>
      </CommonModal>
    </>
  );
};

export default RemoteEditModal;
