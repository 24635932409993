import React, { useState, useEffect } from "react";
import "./Articlecomment.css";
import "../LiveWireMcArticle2/LiveWireMcArticle2.css";
import CommentForm from "./CommentForm";
import chatRed from "../../../Assets/Images/chat_red.svg";
import heartRed from "../../../Assets/Images/heart_red.svg";
import Delete from "../../../Assets/Images/delete.svg";

import { Paper, Grid } from "@material-ui/core";
import Avatar from "@material-ui/core/Avatar";
import services from "../../../Services";

function Comment({
  comment,
  replies,
  repliesCount,
  currentUserId,
  deleteComment,
  activeState,
  setActiveState,
  addComment,
  updateComment,
  setLikeData,
  parentId = null,
  innerR,
  articleID,
  likedCount,
}) {
  const fiveSeconds = 60000;
  const timePassed =
    new Date().getTime() - new Date(comment.createdAt).getTime() > fiveSeconds;
  const canReply = Boolean(currentUserId);
  const [toggle, setToggle] = useState(false);

  const canEdit = comment.employeeId === currentUserId;
  const canDelete = comment.employeeId === currentUserId;
  const createdAt = new Date(comment.createdAt).toLocaleDateString();
  const isReplying =
    activeState &&
    activeState.type === "replying" &&
    comment.id === activeState.id &&
    toggle === true;
  const isEditing =
    activeState &&
    activeState.type === "editing" &&
    comment.id === activeState.id;
  const replyId = parentId ? parentId : comment.id;

  const [widthAC, setwidthAC] = useState(false);

  const ReplyF = () => {
    setToggle(!toggle);
    setActiveState({ id: comment.id, type: "replying" });
    setwidthAC(!widthAC);
  };

  // useEffect(() => {
  //  setLikeCount(likedCount)
  // }, [likedCount]);

  const [likeCount, setLikeCount] = useState(likedCount);

  const handleLike = (e, comment) => {
    console.log(comment, 'comment>>>>>')
    console.log(comment.contentType, 'content type...')
    let data = {
      id: comment.id,
      comment: comment.comment,
      createdAt: comment.createdAt,
      updatedAt: comment.updatedAt,
      type: comment.type,
      employeeId: localStorage.getItem("token"),
      contentType: comment.contentType,

      likedBy: [],
      parentId: comment.parentId,
      contenId: comment.contenId,
    };

    handleLikeApi(data);
  };

  const handleLikeApi = async (data) => {
    try {
      console.log('i was liked.....')
      const res = await services.api("POST", `/ui-assist/v1/comment`, data);
      setLikeCount(res?.data?.likedBy?.length);
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div key={comment.commentId} className="comment" label="reply">
      <Paper
        className={`commentDivReply mt paperH  ${
          widthAC ? `widthInnersetAfterClick` : `widthInnerset`
        }`}
      >
        <Grid container direction="row" justifyContent="flex-start" spacing={2}>
          <Grid item xs={12} sm={12} md={12}>
            <div>
              <div className="comment-header">
                <div className="lefthead">
                  <Avatar className="mrAvatar">
                    <img
                      src={comment.empPhotoUrl}
                      alt="avatar"
                      className="avatarComment"
                    />
                  </Avatar>
                </div>
                <div className="righthead">
                  <div className="comment-username">{comment.username}</div>
                  <div className="comment-date">{createdAt}</div>
                </div>
              </div>
              {/* <div className="flex">
                <p className="nameDateMbMt nameSize">{comment.username}</p>
                
                <p className="nameDateMbMt dateSize">{createdAt}</p>
              </div> */}
              <div className="Commentfooter">
                {!isEditing && (
                  <div className="commentMbMt">{comment.comment}</div>
                )}
                {isEditing && (
                  <CommentForm
                    label="Edit"
                    hasCancellButton
                    initialText={comment.comment}
                    handleSubmit={(text) => {
                      updateComment(text, comment.id);
                    }}
                    handleCancel={() => {
                      setActiveState(null);
                    }}
                  />
                )}
                <div className="flex cRed">
                  <div className="flex">
                    <div
                      style={{ cursor: "pointer" }}
                      onClick={(e) => handleLike(e, comment)}
                      className="pR"
                    >
                      <img src={heartRed} alt="like" />
                    </div>
                    <p className="likeCountMbMt countSize">{likeCount}</p>
                  </div>

                  <div className="flex">
                    {replies && replies.length > 0 && (
                      <>
                        <div className="mlimg pR">
                          <img src={chatRed} alt="replies" />
                        </div>
                        <p className="likeCountMbMt countSize">
                          {replies?.length}
                        </p>{" "}
                      </>
                    )}
                    <div
                      style={{
                        display: "flex",
                        marginLeft: "16px",
                        zIndex: "99",
                      }}
                    >
                      {canReply && (
                        <p className="mlP likeCountMbMt reply" onClick={ReplyF}>
                          REPLY
                        </p>
                      )}
                      {canEdit && (
                        <div
                          className="mlP likeCountMbMt reply"
                          onClick={() =>
                            setActiveState({ id: comment.id, type: "editing" })
                          }
                        >
                          EDIT
                        </div>
                      )}
                      {canDelete && (
                        <div
                          className="mlP likeCountMbMt reply deleteComment"
                          onClick={() => deleteComment(comment.id)}
                        >
                          <img src={Delete} alt="DELETE" />
                        </div>
                      )}
                    </div>
                  </div>
                </div>

                {isReplying && (
                  <CommentForm
                    submitLabel="Replying"
                    handleSubmit={(text) => addComment(text, replyId)}
                    commentBoxStyle={innerR ? `innerR` : `R`}
                  />
                )}
                {replies && replies.length > 0 && (
                  <div className="replies wR repliesVerticalLine">
                    {replies.map((reply) => (
                      <div style={{ display: "flex", height: "auto" }}>
                        <Comment
                          comment={reply}
                          key={reply.id}
                          replies={[]}
                          currentUserId={currentUserId}
                          activeState={activeState}
                          setActiveState={setActiveState}
                          addComment={addComment}
                          parentId={comment.id}
                          deleteComment={deleteComment}
                          updateComment={updateComment}
                          innerR={true}
                          likedCount={reply?.likedBy?.length}
                        />
                      </div>
                    ))}
                  </div>
                )}
              </div>
            </div>
          </Grid>
        </Grid>
      </Paper>
    </div>
  );
}

export default Comment;
