import React from "react";
import "./EthicsHelpLine.css";
import { Grid } from "@material-ui/core";
import Template from "../../Common/Template/Template";
import "../Policies/PoliciesComponent.css";
import SearchBarButton from "../../Common/SearchBar/SearchBarButton";
import EthicsWhatsNewCard from "./EthicsWhatsNewCard";
import { useMediaQuery, useTheme } from "@material-ui/core";
// import EthicsCollapse from "./EthicsMobileComponent/EthicsCollapse";
import EthicsMobileCard from "./EthicsMobileComponent/EthicsMobileCard";
import { cardDataEthics } from "./EthicsData";
import EthicsHelpLineBanner from "./EthicsHelpLineBanner";

export default function EthicsHelpLine() {
  const [placeholder] = React.useState(
    "Search people, tags, sectors or a story name"
  );
  //breakPoint
  const Bp = useTheme();
  const isXs = useMediaQuery(Bp.breakpoints.down("xs"));

  return (
    <section>
      <Template>
        <Grid
          container
          direction="row"
          justifyContent="space-between"
          alignItems="center"
        >
          <Grid item xs={12} sm={7}>
            <p className="offCHeaderTitle">Ethics helpline</p>
          </Grid>
          <Grid item xs={12} sm={5}>
            <div className="policiesSearchWidth">
              <SearchBarButton placeholder={placeholder} />
            </div>
          </Grid>
        </Grid>
        {/* <Grid
                    container
                    direction="row"
                    justifyContent="space-between"
                    alignItems="center"
                    className="fFSaira"
                > */}
        {/* <Grid item xs={12}>
                        <div className="EthicsSubTitlte">What’s new</div>
                    </Grid> */}
        {/* {isXs ? (
                        <>
                            <EthicsCollapse /> <EthicsCollapse /> <EthicsCollapse />
                        </>
                    ) : (
                        <> */}
        {/* <EthicsHelpLineBanner /> */}
        {/* </>
                    )} */}
        {/* </Grid> */}
        <Grid
          container
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          className="FooterCardDiv"
        >
          <Grid item xs={12}>
            <div className="EthicsSubTitlte mbEthics">Services</div>
          </Grid>
          <Grid item xs={5}>
                {cardDataEthics.map((item, index) => (
                  <Grid item xs={6}>
                    <div key={index}>
                      <EthicsWhatsNewCard EthicsCardData={item} />
                    </div>
                  </Grid>
                ))}
          </Grid>
        </Grid>
      </Template>
    </section>
  );
}
