import services from "../../Services/index";
import Swal from "sweetalert2";
import {
    GET_APPROVE_WALLPAPER_REQUEST_SUCCESS,
    GET_APPROVE_WALLPAPER_REQUEST_LOAD,
    GET_APPROVE_WALLPAPER_REQUEST_FAILED,
} from "../Contants/Constants";

const getApproveWallpaperRequestLoad = () => ({
    type: GET_APPROVE_WALLPAPER_REQUEST_LOAD,
});
const getApproveWallpaperRequestSuccess = (data) => ({
    type: GET_APPROVE_WALLPAPER_REQUEST_SUCCESS,
    payload: data,
});
const getApproveWallpaperRequestFailed = (error) => ({
    type: GET_APPROVE_WALLPAPER_REQUEST_FAILED,
    err: error,
});

export const getApproveWallpaperRequestData = (id,sDate,eDate,location,sector) => async (dispatch) => {
  dispatch(getApproveWallpaperRequestLoad());
  try {
    const res = await services.api(
      "POST",
      `/ui-assist/v1/wallpaper/approve/${id}?proposedStartDate=${sDate}&proposedEndDate=${eDate}&locationName=${location}&sector=${sector}`
    );
    if (res) {
      dispatch(getApproveWallpaperRequestSuccess(res.data));
    }
    if (res.status === 201) {
        Swal.fire(
              "Successfully approved!",
              " ",
              "success"
            );
      } else {
        Swal.fire("Oops", "Server error", "file not uploaded");
      }
  } catch (err) {
    dispatch(getApproveWallpaperRequestFailed(err.response));
    Swal.fire("Something went wrong!", `${err?.response?.data}`, "error");
  }
};