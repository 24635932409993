import services from "../../Services/index";
import axios from 'axios'
//import { useLocation } from 'react-router-dom';
export const addAnalayticsLog = (data) => async () => {
  try {
    const res = await services.api("POST", `/ui-assist/log`, data);

    if (res.status === 200) {
      localStorage.setItem("logID", res?.data?.id)
      console.log(localStorage.getItem("logID", "log id...."));
    }
  } catch (err) {
    console.log("analytics log error");
  }
};

export const addInterval = (id) => async () => {
  try {
    //const res = services.api("PUT", `/ui-assist/log?id=${id}`);
    const res = axios.put(`${process.env.REACT_APP_API_BASE_URL}/ui-assist/log?id=${id}`)
    if (res.status === 200) {
      console.log("Log saved");
    }
  } catch (err) {
    console.log("analytics log error");
  }
};
