import React, { useState } from "react";
import "./DatePicker.css";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
// import { Icon } from "@material-ui/core";
import MomentUtils from "@date-io/moment";
import { KeyboardDatePicker } from "@material-ui/pickers";
import textFieldStyle from "../../Common/Styles/TextFieldStyle";
import dateIcon from "../../../Assets/Images/date.svg";
import dateIconHover from "../../../Assets/Images/date-fill.svg";
import downA from "../../../Assets/Images/downA.svg";

function DatePickerOrg({
    handleDateChange,
    selectedDate,
    label,
    name,
    errorMsg,
    handleBlur,
    disabled,
    disableToolbarr,
}) {
    const classes = textFieldStyle();
    const [hoverDate, setHoverDate] = useState(false);
    const handleMouseEnter = () => {
        setHoverDate(true);
    };
    const handleMouseLeave = () => {
        setHoverDate(false);
    };
    const dateIconSelection = selectedDate ? downA : downA ? downA : downA;

    return (
        <div className="dateO">
            {/* <TextField
        className={`${classes.root} datePickerStyle`}
        id="date"
        name={name}
        label={label}
        type="date"
        value={selectedDate}
        onChange={handleDateChange}
        variant="outlined"
        format="DD-MM-YYYY"
        InputLabelProps={{
          shrink: true,
        }}
      /> */}
            <MuiPickersUtilsProvider utils={MomentUtils}>
                <KeyboardDatePicker
                    disabled={disabled}
                    className={`${classes.root} datePickerStyle`}
                    disableToolbar={disableToolbarr}
                    inputVariant="outlined"
                    format="MM/YYYY"
                    margin="normal"
                    label={label}
                    value={selectedDate}
                    onChange={handleDateChange}
                    onBlur={handleBlur}
                    openTo="year"
                    views={["year", "month"]}
                    KeyboardButtonProps={{
                        "aria-label": "change date",
                    }}
                    error={errorMsg}
                    helperText={errorMsg}
                    keyboardIcon={
                        // <Icon
                        //   onMouseEnter={handleMouseEnter}
                        //   onMouseLeave={handleMouseLeave}
                        // >
                        <img
                            onMouseEnter={handleMouseEnter}
                            onMouseLeave={handleMouseLeave}
                            src={dateIconSelection}
                            alt="keyboard icon"
                        />
                        // </Icon>
                    }
                />
            </MuiPickersUtilsProvider>
        </div>
    );
}

export default DatePickerOrg;
