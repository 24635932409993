import {
    GET_WELCOME_DATA_LOAD,
    GET_WELCOME_DATA_FAILED,
    GET_WELCOME_DATA_SUCCESS,
    GET_ALL_WELCOME_DATA_LOAD,
    GET_ALL_WELCOME_DATA_FAILED,
    GET_ALL_WELCOME_DATA_SUCCESS,
  } from "../Contants/Constants";
  
  const initialState = {
    getData: [],
    isLoading: false,
    isError: "",
  };
  
  export const getWelcomeDataReducer = (state = initialState, action) => {
    switch (action.type) {
      case GET_WELCOME_DATA_LOAD:
        return {
          ...state,
          isLoading: true,
        };
      case GET_WELCOME_DATA_SUCCESS:
        return {
          ...state,
          getData: action.payload,
          isLoading: false,
        };
      case GET_WELCOME_DATA_FAILED:
        return {
          ...state,
          isError: action.payload,
          isLoading: false,
        };
      default:
        return state;
    }
  };
  
 // export default getWelcomeDataReducer;
 export const getAllWelcomeDataReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_ALL_WELCOME_DATA_LOAD:
      return {
        ...state,
        isLoading: true,
      };
    case GET_ALL_WELCOME_DATA_SUCCESS:
      return {
        ...state,
        getData: action.payload,
        isLoading: false,
      };
    case GET_ALL_WELCOME_DATA_FAILED:
      return {
        ...state,
        isError: action.payload,
        isLoading: false,
      };
    default:
      return state;
  }
};