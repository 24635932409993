import Swal from "sweetalert2";
import services from "../../Services/index";
import {
    GET_LOCATION_BY_SECTOR_SUCCESS,
    GET_LOCATION_BY_SECTOR_LOAD,
    GET_LOCATION_BY_SECTOR_FAILED,
} from "../Contants/Constants";

const getLocationBySectorLoad = () => ({
    type: GET_LOCATION_BY_SECTOR_LOAD,
});
const getLocationBySectorSuccess = (data) => ({
    type: GET_LOCATION_BY_SECTOR_SUCCESS,
    payload: data,
});
const getLocationBySectorFailed = (error) => ({
    type: GET_LOCATION_BY_SECTOR_FAILED,
    err: error,
});

export const getLocationBySectorData = (data) => async (dispatch) => {
  dispatch(getLocationBySectorLoad());
  try {
    const res = await services.api(
      "POST",
      `/ui-assist/v1/wallpaper/location`,data
    );
    if (res) {
      dispatch(getLocationBySectorSuccess(res.data));
    }
    // if(res.status === 200)
    // {
    //   Swal.fire(
    //           "Successfully Registered!",
    //           " ",
    //           "success"
    //         );
    // }
  } catch (err) {
    dispatch(getLocationBySectorFailed(err.response));
    Swal.fire("Something went wrong!", `${err?.response?.data}`, "error");
  }
};