import React from "react";
import "./QuillEditor.css";

import ReactQuill, { Quill } from "react-quill";
import "react-quill/dist/quill.snow.css";

//import Quill from "quill";
import ImageResize from "quill-image-resize-module-react";
import { ImageDrop } from "quill-image-drop-module";
import services from "../../../Services/index";
import CustomImage from "../../../Components/Common/QuillEditor/CustomImage";
import Swal from "sweetalert2";
import Loader from "../Loader/Loader";
Quill.register("modules/imageDrop", ImageDrop);
Quill.register("modules/imageResize", ImageResize);
Quill.register("formats/image", CustomImage);

var quillObj;

export default function QuillEditor({
  handleChangeEditor,
  editorValue,
  value,
  readOnly,
}) {
  const [state, setState] = React.useState({ text: "" });
  const [loading, setLoading] = React.useState(false);
  // const handleChange = (value) => {
  //   setState({ text: value });
  // };

  const imageHandler = () => {
    const input = document.createElement("input");

    input.setAttribute("type", "file");
    input.setAttribute("accept", "image/*");
    input.click();

    input.onchange = async () => {
      var file = input.files[0];
      var formData = new FormData();

      formData.append("image", file);

      var fileName = file.name;

      const res = await uploadFiles(file, fileName, quillObj);
      //console.log(res);
    };
  };
  const uploadFiles = async (uploadFileObj, filename, quillObj) => {
    var libraryName = "ImageFiles";
    // var context = this.props.context;
    //var siteUrl = this.props.context.pageContext.site.absoluteUrl;

    var currentdate = new Date();
    var fileNamePredecessor =
      currentdate.getDate().toString() +
      currentdate.getMonth().toString() +
      currentdate.getFullYear().toString() +
      currentdate.getTime().toString();

    filename = fileNamePredecessor + filename;
    let formData = new FormData();
    formData.append("media", uploadFileObj);

    //To Upload in root folder
    try {
      setLoading(true);
      const data = await services.api("POST", "/ui-assist/v1/upload", formData);
      if (data.status === 200) {
        setLoading(false);
        const range = quillObj.getEditorSelection();

        quillObj.getEditor().insertEmbed(range.index, "image", data.data);
      } else {
        setLoading(false);
        Swal.fire("Oops", "Server error", "file not uploaded");
      }
    } catch (error) {
      setLoading(false);
      Swal.fire("Oops", "Server error", "Something went wrong");
    }
  };
  /*
   * Quill modules to attach to editor
   * See https://quilljs.com/docs/modules/ for complete options
   */
  QuillEditor.modules = React.useMemo(
    () => ({
      imageResize: {
        parchment: Quill.import("parchment"),
        modules: ["Resize", "Toolbar"], // "Toolbar"
      },
      imageDrop: true,
      toolbar: {
        container: [
          //   [{ header: "1" }, { header: "2" }],
          //   [{ size: [] }],
          [
            "bold",
            "italic",
            //    "underline",
            //    "strike",
            //    "blockquote"
          ],
          [{ align: "right" }, { align: "center" }],
          [
            { indent: "-1" },
            { indent: "+1" },
            { list: "ordered" },
            { list: "bullet" },
          ],
          ["video", "image", "link"],
          ["clean"],
        ],
        handlers: {
          image: imageHandler,
        },
      },
      clipboard: {
        // toggle to add extra line breaks when pasting HTML:
        matchVisual: false,
      },
    }),
    []
  );

  /*
   * Quill editor formats
   * See https://quilljs.com/docs/formats/
   */
  QuillEditor.formats = [
    // "header",
    // "font",
    // "size",
    "bold",
    "italic",
    // "underline",
    "align",
    // "strike",
    // "blockquote",
    "background",
    "list",
    "bullet",
    "indent",
    "video",
    "image",
    "width",
    "link",
    "height",
    "class",
    "style",
  ];

  return (
    <div className="customTextEditor">
      {loading ? <Loader /> : null}
      <ReactQuill
        // name={name}
        ref={(el) => {
          quillObj = el;
        }}
        placeholder={"Write your story"}
        defaultValue={value}
        value={value}
        modules={QuillEditor.modules}
        formats={QuillEditor.formats}
        onChange={handleChangeEditor}
        readOnly={readOnly}
      />
    </div>
  );
}
