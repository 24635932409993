import React, { useState, useEffect } from "react";
import { Grid } from "@material-ui/core";
import ContactComponent from "./ContactComponent";
import TimelineComponent from "./TimelineComponent";
import CommonPDFUpload from "../../../../Common/CommonPDFUpload/CommonPDFUpload";
import Title from "./Title";

const ClubMembership = ({ data, formData, isValid }) => {
  const [contactPerson, setContactPerson] = useState();
  const [timelines, setTimelines] = useState();
  const [escalationSPOC, setEscalationSPOC] = useState();
  const [policyPdf, setPolicyPdf] = useState();

  useEffect(() => {
    setPolicyPdf(data?.policyPdf);
  }, [data]);

  useEffect(() => {
    const check =
      contactPerson?.isValid &&
      timelines?.isValid &&
      escalationSPOC?.isValid &&
      policyPdf;

      let formdata = new FormData();
      formdata.append("entitlementType", data?.entitlementType);
      formdata.append("contactPerson", JSON.stringify([{...contactPerson?.value}]));
      formdata.append("timelines", JSON.stringify([{...timelines?.value}]));
      formdata.append("escalation", JSON.stringify([{...escalationSPOC?.value}]));
      formdata.append("policyPdf", policyPdf);

      formData(formdata);
      isValid(check);

  }, [contactPerson, timelines, escalationSPOC, policyPdf]);

  

// console.log("contact person =====>", contactPerson);
// console.log("timeline =====>", timelines);
// console.log("escalationSPOC =====>", escalationSPOC);
// console.log("policyPdf =====>", policyPdf);

  return (
    <>
      <ContactComponent
        data={data?.contactPerson}
        setData={setContactPerson}
        title="Contact"
      />
      <TimelineComponent data={data?.timelines} setData={setTimelines} />
      <ContactComponent
        data={data?.escalationSPOC}
        setData={setEscalationSPOC}
        title="Escalation"
      />
      <Title title="Policy PDF" />
      <Grid container spacing={2} alignItems="top">
        <Grid item sm={6} md={4} xs={12}>
          <CommonPDFUpload value={data?.policyPdf} setValue={setPolicyPdf} />
        </Grid>
      </Grid>
    </>
  );
};

export default ClubMembership;
