import React, { useState, useEffect } from "react";
import "./OfferCampaigns.css";
import OfferCampaignsCard from "./OfferCampaignsCard/OfferCampaignsCard";
import { Paper, Grid } from "@material-ui/core";
import Template from "../../Common/Template/Template";
import CommonModal from "../../Common/CommonModal/CommonModal";
import SearchBarButton from "../../Common/SearchBar/SearchBarButton";
import SubmitButton from "../../Common/Button/SubmitButton";
import { useMediaQuery } from "@material-ui/core";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Box from "@material-ui/core/Box";
import { useHistory } from "react-router-dom";
import { LIVEWIRE, SUBMIT_OFFER_CAMPAIGN } from "../../../Routes/constant";
import AdminModalComponent from "../MahindraCollective/Admin/AdminModalComponent";
import settingIcon from "../../../Assets/Images/settings_5_line.svg";
import services from "../../../Services";
import PageTutorialComponent from "../../Common/PageTutorial/PageTutorialComponent";
import platform from "platform";
import {
  isAccesible,
  offsetCount,
} from "../../../Assets/helperFunctions/helperFunctions";
import Swal from "sweetalert2";
import Loader from "../../Common/Loader/Loader";
import { useSelector, useDispatch } from "react-redux";
import { getMahindraCollectivePendingData } from "../../../Redux/Actions/MahindraCollectiveAction";
import {
  addAnalayticsLog,
  addInterval,
} from "../../../Redux/Actions/Anaylatics";
import {
  getOffersList,
  OFFER_OFFSET,
} from "../../../Redux/Actions/OfferCampaignAction";
import { Pagination } from "@material-ui/lab";
import { Stack } from "@mui/material";

const list = [
  {
    img: "/Images/ongoing-offers/slide1.png",
    title:
      "Avail exclusive benefit of Rs 50,000 at Mahindra World City, Chennai",
    urlLink:
      "https://www.mahindralifespaces.com/real-estate-properties/chennai-property/mahindra-happinest-mahindra-world-city-chennai/?utm_source=Corporate&utm_medium=cpc&utm_campaign=Mahindra_Group&srd=60f7a7c84443ae504402b160",
  },
  {
    img: "/Images/ongoing-offers/slide2.png",
    title: "Watch Mahindra's Sustainability Journey during FY21",
    urlLink: "https://www.youtube.com/watch?v=XiBCbmvcZls",
  },
  {
    img: "/Images/ongoing-offers/slide3.png",
    title: "Launching Mahindra Innovation Awards 2021",
    urlLink: "https://innovation.mahindra.com/home ",
  },
  {
    img: "/Images/ongoing-offers/slide4.png",
    title:
      "Know why term insurance is the perfect option to secure your family's future?",
    urlLink:
      "https://blog.paybima.com/why-term-insurance-plan-should-be-good-for-securing-your-future/",
  },
  {
    img: "/Images/ongoing-offers/slide5.png",
    title: "Have you protected your family from life’s uncertainties?",
    urlLink:
      "https://www.paybima.com/life-insurance-campaign?utm_source=Mailer&utm_medium=Email&utm_campaign=Term&utm_id=MMAFS",
  },
];
function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3} className="paddingLR0">
          <div>{children}</div>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    // backgroundColor: theme.palette.background.paper,
  },
}));

const OfferCampaigns = () => {
  const [loading, setLoading] = useState(false);
  const [placeholder] = React.useState(
    "Search people, tags, sectors or a story name"
  );
  const [statusBol] = React.useState(true);
  const [btnBool1, setbtnBool1] = React.useState(true);
  const [btnBool2, setbtnBool2] = React.useState(false);
  const isXs = useMediaQuery("(max-width:812px)");
  const classes = useStyles();
  const [value, setValue] = React.useState(0);
  const history = useHistory();
  const dispatch = useDispatch();
  const [scheduleData, setScheduleData] = useState([]);
  const [searchText, setSearchText] = useState("");
  const [getList, setGetList] = useState([]);
  const [manageDataLength, setManageDataLength] = useState("");
  const [callAPI, setCallAPI] = useState(false);

  // const [selectedSortBy, setSelectedSortBy] = useState("");

  const [totalPages, setTotalPages] = useState(1);
  const [pageNo, setPageNo] = useState(1);
  const [storyType, setStoryType] = useState("");
  const [highLightTab, setHighLightTab] = useState(1);

  const [calYearValue, setCalYearValue] = useState("");
  const [monthValue, setMonthValue] = useState("");
  const mahindraCollectiveApprovedData = useSelector(
    (state) => state.getMahindraCollectivePendingReducer
  );
  const { getData: getApprovedData } = mahindraCollectiveApprovedData;
  const [filterdList, setFilterdList] = useState(getApprovedData);

  let totalRecords = useSelector((state) => {
    return state?.getOfferCampaignListReducer?.getTotalCount;
  });
  let getRecords = useSelector((state) => {
    return state?.getOfferCampaignListReducer?.getData;
  });

  /* == Code for pagination === */
  const handlePageChange = (e, val) => {
    setPageNo(val);
  };

  /* ======== UseEffects added for common API ==========*/
  useEffect(() => {
    if (pageNo === 1) {
      if (totalRecords) {
        setTotalPages(offsetCount(totalRecords, OFFER_OFFSET));
      }
    }
  }, [totalRecords]);

  useEffect(() => {
    setPageNo(1);
  }, [storyType]);

  useEffect(() => {
    setLoading(true);
    getOfferCampaignData();
  }, [pageNo, storyType]);
  
  useEffect(() => {
    if(callAPI)
    {
      setPageNo(1);
      setLoading(true);
      getOfferCampaignData();
      setCallAPI(false);
    }
  },[callAPI]);

  useEffect(() => {
    if (getRecords) {
      setLoading(false);
      // getOfferCampaignData();
    }
  }, [getRecords]);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleTabChange = async (currentTab) => {
    if (currentTab) {
      setStoryType(currentTab.toLowerCase().trim());
    } else {
      setStoryType("");
    }

    if (storyType === "mycontribution") {
      setHighLightTab(2);
    } else {
      setHighLightTab(1);
    }
  };

  const getOfferCampaignData = async () => {
    try {
      setLoading(true);
      dispatch(getOffersList(pageNo, storyType.toLowerCase(), searchText));
    } catch (error) {
      setLoading(false);
      console.log("Error ===> ", error);
      Swal.fire("Something went wrong!", `${error?.response?.data}`, "error");
    }
  };

  const handleBool1 = async () => {
    setbtnBool1(true);
    setbtnBool2(false);
    setStoryType("");
    // getOfferCampaignData();
  };
  const handleBool2 = async () => {
    dispatch(
      addAnalayticsLog({
        module: "Livewire/Ongoing offers and campaigns/My Contributions",
        browserDetails: `${platform.name} ${platform.os.architecture} ${platform.os.family} ${platform.os.version}`,
        deviceDetails: platform.product ? platform.product : "Desktop",
        isSearch: false,
      })
    );
    setbtnBool1(false);
    setbtnBool2(true);
    setStoryType("mycontribution");
  };
  const [openModal, setOpenModal] = useState(false);
  const onEditOfferCampaigns = () => {
    setOpenModal(true);
  };

  const handleCloseModal = () => {
    setOpenModal(false);
  };

  const getScheduleData = async () => {
    try {
      const res = await services.api(
        "GET",
        `/ui-assist/v1/${
          true ? "offerorcampaign" : "mahindracollective"
        }/schedule`
      );
      if (res?.status === 200) {
        setScheduleData(res?.data);
      }
    } catch (error) {
      Swal.fire("Something went wrong!", `${error?.response?.data}`, "error");
    }
  };

  //anaylatics log save
  useEffect(() => {
    dispatch(
      addAnalayticsLog({
        module: "Livewire/Ongoing offers and campaigns",
        browserDetails: platform.name,
        deviceDetails: platform.product ? platform.product : "Desktop",
        isSearch: false,
      })
    );
    // you know what is this, don't you?
  }, []);

  //timer log...
  useEffect(() => {
    const interval = setInterval(() => {
      dispatch(addInterval(localStorage.getItem("logID")));
    }, 60000);
    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    setManageDataLength(filterdList?.length + scheduleData?.length);
  }, [filterdList, scheduleData]);

  useEffect(() => {
    setFilterdList(getApprovedData);
  }, [mahindraCollectiveApprovedData]);

  useEffect(() => {
    dispatch(getMahindraCollectivePendingData(false));
    getScheduleData();
  }, []);

  const handleSearch = async (e) => {
    setSearchText(e.target.value);
  };

  
  const handleAPI = () => {
    setCallAPI(true);
  };

  const onKeyPress = (e) => {
    if(e.key === "Enter")
    {
      setCallAPI(true);
    }
  };

  
console.log("searchText ====>", searchText);

  return (
    <div>
      {/* <Container className="offerCampaign"> */}
      <Template pageName={"Offers and campaigns"}>
        {loading ? <Loader /> : null}
        <div>
          <Grid
            container
            direction="row"
            justifyContent="space-between"
            alignItems="flex-end"
          >
            <Grid item md={7} xs={12}>
              <div className="whatsNewHeader">
                <div className="containerTxt">
                  <div className="section-header-text">
                    {" "}
                    Offers and campaigns
                  </div>

                  {/* <PageTutorialComponent
            modelHeadtxt={"Livewire Tutorial"}
            videoLink={
              "https://newmrisedev.blob.core.windows.net/mrisestorage/1635246876564_Livewire%20V2.mp4"
            }
          /> */}
                  {isAccesible("OFFERS_AND_CAMPAIGNS") && (
                    <div className="editIcon" onClick={onEditOfferCampaigns}>
                      <img
                        src={
                          process.env.PUBLIC_URL + "/iconImages/MC_admin.svg"
                        }
                        alt=""
                        className="edit-line"
                      />
                      <div> manage {`(${manageDataLength})`}</div>
                    </div>
                  )}
                </div>
              </div>
            </Grid>
            <Grid item md={5} xs={12}>
              <SearchBarButton
                placeholder={placeholder}
                searchText={searchText}
                handleSearch={handleSearch}
                    handleSearchIcon={handleAPI}
                    onKeyPress={onKeyPress}
                name="search"
              />
            </Grid>
          </Grid>
        </div>

        <Paper
          className="MClandingpageAlign mt-3 mb-0 pb-3 mFilter Standardfilter"
          style={{ overflow: "auto" }}
        >
          <div className="liveWireFilters">
            <SubmitButton
              //disabled={!isAdmin()}
              label="Submit"
              className="MCsubmitbutton floatRightAlign"
              onClick={() =>
                history.push(`${LIVEWIRE}${SUBMIT_OFFER_CAMPAIGN}`)
              }
            />
          </div>
        </Paper>

        <Paper className="MClandingpageAlign mt-0 pt-0 standardtabcontainer">
          <div className="responsiveTabs">
            <Box sx={{ width: "100%" }}>
              <Box
                sx={{ borderBottom: 1, borderColor: "divider" }}
                className="boxAlignPadMC standardlefttab"
              >
                <Tabs
                  value={value}
                  onChange={handleChange}
                  variant="scrollable"
                  scrollButtons={false}
                  aria-label="basic tabs example"
                  className="mahindraCollectiveTabsDM"
                >
                  <Tab
                    label="Offers and campaigns"
                    {...a11yProps(0)}
                    onClick={handleBool1}
                    // onClick={handleTabChange("")}
                  />
                  <Tab
                    label="My contributions"
                    {...a11yProps(1)}
                    onClick={handleBool2}
                    // onClick={handleTabChange("mycontribution")}
                  />
                </Tabs>
              </Box>

              <div className="alignMCCOntentTabPanel  standardrighttab">
                <TabPanel value={value} index={0}>
                  <Grid
                    container
                    direction="row"
                    justifyContent="flex-start"
                    alignItems="center"
                    spacing={2}
                    className="alignCards"
                  >
                    {getRecords?.length ? (
                      getRecords.map((result, index) => {
                        return (
                          <Grid
                            key={index}
                            item
                            sm={index >= 0 && index <= 1 ? 6 : 6}
                            md={index >= 0 && index <= 1 ? 6 : 6}
                            lg={index >= 0 && index <= 1 ? 4 : 4}
                            xs={index >= 0 && index <= 1 ? 12 : 12}
                            className="width100"
                          >
                            <OfferCampaignsCard
                              currentTab={"allarticle"}
                              list={result}
                              btnBool1={!btnBool1}
                              searchText={searchText}
                            />
                          </Grid>
                        );
                      })
                    ) : (
                      <span
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          height: "100vh",
                          width: "100%",
                          
                          color: "#e31837",
                        }}
                      >
                        No Data Available
                      </span>
                    )}
                  </Grid>
                </TabPanel>
                <TabPanel value={value} index={1}>
                  <Grid
                    container
                    direction="row"
                    justifyContent="flex-start"
                    alignItems="center"
                    spacing={2}
                    className="alignCards"
                  >
                    {getRecords?.length ? (
                      getRecords.map((result, index) => {
                        return (
                          <Grid
                            key={index}
                            item
                            sm={index >= 0 && index <= 1 ? 6 : 6}
                            md={index >= 0 && index <= 1 ? 6 : 6}
                            lg={index >= 0 && index <= 1 ? 4 : 4}
                            xs={index >= 0 && index <= 1 ? 12 : 12}
                            className="width100"
                          >
                            <OfferCampaignsCard
                              currentTab={"mycontribution"}
                              list={result}
                              btnBool1={!btnBool1}
                              searchText={searchText}
                            />
                          </Grid>
                        );
                      })
                    ) : (
                      <span
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          height: "100vh",
                          width: "100%",
                          
                          color: "#e31837",
                        }}
                      >
                        No Data Available
                      </span>
                    )}
                  </Grid>
                </TabPanel>
              </div>
            </Box>
          </div>
        </Paper>
        <div style={{ margin: "2rem 0", float: "right" }}>
          <div className="paginationAlignFlex">
            <Stack spacing={2}>
              <Pagination
                count={totalPages}
                variant="outlined"
                shape="rounded"
                onChange={handlePageChange}
              />
            </Stack>
          </div>
        </div>
      </Template>
      <CommonModal
        open={openModal}
        handleClose={handleCloseModal}
        headerText="Manage offers and campaigns"
        icon={settingIcon}
        modalSize="lg"
        submitBtnText="SAVE CHANGES"
        fullWidth
        policiesButtonSize
        onButtonClick={handleCloseModal}
      >
        <AdminModalComponent
          isOfferCampaigns={true}
          filterdList={filterdList}
          scheduleData={scheduleData}
          // getManageDataNo={getManageDataNo}
        />
      </CommonModal>
      {/* </Container> */}
    </div>
  );
};

export default OfferCampaigns;
