export const ER_DATA = [
  {
    id: "1",
    title: "ER MIS",
    url: "https://ermis.mahindra.com/",
    icon: "https://mahindraassets8pgpo9mb.blob.core.windows.net/mrisestorage/er-mis_grey.png_1678254274675.png",
    activeIcon:
      "https://mahindraassets8pgpo9mb.blob.core.windows.net/mrisestorage/er-mis_red.png_1678254320261.png",
  },
  // {
  //   id: "2",
  //   title: "Canteen Management",
  //   url: "http://ipoints/Home.aspx",
  //   icon: "https://mahindraassets8pgpo9mb.blob.core.windows.net/mrisestorage/canteen.png_1673968004624.png",
  //   activeIcon: "",
  // },
  // {
  //   id: "3",
  //   title: "Vehicle Track VTS-Old",
  //   url: "https://mahindra.com",
  //   icon: "https://mahindraassets8pgpo9mb.blob.core.windows.net/mrisestorage/vehicle-tracking.png_1673971350980.png",
  //   activeIcon: "",
  // },
  {
    id: "4",
    title: "i4 Portal",
    url: "https://i4ideas.mahindra.com/ui",
    icon: "https://mahindraassets8pgpo9mb.blob.core.windows.net/mrisestorage/i4_grey.png_1678254554126.png",
    activeIcon:
      "https://mahindraassets8pgpo9mb.blob.core.windows.net/mrisestorage/i4_red.png_1678254583979.png",
  },
  // {
  //   id: "5",
  //   title: "RTM NGP",
  //   url: "https://mahindra.com",
  //   icon: "https://mahindraassets8pgpo9mb.blob.core.windows.net/mrisestorage/rtm-ngp.png_1673971472564.png",
  //   activeIcon: "",
  // },
  // {
  //   id: "6",
  //   title: "OT NGP",
  //   url: "https://mahindra.com",
  //   icon: "https://mahindraassets8pgpo9mb.blob.core.windows.net/mrisestorage/ot-nhp.png_1673971765959.png",
  //   activeIcon: "",
  // },
  // {
  //   id: "7",
  //   title: "Exit Pass NGP",
  //   url: "https://mahindra.com",
  //   icon: "https://mahindraassets8pgpo9mb.blob.core.windows.net/mrisestorage/sim-card.png_1673971827190.png",
  //   activeIcon: "",
  // },
  // {
  //   id: "8",
  //   title: "OH ZHB",
  //   url: "https://mahindra.com",
  //   icon: "https://mahindraassets8pgpo9mb.blob.core.windows.net/mrisestorage/oh-zhb.png_1673971893893.png",
  //   activeIcon: "",
  // },

  // {
  //   id: "9",
  //   title: "Food Junction/Café 4x4",
  //   url: "https://mahindra.com",
  //   icon: "https://mahindraassets8pgpo9mb.blob.core.windows.net/mrisestorage/food-junction.png_1673971954945.png",
  //   activeIcon: "",
  // },
  // {
  //   id: "10",
  //   title: "Admin Vehicle Request",
  //   url: "https://mahindra.com",
  //   icon: "https://mahindraassets8pgpo9mb.blob.core.windows.net/mrisestorage/vehicle-request.png_1673972036163.png",
  //   activeIcon: "",
  // },
  // {
  //   id: "11",
  //   title: "Centralized RTM application",
  //   url: "https://mahindra.com",
  //   icon: "https://mahindraassets8pgpo9mb.blob.core.windows.net/mrisestorage/rtm-application.png_1673972096046.png",
  //   activeIcon: "",
  // },
  {
    id: "12",
    title: "Jigyasa / Reward & Recognition",
    url: "https://rnrassociates.mahindra.com/app/",
    icon: "https://mahindraassets8pgpo9mb.blob.core.windows.net/mrisestorage/jigyasa_grey.png_1678254095611.png",
    activeIcon:
      "https://mahindraassets8pgpo9mb.blob.core.windows.net/mrisestorage/jigyasa_red.png_1678254059634.png",
  },

  {
    id: "13",
    title: "Mkaksha",
    url: "https://mkaksha.mahindra.com/app/",
    icon: "https://mahindraassets8pgpo9mb.blob.core.windows.net/mrisestorage/mkaksha_grey.png_1678254614443.png",
    activeIcon:
      "https://mahindraassets8pgpo9mb.blob.core.windows.net/mrisestorage/mkaksha_red.png_1678254643967.png",
  },
  // {
  //   id: "14",
  //   title: "LMS for Associates",
  //   url: "https://mahindra.com",
  //   icon: "https://mahindraassets8pgpo9mb.blob.core.windows.net/mrisestorage/lms-associates.png_1673972357221.png",
  //   activeIcon: "",
  // },
  {
    id: "15",
    title: "Legal Portal",
    url: "https://mahindraonline.sharepoint.com/sites/LitigationMgmt/",
    icon: "https://mahindraassets8pgpo9mb.blob.core.windows.net/mrisestorage/legalportal_grey.png_1678254404135.png",
    activeIcon:
      "https://mahindraassets8pgpo9mb.blob.core.windows.net/mrisestorage/legalportal_red.png_1678254450028.png",
  },
  {
    id: "16",
    title: "ECC",
    url: "https://econnect.mahindra.com/",
    icon: "https://mahindraassets8pgpo9mb.blob.core.windows.net/mrisestorage/ecc_grey.png_1678254219386.png",
    activeIcon:
      "https://mahindraassets8pgpo9mb.blob.core.windows.net/mrisestorage/ecc_red.png_1678254190950.png",
  },
];
