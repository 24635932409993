import services from "../../Services/index";
import {
  GET_SOP_CATEGORY_DATA_SUCCESS,
  GET_SOP_CATEGORY_DATA_LOAD,
  GET_SOP_CATEGORY_DATA_FAILED,
  GET_SOP_SUBCATEGORY_DATA_SUCCESS,
  GET_SOP_SUBCATEGORY_DATA_LOAD,
  GET_SOP_SUBCATEGORY_DATA_FAILED,
  GET_SOP_TABLE_DATA_SUCCESS,
  GET_SOP_TABLE_DATA_LOAD,
  GET_SOP_TABLE_DATA_FAILED,
  GET_SOP_CATEGORY_ID_DATA_FAILED,
  GET_SOP_CATEGORY_ID_DATA_SUCCESS,
  GET_SOP_CATEGORY_ID_DATA_LOAD,
} from "../Contants/Constants";

const getSopCategoryDataLoad = () => ({
  type: GET_SOP_CATEGORY_DATA_LOAD,
});
const getSopCategoryDataSuccess = (data) => ({
  type: GET_SOP_CATEGORY_DATA_SUCCESS,
  payload: data,
});
const getSopCategoryDataFailed = (error) => ({
  type: GET_SOP_CATEGORY_DATA_FAILED,
  err: error,
});

const getSopCategoryIdDataLoad = () => ({
  type: GET_SOP_CATEGORY_ID_DATA_LOAD,
});
const getSopCategoryIdDataSuccess = (data) => ({
  type: GET_SOP_CATEGORY_ID_DATA_SUCCESS,
  payload: data,
});
const getSopCategoryIdDataFailed = (error) => ({
  type: GET_SOP_CATEGORY_ID_DATA_FAILED,
  err: error,
});

export const getSopCategoryData = () => async (dispatch) => {
  dispatch(getSopCategoryDataLoad());
  try {
    const res = await services.api("GET", `/ui-assist/v1/sopcategorys`);
    if (res) {
      dispatch(getSopCategoryDataSuccess(res.data));
      dispatch(getSopSubCategoryData(res.data[0].categoryId));
    }
  } catch (err) {
    dispatch(getSopCategoryDataFailed(err.response));
  }
};

const getSopSubCategoryDataLoad = () => ({
  type: GET_SOP_SUBCATEGORY_DATA_LOAD,
});
const getSopSubCategoryDataSuccess = (data) => ({
  type: GET_SOP_SUBCATEGORY_DATA_SUCCESS,
  payload: data,
});
const getSopSubCategoryDataFailed = (error) => ({
  type: GET_SOP_SUBCATEGORY_DATA_FAILED,
  err: error,
});

export const getSopSubCategoryData = (cat_id) => async (dispatch) => {
  dispatch(getSopSubCategoryDataLoad());
  let res;
  try {
    if (cat_id) {
      res = await services.api(
        "GET",
        `/ui-assist/v1/sopsubcategory/${cat_id}`,
        {}
      );

      if (res) {
        dispatch(getSopSubCategoryDataSuccess(res.data));
        dispatch(getSopTableDataByID(res.data[0].sopSubCategoryId));
      }
    }
  } catch (err) {
    dispatch(getSopSubCategoryDataFailed(err.response));
  }
  console.log(res);
  return res;
};

const getSopTableDataLoad = () => ({
  type: GET_SOP_TABLE_DATA_LOAD,
});
const getSopTableDataSuccess = (data) => ({
  type: GET_SOP_TABLE_DATA_SUCCESS,
  payload: data,
});
const getSopTableDataFailed = (error) => ({
  type: GET_SOP_TABLE_DATA_FAILED,
  err: error,
});

export const getSopTableDataByID = (subCat_id) => async (dispatch) => {
  dispatch(getSopTableDataLoad());
  try {
    if (subCat_id) {
      const res = await services.api(
        "GET",
        `/ui-assist/v1/sop/${subCat_id}`,
        {}
      );
      if (res) {
        dispatch(getSopTableDataSuccess(res.data));
      }
    }
  } catch (err) {
    dispatch(getSopTableDataFailed(err.response));
  }
};

export const getSopFirstCategoryIdData = () => async (dispatch) => {
  dispatch(getSopCategoryIdDataLoad());
  try {
    const res = await services.api("GET", `/ui-assist/v1/sopcategorys`, {});
    if (res) {
      console.log(res, "res>>>>>>>>>>>>>>>>>>>>>>>>>>>");
      dispatch(getSopCategoryIdDataSuccess(res.data));
    }
  } catch (err) {
    dispatch(getSopCategoryIdDataFailed(err.response));
  }
};
