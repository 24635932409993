import { TODAY_TOOL_TIP } from "../Contants/Constants";

const initialState = {
  getData: false,
};

const getTodayToolTipReducer = (state = initialState, action) => {
  switch (action.type) {
    case TODAY_TOOL_TIP:
      return {
        ...state,
        getData: action.payload,
      };
    default:
      return state;
  }
};

export default getTodayToolTipReducer;
