import {
  EXPERIENCE_LIST_SUCCESS,
  EXPERIENCE_LIST_FAILED,
  EXPERIENCE_LIST_LOAD,
  EXPERIENCE_PENDING_FAILED,
  EXPERIENCE_PENDING_SUCCESS,
  EXPERIENCE_PENDING_LOAD,
  EXPERIENCE_ARTICLE_FAILED,
  EXPERIENCE_ARTICLE_LOAD,
  EXPERIENCE_ARTICLE_SUCCESS,
  EXPERIENCE_SCHEDULE_FAILED,
  EXPERIENCE_SCHEDULE_SUCCESS,
  EXPERIENCE_SCHEDULE_LOAD,
  EXPERIENCE_DELETE_FAILED,
  EXPERIENCE_DELETE_SUCCESS,
  EXPERIENCE_DELETE_LOAD,
  PREV_NEXT_ID_FAILED,
  PREV_NEXT_ID_SUCCESS,
  PREV_NEXT_ID_LOAD,
} from "../Contants/Constants";

const initialState = {
  getData: [],
  isLoading: false,
  isError: "",
};

export const getExperienceListReducer = (state = initialState, action) => {
  switch (action.type) {
    case EXPERIENCE_LIST_LOAD:
      return {
        ...state,
        isLoading: true,
      };
    case EXPERIENCE_LIST_SUCCESS:
      if (action.payload.record.length === 0) {
        return {
          ...state,
          isLoading: false,
          getTotalCount: action?.payload?.totalCount,
          getData:
            action.pageNo == 0
              ? [...action.payload.record]
              : [...state.getData, action.payload.record],
        };
      } else {
        return {
          ...state,
          isLoading: false,
          getTotalCount: action.payload?.totalCount,
          getData: action.payload.record,
        };
      }
    case EXPERIENCE_LIST_FAILED:
      return {
        ...state,
        isError: action.payload,
        isLoading: false,
      };
    default:
      return state;
  }
};

const initialStatePending = {
  getData: [],
  scheduleData: [],
  isLoading: false,
  isError: "",
};

export const getPendingReducer = (state = initialStatePending, action) => {
  switch (action.type) {
    case EXPERIENCE_PENDING_LOAD:
      return {
        ...state,
        isLoading: true,
      };

    case EXPERIENCE_PENDING_SUCCESS:
      return {
        ...state,
        isLoading: false,
        getData: action.payload,
      };

    case EXPERIENCE_PENDING_FAILED:
      return {
        ...state,
        isError: action.payload,
        isLoading: false,
      };

    case EXPERIENCE_SCHEDULE_LOAD:
      return {
        ...state,
        isLoading: true,
      };
    case EXPERIENCE_SCHEDULE_SUCCESS:
      return {
        ...state,
        isLoading: false,
        scheduleData: action.payload,
      };
    case EXPERIENCE_SCHEDULE_FAILED:
      return {
        ...state,
        isError: action.payload,
        isLoading: false,
      };
    default:
      return state;
  }
};

const initialArticleState = {
  getData: {},
  // articleID: "",
  // previousID: "",
  // nextID: "",
  isLoading: false,
  isError: "",
};

export const getExperienceArticleDataReducer = (
  state = initialArticleState,
  action
) => {
  switch (action.type) {
    case EXPERIENCE_ARTICLE_LOAD:
      return {
        ...state,
        getData: {},
        // articleID: "",
        isLoading: true,
      };

    case EXPERIENCE_ARTICLE_SUCCESS: {
      return {
        ...state,
        getData: action.payload,
        // articleID: action.payload?.object?.id,
        // previousID: action.payload?.previous,
        // nextID: action.payload?.next,
        isLoading: false,
      };
    }

    case EXPERIENCE_ARTICLE_FAILED:
      return {
        ...state,
        getData: {},
        // articleID: "",
        isError: action.payload,
        isLoading: false,
      };
    default:
      return state;
  }
};

// delete article

const initialDeleteState = {
  deleteStatus: false,
  isLoading: false,
  isError: "",
  status: "",
};

export const deleteExperienceArticleReducer = (state = initialDeleteState, action) => {
  switch (action.type) {
    case EXPERIENCE_DELETE_LOAD:
      return {
        ...state,
        isLoading: true,
        status: "loading",
      };

    case EXPERIENCE_DELETE_SUCCESS:
      return {
        ...state,
        isLoading: false,
        deleteStatus: action.payload,
        status: "success",
      };

    case EXPERIENCE_DELETE_FAILED:
      return {
        ...state,
        isError: action.payload,
        isLoading: false,
        status: "failed",
      };
    default:
      return state;
  }
};

// previous n next id of article

const initialPrevNextState = {
  prevNextStatus: {},
  isLoading: false,
  isError: "",
  status: "",
};

export const prevNextArticleReducer = (state = initialPrevNextState, action) => {
  switch (action.type) {
    case PREV_NEXT_ID_LOAD:
      return {
        ...state,
        isLoading: true,
        status: "loading",
      };

    case PREV_NEXT_ID_SUCCESS:
      return {
        ...state,
        isLoading: false,
        prevNextStatus: action.payload,
        status: "success",
      };

    case PREV_NEXT_ID_FAILED:
      return {
        ...state,
        isError: action.payload,
        isLoading: false,
        status: "failed",
      };
    default:
      return state;
  }
};