import React, { useEffect, useState } from "react";
import services from "../../../Services/index";
import "../RollMatrix/ManageIntranetAccessComponent.css";
import Template from "../../Common/Template/Template";
import Pagination from "@mui/material/Pagination";
import Stack from "@mui/material/Stack";
import {
  Grid,
  Tabs,
  Tab,
  Typography,
  Box,
  Paper,
  Button,
} from "@material-ui/core";
import PropTypes from "prop-types";
import SubmitButton from "../../Common/Button/SubmitButton";
import SearchBarButton from "../../Common/SearchBar/SearchBarButton";
import CommonModal from "../../Common/CommonModal/CommonModal";
import addIcon from "../../../Assets/Images/add-white.svg";
import settingIcon from "../../../Assets/Images/settings_5_line.svg";
import AddModal from "./AddModal";
import moment from "moment";
import { useSelector, useDispatch } from "react-redux";
import { addDownTimeData, editDownTimeData } from "../../../Redux/Actions/DownTimeAction";
import Highlighter from "react-highlight-words";
import { isAccesible } from "../../../Assets/helperFunctions/helperFunctions";


function TabPanel(props) {
  const { children, value, index, ...other } = props;
  // console.log(props.value, 'value@@')

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};


function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export default function DowntimeAdmin() {

  const dispatch = useDispatch();
  const allData = useSelector((state) => state.getAllDownTimeReducer);
 

  const [value, setValue] = React.useState(0);
  const [formData, setFormData] = useState();
  const [isFormDataValid, setIsFormDataValid] = useState();
  const [isEdit, setIsEdit] = useState(false);
  const [searchText, setSearchText] = useState("");
  const [newList, setNewList] = useState([]);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const [openModal, setOpenModal] = useState(false);


  const onCreateAccess = () => {
    setOpenModal(true);
  };


  const handleCloseModal = () => {
    setOpenModal(false);
    setIsEdit(false);
    setFormData(null);
  };

  const [sapdowntime, setSapdowntime] = useState(null);

  useEffect(() => {
    handleCloseModal();
  }, [allData?.addData, allData?.editedData]);

  useEffect(() => {
    const getDowntimeData = async () => {
      try {
        const res = await services.api("GET", "/ui-assist/v1/downtime");
        if (res.status === 200) {
          setSapdowntime(res?.data);
          console.log(res?.data);
        }
      } catch (err) {
        console.log(err.message);
      }
    };
    getDowntimeData();
  }, [allData?.addData, allData?.editedData]);

  useEffect(() => {
    let newArray = sapdowntime?.filter(function (el) {
      let regex = new RegExp(searchText, "gi");
      let a = el.title.match(regex);
      return a?.length > 0;
    });
    setNewList(newArray);
  }, [sapdowntime, searchText]);

  const handleDelete = async (id) => {
   try {
     await services.api("DELETE", `/ui-assist/v1/downtime/${id}`) 
    setSapdowntime([...sapdowntime.filter(d => d?._id !== id)])
   } catch (error) {
    console.log(error)
   }
  }

  const handleDataChange = (val) => {
    setFormData(val?.value);
    setIsFormDataValid(val?.isValid);
  };

  const handleSubmit = () => {
    if(isEdit){
      const val = {...formData, isDeleted: false};
      dispatch(editDownTimeData(val));
    }else{
      dispatch(addDownTimeData(formData));
    }
  };

  const handleEdit = (val) => {
    setIsEdit(true);
    setFormData(val);
    setOpenModal(true);
  };

  const handleSearch = (e) => {
    setSearchText(e?.target.value);
  };
 
  return (
    <div>
      <Template>
        <Grid
          container
          direction="row"
          justifyContent="space-between"
          alignItems="center"
        >
          <Grid item xs={12} sm={12}>
            <p className="offCHeaderTitle">Manage Downtime</p>
          </Grid>
        </Grid>
        {isAccesible() ? 
        <Box sx={{ width: "100%" }}>
          <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
            <Tabs
              value={value}
              onChange={handleChange}
              aria-label="basic tabs example"
              className="rollBasedTab"
            >
              <Tab label="Downtime List" {...a11yProps(0)} />
            </Tabs>
            <hr className="deviderP" />
          </Box>
          <TabPanel value={value} index={0} className="rollBasedTabPanel">
            <Paper>
              <Grid
                container
                direction="row"
                alignItems="center"
                spacing={2}
                className="rollBasedPaper"
                alignContent="flex-end"
              >
                <Grid item xs={12} sm={8}>
                  <Grid
                    container
                    direction="row"
                    alignItems="center"
                    spacing={3}
                  >
                    <Grid item>
                      <SubmitButton
                        label="ADD NEW Downtime"
                        className="rollBasedBtn"
                        onClick={onCreateAccess}
                      />
                    </Grid>
                    <Grid item sm={6} className="rollBasedSearchBarButton">
                      <SearchBarButton
                        handleSearch={handleSearch}
                        placeholder="Search by Title"
                      />
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
              <div className="rollBasedTable">
                <div className="rollBasedTableHeader">
                  <div className="rollBasedTableCol1">Title</div>

                  <div className="rollBasedTableCol2">From time</div>

                  <div className="rollBasedTableCol3">To Time</div>

                  <div className="rollBasedTableCol4">Status</div>

                  <div className="rollBasedTableCol5">Created/Updated by</div>

                  <div className="rollBasedTableCol6">Edit</div>

                  <div className="rollBasedTableCol7">Delete</div>
                </div>
                {newList !== null &&
                  newList?.length > 0 &&
                  newList?.map((d) => (
                    <div className="rollBasedTable" key={d._id}>
                      <div className="rollBasedTableContent">
                        <div className="rollBasedTableCol1">
                          <Highlighter
                            highlightStyle={{ backgroundColor: "yellow" }}
                            searchWords={searchText.split(" ")}
                            textToHighlight={d?.title}
                          />
                        </div>

                        <div className="rollBasedTableCol2">
                          {moment(d?.fromdate).format("DD-MM-YYYY")}
                        </div>

                        <div className="rollBasedTableCol3">
                          {moment(d?.todate).format("DD-MM-YYYY")}
                        </div>
                        <div className="rollBasedTableCol4">
                          <Button className="statusActive">{d?.status}</Button>
                        </div>
                        <div className="rollBasedTableCol5">
                          {d?.created_updatedBy}
                        </div>

                        <div
                          style={{ cursor: "pointer" }}
                          className="rollBasedTableCol6"
                          onClick={() => handleEdit(d)}
                        >
                          <img
                            src={
                              process.env.PUBLIC_URL +
                              "/iconImages/gray-edit.svg"
                            }
                            alt=""
                          />
                        </div>

                        <div
                          style={{ cursor: "pointer" }}
                          className="rollBasedTableCol7"
                          onClick={() => handleDelete(d?._id)}
                        >
                          <img
                            src={
                              process.env.PUBLIC_URL +
                              "/iconImages/gray-delete.svg"
                            }
                            alt="delete group"
                          />
                        </div>
                      </div>
                    </div>
                  ))}
              </div>
            </Paper>
            <div className="rollBasedTablePagination">
              <div className="paginationAlignFlex">
                {/* <div className="paginationNumbers">
                  <img
                    src={
                      process.env.PUBLIC_URL +
                      "/Images/universalSearch_Images/left-box-arrow.png"
                    }
                    alt="arrow image"
                    className="arrowPos"
                  />
                </div>
               <div className="paginationNumbers">1</div>
                <div className="paginationNumbers">2</div>
                <div className="paginationNumbers">...</div>
                <div className="paginationNumbers">5</div>
                  <div className="paginationNumbers"> 
                  <img
                    src={
                      process.env.PUBLIC_URL +
                      "/Images/universalSearch_Images/right-box-arrow.svg"
                    }
                    alt="arrow image"
                    className=""
                  />
                </div> */}
                {/* <Stack spacing={2}>
                  <Pagination
                    count={accessGroupPages}
                    variant="outlined"
                    shape="rounded"
                    onChange={handleAccessPaginationChange}
                  />
                </Stack> */}
              </div>
            </div>
          </TabPanel>
        </Box>
        :
        <>
          <Paper>
              <Grid
                container
                direction="row"
                justifyContent="center"
                spacing={2}
                className="rollBasedPaper mt-4"
              >
                <div style={{fontSize: "20px"}}>You are not authorized to access this page.</div>
              </Grid>
          </Paper>
        </>
        }

        <CommonModal
          open={openModal}
          handleClose={handleCloseModal}
          headerText={isEdit ? "Edit DownTime" : "Add New DownTime"}
          icon={settingIcon}
          modalSize="md"
          submitBtnText="SAVE CHANGES"
          fullWidth
          policiesButtonSize
          onButtonClick={handleSubmit}
          disabledSubmitButton={!isFormDataValid}
        >
          <AddModal value={formData} onChange={handleDataChange} />
        </CommonModal>
      </Template>
    </div>
  );

}