import SockJS from "sockjs-client";
import Stomp from "stompjs";


export function registerSocket(
  employeeID,
  connectionString,
  queueType,
  functionCallBack
) {
  var soncket = new SockJS(connectionString);
  var client = Stomp.over(soncket);
  client.connect({}, (frame) => {
    client.subscribe(
      "/user/queue/" + queueType + "-" + employeeID,
      (message) => {
       // console.log('message....................',message);
        functionCallBack.handleMessage(message);
      }
    );
  });
}




