import React from "react";
import { Card, Grid, Avatar } from "@material-ui/core";
import DatePicker from "../../../../Common/DatePicker/DatePicker";
import {
  LIVEWIRE,
  UPLOAD_ARTICLE,
  OC_REVIEW_ARTICLE,
} from "../../../../../Routes/constant";
import { useHistory } from "react-router-dom";
import { alpha } from "@material-ui/core/styles";

export default function TabCardComponent({ records = [], isSchedule = false }) {
  const history = useHistory();

  return (
    <>
      {records.length > 0 &&
        records.map((result) => (
          <Card className="adminCardAlign">
            <div className="adminCard">
              <Grid
                container
                direction="row"
                justifyContent="space-between"
                alignItems="center"
              >
                <div className="adminCardTitle">{result.title} </div>
                {isSchedule && (
                  <div className="AdminCardDaysTogo">
                    <DatePicker selectedDate={result.publishDate} disabled />
                  </div>
                )}
              </Grid>
              <Grid
                container
                direction="row"
                justifyContent="space-between"
                alignItems="center"
              >
                <div className="adminCardSubTitle">{result.subTitle}</div>
              </Grid>
              <Grid
                container
                direction="row"
                justifyContent="space-between"
                alignItems="center"
              >
                <div className="adminCardProfileName">
                  {result?.isAuthorPost == true ? (
                    <>
                      <img
                        src={result.empPhotoUrl}
                        className="profileWidth"
                        alt="Admin profile"
                      />
                      <div>{result.name}</div>
                    </>
                  ) : (
                    <>
                      <Avatar></Avatar>
                      <div>{result?.submittedFor}</div>
                    </>
                  )}
                </div>
                <div className="adminCardView">
                  <div
                    onClick={() => {
                      history.push({
                        pathname: `${LIVEWIRE}/${
                          isSchedule ? "experience-view" : "experience-modify"
                        }/${result?.id}`,
                        state: {
                          details: result,
                          scheduleDataFlag: isSchedule,
                        },
                      });
                    }}
                  >
                    view
                  </div>
                  <img
                    src={
                      process.env.PUBLIC_URL +
                      "/Images/adminProfile/arrow-down-s-line.svg"
                    }
                    alt="rightArrow"
                  />
                </div>
              </Grid>
            </div>
          </Card>
        ))}
    </>
  );
}
