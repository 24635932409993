import services from "../../Services/index";
import {
    GET_USER_WALLPAPER_STATUS_SUCCESS,
    GET_USER_WALLPAPER_STATUS_LOAD,
    GET_USER_WALLPAPER_STATUS_FAILED,
} from "../Contants/Constants";

const getUserWallpaperStatusLoad = () => ({
    type: GET_USER_WALLPAPER_STATUS_LOAD,
});
const getUserWallpaperStatusSuccess = (data) => ({
    type: GET_USER_WALLPAPER_STATUS_SUCCESS,
    payload: data,
});
const getUserWallpaperStatusFailed = (error) => ({
    type: GET_USER_WALLPAPER_STATUS_FAILED,
    err: error,
});

export const getUserWallpaperStatusData = (id) => async (dispatch) => {
  dispatch(getUserWallpaperStatusLoad());
  try {
    const res = await services.api(
      "GET",
      `/ui-assist/v1/wallpaper/${id}`
    );
    if (res) {
      dispatch(getUserWallpaperStatusSuccess(res.data));
    }
  } catch (err) {
    dispatch(getUserWallpaperStatusFailed(err.response));
  }
};