import React, { useState, useEffect } from "react";
import "./AdminModalComponent.css";
import "./Downtime.css";

import PropTypes from "prop-types";
import SubmitButton from "../../Common/Button/SubmitButton";
import addBoxLine from "../../../Assets/Images/WhatsNewIcons/add-box-fill.svg";
import TextareaAutosize from "../../Common/TextArea/TextArea";
import "../MahindraCollective/UploadStory/UploadStoryComponent.css";
import {
  Paper,
  Grid,
  Radio,
  FormControlLabel,
  RadioGroup,
  Button,
  Typography,
  Box,
  Tabs,
  Tab,
} from "@material-ui/core";
import { useFormik } from "formik";
import Template from "./../../Common/Template/Template";
import editIconRed from "../../../Assets/Images/edit-line-red.svg";
import CharLeftField from "../../Common/CharLeftField/CharLeftField";
import DatePicker from "../../Common/DatePicker/DatePicker";
import CommonLabel from "../../Common/ComonLabel/CommonLabel";
import moment from "moment";
import services from "../../../Services";
import CommonAutocomplete from "../../Common/CommonAutocomplete/CommonAutocomplete";

import CommonModal from "../../Common/CommonModal/CommonModal";
import offerCompaignApprovalIcon from "../../../Assets/Images/offer-compaign-approval.svg";
import uploadIcon from "../../../Assets/Images/collectiveIcons/upload-2-line.svg";
import previewArticle from "../../../Assets/Images/search-eye-line.svg";
import {
  uploadStorySchema,
  editStorySchema,
} from "../../../Assets/formikErrors/SubmitOfferCampaignSchema";
import Swal from "sweetalert2";
import { LIVEWIRE, MAHINDRA_COLLECTIVE } from "../../../Routes/constant";
import { useHistory, useLocation, useParams } from "react-router-dom";
import Loader from "../../Common/Loader/Loader";
import rejectArticle from "../../../Assets/Images/file-forbid-line.svg";
import CustomMultiSelect from "../../Common/CustomMultiSelect/CustomMultiSelect";

import CommonFileUpload from "../../Common/CommonFileUpload/CommonFileUpload";
import CommonImageUpload from "../../Common/CommonImageUpload/CommonImageUpload";
import CommonPDFUpload from "../../Common/CommonPDFUpload/CommonPDFUpload";
import CommonWhatsNewDialog from "../../Common/CommonWhatsNewDialog/CommonWhatsNewDialog";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import QuillEditor from "../../Common/QuillEditor/QuillEditor";

const hours = [
  { title: "1", value: "1" },
  { title: "2", value: "2" },
  { title: "3", value: "3" },
  { title: "4", value: "4" },
  { title: "5", value: "5" },
  { title: "6", value: "6" },
  { title: "7", value: "7" },
  { title: "8", value: "8" },
  { title: "9", value: "9" },
  { title: "10", value: "10" },
  { title: "11", value: "11" },
  { title: "12", value: "12" },
];

const minutes = [
  { title: "0", value: "0" },
  { title: "15", value: "15" },
  { title: "30", value: "30" },
  { title: "45", value: "45" },
];

const timeslot = [
  { title: "AM", value: "AM" },
  { title: "PM", value: "PM" },
];

function AddModal({ value, onChange }) {
  const options = [
    {
      title: "Publish",
      value: "Publish",
    },
    {
      title: "Unpublish",
      value: "Unpublish",
    },
  ];

  const [id, setId] = useState("");
  const [title, setTitle] = useState();
  const [fromTime, setFromTime] = useState(
    moment(new Date()).format("YYYY-MM-DD[T]HH:mm:ss")
  );
  const [toTime, setToTime] = useState(
    moment(new Date()).format("YYYY-MM-DD[T]HH:mm:ss")
  );
  const [message, setMessage] = useState();
  const [status, setStatus] = useState();
  const [sapdowntime, setSapdowntime] = useState(null);

  const [startHour, setStartHour] = useState("1");
  const [startMinute, setStartMinute] = useState("0");
  const [startTimeSlot, setStartTimeSlot] = useState("AM");
  const [endHour, setEndHour] = useState("1");
  const [endMinute, setEndMinute] = useState("0");
  const [endTimeSlot, setEndTimeSlot] = useState("PM");

  const StartHour = (event, selectedValue) => setStartHour(selectedValue.value);
  const StartMinute = (event, selectedValue) =>
    setStartMinute(selectedValue.value);
  const StartTimeSlot = (event, selectedValue) =>
    setStartTimeSlot(selectedValue.value);
  const EndHour = (event, selectedValue) => setEndHour(selectedValue.value);
  const EndMinute = (event, selectedValue) => setEndMinute(selectedValue.value);
  const EndTimeSlot = (event, selectedValue) =>
    setEndTimeSlot(selectedValue.value);

  useEffect(() => {
    if (value) {
      setId(value?._id);
      setTitle(value?.title);
      setFromTime(value?.fromdate);
      setToTime(value?.todate);
      setMessage(value?.message);
      setStatus(value?.status);
    }
  }, []);

  function setHours(dt, h) {
    var s = /(\d+):(\d+)(.+)/.exec(h);
    dt.setHours(s[3] === "PM" ? 12 + parseInt(s[1], 10) : parseInt(s[1], 10));
    dt.setMinutes(parseInt(s[2], 10));
  }

  useEffect(() => {
    const startDate = new Date(
      new Date(fromTime).setHours(
        startTimeSlot === "PM"
          ? 12 + parseInt(startHour, 10)
          : parseInt(startHour, 10),
        startMinute
      )
    );
    const endDate = new Date(
      new Date(toTime).setHours(
        endTimeSlot === "PM"
          ? 12 + parseInt(endHour, 10)
          : parseInt(endHour, 10),
        endMinute
      )
    );
    console.log("onChange =====> ", onChange);
    const data = {
      _id: id,
      title: title,
      fromdate: startDate,
      todate: endDate,
      status: status,
      created_updatedBy: localStorage.getItem("fullName"),
      cloudservice: "SAP SuccessFactors Learning",
      message: message,
    };
    let isValid = false;
    if (title && title != "" && status && status != "") {
      isValid = true;
    }
    onChange({ value: data, isValid: isValid });
    console.log("entered data =====>", data);
  }, [title, fromTime, toTime, message, status]);

  useEffect(() => {
    const getDowntimeData = async () => {
      try {
        const res = await services.api("GET", "/ui-assist/v1/downtime");
        if (res.status === 200) {
          setSapdowntime(res?.data);
        }
      } catch (err) {
        console.log(err.message);
      }
    };
    getDowntimeData();
  }, []);

  const latestDowntimeData =
    sapdowntime &&
    sapdowntime.length > 0 &&
    sapdowntime?.sort((a, b) => {
      if (new Date(b.proposedStartDate) > new Date(a.proposedStartDate)) {
        return 1;
      } else {
        return -1;
      }
    })[0];

  const latestDowntime =
    latestDowntimeData && new Date(latestDowntimeData?.fromdate);

  const handleTitle = (e) => setTitle(e.target.value);
  const handleStartDateChange = (name, date) => {
    if (name === "startDate") {
      setFromTime(moment(date).format("YYYY-MM-DD[T]HH:mm:ss"));
    }
  };

  const handleEndDateChange = (name, date) => {
    if (name === "endDate") {
      setToTime(moment(date).format("YYYY-MM-DD[T]HH:mm:ss"));
    }
  };

  const handleStatus = (event, selectedValue) => setStatus(selectedValue.value);

  return (
    <>
      <div>
        <div className="lastmodifiedpanel mb-0">
          <Grid container spacing={2}>
            <Grid item sm={2} xs={12}>
              <div className="displayBlockUserDetails mlP alignMCAdmin">
                <p className="detailDivp">Last modified by</p>
                <p className="detailsFontSize">
                  {latestDowntimeData
                    ? latestDowntimeData.created_updatedBy
                    : "No Data Present"}
                </p>
              </div>
            </Grid>
            <Grid item sm={2} xs={12}>
              <div className="displayBlockUserDetails alignMCAdmin">
                <p className="detailDivp">Modified on</p>
                <p className="detailsFontSize">
                  {latestDowntime
                    ? `${
                        latestDowntime.getDate() >= 10
                          ? latestDowntime.getDate()
                          : `0${latestDowntime.getDate()}`
                      }-${
                        latestDowntime.getMonth() >= 10
                          ? latestDowntime.getMonth() + 1
                          : `0${latestDowntime.getMonth() + 1}`
                      }-${latestDowntime.getFullYear()}`
                    : "No Data Present"}
                </p>
              </div>
            </Grid>
          </Grid>
        </div>

        <div className="MCAdminTabAlign">
          <div className="MCAdminTabs">
            <div className="Modalcontent modalwithspace">
              <Grid
                container
                direction="row"
                justifyContent="space-between"
                alignItems="center"
                spacing={3}
              >
                <Grid
                  item
                  md={12}
                  xs={12}
                  className="inputWidthTitle alignInputTextTitle boxwithlimit"
                >
                  <CharLeftField
                    label={<CommonLabel label="Title" mandatory />}
                    name="title"
                    type="text"
                    value={title}
                    handleChange={handleTitle}
                  />
                </Grid>

                <Grid item md={4} xs={12}>
                  <DatePicker
                    label={<CommonLabel label="From Date" />}
                    name="From Time"
                    minDate={new Date().setDate(new Date().getDate())}
                    selectedDate={fromTime}
                    handleDateChange={(date) => {
                      handleStartDateChange("startDate", date);
                      handleEndDateChange("endDate", date);
                    }}
                  />
                </Grid>
                <Grid
                  item
                  xs={4}
                  sm={2}
                  className="lmar-desk24-mob0 wallpaperDropdown"
                >
                  <CommonAutocomplete
                    handleSelectChange={StartHour}
                    selectedValue={{
                      title: startHour,
                      value: startHour,
                    }}
                    name="hours"
                    options={hours}
                    label={<CommonLabel label="Hours" />}
                  />
                </Grid>
                <Grid item xs={4} sm={2} className="wallpaperDropdown">
                  <CommonAutocomplete
                    handleSelectChange={StartMinute}
                    selectedValue={{
                      title: startMinute,
                      value: startMinute,
                    }}
                    name="minutes"
                    options={minutes}
                    label={<CommonLabel label="Minutes" />}
                  />
                </Grid>
                <Grid item xs={4} sm={2} className="wallpaperDropdown">
                  <CommonAutocomplete
                    handleSelectChange={StartTimeSlot}
                    selectedValue={{
                      title: startTimeSlot,
                      value: startTimeSlot,
                    }}
                    name="timeslot"
                    options={timeslot}
                  />
                </Grid>
                <Grid item md={4} xs={12}>
                  <DatePicker
                    label={<CommonLabel label="To Date" />}
                    name="To Time"
                    minDate={fromTime}
                    selectedDate={toTime}
                    handleDateChange={(date) =>
                      handleEndDateChange("endDate", date)
                    }
                  />
                </Grid>
                <Grid
                  item
                  xs={4}
                  sm={2}
                  className="lmar-desk24-mob0 wallpaperDropdown"
                >
                  <CommonAutocomplete
                    handleSelectChange={EndHour}
                    selectedValue={{ title: endHour, value: endHour }}
                    name="hours"
                    options={hours}
                    label={<CommonLabel label="Hours" />}
                  />
                </Grid>
                <Grid item xs={4} sm={2} className="wallpaperDropdown">
                  <CommonAutocomplete
                    handleSelectChange={EndMinute}
                    selectedValue={{
                      title: endMinute,
                      value: endMinute,
                    }}
                    name="minutes"
                    options={minutes}
                    label={<CommonLabel label="Minutes" />}
                  />
                </Grid>
                <Grid item xs={4} sm={2} className="wallpaperDropdown">
                  <CommonAutocomplete
                    handleSelectChange={EndTimeSlot}
                    selectedValue={{
                      title: endTimeSlot,
                      value: endTimeSlot,
                    }}
                    name="timeslot"
                    options={timeslot}
                  />
                </Grid>

                <Grid item md={12} xs={12}>
                  {/* <TextareaAutosize
                    className="textArea"
                    placeholder="Enter Message"
                    value={message}
                    handleChangeTextArea={handleMessage}
                    minlength="10"
                    maxLength="1000"
                  /> */}

                  <QuillEditor
                    // handleChangeEditor={(e) => setMessage(e)}
                    handleChangeEditor={(content, delta, source, editor) =>
                      setMessage(editor.getHTML())
                    }
                    name="editorValue"
                    value={message || ""}
                    // readOnly={edit}
                  />
                  {/* <ReactQuill
                    theme="snow"
                    value={message || ""}
                    onChange={setMessage}
                  /> */}
                </Grid>
                <Grid item sm={4} className="rollBasedAutoCompplete">
                  <CommonAutocomplete
                    handleSelectChange={handleStatus}
                    selectedValue={{ title: status, value: status }}
                    name="selectFileType"
                    className="width100"
                    options={options}
                    label={<CommonLabel label="Select Type" mandatory />}
                  />
                </Grid>
              </Grid>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default AddModal;
