//DASHBOARD
export const GET_PRIORITY_DATA_LOAD = "GET_PRIORITY_DATA_LOAD";
export const GET_PRIORITY_DATA_FAILED = "GET_PRIORITY_DATA_FAILED";
export const GET_PRIORITY_DATA_SUCCESS = "GET_PRIORITY_DATA_SUCCESS";

export const GET_FAVOURITE_DATA_LOAD = "GET_FAVOURITE_DATA_LOAD";
export const GET_FAVOURITE_DATA_SUCCESS = "GET_FAVOURITE_DATA_SUCCESS";
export const GET_FAVOURITE_DATA_FAILED = "GET_FAVOURITE_DATA_FAILED";

//Notifications BEll Icon
export const GET_NOTIFICATION_DATA_LOAD = "GET_NOTIFICATION_DATA_LOAD";
export const GET_NOTIFICATION_DATA_FAILED = "GET_NOTIFICATION_DATA_FAILED";
export const GET_NOTIFICATION_DATA_SUCCESS = "GET_NOTIFICATION_DATA_SUCCESS";

//Profile Details Non SSO
export const GET_PROFILEDETAILS_DATA_LOAD = "GET_PROFILEDETAILS_DATA_LOAD";
export const GET_PROFILEDETAILS_DATA_FAILED = "GET_PROFILEDETAILS_DATA_FAILED";
export const GET_PROFILEDETAILS_DATA_SUCCESS =
  "GET_PROFILEDETAILS_DATA_SUCCESS";

//Profile scope Details const.
export const GET_SCOPE_DETAILS_DATA_LOAD = "GET_SCOPE_DETAILS_DATA_LOAD";
export const GET_SCOPE_DETAILS_DATA_FAILED = "GET_SCOPE_DETAILS_DATA_FAILED";
export const GET_SCOPE_DETAILS_DATA_SUCCESS = "GET_SCOPE_DETAILS_DATA_SUCCESS";

//feedvack details
export const GET_USERFEEDBACK_DATA_LOAD = "GET_USERFEEDBACK_DATA_LOAD";
export const GET_USERFEEDBACK_DATA_FAILED = "GET_USERFEEDBACK_DATA_FAILED";
export const GET_USERFEEDBACK_DATA_SUCCESS = "GET_USERFEEDBACK_DATA_SUCCESS";

//Handle tooltip of today
export const TODAY_TOOL_TIP = "TODAY_TOOL_TIP";
//export const GET_USERFEEDBACK_DATA_SUCCESS ="GET_USERFEEDBACK_DATA_SUCCESS";

//get current banner
export const GET_WELCOME_DATA_LOAD = "GET_WELCOME_DATA_LOAD";
export const GET_WELCOME_DATA_FAILED = "GET_WELCOME_DATA_FAILED";
export const GET_WELCOME_DATA_SUCCESS = "GET_WELCOME_DATA_SUCCESS";

//get all banners
export const GET_ALL_WELCOME_DATA_LOAD = "GET_ALL_WELCOME_DATA_LOAD";
export const GET_ALL_WELCOME_DATA_FAILED = "GET_ALL_WELCOME_DATA_FAILED";
export const GET_ALL_WELCOME_DATA_SUCCESS = "GET_ALL_WELCOME_DATA_SUCCESS";

//get all org-annoucements
export const GET_ALL_ORG_DATA_LOAD = "GET_ALL_ORG_DATA_LOAD";
export const GET_ALL_ORG_DATA_FAILED = "GET_ALL_ORG_DATA_FAILED";
export const GET_ALL_ORG_DATA_SUCCESS = "GET_ALL_ORG_DATA_SUCCESS";

//get all org-annoucements for Admin
export const GET_ALL_ORG_ADMIN_DATA_LOAD = "GET_ALL_ORG_ADMIN_DATA_LOAD";
export const GET_ALL_ORG_ADMIN_DATA_FAILED = "GET_ALL_ORG_ADMIN_DATA_FAILED";
export const GET_ALL_ORG_ADMIN_DATA_SUCCESS = "GET_ALL_ORG_ADMIN_DATA_SUCCESS";

//get org-annoucements by sector

export const GET_SECTOR_ORG_DATA_LOAD = "GET_SECTOR_ORG_DATA_LOAD";
export const GET_SECTOR_ORG_DATA_FAILED = "GET_SECTOR_ORG_DATA_FAILED";
export const GET_SECTOR_ORG_DATA_SUCCESS = "GET_SECTOR_ORG_DATA_SUCCESS";

//get org-annoucements by business units

export const GET_BUSINESS_UNITS_ORG_DATA_LOAD =
  "GET_BUSINESS_UNITS_ORG_DATA_LOAD";
export const GET_BUSINESS_UNITS_ORG_DATA_FAILED =
  "GET_BUSINESS_UNITS_ORG_DATA_FAILED";
export const GET_BUSINESS_UNITS_ORG_DATA_SUCCESS =
  "GET_BUSINESS_UNITS_ORG_DATA_SUCCESS";

//get org-annoucements by function

export const GET_FUNCTION_ORG_DATA_LOAD = "GET_FUNCTION_ORG_DATA_LOAD";
export const GET_FUNCTION_ORG_DATA_FAILED = "GET_FUNCTION_ORG_DATA_FAILED";
export const GET_FUNCTION_ORG_DATA_SUCCESS = "GET_FUNCTION_ORG_DATA_SUCCESS";

//get org-annoucements by timeframe
export const GET_ORG_COUNT_LOAD = "GET_ORG_COUNT_LOAD";
export const GET_ORG_COUNT_FAILED = "GET_ORG_COUNT_FAILED";
export const GET_ORG_COUNT_SUCCESS = "GET_ORG_COUNT_SUCCESS";

//get all sectors

export const GET_ALL_SECTORS_LOAD = "GET_ALL_SECTORS_LOAD";
export const GET_ALL_SECTORS_FAILED = "GET_ALL_SECTORS_FAILED";
export const GET_ALL_SECTORS_SUCCESS = "GET_ALL_SECTORS_SUCCESS";

//get all business units

export const GET_ALL_BUSINESS_UNITS_LOAD = "GET_ALL_BUSINESS_UNITS_LOAD";
export const GET_ALL_BUSINESS_UNITS_FAILED = "GET_ALL_BUSINESS_UNITS_FAILED";
export const GET_ALL_BUSINESS_UNITS_SUCCESS = "GET_ALL_BUSINESS_UNITS_SUCCESS";

//get all functions

export const GET_ALL_FUNCTIONS_LOAD = "GET_ALL_FUNCTIONS_LOAD";
export const GET_ALL_FUNCTIONS_FAILED = "GET_ALL_FUNCTIONS_FAILED";
export const GET_ALL_FUNCTIONS_SUCCESS = "GET_ALL_FUNCTIONS_SUCCESS";

//get all whats'new
export const GET_ALL_WHATSNEW_DATA_LOAD = "GET_ALL_WHATSNEW_DATA_LOAD";
export const GET_ALL_WHATSNEW_DATA_FAILED = "GET_ALL_WHATSNEW_DATA_FAILED";
export const GET_ALL_WHATSNEW_DATA_SUCCESS = "GET_ALL_WHATSNEW_DATA_SUCCESS";

//Add fav data
export const GET_ALL_ADDFAV_DATA_LOAD = "GET_ALL_ADDFAV_DATA_LOAD";
export const GET_ALL_ADDFAV_DATA_FAILED = "GET_ALL_ADDFAV_DATA_FAILED";
export const GET_ALL_ADDFAV_DATA_SUCCESS = "GET_ALL_ADDFAV_DATA_SUCCESS";

//Add policies category data
export const GET_POLICY_CATEGORY_DATA_LOAD = "GET_POLICY_CATEGORY_DATA_LOAD";
export const GET_POLICY_CATEGORY_DATA_FAILED =
  "GET_POLICY_CATEGORY_DATA_FAILED";
export const GET_POLICY_CATEGORY_DATA_SUCCESS =
  "GET_POLICY_CATEGORY_DATA_SUCCESS";

//get policy first category_id
export const GET_POLICY_CATEGORY_ID_DATA_LOAD =
  "GET_POLICY_CATEGORY_ID_DATA_LOAD";
export const GET_POLICY_CATEGORY_ID_DATA_FAILED =
  "GET_POLICY_CATEGORY_ID_DATA_FAILED";
export const GET_POLICY_CATEGORY_ID_DATA_SUCCESS =
  "GET_POLICY_CATEGORY_ID_DATA_SUCCESS";

//Policies sub category
export const GET_POLICY_SUBCATEGORY_DATA_SUCCESS =
  "GET_POLICY_SUBCATEGORY_DATA_SUCCESS";
export const GET_POLICY_SUBCATEGORY_DATA_LOAD =
  "GET_POLICY_SUBCATEGORY_DATA_LOAD";
export const GET_POLICY_SUBCATEGORY_DATA_FAILED =
  "GET_POLICY_SUBCATEGORY_DATA_FAILED";

//Policies admin sub category
export const GET_POLICY_ADMIN_SUBCATEGORY_DATA_SUCCESS =
  "GET_POLICY_ADMIN_SUBCATEGORY_DATA_SUCCESS";
export const GET_POLICY_ADMIN_SUBCATEGORY_DATA_LOAD =
  "GET_POLICY_ADMIN_SUBCATEGORY_DATA_LOAD";
export const GET_POLICY_ADMIN_SUBCATEGORY_DATA_FAILED =
  "GET_POLICY_ADMIN_SUBCATEGORY_DATA_FAILED";

//Policies table data

export const GET_POLICY_TABLE_DATA_SUCCESS = "GET_POLICY_TABLE_DATA_SUCCESS";
export const GET_POLICY_TABLE_DATA_LOAD = "GET_POLICY_TABLE_DATA_LOAD";
export const GET_POLICY_TABLE_DATA_FAILED = "GET_POLICY_TABLE_DATA_FAILED";

//Sops table data

export const GET_SOP_CATEGORY_DATA_SUCCESS = "GET_SOP_CATEGORY_DATA_SUCCESS";
export const GET_SOP_CATEGORY_DATA_LOAD = "GET_SOP_CATEGORY_DATA_LOAD";
export const GET_SOP_CATEGORY_DATA_FAILED = "GET_SOP_CATEGORY_DATA_FAILED";
export const GET_SOP_SUBCATEGORY_DATA_SUCCESS =
  "GET_SOP_SUBCATEGORY_DATA_SUCCESS";
export const GET_SOP_SUBCATEGORY_DATA_LOAD = "GET_SOP_SUBCATEGORY_DATA_LOAD";
export const GET_SOP_SUBCATEGORY_DATA_FAILED =
  "GET_SOP_SUBCATEGORY_DATA_FAILED";
export const GET_SOP_TABLE_DATA_SUCCESS = "GET_SOP_TABLE_DATA_SUCCESS";
export const GET_SOP_TABLE_DATA_LOAD = "GET_SOP_TABLE_DATA_LOAD";
export const GET_SOP_TABLE_DATA_FAILED = "GET_SOP_TABLE_DATA_FAILED";
export const GET_SOP_CATEGORY_ID_DATA_FAILED =
  "GET_SOP_CATEGORY_ID_DATA_FAILED";
export const GET_SOP_CATEGORY_ID_DATA_SUCCESS =
  "GET_SOP_CATEGORY_ID_DATA_SUCCESS";
export const GET_SOP_CATEGORY_ID_DATA_LOAD = "GET_SOP_CATEGORY_ID_DATA_LOAD";

//MLDL Lifepost
export const GET_MLDL_LIFE_TABLE_DATA_LOAD = "GET_MLDL_LIFE_TABLE_DATA_LOAD";
export const GET_MLDL_LIFE_TABLE_DATA_SUCCESS =
  "GET_MLDL_LIFE_TABLE_DATA_SUCCESS";
export const GET_MLDL_LIFE_TABLE_DATA_FAILED =
  "GET_MLDL_LIFE_TABLE_DATA_FAILED";

//get tasks
export const GET_TASKS_DATA_SUCCESS = "GET_TASKS_DATA_SUCCESS";
export const GET_TASKS_DATA_LOAD = "GET_TASKS_DATA_LOAD";
export const GET_TASKS_DATA_FAILED = "GET_TASKS_DATA_FAILED";

//remove whats'new item
export const REMOVE_WHATSNEW_ITEM_LOAD = " REMOVE_WHATSNEW_ITEM_LOAD,";
export const REMOVE_WHATSNEW_ITEM_FAILED = "REMOVE_WHATSNEW_ITEM_FAILED";
export const REMOVE_WHATSNEW_ITEM_SUCCESS = "REMOVE_WHATSNEW_ITEM_SUCCESS";

//edit whats'new item
export const EDIT_WHATSNEW_ITEM_LOAD = " EDIT_WHATSNEW_ITEM_LOAD,";
export const EDIT_WHATSNEW_ITEM_FAILED = "EDIT_WHATSNEW_ITEM_FAILED";
export const EDIT_WHATSNEW_ITEM_SUCCESS = "EDIT_WHATSNEW_ITEM_SUCCESS";

//Mahindra collective
export const GET_MAHINDRACOLLECTIVE_PENDING_DATA_LOAD =
  "GET_MAHINDRACOLLECTIVE_PENDING_DATA_LOAD";
export const GET_MAHINDRACOLLECTIVE_PENDING__DATA_FAILED =
  "GET_MAHINDRACOLLECTIVE_PENDING__DATA_FAILED";
export const GET_MAHINDRACOLLECTIVE_PENDING__DATA_SUCCESS =
  "GET_MAHINDRACOLLECTIVE_PENDING__DATA_SUCCESS";

//Remote Working
export const GET_REMOTE_WORKING_LOAD = "GET_REMOTE_WORKING_LOAD";
export const GET_REMOTE_WORKING_FAILED = "GET_REMOTE_WORKING_FAILED";
export const GET_REMOTE_WORKING_SUCCESS = "GET_REMOTE_WORKING_SUCCESS";
export const MODIFY_REMOTE_WORKING_SUCCESS = "MODIFY_REMOTE_WORKING_SUCCESS";
export const SET_DATE = "SET_DATE";
export const EMPTY_REMOTE_DETAILS = "EMPTY_REMOTE_DETAILS";

//like share and comment

export const GET_COMMENTS_DATA_SUCCESS = "GET_COMMENTS_DATA_SUCCESS";
export const GET_COMMENTS_DATA_LOAD = "GET_COMMENTS_DATA_LOAD";
export const GET_COMMENTS_DATA_FAILED = "GET_COMMENTS_DATA_FAILED";

//Module Search
export const GET_ModuleSearch_DATA_SUCCESS = "GET_ModuleSearch_DATA_SUCCESS";
export const GET_ModuleSearch_DATA_LOAD = "GET_ModuleSearch_DATA_LOAD";
export const GET_ModuleSearch_DATA_FAILED = "GET_ModuleSearch_DATA_FAILED";
//disable email or unregister blood donor
export const GET_DISABLE_DONOR_STATUS_DATA_SUCCESS =
  "GET_DISABLE_DONOR_STATUS_DATA_SUCCESS";
export const GET_DISABLE_DONOR_STATUS_DATA_LOAD =
  "GET_DISABLE_DONOR_STATUS_DATA_LOAD";
export const GET_DISABLE_DONOR_STATUS_DATA_FAILED =
  "GET_DISABLE_DONOR_STATUS_DATA_FAILED";

//Add blood donor
export const GET_ADD_DONOR_DATA_SUCCESS = "GET_ADD_DONOR_DATA_SUCCESS";
export const GET_ADD_DONOR_DATA_LOAD = "GET_ADD_DONOR_DATA_LOAD";
export const GET_ADD_DONOR_DATA_FAILED = "GET_ADD_DONOR_DATA_FAILED";

//Add blood request
export const GET_ADD_BLOOD_REQUEST_DATA_SUCCESS =
  "GET_ADD_BLOOD_REQUEST_DATA_SUCCESS";
export const GET_ADD_BLOOD_REQUEST_DATA_LOAD =
  "GET_ADD_BLOOD_REQUEST_DATA_LOAD";
export const GET_ADD_BLOOD_REQUEST_DATA_FAILED =
  "GET_ADD_BLOOD_REQUEST_DATA_FAILED";

//location for blood request
export const GET_LOCATION_DATA_SUCCESS = "GET_LOCATION_DATA_SUCCESS";
export const GET_LOCATION_DATA_LOAD = "GET_LOCATION_DATA_LOAD";
export const GET_LOCATION_DATA_FAILED = "GET_LOCATION_DATA_FAILED";

//initial status of blood donor
export const GET_INITIAL_STATUS_DATA_SUCCESS =
  "GET_INITIAL_STATUS_DATA_SUCCESS";
export const GET_INITIAL_STATUS_DATA_LOAD = "GET_INITIAL_STATUS_DATA_LOAD";
export const GET_INITIAL_STATUS_DATA_FAILED = "GET_INITIAL_STATUS_DATA_FAILED";

//send mail
export const GET_SEND_MAIL_DATA_SUCCESS = "GET_SEND_MAIL_DATA_SUCCESS";
export const GET_SEND_MAIL_DATA_LOAD = "GET_SEND_MAIL_DATA_LOAD";
export const GET_SEND_MAIL_DATA_FAILED = "GET_SEND_MAIL_DATA_FAILED";

//get article:

export const ARTICLE_DATA_SUCCESS = "ARTICLE_DATA_SUCCESS";
export const ARTICLE_DATA_LOAD = "ARTICLE_DATA_LOAD";
export const ARTICLE_DATA_FAILED = "ARTICLE_DATA_FAILED";

//Last Modified by
export const GET_MODIFIED_BY_DATA_SUCCESS = "GET_MODIFIED_BY_DATA_SUCCESS";
export const GET_MODIFIED_BY_DATA_LOAD = "GET_MODIFIED_BY_DATA_LOAD";
export const GET_MODIFIED_BY_DATA_FAILED = "GET_MODIFIED_BY_DATA_FAILED";

//Last Modified by
export const LIKE_DATA_SUCCESS = "LIKE_DATA_SUCCESS";
export const LIKE_DATA_LOAD = "LIKE_DATA_LOAD";
export const LIKE_DATA_FAILED = "LIKE_DATA_FAILED";

//wallpaper request approval
export const GET_WALLPAPER_REQUEST_APPROVAL_SUCCESS =
  "GET_WALLPAPER_REQUEST_APPROVAL_SUCCESS";
export const GET_WALLPAPER_REQUEST_APPROVAL_LOAD =
  "GET_WALLPAPER_REQUEST_APPROVAL_LOAD";
export const GET_WALLPAPER_REQUEST_APPROVAL_FAILED =
  "GET_WALLPAPER_REQUEST_APPROVAL_FAILED";

//wallpaper request
export const GET_WALLPAPER_REQUEST_SUCCESS = "GET_WALLPAPER_REQUEST_SUCCESS";
export const GET_WALLPAPER_REQUEST_LOAD = "GET_WALLPAPER_REQUEST_LOAD";
export const GET_WALLPAPER_REQUEST_FAILED = "GET_WALLPAPER_REQUEST_FAILED";

//user's all wallpapers status
export const GET_USER_WALLPAPER_STATUS_SUCCESS =
  "GET_USER_WALLPAPER_STATUS_SUCCESS";
export const GET_USER_WALLPAPER_STATUS_LOAD = "GET_USER_WALLPAPER_STATUS_LOAD";
export const GET_USER_WALLPAPER_STATUS_FAILED =
  "GET_USER_WALLPAPER_STATUS_FAILED";

//approve wallpaper request
export const GET_APPROVE_WALLPAPER_REQUEST_SUCCESS =
  "GET_APPROVE_WALLPAPER_REQUEST_SUCCESS";
export const GET_APPROVE_WALLPAPER_REQUEST_LOAD =
  "GET_APPROVE_WALLPAPER_REQUEST_LOAD";
export const GET_APPROVE_WALLPAPER_REQUEST_FAILED =
  "GET_APPROVE_WALLPAPER_REQUEST_FAILED";

//reject wallpaper request
export const GET_REJECT_WALLPAPER_REQUEST_SUCCESS =
  "GET_REJECT_WALLPAPER_REQUEST_SUCCESS";
export const GET_REJECT_WALLPAPER_REQUEST_LOAD =
  "GET_REJECT_WALLPAPER_REQUEST_LOAD";
export const GET_REJECT_WALLPAPER_REQUEST_FAILED =
  "GET_REJECT_WALLPAPER_REQUEST_FAILED";

// approved wallpaper list
export const GET_APPROVED_WALLPAPER_LIST_SUCCESS =
  "GET_APPROVED_WALLPAPER_LIST_SUCCESS";
export const GET_APPROVED_WALLPAPER_LIST_LOAD =
  "GET_APPROVED_WALLPAPER_LIST_LOAD";
export const GET_APPROVED_WALLPAPER_LIST_FAILED =
  "GET_APPROVED_WALLPAPER_LIST_FAILED";

// initial wallpaper admin information
export const GET_INITIAL_WALLPAPER_INFO_SUCCESS =
  "GET_INITIAL_WALLPAPER_INFO_SUCCESS";
export const GET_INITIAL_WALLPAPER_INFO_LOAD =
  "GET_INITIAL_WALLPAPER_INFO_LOAD";
export const GET_INITIAL_WALLPAPER_INFO_FAILED =
  "GET_INITIAL_WALLPAPER_INFO_FAILED";

// views of org announcement
export const GET_ORG_VIEWS_SUCCESS = "GET_ORG_VIEWS_SUCCESS";
export const GET_ORG_VIEWS_LOAD = "GET_ORG_VIEWS_LOAD";
export const GET_ORG_VIEWS_FAILED = "GET_ORG_VIEWS_FAILED";

//notification filter data......
export const GET_NOTIFICATION_FILTER_DATA_LOAD =
  "GET_NOTIFICATION_FILTER_DATA_LOAD";
export const GET_NOTIFICATION_FILTER_DATA_FAILED =
  "GET_NOTIFICATION_FILTER_DATA_FAILED";
export const GET_NOTIFICATION_FILTER_DATA_SUCCESS =
  "GET_NOTIFICATION_FILTER_DATA_SUCCESS";

//delete article
export const DELETE_ARTICLE_LOAD = "DELETE_ARTICLE_LOAD";
export const DELETE_ARTICLE_FAILED = "DELETE_ARTICLE_FAILED";
export const DELETE_ARTICLE_SUCCESS = "DELETE_ARTICLE_SUCCESS";

//notification icon flag......
export const GET_NOTIFICATION_ICON_DATA_LOAD =
  "GET_NOTIFICATION_ICON_DATA_LOAD";
export const GET_NOTIFICATION_ICON_DATA_FAILED =
  "GET_NOTIFICATION_ICON_DATA_FAILED";
export const GET_NOTIFICATION_ICON_DATA_SUCCESS =
  "GET_NOTIFICATION_ICON_DATA_SUCCESS";

// get all emergency contact
export const GET_ALL_EMERGENCY_CONTACT_LOAD = "GET_ALL_EMERGENCY_CONTACT_LOAD";
export const GET_ALL_EMERGENCY_CONTACT_FAILED =
  "GET_ALL_EMERGENCY_CONTACT_FAILED";
export const GET_ALL_EMERGENCY_CONTACT_SUCCESS =
  "GET_ALL_EMERGENCY_CONTACT_SUCCESS";

//edit emergency contact
export const PUT_EDIT_EMERGENCY_CONTACT_LOAD =
  "PUT_EDIT_EMERGENCY_CONTACT_LOAD";
export const PUT_EDIT_EMERGENCY_CONTACT_FAILED =
  "PUT_EDIT_EMERGENCY_CONTACT_FAILED";
export const PUT_EDIT_EMERGENCY_CONTACT_SUCCESS =
  "PUT_EDIT_EMERGENCY_CONTACT_SUCCESS";

//delete emergency contact
export const DELETE_EMERGENCY_CONTACT_LOAD = "DELETE_EMERGENCY_CONTACT_LOAD";
export const DELETE_EMERGENCY_CONTACT_FAILED =
  "DELETE_EMERGENCY_CONTACT_FAILED";
export const DELETE_EMERGENCY_CONTACT_SUCCESS =
  "DELETE_EMERGENCY_CONTACT_SUCCESS";

//add emergency contact
export const ADD_EMERGENCY_CONTACT_LOAD = "ADD_EMERGENCY_CONTACT_LOAD";
export const ADD_EMERGENCY_CONTACT_FAILED = "ADD_EMERGENCY_CONTACT_FAILED";
export const ADD_EMERGENCY_CONTACT_SUCCESS = "ADD_EMERGENCY_CONTACT_SUCCESS";

//upload image
export const UPLOAD_IMAGE_LOAD = "UPLOAD_IMAGE_LOAD";
export const UPLOAD_IMAGE_FAILED = "UPLOAD_IMAGE_FAILED";
export const UPLOAD_IMAGE_SUCCESS = "UPLOAD_IMAGE_SUCCESS";

//Mahidnra Collective list
export const GET_MAHINDRA_COLLECTIVE_LIST_LOAD =
  "GET_MAHINDRA_COLLECTIVE_LIST_LOAD";
export const GET_MAHINDRA_COLLECTIVE_LIST_FAILED =
  "GET_MAHINDRA_COLLECTIVE_LIST_FAILED";
export const GET_MAHINDRA_COLLECTIVE_LIST_SUCCESS =
  "GET_MAHINDRA_COLLECTIVE_LIST_SUCCESS";
//get locations by sector id
export const GET_LOCATION_BY_SECTOR_LOAD = "GET_LOCATION_BY_SECTOR_LOAD";
export const GET_LOCATION_BY_SECTOR_FAILED = "GET_LOCATION_BY_SECTOR_FAILED";
export const GET_LOCATION_BY_SECTOR_SUCCESS = "GET_LOCATION_BY_SECTOR_SUCCESS";

//get Offer
export const GET_OFFER_DATA_LOAD = "GET_OFFER_DATA_LOAD";
export const GET_OFFER_DATA_FAILED = "GET_OFFER_DATA_FAILED";
export const GET_OFFER_DATA_SUCCESS = "GET_OFFER_DATA_SUCCESS";
// get all contact location
export const GET_ALL_CONTACT_LOCATION_LOAD = "GET_ALL_CONTACT_LOCATION_LOAD";
export const GET_ALL_CONTACT_LOCATION_FAILED =
  "GET_ALL_CONTACT_LOCATION_FAILED";
export const GET_ALL_CONTACT_LOCATION_SUCCESS =
  "GET_ALL_CONTACT_LOCATION_SUCCESS";

//edit contact location
export const PUT_EDIT_CONTACT_LOCATION_LOAD = "PUT_EDIT_CONTACT_LOCATION_LOAD";
export const PUT_EDIT_CONTACT_LOCATION_FAILED =
  "PUT_EDIT_CONTACT_LOCATION_FAILED";
export const PUT_EDIT_CONTACT_LOCATION_SUCCESS =
  "PUT_EDIT_CONTACT_LOCATION_SUCCESS";

//delete contact location
export const DELETE_CONTACT_LOCATION_LOAD = "DELETE_CONTACT_LOCATION_LOAD";
export const DELETE_CONTACT_LOCATION_FAILED = "DELETE_CONTACT_LOCATION_FAILED";
export const DELETE_CONTACT_LOCATION_SUCCESS =
  "DELETE_CONTACT_LOCATION_SUCCESS";

//add contact location
export const ADD_CONTACT_LOCATION_LOAD = "ADD_CONTACT_LOCATION_LOAD";
export const ADD_CONTACT_LOCATION_FAILED = "ADD_CONTACT_LOCATION_FAILED";
export const ADD_CONTACT_LOCATION_SUCCESS = "ADD_CONTACT_LOCATION_SUCCESS";

//get log data
export const GET_LOG_DATA_LOAD = "GET_LOG_DATA_LOAD";
export const GET_LOG_DATA_FAILED = "GET_LOG_DATA_LOAD";
export const GET_LOG_DATA_SUCCESS = "GET_LOG_DATA_LOAD";

//get whats new sectors
export const GET_WHATS_NEW_SECTORS_LOAD = "GET_WHATS_NEW_SECTORS_LOAD";
export const GET_WHATS_NEW_SECTORS_FAILED = "GET_WHATS_NEW_SECTORS_FAILED";
export const GET_WHATS_NEW_SECTORS_SUCCESS = "GET_WHATS_NEW_SECTORS_SUCCESS";

//GET WHATS NEW FROM SECTOR
export const GET_WHATS_NEW_BY_SECTOR_LOAD = "GET_WHATS_NEW_BY_SECTOR_LOAD";
export const GET_WHATS_NEW_BY_SECTOR_FAILED = "GET_WHATS_NEW_BY_SECTOR_FAILED";
export const GET_WHATS_NEW_BY_SECTOR_SUCCESS =
  "GET_WHATS_NEW_BY_SECTOR_SUCCESS";

// share log
export const SHARE_DATA_SUCCESS = "SHARE_DATA_SUCCESS";
export const SHARE_DATA_LOAD = "SHARE_DATA_LOAD";
export const SHARE_DATA_FAILED = "SHARE_DATA_FAILED";
//Mahindra Experience list
export const EXPERIENCE_LIST_LOAD = "EXPERIENCE_LIST_LOAD";
export const EXPERIENCE_LIST_FAILED = "EXPERIENCE_LIST_FAILED";
export const EXPERIENCE_LIST_SUCCESS = "EXPERIENCE_LIST_SUCCESS";

export const EXPERIENCE_PENDING_LOAD = "EXPERIENCE_PENDING_LOAD";
export const EXPERIENCE_PENDING_SUCCESS = "EXPERIENCE_PENDING_SUCCESS";
export const EXPERIENCE_PENDING_FAILED = "EXPERIENCE_PENDING_FAILED";

export const EXPERIENCE_ARTICLE_LOAD = "EXPERIENCE_ARTICLE_LOAD";
export const EXPERIENCE_ARTICLE_SUCCESS = "EXPERIENCE_ARTICLE_SUCCESS";
export const EXPERIENCE_ARTICLE_FAILED = "EXPERIENCE_ARTICLE_FAILED";

export const EXPERIENCE_SCHEDULE_LOAD = "EXPERIENCE_SCHEDULE_LOAD";
export const EXPERIENCE_SCHEDULE_SUCCESS = "EXPERIENCE_SCHEDULE_SUCCESS";
export const EXPERIENCE_SCHEDULE_FAILED = "EXPERIENCE_SCHEDULE_FAILED";

//delete experience article
export const EXPERIENCE_DELETE_LOAD = "EXPERIENCE_DELETE_LOAD";
export const EXPERIENCE_DELETE_SUCCESS = "EXPERIENCE_DELETE_SUCCESS";
export const EXPERIENCE_DELETE_FAILED = "EXPERIENCE_DELETE_FAILED";

// get previous next id of article in mahindra experience
export const PREV_NEXT_ID_LOAD = "PREV_NEXT_ID_LOAD";
export const PREV_NEXT_ID_SUCCESS = "PREV_NEXT_ID_SUCCESS";
export const PREV_NEXT_ID_FAILED = "PREV_NEXT_ID_FAILED";

//getOfferCampaigns list
export const GET_OFFER_LIST_SUCCESS = "GET_OFFER_LIST_SUCCESS";
export const GET_OFFER_LIST_FAILED = "GET_OFFER_LIST_FAILED";
export const GET_OFFER_LIST_LOAD = "GET_OFFER_LIST_LOAD";

//upload file
export const UPLOAD_FILE_LOAD = "UPLOAD_FILE_LOAD";
export const UPLOAD_FILE_FAILED = "UPLOAD_FILE_FAILED";
export const UPLOAD_FILE_SUCCESS = "UPLOAD_FILE_SUCCESS";

//upload pdf
export const UPLOAD_PDF_LOAD = "UPLOAD_PDF_LOAD";
export const UPLOAD_PDF_FAILED = "UPLOAD_PDF_FAILED";
export const UPLOAD_PDF_SUCCESS = "UPLOAD_PDF_SUCCESS";

//publish to whats new for published records
export const PUBLISH_TO_WHATS_NEW_LOAD = "PUBLISH_TO_WHATS_NEW_LOAD";
export const PUBLISH_TO_WHATS_NEW_FAILED = "PUBLISH_TO_WHATS_NEW_FAILED";
export const PUBLISH_TO_WHATS_NEW_SUCCESS = "PUBLISH_TO_WHATS_NEW_SUCCESS";

// GET USER'S ENTITLEMENT BENEFITS
export const GET_USER_BENEFITS_SUCCESS = "GET_USER_BENEFITS_SUCCESS";
export const GET_USER_BENEFITS_FAILED = "GET_USER_BENEFITS_FAILED";
export const GET_USER_BENEFITS_LOAD = "GET_USER_BENEFITS_LOAD";

// GET all grade,s
export const GET_GRADE_SUCCESS = "GET_GRADE_SUCCESS";
export const GET_GRADE_FAILED = "GET_GRADE_FAILED";
export const GET_GRADE_LOAD = "GET_GRADE_LOAD";

// GET benefits by grade
export const GET_BENEFITS_BY_GRADE_SUCCESS = "GET_BENEFITS_BY_GRADE_SUCCESS";
export const GET_BENEFITS_BY_GRADE_FAILED = "GET_BENEFITS_BY_GRADE_FAILED";
export const GET_BENEFITS_BY_GRADE_LOAD = "GET_BENEFITS_BY_GRADE_LOAD";

// add benefits
export const ADD_BENEFITS_SUCCESS = "ADD_BENEFITS_SUCCESS";
export const ADD_BENEFITS_FAILED = "ADD_BENEFITS_FAILED";
export const ADD_BENEFITS_LOAD = "ADD_BENEFITS_LOAD";

// edit benefits
export const EDIT_BENEFITS_SUCCESS = "EDIT_BENEFITS_SUCCESS";
export const EDIT_BENEFITS_FAILED = "EDIT_BENEFITS_FAILED";
export const EDIT_BENEFITS_LOAD = "EDIT_BENEFITS_LOAD";

// get benefit master data
export const GET_BENEFIT_MASTER_SUCCESS = "GET_BENEFIT_MASTER_SUCCESS";
export const GET_BENEFIT_MASTER_FAILED = "GET_BENEFIT_MASTER_FAILED";
export const GET_BENEFIT_MASTER_LOAD = "GET_BENEFIT_MASTER_LOAD";

// EDIT benefit master data
export const EDIT_BENEFIT_MASTER_SUCCESS = "EDIT_BENEFIT_MASTER_SUCCESS";
export const EDIT_BENEFIT_MASTER_FAILED = "EDIT_BENEFIT_MASTER_FAILED";
export const EDIT_BENEFIT_MASTER_LOAD = "EDIT_BENEFIT_MASTER_LOAD";

// get sapdowntime data
export const GET_SAPDOWNTIME_SUCCESS = "GET_SAPDOWNTIME_SUCCESS";
export const GET_SAPDOWNTIME_FAILED = "GET_SAPDOWNTIME_FAILED";
export const GET_SAPDOWNTIME_LOAD = "GET_SAPDOWNTIME_LOAD";

// edit sapdowntime data
export const EDIT_SAPDOWNTIME_SUCCESS = "EDIT_SAPDOWNTIME_SUCCESS";
export const EDIT_SAPDOWNTIME_FAILED = "EDIT_SAPDOWNTIME_FAILED";
export const EDIT_SAPDOWNTIME_LOAD = "EDIT_SAPDOWNTIME_LOAD";

// post sapdowntime data
export const POST_SAPDOWNTIME_SUCCESS = "POST_SAPDOWNTIME_SUCCESS";
export const POST_SAPDOWNTIME_FAILED = "POST_SAPDOWNTIME_FAILED";
export const POST_SAPDOWNTIME_LOAD = "POST_SAPDOWNTIME_LOAD";

// delete sapdowntime data
export const DELETE_SAPDOWNTIME_SUCCESS = "DELETE_SAPDOWNTIME_SUCCESS";
export const DELETE_SAPDOWNTIME_FAILED = "DELETE_SAPDOWNTIME_FAILED";
export const DELETE_SAPDOWNTIME_LOAD = "DELETE_SAPDOWNTIME_LOAD";

// get podcast data
export const GET_PODCAST_SUCCESS = "GET_PODCAST_SUCCESS";
export const GET_PODCAST_FAILED = "GET_PODCAST_FAILED";
export const GET_PODCAST_LOAD = "GET_PODCAST_LOAD";

// post podcast data
export const ADD_PODCAST_SUCCESS = "ADD_PODCAST_SUCCESS";
export const ADD_PODCAST_FAILED = "ADD_PODCAST_FAILED";
export const ADD_PODCAST_LOAD = "ADD_PODCAST_LOAD";

// delete podcast data
export const DELETE_PODCAST_SUCCESS = "DELETE_PODCAST_SUCCESS";
export const DELETE_PODCAST_FAILED = "DELETE_PODCAST_FAILED";
export const DELETE_PODCAST_LOAD = "DELETE_PODCAST_LOAD";

//localStorage population status
export const GET_LOCALSTORAGE_STATUS_LOADING =
  "GET_LOCALSTORAGE_STATUS_LOADING";
export const GET_LOCALSTORAGE_STATUS_SUCCESS =
  "GET_LOCALSTORAGE_STATUS_SUCCESS";
export const GET_LOCALSTORAGE_STATUS_FAILED = "GET_LOCALSTORAGE_STATUS_FAILED";
