import React, { useState, useEffect } from "react";
import "./CreatePolicy.css";
import leftArrow from "../../../../Assets/Images/WhatsNewIcons/arrow-right-s-line.svg";

import textFieldStyle from "../../../Common/Styles/TextFieldStyle";
import TextArea from "../../../Common/TextArea/TextArea";
import CommonMultiAutocomplete from "../../../Common/CommonMultiAutocomplete/CommonMultiAutocomplete";
import CommonLabel from "../../../Common/ComonLabel/CommonLabel";
import CommonCheckBox from "../../../Common/CommonCheckBox/CommonCheckBox";
import SubmitButton from "../../../Common/Button/SubmitButton";
import services from "../../../../Services";
import CommonModal from "../../../Common/CommonModal/CommonModal";
import editIcon from "../../../../Assets/Images/edit-line.svg";

import Swal from "sweetalert2";
import { useDispatch, useSelector } from "react-redux";
import { getPolicyTableDataByID } from "../../../../Redux/Actions/PolicyCategoryAction";
import DatePicker from "../../../Common/DatePicker/DatePicker";
//upload related
import { uploadStorySchema } from "../../../../Assets/formikErrors/SubmitOfferCampaignSchema";
import uploadIcon from "../../../../Assets/Images/collectiveIcons/upload-2-line.svg";

import moment from "moment";
import { Grid, TextField } from "@material-ui/core";
import { getPolicySubCategoryData } from "../../../../Redux/Actions/PolicyCategoryAction";
import axios from "axios";
import "./CreatePolicy.css";
//import { getPolicyTableDataByID } from "../../../../Redux/Actions/PolicyCategoryAction";
import MultiSelect from "../../../Common/MultiSelect/MultiSelect";
import CustomMultiSelect from "../../../Common/CustomMultiSelect/CustomMultiSelect";
import CharLeftField from "../../../Common/CharLeftField/CharLeftField";
import { getSopTableDataByID } from "../../../../Redux/Actions/SOPsAction";

function EditSop({ open, setopen, element }) {
  const [selectedBusinessUnitOptions, setSelectedBusinessUnitOptions] =
    useState([]);
  const [selectedSectorOptions, setSelectedSectorOptions] = useState([]);

  const [slectedBuRev, setSlectedBuRev] = useState([]);
  const [selectedSectorRev, setSelectedSectorRev] = useState([]);
  const [hastagsLeft, setHastagsLeft] = React.useState(3);

  const [isNotify, setIsNotify] = useState(false);
  const [confirmNotify, setConfirmNotify] = useState(false);

  const [element2, setelement2] = useState({});

  //to get selected bu list.
  useEffect(() => {
    let buList = selectedBusinessUnitOptions?.map((el) => {
      return {
        buId: el.value,
        buName: el.title,
      };
    });
    setSlectedBuRev(buList);
  }, [selectedBusinessUnitOptions]);

  //to get selected sector list.
  useEffect(() => {
    let sectorList = selectedSectorOptions?.map((el) => {
      return {
        sectorId: el.value,
        sectorName: el.title,
      };
    });
    setSelectedSectorRev(sectorList);
  }, [selectedSectorOptions]);
  useEffect(() => {
    setelement2(element);

    const sectorDropDown = element?.sectorListDropDown?.map((el) => {
      return {
        title: el.sectorName,
        value: el.sectorId,
      };
    });
    const buDropDown = element?.buListDropDown?.map((el) => {
      return {
        title: el.buName,
        value: el.buId,
      };
    });

    const sectorList = element?.sectorListDropDown?.map((el) => {
      return {
        addsectorId: el.sectorId,
      };
    });

    //getBusinessUnitBySector(sectorList)

    setSelectedSectorOptions(sectorDropDown);
    setSelectedBusinessUnitOptions(buDropDown);
  }, [element]);

  const dispatch = useDispatch();

  const [state, setState] = React.useState(element);
  const [policyName, setpolicyName] = useState("");
  const [policyPdf, setpolicyPdf] = useState("");
  const [policyDescription, setpolicyDescription] = useState("");
  const [policyObjective, setpolicyObjective] = useState("");
  const [policyEligibility, setpolicyEligibility] = useState("");
  const [policyGuidlines, setpolicyGuidlines] = useState("");
  const [policyKey, setpolicyKey] = useState("");
  const [pdfname, setpdfname] = useState("");
  const [sectorList, setSectorList] = useState([]);
  const [buList, setBuList] = useState([]);
  const [businessUnitData, setBusinessUnitData] = useState([]);
  const [effectiveDate, setEffectiveDate] = useState(null);
  React.useEffect(() => {
    // setState(element);
    // setpolicyName(element.policyName)
    // setpolicyPdf(element.policy_pdf_link)
    // setpolicyDescription(element.policyDescription)
    // setpolicyObjective(element.policyObjective)
    // setpolicyEligibility(element.policyEligibility)
    // setpolicyGuidlines(element.policyGuidlines)
    // setpdfname(element.policy_pdf_link.split('/')[4])
  }, [open]);
  useEffect(() => {
    setpolicyName(element.policyName);
    setpolicyPdf(null);
    setpolicyDescription(element.description);
    setpolicyObjective(element.objectives);
    setpolicyEligibility(element.eligibility);
    setpolicyGuidlines(element.generalGuidLines);
    setpdfname(element?.policy_pdf_link);
    setpolicyKey(element?.keys);
    setSectorList(element.sectorList);
    setBuList(element.buList);
    setEffectiveDate(new Date(element.effectiveDate));
    console.log(
      "previous date....",
      effectiveDate,
      new Date(element.effectiveDate)
    );
  }, [open]);

  const classes = textFieldStyle();

  //CUSTOM MULTISELECT.
  const getOptionLabel = (option) => `${option.title}`;

  const getOptionDisabled = (option) => option.value === "foo";

  // const [selectedBusinessUnitOptions, setSelectedBusinessUnitOptions] = useState([]);
  const [sectorListData, setSectorListData] = useState([]);
  const [buisnessUnitList, setBuisnessUnitList] = useState([]);

  const [refresh, setrefresh] = useState(false);
  const [businessGroupValue, setbusinessGroupValue] = React.useState([]);
  const [loading, setLoading] = useState(true);
  const [checkThumbnail, setCheckThumbnail] = useState(null);
  const [sectorData, setSectorData] = useState([]);
  const [getDivisionList, setGetDivisionList] = useState([]);
  const [sector, setSector] = useState([]);

  //handle business unit.
  const handleBusinessUnit = (params) => {};

  const getSelectedSectorOptions = (selectedOptions) => {
    const xyz = selectedOptions.map((el) => {
      return {
        addsectorId: el.value,
      };
    });
    getBusinessUnitBySector(xyz);
  };

  console.log(policyName, "policyName....");

  //get business unit on the sector params.
  const getBusinessUnitBySector = async (data) => {
    try {
      setLoading(true);
      let res = await services.api("POST", `/ui-assist/v1/businessunit`, data);
      if (res.status === 200) {
        setLoading(false);
        let list = res.data.map((result) => {
          return {
            title: result.buName,
            value: result.id,
          };
        });

        setSelectedBusinessUnitOptions(list);
        setBusinessUnitData(list);
      }
    } catch (err) {
      setLoading(false);
      Swal.fire("Oops!", `${err.response.data}`, "error");
    }
  };

  useEffect(() => {
    dispatch(getSopTableDataByID(element.subcategory));
  }, [refresh]);

  const handleSubmit = () => {
    const formData = new FormData();

    formData.append("policy_pdf_link", policyPdf);

    formData.append("scope", "gloable");
    formData.append("policyName", policyName);
    formData.append("subcategory", element.subcategory);
    formData.append("description", policyDescription);
    formData.append("employeeId", localStorage.getItem("token"));
    formData.append("objectives", policyObjective);
    formData.append("limits", "limits not assigned");
    formData.append("eligibility", policyEligibility);
    formData.append("generalGuidLines", policyGuidlines);
    formData.append("keys", policyKey);
    formData.append("policyId", element.policyId);
    formData.append("sectorList", sectorList);
    formData.append("buList", buisnessUnitList);
    formData.append("sectorListDropDown", JSON.stringify(selectedSectorRev));
    formData.append("buListDropDown", JSON.stringify(slectedBuRev));
    formData.append("isNotify", confirmNotify);
    formData.append(
      "effectiveDate",
      moment(effectiveDate).format("YYYY-MM-DD")
    );

    let data = formData;
    handleEditCategoryApi(data);
  };
  const handleCloseModel = () => {
    setopen(false);
  };
  const onBackButtonHandler = () => {
    setopen(false);
  };

  const handleChange = (e, name) => {
    if (name === "name") {
      setpolicyName(e.target.value);
    }
    if (name === "Description") {
      setpolicyDescription(e.target.value);
    }
    if (name === "Objective") {
      setpolicyObjective(e.target.value);
    }
    if (name === "Eligibility") {
      setpolicyEligibility(e.target.value);
    }
    if (name === "Guidlines") {
      setpolicyGuidlines(e.target.value);
    }
    if (name === "pdf") {
      setpolicyPdf(e.target.files[0]);
    }
    if (name === "key") {
      setpolicyKey(e.target.value);
    }
  };

  const handleEditCategoryApi = async (data) => {
    try {
      const res = await services.api("PUT", `/ui-assist/v1/sop`, data);
      if (res) {
        Swal.fire({
          icon: "success",
          title: "success",
          text: "SOP edited successfully!",
        });
        setrefresh(!refresh);
        setopen(false);
      }
    } catch (err) {
      if (err.response) {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Something went wrong!",

          footer: `${err.message}`,
        });
      }
    }
  };

  //get all sectors.
  useEffect(() => {
    (async () => {
      try {
        setLoading(true);
        const res = await services.api("GET", `/ui-assist/v1/sector`);
        if (res.status === 200) {
          setLoading(false);
          const allSectorItem = res?.data?.map((el) => {
            return {
              title: el.sectorName,
              value: el.id,
            };
          });
          setSectorData(allSectorItem);
          //setBuisnessUnitList()
        }
      } catch (err) {
        setLoading(false);
        Swal.fire("Oops!", `${err.response.data}`, "error");
        setSectorData([]);
      }
    })();
  }, []);

  const handleBusiness = (params) => {};

  //to get selected sector list.
  useEffect(() => {
    let dataSectorList = selectedSectorOptions?.map((el) => {
      return el.value;
    });

    // setSectorListData(dataSectorList.join(",").toString());
  }, [selectedSectorOptions]);

  //to get selected bu list.
  useEffect(() => {
    let dataBuList = selectedBusinessUnitOptions?.map((el) => {
      return el.value;
    });
    setBuisnessUnitList(dataBuList?.join(",").toString());
  }, [selectedBusinessUnitOptions]);
  //handle toggle for all the dropdowns
  const handleToggleOption = (selectedOptions, name) => {
    if (name === "sector") {
      setSelectedSectorOptions(selectedOptions);
      getSelectedSectorOptions(selectedOptions);
    } else if (name === "bu") {
      setSelectedBusinessUnitOptions(selectedOptions);
    }
  };

  //handle clear for all the dropdown's
  const handleClearOptions = (isSelected, name) => {
    if (name === "sector") {
      setSelectedSectorOptions([]);
      setSelectedBusinessUnitOptions([]);
    } else if (name === "bu") {
      setSelectedBusinessUnitOptions([]);
      //setSelectedSectorOptions([]);
      // setBusinessUnitData([]);
    }
  };

  //handle select all for dropdown's.
  const handleSelectAll = (isSelected, name) => {
    if (isSelected) {
      if (name === "sector") {
        setSelectedSectorOptions(sectorData);
        getSelectedSectorOptions(sectorData);
      } else if (name === "bu") {
        setSelectedBusinessUnitOptions(businessUnitData);
      } else {
        handleClearOptions();
      }
    }
  };

  //handle effective date change.....
  const handleEditEffectiveDateChange = (e, x) => setEffectiveDate(e);

  const handleNotificationChange = (event) => {
    if (isNotify) {
      setIsNotify(false);
      setConfirmNotify(false);
    } else {
      setIsNotify(true);
    }
  };
  const handleConfirmNotificationChange = (event) => {
    setConfirmNotify(!confirmNotify);
  };

  return (
    <>
      <CommonModal
        open={open}
        handleClose={handleCloseModel}
        headerText="Edit SOP"
        icon={editIcon}
        modalSize="lg"
        submitBtnText="SAVE CHANGES"
        cancleBtnText="BACK"
        // isBackBtn={true}
        // header={header}
        // footer={footer}
        onButtonClick={handleSubmit}
        fullWidth
        policiesButtonSize
        onCancleButtonClick={onBackButtonHandler}
      >
        <div className="leftRightAlign mb16">
          <div
            style={{
              display: "flex",
              alignItems: "center",
              marginLeft: "0px",
              marginBottom: "24px",
            }}
          >
            {/*<img
              src={process.env.PUBLIC_URL + "/policies/arrowleft.svg"}
              alt="Add"
              style={{ marginRight: "0px" }}
            />
            <span
              style={{
                fontSize: "12px",
                color: "#e31837",
                fontWeight: "600",
                lineHeight: "2",
              }}
            >
              EDIT POLICIES
            </span> */}
          </div>
          <Grid
            container
            direction="row"
            alignItems="center"
            spacing={3}
            className="editPolicyInnerHeader"
          >
            <Grid item sm={3} xs={12} className="addPolicyFeild1">
              <TextField
                variant="outlined"
                name="name"
                label="SOP name"
                className={`${classes.root} fullWidth customTextfield `}
                onChange={(e) => {
                  handleChange(e, "name");
                }}
                defaultValue={policyName}
              />
            </Grid>

            <Grid item md={3} xs={6} style={{ marginLeft: "342px" }}>
              <div className="SpT ">Last modified by</div>
              <div className="SpTS ">{element.modifiedBy}</div>
            </Grid>

            <Grid item md={2} xs={6}>
              <div className="SpT ">Modified on</div>
              <div className="SpTS ">
                {moment(element.modificationDate).format("DD/MM/YYYY")}
              </div>
            </Grid>
          </Grid>
          <Grid
            container
            direction="row"
            alignItems="center"
            spacing={3}
            style={{ marginBottom: "10px" }}
          >
            <Grid item xs={12} md={3}>
              <DatePicker
                // disabled={edit}

                label={<CommonLabel label="Effective Date" />}
                selectedDate={effectiveDate}
                minDate={new Date().setDate(new Date().getDate())}
                handleDateChange={(e, x) => {
                  handleEditEffectiveDateChange(e, x);
                  console.log(e, x, "event>>>>>>>>>>>>>>>>>>>>");
                }}
                name="effectiveDate"
                disableToolbarr={true}
              />
            </Grid>
            <Grid item md={3} xs={12} className="uploadBtnStyle">
              <div className="uploadBtn btnEditSub">
                <label for="thumb" className="btn uploadBtnLabel">
                  {pdfname}
                  <span className="colRed">*</span>
                </label>
                <img
                  for="thumb"
                  src={uploadIcon}
                  className="uploadIconStyl"
                  alt="Upload Button Icon"
                />
                <input
                  id="thumb"
                  style={{
                    visibility: "hidden",
                    padding: "19px 16px",
                    position: "absolute",
                  }}
                  type="file"
                  accept=".pdf"
                  name="pdf"
                  onChange={(e) => {
                    handleChange(e, "pdf");
                  }}
                />
              </div>
            </Grid>
            <div className="compatiblityTxt">Compatible formats: Pdf.</div>
          </Grid>

          <Grid container direction="row" alignItems="center" spacing={3}>
            <Grid item sm={12} xs={12}>
              <TextField
                variant="outlined"
                name="Description"
                label="Description"
                className={`${classes.root} fullWidth customTextfield`}
                onChange={(e) => {
                  handleChange(e, "Description");
                }}
                defaultValue={policyDescription}
              />
            </Grid>
            <Grid item sm={12} xs={12}>
              <TextField
                variant="outlined"
                name="Objective"
                label="Objective"
                className={`${classes.root} fullWidth customTextfield`}
                onChange={(e) => {
                  handleChange(e, "Objective");
                }}
                defaultValue={policyObjective}
              />
            </Grid>
            <Grid item sm={12} xs={12}>
              <TextField
                variant="outlined"
                name="Eligibility"
                label="Eligibility"
                className={`${classes.root} fullWidth customTextfield`}
                onChange={(e) => {
                  handleChange(e, "Eligibility");
                }}
                defaultValue={policyEligibility}
              />
            </Grid>
            <Grid item sm={12} xs={12}>
              <TextField
                variant="outlined"
                label="Key"
                onChange={(e) => handleChange(e, "key")}
                name="key"
                className={`${classes.root} fullWidth customTextfield`}
                // // errorMsg={
                // //   error.policyHashtagError ? error.policyHashtagError : ""
                // // }
                // wordCount={hastagsLeft}
                // name="key"
                // type="text"
                defaultValue={policyKey}
              />
            </Grid>

            <Grid item sm={12} xs={12}>
              <TextField
                label="Guidelines"
                name="Guidlines"
                multiline
                rows={8}
                defaultValue={policyGuidlines}
                className={`${classes.root} fullWidth customTextfield sairaFp editSub subCtE`}
                onChange={(e) => {
                  handleChange(e, "Guidlines");
                }}
              />
            </Grid>
            <Grid
              item
              sm={3}
              xs={12}
              className="calenderIcon appPolicyContainer"
            ></Grid>
          </Grid>
          <Grid container spacing={3} style={{ padding: "0px 12px" }}>
            <Grid
              item
              sm={3}
              xs={12}
              className="policiesMultiIcon policiesAlignMulti editPModal"
            >
              <CustomMultiSelect
                items={sectorData}
                getOptionLabel={getOptionLabel}
                getOptionDisabled={getOptionDisabled}
                selectedValues={selectedSectorOptions}
                label={<CommonLabel label="Search sector" />}
                selectAllLabel="Select all"
                onToggleOption={(isSelected) => {
                  handleToggleOption(isSelected, "sector");
                }}
                onClearOptions={(isSelected) => {
                  handleClearOptions(isSelected, "sector");
                }}
                onSelectAll={(isSelected) => {
                  handleSelectAll(isSelected, "sector");
                }}
              />
            </Grid>
            <Grid
              item
              sm={3}
              xs={12}
              className="policyBU policiesAlignMulti editPModal"
            >
              <CustomMultiSelect
                items={businessUnitData}
                getOptionLabel={getOptionLabel}
                getOptionDisabled={getOptionDisabled}
                selectedValues={selectedBusinessUnitOptions}
                label={<CommonLabel label="Search business unit" />}
                selectAllLabel="Select all"
                onToggleOption={(isSelected) => {
                  handleToggleOption(isSelected, "bu");
                }}
                onClearOptions={(isSelected) => {
                  handleClearOptions(isSelected, "bu");
                }}
                onSelectAll={(isSelected) => {
                  handleSelectAll(isSelected, "bu");
                }}
              />
            </Grid>
          </Grid>
        </div>
      </CommonModal>
    </>
  );
}

export default EditSop;
