import { Grid, TextField } from "@material-ui/core";
import React, { useState, useEffect } from "react";
import editIcon from "../../../../Assets/Images/edit-line.svg";
import CommonModal from "../../../Common/CommonModal/CommonModal";
import textFieldStyle from "../../../Common/Styles/TextFieldStyle";
import Services from "../../../../Services";
import Swal from "sweetalert2";
import { useDispatch } from "react-redux";
import { getPolicySubCategoryData } from "../../../../Redux/Actions/PolicyCategoryAction";
function AddSubCategory({ open, close, closeEdit, openEdit, data, nullItem }) {
  const dispatch = useDispatch();
  let cat_id = window.location.pathname.split("/")[4];
  const [subcategoryTitle, setsubcategoryTitle] = useState("");
  const [subcategoryDescription, setsubcategoryDescription] = useState("");
  const [refresh, setrefresh] = useState(false);

  useEffect(() => {
    dispatch(getPolicySubCategoryData(cat_id));
  }, [refresh]);

  const classes = textFieldStyle();
  const handleCloseModal = () => {
    close(false);
    closeEdit(false);
    nullItem(null);
  };
  const onBackButtonHandler = () => {
    close(false);
    closeEdit(false);
    nullItem(null);
  };

  const handleEditSubmit = () => {
    let edit_data = {
      policySubCategoryId: data.policySubCategoryId,
      categoryId: data.categoryId,
      employeeId: localStorage.getItem("token"),
      description: subcategoryDescription,
      title: subcategoryTitle,
    };
    handleSubCategoryEditApi(edit_data);
  };

  //api edit function
  const handleSubCategoryEditApi = async (data) => {
    try {
      const res = await Services.api(
        "PUT",
        `/ui-assist/v1/policysubcategory`,
        data
      );
      if (res) {
        close(false);
        Swal.fire({
          position: "center",
          icon: "success",
          title: "category added successfully",
          showConfirmButton: false,
          timer: 1500,
        });
        closeEdit(false);
        setrefresh(!refresh);
        nullItem(null);
      }
    } catch (err) {
      if (err.response) {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Something went wrong!",

          footer: `${err.message}`,
        });
        setrefresh(!refresh);
      }
    }
  };

  //api delete function

  const handleCategoryDeleteApi = async (data) => {
    Swal.fire({
      title: "Are you sure?",
      text: "All Policies in this category will be deleted",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        Services.api("DELETE", `/ui-assist/v1/policysubcategory/${data.policySubCategoryId}`)
          .then((res) => {
            Swal.fire("Deleted!", "Policy has been deleted.", "success");
            setrefresh(!refresh);
          })
          .catch((err) => {
            Swal.fire({
              icon: "error",
              title: "Oops...",
              text: "Something went wrong!",

              footer: `${err.message}`,
            });
          });
      }
    });
  };

  //api function
  const handleSubCategorySubmitApi = async (data) => {
    try {
      const res = await Services.api(
        "POST",
        `/ui-assist/v1/policysubcategory`,
        data
      );
      if (res) {
        close(false);
        Swal.fire({
          position: "center",
          icon: "success",
          title: "category added successfully",
          showConfirmButton: false,
          timer: 1500,
        });
        setrefresh(!refresh);
      }
    } catch (err) {
      if (err.response) {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Something went wrong!",

          footer: `${err.message}`,
        });
      }
    }
  };

  const handleSubmit = () => {
    let data = {
      title: subcategoryTitle,
      description: subcategoryDescription,
      categoryId: cat_id,

      employeeId: localStorage.getItem("token"),
    };
    handleSubCategorySubmitApi(data);
  };
  const handleEditChange = (e, name) => {
    if (name === "EditSubcategory") {
      setsubcategoryTitle(e.target.value);
    }
    if (name === "Description") {
      setsubcategoryDescription(e.target.value);
    }
  };
  const handleChange = (e, name) => {
    if (name === "AddSubcategory") {
      setsubcategoryTitle(e.target.value);
    }
    if (name === "Description") {
      setsubcategoryDescription(e.target.value);
    }
  };

  

  return (
    <>
      <CommonModal
        open={open || openEdit}
        handleClose={handleCloseModal}
        headerText={data ? "Edit Policy" : "Add Policy"}
        icon={editIcon}
        modalSize="lg"
        submitBtnText="SAVE CHANGES"
        cancleBtnText="BACK"
        // isBackBtn={true}
        // header={header}
        // footer={footer}
        onButtonClick={data ? handleEditSubmit : handleSubmit}
        fullWidth
        policiesButtonSize
        onCancleButtonClick={handleCloseModal}
        className="editAddPolicyModal"
      >
        <div className="leftRightAlign mb16">
          <Grid
            container
            direction="row"
            className="gridRowSpacing"
            spacing={3}
          >
            <Grid item sm={12} xs={12}>
              {data ? (
                <TextField
                  variant="outlined"
                  name="EditSubcategory"
                  label=" Edit Subcategory"
                  className={`${classes.root} fullWidth customTextfield`}
                  onChange={(e) => {
                    handleEditChange(e, "EditSubcategory");
                  }}
                  defaultValue={data.title}
                />
              ) : (
                <TextField
                  variant="outlined"
                  name="AddSubcategory"
                  label="Subcategory"
                  className={`${classes.root} fullWidth customTextfield`}
                  onChange={(e) => {
                    handleChange(e, "AddSubcategory");
                  }}
                  defaultValue="add subcategory"
                />
              )}
            </Grid>
            <Grid item sm={12} xs={12}>
              {data ? (
                <TextField
                  variant="outlined"
                  name="Description"
                  label="edit Sub Category"
                  className={`${classes.root} fullWidth customTextfield`}
                  onChange={(e) => {
                    handleEditChange(e, "Description");
                  }}
                  defaultValue={data.description}
                />
              ) : (
                <TextField
                  variant="outlined"
                  name="Description"
                  label="Description"
                  className={`${classes.root} fullWidth customTextfield`}
                  onChange={(e) => {
                    handleChange(e, "Description");
                  }}
                  defaultValue="add category"
                />
              )}
            </Grid>
          </Grid>
        </div>
      </CommonModal>
    </>
  );
}

export default AddSubCategory;
