import {
  GET_PRIORITY_DATA_LOAD,
  GET_PRIORITY_DATA_FAILED,
  GET_PRIORITY_DATA_SUCCESS,
  GET_FAVOURITE_DATA_LOAD,
  GET_FAVOURITE_DATA_SUCCESS,
  GET_FAVOURITE_DATA_FAILED,
} from "../Contants/Constants";

const initialState = {
  getData: [],
  isLoading: false,
  isError: "",
};

const initialStateFavourite = {
  getData: [],
  isLoading: false,
  isError: "",
};

const getPriorityDataReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_PRIORITY_DATA_LOAD:
      return {
        ...state,
        isLoading: true,
      };
    case GET_PRIORITY_DATA_SUCCESS:
      return {
        ...state,
        getData: action.payload,
        isLoading: false,
      };
    case GET_PRIORITY_DATA_FAILED:
      return {
        ...state,
        isError: action.payload,
        isLoading: false,
      };
    default:
      return state;
  }
};

const getFavouriteDataReducer = (state = initialStateFavourite, action) => {
  switch (action.type) {
    case GET_FAVOURITE_DATA_LOAD:
      return {
        ...state,
        isLoading: true,
      };
    case GET_FAVOURITE_DATA_SUCCESS:
      return {
        ...state,
        getData: action.payload,
        isLoading: false,
      };
    case GET_FAVOURITE_DATA_FAILED:
      return {
        ...state,
        isError: action.payload,
        isLoading: false,
      };
    default:
      return state;
  }
};

export { getFavouriteDataReducer, getPriorityDataReducer };

// export default getPriorityDataReducer;
