import React, { useState, useEffect } from "react";
import "./ExperienceItem.css";
import {
  Card,
  CardContent,
  CardMedia,
  CardActions,
  // Icon,
  Paper,
  Popper,
  ClickAwayListener,
} from "@material-ui/core";
import { useHistory } from "react-router-dom";
import { Button } from "@material-ui/core";
import shareFillIcon from "../../../../Assets/Images/share-fill.svg";
import deleteIcon from "./../../../../Assets/Images/delete.svg";
import mailBoxIcon from "./../../../../Assets/Images/collectiveIcons/mail-line.svg";
import teamsIcon from "./../../../../Assets/Images/collectiveIcons/teamsIcon.jpg";
import yammerIcon from "./../../../../Assets/Images/collectiveIcons/Yammer.jpg";
import ReactPlayer from "react-player/lazy";
// import Card from "@material-ui/core/Card";
import CardActionArea from "@material-ui/core/CardActionArea";

import "../../../DashBoard/WhatsNew/WhatsNewComponent.css";
import CommonModal from "../../../Common/CommonModal/CommonModal";
import IconButton from "@material-ui/core/IconButton";
import Avatar from "@material-ui/core/Avatar";
import heartIcon from "../../../../Assets/Images/heart-3-line.svg";
import heartIconFill from "../../../../Assets/Images/heartred-fill.svg";


import RemoteModal from "../../Remote/RemoteVideoModal";
import moment from "moment";
import { LIVEWIRE, MAHINDRA_EXPERIENCE_DETAIL } from "../../../../Routes/constant";
import services from "../../../../Services";
//For Like API integration
import { useDispatch, useSelector } from "react-redux";
import { likeArticle } from "../../../../Redux/Actions/CommonActions";
import { getFormattedDate } from "../../../../Assets/helperFunctions/helperFunctions";
import Sharefiles from "../../../Common/Shareoption/Sharefiles";
import { AspectRatio } from "react-aspect-ratio";
import Highlighter from "react-highlight-words";

const ExperienceItem = ({
  allList,
  list,
  footerIcon,
  currentTab,
  handleDelete,
  searchText,
}) => {
  const [likeCount, setlikeCount] = useState(null);
  const {
    thumbnail,
    videoUri,
    title,
    subTitle,
    status,
    // detailsPage,
    name,
    empPhotoUrl,
    publishDate,
    articalLikes,
    count,
    id,
    submittedFor,
    isAuthorPost,
  } = list;

  const dispatch = useDispatch();
  let pathURL = `/connect/livewire/experience-detail/${id}`;
  let articleID = id;
  const history = useHistory();
  const [anchorEl, setAnchorEl] = React.useState(null);

  //For Like/ unlike feature
  const [isLiked, setIsLiked] = useState(false);
  const [likesCount, setLikesCount] = useState(count);
  let empId = localStorage.getItem("token");

  const currentStatus = useSelector((state) => {
    return state?.likeDataReducer;
  });

  const deleteStatus = useSelector((state) => {
    return state?.deleteArticleReducer;
  });

  const handleClickAway = () => {
    setAnchorEl(false);
  };

  //video modal
  const [open, setOpen] = React.useState(false);
  const modal = () => {
    setOpenSettingssub(!openSettingssub);
  };
  const [openSettingssub, setOpenSettingssub] = useState(false);

  //
  const handleAnchorEl = (event, id) => {
    event.stopPropagation();
    event.preventDefault();
    setAnchorEl(anchorEl ? null : event.currentTarget);
    // setAnchorEl(event.currentTarget);
  };

  const renderDetailsPage = (allList) => {
    history.push({
      pathname: `${LIVEWIRE}/experience-detail/${list.id}`,
      state: {
        list: list ? list : { name: "neeraj" },
        typeCheckForMahindra: "mahindraexperience",
        contentType: currentTab,
        empPhotoUrl: empPhotoUrl,
      },
    });
  };

  const handleLike = async () => {
    try {
      dispatch(likeArticle("MahindraExperience", id));

      if (!isLiked) {
        setLikesCount(likesCount + 1);
      } else {
        if (likesCount > 0) {
          setLikesCount(likesCount - 1);
        }
      }
      setIsLiked(!isLiked);
    } catch (error) {}
  };

  useEffect(() => {
    if (articalLikes) {
      let isExistinLike = false;
      articalLikes?.map((obj) => {
        if(obj?.employeeId == empId)
        { isExistinLike = true; }
      });
      setIsLiked(isExistinLike ? isExistinLike : false);
      setLikesCount(articalLikes?.length);
    }
  }, []);

  return (
    <Card className="cardShadow">
      <CardMedia className="card-media" style={{ display: "flex" }}>
        <AspectRatio ratio="3/4" style={{ maxWidth: "400px" }}>
          <img
            onClick={() => renderDetailsPage(allList)}
            src={thumbnail}
            alt="Card Logo"
            className="McCardImage"
          />
        </AspectRatio>
        {videoUri && (
          <>
            <img
              src={process.env.PUBLIC_URL + "/youtube.png"}
              alt="Card Logo"
              style={{ position: "absolute", width: "40%", cursor: "pointer" }}
              onClick={modal}
            />
          </>
        )}
      </CardMedia>
      <CardContent>
        <p
          className="McCardDescription cursorPointer"
          onClick={() => renderDetailsPage(allList)}
        >
          <Highlighter
            highlightStyle={{ backgroundColor:"yellow" }}
            searchWords={searchText.split(" ")}
            textToHighlight={title}
          />
        </p>
        <p className="McCardDesc">
          <Highlighter
            highlightStyle={{ backgroundColor:"yellow" }}
            searchWords={searchText.split(" ")}
            textToHighlight={subTitle}
          />
        </p>
        <>
          {currentTab == "mycontribution" && (
            <span className="status">
              Status:
              {status === "published" ? (
                <span className="statusApproved"> Published</span>
              ) : status === "rejected" ? (
                <span className="statusRejected"> Rejected</span>
              ) : (
                <span className="statusPendingOrange"> Pending</span>
              )}
            </span>
          )}
        </>
      </CardContent>

      <CardActions className="">
        <div className="cardActionContainer">
          <div className="avatarContainer">
            {footerIcon ? (
              <>
                <div className="avatarIcon">
                  {empPhotoUrl && isAuthorPost == true ? (
                    <img src={empPhotoUrl} alt="" />
                  ) : (
                    <Avatar style={{ height: 24, width: 24 }}></Avatar>
                  )}
                </div>
                <div className="avatarDetails">
                  <div className="avatarName">
                    {isAuthorPost == false ? submittedFor : name}
                  </div>
                  <div className="dateContainer">
                    {getFormattedDate(publishDate)}
                  </div>
                </div>
              </>
            ) : (
              <>
                <Button variant="outlined" className="tipsBtn">
                  Tips
                </Button>
              </>
            )}
          </div>

          {currentTab !== "mycontribution" &&
          <div className="">
            <IconButton aria-label="share" className="p-0">
              <img
                src={
                  anchorEl
                    ? shareFillIcon
                    : process.env.PUBLIC_URL + "/share_icon.png"
                }
                alt="Share Icon"
                className="shareIcon"
                onClick={(event) => handleAnchorEl(event)}
              />
            </IconButton>
            <Popper
              className="popperBlock"
              open={Boolean(anchorEl)}
              anchorEl={anchorEl}
            >
              <ClickAwayListener onClickAway={handleClickAway}>
                <Paper
                  style={{
                    width: "226px",
                    height: "96px",
                    position: "relative",
                    right: "auto",
                    top: "14px",
                    boxShadow: "none",
                  }}
                  className="papperClass"
                >
                  <Sharefiles pathURL={pathURL} id={articleID} contentType="MahindraExperience" />
                </Paper>
              </ClickAwayListener>
            </Popper>

            <IconButton
              aria-label="add to favorites"
              onClick={handleLike}
              style={{ paddingRight: "5px", marginRight: "10px" }}
            >
              {isLiked ? (
                <img src={heartIconFill} alt="unlike" />
              ) : (
                <img src={heartIcon} alt="like" />
              )}
            </IconButton>
            <span style={{ paddingLeft: "5px" }} className="articleCount">
              {likesCount}
            </span>
          </div>
          }
        </div>
      </CardActions>

      <RemoteModal
        open={openSettingssub}
        handleClose={() => setOpenSettingssub(false)}
        headerText="Video"
        modalSize="md"
        header={true}
        footer={true}
        fullWidth
        link={videoUri}
      />
    </Card>
  );
};

export default ExperienceItem;
