import React from "react";
import "./TextArea.css";
import { TextareaAutosize } from "@material-ui/core";

function TextArea({
  placeholder,
  handleChangeTextArea,
  value,
  maxLength,
  name,
}) {
  return (
    <TextareaAutosize
      aria-label="empty textarea"
      placeholder={placeholder}
      className="textareastyle commonFontNormalStyle"
      onChange={handleChangeTextArea}
      value={value}
      maxLength={maxLength}
      name={name}
    />
  );
}

export default TextArea;
