import React from "react";
import { FormControlLabel, Switch } from "@material-ui/core";
import PropTypes from "prop-types";

function CommonSwitch({
  labelPlacement,
  label,
  checked,
  handleChange,
  name,
  style,
  defaultChecked
}) {
  return (
    <div>
      <FormControlLabel
        labelPlacement={labelPlacement && labelPlacement}
        label={label && label}
        control={
          <Switch
          defaultChecked={defaultChecked}
            checked={checked}
            onChange={handleChange}
            name={name}
            style={style && style}
          />
        }
      />
    </div>
  );
}

CommonSwitch.propTypes = {
  labelPlacement: PropTypes.string,
  label: PropTypes.string,
  checked: PropTypes.bool,
  handleChange: PropTypes.func,
  name: PropTypes.string,
  style: PropTypes.object,
};

CommonSwitch.defaultProps = {
  labelPlacement: "start",
  label: "",
  checked: false,
  handleChange: () => {},
  name: "",
  style: {},
};

export default CommonSwitch;
