import { Grid, Icon, Paper, TextField } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import CommonAutocomplete from "../../Common/CommonAutocomplete/CommonAutocomplete";
import CommonMultiAutocomplete from "../../Common/CommonMultiAutocomplete/CommonMultiAutocomplete";
import CommonLabel from "../../Common/ComonLabel/CommonLabel";
import "./AddNewIntranetAccessComponent.css";
import DatePicker from "../../Common/DatePicker/DatePicker";

import Autocomplete from "@material-ui/lab/Autocomplete";
import services from "../../../Services";
import Swal from "sweetalert2";
import Loader from "../../Common/Loader/Loader";
import CircularProgress from "@mui/material/CircularProgress";
import Box from "@mui/material/Box";
import moment from "moment";
// import { DateTimePicker } from "@material-ui/pickers";
import addIcon from "../../../Assets/Images/add-white.svg";
import AddNewUserGroupComponent from "./AddNewUserGroupComponent";
import CommonModal from "../../Common/CommonModal/CommonModal";
import MultiSelect from "../../Common/MultiSelect/MultiSelect";
import CustomMultiSelect from "../../Common/CustomMultiSelect/CustomMultiSelect";
export default function EditIntranetAccessComponent({
  setSelectedMenu,
  setSelectedSubMenu,
  setActiveFrom,
  setActiveTo,
  setSelectedUserGroup,
  activeTo,
  activeFrom,
  selectedUserGroup,
  selectedUserGropName,
  setSelectedUserGroupName,
  employeeCount,
  innerLoader,
  handleActiveFromDate,
  handleActiveToDate,
  selectedSubMenuList,
  setSelectedSubMenulist,
  previosValues,
  defaultmenuItemData,
  defaultSubmenuItemData,
  SelectedSubMenuItemData,
  selecteduserGroupData, 
  setSelecteduserGroupData, 
  selectedStatus, 
  setSelectedStatus, 
  currentDateType,
  setCurrentDateType
}) {
  

  const [menuItemData, setMenuItemData] = useState([]);
  
  const [submenuItemData, setSubMenuItemData] = useState([]);
  const [userGruoupItemData, setUserGruoupItemData] = useState([]);
  const [dateType, setDateType] = useState(selectedStatus?.title);
  
  const [loading, setLoading] = useState(false);
  const [disableSubMenu, setDisableSubMenu] = useState(true);
  const [pageRefresh, setpageRefresh] = useState(false);

  const [openModalNewUserGroup, setOpenModalNewUserGroup] = useState(false);
  const [currentUserGroup, setCurrentUserGroup] = useState("");
  const [userCount, setUserCount] = useState(0);
  const handleCloseModal = () => {
    //setOpenModal(false);
    setOpenModalNewUserGroup(false);
  };

  const [selectedSubMenuData, setSelectedSubMenuData] = useState([]);
  const [defaultSubMenuOption, setDefaultSubMenuOption] = useState([]);

//to get selected sector list.
useEffect(() => {
  let dataSubMenuList = selectedSubMenuData?.map((el) => {
    return el.value;
  });

  setSelectedSubMenulist(dataSubMenuList);
}, [selectedSubMenuData]);
  //custome dropdown.
  const getOptionLabel = (option) => `${option.title}`;

  const getOptionDisabled = (option) => option.value === "foo";
  const handleToggleOption = (selectedOptions, name) => {
    
      
      console.log(selectedOptions, 'selectedOptions.....');
      setSelecteduserGroupData(selectedOptions)
    
   // setSelectedSubMenuData(selectedOptions)
    //setSelectedSubMenulist(selectedSubMenuList)
  };

  //handle clear for all the dropdown's
  const handleClearOptions = (isSelected, name) => {
    setSelecteduserGroupData([])
    //setSelectedSubMenulist(selectedSubMenuList)
  };

  //handle select all for dropdown's.
  const handleSelectAll = (isSelected, name) => {
   
    setSelecteduserGroupData(userGruoupItemData)
    //setSelectedSubMenu(selectedSubMenuList)
  };

  // useEffect(() => {
  //   (async () => {
  //     try {
  //       setLoading(true);
  //       const res = await services.api(
  //         "GET",
  //         `/ui-assist/v1/rollmatrix/manageintranet/menu`
  //       );
  //       if (res.status === 200) {
         
  //         setLoading(false);
  //         const menuItem = res?.data?.map((el) => {
  //           return {
  //             title: el,
  //             value: el,
  //           };
  //         });
         
  //         setMenuItemData(menuItem);
  //       }
  //     } catch (err) {
  //       setLoading(false);
  //       Swal.fire("Oops!", `${err.response}`, "error");
  //     }
  //   })();
  // }, []);

  // useEffect(() => {
  //  console.log(previosValues, 'previous values......')
  //  const defaultSubmenu = previosValues?.subMenu?.map(el => {
  //    return {
  //      title: el,
  //      value: el
  //     }
      
  //   })
  //   setDefaultSubMenuOption(defaultSubmenu)
  //   console.log(defaultSubMenuOption, 'default sub-menu')
  // }, []);
  

  useEffect(() => {
    (async () => {
      try {
        setLoading(true);
        const res = await services.api(
          "GET",
          `/ui-assist/v1/rollmatrix/manageintranet/usergroup`
        );
        if (res.status === 200) {
          setLoading(false);
          const userGruopmenuItem = res?.data?.map((el) => {
            return {
              title: el.groupName,
              value: el.usergroupId,
            };
          });
          setUserGruoupItemData(userGruopmenuItem);
        }
      } catch (err) {
        setLoading(false);
        Swal.fire("Oops!", `${err.response}`, "error");
      }
    })();
  }, []);
  
//load the default values.
// useEffect(() => {
//   (async () => {
//     let data = {
//       menu: previosValues.menu,
//       submenu: previosValues.subMenu
//     }
//     try {
//       setLoading(true);
//       const res = await services.api(
//         "POST",
//         `/ui-assist/v1/rollmatrix/manageintranet/edit`, data
//       );
//       if (res.status === 200) {
//         setLoading(false);
//         console.log(res, 'res@@')
//         const menuItem = res?.data?.menu?.map((el) => {
//           return {
//             title: el,
//             value: el,
//           };
//         });
       
      

//         const submenuItem = res?.data?.submenu?.map((el) => {
//           return {
//             title: el,
//             value: el,
//           };
//         });
//         console.log(submenuItem, 'submenuItem')
//         const firstMenu = res?.data?.menu[0]
//         setMenuItemData(menuItem);
//         
//         getSubMenuApi(firstMenu)
        
//         setSubMenuItemData(submenuItem)
//       }
//     } catch (err) {
//       setLoading(false);
//       Swal.fire("Oops!", `${err.response}`, "error");
//     }
//   })();
// }, [previosValues]);

console.log(defaultSubMenuOption, 'default sub-menu outside')
  const [focus, setFocus] = React.useState(false);
  const focusStyle = { color: "#db233a" };

  const handleMenuSelectChange = (e, selectType) => {
    getSubMenuApi(selectType.value);
    setSelectedMenu(selectType.value);
    setpageRefresh(!pageRefresh);
  };

  const handleDateRange = (e, selectType) => {
    if (selectType.value === "Always") {
      setDateType("Always");
      setCurrentDateType("Always")
      setSelectedStatus(selectType);
    } else if (selectType.value === "Date Range") {
      setDateType("Date Range");
      setSelectedStatus(selectType);
    } else if (selectType.value === "Based On Start Date") {
      setDateType("Based On Start Date");
      setSelectedStatus(selectType);
    } else {
      setSelectedStatus({});
      setDateType("");
    }
  };

  const getSubMenuApi = async (name) => {
    let obj = {
      menu: name,
    };
   

    setLoading(true);
    try {
      let res = await services.api(
        "POST",
        `/ui-assist/v1/rollmatrix/manageintranet/submenu`,
        obj
      );
      setLoading(false);
      //setSubMenuItemData(subMenuData.data);
      if (res.status === 200) {
        //  if(res.data.length > 0){
        //   const subMenuItem = res?.data?.map(el =>
        //     {
        //       return {
        //         title: el,
        //         value: el
        //       }
        //     }
        //     )
        //    setSubMenuItemData(subMenuItem);
        //   setDisableSubMenu(false)
        //  }else{
        //    console.log('coming in else')
        //   setSubMenuItemData('');
        //   //setDisableSubMenu(true)
        //  }

        const subMenuItem = res?.data?.map((el) => {
          return {
            title: el,
            value: el,
          };
        });
        setSubMenuItemData(subMenuItem);
        setDisableSubMenu(false);
      }
    } catch (err) {
      setLoading(false);
      Swal.fire("Oops!", `${err.response}`, "error");
    }
  };

    const handleTotalEmployeeApi = async () => {
      console.log('selected', selectedUserGroup)

  //setInnerLoader(true)
      try {
        const res = await services.api('POST',`/ui-assist/v1/rollmatrix/manageintranet/noofusers?usergroup=${selectedUserGroup}`)
        //setInnerLoader(false);
      } catch (error) {
        alert(error.message);
        //setInnerLoader(false)
      }
    }

  const handleAlwaysDate = () => {
    handleActiveFromDate('')
    handleActiveToDate('')
  }
  

  const handleUserGruopSelectChange = (e, selectType) => {
    //console.log(selectType, "selected value");

    const add = [];
    let gruopValue = selectType.map((el) => el.value);
   // console.log(gruopValue, "select type");
    //let convertedGroupValue = add.join(',').toString()

    const addName = [];
    let groupName = selectType.map((el) => el.title);
   // console.log(groupName, "select name");
    //let convertedGroupTitle = addName.join(',').toString()

    setSelectedUserGroup(gruopValue);
    setSelectedUserGroupName(groupName);
    handleTotalEmployeeApi();
  };

  const handleSubMenuSelectChannge = (e, selectType) => {
    setSelectedSubMenu(selectType.value);
  };

  // const handleActiveFromDate = (e, selectType) => {
  //   console.log('selectType!!', selectType)
  //  setActiveFrom(selectType)

  // }

  const handleActiveTo = (e, selectType) => {
    setActiveTo(selectType);
  };

  //date range data
  const dateRangeData = [
    {
      title: "Always",
      value: "Always",
    },
    {
      title: "Date Range",
      value: "Date Range",
    },
    {
      title: "Based On Start Date",
      value: "Based On Start Date",
    },
  ];

  //get no of useres.

//on Toggle options. 



  return (
    <div>
      {loading ? <Loader /> : null}
      <div className="leftRightAlign">
        <Grid container direction="row" alignItems="center" spacing={3}>
          {/*<Grid item sm={2} xs={12}>
            <div className="displayBlockUserDetails mlP alignMCAdmin">
              <p className="detailDivp">Created by</p>
              <p className="detailsFontSize">Garima Rai</p>
            </div>
  </Grid> */}
          {/*<Grid item sm={2} xs={12}>
            <div className="displayBlockUserDetails alignMCAdmin">
              <p className="detailDivp">Created on</p>
              <p className="detailsFontSize">12 Jan 2021 17:00</p>
            </div>
</Grid> */}
          <Grid item sm={8} xs={12}>
            <div className="uploadBtn">
              <div className="displayBlockUserDetails alignMCAdmin floatRightAlign">
                <p className="detailDivp">
                  USER COUNT:{" "}
                  {innerLoader ? (
                    <CircularProgress
                      style={{ height: "10px", width: "10px" }}
                    />
                  ) : (
                    employeeCount
                  )}{" "}
                </p>
                <p className="detailsFontSize floatRightAlign">-</p>
              </div>
            </div>
          </Grid>
        </Grid>
      </div>
      <div className="rollBasedAddAccess">
        <Paper className="rollBasedPaperAddNew">
          <div className="rollBasedPaperInnnerContent">
            <Grid container direction="row" alignItems="center" spacing={3}>
              <Grid item sm={3}>
                <span className="rollBasedPaperInnnerContentLabel">
                  1. Apply access to
                </span>
              </Grid>
              <Grid item sm={4} className="rollBasedAutoCompplete">
                <CommonAutocomplete
                disabled={true}
                  handleSelectChange={handleMenuSelectChange}
                  options={menuItemData}
                  selectedValue = {defaultmenuItemData}
                  label={<CommonLabel label="Menu" mandatory />}
                />
              </Grid>
              <Grid item sm={4} className="rollBasedAutoCompplete rBautoC2">
              {/*<CommonAutocomplete
                handleSelectChange={handleSubMenuSelectChannge}
                disabled={disableSubMenu}
                options={submenuItemData}
                label={<CommonLabel label="Sub-menu" mandatory />}
              /> */}
              <CustomMultiSelect
                      limitTags={10}
                      items={defaultSubmenuItemData}
                      getOptionLabel={getOptionLabel}
                      getOptionDisabled={getOptionDisabled}
                      selectedValues={SelectedSubMenuItemData}
                      placeholder="Search sub menu"
                      label={<CommonLabel label="Sub-menu" mandatory />}
                      selectAllLabel="Select all"
                      onToggleOption={(isSelected) => {
                        handleToggleOption(isSelected);
                      }}
                      onClearOptions={(isSelected) => {
                        handleClearOptions(isSelected);
                      }}
                      onSelectAll={(isSelected) => {
                        handleSelectAll(isSelected);
                      }}
                      disabled={true}
                    />
              </Grid>
            </Grid>
            <Grid
              container
              direction="row"
              alignItems="center"
              spacing={3}
              className="rollBasedGrid"
            >
              <Grid item sm={3}>
                <span className="rollBasedPaperInnnerContentLabel">
                  2. Assign a user group
                </span>
              </Grid>
              <Grid item sm={9} className="rollBasedAutoCompplete assignUGField">
                
                {/*<MultiSelect
                  options={userGruoupItemData}
                  label={<CommonLabel label="User Group" mandatory />}
                  name="user_group"
                  handleSelectChange={(e, value) => {
                    handleUserGruopSelectChange(e, value);
                  }}
                  
                />  */}

                <CustomMultiSelect
                      
                      items={userGruoupItemData}
                      getOptionLabel={getOptionLabel}
                      getOptionDisabled={getOptionDisabled}
                      selectedValues={selecteduserGroupData}
                      placeholder="Search usergruop"
                      label={<CommonLabel label="usergruop" mandatory />}
                      selectAllLabel="Select all"
                      onToggleOption={(isSelected) => {
                        handleToggleOption(isSelected, 'usergroup');
                      }}
                      onClearOptions={(isSelected) => {
                        handleClearOptions(isSelected);
                      }}
                      onSelectAll={(isSelected) => {
                        handleSelectAll(isSelected);
                      }}
                      
                    />
              </Grid>
            </Grid>
            <div className=""></div>
            {/*<div className="editPoliciesStructure roleBasedCreateUserG mb26">
              <img
                src={process.env.PUBLIC_URL + "/policies/add-fill.svg"}
                alt="Add"
              />
              <span
                onClick={() => {
                  setOpenModalNewUserGroup(true);
                }}
                className="editPolicies"
              >
                CREATE USER GROUP
              </span>
              </div> */}
            <Grid
              container
              direction="row"
              alignItems="center"
              spacing={3}
              className="rollBasedGrid"
            >
              <Grid item sm={3}>
                <span className="rollBasedPaperInnnerContentLabel">
                  3. Assign dates
                </span>
              </Grid>
              <Grid item sm={3} className="rollBasedAutoCompplete">
                <CommonAutocomplete
                  handleSelectChange={handleDateRange}
                  selectedValue={selectedStatus}
                  options={dateRangeData}
                  label={<CommonLabel label="Type" mandatory />}
                />
              </Grid>
              {dateType === "Date Range" && (
                <>
                  <Grid item md={3} xs={12} className="rollBasedAutoCompplete">
                   

                    <DatePicker
                     
                      selectedDate={new Date(activeFrom)}
                      handleDateChange={(date) => {
                        console.log(date, 'date....')
                        handleActiveFromDate(date)
                        }}
                        
                      
                      label={<CommonLabel label="Active From" mandatory />}
                     
                    />
                  </Grid>
                  <Grid item md={3} xs={12} className="rollBasedAutoCompplete">
                    <DatePicker
                      selectedDate={new Date(activeTo)}
                      handleDateChange={(date) => {
                        handleActiveToDate(date)
                        }}
                      label={<CommonLabel label="Active To" mandatory />}
                      minDate={new Date().setDate(new Date().getDate() + 1)}
                   /> 
                  </Grid>
                </>
              )}
              {dateType === "Always" && <>
             
                      {handleAlwaysDate()}  
                       
              </>}
              {dateType === "Based On Start Date" && (
                <Grid xs={12} sm={4} className="assignUGField">
                <DatePicker
                     
                selectedDate={new Date(activeFrom)}
                handleDateChange={(date) => {
                  handleActiveFromDate(date, 'Based On Start Date')
                  }}
                disableToolbarr={true}
                minDate={new Date().setDate(new Date().getDate())}
                label={<CommonLabel label="Active From" mandatory />}
               // disableToolbarr={true}
              />
                </Grid>
              )}
            </Grid>
          </div>
        </Paper>
        <CommonModal
          open={openModalNewUserGroup}
          handleClose={handleCloseModal}
          headerText="Create new usergroup"
          icon={addIcon}
          modalSize="md"
          footerBannerCss
          fullWidth
          policiesButtonSize
          cancleBtnText="Cancle"
          onCancleButtonClick={handleCloseModal}
          setSelectedUserGroup={setSelectedUserGroup}
        >
          <AddNewUserGroupComponent />
        </CommonModal>
      </div>
    </div>
  );
}


