import React, { useEffect, useState } from "react";
import { Grid, Paper, Tabs, Tab, Typography, Box } from "@material-ui/core";
import PropTypes from "prop-types";
import CommonModal from "../../Common/CommonModal/CommonModal";
import Template from "../../Common/Template/Template";
import "../MahindraCollective/MahindraCollective.css";
import "./CarLeasing.css";
import settingIcon from "../../../Assets/Images/settings_5_line.svg";
import Carpolicy from "../../../Assets/documents/Car_Lease_T&C.pdf"
import PageTutorialComponent from "../../Common/PageTutorial/PageTutorialComponent";

import Loader from "../../Common/Loader/Loader";
import SubmitButton from "../../Common/Button/SubmitButton";
import { useSelector, useDispatch } from "react-redux";
import { getUserBenefitsData } from "../../../Redux/Actions/AllEntitlementAction";
import { isAccesible } from "../../../Assets/helperFunctions/helperFunctions";
import PdfViewerComponent from "../../DashBoard/WhatsNew/PdfViewer/PdfViewerComponent";

import { gradeA7, gradeA8 } from "../../../Assets/data/EntitlementData";
import isAdmin from "../../isAdmin";
import services from "../../../Services";

let data = gradeA7;

// mobile responsive tabs
function TabPanel(props) {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}


  const downloadFile = () => {
    const pdfProxy=Carpolicy;
window.open(pdfProxy,'_blank');
    
  }

const CarLeasing = (props) => {
  const dispatch = useDispatch();

  const userBenefitsData = useSelector(
    (state) => state.getEntitlementReducer?.userBenefits
  );

  // const [openFilterModal, setOpenFilterModal] = useState(false);
  const [value, setValue] = React.useState(0);
  const [currentTabData, setCurrentTabData] = useState({});
  const [openPDFModal, setOpenPDFModal] = useState(false);
  // const [open, setOpen] = useState(false);
  const [openModal, setOpenModal] = useState(false);

  useEffect(() => {
    dispatch(getUserBenefitsData());
  }, []);

  useEffect(() => {
    if (userBenefitsData) {
      setValue(0);
      setCurrentTabData({ ...userBenefitsData?.entitlementCategoriesList[0] });
    }
  }, [userBenefitsData]);



  //handle submit
  // const handleClick = (status, e) => { console.log(status, e) }

  const handleClick = async (status) => {


    try {
      const res = await services.api(
        "POST",
        `/ui-assist/v1/cnb-termscondition/${window.location.pathname.split("/")[2]}/${status}`,
      );
      if (res) {
        if (res.status === 200) {
          window.open(res?.data, '_self')
        } else {
          console.log(res)
        }
      }
    } catch (err) {
      if (err.response) {
        console.log(err.response)
      }
    }
  };




  const onEdit = () => {
    setOpenModal(true);
  };
  // const onFilterMahindraCollective = () => {
  //   setOpenFilterModal(true);
  // };

  const handleCloseModal = () => {
    setOpenModal(false);
    // setOpenFilterModal(false);
  };
  // const [currentTab, setCurrentTab] = useState('');

  const handleChange = (event, newValue) => {
    setValue(newValue);
    setCurrentTabData({
      ...userBenefitsData?.entitlementCategoriesList[newValue],
    });
  };

  const openPDF = () => {
    setOpenPDFModal(true);
  };

  const handlePDFModal = () => {
    setOpenPDFModal(false);
  };

  console.log("check selected tab value =====> ", value);
  console.log("check selected tab data =====> ", currentTabData);
  console.log("user benefits =====> ", userBenefitsData);
  return (
    <>
      <Template pageName={"Entitlement"}>
        {/* {loading ? <Loader /> : null} */}
        <div>
          <Grid
            container
            direction="row"
            justifyContent="space-between"
            alignItems="flex-end"
          >
            <Grid item md={7} xs={12}>
              <div className="whatsNewHeader">
                <div className="containerTxt">
                  <div className="section-header-text">
                    {" "}
                    Terms and condition{" "}
                  </div>
                  
                </div>
              </div>
            </Grid>
            <Grid item md={5} xs={12} className="mb-3">
            <SubmitButton
                        label="T&C For Car Lease"
                        className="MCsubmitbutton "
                        onClick={downloadFile}
                      />
            </Grid>
          </Grid>
        </div>

        <Paper className="MClandingpageAlign mt-0 pt-4 mb-5 standardtabcontainer">
          <div className="responsiveTabs">
            <Box sx={{ width: "100%" }}>
              <Box
                sx={{ borderBottom: 1, borderColor: "divider" }}
                className="boxAlignPadMC standardlefttab"
              >
                <Tabs
                  value={value}
                  onChange={handleChange}
                  variant="scrollable"
                  scrollButtons={false}
                  aria-label="basic tabs example"
                  className="mahindraCollectiveTabsDM "
                >
                  <Tab label="Introduction" {...a11yProps(0)} />
                  <Tab label="Definition" {...a11yProps(1)} />
                  <Tab label="Scope" {...a11yProps(2)} />
                  <Tab label="Terms and Condition" {...a11yProps(3)} />
                  <Tab label="Insurance" {...a11yProps(4)} />
                  <Tab label="Lease Term" {...a11yProps(5)} />
                  <Tab label="Inspection, Maintenance and repairs" {...a11yProps(6)} />
                  <Tab label="RIGHTS AND OBLIGATIONS" {...a11yProps(7)} />
                  <Tab label="Foreclosure & Surrender Payment" {...a11yProps(8)} />
                  <Tab label="Vehicle Return/ Vehicle Purchase Clause" {...a11yProps(9)} />
                  <Tab label="EVENTS OF DEFAULT" {...a11yProps(10)} />
                  <Tab label="11	MISCELLANEOUS" {...a11yProps(11)} />
                </Tabs>
              </Box>

              <div className="alignMCCOntentTabPanel standardrighttab Econtent ">
                <TabPanel value={value} index={0}>
                  <Grid
                    container
                    direction="row"
                    justifyContent="flex-start"
                    alignItems="center"
                    spacing={2}
                    className="alignCards"
                  >
                    <>
                      <div className="scrollpanel">
                      <strong>TERMS AND CONDITIONS </strong>

<strong>THIS DOCUMENT IS AN ELECTRONIC RECORD IN TERMS OF THE INFORMATION TECHNOLOGY ACT, 2000 AND RULES MADE THEREUNDER. THIS ELECTRONIC RECORD IS GENERATED BY A COMPUTER SYSTEM AND DOES NOT REQUIRE ANY PHYSICAL OR DIGITAL SIGNATURES.</strong>

<p>These terms and conditions govern the request placed by the Employee for the Vehicle as per the Car Policy along with the vehicle allotment letter that shall be issued by Mahindra &amp; Mahindra Ltd to the Employee at the time of allotment of the Vehicle to the Employee (hereinafter referred to as the “Letter”) issued by Mahindra &amp; Mahindra Ltd.</p>

<strong>PLEASE CAREFULLY READ THESE TERMS AND CONDITIONS</strong>. BY ACCESSING AND APPLYING FOR A VEHICLE USING THIS WEBSITE, YOU INDICATE YOUR UNDERSTANDING, CONSENT AND ACCEPTANCE OF THE FOLLOWING TERMS AND CONDITIONS;

&nbsp;
<ol className="listtitleinside" >
 	<li>The Employee is currently employed with the Mahindra &amp; Mahindra Ltd (hereinafter referred to as the “Company” or “M&amp;M”).</li>
 	<li>The Employee is desirous of availing the benefit extended under the Company’s car policy for its employees (referred hereinafter as the “Car Policy” and attached herewith as Schedule- 1), whereby a vehicle of the Employee’s choice would be leased from a third party organization by the Company for a limited period of time for allowing the Employee to use the vehicle for official and personal purposes.</li>
 	<li>As per the Car Policy, the Employee is hereby submitting a request for the car model/ variant of the Employee’s choosing (hereinafter referred to as the “Vehicle”), on carandbike.com/leasing (“Website”) which the Company may as per its Car policy decide to approve or reject.</li>
 	<li>If the request by the Employee is approved, the Company shall procure on lease the Vehicle for a limited period and on the terms and conditions mentioned in Schedule- 2, for the Employee’s professional and personal use, possession of which shall be handed over to the Employee upon acceptance by the Employee of the terms of the Letter.</li>
</ol>
<strong>The Letter is issued upon the following terms and conditions;</strong>
   
                      </div>
                    </>
                  </Grid>
                </TabPanel>
                <TabPanel value={value} index={1}>
                  <Grid
                    container
                    direction="row"
                    justifyContent="flex-start"
                    alignItems="center"
                    spacing={2}
                    className="alignCards"
                  >
                    <>
                      <div className="scrollpanel">
                      <ol className="listtitle" start="1">
 	<li><strong>DEFINITIONS</strong></li>
</ol>
<p>In this Letter, unless the context or meaning thereof otherwise requires, the following capitalized terms wherever used shall have the meaning assigned to them in this clause:</p>
<ol className="listtitleinside" >
 	<li><strong>Letter</strong> – this vehicle allotment letter, along with all annexes, amendments, and supplements hereto.</li>
 	<li><strong>Vehicle</strong></li>
 	<li><strong>Break Fee</strong> - the fee payable by the Employee to the Company in case of termination of this Letter prior to the Minimum Holding Period, in the proportion as set out in Schedule 2. Unless otherwise specified in Schedule 2, no Break Fee shall be payable by the Employee under the Letter.</li>
 	<li><strong>Contracted Boundary</strong> - the geographical limits within which the Vehicle may be taken by the Employee or allowed to be taken by the Employee, and as more particularly recorded in Schedule 2.</li>
 	<li><strong>Designated Workshops</strong> - the auto workshops or any of them, designated by the Company or a third party indicated to the Employee by the Company for the repair and maintenance of the Vehicle, and communicated to the Employee from time to time. The Company reserves the right to modify the list of workshops from the list of such Designated Workshops without the prior written consent of the Employee.</li>
 	<li><strong>Driver's Manual</strong> - the manual issued by the Company and as well as amendments to such manual from time to time.</li>
 	<li><strong>Excess Kilometer Charge</strong> - the charges, which the Employee has to pay for using the Vehicle over and above the contracted kilometers, at the rate as mentioned in Schedule 2.</li>
 	<li>FRV (Fixed Residual Value)- the salvage value estimated on the value of the Vehicle at the beginning of the Tenure, as mentioned in Car Policy in Schedule 1.</li>
 	<li>MRV (Market Residual Value)- the fair market value of the Vehicle calculated through a discovery process at the end of the Tenure as mentioned in the Car Policy.</li>
 	<li><strong>Force Majeure</strong> - any event that is not within reasonable control of a Party (whose performance is affected by such event) including without limitation, insurrection, restraint imposed by the Government, act of legislative or other authority, war, hostilities, acts of the public enemy, civil commotion, sabotage, fires, floods, explosions, epidemics, quarantine restrictions, strikes, lockouts or acts of God, that affects the ability of a Party to perform its obligations as envisaged herein;</li>
 	<li><strong>Insurance Policy</strong> - the insurance policy obtained for the Vehicle from the Insurer pursuant to the terms of this Letter.</li>
 	<li><strong>Insurer</strong> – the insurance company which has insured the Vehicle.</li>
 	<li><strong>Tenure Commencement Date</strong> – the day on which the Vehicle is given for the use and possession of the Employee by the Company.</li>
 	<li><strong>Tenure End Date</strong> – the day of expiry of the Tenure.</li>
 	<li><strong>Vehicle Usage Charges </strong>– the periodic payment specified in Schedule 2 to be paid by the Employee for the right to use the Vehicle during the Tenure.</li>
 	<li><strong>Tenure</strong> – the term for which the Vehicle is given for the possession and use of the Employee by the Company and which begins on the Tenure Commencement Date and ends on the Tenure End Date.</li>
 	<li><strong>Manufacturer Manual</strong> - the manual(s) issued by the manufacturer/dealer of the Vehicle.</li>
 	<li><strong>Minimum Holding Period</strong> – the minimum period (as set out in Schedule 2) for which the Employee is required to continue possessing and using the Vehicle;</li>
 	<li><strong>Outstanding Vehicle Usage Charges –</strong> The Vehicle Usage Charges due but not paid by the Employee on due date.</li>
 	<li><strong>Book Value for Foreclosure- </strong>Book Value shall be the amount calculated as per the terms mentioned in the Car Policy.</li>
 	<li><strong>Parties </strong>– the Company and the Employee; <strong>Party</strong> – either the Company or the Employee individually.</li>
 	<li><strong>Pre</strong><strong>mature Termination Fee</strong>: The fee payable by the Employee to terminate the allotment granted under this Letter post Minimum Holding Period, as specified in Schedule 2.</li>
 	<li><strong>Reimbursements</strong>: A periodic or one-time reimbursement as detailed in the Car Policy, in the manner specified in Schedule 2, for such sums as have, at the Employee’s instance and for the convenience of the Employee, been paid by the Company, such however that the same were payable solely by the Employee, and shall accordingly be reimbursed by the Employee in the manner specified in Schedule 2;</li>
 	<li><strong>Services – </strong>One or more services, as detailed in the Car Policy/ Schedule 1 and opted for by the Employee in Schedule 2, which, being independent services provided by the third-party company from whom the Vehicle has been leased to various parties irrespective of whether such parties have availed of leasing services or not, have been opted to be availed by the Employee, at the Employee’s independent discretion, and shall not include any other services.</li>
 	<li><strong>Service Charges</strong> – the charges payable by the Employee, chargeable and invoiced separately by the third party leasing company, for any of the Services, as per rates specified in Schedule 2.</li>
 	<li><strong>Superdari Event</strong> - an event when an Vehicle is impounded by the government authorities under a third-party liability or otherwise and is released on a bond with a condition that it cannot be sold / transferred before settlement of the relevant case.</li>
 	<li><strong>Total Loss</strong> - total loss of the Vehicle as determined by the Insurer.</li>
</ol>
                      </div>
                    </>
                  </Grid>
                </TabPanel>
                <TabPanel value={value} index={2}>
                  <Grid
                    container
                    direction="row"
                    justifyContent="flex-start"
                    alignItems="center"
                    spacing={2}
                    className="alignCards"
                  >
                    <>
                      <div className="scrollpanel">
                      <ol className="listtitle" start="2">
 	<li><strong>SCOPE</strong></li>
  </ol>
  <ol className="listtitleinside" >
 	<li>In consideration of the Employee undertaking to perform the contractual obligations as set out herein, including, without limitation, timely payment of the Vehicle Usage Charges and other amounts payable hereunder, the Company shall, based on the request of the Employee, provide for the use of the Employee during the Tenure the Vehicle, the details of which have been mentioned in Schedule 2 hereto, for use of the Employee and shall also procure for the Employee through third-parties, certain services at the option of the Employee, as specified in Schedule 2  or any amendments thereof executed by the Employee..</li>
 	<li>Where the Employee has opted to avail of any of the Services specified in the Schedule 2 , the Employee shall pay Service Charges in respect thereof in addition to the Vehicle Usage Charges.</li>
 	<li>In addition, Employee shall pay the amounts specified in Schedule 2 by way of Reimbursement for the costs or outgoings incurred by the Company on behalf of and at the behest of the Employee.</li>
 	<li>The Vehicle Usage Charges, Service Charges and Reimbursements shall be included as amounts payable hereunder by the Employee, and without affecting their mutual independence, the failure to pay any of these at any time shall be deemed to be a failure going to the root of this contract.</li>
 	<li>The Vehicle which has been provided to the Employee is pursuant to the specific needs and requirements of the Employee and therefore the Employee will ensure that the Vehicle is put to use to meet the objective of this Letter and is used by the Employee only for the purposes indicated under this Letter to the Company.</li>
 	<li>The Schedules to this Letter shall form an integral part of this Letter and shall be read together with this Letter for all practical purposes.</li>
</ol>
                      </div>
                    </>
                  </Grid>
                </TabPanel>

                <TabPanel value={value} index={3}>
                  <Grid
                    container
                    direction="row"
                    justifyContent="flex-start"
                    alignItems="center"
                    spacing={2}
                    className="alignCards"
                  >
                    <>
                      <div className="scrollpanel">
                      <ol className="listtitle" start="3">
 	<li><strong>TERMS AND CONDITIONS</strong></li>
   </ol>
   <ol className="listtitleinside" >
 	<li>In consideration of allowing the use of the of the Vehicle by the Company in favour of the Employee and discharge of the obligations by the Company, the Employee hereby agrees to regularly and punctually pay without any abatements or deductions, the Vehicle Usage Charges, Service Charges and Reimbursements, for the entire Tenure, on the due dates, as more specifically mentioned in Schedule 2, from the date the possession of the Vehicle is handed over to the Employee, irrespective of whether the Vehicle has been put to use or not or is under repairs or maintenance or has been replaced for any period whatsoever.</li>
 	<li>The Company may revise the Vehicle Usage Charges only if such revision is mandated in accordance with any changes in the existing laws with a prior written notice of 15 days to the Employee.</li>
 	<li>The Company is entitled to appropriate the amounts paid by the Employee in the manner it deems fit.</li>
 	<li>In the event the Employee fails to make timely payments that are due to the Company under this Letter, the Employee shall be liable to pay in addition to the Vehicle Usage Charges payable, the late payment charges at the rate as specified in Schedule 2 or as may be agreed decided by the Company from time to time, on the amounts due and outstanding, for the period commencing on the date from when the amount becomes due and payable, till such time the amounts are actually paid by the Employee without prior notification or reminder. The Employee hereby authorizes the Company to deduct from the salary to be paid to the Employee any and all charges including the Vehicle Usage Charges, Service Charges or Reimbursements as may become due under this Letter.</li>
 	<li>In the event, in respect of any of the Vehicle, the actual kilometers covered by the Employee exceeds the contracted kilometers as specified in Schedule 2, the Employee shall pay, over and above the Vehicle Usage Charges, the Excess Kilometers Charge, at the rate as specified in Schedule 2. The terms and conditions governing the contracted kilometers is as mentioned in the Schedule 1.</li>
 	<li>The Employee shall not terminate this Letter prior to the expiry of the Minimum Holding Period. It is agreed by the Employee that in the event of termination of this Letter prior to end of the Minimum Holding Period, the Employee shall be obligated to pay the Break Fee if any, as specified in Schedule 2, by the Company.</li>
 	<li>Upon premature termination or expiration of the Tenure allotted under the Letter, the Employee agrees and undertakes to pay to the Company the pre-closure/foreclosure charges mentioned in the Car Policy attached herewith as Schedule , as per the Pre- closure Calculation set out therein.</li>
 	<li>Any damages, losses, costs or expenses cause to or incurred on the Vehicle due to the deterioration in condition of the Vehicle, in view of the inappropriate use of the Vehicle by the Employee other than normal wear and tear, shall be reimbursed to the Company. In all cases, Company’s endeavor will be to assess damage to the Vehicle in a fair and transparent manner.</li>
 	<li>The Employee shall ensure that the Vehicle Usage Charges or any other charges under this Letter is paid on the due dates specified in Schedule 2.</li>
 	<li>All payments shall be made by the Employee in INR.</li>
</ol>

                      </div>
                    </>
                  </Grid>
                </TabPanel>

                <TabPanel value={value} index={4}>
                  <Grid
                    container
                    direction="row"
                    justifyContent="flex-start"
                    alignItems="center"
                    spacing={2}
                    className="alignCards"
                  >
                    <>
                      <div className="scrollpanel">
                      <ol className="listtitle" start="4">
 	<li><strong>INSURANCE</strong></li>
  </ol>
  <ol className="listtitleinside" >
 	<li>For Vehicles manufactured by Mahindra &amp; Mahindra Ltd., the Company shall through third parties arrange for the insurance of the Vehicle and will also pay the insurance cost. Whereas for Vehicles not manufactured by Mahindra &amp; Mahindra Limited, the Company shall through third parties arrange for the insurance of the Vehicle and charge to the individual at actuals the cost of the insurance so taken. The amounts incurred towards the insurance for non- Mahindra &amp; Mahindra Ltd manufactured Vehicles shall be recovered from the Employee’s salary. Any other terms governing insurance under this Letter shall be as mentioned under Schedule 2.</li>
 	<li>In addition to the provision contained above, the Employee hereby agrees as follows:
<ol className="listtitleinside" >
 	<li>the Employee shall not do or omit to do or permit or suffer any act, which might or could prejudicially vitiate or affect the Insurance Policy(ies) obtained for the Vehicle;</li>
 	<li>that any risks and ensuing damage to the Vehicle, which are not fully covered by the Insurance Policy or risks which are not borne by the Company in terms of this Letter, shall be borne by the Employee. Accordingly, in case of any loss and/or damage to the Vehicle, the Employee shall pay to the Company the amount of loss and/ or damage not paid/deficit under the Insurance Policy by the Insurer and be liable for the deficit amount, if any, which shall be the difference between the claim amount paid by the Insurer and the Book Value of the Vehicle determined by the Company, and shall include the amounts and premium paid towards the Insurance Policy, registration and road tax.;</li>
 	<li>upon the occurrence of any event that causes any damage or loss to the Vehicle and/or its accessories/documentation, including without limitation, fire, explosion, self-ignition, lightening, burglary, housebreaking, riot, strike, earthquake, flood, typhoon, hurricane, storm, tempest, inundation, cyclone, hailstorm, frost, malicious act and/or terrorist activities, the Employee shall immediately notify the Company of such damage or loss relating to the Vehicle and communicate this in writing in accordance with the terms of this Letter, not later than 24 hours of the occurrence of such event causing loss and/or damage to the Vehicle and/or its accessories/documentation;</li>
 	<li>in the event of an accident or theft of the Vehicle or any part and/ or accessory thereof, the Employee shall: (a) immediately report the same to the nearest police station and obtain a copy of the first information report (FIR) and ensure that the registration, chassis and engine number are specified in the FIR; (b) report such event to the Company immediately and not later than 24 hours of the knowledge of the theft/ accident, furnish a copy of the FIR to the Company and (c) bear all costs and/or damages in doing so and arising out of late or incomplete reporting or failure to submit the aforesaid documents.</li>
</ol>
</li>
 	<li>The insurance claim amount disbursed by the Insurer shall be used to repair the Vehicle if the damage to the Vehicle is of a repairable nature or to pay the dues of the Employee to the Company, as per the terms of this Letter.</li>
</ol>

                      </div>
                    </>
                  </Grid>
                </TabPanel>
                <TabPanel value={value} index={5}>
                  <Grid
                    container
                    direction="row"
                    justifyContent="flex-start"
                    alignItems="center"
                    spacing={2}
                    className="alignCards"
                  >
                    <>
                      <div className="scrollpanel">
                      <ol className="listtitle" start="5">
 	<li><strong>LEASE TERM</strong></li>
</ol>

<ol className="listtitleinside" >
 	<li>The Tenure, as specified in Schedule 2, shall commence on the Tenure Commencement Date, and shall expire on the Tenure End Date.</li>
 	<li>This operation of this Letter may be terminated upon occurrence of any of the following events, whichever is later:
<ol className="listtitleinside" >
 	<li>after the Employee has completely fulfilled its obligations under the Letter.</li>
 	<li>occurrence of any Event of Default under Clause 12 of this Letter.</li>
 	<li>in case the Employee wishes to pre-close the Tenure mentioned under the Letter for the Vehicle or to surrender the Vehicle; or</li>
 	<li>If the Employee resigns from the Company.</li>
 	<li>upon mutual consent of the Parties.</li>
</ol>
</li>
 	<li>In the event, the Company determines with the mutual consent of the Employee, that the Vehicle has been damaged extensively and irretrievably and is technically no longer repairable, the Company may terminate the allotment Letter for a specific Vehicle.</li>
 	<li>Upon the premature termination or expiration of the allotment Letter, the Employee shall immediately and without any delay cease usage of the Vehicle and at its own cost and expense, return to the Company:
<ul>
 	<li>the Vehicle at the time and place indicated by the Company within the Contracted Boundary, in good order and condition, except for normal wear and tear. In cases where the Employee has not utilized the maintenance and repair services provided by the Company, the Employee will be solely liable to pay for the damage to the Vehicle. In case of failure by the Employee to return the Vehicle, the Company shall be entitled to take necessary steps to repossess the Vehicle and the Employee hereby unconditionally and irrevocably authorizes the Company or any other person authorised by the Company to take the physical possession of the Vehicle as aforesaid and the Employee shall be liable to reimburse to the Company all costs, expenses, charges etc. incurred by the Company in transporting the Vehicle to the intended location.</li>
 	<li>the relevant documents with respect to the Vehicle, including, but not limited to the registration certificate in original and the original Insurance Policy.</li>
 	<li>keys (original as well as duplicate) of the Vehicle and accessories fitted as original, in a form and manner as acceptable to the Company.</li>
</ul>
</li>
 	<li>The Employee undertakes, prior to the return of the Vehicle to remove all changes and improvements to the Vehicle made during the Tenure without damaging the Vehicle in any manner. If the above changes and improvements of the Vehicle cannot be separated from the Vehicle without damaging it or are not removed from the Vehicle upon return, they shall become the property of the Company and will not be compensated for.</li>
 	<li>The obligations of the Employee as well as those provisions that by their nature are intended to survive termination or expiration of this Letter shall so survive.</li>
</ol>

                      </div>
                    </>
                  </Grid>
                </TabPanel>
                <TabPanel value={value} index={6}>
                  <Grid
                    container
                    direction="row"
                    justifyContent="flex-start"
                    alignItems="center"
                    spacing={2}
                    className="alignCards"
                  >
                    <>
                      <div className="scrollpanel">
                      <ol className="listtitle" start="6">
 	<li><strong>INSPECTION, MAINTAINENACE AND REPAIRS</strong></li>
</ol>

<ol className="listtitleinside" >
 	<li>The Employee shall be fully and unconditionally liable for the preservation of the Vehicle, shall assume the risk for accidental destruction, failure and depreciation of the Vehicle from the time of possession till its actually returned to the Company.</li>
 	<li>In addition, the Employee shall maintain the technical condition of the Vehicle, perform repair of the Vehicle, check the Vehicle regularly and thoroughly, immediately replace or fix any damaged or lost parts of the Vehicle and shall be responsible for all costs, charges and expenses incurred on account of the foregoing. In case of a damage to the Vehicle, and there being a difference between the costs incurred on repair as against the claim amount received from the Insurer, the Employee shall be solely responsible for the differential amount.</li>
 	<li>The terms of the maintenance to be carried out under this Letter shall be as mentioned in the Schedule 1.</li>
 	<li>In addition, the Employee hereby undertakes and agrees as follows:
<ol className="listtitleinside" >
 	<li>that it shall present the Vehicle for pollution checks and other statutorily mandated technical/fitness tests, at the dates and places determined by the relevant authorities/ Company, at the Employee’s costs. Further, the Employee shall present the Vehicle as and when required by any competent authority for any inspection that may be required under any applicable law, regulation and / or rule.</li>
 	<li>that it shall provide such information and documents to the Company that may be required by the Company to ascertain the due performance by the Employee of its obligations under this Letter.</li>
 	<li>upon reasonable notice, as and when requested by the Company, to make the Vehicle available to the Company for inspection or technical check-ups.</li>
 	<li>to use the Vehicle in a proper manner for the normal purposes for which they were designed and equipped. The Employee hereby agrees and undertakes that the Vehicle shall not be used for speed trials, endurance tests and like events, for conducting driving lessons and for the conveyance of dangerous and/ or explosive materials.</li>
 	<li>to ensure compliance with all the applicable laws/rules and regulations in relation to the use and maintenance of the Vehicle, including but not limited to holding a valid driving license and, where necessary, undertake appropriate modifications/additions to the Vehicle at its own cost, with the prior written approval of the Company, to ensure such compliance;</li>
 	<li>to maintain the Vehicle in a good state in conformity with the Driver’s Manual and the Manufacturer Manual. Any liability arising out of non-conformity or failure by the Employee to adhere to the guidelines mentioned in the Driver’s Manual and/or the Manufacturer Manual shall be to the cost/account of the Employee.</li>
 	<li>take the Vehicle only to Designated Workshops for any repair and/or maintenance of the Vehicle.</li>
</ol>
</li>
 	<li>The Employee hereby agrees that it shall report to the Company of the defects in the kilometer indicator (odometer) not later than 24 hours from the time such defect has arisen. The kilometers driven up to the time of the repair of the defect shall be estimated and finalised by the Company.</li>
 	<li>The Company shall have the right to control and monitor the condition of the Vehicle directly or via third parties at their own cost.</li>
 	<li>The Employee understands and agrees that, the performance of the Vehicle including, without limitation, the mileage or fuel efficiency thereof is beyond the control of the Company. Accordingly, the Company shall not entertain or be responsible towards any claim with reference to the performance of the Vehicle or any other claim pertaining to failure of parts including the emergency functions of the Vehicle.</li>
 	<li>Without prejudice to the generality of the provisions given above:
<ol className="listtitleinside" >
 	<li>The Employee undertakes to facilitate the settlement of all cases of damage by providing the relevant information and documents and taking all the steps required if requested to do so. The Company shall retain the right to initiate an investigation in the event of any loss.</li>
 	<li>Under no circumstances shall the Company be held liable towards the Employee or a third party for any loss or damage that may be suffered by the Employee or a third party: (i) for any acts or omissions not attributable to the Company; and(ii) whether or not the same may be attributed to the Employee or user of the Vehicle. The Employee hereby agrees that all such claims will need to be settled by the Employee as per applicable laws and in the event the Company is forced to expend any amounts for such settlement, or penalties, allow the Company to deduct such sum from the salary payable to the Employee.</li>
</ol>
</li>
</ol>

                      </div>
                    </>
                  </Grid>
                </TabPanel>

                <TabPanel value={value} index={7}>
                  <Grid
                    container
                    direction="row"
                    justifyContent="flex-start"
                    alignItems="center"
                    spacing={2}
                    className="alignCards"
                  >
                    <>
                      <div className="scrollpanel">
                      <ol className="listtitle" start="7">
 	<li><strong>RIGHTS AND OBLIGATIONS</strong></li>
</ol>
<ol className="listtitleinside" >
 	<li>The Employee agrees that the Vehicle will be registered in the name of the Company and that the road tax and registration charges will be borne by the Employee i.e. deducted from the Employee’s salary by the Company. The Employee hereby agrees that the Employee shall not alter or prejudice the rights of the Company in any manner whatsoever.</li>
 	<li>Subject to the compliance with the conditions and obligations under this Letter, the Employee shall have the right to have peaceful possession and use of the Vehicle during the Tenure unless there is a premature termination of the allotment granted under this</li>
 	<li>The Employee, after receiving prior written consent by the Company, may make changes and improvements of the Vehicle indicated in Company's permission at its own expense.</li>
 	<li>The Employee understands that in accordance with the internal policies and procedures applicable , the Company is required to undertake periodic review of the performance of Employee in order to safeguard the Vehicle and/or any sum due under this Letter.</li>
 	<li>The Employee confirms that the Vehicle will be used within the Contracted Boundary only.</li>
 	<li>The Employee shall be liable for any violation of law in relation to the Vehicle and shall be fully liable and responsible for all the obligations, liabilities and duties pertaining to the use of the Vehicle during the Tenure.</li>
 	<li>It is hereby agreed by the Employee that, in the event the Vehicle is involved in a Superdari Event during the Lease Term, then the Employee shall be liable to pay the Company any and all losses, claims, damages incurred or suffered by the Company on account of the foregoing.</li>
 	<li>In case the Vehicle is impounded for any reason, then the Employee will be liable to get the Vehicle released from the relevant authorities. The Employee hereby agrees that in the event the Company is required to assist the Employee in the release of the Vehicle, all the costs and expenses incidental thereto will be borne by the Employee. If in the opinion of the Insurer, the Vehicle is technically no longer repairable, or if the costs of repair are otherwise disproportionate to the current Book Value, the Employee will have to compensate the Company for the loss of the Vehicle.</li>
 	<li>The Employee undertakes to do the following at its own expense:
<ol className="listtitleinside" >
 	<li>to refrain from disposing of the Vehicle, to use the Vehicle in a considerate, careful and prudent manner and in compliance with the purpose for which the Vehicle will be used and other requirements as set out herein, as well as to obtain all permits required for the use of the Vehicle and ensure that the Vehicle is used by legally authorised and legally permitted user/s only. Without the consent by the Company, the Employee shall be prohibited from using the Vehicle for carrying passengers, for any commercial purpose, taking part in sports competitions, trainings, or using the Vehicle in any other way which poses risk to the technical condition of the Vehicle or increases its normal depreciation rate;</li>
 	<li>take all measures not to allow third parties to restrict rights of the Employee and/or the Company with respect to the Vehicle; therefore, the Employee shall recover the Vehicle from unlawful extraneous possession and eliminate any violations of the rights of the Employee and/or the Company;</li>
 	<li>ensure that during the Tenure, the Vehicle is free from any kind of legal proceedings, penalties, levies, charges, fines or challans of any nature. For any claims made or actions taken in respect of the Vehicle, which restrict the sale and transfer of the Vehicle, the Employee shall take immediate action to safeguard the rightful property of Company. If the Employee loses possession or control of the Vehicle, the Employee shall inform the Company immediately and not later than 24 (Twenty Four) hours from such loss and if necessary, take appropriate measures to secure the possession of the Vehicle. The Company may take all actions it sees fit in the interests of protecting its rights, not excluding action in the name of the Employee. The costs incurred because of any action as aforesaid are to be borne by the Employee solely.</li>
 	<li>ensure that the Vehicle is only used by persons satisfying the requirements laid down under applicable law and regulations pertaining to such use.</li>
 	<li>allow Company's representatives to freely perform on-site inspection of Employee's activities and the Vehicle as well as allow access to the necessary documentation and store all documents in relation to the Vehicle (if such documents get misplaced or damaged, the Employee shall take immediate measures to ensure that the documents are recovered or obtain duplicates of such documents from the relevant parties);</li>
</ol>
</li>
 	<li>The Employee undertakes to inform the Company in writing within 3 (three) days if initiation of judicial or bankruptcy proceedings against it, as applicable.</li>
 	<li>The Employee hereby agrees, undertakes and covenants that;
<ol className="listtitleinside" >
 	<li>The Employee has read and understood all the contents, terms and conditions contained in Car Policy and agrees to adhere to the terms thereof and amendments, if any, thereto.</li>
 	<li>The Employee understands that the Vehicle is registered in the name of the Company and that the Employee has only been granted the right to use the Vehicle for his/her official and personal use and that no other right is being transferred and/or allowed to the Employee over the Vehicle.</li>
 	<li>The said Vehicle will be exclusively used by the Employee and that the Employee shall not handover the same to any other person. In the event, the Employee decides to keep a driver for the Vehicle, it shall be the Employee’s sole responsibility to ensure that the driver complies with all applicable laws and rules and holds a valid driving license.</li>
 	<li>The Employee accepts any and all risks associated with the use of the Vehicle, over which the Employee shall have possession and shall be liable in accordance with the provisions under the Motor Vehicles, Act 1988 (“the Act”). The Employee shall be fully liable and responsible for all the obligations, liabilities and duties as provided under the Act or under any other law or instrument pertaining to the use and operation of the Vehicle. If any penalty, interest or fine on the Vehicle is levied, arising out of acts, negligence or ignorance on the Employee’s part/or on part of the Employee’s driver, while using the Vehicle, the same shall be borne by the Employee directly. In the event, any cost is incurred by M&amp;M on account for the Employee or his/her driver’s act or omission, as stated above, the same will be deducted from the Employee’s salary and the Employee hereby authorizes M&amp;M for the same.</li>
 	<li>The Employee shall not use the Vehicle for any illegal activity or for any other purpose which may cause damage to Vehicle, at any time. The Employee shall continue to remain liable for any such act(s) committed while in possession of the Vehicle even after handing over the Vehicle back to M&amp;M.</li>
 	<li>In case of any accident, theft, total loss and/or any damage or loss to the Vehicle, The Employee shall take appropriate steps as per the Car Policy and pay the amount as shall be determined and charged by M&amp;M from the Employee’s salary.</li>
 	<li>The Employee will ensure the safekeeping of the Vehicle documentation that has to be produced on demand by the police or representatives of the tax authorities. If these documents are lost or stolen, the Employee shall bear the cost of obtaining duplicates.</li>
 	<li>The Employee shall ensure that the Vehicle remains insured at all times during the continuation of the benefit extended to the Employee under the Car Policy and any costs/risks arising out of non-renewal of insurance or violation of the terms of the insurance which shall render the insurance void shall be to the Employee’s account which the Employee will be liable to pay for and the Employee will be responsible and liable for to keep M&amp;M safe and harmless M&amp;M against all such liabilities.</li>
 	<li>The Employee will ensure that all steps towards the safety and security of the Vehicle are duly undertaken. The Employee will ensure that the Vehicle is used under normal conditions, maintained in good working order making basic checks such as engine oil levels and ensure that the Vehicle undergoes services recommended by the manufacturer and any necessary repairs carried out including tyres and batteries according to the handbook published by the manufacturer. All risks and damages in view of the repairs done by non- approved service provider shall be to the Employee’s account.</li>
 	<li>The Employee will not, without prior written approval from M&amp;M, take or leave the Vehicle outside the Contracted Boundary as mentioned under Schedule 2.</li>
 	<li>The Employee hereby authorizes M&amp;M to recover all such amounts (as would be payable by the Employee under the terms of this Letter from the amount of full and final settlement due to the Employee upon the Employee’s resignation or separation from the Company. In case of any shortfall, the Employee shall pay the shortfall amount to M&amp;M or any other party as per M&amp;M’s directions / requirements.</li>
 	<li>The right of M&amp;M to make the necessary recovery is without prejudice to its right to effect the reimbursement through other legal means, should the recovery amount prove insufficient to settle dues for the Vehicle.</li>
 	<li>The Employee will abide by the Car Policy and adhere to all the terms and conditions of this Letter strictly without any dispute.</li>
</ol>
</li>
 	<li>The Employee hereby agrees, undertakes, and covenants that the Employee shall:
<ol className="listtitleinside" >
 	<li>not claim any right, title, or interest in the Vehicle and/or parts or components thereof other than that of the Employee or contest the Company’s sole and exclusive ownership thereof.</li>
 	<li>not do or omit to do, cause to be done any act or thing by which the warranties given by the manufacturer would be invalidated or become unenforceable, wholly or partly.</li>
 	<li>not transfer, assign or otherwise dispose of or purport to transfer, assign or dispose of the Company’s rights or obligations or interest hereunder by way of mortgage, charge, sublease, sale or assignment, hypothecation, pledge, hire, encumbrance, license or otherwise in any manner part with the possession of the Vehicle or any part thereof or allow or purport to do or allow or create any lien, charge, attachment or other claim of whatsoever nature on the Vehicle or any part thereof;</li>
 	<li>be responsible for and liable, at all times, to pay the Company for any notices, claims, loss, demands, action, suits or proceedings, costs, fees, taxes and expenses (including reasonable attorney’s fees), judgments incurred, suffered or sustained by the Company as a result of or arising from or relating to, any third-party claims in relation to (a) the Vehicle or (b) a breach of the terms of this Letter or (c) death or injury caused to a third party by the Employee, the driver of the Vehicle or any other person who is found to have driving the Vehicle (d) damage to any property caused by the Employee, the driver of the Vehicle or any other person who is found to have driving the Vehicle(e) any illegal activities which may have been conducted with the Vehicle (f) any modification to the Vehicle in contravention of the Motor Vehicles Act,1988 and its subsequent amendments and the Rules framed thereunder (g) any violation of the Motor Vehicles Act,1988 and its subsequent amendments and the Rules framed thereunder while driving the Vehicle or while causing the Vehicle to be driven.</li>
 	<li>be liable to pay all losses and/or damages that Company may incur as a consequence of non-compliance by the Employee with the provisions of this Letter.</li>
 	<li>sign, execute and deliver all such documents as may be reasonably requested by the Company, in relation to the Vehicle, including such forms, affidavits, powers of attorney etc., as may be required to be filed with the transport authorities or the insurance companies;</li>
 	<li>authorise the Company to fill in, alter, amend, sign or complete such forms, documents or papers relating to the regional transport office or the insurance companies and to give full and complete effect to the Company’s obligations under this Letter.</li>
</ol>
</li>
 	<li>Limitation of Liability of the Company:
<ol className="listtitleinside" >
 	<li>The Company shall in no way be liable or responsible to the Employee or any other person for any liability, claim, loss, damage, or expense of any kind or nature whatsoever arising from the use of the Vehicle by the Employee or any other person, during the Tenure;</li>
 	<li>In particular, the Company shall in no way be liable or responsible to the Employee or any other person for any liability, claim, loss, or damage arising from the use of Vehicle for any illegal activities, irrespective of whether the Employee was in actual possession of the Vehicle or not; and</li>
</ol>
</li>
</ol>

                      </div>
                    </>
                  </Grid>
                </TabPanel>
                <TabPanel value={value} index={8}>
                  <Grid
                    container
                    direction="row"
                    justifyContent="flex-start"
                    alignItems="center"
                    spacing={2}
                    className="alignCards"
                  >
                    <>
                      <div className="scrollpanel">
                      <ol className="listtitle" start="8">
 	<li><strong>Foreclosure &amp; Surrender Payment.</strong></li>
</ol>
<p>The Employee shall be subject to the compliance with the requirements as set out below in case of a foreclosure: -</p>
<ol className="listtitleinside" >
 	<li>In case of foreclosure where the Employee decides to purchase the Vehicle the Pre-closure calculation mentioned in Schedule 1 shall apply</li>
 	<li>In case of a foreclosure where the Employee decides not to purchase the Vehicle the Surrender Calculation as mentioned in Schedule 1 shall apply.</li>
</ol>

                      </div>
                    </>
                  </Grid>
                </TabPanel>
                <TabPanel value={value} index={9}>
                  <Grid
                    container
                    direction="row"
                    justifyContent="flex-start"
                    alignItems="center"
                    spacing={2}
                    className="alignCards"
                  >
                    <>
                      <div className="scrollpanel">
                      <ol className="listtitle" start="9">
 	<li><strong>Vehicle Return/ Vehicle Purchase Clause</strong></li>
</ol>
<p>Upon expiration of the Tenure or early termination of the allotment granted under this Letter, and in case where the Employee does not wish to purchase the Vehicle,  the Employee shall deliver to the Company, at his own cost and expenses, and forthwith surrender to the Company :</p>
<ol className="listtitleinside" >
 	<li>The Vehicle at the location specified by the Company at such time as may be directed by the Company in good order and condition.</li>
 	<li>Manufacturer Manual, Warranties and other documents furnished by the Manufacturer in respect of the Vehicle, all service logs and service reports as well as all written material that the Employee may have been provided by the Company for the operation and maintenance of the Vehicle.</li>
 	<li>Registration certificate in original and the original Insurance Policy.</li>
 	<li>Keys (original as well as duplicate/s) and accessories of the Vehicle.</li>
</ol>
<p>In the event the Employee surrenders the Vehicle with an unreasonable or unacceptable level of wear and tear as determined by the Manufacturer Authorised Service Centre (reasonable wear and tear accepted), as per the guidelines mentioned in Schedule 2. the Employee shall be liable to bear all costs and charges for refurbishing/repairing the Vehicle depending on the condition of the Vehicle at the time of its surrender.</p>

<p>If the Employee shall duly observe and performs all the conditions herein contained and on its part to be observed and performed and shall pay to the Company the Vehicle Usage Charges  specified in Schedule 2 hereof, together with all other sums if any payable by the Employee to the Company under the provisions of this Letter, then at the end of the Tenure or upon fore-closure as allowed under this Letter, Employee shall have the option of purchasing the said Vehicle by paying in one lump sum the charges as mentioned under this Letter. Until all such payments as aforesaid have been made, the said Vehicle shall remain the property of the Company.</p>

                      </div>
                    </>
                  </Grid>
                </TabPanel>
                <TabPanel value={value} index={10}>
                  <Grid
                    container
                    direction="row"
                    justifyContent="flex-start"
                    alignItems="center"
                    spacing={2}
                    className="alignCards"
                  >
                    <>
                      <div className="scrollpanel">
                      <ol className="listtitle" start="10">
 	<li><strong>EVENTS OF DEFAULT</strong></li>
</ol>
&nbsp;
<ol className="listtitle" start="10">
 	<li>An event of default (“<strong>Event of Default</strong>”) shall occur in respect of the Employee hereunder if, the Employee:
<ol className="listtitleinside" >
 	<li>fails to make any of the payments or part thereof required to be made hereunder when due and such failure continues for a continuous period of 30 days from the due date: or</li>
 	<li>fails to perform or observe any other covenant, conditions, or Letter to be performed or observed by it hereunder or in any other document furnished to the Company in connection herewith; or</li>
 	<li>without the Company's consent transfers, assigns or otherwise disposes of or purports to transfer, assign or dispose of the Company's rights or obligations or interest hereunder by way of mortgage, charge, sublease, sale or other assignment, hypothecation, pledge, hire, encumbrance, license or otherwise in any manner part with the possession of the Vehicle or any part thereof or allow or purport to do or allow or create any lien, charge, attachment or other claim of whatsoever nature on the Vehicle or any part thereof;</li>
 	<li>is bankrupt or makes an assignment for the benefit of creditors, or consents to the appointment of a trustee or receiver; or a trustee or receiver is appointed in relation to a substantial part of the Employee’s property;</li>
 	<li>fails to maintain the Vehicle in terms of this Letter and/or as specified in the Driver’s Manual or Manufacturer Manual.</li>
 	<li>abandons the Vehicle or makes any major additions/alterations to the Vehicle, without the prior written consent of the Company, which affects the marketability of the Vehicle;</li>
 	<li>is in breach of any representation or warranty as mentioned herein or they are found to be or become incorrect.</li>
</ol>
</li>
 	<li><strong>Consequences of default</strong>-Upon the occurrence of an Event of Default, the Company shall be entitled to: (a) terminate the Letter with immediate effect, and (b) without any prior notice or consent , enter upon the premises where the Vehicle are kept and take possession of the same (c) demand from the Employee, the dues arising out of this Letter, which the Employee shall be liable to pay (d) and deduct from the Employee’s salary any amounts due to be paid by the Employee to the Company under the terms of this Letter.</li>
</ol>

                      </div>
                    </>
                  </Grid>
                </TabPanel>
                <TabPanel value={value} index={11}>
                  <Grid
                    container
                    direction="row"
                    justifyContent="flex-start"
                    alignItems="center"
                    spacing={2}
                    className="alignCards"
                  >
                    <>
                      <div className="scrollpanel">
                      <ol className="listtitle" start="11">
 	<li><strong>MISCELLANEOUS</strong></li>
</ol>

<ol className="listtitleinside" >
 	<li>This Letter shall be governed by the laws of India. The Employee agrees that in the event of any dispute or disagreement between the Parties hereto under or with respect to the interpretation of any provision of this Letter or its Schedules, the Company’s decision with respect to such dispute or disagreement will be final and binding on the Employee and the Employee waives any and all rights that the Employee may have to challenge such decision of the Company.</li>
 	<li>No failure on the part of the Company to exercise, and no delay in exercising any right or remedy herein, shall operate as a waiver thereof, nor shall any single or partial exercise by the Company of any right or remedy hereunder preclude any other or further exercise thereof, or the exercise of any other right or remedy. Waiver by the Company of any default by the Employee shall not be deemed a waiver of any other default.</li>
 	<li>Unless required for the performance of its obligations under this Letter or under applicable law, either Party shall keep strictly confidential and shall not disclose to third parties without the consent of the other Party, the terms of this Letter, all communication, information, correspondence, documents and other material provided by either Party or obtained during the term of this Letter. This clause shall survive the termination or expiry of this Letter.</li>
 	<li>The Employee shall bear the stamp duty, if any, payable on this Letter, and its duplicate and all Letters, deeds, writings and documents executed by and between the Parties hereto in respect of the Vehicle. Such stamp duty if borne by the Company, will be deducted from the salary payable to the Employee.</li>
 	<li>Only the Company shall be entitled to assign (in whole or in part) this Letter or any of its rights or obligations under the Letter, without the prior written consent of the Employee. The Employee will not be entitled to assign (in whole or in part) this Letter or any of its rights or obligations under this Letter, without the prior written consent of the Company.</li>
 	<li>This Letter may only be amended with the Company’s prior written consent.</li>
 	<li>If either Party is unable to perform its obligations under this Letter due to the occurrence of a Force Majeure event, that Party will not be deemed to have defaulted under this Letter. Each Party agrees to use all reasonable efforts to enable performance under this Letter to continue. If the period of non-performance due to a Force Majeure event exceeds 30 days, the affected Party may, by giving written notice, terminate this Letter. Upon termination of the allotment granted under this Letter, the Employee must settle all the payments and comply with all the obligations as set out under this Letter.</li>
 	<li>The terms and provisions of this Letter that by their nature and content are intended to survive the performance hereof by any or all Parties hereto shall so survive the completion and termination of this Letter.</li>
</ol>


<strong>SCHEDULE 1 (CAR POLICY)</strong>

<strong> </strong>
<table width="671">
<tbody>
<tr>
<td width="116"><strong>Eligibility</strong></td>
<td width="555">§  All officers in grades L5M &amp; above.</td>
</tr>
<tr>
<td width="116"><strong>Leasing Tie-up</strong></td>
<td width="555">M&amp;M Ltd. will have an agreement with Mahindra &amp; Mahindra Financial Services Limited (the leasing company) for providing Vehicles to its eligible officers.

</td>
</tr>
<tr>
<td width="116"><strong>Tenure Options</strong></td>
<td width="555">The policy provides the following two options for structuring of the car lease scheme:


<table width="509">
<tbody>
<tr>
<td width="111"><strong>Options</strong></td>
<td width="231"><strong>Residual Value</strong></td>
<td width="167"><strong>On Tenure Completion</strong></td>
</tr>
<tr>
<td width="111"><strong>Fixed Residual Value (FRV)</strong></td>
<td width="231"><p>35% - 3 years</p>

<p>20% - 4 years / 5 years</p>

<p>The above % are on vehicle ex-showroom prices excluding accessories</p></td>
<td width="167">Option to purchase at pre-determined residual value</td>
</tr>
<tr>
<td width="111"><strong>Market Residual Value (MRV)</strong></td>
<td width="231">Flexible RV based on tenure</td>
<td width="167">Option to purchase at market residual value</td>
</tr>
</tbody>
</table>


<p>In both the above options, the employee has a choice to return the vehicle at the end of the Tenure.</p>



<p>In case of MRV, an indicative RV value will be disclosed to the employee at the time of vehicle selection. However exact MRV for purchase of vehicle at the end of lease tenure will be ascertained using price discovery process, wherein, lessor will invite three quotes from the market and MRV will be decided based on higher of the three quotes.  The MRV scheme shall be available for a Tenure of 3 years, 4 years and 5 years</p></td>
</tr>
<tr>
<td width="116"><strong>Vehicle Value Limit</strong></td>
<td width="555">&nbsp;
<table width="528">
<tbody>
<tr>
<td width="207"><strong>Grade</strong></td>
<td width="320"><strong>Vehicle Value Limit**</strong></td>
</tr>
<tr>
<td width="207">President &amp; above</td>
<td width="320">₹ 65 Lacs</td>
</tr>
<tr>
<td width="207">L1S</td>
<td width="320">₹ 50 Lacs</td>
</tr>
<tr>
<td width="207">L2S</td>
<td width="320">₹ 40 Lacs</td>
</tr>
<tr>
<td width="207">L2EX, L3EX &amp; L3DH</td>
<td width="320">₹ 30 Lacs

<em>For Mahindra vehicle this limit will be relaxed.</em></td>
</tr>
<tr>
<td width="207">L4DH to L5M</td>
<td width="320">₹ 15 Lacs

<em>For Mahindra vehicle this limit will be relaxed.</em></td>
</tr>
</tbody>
</table>
<em>** ex-showroom price with GST and excluding RTO &amp; Insurance</em>


<p>§  For Vehicle Usage Charges calculation, the vehicle value would comprise of invoice value, registration charges, RTO Charges, Taxes (where applicable), incidental expenses and any other related expenses.</p>

<p>§  Accessories fitted at the time of buying the vehicle will be added to arrive at the vehicle value. Cost of Accessories, however, cannot exceed 10% of the ex-showroom price of the vehicle without accessories.</p>

<p>§  Basis the vehicle value, the lease option (FRV or MRV) and rate of interest, Vehicle Usage Charges will be calculated and applicable from the date of registration of the vehicle.</p></td>
</tr>
<tr>
<td width="116"><strong>Fixed Residual Value (FRV) Scheme: Fuel &amp; Maintenance</strong></td>
<td width="555">

<strong>Mahindra Vehicle for L3DH &amp; Above</strong><strong>:</strong>

<p>§  The leasing company will maintain the vehicle which will include scheduled and unscheduled maintenance.</p>

<p>§  The entire maintenance lifecycle including booking for the service, pick-up &amp; drop of the vehicle, road-side assistance, processing of the maintenance bills etc. will be managed by the leasing company</p>

<p>§  A loaner vehicle may be provided in case of scheduled maintenance or vehicle breakdown.</p>

<p>§  Fuel card or reimbursement of fuel expenses will be provided by M&amp;M / leasing company.</p>

<p>§  All the above cost will be paid by M&amp;M and a fixed amount will be debited from flexi-pay for fuel, maintenance, tolls, and other expenses as per the limits specified below:</p>


<table>
<tbody>
<tr>
<td width="201"><strong>Vehicle Value**</strong></td>
<td width="251"><strong>Fixed Deduction in Flexi - Pay (p.a.)</strong></td>
</tr>
<tr>
<td width="201">Greater than 15 Lacs</td>
<td width="251">₹ 1.5 Lacs</td>
</tr>
<tr>
<td width="201">Lesser than 15 Lacs</td>
<td width="251">₹ 1.2 Lacs</td>
</tr>
<tr>
<td width="201">For Electric Vehicles</td>
<td width="251">₹ 0.40 Lacs

<p>(Includes repair &amp; maintenance, electricity charges, aux battery, main battery refurbishment)</p></td>
</tr>
</tbody>
</table>
<em>** ex-showroom price with GST and excluding RTO &amp; Insurance</em>

&nbsp;

<strong>Mahindra Vehicle for L4DH to L5M</strong><strong> OR Non-Mahindra Vehicle for all levels:</strong>

<p>§  The employee will maintain the vehicle (self-maintained) which includes the expenses towards fuel, repairs &amp; maintenance, toll and other expenses.</p>

<p>§  An amount can be blocked by the individual in flexi-pay towards fuel, maintenance, tolls, and other expenses as per the limits specified below:</p>

&nbsp;
<table>
<tbody>
<tr>
<td width="226"><strong>Vehicle Value**</strong></td>
<td width="226"><strong>Flexi-pay (maximum block amount) (p.a.)</strong></td>
</tr>
<tr>
<td width="226">Greater than 15 Lacs</td>
<td width="226">₹ 1.5 Lacs</td>
</tr>
<tr>
<td width="226">Lesser than 15 Lacs</td>
<td width="226">₹ 1.2 Lacs</td>
</tr>
<tr>
<td width="226">For electric vehicles</td>
<td width="226">₹ 0.40 Lacs

<p>(Includes repair &amp; maintenance electricity charges, aux battery, main battery refurbishment)</p></td>
</tr>
</tbody>
</table>
<em>** ex-showroom with GST and excluding RTO &amp; Insurance</em>

<em> </em></td>
</tr>
<tr>
<td width="116"><strong>Market Residual Value (MRV) scheme: Fuel &amp; Maintenance</strong></td>
<td width="555"><strong> </strong>

<strong>Mahindra Vehicle for L3DH &amp; Above</strong><strong>:</strong>

<p>Same as Fixed residual Value (FRV) case for L3DH &amp; Above</p>

<strong> </strong>

<strong>Mahindra Vehicle for L4DH to L5M</strong><strong>, OR, Non-Mahindra Vehicle for all levels:</strong>

<p>§  The vehicle will be maintained through Fleet Management Service (FMS) provided by the leasing company.  Details of the services covered and the cost which will be charged to the employee via monthly FMS fee will be quoted separately from the normal lease rental, at the time of finalizing the leasing contract.</p>

<p>§  An amount can be blocked by individual in flexi-pay towards fuel, maintenance/FMS, tolls, and other expenses as per the limits specified below:</p>

&nbsp;
<table>
<tbody>
<tr>
<td width="225"><strong>Vehicle Value**</strong></td>
<td width="225"><strong>Flexi-pay (maximum block amount) (p.a.)</strong></td>
</tr>
<tr>
<td width="225">Greater than 15 Lacs</td>
<td width="225">₹ 1.5 Lacs</td>
</tr>
<tr>
<td width="225">Lesser than 15 Lacs</td>
<td width="225">₹ 1.2 Lacs</td>
</tr>
<tr>
<td width="225">For electric vehicles</td>
<td width="225">₹ 0.40 Lacs

(Includes repair &amp; maintenance, electricity charges, aux battery, main battery refurbishment)</td>
</tr>
</tbody>
</table>
<em>** ex-showroom price with GST and excluding RTO &amp; Insurance</em>

<strong> </strong></td>
</tr>
<tr>
<td width="116"><strong>Fleet Management Services (FMS) Fees</strong></td>
<td width="555"><p>The Fleet Management Services (FMS) fees will be charged separately and will be to the tune of the amount mentioned in Schedule 2 of the Letter along with the Vehicle Usage Charges. It will be recovered from the employee as part of monthly deduction from salary.</p>


<p>Additionally, for managing maintenance, an amount equivalent to 10% on overall maintenance actual cost shall be charged and included in the Fleet Management fee (FMS Fees) and will be payable by the Employee.</p>



<p>The FMS fees will be applicable for MRV vehicles for L4 &amp; l5 levels (Mahindra vehicles) and all levels for non-Mahindra vehicles or in cases where maintenance has been opted with leasing company.</p></td>
</tr>
<tr>
<td width="116"><strong>Mileage</strong></td>
<td width="555">§  No mileage limit for Fixed RV case

§  20,000 km per year for Market RV. <em>(Extra per km charges are applicable beyond prescribed limit of Contracted Kilometers)</em>

&nbsp;

In case of MRV additional cost per KM (exclusive of GST) will be:

 Ex-showroom cost of Vehicle upto 10 lacs : Rs 3/- per KM

 Ex-showroom cost of Vehicle between 11- 30 Lacs : Rs 5/- per KM

 Ex-showroom cost of Vehicle greater than 30 Lacs : Rs 6/- per KM

&nbsp;

GST will be chargeable extra on the aforementioned additional charges</td>
</tr>
<tr>
<td width="116"><strong>Second Vehicle</strong></td>
<td width="555">§  L2 S &amp; above are entitled for 2<sup>nd</sup> vehicle subject to provisions of Income Tax.

§  Out of the two vehicles, one should be Mahindra vehicle.

&nbsp;</td>
</tr>
<tr>
<td width="116"><strong>Insurance </strong></td>
<td width="555"><strong>Mahindra Vehicle:</strong>

§  The insurance cost will be paid by the company.

§  In case of any major accident of the vehicles, the company will bear the difference between the repair cost and amount received from the insurance company. The Company reserves the right of referring the repair bill to a dealer/mechanic of its choice for cross verification / estimation. The Employee concerned will have to lodge a FIR, a copy of which will need to be given to the Insurance Department. The reimbursement claim will be approved by concerned CIS/Administration Department.

§  If an employee loses his/her Company provided Vehicle due to fire or theft, the company will bear the difference between the repair cost and amount received from the insurance company.

<strong> </strong>

<strong>Non-Mahindra Vehicle:</strong>

§  The insurance cost will be paid for by the Company, charged to the individual at actuals and offset against allocation in flexi-pay.

§  In case of any major accident / fire, theft, the individual will bear the difference between the repair cost and amount received from the insurance company. The leasing company and M&amp;M reserve the right of referring the repair bill to a dealer / mechanic of its choice for cross verification / estimation. The Employee concerned will have to lodge a FIR, a copy of which will need to be provided.

§  Any difference in the insurance amount and repair cost to be borne by the Employee. In case the Vehicle is declared to be total damage or stolen, it will be considered as a surrendered vehicle. The surrender calculation for such cases would be (Book Value + 2% BV + residual instalment of road tax &amp; Registration + any arrears of Vehicle Usage Charges, charges etc – Insurance claim amount). Vehicle Usage Charges will be payable till the insurance claim amount is not received by the Company.

&nbsp;

&nbsp;

Fort MRV vehicles, comprehensive insurance with zero depreciation charges will be provided.

&nbsp;

For FRV vehicles

costing less than 30 Lakhs: comprehensive insurance coverage

costing equal to or greater than 30 Lakhs: comprehensive insurance coverage with zero deprecation

&nbsp;</td>
</tr>
<tr>
<td width="116"><strong>Vehicle Usage Charges</strong></td>
<td width="555">Payable monthly in arrears. The Vehicle Usage Charges for each tranche shall be Fixed for the entire Tenure. Any change in the Vehicle Usage Charges will be informed in advance. Taxes such GST or any other levies on the Vehicle Usage Charges will be to Employee’s account.</td>
</tr>
<tr>
<td width="116"><strong>Application Process</strong></td>
<td width="555">§  An individual who is eligible under the scheme will have to apply through the Employee Car Lease portal on mecentral.

§  The application will be verified and processed by M&amp;M and will be forwarded to the leasing company.

&nbsp;</td>
</tr>
<tr>
<td width="116"><strong>Road Tax and Registration</strong></td>
<td width="555">§  Vehicle will be registered in the name of Mahindra &amp; Mahindra Limited. Road tax and registration charges will depend on the city of registration. Mahindra &amp; Mahindra Ltd will pay such road tax and registration charges at the time of registration of the Vehicle and will then deduct on a monthly basis such amount expended by M&amp;M from the salary of the Employee.</td>
</tr>
<tr>
<td width="116"><strong>Transfer</strong></td>
<td width="555">§  In case of transfer within India, the leasing company and Admin department will assist in transfer of Vehicle registration to that of the new location that the Vehicle will be used in. The cost related to vehicle transfer, registration and agent charges will be borne by M&amp;M.

§  In case of transfer of the individual to overseas posting (outside India), M&amp;M will bear the surrender charges of the vehicle.</td>
</tr>
<tr>
<td width="116"><strong>Separation</strong></td>
<td width="555">In the event of any kind of separation before completion of the Tenure, the Employee will need to choose one of the following:

§  Pay surrender charges and return the Vehicle to the Company

§  Purchase the Vehicle from the leasing company with M&amp;M’s written consent after the defined pre-closure charges are paid.</td>
</tr>
<tr>
<td width="116"><strong>Change of vehicle before end of tenure </strong>

&nbsp;

&nbsp;</td>
<td width="555">§  To allow employees to switch to latest M&amp;M manufactured vehicle early termination of the Letter is allowed after 1 year of lock-in period.

§  In such case Employee will have to pay surrender charges as mentioned herein.

§  Switching to only M&amp;M manufactured vehicle is allowed in case of early termination

<strong> </strong></td>
</tr>
<tr>
<td width="116"><strong>Pre-closure &amp; surrender charges</strong></td>
<td width="555">§  The Employee will have the choice to Pre-close the Arrangement or Surrender the Vehicle to the Company after the lock-in period of 12 months from the Tenure Commencement Date. In such case an amount as per the formula below will have to be paid by the Employee. For any pre-closure before 12 months the Employee will have to pay Vehicle Usage Charges for the remaining period till completion of 12 months, and thereafter as per the formula below;

&nbsp;

§  Pre-closure Calculation:  Book Value (BV) + 2% of BV + residual instalment of road tax &amp; registration charges + any arrears of Vehicle Usage Charges, charges for any damages beyond normal wear &amp; tear + GST etc).

§  Surrender Calculation: Book Value (BV) + 2% of BV + residual instalment of road tax &amp; registration charges + any arrears of Vehicle Usage Charges + GST -  Market residual value of the Vehicle

§  Book Value (BV) will be calculated and given to employee at the time of request for pre-closure or surrender.

&nbsp;</td>
</tr>
<tr>
<td width="116"><strong>Buy back option at the end of the Tenure</strong></td>
<td width="555">§  The Employee will have an option to buy the Vehicle at the end of Tenure based on scheme opted. ·Fixed RV scheme buy back can be at RV rate defined as per Tenure ·MRV scheme buy back will be at the price based on market discovery process determined at the sole discretion of the Company.</td>
</tr>
<tr>
<td width="116"><strong>Loaner Vehicle</strong></td>
<td width="555">§  Loaner vehicle will be provided only for L3DH and above when they opt for M&amp;M brand of vehicles. Cost for any excessive damage to loaner vehicle which is not covered under insurance will be borne by the Employee. The Loaner vehicle will be provided without a driver. The loaner vehicle will be provided maximum 3 times in a year. Loaner vehicle will be provided in the cities of Mumbai, Pune, Chennai, Nasik and Mohali only. Loaner vehicles expenses will be charged as part of Maintenance cost to the Employee.</td>
</tr>
<tr>
<td width="116"><strong>Program Management Fees</strong></td>
<td width="555">§  A program management fee of Rs. 400 per month will also be included in the Vehicle Usage Charges and will be borne by the Employee</td>
</tr>
<tr>
<td width="116"><strong>Liability</strong></td>
<td width="555">§  All liabilities towards the Vehicles unless specified otherwise under the Letter is that of the Employee. In case of separation (resignation, termination etc) of the Employee, till such time an agreement is reached with respect to whether the Employee will like to pay surrender charges and return the Vehicle to the Company or purchase the Vehicle from the leasing company with M&amp;M’s written consent after the defined pre-closure charges are paid, the Company will be entitled to withhold the full &amp; final settlement of the due payable by the Company to the Employee as part of the terms and conditions governing the Employee’s employment. Any expenses that may be incurred towards closing of this transaction and all the schedules hereunder, including legal costs on actuals, stamp duties, registration related costs and general costs associated with the preparation and settlement of documentation relating to this transaction will be to Employee’s account</td>
</tr>
<tr>
<td width="116"><strong>Taxation</strong></td>
<td width="555">This scheme is subject to taxation as per the relevant provisions of the Income Tax Act and the rules framed therein from time to time.

&nbsp;</td>
</tr>
<tr>
<td width="116"><strong>Other Conditions</strong></td>
<td width="555"></td>
</tr>
</tbody>
</table>
<strong> </strong>

<strong> </strong>

<strong>SCHEDULE 2</strong>
<table width="604">
<tbody>
<tr>
<td></td>
<td width="214">Place of the execution of Letter</td>
<td width="340"></td>
</tr>
<tr>
<td></td>
<td width="214">Day, month, and year of the execution of this Schedule</td>
<td width="340"></td>
</tr>
<tr>
<td></td>
<td width="214">Employee Grade</td>
<td width="340"></td>
</tr>
<tr>
<td></td>
<td width="214">Scheme Type</td>
<td width="340">FRV / MRV</td>
</tr>
<tr>
<td></td>
<td width="214">Description of the Vehicle</td>
<td width="340"><strong> </strong></td>
</tr>
<tr>
<td></td>
<td width="214">Details of Manufacturer</td>
<td width="340"><strong>M&amp;M / Non-M&amp;M</strong></td>
</tr>
<tr>
<td></td>
<td width="214">Registration No.</td>
<td width="340"></td>
</tr>
<tr>
<td></td>
<td width="214">Engine No.</td>
<td width="340"></td>
</tr>
<tr>
<td></td>
<td width="214">Chassis No.</td>
<td width="340"><strong> </strong></td>
</tr>
<tr>
<td></td>
<td width="214">Location</td>
<td width="340"></td>
</tr>
<tr>
<td></td>
<td width="214">Tenure Commencement Date</td>
<td width="340"></td>
</tr>
<tr>
<td></td>
<td width="214">a) Tenure

b) Minimum Holding Period</td>
<td width="340">&nbsp;

12 months</td>
</tr>
<tr>
<td></td>
<td width="214">Tenure End Date</td>
<td width="340"><strong> </strong></td>
</tr>
<tr>
<td></td>
<td width="214">Funded Value</td>
<td width="340"></td>
</tr>
<tr>
<td></td>
<td width="214">Vehicle Usage Charges</td>
<td width="340"><strong> </strong></td>
</tr>
<tr>
<td></td>
<td width="214">Services</td>
<td width="340"><strong> </strong></td>
</tr>
<tr>
<td></td>
<td width="214">Service Charges / FMS</td>
<td width="340"><strong> </strong></td>
</tr>
<tr>
<td></td>
<td width="214">Reimbursement – RTO Tax and registration charges</td>
<td width="340"></td>
</tr>
<tr>
<td></td>
<td width="214">Insurance</td>
<td width="340"></td>
</tr>
<tr>
<td></td>
<td width="214">Frequency</td>
<td width="340"></td>
</tr>
<tr>
<td></td>
<td width="214">Due Date</td>
<td width="340"></td>
</tr>
<tr>
<td></td>
<td width="214">Contract Kms</td>
<td width="340"></td>
</tr>
<tr>
<td></td>
<td width="214">Contracted Boundary</td>
<td width="340"></td>
</tr>
<tr>
<td></td>
<td width="214">Late Payment Charge</td>
<td width="340">1% of the overdue</td>
</tr>
<tr>
<td></td>
<td width="214">Premature Termination Fee</td>
<td width="340">2%</td>
</tr>
<tr>
<td></td>
<td width="214">Break Fee</td>
<td width="340"></td>
</tr>
<tr>
<td></td>
<td width="214">Excess Kilometer Charge</td>
<td width="340"></td>
</tr>
<tr>
<td></td>
<td width="214">Repairs &amp; Maintenance</td>
<td width="340"></td>
</tr>
<tr>
<td></td>
<td width="214">Payment Terms</td>
<td width="340">NEFT / RTGS / ACH</td>
</tr>
<tr>
<td></td>
<td width="214">Security Deposit (if any)</td>
<td width="340">Nil</td>
</tr>
<tr>
<td></td>
<td width="214">Other Conditions</td>
<td width="340"></td>
</tr>
</tbody>
</table>
&nbsp;

&nbsp;

<strong>Terms and Conditions on Return of Vehicle</strong>

&nbsp;

<strong>Return of Documents / Standard Accessories </strong>

When a Vehicle is returned to Company, Employee is required to ensure that everything that came along with the Vehicle at the beginning of the Tenure is returned with the Vehicle. These include:

&nbsp;
<ol className="listtitleinside" >
 	<li>All asset registration documents</li>
 	<li>Insurance policy</li>
 	<li>All original keys</li>
 	<li>Service book</li>
 	<li>Operating manual</li>
 	<li>Standard accessories like CD players, DVD screens, remote locking device, clock, wheel caps, fog lamps etc.</li>
 	<li>Spare wheel</li>
 	<li>Tool kit and jack</li>
</ol>
&nbsp;

In case any of the above documents/ accessory is/are missing from the asset, charges for regaining, repurchasing of such items will be payable by Employee.

&nbsp;

<strong>Wear and Tear on Return of Vehicle</strong>

As we are all aware that every Vehicle will be subject to some wear and tear during the Tenure and such wear and tear will directly impact the resale value of the Vehicle. Thus, the Vehicle will be assessed / valued at the time or immediately prior to return of the Vehicle on account of end of Tenure or pre-termination of the Letter. It will be assessed considering the age and mileage of the Vehicle. The Vehicle is allowed to be used by the Employee assuming return of the car in a fair condition along with all relevant documents. Any change in the above would drastically impact the market value of the Vehicle.

&nbsp;

In case the wear and tear of the Vehicle indicates a higher degree of deterioration compared to the acceptable / unacceptable wear and tear as mentioned below, refurbishing charges will be applicable. In all cases, Company’s endeavor will be to assess damage and recharges in a fair and transparent manner. Given below is the indicative list of examples which would guide Employee on acceptable and unacceptable wear and tear of the asset.

&nbsp;
<table>
<tbody>
<tr>
<td width="283"><strong>Acceptable</strong></td>
<td width="319"><strong>Unacceptable</strong></td>
</tr>
<tr>
<td width="283">Light scratches up to 100 mm, in length which can be removed by mechanical polishing, provided the base coat has not been penetrated, nor any rust developed.</td>
<td width="319">Advertising of stickers. Must be properly removed prior to returning the asset without damage to asset</td>
</tr>
<tr>
<td width="283">Dents upto 2 on the body of asset is acceptable</td>
<td width="319">Multiple Dents not acceptable</td>
</tr>
<tr>
<td width="283">Bumpers with original paint</td>
<td width="319">Broken and cracked Grills</td>
</tr>
<tr>
<td width="283"></td>
<td width="319">Missing spare wheel,</td>
</tr>
<tr>
<td width="283"></td>
<td width="319">Torn upholstery or cigarette burns.

&nbsp;</td>
</tr>
<tr>
<td width="283"></td>
<td width="319">The carpet of the asset showing rips, tears, or heavy stains.</td>
</tr>
</tbody>
</table>
&nbsp;

<p>All the terms and of the Letter are hereby incorporated herein and made a part hereof as if such terms and conditions were set forth herein. By execution of this Schedule 2, the Employee hereby reaffirms to abide by all the terms and conditions of this Schedule 2, the Letter and Schedule 1 except to the extent, if any, modified hereby under Schedule 2. The Employee declares that the Letter was duly read and understood by him /her /it prior to affixing signatures(s) hereunder.</p>

                      </div>
                    </>
                  </Grid>
                </TabPanel>
                
                <Grid item xs={12} sm={12} className="">
                  <div class="d-flex justify-content-end">
                    <div className="mr-15">
                      <SubmitButton
                        label="Aceept"
                        className="MCsubmitbutton "
                        onClick={() => { handleClick('agreed') }}
                      />
                    </div>
                    <div>
                      <SubmitButton
                        label="Reject"
                        className="MCsubmitbutton "
                        onClick={() => { handleClick('cancelled') }}
                      />
                    </div>
                  </div>
                </Grid>
              </div>
            </Box>
          </div>
        </Paper>
      </Template>
      {/* {openEntitlementAdminModal()} */}
    </>
  );
};
export default CarLeasing;
