import React, { useState } from "react";
import "./Login.css";
import AuthTopSection from "./AuthTopSection";
import AuthButton from "./AuthButton";
import { Paper, TextField, Grid } from "@material-ui/core";
import { Link } from "react-router-dom";
import textFieldStyle from "../../../Common/Styles/TextFieldStyle";
import authPageArrow from "../../../../Assets/Images/authPageArrow.svg";

function OTPScreen(props) {
  const classes = textFieldStyle();
  const [state, setState] = useState({
    mobileNumber: "",
    otp: "",
  });

  const handleChange = (e) => {
    const { name, value } = e;
    if (name === "mobileNumber") {
      setState({ ...state, mobileNumber: value });
    } else {
      setState({ ...state, otp: value });
    }
  };

  const { otp, mobileNumber } = state;

  return (
    <div className="loginContainer justiFyCenter">
      <Paper elevation={3} className="paperStyle">
        <form noValidate autoComplete="off">
          <AuthTopSection />
          <Grid container style={{ padding: "0 48px 0 48px" }}>
            <Grid item xs={12}>
              <TextField
                variant="outlined"
                name="mobileNumber"
                onChange={handleChange}
                value={mobileNumber}
                className={`${classes.root} fullWidth customTextfield`}
                label="Mobile number"
              />
            </Grid>
            <div className="alignOtpFromOtpScreen">
              <Link
                // color="inherit"/
                className="getOtpLink commonFontNormalStyle"
                to="#"
              >
                GET OTP
              </Link>
              <img
                src={authPageArrow}
                alt="auth_page_arrow"
                className="getOtpIcon"
              />
            </div>
            <Grid item xs={12}>
              <TextField
                variant="outlined"
                name="otp"
                label="OTP"
                onChange={handleChange}
                value={otp}
                className={`${classes.root} fullWidth customTextfield`}
              />
            </Grid>
            <Grid item xs={12} style={{ margin: "16px 0px 24px 0px" }}>
              <AuthButton label="SIGN IN" />
            </Grid>
          </Grid>
          <div className="resendOtp commonFontNormalStyle">
            <p>
              Resend OTP in <span className="otpTimer">00:30</span>
            </p>
          </div>
        </form>
      </Paper>
    </div>
  );
}

export default OTPScreen;
