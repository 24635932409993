import React, { useEffect, useState } from "react";
import { Grid, Paper, Tabs, Tab, Typography, Box } from "@material-ui/core";
import PropTypes from "prop-types";
import CommonModal from "../../Common/CommonModal/CommonModal";
import Template from "../../Common/Template/Template";
import "../MahindraCollective/MahindraCollective.css";
import "./Entitlement.css";
import settingIcon from "../../../Assets/Images/settings_5_line.svg";
import PageTutorialComponent from "../../Common/PageTutorial/PageTutorialComponent";

import Loader from "../../Common/Loader/Loader";
import SubmitButton from "../../Common/Button/SubmitButton";
import { useSelector, useDispatch } from "react-redux";
import { getUserBenefitsData } from "../../../Redux/Actions/AllEntitlementAction";
import { isAccesible } from "../../../Assets/helperFunctions/helperFunctions";
import PdfViewerComponent from "../../DashBoard/WhatsNew/PdfViewer/PdfViewerComponent";
import EntitlementGradeList from "./EntitlementAdmin/EntitlementGradeList";
import { gradeA7, gradeA8 } from "../../../Assets/data/EntitlementData";
import isAdmin from "../../isAdmin";
import TruncateGradeLabel from "./TruncateGradeLabel";

import ClubMembership from "./DisplayComponents/ClubMembership";
import ClubMahindra from "./DisplayComponents/ClubMahindra";
import CTCVehicle from "./DisplayComponents/CTCVehicle";
import CreditCard from "./DisplayComponents/CreditCard";
import MobileHandset from "./DisplayComponents/MobileHandset";
import PolicyPDF from "./DisplayComponents/PolicyPDF";

let data = gradeA7;

// mobile responsive tabs
function TabPanel(props) {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const Entitlement = (props) => {
  const dispatch = useDispatch();

  const userBenefitsData = useSelector(
    (state) => state.getEntitlementReducer?.userBenefits
  );

  // const [openFilterModal, setOpenFilterModal] = useState(false);
  const [value, setValue] = React.useState(0);
  const [currentTabData, setCurrentTabData] = useState({});
  const [openPDFModal, setOpenPDFModal] = useState(false);
  // const [open, setOpen] = useState(false);
  const [openModal, setOpenModal] = useState(false);

  useEffect(() => {
    dispatch(getUserBenefitsData());
  }, []);

  useEffect(() => {
    if (userBenefitsData) {
      setValue(0);
      setCurrentTabData({ ...userBenefitsData?.entitlementCategoriesList[0] });
    }
  }, [userBenefitsData]);

  const onEdit = () => {
    setOpenModal(true);
  };
  // const onFilterMahindraCollective = () => {
  //   setOpenFilterModal(true);
  // };

  const handleCloseModal = () => {
    setOpenModal(false);
    // setOpenFilterModal(false);
  };
  // const [currentTab, setCurrentTab] = useState('');

  const handleChange = (event, newValue) => {
    setValue(newValue);
    setCurrentTabData({
      ...userBenefitsData?.entitlementCategoriesList[newValue],
    });
  };

  const openPDF = () => {
    setOpenPDFModal(true);
  };

  const handlePDFModal = () => {
    setOpenPDFModal(false);
  };

  // console.log("check selected tab value =====> ", value);
  // console.log("check selected tab data =====> ", currentTabData);
  // console.log("user benefits =====> ", userBenefitsData);
  console.log("check isAdmin value =====>", isAdmin());
  return (
    <>
      <Template pageName={"Entitlement"}>
        {/* {loading ? <Loader /> : null} */}
        <div>
          <Grid
            container
            direction="row"
            justifyContent="space-between"
            alignItems="flex-end"
          >
            <div className="mshow pagetuttop">
              {/* <PageTutorialComponent
                modelHeadtxt={"Today Page Tutorial"}
                videoLink={
                  "https://newmrisedev.blob.core.windows.net/mrisestorage/1635247382000_Core%20Intranet%20V3.mp4"
                }
              /> */}
            </div>
            <Grid item md={7} xs={12}>
              <div className="whatsNewHeader">
                <div className="containerTxt">
                  <div className="section-header-text"> My Entitlement </div>
                  {/* <div className="mhide">
                  <PageTutorialComponent
                    modelHeadtxt={"Livewire Tutorial"}
                    videoLink={
                      "https://newmrisedev.blob.core.windows.net/mrisestorage/1635246876564_Livewire%20V2.mp4"
                    }
                  />
                </div> */}
                  {isAdmin() && (
                    <div className="editIcon" onClick={onEdit}>
                      <img
                        src={
                          process.env.PUBLIC_URL + "/iconImages/MC_admin.svg"
                        }
                        alt=""
                        className="edit-line"
                      />
                      <div>Manage</div>
                    </div>
                  )}
                </div>
              </div>
            </Grid>
            <Grid item md={5} xs={12}>
              <div className="floatRightAlign fontboldnew mb-2">
                <TruncateGradeLabel label={userBenefitsData?.gradeLabel} />
              </div>
            </Grid>
          </Grid>
        </div>

        <Paper className="MClandingpageAlign mt-0 pt-4 mb-5 standardtabcontainer">
          <div className="responsiveTabs">
            <Box sx={{ width: "100%" }}>
              <Box
                sx={{ borderBottom: 1, borderColor: "divider" }}
                className="boxAlignPadMC standardlefttab"
              >
                <Tabs
                  value={value}
                  onChange={handleChange}
                  variant="scrollable"
                  scrollButtons={false}
                  aria-label="basic tabs example"
                  className="mahindraCollectiveTabsDM "
                >
                  {userBenefitsData?.entitlementCategoriesList &&
                    userBenefitsData?.entitlementCategoriesList?.map(
                      (val, index) => {
                        return (
                          <Tab
                            key={index}
                            label={val?.entitlementType}
                            {...a11yProps(index)}
                          />
                        );
                      }
                    )}
                </Tabs>
              </Box>

              <div className="alignMCCOntentTabPanel standardrighttab Econtent">
                {currentTabData?.id == "62720e2c6bc86099de7d7bb1" ? (
                  <>
                    <ClubMembership data={currentTabData} />
                    <PolicyPDF file={currentTabData?.policyPdf} />
                  </>
                ) : currentTabData?.id == "62720e2c6bc86099de7d7bb2" ? (
                  <>
                    <ClubMahindra data={currentTabData} />
                    <PolicyPDF file={currentTabData?.policyPdf} />
                  </>
                ) : currentTabData?.id == "62720e2c6bc86099de7d7bb3" ? (
                  <>
                    <CTCVehicle data={currentTabData} />
                    <PolicyPDF file={currentTabData?.policyPdf} />
                  </>
                ) : currentTabData?.id == "62720e2c6bc86099de7d7bb4" ? (
                  <>
                    <CreditCard data={currentTabData} />
                    <PolicyPDF file={currentTabData?.policyPdf} />
                  </>
                ) : (
                  currentTabData?.id == "62720e2c6bc86099de7d7bb5" && (
                    <>
                      <MobileHandset data={currentTabData} />
                      {/* <PolicyPDF onClick={openPDF} /> */}
                      <PolicyPDF file={currentTabData?.policyPdf} />
                    </>
                  )
                )}
              </div>
            </Box>
            {userBenefitsData?.entitlementCategoriesList?.length == 0 && (
              <Grid container direction="row" justifyContent="center">
                <Grid item xs="auto" className="section-header-text">
                  No Entitlements Found.
                </Grid>
              </Grid>
            )}
          </div>
        </Paper>

        <CommonModal
          open={openPDFModal}
          handleClose={handlePDFModal}
          headerText={currentTabData?.policyPdf}
          // icon={settingIcon}
          modalSize="lg"
          fullWidth
          policiesButtonSize
        >
          <PdfViewerComponent
            link={currentTabData?.policyPdf}
          ></PdfViewerComponent>
        </CommonModal>

        <CommonModal
          open={openModal}
          handleClose={handleCloseModal}
          headerText="Manage Entitlement"
          icon={settingIcon}
          modalSize="lg"
          submitBtnText="SAVE CHANGES"
          fullWidth
          policiesButtonSize
          onButtonClick={handleCloseModal}
        >
          <EntitlementGradeList />
        </CommonModal>
      </Template>
      {/* {openEntitlementAdminModal()} */}
    </>
  );
};
export default Entitlement;
