import { Grid, Icon, Paper, TextField } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import CommonAutocomplete from "../../Common/CommonAutocomplete/CommonAutocomplete";
import CommonMultiAutocomplete from "../../Common/CommonMultiAutocomplete/CommonMultiAutocomplete";
import CommonLabel from "../../Common/ComonLabel/CommonLabel";
import "./AddNewIntranetAccessComponent.css";
import DatePicker from "../../Common/DatePicker/DatePicker";

import Autocomplete from "@material-ui/lab/Autocomplete";
import services from "../../../Services";
import Swal from "sweetalert2";
import Loader from "../../Common/Loader/Loader";
import CircularProgress from "@mui/material/CircularProgress";
import Box from "@mui/material/Box";
import moment from "moment";
// import { DateTimePicker } from "@material-ui/pickers";
import addIcon from "../../../Assets/Images/add-white.svg";
import AddNewUserGroupComponent from "./AddNewUserGroupComponent";
import CommonModal from "../../Common/CommonModal/CommonModal";
import MultiSelect from "../../Common/MultiSelect/MultiSelect";
import CustomMultiSelect from "../../Common/CustomMultiSelect/CustomMultiSelect";
export default function AddNewIntranetAccessComponent({
  setSelectedMenu,
  setSelectedSubMenu,
  setActiveFrom,
  setActiveTo,
  setSelectedUserGroup,
  activeTo,
  activeFrom,
  selectedUserGroup,
  selectedUserGropName,
  setSelectedUserGroupName,
  employeeCount,
  innerLoader,
  handleActiveFromDate,
  handleActiveToDate,
  selectedSubMenuList,
  setSelectedSubMenulist,
  setGroupCompanyDetails
}) {
  

  const [menuItemData, setMenuItemData] = useState([]);
  const [submenuItemData, setSubMenuItemData] = useState([]);
  const [userGruoupItemData, setUserGruoupItemData] = useState([]);
  const [dateType, setDateType] = useState("");
  const [loading, setLoading] = useState(false);
  const [disableSubMenu, setDisableSubMenu] = useState(true);
  const [pageRefresh, setpageRefresh] = useState(false);

  const [openModalNewUserGroup, setOpenModalNewUserGroup] = useState(false);
  const [currentUserGroup, setCurrentUserGroup] = useState("");
  const [userCount, setUserCount] = useState(0);
  const handleCloseModal = () => {
    //setOpenModal(false);
    setOpenModalNewUserGroup(false);
  };

  const [selectedSubMenuData, setSelectedSubMenuData] = useState([]);
 

//to get selected sector list.
useEffect(() => {
  let dataSubMenuList = selectedSubMenuData?.map((el) => {
    return el.value;
  });

  setSelectedSubMenulist(dataSubMenuList);
}, [selectedSubMenuData]);
  //custome dropdown.
  const getOptionLabel = (option) => `${option.title}`;

  const getOptionDisabled = (option) => option.value === "foo";
  const handleToggleOption = (selectedOptions, name) => {
    setSelectedSubMenuData(selectedOptions)
    //setSelectedSubMenulist(selectedSubMenuList)
  };

  //handle clear for all the dropdown's
  const handleClearOptions = (isSelected, name) => {
    setSelectedSubMenuData([])
    //setSelectedSubMenulist(selectedSubMenuList)
  };

  //handle select all for dropdown's.
  const handleSelectAll = (isSelected, name) => {
   
    setSelectedSubMenuData(submenuItemData)
    //setSelectedSubMenu(selectedSubMenuList)
  };

  useEffect(() => {
    (async () => {
      try {
        setLoading(true);
        const res = await services.api(
          "GET",
          `/ui-assist/v1/rollmatrix/manageintranet/menu`
        );
        if (res.status === 200) {
         
          setLoading(false);
          const menuItem = res?.data?.map((el) => {
            return {
              title: el,
              value: el,
            };
          });
         
          setMenuItemData(menuItem);
        }
      } catch (err) {
        setLoading(false);
        Swal.fire("Oops!", `${err.response}`, "error");
      }
    })();
  }, []);

  useEffect(() => {
    (async () => {
      try {
        setLoading(true);
        const res = await services.api(
          "GET",
          `/ui-assist/v1/rollmatrix/manageintranet/usergroup`
        );
        if (res.status === 200) {
          setLoading(false);
          const userGruopmenuItem = res?.data?.map((el) => {
            return {
              title: el.groupName,
              value: el.usergroupId,
            };
          });
          setUserGruoupItemData(userGruopmenuItem);
        }
      } catch (err) {
        setLoading(false);
        Swal.fire("Oops!", `${err.response}`, "error");
      }
    })();
  }, []);

  const [focus, setFocus] = React.useState(false);
  const focusStyle = { color: "#db233a" };

  const handleMenuSelectChange = (e, selectType) => {
    getSubMenuApi(selectType.value);
    setSelectedMenu(selectType.value);
    setpageRefresh(!pageRefresh);
  };

  const handleDateRange = (e, selectType) => {
    if (selectType.value === "always") {
      setDateType("always");
    } else if (selectType.value === "date_range") {
      setDateType("date_range");
    } else if (selectType.value === "based_on_start_date") {
      setDateType("based_on_start_date");
    } else {
      setDateType("");
    }
  };

  const getSubMenuApi = async (name) => {
    let obj = {
      menu: name,
    };
   

    setLoading(true);
    try {
      let res = await services.api(
        "POST",
        `/ui-assist/v1/rollmatrix/manageintranet/submenu`,
        obj
      );
      setLoading(false);
      //setSubMenuItemData(subMenuData.data);
      if (res.status === 200) {
        //  if(res.data.length > 0){
        //   const subMenuItem = res?.data?.map(el =>
        //     {
        //       return {
        //         title: el,
        //         value: el
        //       }
        //     }
        //     )
        //    setSubMenuItemData(subMenuItem);
        //   setDisableSubMenu(false)
        //  }else{
        //    console.log('coming in else')
        //   setSubMenuItemData('');
        //   //setDisableSubMenu(true)
        //  }

        const subMenuItem = res?.data?.map((el) => {
          return {
            title: el,
            value: el,
          };
        });
        setSubMenuItemData(subMenuItem);
        setDisableSubMenu(false);
      }
    } catch (err) {
      setLoading(false);
      Swal.fire("Oops!", `${err.response}`, "error");
    }
  };

  //   const handleTotalEmployeeApi = async () => {
  //     console.log('selected', selectedUserGroup)

  // setInnerLoader(true)
  //     try {
  //       const res = await services.api('POST',`/ui-assist/v1/rollmatrix/manageintranet/noofusers?usergroup=${selectedUserGroup}`)
  //       setInnerLoader(false);
  //     } catch (error) {
  //       alert(error.message);
  //       setInnerLoader(false)
  //     }
  //   }

  const handleAlwaysDate = () => {
    handleActiveFromDate('')
    handleActiveToDate('')
  }
  

  const handleUserGruopSelectChange = (e, selectType) => {
    console.log(selectType, "selected value>>>>>>>>>>>");

    const add = [];
    let gruopValue = selectType.map((el) => el.value);
   // console.log(gruopValue, "select type");
    //let convertedGroupValue = add.join(',').toString()

    const addName = [];
    let groupName = selectType.map((el) => el.title);
   // console.log(groupName, "select name");
    //let convertedGroupTitle = addName.join(',').toString()

    setSelectedUserGroup(gruopValue);
    setSelectedUserGroupName(groupName);
    setGroupCompanyDetails(selectType)
    //handleTotalEmployeeApi();
  };

  const handleSubMenuSelectChannge = (e, selectType) => {
    setSelectedSubMenu(selectType.value);
  };

  // const handleActiveFromDate = (e, selectType) => {
  //   console.log('selectType!!', selectType)
  //  setActiveFrom(selectType)

  // }

  const handleActiveTo = (e, selectType) => {
    setActiveTo(selectType);
  };

  //date range data
  const dateRangeData = [
    {
      title: "Always",
      value: "always",
    },
    {
      title: "Date range",
      value: "date_range",
    },
    {
      title: "Based on start date",
      value: "based_on_start_date",
    },
  ];

  //get no of useres.

  return (
    <div>
      {loading ? <Loader /> : null}
      <div className="leftRightAlign">
        <Grid container direction="row" alignItems="center" spacing={3}>
          {/*<Grid item sm={2} xs={12}>
            <div className="displayBlockUserDetails mlP alignMCAdmin">
              <p className="detailDivp">Created by</p>
              <p className="detailsFontSize">Garima Rai</p>
            </div>
  </Grid> */}
          {/*<Grid item sm={2} xs={12}>
            <div className="displayBlockUserDetails alignMCAdmin">
              <p className="detailDivp">Created on</p>
              <p className="detailsFontSize">12 Jan 2021 17:00</p>
            </div>
</Grid> */}
          <Grid item sm={8} xs={12}>
            <div className="uploadBtn">
              <div className="displayBlockUserDetails alignMCAdmin floatRightAlign">
                <p className="detailDivp">
                  USER COUNT:{" "}
                  {innerLoader ? (
                    <CircularProgress
                      style={{ height: "10px", width: "10px" }}
                    />
                  ) : (
                    employeeCount
                  )}{" "}
                </p>
                <p className="detailsFontSize floatRightAlign">-</p>
              </div>
            </div>
          </Grid>
        </Grid>
      </div>
      <div className="rollBasedAddAccess">
        <Paper className="rollBasedPaperAddNew">
          <div className="rollBasedPaperInnnerContent">
            <Grid container direction="row" alignItems="center" spacing={3}>
              <Grid item sm={3}>
                <span className="rollBasedPaperInnnerContentLabel">
                  1. Apply access to
                </span>
              </Grid>
              <Grid item sm={4} className="rollBasedAutoCompplete">
                <CommonAutocomplete
                  handleSelectChange={handleMenuSelectChange}
                  options={menuItemData}
                  label={<CommonLabel label="Menu" mandatory />}
                />
              </Grid>
              <Grid item sm={4} className="rollBasedAutoCompplete rBautoC2">
              {/*<CommonAutocomplete
                handleSelectChange={handleSubMenuSelectChannge}
                disabled={disableSubMenu}
                options={submenuItemData}
                label={<CommonLabel label="Sub-menu" mandatory />}
              /> */}
              <CustomMultiSelect
                      items={submenuItemData}
                      getOptionLabel={getOptionLabel}
                      getOptionDisabled={getOptionDisabled}
                      selectedValues={selectedSubMenuData}
                      placeholder="Search sub menu"
                      label={<CommonLabel label="Sub-menu" mandatory />}
                      selectAllLabel="Select all"
                      onToggleOption={(isSelected) => {
                        handleToggleOption(isSelected);
                      }}
                      onClearOptions={(isSelected) => {
                        handleClearOptions(isSelected);
                      }}
                      onSelectAll={(isSelected) => {
                        handleSelectAll(isSelected);
                      }}
                    />
              </Grid>
            </Grid>
            <Grid
              container
              direction="row"
              alignItems="center"
              spacing={3}
              className="rollBasedGrid"
            >
              <Grid item sm={3}>
                <span className="rollBasedPaperInnnerContentLabel">
                  2. Assign a user group
                </span>
              </Grid>
              <Grid item sm={9} className="rollBasedAutoCompplete assignUGField">
                {/*<Autocomplete
                // handleSelectChange = {handleUserGruopSelectChange}
                onChange = {handleUserGruopSelectChange}
                multiple
                onFocus={() => setFocus(true)}
                onBlur={() => setFocus(false)}
                id="tags-outlined"
                limitTags={3}
                options={userGruoupItemData}
                getOptionLabel={(option) => option.title}
                filterSelectedOptions
                popupIcon={
                  <Icon style={focus ? focusStyle : null}>expand_more</Icon>
                }
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="outlined"
                    label={<CommonLabel label="User groups" mandatory />}
                  />
                )}
                /> */}
                <MultiSelect
                  options={userGruoupItemData}
                  label={<CommonLabel label="User Group" mandatory />}
                  name="user_group"
                  handleSelectChange={(e, value) => {
                    handleUserGruopSelectChange(e, value);
                  }}
                />
              </Grid>
            </Grid>
            <div className=""></div>
            <div className="editPoliciesStructure roleBasedCreateUserG mb26">
              <img
                src={process.env.PUBLIC_URL + "/policies/add-fill.svg"}
                alt="Add"
              />
              <span
                onClick={() => {
                  setOpenModalNewUserGroup(true);
                }}
                className="editPolicies"
              >
                CREATE USER GROUP
              </span>
            </div>
            <Grid
              container
              direction="row"
              alignItems="center"
              spacing={3}
              className="rollBasedGrid"
            >
              <Grid item sm={3}>
                <span className="rollBasedPaperInnnerContentLabel">
                  3. Assign dates
                </span>
              </Grid>
              <Grid item sm={3} className="rollBasedAutoCompplete">
                <CommonAutocomplete
                  handleSelectChange={handleDateRange}
                  options={dateRangeData}
                  label={<CommonLabel label="Type" mandatory />}
                />
              </Grid>
              {dateType === "date_range" && (
                <>
                  <Grid item md={3} xs={12} className="rollBasedAutoCompplete">
                   

                    <DatePicker
                     
                      selectedDate={activeFrom}
                      handleDateChange={(date) => {
                        handleActiveFromDate(date)
                        }}
                        minDate={new Date().setDate(new Date().getDate() )}
                      disableToolbarr={true}
                      label={<CommonLabel label="Active From" mandatory />}
                     
                    />
                  </Grid>
                  <Grid item md={3} xs={12} className="rollBasedAutoCompplete">
                    <DatePicker
                      selectedDate={activeTo}
                      handleDateChange={(date) => {
                        handleActiveToDate(date)
                        }}
                      label={<CommonLabel label="Active To" mandatory />}
                      minDate={new Date().setDate(new Date().getDate() + 1)}
                   /> 
                  </Grid>
                </>
              )}
              {dateType === "always" && <>
             
                      {handleAlwaysDate()}  
                       
              </>}
              {dateType === "based_on_start_date" && (
                <Grid xs={12} sm={4} className="assignUGField">
                <DatePicker
                     
                selectedDate={activeFrom}
                handleDateChange={(date) => {
                  handleActiveFromDate(date, 'based_on_start_date')
                  }}
                disableToolbarr={true}
                minDate={new Date().setDate(new Date().getDate())}
                label={<CommonLabel label="Active From" mandatory />}
               // disableToolbarr={true}
              />
                </Grid>
              )}
            </Grid>
          </div>
        </Paper>
        <CommonModal
          open={openModalNewUserGroup}
          handleClose={handleCloseModal}
          headerText="Create new usergroup"
          icon={addIcon}
          modalSize="md"
          footerBannerCss
          fullWidth
          policiesButtonSize
          cancleBtnText="Cancle"
          onCancleButtonClick={handleCloseModal}
          setSelectedUserGroup={setSelectedUserGroup}
        >
          <AddNewUserGroupComponent />
        </CommonModal>
      </div>
    </div>
  );
}


