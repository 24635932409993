import React, { useState, useEffect } from "react";
import "./Login.css";
import AuthTopSection from "./AuthTopSection";
import AuthButton from "./AuthButton";
import { Paper, Grid, Container } from "@material-ui/core";
import checkbox_circle_line from "../../../../Assets/Images/checkbox_circle_line.svg";
import { LOGIN } from "../../../../Routes/constant";
import { useHistory } from "react-router-dom";

function ResetLinkScreen(props) {
  const [minutes, setMinutes] = useState(5);
  const [seconds, setSeconds] = useState(0);
  const history = useHistory();
  useEffect(() => {
    let myInterval = setInterval(() => {
      if (seconds > 0) {
        setSeconds(seconds - 1);
      }
      if (seconds === 0) {
        if (minutes === 0) {
          clearInterval(myInterval);
        } else {
          setMinutes(minutes - 1);
          setSeconds(59);
        }
      }
    }, 1000);
    return () => {
      clearInterval(myInterval);
    };
  });

  return (
    <div className="loginContainer justiFyCenter">
      <Paper elevation={3} className="paperStyle">
        <AuthTopSection />
        <Container style={{ padding: "0 48px 0 48px" }}>
          <div className="lock_line_icon justiFyCenter">
            <img
              src={checkbox_circle_line}
              alt="lock_line"
              className="lock_line_iconImage"
            />
          </div>
          <p className="forgotPasswordText commonFontNormalStyle Center">
            Reset Link Sent!
          </p>
          <p className="resetLinkScreenText resetLinkTextAlign commonFontNormalStyle Center">
            Email with instructions to reset your password was sent to
            {/* <br /> */}
            <strong> gauri@zoomcar.com</strong>
          </p>
          <Grid container>
            <Grid item xs={12} style={{ marginTop: "24px" }}>
              <AuthButton
                label="LOGIN"
                handleClick={() => history.push(LOGIN)}
              />
            </Grid>
          </Grid>
          <p className="restEmailSend justiFyCenter commonFontNormalStyle">
            Resend email in{" "}
            <span className="timerColorMl">
              {minutes}:{seconds}
            </span>
          </p>
        </Container>
      </Paper>
    </div>
  );
}

export default ResetLinkScreen;
