import Swal from "sweetalert2";
import services from "../../Services/index";
import {
    GET_ALL_EMERGENCY_CONTACT_SUCCESS,
    GET_ALL_EMERGENCY_CONTACT_LOAD,
    GET_ALL_EMERGENCY_CONTACT_FAILED,
    PUT_EDIT_EMERGENCY_CONTACT_SUCCESS,
    PUT_EDIT_EMERGENCY_CONTACT_LOAD,
    PUT_EDIT_EMERGENCY_CONTACT_FAILED,
    DELETE_EMERGENCY_CONTACT_SUCCESS,
    DELETE_EMERGENCY_CONTACT_LOAD,
    DELETE_EMERGENCY_CONTACT_FAILED,
    ADD_EMERGENCY_CONTACT_SUCCESS,
    ADD_EMERGENCY_CONTACT_LOAD,
    ADD_EMERGENCY_CONTACT_FAILED,
} from "../Contants/Constants";


//GET ALL EMERGENCY CONTACTS

const getAllEmergencyContactLoad = () => ({
    type: GET_ALL_EMERGENCY_CONTACT_LOAD,
});
const getAllEmergencyContactSuccess = (data) => ({
    type: GET_ALL_EMERGENCY_CONTACT_SUCCESS,
    payload: data,
});
const getAllEmergencyContactFailed = (error) => ({
    type: GET_ALL_EMERGENCY_CONTACT_FAILED,
    err: error,
});

export const getAllEmergencyContactData = () => async (dispatch) => {
  dispatch(getAllEmergencyContactLoad());
  try {
    const res = await services.api(
      "GET",
      `/ui-assist/v1/emergency`
    );
    if (res) {
      dispatch(getAllEmergencyContactSuccess(res.data));
    }
  } catch (err) {
    dispatch(getAllEmergencyContactFailed(err.response));
    Swal.fire("Something went wrong!", `${err?.response?.data}`, "error");
  }
};


//EDIT EMERGENCY CONTACT

const editEmergencyContactLoad = () => ({
  type: PUT_EDIT_EMERGENCY_CONTACT_LOAD,
});
const editEmergencyContactSuccess = (data) => ({
  type: PUT_EDIT_EMERGENCY_CONTACT_SUCCESS,
  payload: data,
});
const editEmergencyContactFailed = (error) => ({
  type: PUT_EDIT_EMERGENCY_CONTACT_FAILED,
  err: error,
});

export const editEmergencyContactData = (data) => async (dispatch) => {
dispatch(editEmergencyContactLoad());
try {
  const res = await services.api(
    "PUT",
    `/ui-assist/v1/emergency`,data
  );
  if (res) {
    dispatch(editEmergencyContactSuccess(res.data));
  }
} catch (err) {
  dispatch(editEmergencyContactFailed(err.response));
  Swal.fire("Something went wrong!", `${err?.response?.data}`, "error");
}
};



//DELETE EMERGENCY CONTACT

const deleteEmergencyContactLoad = () => ({
  type: DELETE_EMERGENCY_CONTACT_LOAD,
});
const deleteEmergencyContactSuccess = (data) => ({
  type: DELETE_EMERGENCY_CONTACT_SUCCESS,
  payload: data,
});
const deleteEmergencyContactFailed = (error) => ({
  type: DELETE_EMERGENCY_CONTACT_FAILED,
  err: error,
});

export const deleteEmergencyContactData = (data,id) => async (dispatch) => {
dispatch(deleteEmergencyContactLoad());
try {
  const res = await services.api(
    "DELETE",
    `/ui-assist/v1/emergency/${id}`,data
  );
  if (res) {
    dispatch(deleteEmergencyContactSuccess(res.data));
  }
} catch (err) {
  dispatch(deleteEmergencyContactFailed(err.response));
  Swal.fire("Something went wrong!", `${err?.response?.data}`, "error");
}
};


//ADD EMERGENCY CONTACT

const addEmergencyContactLoad = () => ({
  type: ADD_EMERGENCY_CONTACT_LOAD,
});
const addEmergencyContactSuccess = (data) => ({
  type: ADD_EMERGENCY_CONTACT_SUCCESS,
  payload: data,
});
const addEmergencyContactFailed = (error) => ({
  type: ADD_EMERGENCY_CONTACT_FAILED,
  err: error,
});

export const addEmergencyContactData = (data) => async (dispatch) => {
dispatch(addEmergencyContactLoad());
try {
  const res = await services.api(
    "POST",
    `/ui-assist/v1/emergency`,data
  );
  if (res) {
    dispatch(addEmergencyContactSuccess(res.data));
  }
} catch (err) {
  dispatch(addEmergencyContactFailed(err.response));
  Swal.fire("Something went wrong!", `${err?.response?.data}`, "error");
}
};