import React, { useState, useEffect } from "react";
import "../../../MahindraCollective/UploadStory/UploadStoryComponent.css";
import { Grid } from "@material-ui/core";
import CharLeftField from "../../../../Common/CharLeftField/CharLeftField";
import CommonLabel from "../../../../Common/ComonLabel/CommonLabel";
import Title from "./Title";

const ContactComponent = ({ data, setData, title }) => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");

  useEffect(() => {
    if (data) {
      setName(data?.name);
      setEmail(data?.email);
    }
  }, [data]);

  useEffect(() => {
    const val = {
      name: name,
      email: email
    };
    const isValid = CheckValidation();
    const data = {
      value: val,
      isValid: isValid
    };
    setData(data);
  }, [name, email]);

  function CheckValidation() {
    if (name == "" || email == "") {
      return false;
    } else {
      return true;
    }
  }

  const handleName = (e) => setName(e.target.value);
  const handleEmail = (e) => setEmail(e.target.value);

//   console.log("name =====>", name, "email =====> ", email);

  return (
    <>
      <Title title={title} />
      <div className="divContainerOfferCampaign mb-5">
        <Grid container spacing={2} alignItems="top">
          <Grid item sm={6} md={4} xs={12}>
            <CharLeftField
              label={<CommonLabel label="Name" mandatory />}
              value={name}
              name="name"
              type="text"
              handleChange={handleName}
            />
          </Grid>
          <Grid
            item
            sm={6}
            md={4}
            xs={12}
            className="inputWidthTitle alignInputTextTitle boxwithlimit"
          >
            <CharLeftField
              label={<CommonLabel label="Email" mandatory />}
              value={email}
              name="email"
              type="text"
              handleChange={handleEmail}
            />
          </Grid>
        </Grid>
      </div>
    </>
  );
};

export default ContactComponent;
