import {
  GET_SOP_CATEGORY_DATA_SUCCESS,
  GET_SOP_CATEGORY_DATA_LOAD,
  GET_SOP_CATEGORY_DATA_FAILED,
  GET_SOP_SUBCATEGORY_DATA_SUCCESS,
  GET_SOP_SUBCATEGORY_DATA_LOAD,
  GET_SOP_SUBCATEGORY_DATA_FAILED,
  GET_SOP_TABLE_DATA_SUCCESS,
  GET_SOP_TABLE_DATA_LOAD,
  GET_SOP_TABLE_DATA_FAILED,
  GET_SOP_CATEGORY_ID_DATA_FAILED,
  GET_SOP_CATEGORY_ID_DATA_SUCCESS,
  GET_SOP_CATEGORY_ID_DATA_LOAD,
} from "../Contants/Constants";

const initialState = {
  getData: [],
  isLoading: false,
  isError: "",
};
const initialStateSubCategory = {
  getData: [],
  isLoading: false,
  isError: "",
};
const initialStateTableData = {
  getData: [],
  isLoading: false,
  isError: "",
};
const initialStateCategoryIdData = {
  getData: [],
  isLoading: false,
  isError: "",
};

const getSOPCategoryDataReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_SOP_CATEGORY_DATA_LOAD:
      return {
        ...state,
        isLoading: true,
      };
    case GET_SOP_CATEGORY_DATA_SUCCESS:
      return {
        ...state,
        getData: action.payload,
        isLoading: false,
      };
    case GET_SOP_CATEGORY_DATA_FAILED:
      return {
        ...state,
        isError: action.payload,
        isLoading: false,
      };
    default:
      return state;
  }
};

const getSOPSubCategoryDataReducer = (
  state = initialStateSubCategory,
  action
) => {
  switch (action.type) {
    case GET_SOP_SUBCATEGORY_DATA_LOAD:
      return {
        ...state,
        isLoading: true,
      };
    case GET_SOP_SUBCATEGORY_DATA_SUCCESS:
      return {
        ...state,
        getData: action.payload,
        isLoading: false,
      };
    case GET_SOP_SUBCATEGORY_DATA_FAILED:
      return {
        ...state,
        isError: action.payload,
        isLoading: false,
      };
    default:
      return state;
  }
};

const getSOPTableDataReducer = (state = initialStateTableData, action) => {
  switch (action.type) {
    case GET_SOP_TABLE_DATA_LOAD:
      return {
        ...state,
        isLoading: true,
      };
    case GET_SOP_TABLE_DATA_SUCCESS:
      return {
        ...state,
        getData: action.payload,
        isLoading: false,
      };
    case GET_SOP_TABLE_DATA_FAILED:
      return {
        ...state,
        isError: action.payload,
        isLoading: false,
      };
    default:
      return state;
  }
};

const getSOPCategoryIdDataReducer = (
  state = initialStateCategoryIdData,
  action
) => {
  console.log("inside this reducer");
  switch (action.type) {
    case GET_SOP_CATEGORY_ID_DATA_LOAD:
      return {
        ...state,
        isLoading: true,
      };
    case GET_SOP_CATEGORY_ID_DATA_SUCCESS:
      return {
        ...state,
        getData: action.payload,
        isLoading: false,
      };
    case GET_SOP_CATEGORY_ID_DATA_FAILED:
      return {
        ...state,
        isError: action.payload,
        isLoading: false,
      };
    default:
      return state;
  }
};

export {
  getSOPSubCategoryDataReducer,
  getSOPCategoryDataReducer,
  getSOPTableDataReducer,
  getSOPCategoryIdDataReducer,
};
