import services from "../../Services/index";
import {
  GET_MLDL_LIFE_TABLE_DATA_LOAD,
  GET_MLDL_LIFE_TABLE_DATA_SUCCESS,
  GET_MLDL_LIFE_TABLE_DATA_FAILED,
} from "../Contants/Constants";

const getLifePostTableDataLoad = () => ({
  type: GET_MLDL_LIFE_TABLE_DATA_LOAD,
});
const getLifePostTableDataSuccess = (data) => ({
  type: GET_MLDL_LIFE_TABLE_DATA_SUCCESS,
  payload: data,
});
const getLifePostTableDataFailed = (error) => ({
  type: GET_MLDL_LIFE_TABLE_DATA_FAILED,
  err: error,
});

export const getLifePostTableData = () => async (dispatch) => {
  dispatch(getLifePostTableDataLoad());
  try {
    const res = await services.api("GET", `/ui-assist/v1/lifeposts`, {});
    if (res) {
      dispatch(getLifePostTableDataSuccess(res.data));
    }
  } catch (err) {
    dispatch(getLifePostTableDataFailed(err.response));
  }
};
