import React from "react";
import { Switch } from "react-router-dom";
import EditNewPolicies from "../../Components/Pages/Policies/EditNewPolicies/EditNewPolicies";
import PoliciesComponent from "../../Components/Pages/Policies/PoliciesComponent";
import SearchPoliciesComponent from "../../Components/Pages/Policies/SearchPolicies/SearchPoliciesComponent";
//import SearchPoliciesComponent from "../../Components/Pages/Policies/SearchPolicies/SearchPoliciesComponent";
import SearchPoliciesTableComponent from "../../Components/Pages/Policies/SearchPoliciesTable/SearchPoliciesTableComponent";
import EditSubCategories from "../../Components/Pages/Policies/EditSubcategories/EditSubCategories";
import EditPolicys from "../../Components/Pages/Policies/EditPolicys/EditPolicys";
import {
  POLICIES,
  POLICIES_SEARCH,
  POLICIES_SEARCH_TABLE,
  POLICIES_EDIT,
  POLICIES_SUBCATEGORY,
  POLICYS_EDIT,
  POLICIES_DETAILS,
  SOPS,
} from "../constant";
import PoliciesSearchComponent from "../../Components/Pages/Policies/PoliciesSearchTemplate/PoliciesSearchComponent";
import { ApmRoute } from "@elastic/apm-rum-react";

export default function PoliciesRoutes() {
  return (
    <div>
      <Switch>
        <ApmRoute exact path={`${POLICIES}`} component={PoliciesComponent} />
        <ApmRoute
          exact
          path={`${POLICIES}${POLICIES_SEARCH}`}
          component={SearchPoliciesComponent}
        />
        <ApmRoute
          exact
          path={`${POLICIES}${POLICIES_SEARCH_TABLE}`}
          component={SearchPoliciesTableComponent}
        />
        <ApmRoute
          exact
          path={`${POLICIES}${POLICIES_EDIT}`}
          component={EditNewPolicies}
        />
        <ApmRoute
          path={`${POLICIES}${POLICIES_SUBCATEGORY}/`}
          component={EditSubCategories}
        />
        <ApmRoute
          path={`${POLICIES}${POLICYS_EDIT}/`}
          component={EditPolicys}
        />
        <ApmRoute
          path={`${POLICIES}${POLICIES_DETAILS}/`}
          component={PoliciesSearchComponent}
        />
      </Switch>
    </div>
  );
}
