import React, { useState } from "react";
import "./Login.css";
import AuthTopSection from "./AuthTopSection";
import AuthButton from "./AuthButton";
import { Paper, TextField, Grid, Container } from "@material-ui/core";
import lock_line from "../../../../Assets/Images/lock-line.svg";
import { Link, useHistory } from "react-router-dom";
import { LOGIN, RESET_LINK, AUTH } from "../../../../Routes/constant";
import authPageArrow from "../../../../Assets/Images/authPageArrow.svg";
import textFieldStyle from "../../../Common/Styles/TextFieldStyle";

function ForgotPassword(props) {
  const [email, setEmail] = useState("");

  const handleChange = (e) => {
    setEmail(e.target.value);
  };
  const history = useHistory();
  const classes = textFieldStyle();

  return (
    <div className="loginContainer justiFyCenter">
      <Paper elevation={3} className="paperStyle">
        <form noValidate autoComplete="off">
          <AuthTopSection />
          <div className="lock_lin_icon justiFyCenter">
            <img
              src={lock_line}
              alt="lock_line"
              className="lock_line_iconImage"
            />
          </div>
          <p className="forgotPasswordText commonFontNormalStyle">
            Forgot Password
          </p>
          <p className="resetLinkText commonFontNormalStyle">
            Reset link will be sent to your registered email
          </p>
          <Container style={{ padding: "0 48px 0 48px" }}>
            <Grid container>
              <Grid item xs={12}>
                <TextField
                  variant="outlined"
                  name="email"
                  onChange={handleChange}
                  value={email}
                  className={`${classes.root} fullWidth customTextfield`}
                  label="Email"
                />
              </Grid>
              <Grid item xs={12} style={{ paddingTop: "16px" }}>
                <AuthButton
                  label="SEND"
                  handleClick={() => history.push(`${AUTH}${RESET_LINK}`)}
                />
              </Grid>
            </Grid>
          </Container>

          <div className="backToSignInText">
            <img
              src={authPageArrow}
              alt="auth_page_arrow"
              className="signInArrowIcon"
            />
            <Link
              color="inherit"
              to={`${AUTH}${LOGIN}`}
              className="backForgot commonFontNormalStyle"
            >
              BACK TO SIGN IN
            </Link>
          </div>
        </form>
      </Paper>
    </div>
  );
}

export default ForgotPassword;
