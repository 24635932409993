import React, { useState } from "react";
import "./ViewOfferCampaignsComponent.css";
import {
  Paper,
  Grid,
  Button,
  RadioGroup,
  FormControlLabel,
  Radio,
} from "@material-ui/core";
import { useFormik } from "formik";
import CharLeftField from "../../../Common/CharLeftField/CharLeftField";
import SubmitButton from "../../../Common/Button/SubmitButton";
import DatePicker from "../../../Common/DatePicker/DatePicker";
import CommonAutocomplete from "../../../Common/CommonAutocomplete/CommonAutocomplete";
import CommonModal from "../../../Common/CommonModal/CommonModal";
import SelectFileTypeComponent from "../../SubmitOfferCampaign/SelectFileTypeComponent";
import { EditorState } from "draft-js";
import CommonLabel from "../../../Common/ComonLabel/CommonLabel";
import Template from "../../../Common/Template/Template";
import offerCompaignApprovalIcon from "../../../../Assets/Images/offer-compaign-approval.svg";
import { SubmitOfferCampaignSchema } from "../../../../Assets/formikErrors/SubmitOfferCampaignSchema";
import uploadIcon from "../../../../Assets/Images/collectiveIcons/upload-2-line.svg";
import services from "../../../../Services/index";
import moment from "moment";
import rejectArticle from "../../../../Assets/Images/file-forbid-line.svg";
import previewArticle from "../../../../Assets/Images/search-eye-line.svg";
import PublishArticleComponent from "../../MahindraCollective/Admin/PreviewArticleModel/PreviewArticleComponent";
import RejectArticleComponent from "../../MahindraCollective/Admin/RejectArticleModel/RejectArticleComponent";
import PreviewOCArticleComponent from "./PreviewOCArticleComponent";
import { LIVEWIRE, OFFER_CAMPAIGNS } from "../../../../Routes/constant";
import { useHistory } from "react-router-dom";
import Swal from "sweetalert2";
import Loader from "../../../Common/Loader/Loader";
import MultiSelect from "../../../Common/MultiSelect/MultiSelect";
const getSectorList = [
  {
    title: "sector",
    value: "sector",
  },
];

const style = {
  width: 300,
  marginBottom: "1.5em",
};

const options = [
  {
    title: "Image",
    value: "image",
  },
  {
    title: "PDF",
    value: "document",
  },
  {
    title: "Text content",
    value: "text",
  },
  {
    title: "HTML content",
    value: "html",
  },
  {
    title: "Youtube URL",
    value: "youtube",
  },
];

function SubmitOfferCampaign(props) {
  const [loading, setLoading] = useState(false);

  let getCampaign = props?.location?.state?.details;
  const history = useHistory();

  const [state, setState] = useState({
    // title: "",
    // description: "",
    // distributes: "",
    // keywords: "",
    // name: "",
    // email: "",
    // phoneNumber: "",
    htmlContent: "",
  });
  const handleSubmitForPublish = () => {
    setSubmitForPublish(true);
  };
  const handleSubmitForReject = () => {
    setSubmitForReject(true);
  };
  const handleSubmitForPreview = () => {
    setSubmitForPreview(true);
  };
  const [submitForPublish, setSubmitForPublish] = React.useState(false);
  const [submitForReject, setSubmitForReject] = React.useState(false);
  const [submitForPreview, setSubmitForPreview] = React.useState(false);

  // const onEditorStateChange = (editorState) => {
  //   setEditorState(editorState);
  // };
  const handleCloseModal = () => {
    setSubmitForPublish(false);
    setSubmitForReject(false);
    setSubmitForPreview(false);
  };
  const [selectedValue, setSelectedValue] = useState("");
  const [titleCharLeft, setTitleCharLeft] = useState(100);
  const [distributesCharLeft, setDistributesCharLeft] = useState(50);
  const [keywordsCharLeft, setKeywordsCharLeft] = useState(50);
  const [textAreaCharLeft, setTextAreaCharLeft] = useState(3500);
  const [selectedDate, setSelectedDate] = useState({
    expiryDate: null,
    publishDate: null,
  });
  // const [radioValues, setRadioValues] = useState("colleague");
  const [fileType, setFileType] = useState({
    image: null,
    pdf: null,
  });
  const [errorSelectFileType, setErrorSelectFileType] = useState("");
  const [editorState, setEditorState] = useState(() =>
    EditorState.createEmpty()
  );
  const [publishOfferAndCampaignsData, setPublishOfferAndCampaignsData] =
    useState({});
  const [publishOfferAndCampaignsError, setPublishOfferAndCampaignsError] =
    useState({});
  const [submitForApproval, setSubmitForApproval] = useState(false);
  const [rejectionNote, setRejectionNote] = useState("");

  const handleDateChange = (name, date) => {
    if (name === "expiryDate") {
      setSelectedDate({ ...selectedDate, expiryDate: date });
    } else if (name === "publishDate") {
      setSelectedDate({ ...selectedDate, publishDate: date });
    }
  };

  const handleChange = (e) => {
    const { value, name } = e.target;
    // setState({ ...state, [name]: value });
    formik.handleChange(e);
    if (name === "title") {
      if (value.length <= 100) setTitleCharLeft(100 - value.length);
    } else if (name === "distributeTo") {
      if (value.length <= 50) setDistributesCharLeft(50 - value.length);
    } else if (name === "tags") {
      if (value.length <= 50) setKeywordsCharLeft(50 - value.length);
    }
  };

  const handleSelectChange = (event, selectType) => {
    setSelectedValue(selectType);
  };

  const handleChangeTextArea = (e) => {
    formik.handleChange(e);
    const { value } = e.target;
    // setState({ ...state, htmlContent: value });
    if (value.length <= 3500) {
      setTextAreaCharLeft(3500 - value.length);
    }
  };

  const handleFileChange = (e) => {
    const { name, files } = e.target;
    if (name === "image") {
      setFileType({ ...fileType, image: files[0] });
    } else {
      setFileType({ ...fileType, pdf: files[0] });
    }
  };

  const onEditorStateChange = (editorState) => {
    setEditorState(editorState);
  };

  // const handleRadioChange = (e) => {
  //   setRadioValues(e.target.value);
  // };

  // const handleReplaceImage = () => {
  //   setFileType({
  //     image: null,
  //   });
  // };

  // const handleReplacePDf = () => {
  //   setFileType({
  //     pdf: null,
  //   });
  // };
  // const handleSubmitForApproval = () => {
  //   setSubmitForApproval(true);
  // };

  // const handleBlurAutoComplete = () => {
  //   if (selectedValue) {
  //     setErrorSelectFileType("");
  //   } else {
  //     setErrorSelectFileType("Select file is required");
  //   }
  // };

  const formik = useFormik({
    initialValues: getCampaign,
    enableReinitialize: true,
    // validationSchema: SubmitOfferCampaignSchema,
    // onSubmit: (values, { resetForm }) => handleSubmit(values, resetForm),
    // onSubmit: (values) => {
    //   alert(JSON.stringify(values, null, 2));
    // },
  });

  const {
    title,
    subTitle,
    distributeTo,
    tags,
    name,
    submittedFor,
    email,
    phoneNumber,
    expiryDate,
    publishDate,
    authorPost,
  } = formik.values;

  let radioValues = authorPost === true ? "author" : "colleague";

  const publishOfferAndCampaigns = (data, error) => {
    setPublishOfferAndCampaignsData(data);
    setPublishOfferAndCampaignsError(error);
  };

  const submitOfferAndCampaignForApproval = async () => {
    const { publishDate, publishOrSchedule, iswhatsnew, expiryDate } =
      publishOfferAndCampaignsData;

    const { id, author } = formik.values;

    let formData = new FormData();
    formData.append("admin", author);
    formData.append("iswhatsnew", iswhatsnew);
    formData.append(
      "publishDate",
      publishOrSchedule === "publish"
        ? moment(formik.values.publishDate).format("YYYY-MM-DD")
        : publishDate
    );
    formData.append("whatsnewexpiry", expiryDate);

    try {
      setLoading(true);
      let res = await services.api(
        "POST",
        `/ui-assist/v1/offerorcampaign/approve/${id}`,
        formData
      );
      if (res.status === 200) {
        let q = new Date();
        let m = q.getMonth() + 1;
        let d = q.getDay();
        let y = q.getFullYear();

        let date = new Date(y, m, d);
        let myDate = new Date(res.data.publishDate);

        let sucessMsg =
          date == myDate
            ? "Your story has been published successfully"
            : `Story will be publish on ${moment(res.data.publishDate).format(
                "DD/MM/YYYY"
              )}`;
        setLoading(false);
        setSubmitForPublish(false);
        if (res) {
          Swal.fire("Successfully updated!", `${sucessMsg}`, "success").then(
            (result) => {
              if (result.isConfirmed) {
                history.push(`${LIVEWIRE}${OFFER_CAMPAIGNS}`);
              }
            }
          );
        }
      } else {
        setLoading(false);
        Swal.fire("Oops!", "Something went wrong.", "warning");
      }
    } catch (err) {
      setLoading(false);
      setSubmitForPublish(false);
      Swal.fire("Oops!", err.response.data, "warning");
    }
  };

  const handleRejectStory = async () => {
    const {
      id,
      title,
      subTitle,
      story,
      isAuthorPost,
      author,
      name,
      submittedFor,
      email,
      phoneNumber,
      requestedDate,
      thumbnail,
      storyType,
      sector,
      businessOrDivisionName,
      authorPost,
      tags,
      expiryDate,
      distributeTo,
      publishDate,
    } = formik.values;
    let data = {
      id: id,
      title: title,
      description: subTitle,
      tags: tags,
      story: story,
      isAuthorPost: isAuthorPost,
      author: author,
      name: isAuthorPost ? name : submittedFor,
      email: email,
      phoneNumber: phoneNumber,
      requestedDate: requestedDate,
      publishDate: publishDate,
      // .toDate()
      // .format("YYYY-MM-DD"),
      expiryDate: expiryDate,
      // .toDate()
      // .format("YYYY-MM-DD"),
      thumbnail: thumbnail,
      distributeTo: distributeTo,
      storyType: storyType,
      sector: sector,
      businessOrDivisionName: businessOrDivisionName,
      isApproved: false,
      approvedBy: author,
      mahindraCollective: false,
      authorPost: authorPost,
    };
    // toDate()).format("YYYY-MM-DD")
    try {
      setLoading(true);
      let res = await services.api(
        "POST",
        `/ui-assist/v1/offerorcampaign/reject/${id}?admin=${localStorage.getItem(
          "token"
        )}`
      );
      if (res.status === 200) {
        setLoading(false);
        setSubmitForReject(false);
        if (res) {
          Swal.fire(
            "Successfully updated!",
            "Story has been rejected successfully.",
            "success"
          ).then((result) => {
            if (result.isConfirmed) {
              history.push(`${LIVEWIRE}${OFFER_CAMPAIGNS}`);
            }
          });
        }
      } else {
        setLoading(false);
        Swal.fire("Oops!", "Something went wrong.", "warning").then(
          (result) => {
            if (result.isConfirmed) {
              history.push(`${LIVEWIRE}${OFFER_CAMPAIGNS}`);
            }
          }
        );
      }
    } catch (err) {
      setLoading(false);
      setSubmitForReject(false);
      Swal.fire("Oops!", err.response.data, "warning").then((result) => {
        // if (result.isConfirmed) {
        //   history.push(DASHBOARD);
        // }
      });
    }
  };

  const getRejectionNote = (data) => {
    setRejectionNote(data);
  };
  // const, [selecteddDate, handleDateeChange] = useState(null);
  const getSelectedTypeFromExisting = (values) => {
    const { mediaType } = values;
    if (mediaType == "image") {
      return options[0];
    } else if (mediaType === "document") {
      return options[1];
    } else if (mediaType === "text") {
      return options[2];
    } else if (mediaType === "html") {
      return options[3];
    } else
    { return options[4]; }
  };
  const getExsitingSector = formik.values.distributeTo.map((result) => {
    return {
      title: result.sectorName,
      value: result.sectorName,
    };
  });

  return (
    <Template pageName={"Review offer/campaign"}>
      {loading ? <Loader /> : null}
      <div className="submitOfferCampaignContainer">
        <Paper className="paperContainerOfferCampaign">
          <form onSubmit={formik.handleSubmit}>
            <div className="innerContainersubmitOfferCampaign">
              {/* <form onSubmit={formik.handleSubmit}> */}
              <div className="inlineContent">
                <span className="submitOfferCampaignText commonFontNormalStyle m0">
                  Review offer/campaign
                </span>
                <span
                  className="previewMCData"
                  onClick={handleSubmitForPreview}
                >
                  Preview
                </span>
              </div>
              <div className="divContainerOfferCampaign">
                <Grid container spacing={2} alignItems="center">
                  <Grid item md={12} xs={12}>
                    <CharLeftField
                      label={<CommonLabel label="Title" validate mandatory />}
                      validate
                      disabled
                      charLeft={titleCharLeft}
                      // handleChange={handleChange}
                      value={title}
                      name="title"
                      inputProps={{
                        maxLength: 100,
                      }}
                      type="text"
                      handleBlur={formik.handleBlur}
                      errorMsg={
                        formik.errors.title && formik.touched.title
                          ? formik.errors.title
                          : ""
                      }
                    />
                  </Grid>
                  <Grid item md={12} xs={12}>
                    <CharLeftField
                      disabled
                      label={
                        <CommonLabel label="Subtitle" validate mandatory />
                      }
                      // handleChange={formik.handleChange}
                      value={subTitle}
                      name="subTitle"
                      handleBlur={formik.handleBlur}
                      type="text"
                      errorMsg={
                        formik.errors.subTitle && formik.touched.subTitle
                          ? formik.errors.subTitle
                          : ""
                      }
                    />
                  </Grid>
                  <Grid item md={4} xs={12} sm={4} className="selectType">
                    <CommonAutocomplete
                      disabled
                      className="width100"
                      name="selectFileType"
                      // handleSelectChange={handleSelectChange}
                      defaultValue={getSelectedTypeFromExisting(formik.values)}
                      // selectedValue={getSelectedTypeFromExisting(formik.values)}
                      options={options}
                      style={style}
                      // handleBlurAutoComplete={handleBlurAutoComplete}
                      label={
                        <CommonLabel
                          label="Select file type"
                          validate
                          mandatory
                        />
                      }
                      errorMsg={errorSelectFileType}
                    />
                  </Grid>
                  {selectedValue.value === "text" ? (
                    <>
                      <Grid item md={4} xs={12} className="uploadBtnStyle">
                        <div className="uploadBtn">
                          <label for="thumb" className="btn uploadBtnLabel">
                            Upload thumbnail <span className="colRed">*</span>
                          </label>
                          <img
                            for="thumb"
                            src={uploadIcon}
                            className="uploadIconStyl"
                            alt="Upload Button Icon"
                          />
                          <input
                            id="thumb"
                            style={{
                              visibility: "hidden",
                              padding: "19px 16px",
                              position: "absolute",
                            }}
                            type="file"
                            accept=".png, .jpg, video/*"
                            disabled={true}
                          />
                        </div>
                      </Grid>
                      <div className="compatiblityTxt">
                        Compatible formats: Image - JPG, PNG Video - MP4
                      </div>
                    </>
                  ) : null}
                  {formik.values?.mediaType === "youtube" && (
                    <>
                      <Grid item md={4} xs={12} className="uploadBtnStyle">
                        <CharLeftField
                          disabled={true}
                          label={
                            <CommonLabel
                              label="Enter youtube link only"
                              mandatory
                            />
                          }
                          // handleChange={(e) => handlevideoUri(e, formik)}
                          handleBlur={formik.handleBlur}
                          value={formik.values?.videoUri}
                          name="videoUri"
                          type="text"
                        />
                      </Grid>
                      <Grid item xs={12} md={4}>
                        {""}
                      </Grid>
                    </>
                  )}
                  <Grid
                    item
                    md={
                      selectedValue.value === "html" ||
                      selectedValue.value === "text"
                        ? 12
                        : 8
                    }
                    xs={12}
                    sm={12}
                    md={12}
                  >
                    <SelectFileTypeComponent
                      readOnly
                      mediaType={formik.values.mediaType}
                      story={formik.values.story}
                      media={formik.values.media}
                      formik={formik}
                      selectedValue={selectedValue}
                      // handleFileChange={(event) => handleFileChange(event)}
                      // fileType={fileType}
                      // handleChangeTextArea={handleChangeTextArea}
                      htmlContent={state.htmlContent}
                      textAreaCharLeft={textAreaCharLeft}
                      editorState={editorState}
                      // onEditorStateChange={onEditorStateChange}
                      // handleReplaceImage={handleReplaceImage}
                      // handleReplacePDf={handleReplacePDf}
                    />
                  </Grid>
                  {formik.values.mediaType === "text" ||
                  formik.values.mediaType === "document" ||
                  formik.values.mediaType === "html" ? (
                    <>
                      <Grid item md={4} xs={12} className="uploadBtnStyle">
                        <div className="uploadBtn">
                          <label for="files" className="btn uploadBtnLabel">
                            {formik.values.thumbnail}
                            <span className="colRed">*</span>
                          </label>
                          <img
                            src={uploadIcon}
                            className="uploadIconStyl"
                            alt="Upload Button Icon"
                          />
                          <input
                            disabled={true}
                            id="files"
                            style={{
                              visibility: "hidden",
                              padding: "19px 16px",
                              position: "absolute",
                            }}
                            type="file"
                            accept="application/pdf"
                          />
                        </div>
                      </Grid>
                      <div className="compatiblityTxt">
                        Compatible formats: PDF
                      </div>
                    </>
                  ) : null}
                </Grid>
              </div>
              <span className="additionalDetailsText commonFontNormalStyle">
                Additional details
              </span>
              <div className="additionalDetailsContainer">
                <Grid container spacing={5}>
                  <Grid item md={6} xs={12} className="maxWidthContent">
                    {/* <CharLeftField
                      disabled
                      label={
                        <CommonLabel
                          label="Distribute it to"
                          mandatory
                          className="maxWidthContent"
                        />
                      }
                      validate
                      charLeft={distributesCharLeft}
                      // handleChange={handleChange}
                      value={distributeTo.toString()}
                      name="distributeTo"
                      handleBlur={formik.handleBlur}
                      inputProps={{
                        maxLength: 50,
                      }}
                      type="text"
                      errorMsg={
                        formik.errors.distributeTo &&
                        formik.touched.distributeTo
                          ? formik.errors.distributeTo
                          : ""
                      }
                    /> */}
                    <MultiSelect
                      className="width100"
                      // handleSelectChange={handleSelectChange}
                      selectedValue={getExsitingSector}
                      options={getSectorList?.length && getSectorList}
                      // style={style}
                      name="sector"
                      disabled={true}
                      // handleSelectChange={(e, value) => {
                      //   // getDivsionBySector(value);
                      //   formik.setFieldValue("sector", value);
                      // }}
                      // handleBlur={formik.handleBlur}
                      // errorMsg={
                      //   formik.errors.sector && formik.touched.sector
                      //     ? formik.errors.sector
                      //     : ""
                      // }
                      label={<CommonLabel label="Sector" mandatory />}
                    />
                  </Grid>
                  <Grid item md={6} xs={12} className="maxWidthContent">
                    <CharLeftField
                      disabled
                      label={<CommonLabel label="Keywords" mandatory />}
                      validate
                      value={tags.toString()}
                      charLeft={keywordsCharLeft}
                      // handleChange={handleChange}
                      name="tags"
                      handleBlur={formik.handleBlur}
                      inputProps={{
                        maxLength: 50,
                      }}
                      type="text"
                      errorMsg={
                        formik.errors.tags && formik.touched.tags
                          ? formik.errors.tags
                          : ""
                      }
                    />
                  </Grid>
                  <Grid item md={6} xs={12}>
                    <DatePicker
                      disabled
                      label={<CommonLabel label="Publish date" />}
                      selectedDate={publishDate}
                      // handleDateChange={(date) => {
                      //   formik.setFieldValue("publishDate", date);
                      // }}
                      // handleDateChange={(date) =>
                      //   handleDateChange("publishDate", date)
                      // }
                      name="publishDate"
                    />
                  </Grid>
                  <Grid item md={6} xs={12}>
                    <DatePicker
                      disabled
                      label={<CommonLabel label="Expiry date" />}
                      selectedDate={expiryDate}
                      // handleDateChange={(date) => {
                      //   formik.setFieldValue("expiryDate", date);
                      // }}
                      name="expiryDate"
                    />
                  </Grid>
                  <Grid item md={12} xs={12}>
                    <RadioGroup
                      aria-label="I am the author"
                      name={authorPost === true ? "author" : "colleague"}
                      value={authorPost === true ? "author" : "colleague"}
                      disabled={true}
                      // onChange={formik.handleChange}
                    >
                      <Grid container spacing={5}>
                        <Grid item md={4} xs={12}>
                          <div
                            className={`${
                              radioValues === "author" ? "radioBackground" : ""
                            } authorRadioButton`}
                          >
                            <FormControlLabel
                              value="author"
                              className="radioButtonForm"
                              control={<Radio />}
                              label={
                                <span className="formControlLabelStyle commonFontNormalStyle">
                                  I am the author
                                </span>
                              }
                              labelPlacement="end"
                            />
                          </div>
                        </Grid>
                        <Grid item md={4} xs={12}>
                          <div
                            className={`${
                              radioValues === "colleague"
                                ? "radioBackground"
                                : ""
                            } authorRadioButton`}
                          >
                            <FormControlLabel
                              value="colleague"
                              className="radioButtonForm"
                              control={<Radio />}
                              label={
                                <span className="formControlLabelStyle commonFontNormalStyle">
                                  Submitting it for a colleague{" "}
                                </span>
                              }
                              labelPlacement="end"
                            />
                          </div>
                        </Grid>
                      </Grid>
                    </RadioGroup>
                  </Grid>
                  <Grid item md={4} xs={12}>
                    <CharLeftField
                      disabled
                      inputType={true}
                      label={<CommonLabel label="Name" mandatory />}
                      // handleChange={formik.handleChange}
                      value={authorPost ? name : submittedFor}
                      name="name"
                      type="text"
                      handleBlur={formik.handleBlur}
                      errorMsg={
                        formik.errors.name && formik.touched.name
                          ? formik.errors.name
                          : ""
                      }
                    />
                  </Grid>
                  <Grid item md={4} xs={12}>
                    <CharLeftField
                      disabled
                      inputType={true}
                      label={<CommonLabel label="Email" mandatory />}
                      // handleChange={formik.handleChange}
                      value={email}
                      name="email"
                      type="email"
                      handleBlur={formik.handleBlur}
                      errorMsg={
                        formik.errors.email && formik.touched.email
                          ? formik.errors.email
                          : ""
                      }
                    />
                  </Grid>
                  <Grid item md={4} xs={12}>
                    <CharLeftField
                      disabled
                      inputType={true}
                      label={<CommonLabel label="Phone number" mandatory />}
                      // handleChange={formik.handleChange}
                      value={phoneNumber}
                      name="phoneNumber"
                      handleBlur={formik.handleBlur}
                      type="tel"
                      // inputProps={{
                      //   maxLength: 10,
                      // }}
                      errorMsg={
                        formik.errors.phoneNumber && formik.touched.phoneNumber
                          ? formik.errors.phoneNumber
                          : ""
                      }
                    />
                  </Grid>
                </Grid>
              </div>
              <div className="supportPart">
                <Grid container justifyContent="flex-end" alignItems="center">
                  <div className="SubmitForApprovalButtonDiv m8 publishRejectBtn">
                    <Button
                      variant="outlined"
                      className="btnReject"
                      onClick={handleSubmitForReject}
                    >
                      Reject Story
                    </Button>
                    <SubmitButton
                      label="publish story"
                      type="button"
                      variant="contained"
                      className=" commonFontNormalStyle"
                      size="large"
                      onClick={handleSubmitForPublish}
                    />
                  </div>
                </Grid>
              </div>
              {/* </form> */}
            </div>
          </form>
        </Paper>
      </div>
      <CommonModal
        open={submitForPublish}
        handleClose={handleCloseModal}
        headerText="Publish offer/campaign"
        icon={offerCompaignApprovalIcon}
        modalSize="md"
        submitBtnText="SAVE CHANGES"
        fullWidth
        onButtonClick={submitOfferAndCampaignForApproval}
        disabledSubmitButton={
          Object.keys(publishOfferAndCampaignsError).length ? true : false
        }
      >
        <PreviewOCArticleComponent
          title={formik.values.title}
          publishOfferAndCampaigns={publishOfferAndCampaigns}
          existingPublishDate={formik.values.publishDate}
          existingExpiryDate={formik.values.expiryDate}
        />
      </CommonModal>
      <CommonModal
        open={submitForReject}
        handleClose={handleCloseModal}
        headerText="Reject article"
        icon={rejectArticle}
        modalSize="md"
        submitBtnText="SEND"
        fullWidth
        onButtonClick={handleRejectStory}
      >
        <RejectArticleComponent getRejectionNote={getRejectionNote} />
      </CommonModal>
      <CommonModal
        open={submitForPreview}
        handleClose={handleCloseModal}
        headerText="Preview"
        icon={previewArticle}
        modalSize="md"
        submitBtnText="DONE"
        fullWidth
        onButtonClick={() => setSubmitForPreview(false)}
      >
        <PublishArticleComponent previewData={formik.values} />
      </CommonModal>
    </Template>
  );
}

export default SubmitOfferCampaign;
