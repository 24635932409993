import React from "react";
import { Card, Grid, Avatar } from "@material-ui/core";
import "./ScheduleTabCardComponent.css";
import DatePicker from "../../../../Common/DatePicker/DatePicker";
import {
  LIVEWIRE,
  UPLOAD_ARTICLE,
  OC_REVIEW_ARTICLE,
} from "../../../../../Routes/constant";
import { useHistory } from "react-router-dom";
import { alpha } from "@material-ui/core/styles";

export default function ScheduleTabCardComponent({
  scheduleData,
  isOfferCampaignsView,
}) {
  const history = useHistory();

  const scope =
    JSON.parse(localStorage.getItem("scopeDetails"))?.admin === true &&
    JSON.parse(localStorage.getItem("scopeDetails")).adminType === "OTHERS";

  const scopeDetails = JSON.parse(localStorage.getItem("scopeDetails"));

  return (
    <>
      {scheduleData.length > 0 && scope
        ? scheduleData
            ?.filter((item) =>
              scopeDetails?.moduleAssigneds
                ?.map(
                  (moduleItem) =>
                    moduleItem?.module === "MAHINDRA_COLLECTIVE" &&
                    moduleItem?.sectorScope
                )
                ?.flat()
                ?.some((r) => item?.sector.indexOf(r) >= 0)
            )
            ?.map((result) => (
              <Card className="adminCardAlign" key={result.id}>
                <div className="adminCard">
                  <Grid
                    container
                    direction="row"
                    justifyContent="space-between"
                    alignItems="center"
                  >
                    <div className="adminCardTitle">{result.title} </div>
                    <div className="AdminCardDaysTogo">
                      <DatePicker
                        // label={<CommonLabel label="Expiry date" />}
                        selectedDate={result.publishDate}
                        // handleDateChange={(date) =>
                        //   handleDateChange("expiryDate", date)
                        // }
                        // name="expiryDate"
                        disabled
                      />
                    </div>
                  </Grid>
                  <Grid
                    container
                    direction="row"
                    justifyContent="space-between"
                    alignItems="center"
                  >
                    <div className="adminCardSubTitle">{result.subTitle}</div>
                  </Grid>
                  <Grid
                    container
                    direction="row"
                    justifyContent="space-between"
                    alignItems="center"
                  >
                    <div className="adminCardProfileName">
                      {result?.isAuthorPost == true ? (
                        <>
                          <img
                            src={result.empPhotoUrl}
                            className="profileWidth"
                            alt="Admin profile"
                          />
                          <div>{result.name}</div>
                        </>
                      ) : (
                        <>
                          <Avatar></Avatar>
                          <div>{result?.submittedFor}</div>
                        </>
                      )}
                    </div>
                    <div className="adminCardView">
                      <div
                        onClick={() => {
                          isOfferCampaignsView
                            ? history.push({
                                pathname: `${LIVEWIRE}${OC_REVIEW_ARTICLE}`,
                                state: {
                                  details: result,
                                },
                              })
                            : history.push({
                                pathname: `${LIVEWIRE}/view-article/${result?.id}`,
                                state: {
                                  details: result,
                                  scheduleDataFlag: true,
                                },
                              });
                        }}
                      >
                        view
                      </div>
                      <img
                        src={
                          process.env.PUBLIC_URL +
                          "/Images/adminProfile/arrow-down-s-line.svg"
                        }
                        alt="rightArrow"
                      />
                    </div>
                  </Grid>
                </div>
              </Card>
            ))
        : scheduleData.map((result) => (
            <Card className="adminCardAlign" key={result.id}>
              <div className="adminCard">
                <Grid
                  container
                  direction="row"
                  justifyContent="space-between"
                  alignItems="center"
                >
                  <div className="adminCardTitle">{result.title} </div>
                  <div className="AdminCardDaysTogo">
                    <DatePicker
                      // label={<CommonLabel label="Expiry date" />}
                      selectedDate={result.publishDate}
                      // handleDateChange={(date) =>
                      //   handleDateChange("expiryDate", date)
                      // }
                      // name="expiryDate"
                      disabled
                    />
                  </div>
                </Grid>
                <Grid
                  container
                  direction="row"
                  justifyContent="space-between"
                  alignItems="center"
                >
                  <div className="adminCardSubTitle">{result.subTitle}</div>
                </Grid>
                <Grid
                  container
                  direction="row"
                  justifyContent="space-between"
                  alignItems="center"
                >
                  <div className="adminCardProfileName">
                    {result?.isAuthorPost == true ? (
                      <>
                        <img
                          src={result.empPhotoUrl}
                          className="profileWidth"
                          alt="Admin profile"
                        />
                        <div>{result.name}</div>
                      </>
                    ) : (
                      <>
                        <Avatar></Avatar>
                        <div>{result?.submittedFor}</div>
                      </>
                    )}
                  </div>
                  <div className="adminCardView">
                    <div
                      onClick={() => {
                        isOfferCampaignsView
                          ? history.push({
                              pathname: `${LIVEWIRE}${OC_REVIEW_ARTICLE}`,
                              state: {
                                details: result,
                              },
                            })
                          : history.push({
                              pathname: `${LIVEWIRE}/view-article/${result?.id}`,
                              state: {
                                details: result,
                                scheduleDataFlag: true,
                              },
                            });
                      }}
                    >
                      view
                    </div>
                    <img
                      src={
                        process.env.PUBLIC_URL +
                        "/Images/adminProfile/arrow-down-s-line.svg"
                      }
                      alt="rightArrow"
                    />
                  </div>
                </Grid>
              </div>
            </Card>
          ))}
    </>
  );
}
