import React, { useState, useEffect } from "react";
import "./TabsPanel.css";
import {
  Paper,
  Grid,
  // Menu,
  // MenuItem,
  Popper,
  ClickAwayListener,
} from "@material-ui/core";
import menu_vertical from "../../../../Assets/Images/menu_vertical.svg";
import plusDisabled from "../../../../Assets/Images/plus-disabled.svg";
// import fav_myprofile from "../../../../../public/myWork/fav_myprofile.svg";
import { getFavouriteData } from "../../../../Redux/Actions/DashboardAction";
import Swal from "sweetalert2";
import { EMERGENCY_CONTACTS } from "../../../../Routes/constant";
import { useHistory } from "react-router-dom";
import services from "../../../../Services";
import { useDispatch } from "react-redux";
import { addAnalayticsLog } from "../../../../Redux/Actions/Anaylatics";
import platform from "platform";
function TabsPanel({ data, value, index, handleAdd, title }) {
  const dispatch = useDispatch();
  const history = useHistory();
  const [anchorEl, setAnchorEl] = useState(null);
  const [activeMyWork, setActiveMyWork] = useState(null);
  const [myWorkData, setMyWorkData] = useState(data);
  const [dragId, setDragId] = useState();
  const [hoverImages, setHoverImages] = useState({ show: false, index: null });
  const [favCallAddMoreHover, setFavCallAddMore] = useState({
    emergencyCall: false,
    addMore: false,
    Ethics: false,
  });
  const [deleteTitle, setDeleteTile] = useState("");

  const [counter, setCounter] = useState({
    count: data?.length,
  });

  const handleAnchorEl = (event, id, titleForDelete) => {
    setDeleteTile(titleForDelete);
    event.stopPropagation();
    event.preventDefault();
    setAnchorEl(anchorEl ? null : event.currentTarget);
    // setAnchorEl(event.currentTarget);
    setActiveMyWork(id);
  };

  const handleDelete = () => {
    console.log("deleted title ===>", deleteTitle + "123");
    Swal.fire({
      title: "Are you sure?",
      text: "You want to delete it!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Yes, delete it!",
      cancelButtonText: "No, keep it",
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          let deleteTiles = await services.api(
            "POST",
            `/ui-assist/v1/favourite/${localStorage.getItem("token")}/tileId` +
              "?tileInfoId=" +
              `${
                deleteTitle.includes("&")
                  ? deleteTitle.substring(0, deleteTitle.indexOf("&")) +
                    "%26" +
                    deleteTitle.substring(deleteTitle.indexOf("&") + 1)
                  : deleteTitle
              }` +
              "&st"
          );
          if (deleteTiles.status === 200) {
            Swal.fire(
              "Successfully deleted!",
              "Your tile has been updated.",
              "success"
            );
            dispatch(getFavouriteData());
            setAnchorEl(false);
          }
        } catch (err) {
          console.log("err for delete titles", err.response);
        }
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire("Cancelled", "Your tiles are safe :)", "error");
        setAnchorEl(false);
      }
    });
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  const handleDrag = (ev) => {
    setDragId(ev.currentTarget.id);
  };

  const handleDrop = async (ev, index) => {
    // console.log("index=======>", index);
    // const dragBox = myWorkData.find((box) => box._id == dragId);
    // const dropBox = myWorkData.find(
    //   (box) => box._id == ev.currentTarget.id
    // );
    // console.log("dragBox:", dragBox, "dropBox:", dropBox);

    // const dragBoxOrder = dragBox.order;
    // const dropBoxOrder = dropBox.order;

    const dragBoxOrder = myWorkData.findIndex(
      (result) => result._id === dragId
    );

    const dropBoxOrder = myWorkData.findIndex(
      (result) => result._id === ev.currentTarget.id
    );

    // const newBoxState = myWorkData.map((box) => {
    //   if (box.id == dragId) {
    //     box.order = dropBoxOrder;
    //   } else if (box.id == ev.currentTarget.id) {
    //     box.order = dragBoxOrder;
    //   }

    //   return box;
    // });

    const newBoxState = [...myWorkData];
    let b = newBoxState[dragBoxOrder];

    newBoxState[dragBoxOrder] = newBoxState[dropBoxOrder];
    newBoxState[dropBoxOrder] = b;
    // setMyWorkData(newBoxState);
    let getTitle = title === "myWork" ? "mywork" : "manageteam";
    try {
      let res = await services.api(
        "PUT",
        `/ui-assist/v1/favourite/${localStorage.getItem("token")}/${getTitle}`,
        newBoxState
      );
      if (res.status === 200) {
        dispatch(getFavouriteData());
      }
    } catch (err) {
      console.log("err from drag and drop", err.response);
    }
  };

  const handleMouseEnter = (index) => {
    setHoverImages({ show: true, index });
  };

  const handleMouseLeave = () => {
    setHoverImages({ show: false, index: null });
  };

  const handleCallAddMoreHoverMouseEnter = (name) => {
    if (name === "addMore") {
      setFavCallAddMore({ addMore: true });
    } else {
      setFavCallAddMore({ emergencyCall: true });
    }
  };
  const handleCallAddMoreHoverMouseLeave = (name) => {
    if (name === "addMore") {
      setFavCallAddMore({ addMore: false });
    } else {
      setFavCallAddMore({ emergencyCall: false });
    }
  };

  const handleClickAway = () => {
    setAnchorEl(false);
  };
  const handleFavLinks = (link, result) => {
    dispatch(
      addAnalayticsLog({
        module: `Favourites/${result._id}`,

        browserDetails: `${platform.name} ${platform.os.architecture} ${platform.os.family} ${platform.os.version}`,

        deviceDetails: platform.product ? platform.product : "Desktop",

        isSearch: false,
      })
    );
    if (link) window.open(link, "_blank");
  };

  const handleAddMore = (length) => {
    if (length < 5) {
      handleAdd(length);
    }
  };

  useEffect(() => {
    setMyWorkData(data);
  }, [data]);

  const handleEmergencyCall = () => {
    // window.open("https://www.mahindrarise.com/hr_desk", "_blank");
    history.push({
      pathname: `${EMERGENCY_CONTACTS}`,
    });
  };

  const handleEthicsHelpline = () => {
    window.open("https://ethics.mahindra.com/", "_blank");
  };

  const handleIcon = (iconName, defaultIcon) => {
    if (iconName) {
      return hoverImages.show && hoverImages.index === index
        ? process.env.PUBLIC_URL + `/myWork/${iconName}.svg`
        : process.env.PUBLIC_URL + `/myWork/${iconName}.svg`;
    } else {
      return process.env.PUBLIC_URL + `/myWork/${defaultIcon}.svg`;
    }
  };

  return (
    <div className="alignFavBoxes">
      {value === index && (
        <div>
          <Grid container spacing={2}>
            {myWorkData?.length > 0 &&
              myWorkData
                .slice(0, 5)
                // .sort((a, b) => a.order - b.order)
                .map((result, index) => (
                  <Grid
                    item
                    xs={12}
                    md={6}
                    id={result?._id}
                    key={result?._id}
                    className="cursorMove"
                    onDragOver={(ev) => ev.preventDefault()}
                    onDrop={(e) => handleDrop(e, index)}
                  >
                    <Paper
                      key={index}
                      className="tabsPanelContainer favtab"
                      onMouseEnter={() => handleMouseEnter(index)}
                      onMouseLeave={() => handleMouseLeave()}
                    >
                      <div className="favBoxIconAlign favBoxSpace">
                        <span
                          id={result?._id}
                          draggable={true}
                          onDragStart={handleDrag}
                        >
                          <img
                            src={
                              process.env.PUBLIC_URL +
                              "/iconImages/dragDrop.svg"
                            }
                            className="alignDragIcon cursorDragDrop"
                            alt="dragDrop"
                            onClick={(ev) => ev.stopPropagation()}
                          />
                        </span>
                        <img
                          src={handleIcon(result?.icon_class)}
                          className="iconImage favIconPL"
                          alt="Icon_iamge"
                          onClick={() => handleFavLinks(result?.url, result)}
                        />
                        <span
                          className="favBox"
                          title={result?._id}
                          onClick={() => handleFavLinks(result?.url, result)}
                        >
                          {result?._id}
                        </span>
                      </div>
                      <img
                        src={menu_vertical}
                        alt="menu vertical"
                        onClick={(event) =>
                          handleAnchorEl(event, index, result?._id + "")
                        }
                        className="verticalDots"
                      />
                    </Paper>
                    <Popper
                      className="popperBlock"
                      open={Boolean(anchorEl)}
                      anchorEl={anchorEl}
                    >
                      <ClickAwayListener
                      // onClickAway={handleClickAway}
                      >
                        <Paper
                        // style={{
                        //   width: "80px",
                        //   height: "32",
                        //   position: "relative",
                        //   right: "-4px",
                        //   top: "10px",
                        //   boxShadow: "none",
                        // }}
                        >
                          <p
                            onClick={() => handleDelete()}
                            className="favMenuItems commonFontNormalStyle"
                          >
                            Delete
                          </p>
                          {/* <p
                          onClick={handleCloseMenu}
                          className="favMenuItems commonFontNormalStyle"
                        >
                          Replace
                        </p> */}
                        </Paper>
                      </ClickAwayListener>
                    </Popper>
                  </Grid>
                ))}

            <Grid
              item
              md={6}
              xs={12}
              className="cursorMove"
              onClick={handleEthicsHelpline}
            >
              <Paper
                className="tabsPanelContainer"
                onMouseEnter={() => handleCallAddMoreHoverMouseEnter()}
                onMouseLeave={() => handleCallAddMoreHoverMouseLeave()}
              >
                <div className="favBoxIconAlign">
                  {/* <img
                   src={process.env.PUBLIC_URL + "/iconImages/dragDrop.svg"}
                   className="alignDragIcon cursorDragDrop"
                   alt="dragDrop Image"
                 /> */}
                  <img
                    src={
                      favCallAddMoreHover.emergencyCall
                        ? process.env.PUBLIC_URL +
                          "/myWork/fav_ethicshelpline_hover.svg"
                        : process.env.PUBLIC_URL +
                          "/myWork/fav_ethicshelpline.svg"
                    }
                    className="iconImage"
                    alt="Icon_iamge"
                  />

                  <span className="favBox">24X7 Speak up ethics Helpline</span>
                </div>
              </Paper>
            </Grid>
            <Grid
              item
              md={6}
              xs={12}
              className="cursorMove"
              onClick={handleEmergencyCall}
            >
              <Paper
                className="tabsPanelContainer"
                onMouseEnter={() => handleCallAddMoreHoverMouseEnter()}
                onMouseLeave={() => handleCallAddMoreHoverMouseLeave()}
              >
                <div className="favBoxIconAlign">
                  {/* <img
                   src={process.env.PUBLIC_URL + "/iconImages/dragDrop.svg"}
                   className="alignDragIcon cursorDragDrop"
                   alt="dragDrop Image"
                 /> */}
                  <img
                    src={
                      favCallAddMoreHover.emergencyCall
                        ? process.env.PUBLIC_URL + "/iconImages/phone-fill.svg"
                        : process.env.PUBLIC_URL + "/iconImages/phone.svg"
                    }
                    className="iconImage"
                    alt="Icon_iamge"
                  />
                  <span className="favBox">Emergency contacts</span>
                </div>
              </Paper>
            </Grid>

            <Grid
              item
              md={6}
              xs={12}
              onClick={() => handleAddMore(myWorkData.length)}
              className="cursorMove"
            >
              <Paper
                className={
                  myWorkData?.length >= 5
                    ? "favBoxIconAlignMoreThan6 borderAddMore"
                    : "tabsPanelContainer borderAddMore"
                }
                style={{ display: "flex", justifyContent: "center" }}
                onMouseEnter={() => handleCallAddMoreHoverMouseEnter("addMore")}
                onMouseLeave={() => handleCallAddMoreHoverMouseLeave("addMore")}
              >
                <div className="favBoxIconAlign">
                  <img
                    src={
                      myWorkData?.length >= 5
                        ? plusDisabled
                        : favCallAddMoreHover.addMore
                        ? process.env.PUBLIC_URL + "iconImages/add-box-fill.svg"
                        : process.env.PUBLIC_URL + "iconImages/plus-square.svg"
                    }
                    className="iconImage"
                    alt="Icon_iamge"
                  />
                  <span
                    className={
                      myWorkData?.length >= 5
                        ? "moreThan6"
                        : "favBox textTransform"
                    }
                  >
                    {"Add More"}
                  </span>
                </div>
              </Paper>
            </Grid>
          </Grid>

          <Grid container spacing={2} style={{ paddingTop: "8px" }}></Grid>
          {/* <MenuComponent
            anchorEl={anchorEl}
            handleCloseMenu={handleCloseMenu}
            handleDelete={handleDelete}
          /> */}
        </div>
      )}
    </div>
  );
}
export default TabsPanel;

// const MenuComponent = ({ anchorEl, handleCloseMenu, handleDelete }) => {
//   return (
//     <Menu
//       id="simple-menu"
//       anchorEl={anchorEl}
//       keepMounted
//       open={Boolean(anchorEl)}
//       onClose={handleCloseMenu}
//       style={{ marginTop: "3em" }}
//     >
//       <MenuItem
//         onClick={handleDelete}
//         className="commonFontNormalStyle favSectionMenuOptions"
//       >
//         Delete
//       </MenuItem>
//       <MenuItem
//         onClick={handleCloseMenu}
//         className="commonFontNormalStyle favSectionMenuOptions"
//       >
//         Replace
//       </MenuItem>
//     </Menu>
//   );
// };
