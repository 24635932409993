import services from "../../Services/index";
import Swal from "sweetalert2";
import {
    GET_SEND_MAIL_DATA_SUCCESS,
    GET_SEND_MAIL_DATA_LOAD,
    GET_SEND_MAIL_DATA_FAILED,
} from "../Contants/Constants";

const getSendMailLoad = () => ({
    type: GET_SEND_MAIL_DATA_LOAD,
});
const getSendMailSuccess = (data) => ({
    type: GET_SEND_MAIL_DATA_SUCCESS,
    payload: data,
});
const getSendMailFailed = (error) => ({
    type: GET_SEND_MAIL_DATA_FAILED,
    err: error,
});

export const getSendMailData = (donorList) => async (dispatch) => {
  dispatch(getSendMailLoad());
  try {
    const res = await services.api(
      "POST",
      `/ui-assist/v1/sendemail`,donorList
    );
    if (res) {
      dispatch(getSendMailSuccess(res.data));
    }
    if(res.status === 200)
    {
      Swal.fire(
              "Request Sent Successfully!",
              " ",
              "success"
            );
    }
  } catch (err) {
    dispatch(getSendMailFailed(err.response));
    Swal.fire("Something went wrong!", `${err?.response?.data}`, "error");
  }
};