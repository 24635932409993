import React, { useState, useEffect } from "react";
import { Grid, setRef } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import CardMedia from "@material-ui/core/CardMedia";
import CardContent from "@material-ui/core/CardContent";
import CardActions from "@material-ui/core/CardActions";
import Typography from "@material-ui/core/Typography";
import Swal from "sweetalert2";
import CommonCheckBox from "../../../Common/CommonCheckBox/CommonCheckBox";
import { getSendMailData } from "../../../../Redux/Actions/SendMailAction";
import { useDispatch, useSelector } from "react-redux";
import { AspectRatio } from "react-aspect-ratio";

const useStyles = makeStyles({
  root: {
    maxWidth: "100%",
    boxShadow: "0 6px 35px 0 rgb(0 0 0 / 10%) !important",
    height: "100%",
  },
  media: {},
});

export default function BloodBankCard({
  data,
  sendAllClick,
  checked,
  AddDonorsList,
  DeleteDonorsList,
}) {
  const dispatch = useDispatch();
  const sendMail = useSelector((state) => state.getSendMailReducer);

  const classes = useStyles();

  const [check, setCheck] = useState(false);
  const [selectDonor, setSelectDonor] = useState([]);
  const [sendRequestCheck, setSendRequestCheck] = useState(false);

  useEffect(() => {
    setCheck(checked);
    if (checked && !sendRequestCheck) {
      // AddDonorsList(data);
      AddDonorList(data);
    } else {
      // DeleteDonorsList(data.employeeId);
      DeleteDonorList(data.employeeId);
    }
  }, [checked]);
  useEffect(() => {
    selectDonor?.length > 0 && sendAllClick && setSendRequestCheck(true);
  }, [sendAllClick]);

  const sendRequest = () => {
    if (check) {
      setCheck(false);
      DeleteDonorsList(data.employeeId);
      DeleteDonorList(data.employeeId);
    } else {
      setCheck(true);
      AddDonorList(data);
      !sendRequestCheck &&  AddDonorsList(data);
    }
  };

  const Send = () => {
    if (selectDonor?.length > 0) {
      dispatch(getSendMailData(selectDonor));
      setSendRequestCheck(true);
      DeleteDonorsList(data.employeeId);
    } else {
      Swal.fire("Please select donor to send request.", " ", "error");
    }
  };

  // let selectDonor = [];

  const AddDonorList = (data) => {
    setSelectDonor([data]);
  };
  const DeleteDonorList = (data) => {
    let length = selectDonor.length;
    let a = length;
    selectDonor.map((val) => {
      const index = length - a;
      a = a - 1;
      if (val.employeeId == data) {
        setSelectDonor([]);
      }
    });
  };

  console.log("selected list =====================> ", selectDonor);
  console.log(" selectDonor.length===========> ", selectDonor.length);

  return (
    <Card className={classes.root}>
      <CardMedia className={classes.media} title="">
        <div
          className="card-media-1"
          style={{ position: "relative", display: "flex" }}
        >
          <div className="checkBg">
            <CommonCheckBox
              checked={check}
              name=""
              handleChange={sendRequest}
            />
          </div>
          <AspectRatio ratio="3/4" style={{ maxWidth: "120px" }}>
            <img
              src={data.bloodDonarPhotoUrl}
              alt="Card Logo"
              className="McCardImage"
            />
          </AspectRatio>
        </div>
      </CardMedia>
      <CardContent>
        <Typography gutterBottom variant="h5" component="h2">
          <span style={styles.name}>{data.bloodDonarName}</span>
        </Typography>
        <Typography variant="body2" color="textSecondary" component="p">
          <span style={styles.designation}>{data.designation}</span>
        </Typography>
      </CardContent>
      <CardActions disableSpacing>
        <Grid item xs="auto" className="mb-3">
          {sendMail.status == "success" &&
          sendRequestCheck ? (
            <div className="requestBloodTxt">REQUEST SENT SUCCESSFULLY.</div>
          ) : (
            <div className="requestBloodTxt" onClick={Send}>
              SEND REQUEST
            </div>
          )}
        </Grid>
      </CardActions>
    </Card>
  );
}

const styles = {
  name: {
    fontSize: "16px",
    
    fontWeight: "600",
    lineHeight: "1.5",
    color: "black",
  },
  designation: {
    fontSize: "12px",
    
    fontWeight: "500",
    lineHeight: "1.67",
    color: "black",
  },
};
