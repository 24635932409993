import {
    GET_ALL_CONTACT_LOCATION_SUCCESS,
    GET_ALL_CONTACT_LOCATION_LOAD,
    GET_ALL_CONTACT_LOCATION_FAILED,
    PUT_EDIT_CONTACT_LOCATION_SUCCESS,
    PUT_EDIT_CONTACT_LOCATION_LOAD,
    PUT_EDIT_CONTACT_LOCATION_FAILED,
    DELETE_CONTACT_LOCATION_SUCCESS,
    DELETE_CONTACT_LOCATION_LOAD,
    DELETE_CONTACT_LOCATION_FAILED,
    ADD_CONTACT_LOCATION_SUCCESS,
    ADD_CONTACT_LOCATION_LOAD,
    ADD_CONTACT_LOCATION_FAILED,
} from "../Contants/Constants";

const initialState = {
  getAllData: [],
  editedData: {},
  addData: {},
  deleteData: "",
  isLoading: false,
  isError: "",
  status: "idle",
};

const getAllContactLocationReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_ALL_CONTACT_LOCATION_LOAD:
      return {
        ...state,
        isLoading: true,
        status: "pending",
      };
    case GET_ALL_CONTACT_LOCATION_SUCCESS:
      return {
        ...state,
        getAllData: action.payload,
        isLoading: false,
        status: "success",
      };
    case GET_ALL_CONTACT_LOCATION_FAILED:
      return {
        ...state,
        isError: action.payload,
        isLoading: false,
        status: "failed",
      };

      
    case PUT_EDIT_CONTACT_LOCATION_LOAD:
      return {
        ...state,
        isLoading: true,
        status: "pending",
      };
    case PUT_EDIT_CONTACT_LOCATION_SUCCESS:
      return {
        ...state,
        editedData: action.payload,
        isLoading: false,
        status: "success",
      };
    case PUT_EDIT_CONTACT_LOCATION_FAILED:
      return {
        ...state,
        isError: action.payload,
        isLoading: false,
        status: "failed",
      };

    
    case ADD_CONTACT_LOCATION_LOAD:
      return {
        ...state,
        isLoading: true,
        status: "pending",
      };
    case ADD_CONTACT_LOCATION_SUCCESS:
      return {
        ...state,
        addData: action.payload,
        isLoading: false,
        status: "success",
      };
    case ADD_CONTACT_LOCATION_FAILED:
      return {
        ...state,
        isError: action.payload,
        isLoading: false,
        status: "failed",
      };

      
    case DELETE_CONTACT_LOCATION_LOAD:
      return {
        ...state,
        isLoading: true,
        status: "pending",
      };
    case DELETE_CONTACT_LOCATION_SUCCESS:
      return {
        ...state,
        deleteData: action.payload,
        isLoading: false,
        status: "success",
      };
    case DELETE_CONTACT_LOCATION_FAILED:
      return {
        ...state,
        isError: action.payload,
        isLoading: false,
        status: "failed",
      };
    default:
      return state;
  }
};

export default getAllContactLocationReducer;
