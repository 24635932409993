import React, { useState, useEffect } from "react";
import "./RejectArticleComponent.css";
import { Grid } from "@material-ui/core";
import TextArea from "../../../../Common/TextArea/TextArea";
export default function RejectArticleComponent({ getRejectionNote }) {
  const [noteToRejectStory, setNoteToRejectStory] = useState("");
  const handleChangeTextArea = (e) => {
    const { value } = e.target;
    setNoteToRejectStory(value);
    getRejectionNote(value);
  };

  return (
    <div>
      <div className="publishArticleTitle">
        Leave a rejection note for the author
      </div>
      <div className="rejectContentInputs">
        <Grid container>
          <Grid item md={12} xs={12} className="rejectArticleTxtArea">
            <TextArea
              placeholder="Start writing here..."
              handleChangeTextArea={handleChangeTextArea}
              value={noteToRejectStory}
            />
          </Grid>
        </Grid>
      </div>
    </div>
  );
}
