import React from "react";

function NotFound(props) {
  return (
    <div
      style={{
        textAlign: "center",
        marginTop: "68px",
        padding: "114px 0px",
      }}
    >
      <div>
        <h1>404, Page Not Found</h1>
      </div>
      <div>
        <p>
          No such page found,Request you to check the URL which you have
          entered.
        </p>
      </div>
    </div>
  );
}

export default NotFound;
