import React, { useEffect } from "react";
import "./Sharefiles.css";
import mailBoxIcon from "../../../Assets/Images/collectiveIcons/mail-line.svg";
import yammerIcon from "../../../Assets/Images/collectiveIcons/Yammer.jpg";
import { useDispatch, useSelector } from "react-redux";
import { shareArticle } from "../../../Redux/Actions/CommonActions";

export default function Sharefiles({ id, pathURL, contentType }) {

  
    const dispatch = useDispatch();

  useEffect(() => {
    const script = document.createElement("script");

    script.src = "https://teams.microsoft.com/share/launcher.js";
    script.async = true;
    script.defer = true;

    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, []);
  useEffect(() => {
    const script = document.createElement("script");

    script.src =
      "https://s0.assets-yammer.com/assets/platform_social_buttons.min.js";
    script.async = true;
    script.defer = true;

    document.head.appendChild(script);

    return () => {
      document.head.removeChild(script);
    };
  }, []);
  useEffect(() => {
    const script = document.createElement("script");

    script.src = "/YammerJs/yammer.js";
    script.async = true;
    script.defer = true;

    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, []);

  const Share = (app) => {
    console.log("app ======>", app);
    dispatch(shareArticle(contentType,id,app));
  };

  return (
    <>
      <div className="shareMenuItems commonFontNormalStyle">
        <div className="popperInnerLinksContainer" onClick={(e) => Share("Teams")}>
          {/* <img src={teamsIcon} alt="teams icon" /> */}

          {/* <div className="popperInnerTextMl">Microsoft Teams</div> */}
          <div
            className="teams-share-button teamImg"
            data-href={contentType == "OrgAnnouncement" ?
                        `${process.env.REACT_APP_API_BASE_URL}/ui-assist/v1/download/${pathURL}`
                        :
                        contentType == "MahindraExperience" 
                        ?
                        `${process.env.REACT_APP_API_BASE_URL}${pathURL}`
                        :
                        `${process.env.REACT_APP_API_BASE_URL}${pathURL}?id=${id}`
                      }
          ></div>
          <span className="mlTeam"> Microsoft Team</span>
        </div>
      </div>

      <div className="shareMenuItems commonFontNormalStyle" onClick={(e) => Share("Email")}>
        <a
          href={contentType == "OrgAnnouncement" ?
                  `mailto:?body=${process.env.REACT_APP_API_BASE_URL}/ui-assist/v1/download/${pathURL}`
                  :
                  contentType == "MahindraExperience" 
                  ?
                  `mailto:?body=${process.env.REACT_APP_API_BASE_URL}${pathURL}`
                  :
                  `mailto:?body=${process.env.REACT_APP_API_BASE_URL}${pathURL}?id=${id}`
                }
          className="popperInnerLinksContainer mlMail"
        >
          <img src={mailBoxIcon} alt="Email icon" />
          <div className="popperInnerTextMl">Email address</div>
        </a>
      </div>

      {/* <div
                className="shareMenuItems commonFontNormalStyle yammer"
            >
                <a href = 'https://www.yammer.com/mahindra.com/' className="popperInnerLinksContainer"> 
                <img src={yammerIcon} alt="yammer icon" /> 
                    </a>
                 <div className="popperInnerTextMl">Microsoft Yammer</div> 
            </div> */}
    </>
  );
}
