import services from "../../Services/index";
import {
    GET_COMMENTS_DATA_SUCCESS,
    GET_COMMENTS_DATA_LOAD,
    GET_COMMENTS_DATA_FAILED,
} from "../Contants/Constants";
const getCommentsLoad = () => ({
  type: GET_COMMENTS_DATA_LOAD,
});
const getCommentsSuccess = (data) => ({
  type: GET_COMMENTS_DATA_SUCCESS,
  payload: data,
});
const getCommentsFailed = (error) => ({
  type: GET_COMMENTS_DATA_FAILED,
  err: error,
});
export const getCommentsData = (id) => async (dispatch) => {
  dispatch(getCommentsLoad());
  try {
    if(id){
      const res = await services.api(
        "GET",
        `/ui-assist/v1/comment/${id}`,
        {}
      );
      if (res) {
        
        const sortedResults = res.data.sort((a, b) => new Date(a.createdAt).getTime() - new Date(b.createdAt).getTime())
       
        dispatch(getCommentsSuccess(sortedResults.reverse()));
  
      }
    }
    
  } catch (err) {
    dispatch(getCommentsFailed(err.response));
  }
};
