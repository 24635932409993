import React, { useState } from "react";
import "../MicroFeedback/MicroFeedback.css";
import PropTypes from "prop-types";
import Rating from "@material-ui/lab/Rating";
import Close from "../../Assets/Images/cross.svg";
import { Typography, TextareaAutosize, Box } from "@material-ui/core";
import { useDispatch } from "react-redux";
import { RBoolean } from "../../Redux/Actions/MicrofeedbackAction";
import { useLocation } from "react-router-dom";

const getLocationForPage = (location) => {
  const queryParams = new URLSearchParams(window.location.search);
  const id = queryParams.get("id");
  //console.log(location, 'location@@')
  
  if (location === "/") {
    return "Dashboard";
  } else {
    let splitLocation = location.split("/");
    let getSplitedLocation = splitLocation.pop();
    if (getSplitedLocation.includes("?")) {
      return "policies details";
    } else {
      return getSplitedLocation;
    }
  }
};

export default function FeedbackContent({
  isXs,
  handleFeedBackApi,
  apiFeedbackError,
}) {
  const [contentBool, setcontentBool] = useState(false);
  const [badEmoji, setBadEmoji] = useState(false);
  const [neutralEmoji, setneutralEmoji] = useState(false);
  const [happyEmoji, sethappyEmoji] = useState(false);
  const [feedShare, setfeedShare] = useState(false);
  const [colorChanger, setcolorChanger] = useState(true);
  const [badButton, setbadButton] = useState(false);
  const [value, setValue] = React.useState(2);
  const [star] = React.useState(true);
  const [feedbackText, setFeedbackText] = useState("");
  const location = useLocation().pathname;
  // const myState = useSelector((state) => state.changeBoolean);
  const dispatch = useDispatch();

  const page = getLocationForPage(location);

  const openBemoji = () => {
    setBadEmoji("Bad");
    setbadButton(true);
    setneutralEmoji(false);
    sethappyEmoji(false);
    setfeedShare(true);
  };
  const openNemoji = () => {
    setBadEmoji(false);
    setneutralEmoji("Neutral");
    sethappyEmoji(false);
    setfeedShare(true);
  };
  const openEemoji = () => {
    setBadEmoji(false);
    setneutralEmoji(false);
    sethappyEmoji("Excellent");
    setfeedShare(true);
  };

  const colorReverse = () => {
    setcolorChanger(false);
  };

  const ThanksPage = () => {
    setcontentBool(true);
  };

  const handleFeedbackText = (e) => {
    setFeedbackText(e.target.value);
  };

  const checkFeedbackRating = neutralEmoji
    ? neutralEmoji
    : badEmoji
    ? badEmoji
    : happyEmoji
    ? happyEmoji
    : null;

  const handleSubmitFeedback = () => {
    const data = {
      employeeId: localStorage.getItem("token"),
      employeeName: localStorage.getItem("fullName"),
      page: page,
      rating: checkFeedbackRating,
      comment: feedbackText,
      contentName: location
      // section: "App",
      // experience: checkFeedbackRating,
      // comment: feedbackText,
      // feedbackBy: localStorage.getItem("token"),
    };
    handleFeedBackApi(data);
  };

  const showFeedbackSuccessFail = () => {
    if (apiFeedbackError) {
      return (
        // <>
        //   <span style={{ textAlign: "center" }} className="">Opps something went wrong</span>
        // </>
        <>
          Thank <span className="You">you</span>!
        </>
      );
    } else {
      return (
        <>
          Thank <span className="You">you</span>!
        </>
      );
    }
  };

  return (
    <>
      {contentBool ? (
        <>
          <div className="thanksDiv">
            <p className="fontSaira thankYou">{showFeedbackSuccessFail()}</p>
            {/* {!apiFeedbackError && (
              <p className="fontSaira thanksContained" variant="subtitle2">
                We <span className="You">appreciated your feedback</span>
              </p>
            )} */}
            <p className="fontSaira thanksContained" variant="subtitle2">
              We <span className="You">appreciated your feedback</span>
            </p>
            <div
              className="feedbackDismiss feedbackButton fontSaira"
              variant="contained"
              onClick={() => dispatch(RBoolean())}
            >
              <span className="dismissFeedback">DISMISS</span>
            </div>
          </div>
        </>
      ) : (
        <>
          {/* <Icon className="closeIcon" onClick={handleClose}>
                        close
                    </Icon> */}
          <img
            style={{ marginRight: "16px" }}
            src={Close}
            alt="star"
            className="closeIcon"
            onClick={() => dispatch(RBoolean())}
          />
          <Typography
            variant="subtitle2"
            className="feedback-Tasks animationFeedbacktext"
            component="h2"
          >
            {neutralEmoji ? (
              <span className="gotItText animationFeedbacktext">
                Got it! Is there something we can <br /> do to improve your
                experience?
              </span>
            ) : badEmoji ? (
              <span className="gotItText animationFeedbacktext">
                We're sorry you're having a bad <br />
                experience. What can we do to improve?
              </span>
            ) : happyEmoji ? (
              <span className="gotItText animationFeedbacktext">
                Glad you like the experience. Tell <br /> us what you love most?
              </span>
            ) : (
              <span className="gotItText animationFeedbacktext">
                How has your experience <br /> been so far?
              </span>
            )}
          </Typography>
          {feedShare ? (
            <>
              <div className="fieldEmojiBox">
                <TextareaAutosize
                  className={
                    badButton ? "fontSaira shareFormBad" : "fontSaira shareForm"
                  }
                  aria-label="minimum height"
                  minRows={3}
                  onChange={handleFeedbackText}
                  placeholder="Share your feedback (optional)"
                  maxLength={500}
                  value={feedbackText}
                />
                <div className="fieldEmojiContainer">
                  {neutralEmoji ? (
                    <div className="emojiLogo">
                      <img
                        className="logoSize animationFeedbacktextdown"
                        src={process.env.PUBLIC_URL + "/logo/neutral.png"}
                        alt="header logo"
                      />
                      <span className="emojiSpan  ">Neutral</span>
                    </div>
                  ) : badEmoji ? (
                    <div className={isXs ? "emojiLogoBad" : "emojiLogo"}>
                      <img
                        className="logoSize animationFeedbacktextdown"
                        src={process.env.PUBLIC_URL + "/logo/unhappy.png"}
                        alt="header logo"
                      />
                      <span className="emojiSpan  ">Bad</span>
                    </div>
                  ) : (
                    <div className="emojiLogo">
                      <img
                        className="logoSize animationFeedbacktextdown"
                        src={process.env.PUBLIC_URL + "/logo/happy.png"}
                        alt="header logo"
                      />
                      <span className="emojiSpan  ">Excellent</span>
                    </div>
                  )}
                </div>
                <div
                  className={
                    colorChanger
                      ? `indicator1 indicator1Color animationFeedbacktextindicator2`
                      : `indicator1 indicator2Color animationFeedbacktextindicator2`
                  }
                ></div>
                <div
                  className={
                    colorChanger
                      ? `indicator2 indicator2Color animationFeedbacktextindicator2`
                      : `indicator2 indicator1Color animationFeedbacktextindicator2`
                  }
                ></div>
                <div onClick={ThanksPage}>
                  <div
                    className={
                      badButton && isXs
                        ? "feedbackBadSubmit feedbackButton fontSaira"
                        : "feedbackSubmit feedbackButton fontSaira"
                    }
                    variant="contained"
                    onClick={handleSubmitFeedback}
                  >
                    <span className="submitFix">SUBMIT</span>
                  </div>
                </div>
              </div>
            </>
          ) : (
            <>
              <div>
                {star ? (
                  <div className="emojiContainer" onClick={colorReverse}>
                    <div><img
                      src={process.env.PUBLIC_URL + "/logo/happy.png"}
                      className="logo animationFeedbackimg1"
                      alt="header logo"
                      onClick={openEemoji}
                    />
                    <div className="emojiTitleDiv animationFeedbackimg1 ExcellentDiv">
                    Excellent
                  </div>
                  </div>
                  
                  <div>
                    <img
                      src={process.env.PUBLIC_URL + "/logo/neutral.png"}
                      className="logo animationFeedbackimg2"
                      alt="header logo"
                      onClick={openNemoji}
                    />
                    
                  <div className="emojiTitleDiv animationFeedbackimg2 neutralText">
                    Neutral
                  </div>
                  </div>
                  <div><img
                      src={process.env.PUBLIC_URL + "/logo/unhappy.png"}
                      className="logo animationFeedbackimg3"
                      alt="header logo"
                      onClick={openBemoji}
                    />
                    <div className="emojiTitleDiv animationFeedbackimg3 badtext">Bad</div>
                    </div>
                  </div>
                ) : (
                  <div className="emojiContainerRating" onClick={colorReverse}>
                    <Box component="fieldset" mb={3} borderColor="transparent">
                      <Typography component="legend">{value}</Typography>
                      <Rating
                        name="simple-controlled"
                        value={value}
                        onChange={(event, newValue) => {
                          setValue(newValue);
                        }}
                      />
                    </Box>
                  </div>
                )}
              </div>
              {/* <div>
                <div className="emojiTitle">
                
                </div>
              </div> */}
              <div
                className={
                  colorChanger
                    ? `indicator1 indicator1Color animationFeedbacktextindicator`
                    : `indicator1 indicator2Color animationFeedbacktextindicator`
                }
              ></div>
              <div
                className={
                  colorChanger
                    ? `indicator2 indicator2Color animationFeedbacktextindicator`
                    : `indicator2 indicator1Color animationFeedbacktextindicator`
                }
              ></div>
            </>
          )}
        </>
      )}
    </>
  );
}

FeedbackContent.propTypes = {
  handleClose: PropTypes.func,
  isXs: PropTypes.bool,
};

FeedbackContent.defaultProps = {
  handleClose: () => {},
  isXs: false,
};
