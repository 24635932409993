import React, { useState, useEffect } from "react";
import { Grid } from "@material-ui/core";
import uploadIcon from "../../../Assets/Images/collectiveIcons/upload-2-line.svg";
import { useDispatch, useSelector } from "react-redux";
import { getUploadPDFData } from "../../../Redux/Actions/UploadPDFAction";
import CircularProgress from "@mui/material/CircularProgress";

const CommonPDFUpload = ({
  disabled,
  formik,
  value,
  setValue,
  contentType,
}) => {
  const dispatch = useDispatch();
  const fileData = useSelector((state) => state.getUploadPDFReducer);

  const [currentFile, setCurrentFile] = useState();
  const [fileName, setFileName] = useState();

  useEffect(() => {
    setFileName(value);
  }, [value]);

  useEffect(() => {
    if (fileData.getData != "" && currentFile) {
      formik?.setFieldValue("pdf", fileData.getData);
      if (setValue) {
        setValue(fileData.getData);
      }
      setFileName(fileData.getData);
    }
  }, [fileData.getData]);

  const handlePDF = (event) => {
    const formData = new FormData();
    formData.append("media", event.currentTarget.files[0]);
    formData.append("name", event.currentTarget.files[0]?.name);
    setCurrentFile(event.currentTarget.files[0]);
    if (
      event.currentTarget.files[0]?.name &&
      event.currentTarget.files[0]?.size < 5242900
    ) {
      dispatch(getUploadPDFData(formData));
    } else {
      formik?.setFieldValue("pdf", "");
      if (setValue) {
        setValue("");
      }
    }
  };

  // console.log("selected PDF file =====> ", fileData, currentFile?.size);

  return (
    <Grid container spacing={2} alignItems="top">
      <Grid item xs={12} className="uploadBtnStyle">
        <div className="uploadBtn">
          <label for="pdf" className="btn uploadBtnLabel">
            {formik?.values?.pdf
              ? formik?.values?.pdf
              : fileName
              ? fileName
              : " Upload PDF"}{" "}
            {contentType && contentType === "mahindraCollective" ? (
              ""
            ) : (
              <span className="colRed">*</span>
            )}
          </label>

          <img
            for="pdf"
            src={uploadIcon}
            className="uploadIconStyl"
            alt="Upload Button Icon"
          />
          <input
            id="pdf"
            style={{
              visibility: "hidden",
              padding: "19px 16px",
              position: "absolute",
            }}
            disabled={disabled}
            type="file"
            accept="application/pdf"
            name="pdf"
            onChange={handlePDF}
          />
        </div>
        <div>
          {fileData.isLoading && (
            <>
              <CircularProgress
                color="success"
                style={{ width: "20px", height: "20px" }}
              />
              <span className="ms-2">Uploading...</span>
            </>
          )}
        </div>
        <span style={{ color: "#f44336" }}>
          {currentFile && currentFile.size > 5242900 && (
            <span style={{ color: "red" }}>
              File Size should be less than 5 MB
            </span>
          )}
        </span>
      </Grid>
    </Grid>
  );
};

export default CommonPDFUpload;
