import React, { useState, useEffect } from "react";
import "./SubmitOfferCampaign.css";
import {
  Paper,
  Grid,
  Radio,
  FormControlLabel,
  RadioGroup,
  Button,
} from "@material-ui/core";
import { useFormik } from "formik";
import previewArticle from "../../../Assets/Images/search-eye-line.svg";
import rejectArticle from "../../../Assets/Images/file-forbid-line.svg";

import CharLeftField from "../../Common/CharLeftField/CharLeftField";
import SubmitButton from "../../Common/Button/SubmitButton";
import DatePicker from "../../Common/DatePicker/DatePicker";
import CommonAutocomplete from "../../Common/CommonAutocomplete/CommonAutocomplete";
import CommonModal from "../../Common/CommonModal/CommonModal";
import SelectFileTypeComponent from "./SelectFileTypeComponent";
import { EditorState } from "draft-js";
import CommonLabel from "../../Common/ComonLabel/CommonLabel";
import CommonCheckBox from "../../Common/CommonCheckBox/CommonCheckBox";
import Template from "../../Common/Template/Template";
import offerCompaignApprovalIcon from "../../../Assets/Images/offer-compaign-approval.svg";
import { SubmitOfferCampaignSchema } from "../../../Assets/formikErrors/SubmitOfferCampaignSchema";
import uploadIcon from "../../../Assets/Images/collectiveIcons/upload-2-line.svg";
import services from "../../../Services/index";
import moment from "moment";
import Swal from "sweetalert2";
import { LIVEWIRE, OFFER_CAMPAIGNS } from "../../../Routes/constant";
import { useHistory, useLocation, useParams } from "react-router-dom";
import Loader from "../../Common/Loader/Loader";
import MultiSelect from "../../Common/MultiSelect/MultiSelect";
import CustomMultiSelect from "../../Common/CustomMultiSelect/CustomMultiSelect";
import PreviewArticleComponent from "../MahindraCollective/Admin/PreviewArticleModel/PreviewArticleComponent";
import PreviewComponent from "../OfferCampaigns/Admin/PreviewComponent";
import RejectArticleComponent from "../MahindraCollective/Admin/RejectArticleModel/RejectArticleComponent";
import PreviewOCArticleComponent from "../OfferCampaigns/Admin/PreviewOCArticleComponent";
import { emptyOffer, getOffer } from "../../../Redux/Actions/CommonActions";
import { useDispatch, useSelector } from "react-redux";
import CommonFileUpload from "../../Common/CommonFileUpload/CommonFileUpload";
import CommonImageUpload from "../../Common/CommonImageUpload/CommonImageUpload";

const style = {
  width: 300,
  marginBottom: "1.5em",
};

const options = [
  {
    title: "Image",
    value: "image",
  },
  {
    title: "PDF",
    value: "document",
  },
  {
    title: "Text content",
    value: "text",
  },
  {
    title: "HTML content",
    value: "html",
  },
  {
    title: "Youtube URL",
    value: "youtube",
  },
  {
    title: "Video",
    value: "video",
  },
];

function SubmitOfferCampaign(props) {
  const location = useLocation();
  const dispatch = useDispatch();
  let { id: articleId } = useParams();
  let getUploadedData = location?.state?.details || {};

  const getEditValue =
    Object.keys(getUploadedData).length === 0 &&
    getUploadedData.constructor === Object
      ? false
      : true;
  // const getEditValue = articleId && articleId !== ":id" ? true : false;
  const [loading, setLoading] = useState(false);
  const [state, setState] = useState({
    // title: "",
    // description: "",
    // distributes: "",
    // keywords: "",
    // name: "",
    // email: "",
    // phoneNumber: "",
    textContent: "",
  });
  const [getExsitingData, setExsitingData] = useState("");
  const [getSectorList, setGetSectorList] = useState([]);
  const [selectedValue, setSelectedValue] = useState("");
  const [getTitle, getSetTitle] = useState("");
  const [titleCharLeft, setTitleCharLeft] = useState(100);
  const [distributesCharLeft, setDistributesCharLeft] = useState(50);
  const [keywordsCharLeft, setKeywordsCharLeft] = useState(50);
  const [textAreaCharLeft, setTextAreaCharLeft] = useState(3500);
  const [checkThumbnail, setCheckThumbnail] = useState(null);
  const history = useHistory();
  const [edit, setEdit] = useState(getEditValue);
  const [editApi, setEditApi] = useState(false);
  // const [selectedVideo, setSelectedVideo] = useState("");
  // const [selectedThumbnail, setSelectedThumbnail] = useState();
  // const [thumbnailName, setThumbnailName] = useState("");
  // const [isVideoUploading, setIsVideoUploading] = useState(false);

  const [submitForReject, setSubmitForReject] = React.useState(false);
  const [rejectionNote, setRejectionNote] = useState("");
  const [submitForPublish, setSubmitForPublish] = React.useState(false);
  const [getPublishErrorByAdmin, setGetPublishErrorByAdmin] = useState({});
  const [getPublishDataByAdmin, setGetPublishDataByAdmin] = useState({});
  const [submitForPreview, setSubmitForPreview] = React.useState(false);
  const [publishOfferAndCampaignsError, setPublishOfferAndCampaignsError] =
    useState({});
  const [publishOfferAndCampaignsData, setPublishOfferAndCampaignsData] =
    useState({});

  const [selectedDate, setSelectedDate] = useState({
    expiryDate: null,
    publishDate: null,
  });
  // const [radioValues, setRadioValues] = useState("colleague");
  const [fileType, setFileType] = useState({
    image: null,
    pdf: null,
  });
  const [errorSelectFileType, setErrorSelectFileType] = useState("");
  const [editorState, setEditorState] = useState(() =>
    EditorState.createEmpty()
  );
  const [submitForApproval, setSubmitForApproval] = useState(false);

  //use state for business unit.
  const [selectedBusinessUnitOptions, setSelectedBusinessUnitOptions] =
    useState([]);
  const [businessUnitData, setBusinessUnitData] = useState([]);
  const [sendBuData, setsendBuData] = useState([]);

  const [isNotify, setIsNotify] = useState(false);
  const [confirmNotify, setConfirmNotify] = useState(false);

  console.log(selectedBusinessUnitOptions, "business unit options.....");
  console.log(businessUnitData, "business unit data");

  let articleData = useSelector((state) => state.getOfferDataReducer);

  const videoData = useSelector((state) => state.getUploadFileReducer);
  if (articleData) {
    getUploadedData = articleData?.getData;
  }

  useEffect(() => {
    if (articleId) {
      if (articleId === ":id") {
        dispatch(emptyOffer());
      } else {
        dispatch(getOffer("offerorcampaign", articleId));
      }
    } else {
      dispatch(emptyOffer());
    }
  }, [articleId]);

  // useEffect(() => {
  //   setSelectedVideo(getUploadedData?.customVideo ? getUploadedData?.videoUri : "");
  //   setThumbnailName(getUploadedData?.customVideo ? getUploadedData?.thumbnail : "");
  // },[getUploadedData]);

  // useEffect(() => {
  //   if (getUploadedData) {
  //     setIsNotify(getUploadedData?.notify ? true : false);
  //     setConfirmNotify(getUploadedData?.notify ? true : false);
  //   }
  // }, [getUploadedData]);

  const handleDateChange = (name, date) => {
    if (name === "expiryDate") {
      setSelectedDate({ ...selectedDate, expiryDate: date });
    } else if (name === "publishDate") {
      setSelectedDate({ ...selectedDate, publishDate: date });
    }
  };

  const handleChange = (e) => {
    const { value, name } = e.target;
    // setState({ ...state, [name]: value });
    formik.handleChange(e);
    if (name === "title") {
      getSetTitle(e.target.value);
      if (value.length <= 100) setTitleCharLeft(100 - value.length);
    } else if (name === "distributes") {
      if (value.length <= 50) setDistributesCharLeft(50 - value.length);
    } else if (name === "keywords") {
      if (value.length <= 50) setKeywordsCharLeft(50 - value.length);
    }
  };

  const handleSelectChange = (event, selectType) => {
    setSelectedValue(selectType);
  };

  const handleChangeTextArea = (e) => {
    formik.handleChange(e);
    const { value } = e.target;
    // setState({ ...state, htmlContent: value });
    if (value.length <= 3500) {
      setTextAreaCharLeft(3500 - value.length);
    }
  };

  const handleFileChange = (e) => {
    const { name, files } = e.target;
    if (name === "image") {
      setFileType({ ...fileType, image: files[0] });
    } else {
      setFileType({ ...fileType, pdf: files[0] });
    }
  };

  const onEditorStateChange = (editorState) => {
    setEditorState(editorState);
  };

  const handleCloseModal = () => {
    setSelectedValue("");
    setSubmitForApproval(false);
  };

  const handleSubmitForPublish = () => {
    setSubmitForPublish(true);
  };

  const handleClose = () => {
    setSubmitForApproval(false);
    setSubmitForPreview(false);
    setSubmitForReject(false);
    setSubmitForPublish(false);
  };

  const handleSubmit = async (values, resetForm) => {
    let formData = new FormData();
    setErrorSelectFileType("");
    const {
      title,
      description,
      url,
      distributes,
      keywords,
      name,
      email,
      phoneNumber,
      file,
      pdf,
      expiryDate,
      publishDate,
      radioValues,
      thumbnail,
      textContent,
      editorValue,
      selectFileType,
      sector,
      bu,
      videoUri,
    } = values;
    const getAllIdSector = sector.map((result) => {
      return { sectorId: result.sectorCode, sectorName: result.title };
    });
    const getBU = bu.map((result) => {
      return { buId: result.value, buName: result.title };
    });
    formData.append("title", title);
    formData.append("isMahindraCollective", false);
    formData.append(
      "story",
      selectFileType.value === "text"
        ? editorValue
        : selectFileType.value === "html"
        ? textContent
        : null
    );

    formData.append("subTitle", description);
    formData.append("distributeTo", JSON.stringify(getAllIdSector));

    formData.append("tags", keywords);
    if (expiryDate !== null) {
      formData.append(
        "expiryDate",
        typeof expiryDate === "string"
          ? moment(expiryDate).format("YYYY-MM-DD")
          : moment(expiryDate.toDate()).format("YYYY-MM-DD")
      );
    }
    formData.append(
      "publishDate",
      typeof publishDate === "string"
        ? moment(publishDate).format("YYYY-MM-DD")
        : moment(publishDate.toDate()).format("YYYY-MM-DD")
    );
    formData.append("author", localStorage.getItem("token"));
    formData.append(
      "name",
      radioValues === "author"
        ? `${getExsitingData.firstname} ${getExsitingData.lastname}`
        : `${getExsitingData.firstname} ${getExsitingData.lastname}`
    );
    formData.append("submittedFor", radioValues === "author" ? "" : name);
    formData.append(
      "email",
      radioValues === "author" ? getExsitingData.emailid : email
    );
    formData.append("phoneNumber", phoneNumber);
    formData.append("isNotify", confirmNotify);
    formData.append("mediaType", selectFileType.value);
    if (
      selectFileType.value === "image" ||
      selectFileType.value === "document"
    ) {
      formData.append(
        "media",
        selectFileType.value === "image"
          ? thumbnail
          : selectFileType.value === "document"
          ? pdf
          : null
      );
    }
    //Added by Sagar
    formData.append(
      "isVideo",
      selectFileType.value == "video" ? false : videoUri ? true : false
    );
    formData.append("videoUri", videoUri);
    formData.append(
      "isCustomVideo",
      selectFileType.value == "video" ? true : false
    );
    if (thumbnail) {
      formData.append("thumbnail", thumbnail);
    }
    formData.append("description", description);
    formData.append("url", url);
    formData.append("isAuthorPost", radioValues === "author" ? true : false);
    formData.append("businessUnit", JSON.stringify(getBU));
    getSetTitle(title);
    try {
      setLoading(true);
      const data = await services.api(
        `${editApi ? "PATCH" : "POST"}`,
        `/ui-assist/v1/offerorcampaign/${editApi ? getUploadedData.id : ""}`,
        formData
      );
      if (data.status === 201) {
        setLoading(false);
        resetForm();
        setErrorSelectFileType("");
        setSubmitForApproval(true);
      }
    } catch (error) {
      setLoading(false);
      Swal.fire("Oops", `${error.response.data}`, "error");
    }
  };
  const getSector = (sec) => {
    return sec.map((result) => {
      return {
        title: result.sectorName,
        value: result.sectorName,
        sectorCode: result.sectorId,
      };
    });
  };
  const getBU = (sec) => {
    return sec.map((result) => {
      return {
        title: result.buName,
        value: result.buId,
      };
    });
  };

  const getSelectFileType = (mediaType) => {
    if (mediaType === "document") {
      return options[1];
    } else if (mediaType === "html") {
      return options[3];
    } else if (mediaType === "image") {
      return options[0];
    } else if (mediaType === "youtube") {
      return options[4];
    } else if (mediaType === "video") {
      return options[5];
    } else {
      return options[2];
    }
  };

  const formik = useFormik({
    initialValues: {
      title: getUploadedData?.title ? getUploadedData.title : "",
      notify: getUploadedData?.notify ? getUploadedData.notify : false,
      description: getUploadedData?.description
        ? getUploadedData.description
        : "",
      url: getUploadedData?.url ? getUploadedData.url : "",
      sector: getUploadedData?.distributeTo
        ? getSector(getUploadedData?.distributeTo)
        : [],
      bu: getUploadedData?.businessUnit
        ? getBU(getUploadedData?.businessUnit)
        : [],
      keywords: getUploadedData?.tags ? getUploadedData?.tags.toString() : "",
      name:
        getUploadedData?.isAuthorPost == false
          ? getUploadedData?.submittedFor
          : getUploadedData?.name
          ? getUploadedData?.name
          : "",
      email: getUploadedData?.email ? getUploadedData.email : "",
      phoneNumber: getUploadedData?.phoneNumber
        ? getUploadedData.phoneNumber
        : "",
      textContent: getUploadedData?.story ? getUploadedData?.story : "",
      file:
        getUploadedData?.mediaType === "image" ? getUploadedData?.media : null,
      videoUri: getUploadedData?.videoUri ? getUploadedData.videoUri : "",
      pdf:
        getUploadedData?.mediaType === "document"
          ? getUploadedData?.media
          : null,
      expiryDate: getUploadedData?.expiryDate
        ? getUploadedData.expiryDate
        : null,
      publishDate: getUploadedData?.publishDate
        ? getUploadedData.publishDate
        : null,
      editorValue: getUploadedData?.story ? getUploadedData?.story : "",
      radioValues:
        getUploadedData?.isAuthorPost ||
        getUploadedData?.isAuthorPost == undefined
          ? "author"
          : "colleague",
      thumbnail: getUploadedData?.thumbnail ? getUploadedData.thumbnail : null,
      selectFileType: getUploadedData?.mediaType
        ? getSelectFileType(getUploadedData?.mediaType)
        : {},
      isAuthorPost: getUploadedData?.isAuthorPost,
      submittedFor: getUploadedData?.submittedFor
        ? getUploadedData?.submittedFor
        : "",
    },
    enableReinitialize: true,
    validationSchema: SubmitOfferCampaignSchema,
    onSubmit: (values, { resetForm }) => handleSubmit(values, resetForm),
  });

  const {
    title,
    description,
    url,
    distributes,
    keywords,
    name,
    email,
    phoneNumber,
    expiryDate,
    publishDate,
    radioValues,
    thumbnail,
    videoUri,
    submittedFor,
  } = formik.values;

  useEffect(() => {
    formik.resetForm();
    // formik.setFieldValue("title", undefined);
  }, []);

  useEffect(async () => {
    let userProfile = JSON.parse(
      localStorage.getItem("userProfile")
        ? localStorage.getItem("userProfile")
        : null
    );
    setExsitingData(userProfile);
    try {
      setLoading(true);
      let res = await services.api(
        "GET",
        `/ui-assist/v1/rollmatrix/adminsectors/${localStorage.getItem(
          "token"
        )}?modulename=OFFERS_AND_CAMPAIGNS`
      );
      if (res.status === 200) {
        setLoading(false);
        let list = res?.data?.map((result) => {
          return {
            title: result.sectorName,
            value: result.sectorName,
            sectorCode: result.sectorCode,
          };
        });
        setGetSectorList(list);
      }
    } catch (err) {
      setLoading(false);
      Swal.fire("Oops!", `${err?.response?.data}`, "error");
      setGetSectorList([]);
    }

    formik.handleChange("publishDate", new Date());
  }, []);

  const submitApproval = () => {
    setSubmitForApproval(false);
    history.push(`${LIVEWIRE}${OFFER_CAMPAIGNS}`);
  };

  const validSlectType = (formik) => {
    const { pdf, selectFileType, thumbnail, videoUri } = formik.values;

    if (selectFileType.value === "image") {
      if (thumbnail) {
        return false;
      } else {
        return true;
      }
    } else if (selectFileType.value === "youtube") {
      if (videoUri) {
        return false;
      } else {
        return true;
      }
    } else if (selectFileType.value === "video") {
      if (videoUri && thumbnail) {
        return false;
      } else {
        return true;
      }
    } else if (
      selectFileType.value === "text" ||
      selectFileType.value === "html"
    ) {
      if (thumbnail) {
        return false;
      } else {
        return true;
      }
    } else if (selectFileType.value === "document") {
      if (thumbnail && pdf) {
        return false;
      } else {
        return true;
      }
    }
  };

  const getOptionLabel = (option) => `${option.title}`;
  const handleClear = () => {
    formik.setFieldValue("sector", []);
    getBusinessUnitBySector("");
  };
  const handleClearBU = () => {
    formik.setFieldValue("bu", []);
  };

  const handleEdit = () => {
    setEditApi(true);
    setEdit(!edit);
  };

  const getRejectionNote = (data) => {
    setRejectionNote(data);
  };

  const getValue = (data, errors) => {
    setGetPublishErrorByAdmin(errors);
    setGetPublishDataByAdmin(data);
  };

  const handleSubmitForPreview = () => {
    setSubmitForPreview(true);
  };

  const handleSubmitForReject = () => {
    setSubmitForReject(true);
  };

  const handleRejectStory = async () => {
    const { id } = getUploadedData;
    try {
      setLoading(true);
      let res = await services.api(
        "POST",
        `/ui-assist/v1/offerorcampaign/reject/${id}?admin=${localStorage.getItem(
          "token"
        )}`
      );
      if (res.status === 200) {
        setLoading(false);
        setSubmitForReject(false);
        if (res) {
          Swal.fire(
            "Successfully updated!",
            "Story has been rejected successfully.",
            "success"
          ).then((result) => {
            if (result.isConfirmed) {
              history.push(`${LIVEWIRE}${OFFER_CAMPAIGNS}`);
            }
          });
        }
      } else {
        setLoading(false);
        Swal.fire("Oops!", "Something went wrong.", "warning").then(
          (result) => {
            if (result.isConfirmed) {
              history.push(`${LIVEWIRE}${OFFER_CAMPAIGNS}`);
            }
          }
        );
      }
    } catch (err) {
      setLoading(false);
      setSubmitForReject(false);
      Swal.fire("Oops!", err?.response?.data, "warning").then((result) => {
        // if (result.isConfirmed) {
        //   history.push(DASHBOARD);
        // }
      });
    }
  };

  const publishOfferAndCampaigns = (data, error) => {
    setPublishOfferAndCampaignsData(data);
    setPublishOfferAndCampaignsError(error);
  };

  const submitOfferAndCampaignForApproval = async () => {
    const { publishDate, publishOrSchedule, iswhatsnew, expiryDate } =
      publishOfferAndCampaignsData;

    const { id, author } = getUploadedData;

    let formData = new FormData();
    formData.append("admin", author);
    formData.append("iswhatsnew", iswhatsnew);
    formData.append(
      "publishDate",
      publishOrSchedule === "publish"
        ? moment(formik.values.publishDate).format("YYYY-MM-DD")
        : publishDate
    );
    formData.append("whatsnewexpiry", expiryDate);

    try {
      setLoading(true);
      let res = await services.api(
        "POST",
        `/ui-assist/v1/offerorcampaign/approve/${id}`,
        formData
      );
      if (res.status === 200) {
        let q = new Date();
        let m = q.getMonth() + 1;
        let d = q.getDay();
        let y = q.getFullYear();

        let date = new Date(y, m, d);
        let myDate = new Date(res?.data?.publishDate);

        let sucessMsg =
          date >= myDate
            ? "Your story has been published successfully"
            : `Story will be publish on ${moment(res?.data?.publishDate).format(
                "DD/MM/YYYY"
              )}`;
        setLoading(false);
        setSubmitForPublish(false);
        if (res) {
          Swal.fire("Successfully updated!", `${sucessMsg}`, "success").then(
            (result) => {
              if (result.isConfirmed) {
                history.push(`${LIVEWIRE}${OFFER_CAMPAIGNS}`);
              }
            }
          );
        }
      } else {
        setLoading(false);
        Swal.fire("Oops!", "Something went wrong.", "warning");
      }
    } catch (err) {
      setLoading(false);
      setSubmitForPublish(false);
      Swal.fire("Oops!", err?.response?.data, "warning");
    }
  };

  const handlevideoUri = (e, formik) => {
    formik.setFieldValue("videoThumbnail", "");
    formik.setFieldValue("thumbnail", "");
    formik.setFieldValue("video", "");
    formik.handleChange(e);
  };

  //getSelectedSectorOptions......
  const getSelectedSectorOptions = (selectedOptions) => {
    console.log("i was here", selectedOptions);
    const xyz = selectedOptions.map((el) => {
      return {
        addsectorId: el.sectorCode,
      };
    });
    const sectorList = selectedOptions.map((el) => el.sectorCode);
    getBusinessUnitBySector(sectorList.join(",").toString());
  };

  //to get business unit by sectors...
  const getBusinessUnitBySector = async (data) => {
    console.log(data, "data...");
    try {
      let moduleName = "OFFERS_AND_CAMPAIGNS";
      setLoading(true);
      let res = await services.api(
        "POST",
        `/ui-assist/v1/rollmatrix/adminbu/${localStorage.getItem(
          "token"
        )}?modulename=${moduleName}&sectorid=${data}`
      );
      if (res.status === 200) {
        setLoading(false);
        let list = res.data.map((result) => {
          return {
            title: result.buName,
            value: result.id,
          };
        });
        let divisionList = res.data.map((result) => {
          return {
            buId: result.id,
            buName: result.buName,
          };
        });
        setSelectedBusinessUnitOptions(list);
        setBusinessUnitData(list);
        formik.setFieldValue("bu", list);
        setsendBuData(divisionList);
      }
    } catch (err) {
      setLoading(false);
      Swal.fire("Oops!", `${err.response.data}`, "error");
    }
  };

  const handleNotificationChange = (event) => {
    if (isNotify) {
      setIsNotify(false);
      setConfirmNotify(false);
    } else {
      setIsNotify(true);
    }
  };
  const handleConfirmNotificationChange = (event) => {
    setConfirmNotify(!confirmNotify);
  };

  console.log("selected video =====> ", formik.values.videoUri);
  console.log("selected thumbnail =====> ", formik.values.thumbnail);

  return (
    <Template pageName={"Submit an offer or a campaign"}>
      {loading ? <Loader /> : null}
      <div className="submitOfferCampaignContainer">
        <Paper className="paperContainerOfferCampaign">
          <form onSubmit={formik.handleSubmit}>
            <div className="innerContainersubmitOfferCampaign">
              {getUploadedData &&
              Object.keys(getUploadedData).length === 0 &&
              getUploadedData.constructor === Object ? (
                <span className="submitOfferCampaignText commonFontNormalStyle">
                  Submit an offer or a campaign
                </span>
              ) : (
                <div className="inlineContent">
                  <span className="submitOfferCampaignText commonFontNormalStyle m0">
                    Review article
                  </span>
                  <span className="previewMCData">
                    {edit && (
                      <span style={{ marginRight: "5px" }} onClick={handleEdit}>
                        Edit
                      </span>
                    )}
                    {articleId && articleId !== ":id" && (
                      <span onClick={handleSubmitForPreview}>Preview</span>
                    )}
                  </span>
                </div>
              )}
              <div className="divContainerOfferCampaign">
                <Grid container spacing={2} alignItems="top">
                  <Grid item md={12} xs={12} className="alignInputTextTitle">
                    <CharLeftField
                      disabled={edit}
                      label={<CommonLabel label="Title" mandatory validate />}
                      validate
                      charLeft={titleCharLeft}
                      handleChange={handleChange}
                      value={title}
                      name="title"
                      inputProps={{
                        maxLength: 100,
                      }}
                      type="text"
                      handleBlur={formik.handleBlur}
                      errorMsg={
                        formik.errors.title && formik.touched.title
                          ? formik.errors.title
                          : ""
                      }
                    />
                  </Grid>
                  <Grid item md={12} xs={12}>
                    <CharLeftField
                      disabled={edit}
                      label={<CommonLabel label="Description" mandatory />}
                      handleChange={formik.handleChange}
                      value={description}
                      name="description"
                      handleBlur={formik.handleBlur}
                      type="text"
                      errorMsg={
                        formik.errors.description && formik.touched.description
                          ? formik.errors.description
                          : ""
                      }
                    />
                  </Grid>
                  <Grid item md={4} xs={12} sm={4} className="selectType">
                    <CommonAutocomplete
                      disabled={edit}
                      handleSelectChange={(e, value) => {
                        formik.setFieldValue("thumbnail", null);
                        formik.setFieldValue("videoUri", null);
                        formik.setFieldValue("url", "");
                        formik.setFieldValue("textContent", null);
                        formik.setFieldValue("file", null);
                        formik.setFieldValue("pdf", null);
                        formik.setFieldValue("editorValue", null);
                        formik.setFieldValue("selectFileType", value);
                      }}
                      errorMsg={
                        formik.errors.selectFileType &&
                        formik.touched.selectFileType
                          ? formik.errors.selectFileType
                          : ""
                      }
                      handleBlur={formik.handleBlur}
                      name="selectFileType"
                      className="width100"
                      selectedValue={formik.values.selectFileType}
                      options={options}
                      style={style}
                      label={<CommonLabel label="Select file type" mandatory />}
                    />
                  </Grid>
                  {formik?.values?.selectFileType?.value === "video" && (
                    <>
                      <Grid item md={4} xs={12}>
                        <CommonFileUpload disabled={edit} formik={formik} />
                      </Grid>
                    </>
                  )}
                  {formik?.values?.selectFileType?.value === "youtube" && (
                    <>
                      <Grid item md={4} xs={12} className="uploadBtnStyle">
                        <CharLeftField
                          disabled={edit}
                          label={
                            <CommonLabel
                              label="Enter youtube link only"
                              mandatory
                            />
                          }
                          handleChange={(e) => handlevideoUri(e, formik)}
                          handleBlur={formik.handleBlur}
                          value={videoUri}
                          name="videoUri"
                          type="text"
                        />
                      </Grid>
                    </>
                  )}
                  {formik?.values?.selectFileType?.value === "text" ||
                  formik?.values?.selectFileType?.value === "html" ||
                  formik?.values?.selectFileType?.value === "document" ? (
                    <>
                      <Grid item md={4} xs={12} className="uploadBtnStyle">
                        {false && ""}
                        <CommonImageUpload disabled={edit} formik={formik} />
                      </Grid>
                      <Grid item md={4} xs={12} className="uploadBtnStyle">
                        <div className="compatiblityTxtImg">
                          Compatible formats: - JPG, PNG (Max-5 MB) Recommended
                          dimension - 1010 X 670 PX
                        </div>
                      </Grid>
                      {/* <Grid item md={3}>
                        <div className="compatiblityTxt">
                          Compatible formats:JPG, PNG <br />
                          (Max size: 1010px * 560px)
                        </div>
                      </Grid> */}
                    </>
                  ) : null}
                  {formik?.values?.selectFileType?.value === "video" ? (
                    <>
                      <Grid item md={4} xs={12} className="uploadBtnStyle">
                        {false && ""}
                        <CommonImageUpload disabled={edit} formik={formik} />
                        <div className="compatiblityTxtImg">
                          Compatible formats: - JPG, PNG (Max-5 MB) Recommended
                          dimension - 1010 X 670 PX
                        </div>
                      </Grid>

                      {/* <Grid item md={3}>
                                <div className="compatiblityTxt">
                                  Compatible formats:JPG, PNG <br />
                                  (Max size: 1010px * 560px)
                                </div>
                              </Grid> */}
                    </>
                  ) : null}
                  <Grid
                    item
                    md={
                      formik?.values?.selectFileType?.value === "text" ||
                      formik?.values?.selectFileType?.value === "html"
                        ? 12
                        : 8
                    }
                    xs={12}
                    sm={8}
                  >
                    {
                      <SelectFileTypeComponent
                        formik={formik}
                        disabled={edit}
                        value={url}
                        selectedValue={formik.values.selectFileType}
                        handleChangeTextArea={handleChangeTextArea}
                        htmlContent={state.textContent}
                        editorState={editorState}
                        onEditorStateChange={onEditorStateChange}
                      />
                    }
                  </Grid>
                </Grid>
              </div>
              <span className="additionalDetailsText commonFontNormalStyle">
                Additional details
              </span>
              <div className="additionalDetailsContainer">
                <Grid container spacing={5}>
                  <Grid
                    item
                    md={6}
                    xs={12}
                    className="maxWidthContent uploadSector"
                  >
                    <CustomMultiSelect
                      disabled={!getSectorList?.length || edit}
                      items={getSectorList?.length && getSectorList}
                      getOptionLabel={getOptionLabel}
                      selectedValues={formik.values.sector}
                      name="sector"
                      label={<CommonLabel label="Sector" mandatory />}
                      selectAllLabel="Select all"
                      onToggleOption={(value) => {
                        console.log(value, "toggel value...");
                        formik.setFieldValue("sector", value);
                        getSelectedSectorOptions(value);
                      }}
                      onClearOptions={() => handleClear()}
                      onSelectAll={(isSelected, value) => {
                        console.log(getSectorList, "select all");
                        isSelected
                          ? formik.setFieldValue(
                              "sector",
                              getSectorList?.length && getSectorList
                            )
                          : handleClear();
                        isSelected && getSelectedSectorOptions(getSectorList);
                      }}
                    />
                  </Grid>
                  <Grid
                    item
                    md={6}
                    xs={12}
                    className="maxWidthContent uploadSector"
                  >
                    <CustomMultiSelect
                      disabled={!businessUnitData?.length || edit}
                      items={businessUnitData?.length && businessUnitData}
                      getOptionLabel={getOptionLabel}
                      selectedValues={formik.values.bu}
                      name="bu"
                      label={<CommonLabel label="Businesses" />}
                      selectAllLabel="Select all"
                      onToggleOption={(value) => {
                        formik.setFieldValue("bu", value);
                      }}
                      onClearOptions={() => handleClearBU()}
                      onSelectAll={(isSelected) => {
                        isSelected
                          ? formik.setFieldValue(
                              "bu",
                              businessUnitData?.length && businessUnitData
                            )
                          : handleClearBU();
                      }}
                    />
                  </Grid>

                  <Grid item md={6} xs={12} className="maxWidthContent">
                    <CharLeftField
                      disabled={edit}
                      label={<CommonLabel label="Keywords" mandatory />}
                      validate
                      value={keywords}
                      charLeft={keywordsCharLeft}
                      handleChange={handleChange}
                      description={keywords}
                      name="keywords"
                      handleBlur={formik.handleBlur}
                      inputProps={{
                        maxLength: 50,
                      }}
                      type="text"
                      errorMsg={
                        formik.errors.keywords && formik.touched.keywords
                          ? formik.errors.keywords
                          : ""
                      }
                    />
                  </Grid>
                  <Grid item md={6} xs={12}>
                    <DatePicker
                      disabled={edit}
                      label={<CommonLabel label="Publish date" />}
                      selectedDate={formik.values.publishDate}
                      handleDateChange={(date) => {
                        formik.setFieldValue("publishDate", date);
                      }}
                      // maxDate={new Date().setDate(new Date().getDate())}
                      name="publishDate"
                      errorMsg={
                        formik.errors.publishDate
                          ? formik.errors.publishDate
                          : ""
                      }
                    />
                  </Grid>
                  <Grid item md={6} xs={12}>
                    <DatePicker
                      disabled={edit}
                      label={<CommonLabel label="Expiry date" />}
                      selectedDate={expiryDate}
                      handleDateChange={(date) => {
                        formik.setFieldValue("expiryDate", date);
                      }}
                      name="expiryDate"
                      errorMsg={
                        formik.errors.expiryDate ? formik.errors.expiryDate : ""
                      }
                    />
                  </Grid>
                  <Grid item md={12} xs={12}>
                    <RadioGroup
                      disabled={getEditValue}
                      aria-label="I am the author"
                      name="radioValues"
                      value={radioValues}
                      onChange={formik.handleChange}
                    >
                      <Grid container spacing={5}>
                        <Grid item md={4} xs={12}>
                          <div
                            className={`${
                              radioValues === "author" ? "radioBackground " : ""
                            } authorRadioButton fRadio`}
                          >
                            <FormControlLabel
                              disabled={getEditValue}
                              value="author"
                              className="radioButtonForm "
                              control={<Radio />}
                              label={
                                <span className="formControlLabelStyle commonFontNormalStyle">
                                  I am the author
                                </span>
                              }
                              labelPlacement="end"
                            />
                          </div>
                        </Grid>
                        <Grid item md={4} xs={12}>
                          <div
                            className={`${
                              radioValues === "colleague"
                                ? "radioBackground "
                                : ""
                            } authorRadioButton sRadio`}
                          >
                            <FormControlLabel
                              disabled={getEditValue}
                              value="colleague"
                              className="radioButtonForm "
                              control={<Radio />}
                              label={
                                <span className="formControlLabelStyle commonFontNormalStyle">
                                  Submitting it for a colleague{" "}
                                </span>
                              }
                              labelPlacement="end"
                            />
                          </div>
                        </Grid>
                      </Grid>
                    </RadioGroup>
                  </Grid>
                  <Grid item md={4} xs={12}>
                    <CharLeftField
                      disabled={
                        getEditValue || formik.values.radioValues === "author"
                      }
                      inputType={true}
                      label={<CommonLabel label="Name" mandatory />}
                      handleChange={formik.handleChange}
                      value={
                        formik.values.radioValues === "author"
                          ? getEditValue
                            ? formik.values.name
                            : getExsitingData.firstname
                            ? `${getExsitingData.firstname} ${getExsitingData.lastname}`
                            : ""
                          : formik.values.name
                      }
                      name="name"
                      type="text"
                      handleBlur={formik.handleBlur}
                      errorMsg={
                        formik.errors.name && formik.touched.name
                          ? formik.errors.name
                          : ""
                      }
                    />
                  </Grid>
                  <Grid item md={4} xs={12}>
                    <CharLeftField
                      disabled={
                        getEditValue || formik.values.radioValues === "author"
                      }
                      inputType={true}
                      label={<CommonLabel label="Email" mandatory />}
                      handleChange={formik.handleChange}
                      value={
                        formik.values.radioValues === "author"
                          ? getEditValue
                            ? formik.values.email
                            : getExsitingData.emailid
                            ? getExsitingData.emailid
                            : ""
                          : formik.values.email
                      }
                      name="email"
                      type="email"
                      handleBlur={formik.handleBlur}
                      errorMsg={
                        formik.errors.email && formik.touched.email
                          ? formik.errors.email
                          : ""
                      }
                    />
                  </Grid>
                  <Grid item md={4} xs={12}>
                    <CharLeftField
                      disabled={edit}
                      inputType={true}
                      label={<CommonLabel label="Phone number" mandatory />}
                      handleChange={formik.handleChange}
                      value={phoneNumber}
                      name="phoneNumber"
                      handleBlur={formik.handleBlur}
                      type="tel"
                      errorMsg={
                        formik.errors.phoneNumber && formik.touched.phoneNumber
                          ? formik.errors.phoneNumber
                          : ""
                      }
                    />
                  </Grid>
                  <Grid item xs={2} sm="auto" className="p-0 ps-2">
                    <CommonCheckBox
                      checked={isNotify}
                      disabled={
                        !(
                          (getUploadedData &&
                            Object.keys(getUploadedData).length === 0 &&
                            getUploadedData.constructor === Object) ||
                          !edit
                        )
                      }
                      handleChange={handleNotificationChange}
                    />
                  </Grid>
                  <Grid item xs={10} sm="auto" className="p-0 pt-2">
                    <span
                      style={{
                        fontSize: "16px",

                        fontWeight: "500",
                        color: "black",
                      }}
                    >
                      Send notification to all users.
                    </span>
                  </Grid>
                  {isNotify && (
                    <Grid container className="NameUpload ps-3">
                      <Grid item xs={2} sm="auto" className="checkPad1">
                        <CommonCheckBox
                          checked={confirmNotify}
                          disabled={
                            !(
                              (getUploadedData &&
                                Object.keys(getUploadedData).length === 0 &&
                                getUploadedData.constructor === Object) ||
                              !edit
                            )
                          }
                          handleChange={handleConfirmNotificationChange}
                        />
                      </Grid>
                      <Grid item xs={10} sm="auto">
                        <span
                          style={{
                            fontSize: "15px",

                            fontWeight: "500",
                            color: "red",
                          }}
                        >
                          I agree to send notification to all users.
                        </span>
                      </Grid>
                    </Grid>
                  )}
                </Grid>
              </div>
              <div className="supportPart">
                <Grid
                  container
                  justifyContent="space-between"
                  alignItems="center"
                >
                  <span className="supportText commonFontNormalStyle">
                    For support and queries please reach out to &nbsp;
                    <span className="queriesEmail">
                      {" "}
                      {process.env.REACT_APP_SUPPORT_EMAIL_ID}
                    </span>
                  </span>
                  <div className="SubmitForApprovalButtonDiv">
                    {(getUploadedData &&
                      Object.keys(getUploadedData).length === 0 &&
                      getUploadedData.constructor === Object) ||
                    !edit ? (
                      <SubmitButton
                        disabled={
                          videoData.isLoading ||
                          !(formik.isValid && formik.dirty) ||
                          validSlectType(formik) ||
                          (isNotify && !confirmNotify)
                        }
                        label="Submit for Approval"
                        type="submit"
                        variant="contained"
                        className={`${
                          videoData.isLoading ||
                          !(formik.isValid && formik.dirty) ||
                          validSlectType(formik) ||
                          (isNotify && !confirmNotify)
                            ? "SubmitForApprovalButton"
                            : "enabledButton"
                        } commonFontNormalStyle`}
                        size="large"
                      />
                    ) : (
                      <div className="supportPart">
                        <Grid
                          container
                          justifyContent="flex-end"
                          alignItems="center"
                        >
                          <div className="SubmitForApprovalButtonDiv m8 publishRejectBtn">
                            <Button
                              variant="outlined"
                              className="btnReject"
                              onClick={handleSubmitForReject}
                            >
                              Reject Story
                            </Button>
                            <SubmitButton
                              label="publish story"
                              type="button"
                              variant="contained"
                              className=" commonFontNormalStyle"
                              size="large"
                              onClick={handleSubmitForPublish}
                            />
                          </div>
                        </Grid>
                      </div>
                    )}
                  </div>
                </Grid>
              </div>
            </div>
          </form>
        </Paper>
        <span className="SupportTextBlock commonFontNormalStyle">
          For support and queries please reach out to &nbsp;
          <span className="queriesEmail">
            {" "}
            {process.env.REACT_APP_SUPPORT_EMAIL_ID}
          </span>
        </span>
      </div>
      <CommonModal
        open={submitForApproval}
        handleClose={handleCloseModal}
        headerText="Offer/campaign submitted for approval"
        icon={offerCompaignApprovalIcon}
        modalSize="md"
        submitBtnText="DONE"
        fullWidth
        onButtonClick={() => submitApproval()}
      >
        <div className="Modalcontent">
          <div className="commonFontNormalStyle">
            Your story ‘{`${getTitle}`}’ has been submitted to the admin for
            approval. For any queries please reach out to
            <strong> {process.env.REACT_APP_SUPPORT_EMAIL_ID}</strong>
          </div>
        </div>
      </CommonModal>
      <CommonModal
        open={submitForPreview}
        handleClose={handleClose}
        headerText="Preview"
        icon={previewArticle}
        modalSize="lg"
        submitBtnText="DONE"
        fullWidth
        onButtonClick={() => setSubmitForPreview(false)}
      >
        <PreviewComponent previewData={getUploadedData} />
      </CommonModal>
      <CommonModal
        open={submitForReject}
        handleClose={handleClose}
        headerText="Reject article"
        icon={rejectArticle}
        modalSize="md"
        submitBtnText="SEND"
        fullWidth
        onButtonClick={handleRejectStory}
      >
        <RejectArticleComponent
          // handleRejectStory={handleRejectStory}
          getRejectionNote={getRejectionNote}
        />
      </CommonModal>
      <CommonModal
        open={submitForPublish}
        handleClose={handleCloseModal}
        headerText="Publish offer/campaign"
        icon={offerCompaignApprovalIcon}
        modalSize="md"
        submitBtnText="SAVE CHANGES"
        fullWidth
        onButtonClick={submitOfferAndCampaignForApproval}
        disabledSubmitButton={
          Object.keys(publishOfferAndCampaignsError).length ? true : false
        }
      >
        <PreviewOCArticleComponent
          title={formik.values.title}
          publishOfferAndCampaigns={publishOfferAndCampaigns}
          existingPublishDate={formik.values.publishDate}
          existingExpiryDate={formik.values.expiryDate}
        />
      </CommonModal>
    </Template>
  );
}

export default SubmitOfferCampaign;
