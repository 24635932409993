import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import "./WallpaperComponent.css";
import Template from "../../Common/Template/Template";
import { Grid, TextField } from "@material-ui/core";
import SubmitButton from "../../Common/Button/SubmitButton";
import CommonLabel from "../../Common/ComonLabel/CommonLabel";
import textFieldStyle from "../../Common/Styles/TextFieldStyle";
import DatePicker from "../../Common/DatePicker/DatePicker";
import CommonCheckBox from "../../Common/CommonCheckBox/CommonCheckBox";
import CommonAutocomplete from "../../Common/CommonAutocomplete/CommonAutocomplete";
import SnackbarC from "../../Common/Snackbar/SnackbarC";
import alert from "../../../Assets/Images/SnackBar/alertError.svg";
import { useFormik } from "formik";
import Swal from "sweetalert2";
import { uploadStorySchema } from "../../../Assets/formikErrors/SubmitOfferCampaignSchema";
import { getWallpaperRequestData } from "../../../Redux/Actions/WallpaperRequestAction";
import { getInitialWallpaperInfoData } from "../../../Redux/Actions/InitialWallpaperInfoAction";
import { useDispatch, useSelector } from "react-redux";

const hours = [
  { title: "1", value: "1" },
  { title: "2", value: "2" },
  { title: "3", value: "3" },
  { title: "4", value: "4" },
  { title: "5", value: "5" },
  { title: "6", value: "6" },
  { title: "7", value: "7" },
  { title: "8", value: "8" },
  { title: "9", value: "9" },
  { title: "10", value: "10" },
  { title: "11", value: "11" },
  { title: "12", value: "12" },
];

const minutes = [
  { title: "0", value: "0" },
  { title: "15", value: "15" },
  { title: "30", value: "30" },
  { title: "45", value: "45" },
];

const timeslot = [
  { title: "AM", value: "AM" },
  { title: "PM", value: "PM" },
];

export default function PostWallpaperRequest() {
  const dispatch = useDispatch();
  const requestData = useSelector((state) => state.getWallpaperRequestReducer);
  const initialData = useSelector(
    (state) => state.getInitialWallpaperInfoReducer
  );

  const [selectedStartDate, setSelectedStartDate] = useState({
    startDate: null,
  });
  const [selectedEndDate, setSelectedEndDate] = useState({ endDate: null });
  const [title, setTitle] = useState("");
  const [userName, setUserName] = useState("");
  const [userSector, setUserSector] = useState("");
  const [userLocation, setUserLocation] = useState("");
  const [userDepartment, setUserDepartment] = useState("");
  const [contactDetails, setContactDetails] = useState("");
  const [ASCheck, setASCheck] = useState(false);
  const [FESCheck, setFESCheck] = useState(false);
  const [corporateCenterCheck, setCorporateCenterCheck] = useState(false);
  const [HODApprovalCheck, setHODApprovalCheck] = useState(false);
  const [startHour, setStartHour] = useState("");
  const [startMinute, setStartMinute] = useState("");
  const [startTimeSlot, setStartTimeSlot] = useState("AM");
  const [endHour, setEndHour] = useState("");
  const [endMinute, setEndMinute] = useState("");
  const [endTimeSlot, setEndTimeSlot] = useState("PM");
  const [targetSector, setTargetSector] = useState([]);
  const [userDetails, setUserDetails] = useState();

  const [open1, setOpen1] = useState(false);
  const [open2, setOpen2] = useState(false);
  const [open3, setOpen3] = useState(false);
  const [open4, setOpen4] = useState(false);
  const [open5, setOpen5] = useState(false);
  const [open6, setOpen6] = useState(false);
  const [open7, setOpen7] = useState(false);
  const [open8, setOpen8] = useState(false);
  const [open9, setOpen9] = useState(false);
  const [open10, setOpen10] = useState(false);

  const history = useHistory();

  const handleStartDateChange = (name, date) => {
    if (name === "startDate") {
      setSelectedStartDate({ ...selectedStartDate, startDate: date });
    }
  };

  const handleEndDateChange = (name, date) => {
    if (name === "endDate") {
      setSelectedEndDate({ ...selectedEndDate, endDate: date });
    }
  };

  const changeTitle = (e) => setTitle(e.target.value);
  const changeUserName = (e) => setUserName(e.target.value);
  const changeUserSector = (e) => setUserSector(e.target.value);
  const changeUserLocation = (e) => setUserLocation(e.target.value);
  const changeUserDepartment = (e) => setUserDepartment(e.target.value);
  const changeContactDetails = (e) => setContactDetails(e.target.value);

  const handleASCheck = () => {
    const data = { sectorId: 50067878, sectorName: "AS" };
    if (ASCheck) {
      setASCheck(false);
      DeleteTargetSector(data);
    } else {
      setASCheck(true);
      AddTargetSector(data);
    }
  };
  const handleFESCheck = () => {
    const data = { sectorId: 50067676, sectorName: "FES" };
    if (FESCheck) {
      setFESCheck(false);
      DeleteTargetSector(data);
    } else {
      setFESCheck(true);
      AddTargetSector(data);
    }
  };
  const handleCorporateCenterCheck = () => {
    const data = { sectorId: 50071900, sectorName: "GCO" };
    if (corporateCenterCheck) {
      setCorporateCenterCheck(false);
      DeleteTargetSector(data);
    } else {
      setCorporateCenterCheck(true);
      AddTargetSector(data);
    }
  };
  const handleHODApprovalCheck = () =>
    HODApprovalCheck ? setHODApprovalCheck(false) : setHODApprovalCheck(true);

  const StartHour = (event, selectedValue) => setStartHour(selectedValue.value);
  const StartMinute = (event, selectedValue) => setStartMinute(selectedValue.value);
  const StartTimeSlot = (event, selectedValue) => setStartTimeSlot(selectedValue.value);
  const EndHour = (event, selectedValue) => setEndHour(selectedValue.value);
  const EndMinute = (event, selectedValue) => setEndMinute(selectedValue.value);
  const EndTimeSlot = (event, selectedValue) => setEndTimeSlot(selectedValue.value);

  const [submitSuccess, setSubmitSuccess] = useState(false);

  useEffect(() => {
    let userProfile = JSON.parse(
      localStorage.getItem("userProfile")
        ? localStorage.getItem("userProfile")
        : null
    );

    dispatch(getInitialWallpaperInfoData(userProfile.employeeid));
  }, []);

  useEffect(() => {
    setUserDetails(initialData?.getData);
    setUserName(
      initialData.getData?.firstname + " " + initialData.getData?.lastname
    );
    setUserSector(initialData.getData?.sectorName);
    setUserLocation(initialData.getData?.location);
    setUserDepartment(initialData.getData?.departmentName);
    setContactDetails(initialData.getData?.phoneNumber);
  }, [initialData]);

  useEffect(() => {
    console.log("In Use effect =====");
    if (requestData.status === "success" && submitSuccess == true) {
      console.log("Request success ======> ");
      setSubmitSuccess(false);

      history.push("/work/role-based/wallpaper");
    }
    console.log("useEffect Ends here");
  }, [requestData]);

  const AddTargetSector = (data) => {
    setTargetSector([...targetSector, data]);
  };
  const DeleteTargetSector = (data) => {
    let index = targetSector.findIndex((val) => val.sectorId == data.sectorId);

    if (index !== -1) {
      targetSector.splice(index, 1);
    }
  };

  const Cancel = () => {
    history.push("/work/role-based/wallpaper");
  };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen1(false);
    setOpen2(false);
    setOpen3(false);
    setOpen4(false);
    setOpen5(false);
    setOpen6(false);
    setOpen7(false);
    setOpen8(false);
    setOpen9(false);
    setOpen10(false);
  };
  const autoHideDuration = 3000;
  const errormessage = (msg) => {
    return (
      <>
        <div style={{ display: "flex", alignItems: "center", width: "400px" }}>
          <img
            src={alert}
            alt="Download line"
            className="sImg"
            style={{ marginLeft: "16px" }}
          />
          <div className="contentS1">{msg}</div>
        </div>
      </>
    );
  };

  const Submit = () => {
    let userProfile = JSON.parse(
      localStorage.getItem("userProfile")
        ? localStorage.getItem("userProfile")
        : null
    );
        const y1 = selectedStartDate?.startDate?._d?.getFullYear();
        const m1 = selectedStartDate?.startDate?._d?.getMonth();
        const d1 = selectedStartDate?.startDate?._d?.getDate();
        const y2 = selectedEndDate?.endDate?._d?.getFullYear();
        const m2 = selectedEndDate?.endDate?._d?.getMonth();
        const d2 = selectedEndDate?.endDate?._d?.getDate();
        let sh = "";
        let eh = "";
        if(startTimeSlot == "PM")
        { sh = parseInt(startHour)+12; }
        else
        { sh = parseInt(startHour); }
        if(endTimeSlot == "PM")
        { eh = parseInt(endHour)+12; }
        else
        { eh = parseInt(endHour); }
        const sd = new Date(y1,m1,d1,sh,parseInt(startMinute));
        const ed = new Date(y2,m2,d2,eh,parseInt(endMinute));
    if (title == "") {
      // { setOpen1(true) }
      Swal.fire("Please Enter Title.", " ", "error");
    } else if (selectedStartDate.startDate == null) {
      // { setOpen2(true) }
      Swal.fire("Please Select Start Date.", " ", "error");
    } else if (selectedEndDate.endDate == null) {
      // { setOpen4(true) }
      Swal.fire("Please Select End Date.", " ", "error");
    } else if (startHour == "" || startMinute == "" || startTimeSlot == "") {
      // { setOpen3(true) }
      Swal.fire("Please Select Start Time.", " ", "error");
    } else if (endHour == "" || endMinute == "" || endTimeSlot == "") {
      // { setOpen5(true) }
      Swal.fire("Please Select End Time.", " ", "error");
    } else if (
      selectedStartDate.startDate == selectedEndDate.endDate &&
      startHour == endHour &&
      startMinute == endMinute &&
      startTimeSlot == endTimeSlot
    ) {
      Swal.fire(
        "Proposed Start Date and Time should not be similar as Proposed End Date and Time.",
        " ",
        "error"
      );
    }
    else if (sd > ed) {
          Swal.fire("Start Date & Time should not be greater than End Date & Time.", " ", "error");
    }
    else if (
      ASCheck == false &&
      FESCheck == false &&
      corporateCenterCheck == false
    ) {
      // { setOpen6(true) }
      Swal.fire("Please Select Target Sector.", " ", "error");
    } else if (formik.values.thumbnail == null) {
      // { setOpen7(true) }
      Swal.fire("Please Select Wallpaper Image.", " ", "error");
    } else if (formik.values.thumbnail.size > 102500) {
      // { setOpen9(true) }
      Swal.fire(
        "Wallpaper image size should be less than 100KB.",
        " ",
        "error"
      );
    } else if (formik.values.thumbnail1 == null && HODApprovalCheck == true) {
      // { setOpen8(true) }
      Swal.fire("Please Select HOD Approval Document.", " ", "error");
    } else if (
      title != "" &&
      selectedStartDate.startDate != null &&
      selectedEndDate.endDate != null &&
      startHour != "" &&
      startMinute != "" &&
      startTimeSlot != "" &&
      endHour != "" &&
      endMinute != "" &&
      endTimeSlot != "" &&
      formik.values.thumbnail != null &&
      formik.values.thumbnail.size <= 102500 &&
      (ASCheck == true || FESCheck == true || corporateCenterCheck == true) &&
      ((formik.values.thumbnail1 != null && HODApprovalCheck == true) ||
        (formik.values.thumbnail1 == null && HODApprovalCheck == false))
    ) {
      const formData = new FormData();
      formData.append("title", title);
      formData.append(
        "proposedStartDate",
        FormatDate(
          selectedStartDate.startDate._d,
          startHour,
          startMinute,
          startTimeSlot
        )
      );
      formData.append(
        "proposedEndDate",
        FormatDate(selectedEndDate.endDate._d, endHour, endMinute, endTimeSlot)
      );
      formData.append("wallpaper", formik.values.thumbnail);
      formData.append("targetSector", JSON.stringify(targetSector));
      formData.append("requesterName", userName);
      formData.append("requestSector", userSector);
      formData.append("requesterId", userProfile.employeeid);
      formData.append("location", userLocation);
      formData.append("departMent", userDepartment);
      formData.append("contactDetails", contactDetails);
      formData.append("isHodApproved", HODApprovalCheck);
      formData.append("hodApprovalDetails", formik.values.thumbnail1);
      setSubmitSuccess(true);
      const check = dispatch(getWallpaperRequestData(formData));
      console.log("check API call ========> ", check);
    }
  };

  const FormatDate = (date, hour, minute, slot) => {
    var dd = date.getDate();
    var mm = date.getMonth() + 1;

    var yyyy = date.getFullYear();
    if (dd < 10) {
      dd = "0" + dd;
    }
    if (mm < 10) {
      mm = "0" + mm;
    }
    var selectedDate =
      yyyy + "-" + mm + "-" + dd + " " + hour + ":" + minute + " " + slot;
    return selectedDate;
  };

  const classes = textFieldStyle();

  const formik = useFormik({
    initialValues: {
      thumbnail: null,
      thumbnail1: null,
    },
    enableReinitialize: true,
    validationSchema: uploadStorySchema,
    onSubmit: (values, { resetForm }) => handleSubmit(values, resetForm),
  });
  const { thumbnail, thumbnail1 } = formik.values;

  const handleSubmit = async (values, resetForm) => {
    const { thumbnail, thumbnail1, division } = values;
    let getAllIdBu = division.map((result) => {
      return { buId: result.id, buName: result.title };
    });
  };

  // console.log("Initial Data for request =====> ", initialData);
  // console.log("User Data for request =====> ", userDetails);
  // console.log("start time slot ======>", startTimeSlot);
  // console.log("selected start date ====> ", selectedStartDate);

  return (
    <div>
      <Template>
        <Grid
          container
          direction="row"
          justifyContent="center"
          alignItems="center"
          spacing={2}
        >
          <Grid item xs={12} sm={10}>
            <div className="bg-white mb-5 shadow" style={{ padding: "24px" }}>
              <Grid item xs={12} sm={12}>
                <p className="offCHeaderTitle" style={{ marginBottom: "24px" }}>
                  Post a wallpaper request
                </p>
              </Grid>
              <div className="border1 pad-desk24-mob0">
                <Grid direction="row" className="rollBasedAutoCompplete">
                  <TextField
                    value={title}
                    onChange={changeTitle}
                    variant="outlined"
                    name="name"
                    label={<CommonLabel label="Title" mandatory />}
                    className={`${classes.root} fullWidth customTextfield`}
                  />
                  <SnackbarC
                    open={open1}
                    handleClose={handleClose}
                    message={errormessage("Please Enter Title.")}
                    autoHideDuration={autoHideDuration}
                  />
                </Grid>
                <Grid
                  container
                  direction="row"
                  justifyContent="flex-start"
                  spacing={2}
                  alignItems="center"
                  className="rollBasedAutoCompplete mt-4"
                >
                  <Grid item xs={12} sm={4}>
                    <DatePicker
                      className="mt-4"
                      label={
                        <CommonLabel label="Proposed start date" mandatory />
                      }
                      minDate={new Date().setDate(new Date().getDate() + 1)}
                      selectedDate={selectedStartDate.startDate}
                      handleDateChange={(date) => {
                        handleStartDateChange("startDate", date);
                        handleEndDateChange("endDate", date);
                      }}
                      name="startDate"
                    />
                    <SnackbarC
                      open={open2}
                      handleClose={handleClose}
                      message={errormessage("Please Select Start Date.")}
                      autoHideDuration={autoHideDuration}
                    />
                  </Grid>
                  <Grid
                    item
                    xs={4}
                    sm={2}
                    className="lmar-desk24-mob0 wallpaperDropdown"
                  >
                    <CommonAutocomplete
                      handleSelectChange={StartHour}
                      selectedValue={startHour?.value}
                      name="hours"
                      options={hours}
                      label={<CommonLabel label="Hours" mandatory />}
                    />
                  </Grid>
                  <Grid item xs={4} sm={2} className="wallpaperDropdown">
                    <CommonAutocomplete
                      handleSelectChange={StartMinute}
                      selectedValue={startMinute?.value}
                      name="minutes"
                      options={minutes}
                      label={<CommonLabel label="Minutes" mandatory />}
                    />
                  </Grid>
                  <Grid item xs={4} sm={2} className="wallpaperDropdown">
                    <CommonAutocomplete
                      handleSelectChange={StartTimeSlot}
                      selectedValue={startTimeSlot?.value}
                      name="timeslot"
                      options={timeslot}
                      defaultValue={{ title: "AM", value: "AM" }}
                      // label={<CommonLabel label="AM" mandatory/>}
                    />
                  </Grid>
                  <SnackbarC
                    open={open3}
                    handleClose={handleClose}
                    message={errormessage("Please Select Start Time.")}
                    autoHideDuration={autoHideDuration}
                  />
                </Grid>
                <Grid
                  container
                  direction="row"
                  justifyContent="flex-start"
                  spacing={2}
                  alignItems="center"
                  className="rollBasedAutoCompplete mt-4"
                >
                  <Grid item xs={12} sm={4}>
                    <DatePicker
                      className="mt-4"
                      label={
                        <CommonLabel label="Proposed end date" mandatory />
                      }
                      minDate={selectedStartDate.startDate}
                      selectedDate={selectedEndDate.endDate}
                      handleDateChange={(date) =>
                        handleEndDateChange("endDate", date)
                      }
                      name="endDate"
                    />
                  </Grid>
                  <SnackbarC
                    open={open4}
                    handleClose={handleClose}
                    message={errormessage("Please Select End Date.")}
                    autoHideDuration={autoHideDuration}
                  />
                  <Grid
                    item
                    xs={4}
                    sm={2}
                    className="lmar-desk24-mob0 wallpaperDropdown"
                  >
                    <CommonAutocomplete
                      handleSelectChange={EndHour}
                      selectedValue={endHour?.value}
                      name="hours"
                      options={hours}
                      label={<CommonLabel label="Hours" mandatory />}
                    />
                  </Grid>
                  <Grid item xs={4} sm={2} className="wallpaperDropdown">
                    <CommonAutocomplete
                      handleSelectChange={EndMinute}
                      selectedValue={endMinute?.value}
                      name="minutes"
                      options={minutes}
                      label={<CommonLabel label="Minutes" mandatory />}
                    />
                  </Grid>
                  <Grid item xs={4} sm={2} className="wallpaperDropdown">
                    <CommonAutocomplete
                      handleSelectChange={EndTimeSlot}
                      selectedValue={endTimeSlot?.value}
                      name="timeslot"
                      options={timeslot}
                      defaultValue={{ title: "PM", value: "PM" }}
                      // label={<CommonLabel label="AM" mandatory/>}
                    />
                  </Grid>
                  <SnackbarC
                    open={open5}
                    handleClose={handleClose}
                    message={errormessage("Please Select End Time.")}
                    autoHideDuration={autoHideDuration}
                  />
                </Grid>
                <Grid
                  container
                  direction="row"
                  justifyContent="flex-start"
                  alignItems="center"
                  className="rollBasedAutoCompplete"
                >
                  <Grid item xs={12} sm={12}>
                    <p style={styles.heading}>
                      Select the target sector{" "}
                      <span style={{ color: "red" }}>*</span>
                    </p>
                    <p style={styles.content}>
                      In case of M&M all users, please tick all the boxes
                    </p>
                  </Grid>
                  <Grid item xs={6} sm={2} md={2} className="wallpaperCheckbox">
                    <CommonCheckBox name="as" handleChange={handleASCheck} />
                    <span style={styles.checkboxTitle}>AS</span>
                  </Grid>
                  <Grid item xs={6} sm={2} md={2} className="wallpaperCheckbox">
                    <CommonCheckBox name="fes" handleChange={handleFESCheck} />
                    <span style={styles.checkboxTitle}>FES</span>
                  </Grid>
                  <Grid item xs="auto" sm="auto" className="wallpaperCheckbox">
                    <CommonCheckBox
                      name="corporate-center"
                      handleChange={handleCorporateCenterCheck}
                    />
                    <span style={styles.checkboxTitle}>Corporate center</span>
                  </Grid>
                  <SnackbarC
                    open={open6}
                    handleClose={handleClose}
                    message={errormessage("Please Select the target Sector.")}
                    autoHideDuration={autoHideDuration}
                  />
                </Grid>
                <Grid item xs={12} sm={12}>
                  {true && (
                    <>
                      <Grid item md={3} xs={12} className="uploadBtnStyle mt-3">
                        <div className="uploadBtn">
                          <label for="thumb" className="btn uploadBtnLabel">
                            {thumbnail ? thumbnail.name : " Wallpaper image"}{" "}
                            <span className="colRed">*</span>
                          </label>
                          <input
                            id="thumb"
                            style={{
                              visibility: "hidden",
                              padding: "19px 16px",
                              position: "absolute",
                            }}
                            type="file"
                            accept=".png, .jpg, .svg"
                            name="thumbnail"
                            onChange={(event) => {
                              formik.setFieldValue(
                                "thumbnail",
                                event.currentTarget.files[0]
                              );
                            }}
                          />
                        </div>
                        <span style={{ color: "#f44336" }}>
                          {formik.errors.thumbnail && formik.touched.thumbnail
                            ? formik.errors.thumbnail
                            : ""}
                        </span>
                      </Grid>
                    </>
                  )}
                  <SnackbarC
                    open={open7}
                    handleClose={handleClose}
                    message={errormessage("Please Select Wallpaper Image.")}
                    autoHideDuration={autoHideDuration}
                  />
                  <SnackbarC
                    open={open9}
                    handleClose={handleClose}
                    message={errormessage(
                      "Size of Wallpaper image should not exceed 100KB."
                    )}
                    autoHideDuration={autoHideDuration}
                  />
                  <p style={styles.content}>
                    <span style={styles.link}>Attach file</span>Should not
                    exceed 100KB
                  </p>
                </Grid>
              </div>
              <div className="line" />
              <Grid item xs={12} sm={12}>
                <p
                  className="heading"
                  style={{ marginBottom: "24px", marginTop: "24px" }}
                >
                  Additional details
                </p>
              </Grid>
              <div className="border1 pad-desk24-mob0">
                <Grid
                  container
                  direction="row"
                  spacing={3}
                  className="rollBasedAutoCompplete mb-3"
                >
                  <Grid item xs={12} sm={4}>
                    <TextField
                      value={userName}
                      onChange={changeUserName}
                      variant="outlined"
                      name="name-of-requester"
                      label={<CommonLabel label="Name of the requester" />}
                      className={`${classes.root} fullWidth customTextfield`}
                      disabled={
                        userDetails?.firstname != null &&
                        userDetails?.lastname != null
                          ? true
                          : false
                      }
                    />
                  </Grid>
                  <Grid item xs={12} sm={4}>
                    <TextField
                      value={userSector}
                      onChange={changeUserSector}
                      variant="outlined"
                      name="sector"
                      label={<CommonLabel label="Sector" />}
                      className={`${classes.root} fullWidth customTextfield`}
                      disabled={userDetails?.sectorName != null ? true : false}
                    />
                  </Grid>
                  <Grid item xs={12} sm={4}>
                    <TextField
                      value={userLocation}
                      onChange={changeUserLocation}
                      variant="outlined"
                      name="location"
                      label={<CommonLabel label="Location" />}
                      className={`${classes.root} fullWidth customTextfield`}
                      disabled={userDetails?.location != null ? true : false}
                    />
                  </Grid>
                  <Grid item xs={12} sm={4}>
                    <TextField
                      value={userDepartment}
                      onChange={changeUserDepartment}
                      variant="outlined"
                      name="department"
                      label={<CommonLabel label="Department" />}
                      className={`${classes.root} fullWidth customTextfield`}
                      disabled={
                        userDetails?.departmentName != null ? true : false
                      }
                    />
                  </Grid>
                  <Grid item xs={12} sm={4}>
                    <TextField
                      value={contactDetails}
                      onChange={changeContactDetails}
                      variant="outlined"
                      name="contact-details"
                      label={<CommonLabel label="Contact details" />}
                      className={`${classes.root} fullWidth customTextfield`}
                      disabled={userDetails?.phoneNumber != null ? true : false}
                    />
                  </Grid>
                </Grid>
                <Grid item xs={12} sm={12} className="wallpaperCheckbox">
                  <CommonCheckBox
                    name="corporate-center"
                    handleChange={handleHODApprovalCheck}
                  />
                  <span style={styles.checkboxTitle}>HOD approval</span>
                </Grid>
                <Grid item xs={12} sm={12}>
                  {HODApprovalCheck && (
                    <>
                      <Grid item md={3} xs={12} className="uploadBtnStyle mt-3">
                        <div className="uploadBtn">
                          <label for="thumb1" className="btn uploadBtnLabel">
                            {thumbnail1
                              ? thumbnail1.name
                              : " HOD Approval Document"}{" "}
                            <span className="colRed">*</span>
                          </label>
                          <input
                            id="thumb1"
                            style={{
                              visibility: "hidden",
                              padding: "19px 16px",
                              position: "absolute",
                            }}
                            type="file"
                            accept=".png, .jpg, .svg, .pdf, .docx, .doc"
                            name="thumbnail1"
                            onChange={(event) => {
                              formik.setFieldValue(
                                "thumbnail1",
                                event.currentTarget.files[0]
                              );
                            }}
                          />
                        </div>
                        <span style={{ color: "#f44336" }}>
                          {formik.errors.thumbnail1 && formik.touched.thumbnail1
                            ? formik.errors.thumbnail1
                            : ""}
                        </span>
                      </Grid>
                    </>
                  )}
                  <SnackbarC
                    open={open8}
                    handleClose={handleClose}
                    message={errormessage(
                      "Please Select HOD Approval Document."
                    )}
                    autoHideDuration={autoHideDuration}
                  />
                </Grid>
              </div>
              <div className="line" />
              <Grid
                container
                direction="row"
                spacing={3}
                justifyContent="flex-end"
                className="rollBasedAutoCompplete wallPaperBtnContainerApproval"
                style={{ marginTop: "16px" }}
              >
                <Grid className="mb-0" item xs={4} sm="auto">
                  <SubmitButton
                    label="Cancel"
                    className="modalBackButton"
                    onClick={Cancel}
                  />
                </Grid>
                <Grid className="mb-0" item xs={8} sm="auto">
                  <SubmitButton
                    label="Submit for approval"
                    isLoader={submitSuccess}
                    className="rollBasedBtn wallPaperBtnApproval"
                    onClick={Submit}
                  />
                  <SnackbarC
                    open={open1}
                    handleClose={handleClose}
                    message={errormessage(
                      "Wallpaper Request Sent Successfully."
                    )}
                    autoHideDuration={autoHideDuration}
                  />
                </Grid>
              </Grid>
            </div>
          </Grid>
        </Grid>
      </Template>
    </div>
  );
}

const styles = {
  heading: {
    fontSize: "16px",
    
    fontWeight: "500",
    lineHeight: "1.5",
    color: "black",
    marginTop: "24px",
  },
  content: {
    fontSize: "12px",
    
    fontWeight: "500",
    lineHeight: "1.67",
    color: "#6d6e71",
    marginTop: "8px",
    marginBottom: "16px",
  },
  checkboxTitle: {
    fontSize: "16px",
    
    fontWeight: "400",
    lineHeight: "1.5",
    color: "#242424",
  },
  link: {
    
    fontSize: "12px",
    fontWeight: "600",
    color: "#e31837",
    lineHeight: "2",
    textTransform: "uppercase",
    padding: "8px",
  },
};
