import {
    GET_ALL_SECTORS_LOAD,
    GET_ALL_SECTORS_FAILED,
    GET_ALL_SECTORS_SUCCESS,
    GET_ALL_BUSINESS_UNITS_LOAD,
    GET_ALL_BUSINESS_UNITS_FAILED,
    GET_ALL_BUSINESS_UNITS_SUCCESS,
} from "../Contants/Constants";
  
  //all sectors
  const sectorsState = {
    getData: [],
    isLoading: false,
    isError: "",
  };
  
  const getRollBasedSectorsReducer = (state = sectorsState, action) => {
    switch (action.type) {
      case GET_ALL_SECTORS_LOAD:
        return {
          ...state,
          isLoading: true,
        };
      case GET_ALL_SECTORS_SUCCESS:
        return {
          ...state,
          getData: action.payload,
          isLoading: false,
        };
      case GET_ALL_SECTORS_FAILED:
        return {
          ...state,
          isError: action.payload,
          isLoading: false,
        };
      default:
        return state;
    }
  };
  
  //all business units
  const businessUnitState = {
    getData: [],
    isLoading: false,
    isError: "",
  };
  
  const getRollBasedBusinessUnitsReducer = (state = businessUnitState, action) => {
    switch (action.type) {
      case GET_ALL_BUSINESS_UNITS_LOAD:
        return {
          ...state,
          isLoading: true,
        };
      case GET_ALL_BUSINESS_UNITS_SUCCESS:
        return {
          ...state,
          getData: action.payload,
          isLoading: false,
        };
      case GET_ALL_BUSINESS_UNITS_FAILED:
        return {
          ...state,
          isError: action.payload,
          isLoading: false,
        };
      default:
        return state;
    }
  };
  
  //all functions
//   const functionsState = {
//     getData: [],
//     isLoading: false,
//     isError: "",
//   };
  
//   const getFunctionsReducer = (state = functionsState, action) => {
//     switch (action.type) {
//       case GET_ALL_FUNCTIONS_LOAD:
//         return {
//           ...state,
//           isLoading: true,
//         };
//       case GET_ALL_FUNCTIONS_SUCCESS:
//         return {
//           ...state,
//           getData: action.payload,
//           isLoading: false,
//         };
//       case GET_ALL_FUNCTIONS_FAILED:
//         return {
//           ...state,
//           isError: action.payload,
//           isLoading: false,
//         };
//       default:
//         return state;
//     }
//   };
  
  export { getRollBasedSectorsReducer, getRollBasedBusinessUnitsReducer };
  