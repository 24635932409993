import React, { useState } from "react";
import Button from "@material-ui/core/Button";
import { useMediaQuery, useTheme } from "@material-ui/core";
import "../MicroFeedback/MicroFeedback.css";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Drawer from "@material-ui/core/Drawer";
import FeedbackContent from "./FeedbackContent";
import Star from "../../Assets/Images/star.svg";
import { useSelector, useDispatch } from "react-redux";
import { RBoolean } from "../../Redux/Actions/MicrofeedbackAction";
import services from "../../Services";

export default function MicroFeedback() {
  const myState = useSelector((state) => state.changeBoolean);
  const dispatch = useDispatch();

  // const [feedBool, setfeedChange] = useState(true);
  const Bp = useTheme();
  const isXs = useMediaQuery(Bp.breakpoints.down("xs"));
  const [apiFeedbackError, setApiFeedbackError] = useState();
  const [state, setState] = useState({
    bottom: false,
  });

  const toggleDrawer = (anchor, open) => (event) => {
    setState({ ...state, [anchor]: open });
  };
  const handleFeedBackApi = async (data) => {
    try {
      const res = await services.api("POST", `/ui-assist/v1/feedback`, data);
      if (res) {
        //
      }
    } catch (err) {
      if (err.response) {
        setApiFeedbackError(true);
      }
    }
  };
  const list = (anchor) => (
    <div className="drawerFeedback" role="presentation">
      <FeedbackContent isXs={isXs} />
    </div>
  );


  // const handleClose = () => {
  //     setfeedChange(!feedBool);
  // };

  return (
    <>
      <Button
        variant="contained"
        className={myState ? `feedback` : `feedbackAfterClick`}
      >
        {isXs ? (
          <>
            {myState ? (
              <div onClick={() => dispatch(RBoolean())}>
                <img
                  style={{ marginRight: "10px",marginBottom:"5px",width:"22.8px",height:"21.7px" }}
                  src={Star}
                  alt="star"
                  onClick={toggleDrawer("bottom", true)}
                />
              </div>
            ) : (
              <>
                {["bottom"].map((anchor) => (
                  <React.Fragment key={anchor}>
                    <Drawer
                      anchor={anchor}
                      open={state[anchor]}
                      onClose={toggleDrawer(anchor, false)}
                    >
                      {list(anchor)}
                    </Drawer>
                  </React.Fragment>
                ))}
              </>
            )}
          </>
        ) : (
          <>
            {myState ? (
              <div
                className="inlineFeedback"
                onClick={() => dispatch(RBoolean())}
              >
                <img
                  style={{ marginRight: "7px", width: "16px", height: "16px" }}
                  src={Star}
                  alt="star"
                  onClick={toggleDrawer("bottom", true)}
                  className="imgStar"
                />
                <span className="textCapatalize">Feedback</span>
              </div>
            ) : (
              <>
                <Card className="cardMain">
                  <CardContent>
                    <FeedbackContent
                      handleFeedBackApi={(data) => handleFeedBackApi(data)}
                      apiFeedbackError={apiFeedbackError}
                    />
                  </CardContent>
                </Card>
              </>
            )}
          </>
        )}
      </Button>
    </>
  );
}
