import React, { useState, useEffect } from "react";
import "./SiteMap.css";
import { Card, CardContent, Grid } from "@material-ui/core";
import Template from "../../Common/Template/Template";
import SiteCard from "./SiteCard";
import { useMediaQuery, useTheme } from "@material-ui/core";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import services from "../../../Services";
import Loader from "../../Common/Loader/Loader";

export default function SiteMap() {
  const Bp = useTheme();
  const [config, setConfig] = useState([]);

  const [isLoading, setIsLoading] = useState(false);
  const isXs = useMediaQuery(Bp.breakpoints.down("xs"));
  //Real Estate
  const isMldl =
    JSON.parse(localStorage.getItem("userProfile")).sectorName ===
      "Real Estate Sector" &&
    JSON.parse(localStorage.getItem("userProfile")).sector === "52304758";

  const getSite = async () => {
    try {
      setIsLoading(true);
      let res = await services.api(
        "GET",
        `/ui-assist/v1/tiles/menutiles/${localStorage.getItem("token")}`
      );
      if (res.status === 200) {
        setIsLoading(false);
        if (isMldl) {
          setConfig(res.data?.filter((item) => item?.title !== "Profile"));
        } else {
          setConfig(res.data);
        }
      }
    } catch (err) {
      setConfig([]);
      setIsLoading(false);
      console.log("err from site", err);
    }
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    getSite();
  }, []);

  return (
    <section className="siteMapOuterContainer">
      {isLoading ? <Loader /> : null}
      <Template>
        <Grid
          container
          direction="row"
          justifyContent="space-between"
          alignItems="center"
        >
          <Grid item xs={12} sm={7}>
            <p className="offCHeaderTitle mbT">Sitemap</p>
          </Grid>
          {isXs ? null : (
            <>
              <Grid
                item
                xs={12}
                sm={5}
                style={{
                  display: "flex",
                  justifyContent: "flex-end",
                  alignItems: "center",
                  marginBottom: "38px",
                }}
              >
                {/* <div className="siteCrump">
                  VIEW POLICIES <KeyboardArrowRightIcon className="iconsC" />{" "}
                </div>
                <div className="siteCrump mlCB">
                  MY DETAILS <KeyboardArrowRightIcon className="iconsC" />{" "}
                </div> */}
              </Grid>
            </>
          )}
        </Grid>
        <SiteCard config={config} />
      </Template>
    </section>
  );
}
