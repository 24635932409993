import services from "../../Services/index";
import {
  GET_POLICY_CATEGORY_DATA_SUCCESS,
  GET_POLICY_CATEGORY_DATA_LOAD,
  GET_POLICY_CATEGORY_DATA_FAILED,
  GET_POLICY_CATEGORY_ID_DATA_SUCCESS,
  GET_POLICY_CATEGORY_ID_DATA_LOAD,
  GET_POLICY_CATEGORY_ID_DATA_FAILED,
  GET_POLICY_SUBCATEGORY_DATA_SUCCESS,
  GET_POLICY_SUBCATEGORY_DATA_LOAD,
  GET_POLICY_SUBCATEGORY_DATA_FAILED,
  GET_POLICY_ADMIN_SUBCATEGORY_DATA_SUCCESS,
  GET_POLICY_ADMIN_SUBCATEGORY_DATA_LOAD,
  GET_POLICY_ADMIN_SUBCATEGORY_DATA_FAILED,
  GET_POLICY_TABLE_DATA_SUCCESS,
  GET_POLICY_TABLE_DATA_LOAD,
  GET_POLICY_TABLE_DATA_FAILED,
} from "../Contants/Constants";

const getPolicyCategoryDataLoad = () => ({
  type: GET_POLICY_CATEGORY_DATA_LOAD,
});
const getPolicyCategoryDataSuccess = (data) => ({
  type: GET_POLICY_CATEGORY_DATA_SUCCESS,
  payload: data,
});
const getPolicyCategoryDataFailed = (error) => ({
  type: GET_POLICY_CATEGORY_DATA_FAILED,
  err: error,
});

const getPolicyCategoryIdDataLoad = () => ({
  type: GET_POLICY_CATEGORY_ID_DATA_LOAD,
});
const getPolicyCategoryIdDataSuccess = (data) => ({
  type: GET_POLICY_CATEGORY_ID_DATA_SUCCESS,
  payload: data,
});
const getPolicyCategoryIdDataFailed = (error) => ({
  type: GET_POLICY_CATEGORY_ID_DATA_FAILED,
  err: error,
});

export const getPolicyCategoryData = () => async (dispatch) => {
  dispatch(getPolicyCategoryDataLoad());
  try {
    const res = await services.api("GET", `/ui-assist/v1/policycategorys`, {});
    if (res) {
      dispatch(getPolicyCategoryDataSuccess(res.data));
      dispatch(getPolicySubCategoryData(res.data[0].categoryId));
    }
  } catch (err) {
    dispatch(getPolicyCategoryDataFailed(err.response));
  }
};

const getPolicySubCategoryDataLoad = () => ({
  type: GET_POLICY_SUBCATEGORY_DATA_LOAD,
});
const getPolicySubCategoryDataSuccess = (data) => ({
  type: GET_POLICY_SUBCATEGORY_DATA_SUCCESS,
  payload: data,
});
const getPolicySubCategoryDataFailed = (error) => ({
  type: GET_POLICY_SUBCATEGORY_DATA_FAILED,
  err: error,
});

export const getPolicySubCategoryData = (cat_id) => async (dispatch) => {
  dispatch(getPolicySubCategoryDataLoad());
  try {
    if (cat_id) {
      const res = await services.api(
        "GET",
        `/ui-assist/v1/policysubcategory/${cat_id}`,
        {}
      );
      let resp;
      resp = await Promise.all(
        res.data?.map(async (sub) => {
          var data = await services.api(
            "GET",
            `/ui-assist/v1/policy/${sub.policySubCategoryId}`
          );

          return { data: data.data, subcategory: sub };
        })
      );
      if (res && resp) {
        var subcategories = resp
          .filter((result) => result.data.length > 0)
          .map((item) => item.subcategory);

        dispatch(getPolicySubCategoryDataSuccess(subcategories));

        dispatch(
          getPolicyTableDataByID(
            subcategories.length > 0
              ? subcategories[0].policySubCategoryId
              : "No"
          )
        );
        return subcategories;
      }
    }
  } catch (err) {
    dispatch(getPolicySubCategoryDataFailed(err.response));
  }
};

const getPolicyAdminSubCategoryDataLoad = () => ({
  type: GET_POLICY_ADMIN_SUBCATEGORY_DATA_LOAD,
});
const getPolicyAdminSubCategoryDataSuccess = (data) => ({
  type: GET_POLICY_ADMIN_SUBCATEGORY_DATA_SUCCESS,
  payload: data,
});
const getPolicyAdminSubCategoryDataFailed = (error) => ({
  type: GET_POLICY_ADMIN_SUBCATEGORY_DATA_FAILED,
  err: error,
});

export const getPolicyAdminSubCategoryData = (cat_id) => async (dispatch) => {
  dispatch(getPolicyAdminSubCategoryDataLoad());
  try {
    if (cat_id) {
      const res = await services.api(
        "GET",
        `/ui-assist/v1/policysubcategory/${cat_id}`,
        {}
      );

      if (res) {
        dispatch(getPolicyAdminSubCategoryDataSuccess(res.data));
        dispatch(getPolicyTableDataByID(res.data[0].policySubCategoryId));
      }
    }
  } catch (err) {
    dispatch(getPolicyAdminSubCategoryDataFailed(err.response));
  }
};

const getPolicyTableDataLoad = () => ({
  type: GET_POLICY_TABLE_DATA_LOAD,
});
const getPolicyTableDataSuccess = (data) => ({
  type: GET_POLICY_TABLE_DATA_SUCCESS,
  payload: data,
});
const getPolicyTableDataFailed = (error) => ({
  type: GET_POLICY_TABLE_DATA_FAILED,
  err: error,
});

export const getPolicyTableDataByID = (subCat_id) => async (dispatch) => {
  dispatch(getPolicyTableDataLoad());
  try {
    if (subCat_id) {
      const res = await services.api(
        "GET",
        `/ui-assist/v1/policy/${subCat_id}`
      );
      if (res) {
        dispatch(getPolicyTableDataSuccess(res.data));
      }
    }
  } catch (err) {
    dispatch(getPolicyTableDataFailed(err.response));
  }
};

export const getPolicyFirstCategoryIdData = () => async (dispatch) => {
  dispatch(getPolicyCategoryIdDataLoad());
  try {
    const res = await services.api("GET", `/ui-assist/v1/policycategorys`, {});
    if (res) {
      console.log(res, "res>>>>>>>>>>>>>>>>>>>>>>>>>>>");
      dispatch(getPolicyCategoryIdDataSuccess(res.data));
    }
  } catch (err) {
    dispatch(getPolicyCategoryIdDataFailed(err.response));
  }
};
