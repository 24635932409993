import React from "react";
import "./ApprovalCenter.css";
import CommonModal from "../../Common/CommonModal/CommonModal";
import ApprovalCard from "./ApprovalCard";

export default function ApprovalDetails({
    open,
    handleClose,
    headerText,
    icon,
    modalSize,
    header,
    footer,
    fullWidth,
    onButtonClick,
}) {
    const handleCloseModal = () => {
        handleClose();
    };

    return (
        <section>
            <CommonModal
                open={open}
                handleClose={handleCloseModal}
                headerText={headerText}
                modalSize={modalSize}
                header={header}
                footer={footer}
                fullWidth={fullWidth}
                backgroundColor
                className="bannerModalEdit"
            >
                <ApprovalCard />
            </CommonModal>
        </section>
    );
}
