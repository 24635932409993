import Swal from "sweetalert2";
import services from "../../Services/index";
import {
  EDIT_SAPDOWNTIME_SUCCESS,
  EDIT_SAPDOWNTIME_LOAD,
  EDIT_SAPDOWNTIME_FAILED,
  POST_SAPDOWNTIME_SUCCESS,
  POST_SAPDOWNTIME_LOAD,
  POST_SAPDOWNTIME_FAILED
} from "../Contants/Constants";


//EDIT DOWN TIME DATA

const editDownTimeLoad = () => ({
  type: EDIT_SAPDOWNTIME_LOAD
});
const editDownTimeSuccess = (data) => ({
  type: EDIT_SAPDOWNTIME_SUCCESS,
  payload: data
});
const editDownTimeFailed = (error) => ({
  type: EDIT_SAPDOWNTIME_FAILED,
  err: error
});

export const editDownTimeData = (data) => async (dispatch) => {
  dispatch(editDownTimeLoad());
  try {
    const res = await services.api("PUT", `/ui-assist/v1/downtime`, data);
    if (res) {
      dispatch(editDownTimeSuccess(res.data));
    }
  } catch (err) {
    dispatch(editDownTimeFailed(err.response));
    Swal.fire("Something went wrong!", `${err?.response?.data}`, "error");
  }
};


//ADD DOWN TIME DATA

const addDownTimeLoad = () => ({
  type: POST_SAPDOWNTIME_LOAD
});
const addDownTimeSuccess = (data) => ({
  type: POST_SAPDOWNTIME_SUCCESS,
  payload: data
});
const addDownTimeFailed = (error) => ({
  type: POST_SAPDOWNTIME_FAILED,
  err: error
});

export const addDownTimeData = (data) => async (dispatch) => {
  dispatch(addDownTimeLoad());
  try {
    const res = await services.api("POST", `/ui-assist/v1/downtime`, data);
    if (res) {
      dispatch(addDownTimeSuccess(res.data));
    }
  } catch (err) {
    dispatch(addDownTimeFailed(err.response));
    Swal.fire("Something went wrong!", `${err?.response?.data}`, "error");
  }
};
