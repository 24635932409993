import services from "../../Services/index";
import Swal from "sweetalert2";
import {
  GET_WALLPAPER_REQUEST_SUCCESS,
  GET_WALLPAPER_REQUEST_LOAD,
  GET_WALLPAPER_REQUEST_FAILED,
} from "../Contants/Constants";

const getWallpaperRequestLoad = () => ({
  type: GET_WALLPAPER_REQUEST_LOAD,
});
const getWallpaperRequestSuccess = (data) => ({
  type: GET_WALLPAPER_REQUEST_SUCCESS,
  payload: data,
});
const getWallpaperRequestFailed = (error) => ({
  type: GET_WALLPAPER_REQUEST_FAILED,
  err: error,
});

export const getWallpaperRequestData = (formData) => async (dispatch) => {
  dispatch(getWallpaperRequestLoad());
  try {
    const res = await services.api("POST", `/ui-assist/v1/wallpaper`, formData);
    if (res) {
      dispatch(getWallpaperRequestSuccess(res?.data));
    }
    if (res.status === 201) {
      Swal.fire("Request Sent Successfully", " ", " ");
    } else {
      dispatch(getWallpaperRequestFailed(res?.data));
      Swal.fire("Oops", "Server error", "Request not sbmitted.");
    }
  } catch (err) {
    dispatch(getWallpaperRequestFailed(err?.response));
    Swal.fire("Something went wrong!", `${err?.response?.data}`, "error");
  }
};
