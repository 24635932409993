import Swal from "sweetalert2";
import services from "../../Services/index";
import {
    UPLOAD_FILE_SUCCESS,
    UPLOAD_FILE_LOAD,
    UPLOAD_FILE_FAILED,
} from "../Contants/Constants";

const getUploadFileLoad = () => ({
    type: UPLOAD_FILE_LOAD,
});
const getUploadFileSuccess = (data) => ({
    type: UPLOAD_FILE_SUCCESS,
    payload: data,
});
const getUploadFileFailed = (error) => ({
    type: UPLOAD_FILE_FAILED,
    err: error,
});

export const getUploadFileData = (data) => async (dispatch) => {
  dispatch(getUploadFileLoad());
  try {
    const res = await services.api(
      "POST",
      `/ui-assist/v1/upload/video`,data
    );
    if (res.status == 201) {
      dispatch(getUploadFileSuccess(res.data));
    }
    else
    { dispatch(getUploadFileFailed(res)); }
  } catch (err) {
    dispatch(getUploadFileFailed(err.response));
    Swal.fire("Something went wrong!", `${err?.response?.data}`, "error");
  }
};