import services from "../../Services/index";
import {
  GET_REMOTE_WORKING_LOAD,
  GET_REMOTE_WORKING_SUCCESS,
  GET_REMOTE_WORKING_FAILED,
  MODIFY_REMOTE_WORKING_SUCCESS,
  SET_DATE,
  EMPTY_REMOTE_DETAILS,
} from "../Contants/Constants";
import Swal from "sweetalert2";

// const { getData: records } = feedbackData;

const getRemoteWorkingLoad = () => ({
  type: GET_REMOTE_WORKING_LOAD,
});
const getRemoteWorkingSuccess = (data) => ({
  type: GET_REMOTE_WORKING_SUCCESS,
  payload: data,
});
const getRemoteWorkingFailed = (error) => ({
  type: GET_REMOTE_WORKING_FAILED,
  err: error,
});
const modifyRemoteWorkingSuccess = (data) => ({
  type: MODIFY_REMOTE_WORKING_SUCCESS,
  payload: data,
});

const emptiesRemoteWorkingDataSuccess = (data) => ({
  type: EMPTY_REMOTE_DETAILS,
  payload: data,
});
export const setRemoteWorkingFilterDateSuccess = (date) => ({
  type: SET_DATE,
  payload: date,
});

export const setRemoteWorkingFilterDate = (date) => async (dispatch) => {
  dispatch(setRemoteWorkingFilterDateSuccess(date));
};
export const getRemoteWorkingData =
  (type, date, sortOrder) => async (dispatch) => {
    dispatch(getRemoteWorkingLoad());
    try {
      let res = await services.api(
        "GET",
        `/ui-assist/v1/remoteworkings?type=${type}&date=${date}&sort=${sortOrder}`
      );
      if (res) {
        dispatch(getRemoteWorkingSuccess(res.data));
      }
    } catch (err) {
      dispatch(getRemoteWorkingFailed(err.response));
    }
  };

export const emptiesRemoteWorkingData = () => async (dispatch) => {
  dispatch(emptiesRemoteWorkingDataSuccess());
};

export const modifyRemoteWorkingData =
  (method, recordId, data) => async (dispatch) => {
    dispatch(getRemoteWorkingLoad());
    let id = recordId ? recordId : "";

    let url = `/ui-assist/v1/remoteworking/${id}`;
    if (method == "GET") {
      url = `/ui-assist/v1/remoteworkings/${id}`;
      try {
        let res = await services.api(method, url);
        if (res) {
          console.log(res, "res..................................");
          dispatch(modifyRemoteWorkingSuccess(res.data));
        }
      } catch (err) {
        dispatch(getRemoteWorkingFailed(err.response));
      }
    } else {
      let headers = { "Content-Type": "multipart/form-data" };
      try {
        let res = await services.api(method, url, data, false, headers);
        if (res) {
          console.log(res, "res..................................");
          dispatch(modifyRemoteWorkingSuccess(res.data));
        }
      } catch (err) {
        dispatch(getRemoteWorkingFailed(err.response));
      }
    }
  };
