import React, { useEffect, useState } from "react";
import { Grid } from "@material-ui/core";
import "./SearchPoliciesTableComponent.css";
import Template from "../../../Common/Template/Template";
import SearchBarButton from "../../../Common/SearchBar/SearchBarButton";
import PoliciesTableComponent from "../PoliciesTable/PoliciesTableComponent";
import arrowRight from "../../../../Assets/Images/collectiveIcons/arrow-right-s-line-white.svg";
import policiesData from "../policiesData.json";
import { useLocation, useHistory } from "react-router-dom";
import Swal from "sweetalert2";
import services from "../../../../Services";
import moment from "moment";
import { POLICIES, POLICIES_DETAILS } from "../../../../Routes/constant";
import { getFormattedDate } from "../../../../Assets/helperFunctions/helperFunctions";
import Highlighter from "react-highlight-words";

export default function SearchPoliciesTableComponent() {
  const location = useLocation();
  const history = useHistory();
  const [policies, setPolicies] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [placeholder] = React.useState("Search e.g. code of conduct");
  const [searchTerm, setSearchTerm] = useState("");
  const [highlightText, setHighlightText] = useState("");
  const [call, setCall] = useState(false);
  const [callAPI, setCallAPI] = useState(false);
  //fetching the policies data asd the query
  useEffect(() => {
    setIsLoading(true);
    try {
      (async () => {
        const res = await services.api(
          "GET",
          `/ui-assist/v1/policies/_search?q=${location?.state?.query}&page=1`
        );
        if (res.status === 200) {
          const { hits } = res.data.hits;
          let searchData = hits.map((el) => el._source);

          setIsLoading(false);
          setPolicies(searchData);
        }
      })();
    } catch (err) {
      setIsLoading(false);
      Swal.fire("Oops!", `${err.response.data}`, "error");
      setPolicies([]);
    }
  }, [location.state.query, call]);

  useEffect(() => {
    setHighlightText(location?.state?.query);
  }, [location?.state?.query]);

  //change handler for search
  const handleSearch = (e) => {
    const { value } = e.target;
    if (value) {
      setSearchTerm(value);
    } else if (value === "") {
      setSearchTerm("");
      setCall(!call);
    }
  };

  //useState for handeling the internal search
  useEffect(() => {
    if (callAPI) {
      const search = async () => {
        try {
          const res = await services.api(
            "GET",
            `/ui-assist/v1/policies/_search?q=${searchTerm} &page=1`
          );
          if (res.status === 200) {
            const { hits } = res.data.hits;
            let searchData = hits.map((el) => el._source);

            setIsLoading(false);
            setPolicies(searchData);
          }
        } catch (err) {
          setIsLoading(false);
          Swal.fire("Oops!", `${err.response.data}`, "error");
          setPolicies([]);
        }
      };

      //set timeout function
      // const expID = setTimeout(() => {
      //   if (searchTerm) {
      search();

      setCallAPI(false);
      //   }
      // }, 1000);

      //excute the cleanup function.
      // return () => {
      //   clearTimeout(expID);
      // };
    }
  }, [callAPI]);

  //policy redirection function.
  const handlePolicyClick = (el) => {
    history.push({
      pathname: `${POLICIES}${POLICIES_DETAILS}/?id=${el.policyId}`,
    });
  };

  const handleAPI = () => {
    setCallAPI(true);
  };

  const onKeyPress = (e) => {
    if (e.key === "Enter") {
      setCallAPI(true);
      setHighlightText(searchTerm);
    }
  };

  //render policies.
  const renderPolices = () => {
    return policies.map((el, index) => {
      return (
        <>
          <div className="policiestable HidePoliciesTableMbl">
            <div className="policiesdatascroll">
              <div className="Responsivescroll">
                <div className="policiesTableHead tableheadmhide">
                  <div className="policyName">Policy Name</div>
                  <div className="policyDescriptionHead ">Context</div>
                  <div className="policyLastUpdatedOn">Last Updated On</div>
                  <div className="policyDownload">Download</div>
                </div>
              </div>

              <div key={index} className="policiesTableContent">
                <div
                  style={{ cursor: "pointer" }}
                  className="policyName mpadd10"
                  onClick={() => {
                    handlePolicyClick(el);
                  }}
                >
                  <div className="policyheadm">Policy Name</div>
                  <Highlighter
                    highlightStyle={{ backgroundColor: "yellow" }}
                    searchWords={highlightText.split(" ")}
                    textToHighlight={el.policyName}
                  />
                </div>
                <div
                  style={{ cursor: "pointer" }}
                  className="policyDescription mpadd10"
                  onClick={() => {
                    handlePolicyClick(el);
                  }}
                >
                  <div className="policyheadm">Policy Context</div>
                  <Highlighter
                    highlightStyle={{ backgroundColor: "yellow" }}
                    searchWords={highlightText.split(" ")}
                    textToHighlight={el.objectives}
                  />
                </div>
                <div className="policyLastUpdatedOn">
                  <div className="policyheadm mpadd10">Effective Date</div>
                  <div className="policydate">
                    <Highlighter
                      highlightStyle={{ backgroundColor: "yellow" }}
                      searchWords={highlightText.split(" ")}
                      textToHighlight={
                        el?.modificationDate
                          ? getFormattedDate(el?.modificationDate)
                          : "Not Available"
                      }
                    />
                    {/* {moment(el?.effectiveDate).format("DD/MM/YYYY")} */}
                  </div>
                </div>
                <div
                  style={{ cursor: "pointer" }}
                  className="policyDownload mpadd10"
                  onClick={() =>
                    window.open(
                      `${process.env.REACT_APP_API_BASE_URL}/ui-assist/v1/download/true?fileName=${el.policy_pdf_link}`,
                      "_self"
                    )
                  }
                >
                  <div className="policyheadm">Download</div>
                  <img
                    src={
                      process.env.PUBLIC_URL +
                      "/policies/download-line-table.svg"
                    }
                    alt="Download line"
                  />
                </div>
              </div>
            </div>
          </div>
        </>
      );
    });
  };

  console.log("highlighted text ====>", highlightText);
  console.log("call api ======> ", callAPI);

  return (
    <>
      <div>
        <section>
          <Template>
            <Grid
              container
              direction="row"
              justifyContent="space-between"
              alignItems="center"
            >
              <Grid item xs={12} sm={7}>
                <p className="offCHeaderTitle">Policies</p>
              </Grid>
              <Grid item xs={12} sm={5}>
                <div className="policiesSearchWidth">
                  <SearchBarButton
                    placeholder={placeholder}
                    handleSearch={handleSearch}
                    handleSearchIcon={handleAPI}
                    onKeyPress={onKeyPress}
                  />
                  {/* onClick={() => history.push(`${POLICIES}${POLICIES_SEARCH}`)} */}
                </div>
              </Grid>
            </Grid>
            <div className="totlaPoliciesCount alignSearchTable">
              Showing{" "}
              <span className="highlightCount fw600">
                {policies ? policies.length : 0}
              </span>{" "}
              Result for{" "}
              <span className="highlightCount fw600">
                {searchTerm ? searchTerm : location?.state?.query}
              </span>
            </div>
            <div className="policyCountTable">{renderPolices()}</div>
          </Template>
        </section>
      </div>
    </>
  );
}
