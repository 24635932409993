import React from "react";

const TruncateGradeLabel = ({label}) => {
    let arr = label?.split("(");
    console.log("truncate array ====>", arr);
  return (
      <>{arr && arr[0]}</>
  );
};

export default TruncateGradeLabel;
