import {
    UPLOAD_IMAGE_SUCCESS,
    UPLOAD_IMAGE_LOAD,
    UPLOAD_IMAGE_FAILED,
} from "../Contants/Constants";

const initialState = {
  getData: [],
  isLoading: false,
  isError: "",
};

const getUploadImageReducer = (state = initialState, action) => {
  switch (action.type) {
    case UPLOAD_IMAGE_LOAD:
      return {
        ...state,
        isLoading: true,
      };
    case UPLOAD_IMAGE_SUCCESS:
      return {
        ...state,
        getData: action.payload,
        isLoading: false,
      };
    case UPLOAD_IMAGE_FAILED:
      return {
        ...state,
        isError: action.payload,
        isLoading: false,
      };
    default:
      return state;
  }
};

export default getUploadImageReducer;
