import {
    GET_REJECT_WALLPAPER_REQUEST_SUCCESS,
    GET_REJECT_WALLPAPER_REQUEST_LOAD,
    GET_REJECT_WALLPAPER_REQUEST_FAILED,
} from "../Contants/Constants";

const initialState = {
  getData: [],
  isLoading: false,
  isError: "",
};

const getRejectWallpaperRequestReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_REJECT_WALLPAPER_REQUEST_LOAD:
      return {
        ...state,
        isLoading: true,
      };
    case GET_REJECT_WALLPAPER_REQUEST_SUCCESS:
      return {
        ...state,
        getData: action.payload,
        isLoading: false,
      };
    case GET_REJECT_WALLPAPER_REQUEST_FAILED:
      return {
        ...state,
        isError: action.payload,
        isLoading: false,
      };
    default:
      return state;
  }
};

export default getRejectWallpaperRequestReducer;
