import React, { useEffect, useState } from "react";
import { Grid } from "@material-ui/core";
import CharLeftField from "../../../../Common/CharLeftField/CharLeftField";
import CommonLabel from "../../../../Common/ComonLabel/CommonLabel";
import PLUS_ICON from "../../../../../Assets/Images/Plus.svg";
import BIN_ICON from "../../../../../Assets/Images/delete-bin-line.svg";
// import uploadIcon from "../../../../../Assets/Images/collectiveIcons/upload-2-line.svg";

// import Tabs from "@material-ui/core/Tabs";
// import Tab from "@material-ui/core/Tab";
// import SearchBarButton from "../../../../Common/SearchBar/SearchBarButton";
// import PropTypes from "prop-types";
// import Typography from "@material-ui/core/Typography";
// import Box from "@material-ui/core/Box";
import SubmitButton from "../../../../Common/Button/SubmitButton";
// import MultiSelect from "../../../../Common/MultiSelect/MultiSelect";
import CommonAutocomplete from "../../../../Common/CommonAutocomplete/CommonAutocomplete";
import CustomMultiSelect from "../../../../Common/CustomMultiSelect/CustomMultiSelect";
// import ImageComponent from "./ImageComponent";

// import { useFormik } from "formik";
// import { editSupport } from "../../../../../Assets/formikErrors/SubmitOfferCampaignSchema";

const ITSupportAdminForm = ({
  handleChange,
  //   handleChangePhoneData,
  handleAddPhoneData,
  handleDeletePhoneData,
  //   phoneNumbers,
  handleSubmit,
  iconOpt,
  detailsAction,
  // detailsActionAdd,
  // detailsActionAdd,
  // titleText,
  //   emails,
  //   handleDeleteEmailData,
  //   handleAddEmailData,
  uniqueIndex,
  // hideAddServices,
  // handleChangeTab,
  // tab,
  handleDeleteServices,
  // getDivisionList,
  getSectorList,
  handleSectorBuList,
  categoryList,
  getDivisionListEdit,
  handleValidEdit,
  getMandatoryFieldsEdit,
  getOptionLabel,
  handleToggleOption,
  handleClearOptions,
  handleSelectAll,
  // TabPanel,
  // hideModelFlag2,
  // setHideModelFlag2,
  // handleAddIcon,
  //   handleChangeEmail,
}) => {
  // const [hideModelFlag, setHideModelFlag] = React.useState(false);
  const [getApiImage, setGetApiImage] = useState(null);
  const [getImage, setGetImage] = useState(null);
  const {
    title,
    description,
    actionName,
    actionUrl,
    // phoneData,
    // emails,
    phoneNumbers,
    emails,
    image,
    // icon,
    // websites,
    websites,
    sectorList,
    buList,
    category,
    errorObj,
  } = detailsAction[uniqueIndex] || {};

  let getSector = sectorList.map((result) => {
    if (result.title) {
      return {
        title: result.title,
        value: result.title,
        sectorCode: result.sectorCode,
      };
    } else {
      return {
        title: result.sectorName,
        value: result.sectorName,
        sectorCode: result.sectorId,
      };
    }
  });
  let getBu = buList.map((result) => {
    if (result.title) {
      return {
        title: result.title,
        value: result.title,
        buId: result.buId ? result.buId : result.id,
      };
    } else {
      return {
        title: result.buName,
        value: result.buName,
        buId: result.buId ? result.buId : result.id,
      };
    }
  });

  useEffect(() => {
    if (image?.name) {
      setGetImage(URL.createObjectURL(image));
    } else {
      setGetApiImage(image);
    }
  }, [image]);

  return (
    <Grid container alignItems="center">
      <Grid item md={12} xs={12} className="contentPadding">
        <span className="Complete-Tax-Declara">Details</span>
      </Grid>
      <Grid item md={4} xs={12} className="contentPadding">
        <CharLeftField
          label={<CommonLabel label="Title" mandatory />}
          name="title"
          type="text"
          value={title}
          handleChange={(e) => handleChange(e, "", uniqueIndex, "")}
          errorMsg={errorObj.title ? errorObj.title : ""}
        />
      </Grid>

      <Grid item md={6} xs={12} className="contentPadding">
        <div className="uploadBtn">
          <CharLeftField
            name="image"
            type="file"
            handleChange={(e) => handleChange(e, "", uniqueIndex, "")}
          />
        </div>
        {/* <div
          className="upperCharLeft"
          onClick={() => {
            setHideModelFlag2(!hideModelFlag2);
          }}
        >
          <CharLeftField
            label={<CommonLabel label="Icon" mandatory />}
            name="icon"
            type="text"
            value={image ? (image.name ? image.name : image) : icon}
          />
        </div> */}
        {/* {hideModelFlag2 ? (
          <div className="customSelectBox">
            <Tabs
              onChange={(event, newValue) =>
                handleChangeTab(event, newValue, uniqueIndex, "edit")
              }
              value={tab}
            >
               <Tab label="Select an icon" className="favTabs" /> 
              <Tab label="Upload an image" className="favTabs" />
            </Tabs> */}
        {/* <TabPanel value={tab} index={0}>
              <Grid container>
                <Grid item md={12} xs={12}>
                  <SearchBarButton placeholder="Search article"></SearchBarButton>
                </Grid>
                <Grid item md={12} className="mt25 addScrollCls">
                  {iconOpt.map((item, index) => (
                    <div
                      key={index}
                      className="optRoot"
                      onClick={() => handleAddIcon(item, uniqueIndex, "edit")}
                    >
                      <img
                        src={item.image}
                        alt=""
                        className="supportServicesIcon"
                      />
                      <div className="Tasks-that-need-your">{item.name}</div>
                    </div>
                  ))}
                </Grid>
              </Grid>
            </TabPanel> */}
        {/* <TabPanel value={tab} index={0}>
              <div className="uploadImg">
                <label for="img" className="btn uploadBtnLabel">
                  <span className="colRed">CHOOSE AN IMAGE</span>
                </label>
                <input
                  id="img"
                  style={{
                    visibility: "hidden",
                    padding: "19px 16px",
                    position: "absolute",
                  }}
                  type="file"
                  accept=".png, .jpg"
                  name="image"
                  onChange={(e) => handleChange(e, "", uniqueIndex, "image")}
                />
              </div>
              <div className="recommendatinTxt">
                Recommended size is 280 x 280 pixels
              </div>
            </TabPanel>
          </div>
        ) : null} */}
      </Grid>
      <Grid item md={2} xs={12}>
        <img
          src={getImage ? getImage : getApiImage ? getApiImage : null}
          alt="no existing image"
          className="existingImage"
        />
      </Grid>
      <Grid item md={4} xs={12} className="contentPadding">
        <CommonAutocomplete
          className="width100"
          selectedValue={
            typeof category === "string" || category instanceof String
              ? { title: category, value: category }
              : category
          }
          handleSelectChange={(e, newValue) => {
            handleSectorBuList(e, newValue, uniqueIndex, "category");
          }}
          options={categoryList?.length && categoryList}
          label={<CommonLabel label="Category" mandatory />}
          disabled={true}
        />
      </Grid>
      <Grid item md={8} xs={12} className="contentPadding">
        <CharLeftField
          label={<CommonLabel label="Description" mandatory />}
          name="description"
          type="text"
          value={description}
          handleChange={(e) => handleChange(e, "", uniqueIndex, "")}
          errorMsg={errorObj.description ? errorObj.description : ""}
        />
      </Grid>
      <Grid item md={6} xs={6} className="contentPadding iTMulti">
        {/* <MultiSelect
          className="width100"
          handleSelectChange={(e, newValue) => {
            handleSectorBuList(e, newValue, uniqueIndex, "sectorList");
          }}
          // selectedValue={JSON.parse(sectorList)}
          // options={getSectorList?.length && getSectorList}
          options={getSectorList?.length && getSectorList}
          name="sectorList"
          label={<CommonLabel label="Sector" mandatory />}
        /> */}
        <CustomMultiSelect
          disabled={!getSectorList?.length}
          items={getSectorList?.length && getSectorList}
          getOptionLabel={getOptionLabel}
          // getOptionDisabled={getOptionDisabled}
          selectedValues={getSector}
          name="sectorList"
          label={<CommonLabel label="Sector" mandatory />}
          selectAllLabel="Select all"
          onToggleOption={(data) =>
            handleToggleOption(data, "sectorList", uniqueIndex, "edit")
          }
          onClearOptions={() =>
            handleClearOptions("sectorList", uniqueIndex, "edit")
          }
          onSelectAll={(data) =>
            handleSelectAll(data, "sectorList", uniqueIndex, "edit")
          }
        />
      </Grid>
      <Grid item md={6} xs={6} className="contentPadding iTMulti">
        {/* <MultiSelect
          className="width100"
          handleSelectChange={(e, newValue) =>
            handleSectorBuList(e, newValue, uniqueIndex, "buList")
          }
          selectedValue={JSON.parse(buList)}
          disabled={!getDivisionListEdit?.length}
          options={getDivisionListEdit?.length && getDivisionListEdit}
          name="buList"
          // options={getDivisionList?.length && getDivisionList}
          label={<CommonLabel label="Business unit" mandatory />}
        /> */}
        <CustomMultiSelect
          disabled={!getDivisionListEdit?.length}
          items={getDivisionListEdit?.length && getDivisionListEdit}
          getOptionLabel={getOptionLabel}
          // getOptionDisabled={getOptionDisabled}
          selectedValues={getBu}
          name="buList"
          label={<CommonLabel label="Business unit" mandatory />}
          // placeholder="Select category"
          selectAllLabel="Select all"
          onToggleOption={(data) =>
            handleToggleOption(data, "buList", uniqueIndex, "edit")
          }
          onClearOptions={() =>
            handleClearOptions("buList", uniqueIndex, "edit")
          }
          onSelectAll={(data) =>
            handleSelectAll(data, "buList", uniqueIndex, "edit")
          }
        />
      </Grid>
      <Grid item md={12} xs={12} className="contentPadding">
        <span className="Complete-Tax-Declara">Contacts</span>
      </Grid>
      <Grid item md={12} xs={12} className="contentPadding">
        <div className="contactContainer">
          <div className="contactsField">Phone number</div>
          <span
            style={{ display: "flex", cursor: "pointer" }}
            onClick={(e) => handleAddPhoneData(e, uniqueIndex, "phoneNumbers")}
          >
            <img src={PLUS_ICON} alt="" />
            <div className="add">ADD</div>
          </span>
        </div>
      </Grid>
      {phoneNumbers?.length ? (
        <>
          {phoneNumbers.map((item, index) => (
            <Grid container key={index}>
              <Grid item md={4} xs={12} className="contentPadding">
                <CharLeftField
                  label={<CommonLabel label="Name" />}
                  name="name"
                  value={item.name}
                  handleChange={(e) =>
                    handleChange(e, index, uniqueIndex, "phoneNumbers")
                  }
                  type="text"
                  errorMsg={
                    errorObj.phoneNumbers[index].name
                      ? errorObj.phoneNumbers[index].name
                      : ""
                  }
                />
              </Grid>
              <Grid item md={4} xs={12} className="contentPadding">
                <CharLeftField
                  label={<CommonLabel label="Phone number" />}
                  name="phoneNumber"
                  // value={phoneNumber}
                  handleChange={(e) =>
                    handleChange(e, index, uniqueIndex, "phoneNumbers")
                  }
                  value={item.phoneNumber}
                  type="text"
                  errorMsg={
                    errorObj.phoneNumbers[index].phoneNumber
                      ? errorObj.phoneNumbers[index].phoneNumber
                      : ""
                  }
                />
              </Grid>
              <Grid item md={3} xs={12} className="contentPadding">
                <CharLeftField
                  label={<CommonLabel label="Extention" />}
                  name="extension"
                  value={item.extension}
                  handleChange={(e) =>
                    handleChange(e, index, uniqueIndex, "phoneNumbers")
                  }
                  //   value={item.extn}
                  type="text"
                  errorMsg={
                    errorObj.phoneNumbers[index].extension
                      ? errorObj.phoneNumbers[index].extension
                      : ""
                  }
                />
              </Grid>
              <Grid
                style={{ cursor: "pointer" }}
                item
                md={1}
                xs={12}
                className="contentPadding centerIcon"
                onClick={() =>
                  handleDeletePhoneData(index, uniqueIndex, "phoneNumbers")
                }
              >
                <img
                  src={BIN_ICON}
                  alt="Delete Button"
                  //   onClick={() => contactData.pop()}
                />
              </Grid>
            </Grid>
          ))}
        </>
      ) : null}

      <Grid item md={12} xs={12} className="contentPadding">
        <div className="contactContainer">
          <div className="contactsField">Email ID</div>
          <span
            style={{ display: "flex", cursor: "pointer" }}
            onClick={(e) => handleAddPhoneData(e, uniqueIndex, "emails")}
          >
            <img src={PLUS_ICON} alt="" />
            <div className="add">ADD</div>
          </span>
        </div>
      </Grid>
      {emails?.length ? (
        <>
          {emails.map((item, index) => (
            <Grid container key={index}>
              {/* <Grid item md={4} xs={12} className="contentPadding">
                <CharLeftField
                  label={<CommonLabel label="Email title" />}
                  name="emailTitle"
                  value={item.emailTitle}
                  handleChange={(e) =>
                    handleChange(e, index, uniqueIndex, "emails")
                  }
                  type="text"
                />
              </Grid> */}
              <Grid item md={4} xs={12} className="contentPadding">
                <CharLeftField
                  label={<CommonLabel label="Email" />}
                  name="emailId"
                  value={item !== [] ? item : ""}
                  handleChange={(e) =>
                    handleChange(e, index, uniqueIndex, "emails")
                  }
                  // value={item.phoneNumber}
                  type="text"
                  errorMsg={
                    errorObj.emails[index] ? errorObj.emails[index] : ""
                  }
                />
              </Grid>
              <Grid
                style={{ cursor: "pointer" }}
                item
                md={1}
                xs={12}
                className="contentPadding centerIcon"
                onClick={() =>
                  handleDeletePhoneData(index, uniqueIndex, "emails")
                }
              >
                <img
                  src={BIN_ICON}
                  alt="Delete Button"
                  //   onClick={() => contactData.pop()}
                />
              </Grid>
            </Grid>
          ))}
        </>
      ) : null}
      <Grid item md={12} xs={12} className="contentPadding">
        <div className="contactContainer">
          <div className="contactsField">Website</div>
          <span
            style={{ display: "flex", cursor: "pointer" }}
            onClick={(e) => handleAddPhoneData(e, uniqueIndex, "websites")}
          >
            <img src={PLUS_ICON} alt="" />
            <div className="add">ADD</div>
          </span>
        </div>
      </Grid>
      {websites?.length ? (
        <>
          {websites.map((item, index) => (
            <Grid container key={index}>
              {/* <Grid item md={4} xs={12} className="contentPadding">
                <CharLeftField
                  label={<CommonLabel label="Website name" />}
                  name="websiteName"
                  value={item.websiteName}
                  handleChange={(e) =>
                    handleChange(e, index, uniqueIndex, "websites")
                  }
                  type="text"
                />
              </Grid> */}
              <Grid item md={4} xs={12} className="contentPadding">
                <CharLeftField
                  label={<CommonLabel label="Url" />}
                  name="url"
                  value={item !== [] ? item : ""}
                  handleChange={(e) =>
                    handleChange(e, index, uniqueIndex, "websites")
                  }
                  // value={item.phoneNumber}
                  type="text"
                  errorMsg={
                    errorObj.websites[index] ? errorObj.websites[index] : ""
                  }
                />
              </Grid>
              <Grid
                style={{ cursor: "pointer" }}
                item
                md={1}
                xs={12}
                className="contentPadding centerIcon"
                onClick={() =>
                  handleDeletePhoneData(index, uniqueIndex, "websites")
                }
              >
                <img
                  src={BIN_ICON}
                  alt="Delete Button"
                  //   onClick={() => contactData.pop()}
                />
              </Grid>
            </Grid>
          ))}
        </>
      ) : null}
      <Grid item md={12} xs={12} className="contentPadding">
        <span className="Complete-Tax-Declara">Action</span>
      </Grid>
      <Grid item md={6} xs={12} className="contentPadding">
        <CharLeftField
          label={<CommonLabel label="Action Name" mandatory />}
          name="actionName"
          type="text"
          value={actionName}
          handleChange={(e) => handleChange(e, "", uniqueIndex, "")}
          errorMsg={errorObj.actionName ? errorObj.actionName : ""}
        />
      </Grid>
      <Grid item md={6} xs={12} className="contentPadding">
        <CharLeftField
          label={<CommonLabel label="Action URL" mandatory />}
          name="actionUrl"
          value={actionUrl}
          handleChange={(e) => handleChange(e, "", uniqueIndex, "")}
          type="text"
          errorMsg={errorObj.actionUrl ? errorObj.actionUrl : ""}
        />
      </Grid>
      <Grid container alignItems="center" justifyContent="flex-end">
        <Grid
          item
          md={2}
          xs={12}
          className="contentPadding"
          onClick={() => handleDeleteServices(detailsAction[uniqueIndex])}
        >
          <span className="Delete-Service">DELETE SERVICE</span>
        </Grid>
        <Grid item md={2} xs={12} className="contentPadding">
          <SubmitButton
            disabled={
              getMandatoryFieldsEdit(detailsAction[uniqueIndex]) ||
              handleValidEdit(detailsAction[uniqueIndex].errorObj)
            }
            className={`${
              getMandatoryFieldsEdit(detailsAction[uniqueIndex]) ||
              handleValidEdit(detailsAction[uniqueIndex].errorObj)
                ? ""
                : "MCsubmitbutton"
            } floatRightAlign`}
            onClick={() => handleSubmit(detailsAction[uniqueIndex], "edit")}
            label="Save changes"
            // className="MCsubmitbutton floatRightAlign"
          />
        </Grid>
      </Grid>
    </Grid>
  );
};

export default ITSupportAdminForm;
