import React from "react";
import { Switch } from "react-router-dom";
import FilterSearchDataComponent from "../../Components/Header/UniversalSearch/FilterSearchDataComponent";
import { SEARCH } from "../constant";
import { ApmRoute } from "@elastic/apm-rum-react";

export default function UniversalSearchRoutes() {
  return (
    <div>
      <Switch>
        <ApmRoute
          exact
          path={`${SEARCH}`}
          component={FilterSearchDataComponent}
        />
      </Switch>
    </div>
  );
}
