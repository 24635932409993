import React, { useEffect, useState } from "react";
import SubmitButton from "../../../Common/Button/SubmitButton";
import "./PoliciesSearchComponent.css";
import arrowRight from "../../../../Assets/Images/collectiveIcons/arrow-right-s-line-white.svg";
import Loader from "../../../Common/Loader/Loader";
import services from "../../../../Services/index";
import Template from "../../../Common/Template/Template";
import Swal from "sweetalert2";
import { useLocation } from "react-router-dom";
import { convertStringEscapedToFormatted } from "../../../../Assets/helperFunctions/helperFunctions";
export default function PoliciesSearchComponent() {
  const location = useLocation();
  const queryParams = new URLSearchParams(window.location.search);
  const id = queryParams.get("id");

  const [isLoading, setIsLoading] = useState(false);
  const [policy, setPolicy] = useState({});

  useEffect(() => {
    setIsLoading(true);
    try {
      (async () => {
        const res = await services.api(
          "GET",
          `/ui-assist/v1/policybyid/${
            location.state?.id ? location.state?.id : id
          }`
        );
        if (res.status === 200) {
          setIsLoading(false);
          setPolicy(res.data);
        }
      })();
    } catch (err) {
      setIsLoading(false);
      Swal.fire("Oops!", `${err.response.data}`, "error");
      setPolicy({});
    }
  }, []);

  return (
    <Template pageName={"Mahindra Collective"}>
      {isLoading ? <Loader /> : ""}
      {/* <div className="slidingArrowSearchTemp">
        <span className="iconArrow">
          <img src={arrowRight} alt="Download line" />
        </span>
      </div> */}
      <div className="commonTemplate">
        <p className="searchPoliciesName">{policy?.policyName}</p>
        <div className="policiesSearchTemplate">
          <div className="policiesSearchHead">
            <div className="policySearchName">Context</div>
            <div className="policySearchDescription">{policy?.objectives}</div>
          </div>
          {/* <div className="policiesSearchHead">
            <div className="policySearchName">Key</div>
            <div className="policySearchDescription">{policy?.keys?.join(",")}</div>
          </div> */}
          {/*
          <div className="policiesSearchHead">
            <div className="policySearchName">Description</div>
            <div className="policySearchDescription">{policy?.description}</div>
          </div>
          */}
          <div className="policiesSearchHead">
            <div className="policySearchName">Eligibility</div>
            <div className="policySearchDescription">{policy?.eligibility}</div>
          </div>
          {/*<div className="policiesSearchHead">
          <div className="policySearchName">Limits</div>
          <div className="policySearchDescription">
            L3Ex Grade limit (excluding GST) is Rs. 50,000 or latest model of
            iphone. Charged every three years.
          </div>
  </div> */}
          <div className="policiesSearchHead">
            <div className="policySearchName">General Guidelines</div>
            <div className="policySearchDescription">
              {/* <ul>
              <li>
                The employee will be free to choose any model, as per the limit
                specified for their grades. Those who want to opt for higher
                model will have to pay for the excess amount.
              </li>
              <li>
                The invoice should be in the name of the company with GST
                registration number. The employee can claim reimbursement on
                production of the relevant bills.
              </li>
              <li>
                The cost of any additional accessories will have to be borne by
                the employee.
              </li>
              <li>The mobile instrument can be replaced once in 3 years.</li>
              <li>
                However, if it needs a replacement earlier due to wear and tear
                or technical malfunction, the instrument could be replaced by
                obtaining a special approval from Sector HR Head in which case
                faulty instrument will have to be surrendered to the company.
              </li>
              <li>
                In case the handset gets lost, stolen, misplaced during this
                tenure, the employee can purchase a new handset in which case
                20% cost will be borne by the employee and balance 80% will be
                borne by the company, subject to approval from Sector HR Head.
                The tenure will begin from the date of purchase of the new
                handset.
              </li>
              <li>
                The cost incurred for any repair during the 3-year tenure, will
                have to be borne by the employee.
              </li>
              <li>
                At the end of the 3-year tenure of the mobile handset, the
                employee has to buyback at book value.
              </li>
              <li>
                In case of cessation of employment before completion of tenure
                of the handset, the book value of the handset will be recovered
                from the settlement dues.
              </li>
           </ul>*/}
              {convertStringEscapedToFormatted(policy?.generalGuidLines)}
              {/* {policy?.generalGuidLines} */}
            </div>
          </div>
          <div className="policiesSearchHead alignPDFButton">
            <div className="PDFPosition">
              <SubmitButton
                onClick={() =>
                  window.open(
                    `${process.env.REACT_APP_API_BASE_URL}/ui-assist/v1/download/true?fileName=${policy?.policy_pdf_link}`,
                    "_blank"
                  )
                }
                label="DOWNLOAD PDF"
                className="downloadPDF"
              />
            </div>
          </div>
        </div>
      </div>
    </Template>
  );
}
