import React from "react";
import "./CommonCard.css";
import { Card, CardContent, CardMedia, CardActions } from "@material-ui/core";

import IconButton from "@material-ui/core/IconButton";
import Avatar from "@material-ui/core/Avatar";
import PlayButton from "../../../Assets/Images/play_button.svg";

// image import
import shareIcon from "../../../Assets/Images/share-line.svg";
import heartIcon from "../../../Assets/Images/heart-3-line.svg";

export default function CommonCard({ dataObj }) {
  const item = dataObj;
  // console.log("dataObj", dataObj);

  const playButon = (data) => {
    if (data.video) {
      return (
        <div className="gradient playImgContainer">
          <img
            className="centerAlign posAbsolute pointer playButton"
            src={PlayButton}
            alt="no group"
          />
        </div>
      );
    } else return null;
  };

  return (
    <>
      <Card className="cardShadow commonCardStyle">
        <CardMedia
        style={{
          position: "relative",
          display: "flex",
          justifyContent: "center",
        }}
        >
          <div className="imgCardContaier">
            <img
              src={process.env.PUBLIC_URL + item.img}
              alt="header logo"
              className={`commonCardImage ${item.video ? "gradient" : ""}`}
            />
          </div>
          <>{playButon(item)}</>
        </CardMedia>
        <CardContent className="cardContentStyle">
          <div className="cardTitle">{item.title}</div>
          <div className="cardDescriptionTxt">{item.description}</div>
        </CardContent>
        <CardActions className="">
          <div className="cardActionContainer">
            <div className="avatarContainer">
              <div className="avatarIcon">
                <Avatar aria-label="recipe">
                  <img src={item.avatarImg} alt="" />
                </Avatar>
              </div>
              <div className="avatarDetails">
                <div className="avatarName">{item.avatarName}</div>
                <div className="dateContainer">{item.date}</div>
              </div>
            </div>
            <div className="actionItemBtn">
              <IconButton aria-label="share">
                <img src={shareIcon} alt="" />
              </IconButton>
              <IconButton aria-label="add to favorites">
                <img src={heartIcon} alt="" />
              </IconButton>
            </div>
          </div>
        </CardActions>
      </Card>
    </>
  );
}
