import { Grid, Icon } from "@material-ui/core";
import React, { useState } from "react";
import Autosuggest from "react-autosuggest";
import searchIcon from "../../../Assets/Images/search_line.svg";
import axios from "axios";
import SearchBarButton from "../../Common/SearchBar/SearchBarButton";
import CommonLabel from "../../Common/ComonLabel/CommonLabel";
import "./UniversalSearchComponent.css";
import Checkbox from "@material-ui/core/Checkbox";
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";
import CheckBoxOutlineBlankIcon from "@material-ui/icons/CheckBoxOutlineBlank";
import CheckBoxIcon from "@material-ui/icons/CheckBox";
import CustomMultiSelect from "../../Common/CustomMultiSelect/CustomMultiSelect";
import InputAdornment from "@material-ui/core/InputAdornment";

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

// Top 100 films as rated by IMDb users. http://www.imdb.com/chart/top
const top100Films = [
  {
    title: "All business",
    year: 1972,
  },
  {
    title: "Business groups",
    year: 1972,
    // child: [
    //     { title: "Aerospace", value: "Aerospace" },
    //     { title: "Aftermarket", value: "Aftermarket" },
    // ],
  },
  {
    title: "Corporate initiatives",
    year: 1972,
  },
];
export default function UniversalSearchComponent({
  
  handleSelectChange,
  searchOptions,
  handleSearchIcon,
  handleCloseSearch,
  handleSearch,
 
  selectedOptions,
  getOptionLabel,
  getOptionDisabled,
  handleToggleOption,
  handleClearOptions,
  handleSelectAll,
  options,
  setSearchText,
  searchText
}) {
  //checkbox
  const focusStyle = { color: "#db233a" };
  const [focus, setFocus] = useState(false);
  //const [searchText, setSearchText] = React.useState("");
  const [suggestions, setSuggestions] = React.useState([]);
  //handle keyPressValue
  const onKeyPress = (e) => {
    //console.log('search'+e.key);
   // handleSearch();
   
    if (e.key === "Enter") {
      //console.log('search');
      handleSearchIcon();
    }
    //handleSearchIcon();
  };
console.log(searchText, 'value................')
console.log(suggestions, 'suggestions................')
  return (
    <div>
      <Grid container spacing={5} className="universalSearchMultiselectParent">
        <Grid item md={4} xs={12} className="universalSearchMultiselect">
          <CustomMultiSelect
            items={options}
            getOptionLabel={getOptionLabel}
            getOptionDisabled={getOptionDisabled}
            selectedValues={selectedOptions}
            label="Select category"
            // placeholder="Search category"
            selectAllLabel="Select all"
            onToggleOption={handleToggleOption}
            onClearOptions={handleClearOptions}
            onSelectAll={handleSelectAll}
          />
        </Grid>
        <Grid item md={8} xs={12}>
          <div className="alignUSearch">
            
           {/*} <SearchBarButton
              handleSearchIcon={handleSearchIcon}
              handleSearch={handleSearch}
              searchText={searchText}
              onKeyPress={onKeyPress}
            />*/}
            <InputAdornment className="searchicontop" position="start" onClick={handleSearchIcon} style = {{cursor: 'pointer'}}>
              <img src={searchIcon} alt="searchIcon" />
            </InputAdornment>
            <Autosuggest
              suggestions={suggestions}
             
              onSuggestionsFetchRequested={async ({ value }) => {
                if (!value) {
                  setSuggestions([]);
                  return;
                }
{/* `http://localhost/test.php`*/}
                try {
                  const response = await axios.get(
                    `/ui-assist/v1/search/history?key=${value}`
                  );

                  setSuggestions(
                    response.data.map(row => ({
                      name: row.query
                    }))
                  );
                } catch (e) {
                  setSuggestions([]);
                }
              }}
              onSuggestionsClearRequested={() => {
                setSuggestions([]);
              }}
              getSuggestionValue={suggestion => suggestion.name}
              renderSuggestion={suggestion => (
                <div className="suggestionlist">
                  
                  {suggestion.name}
                </div>
              )}
              onSuggestionSelected={(event, { suggestion, method }) => {
                setSearchText(suggestion.name);
                if (method == "enter") {

                // event.preventDefault();
                 
                // handleSearchIcon();
                }else if(method == 'click'){
                 // handleSearchIcon();
                }
              //  console.log(method);
                
                
              }}

              inputProps={{
                placeholder: "Search people, story, policy, org announcements, etc ",
                autoComplete: "off",
               onKeyDown:onKeyPress,
                value: searchText,
                
                name: "search",
                id:"outlined-basic",
                onChange: (_event, { newValue }) => {
                  setSearchText(newValue);
                },
                
                
              }}
            />









            <span onClick={handleCloseSearch}>
              <img
                src={process.env.PUBLIC_URL + "/iconImages/close-fill.svg"}
                alt="close icon"
                className="universalSerchCloseIcon"
              />
            </span>
          </div>
        </Grid>
      </Grid>
    </div>
  );
}
