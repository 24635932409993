import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import "./WallpaperComponent.css";
import Template from "../../Common/Template/Template";
import CommonScheduler from "../../Common/CommonScheduler/CommonScheduler";
import ApprovalComponent from "./ApprovalComponent";
// import isAdmin from "../../isAdmin";
import { getUserWallpaperStatusData } from "../../../Redux/Actions/UserWallpaperStatusAction";
import { getApprovedWallpaperListData } from "../../../Redux/Actions/ApprovedWallpaperListAction";
import { getInitialWallpaperInfoData } from "../../../Redux/Actions/InitialWallpaperInfoAction";
import { useDispatch, useSelector } from "react-redux";
import Loader from "../../Common/Loader/Loader";
import { Popover } from "@mui/material";
import {
  getFormattedDate,
  isAccesible,
} from "../../../Assets/helperFunctions/helperFunctions";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";

import {
  Grid,
  Paper,
  Button,
  Tabs,
  Tab,
  Typography,
  Box,
} from "@material-ui/core";
import PropTypes from "prop-types";
import SubmitButton from "../../Common/Button/SubmitButton";
import platform from "platform";
import {
  addAnalayticsLog,
  addInterval,
} from "../../../Redux/Actions/Anaylatics";

let baseURL = process.env.REACT_APP_API_BASE_URL;

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export default function WallpaperComponent() {
  const dispatch = useDispatch();
  const status = useSelector((state) => state.getUserWallpaperStatusReducer);
  const approvedList = useSelector(
    (state) => state.getApprovedWallpaperListReducer
  );
  const initialData = useSelector(
    (state) => state.getInitialWallpaperInfoReducer
  );

  const history = useHistory();

  const [value, setValue] = React.useState(0);
  const [loader, setLoader] = useState(false);
  const [reset, setReset] = useState(false);
  const [isAdmin, setIsAdmin] = useState(false);
  const [isWindowsAdmin, setIsWindowsAdmin] = useState(false);
  const [sectorScope, setSectorScope] = useState([]);

  const [open, setOpen] = React.useState(false);
  const [selectedData, setSelectedData] = useState();

  const handleClick = (event, item) => {
    setOpen(true);
    setSelectedData(item);
  };

  const handleClose = () => {
    setOpen(false);
  };
  const id = open ? "simple-popover" : undefined;

  useEffect(() => {
    let userProfile = JSON.parse(
      localStorage.getItem("userProfile")
        ? localStorage.getItem("userProfile")
        : null
    );
    const id = userProfile.employeeid;
    // const id = "NAYASA-CONT";
    dispatch(getUserWallpaperStatusData(id));
    dispatch(getInitialWallpaperInfoData(id));
  }, [reset]);
  useEffect(() => {
    if (status.isLoading || approvedList.isLoading || initialData.isLoading) {
      setLoader(true);
    } else {
      setLoader(false);
    }
  }, [status, approvedList, initialData]);
  useEffect(() => {
    const check = initialData.getData?.wallpaperSectorScope?.adminType;
    setIsAdmin(isAccesible("WALLPAPER"));
    setIsWindowsAdmin(check == "WINDOWS" ? true : false);

    initialData.getData?.wallpaperSectorScope?.sectorScope &&
      setSectorScope(initialData.getData?.wallpaperSectorScope?.sectorScope);
  }, [initialData]);
  useEffect(() => {
    dispatch(getApprovedWallpaperListData(sectorScope));
  }, [sectorScope, reset]);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const postRequest = () => {
    history.push("/work/role-based/wallpaper/post-wallpaper-request");
  };

  const Reset = () => (reset ? setReset(false) : setReset(true));

  let list = [];
  {
    approvedList?.getData &&
      approvedList?.getData?.map((item, index) => {
        let secId = 0;
        const secName = item?.wallpaperTargetSectors[0]?.sectorName;
        secName == "AS"
          ? (secId = 1)
          : secName == "FES"
          ? (secId = 2)
          : (secId = 3);
        let slist = [];
        item?.targetSector?.map((data) => {
          slist = [...slist, data.sectorName];
        });
        const startDate =
          item?.wallpaperTargetSectors[0]?.proposedStartDate?.split(" ");
        const endDate =
          item?.wallpaperTargetSectors[0]?.proposedEndDate?.split(" ");
        const sDate = startDate[0]?.split("-");
        const eDate = endDate[0]?.split("-");
        const sTime = startDate[1]?.split(":");
        const eTime = endDate[1]?.split(":");
        let sHour = 0;
        let eHour = 0;
        startDate[2] == "PM"
          ? sTime[0] == "12"
            ? (sHour = 12)
            : (sHour = 12 + parseInt(sTime[0]))
          : sTime[0] == "12"
          ? (sHour = 0)
          : (sHour = parseInt(sTime[0]));
        endDate[2] == "PM"
          ? eTime[0] == "12"
            ? (eHour = 12)
            : (eHour = 12 + parseInt(eTime[0]))
          : eTime[0] == "12"
          ? (eHour = 0)
          : (eHour = parseInt(eTime[0]));
        const alist = {
          id: index,
          title: item?.title,
          ownerId: secId,
          startDate: new Date(
            parseInt(sDate[0]),
            parseInt(sDate[1]) - 1,
            parseInt(sDate[2]),
            sHour,
            parseInt(sTime[1])
          ),
          endDate: new Date(
            parseInt(eDate[0]),
            parseInt(eDate[1]) - 1,
            parseInt(eDate[2]),
            eHour,
            parseInt(eTime[1])
          ),
          targetSector: slist,
          locationName: item?.wallpaperTargetSectors[0]?.locationName,
          image: item?.wallpaper,
          requesterName: item?.requesterName,
          requesterSector: item?.requestSector,
          requesterId: item?.requestId,
          requesterLocation: item?.location,
          requesterDepartment: item?.departMent,
          contactDetails: item?.contactDetails,
          isHodApproved: item?.isHodApproved,
          hodApprovalDetails: item?.hodApprovalDetails,
          isWallpaperAdmin: isAdmin,
          isWindowsAdmin: isWindowsAdmin,
          // isWallpaperAdmin: true,
          // isWindowsAdmin: true,
        };
        list = [...list, alist];
      });
  }
  console.log("selected data ===> ", selectedData);

  //log for wallpaper.
  useEffect(() => {
    dispatch(
      addAnalayticsLog({
        module: "wallpaper",

        browserDetails: `${platform.name} ${platform.os.architecture} ${platform.os.family} ${platform.os.version}`,

        deviceDetails: platform.product ? platform.product : "Desktop",

        isSearch: false,
      })
    );
    // you know what is this, don't you?
  }, []);

  //timer log....
  useEffect(() => {
    const interval = setInterval(() => {
      dispatch(addInterval(localStorage.getItem("logID")));
    }, 60000);
    return () => clearInterval(interval);
  }, []);

  return (
    <div>
      {/* { loader && <Loader /> } */}
      <Template>
        <Grid
          container
          direction="row"
          justifyContent="space-between"
          alignItems="center"
        >
          <Grid item xs="auto" sm="auto">
            <p className="section-header-text">Wallpaper</p>
          </Grid>
          <Grid item xs={12} sm="auto">
            <SubmitButton
              label="Post a wallpaper request"
              className="rollBasedBtn postwallpaperBtn"
              onClick={postRequest}
            />
          </Grid>
          {isAccesible("WALLPAPER") && (
            <Box sx={{ width: "100%" }} className="mb-3">
              <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                <Tabs
                  value={value}
                  onChange={handleChange}
                  aria-label="basic tabs example"
                  className="rollBasedTab"
                >
                  <Tab label="Wallpaper" {...a11yProps(0)} onClick={Reset} />
                  <Tab label="Approval" {...a11yProps(1)} />
                </Tabs>
                <hr className="deviderP" />
              </Box>
            </Box>
          )}
        </Grid>
        <TabPanel value={value} index={0} className="rollBasedTabPanel">
          <Paper className="mb-5 mt-2">
            <Grid
              container
              direction="row"
              spacing={2}
              className="rollBasedPaper"
            >
              <Grid item xs={12} sm={6}>
                <Grid item xs={12} sm={12}>
                  <p className="heading">Availability calendar</p>
                  <div className="border1">
                    {/* {list.length > 0 && */}
                    <CommonScheduler dataList={list} />
                    {/* } */}
                  </div>
                </Grid>
                <Grid item xs={12} sm={12}>
                  <p className="heading mt-4">Guidelines</p>
                </Grid>
                <Grid item xs={12} sm={12}>
                  <p className="content" style={{ marginBottom: "0px" }}>
                    <ul>
                      <li>
                        The size of the wallpaper should be no more than 100 KB
                      </li>
                      <li>
                        The wallpaper design should take up no more than 40% of
                        the space available on the user's desktop and will be
                        placed on the right hand side of the desktop.
                      </li>
                      <li>
                        The left side of the desktop is to be used strictly for
                        desktop icons and hence, the left side of the wallpaper
                        should sport a dark – preferably dark blue - background
                        with no content / graphics
                      </li>
                      <li>The Desktop Wallpaper must be in JPEG format only</li>
                      <li>
                        No Desktop Wallpaper request will be approved without an
                        attachment
                      </li>
                      <li>
                        A single Desktop Wallpaper may be published for a
                        maximum of five working days only
                      </li>
                      <li>
                        Approval authorities reserve the right to cancel a
                        scheduled booking if so required. The final decision in
                        this regard rests with the approval authorities only.
                      </li>
                    </ul>
                    <br />
                  </p>
                </Grid>
              </Grid>
              <Grid item xs={12} sm={6}>
                <Grid item xs={12} sm={12}>
                  <p className="heading" style={{ width: "100%" }}>
                    Request status
                  </p>

                  <Grid container direction="row" className="border2 py-2">
                    <Grid item xs={6} sm={6} md={6}>
                      <span style={styles.tableheading}>Title</span>
                    </Grid>
                    <Grid item xs={6} sm={6} md={6}>
                      <Grid container direction="row" className="">
                        {/* <Grid item xs={12} className="d-flex justify-content-center">
                                                <span style={styles.tableheading} className="py-0">Status</span>
                                            </Grid> */}
                        <Grid item xs={4}>
                          <span style={styles.tableheading} className="py-0">
                            AS
                          </span>
                        </Grid>
                        <Grid item xs={4}>
                          <span style={styles.tableheading} className="py-0">
                            FES
                          </span>
                        </Grid>
                        <Grid item xs={4}>
                          <span style={styles.tableheading} className="py-0">
                            GCO
                          </span>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                  <div className="wallpaperStatus">
                    {status?.getData &&
                      status?.getData?.map((item) => (
                        <Grid
                          key={item?.id}
                          container
                          direction="row"
                          onClick={(e) => handleClick(e, item)}
                          className="border2 py-2"
                          style={{ cursor: "pointer" }}
                        >
                          <Grid item xs={6} sm={6} md={6}>
                            <span style={styles.tabledata}>{item.title}</span>
                          </Grid>
                          <Grid
                            item
                            xs={2}
                            sm={2}
                            md={2}
                            className="pe-1 ps-0 wallpaper"
                          >
                            {item?.wallpaperTargetSectors?.map((data) => {
                              if (data?.sectorName === "AS") {
                                if (data?.status === "approved") {
                                  return (
                                    <Button
                                      className="statusApproved"
                                      disabled={true}
                                    >
                                      {data?.status}
                                    </Button>
                                  );
                                } else if (data?.status === "pending") {
                                  return (
                                    <Button
                                      className="statusPending"
                                      disabled={true}
                                    >
                                      {data?.status}
                                    </Button>
                                  );
                                } else {
                                  return (
                                    <Button
                                      className="statusReject"
                                      disabled={true}
                                    >
                                      {data?.status}
                                    </Button>
                                  );
                                }
                              }
                            })}
                          </Grid>
                          <Grid
                            item
                            xs={2}
                            sm={2}
                            md={2}
                            className="pe-1 ps-0 wallpaper"
                          >
                            {item?.wallpaperTargetSectors?.map((data) => {
                              if (data?.sectorName === "FES") {
                                if (data?.status === "approved") {
                                  return (
                                    <Button
                                      className="statusApproved"
                                      disabled={true}
                                    >
                                      {data?.status}
                                    </Button>
                                  );
                                } else if (data?.status === "pending") {
                                  return (
                                    <Button
                                      className="statusPending"
                                      disabled={true}
                                    >
                                      {data?.status}
                                    </Button>
                                  );
                                } else {
                                  return (
                                    <Button
                                      className="statusReject"
                                      disabled={true}
                                    >
                                      {data?.status}
                                    </Button>
                                  );
                                }
                              }
                            })}
                          </Grid>
                          <Grid
                            item
                            xs={2}
                            sm={2}
                            md={2}
                            className="pe-1 ps-0 wallpaper"
                          >
                            {item?.wallpaperTargetSectors?.map((data) => {
                              if (data?.sectorName === "GCO") {
                                if (data?.status === "approved") {
                                  return (
                                    <Button
                                      className="statusApproved"
                                      disabled={true}
                                    >
                                      {data?.status}
                                    </Button>
                                  );
                                } else if (data?.status === "pending") {
                                  return (
                                    <Button
                                      className="statusPending"
                                      disabled={true}
                                    >
                                      {data?.status}
                                    </Button>
                                  );
                                } else {
                                  return (
                                    <Button
                                      className="statusReject"
                                      disabled={true}
                                    >
                                      {data?.status}
                                    </Button>
                                  );
                                }
                              }
                            })}
                          </Grid>
                        </Grid>
                      ))}
                  </div>
                  <Popover
                    id={id}
                    open={open}
                    onClose={handleClose}
                    anchorOrigin={{
                      vertical: "top",
                      horizontal: "left",
                    }}
                    style={{ maxWidth: "400px" }}
                  >
                    <Grid container alignItems="center" className="p-4">
                      <Grid item xs="auto" className="ms-auto">
                        <IconButton
                          aria-label="close"
                          onClick={handleClose}
                          sx={{
                            position: "absolute",
                            right: 8,
                            top: 8,
                            color: (theme) => theme.palette.grey[500],
                          }}
                        >
                          <CloseIcon />
                        </IconButton>
                      </Grid>
                      <Grid item xs={12} className="schedule mb-3">
                        <h3>{selectedData?.title}</h3>
                      </Grid>
                      <Grid item xs={12} className="schedule mb-1">
                        <h5>Target Sectors</h5>
                      </Grid>
                      {selectedData?.wallpaperTargetSectors?.map((data) => {
                        const date1 = data?.proposedStartDate?.split(" ");
                        const date2 = data?.proposedEndDate?.split(" ");
                        return (
                          <>
                            <h6>{data?.sectorName}</h6>
                            <Grid item xs={12} className="schedule mb-1">
                              <span className="title">
                                Proposed Start Date:{" "}
                              </span>
                              <span className="content mb-0">
                                {getFormattedDate(date1[0]) +
                                  " " +
                                  date1[1] +
                                  " " +
                                  date1[2]}
                              </span>
                            </Grid>
                            <Grid item xs={12} className="schedule mb-1">
                              <span className="title">Proposed End Date: </span>
                              <span className="content mb-0">
                                {getFormattedDate(date2[0]) +
                                  " " +
                                  date2[1] +
                                  " " +
                                  date2[2]}
                              </span>
                            </Grid>
                            <Grid item xs={12} className="schedule mb-3">
                              <span className="title">Status: </span>
                              <span
                                className="content mb-0"
                                style={{ textTransform: "capitalize" }}
                              >
                                {data?.status}
                              </span>
                            </Grid>
                          </>
                        );
                      })}
                      <Grid item xs={12} className="schedule mb-3">
                        <span className="title">HOD Approval</span>
                        <p className="content mb-0">
                          {selectedData?.isHodApproved
                            ? "Submitted"
                            : "Not Required"}
                        </p>
                      </Grid>
                      <Grid
                        item
                        xs={12}
                        style={{ paddingLeft: "24px" }}
                        className="schedule mt-3"
                      >
                        <p
                          className="link"
                          onClick={() => {
                            window.open(
                              `${baseURL}/ui-assist/v1/download/true?fileName=${selectedData?.wallpaper}`,
                              "_self"
                            );
                          }}
                        >
                          {" "}
                          Download Wallpaper Image
                        </p>
                      </Grid>
                    </Grid>
                  </Popover>
                </Grid>
              </Grid>
            </Grid>
          </Paper>
        </TabPanel>
        <TabPanel value={value} index={1} className="rollBasedTabPanel">
          <ApprovalComponent />
        </TabPanel>
      </Template>
    </div>
  );
}

const styles = {
  tableheading: {
    fontSize: "14px",
    lineHeight: "1.67",
    padding: "16px",
    fontWeight: "600",
  },
  tabledata: {
    fontSize: "14px",
    lineHeight: "1.67",
    padding: "16px",
    fontWeight: "400",
  },
  mainheading: {
    fontSize: "32px",
    lineHeight: "1.25",
    fontWeight: "500",
    color: "black",
  },
};
