import React, { useState } from "react";
import "./Articlecomment.css";
function CommentForm({
  label,
  handleSubmit,
  hasCancellButton = false,
  initialText = "",
  handleCancel,
  commentBoxStyle,
}) {
  
  console.log(label, 'my label........................')
  
  const [text, setText] = useState(initialText);
  const isTextAreaDisabled = text.length === 0;

  const handleSubmitForm = (e) => {
    console.log("i was submitted");
    e.preventDefault();
    handleSubmit(text);
    setText("");
  };

  const handleOnChange = (e) => {
    setText(e.target.value);
  };

  return (
    <>
      <form
        onSubmit={handleSubmitForm}
        className={
          commentBoxStyle === "outerR"
            ? `formOuter`
            : commentBoxStyle === "R"
            ? `formReply` 
            : label === 'Edit' ? `formInnerReply editform`
            : `formInnerReply`
        }
      >
        <textarea
          type="text"
          placeholder="What do you think"
          className={
            commentBoxStyle === "outerR"
              ? `commentBoxTextArea`
              : commentBoxStyle === "R"
              ? `commentReplyTextArea`
              : `commentSubReplyTextArea`
          }
          value={text}
          onChange={(e) => {
            handleOnChange(e);
          }}
        ></textarea>
        <div className="commentBoxBtn">
          <button
            disabled={isTextAreaDisabled}
            className={
              commentBoxStyle === "outerR"
                ? `commentBtn mlBtnComment`
                : commentBoxStyle === "R"
                ? `commentRBtn mlBtnComment`
                : `commentRSBtn mlBtnComment`
            }
            type="submit"
          >
            COMMENT
          </button>
          {hasCancellButton && (
            <button
              className={
                commentBoxStyle === "outerR"
                  ? `commentBtn`
                  : commentBoxStyle === "R"
                  ? `commentRBtn`
                  : `commentRSBtn`
              }
              onClick={handleCancel}
            >
              CANCEL
            </button>
          )}
        </div>
      </form>
    </>
  );
}

export default CommentForm;
