import React, { useRef } from "react";

const ImageToggleOnHover = ({ source, activeSource }) => {
  const imageRef = useRef(null);

  return (
    <img
      src={source}
      ref={imageRef}
      width={40}
      onMouseOver={() => {
        imageRef.current.src = activeSource;
      }}
      onMouseOut={() => {
        imageRef.current.src = source;
      }}
    />
  );
};

export default ImageToggleOnHover;
