import React, { useState, useEffect } from "react";
import "../../../MahindraCollective/UploadStory/UploadStoryComponent.css";
import { Grid, FormControlLabel } from "@material-ui/core";
import Checkbox from "@mui/material/Checkbox";
import CharLeftField from "../../../../Common/CharLeftField/CharLeftField";
import CommonLabel from "../../../../Common/ComonLabel/CommonLabel";
import BenefitTitle from "./BenefitTitle";

const MobileHandsetReimbursement = ({ data, setData }) => {
  // const [includingGST, setIncludingGST] = useState(false);
  const [limit, setLimit] = useState("");
  const [noOfDevices, setNoOfDevices] = useState("");
  const [isAllowed, setIsAllowed] = useState(false);
  const [iPhone, setIPhone] = useState(false);

  useEffect(() => {
    if (data) {
      data?.map((val) => {
        if (val?.entitlementType == "Mobile Handset Reimbursement") {
          setIsAllowed(true);
          setLimit(val?.values?.availedLimit);
          setNoOfDevices(val?.values?.noOfDevices);
          setIPhone(val?.values?.latestIPhone);
        }
      });
    }
  }, [data]);

  useEffect(() => {
    const val = {
      benefitsId: "62720e2c6bc86099de7d7bb5",
      availedLimit: limit,
      noOfDevices: noOfDevices,
      latestIPhone: iPhone,
    };
    const isValid = CheckValidation();
    const data = {
      value: val,
      isAllowed: isAllowed,
      isValid: isValid,
    };
    setData(data);
  }, [limit, noOfDevices, iPhone, isAllowed]);

  useEffect(() => {
    if (!isAllowed) {
      setLimit("");
      setNoOfDevices("");
      setIPhone(false);
    }
  }, [isAllowed]);

  function CheckValidation() {
    if (!isAllowed) {
      return true;
    } else {
      if (limit == "") {
        return false;
      } else {
        return true;
      }
    }
  }

  // const handleGST = (e) => setIncludingGST(!includingGST);
  const handleLimit = (e) => setLimit(e.target.value);
  const handleNoOfDevices = (e) => setNoOfDevices(e.target.value);
  const handleChange = (e) => setIPhone(!iPhone);

  return (
    <>
      <BenefitTitle
        title="Mobile Handset Reimbursement"
        val={isAllowed}
        setVal={setIsAllowed}
      />
      <div className="divContainerOfferCampaign mb-5">
        <Grid container spacing={2} alignItems="top">
          <Grid
            item
            sm={6}
            md={3}
            xs={12}
            className="inputWidthTitle alignInputTextTitle boxwithlimit"
          >
            <CharLeftField
              label={<CommonLabel label="Max Limit" mandatory />}
              value={limit}
              name="limit"
              type="number"
              disabled={!isAllowed}
              handleChange={handleLimit}
            />
          </Grid>
          <Grid
            item
            sm={6}
            md={3}
            xs={12}
            className="inputWidthTitle alignInputTextTitle boxwithlimit"
          >
            <CharLeftField
              label={<CommonLabel label="No of device" />}
              value={noOfDevices}
              name="noOfDevices"
              type="number"
              disabled={!isAllowed}
              handleChange={handleNoOfDevices}
            />
          </Grid>
          <Grid item sm={6} md={3} xs={12}>
            <div className="radioBackground authorRadioButton">
              <FormControlLabel
                value="author"
                className="radioButtonForm"
                control={
                  <Checkbox
                    checked={iPhone}
                    disabled={!isAllowed}
                    onChange={handleChange}
                  />
                }
                label={
                  <span className="formControlLabelStyle commonFontNormalStyle ms-1">
                    Latest iPhone
                  </span>
                }
                labelPlacement="end"
              />
            </div>
          </Grid>
        </Grid>
      </div>
    </>
  );
};

export default MobileHandsetReimbursement;
