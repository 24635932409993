import React, { useState, useEffect } from "react";
import { Grid } from "@material-ui/core";
import ContactComponent from "./ContactComponent";
import TimelineComponent from "./TimelineComponent";
import CommonPDFUpload from "../../../../Common/CommonPDFUpload/CommonPDFUpload";
import Title from "./Title";
import CharLeftField from "../../../../Common/CharLeftField/CharLeftField";
import CommonLabel from "../../../../Common/ComonLabel/CommonLabel";

const CreditCard = ({ data, formData, isValid }) => {
  const [contactPerson, setContactPerson] = useState();
  const [timelines, setTimelines] = useState();
  const [applyURl, setApplyURl] = useState();
  const [escalationSPOC, setEscalationSPOC] = useState();
  const [policyPdf, setPolicyPdf] = useState();
  
  useEffect(() => {
    setPolicyPdf(data?.policyPdf);
  }, [data]);

  useEffect(() => {
    const check =
      contactPerson?.isValid &&
      timelines?.isValid &&
      applyURl &&
      escalationSPOC?.isValid &&
      policyPdf;

    let formdata = new FormData();
    formdata.append("entitlementType", data?.entitlementType);
    formdata.append("contactPerson", JSON.stringify([{...contactPerson?.value}]));
    formdata.append("timelines", JSON.stringify([{...timelines?.value}]));
    formdata.append("applyURL", applyURl);
    formdata.append("escalation", JSON.stringify([{...escalationSPOC?.value}]));
    formdata.append("policyPdf", policyPdf);

    formData(formdata);
    isValid(check);
  }, [contactPerson, timelines, escalationSPOC, policyPdf, applyURl]);


  const handleURL = (e) => {
      setApplyURl(e.target.value);
  };

  // console.log("contact person =====>", contactPerson);
  // console.log("timeline =====>", timelines);
  // console.log("escalationSPOC =====>", escalationSPOC);
  // console.log("policyPdf =====>", policyPdf);

  return (
    <>
      <ContactComponent
        data={data?.contactPerson}
        setData={setContactPerson}
        title="Contact"
      />
      <TimelineComponent data={data?.timelines} setData={setTimelines} />
      <Title title="Timeline Availability URL" />
      <Grid container spacing={2} alignItems="top" className="mb-5">
        <Grid item sm={6} md={4} xs={12}>
          <CharLeftField
            label={<CommonLabel label="url" mandatory />}
            value={applyURl}
            name="applyURl"
            type="text"
            handleChange={handleURL}
          />
        </Grid>
      </Grid>
      <ContactComponent
        data={data?.escalationSPOC}
        setData={setEscalationSPOC}
        title="Escalation"
      />
      <Title title="Policy PDF" />
      <Grid container spacing={2} alignItems="top">
        <Grid item sm={6} md={4} xs={12}>
          <CommonPDFUpload value={data?.policyPdf} setValue={setPolicyPdf} />
        </Grid>
      </Grid>
    </>
  );
};

export default CreditCard;
