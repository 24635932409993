import React from "react";
import { Grid } from "@material-ui/core";

const MobileHandset = ({ data }) => {
  let fixUrl =
    "https://epmobile.mahindra.com/sap/bc/ui5_ui5/sap/zreim_exp/index.html#/";
  let applyURL = data?.applyURl ? data?.applyURl : fixUrl;

  return (
    <>
      <Grid
        container
        direction="row"
        justifyContent="flex-start"
        alignItems="center"
        spacing={2}
        className="alignCards Econtent"
      >
        <>
          <Grid item xs={12} sm={12} className="mb-5">
            <Grid container direction="row" className="border2 py-2 ">
              <Grid item xs={2} sm={2} md={2}>
                <div className="TableContent">Entitlement</div>
              </Grid>
              <Grid item xs={8} sm={8} md={8}>
                <div className="TableContent fontboldnew">
                  {data?.values?.availedLimit}
                  {data?.values?.availedLimit &&
                    data?.values?.latestIPhone &&
                    " or "}
                  {data?.values?.latestIPhone && "latest iPhone"},{" "}
                  {data?.values?.noOfDevices} phones
                </div>
              </Grid>
            </Grid>
            <div className="notevalue">
              Please submit the bill online by{" "}
              <a target="_blank" className="emailidfield" href={applyURL}>
                Click here
              </a>{" "}
              and reimbursement is processed as per policy.
            </div>
          </Grid>
        </>
      </Grid>
    </>
  );
};

export default MobileHandset;
