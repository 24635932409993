import React, { memo, useEffect, useState } from "react";
import CommonModal from "../../Common/CommonModal/CommonModal";
import WheelWhite from "../../../Assets/Images/WheelWhite.svg";
import { Grid } from "@material-ui/core";
import { useSelector, useDispatch } from "react-redux";
import {
  getAllYearsMonths,
  getFormattedDate,
} from "../../../Assets/helperFunctions/helperFunctions";
import CommonAutocomplete from "../../Common/CommonAutocomplete/CommonAutocomplete";
import CommonLabel from "../../Common/ComonLabel/CommonLabel";
import SearchBarButton from "../../Common/SearchBar/SearchBarButton";
import { getLastModifiedData } from "../../../Redux/Actions/CommonActions";
import CommonMultiAutocomplete from "../../Common/CommonMultiAutocomplete/CommonMultiAutocomplete";
import { Accordion, AccordionDetails, AccordionSummary } from "@mui/material";
import ExpandMore from "@mui/icons-material/ExpandMore";
import moment from "moment";
import Swal from "sweetalert2";
import { useFormik } from "formik";
import { orgAnnouncementSchema } from "../../../Assets/formikErrors/SubmitOfferCampaignSchema";
import { getOrgAdminData, getOrgCount } from "../../../Redux/Actions/OrgAction";
import services from "../../../Services";
import InternalForm from "./InternalForm";
import {
  getSectors,
  getBusinessUnits,
} from "../../../Redux/Actions/MastersAction";

export default memo(function OrgModal1({
  open,
  handleClose,
  headerText,
  icon,
  modalSize,
  header,
  footer,
  fullWidth,
}) {
  //Variable Declaration-------------------
  const [calSelectYearOptionss, setCalSelectYearOptionss] = useState();
  const [calYearValue, setCalYearValue] = useState("");
  const [monthValue, setMonthValue] = useState("");
  const [openNewAnnouncement, setOpenNewAnnouncement] = useState(false);
  const [selectedMonth, setSelectedMonth] = useState("");
  const [selectedYear, setSelectedYear] = useState("");
  const [searchText, setSearchText] = useState("");
  const [currentAdminPage, setCurrentAdminPage] = useState(0);
  const [isAdminFetching, setIsAdminFetching] = useState(false);
  const [isAddLoader, setIsAddLoader] = useState(false);
  const [refresh, setrefresh] = useState(true);
  const [expanded, setExpanded] = React.useState(false);
  const [callGetApi, setCallGetApi] = useState(false);

  //API Data
  const dispatch = useDispatch();
  const lastModified = useSelector((state) => {
    return state?.getLastModifiedDataReducer?.lastModifiedOrgAnnocement;
  });
  const getOrgAdminDataFromState = useSelector(
    (state) => state.getOrgAdminDataReducer
  );
  const {
    getAdminData: orgAdminData,
    isAdminDataEnds: isAdminDataEnds,
    isLoading,
    isError,
  } = getOrgAdminDataFromState;

  //useEffects-----------------------
  useEffect(() => {
    dispatch(getLastModifiedData("organnouncement"));
    const calSelectYearOptionss = getAllYearsMonths(2);
    setCalSelectYearOptionss(calSelectYearOptionss);
  }, []);

  useEffect(() => {
    setCurrentAdminPage(0);
    async function fetchData() {
      await dispatch(
        getOrgAdminData(
          selectedYear,
          selectedMonth,
          searchText,
          currentAdminPage
        )
      );
    }
    fetchData();
    setIsAdminFetching(false);
  }, [selectedYear, selectedMonth, searchText, currentAdminPage]);

  useEffect(() => {
    if (callGetApi == false) return;
    setCurrentAdminPage(0);
    async function fetchData() {
      await dispatch(
        getOrgAdminData(
          selectedYear,
          selectedMonth,
          searchText,
          currentAdminPage
        )
      );
    }
    fetchData();
    setIsAdminFetching(false);
  }, [callGetApi]);

  const disableFlagsOnAPIcall = () => {
    setCallGetApi(false);
  };

  const needToRefreshUI = (isFromEdit = undefined) => {
    setCallGetApi(true);
    setExpanded(false);
    setOpenNewAnnouncement(false);
    if (!isFromEdit) {
      dispatch(getOrgCount());
    }
  };

  //MARK: - Life cycle

  useEffect(() => {
    async function fetchData() {
      dispatch(getSectors());
    }
    fetchData();
  }, []);

  //Event Handler ------------------

  const handleCloseModal = () => {
    handleClose();
  };
  const handleButtonClick = () => {
    handleClose();
  };

  const handleCalYearChange = (e, selectType) => {
    setCalYearValue(selectType);
    setSelectedYear(selectType.value);
    if (monthValue.value !== selectType) {
      setMonthValue([]);
    }
  };
  const handleMonthChange = (e, selectType) => {
    setMonthValue(selectType);
    let selectedMonth = selectType.map((el) => el.value).join(",");
    setSelectedMonth(selectedMonth);
  };
  const handleSearch = (e) => {
    if (e.target.value) {
      setSearchText(e.target.value);
    } else {
      setSearchText("");
    }
  };
  const handleAdminScroll = (event) => {
    console.log("onScroll is getting called.............");
    const target = event?.target;

    if (target == undefined) {
      return;
    }

    // if (!isAdminDataEnds) {
    //   setIsAdminFetching(false);
    //   return;
    // }

    if (
      target.scrollHeight - target.scrollTop == target.clientHeight + 1 ||
      target.scrollHeight - target.scrollTop == target.clientHeight - 1 ||
      target.scrollHeight - target.scrollTop == target.clientHeight
    ) {
      setCurrentAdminPage((currentAdminPage) => currentAdminPage + 1);
      setIsAdminFetching(true);
    } else {
      setIsAdminFetching(false);
    }
  };
  const handleAccordianChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  //API Calls

  //UI----------------------------

  const handleMonthDropdown = (year, list) => {
    for (let i = 0; i < list.length; i++) {
      if (year === list[i].value && list[i].child !== undefined) {
        // console.log("cal year balue", year, list[i].value);
        return (
          <Grid
            item
            xs={12}
            sm={4}
            className="calenderTypeIcon noIcon calendarMaxWidth monthOrg adminMonthOrg"
          >
            <CommonMultiAutocomplete
              options={list[i].child}
              label={<CommonLabel label={year} />}
              handleSelectChange={handleMonthChange}
              selectedSubcategory={monthValue}
              selectedValue={monthValue}
              className="McShadowBox monthOrg"
            />
          </Grid>
        );
      } else {
        <></>;
      }
    }
  };
  const LastModifiedPanel = () => {
    return (
      <div className="lastmodifiedpanel">
        <Grid container spacing={2}>
          <Grid item sm={2} xs={12}>
            <div className="displayBlockUserDetails mlP alignMCAdmin">
              <p className="detailDivp">Last modified by</p>
              <p className="detailsFontSize">
                {lastModified?.name ? lastModified?.name : "Not Available"}
              </p>
            </div>
          </Grid>
          <Grid item sm={2} xs={12}>
            <div className="displayBlockUserDetails alignMCAdmin">
              <p className="detailDivp">Modified on</p>
              <p className="detailsFontSize">
                {lastModified?.date
                  ? getFormattedDate(lastModified?.date)
                  : "Not Available"}
              </p>
            </div>
          </Grid>
          {/* <Grid item sm={8} xs={12}>
                <div className="uploadBtn">
                  <SubmitButton
                    label="Upload an article"
                    className="MCsubmitbutton floatRightAlign"

                  />
                </div>
              </Grid> */}
        </Grid>
      </div>
    );
  };
  const SubHeader = () => {
    return (
      <div className="bannerTitleBtnOrg">
        <div className="alignBtnRight liveWireDropDownContainer calenderAlign">
          {/*<NestedSearchCheckBox item={menuItem} /> */}
          <div className="calenderTypeIcon calendarMaxWidth orgAdminCalender">
            <CommonAutocomplete
              options={calSelectYearOptionss}
              label={<CommonLabel label="Year" />}
              handleSelectChange={handleCalYearChange}
              selectedValue={calYearValue}
              className="McShadowBox"
            />
          </div>
          {handleMonthDropdown(calYearValue.value, calSelectYearOptionss)}
          <div className="bannerSearchButtonOrg">
            <SearchBarButton
              placeholder="Search announcements"
              searchText={searchText}
              handleSearch={handleSearch}
            />
          </div>
          <div
            className="OrgHeaderT"
            style={{ cursor: "pointer" }}
            onClick={() => {
              if (openNewAnnouncement == true) {
                setExpanded(false);
              }
              setOpenNewAnnouncement(!openNewAnnouncement);
            }}
          >
            <img
              src={process.env.PUBLIC_URL + "/policies/add-fill.svg"}
              alt="Add"
              style={{ marginRight: "5px" }}
            />
            ADD NEW ANNOUNCEMENT
          </div>
        </div>
      </div>
    );
  };

  //MARk - This mainly for internal form functionality
  const ListUI = ({ item, index }) => {
    return (
      <div
        className="setscrollOrg"
        style={{ overflowY: "auto" }}
        onScroll={(e) => {
          if (!isAdminDataEnds) {
            handleAdminScroll(e);
          }
        }}
      >
        {openNewAnnouncement && (
          <>
            <div className="addorgpanel">
              <InternalForm
                needToRefreshUI={needToRefreshUI}
                disableFlagsOnAPIcall={disableFlagsOnAPIcall}
              />
            </div>
          </>
        )}
        {orgAdminData?.map((item, index) => (
          <Accordion
            key={index}
            className="accordionContainer"
            expanded={expanded === item.contentID}
            onChange={handleAccordianChange(item.contentID)}
          >
            <AccordionSummary
              expandIcon={<ExpandMore className="orgModalArrow" />}
              aria-controls="panel1a-content"
              id="panel1a-header"
              // onClick={() => setUpdateProps(item)}
              className="orgModalAccordianSummary"
            >
              <div className="mainAccContainer">
                <div className={"lableClass"}>
                  <div className={"accordionStyle"}>{item.name}</div>
                  {moment(item.publishDate).format("DD/MM/YYYY")}
                  {/* <div className="viewRight">
                              <div className="ViewOrg">
                                <span>view announcement</span>
                                <KeyboardArrowRightIcon />
                              </div>
                            </div> */}
                </div>
              </div>
            </AccordionSummary>
            <AccordionDetails
              className="accordianWidth"
              style={{ marginBottom: "0.5rem" }}
            >
              <InternalForm
                data={item}
                index={index}
                needToRefreshUI={needToRefreshUI}
                disableFlagsOnAPIcall={disableFlagsOnAPIcall}
              />
            </AccordionDetails>
          </Accordion>
        ))}
      </div>
    );
  };

  return (
    <div>
      <CommonModal
        open={open}
        handleClose={handleCloseModal}
        headerText={headerText}
        modalSize={modalSize}
        header={header}
        footer={footer}
        fullWidth={fullWidth}
        backgroundColor
        icon={WheelWhite}
        label="SAVE CHANGES"
        className="bannerModalEdit orgmodalEdit"
        submitBtnText={"CLOSE"}
        onButtonClick={handleButtonClick}
        // erroMsgOrg={errorFooterB}
        orgBtn={true}
      >
        <LastModifiedPanel />
        <div className="Modalcontent">
          <SubHeader />

          <div className="AccordianMessage">
            <ListUI />
            {/* {isAdminFetching && !isAdminDataEnds && "Fetching more list items..."} */}
          </div>
        </div>
      </CommonModal>
    </div>
  );
});
