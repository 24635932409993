import React, { useEffect, useState } from "react";
import "./PoliciesComponent.css";
import { Grid } from "@material-ui/core";
import Template from "../../Common/Template/Template";
import SearchBarButton from "../../Common/SearchBar/SearchBarButton";
import PoliciesTabComponent from "../Policies/PoliciesTab/PoliciesTabComponent";
import isAdmin from '../../isAdmin'
// import PoliciesTableComponent from "./PoliciesTable/PoliciesTableComponent";
 import { useHistory } from "react-router-dom";
 import { addAnalayticsLog, addInterval } from "../../../Redux/Actions/Anaylatics";
import platform from 'platform';
import WindowFocusHandler from '../../WindowFocusHandler'
// import policiesData from "./policiesData.json";
 import { POLICIES, POLICIES_SEARCH , POLICIES_SEARCH_TABLE} from "../../../Routes/constant";

import { useDispatch, useSelector } from "react-redux";
import {
  getPolicyCategoryData,
  getPolicyFirstCategoryIdData
  
} from "../../../Redux/Actions/PolicyCategoryAction";

export default function PoliciesComponent() {
  const [placeholder] = React.useState(
    "Search e.g. code of conduct"
  );
  const history = useHistory();
  const dispatch = useDispatch();

  let checkAdmin =  isAdmin();
  useEffect(() => {
   
   
    dispatch(getPolicyCategoryData());
   
    //dispatch(getPolicyTableData(data));
  }, []);


//timer log....
useEffect(() => {
  const interval = setInterval(() => {
    dispatch(addInterval(localStorage.getItem('logID')))
  }, 60000);
  return () => clearInterval(interval);
}, []);


//anaylatics log save
  useEffect(() => {
    dispatch(addAnalayticsLog({

      module : "Connect/policies",
  
      browserDetails : `${platform.name} ${platform.os.architecture} ${platform.os.family} ${platform.os.version}`,
  
      deviceDetails : platform.product ? platform.product : 'Desktop',
  
      isSearch : false
  
  }));
    // you know what is this, don't you?
}, []);
  // useEffect(() => {
  //   dispatch(getPolicyFirstCategoryIdData())
  // }, []);

  const policyData = useSelector((state) => state.getPolicyCategoryDataReducer);
  
  // const policyTableDataState = useSelector(
  //   (state) => state.getPolicyTableDataReducer
  // );
 // const { getData: policyTableData } = policyTableDataState;
  const { getData: getPolicyData } = policyData;
  
  
  
  const [policyID, setPolicyID] = useState('');

  
// setPolicyID(getPolicyData[0].categoryId)

const [searchText, setSearchText] = useState('');

const handleSearchIcon = (params) => {
  history.push({
      pathname: `${POLICIES}${POLICIES_SEARCH_TABLE}`,
      state: {
        query: searchText,
        
      },
    })
}

const handleSearch = (e) => {
  const {value} = e.target;

  setSearchText(value)
};
const onKeyPress = (e) => {
  if(e.key === "Enter")
  { handleSearchIcon(); }
};


// useEffect(() => {
//   window.addEventListener("focus", () => console.log('i was focused'))

//   // returned function will be called on component unmount 
//   return () => {
//     window.removeEventListener("focus", () => console.log('i was focused'))
//   }
// }, [])


// useEffect(() => {
//   window.addEventListener("blur", () => console.log('i was blured'))

//   // returned function will be called on component unmount 
//   return () => {
//     window.removeEventListener("blur", () => console.log('i was blured'))
//   }
// }, [])
 
  return (
    <section>
      <Template>
        <Grid
          container
          direction="row"
          justifyContent="space-between"
          alignItems="center"
        >
          <Grid item xs={12} sm={7}>
            <p className="offCHeaderTitle">Policies</p>
          </Grid>
          <Grid item xs={12} sm={5}>
            <div className="policiesSearchWidth">
              <SearchBarButton 
                placeholder={placeholder} 
                handleSearchIcon = {handleSearchIcon} 
                name = 'policy' 
                handleSearch= {handleSearch}
                onKeyPress={onKeyPress}
              />
               
            </div>
          </Grid>
        </Grid>
        <PoliciesTabComponent policyCategory={getPolicyData}  admin = {checkAdmin}/>
       
        
      </Template>
    </section>
  );
}
