import {
  REMOVE_WHATSNEW_ITEM_LOAD,
  REMOVE_WHATSNEW_ITEM_FAILED,
  REMOVE_WHATSNEW_ITEM_SUCCESS,
  EDIT_WHATSNEW_ITEM_LOAD,
  EDIT_WHATSNEW_ITEM_FAILED,
  EDIT_WHATSNEW_ITEM_SUCCESS,
} from "../Contants/Constants";

const initialState = {
  getData: [],
  isLoading: false,
  isError: "",
};

const initialItemState = {
  getData: [],
  isLoading: false,
  isError: "",
};

const removeWhatsNewItemReducer = (state = initialState, action) => {
  switch (action.type) {
    case REMOVE_WHATSNEW_ITEM_LOAD:
      return {
        ...state,
        isLoading: true,
      };
    case REMOVE_WHATSNEW_ITEM_SUCCESS:
      return {
        ...state,
        getData: [...state.getData, action.payload],
        isLoading: false,
      };
    case REMOVE_WHATSNEW_ITEM_FAILED:
      return {
        ...state,
        isError: action.payload,
        isLoading: false,
      };
    default:
      return state;
  }
};

const editWhatsNewItemReducer = (state = initialItemState, action) => {
  switch (action.type) {
    case EDIT_WHATSNEW_ITEM_LOAD:
      return {
        ...state,
        isLoading: true,
      };
    case EDIT_WHATSNEW_ITEM_SUCCESS:
      return {
        ...state,
        getData: action.payload,
        isLoading: false,
      };
    case EDIT_WHATSNEW_ITEM_FAILED:
      return {
        ...state,
        isError: action.payload,
        isLoading: false,
      };
    default:
      return state;
  }
};

export { removeWhatsNewItemReducer, editWhatsNewItemReducer };
