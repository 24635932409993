import React, { useState, useEffect } from "react";
import "./OrgAnnouncements.css";
import {
  Paper,
  Popper,
  ClickAwayListener,
} from "@material-ui/core";
import shareFillIcon from "../../../Assets/Images/share-fill.svg"
import IconButton from "@material-ui/core/IconButton";
import moment from "moment";
import eyeLine from "../../../Assets/Images/eye_line.svg";
import Highlighter from "react-highlight-words";
import Sharefiles from "../../Common/Shareoption/Sharefiles";
import { useDispatch, useSelector } from "react-redux";
import { getOrgViewsData } from "../../../../src/Redux/Actions/OrgAction";

export default function OrgSelectFeilds({
  currentData,
  pdfBool,
  name,
  publishDate,
  index,
  data,
  isViewed,
  searchText,
}) {
  const dispatch = useDispatch();
  
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [viewList, setViewList] = useState(
    data?.viewedBy != null ? data?.viewedBy : []
  );

  const [viewCount, setViewCount] = useState(0);
  let empId = localStorage.getItem("token");

  useEffect(() => {
    setViewCount(viewList.length);
  }, [viewList]);

  const handleClick = () => {
    console.log('i was clicked')
    currentData(data);

    let viewed = false;
    viewed = viewList.find((obj) => {
      console.log(`Already viewed ===> ${obj == empId}`);
      return obj == empId;
    });
    if (!viewed) {
      var viewers = [...viewList, empId];
      setViewList(viewers);
      dispatch(getOrgViewsData(data?.contentID));
    }
    pdfBool(data?.contentID, !data?.isSelected);
  };

  const handleClickAway = () => {
    setAnchorEl(false);
  };

  const handleAnchorEl = (event, id) => {
    event.stopPropagation();
    event.preventDefault();
    setAnchorEl(anchorEl ? null : event.currentTarget);
    // setAnchorEl(event.currentTarget);
  };
  //26 Jan 2021
  // console.log("---data----", data);
  // console.log("---current data---", currentData);
  return (
    <div className="alignCardsItems">
      <div
        style={{
          display: "flex",
          alignContent: "center",
        }}
        onClick={handleClick}
        className={data?.isSelected ? "activeOrgContent" : ""}
      >
        {/* <div className="orgCheckbox"> */}
        {/* <CommonCheckBox onClick={pdfBool} /> */}
        {/* <Checkbox onChange={handleClick} />
        </div> */}
        <div className="alignOrgAnnouncementContentText">
          <p className="leftDivContentTitle">
            <Highlighter
              highlightStyle={{ backgroundColor:"yellow" }}
              searchWords={searchText?.split(" ")}
              textToHighlight={data?.name}
            />
          </p>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <p className="marginBlockNull leftDivContentDate">
              {moment(data?.publishDate).format("DD/MM/YYYY")}
            </p>
            <div
              style={{
                display: "flex",
                position: "absolute",
                right: "1em",
                justifyContent: "space-between",
              }}
            >
              <IconButton aria-label="share" className="p-0">
                <img
                  src={
                    anchorEl
                      ? shareFillIcon
                      : process.env.PUBLIC_URL + "/share_icon.png"
                  }
                  alt="Share Icon"
                  className="shareIcon mb-4 me-1"
                  onClick={(event) => handleAnchorEl(event)}
                />
              </IconButton>
              <Popper
                className="popperBlock"
                open={Boolean(anchorEl)}
                anchorEl={anchorEl}
              >
                <ClickAwayListener onClickAway={handleClickAway}>
                  <Paper
                    style={{
                      width: "226px",
                      height: "96px",
                      position: "relative",
                      right: "auto",
                      top: "14px",
                      boxShadow: "none",
                    }}
                    className="papperClass"
                  >
                    <Sharefiles pathURL={data?.documentFileName} id={data?.contentID} contentType="OrgAnnouncement" />
                  </Paper>
                </ClickAwayListener>
              </Popper>
              
              <img src={eyeLine} alt="eye" className="eye-line " />
              <p className="marginBlockNull mlEyeNum leftDivContentViews">
                {/* {viewList ? viewList.length : 0} */}
                {viewCount}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
