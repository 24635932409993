import { Grid, TextField } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import "./AddPoliciesModalComponent.css";
import leftArrow from "../../../../Assets/Images/WhatsNewIcons/arrow-right-s-line.svg";

import textFieldStyle from "../../../Common/Styles/TextFieldStyle";
import TextArea from "../../../Common/TextArea/TextArea";
import CommonMultiAutocomplete from "../../../Common/CommonMultiAutocomplete/CommonMultiAutocomplete";
import CommonLabel from "../../../Common/ComonLabel/CommonLabel";
import SubmitButton from "../../../Common/Button/SubmitButton";
import services from "../../../../Services";
import CommonModal from "../../../Common/CommonModal/CommonModal";
import editIcon from "../../../../Assets/Images/edit-line.svg";
import { yupToFormErrors } from "formik";
import Swal from "sweetalert2";
import { useDispatch } from "react-redux";
import { getPolicyTableDataByID } from "../../../../Redux/Actions/PolicyCategoryAction";


function UpdatePolicies({open, close, data}) {
    const dispatch = useDispatch();
    const [refresh, setrefresh] = useState(false)
    useEffect(() => {
        dispatch(getPolicyTableDataByID(data.subcategory))
    }, [refresh])

    const [policyName, setpolicyName] = useState("");
  const [policyPdf, setpolicyPdf] = useState(null);
  const [policyDescription, setpolicyDescription] = useState("");
  const [policyObjective, setpolicyObjective] = useState("");
  const [policyEligibility, setpolicyEligibility] = useState("");
  const [policyGuidlines, setpolicyGuidlines] = useState("");

  console.log(policyName)
  console.log(policyPdf)
  console.log(policyDescription)
  console.log(policyEligibility)
  console.log(policyGuidlines)
    const classes = textFieldStyle();
    console.log(data, 'open in  dtat')
    const handleCloseModal = () => {
        
    }

    const handleSubmit = () => {
        const formData = new FormData();
        formData.append("scope", "gloable");
        formData.append("policyName", policyName);
        formData.append("subcategory", data.subcategory);
        formData.append("description", policyDescription);
        formData.append("employeeId", localStorage.getItem("token"));
        formData.append("policy_pdf_link", policyPdf);
        formData.append("policyId", data.policyId)
        let data2 = formData;
        handleUpdatePolicyApi(data2)
    }

    const onBackButtonHandler = () => {
        console.log('back')
        close(false)
    }

    const handleChange = (e, name) => {
        console.log(name, "name");
        if (name === "name") {
          setpolicyName(e.target.value);
        }
        if (name === "Description") {
          setpolicyDescription(e.target.value);
        }
        if (name === "Objective") {
          setpolicyObjective(e.target.value);
        }
        if (name === "Eligibility") {
          setpolicyEligibility(e.target.value);
        }
        if (name === "Guidlines") {
          setpolicyGuidlines(e.target.value);
        }
        if (name === "pdf") {
          setpolicyPdf(e.target.files[0]);
        }
      };


      const handleUpdatePolicyApi = async (data) => {
        try {
          const res = await services.api(
            "POST",
            `/ui-assist/v1/policy`,
            data
          );
          if (res) {
            close(false)
            Swal.fire({
              position: "center",
              icon: "success",
              title: "Policy Updated Successfully",
              showConfirmButton: false,
              timer: 1500,
            });
            setrefresh(!refresh)
            // setOrgName("");
            // setOrgKey("");
            // setrefresh(!refresh);
            // setAccordianState(!accordianState);
          }
        } catch (err) {
          if (err.response) {
            Swal.fire({
              icon: "error",
              title: "Oops...",
              text: "Something went wrong!",
              footer: '<a href="">Why do I have this issue?</a>',
            });
          }
        }
      };
    return (
        <>
        <CommonModal
        open={open}
        handleClose={handleCloseModal}
        headerText="Edit policy"
        icon={editIcon}
        modalSize="lg"
        submitBtnText="SAVE CHANGES"
        cancleBtnText="BACK"
        // isBackBtn={true}
        // header={header}
        // footer={footer}
        onButtonClick={handleSubmit}
        fullWidth
        policiesButtonSize
        onCancleButtonClick={onBackButtonHandler}
      >
      
      
      <div className="leftRightAlign mb16">
          <Grid
            container
            direction="row"
            alignItems="center"
            spacing={3}
            className="gridRowSpacing"
          >
            <Grid item sm={3} xs={12}>
              <TextField
                variant="outlined"
                name="name"
                label="Policy name"
                className={`${classes.root} fullWidth customTextfield`}
                onChange={(e) => {
                  handleChange(e, "name");
                }}
                defaultValue={data.policyName}
              />
            </Grid>
            <Grid item sm={3} xs={12} className="uploadPDFDiv">
              <TextField
                variant="outlined"
                name="pdf"
                className={`${classes.root} fullWidth customTextfield`}
                onChange={(e) => {
                  handleChange(e, "pdf");
                }}
                type="file"
                
              />
              <div className="uploadPoliciesPDF">Upload pdf</div>
            </Grid>
            <Grid item sm={3} xs={12}>
              <div className="displayBlockUserDetails mlP">
                <p className="detailDivp">Last modified by</p>
                <p className="detailsFontSize">{data.modifiedBy}</p>
              </div>
            </Grid>
            <Grid item sm={3} xs={12}>
              <div className="displayBlockUserDetails mlP">
                <p className="detailDivp">Modified on</p>
                <p className="detailsFontSize">{data.modificationDate}</p>
              </div>
            </Grid>
          </Grid>
          <Grid container direction="row" alignItems="center" spacing={3}>
            <Grid item sm={12} xs={12}>
              <TextField
                variant="outlined"
                name="Description"
                label="Description"
                className={`${classes.root} fullWidth customTextfield`}
                onChange={(e) => {
                  handleChange(e, "Description");
                }}
                defaultValue={data.description}
              />
            </Grid>
            <Grid item sm={12} xs={12}>
              <TextField
                variant="outlined"
                name="Objective"
                label="Objective"
                className={`${classes.root} fullWidth customTextfield`}
                onChange={(e) => {
                  handleChange(e, "Objective");
                }}
                defaultValue="test objective"
              />
            </Grid>
            <Grid item sm={12} xs={12}>
              <TextField
                variant="outlined"
                name="Eligibility"
                label="Eligibility"
                className={`${classes.root} fullWidth customTextfield`}
                onChange={(e) => {
                  handleChange(e, "Eligibility");
                }}
                defaultValue="test eligibility"
              />
            </Grid>
            <Grid item sm={12} xs={12}>
              <TextField
                label="Guidlines"
                name="Guidlines"
                multiline
                rows={8}
                defaultValue="genereal guidlines"
                className={`${classes.root} fullWidth customTextfield`}
                onChange={(e) => {
                  handleChange(e, "Guidlines");
                }}
              />
            </Grid>
            <Grid
              item
              sm={3}
              xs={12}
              className="calenderIcon appPolicyContainer"
            >
              {/* <CommonMultiAutocomplete
              options={businessSelectOptions}
              label={<CommonLabel label={"Select Business"} />}
              handleSelectChange={handleBusinessGroupChange}
              selectedSubcategory={businessGroupValue}
              selectedValue={businessGroupValue}
              className="McShadowBox"
            /> */}
            </Grid>
          </Grid>
        </div>
      
      
      </CommonModal> 
        </>
    )
}

export default UpdatePolicies
