import React, { useState, useEffect } from "react";
import "../../MahindraCollective/UploadStory/UploadStoryComponent.css";
import { Paper, Grid } from "@material-ui/core";
import { useFormik } from "formik";
import Template from "./../../../Common/Template/Template";
import SubmitButton from "../../../Common/Button/SubmitButton";
import Swal from "sweetalert2";
import { useHistory, useLocation, useParams } from "react-router-dom";
import TruncateGradeLabel from "../TruncateGradeLabel";
import Loader from "../../../Common/Loader/Loader";

import ClubMembership from "./Benefits/ClubMembership";
import ClubMahindra from "./Benefits/ClubMahindra";
import CreditCard from "./Benefits/CreditCard";
import CTCVehicle from "./Benefits/CTCVehicle";
import MobileHandsetReimbursement from "./Benefits/MobileHandsetReimbursement";

import { useDispatch, useSelector } from "react-redux";
import {
  getBenefitByGradeData,
  addBenefitData,
  editBenefitData,
} from "../../../../Redux/Actions/AllEntitlementAction";

export default function UploadStoryComponent() {
  const location = useLocation();
  const dispatch = useDispatch();
  const history = useHistory();

  let gradeCode = useParams()?.id;

  let benefits = useSelector(
    (state) => state.getEntitlementReducer?.benefitsByGrade
  );
  let addBenefits = useSelector(
    (state) => state.getEntitlementReducer?.addBenefits
  );
  let editBenefits = useSelector(
    (state) => state.getEntitlementReducer?.editBenefits
  );

  const [gradeBenefits, setGradeBenefits] = useState();
  const [locationData, setLocationData] = useState();
  const [clubMahindra, setClubMahindra] = useState();
  const [clubMembership, setClubMembership] = useState();
  const [creditCard, setCreditCard] = useState();
  const [cTCVehicle, setCTCVehicle] = useState();
  const [mobile, setMobile] = useState();
  const [isValid, setIsValid] = useState(false);
  const [isAddAPICall, setIsAddAPICall] = useState(false);
  const [isEditAPICall, setIsEditAPICall] = useState(false);

  useEffect(() => {
    location?.state?.data && setLocationData(location?.state?.data);
    dispatch(getBenefitByGradeData(gradeCode));
    if (addBenefits?.length > 0 && isAddAPICall) {
      Swal.fire({
        title: "Benefits added successfully.",
        timer: 3000,
        icon: "success",
      });
      setIsAddAPICall(false);
    }
    if (editBenefits?.length > 0 && isEditAPICall) {
      Swal.fire({
        title: "Benefits edited successfully.",
        timer: 3000,
        icon: "success",
      });
      setIsEditAPICall(false);
    }
  }, [addBenefits, editBenefits]);

  useEffect(() => {
    setGradeBenefits(benefits);
  }, [benefits]);

  useEffect(() => {
    const check =
      clubMembership?.isValid &&
      clubMahindra?.isValid &&
      creditCard?.isValid &&
      cTCVehicle?.isValid &&
      mobile?.isValid;

    setIsValid(check);
  }, [clubMembership, clubMahindra, creditCard, cTCVehicle, mobile]);

  const onSubmit = () => {
    let details = {};
    if (clubMembership?.isAllowed) {
      const val = { clubMembership: clubMembership?.value };
      details = { ...details, ...val };
    }
    if (clubMahindra?.isAllowed) {
      const val = { clubMahindra: clubMahindra?.value };
      details = { ...details, ...val };
    }
    if (creditCard?.isAllowed) {
      const val = { creditCard: creditCard?.value };
      details = { ...details, ...val };
    }
    if (cTCVehicle?.isAllowed) {
      const val = { ctcVehicle: cTCVehicle?.value };
      details = { ...details, ...val };
    }
    if (mobile?.isAllowed) {
      const val = { mobileHandsetReimbursement: mobile?.value };
      details = { ...details, ...val };
    }
    // console.log("entitlement details =====>", details);
    const data = {
      gradeCode: gradeCode,
      gradeLabel: gradeBenefits?.gradeLabel
        ? gradeBenefits?.gradeLabel
        : locationData?.employeegrade,
      entitlementDetails: { ...details },
    };

    if (gradeBenefits?.entitlementCategoriesList?.length > 0) {
      dispatch(editBenefitData(data, gradeBenefits?.gradeCode));
      setIsEditAPICall(true);
    } else {
      dispatch(addBenefitData(data));
      setIsAddAPICall(true);
    }

    // console.log("entitlement details data =====>", data);
  };

  // console.log("benefits Data =====>", gradeBenefits);
  // console.log("club membership =====>", clubMembership);
  // console.log("club Mahindra =====>", clubMahindra);
  // console.log("credit card =====>", creditCard);
  // console.log("CTC Vehicle =====>", cTCVehicle);
  // console.log("Mobile =====>", mobile);
  // console.log("Check Validation =====>", isValid);
  // console.log("location Data =====> ", locationData);
  // console.log("url param value ====>", gradeCode);

  return (
    <Template pageName={""}>
      <div className="submitOfferCampaignContainer">
        <div className="whatsNewHeader ">
          <div className="containerTxt">
            <div className="section-header-text">
              <TruncateGradeLabel
                label={
                  gradeBenefits?.gradeLabel
                    ? gradeBenefits?.gradeLabel
                    : locationData?.employeegrade
                }
              />
            </div>
          </div>
        </div>
        <Paper className="paperContainerOfferCampaign">
          <div className="innerContainersubmitOfferCampaign">
            <ClubMembership
              data={gradeBenefits?.entitlementCategoriesList}
              setData={setClubMembership}
            />

            <ClubMahindra
              data={gradeBenefits?.entitlementCategoriesList}
              setData={setClubMahindra}
            />

            <CTCVehicle
              data={gradeBenefits?.entitlementCategoriesList}
              setData={setCTCVehicle}
            />

            <CreditCard
              data={gradeBenefits?.entitlementCategoriesList}
              setData={setCreditCard}
            />

            <MobileHandsetReimbursement
              data={gradeBenefits?.entitlementCategoriesList}
              setData={setMobile}
            />

            <div className="divContainerOfferCampaign">
              <div className="supportPart">
                <Grid container justifyContent="flex-end" alignItems="center">
                  <div className="SubmitForApprovalButtonDiv">
                    <SubmitButton
                      disabled={!isValid}
                      label="Submit Entitlement"
                      type="submit"
                      variant="contained"
                      className={`${
                        !isValid ? "SubmitForApprovalButton" : "enabledButton"
                      } commonFontNormalStyle`}
                      onClick={onSubmit}
                      size="large"
                    />
                  </div>
                </Grid>
              </div>
            </div>
          </div>
        </Paper>
      </div>
    </Template>
  );
}
