/* eslint-disable no-use-before-define */
import React, { useState, useEffect, useRef } from "react";
import TextField from "@material-ui/core/TextField";
import "./CustomMultiSelect.css";
import Autocomplete, {
  createFilterOptions,
} from "@material-ui/lab/Autocomplete";
import CheckBoxOutlineBlankIcon from "@material-ui/icons/CheckBoxOutlineBlank";
import CheckBoxIcon from "@material-ui/icons/CheckBox";
import { Checkbox } from "@material-ui/core";
import { Icon, makeStyles } from "@material-ui/core";
const useStyles = makeStyles({
  option: {
    fontSize: "14px",
    fontWeight: "normal",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: 1.57,
    letterSpacing: "normal",
    color: " #242424",
    backgroundColor: "transparent !important",
    "&:hover": {
      backgroundColor: "#f6eced !important",
      fontWeight: 600,
    },
  },
});

const focusStyle = { color: "#db233a" };

const CustomMultiSelect = ({
  items,
  selectedValues,
  label,
  placeholder,
  selectAllLabel,
  noOptionsText,
  limitTags,
  onToggleOption,
  onClearOptions,
  onSelectAll,
  getOptionLabel,
  disabled,
  name,
  errorMsg,
}) => {
  const allSelected = items?.length === selectedValues?.length;
  const handleToggleSelectAll = () => {
    onSelectAll && onSelectAll(!allSelected);
  };
  const ref = useRef();
  const [focus, setFocus] = useState(false);

  // useEffect(() => {
  //   // if (document.focus() && ref.current.contains(document.activeElement)) {
  //   //   setFocus(true);
  //   // }
  // }, []);
  const classes = useStyles();

  const handleChange = (event, selectedOptions, reason) => {
    if (reason === "select-option" || reason === "remove-option") {
      if (selectedOptions.find((option) => option.value === "select-all")) {
        handleToggleSelectAll();
        // onToggleOption && onToggleOption(selectedOptions);
      } else {
        onToggleOption && onToggleOption(selectedOptions);
      }
    } else if (reason === "clear") {
      onClearOptions && onClearOptions();
    }
  };
  const optionRenderer = (option, { selected }) => {
    const selectAllProps =
      option.value === "select-all" // To control the state of 'select-all' checkbox
        ? { checked: allSelected }
        : {};

    return (
      <>
        <Checkbox
          color="primary"
          icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
          checkedIcon={
            <CheckBoxIcon fontSize="small" style={{ color: "#e31837" }} />
          }
          style={{ marginRight: 8 }}
          checked={selected}
          {...selectAllProps}
        />
        {getOptionLabel(option)}
      </>
    );
  };
  const inputRenderer = (params) => (
    <TextField
      {...params}
      label={label}
      placeholder="search"
      variant="outlined"
      className="multiselectsearch"
    />
  );

  const getOptionSelected = (option, anotherOption) =>
    option.value === anotherOption.value;
  const filter = createFilterOptions();
  return (
    <>
      <Autocomplete
        name={name}
        ref={ref}
        onFocus={() => setFocus(true)}
        onBlur={() => setFocus(false)}
        className="Pankajtestdrop"
        multiple
        size="small"
        limitTags={limitTags ? limitTags : 1}
        options={items}
        value={selectedValues}
        disableCloseOnSelect
        disabled={disabled}
        classes={{
          option: classes.option,
        }}
        getOptionLabel={getOptionLabel}
        getOptionSelected={getOptionSelected}
        noOptionsText={noOptionsText}
        filterOptions={(options, params) => {
          const filtered = options && filter(options, params);
          return [{ title: selectAllLabel, value: "select-all" }, ...filtered];
        }}
        onChange={handleChange}
        popupIcon={<Icon style={focus ? focusStyle : null}>expand_more</Icon>}
        renderOption={optionRenderer}
        renderInput={inputRenderer}
      />
      {errorMsg && (
        <span style={{ color: "#f44336", fontSize: 12 }}>
          {errorMsg ? errorMsg : ""}
        </span>
      )}
    </>
  );
};

export default CustomMultiSelect;
