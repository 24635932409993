import React,{useState} from "react";
import "./ApprovalCenter.css";
import CommonModal from "../../Common/CommonModal/CommonModal";
import TextareaAutosize from "@material-ui/core/TextareaAutosize";
export default function RejectModal({
    open,
    handleClose,
    headerText,
    icon,
    modalSize,
    header,
    footer,
    fullWidth,
    onButtonClick,
}) {
    const handleCloseModal = () => {
        handleClose();

        setQuoteOfDay("");
        setCharSize(140);
    };


    const [quoteOfDay, setQuoteOfDay] = useState("");
    const [charSize, setCharSize] = useState(140);


    const handleChange = (event) => {
        const { value } = event.target;
        setQuoteOfDay(value);
        setCharSize(140 - value.length);
    };

    return (
        <section>
            <CommonModal
                open={open}
                handleClose={handleCloseModal}
                headerText="Reject Comment"
                modalSize="lg"
                submitBtnText="SAVE CHANGES"
                className="apM"
            >
                <div className="textAreaDiv">
                    <TextareaAutosize
                        className="textArea"
                        placeholder="Enter comment"
                        value={quoteOfDay}
                        onChange={handleChange}
                        maxlength="140"
                        className="textAreaRA"
                    />
                    <span style={{ position: "absolute" }} className="charsLeftTexts">
                        {charSize} characters left
                    </span>
                </div>
            </CommonModal>
        </section>
    );
}
