import services from "../../Services/index";
import Swal from "sweetalert2";
import {
    GET_ADD_BLOOD_REQUEST_DATA_SUCCESS,
    GET_ADD_BLOOD_REQUEST_DATA_LOAD,
    GET_ADD_BLOOD_REQUEST_DATA_FAILED,
} from "../Contants/Constants";

const getAddBloodRequestLoad = () => ({
    type: GET_ADD_BLOOD_REQUEST_DATA_LOAD,
});
const getAddBloodRequestSuccess = (data) => ({
    type: GET_ADD_BLOOD_REQUEST_DATA_SUCCESS,
    payload: data,
});
const getAddBloodRequestFailed = (error) => ({
    type: GET_ADD_BLOOD_REQUEST_DATA_FAILED,
    err: error,
});

export const getAddBloodRequestData = (requestData) => async (dispatch) => {
  dispatch(getAddBloodRequestLoad());
  try {
    const res = await services.api(
      "POST",
      `/ui-assist/v1/bloodrequest`,requestData
    );
    if (res) {
      dispatch(getAddBloodRequestSuccess(res.data));
    }
    // if(res.status === 200)
    // {
    //   Swal.fire(
    //           "Blood Request Sent Successfully!",
    //           " ",
    //           "success"
    //         );
    // }
  } catch (err) {
    dispatch(getAddBloodRequestFailed(err.response));
    Swal.fire("Something went wrong!", `${err?.response?.data}`, "error");
  }
};