import React, { useEffect } from "react";
import { BrowserRouter as Router, Route } from "react-router-dom";
import Layout from "./Components/Main/Layout";
import Routes from "./Routes/Routes";
import "./CustomizedScroll.css";
import "../src/Assets/globalStyle/globalStyle.css";
import AuthRoutes from "./Routes/AuthRoutes/AuthRoutes";
import Login from "./Components/Pages/Auth/Login/Login";
// import LoadingScreen from "./Components/LoadingScreen/LoadingScreen";
import { AUTH, LOGIN } from "./Routes/constant";
import ScrollToTop from "./Components/Common/scrollToTop";
// import { registerSocket } from "./Components/RegisterComponent";
// import { useMediaQuery } from "@material-ui/core";
import ReactGA from "react-ga";
import RouteChangeTracker from "./Components/RouteChangeTracker/RouteChangeTracker";
import { useSelector } from "react-redux";

function App() {
  // const isMobile = useMediaQuery("(max-width:960px)");
  const TRACKING_ID = "G-Q3F050GPMF"; // YOUR_OWN_TRACKING_ID
  ReactGA.initialize(TRACKING_ID);

  // const { isLocalStorage } = useSelector(
  //   (state) => state.getLocalStorageUpdationStatusReducer
  // );

  // useEffect(() => {
  //   function onReady() {
  //     var s = document.createElement("script");
  //     s.defer = true;
  //     s.setAttribute(
  //       "data-application-id",
  //       process.env.REACT_APP_MSOLVE_UTILITY_PORTAL_ID
  //     );
  //     s.setAttribute(
  //       "src",
  //       "https://digitalstore.mahindra.com/msolve/msolve_min.js"
  //     );
  //     s.setAttribute(
  //       "data-user_email",
  //       JSON.parse(localStorage.getItem("userProfile"))?.emailid
  //     );
  //     console.log(JSON.parse(localStorage.getItem("userProfile"))?.emailid);
  //     document.body.appendChild(s);
  //   }
  //   if (isLocalStorage) {
  //     onReady();
  //   }
  // }, [isLocalStorage]);

  return (
    <Router>
      <ScrollToTop />
      {/* <Route
          exact
          path={"https://mahindrarise.com/get_new_anubhavmrise.php"}
        /> */}
      {/*<Route exact path={LOGIN} component={Login} /> */}
      <RouteChangeTracker />
      <Route path={AUTH} component={AuthRoutes} />
      <Layout>
        <div className="mainLayoutContainer">
          <Routes />
        </div>
      </Layout>
    </Router>
  );
}

export default App;
