import services from "../../Services/index";
import Swal from "sweetalert2";
import {
    GET_REJECT_WALLPAPER_REQUEST_SUCCESS,
    GET_REJECT_WALLPAPER_REQUEST_LOAD,
    GET_REJECT_WALLPAPER_REQUEST_FAILED,
} from "../Contants/Constants";

const getRejectWallpaperRequestLoad = () => ({
    type: GET_REJECT_WALLPAPER_REQUEST_LOAD,
});
const getRejectWallpaperRequestSuccess = (data) => ({
    type: GET_REJECT_WALLPAPER_REQUEST_SUCCESS,
    payload: data,
});
const getRejectWallpaperRequestFailed = (error) => ({
    type: GET_REJECT_WALLPAPER_REQUEST_FAILED,
    err: error,
});

export const getRejectWallpaperRequestData = (id,sector) => async (dispatch) => {
  dispatch(getRejectWallpaperRequestLoad());
  try {
    const res = await services.api(
      "POST",
      `/ui-assist/v1/wallpaper/reject/${id}?sector=${sector}`
    );
    if (res) {
      dispatch(getRejectWallpaperRequestSuccess(res.data));
    }
    if (res.status === 201) {
        Swal.fire(
              "Successfully rejected!",
              " ",
              "success"
            );
      } else {
        Swal.fire("Oops", "Server error", "file not uploaded");
      }
  } catch (err) {
    dispatch(getRejectWallpaperRequestFailed(err.response));
    Swal.fire("Something went wrong!", `${err?.response?.data}`, "error");
  }
};