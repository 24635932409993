import React, { useState } from "react";
import "./OfferCampaignsCard.css";
import { Card, CardContent, CardMedia, CardActions } from "@material-ui/core";
import {
  LIVEWIRE,
  LIVE_WIRE_ARTICLE2,
  LIVE_WIRE_ARTICLE3,
} from "../../../../Routes/constant";
import { useHistory } from "react-router-dom";
import moment from "moment";
import { AspectRatio } from "react-aspect-ratio";
import RemoteModal from "../../Remote/RemoteVideoModal";
import Highlighter from "react-highlight-words";

const OfferCampaignsCard = ({ btnBool1, list, currentTab, searchText }) => {
  const {
    media,
    title,
    subTitle,
    publishDate,
    thumbnail,
    status,
    empPhotoUrl,
    videoUri,
  } = list;
  const history = useHistory();
  const modal = () => {
    setOpenSettingssub(!openSettingssub);
  };
  const [openSettingssub, setOpenSettingssub] = useState(false);

  const renderDetailsPage = () => {
    const item = list;

    history.push({
      pathname: `${LIVEWIRE}${LIVE_WIRE_ARTICLE3}/${currentTab}/?id=${list.id}`,
      state: { list: item, empPhotoUrl: empPhotoUrl },
    });
  };

  console.log("search text ====> ", searchText);

  return (
    <Card className="cardShadow">
      <CardMedia className="card-media" style={{ display: "flex" }}>
        {/* <div className="heightOCCardAlign"> */}
        <AspectRatio ratio="3/4" style={{ maxWidth: "400px" }}>
          <img
            onClick={() => renderDetailsPage()}
            src={thumbnail}
            alt="header logo"
            className="cardImage"
          />
        </AspectRatio>
        {videoUri && (
          <>
            <img
              src={process.env.PUBLIC_URL + "/youtube.png"}
              alt="Card Logo"
              style={{ position: "absolute", width: "40%", cursor: "pointer" }}
              onClick={modal}
            />
          </>
        )}
      </CardMedia>
      <CardContent>
        <div
          className="cardDescription cursorPointer"
          onClick={() => renderDetailsPage()}
        >
          <Highlighter
            highlightStyle={{ backgroundColor:"yellow" }}
            searchWords={searchText.split(" ")}
            textToHighlight={title}
          />
        </div>
        <p className="descriptiontext">
          <Highlighter
            highlightStyle={{ backgroundColor:"yellow" }}
            searchWords={searchText.split(" ")}
            textToHighlight={subTitle}
          />
        </p>
      </CardContent>

      <CardActions className="cardAction">
        <span className="actionContent ">
          {moment(publishDate).format("DD/MM/YYYY")}
        </span>
        {!btnBool1 ? (
          <>
            <a
              href={list.urlLink}
              rel="noreferrer"
              target="_blank"
              style={{ textDecoration: "none" }}
            >
              <span className="viewContent" onClick={() => renderDetailsPage()}>
                Click to view
              </span>{" "}
            </a>
          </>
        ) : (
          <> </>
        )}
        {btnBool1 ? (
          <>
            <span className="status">
              Status:
              {status === "published" ? (
                <span className="statusApproved"> Published</span>
              ) : status === "rejected" ? (
                <span className="statusRejected"> Rejected</span>
              ) : (
                <span className="statusApproved"> Submitted</span>
              )}
            </span>
          </>
        ) : (
          <> </>
        )}
      </CardActions>
      <RemoteModal
        open={openSettingssub}
        handleClose={() => setOpenSettingssub(false)}
        headerText="Video"
        modalSize="md"
        header={true}
        footer={true}
        fullWidth
        link={videoUri}
      />
    </Card>
  );
};

export default OfferCampaignsCard;
