import services from "../../Services/index";
import Swal from "sweetalert2";
import {
    GET_WALLPAPER_REQUEST_APPROVAL_SUCCESS,
    GET_WALLPAPER_REQUEST_APPROVAL_LOAD,
    GET_WALLPAPER_REQUEST_APPROVAL_FAILED,
} from "../Contants/Constants";

const getWallpaperRequestApprovalLoad = () => ({
    type: GET_WALLPAPER_REQUEST_APPROVAL_LOAD,
});
const getWallpaperRequestApprovalSuccess = (data) => ({
    type: GET_WALLPAPER_REQUEST_APPROVAL_SUCCESS,
    payload: data,
});
const getWallpaperRequestApprovalFailed = (error) => ({
    type: GET_WALLPAPER_REQUEST_APPROVAL_FAILED,
    err: error,
});

export const getWallpaperRequestApprovalData = (data) => async (dispatch) => {
  dispatch(getWallpaperRequestApprovalLoad());
  try {
    const res = await services.api(
      "POST",
      `/ui-assist/v1/wallpaper/pending`,data
    );
    if (res) {
      dispatch(getWallpaperRequestApprovalSuccess(res.data));
    }
    if (res.status === 201) {
        <></>
      } else {
        Swal.fire("Oops", "Server error", "file not uploaded");
      }
  } catch (err) {
    dispatch(getWallpaperRequestApprovalFailed(err.response));
    Swal.fire("Something went wrong!", `${err?.response?.data}`, "error");
  }
};