import React from "react";
import "./SubmitButton.css";
import { Button } from "@material-ui/core";
import PropTypes from "prop-types";
import LoadingButton from "@mui/lab/LoadingButton";

function SubmitButton({
  variant,
  color,
  type,
  label,
  className,
  size,
  onClick,
  disabled,
  icon,
  buttonStyling,
  variantBoolean,
  isLoader,
  downloadIcon,
  linkedinIcon,
}) {
  if (isLoader) {
    return (
      <div className="commanButton">
        <LoadingButton
          loading={isLoader}
          // loadingIndicator="Loading..."
          color={color}
          type={type && type}
          variant={variant}
          // eslint-disable-next-line no-useless-concat
          className={`commonButtonStyle ${className} ${
            buttonStyling ? "pr32 pl32" : ""
          } ${disabled ? "disabledStyle" : ""}`}
          size={size}
          style={{ borderRadius: "0px" }}
          onClick={onClick && onClick}
          disabled={disabled}
          startIcon={
            icon && <img src={icon} alt="icon" className="buttonIcon" />
          }
        >
          {label ? label : "DONE"}
        </LoadingButton>
      </div>
    );
  }

  return (
    <div className="commanButton">
      <Button
        color={color}
        type={type && type}
        variant={variantBoolean ? "outlined" : variant}
        // eslint-disable-next-line no-useless-concat
        className={`commonButtonStyle ${className} ${
          buttonStyling ? "pr32 pl32" : ""
        } ${disabled ? "disabledStyle" : ""}`}
        size={size}
        style={{ borderRadius: "0px" }}
        onClick={onClick && onClick}
        disabled={disabled}
        startIcon={
          icon && (
            <img
              src={icon}
              alt="icon"
              className={
                downloadIcon
                  ? "downloadIcon"
                  : linkedinIcon
                  ? "linkedInbuttonIcon"
                  : "buttonIcon"
              }
            />
          )
        }
      >
        {label ? label : "DONE"}
      </Button>
    </div>
  );
}

SubmitButton.propTypes = {
  variant: PropTypes.string,
  color: PropTypes.string,
  type: PropTypes.string,
  label: PropTypes.string,
  className: PropTypes.string,
  size: PropTypes.string,
  icon: PropTypes.string,
  buttonStyling: PropTypes.string,
};

SubmitButton.defaultProps = {
  variant: "contained",
  color: "secondary",
  type: "",
  label: "DONE",
  className: "",
  size: "small",
  icon: "",
  buttonStyling: "",
};

export default SubmitButton;
