import axios from "axios";
import { NOT_AUTH, AUTH } from "../Routes/constant";
import { Redirect, useHistory } from "react-router-dom";
import Swal from "sweetalert2";
export const baseUrl = `${process.env.REACT_APP_API_BASE_URL}`;

const services = {
  api: async (type, childUrl, data, isToken, headers) => {
    const history = useHistory;
    const url = `${baseUrl}${childUrl}`;
    if (
      type === "POST" ||
      type === "UPDATE" ||
      type === "PUT" ||
      type === "PATCH" ||
      type === "DELETE" ||
      type === "GET"
    ) {
      // try {
      let res;
      try {
        res = await axios({
          method: type,
          url: url,
          data: data,

          // headers: {
          //   Authorization: isToken
          //     ? `Bearer ${localStorage.getItem("token")}`
          //     : null,
          //   "Content-Type": "application/json",
          // },
        });

        /*  if (res.status === 403) {
          // history.push(`${AUTH}${NOT_AUTH}`);
          window.location.href = `${process.env.REACT_APP_DEV_URL}`;
        } else if (res.status === 401) {
          <Redirect to={`${AUTH}${NOT_AUTH}`} />;
        } else if (res.status === 405) {
          console.log("wrong api call........... try");
        } else {*/
        return res;
        //  }
        // } catch (err) {
        // const { status } = err?.response;
        // if (status === 403) {
        //   history.push(`${AUTH}${NOT_AUTH}`);
        // } else if (status === 401) {
        //   window.location.href = `${process.env.REACT_APP_DEV_URL}`;
        // } else {
        //   history.push(`${AUTH}${NOT_AUTH}`);
        // }
        // }
      } catch (error) {
        //console.log('page url',childUrl);
        //localStorage.removeItem('userProfile');
        //if any http error then clear cookies and localstorage

        const status = error?.response?.status;

        //console.log(window.location.href,'dddddddddd');
        //window.location.href = `${process.env.REACT_APP_DEV_URL}`+"?redirectTo="+window.location.href;
        //return false;
        if (status === 403) {
          //user dont have access
          console.log("in 403");
          Swal.fire(
            "Something went wrong!",
            `${error?.response?.data}`,
            "error"
          );
          window.location.href =
            `${process.env.REACT_APP_API_BASE_URL}` + "/auth/not-auth";
        } else if (status === 401) {
          //session not active then redirect o login with return url
          localStorage.clear();
          document.cookie =
            "authentication=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/ui-assist;";
          window.location.href =
            `${process.env.REACT_APP_DEV_URL}` +
            "?redirectTo=" +
            window.location.href;
          // window.location.href = `${process.env.REACT_APP_DEV_URL}`;
          console.log("in 401");
        } else if (status === 405) {
          console.log("in 405");
          Swal.fire(
            "Something went wrong!",
            `${error?.response?.data}`,
            "error"
          );
        } else {
          Swal.fire(
            "Something went wrong!",
            `${error?.response?.data}`,
            "error"
          );
        }
      }
    } else {
      return axios({
        method: type,
        url: url,
        // headers: {
        //   Authorization: isToken
        //     ? `Bearer ${localStorage.getItem("token")}`
        //     : null,
        //   "Content-Type": "application/json",
        // },
      });
    }
  },
};

export default services;
