import React from "react";
import "../Support/EthicsMobileComponent/EthicsMobileCard.css";
import CommonModal from "../../Common/CommonModal/CommonModal";
import "../Support/EthicsHelpLine.css";
import { Grid } from "@material-ui/core";
import ReactPlayer from "react-player/lazy";
import lappi from "../../../Assets/Images/lappi.svg";
import heartIcon from "../../../Assets/Images/heart-3-line.svg";
import IconButton from "@material-ui/core/IconButton";

const RemoteModal = ({
  open,
  handleClose,
  headerText,
  icon,
  modalSize,
  header,
  footer,
  fullWidth,
  onButtonClick,
  link,
}) => {
  const handleCloseModal = () => {
    handleClose();
  };

  return (
    <>
      <CommonModal
        open={open}
        handleClose={handleCloseModal}
        headerText={headerText}
        modalSize={modalSize}
        header={header}
        // footer={footer}
        fullWidth={fullWidth}
        backgroundColor
        icon={lappi}
        className="bannerModalEdit"
        contentType="video"
        addClassForVideo
      >
        <div className="remotePSize">
          <ReactPlayer
            url={link}
            controls
            width="100%"
            height="100%"
            className="playerStyle"
            playing
            onError={(err) => console.log("Error", err)}
          />
        </div>
        {/* <div>
                    <Grid container style={{padding:"0px 16px 16px 16px",fontSize:"18px",alignItems:"center"}} >
                        <Grid item xs={10}>
                            <div>
                                <strong>Laptop care</strong>
                            </div>
                        </Grid>
                        <Grid item xs={1} style={{paddingLeft:"65px"}}>
                            <IconButton aria-label="share">
                                <img
                                    src={process.env.PUBLIC_URL + "/share_icon.png"}
                                    alt="Share Icon"
                                    className="shareIcon"
                                />
                            </IconButton>
                        </Grid>
                        <Grid item xs={1} style={{paddingLeft:"30px"}}>
                            <IconButton aria-label="add to favorites">
                                <img src={heartIcon} alt="" />
                            </IconButton>
                        </Grid>
                    </Grid>
                    <Grid container style={{padding:"0px 16px 16px 16px",fontSize:"14px"}}>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam
                    </Grid>
                </div> */}
      </CommonModal>
    </>
  );
};

export default RemoteModal;
