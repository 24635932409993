import React from "react";
import "./CharLeftField.css";
import { TextField, InputAdornment } from "@material-ui/core";
import textFieldStyle from "../../Common/Styles/TextFieldStyle";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles({
  root: {
    "& label.Mui-focused": {
      color: "#333333",
    },
    "& .MuiInput-underline:after": {
      borderBottomColor: "#333333",
    },
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: "rgba(0, 0, 0, 0.12)",
      },
      "&:hover fieldset": {
        borderColor: "#a7a9ac",
      },
      "&.Mui-focused fieldset": {
        borderColor: "#333333",
      },
    },
  },
});

function CharLeftField({
  label,
  validate,
  charLeft,
  handleChange,
  value,
  name,
  inputProps,
  type,
  accept,
  wordCount,
  inputType,
  disabled,
  errorMsg,
  handleBlur,
  defValue,
  rows,
  multiline, 
  style
}) {
  const classes = useStyles();
  const commonTextFieldStyle = textFieldStyle();
  return (
    <div className="charLeftField">
      <TextField
        className={`${commonTextFieldStyle.root} ${
          inputType ? "inputTypeHeight" : ""
        } ${classes.textField} ${classes.root}`}
        onChange={handleChange}
        style={style? style: { width: "100%" }}
        value={value}
        name={name}
        type={type}
        accept={accept ? accept : null}
        label={label}
        disabled={disabled}
        variant="outlined"
        defaultValue={defValue}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              {validate && !wordCount ? (
                <span className="charsLeftText commonFontNormalStyle">
                  {charLeft !== "Write upto 3"
                    ? `${charLeft} characters left`
                    : charLeft}
                </span>
              ) : validate && wordCount ? (
                <span className="charsLeftText commonFontNormalStyle">
                  Write upto {wordCount}
                </span>
              ) : (
                ""
              )}
            </InputAdornment>
          ),
        }}
        inputProps={inputProps}
        helperText={errorMsg}
        error={errorMsg}
        onBlur={handleBlur}
        rows = {rows}
        multiline = {multiline}
      />
    </div>
  );
}

export default CharLeftField;
