import React from 'react'

function TermsAndCondition() {
  return (
    <div
          style={{
            textAlign: "center",
            marginTop: "68px",
            padding: "200px 0px"
          }}
        >
          <div>
            <h1>TERMS AND CONDITION...</h1>
          </div>
          <div>
            <p>
              
            </p>
          </div>
        </div>
  )
}

export default TermsAndCondition