import React from "react";
import "./NotificationsSetting.css";
import {
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableBody,
  withStyles,
} from "@material-ui/core";
import MuiTableCell from "@material-ui/core/TableCell";
import CommonSwitch from "../../Common/CommonSwitch/CommonSwitch";
import CommonToolTip from "../../Common/CommonToolTip";

const TableCell = withStyles({
  root: {
    borderBottom: "none",
    marginTop: "1em",
  },
})(MuiTableCell);

function NotificationsSetting({
  settingsData,
  handleChangeSwitch,
  handleChangeSwitch2,
  handleCheckedMobile,
  handleCheckedDesktop,
  handleChangeSwitchMobile
}) {
 
  return (
    <div>
      <TableContainer className="notificationsTable">
        <Table aria-label="simple table" className="tableSpace" stickyHeader>
          <TableHead>
            <TableRow className="tableRow">
              <TableCell className="tableCellNameHead commonFontNormalStyle">
                Notify me for...
              </TableCell>
              <TableCell className="tableCellNameHead commonFontNormalStyle textCenter">
                Rise@Work Web
              </TableCell>
              <TableCell className="tableCellNameHead commonFontNormalStyle textCenter">
                MeConnect Mobile
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {settingsData.map((result) => (
             
                <TableRow className="tableRow_body" key={result.title}>
                  <TableCell component="th" scope="row">
                    <p className="tableCellTitle">{result.title}</p>
                    <p className="tableCellNote">{result.note}</p>
                  </TableCell>
                  {/* <TableCell className="textCenter">
                    <CommonSwitch
                      checked={result.switch}
                      handleChange={handleChangeSwitch}
                      name={result.name}
                      style={{ color: `${!result.switch ? "#000000" : ""}` }}
                    />
                  </TableCell> */}
                  <TableCell className="textCenter">
                    {/* <CommonCheckBox
                      checked={result.desktop}
                      handleChange={handleCheckedDesktop}
                      inputProps={{ "aria-label": "primary checkbox" }}
                      name={result.name}
                    /> */}
                    <CommonSwitch
                     checked={result.switchWeb}
                      handleChange={handleChangeSwitch}
                      name={result.name}
                      style={{ color: `${!result.switch ? "#000000" : ""}` }}
                    />
                  </TableCell>
                  <TableCell className="textCenter">
                    {/* <CommonCheckBox
                      checked={result.mobile}
                      handleChange={handleCheckedMobile}
                      inputProps={{ "aria-label": "primary checkbox" }}
                      name={result.name}
                    /> */}
                    <CommonSwitch
                    checked={result.switchMobile}
                      handleChange={handleChangeSwitch2}
                      name={result.name_mobile}
                      style={{ color: `${!result.switch ? "#000000" : ""}` }}
                    />
                  </TableCell>
                </TableRow>
              
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
}

export default NotificationsSetting;
