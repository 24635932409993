import React from "react";
import "./ModifiedSection.css";
import { getFormattedDate } from "../../../../Assets/helperFunctions/helperFunctions";


function ModifiedSection({ modifiedBy="", modifiedDate="" }) {
  return (
    <div className="modifiedContainer">
      <div className="firstSectionModifield">
        <p className="modifiedText">Last modified by</p>
        <p className="modifiedSubText">{modifiedBy ? modifiedBy : 'Not Available'}</p>
      </div>
      <div className="secondSectionModifield">
        <p className="modifiedText">Modified on</p>
        <p className="modifiedSubText">
          {modifiedDate ? getFormattedDate(modifiedDate) : 'Not Available'}
        </p>
      </div>
    </div>
  );
}

export default ModifiedSection;
