import Swal from "sweetalert2";
import services from "../../Services/index";
import {
  GET_MAHINDRACOLLECTIVE_PENDING_DATA_LOAD,
  GET_MAHINDRACOLLECTIVE_PENDING__DATA_FAILED,
  GET_MAHINDRACOLLECTIVE_PENDING__DATA_SUCCESS,
  GET_MAHINDRA_COLLECTIVE_LIST_LOAD,
  GET_MAHINDRA_COLLECTIVE_LIST_FAILED,
  GET_MAHINDRA_COLLECTIVE_LIST_SUCCESS,
} from "../Contants/Constants";
// import axios from "axios";

const getMahindraCollectivePendingDataLoad = () => ({
  type: GET_MAHINDRACOLLECTIVE_PENDING_DATA_LOAD,
});
const getMahindraCollectivePendingDataSuccess = (data) => ({
  type: GET_MAHINDRACOLLECTIVE_PENDING__DATA_SUCCESS,
  payload: data,
});
const getMahindraCollectivePendingDataFailed = (error) => ({
  type: GET_MAHINDRACOLLECTIVE_PENDING__DATA_FAILED,
  err: error,
});

export const getMahindraCollectivePendingData =
  (isMahindraCollective) => async (dispatch) => {
    dispatch(getMahindraCollectivePendingDataLoad());
    try {
      const res = await services.api(
        "GET",
        `/ui-assist/v1/${
          isMahindraCollective ? "mahindracollective" : "offerorcampaign"
        }/pending`
      );
      if (res) {
        dispatch(getMahindraCollectivePendingDataSuccess(res.data));
      }
    } catch (err) {
      dispatch(getMahindraCollectivePendingDataFailed(err.response));
    }
  };

/* ======== For mahindra collective list ========== */
export const MC_RECORDS_OFFSET = 18;

const getMahindraCollectiveListLoad = () => ({
  type: GET_MAHINDRA_COLLECTIVE_LIST_LOAD,
});

const getMahindraCollectiveListSuccess = (data, pageNo) => ({
  type: GET_MAHINDRA_COLLECTIVE_LIST_SUCCESS,
  payload: data,
  pageNo: pageNo,
});

const getMahindraCollectiveListFailed = (error) => ({
  type: GET_MAHINDRA_COLLECTIVE_LIST_FAILED,
  err: error,
});

export const getMahindraCollectiveList =
  (
    pageNo = 0,
    storyType,
    sectors = [],
    businessUnits = [],
    year = "",
    months = [],
    searchText = "",
    sortOrder = "DESC"
  ) =>
  async (dispatch) => {
    dispatch(getMahindraCollectiveListLoad());
    try {
      let baseUrl = "/ui-assist/v1/filter/mahindracollective?";
      let params = [];
      let i = 0;

      params[i++] = `offset=${MC_RECORDS_OFFSET}`;

      if (pageNo >= 0) {
        params[i++] = `page=${pageNo}`;
      } else {
        params[i++] = `page=0`;
      }

      if (storyType) {
        params[i++] = `storyType=${storyType}`;
      }

      if (sectors.length > 0) {
        let sectorCSV = sectors.join(",");
        params[i++] = `sector=${sectorCSV}`;
      }

      if (businessUnits.length > 0) {
        let bus = businessUnits.join(",");
        params[i++] = `businessUnit=${bus}`;
      }

      if (year) {
        params[i++] = `year=${year}`;

        if (months.length > 0) {
          params[i++] = `months=${months.join(",")}`;
        }
      }

      if (searchText) {
        params[i++] = `q=${searchText}`;
      }

      if (sortOrder) {
        params[i++] = `sort=${sortOrder}`;
      }

      let queryString = "";
      if (params) {
        queryString = params.join("&");
      }

      const res = await services.api("GET", baseUrl + queryString);

      if (res) {
        dispatch(getMahindraCollectiveListSuccess(res.data, pageNo));
      }
    } catch (err) {
      dispatch(getMahindraCollectiveListFailed(err.response));
      Swal.fire("Something went wrong!", `${err?.response?.data}`, "error");
    }
  };
