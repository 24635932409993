import React, { useState } from "react";
import "./SiteMap.css";
import { Card, CardContent, Grid, Paper } from "@material-ui/core";
import { siteMapData } from "./SiteMapData";
import { useMediaQuery, useTheme } from "@material-ui/core";
import { Link } from "react-router-dom";

//accordian
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";

export default function SiteCard({ config }) {
  const Bp = useTheme();
  const isXs = useMediaQuery(Bp.breakpoints.down("xs"));
  return (
    <div className="siteFF">
      {isXs ? (
        <>
          {" "}
          {config.map((item, index) => (
            <Accordion style={{ marginBottom: "40px", marginTop: "-16px" }}>
              <AccordionSummary
                expandIcon={
                  <ExpandMoreIcon className="siteMapAccordianArrow" />
                }
                aria-controls="panel1a-content"
                id="panel1a-header"
                className="siteTitle"
              >
                <Grid item xs={12} sm={12} key={index}>
                  <p
                    style={{ marginLeft: "16px", marginTop: "18px" }}
                    className={isXs ? `cardTitleSMobile` : `cardTitleS`}
                  >
                    {item.title}
                  </p>
                </Grid>
              </AccordionSummary>

              <AccordionDetails>
                <Grid
                  container
                  direction="row"
                  justifyContent="flex-start"
                  alignItems="flex-start"
                  className={item.visibleBool === "true" ? "visibleB" : ""}
                >
                  {item.subMenu.map((dataDesc, index) => {
                    return (
                      <Grid item xs={12} sm={4} md={3} key={index}>
                        <Card
                          variant="outlined"
                          style={{ border: "none", marginLeft: "2px" }}
                          className="siteMapCardMobile"
                        >
                          <CardContent
                            className=""
                            style={{ border: "none", marginLeft: "16px" }}
                          >
                            <Grid
                              container
                              direction="column"
                              justifyContent="flex-start"
                            >
                              <Grid item xs={12} sm={12} key={index}>
                                <p
                                  className={
                                    item.title == "Help" ||
                                    item.title == "Covid 19"
                                      ? `cardTitleSub2`
                                      : `cardTitleSub1`
                                  }
                                >
                                  {dataDesc.linkR ? (
                                    <Link
                                      className={
                                        item.title == "Help" ||
                                        item.title == "Covid 19"
                                          ? `cardTitleSub1`
                                          : `cardTitleSub1`
                                      }
                                      to={dataDesc.linkR}
                                    >
                                      {dataDesc.title}
                                    </Link>
                                  ) : (
                                    <a
                                      target={dataDesc.link && "_blank"}
                                      rel={dataDesc.link && "noreferrer"}
                                      className={
                                        item.title == "Help" ||
                                        item.title == "Covid 19"
                                          ? `cardTitleSub1`
                                          : `cardTitleSub1`
                                      }
                                      href={dataDesc.link}
                                    >
                                      {dataDesc.title}
                                    </a>
                                  )}
                                  {/* {dataDesc.title} */}
                                </p>
                                {dataDesc.subOptions?.map((result) => {
                                  return (
                                    <>
                                      <Grid item xs={12} sm={12} key={index}>
                                        <p className="circle">
                                          {result.linkR ? (
                                            <Link
                                              className={
                                                item.title == "Help" ||
                                                item.title == "Covid 19"
                                                  ? `cardTitleSub2`
                                                  : `cardTitleSub2`
                                              }
                                              to={result.linkR}
                                            >
                                              {result.linkName}
                                            </Link>
                                          ) : (
                                            <a
                                              target={result.link && "_blank"}
                                              rel={result.link && "noreferrer"}
                                              className={
                                                item.title == "Help" ||
                                                item.title == "Covid 19"
                                                  ? `cardTitleSub2`
                                                  : `cardTitleSub2`
                                              }
                                              href={result.link}
                                            >
                                              {result.linkName}
                                            </a>
                                          )}
                                        </p>
                                      </Grid>
                                    </>
                                  );
                                })}
                              </Grid>
                            </Grid>
                          </CardContent>
                        </Card>
                      </Grid>
                    );
                  })}
                </Grid>
              </AccordionDetails>
            </Accordion>
          ))}
        </>
      ) : (
        <>
          {config?.length > 0 &&
            config?.map((item, index) => (
              <Paper
                style={{ marginBottom: "16px" }}
                className="sitepaper"
                key={index}
              >
                <Grid
                  container
                  direction="row"
                  justifyContent="space-between"
                  alignItems="center"
                >
                  <Grid item xs={12} sm={12} className="siteTitle" key={index}>
                    <p
                      style={{ marginLeft: "16px", marginTop: "18px" }}
                      className="cardTitleS"
                    >
                      {item.title}
                    </p>
                  </Grid>
                  <Grid
                    container
                    direction="row"
                    justifyContent="flex-start"
                    alignItems="flex-start"
                    className={item.visibleBool === "true" ? "visibleB" : ""}
                  >
                    {item.subMenu.map((dataDesc, index) => {
                      return (
                        <Grid item xs={12} sm={4} md={3} key={index}>
                          <Card
                            variant="outlined"
                            style={{ border: "none", marginLeft: "2px" }}
                          >
                            <CardContent
                              className=""
                              style={{ border: "none", marginLeft: "16px" }}
                            >
                              <Grid
                                container
                                direction="column"
                                justifyContent="flex-start"
                              >
                                <Grid item xs={12} sm={12} key={index}>
                                  <p
                                    className={
                                      item.title == "Help" ||
                                      item.title == "Covid 19"
                                        ? `cardTitleSub2`
                                        : `cardTitleSub1`
                                    }
                                  >
                                    {dataDesc.linkR ? (
                                      <Link
                                        className={
                                          item.title == "Help" ||
                                          item.title == "Covid 19"
                                            ? `cardTitleSub1`
                                            : `cardTitleSub1`
                                        }
                                        to={dataDesc.linkR}
                                      >
                                      <span className={item.title ==="Covid-19"||item.title ==="Help & support"||item.title ==="Profile" ? 'textColorWhenNull':""}>
                                        {dataDesc.title}

                                      </span>
                                      </Link>
                                    ) : (
                                      <a
                                        target={dataDesc.link && "_blank"}
                                        rel={dataDesc.link && "noreferrer"}
                                        className={
                                          item.title == "Help" ||
                                          item.title == "Covid 19"
                                            ? `cardTitleSub1`
                                            : `cardTitleSub1`
                                        }
                                        href={dataDesc.link}
                                      >
                                        <span className={item.title ==="Covid-19"||item.title ==="Help & support"||item.title ==="Profile" ? 'textColorWhenNull':""}>
                                            {dataDesc.title}
                                        </span>
                                      </a>
                                    )}
                                    {/* {dataDesc.title} */}
                                  </p>
                                </Grid>

                                {dataDesc.subOptions?.map((result) => {
                                  return (
                                    <>
                                      <Grid item xs={12} sm={12} key={index}>
                                        <p className="circle">
                                          {result.linkR ? (
                                            <Link
                                              className={
                                                item.title == "Help" ||
                                                item.title == "Covid 19"
                                                  ? `cardTitleSub2`
                                                  : `cardTitleSub2`
                                              }
                                              to={result.linkR}
                                            >
                                              {result.linkName}
                                            </Link>
                                          ) : (
                                            <a
                                              target={result.link && "_blank"}
                                              rel={result.link && "noreferrer"}
                                              className={
                                                item.title == "Help" ||
                                                item.title == "Covid 19"
                                                  ? `cardTitleSub2`
                                                  : `cardTitleSub2`
                                              }
                                              href={result.link}
                                            >
                                              {result.linkName}
                                              {!result.link && " (Coming Soon)"}
                                            </a>
                                          )}
                                        </p>
                                      </Grid>
                                    </>
                                  );
                                })}
                              </Grid>
                            </CardContent>
                          </Card>
                        </Grid>
                      );
                    })}
                  </Grid>
                </Grid>
              </Paper>
            ))}
        </>
      )}
    </div>
  );
}
