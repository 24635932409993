import React from "react";
import { Switch } from "react-router-dom";
import NotFound from "../../Components/NotFound/NotFound";

import { SITE_MAP, DISCLAIMER, PRIVACYPOLICY } from "../constant";

import SiteMap from "../../Components/Pages/SiteMap/SiteMap";
import LegalDisclaimerr from "../../Components/Pages/LegalDisclaimer/LegalDisclaimer";
import PrivacyPolicyy from "../../Components/Pages/PrivacyPolicy/PrivacyPolicy";
import { ApmRoute } from "@elastic/apm-rum-react";

function SiteMapRoutes(props) {
  return (
    <div>
      <Switch>
        <ApmRoute path={`${SITE_MAP}`} component={SiteMap} />
        <ApmRoute path={`${DISCLAIMER}`} component={LegalDisclaimerr} />
        <ApmRoute path={`${PRIVACYPOLICY}`} component={PrivacyPolicyy} />
        <ApmRoute component={NotFound} />
      </Switch>
    </div>
  );
}

export default SiteMapRoutes;
