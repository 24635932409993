import React, { useState, useEffect } from "react";
import {
  Grid,
  FormControlLabel,
  RadioGroup,
  Checkbox,
} from "@material-ui/core";
import offerCompaignApprovalIcon from "../../../Assets/Images/offer-compaign-approval.svg";
import CommonLabel from "../../Common/ComonLabel/CommonLabel";
import DatePicker from "../../Common/DatePicker/DatePicker";
import CommonModal from "../CommonModal/CommonModal";
import { getFormattedDate } from "../../../Assets/helperFunctions/helperFunctions";
import { useDispatch, useSelector } from "react-redux";
import { getPublishToWhatsNewData } from "../../../Redux/Actions/PublishToWhatsNewAction";
import { useHistory } from "react-router-dom";

export default function CommonWhatsNewDialog({
  title,
  submitForPublished,
  handleCloseModal,
  articleId,
  module,
}) {
  const history = useHistory();
  const dispatch = useDispatch();
  const publishData = useSelector((state) => state.getPublishToWhatsNewReducer);
  console.log("NewRevamp", publishData);

  const [isWhatsNew, setIsWhatsNew] = useState(false);
  const [isPublished, setIsPublished] = useState(false);
  const [expiryDate, setExpiryDate] = useState({ date: null });

  useEffect(() => {
    submitForPublished && setIsWhatsNew(false);
  }, [submitForPublished]);

  useEffect(() => {
    if (
      (publishData.status == "success" && isWhatsNew && expiryDate.date) ||
      (publishData.status == "success" && isPublished)
    ) {
      history.push(`/connect/livewire/mc-article2/allarticle/?id=${articleId}`);
    }
  }, [publishData]);

  const handleWhatsNew = (e) => {
    setIsWhatsNew(!isWhatsNew);
  };
  const handlePublish = (e) => {
    setIsPublished(!isPublished);
  };

  const handlePublishStory = () => {
    let formData = new FormData();
    formData.append("admin", localStorage.getItem("token"));
    formData.append("iswhatsnew", isWhatsNew);
    formData.append(
      "whatsnewexpiry",
      getFormattedDate(expiryDate.date, "YYYY/MM/DD")
    );

    dispatch(getPublishToWhatsNewData(module, articleId, formData));
  };

  console.log(
    "selected date ======>",
    getFormattedDate(expiryDate.date, "YYYY/MM/DD")
  );
  return (
    <CommonModal
      open={submitForPublished}
      handleClose={handleCloseModal}
      headerText="Publish article"
      icon={offerCompaignApprovalIcon}
      modalSize="md"
      submitBtnText="SAVE CHANGES"
      fullWidth
      onButtonClick={handlePublishStory}
      disabledSubmitButton={!((isWhatsNew && expiryDate.date) || isPublished)}
    >
      <div className="Modalcontent">
        <div className="publishArticleTitle">
          The story <b>`{title}`</b>
          {isWhatsNew
            ? `will be published on What's New`
            : " will be published"}
        </div>
        <div className="publishContentInputs">
          <Grid container className="articleM24">
            <Grid item md={12} xs={12}>
              <RadioGroup aria-label="I am the author" name="author">
                <Grid container spacing={5}>
                  <Grid item md={5} xs={12}>
                    <div
                      className={`${
                        isPublished ? "radioBackground" : ""
                      } authorRadioButton`}
                    >
                      <FormControlLabel
                        onChange={(e) => {
                          handlePublish(e);
                          // formik.setFieldValue("iswhatsnew", !iswhatsnew);
                        }}
                        value={isPublished}
                        name="ispublished"
                        className="radioButtonForm"
                        control={<Checkbox />}
                        label={
                          <span className="formControlLabelStyle commonFontNormalStyle">
                            Publish now
                          </span>
                        }
                        labelPlacement="end"
                      />
                    </div>
                  </Grid>
                  <Grid item md={5} xs={12}>
                    <div
                      className={`${
                        isWhatsNew ? "radioBackground" : ""
                      } authorRadioButton`}
                    >
                      <FormControlLabel
                        onChange={(e) => {
                          handleWhatsNew(e);
                          // formik.setFieldValue("iswhatsnew", !iswhatsnew);
                        }}
                        value={isWhatsNew}
                        name="iswhatsnew"
                        className="radioButtonForm"
                        control={<Checkbox />}
                        label={
                          <span className="formControlLabelStyle commonFontNormalStyle">
                            Publish story to What’s new
                          </span>
                        }
                        labelPlacement="end"
                      />
                    </div>
                  </Grid>
                  <Grid item md={5} xs={12}>
                    <DatePicker
                      minDate={new Date().setDate(new Date().getDate() + 1)}
                      disabled={!isWhatsNew}
                      label={<CommonLabel label="Expiry date" />}
                      selectedDate={expiryDate.date}
                      handleDateChange={(date) => {
                        setExpiryDate({ ...expiryDate, date: date });
                      }}
                      name="expiryDate"
                      // errorMsg={
                      //   formik.errors.expiryDate ? formik.errors.expiryDate : ""
                      // }
                    />
                  </Grid>
                </Grid>
              </RadioGroup>
            </Grid>
          </Grid>
        </div>
      </div>
    </CommonModal>
  );
}
