import {
  EDIT_SAPDOWNTIME_SUCCESS,
  EDIT_SAPDOWNTIME_LOAD,
  EDIT_SAPDOWNTIME_FAILED,
  POST_SAPDOWNTIME_SUCCESS,
  POST_SAPDOWNTIME_LOAD,
  POST_SAPDOWNTIME_FAILED
} from "../Contants/Constants";

const initialState = {
  editedData: {},
  addData: {},
  isLoading: false,
  isError: "",
  status: "idle"
};

const getAllDownTimeReducer = (state = initialState, action) => {
  switch (action.type) {
    case EDIT_SAPDOWNTIME_LOAD:
      return {
        ...state,
        isLoading: true,
        status: "pending"
      };
    case EDIT_SAPDOWNTIME_SUCCESS:
      return {
        ...state,
        editedData: action.payload,
        isLoading: false,
        status: "success"
      };
    case EDIT_SAPDOWNTIME_FAILED:
      return {
        ...state,
        isError: action.payload,
        isLoading: false,
        status: "failed"
      };

    case POST_SAPDOWNTIME_LOAD:
      return {
        ...state,
        isLoading: true,
        status: "pending"
      };
    case POST_SAPDOWNTIME_SUCCESS:
      return {
        ...state,
        addData: action.payload,
        isLoading: false,
        status: "success"
      };
    case POST_SAPDOWNTIME_FAILED:
      return {
        ...state,
        isError: action.payload,
        isLoading: false,
        status: "failed"
      };
    default:
      return state;
  }
};

export default getAllDownTimeReducer;
