import React from "react";
import { Switch } from "react-router-dom";
import EditNewPolicies from "../../Components/Pages/Policies/EditNewPolicies/EditNewPolicies";
import PoliciesComponent from "../../Components/Pages/Policies/PoliciesComponent";
import SearchPoliciesComponent from "../../Components/Pages/Policies/SearchPolicies/SearchPoliciesComponent";
//import SearchPoliciesComponent from "../../Components/Pages/Policies/SearchPolicies/SearchPoliciesComponent";
import SearchSopTableComponent from "../../Components/Pages/SOP/SearchSopTable/SearchSopTableComponent";
import EditSubCategories from "../../Components/Pages/Policies/EditSubcategories/EditSubCategories";
import EditPolicys from "../../Components/Pages/Policies/EditPolicys/EditPolicys";
import {
  POLICIES,
  POLICIES_SEARCH,
  POLICIES_SEARCH_TABLE,
  POLICIES_EDIT,
  POLICIES_SUBCATEGORY,
  POLICYS_EDIT,
  POLICIES_DETAILS,
  SOP,
  SOP_DETAILS,
  SOP_SEARCH_TABLE,
} from "../constant";
// import SopSearchComponent from "../../Components/Pages/SOPs/SopsSearchTemplate/SopSearchComponent";
import SopComponent from "../../Components/Pages/SOP/SopComponent";
import SopSearchComponent from "../../Components/Pages/SOP/SopSearchTemplate/SopSearchComponent";
import { ApmRoute } from "@elastic/apm-rum-react";

export default function SopRoutes() {
  return (
    <div>
      <Switch>
        <ApmRoute exact path={`${SOP}`} component={SopComponent} />
        <ApmRoute
          exact
          path={`${SOP}${POLICIES_SEARCH}`}
          component={SearchPoliciesComponent}
        />
        <ApmRoute
          exact
          path={`${SOP}${SOP_SEARCH_TABLE}`}
          component={SearchSopTableComponent}
        />
        <ApmRoute
          exact
          path={`${SOP}${POLICIES_EDIT}`}
          component={EditNewPolicies}
        />
        <ApmRoute path={`${SOP}${POLICYS_EDIT}/`} component={EditPolicys} />
        <ApmRoute
          path={`${SOP}${SOP_DETAILS}/`}
          component={SopSearchComponent}
        />
      </Switch>
    </div>
  );
}
