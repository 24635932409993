import {
  GET_MAHINDRACOLLECTIVE_PENDING_DATA_LOAD,
  GET_MAHINDRACOLLECTIVE_PENDING__DATA_FAILED,
  GET_MAHINDRACOLLECTIVE_PENDING__DATA_SUCCESS,
  GET_MAHINDRA_COLLECTIVE_LIST_LOAD,
  GET_MAHINDRA_COLLECTIVE_LIST_FAILED,
  GET_MAHINDRA_COLLECTIVE_LIST_SUCCESS,
} from "../Contants/Constants";

const initialState = {
  getData: [],
  isLoading: false,
  isError: "",
};

export const getMahindraCollectivePendingReducer = (
  state = initialState,
  action
) => {
  switch (action.type) {
    case GET_MAHINDRACOLLECTIVE_PENDING_DATA_LOAD:
      return {
        ...state,
        isLoading: true,
      };
    case GET_MAHINDRACOLLECTIVE_PENDING__DATA_SUCCESS:
      return {
        ...state,
        getData: action.payload,
        isLoading: false,
      };
    case GET_MAHINDRACOLLECTIVE_PENDING__DATA_FAILED:
      return {
        ...state,
        isError: action.payload,
        isLoading: false,
      };
    default:
      return state;
  }
};

const initialMCState = {
  getTotalCount: 0,
  getData: [],
  isLoading: false,
  isError: "",
};

export const getMahindraCollectiveListReducer = (
  state = initialMCState,
  action
) => {
  switch (action.type) {
    case GET_MAHINDRA_COLLECTIVE_LIST_LOAD:
      return {
        ...state,
        isLoading: true,
      };
    case GET_MAHINDRA_COLLECTIVE_LIST_SUCCESS:
      if (action.payload.record.length === 0) {
        return {
          ...state,
          isLoading: false,
          getTotalCount: action?.payload?.totalCount,
          getData:
            action.pageNo == 0
              ? [...action.payload.record]
              : [...state.getData, action.payload.record],
        };
      } else {
        return {
          ...state,
          isLoading: false,
          getTotalCount: action?.payload?.totalCount,
          getData: action.payload.record,
        };
      }
    case GET_MAHINDRA_COLLECTIVE_LIST_FAILED:
      return {
        ...state,
        isError: action.payload,
        isLoading: false,
      };
    default:
      return state;
  }
};
