import "./PrivacyPolicy.css";
import React, { useState, useEffect } from "react";
import "../SiteMap/SiteMap.css";
import { Grid, Paper } from "@material-ui/core";
import Template from "../../Common/Template/Template";

export default function PrivacyPolicy() {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return (
        <section>
            <>
                <Template>
                    <Grid container direction="row" justifyContent="space-between" alignItems="center">
                        <Grid item xs={12} sm={7}>
                            <p className="offCHeaderTitle mbT">Privacy policy</p>
                        </Grid>
                    </Grid>
                    <Paper style={{ marginBottom: "16px" }} className="sitepaper siteFF">
                        <Grid container direction="row" justifyContent="space-between" alignItems="center">
                            <Grid
                                container
                                direction="row"
                                justifyContent="flex-start"
                                alignItems="center"
                                style={{ padding: "16px" }}
                            >
                                <Grid item xs={12} sm={12}>
                                    <div>
                                        Mahindra & Mahindra Limited (including its subsidiaries and
                                        affiliates, hereafter referred to as “we”/ “M&M”) is committed to
                                        protecting the privacy and security of your personal data. The
                                        protection of your privacy in the processing of your personal data is
                                        an important concern to which we pay special attention in our business
                                        processes. <br />
                                        <br />
                                        This privacy notice describes why and how we collect and use personal
                                        data about you during and after your working relationship with us,
                                        with whom we might share it and how long we usually keep it. This
                                        notice applies to all employees, workers and contractors.
                                    </div>
                                    <div className="PPolicymTop">
                                        <strong>1. Categories of personal data we process</strong>
                                        <div className="PPolicyPl">
                                            <div>
                                                Personal data means any information relating to an identified
                                                or identifiable natural person. It does not include data where
                                                indications to a person’s identity have been removed
                                                (anonymised data). <br /> We process the following categories
                                                of personal information about you:
                                            </div>

                                            <div className="PPolicymTop">
                                                <div>Personal basic data:</div>
                                                <li>
                                                    personal contact details such as name, title, addresses,
                                                    telephone numbers, and personal email addresses
                                                </li>
                                                <li>date of birth</li>
                                                <li>gender</li>
                                                <li>emergency contact information</li>
                                                <li>marital status and dependents</li>
                                                <li>government identification numbers</li>
                                                <li>bank account number</li>
                                                <li>nationality</li>
                                                <li>languages</li>
                                                <li>photo</li>
                                                <li>passport details</li>
                                            </div>
                                            <div className="PPolicymTop">
                                                <div>Data related to employment relationship:</div>
                                                <li>work contact details</li>
                                                <li>
                                                    type of contract, contract details, employment ID number
                                                </li>
                                                <li>
                                                    title, position description, working history and records,
                                                    working location, years of services
                                                </li>
                                                <li>
                                                    salary, benefits, tax and pension-related information and
                                                    historical records
                                                </li>
                                                <li>
                                                    performance-related data, such as your objectives,
                                                    performance discussions and assessment records, feedback
                                                    and tests results
                                                </li>
                                                <li>
                                                    qualifications and personal development related data, such
                                                    as records of development discussions and competence
                                                    assessments, learning and development records, records of
                                                    career and succession planning
                                                </li>
                                                <li>
                                                    recruitment history – CV details, previous work
                                                    experiences and educational background, any information
                                                    you have provided us during the interviews, information
                                                    gathered from reference persons whose contact details you
                                                    have provided to us
                                                </li>
                                                <li>
                                                    information related to immigration, right-to-work and
                                                    residence status
                                                </li>
                                                <li>
                                                    travelling and travel expenses related data as well as
                                                    user account details
                                                </li>
                                                <li>
                                                    user logs and access control related information,
                                                    including physical access to M&M premises
                                                </li>
                                                <li>
                                                    CCTV footage and other information obtained through
                                                    electronic means such as swipe card records
                                                </li>
                                                <li>
                                                    information about your use of our information and
                                                    communications systems and other M&M’s assets, e.g.
                                                    emails, computers, servers etc.
                                                </li>
                                            </div>
                                            <div className="PPolicymTop">
                                                <div>Sensitive personal data:</div>
                                                <li>information about religion</li>
                                                <li>health records</li>
                                                <li>biometric data</li>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="PPolicymTop">
                                        <strong>2. Purposes for which we process your data</strong>
                                        <div className="PPolicyPl">
                                            <li>
                                                Entering or performing employment contracts, determining the
                                                terms on which you work for M&M, management of employment
                                                contract, and other contracts related to employment relation.
                                            </li>
                                            <li>
                                                Checking if you are legally entitled to work in the country
                                                where we operate and your resident status.
                                            </li>
                                            <li>
                                                Payments of agreed compensation and as applicable deducting
                                                tax, mandatory social contributions and national insurance
                                                contributions in accordance with local legislation.
                                            </li>
                                            <li>
                                                Attendance monitoring, vacation, absence and sick/leave
                                                administration.
                                            </li>
                                            <li>
                                                Determining performance requirements, setting individual
                                                targets, conducting regular performance reviews and
                                                assessments, managing performance records and analytics in
                                                accordance with M&M’s performance and development processes.
                                            </li>
                                            <li>
                                                Your participation in recruitments – conducting recruitment
                                                process, arranging interviews and testing, making a decision
                                                on appointment according to M&M recruitment policy.
                                            </li>
                                            <li>
                                                Travel management – arrangement of accommodation and flights,
                                                managing travel expenses reports and payments, arranging visa
                                                invitations and/or visas.
                                            </li>
                                            <li>
                                                Assurance of network and information security, including
                                                access management to prevent unauthorized access to our
                                                computers and electronic communications systems and preventing
                                                malicious software distribution.
                                            </li>
                                            <li>
                                                Monitoring your work-related use of our information and
                                                communication systems and other M&M assets to ensure
                                                compliance with our IT policies.
                                            </li>
                                            <li>
                                                Assurance of security of M&M’s facilities and assets, and
                                                M&M’s employees personal belongings via monitoring swipe card
                                                records (or biometric data readers) and surveillance cameras
                                                (CCTV)
                                            </li>
                                        </div>
                                    </div>
                                    <div className="PPolicymTop">
                                        <strong>3. Legal basis for our processing of your data</strong>
                                        <div className="PPolicyPl">
                                            <div>
                                                We process the categories of your personal data listed above
                                                either on the basis of
                                            </div>
                                            <li>
                                                the performance of the contract we have concluded with you or
                                                the necessity to take steps at your request prior to entering
                                                into such an agreement, insofar as it is necessary, or
                                            </li>
                                            <li>
                                                our overriding legitimate interests and to efficiently manage
                                                our human resources as well as our internal and external
                                                communication, or
                                            </li>
                                            <li>
                                                the necessity to comply with legal obligations to which we are
                                                subject.
                                            </li>
                                        </div>
                                    </div>
                                    <div className="PPolicymTop">
                                        <strong>4. How your data is collected</strong>
                                        <div className="PPolicyPl">
                                            <div>
                                                We collect personal information about you from you directly
                                                during contracting employment relationship. We collect
                                                personal information about you also through the application
                                                and recruitment process either directly from you, or from a
                                                recruitment service providers or consultants if they were
                                                involved.
                                            </div>
                                            <div className="PPolicymTop">
                                                We sometimes collect additional information from third parties
                                                including former employers, authorities or other information
                                                providers. We will collect additional personal information in
                                                the course of job-related activities throughout the period of
                                                you working for us.
                                            </div>
                                            <div className="PPolicymTop">
                                                You are under no obligation to provide us with the data we ask
                                                you for. However, if you do not provide your personal data,
                                                certain HR processes as well as processes of internal and
                                                external communication might be delayed or even impossible.
                                                Should the provision of your personal data be mandatory by
                                                law, we will inform you separately thereof.
                                            </div>
                                        </div>
                                    </div>
                                    <div className="PPolicymTop">
                                        <strong>5. Transfer of your data</strong>
                                        <div className="PPolicyPl">
                                            <div>
                                                To achieve the purposes set out above, we will transfer your
                                                personal data to the following categories of recipients:
                                            </div>
                                            <li>IT service providers that we use;</li>
                                            <li>companies that are part of our corporate group;</li>
                                            <li>social insurance agencies;</li>
                                            <li>
                                                public authorities (tax authority, employment service, etc.);
                                            </li>
                                            <li>electoral committee for works council elections;</li>
                                            <li>insurance companies;</li>
                                            <li>banks handling your payments;</li>
                                            <li>legal representation of interests;</li>
                                            <li>works council fund;</li>
                                            <li>works doctors</li>
                                            <li>pension funds;</li>
                                            <li>court of auditors;</li>
                                            <li>legal representatives;</li>
                                            <li>courts and authorities;</li>
                                            <li>co-insured persons;</li>
                                            <li>employee pension funds; and</li>
                                            <li>
                                                our clients and prospective clients (exclusively contact data
                                                such as your email).
                                            </li>
                                            <div>
                                                Some of the recipients mentioned above maybe located in or
                                                process personal data outside of your country. The level of
                                                data protection in another country may not be equivalent to
                                                the standards required by many legislations like the EU GDPR
                                                and the proposed Indian Personal Data Protection law in the
                                                making. However, we only transfer your personal data to
                                                countries where they have an adequate level of data protection
                                                or we take measures to ensure that all recipients provide an
                                                adequate level of data protection. We do this for example by
                                                entering into appropriate data transfer agreements based on
                                                Standard Contractual Clauses ((EU) 2016/679 and/or (EU)
                                                2021/914 or (EU) 2021/915). Such agreements are accessible
                                                upon request which may be directed at the contacts listed at
                                                the very end of this privacy notice.
                                            </div>
                                        </div>
                                    </div>
                                    <div className="PPolicymTop">
                                        <strong>6. Retention period</strong>
                                        <div className="PPolicyPl">
                                            <div>
                                                We will retain your personal data for the duration of your
                                                employment and thereafter for as long as there are statutory
                                                retention obligations or potential legal claims, where
                                                personal data is needed to raise or defend against the claim,
                                                are not yet time-barred.
                                            </div>
                                        </div>
                                    </div>
                                    <div className="PPolicymTop">
                                        <strong>7. Your rights regarding your data</strong>
                                        <div className="PPolicyPl">
                                            <div>
                                                Under applicable law, you have, among others, the rights
                                                (under the conditions set out in applicable law):
                                            </div>
                                            <li>
                                                obtain confirmation as to whether and what kind of personal
                                                data we store about you and to request copies of such data,
                                            </li>
                                            <li>request rectification or erasure of your personal data,</li>
                                            <li>
                                                request us to restrict the processing of your personal data,
                                            </li>
                                            <li>object to the processing of your personal data,</li>
                                            <li>request data portability and</li>
                                            <li>lodge a complaint with the competent authority.</li>
                                        </div>
                                    </div>
                                    <div className="PPolicymTop">
                                        <strong>8. Changes to this privacy notice</strong>
                                        <div className="PPolicyPl">
                                            <div>
                                                M&M may update this privacy notice from time to time. We
                                                encourage you to check our website frequently to see the
                                                current privacy notice so that you may stay informed of how
                                                M&M is using and protecting your information. Whenever a
                                                change to this notice is significant, we will place a
                                                prominent notice on this website and provide an updated
                                                effective date.
                                            </div>
                                        </div>
                                    </div>
                                    <div className="PPolicymTop">
                                        <strong>9. Contact Information</strong>
                                        <div className="PPolicyPl">
                                            <div>
                                                If you have questions or comments regarding this privacy
                                                notice, please contact us at:
                                            </div>
                                            <div className="PPolicymTop">Group Technology – IT Governance & IT Security</div>
                                            <div>1800 270 5544</div>
                                            <div>securitymanagement@mahindra.com</div>
                                        </div>
                                        <br />
                                        <br />
                                        <strong>Effective Date of this privacy notice:</strong>
                                    </div>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Paper>
                </Template>
            </>
        </section>
    );
}
