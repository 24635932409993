import React, { useEffect } from "react";
import "./PreviewOCArticleComponent.css";
import {
  Grid,
  Radio,
  FormControlLabel,
  RadioGroup,
  Checkbox,
} from "@material-ui/core";
import CommonLabel from "../../../Common/ComonLabel/CommonLabel";
import DatePicker from "../../../Common/DatePicker/DatePicker";
import { useFormik } from "formik";
import moment from "moment";
import {
  publisArticleSchema,
  publishStorySchema,
} from "../../../../Assets/formikErrors/SubmitOfferCampaignSchema";
import { checkPublishDatePassed } from "../../../../Assets/helperFunctions/helperFunctions";

export default function PreviewOCArticleComponent({
  title,
  publishOfferAndCampaigns,
  existingPublishDate,
  // existingExpiryDate,
}) {
  // const [publishOrSchedule, setpublishOrSchedule] = React.useState("colleague");
  // const handleRadioChange = (e) => {
  //   setpublishOrSchedule(e.target.value);
  // };
  // const [selectedDate, setSelectedDate] = React.useState({
  //   expiryDate: null,
  // });
  // const handleDateChange = (name, date) => {
  //   if (name === "expiryDate") {
  //     setSelectedDate({ ...selectedDate, expiryDate: date });
  //   }
  // };
  let isPublishDateOlder = checkPublishDatePassed(existingPublishDate);

  const formik = useFormik({
    initialValues: {
      publishDate: null,
      publishOrSchedule: isPublishDateOlder ? "schedule" : "publish",
      iswhatsnew: false,
      expiryDate: null,
      // expiryDate: existingExpiryDate
      //   ? existingExpiryDate
      //   : moment(new Date()).format("YYYY-MM-DD"),
    },
    enableReinitialize: true,
    validationSchema: publishStorySchema,
    // onSubmit: (values, { resetForm }) => handleSubmit(values, resetForm),
  });

  useEffect(() => {
    publishOfferAndCampaigns(formik.values, formik.errors);
  }, [formik]);

  const handleRadioGroup = (e, name) => {
    if (name === "publish") {
      formik.setFieldValue("publishDate", null);
      formik.setFieldValue("expiryDate", null);
    }
    formik.handleChange(e);
  };

  const handleWhatsNew = (e) => {
    if (!formik.values.iswhatsnew) {
      formik.setFieldValue("expiryDate", null);
    }
    formik.setFieldValue("iswhatsnew", !iswhatsnew);
  };

  const { publishOrSchedule, publishDate, iswhatsnew, expiryDate } =
    formik.values;
  return (
    <div>
      <div className="Modalcontent">
        <div className="publishArticleTitle">
          The story <b>`{title}`</b> will be published on Offer Campaign
        </div>
        <div className="publishContentInputs">
          <Grid container>
            <Grid item md={12} xs={12}>
              <RadioGroup
                aria-label="I am the author"
                name="publishOrSchedule"
                value={publishOrSchedule}
                onChange={(e, publishOrSchedul) =>
                  handleRadioGroup(e, publishOrSchedul)
                }
                disabled={isPublishDateOlder}
              >
                <Grid container spacing={5}>
                  <Grid item md={4} xs={12}>
                    <div
                      className={`${
                        publishOrSchedule === "publish" ? "radioBackground" : ""
                      } authorRadioButton`}
                    >
                      <FormControlLabel
                        value="publish"
                        className="radioButtonForm"
                        control={<Radio />}
                        label={
                          <span className="formControlLabelStyle commonFontNormalStyle">
                            Publish now
                          </span>
                        }
                        labelPlacement="end"
                        disabled={isPublishDateOlder}
                      />
                    </div>
                  </Grid>
                  <Grid item md={4} xs={12}>
                    <div
                      className={`${
                        publishOrSchedule === "schedule"
                          ? "radioBackground"
                          : ""
                      } authorRadioButton`}
                    >
                      <FormControlLabel
                        value="schedule"
                        className="radioButtonForm"
                        control={<Radio />}
                        label={
                          <span className="formControlLabelStyle commonFontNormalStyle">
                            Schedule to publish
                          </span>
                        }
                        labelPlacement="end"
                      />
                    </div>
                  </Grid>
                  <Grid item md={4} xs={12}>
                    <DatePicker
                      disabled={publishOrSchedule === "publish" ? true : false}
                      minDate={new Date().setDate(new Date().getDate() + 1)}
                      label={<CommonLabel label="Publish date" />}
                      selectedDate={publishDate}
                      handleDateChange={(date) => {
                        formik.setFieldValue(
                          "publishDate",
                          moment(date.toDate()).format("YYYY-MM-DD")
                        );
                      }}
                      name="publishDate"
                      errorMsg={
                        formik.errors.publishDate
                          ? formik.errors.publishDate
                          : ""
                      }
                    />
                  </Grid>
                </Grid>
              </RadioGroup>
            </Grid>
          </Grid>
          <Grid container className="articleM24">
            <Grid item md={12} xs={12}>
              <RadioGroup
                aria-label="I am the author"
                name="author"
                disabled={isPublishDateOlder}
              >
                <Grid container spacing={5}>
                  <Grid item md={5} xs={12}>
                    <div
                      className={`${
                        iswhatsnew ? "radioBackground" : ""
                      } authorRadioButton`}
                    >
                      <FormControlLabel
                        onChange={(e) => {
                          handleWhatsNew(e);
                        }}
                        value={iswhatsnew}
                        name="iswhatsnew"
                        className="radioButtonForm"
                        control={<Checkbox />}
                        label={
                          <span className="formControlLabelStyle commonFontNormalStyle">
                            Publish story to What’s new
                          </span>
                        }
                        labelPlacement="end"
                        disabled={isPublishDateOlder}
                      />
                    </div>
                  </Grid>
                  <Grid item md={5} xs={12}>
                    <DatePicker
                      disabled={
                        publishOrSchedule === "publish" && !iswhatsnew
                          ? true
                          : false
                      }
                      label={<CommonLabel label="Expiry date" />}
                      selectedDate={expiryDate}
                      handleDateChange={(date) => {
                        formik.setFieldValue(
                          "expiryDate",
                          moment(date.toDate()).format("YYYY-MM-DD")
                        );
                      }}
                      name="expiryDate"
                      errorMsg={
                        formik.errors.expiryDate ? formik.errors.expiryDate : ""
                      }
                    />
                  </Grid>
                </Grid>
              </RadioGroup>
            </Grid>
          </Grid>
        </div>
      </div>
    </div>
  );
}
