import {
    GET_WALLPAPER_REQUEST_SUCCESS,
    GET_WALLPAPER_REQUEST_LOAD,
    GET_WALLPAPER_REQUEST_FAILED,
} from "../Contants/Constants";

const initialState = {
  getData: [],
  isLoading: false,
  isError: "",
  status: "",
};

const getWallpaperRequestReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_WALLPAPER_REQUEST_LOAD:
      return {
        ...state,
        status: "loading",
        isLoading: true,
      };
    case GET_WALLPAPER_REQUEST_SUCCESS:
      return {
        ...state,
        getData: action.payload,
        status: "success",
        isLoading: false,
      };
    case GET_WALLPAPER_REQUEST_FAILED:
      return {
        ...state,
        status: "failed",
        isError: action.payload,
        isLoading: false,
      };
    default:
      return state;
  }
};

export default getWallpaperRequestReducer;
