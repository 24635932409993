import { Card, CardContent, Grid } from "@material-ui/core";
import React from "react";
import "./PoliciesCardComponent.css";
import moment from "moment";

export default function PoliciesCardComponent({
  date,
  title,
  description,
  id,
  policyTableData,
}) {
  return (
    <div>
      <Card className="PoliciesCard">
        <CardContent className="PoliciesCardContent">
          <div>
            <Grid
              container
              direction="row"
              justifyContent="space-between"
              alignItems="center"
            >
              <div className="PoliciesDate">{`Last Updated ${moment(
                date
              ).format("DD/MM/YYYY")}`}</div>
              {/* {policyTableData[0]?.subcategory == id &&
              policyTableData?.length > 0 ? (
                <img
                  src={process.env.PUBLIC_URL + "/policies/download-line.svg"}
                  style={{ cursor: "pointer" }}
                  alt="Download line"
                  onClick={() =>
                    window.open(
                      `${process.env.REACT_APP_API_BASE_URL}/ui-assist/v1/downloadpolicyzip/${id}`,
                      "_self"
                    )
                  }
                  //onClick={() => window.open(`http://newmrise-dev.m-devsecops.com/ui-assist/v1/downloadpolicyzip/619b81738b40ca4e9080fb54`, '_self')}
                  //onClick={() => {console.log(id, 'id')}}
                />
              ) : (
                ""
              )} */}
            </Grid>
          </div>
          <div className="policiesTitle">{title}</div>
          <div className="policiesDescription">{description}</div>
        </CardContent>
      </Card>
    </div>
  );
}
