import Swal from "sweetalert2";
import services from "../../Services/index";
import {
    PUBLISH_TO_WHATS_NEW_SUCCESS,
    PUBLISH_TO_WHATS_NEW_LOAD,
    PUBLISH_TO_WHATS_NEW_FAILED,
} from "../Contants/Constants";

const getPublishToWhatsNewLoad = () => ({
    type: PUBLISH_TO_WHATS_NEW_LOAD,
});
const getPublishToWhatsNewSuccess = (data) => ({
    type: PUBLISH_TO_WHATS_NEW_SUCCESS,
    payload: data,
});
const getPublishToWhatsNewFailed = (error) => ({
    type: PUBLISH_TO_WHATS_NEW_FAILED,
    err: error,
});

export const getPublishToWhatsNewData = (module,id,data) => async (dispatch) => {
  dispatch(getPublishToWhatsNewLoad());
  try {
    const res = await services.api(
      "POST",
      `/ui-assist/v1/common/approve/${module}/${id}`,data
    );
    if (res) {
      dispatch(getPublishToWhatsNewSuccess(res.data));
    }
  } catch (err) {
    dispatch(getPublishToWhatsNewFailed(err.response));
    Swal.fire("Something went wrong!", `${err?.response?.data}`, "error");
  }
};