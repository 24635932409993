import React, { useState, useEffect } from "react";
import "./AdminModalComponent.css";
import { Tabs, Tab, Typography, Box, Grid } from "@material-ui/core";
import PropTypes from "prop-types";
import TabCardComponent from "./AdminTabCard/TabCardComponent";
import SubmitButton from "../../../Common/Button/SubmitButton";
import addBoxLine from "./../../../../Assets/Images/WhatsNewIcons/add-box-fill.svg";
import { useHistory } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import {
  LIVEWIRE,
  MAHINDRA_EXPERIENCE_UPLOAD,
} from "./../../../../Routes/constant";
import { getLastModifiedData } from "../../../../Redux/Actions/CommonActions";
import { getFormattedDate } from "../../../../Assets/helperFunctions/helperFunctions";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}
export default function AdminModalComponent({
  isOfferCampaigns,
  filterdList,
  scheduleData,
  module = "",
}) {
  const [value, setValue] = React.useState(0);
  const history = useHistory();

  const dispatch = useDispatch();
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const lastModified = useSelector((state) => {
    if (isOfferCampaigns == true) {
      return state?.getLastModifiedDataReducer?.lastModifiedExperience;
    } else {
      return state?.getLastModifiedDataReducer?.lastModifiedExperience;
    }
  });

  useEffect(() => {
    dispatch(getLastModifiedData("mahindraexperience"));
  }, []);

  return (
    <div>
      <div className="lastmodifiedpanel mb-0">
        <Grid container spacing={2}>
          <Grid item sm={2} xs={12}>
            <div className="displayBlockUserDetails mlP alignMCAdmin">
              <p className="detailDivp">Last modified by</p>
              <p className="detailsFontSize">
                {lastModified?.name ? lastModified?.name : "Not Available"}
              </p>
            </div>
          </Grid>
          <Grid item sm={2} xs={12}>
            <div className="displayBlockUserDetails alignMCAdmin">
              <p className="detailDivp">Modified on</p>
              <p className="detailsFontSize">
                {lastModified?.date
                  ? getFormattedDate(lastModified?.date)
                  : "Not Available"}
              </p>
            </div>
          </Grid>
          <Grid item sm={8} xs={12}>
            <div className="uploadBtn">
              <SubmitButton
                label="Upload an article"
                className="MCsubmitbutton floatRightAlign"
                icon={addBoxLine}
                onClick={() => {
                  history.push(`${LIVEWIRE}${MAHINDRA_EXPERIENCE_UPLOAD}`);
                }}
              />
            </div>
          </Grid>
        </Grid>
      </div>

      <div className="MCAdminTabAlign">
        <div className="MCAdminTabs">
          <Grid
            container
            direction="row"
            justifyContent="space-between"
            alignItems="center"
          >
            <Tabs
              value={value}
              onChange={handleChange}
              aria-label="basic tabs example"
              className="MCalignTabContent"
            >
              <Tab
                label={`Pending approval (${
                  filterdList?.length ? filterdList?.length : 0
                })`}
              />
              <Tab
                label={`Scheduled (${
                  scheduleData?.length ? scheduleData?.length : 0
                })`}
              />
            </Tabs>
          </Grid>
        </div>
        <hr className="deviderP" />
        <TabPanel value={value} index={0} className="policiesTab">
          <Grid container direction="row">
            <TabCardComponent records={filterdList} />
          </Grid>
        </TabPanel>
        <TabPanel value={value} index={1} className="policiesTab">
          <Grid container direction="row">
            <TabCardComponent records={scheduleData} isSchedule={true} />
          </Grid>
        </TabPanel>
      </div>
    </div>
  );
}
