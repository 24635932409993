import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { Provider } from "react-redux";
import store from "../src/Redux/Store/index";
import { init as initApm } from "@elastic/apm-rum";

initApm({
  serviceName: "workportal-frontend",
  serverUrl: process.env.REACT_APP_APM_SERVER_URL,
  // Set the service version (required for source map feature)
  serviceVersion: "",
  environment: process.env.NODE_ENV,
  secretToken: process.env.REACT_APP_APM_SECRET_TOKEN,
});

ReactDOM.render(
  // <React.StrictMode>
  <Provider store={store}>
    <App />
  </Provider>,
  // </React.StrictMode>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
