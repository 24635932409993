import React, { useState, useEffect } from "react";
import "../MahindraCollective/MahindraCollectiveCard/MahindraCollectiveCard.css";
import {
  Card,
  CardContent,
  CardMedia,
  CardActions,
  // Icon,
  Paper,
  Popper,
  ClickAwayListener,
} from "@material-ui/core";
import { useHistory } from "react-router-dom";
import { Button } from "@material-ui/core";
import shareFillIcon from "../../../Assets/Images/share-fill.svg";
import mailBoxIcon from "../../../Assets/Images/collectiveIcons/mail-line.svg";
import teamsIcon from "../../../Assets/Images/collectiveIcons/teamsIcon.jpg";
import yammerIcon from "../../../Assets/Images/collectiveIcons/Yammer.jpg";

import "../../DashBoard/WhatsNew/WhatsNewComponent.css";
import IconButton from "@material-ui/core/IconButton";
import Avatar from "@material-ui/core/Avatar";
import heartIcon from "../../../Assets/Images/heart-3-line.svg";
import heartIconFill from "../../../Assets/Images/heartred-fill.svg";
import RemoteModal from "./RemoteVideoModal";
import moment from "moment";
import { RemoteAArticle, Support } from "../../../Routes/constant";
import { likeArticle } from "../../../Redux/Actions/CommonActions";
import { useDispatch, useSelector } from "react-redux";

const RemoteCard = ({ props, status, btnBool1, list = {}, footerIcon }) => {
  const {
    videoUri = "",
    detailsPage,
    title,
    expiryDate,
    thumbnail,
    subTitle,
    story,
    type,
    id,
    tags,
    likeBy,
    count,
  } = list;

  const dispatch = useDispatch();
  const history = useHistory();

  const [anchorEl, setAnchorEl] = useState(null);
  //For Like/ unlike feature
  const [isLiked, setIsLiked] = useState(false);
  const [likesCount, setLikesCount] = useState(count);

  let empId = localStorage.getItem("token");

  const currentStatus = useSelector((state) => {
    return state?.likeDataReducer;
  });

  const handleClickAway = () => {
    setAnchorEl(false);
  };

  //video modal
  const modal = () => {
    setOpenSettingssub(!openSettingssub);
  };
  const [openSettingssub, setOpenSettingssub] = useState(false);

  //
  const handleAnchorEl = (event, id) => {
    event.stopPropagation();
    event.preventDefault();
    setAnchorEl(anchorEl ? null : event.currentTarget);
    // setAnchorEl(event.currentTarget);
  };

  const renderDetailsPage = (detailPageLink) => {
    history.push({
      pathname: `${Support}/remotearticle/${id}`,
      state: { id: id },
    });
  };

  const handleLike = async () => {
    try {
      dispatch(likeArticle("RemoteWorking", id));

      if (!isLiked) {
        setLikesCount(likesCount + 1);
      } else {
        if (likesCount > 0) {
          setLikesCount(likesCount - 1);
        }
      }
      setIsLiked(!isLiked);
    } catch (error) {}
  };

  useEffect(() => {
    if (likeBy) {
      let isExistinLike = likeBy.find((obj) => {
        return obj == empId;
      });
      setIsLiked(isExistinLike ? isExistinLike : false);
    }
  }, []);

  return (
    <Card className="cardShadow">
      <CardMedia
        className="cursorPointer"
        style={{
          position: "relative",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <img src={thumbnail} alt="Card Logo" className="McCardImage" />
        {videoUri && (
          <img
            src={process.env.PUBLIC_URL + "/youtube.png"}
            alt="Card Logo"
            style={{ position: "absolute", width: "40%", cursor: "pointer" }}
            onClick={modal}
          />
        )}
      </CardMedia>
      <CardContent>
        <p
          className="McCardstoryription cursorPointer remoteCardtextHeader"
          onClick={() => renderDetailsPage(detailsPage)}
        >
          {title}
        </p>
        <p className="McCardstory cursorPointer remoteCardsubtextHeader">
          {subTitle}
        </p>
      </CardContent>

      <CardActions className="">
        <div className="cardActionContainer">
          <div className="avatarContainer">
            {footerIcon ? (
              <>
                <div className="avatarIcon">
                  <Avatar aria-label="recipe">
                    <img src={process.env.PUBLIC_URL + "/avatar.png"} alt="" />
                  </Avatar>
                </div>
                <div className="avatarDetails">
                  <div className="avatarName">{title}</div>
                  <div className="dateContainer">
                    {moment(expiryDate, "DD-MM-YYYY").format("DD/MM/YYYY")}
                  </div>
                </div>{" "}
              </>
            ) : (
              <>
                <Button variant="outlined" className="tipsBtn">
                  {videoUri ? "Tutorial" : "Tips"}
                </Button>
              </>
            )}
          </div>
          <div className="actionItemBtn">
            <IconButton aria-label="share">
              <img
                src={
                  anchorEl
                    ? shareFillIcon
                    : process.env.PUBLIC_URL + "/share_icon.png"
                }
                alt="Share Icon"
                className="shareIcon"
                onClick={(event) => handleAnchorEl(event)}
              />
            </IconButton>
            <Popper
              className="popperBlock"
              open={Boolean(anchorEl)}
              anchorEl={anchorEl}
            >
              <ClickAwayListener onClickAway={handleClickAway}>
                <Paper
                  style={{
                    width: "226px",
                    height: "144px",
                    position: "relative",
                    right: "auto",
                    top: "14px",
                    boxShadow: "none",
                  }}
                  className="papperClass"
                >
                  <div
                    // onClick={handleDelete}
                    className="shareMenuItems commonFontNormalStyle"
                  >
                    <img src={teamsIcon} alt="Email icon" />
                    <div>Microsoft Teams</div>
                  </div>
                  <div
                    // onClick={handleCloseMenu}
                    className="shareMenuItems commonFontNormalStyle"
                  >
                    <img src={yammerIcon} alt="Email icon" />
                    <div>Microsoft Yammer</div>
                  </div>
                  <div className="shareMenuItems commonFontNormalStyle">
                    <img src={mailBoxIcon} alt="Email icon" />
                    <div>Email address</div>
                  </div>
                </Paper>
              </ClickAwayListener>
            </Popper>
            <IconButton
              aria-label="add to favorites"
              onClick={handleLike}
              style={{ paddingRight: "5px", marginRight: "10px" }}
            >
              {isLiked ? (
                <img src={heartIconFill} alt="unlike" />
              ) : (
                <img src={heartIcon} alt="like" />
              )}
            </IconButton>
            <span style={{ paddingLeft: "5px" }} className="articleCount">
              {likesCount}
            </span>
          </div>
        </div>
      </CardActions>
      <RemoteModal
        open={openSettingssub}
        handleClose={() => setOpenSettingssub(false)}
        headerText="Video"
        modalSize="md"
        header={true}
        footer={true}
        fullWidth
        link={videoUri}
      />
    </Card>
  );
};

export default RemoteCard;
