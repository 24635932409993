import services from "../../Services/index";
import {
  GET_WELCOME_DATA_LOAD,
  GET_WELCOME_DATA_FAILED,
  GET_WELCOME_DATA_SUCCESS,
  GET_ALL_WELCOME_DATA_LOAD,
  GET_ALL_WELCOME_DATA_FAILED,
  GET_ALL_WELCOME_DATA_SUCCESS,
} from "../Contants/Constants";

const getWelcomeDataLoad = () => ({
  type: GET_WELCOME_DATA_LOAD,
});
const getWelcomeDataSuccess = (data) => ({
  type: GET_WELCOME_DATA_SUCCESS,
  payload: data,
});
const getWelcomeDataFailed = (error) => ({
  type: GET_WELCOME_DATA_FAILED,
  err: error,
});

//GET CURRENT BANNER DATA
export const getWelcomeData = () => async (dispatch) => {
  dispatch(getWelcomeDataLoad());
  try {
    const res = await services.api("GET", `/ui-assist/v1/banner`, {});
    if (res) {
      dispatch(getWelcomeDataSuccess(res.data));
    }
  } catch (err) {
    dispatch(getWelcomeDataFailed(err.response));
  }
};

const getAllWelcomeDataLoad = () => ({
  type: GET_ALL_WELCOME_DATA_LOAD,
});
const getAllWelcomeDataSuccess = (data) => ({
  type: GET_ALL_WELCOME_DATA_SUCCESS,
  payload: data,
});
const getAllWelcomeDataFailed = (error) => ({
  type: GET_ALL_WELCOME_DATA_FAILED,
  err: error,
});

//GET ALL BANNER DATA.
export const getAllWelcomeData = () => async (dispatch) => {
  dispatch(getAllWelcomeDataLoad());
  try {
    const res = await services.api("GET", `/ui-assist/v1/banners`, {});
    if (res) {
      dispatch(getAllWelcomeDataSuccess(res.data));
    }
  } catch (err) {
    dispatch(getAllWelcomeDataFailed(err.response));
  }
};
