import services from "../../Services/index";
import {
  GET_USERFEEDBACK_DATA_LOAD,
  GET_USERFEEDBACK_DATA_FAILED,
  GET_USERFEEDBACK_DATA_SUCCESS,
} from "../Contants/Constants";

const getUserfeedbackDataLoad = () => ({
  type: GET_USERFEEDBACK_DATA_LOAD,
});

const getUserfeedbackDataSuccess = (data) => ({
  // console.log('data.................', data)
  type: GET_USERFEEDBACK_DATA_SUCCESS,
  payload: data,
});

const getUserFeedbackFailed = (error) => ({
  type: GET_USERFEEDBACK_DATA_FAILED,
  err: error,
});

// const getUrl = (userProfile) => {
//   if (userProfile.admin && userProfile.adminType === "Central Admin") {
//     return `/ui-assist/v1/feedback/`;
//   } else {
//     return `/ui-assist/v1/feedback/`;
//   }
// else {
//   return `/ui-assist/v1/feedback/employeeId/${localStorage.getItem("token")}`;
// }
// };

export const getUserfeedbackData = () => async (dispatch) => {
  dispatch(getUserfeedbackDataLoad());
  try {
    const res = await services.api("GET", "/ui-assist/v1/feedbacks", {});
   
    if (res) {
      dispatch(getUserfeedbackDataSuccess(res.data));
    }
  } catch (err) {
    dispatch(getUserFeedbackFailed(err.response));
  }
};

// `/ui-assist/v1/feedback/employeeId/00210030`,
