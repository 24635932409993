import React, { useState } from "react";
import Button from "@material-ui/core/Button";
import { useMediaQuery, useTheme } from "@material-ui/core";
import "./Downtimehome.css";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Drawer from "@material-ui/core/Drawer";
import Close from "../../../Assets/Images/cross.svg";
import cardox from "../../../Assets/Images/card.png";
import { Typography, TextareaAutosize, Box } from "@material-ui/core";
import {
  isAccesible,
  offsetCount,
} from "../../../Assets/helperFunctions/helperFunctions";
import { useDispatch } from "react-redux";
import moment from "moment";
import { useHistory } from "react-router-dom";
import { DASHBOARD } from "../../../Routes/constant";

function Downtimehome({ sapdowntime }) {
  const dispatch = useDispatch();
  const history = useHistory();
  const [isOpened, setIsOpened] = useState(true);

  function toggle() {
    setIsOpened((wasOpened) => !wasOpened);
  }
  const onManageSfDowntime = () => {
    history.push("/downtime");
  };

  const isGCOAFS =
    JSON.parse(localStorage.getItem("userProfile")).company === "1001" ||
    JSON.parse(localStorage.getItem("userProfile")).company === "1024" ||
    JSON.parse(localStorage.getItem("userProfile")).company === "1020" ||
    JSON.parse(localStorage.getItem("userProfile")).company === "1079" ||
    JSON.parse(localStorage.getItem("userProfile")).company === "1081" ||
    JSON.parse(localStorage.getItem("userProfile")).company === "9026";

  return (
    <>
      {isOpened && isGCOAFS && (
        <Card className="Fixedcard">
          <CardContent>
            <img
              style={{ marginRight: "16px", color: "red !important" }}
              src={Close}
              alt="star"
              className="closeIcon"
              onClick={toggle}
            />

            <Typography
              variant="subtitle2"
              className="feedback-Tasks animationFeedbacktext downtimemaintitle"
              component="h2"
            >
              <div className="gotItText animationFeedbacktext ">
                Portal Notices
                {/* {isAccesible() && (
              <div className="editIcon editdowntime" onClick={onManageSfDowntime}>
                <img
                  src={process.env.PUBLIC_URL + "/iconImages/MC_admin.svg"}
                  alt=""
                  className="edit-line"
                />
                <div>Manage {`(${sapdowntime && sapdowntime.length})`}</div>
              </div>
            )} */}
              </div>
            </Typography>

            {sapdowntime !== null &&
              sapdowntime.length > 0 &&
              sapdowntime?.map((d) => (
                <div className="imgbox" key={d?._id}>
                  <h6 className="Downtimetitle">{d?.title}</h6>
                  <div className="dowtimedate">
                    Date - {moment(d?.fromdate).format("Do MMMM YYYY")} to{" "}
                    {moment(d?.todate).format("Do MMMM YYYY")}
                  </div>
                  <p>
                    {d?.message !== "null" && d?.message !== null ? (
                      <div
                        dangerouslySetInnerHTML={{
                          __html: d?.message,
                        }}
                      ></div>
                    ) : (
                      ""
                    )}
                  </p>
                  {/* Success Factor services will be down From {moment(d?.fromdate).format('Do MMMM')} 
                     <span>Morning {moment(d?.fromdate).format('h:mm A')} to {moment(d?.todate).format('Do MMMM')} Evening {moment(d?.todate).format('h:mm A')}.</span> */}
                </div>
              ))}

            {/* <div className="imgbox">
              <img src={cardox} />
            </div> */}
          </CardContent>
        </Card>
      )}
    </>
  );
}

export default Downtimehome;
