import Swal from "sweetalert2";
import services from "../../Services/index";
import {
    UPLOAD_IMAGE_SUCCESS,
    UPLOAD_IMAGE_LOAD,
    UPLOAD_IMAGE_FAILED,
} from "../Contants/Constants";

const getUploadImageLoad = () => ({
    type: UPLOAD_IMAGE_LOAD,
});
const getUploadImageSuccess = (data) => ({
    type: UPLOAD_IMAGE_SUCCESS,
    payload: data,
});
const getUploadImageFailed = (error) => ({
    type: UPLOAD_IMAGE_FAILED,
    err: error,
});

export const getUploadImageData = (data) => async (dispatch) => {
  dispatch(getUploadImageLoad());
  try {
    const res = await services.api(
      "POST",
      `/ui-assist/v1/upload/image`,data
    );
    if (res) {
      dispatch(getUploadImageSuccess(res.data));
    }
  } catch (err) {
    dispatch(getUploadImageFailed(err.response));
    Swal.fire("Something went wrong!", `${err?.response?.data}`, "error");
  }
};