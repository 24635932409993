import massWeight from "../../../Assets/Images/massWeight.svg";
import Mic from "../../../Assets/Images/Mic.svg";
import remoteHappy from "../../../Assets/Images/remoteHappy.svg";
import remoteGenie from "../../../Assets/Images/remoteGenie.svg";
import remoteNurse from "../../../Assets/Images/remoteNurse.svg";
import remoteDilsay from "../../../Assets/Images/remoteDilsay.svg";
import ITbulb from "../../../Assets/Images/ITbulb.svg";
import IT24 from "../../../Assets/Images/IT24.svg";
import ITcomputer from "../../../Assets/Images/ITcomputer.svg";
import InfraMusic from "../../../Assets/Images/InfraMusic.svg";
import InfraHotel from "../../../Assets/Images/InfraHotel.svg";
import InfraCar from "../../../Assets/Images/ITcomputer.svg";
import InfraAid from "../../../Assets/Images/InfraAid.svg";
import InfraSecurity from "../../../Assets/Images/InfraSecurity.svg";
import InfraCup from "../../../Assets/Images/InfraCup.svg";
import InfraCalender from "../../../Assets/Images/InfraCalender.svg";
import InfraPencil from "../../../Assets/Images/InfraPencil.svg";
import InfraPulse from "../../../Assets/Images/InfraPulse.svg";
import InfraLuggage from "../../../Assets/Images/InfraLuggage.svg";
import InfraBus from "../../../Assets/Images/InfraBus.svg";
import InfraCoupon from "../../../Assets/Images/InfraCoupon.svg";

export const cardDataITsupport = [
  {
    title: "MSOLVE",
    description:
      "Email and Microsoft Team based Chat services to resolve employee technical support issues and queries",
    footer: "Visit here",
    email: "msolve5544@mahindra.com",
    icon: ITbulb,
  },
  {
    title: "5544",
    description:
      "Telephonic service to resolve employee technical support issues and queries",
    number: "1800 270 5544",
    icon: IT24,
    IT: "IT",
  },
  {
    title: "IT support",
    description:
      "Technology support website that enables you to raise tickets w.r.t. Incidences and Requests, track the progress and rate the services.",
    footer: "Visit here",
    icon: ITcomputer,
  },
  //   {
  //     title: "IT support",
  //     description:
  //       "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum is industry's standard. Lorem Ipsum is simply dummy info.",
  //     footer: "CHAT WITH GENIE",
  //     icon: remoteGenie,
  //   },
];

export const cardDataLegal = [
  {
    title: "MLexoasis",
    footer: "VISIT HERE",
    icon: massWeight,
  },
];

export const cardDataEthics = [
  {
    title: "Speak up",
    description:
      "Speak-Up Ethics Helpline is a third party anonymous Ethics Helpline, where any type of code of conduct violation complaint can be registered, that you observe during your day to day business transactions at Mahindra.",
    footer: "ethics.mahindra.com",
    img: "../../../Assets/Images/Mic.svg",
    icon: Mic,
    link: "https://ethics.mahindra.com",
  },
];

export const cardDataRemote = [
  {
    title: "mHappy",
    description:
      "Free counselling for you and your family available in 9 languages including Hindi, Tamil, Telegu, Kannada, Malyalam, Bengali, Gujrati, Marathi. ",
    domestic: "Domestic :",
    domesticsno: "1800 102 7293 / 1800 209 8424",
    International: "International :",
    Internationalno: "+91 80 6539 3188 / 80 6539 3189",
    emai: "askanexpert@optum.in",
    website: "www. livewell.optum.com",
    footer: "START ONLINE CHAT",
    remote: "remote",
    icon: remoteHappy,
  },
  {
    title: "Genie HR support",
    description:
      "Genie is your personal assistant for HR policies, employee services and MSOLVE related queries. Incase of any issue, write to ",
    email: "genie. support@mahindra.com",
    ChatGenie: "CHAT WITH GENIE",
    footer: "Genie manual",
    remote: "remote",
    icon: remoteGenie,
  },
  {
    title: "List of doctors",
    description:
      "For any medical related concerns and queries, kindly contact the doctors on the list.",
    footer: "View List",
    remote: "remote",
    icon: remoteNurse,
  },
  ,
  {
    tite: "Dilsay",
    description: "For Dilsay(yoga, pilates, etc), please write to:",
    email: "SALDANHA.LEEANN @mahindra.com",
    remote: "remote",
    icon: remoteDilsay,
  },
];

export const cardDataInfra = [
  {
    title: "Genie",
    description:
      " Employee support chat bot to resolve your everyday technical support issues and queries",
    email: "genie. support@mahindra.com",
    ChatGenie: "CHAT WITH GENIE",
    footer: "Chat with Genie",
    link: "https://teams.microsoft.com/l/entity/28:2de9105a-a7ee-48d2-98f3-c182de7116b4/bot?conversationType=chat",
    icon: remoteGenie,
    id: "infra",
  },
  {
    title: "CIS MISSION",
    description:
      "To achieve Customer Delight in infrastructure and services through the application of the Rise philosophy. The services provided should be Timely, Transparent and Equitable. While doing so we will implement due processes, ensure commercial integrity and contemporary standards.To provide proactive security to safeguard personnel, material and information of the Group Corporate Office (GCO). To create security awareness within the group and conduct investigations on directions of senior management.",
    email: "genie. support@mahindra.com",
    ChatGenie: "CHAT WITH GENIE",
    footer: "Genie manual",
    icon: InfraMusic,
    id: "infra",
  },
  {
    title: "Projects & technical facilities management",
    description:
      "CIS manages building new spaces & renovations along with maintainence of existing facilities. For any further assistance contact:",
    footer: "View List",
    icon: InfraHotel,
    name1: "Ajit Godekar : ",
    name1Value: "9004074330",
    name2: "Sandesh Parab :  ",
    name2Value: "9867743069",
    id: "infra",
  },
  {
    title: "CTC cars",
    description:
      "CTC supports selection and procurements of CTC cars to eligible employees as per HR policy. CIS facilitiates maintainence and repair of company maintained CTC cars. For any further assistance contact:",
    email: "SALDANHA.LEEANN @mahindra.com",
    icon: InfraCar,
    name1: "Sebastic Dsouza : ",
    name1Value: "9820366601",
    name2: "Ghanashyam Pandit : ",
    name2Value: "8451888078",
    id: "infra",
  },
  {
    title: "Mitra",
    description:
      "Mahindra provides services in the time of emergencies within Greater Mumbai. For any assistance please contact Mahindra Towers Worli",
    email: "SALDANHA.LEEANN @mahindra.com",
    icon: InfraAid,
    id: "infra",
    name2Value: "9867734447",
    name3Value: "9867734447",
    name4Value: "9867734447",
    footer: "Register for such services",
  },
  {
    title: "Group security",
    description:
      "For any security concerns at CIS managed locations, please contact 24X7 control room",
    domestic: "Domestic :",
    domesticsno: "1800 102 7293 / 1800 209 8424",
    International: "International :",
    Internationalno: "+91 80 6539 3188 / 80 6539 3189",
    emai: "askanexpert@optum.in",
    website: "www. livewell.optum.com",
    icon: InfraSecurity,
    id: "infra",
    name2Value: "9930600100",
    name3Value: "9867600100",
    name4Value: "022-4916868",
    footer2: "More details",
    footer3: "Visitor management system",
    footer: "Parking management system",
  },
  {
    title: "Cafeteria services",
    description:
      "Mahindra Towers Worli has a cafeteria which offers breakfast, lunch nad snacks to employees on payment. Escalation point:",
    email: "genie. support@mahindra.com",
    ChatGenie: "CHAT WITH GENIE",
    footer: "Order a takeaway online",
    icon: InfraCup,
    name1: "Renu Sharma : ",
    name1Value: "9004386978",
    id: "infra",
  },
  {
    title: "Conference room booking",
    description:
      "Well-equipped conference rooms are available at all CIS managed locations.",
    footer: "View List",
    icon: InfraCalender,
    name1: "Prabhakaran B. :",
    name1Value: "8451888078",
    id: "infra",
    footer: "Check availability",
  },
  ,
  {
    title: "Stationery",
    description:
      "CIS procures stationery for various GCO departments . For any queries/further assistance, contact:",
    email: "SALDANHA.LEEANN @mahindra.com",
    icon: InfraPencil,
    name1: "Neetishree Sinha:",
    name1Value: "9820366601",
    name2: "Prabhakaran B.:",
    name2Value: "8451888078",
    id: "infra",
    footer: "Visit here",
  },
  {
    title: "Health",
    description:
      "Health services provided in Mahindra Towers, Worli include a well-equipped gymnasium and multidisciplinary inhouse doctors. For any assistance contact:",
    domestic: "Domestic :",
    domesticsno: "1800 102 7293 / 1800 209 8424",
    International: "International :",
    Internationalno: "+91 80 6539 3188 / 80 6539 3189",
    emai: "askanexpert@optum.in",
    website: "www. livewell.optum.com",
    footer: "Book a consultation",
    icon: InfraPulse,
    name1: "Mr. Afzal Khan",
    name1Value: "9820366601",
    id: "infra",
  },
  {
    title: "Travel",
    description:
      "CIS has a travel desk to help you manage all your travel requirements. ",
    email: "genie. support@mahindra.com",
    ChatGenie: "CHAT WITH GENIE",
    footer: "Book air tickets",
    icon: InfraLuggage,
    name1: "Travel requirements : ",
    name1Value: "022-491667706",
    name2: "Mr. Afzal Khan :",
    name2Value: "9867734447",
    id: "infra",
  },
  {
    title: "Transit quarters booking",
    description:
      "CIS procures stationery for various GCO departments . For any queries/further assistance, contact:",
    footer: "Visit here",
    icon: InfraBus,
    name1: "Neetishree Sinha",
    name1Value: "9820366601",
    name2: "Prabhakaran B.",
    name2Value: "9820366601",
    id: "infra",
  },
  ,
  {
    title: "Ask a car",
    description:
      "Employees can book a car for official purpose adhering to Group HR Policy. For any queries, please reach out to:",
    email: "SALDANHA.LEEANN @mahindra.com",
    icon: InfraCar,
    name1: "Neetishree Sinha:",
    name1Value: "9820366601",
    name2: "Prabhakaran B.:",
    name2Value: "8451888078",
    id: "infra",
    footer: "Visit here",
  },
  {
    title: "Sodexo",
    description:
      "Employees can opt for sodexo meal digital vouchers as component of their flexi pay in accordance to the HR policy. FOr any queries or further assistance contact:",
    domestic: "Domestic :",
    domesticsno: "1800 102 7293 / 1800 209 8424",
    International: "International :",
    Internationalno: "+91 80 6539 3188 / 80 6539 3189",
    emai: "askanexpert@optum.in",
    website: "www. livewell.optum.com",
    icon: InfraCoupon,
    name1: "Shabnam Mago",
    name1Value: "9820366601",
    name2: "Prabhakaran B.",
    name2Value: "9820366601",
    id: "infra",
  },
];
