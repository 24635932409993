import * as Yup from "yup";
import moment from "moment";
import { nameRegex } from "../helperFunctions/helperFunctions";
import { formLabelClasses } from "@mui/material";

const phoneRegExp = /^[6-9]\d{9}$/;
const commaSeparatedRegex = /^\w+(,\w+)*$/;
const FileSize = 5243000;

export const SubmitOfferCampaignSchema = Yup.object().shape({
  radioValues: Yup.string(),
  title: Yup.string()
    .min(3, "Too Short!")
    .max(100, "Too Long!")
    .required("This field is required"),
  description: Yup.string()
    .required("This field is required")
    .min(3, "Too Short!")
    .max(100, "Too Long!"),
  url: Yup.string(),
  // distributes: Yup.string()
  //   .min(3, "Too Short!")
  //   .max(50, "Too Long!")
  //   .matches(
  //     commaSeparatedRegex,
  //     "Distributes to should be single comma separated ex test1,test2"
  //   )
  //   .required("This field is required"),
  sector: Yup.array()
    .of(
      Yup.object().shape({
        title: Yup.string(),
        value: Yup.string(),
      })
    )
    .required("This field is required"),
  keywords: Yup.string()
    .min(3, "Too Short!")
    .max(50, "Too Long!")
    .matches(
      commaSeparatedRegex,
      "Keywords should be single comma separated ex test1,test2"
    )
    .required("This field is required"),
  name: Yup.string()
    .matches(nameRegex, "Name is not valid")
    .when("radioValues", {
      is: "colleague",
      then: Yup.string().required("Name is mandatory"),
    }),
  email: Yup.string()
    .email()
    .when("radioValues", {
      is: "colleague",
      then: Yup.string().required("Email is mandatory"),
    }),
  phoneNumber: Yup.string()
    .min(10, "to short")
    .max(10, "to long")
    .matches(phoneRegExp, "Phone number is not valid")
    .required("This field is required"),
  selectFileType: Yup.object().required("Select file type value is required."),
  publishDate: Yup.date()
    .nullable()
    .transform((curr, orig) => (orig === "" ? null : curr))
    // .min(moment(new Date()).format("YYYY-MM-DD"))
    .required("Publish date is required"),
  expiryDate: Yup.date()
    .nullable()
    .transform((curr, orig) => (orig === "" ? null : curr))
    .min(
      Yup.ref("publishDate"),
      "Expiry date should be same or greater than publish date"
    ),
  thumbnail: Yup.mixed(),

  pdf: Yup.mixed(),
  // .when("selectFileType.value", {
  //   is: "document",
  //   then: Yup.mixed().test(
  //     "fileSize",
  //     "File Size should be less than 5 MB",
  //     (value) => value?.size <= FileSize
  //   ),
  // }),
});

export const uploadStorySchema = Yup.object().shape({
  title: Yup.string()
    .min(3, "Too Short!")
    .max(100, "Too Long!")
    .required("This field is required"),
  subTitle: Yup.string()
    .required("This field is required")
    .min(3, "Too Short!")
    .max(100, "Too Long!"),
  hasTags: Yup.string()
    .matches(
      commaSeparatedRegex,
      "Hashtags  should be single comma separated ex test1,test2"
    )
    .required("This field is required"),
  thumbnail: Yup.mixed(),
  video: Yup.mixed(),
  docs: Yup.mixed(),
  // .test(
  //   "fileSize",
  //   "File Size should be less than 5 MB",
  //   (value) => value?.size <= FileSize
  // ),
  videoUri: Yup.string(),
  videoThumbnail: Yup.mixed(),
  // .test(
  //   "fileSize",
  //   "File Size is too large, should be less than 2 mb",
  //   (value) => value.size <= fileSize
  // )
  // .required("A file is required"),
  selectFileType: Yup.object().required("Select type value is required."),
  selectType: Yup.object().required("Select type value is required."),
  // sector: Yup.object().required("Sector type value is required."),
  sector: Yup.array()
    .of(
      Yup.object().shape({
        title: Yup.string(),
        value: Yup.string(),
      })
    )
    .required("This field is required"),
  // division: Yup.object().required("Division type value is required."),
  division: Yup.array()
    .of(
      Yup.object().shape({
        title: Yup.string(),
        value: Yup.string(),
      })
    )
    .required("This field is required"),
  publishDate: Yup.date()
    .nullable()
    .transform((curr, orig) => (orig === "" ? null : curr))
    // .min(moment(new Date()).format("YYYY-MM-DD"))
    .required("Publish date is required"),
  name: Yup.string()
    .matches(nameRegex, "Name is not valid")
    .when("radioValues", {
      is: "colleague",
      then: Yup.string().required("Name is mandatory"),
    }),
  email: Yup.string()
    .email()
    .when("radioValues", {
      is: "colleague",
      then: Yup.string().required("Email is mandatory"),
    }),
  phoneNumber: Yup.string()
    .min(8, "to short")
    .max(10, "to long")
    .matches(phoneRegExp, "Phone number is not valid")
    .required("This field is required"),
});

export const editStorySchema = Yup.object().shape({
  title: Yup.string()
    .min(3, "Too Short!")
    .max(100, "Too Long!")
    .required("This field is required"),
  subTitle: Yup.string()
    .required("This field is required")
    .min(3, "Too Short!")
    .max(100, "Too Long!"),
  hasTags: Yup.string()
    .matches(
      commaSeparatedRegex,
      "Hashtags  should be single comma separated ex test1,test2"
    )
    .required("This field is required"),
  // thumbnail: Yup.mixed(),
  video: Yup.mixed(),
  docs: Yup.mixed(),
  // .test(
  //   "fileSize",
  //   "File Size should be less than 5 MB",
  //   (value) => value?.size <= FileSize
  // ),
  videoUri: Yup.string(),
  videoThumbnail: Yup.mixed(),
  // .test(
  //   "fileSize",
  //   "File Size is too large, should be less than 2 mb",
  //   (value) => value.size <= fileSize
  // )
  // .required("A file is required"),
  selectFileType: Yup.object().required("Select type value is required."),
  selectType: Yup.object().required("Select type value is required."),
  // sector: Yup.object().required("Sector type value is required."),
  sector: Yup.array()
    .of(
      Yup.object().shape({
        title: Yup.string(),
        value: Yup.string(),
      })
    )
    .required("This field is required"),
  // division: Yup.object().required("Division type value is required."),
  division: Yup.array()
    .of(
      Yup.object().shape({
        title: Yup.string(),
        value: Yup.string(),
      })
    )
    .required("This field is required"),
  publishDate: Yup.date()
    .nullable()
    .transform((curr, orig) => (orig === "" ? null : curr))
    // .min(moment(new Date()).format("YYYY-MM-DD"))
    .required("Publish date is required"),
  name: Yup.string()
    .matches(nameRegex, "Name is not valid")
    .when("radioValues", {
      is: "colleague",
      then: Yup.string().required("Name is mandatory"),
    }),
  email: Yup.string()
    .email()
    .when("radioValues", {
      is: "colleague",
      then: Yup.string().required("Email is mandatory"),
    }),
  phoneNumber: Yup.string()
    .min(8, "to short")
    .max(10, "to long")
    .matches(phoneRegExp, "Phone number is not valid")
    .required("This field is required"),
});

export const viewArticleSchema = Yup.object().shape({
  title: Yup.string()
    .min(3, "Too Short!")
    .max(100, "Too Long!")
    .required("This field is required"),
  subTitle: Yup.string().required("This field is required"),
  name: Yup.string().required("This field is required"),
  email: Yup.string().email().required("This field is required"),
  phoneNumber: Yup.string()
    .min(10, "to short")
    .max(10, "to long")
    .matches(phoneRegExp, "Phone number is not valid")
    .required("This field is required"),
  // videoUri: Yup.string().required("This field is required"),
  hasTags: Yup.string().matches(
    commaSeparatedRegex,
    "Hashtags  should be single comma separated ex test1,test2"
  ),
  thumbnail: Yup.mixed()
    // .test(
    //   "fileSize",
    //   "File Size is too large, should be less than 2 mb",
    //   (value) => value.size <= fileSize
    // )
    .required("A file is required"),
  storyType: Yup.object().required("Select type value is required."),
  sector: Yup.object().required("Sector type value is required."),
  businessOrDivisionName: Yup.object().required(
    "Division type value is required."
  ),
  publishDate: Yup.date()
    .nullable()
    .transform((curr, orig) => (orig === "" ? null : curr))
    .min(moment(new Date()).format("YYYY-MM-DD"))
    .required("Publish date is required"),
});

export const publishStorySchema = Yup.object().shape({
  iswhatsnew: Yup.boolean(),
  publishOrSchedule: Yup.string(),
  publishDate: Yup.date()
    .nullable()
    .transform((curr, orig) => (orig === "" ? null : curr))
    .min(moment(new Date()).format("YYYY-MM-DD"))
    .when("publishOrSchedule", {
      is: "schedule",
      then: Yup.date()
        .nullable()
        .transform((curr, orig) => (orig === "" ? null : curr))
        .min(moment(new Date()).format("YYYY-MM-DD"))
        .required("Publish date is required"),
      otherwise: Yup.date()
        .nullable()
        .transform((curr, orig) => (orig === "" ? null : curr))
        .min(moment(new Date()).format("YYYY-MM-DD")),
    }),
  // .when("iswhatsnew", {
  //   is: true,
  //   then: Yup.date()
  //     .nullable()
  //     .transform((curr, orig) => (orig === "" ? null : curr))
  //     .min(moment(new Date()).format("YYYY-MM-DD"))
  //     .required("Publish date is required"),
  //   otherwise: Yup.date()
  //     .nullable()
  //     .transform((curr, orig) => (orig === "" ? null : curr))
  //     .min(moment(new Date()).format("YYYY-MM-DD")),
  // }),
  // .required("Publish date is required"),
  expiryDate: Yup.date()
    .nullable()
    .transform((curr, orig) => (orig === "" ? null : curr))
    .min(
      Yup.ref("publishDate"),
      "Expiry date should be same or greater than publish date"
    )
    .when("iswhatsnew", {
      is: true,
      then: Yup.date()
        .nullable()
        .transform((curr, orig) => (orig === "" ? null : curr))
        .min(
          Yup.ref("publishDate"),
          "Expiry date should be same or greater than publish date"
        )
        .required("Expiary date is required"),
      otherwise: Yup.date()
        .nullable()
        .transform((curr, orig) => (orig === "" ? null : curr))
        .min(
          Yup.ref("publishDate"),
          "Expiry date should be same or greater than publish date"
        ),
    }),
  // .when("publishOrSchedule", {
  //   is: "schedule",
  //   then: Yup.date()
  //     .nullable()
  //     .transform((curr, orig) => (orig === "" ? null : curr))
  //     .min(moment(new Date()).format("YYYY-MM-DD"))
  //     .required("Expiry date is required"),
  //   otherwise: Yup.date()
  //     .nullable()
  //     .transform((curr, orig) => (orig === "" ? null : curr))
  //     .min(moment(new Date()).format("YYYY-MM-DD")),
  // }),
});

// export const publisArticleSchema = Yup.object().shape({
//   iswhatsnew: Yup.boolean(),
//   publishOrSchedule: Yup.string(),
//   publishDate: Yup.date()
//     .nullable()
//     .transform((curr, orig) => (orig === "" ? null : curr))
//     .min(moment(new Date()).format("YYYY-MM-DD"))
//     .when("publishOrSchedule", {
//       is: "schedule",
//       then: Yup.date()
//         .nullable()
//         .transform((curr, orig) => (orig === "" ? null : curr))
//         .min(moment(new Date()).format("YYYY-MM-DD"))
//         .required("Publish date is required"),
//       otherwise: Yup.date()
//         .nullable()
//         .transform((curr, orig) => (orig === "" ? null : curr))
//         .min(moment(new Date()).format("YYYY-MM-DD")),
//     }),
//   // .required("Publish date is required"),
//   expiryDate: Yup.date()
//     .nullable()
//     .transform((curr, orig) => (orig === "" ? null : curr))
//     .min(
//       Yup.ref("publishDate"),
//       "Expiry date should be same or greater than publish date"
//     )
//     .when("iswhatsnew", {
//       is: true,
//       then: Yup.date()
//         .nullable()
//         .transform((curr, orig) => (orig === "" ? null : curr))
//         .min(
//           Yup.ref("publishDate"),
//           "Expiry date should be same or greater than publish date"
//         )
//         .required("Expiary date is required"),
//       otherwise: Yup.date()
//         .nullable()
//         .transform((curr, orig) => (orig === "" ? null : curr))
//         .min(
//           Yup.ref("publishDate"),
//           "Expiry date should be same or greater than publish date"
//         ),
//     }),
// });

// export const editSupport = Yup.object().shape({
//   title: Yup.string().required("Title  is required"),
//   description: Yup.string(),
//   name: Yup.string(),
//   phoneNumber: Yup.number(),
//   extension: Yup.number(),
//   emailId: Yup.string().email(),
//   website: Yup.string(),
//   actionName: Yup.string(),
//   actionUrl: Yup.string(),
// });

export const uploadArticleSchema = Yup.object().shape({
  radioValues: Yup.string(),
  title: Yup.string()
    .min(3, "Too Short!")
    .max(100, "Too Long!")
    .required("This field is required"),
  subTitle: Yup.string().required("This field is required"),
  hasTags: Yup.string()
    .matches(
      commaSeparatedRegex,
      "Hashtags  should be single comma separated ex test1,test2"
    )
    .required("This field is required"),
  thumbnail: Yup.mixed(),
  video: Yup.mixed(),
  docs: Yup.mixed().test(
    "fileSize",
    "File Size should be less than 5 MB",
    (value) => value?.size <= FileSize
  ),
  videoUri: Yup.string(),
  videoThumbnail: Yup.mixed(),
  selectFileType: Yup.object().required("Select type value is required."),
  selectType: Yup.object().required("Select type value is required."),
});

export const orgAnnouncementSchema = Yup.object().shape({
  name: Yup.string().required("This field is required"),
  // orgKey: Yup.string().required("This field is required"),
  // publishDate: Yup.date().nullable().required("Publish date is required"),
  isFromEdit: Yup.boolean(),
  uploadOrgPdfTitle: Yup.string().when("isFromEdit", {
    is: true,
    then: Yup.string().required("This field is required"),
  }),
  uploadOrgPdf: Yup.string().when("isFromEdit", {
    is: false,
    then: Yup.string().required("This field is required"),
  }),
  // selectedSectors: Yup.array().required().min(1, "Atleast on required"),
});
