import React, { useState } from "react";
import "./Login.css";
import {
  Paper,
  TextField,
  Grid,
} from "@material-ui/core";
import AuthTopSection from "./AuthTopSection";
import AuthButton from "./AuthButton";
import axios from "axios";
function Login() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [message, setMessage] = useState("");
 const handleSubmit = async  (e) => {
    e.preventDefault();
  //sed it to server
  try {
    var data = new FormData();
    data.append("token", email);
    data.append("password", password);
   /* 
   let res = await fetch("https://newmrise-uatm.m-devsecops.com/ui-assist/login", {
      method: "POST",
      mode: 'no-cors',
      body: data
   });
    let resJson = await res.json();
*/
    const res = await axios({
      method : "POST",
      url: `${process.env.REACT_APP_API_BASE_URL}/ui-assist/login`,
      data: data
   });
 if (res.status === 200) {
      console.log('sucess')
      setEmail("");
      setPassword("");
      setMessage("Successful");
      window.location.href = `${process.env.REACT_APP_API_BASE_URL}`;
    } else {
      setMessage("Given login details is not correct");
    }
  } catch (err) {
    console.log(err);
    setMessage("Given login details is not correct");
  }


   
   // return false;

  };

  return (
    <div className="loginContainer justiFyCenter">
      <Paper elevation={3} className="paperStyle">
        <form noValidate autoComplete="off" onSubmit={handleSubmit}>
          <AuthTopSection />
          <Grid container style={{ padding: "0 48px 0 48px" }}>
            <Grid item xs={12}>
            <TextField
                variant="outlined"
                name="email"
                onChange={(e) => setEmail(e.target.value)}
                value={email}
                className="fullWidth"
                label="Username / empl​oyee Id"
              />
            </Grid>
            <Grid item xs={12} style={{ marginTop: "16px" }}>
            <TextField
                label="Password"
                variant="outlined"
                name="password"
                onChange={(e) => setPassword(e.target.value)}
                value={password}
                className="fullWidth"
                type="password"
              />
            </Grid>
            
            
            <Grid item xs={12} style={{ marginTop: "16px" }}>
              <AuthButton label="SIGN IN" />
            </Grid>
            <Grid item xs={12} style={{ marginTop: "16px" }}>
            <div className="message">{message ? <p>{message}</p> : null}</div>
            </Grid>
          </Grid>
        </form>
      </Paper>
    </div>
  );
}

export default Login;
