import React, { useState, useEffect } from "react";
import { Grid, useMediaQuery, useTheme, Paper } from "@material-ui/core";
import "./Footer.css";
import { Link } from "react-router-dom";
import { SITE_MAP, DISCLAIMER, PRIVACYPOLICY } from "../../Routes/constant";
import BottomNavigation from "@material-ui/core/BottomNavigation";
import BottomNavigationAction from "@material-ui/core/BottomNavigationAction";
import FolderIcon from "@material-ui/icons/Folder";
import RestoreIcon from "@material-ui/icons/Restore";
import FavoriteIcon from "@material-ui/icons/Favorite";
import LocationOnIcon from "@material-ui/icons/LocationOn";
import todayIconRed from "../../Assets/Images/footer-navbar/calendar-check-fill.svg";
import fevIconGray from "../../Assets/Images/footer-navbar/star-line.svg";
import genieIconGray from "../../Assets/Images/footer-navbar/message-2-line.svg";
import covidIconGray from "../../Assets/Images/footer-navbar/virus-line.svg";
import { useHistory } from "react-router-dom";
import { DASHBOARD } from "../../Routes/constant";
import services from "../../Services";
import CommonModal from "../Common/CommonModal/CommonModal";
import Swal from "sweetalert2";
import { useDispatch, useSelector } from "react-redux";
import { getFormattedDate } from "../../Assets/helperFunctions/helperFunctions";
import editIconRed from "../../Assets/Images/edit-line-red.svg";
import editIcon from "../../Assets/Images/edit-line.svg";

import {isAccesible} from '../../Assets/helperFunctions/helperFunctions'

export default function Footer() {
  const [openQuoteModal, setOpenQuoteModal] = useState(false);
  const [quoteOfDay, setQuoteOfDay] = useState("");
  const [charSize, setCharSize] = useState(140);
  const history = useHistory();
  const date = new Date().getFullYear();
  const Bp = useTheme();
  const isXs = useMediaQuery(Bp.breakpoints.down("xs"));
  const [quote, setQuote] = useState("");
  const [quotefilename, setQuotefilename] = useState("");
  const [selectedFile, setSelectedFile] = useState(null);
  const [isFilePicked, setIsFilePicked] = useState(false);
  const [isSelected, setIsSelected] = useState(false);
  const [modificationDate, setmodificationDate] = useState("");
  const [modifiedBy, setmodifiedBy] = useState("");
  const [refresh, setRefresh] = useState(false);
  //for getting Last modified by
  const lastModified = useSelector((state) => {
    return state?.getLastModifiedDataReducer?.lastModifiedWhatsNew;
  });
  const changeHandler = (event) => {
    setSelectedFile(event.target.files[0]);
    setIsSelected(true);
  };

  let baseurl = process.env.REACT_APP_API_BASE_URL;
  useEffect(() => {
    (async () => {
      try {
        const res = await services.api("GET", "/ui-assist/v1/quote");
        if (res.status === 201) {
          setQuote(res?.data?.messageText);
          setQuotefilename(res?.data?.fileName);
          setmodificationDate(res?.data?.modificationDate);
          setmodifiedBy(res?.data?.modifiedBy);
        }
      } catch (error) {
        console.log("error");
      }
    })();
  }, [refresh]);

  const handledownload = () => {
    window.open(
      `${baseurl}/ui-assist/v1/quote/download/${quotefilename}`,
      "_self"
    );
  };

  const handlequoteapi = async (data) => {
    try {
      //setLoading(true);

      const res = await services.api("POST", `/ui-assist/v1/quote`, data);

      if (res.status === 200) {
        // y(false);

        Swal.fire({
          position: "center",

          icon: "success",

          title: "quote  Added Successfully",

          showConfirmButton: false,

          timer: 1500,
        });

        setRefresh(!refresh);
      }
    } catch (err) {
      if (err.response) {
        // Swal.fire({
        //   icon: "error",

        //   title: "Oops...",

        //   text: "Something went wrong!",

        //   footer: '<a href="">Why do I have this issue?</a>',
        // });
      }
    }
  };

  const handleSubmission = () => {
    console.log("button clicked");
    const formData = new FormData();

    formData.append("documentFile", selectedFile);

    let data = formData;

    handlequoteapi(data);
  };

  const handleQuoteModal = () => {
    setOpenQuoteModal(true);
  };

  const handleCloseQuoteModal = () => {
    setOpenQuoteModal(false);
    setQuoteOfDay("");
    setCharSize(140);
  };

  const handleChange = (event) => {
    const { value } = event.target;
    setQuoteOfDay(value);
    setCharSize(140 - value.length);
  };
  //let footerQuote = "Either you run the day, or the day runs you.";
  const openQuotesImage = () => {
    return (
      <img
        src={process.env.PUBLIC_URL + "/footer/1.png"}
        alt="inverted quoma"
        className="firstQuoma"
      />
    );
  };
  const closeQuotesImage = () => {
    return (
      <img
        src={process.env.PUBLIC_URL + "/footer/2.png"}
        alt="inverted quoma"
        className="lastQuoma"
      />
    );
  };

  const covid = "https://mahindrarise.com/Spotlight-COVID-19/";
  const genie =
    "https://teams.microsoft.com/dl/launcher/launcher.html?url=%2F_%23%2Fl%2Fentity%2F28%3A2de9105a-a7ee-48d2-98f3-c182de7116b4%2Fbot%3FconversationType%3Dchat&type=entity&deeplinkId=f7284766-d1dd-4781-9dc7-25251edf3833&directDl=true&msLaunch=true&enableMobilePage=true&suppressPrompt=true";

  const [value, setValue] = React.useState(null);

  const setLink = () => {
    if (value === 0) {
      history.push(DASHBOARD);
    } else if (value === 1) {
      window.open(genie, "_blank");
    } else if (value === 2) {
      window.open(covid, "_blank");
    }
  };

  useEffect(() => {
    setLink();
  }, [value]);

  return (
    <footer className="footer">
      <Grid
        container
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        className="mL"
      >
        <Grid item xs={12} md={6} className="Grid1">
          <div className="firstDiv">
            {isXs ? (
              <div className="Qposition">
                <div className="footerFirstText">QUOTE OF THE DAY </div>
                <div
                  className="editIcon"
                  onClick={(event) => handleQuoteModal(event)}
                >
                  <img src={editIconRed} alt="" className="edit-line" />
                  <div 
                  
                  >EDIT</div>
                </div>
              </div>
            ) : (
              <div className="Qposition">
                <div className="footerFirstText">QUOTE OF THE DAY </div>
                {isAccesible("QUOTE_OF_THE_DAY") && (
                  <>
                    <div
                      className="editIcon"
                      onClick={(event) => handleQuoteModal(event)}
                    >
                      <img src={editIconRed} alt="" className="edit-line" />
                      <div>EDIT</div>
                    </div>
                  </>
                )}
              </div>
            )}
            {!isXs && (
              <>
                <div className="footerSecondText">
                  <div>{openQuotesImage()}</div>
                  <div className="footerMessage">{quote}</div>
                  <div>{closeQuotesImage()}</div>
                </div>
              </>
            )}
            {isXs && (
              <>
                <div className="footerSecondText">
                  <div>{openQuotesImage()}</div>
                  <div className="footerMessage">
                    {quote}
                    {closeQuotesImage()}
                  </div>
                </div>
              </>
            )}
          </div>
        </Grid>
        <Grid item xs={12} md={6} className="Grid2">
          <div className="secondDiv">
            <div className="flexContainer">
              <div className="termsAndConditions div2P1">
                {/* TERMS OF USE |{" "} */}
                <Link to={DISCLAIMER} className="footerLink">
                  DISCLAIMER
                </Link>{" "}
                <Link to={PRIVACYPOLICY} className="footerLink">
                  | PRIVACY POLICY |{" "}
                </Link>
                <Link to={SITE_MAP} className="footerLink">
                  SITEMAP
                </Link>
              </div>
              <div className="termsAndConditions pt15">
                Copyright 2015-{date} Mahindra & Mahindra Ltd. All rights
                reserved.
              </div>
            </div>
          </div>
        </Grid>
      </Grid>
      <Paper elevation={3} className="footerNavigation">
        <BottomNavigation
          showLabels
          value={value}
          onChange={(event, newValue) => {
            setValue(newValue);
          }}
        >
          <BottomNavigationAction
            label="Today"
            icon={<img src={todayIconRed} alt="today" />}
            name="today"
          />
          {/*<BottomNavigationAction
            label="Favorites"
            icon={<img src={fevIconGray} alt="" />}
          /> */}
          <BottomNavigationAction
            label="Genie"
            icon={<img src={genieIconGray} alt="" />}
            name="jenie"
          />
          <BottomNavigationAction
            label="Covid 19"
            icon={<img src={covidIconGray} alt="" />}
            name="covid19"
          />
          {/* <BottomNavigationAction label="Archive" icon={<ArchiveIcon />} /> */}
        </BottomNavigation>
      </Paper>
      <CommonModal
        open={openQuoteModal}
        handleClose={handleCloseQuoteModal}
        headerText="Edit quote of the day"
        // modalStyle="modalStyle"
        icon={editIcon}
        modalSize="lg"
        submitBtnText="SAVE CHANGES"
        onButtonClick={handleSubmission}
        className="quotepanel"
      >
        <div className="lastmodifiedpanel">
          <Grid container spacing={2}>
            <Grid item sm={2} xs={12}>
              <div className="displayBlockUserDetails mlP alignMCAdmin">
                <p className="detailDivp">Last modified by</p>
                <p className="detailsFontSize">
                  {modifiedBy}
                  {/* {lastModified?.name ? lastModified?.name : "Not Available"} */}
                </p>
              </div>
            </Grid>
            <Grid item sm={2} xs={12}>
              <div className="displayBlockUserDetails alignMCAdmin">
                <p className="detailDivp">Modified on</p>
                <p className="detailsFontSize">
                  {getFormattedDate(modificationDate)}
                  {/* {lastModified?.date
                    ? getFormattedDate(lastModified?.date)
                    : "Not Available"} */}
                </p>
              </div>
            </Grid>
            {/* <Grid item sm={8} xs={12}>
                <div className="uploadBtn">
                  <SubmitButton
                    label="Upload an article"
                    className="MCsubmitbutton floatRightAlign"

                  />
                </div>
              </Grid> */}
          </Grid>
        </div>

        <div className="textAreaDiv Modalcontent">
          <div style={{ width: "800px" }}>
            <div className="steppanel">
              <span className="Step-1">Step 1:</span>
              <span className="Download-csv">
                <div>Download .csv standard template for quotes</div>
                <div>
                  <button onClick={handledownload} className="Engage">
                    DOWNLOAD CSV TEMPLATE
                  </button>
                </div>
              </span>
            </div>
            <div className="steppanel">
              <span className="Step-1">Step 2:</span>
              <span className="Download-csv">
                <div>
                  Upload .csv template here. The first quote on the list will be
                  the quote of the day
                </div>
                <div>
                  <input type="file" name="file" onChange={changeHandler} />
                </div>
              </span>
            </div>

            <div className=""></div>

            {/* {isSelected ? (
				<div>
					<p>Filename: {selectedFile.name}</p>
					<p>Filetype: {selectedFile.type}</p>
					<p>Size in bytes: {selectedFile.size}</p>
					<p>
						lastModifiedDate:{' '}
						{selectedFile.lastModifiedDate.toLocaleDateString()}
					</p>
				</div>
			) : (
				<p>Select a file to show details</p>
			)} */}
            <div>
              {/* <button onClick={handleSubmission}>Submit</button> */}
            </div>
          </div>
          {/* <TextareaAutosize
            className="textArea"
            placeholder="Enter quote"
            value={quoteOfDay}
            onChange={handleChange}
            maxlength="140"
          /> */}
          {/* <span style={{ position: "absolute" }} className="charsLeftText">
            {charSize} characters left
          </span> */}
        </div>
        {/* <ModifiedSection /> */}
      </CommonModal>
    </footer>
  );
}
