import { Grid, Tab, Tabs, Typography } from "@material-ui/core";
import "./EmergencyContacts.css";
import React, { useState, useEffect } from "react";
import Template from "../../Common/Template/Template";
// import Masonry from "react-masonry-css";
import editIconRed from "../../../Assets/Images/edit-line-red.svg";
import editIcon from "../../../Assets/Images/edit-line.svg";


import EthicsWhatsNewCard from "./../Support/EthicsWhatsNewCard";
import hospitalIcon from "../../../Assets/Images/hospital-line.svg";
import { Box } from "@mui/system";
import PropTypes from "prop-types";
import CommonModal from "../../Common/CommonModal/CommonModal";
import ITSupportAdminComponent from "../Support/ITSupport/ITSupportAdminComponent/ITSupportAdminComponent";
import EmergencyContactAdmin from "./EmergencyContactAdmin/EmergencyContactAdmin";
import ITbulb from "../../../Assets/Images/ITbulb.svg";
import Masonry from '@mui/lab/Masonry';
import PageTutorialComponent from "../../Common/PageTutorial/PageTutorialComponent";
import { getAllEmergencyContactData } from "../../../Redux/Actions/AllEmergencyContactAction";
import { useDispatch, useSelector } from "react-redux";
import platform from "platform";
import { addAnalayticsLog, addInterval } from "../../../Redux/Actions/Anaylatics";
import { isAccesible } from "../../../Assets/helperFunctions/helperFunctions";





export default function EmergencyContacts() {
    const dispatch = useDispatch();
    const allContacts = useSelector((state) => state.getAllEmergencyContactReducer);
    let allLocations = useSelector((state) => state.getAllContactLocationReducer);

    const [open, setOpen] = useState(false);
    const [modContent, setModContent] = useState("");

    useEffect(() => {
        dispatch(getAllEmergencyContactData());
    },[allLocations?.getAllData, allContacts?.addData, allContacts?.editedData, allContacts?.deleteData]);

    const handleClickOpen = (ev, mod) => {
        setModContent(mod);
        setOpen(true);
    };

    const [tab, setTab] = useState(0);

    const handleChange = (event, newValue) => {
        setTab(newValue);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const openECAdminModal = () => {
        return (
            <CommonModal
                open={open}
                handleClose={handleClose}
                header={true}
                footer={false}
                headerText={"Edit emergency contacts"}
                icon={editIcon}
                modalSize="lg"
                fullWidth
                onButtonClick={handleClose}
            >
                <EmergencyContactAdmin allEmergencyContacts={[...allContacts?.getAllData]} />
            </CommonModal>
        );
    };

    function TabPanel(props) {
        const { children, value, index, ...other } = props;

        return (
            <div
                role="tabpanel"
                hidden={value !== index}
                id={`simple-tabpanel-${index}`}
                aria-labelledby={`simple-tab-${index}`}
                {...other}
            >
                {value === index && (
                    <Box p={3}>
                        <Typography>{children}</Typography>
                    </Box>
                )}
            </div>
        );
    }

    TabPanel.propTypes = {
        children: PropTypes.node,
        index: PropTypes.any.isRequired,
        value: PropTypes.any.isRequired,
    };

    const breakpointColumnsObj = {
        default: 4,
        1100: 3,
        700: 2,
        500: 1,
    };

    

    //log dat for emergency contacts...
    useEffect(() => {
        dispatch(addAnalayticsLog({
    
          module : "emergency contacts",
      
          browserDetails : `${platform.name} ${platform.os.architecture} ${platform.os.family} ${platform.os.version}`,
      
          deviceDetails : platform.product ? platform.product : 'Desktop',
      
          isSearch : false
      
      }));
        
    }, []);

    //timer log
    useEffect(() => {
        const interval = setInterval(() => {
          dispatch(addInterval(localStorage.getItem('logID')))
        }, 60000);
        return () => clearInterval(interval);
      }, []);
    

    // console.log("all emergency contact =====> ", allContacts?.getAllData);
    return (
        <section className="emergencyContactContainer">
            <Template>
            <div>
        <Grid
          container
          direction="row"
          justifyContent="space-between"
          alignItems="flex-end"
        >
          {/* <div className="mshow pagetuttop">
            <PageTutorialComponent
              modelHeadtxt={"Today Page Tutorial"}
              videoLink={
                "https://newmrisedev.blob.core.windows.net/mrisestorage/1635247382000_Core%20Intranet%20V3.mp4"
              }
            />
          </div> */}
          <Grid item md={7} xs={12}>
            <div className="whatsNewHeader">
              <div className="containerTxt">
                <div className="section-header-text"> Emergency contacts</div>
                <div className="mhide">
                  {/* <PageTutorialComponent
                    modelHeadtxt={"Livewire Tutorial"}
                    videoLink={
                      "https://newmrisedev.blob.core.windows.net/mrisestorage/1635246876564_Livewire%20V2.mp4"
                    }
                  /> */}
                </div>
                {isAccesible("EMERGENCY_CONTACTS") && (
                  <div className="editIcon" onClick={(event) => handleClickOpen(event)}>
                    <img
                      src={editIconRed}
                      alt=""
                      className="edit-line"
                    />
                    <div>Edit</div>
                  </div>
                )}
              </div>
            </div>
          </Grid>
          {/* <Grid item md={5} xs={12}>
            <SearchBarButton
              placeholder={placeholder}
              searchText={searchText}
              handleSearch={handleSearch}
              name="search"
            />
          </Grid> */}
        </Grid>
      </div>
                <Grid container direction="row" justifyContent="space-between" alignItems="center">
                   
                    
                    <Grid container direction="row" className="">
                        <Grid item xs={12}>
                            <Tabs onChange={handleChange} value={tab}>
                                {allContacts?.getAllData?.map((item, index) => (
                                    <Tab key={index} label={item.location} className="favTabs" />
                                ))}
                                {/* {cardDataEmergencyContacts.map((item, index) => (
                                    <Tab key={index} label={item.location} className="favTabs" />
                                ))} */}
                            </Tabs>
                            <TabPanel value={tab} index={tab}>
                                <Grid
                                    container
                                    direction="row"
                                    spacing={2}
                                    justifyContent="flex-start"
                                    className="FooterCardDiv"
                                >
                                    <>
                                        {/* {console.log(
                      "tabs  ",
                      tab,
                      cardDataEmergencyContacts[tab].details
                    )} */}
                                        <Grid item xs={12}>
                                        <Masonry columns={{ xs: 1, sm: 3 ,md: 4 }}  spacing={1}>
                                                
                        
                                                {allContacts?.getAllData[tab]?.contacts.map((item, index) => (
                                                    <div key={index}>
                                                        <EthicsWhatsNewCard EthicsCardData={item} />
                                                    </div>
                                                ))}
                                                {allContacts?.getAllData[tab]?.contacts?.length == 0 &&
                                                    <div style={{textAlign:"center", fontSize:"20px", width:"100%"}}>
                                                        No data found.
                                                    </div>
                                                }
                                                {/* {cardDataEmergencyContacts[tab]?.details.map((item, index) => (
                                                    <div key={index}>
                                                        <EthicsWhatsNewCard EthicsCardData={item} />
                                                    </div>
                                                ))} */}
                                            </Masonry>
                                        </Grid>
                                    </>
                                </Grid>
                            </TabPanel>
                        </Grid>
                    </Grid>
                </Grid>
            </Template>
            {openECAdminModal()}
        </section>
    );
}
