import React, { useState, useEffect } from "react";
import "./LiveWireFilters.css";

import { Grid, makeStyles } from "@material-ui/core";
import { useHistory } from "react-router-dom";
import SubmitButton from "../../../Common/Button/SubmitButton";
import CommonAutocomplete from "../../../Common/CommonAutocomplete/CommonAutocomplete";
import CommonMultiAutocomplete from "../../../Common/CommonMultiAutocomplete/CommonMultiAutocomplete";
import CommonLabel from "../../../Common/ComonLabel/CommonLabel";
import isAdmin from "../../../../Components/isAdmin";
import CommonToolTip from "../../../Common/CommonToolTip";
import services from "../../../../Services/index";
import { getAllYearsMonths } from "../../../../Assets/helperFunctions/helperFunctions";
import MultiSelect from "../../../Common/MultiSelect/MultiSelect";

export default function LiveWireFilters({
  showSubmit,
  isShortBy,
  handleSortByChange,
  selectedSortBy,
  path,
  handleCalYearChange,
  handleMonthChange,
  calYearValue,
  monthValue,
  handleBusinessChange,
  sectorValue,
  getDivisionList,
  buValue,
  // getSectorList,
}) {
  const [getSectorList, setGetSectorList] = useState([]);
  // const [getDivisionList, setGetDivisionList] = useState([]);
  const [getExsitingData, setExsitingData] = useState("");
  const businessSelectOptions = [
    {
      title: "All business",
      value: "All business",
    },
    {
      title: "Business groups",
      value: "Business groups",
      child: [
        { title: "Aerospace", value: "Aerospace" },
        { title: "Aftermarket", value: "Aftermarket" },
      ],
    },
    {
      title: "Corporate initiatives",
      value: "Corporate initiatives",
    },
  ];

  const calSelectYearOptions = getAllYearsMonths();

  const sortBy = [
    {
      title: "Sort by latest",
      value: "Sort by latest",
    },
    {
      title: "Sort by old",
      value: "Sort by old",
    },
  ];

  const [allBusinessValue, setAllBusinessValue] = useState("");
  const [businessGroupValue, setbusinessGroupValue] = useState("");
  // const [calYearValue, setCalYearValue] = useState("");
  // const [monthValue, setMonthValue] = useState("");
  // const [selectedSortBy, setSelectedSortBy] = useState("");
  const history = useHistory();

  // const handleBusinessChange = (e, selectType) => {
  //   setAllBusinessValue(selectType);
  // if (businessGroupValue.value !== selectType) {
  //   setbusinessGroupValue([]);
  // }
  // };

  // const handleBusinessGroupChange = (e, selectType) => {
  //   setbusinessGroupValue(selectType);
  // };

  // const handleCalYearChange = (e, selectType) => {
  //   setCalYearValue(selectType);
  //   // console.log("type ", selectType, monthValue);
  //   if (monthValue.value !== selectType) {
  //     setMonthValue([]);
  //   }
  // };

  // const handleMonthChange = (_, selectType) => {
  //   setMonthValue(selectType);
  // };

  // const handleSortByChange = (e, selectType) => {
  //   setSelectedSortBy(selectType);
  // };

  const handleBusinessDropdown = (getSectorList) => {
    // for (let i = 0; i < list.length; i++) {
    if (getDivisionList) {
      return (
        <Grid
          item
          xs={12}
          sm={showSubmit ? 4 : 3}
          className="businessTypeIcon noIcon mahindraCBU mahindraCollectiveSector"
        >
          <MultiSelect
            // disabled
            options={getDivisionList?.length && getDivisionList}
            label={<CommonLabel label="Business unit" />}
            handleSelectChange={(e, newValue) => {
              handleBusinessChange(e, newValue, "bu");
            }}
            // handleSelectChange={handleBusinessGroupChange}
            // selectedSubcategory={businessGroupValue}
            selectedValue={buValue}
            className="McShadowBox"
          />
        </Grid>
      );
      // }
      //  else {
      //   <></>;
      // }
    }
  };

  //picking the month dropdown according to selected year
  const handleMonthDropdown = (year, list) => {
    for (let i = 0; i < list.length; i++) {
      if (year === list[i].value && list[i].child !== undefined) {
        // console.log("cal year balue", year, list[i].value);
        return (
          <Grid
            item
            xs={12}
            sm={showSubmit ? 4 : 3}
            className="calenderTypeIcon noIcon"
          >
            <CommonMultiAutocomplete
              options={list[i].child}
              label={<CommonLabel label={year} />}
              handleSelectChange={handleMonthChange}
              selectedSubcategory={monthValue}
              selectedValue={monthValue}
              className="McShadowBox"
            />
          </Grid>
        );
      } else {
        <></>;
      }
    }
  };

  useEffect(async () => {
    let userProfile = JSON.parse(
      localStorage.getItem("userProfile")
        ? localStorage.getItem("userProfile")
        : null
    );
    setExsitingData(userProfile);
    try {
      // setLoading(true);
      let res = await services.api(
        "GET",
        `/ui-assist/v1/sectors?compcode=${userProfile.company}`
      );
      if (res.status === 200) {
        // setLoading(false);
        let list = res.data.map((result) => {
          return {
            title: result.sectorName,
            value: result.sectorName,
            sectorCode: result.sectorCode,
          };
        });
        setGetSectorList(list);
      }
    } catch (err) {
      // setLoading(false);
      // Swal.fire("Oops!", `${err.response.data}`, "error");
      setGetSectorList([]);
    }
  }, []);

  return (
    <div className="liveWireDropDownContainer">
      <Grid container direction="row">
        <Grid item xs={12} sm={showSubmit ? 9 : 12} className="zeroPad">
          <Grid container direction="row">
            {showSubmit ? (
              <>
                {/* <CommonToolTip> */}
                <Grid
                  item
                  xs={12}
                  sm={showSubmit ? 4 : 3}
                  className="businessTypeIcon mahindraCollectiveSector"
                >
                  <MultiSelect
                    // disabled
                    // options={getSectorList}
                    // label={<CommonLabel label="Business Type" />}
                    // inputProps={{ "aria-label": "Without label" }}
                    // handleSelectChange={handleBusinessChange}
                    // selectedValue={allBusinessValue}
                    // className="McShadowBox"
                    className="width100"
                    handleSelectChange={(e, newValue) => {
                      handleBusinessChange(e, newValue, "sector");
                    }}
                    // handleSelectChange={handleBusinessChange}
                    selectedValue={sectorValue}
                    // options={getSectorList?.length && getSectorList}
                    options={getSectorList?.length && getSectorList}
                    name="sector"
                    // errorMsg={
                    //   formik.errors.sector && formik.touched.sector
                    //     ? formik.errors.sector
                    //     : ""
                    // }
                    label={<CommonLabel label="Sector" mandatory />}
                  />
                </Grid>
                {/* </CommonToolTip> */}
              </>
            ) : (
              <> </>
            )}
            {/* <CommonToolTip> */}
            <Grid
              item
              xs={12}
              sm={showSubmit ? 4 : 3}
              className="calenderTypeIcon"
            >
              <CommonAutocomplete
                // disabled
                options={calSelectYearOptions}
                label={<CommonLabel label="Calendar" />}
                handleSelectChange={handleCalYearChange}
                selectedValue={calYearValue}
                className="McShadowBox"
              />
            </Grid>
            {/* </CommonToolTip> */}
            {isShortBy ? (
              <Grid
                item
                xs={12}
                sm={showSubmit ? 4 : 3}
                className="shortingTypeIcon"
              >
                <CommonAutocomplete
                  options={sortBy}
                  label={<CommonLabel label={"Sort by"} />}
                  handleSelectChange={handleSortByChange}
                  selectedValue={selectedSortBy}
                  className="McShadowBox"
                />
              </Grid>
            ) : null}
            {showSubmit && sectorValue && sectorValue.length > 0 ? (
              <>
                {
                  handleBusinessDropdown(getSectorList)
                  // allBusinessValue.value,
                  // businessSelectOptions
                }
              </>
            ) : (
              <> </>
            )}

            {handleMonthDropdown(calYearValue.value, calSelectYearOptions)}
          </Grid>
        </Grid>
        <Grid
          item
          xs={12}
          sm={3}
          className="zeroPad posRight isMobileHideButton"
        >
          {showSubmit && (
            <Grid item xs={12} sm={12} className="pr-0">
              <SubmitButton
                // disabled={!isAdmin()}
                label="Upload a story"
                className="MCsubmitbutton floatRightAlign"
                onClick={() => history.push(path)}
              />
            </Grid>
          )}
          {/* {showSubmit && (
            <CommonToolTip>
              <Grid item xs={12} sm={12}>
                <SubmitButton
                  // disabled={!isAdmin()}
                  label="Upload a story"
                  className="MCsubmitbutton floatRightAlign"
                  // onClick={() => history.push(path)}
                />
              </Grid>
            </CommonToolTip>
          )} */}
        </Grid>
      </Grid>
    </div>
  );
}
