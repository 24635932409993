import React from "react";
import { Switch } from "react-router-dom";
import NotFound from "../../Components/NotFound/NotFound";
import ManageIntranetAccessComponent from "../../Components/Pages/RollMatrix/ManageIntranetAccessComponent";
import WallpaperComponent from "../../Components/Pages/RollMatrix/WallpaperComponent";
import PostWallpaperRequestComponent from "../../Components/Pages/RollMatrix/PostWallpaperRequestComponent";
import EditModal from "../../Components/FadeInOutHeaderCard/EditModal";
import FeedbackTable from "../../Components/Pages/Table/Feedbacktable";
import {
  ADD_USER_GROUP,
  MANAGE_ACCESS,
  ROLE_BASED,
  WORK,
  WALLPAPER,
  POST_WALLPAPER_REQUEST,
  ADD_NEW_ADMIN,
  WELCOME,
  EDIT_WELCOME_BANNER,
  EDIT_USER_GROUP,
  FEEDBACK_TABLE,
  ER_DASHBOARD,
} from "../constant";
import AddNewUserGroupComponent from "../../Components/Pages/RollMatrix/AddNewUserGroupComponent";
import EditUserGroupComponent from "../../Components/Pages/RollMatrix/EditUserGroupComponent";
import CreateNewAdmin from "../../Components/Pages/RollMatrix/CreateNewAdmin";
import ERDashboard from "../../Components/ERDashboard/ERDashboard";
import { ApmRoute } from "@elastic/apm-rum-react";

export default function WorkRoutes(props) {
  return (
    <div>
      <Switch>
        <ApmRoute
          exact
          path={`${WORK}${ROLE_BASED}${MANAGE_ACCESS}`}
          component={ManageIntranetAccessComponent}
        />
        <ApmRoute
          exact
          path={`${WORK}${ROLE_BASED}${ADD_USER_GROUP}`}
          component={AddNewUserGroupComponent}
        />
        <ApmRoute
          path={`${WORK}${ROLE_BASED}${EDIT_USER_GROUP}`}
          component={EditUserGroupComponent}
        />
        <ApmRoute
          exact
          path={`${WORK}${ROLE_BASED}${ADD_NEW_ADMIN}`}
          component={CreateNewAdmin}
        />
        <ApmRoute
          exact
          path={`${WORK}${ROLE_BASED}${WALLPAPER}`}
          component={WallpaperComponent}
        />
        <ApmRoute
          exact
          path={`${WORK}${ROLE_BASED}${WALLPAPER}${POST_WALLPAPER_REQUEST}`}
          component={PostWallpaperRequestComponent}
        />
        <ApmRoute
          exact
          path={`${WORK}${ROLE_BASED}${WELCOME}${EDIT_WELCOME_BANNER}`}
          component={EditModal}
        />
        <ApmRoute
          exact
          path={`${WORK}${ROLE_BASED}${FEEDBACK_TABLE}`}
          component={FeedbackTable}
        />
        <ApmRoute
          exact
          path={`${WORK}${ER_DASHBOARD}`}
          component={ERDashboard}
        />
        <ApmRoute component={NotFound} />
      </Switch>
    </div>
  );
}
