import React, { useState, useEffect } from "react";
import "./HeaderMenu.css";
import DrawerComponent from "../DrawerComponent/DrawerComponent";
import { Icon, makeStyles, Tooltip } from "@material-ui/core";
import DropDown from "../../Common/DropDown/DropDown";
import PropTypes from "prop-types";
import { Link, useLocation, useHistory } from "react-router-dom";
import { DASHBOARD } from "../../../Routes/constant";
import { useSelector } from "react-redux";
import services from "../../../Services";
import Loader from "../../Common/Loader/Loader";
import { browserName } from "react-device-detect";

const useStyles = makeStyles({
  customTooltip: {
    backgroundColor: "#fefdfd",

    fontSize: "12px",
    width: "250px",
    textAlign: "center",
    padding: "8px 8px 8px 8px",
    borderRadius: "0px",
    color: "#242424",
    fontWeight: "normal",
    position: "relative",
    top: "20px",
  },
  customArrow: {
    color: "#fefdfd",
  },
});

function HeaderMenu({ isMobile, leftMenu }) {
  const location = useLocation();
  const classes = useStyles();
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [hidenavigationdropDown, setHidenavigationdropDown] = useState(false);
  const history = useHistory();
  // const [config, setConfig] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const handleDrawerOpen = () => {
    setDrawerOpen(true);
  };

  const handleDrawerClose = () => {
    setDrawerOpen(false);
  };

  const handleNavigationDropDownHide = () => {
    console.log("i was clicked.....");
    if (browserName !== "Safari") setHidenavigationdropDown(true);
  };

  const handleNavigationDropDownShow = () => {
    setHidenavigationdropDown(false);
  };
  const getTodayToolTip = useSelector(
    (state) => state.getTodayToolTipReducer.getData
  );

  // const getLeftHeaderMenu = async () => {
  //   try {
  //     setIsLoading(true);
  //     let res = await services.api(
  //       "GET",
  //       `/ui-assist/v1/tiles/menutiles/${localStorage.getItem("token")}`
  //     );
  //     if (res.status === 200) {
  //       setIsLoading(false);
  //       let getLeftMenus = res.data.filter((result, index) => index <= 3);
  //       setConfig(getLeftMenus);
  //     }
  //   } catch (err) {
  //     setIsLoading(false);
  //     console.log("err from headerMenu", err);
  //   }
  // };

  // useEffect(() => {
  //   getLeftHeaderMenu();
  // }, []);

  return (
    <div className="menuContainer">
      {isMobile && (
        <div className="alignMenuIconCenter">
          {drawerOpen === true ? (
            <Icon onClick={handleDrawerClose} className="iconAlign">
              close
            </Icon>
          ) : (
            <img
              src={process.env.PUBLIC_URL + "/iconImages/header/menu-fill.svg"}
              onClick={handleDrawerOpen}
              className="iconAlign"
              alt="header logo"
            />
          )}
        </div>
      )}

      {isMobile && (
        <DrawerComponent
          drawerOpen={drawerOpen}
          handleDrawerClose={handleDrawerClose}
        />
      )}
      {isMobile && (
        <img
          src={process.env.PUBLIC_URL + "/logo/mlogo.png"}
          className="logo-width"
          alt="header logo"
          onClick={() => history.push(DASHBOARD)}
        />
      )}
      {!isMobile && (
        <div className="alignLogo" onClick={() => history.push(DASHBOARD)}>
          {/* <img
            src={process.env.PUBLIC_URL + "/logo/TopLeftRidge.png"}
            className="logoStrip"
            alt="header logo strip"
          /> */}
          <span className="navLogoAlign">
            <img
              src={process.env.PUBLIC_URL + "/logo/mlogo.png"}
              className="logoAlignWidth"
              alt="header logo"
            />
          </span>
        </div>
      )}
      <span className="navMenuAlign">
        {!isMobile && (
          <Tooltip
            title={
              getTodayToolTip
                ? ""
                : "Your daily reckoner of updates and tasks that need attention."
            }
            classes={{
              tooltip: classes.customTooltip,
              arrow: classes.customArrow,
            }}
            arrow
          >
            <Link
              className={`fromCenter dropDownContainer linkStyleDecorationNone ${
                location.pathname === "/" ? "activeNavTab" : ""
              }`}
              to={DASHBOARD}
            >
              Today
            </Link>
          </Tooltip>
        )}
        <DropDown
          config={
            leftMenu
              ? leftMenu?.filter((tab) => tab?.title !== "AFS Microsite")
              : []
          }
          isMobile={isMobile}
          className="fromCenter"
          position="right"
          hidenavigationdropDown={hidenavigationdropDown}
          handleNavigationDropDownHide={handleNavigationDropDownHide}
          handleNavigationDropDownShow={handleNavigationDropDownShow}
        />

        {!isMobile &&
          (leftMenu?.filter((item) => item?.title === "AFS Microsite")
            ?.length === 0 ? (
            ""
          ) : (
            <Tooltip
              title={
                getTodayToolTip ? "" : "The AFS Internal Communications Hub"
              }
              classes={{
                tooltip: classes.customTooltip,
                arrow: classes.customArrow,
              }}
              arrow
            >
              <a
                className={`fromCenter dropDownContainer linkStyleDecorationNone ${
                  location.pathname === "/" ? "" : "activeNavTab"
                }`}
                href={
                  leftMenu?.filter((item) => item?.title === "AFS Microsite")[0]
                    ?.subMenu[0]?.link
                }
                target="_blank"
              >
                {
                  leftMenu?.filter((item) => item?.title === "AFS Microsite")[0]
                    ?.subMenu[0]?.title
                }
              </a>
            </Tooltip>
          ))}
      </span>
      {isLoading ? <Loader /> : null}
    </div>
  );
}

HeaderMenu.propTypes = {
  config: PropTypes.array,
  isMobile: PropTypes.bool,
};

HeaderMenu.defaultProps = {
  config: [],
  isMobile: false,
};

export default HeaderMenu;
