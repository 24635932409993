import React, { useState, useEffect } from "react";
import "../../../MahindraCollective/UploadStory/UploadStoryComponent.css";
import Checkbox from "@mui/material/Checkbox";

function BenefitTitle({ title, val, setVal }) {
  const [check, setCheck] = useState(false);

  useEffect(() => {
    setCheck(val);
  }, [val]);

  const handleChange = (e) => {
    setCheck(!check);
    setVal(!check);
  };

  return (
    <>
      <div className="inlineContent">
        <span className="submitOfferCampaignText commonFontNormalStyle  m0">
          <Checkbox
            checked={check}
            className="p-0 pb-1 pe-1"
            onChange={handleChange}
          />
          {title}
        </span>
      </div>
    </>
  );
}

export default BenefitTitle;
