import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import Loader from "../../Common/Loader/Loader";
import {
  deleteComment as deleteCommentApi,
  updateComment as updateCommentApi,
} from "./api";
import Comment from "./Comment";
import CommentForm from "./CommentForm";
import "./Articlecomment.css";
import "../LiveWireMcArticle2/LiveWireMcArticle2.css";
import { Grid } from "@material-ui/core";

//import {getComments as getCommentsNewApi} from '../../mApi'
//import { arrayExpression } from "@babel/types";
import services from "../../../Services";
import Swal from "sweetalert2";

function Comments({
  articleID,
  currentUserId = localStorage.getItem("token"),
 setRootCommentCount, 
 refreshComment, 
 contentType
}) {
  const location = useLocation();
  const path = location.pathname.split("/");

  const queryParams = new URLSearchParams(window.location.search);
  const id = queryParams.get("id") ? queryParams.get("id") : path[path.length-1];
  const [currentArticleID, setCurrentArticleID] = useState(id);
  console.log(id, 'current artivle id')
  console.log(currentArticleID, 'article id.....')
  console.log(refreshComment, 'refresh id:')

  // useEffect(() => {
  //   setCurrentArticleID(articleID);
  // }, [articleID]);

  const [likeData, setLikeData] = useState(null);
  const [commentCount, setCommentCount] = useState(0);
  const [backendComments, setbackendComments] = useState([]);
  const [refresh, setrefresh] = useState(false);
  const [refresh2, setrefresh2] = useState(false);
  const [refreshDelete, setrefreshDelete] = useState(false);
  const [activeState, setActiveState] = useState(null);
  const [loading, setLoading] = useState(false);
  const [likeCount, setLikeCount] = useState(0)
  
  //returns only the root comments.
  const rootComments = backendComments?.filter((el) => el.parentId === null);
  setRootCommentCount(rootComments.length)
  //returns replies for the specific comments.
  const [rootReplyCount, setRootReplyCount] = useState('')
  const getReplies = (commentId) => {
  

    return backendComments?.filter((el) => el.parentId === commentId);
  };


 
  

  const handleCommentApi = async (data) => {
    setLoading(true);
    try {
      let res = await services.api("POST", `/ui-assist/v1/comment`, data);
      if (res.status === 200) {
        setLoading(false);
        setrefresh2(!refresh2);
      }
    } catch (err) {
      Swal.fire("Oops!", `${err.response}`, "error");
    }
  };

  const addComment = async (text, parentId) => {
    const data = {
      parentId: parentId,
      comment: text,
      contenId: id,
      employeeId: localStorage.getItem("token"),
      contentType: contentType,
    };

    handleCommentApi(data);
    setrefresh(!refresh);
  };

  const deleteComment = (commentId) => {
    handleDeleteApi(commentId);
  };

  const handleDeleteApi = async (id) => {
    try {
      const res = await services.api("DELETE", `/ui-assist/v1/comment/${id}`);
      
      if (res.status === 200) {
        setrefreshDelete(!refreshDelete);
      }
    } catch (error) {
      console.log(error, "error");
    }
  };

 
  const updateComment = (text, commentId) => {
    let formData = new FormData();
    formData.append("comment", text);
    handleUpdateCommentApi(formData, commentId);
  };

  //update the comment.
  const handleUpdateCommentApi = async (data, id) => {
    try {
      const res = await services.api(
        "PATCH",
        `/ui-assist/v1/comment/${id}`,
        data
      );
      
      if (res.status === 200) {
        setActiveState(null);
        setrefresh2(!refresh2);
      }
    } catch (error) {
      console.log(error);
    }
  };

  //get all the comment for articleID.
  useEffect(() => {
    (async () => {
      
      try {
        setLoading(true);
        const res = await services.api(
          "GET",
          `/ui-assist/v1/comment/${id}`
        );
        if (res.status === 200) {
          setLoading(false);
          setbackendComments(res.data);
         
        }
      } catch (err) {
        console.log(err.message);
      }
    })();
  }, [currentArticleID, refresh, refresh2, refreshDelete, id]);

  //like comment api.
  const handleLikeApi = async (data) => {
    setLoading(true);
    try {
      let res = await services.api("POST", `/ui-assist/v1/comment`, data);
      if (res.status === 200) {
        setLoading(false);
        if (res.data === null) {
          setCommentCount(0);
        } else {
          setCommentCount(res.data.length);
        }
      }
    } catch (error) {}
  };

  return (
    <div className="ffarticleSaira">
      <CommentForm
        label="COMMENT"
        handleSubmit={addComment}
        commentBoxStyle="outerR"
      />
      <Grid item xs={12}>
        <p className="commentTitle">Comments</p>
      </Grid>
      <div className="comments-container">
        {loading ? (
          <Loader />
        ) : (
          <>
           
            {rootComments.map((el) => (
              <Comment
                key={el.id}
                replies={getReplies(el.id)}
                articleID = {articleID}
                comment={el}
                currentUserId={currentUserId}
                deleteComment={deleteComment}
                activeState={activeState}
                setActiveState={setActiveState}
                addComment={addComment}
                likeData={likeData}
                setLikeData={setLikeData}
                updateComment={updateComment}
                className="commentBox"
                likedCount = {el.likedBy.length}
              />
            ))}
          </>
        )}
      </div>
    </div>
  );
}

export default Comments;
