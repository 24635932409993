import React, { useState, useEffect } from "react";
import Header from "../Header/Header";
import Footer from "../Footer/Footer";
import MicroFeedback from "../MicroFeedback/MicroFeedback";
import LinearProgress from "@material-ui/core/LinearProgress";
import "../LoadingScreen/LoadingScreen.css";
import { MBoolean } from "../../Redux/Actions/EditModalBannerAction";
import { useDispatch, useSelector } from "react-redux";
import EditModal from "../FadeInOutHeaderCard/EditModal";
import { useHistory, useLocation } from "react-router-dom";
import {
  AUTH,
  LOGIN,
  FORGOT_PASSWORD,
  OTP,
  RESET_LINK,
  NOT_AUTH,
} from "../../Routes/constant";
import axios from "axios";
import { getScopeDetailsData } from "../../Redux/Actions/ProfileScopeDetails";
import { getLocalStorageUpdationStatus } from "../../Redux/Actions/AddLocalStorageDataConfirmationAction";
function Layout(props) {
  const [progress, setProgress] = useState(0);
  const dispatch = useDispatch();
  const myState = useSelector((state) => state.changeBooleanBanner);
  const history = useHistory();
  //const location = useLocation();
  const getEmployee = async () => {
    if (process.env.REACT_APP_APP_ENV === "local") {
      localStorage.setItem("token", 207624);
      //  history.push(`${DASHBOARD}`);
    } else {
      try {
        if (!localStorage.getItem("token")) {
          //if loacal storage blank then call employee api for adding value in loaclstorage
          let res = await axios(
            `${process.env.REACT_APP_API_BASE_URL}/ui-assist/v1/employee`
          );
          if (res.status === 200) {
            localStorage.setItem("userProfile", JSON.stringify(res.data));
            localStorage.setItem("isLocalStorage", JSON.stringify(true));
            dispatch(getLocalStorageUpdationStatus());
            localStorage.setItem("isEdcastUser", true);
            const { firstname, lastname, employeeid } = res.data;
            localStorage.setItem("fullName", `${firstname} ${lastname}`);
            localStorage.setItem("lastName", lastname);
            // let firstName = name.split(" ")[1];
            localStorage.setItem("token", employeeid);
            localStorage.setItem("name", firstname);
            localStorage.setItem("isLogin", true);
            // history.push(`${DASHBOARD}`);
            // setGetUrl(<Redirect to={`${DASHBOARD}`} />);
            dispatch(getScopeDetailsData(localStorage.getItem("token")));
          } else {
            console.log("error occured in header 200");
          }
        }
      } catch (err) {
        console.log("error occured in header");
        if (process.env.REACT_APP_APP_ENV != "uatm") {
          window.location.href =
            `${process.env.REACT_APP_DEV_URL}` +
            "?redirectTo=" +
            window.location.href;
        } else {
          history.push("/login");
        }
      }
    }
  };

  // const getEmployeeScope = async () => {
  // await

  // };

  useEffect(() => {
    getEmployee();
    //getEmployeeScope();
    const timer = setInterval(() => {
      setProgress((oldProgress) => {
        if (oldProgress === 50) {
          return 50;
        }
        const diff = Math.random() * 10;
        return Math.min(oldProgress + diff, 50);
      });
    }, 500);

    return () => {
      clearInterval(timer);
    };
  }, []);
  const path = useLocation();

  const checkPath = [
    `${AUTH}${LOGIN}`,
    `${AUTH}${FORGOT_PASSWORD}`,
    `${AUTH}${OTP}`,
    `${AUTH}${RESET_LINK}`,
    `${AUTH}${NOT_AUTH}`,
  ];
  return (
    <div>
      {!checkPath.includes(path.pathname) &&
        path.pathname != "/login" &&
        localStorage.getItem("token") &&
        progress === 50 && <Header />}

      {!checkPath.includes(path.pathname) &&
        localStorage.getItem("token") &&
        progress === 50 && <MicroFeedback />}
      {progress === 50 ? (
        props.children
      ) : (
        <div className="innerDiv">
          <img
            className="gifSize"
            src={process.env.PUBLIC_URL + "/loaderGif/Loader.gif"}
            alt="header logo"
          />
          <div style={{ display: "flex" }}>
            <LinearProgress
              variant="determinate"
              color="secondary"
              className="linearP1"
              value={progress}
            />
            <LinearProgress
              variant="determinate"
              color="secondary"
              className="linearP2"
              value={progress}
            />
          </div>
        </div>
      )}

      {!checkPath.includes(path.pathname) &&
        path.pathname != "/login" &&
        localStorage.getItem("token") &&
        progress === 50 && <Footer />}
    </div>
  );
}

export default Layout;
