import services from "../../Services/index";
import {
  GET_NOTIFICATION_DATA_LOAD,
  GET_NOTIFICATION_DATA_FAILED,
  GET_NOTIFICATION_DATA_SUCCESS,
  GET_NOTIFICATION_FILTER_DATA_SUCCESS, 
  GET_NOTIFICATION_FILTER_DATA_FAILED,
  GET_NOTIFICATION_FILTER_DATA_LOAD,
 
} from "../Contants/Constants";

const getNotificationsDataLoad = () => ({
  type: GET_NOTIFICATION_DATA_LOAD,
});
const getNotificationsDataSuccess = (data) => ({
  type: GET_NOTIFICATION_DATA_SUCCESS,
  payload: data,
});
const getNotificationsDataFailed = (error) => ({
  type: GET_NOTIFICATION_DATA_FAILED,
  err: error,
});

export const getNotificationsData = () => async (dispatch) => {
  dispatch(getNotificationsDataLoad());
  try {
    const res = await services.api(
      "GET",
      `/ui-assist/v1/notification/${localStorage.getItem("token")}`,
      {}
    );
    if (res) {
      dispatch(getNotificationsDataSuccess(res.data));
     // console.log('response..........', res.data)
    }
  } catch (err) {
    dispatch(getNotificationsDataFailed(err.response));
  }
};



const getNotificationsFilterDataLoad = () => ({
  type: GET_NOTIFICATION_FILTER_DATA_LOAD,
});
const getNotificationsFilterDataSuccess = (data) => ({
  type: GET_NOTIFICATION_FILTER_DATA_SUCCESS,
  payload: data,
});
const getNotificationsFilterDataFailed = (error) => ({
  type: GET_NOTIFICATION_FILTER_DATA_FAILED,
  err: error,
});



export const getNotificationsFilterData = () => async (dispatch) => {
  dispatch(getNotificationsFilterDataLoad());
  try {
    const res = await services.api(
      "GET",
      `/ui-assist/v1/notification/status`,
      
    );
    if (res) {
      dispatch(getNotificationsFilterDataSuccess(res.data));
     
    }
  } catch (err) {
    dispatch(getNotificationsFilterDataFailed(err.response));
  }
};



