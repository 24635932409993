import React, { useState, useEffect } from "react";
import "./WhatsnewDeleteModal.css";
import leftArrow from "../../../../Assets/Images/WhatsNewIcons/arrow-right-s-line.svg";

import textFieldStyle from "../../../Common/Styles/TextFieldStyle";
import TextArea from "../../../Common/TextArea/TextArea";
import CommonMultiAutocomplete from "../../../Common/CommonMultiAutocomplete/CommonMultiAutocomplete";
import CommonLabel from "../../../Common/ComonLabel/CommonLabel";
import SubmitButton from "../../../Common/Button/SubmitButton";
import services from "../../../../Services";
import CommonModal from "../../../Common/CommonModal/CommonModal";
import deleteIcon from "../../../../Assets/Images/delete.svg";
import Swal from "sweetalert2";
import { useDispatch, useSelector } from "react-redux";
import { getPolicyTableDataByID } from "../../../../Redux/Actions/PolicyCategoryAction";

//upload related
import { uploadStorySchema } from "../../../../Assets/formikErrors/SubmitOfferCampaignSchema";
import uploadIcon from "../../../../Assets/Images/collectiveIcons/upload-2-line.svg";

import moment from "moment";
import { Divider, Grid, TextField } from "@material-ui/core";
import MultiSelect from "../../../Common/MultiSelect/MultiSelect";
import CommonCheckBox from "../../../Common/CommonCheckBox/CommonCheckBox";
import Loader from "../../../Common/Loader/Loader";
import CustomMultiSelect from "../../../Common/CustomMultiSelect/CustomMultiSelect";
import CharLeftField from "../../../Common/CharLeftField/CharLeftField";
import DatePicker from "../../../Common/DatePicker/DatePicker";
import { getFormattedDate } from "../../../../Assets/helperFunctions/helperFunctions";
import {
  getWhatsNewData,
  getWhatsNewSectors,
} from "../../../../Redux/Actions/WhatsAction";
import { getLastModifiedData } from "../../../../Redux/Actions/CommonActions";
// import services from "../../../../Services";
//import CommonLabel from "../../../Common/ComonLabel/CommonLabel";
function WhatsnewDeleteModal({ x, y, item, sectors }) {
  const dispatch = useDispatch();

  const [sectorsList, setSectorsList] = useState([]);
  const [loading, setLoading] = useState(true);
  const whatsNewDetails = useSelector((state) => state.getWhatsNewDataReducer);

  //CUSTOM MULTISELECT.
  const getOptionLabel = (option) => `${option.title}`;

  const getOptionDisabled = (option) => option.value === "foo";
  const [selectedSectorOptions, setSelectedSectorOptions] = useState([]);
  const [sectorListData, setSectorListData] = useState([]);

  const [slectedBuRev, setSlectedBuRev] = useState([]);
  const [selectedSectorRev, setSelectedSectorRev] = useState([]);
  //to get selected sector list.
  useEffect(() => {
    let data = [];
    whatsNewDetails?.sectors?.map((val) => {
      const a = { title: val?.sectorName, value: val?.sectorCode };
      data = [...data, a];
    });
    setSectorsList([...data]);
  }, [whatsNewDetails?.sectors]);
  useEffect(() => {
    let dataSectorList = selectedSectorOptions?.map((el) => {
      return el.value;
    });

    let sectorList = selectedSectorOptions?.map((el) => {
      return {
        sectorId: el.value,
        sectorName: el.title,
      };
    });
    setSelectedSectorRev(sectorList);
    setSectorListData(dataSectorList.join(",").toString());
  }, [selectedSectorOptions]);

  //handle toggle for all the dropdowns
  const handleToggleOption = (selectedOptions, name) => {
    if (name === "sector") {
      setSelectedSectorOptions(selectedOptions);
      getSelectedSectorOptions(selectedOptions);
    }
  };

  //handle clear for all the dropdown's
  const handleClearOptions = (isSelected, name) => {
    if (name === "sector") {
      setSelectedSectorOptions([]);
    }
  };

  //handle select all for dropdown's.
  const handleSelectAll = (isSelected, name) => {
    if (isSelected) {
      if (name === "sector") {
        setSelectedSectorOptions(sectors);
        getSelectedSectorOptions(sectors);
      } else {
        handleClearOptions();
      }
    }
  };

  const getSelectedSectorOptions = (selectedOptions) => {
    const xyz = selectedOptions.map((el) => {
      return {
        addsectorId: el.value,
      };
    });
    const sectorList = selectedOptions.map((el) => el.value);
  };

  const articleToBeDeletedForSector = selectedSectorOptions
    ?.map((el) => el.title)
    ?.join(", ")
    .toString();

  const handleDelete = async (id) => {
    Swal.fire({
      title: "Are you sure?",
      html: `Do you want <b>${item?.contents?.title}</b> article to be removed for <b>${articleToBeDeletedForSector}</b>`,
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Yes, remove it!",
      cancelButtonText: "No, keep it",
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          setLoading(true);
          const res = await services.api(
            "DELETE",
            `/ui-assist/v1/whatsnewsectionbulk/contentId/${id}?scope=${sectorListData}`
          );
          if (res.status === 200) {
            setLoading(false);
            // y(false);
            Swal.fire({
              position: "center",
              icon: "success",
              title: "What'snew article deleted successfully",
              showConfirmButton: false,
              timer: 1500,
            });
            dispatch(getWhatsNewData());
            dispatch(getWhatsNewSectors());
            dispatch(getLastModifiedData("whatsnew"));
            y(false);
          }
        } catch (err) {
          setLoading(false);
          if (err.response) {
            Swal.fire({
              icon: "error",
              title: "Oops...",
              text: "Something went wrong!",
              footer: '<a href="">Why do I have this issue?</a>',
            });
            y(false);
          }
        }
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire("Cancelled", "The said article is intact!", "error");
      }
    });
  };
  const handleCloseModel = () => {
    y(false);
  };
  const onBackButtonHandler = () => {
    y(false);
  };
  const data = true;

  return (
    <>
      {/* <CommonModal
        open={x}
        handleClose={handleCloseModel}
        headerText="Remove What'snew article"
        icon={deleteIcon}
        modalSize="lg"
        submitBtnText="Remove"
        cancleBtnText="BACK"
        onButtonClick={() => handleDelete(item?.contents?._id)}
        fullWidth
        policiesButtonSize
        onCancleButtonClick={onBackButtonHandler}
        disabledSubmitButton={!Boolean(sectorListData)}
      >
        <Grid container spacing={3}>
          <Grid item md={3} xs={12}>
            <div className="mainAccContainer">
              <div className={"accordionStyle"}>{item?.contents?.title}</div>
              {item.expiry !== null && (
                <div className={"lableClass"}>
                  Expires on {moment(item?.expiry).format("MMM Do YY")}
                </div>
              )}
            </div>
          </Grid>
        </Grid>
        <Grid container spacing={3} style={{ padding: "16px 12px" }}></Grid>
        <Grid
          container
          direction="column"
          spacing={3}
          style={{ padding: "16px 12px" }}
        >
          <Grid
            item
            sm={3}
            xs={12}
            className="policiesMultiIcon policiesAlignMulti"
          >
            <div className="mainAccContainer">
              <div className={"accordionStyle"}>
                Choose any sector to proceed :-
              </div>
            </div>
          </Grid>

          <Grid
            item
            sm={3}
            xs={12}
            className="policiesMultiIcon policiesAlignMulti"
          >
            <CustomMultiSelect
              items={sectorsList}
              getOptionLabel={getOptionLabel}
              getOptionDisabled={getOptionDisabled}
              selectedValues={selectedSectorOptions}
              label={<CommonLabel label="Sector" mandatory />}
              selectAllLabel="Select all"
              onToggleOption={(isSelected) => {
                handleToggleOption(isSelected, "sector");
              }}
              onClearOptions={(isSelected) => {
                handleClearOptions(isSelected, "sector");
              }}
              onSelectAll={(isSelected) => {
                handleSelectAll(isSelected, "sector");
              }}
            />
          </Grid>
        </Grid>
      </CommonModal> */}

      <CommonModal
        open={x}
        handleClose={handleCloseModel}
        headerText="Remove What'snew article"
        icon={deleteIcon}
        modalSize="lg"
        submitBtnText="Remove"
        cancleBtnText="BACK"
        onButtonClick={() => handleDelete(item?.contents?._id)}
        fullWidth
        policiesButtonSize
        onCancleButtonClick={onBackButtonHandler}
        disabledSubmitButton={!Boolean(sectorListData)}
      >
        <div className="leftRightAlign mb16" style={{ marginTop: "22px" }}>
          <Grid
            container
            direction="row"
            className="gridRowSpacing"
            spacing={3}
          >
            <Grid item sm={12} xs={12}>
              <div className="mainAccContainer">
                <div className={"accordionStyle"}>{item?.contents?.title}</div>
                {item.expiry !== null && (
                  <div className={"lableClass"}>
                    Expires on {moment(item?.expiry).format("MMM Do YY")}
                  </div>
                )}
              </div>
            </Grid>
          </Grid>
          <Divider
            orientation="horizontal"
            flexItem
            fullWidth
            style={{
              backgroundColor: "lightgray",
              height: "2px",
              marginBottom: "32px",
            }}
          />

          <Grid
            container
            direction="row"
            className="gridRowSpacing"
            spacing={3}
          >
            <Grid
              item
              sm={12}
              xs={12}
              className="policiesMultiIcon policiesAlignMulti"
            >
              <div className="mainAccContainer">
                <div className={"accordionStyle"}>
                  Choose any sector to proceed :-
                </div>
              </div>
            </Grid>

            <Grid item sm={12} xs={12} md={3}>
              <CustomMultiSelect
                items={sectorsList}
                getOptionLabel={getOptionLabel}
                getOptionDisabled={getOptionDisabled}
                selectedValues={selectedSectorOptions}
                label={<CommonLabel label="Sector" mandatory />}
                selectAllLabel="Select all"
                onToggleOption={(isSelected) => {
                  handleToggleOption(isSelected, "sector");
                }}
                onClearOptions={(isSelected) => {
                  handleClearOptions(isSelected, "sector");
                }}
                onSelectAll={(isSelected) => {
                  handleSelectAll(isSelected, "sector");
                }}
              />
            </Grid>
          </Grid>
        </div>
      </CommonModal>
    </>
  );
}

export default WhatsnewDeleteModal;
