import {
  GET_ALL_ORG_DATA_LOAD,
  GET_ALL_ORG_DATA_FAILED,
  GET_ALL_ORG_DATA_SUCCESS,
  GET_ALL_ORG_ADMIN_DATA_LOAD,
  GET_ALL_ORG_ADMIN_DATA_FAILED,
  GET_ALL_ORG_ADMIN_DATA_SUCCESS,
  GET_SECTOR_ORG_DATA_LOAD,
  GET_SECTOR_ORG_DATA_FAILED,
  GET_SECTOR_ORG_DATA_SUCCESS,
  GET_BUSINESS_UNITS_ORG_DATA_LOAD,
  GET_BUSINESS_UNITS_ORG_DATA_FAILED,
  GET_BUSINESS_UNITS_ORG_DATA_SUCCESS,
  GET_FUNCTION_ORG_DATA_LOAD,
  GET_FUNCTION_ORG_DATA_FAILED,
  GET_FUNCTION_ORG_DATA_SUCCESS,
  GET_ORG_COUNT_LOAD,
  GET_ORG_COUNT_FAILED,
  GET_ORG_COUNT_SUCCESS,
  GET_ORG_VIEWS_SUCCESS,
  GET_ORG_VIEWS_LOAD,
  GET_ORG_VIEWS_FAILED,
} from "../Contants/Constants";

const initialState = {
  getData: [],
  isLoading: false,
  isError: "",
};

const getOrgDataReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_ALL_ORG_DATA_LOAD:
      return {
        ...state,
        isLoading: true,
      };
    case GET_ALL_ORG_DATA_SUCCESS: {
      if (action.payload.length !== 0) {
        return {
          ...state,
          getData:
            action.page === 0
              ? [...action.payload]
              : [...state.getData, ...action.payload],
          isLoading: false,
        };
      } else {
        return {
          ...state,
          getData:
            action.page === 0
              ? [...action.payload]
              : [...state.getData, ...action.payload],
          isLoading: false,
          isDataEnds: true,
        };
      }
    }
    case GET_ALL_ORG_DATA_FAILED:
      return {
        ...state,
        isError: action.payload,
        isLoading: false,
        getData: [],
      };
    default:
      return state;
  }
};

const initialAdminState = {
  getAdminData: [],
  isLoading: false,
  isError: "",
};

const getOrgAdminDataReducer = (state = initialAdminState, action) => {
  switch (action.type) {
    case GET_ALL_ORG_ADMIN_DATA_LOAD:
      return {
        ...state,
        isLoading: true,
      };
    case GET_ALL_ORG_ADMIN_DATA_SUCCESS: {
      if (action.payload.length !== 0) {
        return {
          ...state,
          getAdminData:
            action.page === 0
              ? [...action.payload]
              : [...state.getAdminData, ...action.payload],
          isLoading: false,
        };
      } else {
        return {
          ...state,
          isLoading: false,
          getAdminData:
            action.page === 0
              ? [...action.payload]
              : [...state.getAdminData, ...action.payload],
          isAdminDataEnds: true,
        };
      }
    }
    case GET_ALL_ORG_ADMIN_DATA_FAILED:
      return {
        ...state,
        isError: action.payload,
        isLoading: false,
        getAdminData: [],
      };
    default:
      return state;
  }
};

const sectorDataState = {
  getData: [],
  isLoading: false,
  isError: "",
};

const getOrgDataBySectorReducer = (state = sectorDataState, action) => {
  switch (action.type) {
    case GET_SECTOR_ORG_DATA_LOAD:
      return {
        ...state,
        isLoading: true,
      };
    case GET_SECTOR_ORG_DATA_SUCCESS:
      return {
        ...state,
        getData: action.payload,
        isLoading: false,
      };
    case GET_SECTOR_ORG_DATA_FAILED:
      return {
        ...state,
        isError: action.payload,
        isLoading: false,
      };
    default:
      return state;
  }
};

const businessUnitDataState = {
  getData: [],
  isLoading: false,
  isError: "",
};

const getOrgDataByBusinessUnitsReducer = (
  state = businessUnitDataState,
  action
) => {
  switch (action.type) {
    case GET_BUSINESS_UNITS_ORG_DATA_LOAD:
      return {
        ...state,
        isLoading: true,
      };
    case GET_BUSINESS_UNITS_ORG_DATA_SUCCESS:
      return {
        ...state,
        getData: action.payload,
        isLoading: false,
      };
    case GET_BUSINESS_UNITS_ORG_DATA_FAILED:
      return {
        ...state,
        isError: action.payload,
        isLoading: false,
      };
    default:
      return state;
  }
};

const functionDataState = {
  getData: [],
  isLoading: false,
  isError: "",
};

const getOrgDataByFunctionReducer = (state = functionDataState, action) => {
  switch (action.type) {
    case GET_FUNCTION_ORG_DATA_LOAD:
      return {
        ...state,
        isLoading: true,
      };
    case GET_FUNCTION_ORG_DATA_SUCCESS:
      return {
        ...state,
        getData: action.payload,
        isLoading: false,
      };
    case GET_FUNCTION_ORG_DATA_FAILED:
      return {
        ...state,
        isError: action.payload,
        isLoading: false,
      };
    default:
      return state;
  }
};

const totalOrgCountState = {
  getTotalCount: [],
  isLoading: false,
  isError: "",
};

const getOrgCountReducer = (state = totalOrgCountState, action) => {
  switch (action.type) {
    case GET_ORG_COUNT_LOAD:
      return {
        ...state,
        getTotalCount: 0,
        isLoading: true,
      };
    case GET_ORG_COUNT_SUCCESS:
      return {
        ...state,
        getTotalCount: action.payload,
        isLoading: false,
      };
    case GET_ORG_COUNT_FAILED:
      return {
        ...state,
        getTotalCount: 0,
        isError: action.payload,
        isLoading: false,
      };
    default:
      return state;
  }
};

const initialViewState = {
  getData: [],
  isLoading: false,
  isError: "",
};

const getOrgViewsReducer = (state = initialViewState, action) => {
  switch (action.type) {
    case GET_ORG_VIEWS_LOAD:
      return {
        ...state,
        isLoading: true,
      };
    case GET_ORG_VIEWS_SUCCESS:
      return {
        ...state,
        getData: action.payload,
        isLoading: false,
      };
    case GET_ORG_VIEWS_FAILED:
      return {
        ...state,
        isError: action.payload,
        isLoading: false,
      };
    default:
      return state;
  }
};

export {
  getOrgDataReducer,
  getOrgAdminDataReducer,
  getOrgDataBySectorReducer,
  getOrgDataByBusinessUnitsReducer,
  getOrgDataByFunctionReducer,
  getOrgCountReducer,
  getOrgViewsReducer,
};
