import React from "react";
import NavigateNextIcon from "@material-ui/icons/NavigateNext";
import { Link, Breadcrumbs, Grid } from "@material-ui/core";

import "./Template.css";
import PageTutorialComponent from "../PageTutorial/PageTutorialComponent";

export default function Template({ children, pageName }) {
  return (
    <div className="bgImageTemplate" >
      <Grid
        container
        direction="row"
        justifyContent="space-between"
        alignItems="center"
      >
        <Grid item xs={12} className="commonTemplate"
        >
          <Breadcrumbs
            separator={<NavigateNextIcon style={{ fontSize: "1em" }} />}
            aria-label="breadcrumb"
            style={{
              fontSize: "0.7em",
              textTransform: "uppercase",
              color: "#979797",
              paddingTop: "80px",
              marginTop: "32px",
              // marginBottom: "16px",
              position: "relative",
              display:"none"
            }}
          >
            {/* <Link color="inherit" to="/">
              Today
            </Link>
            <Link color="inherit" to="#">
              Connect
            </Link>
            <Link color="inherit" to="#">
              Livewire
            </Link>
            <Link color="inherit" to="#">
              {pageName}
            </Link> */}
          </Breadcrumbs>
          {pageName === "Mahindra Collective" ? (
            <Grid
              container
              direction="row"
              justifyContent="flex-end"
              alignItems="center"
            >
              
            </Grid>
          ) : null}

          <div>{children}</div>
        </Grid>
      </Grid>
    </div>
  );
}
