const initialState = {
    status: null
}

const NotificationStatusReducer = (state = initialState, action) => {
   
if(action.type.type === 'READNOTIFICATION')   {
   
    return {...state, status: false}
} else if(action.type.type === 'SOCKETNOTIFICATION'){
    
    return {...state, status: true}
}else {
    
    return state
}
  
}

export default NotificationStatusReducer;