import React from "react";
import { Switch } from "react-router-dom";
import NotFound from "../../Components/NotFound/NotFound";
import ApprovalCardPage from "../../Components/Pages/AprrovalCenter/ApprovalCardPage";
import { Approval, ApprovalCardd, ApprovalDetaill } from "../constant";
import { ApmRoute } from "@elastic/apm-rum-react";

function ApprovalRoutes(props) {
  return (
    <div>
      <Switch>
        <ApmRoute
          path={`${Approval}${ApprovalCardd}`}
          component={ApprovalCardPage}
        />
        <ApmRoute component={NotFound} />
      </Switch>
    </div>
  );
}

export default ApprovalRoutes;
