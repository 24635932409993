import React from "react";
import "./EthicsHelpLine.css";
import { Card, CardContent, Grid } from "@material-ui/core";
import blackIcon from "../../../Assets/Images/blackIcon.svg";

export default function EthicsWhatsNewCard(EthicsCardData) {
  return (
    <div>
      <Card
        variant="outlined"
        className={
          EthicsCardData.EthicsCardData.title === "Speak up" ||
          EthicsCardData.EthicsCardData.title === "MLexoasis"
            ? "EthicsCardE"
            : "EthicsCard"
        }
      >
        <CardContent className="EthicsCardContent">
          {/* {Object.keys(EthicsCardData.EthicsCardData).map((item, index) => ( */}
          <div>
            <div className="parent">
              <img
                src={blackIcon}
                alt="Download line"
                className="EthicsImg ImgP Img1"
              />{" "}
              <img
                src={EthicsCardData.EthicsCardData.icon}
                alt="Download line"
                className="EthicsImg EthicsImgSize ImgP Img2"
              />
            </div>

            <div>
              <Grid container direction="column">
                <div className="EthicsTitle">
                  {EthicsCardData.EthicsCardData.title}
                </div>
                {EthicsCardData.EthicsCardData.address
                  ? EthicsCardData.EthicsCardData.address.map((item, index) => (
                      <div key={index}>
                        <div className="EthicsDataAddress">
                          {item.location}
                        </div>
                        <div className="EthicsDataContact">{item.extension && item.extension+"-"}{item.contact}</div>
                        <div className="EthicsDataContact">
                          <a href={"mailto:"+EthicsCardData.EthicsCardData.email[index]}>{EthicsCardData.EthicsCardData.email[index]}</a>
                        </div>
                        <div className="EthicsDataContact">
                          <a href={EthicsCardData.EthicsCardData.website[index]}>{EthicsCardData.EthicsCardData.website[index]}</a>
                        </div>
                      </div>
                    ))
                  : null}
                {EthicsCardData.EthicsCardData.description ? (
                  <div className="EthicsData mt-4">
                    {EthicsCardData.EthicsCardData.description}
                  </div>
                ) : null}
                {EthicsCardData.EthicsCardData.actionName ? (
                  <div className="EthicsDataContact mt-4">
                    <a href={EthicsCardData.EthicsCardData.actionUri}>{EthicsCardData.EthicsCardData.actionName}</a>
                  </div>
                ) : null}
                {EthicsCardData.EthicsCardData.title === "MLexoasis" ? (
                  <>
                    <div className="EthicsData">
                      <div>The Group Legal employee services :</div>
                      <div>
                        1.<strong>M-eVakil</strong> : Pro bono employee outreach
                        initiative and advisory services to help M&M employees
                        understand and manage common legal issues
                      </div>
                      <div>
                        2.<strong>Jigyasa </strong>: legal knowledge management3
                      </div>
                      <div>3. Training sessions & learning modules</div>
                      <div>
                        4. Lawyers directory & legal resources for employees
                      </div>
                    </div>
                  </>
                ) : (
                  <> </>
                )}
                {EthicsCardData.EthicsCardData.title === "Speak up" ? (
                  <>
                    <div className="helpNo">
                      <div className="noTitile">Helpline no. (India) :</div>
                      <div className="noDataEthics">000 800 100 4175</div>
                    </div>
                    <div className="helpEmailId">
                      <div className="EmailTitile">Email id :</div>
                      <div className="EmailData">ethics@mahindra.com</div>
                    </div>
                  </>
                ) : (
                  <> </>
                )}
                {EthicsCardData.EthicsCardData.remote === "remote" ? (
                  <>
                    <div className="helpNo">
                      <div className="noTitile">
                        {EthicsCardData.EthicsCardData.domestic}
                      </div>
                      <div className="noDataEthics">
                        {EthicsCardData.EthicsCardData.domesticsno}
                      </div>
                    </div>
                    <div className="helpEmailId">
                      <div className="EmailTitile">
                        {EthicsCardData.EthicsCardData.International}
                      </div>
                      <div className="EmailData">
                        {EthicsCardData.EthicsCardData.Internationalno}
                      </div>
                      <div className="EmailData">
                        {EthicsCardData.EthicsCardData.email}
                      </div>
                      <div className="EmailData">
                        {EthicsCardData.EthicsCardData.website}
                      </div>
                    </div>
                  </>
                ) : (
                  <> </>
                )}
                {EthicsCardData.EthicsCardData.id === "infra" ? (
                  <>
                    <div className="helpNo">
                      <div className="noTitile">
                        {EthicsCardData.EthicsCardData.name1}
                      </div>
                      <div className="noDataEthics">
                        {EthicsCardData.EthicsCardData.name1Value}
                      </div>
                    </div>
                    <div className="helpEmailId">
                      <div className="EmailTitile">
                        {EthicsCardData.EthicsCardData.name2}
                      </div>
                      <div className="EmailData">
                        {EthicsCardData.EthicsCardData.name2Value}
                      </div>
                      <div className="EmailData">
                        {EthicsCardData.EthicsCardData.name3Value}
                      </div>
                      <div className="EmailData">
                        {EthicsCardData.EthicsCardData.name4Value}
                      </div>
                    </div>
                    {EthicsCardData.EthicsCardData.title ===
                    "Group security" ? (
                      <>
                        <div className="cardEthicsFooter">
                          {EthicsCardData.EthicsCardData.footer2}
                        </div>
                        <div className="cardEthicsFooter">
                          {EthicsCardData.EthicsCardData.footer3}
                        </div>
                      </>
                    ) : (
                      <> </>
                    )}
                  </>
                ) : (
                  <> </>
                )}
                {EthicsCardData.EthicsCardData.IT === "IT" ? (
                  <>
                    <div className="helpNo">
                      <div className="noDataEthics">000 800 100 4175</div>
                    </div>
                  </>
                ) : (
                  <> </>
                )}
                <div className="cardEthicsFooterf">
                  {EthicsCardData.EthicsCardData.link ? (
                    <a
                      href={EthicsCardData.EthicsCardData.link}
                      target="_blank"
                      className="cardEthicsFooterf"
                    >
                      {EthicsCardData.EthicsCardData.footer}
                    </a>
                  ) : (
                    EthicsCardData.EthicsCardData.footer
                  )}
                </div>
              </Grid>
            </div>
          </div>
          {/* ))} */}
        </CardContent>
      </Card>
    </div>
  );
}
