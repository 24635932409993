import React, { useEffect, useState } from "react";
import { Grid, Paper, Tabs, Tab, Typography, Box } from "@material-ui/core";
import PropTypes from "prop-types";
import MahindraCollectiveCard from "../MahindraCollective/MahindraCollectiveCard/MahindraCollectiveCard";
import CommonModal from "../../Common/CommonModal/CommonModal";
import SearchBarButton from "../../Common/SearchBar/SearchBarButton";
import Template from "../../Common/Template/Template";
import { LIVEWIRE, UPLOAD_A_STORY } from "./../../../Routes/constant";
import "./MahindraCollective.css";
import settingIcon from "../../../Assets/Images/settings_5_line.svg";
import filterIcon from "../../../Assets/Images/filter-line.svg";
import AdminModalComponent from "./Admin/AdminModalComponent";
import services from "../../../Services";
import PageTutorialComponent from "../../Common/PageTutorial/PageTutorialComponent";
import Sharefiles from "../../Common/Shareoption/Sharefiles";
import {
  isAccesible,
  offsetCount,
} from "../../../Assets/helperFunctions/helperFunctions";
import Swal from "sweetalert2";
import Loader from "../../Common/Loader/Loader";
import SubmitButton from "../../Common/Button/SubmitButton";
import { useSelector, useDispatch } from "react-redux";
import {
  getMahindraCollectivePendingData,
  getMahindraCollectiveList,
  MC_RECORDS_OFFSET,
} from "../../../Redux/Actions/MahindraCollectiveAction";
import { deleteArticle } from "../../../Redux/Actions/CommonActions";
import { useHistory } from "react-router-dom";
import MahindraCollectiveFilters from "./MahindraCollectiveFilters/MahindraCollectiveFilters";
import {
  addAnalayticsLog,
  addInterval,
} from "../../../Redux/Actions/Anaylatics";
import { Stack } from "@mui/material";
import { Pagination } from "@material-ui/lab";
import platform from "platform";

// mobile responsive tabs
function TabPanel(props) {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const MaindraCollective = (props) => {
  const [value, setValue] = React.useState(0);

  // const [currentTab, setCurrentTab] = useState('');

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const [loading, setLoading] = useState(false);

  const [placeholder] = React.useState(
    "Search people, tags, sectors or a story name"
  );
  const [statusBol] = React.useState(true);
  //filters hide show in mobile
  const [display, setDisplay] = React.useState(false);

  const mahindraCollectiveApprovedData = useSelector(
    (state) => state.getMahindraCollectivePendingReducer
  );

  const { getData: getApprovedData } = mahindraCollectiveApprovedData;

  /* ============= Code for implementation of New API ========= */
  let totalRecords = useSelector((state) => {
    // console.log(state);
    return state?.getMahindraCollectiveListReducer?.getTotalCount;
  });
  let getCollectiveRecords = useSelector((state) => {
    // console.log(state);
    return state?.getMahindraCollectiveListReducer?.getData;
  });
  // const getApprovedData = [];
  const dispatch = useDispatch();

  const [btnBool1, setbtnBool1] = React.useState(true);
  const [btnBool2, setbtnBool2] = React.useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [openFilterModal, setOpenFilterModal] = useState(false);
  const [getList, setGetList] = useState([]);
  const [highLightTab, setHighLightTab] = useState(1);
  const [selectedSortBy, setSelectedSortBy] = useState("");
  const [calYearValue, setCalYearValue] = useState("");
  const [monthValue, setMonthValue] = useState([]);
  const [getDivisionList, setGetDivisionList] = useState([]);
  const [sectorValue, setSectorValue] = useState([]);
  const [buValue, setbuValue] = useState([]);
  const [manageDataLength, setManageDataLength] = useState("");
  const [scheduleData, setScheduleData] = useState([]);
  const [filterdList, setFilterdList] = useState(getApprovedData);
  const [searchText, setSearchText] = useState("");
  const [pageNo, setPageNo] = useState(0);
  const [sortOrderType, setSortOrderType] = useState("DESC");
  const [storyType, setStoryType] = useState("");
  const [monthsArray, setMonthsArray] = useState([]);
  const [selectedBU, setSelectedBU] = useState([]);
  const [selectedSectors, setSelectedSectors] = useState([]);
  //Added for pagination
  const [totalPages, setTotalPages] = useState(1);

  const [callAPI, setCallAPI] = useState(false);

  const history = useHistory();

  useEffect(() => {
    setPageNo(0);
  }, [
    storyType,
    selectedSectors,
    selectedBU,
    calYearValue,
    monthValue,
    selectedSortBy,
  ]);

  useEffect(() => {
    if (selectedSectors && selectedSectors.length > 0) {
      setLoading(true);
      getAllRecords();
    }
  }, [
    pageNo,
    storyType,
    selectedSectors,
    selectedBU,
    calYearValue,
    monthValue,
    selectedSortBy,
  ]);

  useEffect(() => {
    if (callAPI) {
      setPageNo(0);
      setLoading(true);
      getAllRecords();
      setCallAPI(false);
    }
  }, [callAPI]);

  const handleSearch = (e) => {
    setSearchText(e.target.value);
  };

  function handleDelete(id) {
    try {
      let list = [];
      dispatch(deleteArticle("mahindraCollective", id));
      console.log("In delete function ======> ", id);
      let index = getList.findIndex((val) => val.id == id);
      console.log("index =====>", index);
      if (index !== -1) {
        list = getList.splice(index, 1);
      }
      console.log("list after deletion ====>", getList);
      setGetList([...getList]);
    } catch (error) {
      console.log("In delete function, got error");
    }
  }

  const onEditMahindraCollective = () => {
    setOpenModal(true);
  };
  const onFilterMahindraCollective = () => {
    setOpenFilterModal(true);
  };

  const handleCloseModal = () => {
    setOpenModal(false);
    setOpenFilterModal(false);
  };

  const handleSortByChange = (e, orderType) => {
    setSelectedSortBy(orderType);
    setSortOrderType(orderType);
  };

  const handleCalYearChange = (e, year) => {
    setCalYearValue(year);
    if (monthValue.value !== year) {
      setMonthValue([]);
    }
  };

  const getAllRecords = async () => {
    try {
      setLoading(true);
      dispatch(
        getMahindraCollectiveList(
          pageNo,
          storyType.toLowerCase(),
          selectedSectors,
          selectedBU,
          calYearValue.value,
          monthsArray,
          searchText,
          sortOrderType.value
        )
      );
    } catch (error) {
      setLoading(false);
      console.log("Error ===> ", error);
      Swal.fire("Something went wrong!", `${error?.response?.data}`, "error");
    }
  };

  const getMahindraCollective = async () => {
    try {
      setLoading(true);
      const res = await services.api(
        "GET",
        `/ui-assist/v1/mahindracollective?employeeId=${localStorage.getItem(
          "token"
        )}`
      );
      if (res.status === 200) {
        setLoading(false);
        setGetList(res.data);
      }
    } catch (error) {
      setLoading(false);
      Swal.fire("Something went wrong!", `${error?.response?.data}`, "error");
    }
  };

  const handlePageChange = (e, val) => {
    setPageNo(val - 1);
  };

  const handleMonthChange = async (_, selectType) => {
    setMonthValue(selectType);
    let year = calYearValue.value;
    let months = selectType.map((result) => {
      let getMonths = [];
      getMonths.push(result.value);
      return getMonths;
    });

    if (months.length > 0) {
      setMonthsArray(months);
    }
  };

  const handleBusinessChange = async (newValue, name) => {
    setbuValue(newValue);

    // let getBU = [];
    let selBU = newValue.map((result) => {
      // getBU.push(result.value);
      return result.value;
    });
    setSelectedBU(selBU);
  };

  const getScheduleData = async () => {
    try {
      const res = await services.api(
        "GET",
        `/ui-assist/v1/${
          false ? "offerorcampaign" : "mahindracollective"
        }/schedule`
      );
      if (res.status === 200) {
        setScheduleData(res.data);
      }
    } catch (error) {
      Swal.fire("Something went wrong!", `${error?.response?.data}`, "error");
    }
  };

  useEffect(() => {
    dispatch(
      addAnalayticsLog({
        module: "Livewire/mahindra-collective",

        browserDetails: `${platform.name} ${platform.os.architecture} ${platform.os.family} ${platform.os.version}`,

        deviceDetails: platform.product ? platform.product : "Desktop",

        isSearch: false,
      })
    );
    // you know what is this, don't you?
  }, []);

  //timer log
  useEffect(() => {
    const interval = setInterval(() => {
      dispatch(addInterval(localStorage.getItem("logID")));
    }, 60000);
    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    setLoading(false);
  }, [getCollectiveRecords]);

  useEffect(() => {
    if (pageNo == 0) {
      if (totalRecords) {
        setTotalPages(offsetCount(totalRecords, MC_RECORDS_OFFSET));
      } else {
        setTotalPages(1);
      }
    }
  }, [totalRecords]);

  useEffect(() => {
    setManageDataLength(
      (scope &&
      filterdList?.filter((item) =>
        scopeDetails?.moduleAssigneds
          ?.map(
            (moduleItem) =>
              moduleItem?.module === "MAHINDRA_COLLECTIVE" &&
              moduleItem?.sectorScope
          )
          ?.flat()
          ?.some((r) => item?.sector.indexOf(r) >= 0)
      )?.length
        ? filterdList?.filter((item) =>
            scopeDetails?.moduleAssigneds
              ?.map(
                (moduleItem) =>
                  moduleItem?.module === "MAHINDRA_COLLECTIVE" &&
                  moduleItem?.sectorScope
              )
              ?.flat()
              ?.some((r) => item?.sector.indexOf(r) >= 0)
          )?.length
        : filterdList?.length) +
        (scope &&
        scheduleData?.filter((item) =>
          scopeDetails?.moduleAssigneds
            ?.map(
              (moduleItem) =>
                moduleItem?.module === "MAHINDRA_COLLECTIVE" &&
                moduleItem?.sectorScope
            )
            ?.flat()
            ?.some((r) => item?.sector.indexOf(r) >= 0)
        )?.length
          ? scheduleData?.filter((item) =>
              scopeDetails?.moduleAssigneds
                ?.map(
                  (moduleItem) =>
                    moduleItem?.module === "MAHINDRA_COLLECTIVE" &&
                    moduleItem?.sectorScope
                )
                ?.flat()
                ?.some((r) => item?.sector.indexOf(r) >= 0)
            )?.length
          : scheduleData?.length)
    );
  }, [filterdList, scheduleData]);

  useEffect(() => {
    setFilterdList(getApprovedData);
  }, [mahindraCollectiveApprovedData]);

  useEffect(() => {
    dispatch(getMahindraCollectivePendingData(true));
    getScheduleData();
  }, []);

  const handleSector = async (sectorType) => {
    console.log(sectorType, "sector type....");

    setPageNo(0);
    if (sectorType) {
      setStoryType(sectorType.toLowerCase().trim());
    } else {
      setStoryType("");
    }

    if (sectorType === "Rise story") {
      dispatch(
        addAnalayticsLog({
          module: "Livewire/Mahindra collective/Rise stories",

          browserDetails: `${platform.name} ${platform.os.architecture} ${platform.os.family} ${platform.os.version}`,

          deviceDetails: platform.product ? platform.product : "Desktop",

          isSearch: false,
        })
      );

      setHighLightTab(2);
    } else if (sectorType === "Diversity") {
      dispatch(
        addAnalayticsLog({
          module: "Livewire/Mahindra collective/Divercity & inclusion",

          browserDetails: `${platform.name} ${platform.os.architecture} ${platform.os.family} ${platform.os.version}`,

          deviceDetails: platform.product ? platform.product : "Desktop",

          isSearch: false,
        })
      );
      setHighLightTab(3);
    } else if (sectorType === "Customer") {
      dispatch(
        addAnalayticsLog({
          module: "Livewire/Mahindra collective/Customer experience",

          browserDetails: `${platform.name} ${platform.os.architecture} ${platform.os.family} ${platform.os.version}`,

          deviceDetails: platform.product ? platform.product : "Desktop",

          isSearch: false,
        })
      );
      setHighLightTab(4);
    } else if (sectorType === "constributions") {
      setHighLightTab(5);
    } else if (sectorType === "mycontribution") {
      dispatch(
        addAnalayticsLog({
          module: "Livewire/Mahindra collective/My Contributions",

          browserDetails: `${platform.name} ${platform.os.architecture} ${platform.os.family} ${platform.os.version}`,

          deviceDetails: platform.product ? platform.product : "Desktop",

          isSearch: false,
        })
      );
    }
    if (sectorType) {
      if (sectorType === "constributions") {
        try {
          setLoading(true);

          let res = await services.api(
            "GET",
            `/ui-assist/v1/mahindracollective/mycontribution?employeeId=${localStorage.getItem(
              "token"
            )}`
          );
          if (res.status == 200) {
            setLoading(false);
            if (res.data) {
              setGetList(res.data);
            } else {
              setGetList([]);
            }
          }
        } catch (error) {
          setLoading(false);
          setGetList([]);
          Swal.fire("Something went wrong!", `${error.response.data}`, "error");
        }
      }
      //  else {
      //   try {
      //     setLoading(true);
      //     let res = await services.api(
      //       "GET",
      //       `/ui-assist/v1/mahindracollective/category/${sectorType.toLowerCase()}?employeeId=${localStorage.getItem(
      //         "token"
      //       )}`
      //     );
      //     if (res.status == 200) {
      //       setLoading(false);
      //       if (res.data) {
      //         setGetList(res.data);
      //       } else {
      //         setGetList([]);
      //       }
      //     }
      //   } catch (error) {
      //     setLoading(false);
      //     setGetList([]);
      //     Swal.fire(
      //       "Something went wrong!",
      //       `${error?.response?.data}`,
      //       "error"
      //     );
      //   }
      // }
    } else {
      setHighLightTab(1);
      try {
        setLoading(true);
        const res = await services.api(
          "GET",
          `/ui-assist/v1/mahindracollective?employeeId=${localStorage.getItem(
            "token"
          )}`
        );
        if (res) {
          setLoading(false);
          setGetList(res.data);
        }
      } catch (error) {
        setLoading(false);
        Swal.fire("Something went wrong!", `${error.response.data}`, "error");
      }
    }
  };

  const handleAPI = () => {
    setCallAPI(true);
  };

  const onKeyPress = (e) => {
    if (e.key === "Enter") {
      setCallAPI(true);
    }
  };

  // const filterdList =
  //   selectedSortBy.value === "Sort by latest"
  //     ? sortingFunction(getList, "requestedDate")
  //     : selectedSortBy.value === "Sort by old"
  //     ? sortingFunctionDescending(getList, "requestedDate")
  //     : getList;

  // const getManageDataNo = (length) => {
  //   setManageDataLength(length);
  // };
  const scope =
    JSON.parse(localStorage.getItem("scopeDetails"))?.admin === true &&
    JSON.parse(localStorage.getItem("scopeDetails")).adminType === "OTHERS";

  const scopeDetails = JSON.parse(localStorage.getItem("scopeDetails"));

  const isMldl =
    JSON.parse(localStorage.getItem("userProfile")).sectorName ===
      "Real Estate Sector" &&
    JSON.parse(localStorage.getItem("userProfile")).sector === "52304758";

  return (
    <Template pageName={"Mahindra Collective"}>
      {loading ? <Loader /> : null}
      <div>
        <Grid
          container
          direction="row"
          justifyContent="space-between"
          alignItems="flex-end"
        >
          <div className="mshow pagetuttop">
            {!isMldl && (
              <PageTutorialComponent
                modelHeadtxt={"Today Page Tutorial"}
                videoLink={
                  "https://mazpmodsa01.blob.core.windows.net/mrisestorage/Core%20Intranet%20V3.mp4_1706088702435.mp4"
                }
              />
            )}
          </div>
          <Grid item md={7} xs={12}>
            <div className="whatsNewHeader">
              <div className="containerTxt">
                <div className="section-header-text"> Mahindra Collective </div>
                <div className="mhide">
                  {!isMldl && (
                    <PageTutorialComponent
                      modelHeadtxt={"Livewire Tutorial"}
                      videoLink={
                        "https://mazpmodsa01.blob.core.windows.net/mrisestorage/Core%20Intranet%20V3.mp4_1706088702435.mp4"
                      }
                    />
                  )}
                </div>
                {isAccesible("MAHINDRA_COLLECTIVE") && (
                  <div className="editIcon" onClick={onEditMahindraCollective}>
                    <img
                      src={process.env.PUBLIC_URL + "/iconImages/MC_admin.svg"}
                      alt=""
                      className="edit-line"
                    />
                    <div>Manage {`(${manageDataLength})`}</div>
                  </div>
                )}
              </div>
            </div>
          </Grid>
          <Grid item md={5} xs={12}>
            <SearchBarButton
              placeholder={placeholder}
              searchText={searchText}
              handleSearch={handleSearch}
              handleSearchIcon={handleAPI}
              onKeyPress={onKeyPress}
              name="search"
            />
          </Grid>
        </Grid>
      </div>
      <div className="MCMobileFilter">
        <Grid container direction="row" justifyContent="space-between">
          <Grid item onClick={onFilterMahindraCollective}>
            <div className="MCFilter" xs={6}>
              <img
                src={process.env.PUBLIC_URL + "/iconImages/filter-line.svg"}
                alt="filter icon"
              />
              Filter
            </div>
          </Grid>
          <Grid item xs={5}>
            <SubmitButton
              label="Upload a story"
              className="MCsubmitbutton floatRightAlign"
              onClick={() => history.push(`${LIVEWIRE}/upload-story`)}
            />
          </Grid>
        </Grid>
      </div>
      <Paper className="MClandingpageAlign mt-3 mb-0 mFilter Standardfilter">
        <div className="liveWireFilters">
          <div className="responsiveMobFilter">
            <div
              className="MCFilter"
              xs={6}
              onClick={() => setDisplay(!display)}
            >
              <img
                src={process.env.PUBLIC_URL + "/iconImages/filter-line.svg"}
                alt="filter icon"
              />
              Filter
            </div>
            <SubmitButton
              label="Upload a story"
              className="MCsubmitbutton floatRightAlign"
              onClick={() => history.push(`${LIVEWIRE}/upload-story`)}
            />
          </div>
          <div
            className={`${display ? "responsiveShowMC" : "responsiveHideMC"}`}
          >
            <MahindraCollectiveFilters
              showSubmit={true}
              isShortBy={true}
              isBussiness={true}
              handleSortByChange={handleSortByChange}
              handleCalYearChange={handleCalYearChange}
              handleMonthChange={handleMonthChange}
              selectedSortBy={selectedSortBy}
              path={`${LIVEWIRE}/upload-story`}
              calYearValue={calYearValue}
              monthValue={monthValue}
              handleBusinessChange={handleBusinessChange}
              sectorValue={sectorValue}
              getDivisionList={getDivisionList}
              buValue={buValue}
              setSectors={setSelectedSectors}
              getAllRecords={getAllRecords}
              setLoading={setLoading}
              // options={getSectorList?.length && getSectorList}
            />
          </div>
        </div>
      </Paper>

      <Paper className="MClandingpageAlign mt-0 pt-0 standardtabcontainer">
        {/* <div className="liveWireFilters">
          <LiveWireFilters
            showSubmit={true}
            isShortBy={true}
            isBussiness={true}
            handleSortByChange={handleSortByChange}
            handleCalYearChange={handleCalYearChange}
            handleMonthChange={handleMonthChange}
            selectedSortBy={selectedSortBy}
            path={`${LIVEWIRE}${UPLOAD_A_STORY}`}
            calYearValue={calYearValue}
            monthValue={monthValue}
            handleBusinessChange={handleBusinessChange}
            sectorValue={sectorValue}
            getDivisionList={getDivisionList}
            buValue={buValue}
            // options={getSectorList?.length && getSectorList}
          />
        </div> */}
        <div className="responsiveTabs">
          <Box sx={{ width: "100%" }}>
            <Box
              sx={{ borderBottom: 1, borderColor: "divider" }}
              className="boxAlignPadMC standardlefttab"
            >
              <Tabs
                value={value}
                onChange={handleChange}
                variant="scrollable"
                scrollButtons={false}
                aria-label="basic tabs example"
                className="mahindraCollectiveTabsDM "
              >
                <Tab
                  label="All articles"
                  {...a11yProps(0)}
                  onClick={() => handleSector()}
                />
                <Tab
                  label="LivingRiseReels (Rise Stories)"
                  {...a11yProps(1)}
                  onClick={() => handleSector("Rise story")}
                />
                <Tab
                  label="I’m in. (Diversity & Inclusion)"
                  {...a11yProps(2)}
                  onClick={() => handleSector("Diversity")}
                />
                <Tab
                  label="Customer experience"
                  {...a11yProps(3)}
                  onClick={() => handleSector("Customer")}
                />
                <Tab
                  label="My contributions"
                  {...a11yProps(4)}
                  onClick={() => handleSector("mycontribution")}
                />
              </Tabs>
            </Box>

            <div className="alignMCCOntentTabPanel standardrighttab">
              <TabPanel value={value} index={0}>
                <Grid
                  container
                  direction="row"
                  justifyContent="flex-start"
                  alignItems="center"
                  spacing={2}
                  className="alignCards"
                >
                  {getCollectiveRecords.length ? (
                    getCollectiveRecords.map((result, index) => {
                      return (
                        <Grid
                          item
                          className="padding12"
                          sm={index >= 0 && index <= 1 ? 6 : 6}
                          md={index >= 0 && index <= 1 ? 6 : 6}
                          lg={index >= 0 && index <= 1 ? 4 : 4}
                          xs={index >= 0 && index <= 1 ? 12 : 12}
                          key={index}
                        >
                          <MahindraCollectiveCard
                            currentTab={"allarticle"}
                            allList={getList}
                            list={result}
                            footerIcon={true}
                            handleDelete={handleDelete}
                            searchText={searchText}
                          />
                        </Grid>
                      );
                    })
                  ) : (
                    <span
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        height: "100vh",
                        width: "100%",
                        color: "#e31837",
                      }}
                    >
                      No Data Available
                    </span>
                  )}
                </Grid>
              </TabPanel>
              <TabPanel value={value} index={1}>
                <Grid
                  container
                  direction="row"
                  justifyContent="flex-start"
                  alignItems="center"
                  spacing={2}
                  className="alignCards"
                >
                  {getCollectiveRecords.length ? (
                    getCollectiveRecords.map((result, index) => {
                      return (
                        <Grid
                          item
                          className="padding12"
                          sm={index >= 0 && index <= 1 ? 6 : 6}
                          md={index >= 0 && index <= 1 ? 6 : 6}
                          lg={index >= 0 && index <= 1 ? 4 : 4}
                          xs={index >= 0 && index <= 1 ? 12 : 12}
                        >
                          <MahindraCollectiveCard
                            currentTab={"risestory"}
                            list={result}
                            footerIcon={true}
                            handleDelete={handleDelete}
                            searchText={searchText}
                          />
                        </Grid>
                      );
                    })
                  ) : (
                    <span
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        height: "100vh",
                        width: "100%",
                        color: "#e31837",
                      }}
                    >
                      No Data Available
                    </span>
                  )}
                </Grid>
              </TabPanel>
              <TabPanel value={value} index={2}>
                <Grid
                  container
                  direction="row"
                  justifyContent="flex-start"
                  alignItems="center"
                  spacing={2}
                  className="alignCards"
                >
                  {getCollectiveRecords.length ? (
                    getCollectiveRecords.map((result, index) => {
                      return (
                        <Grid
                          item
                          className="padding12"
                          sm={index >= 0 && index <= 1 ? 6 : 6}
                          md={index >= 0 && index <= 1 ? 6 : 6}
                          lg={index >= 0 && index <= 1 ? 4 : 4}
                          xs={index >= 0 && index <= 1 ? 12 : 12}
                        >
                          <MahindraCollectiveCard
                            currentTab={"diversity&inclusion"}
                            list={result}
                            footerIcon={true}
                            handleDelete={handleDelete}
                            searchText={searchText}
                          />
                        </Grid>
                      );
                    })
                  ) : (
                    <span
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        height: "100vh",
                        width: "100%",
                        color: "#e31837",
                      }}
                    >
                      No Data Available
                    </span>
                  )}
                </Grid>
              </TabPanel>
              <TabPanel value={value} index={3}>
                <Grid
                  container
                  direction="row"
                  justifyContent="flex-start"
                  alignItems="center"
                  spacing={2}
                  className="alignCards"
                >
                  {getCollectiveRecords.length ? (
                    getCollectiveRecords.map((result, index) => {
                      return (
                        <Grid
                          item
                          className="padding12"
                          sm={index >= 0 && index <= 1 ? 6 : 6}
                          md={index >= 0 && index <= 1 ? 6 : 6}
                          lg={index >= 0 && index <= 1 ? 4 : 4}
                          xs={index >= 0 && index <= 1 ? 12 : 12}
                        >
                          <MahindraCollectiveCard
                            currentTab={"customer"}
                            list={result}
                            footerIcon={true}
                            handleDelete={handleDelete}
                            searchText={searchText}
                          />
                        </Grid>
                      );
                    })
                  ) : (
                    <span
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        height: "100vh",
                        width: "100%",
                        color: "#e31837",
                      }}
                    >
                      No Data Available
                    </span>
                  )}
                </Grid>
              </TabPanel>
              <TabPanel value={value} index={4}>
                <Grid
                  container
                  direction="row"
                  justifyContent="flex-start"
                  alignItems="center"
                  spacing={2}
                  className="alignCards mycontributionblock"
                >
                  {getCollectiveRecords.length ? (
                    getCollectiveRecords.map((result, index) => {
                      return (
                        <Grid
                          item
                          className="padding12"
                          sm={index >= 0 && index <= 1 ? 6 : 6}
                          md={index >= 0 && index <= 1 ? 6 : 6}
                          lg={index >= 0 && index <= 1 ? 4 : 4}
                          xs={index >= 0 && index <= 1 ? 12 : 12}
                        >
                          <MahindraCollectiveCard
                            currentTab={"mycontribution"}
                            list={result}
                            footerIcon={true}
                            handleDelete={handleDelete}
                            searchText={searchText}
                          />
                        </Grid>
                      );
                    })
                  ) : (
                    <span
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        height: "100vh",
                        width: "100%",
                        color: "#e31837",
                      }}
                    >
                      No Data Available
                    </span>
                  )}
                </Grid>
              </TabPanel>
            </div>
          </Box>
        </div>
      </Paper>
      <div style={{ margin: "2rem 0", float: "right" }}>
        <div className="paginationAlignFlex">
          <Stack spacing={2}>
            <Pagination
              count={totalPages ? totalPages : 1}
              page={pageNo + 1}
              variant="outlined"
              shape="rounded"
              onChange={handlePageChange}
            />
          </Stack>
        </div>
      </div>
      <CommonModal
        open={openModal}
        handleClose={handleCloseModal}
        headerText="Manage Mahindra collective"
        icon={settingIcon}
        modalSize="lg"
        submitBtnText="SAVE CHANGES"
        fullWidth
        policiesButtonSize
        onButtonClick={handleCloseModal}
      >
        <AdminModalComponent
          isOfferCampaigns={false}
          // getManageDataNo={getManageDataNo}
          // getApprovedData={getApprovedData}
          // mahindraCollectiveApprovedData={mahindraCollectiveApprovedData}
          filterdList={filterdList}
          scheduleData={scheduleData}
        />
      </CommonModal>
      <CommonModal
        open={openFilterModal}
        handleClose={handleCloseModal}
        headerText="Filter"
        icon={filterIcon}
        modalSize="lg"
        //submitBtnText="Done"
        onButtonClick={handleCloseModal}
        fullWidth
        footerBannerCss
      >
        <div className="MCFilters Standardfilter">
          <MahindraCollectiveFilters
            showSubmit={true}
            isShortBy={true}
            isBussiness={true}
            handleSortByChange={handleSortByChange}
            handleCalYearChange={handleCalYearChange}
            handleMonthChange={handleMonthChange}
            selectedSortBy={selectedSortBy}
            path={`${LIVEWIRE}${UPLOAD_A_STORY}`}
            calYearValue={calYearValue}
            monthValue={monthValue}
            handleBusinessChange={handleBusinessChange}
            sectorValue={sectorValue}
            getDivisionList={getDivisionList}
            buValue={buValue}

            // options={getSectorList?.length && getSectorList}
          />
        </div>
      </CommonModal>
    </Template>
  );
};
export default MaindraCollective;
