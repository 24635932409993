import {
    GET_COMMENTS_DATA_SUCCESS,
    GET_COMMENTS_DATA_LOAD,
    GET_COMMENTS_DATA_FAILED,
    
  } from "../Contants/Constants";
  
  const initialState = {
    getData: [],
    isLoading: false,
    isError: "",
  };
  
  const getCommentDataReducer = (state = initialState, action) => {
    switch (action.type) {
      case GET_COMMENTS_DATA_LOAD:
        return {
          ...state,
          isLoading: true,
        };
      case GET_COMMENTS_DATA_SUCCESS:
        return {
          ...state,
          getData: action.payload,
          isLoading: false,
        };
      case GET_COMMENTS_DATA_FAILED:
        return {
          ...state,
          isError: action.payload,
          isLoading: false,
        };
      default:
        return state;
    }
  };
  
  export default getCommentDataReducer;
  