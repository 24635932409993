import React from "react";
import "./Login.css";
import loginIcon from "../../../../Assets/Images/login_icon.svg";

function AuthTopSection(props) {
  return (
    <>
      <div className="justiFyCenter loginlogo">
        <img src={loginIcon} alt="Login Page Logo" className="authPageIcon" />
      </div>
      <div className="justiFyCenter">
        <span className="loginTitleText justiFyCenter">
          Login to Mrise Portal
        </span>
      </div>
    </>
  );
}

export default AuthTopSection;
