import { combineReducers } from "redux";
import {
  getPriorityDataReducer,
  getFavouriteDataReducer,
} from "../Reducers/dashboard";
import getUserfeedbackReducer from "../Reducers/feedback";
import changeBoolean from "../Reducers/MicrofeedbackR";
import changeBooleanBanner from "../Reducers/EditBanner";
import NotificationStatusReducer from "./NotificatoinStatusReducer";
import changeBooleanCard from "../Reducers/FadeCardOnce";
import getNotificationsDataReducer from "../Reducers/notifications";
import getNotificationsFilterDataReducer from "../Reducers/NotificationSetting";
import getProfileDetailsData from "../Reducers/profileDetails";
import getProfileScopeData from "../Reducers/ProfileScopeDetails";
import getTodayToolTipReducer from "../Reducers/todayToolTip";
import getWhatsNewDataReducer from "../Reducers/whatsNew";
import getaddFavDataReducer from "../Reducers/addFav";
import getAddBloodDonorReducer from "../Reducers/addBloodDonor";
import getAddBloodRequestReducer from "../Reducers/addBloodRequest";
import getDisableDonorStatusReducer from "../Reducers/disableDonorStatus";
import getLocationReducer from "../Reducers/location";
import getInitialStatusReducer from "../Reducers/initialStatus";
import getSendMailReducer from "../Reducers/sendMail";
import getWallpaperRequestApprovalReducer from "../Reducers/wallpaperRequestApproval";
import getWallpaperRequestReducer from "../Reducers/wallpaperRequest";
import getApproveWallpaperRequestReducer from "../Reducers/approveWallpaperRequest";
import getRejectWallpaperRequestReducer from "../Reducers/rejectWallpaperRequest";
import getUserWallpaperStatusReducer from "../Reducers/userWallpaperStatus";
import getApprovedWallpaperListReducer from "../Reducers/approvedWallpaperList";
import getInitialWallpaperInfoReducer from "./initialWallpaperInfo";
import getAllEmergencyContactReducer from "./allEmergencyContact";
import getAllContactLocationReducer from "./allContactLocation";
import getLocationBySectorReducer from "./locationListBySector";
import Tasks from "../Reducers/Tasks";
import getRemoteWorkingReducer from "./remoteWorking";
import getUploadImageReducer from "./uploadImage";
import {
  getPolicyCategoryDataReducer,
  getPolicySubCategoryDataReducer,
  getPolicyTableDataReducer,
  getPolicyAdminSubCategoryDataReducer,
} from "../Reducers/policyCategory";
import {
  getSOPCategoryDataReducer,
  getSOPSubCategoryDataReducer,
  getSOPTableDataReducer,
} from "../Reducers/Sops";
import {
  getMahindraCollectivePendingReducer,
  getMahindraCollectiveListReducer, //Added by Sagar
} from "../Reducers/mahindraCollective";
import {
  removeWhatsNewItemReducer,
  editWhatsNewItemReducer,
} from "./whatsNewAdmin";
import {
  getWelcomeDataReducer,
  getAllWelcomeDataReducer,
} from "../Reducers/welcome";
import {
  getOrgDataReducer,
  getOrgAdminDataReducer,
  getOrgCountReducer,
  getOrgDataBySectorReducer,
  getOrgDataByBusinessUnitsReducer,
  getOrgDataByFunctionReducer,
  getOrgViewsReducer,
} from "../Reducers/orgDetails";
import {
  getAllSectorsReducer,
  getAllBusinessUnitsReducer,
  getAllFunctionsReducer,
} from "./orgAnnouncements";
import getCommentDataReducer from "../Reducers/comment";
import {
  getLastModifiedDataReducer,
  likeDataReducer,
  getArticleDataReducer,
  deleteArticleReducer,
  getOfferDataReducer,
  shareDataReducer,
} from "../Reducers/CommonReducer";
import {
  getSectorsReducer,
  getBusinessUnitsReducer,
  getFunctionsReducer,
} from "../Reducers/MastersReducer";
import {
  getRollBasedSectorsReducer,
  getRollBasedBusinessUnitsReducer,
} from "../Reducers/MastersRollBasedReducer";
import { getExperienceListReducer } from "../Reducers/ExperienceReducer";
import {
  getPendingReducer,
  getExperienceArticleDataReducer,
  deleteExperienceArticleReducer,
  prevNextArticleReducer,
} from "../Reducers/ExperienceReducer";
import { getOfferCampaignListReducer } from "./OfferCampaignReducer";
import getUploadFileReducer from "./UploadFile";
import getUploadPDFReducer from "./UploadPDF";
import getPublishToWhatsNewReducer from "./PublishToWhatsNew";
import getEntitlementReducer from "./allEntitlement";
import getEntitlementBenefitReducer from "./EntitlementBenefitMaster";
import getAllDownTimeReducer from "./downTimeReducers";
import getAllPodcastReducer from "./podcast";
import { getLifepostTableDataReducer } from "./MLDLLifePost";
import getLocalStorageUpdationStatusReducer from "./addLocalStorageDataConfirmation";

export const rootReducer = combineReducers({
  getPriorityDataReducer,
  getNotificationsDataReducer,
  getNotificationsFilterDataReducer,
  getSOPCategoryDataReducer,
  getSOPSubCategoryDataReducer,
  getSOPTableDataReducer,
  getLifepostTableDataReducer,
  changeBoolean,
  changeBooleanCard,
  getProfileDetailsData,
  getProfileScopeData,
  getUserfeedbackReducer,
  getTodayToolTipReducer,
  getWelcomeDataReducer,
  getAllWelcomeDataReducer,
  getOrgDataReducer,
  getWhatsNewDataReducer,
  changeBooleanBanner,
  getaddFavDataReducer,
  getPolicyCategoryDataReducer,
  getPolicySubCategoryDataReducer,
  getPolicyAdminSubCategoryDataReducer,
  getPolicyTableDataReducer,
  getFavouriteDataReducer,
  getMahindraCollectivePendingReducer,
  getAllSectorsReducer,
  getAllBusinessUnitsReducer,
  getAllFunctionsReducer,
  getOrgDataBySectorReducer,
  getOrgDataByBusinessUnitsReducer,
  getOrgDataByFunctionReducer,
  getRemoteWorkingReducer,
  getAddBloodDonorReducer,
  getAddBloodRequestReducer,
  getDisableDonorStatusReducer,
  getLocationReducer,
  getInitialStatusReducer,
  getSendMailReducer,
  getOrgAdminDataReducer,
  getLastModifiedDataReducer,
  getWallpaperRequestApprovalReducer,
  getWallpaperRequestReducer,
  getUserWallpaperStatusReducer,
  getApproveWallpaperRequestReducer,
  getRejectWallpaperRequestReducer,
  likeDataReducer,
  getArticleDataReducer,
  getCommentDataReducer,
  getOrgCountReducer,
  getSectorsReducer,
  getBusinessUnitsReducer,
  getFunctionsReducer,
  getApprovedWallpaperListReducer,
  getInitialWallpaperInfoReducer,
  getOrgViewsReducer,
  deleteArticleReducer,
  NotificationStatusReducer,
  getAllEmergencyContactReducer,
  getUploadImageReducer,
  getMahindraCollectiveListReducer,
  getLocationBySectorReducer,
  getOfferDataReducer,
  getAllContactLocationReducer,
  editWhatsNewItemReducer,
  removeWhatsNewItemReducer,
  shareDataReducer,

  getExperienceListReducer,
  getPendingReducer,
  getExperienceArticleDataReducer,
  deleteExperienceArticleReducer,
  prevNextArticleReducer,
  getOfferCampaignListReducer,
  getRollBasedSectorsReducer,
  getRollBasedBusinessUnitsReducer,
  getUploadFileReducer,
  getUploadPDFReducer,
  getPublishToWhatsNewReducer,
  getEntitlementReducer,
  getEntitlementBenefitReducer,
  getAllDownTimeReducer,
  getAllPodcastReducer,
  getLocalStorageUpdationStatusReducer,
});
