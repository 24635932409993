import { DataGrid } from '@mui/x-data-grid';
import React from 'react'
import EditIcon from '@mui/icons-material/Edit';

function EditNewPoliciesTable() {

    const columns = [
        
        { field: 'id', headerName: 'Sr No.', width: 130, sortable: false},
        { field: 'category', headerName: 'Category Name', width: 500 },
        { field: 'subcategory', headerName: 'Sub Category', width: 400 , sortable: false, filtering: false },
        { field: 'action', headerName: 'Action', width: 150 , sortable: false},

        
        
      ];
      
      
      const rows = [
        {id: 1,  category: 'Snow', subcategory: 'Jon' , action: 'hi'},
        {id: 2,  category: 'Snow', subcategory: 'Jon', action: 'hi'},
        {id: 3,  category: 'Snow', subcategory: 'Jon' ,action: 'hi'},
        {id: 4,  category: 'Snow', subcategory: 'Jon' ,action: 'hi'},
        {id: 5,  category: 'Snow', subcategory: 'Jon' ,action: 'hi'},
        {id: 6,  category: 'Snow', subcategory: 'Jon' ,action: <EditIcon />},
        
      ];

      
    return (
        <div style={{ height: 400, width: '100%' }}>
      <DataGrid
        rows={rows}
        columns={columns}
        pageSize={5}
        rowsPerPageOptions={[5]}
        onRowClick={(e) => {console.log('asdfsdf', e)}}
        
      />
    </div>
    )
}

export default EditNewPoliciesTable
