import React, { useEffect, useState } from "react";
import Template from "../../Common/Template/Template";
import { useHistory } from "react-router-dom";
import {
  Grid,
  TextField,
  CircularProgress,
  FormControlLabel,
  Radio,
  Paper,

  // Icon,
} from "@material-ui/core";
import { WORK, ROLE_BASED, MANAGE_ACCESS } from "../../../Routes/constant";
import DatePicker from "../../Common/DatePicker/DatePicker";
import CustomMultiSelect from "../../Common/CustomMultiSelect/CustomMultiSelect";
import CommonLabel from "../../Common/ComonLabel/CommonLabel";
import SubmitButton from "../../Common/Button/SubmitButton";
import Swal from "sweetalert2";
import services from "../../../Services";
import CommonMultiAutocomplete from "../../Common/CommonMultiAutocomplete/CommonMultiAutocomplete";
import moment from "moment";
import "./CreateNewAdmin.css";
import CommonAutocomplete from "../../Common/CommonAutocomplete/CommonAutocomplete";
import { IconButton } from "@mui/material";
import RemoveIcon from "@mui/icons-material/Remove";
import AddIcon from "@mui/icons-material/Add";

//date range data
const dateRangeData = [
  {
    title: "Always",
    value: "always",
  },
  {
    title: "Date range",
    value: "date_range",
  },
  {
    title: "Based on start date",
    value: "based_on_start_date",
  },
];

export default function CreateNewAdmin() {
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  //SECTOR DTA
  const [sectorData, setSectorData] = useState([]);
  const [sectorListData, setSectorListData] = useState([]);
  const [selectedSectorOptions, setSelectedSectorOptions] = useState([]);

  //BU DATA
  const [businessUnitData, setBusinessUnitData] = useState([]);
  const [buisnessUnitListData, setBuisnessUnitListData] = useState([]);
  const [selectedBusinessUnitOptions, setSelectedBusinessUnitOptions] =
    useState(businessUnitData);
  //PA DATA.
  const [paData, setPaData] = useState([]);
  const [selectedPaOptions, setSelectedPaOptions] = useState(paData);
  const [paListData, setPaListData] = useState([]);

  const [dateType, setDateType] = useState("");
  const [selectedStatus, setSelectedStatus] = useState({});
  const [activeFrom, setActiveFrom] = useState(null);
  const [activeTo, setActiveTo] = useState(null);
  const [userID, setUserID] = useState("");
  const [adminTypeData, setAdminTypeData] = useState([
    
      {
          "value": "1",
          "title": "IT_ADMIN"
      },
      {
          "value": "2",
          "title": "CENTRAL_ADMIN"
      },
      {
          "value": "3",
          "title": "OTHERS"
      }
  
  ]);

  const [selectedAdminTypeData, setSelectedAdminTypeData] = useState([]);
  const [wallPaperSectorData, setWallPaperSectorData] = useState([]);
  const [selectedModulesConverted, setSelectedModulesConverted] = useState([]);
  const [moduleDropDownData, setModuleDropDownData] = useState([]);
  const [selectedModuleDropDownData, setSelectedModuleDropDownData] = useState(
    []
  );

  const [moduleData, setModuleData] = useState([
    {
      module: "",
      moduleDropdown: [],
      sectorScope: [],
      buScope: [],
      paScope: [],
      sectorDropdown: [],
      buDropdown: [],
      paDropdown: [],
    },
  ]);
const [selectedModules, setSelectedModules] = useState([]);


  
  //to get the sector belonging to wallpaper.
  // useEffect(() => {
  //   (async() =>{
  //     try {
  //       const res = await services.api('GET', `/ui-assist/v1/rollmatrix/adminrights/allsector/wallpaperadmin`)
  //       if(res.status === 200){
  //         let wallpaperSector = res?.data.map(el =>{
  //           return {
  //             title: el.sectorName,
  //             value: el.id
  //           }
  //         })
  //         setWallPaperSectorData(wallpaperSector)
  //       }
  //     } catch (error) {
  //       console.log(error)
  //     }
  //   })();
  // }, []);

  //to get the modules belonging to the user.
  useEffect(() => {
    (async () => {
      try {
        const res = await services.api(
          "POST",
          `/ui-assist/v1/rollmatrix/adminrights/modulesassigned`,
          selectedModules
        );
        if (res.status === 200) {
          setModuleDropDownData(res?.data);
        }
      } catch (error) {
        console.log(error);
      }
    })();
  }, [selectedModulesConverted, selectedModules]);

  //handle cancel...
  const hanldeCancel = (second) => {
    history.push(`${WORK}${ROLE_BASED}${MANAGE_ACCESS}`);
  };

  //to get selected sector list.
  useEffect(() => {
    let dataSectorList = selectedSectorOptions?.map((el) => {
      return el.value;
    });

    setSectorListData(dataSectorList);
  }, [selectedSectorOptions]);

  //to get selected bu list.
  useEffect(() => {
    let dataBuList = selectedBusinessUnitOptions?.map((el) => {
      return el.value;
    });
    setBuisnessUnitListData(dataBuList);
  }, [selectedBusinessUnitOptions]);

  //handle add fields....
  const handleAddFields = () => {
    setModuleData([
      ...moduleData,
      {
        module: "",
        moduleDropdown: [],
        sectorScope: [],
        buScope: [],
        paScope: [],
        sectorDropdown: [],
        buDropdown: [],
        paDropdown: [],
      },
    ]);
  };

  const handleRemoveFields = (index) => {
    if(index !== 0){
      const values = [...moduleData];
      values.splice(index, 1);
      setModuleData(values);
    }
    
  };

  //to get selected pa list.
  useEffect(() => {
    let datapaList = selectedPaOptions?.map((el) => {
      return el.value;
    });
    setPaListData(datapaList);
  }, [selectedPaOptions]);

  //to get all sectors for inclusion
  useEffect(() => {
    (async () => {
      try {
        setLoading(true);
        const res = await services.api(
          "GET",
          `/ui-assist/v1/rollmatrix/usergroup/allsector`
        );
        if (res.status === 200) {
          setLoading(false);
          const allSectorItem = res?.data?.map((el) => {
            return {
              title: el.sectorName,
              value: el.id,
            };
          });

          setSectorData(allSectorItem);
        }
      } catch (err) {
        setLoading(false);
        Swal.fire("Oops!", `${err.response.data}`, "error");
        setSectorData([]);
      }
    })();
  }, []);

  //get business unit on the sector params.
  const getBusinessUnitBySector = async (data) => {
    try {
      setLoading(true);
      let res = await services.api(
        "POST",
        `/ui-assist/v1/rollmatrix/usergroup/businessunit`,
        data
      );
      if (res.status === 200) {
        setLoading(false);
        let list = res.data.map((result) => {
          return {
            title: result.buName,
            value: result.id,
          };
        });

        setSelectedBusinessUnitOptions(list);
        setBusinessUnitData(list);
      }
    } catch (err) {
      setLoading(false);
      Swal.fire("Oops!", `${err.response.data}`, "error");
    }
  };

  //get pa by sector.
  const getPaBySector = async (data) => {
    try {
      setLoading(true);
      let res = await services.api(
        "POST",
        `/ui-assist/v1/rollmatrix/usergroup/pa`,
        data
      );
      if (res.status === 200) {
        setLoading(false);
        let list = res?.data?.map((result) => {
          return {
            title: result.persaText,
            value: result.persaId,
          };
        });

        let persaList = res?.data?.map((result) => {
          return {
            addpersa: result.persaId,
          };
        });

        setPaData(list);
        setSelectedPaOptions(list);
      }
    } catch (err) {
      setLoading(false);
      Swal.fire("Oops!", `${err.response.data}`, "error");
      setPaData([]);
    }
  };

  //ADD ACCESS RIGHTS.

  //handle date range......
  const handleDateRange = (e, selectType) => {
    if (selectType.value === "always") {
      setDateType("always");
    } else if (selectType.value === "date_range") {
      setDateType("date_range");
    } else if (selectType.value === "based_on_start_date") {
      setDateType("based_on_start_date");
    } else {
      setDateType("");
    }
  };

  const handleCreateAdminApi = async (data) => {
    try {
      setLoading(true);
      let res = await services.api(
        "POST",
        `/ui-assist/v1/rollmatrix/adminrights/${localStorage.getItem("token")}`,
        data
      );
      if (res.status === 200) {
        setLoading(false);

      
        Swal.fire("Success", `Data added Successfully`, "success");
        history.push(`${WORK}${ROLE_BASED}${MANAGE_ACCESS}`);
      }
    } catch (err) {
      setLoading(false);
      Swal.fire("Oops!", `${err.response.data}`, "error");
    }
  };

  //HANDLE TOKEN CHANGE
  const handleToken = async (e) => {
    setUserID(e.target.value);
    try {
      const res = await services.api(
        "GET",
        `/ui-assist/v1/rollmatrix/adminrights/admintypes/${e.target.value}`
      );
      setAdminTypeData(res?.data);
    } catch (error) {
      console.log(error, "error....");
    }
  };

  //DROPDWON METHODS.
  const getOptionLabel = (option) => `${option.title}`;
  const getOptionDisabled = (option) => option.value === "foo";
  //handle toggle for all the dropdowns
  const handleToggleOption = (selectedOptions, name, index) => {
    if (name === "sector") {
      let sectorArray = selectedOptions.map((el) => el.value);

      const values = [...moduleData];

      values[index].sectorScope = sectorArray;
      values[index].sectorDropdown = selectedOptions;
      setSelectedSectorOptions(selectedOptions);
      getSelectedSectorOptions(selectedOptions);
    } else if (name === "module") {
      setSelectedModuleDropDownData(selectedOptions);
      // setSelectedModulesConverted(selectedOptions.map((el) => el.title))
    } else if (name === "bu") {

      let burArray = selectedOptions.map((el) => el.value);

      const values = [...moduleData];

      values[index].buScope = burArray;
      values[index].buDropdown = selectedOptions;
      setSelectedBusinessUnitOptions(selectedOptions);
    } else if (name === "pa") {
      let paArray = selectedOptions.map((el) => el.value);

      const values = [...moduleData];

      values[index].paScope = paArray;
      values[index].paDropdown = selectedOptions;
      setSelectedPaOptions(selectedOptions);
    }
  };

  //handle clear for all the dropdown's
  const handleClearOptions = (isSelected, name, index) => {
    if (name === "sector") {
      const values = [...moduleData];
      values[index].sectorScope = [];
      values[index].sectorDropdown = [];
      values[index].buScope = [];
      values[index].buDropdown = [];
      values[index].paScope = [];
      values[index].paDropdown = [];
      setSelectedSectorOptions([]);
      setSelectedBusinessUnitOptions([]);

      setSelectedPaOptions([]);

      setBusinessUnitData([]);

      setPaData([]);
    } else if (name === "module") {
      setSelectedModuleDropDownData([]);
    } else if (name === "bu") {
      const values = [...moduleData];
      values[index].buScope = [];
      values[index].buDropdown = [];
      values[index].paScope = [];
      values[index].paDropdown = [];
      setSelectedBusinessUnitOptions([]);
    } else if (name === "pa") {
      const values = [...moduleData];
      values[index].paScope = [];
      values[index].paDropdown = [];
      setSelectedPaOptions([]);
    }
  };
  //handle select all for dropdown's.
  const handleSelectAll = (isSelected, name, index) => {
    if (isSelected) {
      if (name === "sector") {
        let sectorArray = sectorData.map((el) => el.value);
        const values = [...moduleData];

      values[index].sectorScope = sectorArray;
      values[index].sectorDropdown = sectorData;
        setSelectedSectorOptions(sectorData);

        getSelectedSectorOptions(sectorData);
      } else if (name === "module") {
        setSelectedModuleDropDownData(moduleDropDownData);
      } else if (name === "sector_wallpaper") {
        setSelectedSectorOptions(wallPaperSectorData);
      } else if (name === "bu") {
        let buArray = businessUnitData.map((el) => el.value);
        const values = [...moduleData];
        
        values[index].buScope = buArray;
        values[index].buDropdown = businessUnitData;
        setSelectedBusinessUnitOptions(businessUnitData);
       
      } else if (name === "pa") {
        let paArray = paData.map((el) => el.value);
        const values = [...moduleData];

      values[index].paScope = paArray;
      values[index].paDropdown = paData;
      
        setSelectedPaOptions(paData);
        setModuleData(values)
      }
    } else {
      handleClearOptions();
    }
  };

  //HELPER FUNCTIONS.
  const getSelectedSectorOptions = (selectedOptions) => {
    const xyz = selectedOptions.map((el) => {
      return {
        addsectorId: el.value,
      };
    });
    getBusinessUnitBySector(xyz);
    getPaBySector(xyz);
   
  };

  //DATE FUNCTION
  // const handleDateRange = (e, selectType) => {
  //   if (selectType.value === "always") {
  //     setDateType("always");
  //   } else if (selectType.value === "date_range") {
  //     setDateType("date_range");
  //   } else if (selectType.value === "based_on_start_date") {
  //     setDateType("based_on_start_date");
  //   } else {
  //     setDateType("");
  //   }
  // };

  const hanldeSubmit = (params) => {
    let data = {};
    let sendActiveFrom = "";
    let sendActiveTo = "";
    if (dateType === "always") {
      sendActiveFrom = "";
      sendActiveTo = "";
    } else if (dateType === "date_range") {
      sendActiveFrom = moment(activeFrom.toDate()).format("DD/MM/YYYY");
      sendActiveTo = moment(activeTo.toDate()).format("DD/MM/YYYY");
    } else {
      sendActiveFrom = moment(activeFrom.toDate()).format("DD/MM/YYYY");
      sendActiveTo = "";
    }
 if (selectedAdminTypeData?.title === "OTHERS") {
      data = {
        employeeId: userID,
        adminType: selectedAdminTypeData?.title,
        admintypeDropdown: [selectedAdminTypeData],
        moduleAssigneds: moduleData,
        activeFrom: sendActiveFrom,
        activeTo: sendActiveTo,
      };
    } else {
      data = {
        employeeId: userID,
        adminType: selectedAdminTypeData?.title,
        admintypeDropdown: [selectedAdminTypeData],
        activeFrom: sendActiveFrom,
        activeTo: sendActiveTo,
      };
    }

    //    data = {
    //     "employeeId": userID,
    //     "adminType": "OTHERS",
    //     "admintypeDropdown":  [{

    //       value: "4",

    //       title: "OTHERS"

    //   }],
    //     "sectorScope": sectorListData,
    //     "buScope": buisnessUnitListData,
    //     "paScope": paListData,
    //     "sectorDropdown" : selectedSectorOptions,
    //     "buDropdown" : selectedBusinessUnitOptions,
    //     "paDropdown" : selectedPaOptions,
    //     "activeFrom" : sendActiveFrom,
    //     "activeTo" : sendActiveFrom,

    // }
    handleCreateAdminApi(data);
  };



  const handleAdminType = (e, selectType) => {
    setSelectedAdminTypeData(selectType);
  };


  const handleModuleType = (e, selectType, index) => {
  
    const values = [...moduleData];
    

      const module= [...selectedModules,selectType?.title]
    
    setSelectedModules(module)
    values[index].module = selectType?.title;
    values[index].moduleDropdown = selectType;
    setModuleData(values);
    
  };
  return (
    <div>
      <Template>
        <Grid
          container
          direction="row"
          justifyContent="space-between"
          alignItems="center"
        >
          <Grid item md={7} xs={12}>
            <p className="offCHeaderTitle">Create new admin</p>
          </Grid>
        </Grid>
        <Grid container direction="row" spacing={3}>
          {/*<Grid item sm={3}>
             <div className="authorRadioButton">
              <FormControlLabel
                value="publish"
                className="radioButtonForm"
                control={<Radio />}
                label={
                  <span className="formControlLabelStyle commonFontNormalStyle">
                    Internal Employee
                  </span>
                }
                labelPlacement="end"
              /> 
            </div> 
              </Grid> */}
          <Grid item sm={3}>
            {/*<div className="authorRadioButton radioBackground">
              <FormControlLabel
                value="schedule"
                className="radioButtonForm"
                control={<Radio />}
                label={
                  <span className="formControlLabelStyle commonFontNormalStyle">
                    External Employee
                  </span>
                }
                labelPlacement="end"
              /> 
              </div> */}
          </Grid>
        </Grid>
        <Paper className="rollBasedAddNewUserGrp">
          <Grid container direction="row" spacing={3}>
            <Grid item sm={3} xs={12}>
              <span className="rollBasedPaperInnnerContentLabel">
                Add employee Token
              </span>
              <TextField
                variant="outlined"
                name="User id"
                label={<CommonLabel label="User id" mandatory />}
                className="fullWidth customTextfield"
                onChange={handleToken}
              />
            </Grid>
          </Grid>

          <Grid container direction="row" spacing={3}>
            <Grid item md={3} sm={3} xs={12} className="rollBasedAutoCompplete">
              <span className="rollBasedPaperInnnerContentLabel">
                Assign dates
              </span>
              <CommonAutocomplete
                handleSelectChange={handleDateRange}
                options={dateRangeData}
                label={<CommonLabel label="Type" mandatory />}
              />
            </Grid>
            {dateType === "date_range" && (
              <>
                <Grid
                  item
                  md={3}
                  sm={3}
                  xs={12}
                  className="rollBasedAutoCompplete"
                >
                  <span className="rollBasedPaperInnnerContentLabel">
                    Active from
                  </span>
                  <DatePicker
                    selectedDate={activeFrom}
                    handleDateChange={(date) => {
                      setActiveFrom(date);
                    }}
                    minDate={new Date().setDate(new Date().getDate())}
                    disableToolbarr={true}
                    label={<CommonLabel label="Active From" mandatory />}
                  />
                </Grid>
                <Grid
                  item
                  md={3}
                  sm={3}
                  xs={12}
                  className="rollBasedAutoCompplete"
                >
                  <span className="rollBasedPaperInnnerContentLabel">
                    Active to
                  </span>
                  <DatePicker
                    selectedDate={activeTo}
                    handleDateChange={(date) => {
                      setActiveTo(date);
                    }}
                    minDate={new Date().setDate(new Date().getDate())}
                    disableToolbarr={true}
                    label={<CommonLabel label="Active To" mandatory />}
                  />
                </Grid>
              </>
            )}

            {dateType === "based_on_start_date" && (
              <>
                <Grid
                  item
                  md={3}
                  sm={3}
                  xs={12}
                  className="rollBasedAutoCompplete"
                >
                  <span className="rollBasedPaperInnnerContentLabel">
                    Active from
                  </span>
                  <DatePicker
                    selectedDate={activeFrom}
                    handleDateChange={(date) => {
                      setActiveFrom(date);
                    }}
                    minDate={new Date().setDate(new Date().getDate())}
                    disableToolbarr={true}
                    label={<CommonLabel label="Active From" mandatory />}
                  />
                </Grid>
              </>
            )}
          </Grid>

          <Grid container direction="row" spacing={3}>
            {/*<Grid item md={3} sm={3} xs={12} className="rollBasedAutoCompplete">
                   

                    <DatePicker
                     
                      selectedDate={activeFrom}
                      handleDateChange={(date) => {
                        setActiveFrom(date)
                        }}
                        minDate={new Date().setDate(new Date().getDate() )}
                      disableToolbarr={true}
                      label={<CommonLabel label="Active From" mandatory />}
                     
                    />
                  </Grid>
                  <Grid item md={3} sm={3} xs={12} className="rollBasedAutoCompplete">
                    <DatePicker
                      selectedDate={activeTo}
                      handleDateChange={(date) => {
                        setActiveTo(date);
                        }}
                      label={<CommonLabel label="Active To" mandatory />}
                      minDate={new Date().setDate(new Date().getDate() + 1)}
                   /> 
                      </Grid> */}
          </Grid>
          <Grid
            container
            direction="row"
            spacing={3}
            className="rollCreateAdminFeildContainer"
          >
            <Grid item sm={3} xs={12} className="rollBasedAutoCompplete">
              <span className="rollBasedPaperInnnerContentLabel">
                Admin Type
              </span>
              <CommonAutocomplete
                handleSelectChange={handleAdminType}
                options={adminTypeData}
                label={<CommonLabel label="Type" mandatory />}
              />
            </Grid>
          </Grid>

          {selectedAdminTypeData && selectedAdminTypeData.title === "OTHERS" && (
            <>
              {moduleData.map((el, index) => {
                return (
                  <>
                    <Grid
                      container
                      direction="row"
                      spacing={1}
                      className="rollCreateAdminFeildContainer"
                    >
                      <Grid
                        item
                        sm={3}
                        xs={12}
                        className="rollBasedAutoCompplete"
                      >
                        <span className="rollBasedPaperInnnerContentLabel">
                          module name
                        </span>

                        <CommonAutocomplete
                          name="module"
                          handleSelectChange={(e, selectType) => {
                            handleModuleType(e, selectType, index);
                          }}
                          options={moduleDropDownData}
                          label={<CommonLabel label="Type" mandatory />}
                        />
                      </Grid>
                      <Grid
                        item
                        sm={3}
                        xs={12}
                        className="rollBasedAutoCompplete"
                      >
                        <span className="rollBasedPaperInnnerContentLabel">
                          Sector
                        </span>
                        <CustomMultiSelect
                          items={sectorData}
                          getOptionLabel={getOptionLabel}
                          getOptionDisabled={getOptionDisabled}
                          selectedValues= {moduleData[index].sectorDropdown}
                          placeholder="Search sector"
                          selectAllLabel="Select all"
                          onToggleOption={(isSelected) => {
                            handleToggleOption(isSelected, "sector", index);
                          }}
                          onClearOptions={(isSelected) => {
                            handleClearOptions(isSelected, "sector", index);
                          }}
                          onSelectAll={(isSelected) => {
                            handleSelectAll(isSelected, "sector", index);
                          }}
                        />
                      </Grid>
                      <Grid
                        item
                        sm={3}
                        xs={12}
                        className="rollBasedAutoCompplete"
                      >
                        <span className="rollBasedPaperInnnerContentLabel">
                          BU
                        </span>
                        <CustomMultiSelect
                          items={businessUnitData}
                          getOptionLabel={getOptionLabel}
                          getOptionDisabled={getOptionDisabled}
                          selectedValues= {moduleData[index].buDropdown}
                          placeholder="Search business unit"
                          selectAllLabel="Select all"
                          onToggleOption={(isSelected) => {
                            handleToggleOption(isSelected, "bu", index);
                          }}
                          onClearOptions={(isSelected) => {
                            handleClearOptions(isSelected, "bu", index);
                          }}
                          onSelectAll={(isSelected) => {
                            handleSelectAll(isSelected, "bu", index);
                          }}
                        />
                      </Grid>
                      <Grid
                        item
                        sm={3}
                        xs={12}
                        className="rollBasedAutoCompplete"
                      >
                        <span className="rollBasedPaperInnnerContentLabel">
                          PA(Personal Inclusion)
                        </span>
                        <CustomMultiSelect
                          items={paData}
                          getOptionLabel={getOptionLabel}
                          getOptionDisabled={getOptionDisabled}
                          placeholder="Search P.A."
                          selectAllLabel="Select all"
                          selectedValues= {moduleData[index].paDropdown}
                          onToggleOption={(isSelected) => {
                            handleToggleOption(isSelected, "pa", index);
                          }}
                          onClearOptions={(isSelected) => {
                            handleClearOptions(isSelected, "pa", index);
                          }}
                          onSelectAll={(isSelected) => {
                            handleSelectAll(isSelected, "pa", index);
                          }}
                        />
                      </Grid>
                      <Grid
                        item
                        sm={3}
                        xs={6}
                        className="rollBasedAutoCompplete"
                      >
                        <IconButton>
                          <RemoveIcon
                            onClick={() => {
                              handleRemoveFields(index);
                            }}
                          />
                        </IconButton>
                      </Grid>

                      <Grid
                        item
                        sm={3}
                        xs={6}
                        className="rollBasedAutoCompplete"
                      >
                        <IconButton>
                          <AddIcon onClick={handleAddFields} />
                        </IconButton>
                      </Grid>
                    </Grid>
                  </>
                );
              })}
            </>
          )}

          <Grid
            container
            direction="row"
            spacing={3}
            className="rollBasedSubmitPanel"
          >
            <div style={{ padding: "10px" }}>
              <SubmitButton onClick={hanldeSubmit} label="Submit" />
            </div>
            <div style={{ padding: "10px" }}>
              <SubmitButton onClick={hanldeCancel} label="cancel" />
            </div>
          </Grid>
        </Paper>
      </Template>
    </div>
  );
}
