import services from "../../Services/index";
import {
    GET_INITIAL_WALLPAPER_INFO_SUCCESS,
    GET_INITIAL_WALLPAPER_INFO_LOAD,
    GET_INITIAL_WALLPAPER_INFO_FAILED,
} from "../Contants/Constants";

const getInitialWallpaperInfoLoad = () => ({
    type: GET_INITIAL_WALLPAPER_INFO_LOAD,
});
const getInitialWallpaperInfoSuccess = (data) => ({
    type: GET_INITIAL_WALLPAPER_INFO_SUCCESS,
    payload: data,
});
const getInitialWallpaperInfoFailed = (error) => ({
    type: GET_INITIAL_WALLPAPER_INFO_FAILED,
    err: error,
});

export const getInitialWallpaperInfoData = (id) => async (dispatch) => {
  dispatch(getInitialWallpaperInfoLoad());
  try {
    const res = await services.api(
      "GET",
      `/ui-assist/v1/wallpapercheckadmin/${id}`
    );
    if (res) {
      dispatch(getInitialWallpaperInfoSuccess(res.data));
    }
  } catch (err) {
    dispatch(getInitialWallpaperInfoFailed(err.response));
  }
};