import React, { useState, useEffect } from "react";
import "./Dashboard.css";
import { Tooltip, Paper } from "@material-ui/core";
import TaskCenter from "./TaskCenter/TaskCenter";
import editIconRed from "../../Assets/Images/edit-line-red.svg";
import { addInterval } from "../../Redux/Actions/Anaylatics";

import { Grid } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
// import SmsOutlinedIcon from "@material-ui/icons/SmsOutlined";
import editIcon from "../../Assets/Images/edit-line.svg";
// import CommonModal from "../Common/CommonModal/CommonModal";
//import ModifiedSection from "./WhatsNew/ModifiedSection/ModifiedSection";
import TextareaAutosize from "@mui/base/TextareaAutosize";
import services from "../../Services";
import { getWhatsNewData } from "../../Redux/Actions/WhatsAction";

import { useDispatch, useSelector } from "react-redux";
import {
  removeWhatsNewItem,
  editWhatsNewItem,
} from "../../Redux/Actions/WhatsAction";

import Genie from "../../Components/Genie/Genie";
import WhatsNewComponent from "./WhatsNew/WhatsNewComponent.jsx";
import FadeInOutHeaderCard from "../FadeInOutHeaderCard/FadeInOutHeaderCard";
import { useHistory } from "react-router-dom";
import { LIVEWIRE, MAHINDRA_COLLECTIVE } from "../../Routes/constant";
import CommonModal from "../Common/CommonModal/CommonModal";
import WhatsNewAdminComponent from "./WhatsNew/WhatsNewAdmin/WhatsNewAdminComponent";
import { useMediaQuery, useTheme } from "@material-ui/core";

import whatsNewList from "./WhatsNew/whatsNewList.json";
import PageTutorialComponent from "../Common/PageTutorial/PageTutorialComponent";
import { addAnalayticsLog } from "../../Redux/Actions/Anaylatics";
import platform from "platform";
import { isAccesible } from "../../Assets/helperFunctions/helperFunctions";
import Downtimehome from "../Pages/DownTime/Downtimehome";
import Swal from "sweetalert2";
import Ticker from "../Common/Ticker/Ticker";

const useStyles = makeStyles({
  customTooltip: {
    backgroundColor: "#fefdfd",

    fontSize: "12px",
    width: "326px",
    padding: "8px 8px 8px 8px",
    borderRadius: "0px",
    color: "#242424",
    fontWeight: "normal",
    lineHeight: "1.67",
    position: "relative",
    right: "40px",
  },
  customArrow: {
    color: "#fefdfd",
  },
});

// const year = new Date().getFullYear();

function DashBoard(props) {
  //breakPoint
  const Bp = useTheme();
  const isXs = useMediaQuery(Bp.breakpoints.down("xs"));

  //Genie visibility
  const isMldl =
    JSON.parse(localStorage.getItem("userProfile")).sectorName ===
      "Real Estate Sector" &&
    JSON.parse(localStorage.getItem("userProfile")).sector === "52304758";

  //tooltip
  const classes = useStyles();
  const history = useHistory();

  const dispatch = useDispatch();
  const whatsNewDetails = useSelector((state) => state.getWhatsNewDataReducer);
  const { getData: whatsNewObj } = whatsNewDetails;
  const [getWhatsNewObj, setGetWhatsNewObj] = useState(whatsNewObj);

  const [open, setOpen] = React.useState(false);
  const [value, setValue] = React.useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      dispatch(addInterval(localStorage.getItem("logID")));
    }, 60000);
    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    dispatch(getWhatsNewData());
  }, []);

  //anaylatics log save
  useEffect(() => {
    dispatch(
      addAnalayticsLog({
        module: "today",

        browserDetails: `${platform.name} ${platform.os.architecture} ${platform.os.family} ${platform.os.version}`,

        deviceDetails: platform.product ? platform.product : "Desktop",

        isSearch: false,
      })
    );
    // you know what is this, don't you?
  }, []);
  useEffect(() => {
    setGetWhatsNewObj(whatsNewObj);
  }, [whatsNewObj, whatsNewDetails]);

  const handleClickOpen = (ev) => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };
  // const [message, setMessage] = useState("");
  // const [showTicker, setshowTicker] = useState("");
  // const [showUrl, setshowUrl] = useState("");
  // const [data, setData] = useState(null);
  // const [specialAwardData, setSpecialAwardData] = useState(null);
  const [loading, setLoading] = useState(true);

  // useEffect(() => {
  //   const fetchPPLetter = async () => {
  //     try {
  //       const res = await services.api(
  //         "GET",
  //         `/ui-assist/v1/getppletter/${localStorage.getItem("token")}`
  //       );
  //       if (res && res.data && res.status === 200) {
  //         setMessage(res?.data?.d?.MSG);
  //         setshowTicker(res?.data?.d?.SHOW_TICKER);
  //         setshowUrl(res?.data?.d?.SHOW_URL);
  //       }
  //     } catch (err) {
  //       console.log(err.message);
  //     }
  //   };
  //   !isMldl && fetchPPLetter();
  // }, []);

  // useEffect(() => {
  //   const fetchData = async () => {
  //     try {
  //       const response = await fetch(
  //         `${
  //           process.env.REACT_APP_PROMOTION_LETTER_URL
  //         }/EMP_PROMOTION(PERNR=%27${localStorage.getItem(
  //           "token"
  //         )}%27,YEAR1=%27${year}%27)?$format=json`
  //       );
  //       if (response.ok) {
  //         const jsonData = await response.json();
  //         setData(jsonData);
  //         setLoading(false);
  //       }
  //     } catch (error) {
  //       console.error("Error:", error);
  //     }
  //   };

  //   !isMldl && fetchData();
  // }, []);

  // useEffect(() => {
  //   const fetchSpecialAwardData = async () => {
  //     try {
  //       const response = await fetch(
  //         `${
  //           process.env.REACT_APP_PROMOTION_LETTER_URL
  //         }/EMP_SPECIALAWARD(PERNR=%27${localStorage.getItem(
  //           "token"
  //         )}%27,YEAR1=%27${year}%27)?$format=json`
  //       );
  //       if (response.ok) {
  //         const jsonData = await response.json();
  //         setSpecialAwardData(jsonData);
  //         setLoading(false);
  //       }
  //     } catch (error) {
  //       console.error("Error:", error);
  //     }
  //   };

  //   !isMldl && fetchSpecialAwardData();
  // }, []);

  const [sapdowntime, setSapdowntime] = useState(null);

  useEffect(() => {
    const getDowntimeData = async () => {
      try {
        const res = await services.api("GET", "/ui-assist/v1/downtime");
        if (res.status === 200) {
          setSapdowntime(res?.data);
        }
      } catch (err) {
        console.log(err.message);
      }
    };
    getDowntimeData();
  }, []);

  const openWhatsNewAdminModal = () => {
    return (
      <CommonModal
        open={open}
        handleClose={handleClose}
        header={true}
        footer={true}
        headerText="Edit what’s new section"
        icon={editIcon}
        modalSize="lg"
        fullWidth
        // submitBtnText="SAVE CHANGES"
        submitBtnText="CLOSE"
        // onButtonClick={() => setSubmitForApproval(false)}
        onButtonClick={handleClose}
        // buttonStyling={true}
      >
        <WhatsNewAdminComponent
          data={whatsNewObj}
          getWhatsNewData={getWhatsNewData}
        />
      </CommonModal>
    );
  };

  // const handlePPLetterClick = () => {
  //   dispatch(
  //     addAnalayticsLog({
  //       module: `pp-letter-2022`,

  //       browserDetails: `${platform.name} ${platform.os.architecture} ${platform.os.family} ${platform.os.version}`,

  //       deviceDetails: platform.product ? platform.product : "Desktop",

  //       isSearch: false,
  //     })
  //   );
  //   var link = isMldl
  //     ? "https://emss.mahindra.com/sap/bc/ui5_ui5/ui2/ushell/shells/abap/FioriLaunchpad.html?sap-client=100&sap-ushell-config=headerless&sap-language=EN#Compensation_PPLetters-display"
  //     : "https://emss.mahindra.com/sap/bc/ui5_ui5/sap/zzhr_new_comp/index.html#/my-reports/performance-pay";
  //   window.open(link, "_blank");
  // };
  return (
    <>
      <section className="dashboard">
        {/* {isMldl ? "" : <Genie />} */}
        {sapdowntime?.length > 0 && <Downtimehome sapdowntime={sapdowntime} />}
        {/* <FadeInOutHeaderCard /> */}
        <div className="bgImageTemplate">
          {/* {!isMldl && showTicker === "X" && (
            <Ticker
              handlePPLetterClick={handlePPLetterClick}
              data={data}
              specialAwardData={specialAwardData}
              showUrl={showUrl}
              message={message}
            />
          )}  */}
          <Grid
            container
            direction="row"
            justifyContent="space-evenly"
            alignItems="center"
          >
            <div className="mshow pagetuttop">
              {!isMldl && (
                <PageTutorialComponent
                  modelHeadtxt={"Today Page Tutorial"}
                  videoLink={
                    "https://mazpmodsa01.blob.core.windows.net/mrisestorage/Core%20Intranet%20V3.mp4_1706088702435.mp4"
                  }
                />
              )}
            </div>
            <Grid xs={12} item className="sectionBgImage section-container">
              <div className="whatsNewHeader">
                <div className="containerTxt">
                  <Tooltip
                    classes={{
                      tooltip: classes.customTooltip,
                      arrow: classes.customArrow,
                    }}
                    title="The latest and most important news from the group and company."
                    arrow
                    placement="right"
                  >
                    <div className="section-header-text">What's new</div>
                  </Tooltip>
                  <div className="mhide">
                    {!isMldl && (
                      <PageTutorialComponent
                        className="mhide"
                        modelHeadtxt={"Today Page Tutorial"}
                        videoLink={
                          "https://mazpmodsa01.blob.core.windows.net/mrisestorage/Core%20Intranet%20V3.mp4_1706088702435.mp4"
                        }
                      />
                    )}
                  </div>
                  {isXs ? (
                    <> </>
                  ) : (
                    <>
                      {isAccesible("WHATS_NEW_SECTION") && (
                        <>
                          <div
                            className="editIcon"
                            onClick={(event) => handleClickOpen(event)}
                          >
                            <img
                              src={editIconRed}
                              alt=""
                              className="edit-line"
                            />
                            <div>EDIT</div>
                          </div>
                        </>
                      )}
                    </>
                  )}

                  <div className="viewMoreArticle">
                    {/* <div className="PPleter">
                      PP LETTER
                      
                    </div>
                    <div className="PPleter">/</div> */}
                    <div
                      onClick={() =>
                        history.push({
                          pathname: `${LIVEWIRE}${MAHINDRA_COLLECTIVE}`,
                        })
                      }
                    >
                      VIEW MORE ARTICLES
                    </div>
                  </div>
                </div>
              </div>
              <div className="whats-new-container">
                <WhatsNewComponent list={whatsNewList}></WhatsNewComponent>
              </div>
            </Grid>

            {/* <div
            style={{
              backgroundImage: `url(${background_image})`,
            }}
            className="sectionBgImage beforeImage"
          > */}
            <Grid
              container
              direction="row"
              justifyContent="space-evenly"
              alignItems="center"
            >
              <Grid xs={12} item className="section-container taskCenterGap">
                {/* <div className="section-header-text">Task center</div> */}
                <TaskCenter />
              </Grid>
            </Grid>
            {/* </div> */}
          </Grid>
        </div>
        <>{openWhatsNewAdminModal()}</>
        {/* <>{openEditBanner()}</> */}
      </section>
    </>
  );
}

export default DashBoard;
