import React, { useEffect, useState } from "react";
import "./ManageIntranetAccessComponent.css";
import Template from "../../Common/Template/Template";
import Pagination from "@mui/material/Pagination";
import Stack from "@mui/material/Stack";
import {
  Grid,
  Tabs,
  Tab,
  Typography,
  Box,
  Paper,
  Button,
} from "@material-ui/core";
import { CSVLink } from "react-csv";
import PropTypes from "prop-types";
import SubmitButton from "../../Common/Button/SubmitButton";
import SearchBarButton from "../../Common/SearchBar/SearchBarButton";
import CommonAutocomplete from "../../Common/CommonAutocomplete/CommonAutocomplete";
import CommonLabel from "../../Common/ComonLabel/CommonLabel";
import CommonModal from "../../Common/CommonModal/CommonModal";
import addIcon from "../../../Assets/Images/add-white.svg";

import AddNewIntranetAccessComponent from "./AddNewIntranetAccessComponent";
import AddNewUserGroupComponent from "./AddNewUserGroupComponent";
import services from "../../../Services";
import axios from "axios";
import Swal from "sweetalert2";
import moment from "moment";
import CustomPagination from "../../Common/pagination/CustomPagination";
import { useHistory } from "react-router-dom";
import {
  WORK,
  ROLE_BASED,
  ADD_USER_GROUP,
  EDIT_USER_GROUP,
  ADD_NEW_ADMIN,
} from "../../../Routes/constant";
import EditIntranetAccessComponent from "./EditIntranetAccessComponent";
import { gridColumnsTotalWidthSelector } from "@mui/x-data-grid";

function TabPanel(props) {
  const { children, value, index, ...other } = props;
  console.log(props.value, "value@@");

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export default function ManageIntranetAccessComponent() {
  const [searchTextIntra, setsearchTextIntra] = useState("");
  const [searchTextAdminRights, setsearchTextAdminRights] = useState("");
  const [searchTextUserGroup, setsearchTextUserGroup] = useState("");
  const [loading, setLoading] = useState(false);
  const [tabInfo, setTabInfo] = useState("usergroup");

  let headers = [
    { label: "User group name", key: "userGroupName" },
    { label: "Categories", key: "categories" },
    { label: "Tiles assigned", key: "assigned_tiles" },
    { label: "Token id", key: "tokenId" },
    { label: "Employee name", key: "employeeName" },
  ];

  const [openEditAccessModal, setOpenEditAccessModal] = useState(false);
  const [userGroupPages, setUserGroupPages] = useState(null);
  const [adminRightPages, setAdminRightPages] = useState(null);
  const [accessGroupPages, setAccessGroup] = useState(null);
  const [currentPage, setCurrentPage] = useState(0);
  const [currentAccessPage, setCurrentAccessPage] = useState(0);
  const [currentAdminPage, setCurrentAdminPage] = useState(0);
  const history = useHistory();
  const [user, setUser] = useState("");
  const [tableManageInfraData, setTableManageInfraData] = useState([]);
  const [tableUserGroupData, setTableUserGroupData] = useState([]);
  const [page, setPage] = useState(0);

  const [refresh, setrefresh] = useState(false);
  const [refreshUserGroup, setRefreshUserGroup] = useState(false);
  const [refreshAdminRights, setRefreshAdminRights] = useState(false);

  const [selectedMenu, setSelectedMenu] = useState("");
  const [selectedSubMenu, setSelectedSubMenu] = useState("");
  const [selectedUserGroup, setSelectedUserGroup] = useState([]);
  const [selectedUserGroupName, setSelectedUserGroupName] = useState([]);
  const [activeFrom, setActiveFrom] = useState(null);
  const [activeTo, setActiveTo] = useState(null);
  const [userGroupCount, setUserGroupCount] = useState("");
  const [userIntraCount, setUserIntraCount] = useState("");
  const [updateUserGroupData, setupdateUserGroupData] = useState(false);
  const [employeeCount, setEmployeeCount] = useState(0);
  const [innerLoader, setInnerLoader] = useState(false);
  const [selectedSubMenuList, setSelectedSubMenulist] = useState([]);
  const [groupCompanyDetails, setGroupCompanyDetails] = useState([]);
  const [defaultmenuItemData, setDefaultMenuItemData] = useState({});
  const [defaultSubmenuItemData, setDefaultSubMenuItemData] = useState([]);
  const [SelectedSubMenuItemData, setSelectedSubMenuItemData] = useState([]);
  const [selecteduserGroupData, setSelecteduserGroupData] = useState([]);
  const [selectedStatus, setSelectedStatus] = useState({});

  //CREATE ADMIMN DATA HANDLERS STATES.
  const [selectedAdminStatus, setSelectedAdminStatus] = useState({});
  const handleActiveFromDate = (selectType, type) => {
    setActiveFrom(selectType);
  };

  const handleActiveToDate = (selectType, type) => {
    setActiveTo(selectType);
  };

  //set offset count.
  const offsetCount = (num) => {
    let quotient = Math.floor(num / 10);
    var remainder = num % 10;
    if (remainder) {
      return quotient + 1;
    } else {
      return quotient;
    }
  };

  //generate excel report.
  const [excelData, setExcelData] = useState([]);
  const [loadingexcelData, setLoadingExcelData] = useState(false);

  const [excel, setExcel] = useState([]);
  const handleExeclApi = async (result, uvi) => {
    console.log("handle api", result);
    console.log("uvi", uvi);
    setLoadingExcelData(true);
    try {
      const res = await services.api(
        "POST",
        `/ui-assist/v1/rollmatrix/usergroup/uploadfile?id=${
          result ? result?.usergroupId : uvi
        }`
      );
      if (res.status === 200) {
        // console.log(res, "res????")
        setLoadingExcelData(false);
        //setExcelData(res?.data)
        return res?.data;
      }
    } catch (error) {
      console.log(error);
      return [];
    }
  };

  let csvLinkElem = React.createRef();

  useEffect(() => {
    setTimeout(() => {
      if (excelData?.length > 0) {
        csvLinkElem?.current?.link?.click();
      }
    });
  }, [excelData]);

  const downloadReport = async (result, uvi) => {
    const data = await handleExeclApi(result, uvi);
    setExcelData(data);
  };

  // //get user group count.
  // useEffect(() => {
  //   (async () => {
  //     setLoading(true);
  //     try {
  //       const res = await services.api(
  //         "GET",
  //         `/ui-assist/v1/rollmatrix/usergroup/count`
  //       );
  //       if (res.status === 200) {
  //         setLoading(false);

  //         setUserGroupCount(res?.data);
  //         setUserGroupPages(offsetCount(res?.data));
  //       }
  //     } catch (err) {
  //       setLoading(false);
  //       Swal.fire("Oops!", `${err?.response?.data}`, "error");
  //       setUserGroupCount("");
  //     }
  //   })();
  // }, [updateUserGroupData, refreshUserGroup]);

  // console.log(SelectedSubMenuItemData, "selectedSU");
  //get user inranet module.
  // useEffect(() => {
  //   (async () => {
  //     setLoading(true);
  //     try {
  //       const res = await services.api(
  //         "GET",
  //         `/ui-assist/v1/rollmatrix/manageintranet/count`
  //       );
  //       if (res.status === 200) {
  //         setLoading(false);

  //         // setUserIntraCount(res?.data);
  //         // setAccessGroup(offsetCount(res?.data));
  //       }
  //     } catch (err) {
  //       setLoading(false);
  //       Swal.fire("Oops!", `${err?.response?.data}`, "error");
  //       setUserIntraCount("");
  //     }
  //   })();
  // }, [refresh]);

  const [adminRightData, setAdminRightData] = useState([]);
  const [adminResetData, setAdminResetData] = useState([]);
  //get admin access module.
  useEffect(() => {
    (async () => {
      setLoading(true);
      try {
        const res = await services.api(
          "GET",

          `/ui-assist/v1/rollmatrix/adminrights/${localStorage.getItem(
            "token"
          )}?q=${searchTextAdminRights}&sort&page=${currentAdminPage}&offset=10`
        );
        if (res.status === 200) {
          setLoading(false);

          setAdminRightData(res?.data?.scopes);
          setAdminRightCount(res?.data?.totalCount);
          setAdminRightPages(offsetCount(res?.data?.totalCount));
        }
      } catch (err) {
        setLoading(false);
        Swal.fire("Oops!", `${err?.response?.data}`, "error");
        setAdminRightData([]);
      }
    })();
  }, [refreshAdminRights, currentAdminPage, searchTextAdminRights]);

  const [adminRightCount, setAdminRightCount] = useState("");
  //get admin rights count.
  //get admin reights count 2
  // useEffect(() => {
  //   (async () => {
  //     setLoading(true);
  //     try {
  //       const res = await services.api(
  //         "GET",
  //         `/ui-assist/v1/rollmatrix/adminrights/count`
  //       );
  //       if (res.status === 200) {
  //         setLoading(false);

  //         setAdminRightCount(res?.data);
  //         setAdminRightPages(offsetCount(res?.data));
  //       }
  //     } catch (err) {
  //       setLoading(false);
  //       Swal.fire("Oops!", `${err?.response?.data}`, "error");
  //       setAdminRightCount("");
  //     }
  //   })();
  // }, [refreshAdminRights]);

  useEffect(() => {
    (async () => {
      try {
        setLoading(true);
        const res = await services.api(
          "GET",
          `/ui-assist/v1/rollmatrix/manageintranet/${localStorage.getItem(
            "token"
          )}?page=${currentAccessPage}&offset=10&sort&q=${searchTextIntra}`
        );
        if (res.status === 200) {
          setLoading(false);
          setTableManageInfraData(res.data.record);
          setUserIntraCount(res?.data?.totalCount);
          setAccessGroup(offsetCount(res?.data?.totalCount));
        }
      } catch (err) {
        setLoading(false);
        Swal.fire("Oops!", `${err?.response?.data}`, "error");
        setTableManageInfraData([]);
      }
    })();
  }, [refresh, currentAccessPage, searchTextIntra]);

  const [searchReset, setsearchReset] = useState(false);
  const [buttonReset, setButtonReset] = useState(false);
  //get reset user details......
  useEffect(() => {
    (async () => {
      try {
        setLoading(true);
        if (adminResetToken) {
          const res = await services.api(
            "GET",
            `/ui-assist/v1/employees/employeeid/${adminResetToken}`
          );
          if (res.status === 200) {
            setLoading(false);
            setAdminResetData(res.data);
          }
        }
      } catch (err) {
        setLoading(false);

        Swal.fire("Oops!", `Invalid Token`, "error");
      }
    })();
  }, [searchReset, buttonReset]);

  //reset the current user......
  // useEffect(() => {
  //   (async () => {
  //     try {
  //       setLoading(true);
  //       const res = await services.api(
  //         "GET",
  //         `/ui-assist/v1/employees/reststatus/${adminResetToken}`
  //       );
  //       if (res.status === 200) {
  //         console.log(res.data , 'response....')
  //         setLoading(false);

  //       }
  //     } catch (err) {
  //       setLoading(false);
  //      // Swal.fire("Oops!", `Invalid Token`, "error");
  //       //setTableManageInfraData([]);
  //     }
  //   })();
  // }, [buttonReset]);

  useEffect(() => {
    (async () => {
      try {
        setLoading(true);
        const res = await services.api(
          "GET",
          `/ui-assist/v1/rollmatrix/usergroup/${localStorage.getItem(
            "token"
          )}?q=${searchTextUserGroup}&sort&page=${currentPage}&offset=10`
        );
        if (res.status === 200) {
          setLoading(false);
          setTableUserGroupData(res?.data?.record);
          setUserGroupCount(res?.data?.totalCount);
          setUserGroupPages(offsetCount(res?.data?.totalCount));
        }
      } catch (err) {
        setLoading(false);
        Swal.fire("Oops!", `${err?.response?.data}`, "error");
        setTableUserGroupData([]);
      }
    })();
  }, [updateUserGroupData, currentPage, refreshUserGroup, searchTextUserGroup]);

  //get no of employees per group select.

  useEffect(() => {
    (async () => {
      try {
        setLoading(true);
        setInnerLoader(true);
        const res = await services.api(
          "POST",
          `/ui-assist/v1/rollmatrix/manageintranet/noofusers?usergroup=${selectedUserGroup}`
        );
        if (res.status === 200) {
          setLoading(false);
          setInnerLoader(false);
          setEmployeeCount(res.data.length);
        }
      } catch (err) {
        setLoading(false);
        setInnerLoader(false);
        Swal.fire("Oops!", `${err?.response?.data}`, "error");
        setEmployeeCount(0);
      }
    })();
  }, [selectedUserGroup]);

  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const [openModal, setOpenModal] = useState(false);
  const [openModalNewUserGroup, setOpenModalNewUserGroup] = useState(false);

  const onCreateAccess = () => {
    setOpenModal(true);
  };

  const [adminResetToken, setadminResetToken] = useState("");
  const handleSearchAdminReset = (e) => {
    setadminResetToken(e.target.value);
  };

  const [previosValues, setPreviosValues] = useState({});
  const [currentID, setCurrentID] = useState("");
  const [dateType, setDateType] = useState("");
  const [currentDateType, setCurrentDateType] = useState("");
  const [currentSubMenu, setCurrentSubMenu] = useState([]);

  //on edit click
  const onEditAccess = (e, result) => {
    const selecetsubmenuItem = result?.subMenu?.map((el) => {
      return {
        title: el,
        value: el,
      };
    });

    // const selectedDateRange = result?.status?.map((el) => {
    //   return {
    //     title: el,
    //     value: el,
    //   };
    // });

    console.log(result, "result");
    console.log(result.subMenu, "result submenu");
    console.log(selecetsubmenuItem, "result submenu rendered");

    setSelectedSubMenuItemData(selecetsubmenuItem);
    setSelecteduserGroupData(result?.groupCompanyDetails);
    setPreviosValues(result);
    setOpenEditAccessModal(true);
    getDataForEdit(result);
    setSelectedStatus({ title: result?.dateType, value: result?.dateType });
    setActiveFrom(swap(result?.activeFrom));
    setActiveTo(swap(result?.activeTo));
    setCurrentID(result?.id);
    setDateType(result?.dateType);
  };

  function swap(date) {
    if (date) {
      let firstEl = date.split("/")[0];
      let secEl = date.split("/")[1];
      let thEl = date.split("/")[2];
      let temp = firstEl;
      firstEl = secEl;
      secEl = temp;
      return `${firstEl}/${secEl}/${thEl}`;
    }
  }

  console.log(activeTo, "active to....");
  console.log(activeFrom, "active from");
  const getDataForEdit = async (result) => {
    let data = {
      menu: result.menu,
      submenu: result.subMenu,
    };
    try {
      setLoading(true);
      const res = await services.api(
        "POST",
        `/ui-assist/v1/rollmatrix/manageintranet/edit`,
        data
      );
      if (res.status === 200) {
        setLoading(false);
        console.log(res, "res@@");
        const menuItem = res?.data?.menu?.map((el) => {
          return {
            title: el,
            value: el,
          };
        });

        const submenuItem = res?.data?.submenu?.map((el) => {
          return {
            title: el,
            value: el,
          };
        });

        const firstMenu = res?.data?.menu[0];
        console.log(submenuItem, "submenuItem");
        console.log(firstMenu, "firstMenu");
        console.log(menuItem, "menu");
        setDefaultMenuItemData(menuItem[0]);
        setDefaultSubMenuItemData(submenuItem);
        setCurrentSubMenu(result.subMenu);
      }
    } catch (err) {
      setLoading(false);
      Swal.fire("Oops!", `${err.response}`, "error");
    }
  };

  //handle  search data....
  const handleSearchIntra = (e) => {
    setsearchTextIntra(e.target.value);
  };
  const handleSearchAdminRights = (e) => {
    setsearchTextAdminRights(e.target.value);
  };
  const handleSearchUserGroup = (e) => {
    setsearchTextUserGroup(e.target.value);
  };

  const handleEditIntranetCloseModal = () => {
    setOpenEditAccessModal(false);
  };

  const handleEditIntranetSubmit = async () => {
    console.log("i was active to", activeTo);
    console.log("i was active from", activeFrom);
    console.log("menu", defaultmenuItemData);
    console.log("submenu", defaultSubmenuItemData);
    console.log(selecteduserGroupData, "selected options usergroup");
    console.log(currentID, "id");
    console.log(dateType, "dateType");
    console.log(selectedStatus, "datestatus");
    try {
      setLoading(true);
      let data = {};
      if (selectedStatus.title === "Always") {
        data = {
          menu: defaultmenuItemData.title,

          subMenu: currentSubMenu,

          groupCompanyDetails: selecteduserGroupData,

          activeFrom: "",

          activeTo: "",
        };
      } else if (selectedStatus.title === "Based On Start Date") {
        data = {
          menu: defaultmenuItemData.title,

          subMenu: currentSubMenu,

          groupCompanyDetails: selecteduserGroupData,

          activeFrom: moment(activeFrom).format("DD/MM/YYYY"),

          activeTo: "",
        };
      } else {
        data = {
          menu: defaultmenuItemData.title,

          subMenu: currentSubMenu,

          groupCompanyDetails: selecteduserGroupData,

          activeFrom: moment(activeFrom).format("DD/MM/YYYY"),

          activeTo: moment(activeTo).format("DD/MM/YYYY"),
        };
      }

      let res = await services.api(
        "PUT",
        `/ui-assist/v1/rollmatrix/manageintranet/${localStorage.getItem(
          "token"
        )}/${currentID}`,
        data
      );
      if (res.status === 200) {
        Swal.fire("success", `data updated successfully`, "success");
        setLoading(false);
        setrefresh(!refresh);
        setOpenEditAccessModal(false);
      }
    } catch (error) {
      console.log(error, "error");
    }
  };

  const onNewUserGroup = () => {
    // setOpenModalNewUserGroup(true);
    history.push(`${WORK}${ROLE_BASED}${ADD_USER_GROUP}`);
  };

  const EditUserGroup = (id, groupName) => {
    history.push(
      `${WORK}${ROLE_BASED}${EDIT_USER_GROUP}/${groupName}?id=${id}`
    );
  };

  const handleCloseModal = () => {
    setOpenModal(false);
    setOpenModalNewUserGroup(false);
  };

  const handleDelete = (e, { id }, name) => {
    if (name === "manage_intranet_access") {
      handleManageInfraDeleteApi(id);
    }
  };

  const handleUserGroupDelete = (e, { usergroupId }) => {
    handleUserGroupDeleteApi(usergroupId);
  };

  //delete admin right handler.
  const handleAdminRightDelete = (e, { id }) => {
    handleAdminRightsApi(id);
  };

  const handleManageInfraDeleteApi = async (id) => {
    setLoading(true);
    try {
      const res = await services.api(
        "DELETE",
        `/ui-assist/v1/rollmatrix/manageintranet/${id}`
      );
      if (res.status === 200) {
        Swal.fire("success", `user group delete successfully`, "success");
        setLoading(false);
        setrefresh(!refresh);
      }
    } catch (err) {
      setLoading(false);
      Swal.fire("Oops!", `${err?.response?.data}`, "error");
    }
  };

  //DELETE ADMIN RIGHTS.
  const handleAdminRightsApi = async (id) => {
    setLoading(true);
    try {
      const res = await services.api(
        "DELETE",
        `/ui-assist/v1/rollmatrix/adminrights/${id}`
      );
      if (res.status === 200) {
        Swal.fire("success", `user group delete successfully`, "success");
        setRefreshAdminRights(!refreshAdminRights);
        setLoading(false);
      }
    } catch (err) {
      setLoading(false);
      Swal.fire("Oops!", `${err?.response?.data}`, "error");
      setRefreshAdminRights(!refreshAdminRights);
    }
  };

  const handleUserGroupDeleteApi = async (id) => {
    setLoading(true);
    try {
      const res = await services.api(
        "DELETE",
        `/ui-assist/v1/rollmatrix/usergroup/${id}`
      );
      Swal.fire("success", `user group deleted successfully`, "success");
      setLoading(false);
      setRefreshUserGroup(!refreshUserGroup);
    } catch (err) {
      setLoading(false);
      Swal.fire("Oops!", `${err?.response?.data}`, "error");
    }
  };

  const handleSumitIntranetAccessApi = async (data) => {
    setLoading(true);
    try {
      const res = await services.api(
        "POST",
        `/ui-assist/v1/rollmatrix/manageintranet/${localStorage.getItem(
          "token"
        )}`,
        data
      );

      if (res.status === 200) {
        setLoading(false);
        Swal.fire("Success", `Added New Access Successfully`, "success");
        setSelectedMenu("");
        setSelectedSubMenu("");
        setSelectedUserGroup([]);
        setSelectedUserGroupName([]);
        setActiveFrom(null);
        setActiveTo(null);
        setOpenModal(false);
        setrefresh(!refresh);
      }
    } catch (err) {
      setLoading(false);
      //console.log(err, 'res')
      Swal.fire("Oops!", `something went wrong!`, "error");
    }
  };

  const handleSubmit = () => {
    if ((activeTo === null || activeTo === undefined) && activeFrom) {
      console.log("based on start date.");

      let data = {
        menu: selectedMenu,
        subMenu: selectedSubMenuList,
        // groupcompanyID: selectedUserGroup,
        // groupcompanyName: selectedUserGroupName,
        groupCompanyDetails: groupCompanyDetails,
        activeFrom: moment(activeFrom?.toDate()).format("DD/MM/YYYY"),
        activeTo: "",
      };

      handleSumitIntranetAccessApi(data);
    } else if (activeFrom === "" && activeTo === "") {
      console.log("inside always");
      let data = {
        menu: selectedMenu,
        subMenu: selectedSubMenuList,
        // groupcompanyID: selectedUserGroup,
        // groupcompanyName: selectedUserGroupName,
        groupCompanyDetails: groupCompanyDetails,
        activeFrom: "",
        activeTo: "",
      };
      handleSumitIntranetAccessApi(data);
    } else if (activeTo && activeFrom) {
      console.log("inside date range");

      let data = {
        menu: selectedMenu,
        subMenu: selectedSubMenuList,
        // groupcompanyID: selectedUserGroup,
        // groupcompanyName: selectedUserGroupName,
        groupCompanyDetails: groupCompanyDetails,
        activeFrom: moment(activeFrom.toDate()).format("DD/MM/YYYY"),
        activeTo: moment(activeTo.toDate()).format("DD/MM/YYYY"),
        groupCompanydropdown: [
          { title: "test11", value: "11" },
          { title: "test12", value: "12" },
        ],
      };
      handleSumitIntranetAccessApi(data);
    }
  };

  //handle new pagination.

  //handle pagination.
  const handlePaginationChange = (e, val) => {
    setCurrentPage(val - 1);
  };

  //handle admin pagination.
  const handleAdminPaginationChange = (e, val) => {
    setCurrentAdminPage(val - 1);
  };

  //handle access gruop pagination
  const handleAccessPaginationChange = (e, val) => {
    setCurrentAccessPage(val - 1);
  };

  return (
    <div>
      <Template>
        <Grid
          container
          direction="row"
          justifyContent="space-between"
          alignItems="center"
        >
          <Grid item xs={12} sm={12}>
            <p className="offCHeaderTitle">Manage intranet access</p>
          </Grid>
        </Grid>
        <Box sx={{ width: "100%" }}>
          <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
            <Tabs
              value={value}
              onChange={handleChange}
              aria-label="basic tabs example"
              className="rollBasedTab"
            >
              <Tab label="Access rights" {...a11yProps(0)} />
              <Tab label="User groups" {...a11yProps(1)} />
              <Tab label="Create admin" {...a11yProps(2)} />
              <Tab label="Reset user" {...a11yProps(3)} />
            </Tabs>
            <hr className="deviderP" />
          </Box>
          <TabPanel value={value} index={0} className="rollBasedTabPanel">
            <Paper>
              <Grid
                container
                direction="row"
                alignItems="center"
                spacing={2}
                className="rollBasedPaper"
              >
                <Grid item xs={12} sm={4}>
                  <div className="rollBasedTitle">{`Access Rights (${userIntraCount})`}</div>
                </Grid>
                <Grid item xs={12} sm={8}>
                  <Grid
                    container
                    direction="row"
                    alignItems="center"
                    spacing={9}
                  >
                    <Grid item>
                      <SubmitButton
                        label="ADD NEW ACCESS"
                        className="rollBasedBtn"
                        onClick={onCreateAccess}
                      />
                    </Grid>
                    <Grid item sm={6} className="rollBasedSearchBarButton">
                      <SearchBarButton
                        handleSearch={handleSearchIntra}
                        placeholder="Search"
                      />
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
              <div className="rollBasedTable">
                <div className="rollBasedTableHeader">
                  <div className="rollBasedTableCol1">Menu</div>

                  <div className="rollBasedTableCol2">Sub-menu</div>

                  <div className="rollBasedTableCol3">Applied user groups</div>

                  <div className="rollBasedTableCol4">Active status</div>

                  <div className="rollBasedTableCol5">Created/Updated Date</div>

                  {/*<div className="rollBasedTableCol6">No. of users</div>*/}

                  <div className="rollBasedTableCol7">Created/Updated by</div>

                  <div className="rollBasedTableCol8">Edit</div>

                  <div className="rollBasedTableCol9">Delete</div>
                </div>
                {tableManageInfraData?.map((result) => (
                  <div className="rollBasedTable">
                    <div className="rollBasedTableContent">
                      <div className="rollBasedTableCol1">{result.menu}</div>

                      <div className="rollBasedTableCol2">
                        {result.subMenu.join(",").toString()}
                      </div>

                      <div className="rollBasedTableCol3">
                        {result?.groupCompanyDetails?.map((el) => (
                          <p>{el.title}</p>
                        ))}
                      </div>
                      <div className="rollBasedTableCol4">
                        <Button className="statusActive">
                          {result.status}
                        </Button>
                      </div>

                      <div className="rollBasedTableCol5">
                        {result.created_updatedDate}
                      </div>

                      {/*<div className="rollBasedTableCol6">
                        {result.noOfUsers}
                    </div> */}

                      <div className="rollBasedTableCol7">
                        {result.createdBy}
                      </div>

                      <div
                        style={{ cursor: "pointer" }}
                        className="rollBasedTableCol8"
                      >
                        <img
                          src={
                            process.env.PUBLIC_URL + "/iconImages/gray-edit.svg"
                          }
                          alt=""
                          onClick={(e) => onEditAccess(e, result)}
                        />
                      </div>

                      <div
                        style={{ cursor: "pointer" }}
                        className="rollBasedTableCol9"
                      >
                        <img
                          src={
                            process.env.PUBLIC_URL +
                            "/iconImages/gray-delete.svg"
                          }
                          alt="delete group"
                          onClick={(e) =>
                            handleDelete(e, result, "manage_intranet_access")
                          }
                        />
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </Paper>
            <div className="rollBasedTablePagination">
              <div className="paginationAlignFlex">
                {/* <div className="paginationNumbers">
                  <img
                    src={
                      process.env.PUBLIC_URL +
                      "/Images/universalSearch_Images/left-box-arrow.png"
                    }
                    alt="arrow image"
                    className="arrowPos"
                  />
                </div>
               <div className="paginationNumbers">1</div>
                <div className="paginationNumbers">2</div>
                <div className="paginationNumbers">...</div>
                <div className="paginationNumbers">5</div>
                  <div className="paginationNumbers"> 
                  <img
                    src={
                      process.env.PUBLIC_URL +
                      "/Images/universalSearch_Images/right-box-arrow.svg"
                    }
                    alt="arrow image"
                    className=""
                  />
                </div> */}
                <Stack spacing={2}>
                  <Pagination
                    count={accessGroupPages}
                    variant="outlined"
                    shape="rounded"
                    onChange={handleAccessPaginationChange}
                  />
                </Stack>
              </div>
            </div>
          </TabPanel>
          <TabPanel value={value} index={1} className="rollBasedTabPanel">
            <Paper>
              <Grid
                container
                direction="row"
                alignItems="center"
                spacing={2}
                className="rollBasedPaper"
              >
                <Grid item xs={12} sm={6}>
                  <div className="rollBasedTitle">{`User Groups (${userGroupCount})`}</div>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Grid
                    container
                    direction="row"
                    alignItems="center"
                    spacing={2}
                  >
                    <Grid item>
                      <SubmitButton
                        label="Create NEW USER GROUP"
                        className="rollBasedBtn"
                        onClick={onNewUserGroup}
                      />
                    </Grid>
                    <Grid item sm={5} className="rollBasedSearchBarButton">
                      <SearchBarButton handleSearch={handleSearchUserGroup} />
                    </Grid>
                    <div>
                      <input
                        type="button"
                        value={loadingexcelData ? "loading.." : "export csv"}
                        onClick={() => {
                          downloadReport("", -1);
                        }}
                        disabled={loadingexcelData}
                      />
                    </div>
                  </Grid>
                </Grid>
              </Grid>
              <div className="rollBasedTable">
                <div className="rollBasedTableHeader">
                  <div className="rollBasedTableCol1">User group name</div>

                  <div className="rollBasedTableCol3">
                    Categories included/excluded{" "}
                  </div>

                  {/*<div className="rollBasedTableCol6">No. of users</div> */}

                  <div className="rollBasedTableCol3">Created/Updated by</div>

                  <div className="rollBasedTableCol3">Created/Updated Date</div>
                  <div className="rollBasedTableCol3">download report</div>

                  <div className="rollBasedTableCol8">Edit</div>

                  <div className="rollBasedTableCol9">Delete</div>
                </div>
                {tableUserGroupData?.map((result) => (
                  <div className="rollBasedTable">
                    <div className="rollBasedTableContent">
                      <div className="rollBasedTableCol1">
                        {result.groupName}
                      </div>
                      <div className="rollBasedTableCol3">
                        {result.categoryIncluded}
                      </div>
                      {/*<div className="rollBasedTableCol3">
                        {result.categoryExcluded}
                </div> */}
                      {/* <div className="rollBasedTableCol6">
                        {result.noOfUsers}
                </div> */}
                      <div className="rollBasedTableCol3">
                        {result.createdBy}
                      </div>
                      <div className="rollBasedTableCol3">
                        {result.groupCreated}
                      </div>
                      <div className="rollBasedTableCol3">
                        <input
                          type="button"
                          value={loadingexcelData ? "loading.." : "export csv"}
                          onClick={() => {
                            downloadReport(result);
                          }}
                          disabled={loadingexcelData}
                        />
                        <CSVLink
                          data={excelData}
                          headers={headers}
                          filename="usergroup_report"
                          ref={csvLinkElem}
                        />
                      </div>
                      <div
                        style={{ cursor: "pointer" }}
                        className="rollBasedTableCol8"
                      >
                        <img
                          src={
                            process.env.PUBLIC_URL + "/iconImages/gray-edit.svg"
                          }
                          alt="Edit"
                          onClick={() => {
                            EditUserGroup(result.usergroupId, result.groupName);
                          }}
                        />
                      </div>
                      <div
                        style={{ cursor: "pointer" }}
                        className="rollBasedTableCol9"
                      >
                        <img
                          src={
                            process.env.PUBLIC_URL +
                            "/iconImages/gray-delete.svg"
                          }
                          alt=""
                          onClick={(e) => handleUserGroupDelete(e, result)}
                        />
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </Paper>
            <div className="rollBasedTablePagination">
              <diva className="paginationAlignFlex">
                {/* <div className="paginationNumbers">
                  <img
                    src={
                      process.env.PUBLIC_URL +
                      "/Images/universalSearch_Images/left-box-arrow.png"
                    }
                    alt="arrow image"
                    className="arrowPos"
                  />
                </div>
                <div className="paginationNumbers">1</div>
                <div className="paginationNumbers">2</div>
                <div className="paginationNumbers">.....</div>
                <div className="paginationNumbers">5</div>
                <div className="paginationNumbers">
                  <img
                    src={
                      process.env.PUBLIC_URL +
                      "/Images/universalSearch_Images/right-box-arrow.svg"
                    }
                    alt="arrow image"
                    className=""
                  />
                </div>
                  </div> */}
              </diva>
              <Stack spacing={2}>
                <Pagination
                  count={userGroupPages}
                  variant="outlined"
                  shape="rounded"
                  onChange={handlePaginationChange}
                />
              </Stack>
            </div>
          </TabPanel>
          <TabPanel value={value} index={2} className="rollBasedTabPanel">
            <Paper>
              <Grid
                container
                direction="row"
                alignItems="center"
                spacing={2}
                className="rollBasedPaper"
              >
                <Grid item xs={12} sm={6}>
                  <div className="rollBasedTitle">{`Admin Rights (${adminRightCount})`}</div>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Grid
                    container
                    direction="row"
                    alignItems="center"
                    spacing={2}
                  >
                    <Grid item sm={3}>
                      <SubmitButton
                        label="Create"
                        className="rollBasedBtn"
                        onClick={() =>
                          history.push(`${WORK}${ROLE_BASED}${ADD_NEW_ADMIN}`)
                        }
                      />
                    </Grid>
                    <Grid item sm={9} className="rollBasedSearchBarButton">
                      <SearchBarButton handleSearch={handleSearchAdminRights} />
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
              <div className="rollBasedTable">
                <div className="rollBasedTableHeader">
                  <div className="rollBasedTableCol1">Employee Id</div>

                  <div className="rollBasedTableCol2">Admin Type</div>

                  <div className="rollBasedTableCol3">Status</div>

                  <div className="rollBasedTableCol4">Active From</div>

                  <div className="rollBasedTableCol5">Active To</div>
                  <div className="rollBasedTableCol6">Created/Updated By</div>
                  <div className="rollBasedTableCol7">Created/Updated Date</div>

                  <div className="rollBasedTableCol8">Edit</div>

                  <div className="rollBasedTableCol9">Delete</div>
                </div>
                {adminRightData?.map((result) => (
                  <div className="rollBasedTable">
                    <div className="rollBasedTableContent">
                      <div className="rollBasedTableCol1">
                        {result.employeeId}
                      </div>

                      <div className="rollBasedTableCol2">
                        {result.adminType}
                      </div>
                      <div className="rollBasedTableCol3">
                        <Button className="statusActive">
                          {result.status}
                        </Button>
                      </div>
                      <div className="rollBasedTableCol4">
                        {result?.activeFrom}
                      </div>
                      <div className="rollBasedTableCol5">
                        {result?.activeTo}
                      </div>

                      <div className="rollBasedTableCol6">
                        {result.createdBy}
                      </div>
                      <div className="rollBasedTableCol7">
                        {result.created_updatedDate}
                      </div>

                      <div
                        style={{ cursor: "pointer" }}
                        className="rollBasedTableCol8"
                      >
                        <img
                          src={
                            process.env.PUBLIC_URL + "/iconImages/gray-edit.svg"
                          }
                          alt=""
                          onClick={(e) => onEditAccess(e, result)}
                        />
                      </div>

                      <div
                        style={{ cursor: "pointer" }}
                        className="rollBasedTableCol9"
                      >
                        <img
                          src={
                            process.env.PUBLIC_URL +
                            "/iconImages/gray-delete.svg"
                          }
                          alt="delete group"
                          onClick={(e) => handleAdminRightDelete(e, result)}
                        />
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </Paper>
            <div className="rollBasedTablePagination">
              <div className="paginationAlignFlex">
                <Stack spacing={2}>
                  <Pagination
                    count={adminRightPages}
                    variant="outlined"
                    shape="rounded"
                    onChange={handleAdminPaginationChange}
                  />
                </Stack>
              </div>
            </div>
          </TabPanel>
          <TabPanel value={value} index={3} className="rollBasedTabPanel">
            <Paper>
              <Grid
                container
                direction="row"
                alignItems="center"
                spacing={2}
                className="rollBasedPaper"
              >
                <Grid item xs={12} sm={6}>
                  <Grid
                    container
                    direction="row"
                    alignItems="center"
                    spacing={2}
                  >
                    <Grid item sm={9}>
                      <SearchBarButton
                        placeholder="search token no."
                        handleSearch={handleSearchAdminReset}
                      />
                    </Grid>
                    <Grid item sm={3} className="rollBasedSearchBarButton">
                      <SubmitButton
                        label="Search"
                        className="rollBasedBtn"
                        onClick={() => setsearchReset(!searchReset)}
                      />
                    </Grid>
                  </Grid>
                </Grid>
                {/* <Grid item xs={12} sm={6}>
                  <Grid
                    container
                    direction="row"
                    alignItems="center"
                    spacing={2}
                  >
                    <Grid item sm={3}>
                      <SubmitButton
                        label="Create"
                        className="rollBasedBtn"
                        onClick={() =>
                          history.push(`${WORK}${ROLE_BASED}${ADD_NEW_ADMIN}`)
                        }
                      />
                    </Grid>
                    <Grid item sm={9} className="rollBasedSearchBarButton">
                      <SearchBarButton 
                      handleSearch={handleSearchAdminRights}
                      />
                    </Grid>
                  </Grid>
                </Grid> */}
              </Grid>
              <div className="rollBasedTable">
                <div className="rollBasedTableHeader">
                  <div className="rollBasedTableCol1">Employee Id(Token)</div>

                  <div className="rollBasedTableCol2">Name</div>

                  <div className="rollBasedTableCol3">Email id</div>

                  <div className="rollBasedTableCol4">Employee Status</div>
                  <div className="rollBasedTableCol5">Reset</div>
                </div>
                {adminResetData?.map((result) => (
                  <div className="rollBasedTable">
                    <div className="rollBasedTableContent">
                      <div className="rollBasedTableCol1">
                        {result.employeeId}
                      </div>

                      <div className="rollBasedTableCol2">
                        {`${result.firstname} ${result.lastname}`}
                      </div>
                      <div className="rollBasedTableCol3">
                        {result?.emailid}
                      </div>
                      <div className="rollBasedTableCol4">
                        <Button className="statusActive">
                          {result.employeestatus}
                        </Button>
                      </div>

                      <div className="rollBasedTableCol5">
                        <SubmitButton
                          label="Reset"
                          style={{ height: "10px" }}
                          className="rollBasedBtn"
                          onClick={() => setButtonReset(!buttonReset)}
                        />
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </Paper>
          </TabPanel>
        </Box>
        <CommonModal
          open={openModal}
          handleClose={handleCloseModal}
          headerText="Add new access"
          icon={addIcon}
          modalSize="md"
          submitBtnText="SAVE"
          footerBannerCss
          fullWidth
          policiesButtonSize
          cancleBtnText="Cancel"
          onCancleButtonClick={handleCloseModal}
          onButtonClick={handleSubmit}
        >
          <AddNewIntranetAccessComponent
            setSelectedMenu={setSelectedMenu}
            setSelectedSubMenu={setSelectedSubMenu}
            setActiveFrom={setActiveFrom}
            setActiveTo={setActiveTo}
            setSelectedUserGroup={setSelectedUserGroup}
            activeFrom={activeFrom}
            activeTo={activeTo}
            selectedUserGroup={selectedUserGroup}
            setSelectedUserGroupName={setSelectedUserGroupName}
            selectedUserGroupName={selectedUserGroupName}
            employeeCount={employeeCount}
            innerLoader={innerLoader}
            handleActiveFromDate={handleActiveFromDate}
            handleActiveToDate={handleActiveToDate}
            selectedSubMenuList={selectedSubMenuList}
            setSelectedSubMenulist={setSelectedSubMenulist}
            setGroupCompanyDetails={setGroupCompanyDetails}
          />
        </CommonModal>
        {/* <CommonModal
          open={openModalNewUserGroup}
          handleClose={handleCloseModal}
          headerText="Create new usergroup"
          icon={addIcon}
          modalSize="md"
          footerBannerCss
          fullWidth
          policiesButtonSize
          cancleBtnText="Cancel"
          onCancleButtonClick={handleCloseModal}
          setSelectedUserGroup={setSelectedUserGroup}
          SelectedSubMenuItemData={SelectedSubMenuItemData}
        >
          <AddNewUserGroupComponent
            updateUserGroupData={updateUserGroupData}
            setupdateUserGroupData={setupdateUserGroupData}
          />
       </CommonModal> */}

        <CommonModal
          open={openEditAccessModal}
          handleClose={handleEditIntranetCloseModal}
          headerText="edit new access"
          icon={addIcon}
          modalSize="md"
          submitBtnText="SAVE"
          footerBannerCss
          fullWidth
          policiesButtonSize
          cancleBtnText="Cancel"
          onCancleButtonClick={handleEditIntranetCloseModal}
          onButtonClick={handleEditIntranetSubmit}
        >
          <EditIntranetAccessComponent
            setSelectedMenu={setSelectedMenu}
            setSelectedSubMenu={setSelectedSubMenu}
            setActiveFrom={setActiveFrom}
            setActiveTo={setActiveTo}
            setSelectedUserGroup={setSelectedUserGroup}
            activeFrom={activeFrom}
            activeTo={activeTo}
            selectedUserGroup={selectedUserGroup}
            setSelectedUserGroupName={setSelectedUserGroupName}
            selectedUserGroupName={selectedUserGroupName}
            employeeCount={employeeCount}
            innerLoader={innerLoader}
            handleActiveFromDate={handleActiveFromDate}
            handleActiveToDate={handleActiveToDate}
            selectedSubMenuList={selectedSubMenuList}
            setSelectedSubMenulist={setSelectedSubMenulist}
            previosValues={previosValues}
            defaultmenuItemData={defaultmenuItemData}
            defaultSubmenuItemData={defaultSubmenuItemData}
            SelectedSubMenuItemData={SelectedSubMenuItemData}
            selecteduserGroupData={selecteduserGroupData}
            setSelecteduserGroupData={setSelecteduserGroupData}
            setSelectedStatus={setSelectedStatus}
            selectedStatus={selectedStatus}
            currentDateType={currentDateType}
            setCurrentDateType={setCurrentDateType}
          />
        </CommonModal>
      </Template>
    </div>
  );
}
