import React from "react";
import "../EthicsHelpLine.css";
import { Card, CardContent, Grid } from "@material-ui/core";
import Template from "../../../Common/Template/Template";
import "../../Policies/PoliciesComponent.css";
import SearchBarButton from "../../../Common/SearchBar/SearchBarButton";
import EthicsWhatsNewCard from "../EthicsWhatsNewCard";
import { useMediaQuery, useTheme } from "@material-ui/core";
// import EthicsCollapse from "../EthicsMobileComponent/EthicsCollapse";
import EthicsMobileCard from "../EthicsMobileComponent/EthicsMobileCard";
import { cardDataInfra } from "../EthicsData";
// import EthicsHelpLineBanner from "../EthicsHelpLineBanner";
import Masonry from "react-masonry-css";
import InfraBuilding from "../../../../Assets/Images/InfraBuilding.svg";
import InfraPhone from "../../../../Assets/Images/InfraPhone.svg";

import CommonAutocomplete from "../../../Common/CommonAutocomplete/CommonAutocomplete";
import CommonLabel from "../../../Common/ComonLabel/CommonLabel";
// import policiesData from "../../Policies/policiesData.json";

export default function InfrastructureSupport() {
  const [placeholder] = React.useState(
    "Search people, tags, sectors or a story name"
  );
  // const [inputValue, setInputValue] = React.useState("");
  const [selectedValue] = React.useState("");
  // const [policyBool, setpolicyBool] = useState(true);
  // const boolReverse = () => {
  //     setpolicyBool(!policyBool);
  // };
  //breakPoint
  const Bp = useTheme();
  const isXs = useMediaQuery(Bp.breakpoints.down("xs"));
  // console.log(EthicsHelplineData)
  const sectionOption = [
    {
      title: "Worli, Mumbai",
      value: "Worli, Mumbai",
    },
    {
      title: "Colaba, Mumabi",
      value: "Colaba, Mumabi",
    },
    {
      title: "Kandivali, Mumbai",
      value: "Kandivali, Mumbai",
    },
    {
      title: "Museum road, banglore",
      value: "Museum road, banglore",
    },
    {
      title: "Kyd Street, Kolkata",
      value: "Kyd Street, Kolkata",
    },
    {
      title: "chowringhee road, Kolkata",
      value: "chowringhee road, Kolkata",
    },
    {
      title: "Pattullos road, Chennai",
      value: "Pattullos road, Chennai",
    },
    {
      title: "Erandwane Pune",
      value: "Erandwane Pune",
    },
  ];
  const style = {
    width: 300,
    marginBottom: "1.5em",
  };

  const breakpointColumnsObj = {
    default: 4,
    1100: 3,
    700: 2,
    500: 1,
  };
  function handleSelectChange(e) {
    // setSelectedValue()
    console.log(e.target.value);
    console.log(selectedValue);
  }

  // function handleInputChange(event) {
  //     setInputValue(event.target.value);
  //     console.log(event.target.value)
  // }
  return (
    <section>
      <Template>
        <Grid
          container
          direction="row"
          alignItems="center"
          className="alignTopHeaderIS"
        >
          <Grid item xs={12} lg={7} sm={12}>
            <p className="offCHeaderTitle">
              Infrastructure & facilities support
            </p>
          </Grid>
          <Grid item xs={12} lg={5} sm={12}>
            <div className="policiesSearchWidth alignLeftInMobile">
              <SearchBarButton placeholder={placeholder} />
            </div>
          </Grid>
        </Grid>
        {/* <Grid
                    container
                    direction="row"
                    justifyContent="space-between"
                    alignItems="center"
                    className="fFSaira"
                >
                    <Grid item xs={12}>
                        <div className="EthicsSubTitlte">
                            <>What’s new</>
                        </div>
                    </Grid>
                    {isXs ? (
                        <>
                            <EthicsCollapse /> <EthicsCollapse /> <EthicsCollapse />
                        </>
                    ) : (
                        <>
                            <Grid item xs={12} style={{ marginTop: "8px" }}>
                                <EthicsHelpLineBanner />
                            </Grid>
                        </>
                    )}
                </Grid> */}

        <>
          {" "}
          <Grid container direction="row">
            <Grid item xs={12}>
              <div className="EthicsSubTitlte">Services</div>
            </Grid>
          </Grid>
          <Grid
            container
            direction="row"
            spacing={2}
            justifyContent="flex-start"
            className="FooterCardDiv"
          >

                <Grid item xs={12}>
                  <Masonry
                    breakpointCols={breakpointColumnsObj}
                    className="my-masonry-grid"
                    columnClassName="my-masonry-grid_column"
                  >
                    {cardDataInfra.map((item, index) => (
                      <div key={index}>
                        <EthicsWhatsNewCard EthicsCardData={item} />
                      </div>
                    ))}
                  </Masonry>
                </Grid>

          </Grid>
          <Grid item xs={12}>
            <div className="EthicsSubTitlte">
              <>Contact Us</>
            </div>
          </Grid>
          <section style={{ width: "100%" }}>
            <Grid
              container
              direction="row"
              justifyContent="space-between"
              alignItems="center"
              className="headerDiv"
            >
              <Grid item xs={12} sm={5}>
                <Card variant="outlined" className="EthicsCardHeader">
                  <CardContent className="EthicsHeaderCardContent">
                    <div>
                      <Grid
                        container
                        direction="column"
                        className="EthicsCardHeaderDetailinfra"
                      >
                        <Grid
                          item
                          sm={11}
                          className="EthicsHeaderTitle mbLabel"
                        >
                          <CommonAutocomplete
                            className="width100"
                            handleSelectChange={handleSelectChange}
                            selectedValue={selectedValue}
                            options={sectionOption}
                            //onChange={ handleInputChange }
                            style={style}
                            label={<CommonLabel label="Select Location" />}
                          />
                        </Grid>
                        <div className="EthicsHeaderData InfraFlex">
                          <img
                            src={InfraBuilding}
                            alt="Download line"
                            className=" infraImg"
                          />
                          <div>
                            <div className="InfraBuildingData1 fFSaira">
                              Mahindra & Mahindra Ltd (Corporate Office)
                            </div>
                            <div className="InfraBuildingData2 fFSaira">
                              Worli Towers, PK Kurne Chowk, Worli, Mumbai -
                              400018
                            </div>
                          </div>
                        </div>
                        <div className="EthicsHeaderData InfraFlex">
                          <img
                            src={InfraPhone}
                            alt="Download line"
                            className=" infraImg"
                          />
                          <div>
                            <div className="InfraBuildingData1">
                              022-4916868
                            </div>
                          </div>
                        </div>
                      </Grid>
                    </div>
                  </CardContent>
                </Card>
              </Grid>
              <Grid item xs={12} sm={7} style={{ height: "200px" }}>
                {/* <img
                                    src={process.env.PUBLIC_URL + "/Images/ongoing-offer.png"}
                                    alt="Download line"
                                    className="EthicsImgHeader"
                                /> */}
                <iframe
                  src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3772.3114324164403!2d72.81958004535564!3d19.005993628559047!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3be7ce9406e41667%3A0x454abbd0f0f44ab7!2sMahindra%20%26%20Mahindra%20Financial%20Services%20Limited!5e0!3m2!1sen!2sin!4v1634713303025!5m2!1sen!2sin"
                  allowfullscreen=""
                  loading="lazy"
                  className="iframeInfra"
                ></iframe>
                {/* <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d15097.973350454819!2d72.81043588678111!3d18.909545394644855!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3be7d19aa30ec295%3A0x1bb7dd5bce3d0bcf!2sColaba%2C%20Mumbai%2C%20Maharashtra!5e0!3m2!1sen!2sin!4v1634723502722!5m2!1sen!2sin" width="600" height="450" style="border:0;" allowfullscreen="" loading="lazy"></iframe> */}
              </Grid>
            </Grid>
          </section>
        </>
      </Template>
    </section>
  );
}
