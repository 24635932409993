import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@material-ui/core";
import React, { useEffect, useState } from "react";
import moment from "moment";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import { useDispatch, useSelector } from "react-redux";
import { getPolicyTableDataByID } from "../../../../Redux/Actions/PolicyCategoryAction";
import EditPolicy from "./EditPolicy";
import Swal from "sweetalert2";
import services from "../../../../Services";
import CommonModal from "../../../Common/CommonModal/CommonModal";
import CommonCheckBox from "../../../Common/CommonCheckBox/CommonCheckBox";
import "./sub.css";

function PolicyTable({ element }) {
  const [open, setopen] = useState(false);
  const [refresh, setrefresh] = useState(false);
  const [curretPolicyData, setCurretPolicyData] = useState({});

  const dispatch = useDispatch();

  useEffect(() => {
    if (element.policySubCategoryId) {
      dispatch(getPolicyTableDataByID(element.policySubCategoryId));
    }
  }, [refresh]);

  const getPolicyDataState = useSelector(
    (state) => state.getPolicyTableDataReducer
  );
  const { getData: policyTableData } = getPolicyDataState;

  const handlePolicyEdit = (e, row) => {
    setopen(true);
    setCurretPolicyData(row);
  };

  const handleCategoryDeleteApi = async (data) => {
    Swal.fire({
      title: "Are you sure?",
      text: "All Policies in this category will be deleted",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        services
          .api("DELETE", `/ui-assist/v1/policy/${data}`)
          .then((res) => {
            Swal.fire("Deleted!", "Policy has been deleted.", "success");
            setrefresh(!refresh);
          })
          .catch((err) => {
            Swal.fire({
              icon: "error",
              title: "Oops...",
              text: "Something went wrong!",

              footer: `${err.message}`,
            });
          });
      }
    });
  };

  const handlePolicyDelete = (e, row) => {
    handleCategoryDeleteApi(row.policyId);
  };

  return (
    <>
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>
                <CommonCheckBox />
              </TableCell>

              <TableCell>Sr. No.</TableCell>
              <TableCell align="right">Name</TableCell>
              <TableCell align="right">Description</TableCell>
              <TableCell align="right">Last modified by</TableCell>
              <TableCell align="right">Modified on</TableCell>
              <TableCell align="right" className="lastTcellSub">
                <img
                  src={process.env.PUBLIC_URL + "/policies/delete-row.svg"}
                  alt="Add"
                />
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {policyTableData &&
              policyTableData.map((row, index) => (
                <TableRow
                  key={row.policyId}
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                >
                  <TableCell>
                    <CommonCheckBox />
                  </TableCell>
                  <TableCell component="th" scope="row">
                    {row.srNo}
                  </TableCell>
                  <TableCell align="right">{row.policyName}</TableCell>
                  <TableCell align="right">{row.description}</TableCell>
                  <TableCell align="right">{row.modifiedBy}</TableCell>
                  <TableCell align="right">
                    {moment(row.updatedAt).format("DD/MM/YYYY")}
                  </TableCell>
                  <TableCell align="right">
                    {/* <EditIcon
                style = {{ cursor: "pointer" }}
                onClick = {(e) => {handlePolicyEdit(e, row)}}
                /> <DeleteIcon 
                style = {{ cursor: "pointer" }}
                onClick = {(e) => {handlePolicyDelete(e,row)}}
                /> */}
                    <img
                      src={process.env.PUBLIC_URL + "/policies/edit-row.svg"}
                      alt="Add"
                      onClick={(e) => {
                        handlePolicyEdit(e, row);
                      }}
                      style={{ cursor: "pointer" }}
                    />

                    {/* <DeleteIcon
                      style={{ cursor: "pointer" }}
                      onClick={(e) => {
                        handleDelete(e,row);
                      }}
                    /> */}
                    <img
                      src={process.env.PUBLIC_URL + "/policies/delete-row.svg"}
                      alt="Add"
                      style={{ cursor: "pointer", marginLeft: "40px" }}
                      onClick={(e) => {
                        handlePolicyDelete(e, row);
                      }}
                    />
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>

      <EditPolicy open={open} setopen={setopen} element={curretPolicyData} />
    </>
  );
}

export default PolicyTable;
