import Swal from "sweetalert2";
import services from "../../Services/index";
import {
  GET_BENEFIT_MASTER_SUCCESS,
  GET_BENEFIT_MASTER_FAILED,
  GET_BENEFIT_MASTER_LOAD,
  EDIT_BENEFIT_MASTER_SUCCESS,
  EDIT_BENEFIT_MASTER_FAILED,
  EDIT_BENEFIT_MASTER_LOAD
} from "../Contants/Constants";


//GET ENTITLEMENT BENEFITS MASTER DATA

const getBenefitMasterLoad = () => ({
  type: GET_BENEFIT_MASTER_LOAD
});
const getBenefitMasterSuccess = (data) => ({
  type: GET_BENEFIT_MASTER_SUCCESS,
  payload: data
});
const getBenefitMasterFailed = (error) => ({
  type: GET_BENEFIT_MASTER_FAILED,
  err: error
});

export const getBenefitMasterData = () => async (dispatch) => {
  dispatch(getBenefitMasterLoad());
  try {
    const res = await services.api(
      "GET",
      `/ui-assist/v1/entitlement/entitlementcategory`
    );
    if (res) {
      dispatch(getBenefitMasterSuccess(res.data));
    }
  } catch (err) {
    dispatch(getBenefitMasterFailed(err.response));
    Swal.fire("Something went wrong!", `${err?.response?.data}`, "error");
  }
};


//EDIT ENTITLEMENT BENEFITS MASTER DATA

const editBenefitMasterLoad = () => ({
  type: EDIT_BENEFIT_MASTER_LOAD
});
const editBenefitMasterSuccess = (data) => ({
  type: EDIT_BENEFIT_MASTER_SUCCESS,
  payload: data
});
const editBenefitMasterFailed = (error) => ({
  type: EDIT_BENEFIT_MASTER_FAILED,
  err: error
});

export const editBenefitMasterData = (data,id) => async (dispatch) => {
  dispatch(editBenefitMasterLoad());
  try {
    const res = await services.api(
      "PUT",
      `/ui-assist/v1/entitlement/entitlementcategory/${id}`, data
    );
    if (res) {
      dispatch(editBenefitMasterSuccess(res.data));
    }
  } catch (err) {
    dispatch(editBenefitMasterFailed(err.response));
    Swal.fire("Something went wrong!", `${err?.response?.data}`, "error");
  }
};