import React from "react";
import "./DetailsPriorityTasks.css";
import "../../../Header/Notifications/NotificationsSetting.css";
import {
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableBody,
  withStyles,
  Card,
  Divider,
  useMediaQuery,
  Container,
} from "@material-ui/core";
import MuiTableCell from "@material-ui/core/TableCell";
import { Link } from "react-router-dom";
import arrowRight from "../../../../Assets/Images/arrow-down-s-line.svg";

const TableCell = withStyles({
  root: {
    borderBottom: "none",
    marginTop: "1em",
  },
})(MuiTableCell);

function DetailsPriorityTasks({ detailsPriorityTasks }) {
  const isMobile = useMediaQuery("(max-width:768px)");

  return (
    <>
      {!isMobile ? (
        <TableContainer className="fixHeightTable tableMainContainer">
          <Table className="tableSpace" aria-label="simple table">
            <TableHead>
              <TableRow className="tableRow">
                <TableCell className="tableCellNameHead commonFontNormalStyle">
                  Name
                </TableCell>
                <TableCell className="tableCellNameHead commonFontNormalStyle">
                  Task
                </TableCell>
                <TableCell className="tableCellNameHead commonFontNormalStyle">
                  Due Date
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {detailsPriorityTasks.map((result, index) => (
                <TableRow className="tableRow_body" key={index}>
                  <TableCell
                    component="th"
                    scope="row"
                    className="tableCellName"
                  >
                    <ProfileDetails
                      name={result.name}
                      path={`/header/profile.png`}
                    />
                  </TableCell>
                  <TableCell align="left" className="tableCellName">
                    <span className="nameStylePriorityModal commonFontNormalStyle">
                      {result.task}
                    </span>
                  </TableCell>
                  <TableCell align="left" className="tableCellName">
                    <span className="nameStylePriorityModal commonFontNormalStyle">
                      {result.dueDate}
                    </span>
                  </TableCell>
                  {result.viewDetails && (
                    <TableCell align="left" className="tableCellName">
                      <Link
                        // className="nameStylePriorityModal commonFontNormalStyle"
                        to="#"
                        className="viewDetails commonFontNormalStyle"
                      >
                        View Details
                      </Link>
                    </TableCell>
                  )}
                  {!result.viewDetails && (
                    <TableCell
                      align="left"
                      className="tableCellName"
                    ></TableCell>
                  )}
                  {result.viewDetails && (
                    <TableCell align="left" className="tableCellName">
                      <div className="completeTaskText commonFontNormalStyle">
                        Complete Task
                        <img src={arrowRight} alt="arrow_right" />
                      </div>
                    </TableCell>
                  )}
                  {!result.viewDetails && (
                    <TableCell
                      align="left"
                      className="tableCellName"
                    ></TableCell>
                  )}
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      ) : (
        <div className="vScroll">
          <Container className="alignTopCard">
            {detailsPriorityTasks.map((result, index) => (
              <Card className="MobileViewDetailsPriorityTasks" key={index}>
                <div className="displaySpaceBetween leftRightPadding">
                  <p className="mobileViewFontTableHead">Name</p>
                  <ProfileDetails
                    name={result.name}
                    path={`/header/profile.png`}
                  />
                </div>
                <Divider />
                <div className="displaySpaceBetween leftRightPadding">
                  <p className="mobileViewFontTableHead">Task</p>
                  <p className="mobileViewFontTableCell">{result.task}</p>
                </div>
                <Divider />
                <div className="displaySpaceBetween leftRightPadding">
                  <p className="mobileViewFontTableHead">Date</p>
                  <p className="mobileViewFontTableCell">{result.dueDate}</p>
                </div>
                <Divider />
                {result.viewDetails && (
                  <div className="displaySpaceBetween viewCompleteTask">
                    <Link
                      className="nameStylePriorityModal commonFontNormalStyle"
                      to="#"
                      style={{ textDecoration: "none" }}
                    >
                      View Details
                    </Link>
                    <div className="completeTaskText commonFontNormalStyle">
                      Complete Task
                      <img src={arrowRight} alt="arrow_right" />
                    </div>
                  </div>
                )}
              </Card>
            ))}
          </Container>
        </div>
      )}
    </>
  );
}

const ProfileDetails = ({ name, path }) => {
  return (
    <div className="nameContainer">
      <div className="profileContainerPriorityTask">
        <img
          src={process.env.PUBLIC_URL + path}
          className="profileStyle"
          alt="profile"
        />
      </div>
      <span className="nameStylePriorityModalMobile commonFontNormalStyle">
        {name}
      </span>
    </div>
  );
};

export default DetailsPriorityTasks;
