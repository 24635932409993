import React, { useState, useEffect } from "react";
import SubmitButton from "../../../Common/Button/SubmitButton";
import moment from "moment";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Grid,
  Tab,
  Tabs,
  Typography,
} from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { Box } from "@mui/system";
import PropTypes from "prop-types";
import CharLeftField from "../../../Common/CharLeftField/CharLeftField";
import CommonLabel from "../../../Common/ComonLabel/CommonLabel";
import SearchBarButton from "../../../Common/SearchBar/SearchBarButton";
import Swal from "sweetalert2";

import PLUS_ICON from "../../../../Assets/Images/Plus.svg";
import BIN_ICON from "../../../../Assets/Images/delete-bin-line.svg";
import COMP_LINE from "../../../../Assets/Images/collectiveIcons/computer-line.svg";
import CONTACT_FILL from "../../../../Assets/Images/collectiveIcons/contacts-fill.svg";
import CONTACT_BOOK from "../../../../Assets/Images/collectiveIcons/contacts-book-2-line.svg";
import COUPON_LINE from "../../../../Assets/Images/collectiveIcons/coupon-2-line.svg";

// import { getLastModifiedData } from "../../../../Redux/Actions/CommonActions";
import { getFormattedDate } from "../../../../Assets/helperFunctions/helperFunctions";
import { editEmergencyContactData, addEmergencyContactData, deleteEmergencyContactData } from "../../../../Redux/Actions/AllEmergencyContactAction";
import { getUploadImageData } from "../../../../Redux/Actions/UploadImageAction";
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";

const iconOpt = [
    { icon: COMP_LINE, name: "Computer - line" },
    { icon: CONTACT_FILL, name: "Contact - fill" },
    { icon: CONTACT_BOOK, name: "Contact - book" },
    { icon: COUPON_LINE, name: "Coupon - line" },
    { icon: COMP_LINE, name: "Computer - line" },
  ];

function EditEmergencyContact(item,index,type,loc) {
    const history = useHistory();
    let dispatch = useDispatch();
    const allData = useSelector((state) => state.getAllEmergencyContactReducer);
    const uploadImage = useSelector((state) => state.getUploadImageReducer);
  
    const [data, setData] = useState(item?.item);
    const [title, setTitle] = useState("");
    const [icon, setIcon] = useState("");
    const [description, setDescription] = useState("");
    const [phoneData, setPhoneData] = useState([]);
    const [emailId, setEmailId] = useState([]);
    const [website, setWebsite] = useState([]);
    const [actionName, setActionName] = useState("");
    const [actionUrl, setActionUrl] = useState("");
    const [location, setLocation] = useState("");
    const [id, setId] = useState("");
    const [changeImage, setChangeImage] = useState(false);

    useEffect(() => {
        setTitle(data ? data?.title : "");
        setIcon(data ? data?.icon : "");
        setDescription(data ? data?.description : "");
        setPhoneData(data ? data?.address : []);
        setEmailId(data ? data?.email : []);
        setWebsite(data ? data?.website : []);
        setActionName(data ? data?.actionName : "");
        setActionUrl(data ? data?.actionUri : "");
        setLocation(data ? data?.location : item?.loc);
        setId(data ? data?.id : "");
    },[data]);

    useEffect(() => {
        if(uploadImage?.getData?.image && changeImage)
        { 
            setIcon(uploadImage?.getData?.image);
            setChangeImage(false);
        }
    },[uploadImage?.getData?.image]);

    // useEffect(() => {
    //   dispatch(getLastModifiedData("emergency"));
    // }, []);
  
    function TabPanel(props) {
      const { children, value, index, ...other } = props;
  
      return (
        <div
          role="tabpanel"
          hidden={value !== index}
          id={`simple-tabpanel-${index}`}
          aria-labelledby={`simple-tab-${index}`}
          {...other}
        >
          {value === index && (
            <Box p={3}>
              <Typography>{children}</Typography>
            </Box>
          )}
        </div>
      );
    }
    TabPanel.propTypes = {
      children: PropTypes.node,
      index: PropTypes.any.isRequired,
      value: PropTypes.any.isRequired,
    };
    const [tab, setTab] = useState(0);
    const handleChangeTab = (event, newValue) => {
      setTab(newValue); 
    };

  
    const [hideModelFlag, setHideModelFlag] = React.useState(false);

    
    const handleTitle = (e) => setTitle(e.target.value);
    const handleDescription = (e) => setDescription(e.target.value);
    const AddPhoneClick = () =>{
        const a = {
            location: "",
            contact: "",
            extension: "",
          };
        setPhoneData([...phoneData, a]);
    };
    function deletePhoneData(e,index) {
        let data1 = JSON.parse(JSON.stringify(phoneData));
        data1.splice(index,1);
        setPhoneData([...data1]);
    };
    function handlePhoneData(e,index,varname) {
        let pData = JSON.parse(JSON.stringify(phoneData));
        varname == "location" ?
            pData[index].location = e.target.value
            :
            varname == "contact" ?
                pData[index].contact = e.target.value
                :
                pData[index].extension = e.target.value
        // console.log("phone data ======> ", pData, "pData[index] ====> ", pData[index]);
        setPhoneData([...pData]);
    };

    const AddMailClick = () =>{
        const a = "";
        setEmailId([...emailId, a]);
    };
    function deleteMail(e,index) {
        let data = JSON.parse(JSON.stringify(emailId));
        data.splice(index,1);
        setEmailId([...data]);
    };
    function handleMail(e,index) {
        let pData12 = JSON.parse(JSON.stringify(emailId));
        pData12[index] = e.target.value
        setEmailId([...pData12]);
    };
    
    const AddWebsiteClick = () =>{
        const a = "";
        setWebsite([...website, a]);
    };
    function deleteWebsite(e,index) {
        let data = JSON.parse(JSON.stringify(website));
        data.splice(index,1);
        setWebsite([...data]);
    };
    function handleWebsite(e,index) {
        let pData = JSON.parse(JSON.stringify(website));
        pData[index] = e.target.value;
        setWebsite([...pData]);
    };
    const handleIcon = (e) => {
        const file = e.currentTarget.files[0];
        setChangeImage(true);
      const formData = new FormData();
      formData.append("media", file);
      formData.append("name", file.name);
      dispatch(getUploadImageData(formData));
      setHideModelFlag(false);
    //   console.log("uploaded image", file);
    };
    const handleActionName = (e) => setActionName(e.target.value);
    const handleActionUrl = (e) => setActionUrl(e.target.value);

    const handleEdit = (e) => {
        e.preventDefault();
        const editdata = {
            id: id,
            title: title,
            description: description,
            icon: icon,
            image: true,
            address: phoneData,
            email: emailId,
            website: website,
            location: location,
            actionName: actionName,
            actionUri: actionUrl,
        };
        if(title !== "" && icon !== "")
        { 
            dispatch(editEmergencyContactData(editdata));
            CheckEdit();
        }
        else if(title == "")
        { Swal.fire("Please enter title.", " ", "error") }
        else
        { Swal.fire("Please select icon.", " ", "error") }
    };
    const handleAdd = (e) => {
        // e.preventDefault();
        const adddata = {
            title: title,
            icon: icon,
            description: description,
            image: true,
            address: phoneData,
            email: emailId,
            website: website,
            location: location,
            actionName: actionName,
            actionUri: actionUrl,
        };
        if(title !== "" && icon !== "")
        { 
            dispatch(addEmergencyContactData(adddata));
            CheckAdd();
        }
        else if(title == "")
        { Swal.fire("Please enter title.", " ", "error") }
        else
        { Swal.fire("Please select icon.", " ", "error") }
    };
    const handleDelete = (e) => {
        e.preventDefault();
        const deletedata = {
            title: title,
            icon: icon,
            image: true,
            address: phoneData,
            email: emailId,
            website: website,
            location: location,
        };
        Swal.fire({
            title: "Are you sure?",
            text: "You want to delete this service?",
            icon: "warning",
            showCancelButton: true,
            confirmButtonText: "Yes, delete it!",
            cancelButtonText: "No, keep it",
          }).then(
               (result) => {
            if (result.isConfirmed) {
                dispatch(deleteEmergencyContactData(deletedata,id));
                CheckDelete();
            } else if (result.dismiss === Swal.DismissReason.cancel) {
              Swal.fire("Cancelled", "Your service is safe :)", "error");
            }
          });
    };

    const CheckAdd = () => {
        if(allData?.addData)
        {
            Swal.fire("Service added successfully.", " ", "success");
            history.push("/emergency-contacts");
        }
    };
    const CheckEdit = () => {
        if(allData?.editedData)
        {
            history.push("/emergency-contacts");
            Swal.fire("Service edited successfully.", " ", "success");
        }
    };
    const CheckDelete = () => {
        if(allData?.deleteData)
        {
            Swal.fire("Service deleted successfully.", " ", "success");
            history.push("/emergency-contacts");
        }
    };

    // console.log("item data ====> ", item);
    // console.log("index ====> ", index);
    // console.log("selected icon =====> ", icon);
    // console.log("action ====>", item?.type);
    // console.log("all data ===>", allData);
    // console.log("uploaded image ====>", uploadImage);
  return (
    <>
      <Accordion
        className="accordionContainer pl8 pr8 mb16"
        key={item?.index}
      >
        <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="panel1a-content"
        id="panel1a-header"
        >
            {item?.type ?
                <div className="mainAccContainer">
                    <div className={"accordionStyle accTitletxt"}>
                        <span style={{color:"blue"}}>Add Service here</span>
                    </div>
                </div>
                :
                <div className="mainAccContainer">
                    <div className={"accordionStyle accTitletxt"}>
                        {title}
                    </div>
                    <div className={"lableClass accLabletxt"}>
                        {description}
                    </div>
                </div>
            }
        </AccordionSummary>
        <AccordionDetails>
        <Grid container alignItems="center">
            <Grid item md={12} xs={12} className="contentPadding">
            <span className="Complete-Tax-Declara">Details</span>
            </Grid>
            <Grid item md={6} xs={12} className="contentPadding">
            <CharLeftField
                label={<CommonLabel label="Title" mandatory />}
                name="title"
                type="text"
                value={title}
                handleChange={handleTitle}
            />
            </Grid>
            <Grid item md={6} xs={12} className="contentPadding">
            <div
                className="upperCharLeft"
                onClick={() => {
                setHideModelFlag(!hideModelFlag);
                }}
            >
                <CharLeftField
                label={<CommonLabel label="Icon" />}
                value={item?.type && icon ? icon : icon}
                name="icon"
                type="text"
                />
            </div>
            {hideModelFlag ? (
                <div className="customSelectBox">
                <Tabs onChange={handleChangeTab} value={tab}>
                    {/* <Tab label="Select an icon" className="favTabs" /> */}
                    <Tab
                    label="Upload an image"
                    className="favTabs"
                    />
                </Tabs>
                {/* <TabPanel value={tab} index={0}>
                    <Grid container>
                    <Grid item md={12} xs={12}>
                        <SearchBarButton placeholder="Search article"></SearchBarButton>
                    </Grid>
                    <Grid
                        item
                        md={12}
                        className="mt25 addScrollCls"
                    >
                        {iconOpt.map((item1, index) => (
                        <div key={index} className="optRoot" onClick={() => setIcon(item1.icon)}>
                            <img src={item1.icon} alt="" />
                            <div className="Tasks-that-need-your">
                            {item1.name}
                            </div>
                        </div>
                        ))}
                    </Grid>
                    </Grid>
                </TabPanel> */}
                <TabPanel value={tab} index={0}>
                    <div className="uploadImg">
                    <label
                        for="thumb"
                        className="btn uploadBtnLabel"
                    >
                        <span className="colRed">
                        CHOOSE AN IMAGE
                        </span>
                    </label>
                    <input
                        id="thumb"
                        style={{
                        visibility: "hidden",
                        padding: "19px 16px",
                        position: "absolute",
                        }}
                        type="file"
                        accept=".png, .jpg"
                        name="thumbnail"
                        onChange={handleIcon}
                    />
                    </div>
                    <div className="recommendatinTxt">
                    Recommended size is 280 x 280 pixels
                    </div>
                </TabPanel>
                </div>
            ) : null}
            </Grid>
            <Grid item md={12} xs={12} className="contentPadding">
            <CharLeftField
                label={<CommonLabel label="Description" />}
                name="description"
                type="text"
                value={description}
                handleChange={handleDescription}
            />
            </Grid>
            <Grid item md={12} xs={12} className="contentPadding">
            <span className="Complete-Tax-Declara">Contacts</span>
            </Grid>
            <Grid item md={12} xs={12} className="contentPadding">
            <div className="contactContainer">
                <div className="contactsField">Phone number</div>
                <span
                style={{ display: "flex", cursor: "pointer" }}
                onClick={AddPhoneClick}
                >
                <img src={PLUS_ICON} alt="" />
                <div className="add">ADD</div>
                </span>
            </div>
            </Grid>
            {phoneData?.length > 0 ? (
            
                phoneData?.map((item, index) => (
                <Grid key={index} container>
                    <Grid
                    item
                    md={4}
                    xs={12}
                    className="contentPadding"
                    >
                    <CharLeftField
                        label={<CommonLabel label="Name" />}
                        name="name"
                        value={item.location}
                        handleChange={(e) => 
                                    {   const varname="location";
                                        handlePhoneData(e,index, varname);}}
                        type="text"
                    />
                    </Grid>
                    <Grid
                    item
                    md={4}
                    xs={12}
                    className="contentPadding"
                    >
                    <CharLeftField
                        label={<CommonLabel label="Phone number" />}
                        name="phoneNumber"
                        value={item.contact}
                        handleChange={(e) => 
                                    {   const varname="contact";
                                        handlePhoneData(e,index, varname);}}
                        type="text"
                    />
                    </Grid>
                    <Grid
                    item
                    md={3}
                    xs={12}
                    className="contentPadding"
                    >
                    <CharLeftField
                        label={<CommonLabel label="Extention" />}
                        name="extension"
                        value={item.extension}
                        handleChange={(e) => 
                                    {   const varname="extension";
                                        handlePhoneData(e,index, varname);}}
                        type="text"
                    />
                    </Grid>
                    <Grid
                    style={{ cursor: "pointer" }}
                    item
                    md={1}
                    xs={12}
                    className="contentPadding centerIcon"
                    // onClick={() => handleDeletePhoneData(index)}
                    >
                    <img
                        src={BIN_ICON}
                        alt="Delete Button"
                        onClick={(e) => deletePhoneData(e,index)}
                    />
                    </Grid>
                </Grid>
                ))
            
            ) : null}
            <Grid item md={12} xs={12} className="contentPadding">
            <div className="contactContainer">
                <div className="contactsField">Email ID</div>
                <span
                style={{ display: "flex", cursor: "pointer" }}
                onClick={AddMailClick}
                >
                    <img src={PLUS_ICON} alt="" />
                    <div className="add">ADD</div>
                </span>
            </div>
            </Grid>
            {emailId?.length > 0 ? (
            
                emailId?.map((item, index) => (
            <Grid key={index} container>
                <Grid item md={4} xs={12} className="contentPadding" >
                    <CharLeftField
                        label={<CommonLabel label="Email Id" />}
                        name="emailId"
                        value={item}
                        handleChange={(e) => handleMail(e,index)}
                        type="text"
                    />
                </Grid>
                
                <Grid
                    style={{ cursor: "pointer" }}
                    item
                    md={1}
                    xs={12}
                    className="contentPadding centerIcon"
                    // onClick={() => handleDeletePhoneData(index)}
                >
                    <img
                        src={BIN_ICON}
                        alt="Delete Button"
                        onClick={(e) => deleteMail(e,index)}
                    />
                </Grid>
            </Grid>
            ))
        
        ) : null}
            <Grid item md={12} xs={12} className="contentPadding">
            <div className="contactContainer">
                <div className="contactsField">Website</div>
                <span
                style={{ display: "flex", cursor: "pointer" }}
                onClick={AddWebsiteClick}
                >
                    <img src={PLUS_ICON} alt="" />
                    <div className="add">ADD</div>
                </span>
            </div>
            </Grid>
            {website?.length > 0 ? (
            
                website?.map((item, index) => (
            <Grid key={index} container>
                <Grid item md={4} xs={12} className="contentPadding" >
                    <CharLeftField
                        label={<CommonLabel label="URL" />}
                        name="website"
                        value={item}
                        handleChange={(e) => handleWebsite(e,index)}
                        type="text"
                    />
                </Grid>
                
                <Grid
                    style={{ cursor: "pointer" }}
                    item
                    md={1}
                    xs={12}
                    className="contentPadding centerIcon"
                    // onClick={() => handleDeletePhoneData(index)}
                >
                    <img
                        src={BIN_ICON}
                        alt="Delete Button"
                        onClick={(e) => deleteWebsite(e,index)}
                    />
                </Grid>
            </Grid>
            ))
        
        ) : null}
            <Grid item md={12} xs={12} className="contentPadding">
            <span className="Complete-Tax-Declara">Action</span>
            </Grid>
            <Grid item md={6} xs={12} className="contentPadding">
            <CharLeftField
                label={<CommonLabel label="Action Name" />}
                name="actionName"
                type="text"
                value={actionName}
                handleChange={handleActionName}
            />
            </Grid>
            <Grid item md={6} xs={12} className="contentPadding">
            <CharLeftField
                label={<CommonLabel label="Action URL" />}
                name="actionUrl"
                value={actionUrl}
                handleChange={handleActionUrl}
                type="text"
            />
            </Grid>
            <Grid
            container
            alignItems="center"
            justifyContent="flex-end"
            >
                {item?.type ?
                    
                    <Grid item md={2} xs={12} className="contentPadding">
                        <SubmitButton
                        onClick={handleAdd}
                        label="Add Service"
                        className="MCsubmitbutton floatRightAlign"
                        />
                    </Grid>
                    :
                    <>
                        <Grid item md={2} xs={12} className="contentPadding">
                            <span className="Delete-Service" onClick={handleDelete}>DELETE SERVICE</span>
                        </Grid>
                        <Grid item md={2} xs={12} className="contentPadding">
                            <SubmitButton
                            onClick={handleEdit}
                            label="Save changes"
                            className="MCsubmitbutton floatRightAlign"
                            />
                        </Grid>
                    </>
                }
            </Grid>
        </Grid>
        </AccordionDetails>
      </Accordion>
    </>
  );
}

export default EditEmergencyContact;