import React, { useState, useEffect } from "react";
import { Card, Grid, Paper, Button, Icon } from "@material-ui/core";
// import Checkbox from "@material-ui/core/Checkbox";
// import TextField from "@material-ui/core/TextField";
// import Autocomplete from "@material-ui/lab/Autocomplete";
// import CheckBoxOutlineBlankIcon from "@material-ui/icons/CheckBoxOutlineBlank";
// import CheckBoxIcon from "@material-ui/icons/CheckBox";
import "./FilterSearchDataComponent.css";
import SearchBarButton from "../../Common/SearchBar/SearchBarButton";
import Template from "../../Common/Template/Template";
import { useLocation } from "react-router-dom";
import MultiSelect from "../../Common/MultiSelect/MultiSelect";
import CommonLabel from "../../Common/ComonLabel/CommonLabel";
import services from "../../../Services";
// import Pagination from "../../Common/Pagination/Pagination";
import { useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";
import { addAnalayticsLog } from "../../../Redux/Actions/Anaylatics";
import {
  LIVEWIRE,
  LIVE_WIRE_ARTICLE2,
  POLICIES,
  POLICIES_DETAILS,
  PROFILE_PAGE,
  Support,
  RemoteAArticle,
  LIVE_WIRE_ARTICLE3,
  ITSupportt,
  EMERGENCY_CONTACTS,
} from "../../../Routes/constant";
import CustomMultiSelect from "../../Common/CustomMultiSelect/CustomMultiSelect";
import moment from "moment";
import { AspectRatio } from "react-aspect-ratio";
import platform from "platform";
import Highlighter from "react-highlight-words";
import { Stack } from "@mui/material";
import { Pagination } from "@material-ui/lab";
import { offsetCount } from "../../../Assets/helperFunctions/helperFunctions";

const options = [
  {
    title: "Mahindra collective",
    value: "mahindracollective",
  },
  {
    title: "Mahindra experience",
    value: "mahindraexperience",
  },
  {
    title: "People",
    value: "people",
  },
  {
    title: "Images",
    value: "image",
  },
  {
    title: "Videos",
    value: "video",
  },
  {
    title: "Document",
    value: "document",
  },
  {
    title: "Sites and sub sites",
    value: "site",
  },
  {
    title: "Tiles",
    value: "tile",
  },
  {
    title: "Content",
    value: "content",
  },
  /* {
    title: "Whats new",
    value: "whatsnew",
  },*/
  {
    title: "Org announcement",
    value: "organnouncement",
  },
  {
    title: "Policy",
    value: "policy",
  },

  {
    title: "Offers and campaign",
    value: "offerorcampaign",
  },
  {
    title: "Support services",
    value: "supportservices",
  },
  /* {
    title: "Remote services",
    value: "remoteservices",
  },*/
  {
    title: "Emergency Contact",
    value: "emergencycontact",
  },
];

export default function FilterSearchDataComponent() {
  const focusStyle = { color: "#db233a" };
  const [focus, setFocus] = useState(false);
  const location = useLocation();
  const history = useHistory();
  const dispatch = useDispatch();
  // const { searchText, searchOptions } = location?.state;
  const [searchText, setSearchText] = useState("");
  const [searchOptions, setSearchOptions] = useState([]);
  const [searchData, setSearchData] = useState([]);
  const [selectedOptions, setSelectedOptions] = useState(
    location?.state?.selectedOptions
  );
  const [querryName, setQuerryName] = useState("");
  const [querryValue, setQuerryValue] = useState("");
  const [querrySearchObject, setQuerrySearchObject] = useState({});
  const [querryNewValue, setQuerryNewValue] = useState([]);
  const [querryData, setQuerryData] = useState({});
  const [currentPage, setCurrentPage] = useState(1);
  const [keyPressValue, setKeyPressValue] = useState("");

  /* == Code for pagination === */
  const [totalPages, setTotalPages] = useState(1);
  const [pageNo, setPageNo] = useState(1);
  const [onPageChange, setOnPageChange] = useState(false);

  const [totalRecords, setTotalRecords] = useState(0);

console.log("selected options from location state =====>", location?.state?.selectedOptions);
console.log("selected options from local state =====>", selectedOptions);

  const handlePageChange = (e, val) => {
    setPageNo(val);
    setOnPageChange(true);
    // handleSearch();
    // handleApi();
  };

  // useEffect(() => {
  //   setOnPageChange(true);
  // }, []);

  useEffect(() => {
    if (onPageChange == true) {
      handleSearch();
      handleApi();
      setOnPageChange(false);
    }
  }, [onPageChange]);

  useEffect(() => {
    setPageNo(1);
    // setSearchData([]);
  }, [searchText, selectedOptions]);
  // useEffect(() => {
  //   handleSearch();
  // }, [searchText]);

  useEffect(() => {
    if (pageNo === 1) {
      setTotalRecords(searchData?.hits?.total?.value);
    }
  }, [searchData]);

  // useEffect(() => {
  //   handleApi();
  // }, [pageNo]);

  useEffect(() => {
    if (pageNo == 1) {
      if (totalRecords) {
        setTotalPages(offsetCount(totalRecords, 10));
      }
    }
  }, [totalRecords]);

  const handleSearch = async (e, newValue) => {
    let getSearchObject = {};
    let getSearch = [...selectedOptions];
    let getGlobal = false;
    if (!newValue) {
      getSearch.forEach((result) => {
        if (result.value === "all") {
          return (getGlobal = true);
        } else {
          return (getSearchObject[result.value] = true);
        }
      });
    }
    if (e) {
      if (e.target.name === "search") {
        setSearchText(e.target.value);
      } else {
        setSearchOptions(newValue);
      }
    }
    if (e?.target?.value === "") {
      // setSearchText(location?.state?.searchText);
      setSearchOptions([]);
      setSearchData([]);
    }

    let data = {};
    if (newValue) {
      newValue.forEach((result) => {
        if (result.value === "all") {
          return (getGlobal = true);
        } else {
          return (data[result.value] = true);
        }
      });
    }

    if (getGlobal) {
      try {
        let res = await services.api(
          "GET",
          `/ui-assist/v1/_all/_search?q=${
            e?.target?.name === "search" ? e?.target?.value : searchText
          }&employeeId=${localStorage.getItem("token")}`
        );
        if (res.status === 200) {
          setSearchData(res.data);
        }
      } catch (err) {
        setSearchData([]);
        console.log("err from search all:", err);
      }
    } else {
      setQuerryName(e?.target?.name);
      setQuerryValue(e?.target?.value);
      setQuerrySearchObject(getSearchObject);
      setQuerryNewValue(newValue);
      setQuerryData(data);
      //  console.log(e.target.value, 'value');
      //  console.log(e.target.name, 'name');
      //  console.log(searchText, 'searchTxt');
      //  console.log(newValue, 'newValue')
      //  console.log(data, 'data');
      //  console.log(getSearchObject, 'searchObject')
    }
  };

  useEffect(() => {
    setSearchText(location?.state?.searchText);
    setSearchOptions(location?.state?.selectedOptions);
    setSearchData(location?.state?.searchData);
  }, []);

  const handleCategory = async (list, categoryName) => {
    console.log(list, "list");
    console.log(categoryName, "category name ");
    if (categoryName === "mahindracollective") {
      dispatch(
        addAnalayticsLog({
          module: "mahindra-collective",
          browserDetails: `${platform.name} ${platform.os.architecture} ${platform.os.family} ${platform.os.version}`,
          deviceDetails: platform.product ? platform.product : "Desktop",
          isSearch: true,
        })
      );
      history.push({
        pathname: `${LIVEWIRE}${LIVE_WIRE_ARTICLE2}/?id=${list.id}`,
      });
    }else if (categoryName === "mahindraexperience") {
      dispatch(
        addAnalayticsLog({
          module: "mahindra-experience",
          browserDetails: `${platform.name} ${platform.os.architecture} ${platform.os.family} ${platform.os.version}`,
          deviceDetails: platform.product ? platform.product : "Desktop",
          isSearch: true,
        })
      );
      history.push({
        pathname: `${LIVEWIRE}/experience-detail/${list.id}`,
        state: {
          list: list ? list : { name: "neeraj" },
          typeCheckForMahindra: "mahindraexperience",
          contentType: "",
          empPhotoUrl: "",
        },
      });
    } else if (categoryName === "offerorcampaign") {
      dispatch(
        addAnalayticsLog({
          module: "offer-campaigns",

          browserDetails: `${platform.name} ${platform.os.architecture} ${platform.os.family} ${platform.os.version}`,

          deviceDetails: platform.product ? platform.product : "Desktop",

          isSearch: true,
        })
      );
      history.push({
        pathname: `${LIVEWIRE}${LIVE_WIRE_ARTICLE3}/?id=${list.id}`,
      });
    } else if (categoryName === "remoteworking") {
      dispatch(
        addAnalayticsLog({
          module: "remoteworking",
          browserDetails: `${platform.name} ${platform.os.architecture} ${platform.os.family} ${platform.os.version}`,
          deviceDetails: platform.product ? platform.product : "Desktop",
          isSearch: true,
        })
      );
      history.push({
        pathname: `${Support}${RemoteAArticle}`,
        // state: { id: id },
      });
    } else if (categoryName === "policy") {
      dispatch(
        addAnalayticsLog({
          module: "policy",
          browserDetails: `${platform.name} ${platform.os.architecture} ${platform.os.family} ${platform.os.version}`,
          deviceDetails: platform.product ? platform.product : "Desktop",
          isSearch: true,
        })
      );
      history.push({
        pathname: `${POLICIES}${POLICIES_DETAILS}/?id=${list.policyId}`,
      });
    } else if (categoryName === "employee") {
      if (list.isSf) {
        const { employeeid } = list;
        window.open(
          `https://performancemanager4.successfactors.com/xi/ui/pages/empfile/liveprofile.xhtml?selected_user=${employeeid}&company=Mahindra&loginMethod=SSO`,
          "_blank"
        );
      } else {
        history.push({
          pathname: `${PROFILE_PAGE}`,
          state: {
            list: list,
          },
        });
      }
    } else if (categoryName === "site") {
      window.open(`${list.uri}`, "_blank");
    } else if (categoryName === "tile") {
      window.open(`${list.uri}`, "_blank");
    } else if (categoryName === "supportservice") {
      dispatch(
        addAnalayticsLog({
          module: "supportservice",
          browserDetails: `${platform.name} ${platform.os.architecture} ${platform.os.family} ${platform.os.version}`,
          deviceDetails: platform.product ? platform.product : "Desktop",
          isSearch: true,
        })
      );
      history.push({
        pathname: `${Support}${ITSupportt}`,
      });
    } else if (categoryName === "emergency") {
      dispatch(
        addAnalayticsLog({
          module: "emergency",

          browserDetails: `${platform.name} ${platform.os.architecture} ${platform.os.family} ${platform.os.version}`,

          deviceDetails: platform.product ? platform.product : "Desktop",

          isSearch: true,
        })
      );
      history.push({
        pathname: `${EMERGENCY_CONTACTS}`,
      });
    }
  };

  const renderSearch = (result, categoryName) => {
    console.log(categoryName);
    const {
      title,
      subTitle,
      firstname,
      lastname,
      thumbnail,
      empPhotoUrl,
      policyName,
      description,
      companyName,
      emailid,
      designation,
      name,
      publishDate,
      documentFileName,
    } = result._source;
    if (
      categoryName === "mahindracollective" ||
      categoryName === "offerorcampaign" ||
      categoryName === "remoteworking" ||
      categoryName === "mahindraexperience"
    ) {
      return (
        <>
          <Grid item md={10} xs={12}>
            <Button className="USearchTab">{categoryName.toUpperCase()}</Button>
            <div
              style={{ cursor: "pointer" }}
              onClick={() => handleCategory(result._source, categoryName)}
              className="USearchFilterTitle"
            >
              <Highlighter
                highlightStyle={{ backgroundColor: "yellow" }}
                searchWords={searchText?.split(" ")}
                textToHighlight={title}
              />
            </div>
            <div className="USearchFilterDesc">
              <Highlighter
                highlightStyle={{ backgroundColor: "yellow" }}
                searchWords={searchText?.split(" ")}
                textToHighlight={subTitle}
              />
            </div>
          </Grid>
          <Grid
            item
            md={2}
            xs={12}
            style={{
              position: "relative",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <AspectRatio ratio="3/4" style={{ maxWidth: "200px" }}>
              <img src={thumbnail} alt="filter image" className="width100" />
            </AspectRatio>
          </Grid>
        </>
      );
    } else if (categoryName === "policy") {
      return (
        <>
          <Grid item md={10} xs={12}>
            <Button
              className="USearchTab"
              onClick={() => handleCategory(result._source, categoryName)}
            >
              {categoryName.toUpperCase()}
            </Button>
            <div className="USearchFilterTitle">
              <Highlighter
                highlightStyle={{ backgroundColor: "yellow" }}
                searchWords={searchText?.split(" ")}
                textToHighlight={policyName}
              />
            </div>
            <div className="USearchFilterDesc">
              <Highlighter
                highlightStyle={{ backgroundColor: "yellow" }}
                searchWords={searchText?.split(" ")}
                textToHighlight={description}
              />
            </div>
          </Grid>
        </>
      );
    } else if (categoryName === "organnouncement") {
      return (
        <>
          <Grid item md={10} xs={12}>
            <Button
              className="USearchTab"
              onClick={() => handleCategory(result._source, categoryName)}
            >
              {categoryName.toUpperCase()}
            </Button>
            <div className="USearchFilterTitle">
              <Highlighter
                highlightStyle={{ backgroundColor: "yellow" }}
                searchWords={searchText?.split(" ")}
                textToHighlight={name}
              />
            </div>
            <div className="USearchFilterDesc">
              <Highlighter
                highlightStyle={{ backgroundColor: "yellow" }}
                searchWords={searchText?.split(" ")}
                textToHighlight={publishDate}
              />
            </div>
            <div
              style={{ cursor: "pointer" }}
              className="policyDownload mpadd10"
              onClick={() =>
                window.open(
                  `${process.env.REACT_APP_API_BASE_URL}/ui-assist/v1/download/true?fileName=${documentFileName}`,
                  "_self"
                )
              }
            >
              <div className="policyheadm">Download</div>
              <img
                src={
                  process.env.PUBLIC_URL + "/policies/download-line-table.svg"
                }
                alt="Download line"
              />
            </div>
          </Grid>
        </>
      );
    } else if (categoryName === "employee") {
      return (
        <>
          <Grid item md={10} xs={12}>
            <Button className="USearchTab">{"People".toUpperCase()}</Button>
            <div
              className="USearchFilterTitle"
              style={{ cursor: "pointer" }}
              onClick={() => handleCategory(result._source, categoryName)}
            >
              <Highlighter
                highlightStyle={{ backgroundColor: "yellow" }}
                searchWords={searchText?.split(" ")}
                textToHighlight={`${firstname} ${lastname}`}
              />
            </div>
            <div className="USearchFilterDescInner">
              <Highlighter
                highlightStyle={{ backgroundColor: "yellow" }}
                searchWords={searchText?.split(" ")}
                textToHighlight={companyName}
              />
            </div>
            <div className="USearchFilterDescInner">
              <Highlighter
                highlightStyle={{ backgroundColor: "yellow" }}
                searchWords={searchText?.split(" ")}
                textToHighlight={`${ emailid ? emailid : "" }`}
              />
            </div>
            <div className="USearchFilterDescInner">
              <Highlighter
                highlightStyle={{ backgroundColor: "yellow" }}
                searchWords={searchText?.split(" ")}
                textToHighlight={`${ designation ? designation : "" }`}
              />
            </div>
          </Grid>
          <Grid item md={2} xs={12}>
            <AspectRatio ratio="1/2" style={{ maxWidth: "120px" }}>
              <img
                src={empPhotoUrl}
                alt="User photo"
                className="width100 searchImg"
              />
            </AspectRatio>
          </Grid>
        </>
      );
    } else if (categoryName === "site") {
      return (
        <>
          <Grid item md={10} xs={12}>
            <Button
              className="USearchTab"
              onClick={() => handleCategory(result._source, categoryName)}
            >
              {categoryName.toUpperCase()}
            </Button>
            <div className="USearchFilterTitle">
              <Highlighter
                highlightStyle={{ backgroundColor: "yellow" }}
                searchWords={searchText?.split(" ")}
                textToHighlight={result._source.id}
              />
            </div>
          </Grid>
        </>
      );
    } else if (categoryName === "tile") {
      return (
        <>
          <Grid item md={10} xs={12}>
            <Button
              className="USearchTab"
              onClick={() => handleCategory(result._source, categoryName)}
            >
              {categoryName.toUpperCase()}
            </Button>
            <div className="USearchFilterTitle">
              <Highlighter
                highlightStyle={{ backgroundColor: "yellow" }}
                searchWords={searchText?.split(" ")}
                textToHighlight={result._source.id}
              />
            </div>
          </Grid>
        </>
      );
    } else if (categoryName === "supportservice") {
      return (
        <>
          <Grid item md={10} xs={12}>
            <Button
              className="USearchTab"
              onClick={() => handleCategory(result._source, categoryName)}
            >
              {categoryName.toUpperCase()}
            </Button>

            <div className="USearchFilterTitle">
              <Highlighter
                highlightStyle={{ backgroundColor: "yellow" }}
                searchWords={searchText?.split(" ")}
                textToHighlight={result._source.title}
              />
            </div>
            <div className="USearchFilterDescInner">
              <Highlighter
                highlightStyle={{ backgroundColor: "yellow" }}
                searchWords={searchText?.split(" ")}
                textToHighlight={result._source.description}
              />
            </div>
            {result._source.phoneNumbers.map((item, index) => (
              <div className="USearchFilterDescInner">
                <Highlighter
                  highlightStyle={{ backgroundColor: "yellow" }}
                  searchWords={searchText?.split(" ")}
                  textToHighlight={item.phoneNumber}
                />
              </div>
            ))}
          </Grid>
          <Grid item md={2} xs={12}>
            <AspectRatio ratio="1/2" style={{ maxWidth: "120px" }}>
              <img
                src={result._source.image}
                alt=""
                className="supportServicesIcon width100 searchImg"
              />
            </AspectRatio>
          </Grid>
        </>
      );
    } else if (categoryName === "emergency") {
      return (
        <>
          <Grid item md={10} xs={12}>
            <Button
              className="USearchTab"
              onClick={() => handleCategory(result._source, categoryName)}
            >
              {categoryName.toUpperCase()}
            </Button>
            <div className="USearchFilterTitle">
              <Highlighter
                highlightStyle={{ backgroundColor: "yellow" }}
                searchWords={searchText?.split(" ")}
                textToHighlight={result._source.title}
              />
            </div>
            <div className="USearchFilterDescInner">
              <Highlighter
                highlightStyle={{ backgroundColor: "yellow" }}
                searchWords={searchText?.split(" ")}
                textToHighlight={result._source.location}
              />
            </div>
            {result._source.address.map((item, index) => (
              <div className="USearchFilterDescInner">
                <Highlighter
                  highlightStyle={{ backgroundColor: "yellow" }}
                  searchWords={searchText?.split(" ")}
                  textToHighlight={item.location + " " + item.contact}
                />
              </div>
            ))}
          </Grid>
        </>
      );
    }
  };

  // const [selectedOptions, setSelectedOptions] = useState(
  //   location?.state?.selectedOptions
  // );
  const getOptionLabel = (option) => `${option.title}`;
  const getOptionDisabled = (option) => option.value === "foo";
  const handleToggleOption = (selectedOptions) => {
    setSelectedOptions(selectedOptions);
    setOnPageChange(true);
    handleSearch("", selectedOptions);
  };
  const handleClearOptions = () => {
    setSelectedOptions([]);
    setOnPageChange(true);
  };
  const handleSelectAll = (isSelected) => {
    if (isSelected) {
      setSelectedOptions(options);
      setOnPageChange(true);
    } else {
      handleClearOptions();
    }
  };

  //handleApi
  const handleApi = async () => {
    const op = [...selectedOptions];
    let list = {};
    op?.map((val) => {
      return (list[val.value] = true) ;
    });
    try {
      let i = 0;
      let params = [];

      params[i++] = `employeeId=${localStorage.getItem("token")}`;
      params[i++] = `page=${pageNo}`;
      if (searchText) {
        params[i++] = `q=${searchText}`;
      } else if (querryName) {
        params[i++] = `q=${querryValue}`;
      }

      console.log("list data =====>", list);

      let queryString = params.join("&");
      let res = await services.api(
        "POST",
        `/ui-assist/v1/_search?${queryString}`,
        list
      );
      if (res.status === 200) {
        setSearchData(res.data);
      }
    } catch (err) {
      setSearchData([]);
      console.log("err from search:", err);
    }
  };

  //handle serach click
  const handleSearchClick = () => {
    console.log("i was clicked");
    handleApi();
  };

  //keypress handler
  const onKeyPress = (e) => {
    if (e.key === "Enter") {
      setKeyPressValue("enter");
      handleApi();
    } else {
      setKeyPressValue("");
    }
  };

  return (
    <div>
      <Template pageName={"Search"}>
        <Grid
          container
          direction="row"
          justifyContent="space-between"
          alignItems="flex-end"
          spacing={2}
          // className="alignTopUSearch"
        >
          <Grid item md={4} xs={12}>
            <p className="offCHeaderTitle">Search</p>
          </Grid>
          <Grid item md={4} xs={12} className="searchFilterMultiSelect">
            {/* <MultiSelect
              options={sectionOption}
              label={<CommonLabel label="Category" />}
              handleSelectChange={handleSearch}
              selectedValue={searchOptions}
              className="McShadowBox"
            /> */}
            <CustomMultiSelect
              items={options}
              getOptionLabel={getOptionLabel}
              getOptionDisabled={getOptionDisabled}
              selectedValues={selectedOptions}
              label="Select category"
              // placeholder="Select category"
              selectAllLabel="Select all"
              onToggleOption={handleToggleOption}
              onClearOptions={handleClearOptions}
              onSelectAll={handleSelectAll}
            />
          </Grid>
          <Grid item md={4} xs={12}>
            <SearchBarButton
              searchText={searchText}
              handleSearch={handleSearch}
              name="search"
              onKeyPress={onKeyPress}
              handleSearchIcon={handleSearchClick}
            />
          </Grid>
        </Grid>
        <Paper className="universalDataSearchTable">
          <Grid container direction="row" justifyContent="space-between">
            <Grid item md={12} xs={12}>
              <Card className="UniSearchCard">
                <p className="searchCatFilter">
                  Search results - {totalRecords}
                </p>
                {searchData?.hits?.hits ? (
                  searchData.hits.hits.map((result, index) => (
                    <div className="filterSearchData" key={index}>
                      <Grid
                        container
                        direction="row"
                        justifyContent="space-between"
                        spacing={2}
                      >
                        {renderSearch(result, result._index)}
                      </Grid>
                    </div>
                  ))
                ) : (
                  <div className="noData">
                    <span>No search result found</span>
                  </div>
                )}
              </Card>
            </Grid>
          </Grid>
        </Paper>
        <div style={{ margin: "1rem 0 2rem", float: "right" }}>
          <div className="paginationAlignFlex">
            <Stack spacing={2}>
              <Pagination
                count={totalPages}
                variant="outlined"
                shape="rounded"
                onChange={handlePageChange}
              />
            </Stack>
          </div>
        </div>
      </Template>
    </div>
  );
}
