import React from "react";
import "./CommonModal.css";
import { Dialog, Divider } from "@material-ui/core";
import SubmitButton from "../Button/SubmitButton";
import PropTypes from "prop-types";
import modalCloseIcon from "../../../Assets/Images/modal-close-icon.svg";
import errorIcon from "../../../Assets/Images/error-warning.svg";
import alertIcon from "../../../Assets/Images/alert-line.svg";

function CommonModal({
  open,
  handleClose,
  children,
  headerText,
  icon,
  modalSize,
  header,
  footer,
  fullWidth,
  addClassForVideo,
  contentType,
  onButtonClick,
  backgroundColor,
  className,
  cancleBtnText,
  orgBtn,
  erroMsg,
  erroMsgOrg,
  buttonStyling,
  submitBtnText,
  policiesButtonSize,
  footerBannerCss,
  onCancleButtonClick,
  isUniversalSearchmodal,
  noPadding,
  disabledSubmitButton,
  disableBackdropClick = false,
}) {
  const handleClassName = () => {
    if (addClassForVideo && contentType === "video") {
      return "videoPlayerStyle";
    } else if (addClassForVideo && contentType === "pdf") {
      return "pdfViewerStyle";
    } else if (isUniversalSearchmodal) {
      return "postionTopUniversalSearch";
    } else {
      return "";
    }
  };

  return (
    <Dialog
      onClose={handleClose}
      open={open}
      fullWidth={fullWidth && fullWidth}
      maxWidth={modalSize}
      className={handleClassName()}
      disableBackdropClick={disableBackdropClick}
    >
      <div className={className}>
        {!header && (
          <div>
            <div className="alignedEnd">
              <span
                className="iconStyle cursorP"
                title="close"
                onClick={handleClose}
              >
                <img
                  src={modalCloseIcon}
                  className="modalCloseIcon"
                  alt="modal_close_icon"
                />
              </span>
            </div>
          </div>
        )}
        {header && (
          <div className="modalHeader">
            <div className="headerContent">
              {icon && <img src={icon} alt="icon" className="modalTitleIcon" />}
              <p className="modalHeaderText">{headerText}</p>
            </div>
            <span onClick={handleClose} className="cursorP">
              <img
                src={modalCloseIcon}
                alt="modal_close_icon"
                className="modalCloseIcon"
              />
            </span>
          </div>
        )}
        <div
          className={`modalBody ${noPadding ? "padTop0" : ""} ${
            addClassForVideo && contentType === "video" ? "videoHeight" : ""
          } ${backgroundColor ? "modalBodyBg" : ""}${
            !header ? "addingTop5" : ""
          }`}
        >
          {children}
        </div>
        <Divider />
        {footer && (
          <div
            className={
              erroMsgOrg ? `modalFooter errorOrgFooter` : `modalFooter`
            }
          >
            <div className="alignModalFooter">
              {erroMsgOrg ? null : (
                <>
                  {" "}
                  <div className="modalErrorMsg">
                    {erroMsg && (
                      <div className="errorTextFevSection">
                        <div className="showErrorinLine">
                          <img
                            src={errorIcon}
                            alt="warning icon"
                            className="warningIconModal"
                          />
                          <span className="modalError">{erroMsg}</span>
                        </div>
                        {/* <div className="verticalBarFev"></div> */}
                        {/* <div className="fevClearAll">
                      <img
                        src={
                          process.env.PUBLIC_URL + "/iconImages/close-fill.svg"
                        }
                        alt="close icon"
                      />{" "}
                      Clear All
                    </div> */}
                      </div>
                    )}
                  </div>
                </>
              )}

              {/* orgmodalError */}
              <div className="modalErrorMsg">
                {erroMsgOrg && (
                  <div className="errorTextFevSection">
                    <div className="showErrorinLineOrg">
                      <img
                        src={alertIcon}
                        alt="warning icon"
                        className="warningIconModal"
                      />
                      <div className="alertMessage">
                        <div className="alertOrgTitle">
                          Multiple changes are not saved
                        </div>
                        <div className="alertOrgSubTitle">
                          To save changes ensure saving each change individually
                          before closing this screen
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </div>
              {/* orgmodalError */}

              <div className="FooterContent">
                {cancleBtnText && (
                  <SubmitButton
                    className="modalBackButton"
                    label={cancleBtnText}
                    size="small"
                    onClick={onCancleButtonClick}
                  />
                )}

                {submitBtnText && (
                  <SubmitButton
                    className={`modalButton  ${
                      policiesButtonSize ? "policiesBtn" : ""
                    } ${!footerBannerCss ? "EditBannerFooter" : ""} ${
                      disabledSubmitButton
                        ? "SubmitForApprovalButton"
                        : "enabledButton"
                    }`}
                    label={submitBtnText}
                    size="small"
                    onClick={onButtonClick}
                    buttonStyling={buttonStyling}
                    disabled={disabledSubmitButton}
                    variantBoolean={erroMsgOrg || orgBtn ? true : false}
                  />
                )}
              </div>
            </div>
          </div>
        )}
      </div>
    </Dialog>
  );
}

CommonModal.propTypes = {
  open: PropTypes.bool,
  handleClose: PropTypes.func,
  children: PropTypes.node,
  headerText: PropTypes.string,
  modalSize: PropTypes.string,
  header: PropTypes.bool,
  footer: PropTypes.bool,
  fullWidth: PropTypes.bool,
  addClassForVideo: PropTypes.bool,
  contentType: PropTypes.string,
  buttonStyling: PropTypes.string,
};

CommonModal.defaultProps = {
  open: false,
  handleClose: () => {},
  children: <></>,
  headerText: "",
  modalSize: "lg",
  header: true,
  footer: true,
  fullWidth: false,
  addClassForVideo: false,
  contentType: "",
  buttonStyling: "",
};

export default CommonModal;
