import React, { useEffect, useState } from "react";
import { Grid, Paper } from "@material-ui/core";
import { CSVLink } from "react-csv";
import Template from "../../Common/Template/Template";
import "../MahindraCollective/MahindraCollective.css";
import moment from "moment";
//redux
import { useDispatch, useSelector } from "react-redux";
import { getUserfeedbackData } from "../../../Redux/Actions/FeedbackAction";

//table
import EmployeeForm from "./TableForm";
import { TableBody, TableRow, TableCell, Toolbar } from "@material-ui/core";
import useTable from "../../Common/Form/useTable";

import Popup from "../../Common/Form/Popup";
import Notification from "../../Common/Form/Notification";
import ConfirmDialog from "../../Common/Form/ConfirmDialog";
import "./Table.css";
import { useHistory } from "react-router-dom";
import services from "../../../Services";
//table

const FeedbackTable = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const [concurrentUserCount, setConcurrentUserCount] = useState(0);

  useEffect(() => {
    const getConcurrentUserCount = async () => {
      const res = await services.api("GET", "/ui-assist/log/getSessionLog");
      if (res && res.status === 200) {
        setConcurrentUserCount(res.data);
      }
    };
    getConcurrentUserCount();
  }, []);
  // const [placeholder] = React.useState(
  //   "Search people, tags, sectors or a story name"
  // );
  // const [statusBol] = React.useState(true);

  // const [btnBool1, setbtnBool1] = React.useState(true);
  // const [btnBool2, setbtnBool2] = React.useState(false);
  //const getFB = useSelector((state) => {s})

  // const [feedData, setFeedData] = useState([])
  //data
  const feedbackData = useSelector((state) => state.getUserfeedbackReducer);
  const { getData: records } = feedbackData;

  //   const { getData: feedData } = feedbackData;
  //   console.log('getData...........', feedData)
  //console.log('feedbackData........................',feedbackData.getData)
  const filterCsvData = () => {
    let filterData = [];
    if (feedbackData && feedbackData.getData) {
      for (let i = 0; i < feedbackData.getData.length; i++) {
        // console.log(i)
        filterData.push({
          employeeId: feedbackData.getData[i].employeeId,
          employeeName: feedbackData.getData[i].employeeName,
          page: feedbackData.getData[i].page,
          rating: feedbackData.getData[i].rating,
          comment: feedbackData.getData[i].comment,
          dateTime: moment(feedbackData.getData[i].dateTime).format(
            "DD/MM/YYYY"
          ),
          sourceType: feedbackData.getData[i].sourceType,
        });
      }
    }
    return filterData;
  };
  let csvData = filterCsvData();
  //console.log('result...................',csvData)

  //console.log('records...........',records  )
  //if(feedbackData && feedbackData.getData) setFeedData(feedbackData.getData)  ;
  //console.log('feedData...........................',feedData)

  //

  useEffect(() => {
    dispatch(getUserfeedbackData());
  }, []);

  // const handlePageClick = (location) => {

  //   let path =  location.contentName;

  //    history.push(`${path}`)
  // }
  //table
  // const useStyles = makeStyles((theme) => ({
  //   pageContent: {
  //     margin: theme.spacing(5),
  //     padding: theme.spacing(3),
  //   },
  //   searchInput: {
  //     width: "75%",
  //   },
  //   newButton: {
  //     position: "absolute",
  //     right: "9px",
  //     marginBottom: "10px",
  //   },
  // }));

  const headCells = [
    { id: "employeeId", label: "Employee Id" },
    { id: "employeeName", label: "Employee Name" },
    { id: "page", label: "Page" },
    { id: "rating", label: "Rating" },
    { id: "comment", label: "Comment" },
    { id: "dateTime", label: "Date" },
    { id: "sourceType", label: "Source" },
  ];

  const headCellCsv = [
    { label: "employeeId", key: "employeeId" },
    { label: "employeeName", key: "employeeName" },
    { label: "page", key: "page" },
    { label: "rating", key: "rating" },
    { label: "comment", key: "comment" },
    { label: "Date", key: "dateTime" },
    { label: "Source", key: "sourceType" },
  ];

  //console.log('csvReport', csvReport)

  // const handleCsvExport = () => {
  //console.log('i was clicked')
  //return <CSVLink {...csvReport}></CSVLink>
  //   return (
  //     <CSVLink data={data} headers={headers}>
  //       Download me
  //     </CSVLink>
  //   );
  // };

  // const classes = useStyles();
  const [recordForEdit, setRecordForEdit] = useState(null);
  // const [records, setRecords] = useState([]);

  const [filterFn, setFilterFn] = useState({
    fn: (items) => {
      return items;
    },
  });
  const [openPopup, setOpenPopup] = useState(false);
  const [notify, setNotify] = useState({
    isOpen: false,
    message: "",
    type: "",
  });
  const [confirmDialog, setConfirmDialog] = useState({
    isOpen: false,
    title: "",
    subTitle: "",
  });

  const { TblContainer, TblHead, TblPagination, recordsAfterPagingAndSorting } =
    useTable(records, headCells, filterFn);

  // const handleSearch = (e) => {
  //   let target = e.target;
  //   setFilterFn({
  //     fn: (items) => {
  //       if (target.value == "") return items;
  //       else
  //         return items.filter((x) =>
  //           x.fullName.toLowerCase().includes(target.value)
  //         );
  //     },
  //   });
  // };

  // const addOrEdit = (employee, resetForm) => {
  //   if (employee.id == 0) employeeService.insertEmployee(employee);
  //   else employeeService.updateEmployee(employee);
  //   resetForm();
  //   setRecordForEdit(null);
  //   setOpenPopup(false);
  //  // setRecords(feedbackData?.getData);
  //   setNotify({
  //     isOpen: true,
  //     message: "Submitted Successfully",
  //     type: "success",
  //   });
  // };

  // const openInPopup = (item) => {
  //   setRecordForEdit(item);
  //   setOpenPopup(true);
  // };

  // const onDelete = (id) => {
  //   setConfirmDialog({
  //     ...confirmDialog,
  //     isOpen: false,
  //   });
  //   employeeService.deleteEmployee(id);
  //   setRecords(feedbackData?.getData);
  //  // console.log('records...................', records)

  //   setNotify({
  //     isOpen: true,
  //     message: "Deleted Successfully",
  //     type: "error",
  //   });
  // };

  //moment().format('MMMM Do YYYY, h:mm:ss a');

  // const headers = [
  //   { label: "First Name", key: "firstname" },
  //   { label: "Last Name", key: "lastname" },
  //   { label: "Email", key: "email" },
  // ];

  // const data = [
  //   { firstname: "Ahmed", lastname: "Tomi", email: "ah@smthing.co.com" },
  //   { firstname: "Raed", lastname: "Labes", email: "rl@smthing.co.com" },
  //   { firstname: "Yezzi", lastname: "Min l3b", email: "ymin@cocococo.com" },
  // ];

  // const data2 = [
  //   {
  //     employeeName: "SAMIR BHOR",
  //     page: "App",
  //     rating: "Excellent",
  //     employeeId: "00210030",
  //     comment: "Cool guys",
  //   },
  //   {
  //     employeeId: "00210030",
  //     employeeName: "BHOR SAMIR",
  //     page: "App",
  //     rating: "Excellent",
  //     comment: "good",
  //   },
  // ];

  // const [excelData, setexcelData] = useState(filterCsvData())
  // console.log('excelData',excelData)
  // useEffect(() => {
  //   filterCsvData();
  // }, [])
  // const csvReport = {
  //   filename: 'feedback.csv', headers: headCellCsv, data: filterCsvData()
  // }

  // console.log(csvReport, 'csvReport');

  return (
    <>
      <Template pageName={"Mahindra Collective"}>
        <div>
          <Grid
            container
            direction="row"
            justifyContent="space-between"
            alignItems="flex-end"
          >
            <Grid item md={7} xs={12}>
              <p className="offCHeaderTitle">Feedback</p>
            </Grid>
          </Grid>
          <Grid
            container
            direction="row"
            justifyContent="flex-end"
            alignItems="flex-start"
          >
            <Grid item md={7} xs={12}>
              <p className="detailDivp">
                WORKPORTAL CONCURRENT USERS COUNT:{" "}
                <span>{concurrentUserCount}</span>
              </p>
            </Grid>
          </Grid>
        </div>
        <Paper className="MClandingpageAlign tableGutter">
          <Toolbar className="mbToolbar">
            <div className="exportCsv">
              <CSVLink
                filename="Generated_By_Rise_Admin.csv"
                data={csvData}
                headers={headCellCsv}
              >
                Export Csv
              </CSVLink>
            </div>
          </Toolbar>

          <Grid
            container
            direction="row"
            justifyContent="space-evenly"
            alignItems="flex-start"
            spacing={2}
            className="MCContainer"
          >
            <Grid item sm={12}>
              <div className="MCalignCardsItemstable1 padding16">
                <TblContainer>
                  <TblHead />
                  <TableBody>
                    {recordsAfterPagingAndSorting().map((item) => (
                      <TableRow key={item.id}>
                        <TableCell data-label="employeeId">
                          {item.employeeId}
                        </TableCell>
                        <TableCell data-label="employeeName">
                          {item.employeeName}
                        </TableCell>
                        <TableCell data-label="page" className="transformText">
                          {item.page}
                        </TableCell>
                        <TableCell data-label="rating">{item.rating}</TableCell>
                        <TableCell data-label="comment">
                          {item.comment}
                        </TableCell>
                        <TableCell data-label="comment">
                          {moment(item.dateTime).format("DD/MM/YYYY")}
                        </TableCell>
                        <TableCell data-label="Source">
                          {item.sourceType}
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </TblContainer>
                <TblPagination />
              </div>
            </Grid>
          </Grid>
        </Paper>
        <Popup
          title="Employee Form"
          openPopup={openPopup}
          setOpenPopup={setOpenPopup}
        >
          <EmployeeForm recordForEdit={recordForEdit} />
        </Popup>
        <Notification notify={notify} setNotify={setNotify} />
        <ConfirmDialog
          confirmDialog={confirmDialog}
          setConfirmDialog={setConfirmDialog}
        />
      </Template>
    </>
  );
};
export default FeedbackTable;
