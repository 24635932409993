import {
  GET_BENEFIT_MASTER_SUCCESS,
  GET_BENEFIT_MASTER_FAILED,
  GET_BENEFIT_MASTER_LOAD,
  EDIT_BENEFIT_MASTER_SUCCESS,
  EDIT_BENEFIT_MASTER_FAILED,
  EDIT_BENEFIT_MASTER_LOAD
} from "../Contants/Constants";

const initialState = {
  allBenefits: undefined,
  editBenefits: undefined,
  isLoading: false,
  isError: "",
  status: "idle"
};

const getEntitlementBenefitReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_BENEFIT_MASTER_LOAD:
      return {
        ...state,
        isLoading: true,
        status: "pending"
      };
    case GET_BENEFIT_MASTER_SUCCESS:
      return {
        ...state,
        allBenefits: action.payload,
        isLoading: false,
        status: "success"
      };
    case GET_BENEFIT_MASTER_FAILED:
      return {
        ...state,
        isError: action.payload,
        isLoading: false,
        status: "failed"
      };

    case EDIT_BENEFIT_MASTER_LOAD:
      return {
        ...state,
        isLoading: true,
        status: "pending"
      };
    case EDIT_BENEFIT_MASTER_SUCCESS:
      return {
        ...state,
        editBenefits: action.payload,
        isLoading: false,
        status: "success"
      };
    case EDIT_BENEFIT_MASTER_FAILED:
      return {
        ...state,
        isError: action.payload,
        isLoading: false,
        status: "failed"
      };

    default:
      return state;
  }
};

export default getEntitlementBenefitReducer;
