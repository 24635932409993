import React, { useState } from "react";
import "./ApprovalCenter.css";
import { Grid } from "@material-ui/core";
import Template from "../../Common/Template/Template";
// import { approvalDataCard } from "./ApprovalData";
// import ApprovalCard from "./ApprovalCard";
import SubmitButton from "../../Common/Button/SubmitButton";
import ApprovalDetails from "./ApprovalDetails";

export default function ApprovalCardPage() {
  const modal = () => {
    setOpenSettingssub(!openSettingssub);
  };
  const [openSettingssub, setOpenSettingssub] = useState(false);

  return (
    <section>
      <Template>
        <div className="approvalPH">
          <Grid
            container
            direction="row"
            justifyContent="space-between"
            alignItems="center"
          >
            <Grid item xs={12} sm={7}>
              <p className="offCHeaderTitle mbACard">Approval Center</p>
            </Grid>
          </Grid>
          <Grid container direction="row" alignItems="center" spacing={2}>
            <SubmitButton label="Approval" onClick={modal} />
          </Grid>
        </div>
      </Template>
      <ApprovalDetails
        open={openSettingssub}
        handleClose={() => setOpenSettingssub(false)}
        headerText="Approve Stories"
        modalSize="md"
        header={true}
        footer={true}
        fullWidth
      />
    </section>
  );
}
