import services from "../../Services/index";
import {
  GET_MODIFIED_BY_DATA_SUCCESS,
  GET_MODIFIED_BY_DATA_LOAD,
  GET_MODIFIED_BY_DATA_FAILED,
  LIKE_DATA_SUCCESS,
  LIKE_DATA_LOAD,
  LIKE_DATA_FAILED,
  ARTICLE_DATA_SUCCESS,
  ARTICLE_DATA_LOAD,
  ARTICLE_DATA_FAILED,
  GET_OFFER_DATA_SUCCESS,
  GET_OFFER_DATA_LOAD,
  GET_OFFER_DATA_FAILED,
  DELETE_ARTICLE_SUCCESS,
  DELETE_ARTICLE_LOAD,
  DELETE_ARTICLE_FAILED,
  SHARE_DATA_SUCCESS,
  SHARE_DATA_LOAD,
  SHARE_DATA_FAILED,
} from "../Contants/Constants";

const getLastModifiedDataLoad = () => ({
  type: GET_MODIFIED_BY_DATA_LOAD,
});
const getLastModifiedDataSuccess = (data, moduleName, moduleSubType) => ({
  type: GET_MODIFIED_BY_DATA_SUCCESS,
  payload: data,
  moduleName: moduleName,
  moduleSubType: moduleSubType,
});
const getLastModifiedDataFailed = (error) => ({
  type: GET_MODIFIED_BY_DATA_FAILED,
  err: error,
});
export const getLastModifiedData =
  (moduleName, moduleSubType = "") =>
  async (dispatch) => {
    dispatch(getLastModifiedDataLoad());
    try {
      let url = `/ui-assist/v1/lastmodified/${moduleName}`;

      if (moduleSubType) {
        url = url + `?type=${moduleSubType}`;
      }

      const res = await services.api("GET", url);

      if (res) {
        dispatch(
          getLastModifiedDataSuccess(res.data, moduleName, moduleSubType)
        );
      }
    } catch (err) {
      dispatch(getLastModifiedDataFailed(err.response));
    }
  };

const likeDataLoad = () => ({
  type: LIKE_DATA_LOAD,
});
const likeDataSuccess = (data, moduleName) => ({
  type: LIKE_DATA_SUCCESS,
  payload: data,
  moduleName: moduleName,
});
const likeDataFailed = (error) => ({
  type: LIKE_DATA_FAILED,
  err: error,
});

const employeeId = localStorage.getItem("token");

export const likeArticle = (contentType, contentId) => async (dispatch) => {
  dispatch(likeDataLoad());
  try {
    let url = `/ui-assist/v1/commonlike?contentName=${contentType}&contentId=${contentId}&employeeId=${localStorage.getItem(
      "token"
    )}`;

    const res = await services.api("POST", url);
    if (res) {
      dispatch(likeDataSuccess(res.data, contentType, contentId));
    }
  } catch (err) {
    dispatch(likeDataFailed(err.response));
  }
};

//SINGLE ARTICLE HANDLER......
const ArticleDataLoad = () => ({
  type: ARTICLE_DATA_LOAD,
});
const ArticleDataSuccess = (data, moduleName) => ({
  type: ARTICLE_DATA_SUCCESS,
  payload: data,
  moduleName: moduleName,
});
const ArticleDataFailed = (error) => ({
  type: ARTICLE_DATA_FAILED,
  err: error,
});

export const getArticle =
  (contentType, contentId, contentCategory) => async (dispatch) => {
    dispatch(ArticleDataLoad());
    try {
      let url = `/ui-assist/v1/${contentType}/${contentCategory}/${contentId}`;

      const res = await services.api("GET", url);
      if (res) {
        dispatch(ArticleDataSuccess(res.data));
      }
    } catch (err) {
      dispatch(ArticleDataFailed(err.response));
    }
  };

export const emptyArticle = () => async (dispatch) => {
  dispatch(ArticleDataLoad());
  dispatch(ArticleDataSuccess(undefined));
};

//delete artical
const deleteArticleLoad = () => ({
  type: DELETE_ARTICLE_LOAD,
});
const deleteArticleSuccess = (data, moduleName) => ({
  type: DELETE_ARTICLE_SUCCESS,
  payload: data,
  moduleName: moduleName,
});
const deleteArticleFailed = (error) => ({
  type: DELETE_ARTICLE_FAILED,
  err: error,
});

// const employeeId = localStorage.getItem("token");
export const deleteArticle = (moduleName, id) => async (dispatch) => {
  dispatch(deleteArticleLoad());
  try {
    let url = `/ui-assist/v1/commondelete/${id}?type=${moduleName}`;

    const res = await services.api("DELETE", url);
    if (res) {
      dispatch(deleteArticleSuccess(res.data, moduleName));
    }
  } catch (err) {
    dispatch(deleteArticleFailed(err.response));
  }
};

//SINGLE ARTICLE HANDLER......
const OfferDataLoad = () => ({
  type: GET_OFFER_DATA_LOAD,
});
const OfferDataSuccess = (data, moduleName) => ({
  type: GET_OFFER_DATA_SUCCESS,
  payload: data,
  moduleName: moduleName,
});
const OfferDataFailed = (error) => ({
  type: GET_OFFER_DATA_FAILED,
  payload: {},
  err: error,
});

export const getOffer = (contentType, contentId) => async (dispatch) => {
  dispatch(OfferDataLoad());
  try {
    let url = `/ui-assist/v1/${contentType}/id/${contentId}`;

    const res = await services.api("GET", url);
    if (res) {
      dispatch(OfferDataSuccess(res.data));
    }
  } catch (err) {
    dispatch(OfferDataFailed(err.response));
  }
};

export const emptyOffer = () => async (dispatch) => {
  dispatch(OfferDataLoad());

  dispatch(OfferDataSuccess(undefined));
};

// SHARE LOG
const shareDataLoad = () => ({
  type: SHARE_DATA_LOAD,
});
const shareDataSuccess = (data, moduleName) => ({
  type: SHARE_DATA_SUCCESS,
  payload: data,
  moduleName: moduleName,
});
const shareDataFailed = (error) => ({
  type: SHARE_DATA_FAILED,
  err: error,
});

// const employeeId = localStorage.getItem("token");

export const shareArticle =
  (contentType, contentId, sharedApp) => async (dispatch) => {
    dispatch(shareDataLoad());
    try {
      let url = `/ui-assist/v1/sharelog?employeeid=${localStorage.getItem(
        "token"
      )}&sharedapp=${sharedApp}&contenttype=${contentType}&contentid=${contentId}`;

      const res = await services.api("POST", url);
      if (res) {
        dispatch(shareDataSuccess(res.data, contentType, contentId));
      }
    } catch (err) {
      dispatch(shareDataFailed(err.response));
    }
  };
