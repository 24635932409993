import React from 'react'

function ErrorPage() {
    return (
        <div
          style={{
            textAlign: "center",
            marginTop: "68px",
            padding: "200px 0px"
          }}
        >
          <div>
            <h1>COMING SOON</h1>
          </div>
          <div>
            <p>
              
            </p>
          </div>
        </div>
      );
}

export default ErrorPage
