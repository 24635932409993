import React, { useEffect, useState } from "react";
import { Redirect, useHistory } from "react-router-dom";
import services from "../Services/index";
import { AUTH, NOT_AUTH, DASHBOARD } from "../Routes/constant";
import axios from "axios";
import { ApmRoute } from "@elastic/apm-rum-react";

function ProtectedRoutes({ component: Component, ...rest }) {
  const [getUrl, setGetUrl] = useState(null);
  const history = useHistory();
  let checkAuth = localStorage.getItem("token") ? true : false;

  /* const getEmployee = async () => {
    if (process.env.REACT_APP_APP_ENV === "local") {
      localStorage.setItem("token", 210030);
      history.push(`${DASHBOARD}`);
    } else {
      try {
        let res = await axios(
          `${process.env.REACT_APP_API_BASE_URL}/ui-assist/v1/employee`
        );
         if (res.status === 200) {
          localStorage.setItem("userProfile", JSON.stringify(res.data));
          const { firstname, lastname, employeeid } = res.data;
          localStorage.setItem("fullName", `${firstname} ${lastname}`);
          localStorage.setItem("lastName", lastname);
          // let firstName = name.split(" ")[1];
          localStorage.setItem("token", employeeid);
          localStorage.setItem("name", firstname);
          history.push(`${DASHBOARD}`);
          // setGetUrl(<Redirect to={`${DASHBOARD}`} />);
        }
      } catch (err) {
        const { status } = err?.response;
        if (status === 403) {
          localStorage.clear();
          setGetUrl(<Redirect to={`${AUTH}${NOT_AUTH}`} />);
        } else if (status === 401) {
          setGetUrl(
            (window.location.href = `${process.env.REACT_APP_DEV_URL}`)
          );
        } else {
          setGetUrl(<Redirect to={`${AUTH}${NOT_AUTH}`} />);
        }
      }
    }
  };*/
  useEffect(() => {
    // getEmployee();
  }, []);
  return (
    <ApmRoute
      {...rest}
      render={
        (props) => (checkAuth ? <Component {...props} /> : getUrl)
        // (window.location.href = `${process.env.REACT_APP_DEV_URL}`)
        // <Redirect to={`${AUTH}${LOGIN}`} />
      }
    />
  );
}

export default ProtectedRoutes;
