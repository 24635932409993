import React, { useState, useEffect } from "react";
import "../LiveWireMcArticle2/LiveWireMcArticle2.css";
// import "./commentCss.css";
import { Paper, Grid } from "@material-ui/core";
import Template from "../../Common/Template/Template";
import { useMediaQuery } from "@material-ui/core";
import SubmitButton from "../../Common/Button/SubmitButton";
import excel from "../../../Assets/Images/excel.svg";
import ppt from "../../../Assets/Images/ppt.svg";
import { modifyRemoteWorkingData } from "../../../Redux/Actions/RemoteWorkingAction";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { useLocation } from "react-router-dom";
import parse from "html-react-parser";
import HashTags from "./PreviewArticleModel/HashTags";
import CommonModal from "../../Common/CommonModal/CommonModal";
import { baseUrl } from "../../../Services";
import { getFormattedDate } from "../../../Assets/helperFunctions/helperFunctions";

export default function RemoteArticle(props) {
  const isXs = useMediaQuery("(max-width:812px)");
  let getCampaign = props?.location?.state?.details;

  const [backendComments, setBackendComments] = useState([]);
  const [activeComment, setActiveComment] = useState(null);
  const rootComments = backendComments.filter(
    (backendComment) => backendComment.parentId === null
  );
  const getReplies = (commentId) =>
    backendComments
      .filter((backendComment) => backendComment.parentId === commentId)
      .sort(
        (a, b) =>
          new Date(a.createdAt).getTime() - new Date(b.createdAt).getTime()
      );
  // const addComment = (text, parentId) => {
  //     createCommentApi(text, parentId).then((comment) => {
  //         setBackendComments([comment, ...backendComments]);
  //         setActiveComment(null);
  //     });
  // };

  const dispatch = useDispatch();
  const location = useLocation();
  const remoteWorkData = useSelector((state) => state.getRemoteWorkingReducer);
  //for Preview Modal
  const [submitForPublish, setSubmitForPublish] = React.useState(false);
  const [submitForPreview, setSubmitForPreview] = React.useState(false);
  const [open, setOpen] = useState(false);
  const handleCloseModal = () => {
    setSubmitForPublish(false);
    setSubmitForPreview(false);
  };

  const handleSubmit = (values, resetForm) => {
    setSubmitForPublish(true);
  };

  const { title, subTitle, story, type, tags, media, thumbnail, updatedAt } =
    remoteWorkData?.remoteWorkDetails || {};

  var fileExtension = media?.split(".")?.pop();
  // var updated = new Date(updatedAt);
  //var updatedDate =
    // updated.getDate() + "/" + (updated.getMonth()+1) + "/" + updated.getFullYear();

    var updatedDate = getFormattedDate(updatedAt);

  useEffect(() => {
    window.addEventListener("scroll", listenScrollEvent);
    dispatch(modifyRemoteWorkingData("GET", location?.state?.id));
    // getCommentsApi().then((data) => {
    //     setBackendComments(data);
    // });
    window.scrollTo(0, 0);

    return () => window.removeEventListener("scroll", listenScrollEvent);
  }, []);

  const [header, setHeader] = useState("alignASocialIcon1");

  const listenScrollEvent = (event) => {
    if (window.scrollY < 100) {
      return setHeader("alignASocialIcon1");
    } else if (window.scrollY > 400) {
      return setHeader("alignASocialIcon2");
    }
  };

  const imgModal = () => setOpen(true);
  const handleClose = () => setOpen(false);

  return (
    <div className="fontFamilyArticle2">
      <Template pageName={"The SUVs of Tomorrow are coming"}>
        <Grid container spacing={5} className="alignMCArticals">
          <Grid item sm={1} className={header}></Grid>
          <Grid item sm={11} className="articleRightP">
            <Paper className="landingpageAlign2">
              <div className="Amtmb">
                <Grid container direction="row">
                  {tags?.map((object) => {
                    <Grid item md={2} sm={2} xs={4}>
                      <SubmitButton
                        variant="outlined"
                        color="secondary"
                        label={object}
                        className="buttonFont"
                      />
                    </Grid>;
                  })}
                </Grid>
              </div>
              <Grid
                container
                direction="column"
                justifyContent="flex-start"
                alignItems="flex-start"
              >
                <HashTags tags={tags} />
                <Grid item xs={12}>
                  <p className="article2ContentTitle pdB">{title}</p>
                  <p className="article2ContentSubTitle">{subTitle}</p>
                  <div className="avatarDetailsAlignment">
                    <div className="avatarNameDate">
                      {/* <p className="articleAvatarName">Garima Rai</p> */}
                      <p className="avatarDate">{updatedDate}</p>
                    </div>
                  </div>
                  <div>{parse("" + story)}</div>
                  {media && (
                    <div style={{ marginTop: "40px" }}>
                      <div style={{ marginBottom: "12px" }}>
                        <strong>Attachments</strong>
                      </div>
                      <div>
                        {fileExtension == "pdf" ? (
                          <>
                            <a
                              className="fileName"
                              //href={`${process.env.REACT_APP_API_BASE_URL}/ui-assist/v1/download/true?fileName=${media}`}
                              target="_blank"
                            >
                              <img
                                src={ppt}
                                alt="avatar"
                                style={{ marginLeft: "12px" }}
                                onClick={imgModal}
                              />
                            </a>
                            <CommonModal
                                handleClose={handleClose}
                                //modalSize="xl"
                                open={open}
                                header={true}
                                footer={false}
                                headerText={media}
                            >
                              <div>
                                <iframe
                                  src={`${baseUrl}/ui-assist/v1/download?fileName=${media}`}
                                  width="1000"
                                  height="1000"
                                ></iframe>
                              </div>
                            </CommonModal>
                          </>
                        ) : (
                          <img src={excel} alt="avatar" />
                        )}
                      </div>
                    </div>
                  )}
                </Grid>
              </Grid>
            </Paper>
          </Grid>
        </Grid>
      </Template>
    </div>
  );
}
