import React from "react";
import { Grid } from "@material-ui/core";

const RowItem = ({ label, content }) => {
  return (
    <>
      <Grid container direction="row" className="border2 py-2 ">
        <Grid item xs={2} sm={2} md={2}>
          <div className="TableContent">{label}</div>
        </Grid>
        <Grid item xs={8} sm={8} md={8}>
          <div className="TableContent fontboldnew">{content}</div>
        </Grid>
      </Grid>
    </>
  );
};

export default RowItem;
