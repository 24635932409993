import React from "react";
import { Switch } from "react-router-dom";
import DashBoard from "../Components/DashBoard/DashBoard";
import NotFound from "../Components/NotFound/NotFound";

import {
  DASHBOARD,
  PROFILE_PAGE,
  LIVEWIRE,
  DOWNTIME,
  POLICIES,
  Support,
  Approval,
  SEARCH,
  SITE_MAP,
  DISCLAIMER,
  PRIVACYPOLICY,
  WORK,
  EMERGENCY_CONTACTS,
  FOREX_CENTER,
  ENTITLEMENT,
  ENTITLEMENTADMIN,
  ENTITLEMENTBENEFITS,
  CARLEASING,
  BLOOD_BANK,
  ERROR_PAGE,
  EDIT_WELCOM_BANNER,
  LOGIN,
  TERMS_AND_CONDITION,
  SOPS,
  MLDL,
  SOP,
  MLDLLIFE,
} from "./constant";
import ProtectedRoutes from "./ProtectedRoutes";
import ProfilePage from "../Components/ProfilePage/ProfilePage";
import PoliciesRoutes from "./PoliciesRoutes/PoliciesRoutes";
import LiveWireRoutes from "./LiveWireRoutes/LiveWireRoutes";
import SupportRoutes from "./SupportRoutes/SupportRoutes";
import MLDLLifePostRoutes from "./MLDLLifePostRoutes/MLDLLifePostRoutes";

// import AuthRoutes from "./AuthRoutes/AuthRoutes";
import Login from "../Components/Pages/Auth/Login/Login";
import isAdmin from "../Components/isAdmin";
import ApprovalRoutes from "./ApprovalRoutes/ApprovalRoutes";
import UniversalSearchRoutes from "./UniversalSearchRoutes/UniversalSearchRoutes";
import SiteMapRoutes from "./SitemapRoutes/SiteMapRoutes";
import WorkRoutes from "./WorkRoutes/WorkRoutes";
import EmergencyContacts from "../Components/Pages/EmergencyContacts/EmergencyContacts";
import ForexCenter from "../Components/Pages/Forex/ForexCenter";
import Downtime from "../Components/Pages/DownTime/DowntimeAdmin";
import Entitlement from "../Components/Pages/Entitlement/Entitlement";
import CarLeasing from "../Components/Pages/CarLeasing/CarLeasing";
import EntitlementAdmin from "../Components/Pages/Entitlement/EntitlementAdmin/EntitlementAdmin";
import EntitlementBenefits from "../Components/Pages/Entitlement/EntitlementAdmin/EntitlementBenefits";
import { BloodBank } from "../Components/Pages/HealthAndWellness/BloodBank/BloodBank";
import ErrorPage from "../Components/Pages/redirect/ErrorPage";
import PrivacyPolicy from "../Components/Pages/PrivacyPolicy/PrivacyPolicy";
import TermsAndCondition from "../Components/Pages/TermsAndCondition/TermsAndCondition";
import SopRoutes from "./SopRoutes/SopRoutes";
import MLDLComponent from "../Components/Pages/MLDL/MLDL";
import { ApmRoute } from "@elastic/apm-rum-react";

const Routes = () => {
  return (
    <Switch>
      {/* Home page rotes */}
      <ProtectedRoutes
        exact
        path={DASHBOARD}
        component={DashBoard}
        admin={isAdmin}
      />
      <ApmRoute exact path={PROFILE_PAGE} component={ProfilePage} />
      <ApmRoute path={LIVEWIRE} component={LiveWireRoutes} />
      <ApmRoute path={POLICIES} component={PoliciesRoutes} />
      <ApmRoute path={SOP} component={SopRoutes} />
      <ApmRoute path={Support} component={SupportRoutes} />
      <ApmRoute path={EMERGENCY_CONTACTS} component={EmergencyContacts} />
      <ApmRoute path={FOREX_CENTER} component={ForexCenter} />
      <ApmRoute path={ENTITLEMENT} component={Entitlement} />
      <ApmRoute path={DOWNTIME} component={Downtime} />
      <ApmRoute path={CARLEASING} component={CarLeasing} />
      <ApmRoute path={ENTITLEMENTADMIN} component={EntitlementAdmin} />
      <ApmRoute path={ENTITLEMENTBENEFITS} component={EntitlementBenefits} />
      <ApmRoute path={BLOOD_BANK} component={BloodBank} />
      <ApmRoute path={MLDL} component={MLDLComponent} />
      <ApmRoute path={Approval} component={ApprovalRoutes} />
      <ApmRoute path={SEARCH} component={UniversalSearchRoutes} />
      <ApmRoute path={SITE_MAP} component={SiteMapRoutes} />
      <ApmRoute path={DISCLAIMER} component={SiteMapRoutes} />
      <ApmRoute path={PRIVACYPOLICY} component={PrivacyPolicy} />
      <ApmRoute path={WORK} component={WorkRoutes} />
      <ApmRoute exact path={ERROR_PAGE} component={ErrorPage} />
      <ApmRoute path={LOGIN} component={Login} />
      <ApmRoute path={TERMS_AND_CONDITION} component={TermsAndCondition} />
      <ApmRoute component={NotFound} />
    </Switch>
  );
};

export default Routes;
