import React, { useState, useEffect } from "react";
import "../../../MahindraCollective/UploadStory/UploadStoryComponent.css";
import { Grid, FormControlLabel } from "@material-ui/core";
import Checkbox from "@mui/material/Checkbox";
import CharLeftField from "../../../../Common/CharLeftField/CharLeftField";
import CommonLabel from "../../../../Common/ComonLabel/CommonLabel";
import BenefitTitle from "./BenefitTitle";

const ClubMembership = ({ data, setData }) => {
  const [noLimit, setNoLimit] = useState(false);
  const [limit, setLimit] = useState("");
  const [isAllowed, setIsAllowed] = useState(false);

  useEffect(() => {
    if (data) {
      data?.map((val) => {
        if (val?.entitlementType == "Club Membership") {
          setIsAllowed(true);
          setNoLimit(val?.values?.noLimit);
          setLimit(val?.values?.availedLimit);
        }
      });
    }
  }, [data]);

  useEffect(() => {
    const val = {
      benefitsId: "62720e2c6bc86099de7d7bb1",
      noLimit: noLimit,
      availedLimit: noLimit ? "" : limit
    };
    const isValid = CheckValidation();
    const data = {
      value: val,
      isAllowed: isAllowed,
      isValid: isValid,
    };
    setData(data);
  }, [noLimit, limit, isAllowed]);

  useEffect(() => {
    if (!isAllowed) {
      setNoLimit(false);
      setLimit("");
    }
  }, [isAllowed]);

  function CheckValidation() {
    if(!isAllowed)
    { return true; }
    else{
      if(noLimit)
      { return true; }
      else{
        if(limit == "")
        { return false; }
        else
        { return true; }
      }
    }
  };

  const handleChange = (e) => setNoLimit(!noLimit);
  const handleLimit = (e) => setLimit(e.target.value);
  console.log(" =====>", limit);
  return (
    <>
      <BenefitTitle
        title="Club membership"
        val={isAllowed}
        setVal={setIsAllowed}
      />
      <div className="divContainerOfferCampaign mb-5">
        <Grid container spacing={2} alignItems="top">
          <Grid item sm={6} md={3} xs={12}>
            <div className="radioBackground authorRadioButton">
              <FormControlLabel
                value="author"
                className="radioButtonForm"
                control={
                  <Checkbox
                    checked={noLimit}
                    disabled={!isAllowed}
                    onChange={handleChange}
                  />
                }
                label={
                  <span className="formControlLabelStyle commonFontNormalStyle ms-1">
                    No limit
                  </span>
                }
                labelPlacement="end"
              />
            </div>
          </Grid>
          <Grid
            item
            sm={6}
            md={3}
            xs={12}
            className="inputWidthTitle alignInputTextTitle boxwithlimit"
          >
            <CharLeftField
              label={<CommonLabel label="Limit ( in Lac )" mandatory={!noLimit} />}
              value={limit}
              name="limit"
              type="number"
              disabled={noLimit || !isAllowed}
              handleChange={handleLimit}
            />
          </Grid>
        </Grid>
      </div>
    </>
  );
};

export default ClubMembership;
