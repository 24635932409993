import React, { useState, useEffect } from "react";
import { Grid, Paper, Tabs, Tab, Typography, Box } from "@material-ui/core";
import PropTypes from "prop-types";
import RemoteCard from "./RemoteCard";
import "./RemoteWorking.css";
// import CommonModal from "../../Common/CommonModal/CommonModal";
import SearchBarButton from "../../Common/SearchBar/SearchBarButton";
import Template from "../../Common/Template/Template";
import "../MahindraCollective/MahindraCollective.css";
import {
  RemoteAArticle,
  RemoteUploadArticle,
  RemoteWorkingg,
  RemoteUploadNewArticle,
  Support,
} from "../../../Routes/constant";
import LiveWireFilters from "../liveWirePages/LiveWireFilters/LiveWireFilters";
// import settingIcon from "../../../Assets/Images/settings_5_line.svg";
// import AdminModalComponent from "../MahindraCollective/Admin/AdminModalComponent";

import pdfLink1 from "../../../Assets/documents/8 Icebreaker Ideas for Virtual Ideas.pdf";
import pdfLink2 from "../../../Assets/documents/Mangers playbook1.pdf";
import pdfLink3 from "../../../Assets/documents/VIRTUAL GET-TOGETHER IDEAS.pdf";
import pdfLink4 from "../../../Assets/documents/Creating a culture of gratitude.pdf";
import pdfLink5 from "../../../Assets/documents/Gratitude Week at Work.pdf";
import pdfLink6 from "../../../Assets/documents/1_meeting guidlines_ver2.pdf";
import pdfLink7 from "../../../Assets/documents/VILT guide_ver2.pdf";
import pdfLink8 from "../../../Assets/documents/five ways to support colleagues_final.pdf";
import pdfLink9 from "../../../Assets/documents/4_MLU Courses Links.pdf";
import pdfLink10 from "../../../Assets/documents/5_ERGONOMICS MATTER.pdf";
import RemoteFilters from "./Filters/RemoteFilters";
import CommonModal from "../../Common/CommonModal/CommonModal";
import filterIcon from "../../../Assets/Images/filter-line.svg";
import {
  sortingFunction,
  sortingFunctionDescending,
} from "../../../Assets/helperFunctions/helperFunctions";
import {
  getRemoteWorkingData,
  setRemoteWorkingFilterDateSuccess,
} from "../../../Redux/Actions/RemoteWorkingAction";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import RemoteEditModal from "./RemoteEditModal";

// mobile responsive tabs
function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}
const list = [
  {
    label: ["COVID 19", "Remote Work", "Ideas"],
    desc: "Here are small online team-building activities you as a meeting leader could use to connect with the team working out of remote locations:",
    value: "8 Icebreaker Ideas for Virtual",
    img: "/Images/remoteworking/img1.jpg",
    detailsPage: pdfLink1,
  },
  {
    label: ["Carbon Neutrality", "Sustainability", "ESG"],
    desc: "To Cultivate a Happier and Effective Team",
    value: "Manager's Playbook",
    img: "/Images/remoteworking/img2.jpg",
    detailsPage: pdfLink2,
  },
  {
    label: ["Carbon Neutrality", "Sustainability", "ESG"],
    desc: "From social media shout-outs to team rituals, here is a list of virtual celebration ideas sure to foster connection and positive feelings:",
    value:
      "Virtual Get-together Ideas to Celebrate Your Team Members and Their Achievements!",
    img: "/Images/remoteworking/img3.jpg",
    detailsPage: pdfLink3,
  },

  {
    label: ["Carbon Neutrality", "Sustainability", "ESG"],
    desc: "The positive effects of “Thank You”, Ways for creating a culture of gratitude within your workplace ",
    value: "Creating a culture of gratitude",
    img: "/Images/remoteworking/img4.jpg",
    detailsPage: pdfLink4,
  },
  {
    label: ["Carbon Neutrality", "Sustainability", "ESG"],
    desc: "FIVE BENEFITS OF GRATITUDE IN THE WORKPLACE (AND HOW TO SHOW IT)",
    value: "Gratitude Week at Work!",
    img: "/Images/remoteworking/img5.jpg",
    detailsPage: pdfLink5,
  },
  {
    label: ["Carbon Neutrality", "Sustainability", "ESG"],
    desc: "Here are some useful guidelines to conduct effective virtual meetings",
    value: "Meeting Guidelines",
    img: "/Images/remoteworking/img6.jpg",
    detailsPage: pdfLink6,
  },
  {
    label: ["Carbon Neutrality", "Sustainability", "ESG"],
    desc: "VILT guide_ver2",
    value: "Virtual Learner Guidelines",
    img: "/Images/remoteworking/img7.jpg",
    detailsPage: pdfLink7,
  },
  {
    label: ["Carbon Neutrality", "Sustainability", "ESG"],
    desc: "The change has been sudden, and uncertainty has overtaken the world. Remote working has become the new normal",
    value: "Five Ways to Support Colleagues",
    img: "/Images/remoteworking/img8.jpg",
    detailsPage: pdfLink8,
  },

  {
    label: ["Carbon Neutrality", "Sustainability", "ESG"],
    desc: "The Mahindra Leadership University’s (MLU) IT Academy has introduced a web-based learning series to ensure optimal productivity.",
    value: "Remote Working - MLU Courses",
    img: "/Images/remoteworking/img9.jpg",
    detailsPage: pdfLink9,
  },

  {
    label: ["Carbon Neutrality", "Sustainability", "ESG"],
    desc: "While everyone has got accustomed to remote working, the absence of a dedicated workstation may be indeed a challenge.",
    value: "Ergonomics Matter",
    img: "/Images/remoteworking/img10.jpg",
    detailsPage: pdfLink10,
  },
];

const RemoteWorking = (props) => {
  const dispatch = useDispatch();

  const remoteWorkingList = useSelector(
    (state) => state.getRemoteWorkingReducer
  );

  const [placeholder] = React.useState(
    "Search people, tags, sectors or a story name"
  );
  const [statusBol] = React.useState(true);

  let history = useHistory();

  const [calYearValue, setCalYearValue] = useState("");
  const [monthValue, setMonthValue] = useState("");
  //For Sort By
  const [getList, setGetList] = useState([]);
  const [selectedSortBy, setSelectedSortBy] = useState("desc");
  const [selectedType, setSelectedType] = useState("tips");
  const [selectedDate, setSelectedDate] = useState("2022-1-1");
  const [remoteEditPopup, setRemoteEditPopup] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [openFilterModal, setOpenFilterModal] = useState(false);

  useEffect(() => {
    dispatch(getRemoteWorkingData(selectedType, selectedDate, selectedSortBy));
  }, [selectedSortBy, selectedDate, selectedType]);

  const handleCalYearChange = (e, selectType) => {
    setSelectedDate(`${selectType.year}-${selectType.selectedMonth}-1`);
    dispatch(setRemoteWorkingFilterDateSuccess(selectType));
  };

  const handleSortByChange = (e, selectType) => {
    setSelectedSortBy(selectType.value);
    const filterdList =
      selectType.value === "Sort by latest"
        ? sortingFunction(getList, "requestedDate")
        : selectType.value === "Sort by old"
        ? sortingFunctionDescending(getList, "requestedDate")
        : getList;
    setGetList(filterdList);
  };

  const onSubmitTapped = () => {
    setRemoteEditPopup(true);
  };

  const [value, setValue] = React.useState(0);
  const handleChange = (event, newValue) => {
    setValue(newValue);
    let selectedType = "tips";
    switch (newValue) {
      case 1:
        selectedType = "policy";
        break;
      case 2:
        selectedType = "wellness";
        break;
      default:
        selectedType = "tips";
        break;
    }
    setSelectedType(selectedType);
  };
  //filters hide show in mobile
  const onFilterRemoteWorking = () => {
    setOpenFilterModal(true);
  };

  const handleCloseModal = () => {
    setOpenModal(false);
    setOpenFilterModal(false);
  };
  const [display, setDisplay] = React.useState(false);
  return (
    <Template pageName={"Remote Working"}>
      <div>
        {/* <div className="mahindraColleAdmin" onClick={onEditMahindraCollective}>
                     <img src={process.env.PUBLIC_URL + "/iconImages/MC_admin.svg"} alt="setting admin" />
                     <div className="mCollectiveAdmin">manage (3)</div>
                 </div> */}
        <Grid
          container
          direction="row"
          justifyContent="space-between"
          alignItems="flex-end"
          className="mt24"
        >
          <Grid item md={7} xs={12}>
            <p className="offCHeaderTitle">Remote Working</p>
          </Grid>
          <Grid item md={5} xs={12}>
            <SearchBarButton placeholder={placeholder} />
          </Grid>
        </Grid>
      </div>
      <div className="MCMobileFilter">
        <Grid container direction="row" justifyContent="space-between">
          <Grid item onClick={onFilterRemoteWorking}>
            <div className="MCFilter" xs={6}>
              <img
                src={process.env.PUBLIC_URL + "/iconImages/filter-line.svg"}
                alt="filter icon"
              />
              Filter
            </div>
          </Grid>
        </Grid>
      </div>
      <Paper className="MClandingpageAlign alignResponsivePadding">
        <div className="liveWireFilters">
          <div
            className={`${display ? "responsiveShowMC" : "responsiveHideMC"}`}
          >
            <RemoteFilters
              showSubmit={false}
              isShortBy={true}
              handleSortByChange={handleSortByChange}
              handleCalYearChange={handleCalYearChange}
              path={"/connect/support/upload-article"}
              onSubmitTapped={onSubmitTapped}
              // calYearValue={calYearValue}
            />
          </div>
        </div>

        <div className="responsiveTabs">
          <Box sx={{ width: "100%" }}>
            <Box
              sx={{ borderBottom: 1, borderColor: "divider" }}
              className="boxAlignPadMC"
            >
              <Tabs
                value={value}
                onChange={handleChange}
                variant="scrollable"
                scrollButtons={false}
                aria-label="basic tabs example"
                className="mahindraCollectiveTabsDM"
              >
                <Tab label="Tips & Tutorials" {...a11yProps(0)} />
                <Tab label="Policy & Guidelines" {...a11yProps(1)} />
                <Tab label="Wellness & R&R" {...a11yProps(2)} />
              </Tabs>
            </Box>

            <div className="alignMCCOntentTabPanel">
              <TabPanel value={value} index={0}>
                <Grid
                  container
                  direction="row"
                  justifyContent="flex-start"
                  alignItems="center"
                  spacing={2}
                  className="alignCards"
                >
                  {remoteWorkingList?.remoteWorkingData?.map(
                    (result, index) => {
                      return (
                        <Grid item className="padding12" sm={4} xs={12}>
                          <RemoteCard list={result} />
                        </Grid>
                      );
                    }
                  )}
                </Grid>
              </TabPanel>
              <TabPanel value={value} index={1}>
                <Grid
                  container
                  direction="row"
                  justifyContent="flex-start"
                  alignItems="center"
                  spacing={2}
                  className="alignCards"
                >
                  {remoteWorkingList?.remoteWorkingData?.map(
                    (result, index) => {
                      return (
                        <Grid item className="padding12" sm={4} xs={12}>
                          <RemoteCard list={result} />
                        </Grid>
                      );
                    }
                  )}
                </Grid>
              </TabPanel>
              <TabPanel value={value} index={2}>
                <Grid
                  container
                  direction="row"
                  justifyContent="flex-start"
                  alignItems="center"
                  spacing={2}
                  className="alignCards"
                >
                  {remoteWorkingList?.remoteWorkingData?.map(
                    (result, index) => {
                      return (
                        <Grid item className="padding12" sm={4} xs={12}>
                          <RemoteCard list={result} />
                        </Grid>
                      );
                    }
                  )}
                </Grid>
              </TabPanel>
            </div>
          </Box>
        </div>
      </Paper>

      <RemoteEditModal
        open={remoteEditPopup}
        handleClose={() => setRemoteEditPopup(false)}
        headerText="Edit tips & tutorials"
        modalSize="md"
        header={true}
        footer={true}
        fullWidth
        selectedType={selectedType}
        dateFromList={selectedDate}
      />
      <CommonModal
        open={openFilterModal}
        handleClose={handleCloseModal}
        headerText="Filter"
        icon={filterIcon}
        modalSize="lg"
        submitBtnText="Done"
        onButtonClick={handleCloseModal}
        fullWidth
        footerBannerCss
      >
        <div className="MCFilters">
          <RemoteFilters
            showSubmit={false}
            isShortBy={true}
            handleSortByChange={handleSortByChange}
            handleCalYearChange={handleCalYearChange}
            path={"/connect/support/upload-article"}
            onSubmitTapped={onSubmitTapped}
            // calYearValue={calYearValue}
          />
        </div>
      </CommonModal>
    </Template>
  );

  // return (
  //   <Template pageName={"Remote Working"}>
  //     <div>
  //       {/* <div className="mahindraColleAdmin" onClick={onEditMahindraCollective}>
  //                   <img src={process.env.PUBLIC_URL + "/iconImages/MC_admin.svg"} alt="setting admin" />
  //                   <div className="mCollectiveAdmin">manage (3)</div>
  //               </div> */}
  //       <Grid
  //         container
  //         direction="row"
  //         justifyContent="space-between"
  //         alignItems="flex-end"
  //         className="mt24"
  //       >
  //         <Grid item md={7} xs={12}>
  //           <p className="offCHeaderTitle">Remote Working</p>
  //         </Grid>
  //         <Grid item md={5} xs={12}>
  //           <SearchBarButton placeholder={placeholder} />
  //         </Grid>
  //       </Grid>
  //     </div>
  //     <Paper className="MClandingpageAlign">
  //       <RemoteFilters
  //         showSubmit={false}
  //         isShortBy={true}
  //         handleSortByChange={handleSortByChange}
  //         handleCalYearChange={handleCalYearChange}
  //         path={"/connect/support/upload-article"}
  //         onSubmitTapped={onSubmitTapped}
  //         // calYearValue={calYearValue}
  //       />
  //       <div className="responsiveTabs">
  //         <Box sx={{ width: "100%" }}>
  //           <Box
  //             sx={{ borderBottom: 1, borderColor: "divider" }}
  //             className="boxAlignPadMC"
  //           >
  //             <Tabs
  //               aria-label="basic tabs example"
  //               className="mahindraCollectiveTabsDM"
  //               onChange={handleChange}
  //             >
  //               <Tab
  //                 label="Tips & tutorials"
  //                 {...a11yProps(0)}
  //                 onClick={handleBool1}
  //               />
  //               <Tab
  //                 label="Policy & guidelines"
  //                 {...a11yProps(1)}
  //                 onClick={handleBool2}
  //               />
  //               <Tab
  //                 label="Wellness & R&R"
  //                 {...a11yProps(2)}
  //                 onClick={handleBool3}
  //               />
  //             </Tabs>
  //           </Box>

  //           <div className="alignMCCOntentTabPanel">
  //             <TabPanel index={0}>
  //               <Grid
  //                 container
  //                 direction="row"
  //                 justifyContent="flex-start"
  //                 alignItems="center"
  //                 spacing={2}
  //                 className="alignCards"
  //               >
  //                 {remoteWorkingList?.remoteWorkingData?.map(
  //                   (result, index) => {
  //                     return (
  //                       <Grid item className="padding12" sm={4}>
  //                         <RemoteCard list={result} />
  //                       </Grid>
  //                     );
  //                   }
  //                 )}
  //               </Grid>
  //             </TabPanel>
  //             <TabPanel index={1}>
  //               <Grid
  //                 container
  //                 direction="row"
  //                 justifyContent="flex-start"
  //                 alignItems="center"
  //                 spacing={2}
  //                 className="alignCards"
  //               >
  //                 {getList.length ? (
  //                   getList.map((result, index) => {
  //                     return (
  //                       <Grid
  //                         item
  //                         className="padding12"
  //                         sm={index >= 0 && index <= 1 ? 6 : 4}
  //                         xs={index >= 0 && index <= 1 ? 12 : 12}
  //                       >
  //                         {/* <MahindraCollectiveCard
  //                           list={result}
  //                           footerIcon={true}
  //                         /> */}
  //                       </Grid>
  //                     );
  //                   })
  //                 ) : (
  //                   <span
  //                     style={{
  //                       display: "flex",
  //                       justifyContent: "center",
  //                       alignItems: "center",
  //                       height: "100vh",
  //                       width: "100%",
  //                       
  //                       color: "#e31837",
  //                     }}
  //                   >
  //                     No Data Available
  //                   </span>
  //                 )}
  //               </Grid>
  //             </TabPanel>
  //             <TabPanel index={2}>
  //               <Grid
  //                 container
  //                 direction="row"
  //                 justifyContent="flex-start"
  //                 alignItems="center"
  //                 spacing={2}
  //                 className="alignCards"
  //               >
  //                 {getList.length ? (
  //                   getList.map((result, index) => {
  //                     return (
  //                       <Grid
  //                         item
  //                         className="padding12"
  //                         sm={index >= 0 && index <= 1 ? 6 : 4}
  //                         xs={index >= 0 && index <= 1 ? 12 : 12}
  //                       >
  //                         {/* <MahindraCollectiveCard
  //                           list={result}
  //                           footerIcon={true}
  //                         /> */}
  //                       </Grid>
  //                     );
  //                   })
  //                 ) : (
  //                   <span
  //                     style={{
  //                       display: "flex",
  //                       justifyContent: "center",
  //                       alignItems: "center",
  //                       height: "100vh",
  //                       width: "100%",
  //                       
  //                       color: "#e31837",
  //                     }}
  //                   >
  //                     No Data Available
  //                   </span>
  //                 )}
  //               </Grid>
  //             </TabPanel>
  //           </div>
  //         </Box>
  //       </div>
  //       <Grid
  //         container
  //         direction="row"
  //         justifyContent="space-evenly"
  //         alignItems="flex-start"
  //         spacing={2}
  //         className="MCContainer"
  //       >
  //         <Grid item sm={12} xs={12} md={3} lg={3}>
  //           <div className="MCalignCardsItems">
  //             <div
  //               className={
  //                 btnBool1 ? `MCactiveTab` : `MCtabContent hoverEffect m-1`
  //               }
  //               onClick={handleBool1}
  //             >
  //               <p className="MCtabTitle">Tips & tutorials</p>
  //             </div>
  //             <div
  //               onClick={handleBool2}
  //               className={
  //                 btnBool2 ? `MCactiveTab` : `MCtabContent hoverEffect m-1`
  //               }
  //             >
  //               <p className="MCtabTitle">
  //                 <span className="MCboldTitle">Policy & guidelines </span>
  //                 {/* <span className="McsubDescription">(Rise Stories)</span> */}
  //               </p>
  //             </div>
  //             <div
  //               className={
  //                 btnBool3 ? `MCactiveTab` : `MCtabContent hoverEffect m-1`
  //               }
  //               onClick={handleBool3}
  //             >
  //               <p className="MCtabTitle">
  //                 <span className="MCboldTitle">Wellness & R&R</span>
  //                 {/* <span className="McsubDescription">(Diversity & Inclusion)</span> */}
  //               </p>
  //             </div>
  //           </div>
  //         </Grid>
  //         <Grid item sm={9}>
  //           <div className="MCalignCardsItems padding16">
  //             <>
  //               <Grid
  //                 container
  //                 direction="row"
  //                 justifyContent="flex-start"
  //                 alignItems="center"
  //                 spacing={2}
  //                 className="alignCards"
  //               >
  //                 {remoteWorkingList?.remoteWorkingData?.map(
  //                   (result, index) => {
  //                     return (
  //                       <Grid item className="padding12" sm={4}>
  //                         <RemoteCard list={result} />
  //                       </Grid>
  //                     );
  //                   }
  //                 )}
  //               </Grid>
  //             </>
  //           </div>
  //         </Grid>
  //       </Grid>
  //     </Paper>
  //     {/*
  //     <CommonModal
  //               open={openModal}
  //               handleClose={handleCloseModal}
  //               headerText="Manage Mahindra collective"
  //               icon={settingIcon}
  //               modalSize="lg"
  //               submitBtnText="SAVE CHANGES"
  //               fullWidth
  //               policiesButtonSize
  //           >
  //               <AdminModalComponent />
  //           </CommonModal> */}
  //     <RemoteEditModal
  //       open={remoteEditPopup}
  //       handleClose={() => setRemoteEditPopup(false)}
  //       headerText="Edit"
  //       modalSize="md"
  //       header={true}
  //       footer={true}
  //       fullWidth
  //       selectedType={selectedType}
  //       dateFromList={selectedDate}
  //     />
  //   </Template>
  // );
};
export default RemoteWorking;
