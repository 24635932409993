import React from "react";
import "./LeadersSpeakComponent.css";
import { Paper, Grid } from "@material-ui/core";
import Template from "../../../Common/Template/Template";
import SearchBarButton from "../../../Common/SearchBar/SearchBarButton";
// import CommonAutocomplete from "../../../Common/CommonAutocomplete/CommonAutocomplete";
// import CommonMultiAutocomplete from "../../../Common/CommonMultiAutocomplete/CommonMultiAutocomplete";
// import CommonLabel from "../../../Common/ComonLabel/CommonLabel";
import CommonCard from "../../../Common/CommonCard/CommonCard";

// Icon import
import avatarImg from "../../../../Assets/Images/Unsplash-Avatars_0005s_0007_emile-guillemot-vfijBqzoQE0-unsplash@2x.png";
import LiveWireFilters from "../LiveWireFilters/LiveWireFilters";

export default function LeadersSpeakComponent() {
  const [placeholder] = React.useState(
    "Search people, tags, sectors or a story name"
  );

  const leadersSpeakCardObj = [
    {
      img: "/Images/learn&Grow/Mask Group.png",
      video: false,
      title: "Rajesh Jejurikar on WHF",
      description:
        "Lorem ipsum doler sit amet Lorem ipsum doler sit amet Lorem ipsum doler sit amet",
      avatar: true,
      avatarImg: `${avatarImg}`,
      avatarName: "Subhash Pathare",
      date: "21 Jul 2021",
      actionIcon: true,
    },
    {
      img: "/Images/learn&Grow/Mask Group.png",
      video: false,
      title: "Rajesh Jejurikar on WHF",
      description:
        "Lorem ipsum doler sit amet Lorem ipsum doler sit amet Lorem ipsum doler sit amet",
      avatar: true,
      avatarImg: `${avatarImg}`,
      avatarName: "Subhash Pathare",
      date: "21 Jul 2021",
      actionIcon: true,
    },
    {
      img: "/Images/learn&Grow/Mask Group.png",
      video: true,
      title: "Rajesh Jejurikar on WHF",
      description:
        "Lorem ipsum doler sit amet Lorem ipsum doler sit amet Lorem ipsum doler sit amet",
      avatar: true,
      avatarImg: `${avatarImg}`,
      avatarName: "Subhash Pathare",
      date: "21 Jul 2021",
      actionIcon: true,
    },
    {
      img: "/Images/learn&Grow/Mask Group.png",
      video: true,
      title: "Rajesh Jejurikar on WHF",
      description:
        "Lorem ipsum doler sit amet Lorem ipsum doler sit amet Lorem ipsum doler sit amet",
      avatar: true,
      avatarImg: `${avatarImg}`,
      avatarName: "Subhash Pathare",
      date: "21 Jul 2021",
      actionIcon: true,
    },
    {
      img: "/Images/learn&Grow/Mask Group.png",
      video: false,
      title: "Rajesh Jejurikar on WHF",
      description:
        "Lorem ipsum doler sit amet Lorem ipsum doler sit amet Lorem ipsum doler sit amet",
      avatar: true,
      avatarImg: `${avatarImg}`,
      avatarName: "Subhash Pathare",
      date: "21 Jul 2021",
      actionIcon: true,
    },
    {
      img: "/Images/learn&Grow/Mask Group.png",
      video: false,
      title: "Rajesh Jejurikar on WHF",
      description:
        "Lorem ipsum doler sit amet Lorem ipsum doler sit amet Lorem ipsum doler sit amet",
      avatar: true,
      avatarImg: `${avatarImg}`,
      avatarName: "Subhash Pathare",
      date: "21 Jul 2021",
      actionIcon: true,
    },
    {
      img: "/Images/learn&Grow/Mask Group.png",
      video: false,
      title: "Rajesh Jejurikar on WHF",
      description:
        "Lorem ipsum doler sit amet Lorem ipsum doler sit amet Lorem ipsum doler sit amet",
      avatar: true,
      avatarImg: `${avatarImg}`,
      avatarName: "Subhash Pathare",
      date: "21 Jul 2021",
      actionIcon: true,
    },
    {
      img: "/Images/learn&Grow/Mask Group.png",
      video: false,
      title: "Rajesh Jejurikar on WHF",
      description:
        "Lorem ipsum doler sit amet Lorem ipsum doler sit amet Lorem ipsum doler sit amet",
      avatar: true,
      avatarImg: `${avatarImg}`,
      avatarName: "Subhash Pathare",
      date: "21 Jul 2021",
      actionIcon: true,
    },
  ];

  return (
    <section>
      <Template pageName={"Leader Insight"}>
        <div>
          <Grid
            container
            direction="row"
            justifyContent="space-between"
            alignItems="center"
          >
            <Grid item xs={12} sm={7}>
              <p className="offCHeaderTitle">Leader Insight</p>
            </Grid>
            <Grid item xs={12} sm={5}>
              <SearchBarButton placeholder={placeholder} />
            </Grid>
          </Grid>
        </div>

        <Paper className="leaderSpeakPaper">
          <LiveWireFilters />
          <div className="leaderSpeakCardContainer">
            <Grid
              container
              direction="row"
              justifyContent="space-evenly"
              alignItems="center"
              spacing={2}
              className="alignCards"
            >
              {leadersSpeakCardObj.slice(0, 2).map((item, index) => (
                <Grid item sm={6} xs={12}>
                  <CommonCard dataObj={item} />
                </Grid>
              ))}
              {leadersSpeakCardObj.slice(2).map((item, index) => (
                <Grid item sm={4} xs={12}>
                  <CommonCard dataObj={item} />
                </Grid>
              ))}
            </Grid>
          </div>
        </Paper>
      </Template>
    </section>
  );
}
