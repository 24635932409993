import React from "react";
import { Grid } from "@material-ui/core";
import Template from "../../../Common/Template/Template";
import SearchBarButton from "../../../Common/SearchBar/SearchBarButton";
import PoliciesSearchComponent from "../PoliciesSearchTemplate/PoliciesSearchComponent";
import "./SearchPoliciesComponent.css";

export default function SearchPoliciesComponent() {
  const [placeholder] = React.useState(
    "Search e.g. mobile reimbursement for me"
  );
  return (
    <section>
      <Template>
        <Grid
          container
          direction="row"
          justifyContent="space-between"
          alignItems="center"
        >
          <Grid item xs={12} sm={7}>
            <p className="offCHeaderTitle">Policies</p>
          </Grid>
          <Grid item xs={12} sm={5}>
            <div className="policiesSearchWidth">
              <SearchBarButton placeholder={placeholder} />
            </div>
          </Grid>
        </Grid>
        <div className="totlaPoliciesCount searchPoliciesCount">
          Showing Result for{" "}
          <span className="highlightCount fw600">
            Handset Reimbursement for me
          </span>
        </div>
        <div>
          <PoliciesSearchComponent />
        </div>
      </Template>
    </section>
  );
}
