import Swal from "sweetalert2";
import services from "../../Services/index";
import {
  EXPERIENCE_LIST_SUCCESS,
  EXPERIENCE_LIST_FAILED,
  EXPERIENCE_LIST_LOAD,
  EXPERIENCE_PENDING_FAILED,
  EXPERIENCE_PENDING_SUCCESS,
  EXPERIENCE_PENDING_LOAD,
  EXPERIENCE_ARTICLE_FAILED,
  EXPERIENCE_ARTICLE_LOAD,
  EXPERIENCE_ARTICLE_SUCCESS,
  EXPERIENCE_SCHEDULE_FAILED,
  EXPERIENCE_SCHEDULE_SUCCESS,
  EXPERIENCE_SCHEDULE_LOAD,
  EXPERIENCE_DELETE_FAILED,
  EXPERIENCE_DELETE_SUCCESS,
  EXPERIENCE_DELETE_LOAD,
  PREV_NEXT_ID_FAILED,
  PREV_NEXT_ID_SUCCESS,
  PREV_NEXT_ID_LOAD,
} from "../Contants/Constants";

/* ======== For mahindra collective list ========== */
export const EXP_OFFSET = 18;

const getExperienceListLoad = () => ({
  type: EXPERIENCE_LIST_LOAD,
});

const getExperienceListSuccess = (data, pageNo) => ({
  type: EXPERIENCE_LIST_SUCCESS,
  payload: data,
  pageNo: pageNo,
});

const getExperienceListFailed = (error) => ({
  type: EXPERIENCE_LIST_FAILED,
  err: error,
});

export const getExperienceList =
  (pageNo = 0, categoryType, searchText = "", sortOrder = "DESC") =>
  async (dispatch) => {
    dispatch(getExperienceListLoad());
    try {
      let baseUrl = "/ui-assist/v1/mahindra-experience?";
      let params = [];
      let i = 0;

      params[i++] = `offset=${EXP_OFFSET}`;

      if (pageNo >= 0) {
        params[i++] = `page=${pageNo}`;
      } else {
        params[i++] = `pageNo=${pageNo}`;
      }

      if (categoryType) {
        params[i++] = `categoryType=${categoryType}`;
      }

      if (categoryType == "mycontribution") {
        params[i++] = `employeeId=${localStorage.getItem("token")}`;
      }

      if (searchText) {
        params[i++] = `q=${searchText}`;
      }

      if (sortOrder) {
        params[i++] = `sort=${sortOrder}`;
      }

      let queryString = "";
      if (params) {
        queryString = params.join("&");
      }

      const res = await services.api("GET", baseUrl + queryString);

      if (res) {
        dispatch(getExperienceListSuccess(res.data, pageNo));
      }
    } catch (err) {
      dispatch(getExperienceListFailed(err.response));
      Swal.fire("Something went wrong!", `${err?.response?.data}`, "error");
    }
  };

const getPendingListLoad = () => ({
  type: EXPERIENCE_PENDING_LOAD,
});

const getPendingListSuccess = (data) => ({
  type: EXPERIENCE_PENDING_SUCCESS,
  payload: data,
});

const getPendingListFailed = (error) => ({
  type: EXPERIENCE_PENDING_FAILED,
  err: error,
});

export const getPendingList = () => async (dispatch) => {
  dispatch(getPendingListLoad());

  try {
    let baseUrl = "/ui-assist/v1/mahindra-experience/pending";

    const res = await services.api("GET", baseUrl);

    if (res) {
      dispatch(getPendingListSuccess(res.data));
    }
  } catch (err) {
    dispatch(getPendingListFailed(err.response));
    Swal.fire("Something went wrong!", `${err?.response?.data}`, "error");
  }
};

//SINGLE ARTICLE HANDLER......
const ArticleDataLoad = () => ({
  type: EXPERIENCE_ARTICLE_LOAD,
});
const ArticleDataSuccess = (data, moduleName) => ({
  type: EXPERIENCE_ARTICLE_SUCCESS,
  payload: data,
  moduleName: moduleName,
});
const ArticleDataFailed = (error) => ({
  type: EXPERIENCE_ARTICLE_FAILED,
  err: error,
});

export const getArticle = (contentType, contentId) => async (dispatch) => {
  dispatch(ArticleDataLoad());
  try {
    let url = `/ui-assist/v1/${contentType}/${contentId}`;

    const res = await services.api("GET", url);
    if (res) {
      dispatch(ArticleDataSuccess(res.data));
    }
  } catch (err) {
    dispatch(ArticleDataFailed(err.response));
  }
};

export const emptyArticle = () => async (dispatch) => {
  dispatch(ArticleDataLoad());
  dispatch(ArticleDataSuccess(undefined));
};

const getScheduleListLoad = () => ({
  type: EXPERIENCE_SCHEDULE_LOAD,
});

const getScheduleListSuccess = (data) => ({
  type: EXPERIENCE_SCHEDULE_SUCCESS,
  payload: data,
});

const getScheduleListFailed = (error) => ({
  type: EXPERIENCE_SCHEDULE_FAILED,
  err: error,
});

export const getScheduleList = () => async (dispatch) => {
  dispatch(getScheduleListLoad());

  try {
    let baseUrl = "/ui-assist/v1/mahindra-experience/schedule";

    const res = await services.api("GET", baseUrl);

    if (res) {
      dispatch(getScheduleListSuccess(res.data));
    }
  } catch (err) {
    dispatch(getScheduleListFailed(err.response));
    Swal.fire("Something went wrong!", `${err?.response?.data}`, "error");
  }
};

//delete artical
const deleteArticleLoad = () => ({
  type: EXPERIENCE_DELETE_LOAD,
});
const deleteArticleSuccess = (data) => ({
  type: EXPERIENCE_DELETE_SUCCESS,
  payload: data,
});
const deleteArticleFailed = (error) => ({
  type: EXPERIENCE_DELETE_FAILED,
  err: error,
});

// const employeeId = localStorage.getItem("token");
export const deleteArticle = (id) => async (dispatch) => {
  dispatch(deleteArticleLoad());
  try {
    let url = `/ui-assist/v1/mahindra-experience/${id}`;

    const res = await services.api("DELETE", url);
    if (res) {
      dispatch(deleteArticleSuccess(res.data));
    }
  } catch (err) {
    dispatch(deleteArticleFailed(err.response));
  }
};

//previous n next id of artical
const prevNextArticleLoad = () => ({
  type: PREV_NEXT_ID_LOAD,
});
const prevNextArticleSuccess = (data) => ({
  type: PREV_NEXT_ID_SUCCESS,
  payload: data,
});
const prevNextArticleFailed = (error) => ({
  type: PREV_NEXT_ID_FAILED,
  err: error,
});

// const employeeId = localStorage.getItem("token");
export const prevNextArticle = (contentType,id) => async (dispatch) => {
  dispatch(prevNextArticleLoad());
  try {
    let url = `/ui-assist/v1/mahindra-experiencebyid/${contentType}/${id}`;

    const res = await services.api("GET", url);
    if (res) {
      dispatch(prevNextArticleSuccess(res.data));
    }
  } catch (err) {
    dispatch(prevNextArticleFailed(err.response));
  }
};