import {
  GET_PODCAST_SUCCESS,
  GET_PODCAST_LOAD,
  GET_PODCAST_FAILED,
  DELETE_PODCAST_SUCCESS,
  DELETE_PODCAST_LOAD,
  DELETE_PODCAST_FAILED,
  ADD_PODCAST_SUCCESS,
  ADD_PODCAST_LOAD,
  ADD_PODCAST_FAILED
} from "../Contants/Constants";
import Swal from "sweetalert2";

const initialState = {
  getAllData: [],
  addData: {},
  deleteData: "",
  isLoading: false,
  isError: "",
  status: "idle"
};

const getAllPodcastReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_PODCAST_LOAD:
      return {
        ...state,
        isLoading: true,
        status: "pending"
      };
    case GET_PODCAST_SUCCESS:
      return {
        ...state,
        getAllData: action.payload,
        isLoading: false,
        status: "success"
      };
    case GET_PODCAST_FAILED:
      return {
        ...state,
        isError: action.payload,
        isLoading: false,
        status: "failed"
      };

    case ADD_PODCAST_LOAD:
      return {
        ...state,
        isLoading: true,
        status: "pending"
      };
    case ADD_PODCAST_SUCCESS:
      return {
        ...state,
        addData: action.payload,
        isLoading: false,
        status: "success"
      };
    case ADD_PODCAST_FAILED:
      return {
        ...state,
        isError: action.payload,
        isLoading: false,
        status: "failed"
      };

    case DELETE_PODCAST_LOAD:
      return {
        ...state,
        isLoading: true,
        status: "pending"
      };
    case DELETE_PODCAST_SUCCESS:
      return {
        ...state,
        deleteData: [...state.deleteData, action.payload],
        isLoading: false,
        status: "success"
      };
    case DELETE_PODCAST_FAILED:
      return {
        ...state,
        isError: action.payload,
        isLoading: false,
        status: "failed"
      };
    default:
      return state;
  }
};

export default getAllPodcastReducer;
