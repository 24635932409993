import { Card, Grid, Avatar } from "@material-ui/core";
import React from "react";
import {
  LIVEWIRE,
  UPLOAD_ARTICLE,
  OC_REVIEW_ARTICLE,
  UPLOAD_A_STORY,
  SUBMIT_OFFER_CAMPAIGN,
} from "../../../../../Routes/constant";
import { useHistory } from "react-router-dom";
import "./AdminCardComponent.css";
import { diffDays } from "../../../../../Assets/helperFunctions/helperFunctions";
import moment from "moment";
// import { getDay } from "date-fns";
export default function AdminCardComponent({
  isOfferCampaignsView,
  filterdList,
}) {
  const history = useHistory();
  const getDays = (result) => {
    return diffDays(
      moment(result.requestedDate).format("DD/MM/YYYY"),
      moment(new Date()).format("DD/MM/YYYY")
    );
  };
  const scope =
    JSON.parse(localStorage.getItem("scopeDetails"))?.admin === true &&
    JSON.parse(localStorage.getItem("scopeDetails")).adminType === "OTHERS";

  const scopeDetails = JSON.parse(localStorage.getItem("scopeDetails"));

  return (
    <>
      {filterdList?.length > 0 && scope
        ? filterdList
            ?.filter((item) =>
              scopeDetails?.moduleAssigneds
                ?.map(
                  (moduleItem) =>
                    moduleItem?.module === "MAHINDRA_COLLECTIVE" &&
                    moduleItem?.sectorScope
                )
                ?.flat()
                ?.some((r) => item?.sector?.indexOf(r) >= 0)
            )
            ?.map((result, index) => (
              <Card className="adminCardAlign" key={result?.id}>
                <div className="adminCard">
                  <Grid
                    container
                    direction="row"
                    justifyContent="space-between"
                    alignItems="center"
                  >
                    <div className="adminCardTitle">{result.title}</div>

                    <div className="AdminCardDaysTogo">
                      {getDays(result) === 0
                        ? "Today"
                        : `${getDays(result)} days ago`}
                    </div>
                  </Grid>
                  <Grid
                    container
                    direction="row"
                    justifyContent="space-between"
                    alignItems="center"
                  >
                    <div className="adminCardSubTitle">{result.subTitle}</div>
                  </Grid>
                  <Grid
                    container
                    direction="row"
                    justifyContent="space-between"
                    alignItems="center"
                  >
                    <div className="adminCardProfileName">
                      {result?.isAuthorPost == true ? (
                        <>
                          <img
                            src={result.empPhotoUrl}
                            className="profileWidth"
                            alt="Admin profile"
                          />
                          <div>{result.name}</div>
                        </>
                      ) : (
                        <>
                          <Avatar></Avatar>
                          <div>{result?.submittedFor}</div>
                        </>
                      )}

                      {/* {result.empPhotoUrl ? (
                    <img
                      src={result.empPhotoUrl}
                      className="profileWidth"
                      alt="Admin profile"
                    />
                  ) : (
                    <Avatar></Avatar>
                  )}
                  <div>{result.name}</div> */}
                    </div>
                    <div
                      className="adminCardView"
                      onClick={() => {
                        isOfferCampaignsView
                          ? history.push({
                              pathname: `${LIVEWIRE}/submit-offer-campaign/${result?.id}`,
                              state: {
                                details: result,
                              },
                            })
                          : history.push({
                              // pathname: `${LIVEWIRE}${UPLOAD_A_STORY}`,
                              pathname: `${LIVEWIRE}/upload-story/${result?.id}`,
                              state: {
                                details: result,
                                scheduleDataFlag: false,
                              },
                            });
                      }}
                    >
                      <div>view</div>
                      <img
                        src={
                          process.env.PUBLIC_URL +
                          "/Images/adminProfile/arrow-down-s-line.svg"
                        }
                        alt="rightArrow"
                      />
                    </div>
                  </Grid>
                </div>
              </Card>
            ))
        : filterdList.map((result, index) => (
            <Card className="adminCardAlign">
              <div className="adminCard">
                <Grid
                  container
                  direction="row"
                  justifyContent="space-between"
                  alignItems="center"
                >
                  <div className="adminCardTitle">{result.title}</div>

                  <div className="AdminCardDaysTogo">
                    {getDays(result) === 0
                      ? "Today"
                      : `${getDays(result)} days ago`}
                  </div>
                </Grid>
                <Grid
                  container
                  direction="row"
                  justifyContent="space-between"
                  alignItems="center"
                >
                  <div className="adminCardSubTitle">{result.subTitle}</div>
                </Grid>
                <Grid
                  container
                  direction="row"
                  justifyContent="space-between"
                  alignItems="center"
                >
                  <div className="adminCardProfileName">
                    {result?.isAuthorPost == true ? (
                      <>
                        <img
                          src={result.empPhotoUrl}
                          className="profileWidth"
                          alt="Admin profile"
                        />
                        <div>{result.name}</div>
                      </>
                    ) : (
                      <>
                        <Avatar></Avatar>
                        <div>{result?.submittedFor}</div>
                      </>
                    )}

                    {/* {result.empPhotoUrl ? (
                    <img
                      src={result.empPhotoUrl}
                      className="profileWidth"
                      alt="Admin profile"
                    />
                  ) : (
                    <Avatar></Avatar>
                  )}
                  <div>{result.name}</div> */}
                  </div>
                  <div
                    className="adminCardView"
                    onClick={() => {
                      isOfferCampaignsView
                        ? history.push({
                            pathname: `${LIVEWIRE}/submit-offer-campaign/${result?.id}`,
                            state: {
                              details: result,
                            },
                          })
                        : history.push({
                            // pathname: `${LIVEWIRE}${UPLOAD_A_STORY}`,
                            pathname: `${LIVEWIRE}/upload-story/${result?.id}`,
                            state: {
                              details: result,
                              scheduleDataFlag: false,
                            },
                          });
                    }}
                  >
                    <div>view</div>
                    <img
                      src={
                        process.env.PUBLIC_URL +
                        "/Images/adminProfile/arrow-down-s-line.svg"
                      }
                      alt="rightArrow"
                    />
                  </div>
                </Grid>
              </div>
            </Card>
          ))}
    </>
  );
}
