import React, { useEffect } from "react";
import "./PublishArticleComponent.css";
import {
  Grid,
  Radio,
  FormControlLabel,
  RadioGroup,
  Checkbox,
} from "@material-ui/core";
import { useFormik } from "formik";
import { publishStorySchema } from "../../../../../Assets/formikErrors/SubmitOfferCampaignSchema";
import moment from "moment";
import CommonLabel from "../../../../Common/ComonLabel/CommonLabel";
import DatePicker from "../../../../Common/DatePicker/DatePicker";
import { useState } from "react";
import { checkPublishDatePassed } from "../../../../../Assets/helperFunctions/helperFunctions";
export default function PublishArticleComponent({
  title,
  existingPublishDate,
  existingExpiryDate,
  getValue,
}) {
  // const [radioValues, setRadioValues] = useState("publish");
  let isPublishDateOlder = checkPublishDatePassed(existingPublishDate);

  const formik = useFormik({
    initialValues: {
      expiryDate: null,
      publishDate: null,
      publishOrSchedule: isPublishDateOlder ? "schedule" : "publish",
      iswhatsnew: false,
    },
    enableReinitialize: true,
    validationSchema: publishStorySchema,
  });

  const { expiryDate, publishDate, publishOrSchedule, iswhatsnew } =
    formik.values;
  useEffect(() => {
    getValue(formik.values, formik.errors);
  }, [formik]);

  const handleRadioGroup = (e, name) => {
    if (name === "publish") {
      formik.setFieldValue("publishDate", null);
      formik.setFieldValue("expiryDate", null);
    }
    formik.handleChange(e);
  };

  const handleWhatsNew = (e) => {
    if (!formik.values.iswhatsnew) {
      formik.setFieldValue("expiryDate", null);
    }
    formik.setFieldValue("iswhatsnew", !iswhatsnew);
  };

  return (
    <div className="Modalcontent">
      <div className="publishArticleTitle">
        The story <b>`{title}`</b> will be published on Mahindra collective
      </div>
      <div className="publishContentInputs">
        <Grid container>
          <Grid item md={12} xs={12}>
            <RadioGroup
              aria-label="I am the author"
              name="publishOrSchedule"
              onChange={(e, publishOrSchedul) =>
                handleRadioGroup(e, publishOrSchedul)
              }
              value={publishOrSchedule}
              disabled={isPublishDateOlder}
            >
              <Grid container spacing={5}>
                <Grid item md={4} xs={12}>
                  <div
                    className={`${
                      publishOrSchedule === "publish" ? "radioBackground" : ""
                    } authorRadioButton`}
                  >
                    <FormControlLabel
                      value="publish"
                      className="radioButtonForm"
                      control={<Radio />}
                      label={
                        <span className="formControlLabelStyle commonFontNormalStyle">
                          Publish now
                        </span>
                      }
                      labelPlacement="end"
                      disabled={isPublishDateOlder}
                    />
                  </div>
                </Grid>
                <Grid item md={4} xs={12}>
                  <div
                    className={`${
                      publishOrSchedule === "schedule" ? "radioBackground" : ""
                    } authorRadioButton`}
                  >
                    <FormControlLabel
                      value="schedule"
                      className="radioButtonForm"
                      control={<Radio />}
                      label={
                        <span className="formControlLabelStyle commonFontNormalStyle">
                          Schedule to publish
                        </span>
                      }
                      labelPlacement="end"
                    />
                  </div>
                </Grid>
                <Grid item md={4} xs={12}>
                  <DatePicker
                    label={<CommonLabel label="Publish date" />}
                    // minDate={new Date().setDate(new Date().getDate() + 1)}
                    selectedDate={publishDate}
                    disabled={publishOrSchedule === "publish" ? true : false}
                    handleDateChange={(date) => {
                      formik.setFieldValue(
                        "publishDate",
                        moment(date.toDate()).format("YYYY-MM-DD")
                      );
                    }}
                    name="publishDate"
                    errorMsg={
                      formik.errors.publishDate ? formik.errors.publishDate : ""
                    }
                  />
                </Grid>
              </Grid>
            </RadioGroup>
          </Grid>
        </Grid>
        <Grid container className="articleM24">
          <Grid item md={12} xs={12}>
            <RadioGroup aria-label="I am the author" name="author">
              <Grid container spacing={5}>
                <Grid item md={5} xs={12}>
                  <div
                    className={`${
                      iswhatsnew ? "radioBackground" : ""
                    } authorRadioButton`}
                  >
                    <FormControlLabel
                      onChange={(e) => {
                        handleWhatsNew(e);
                        // formik.setFieldValue("iswhatsnew", !iswhatsnew);
                      }}
                      value={iswhatsnew}
                      name="iswhatsnew"
                      className="radioButtonForm"
                      control={<Checkbox />}
                      label={
                        <span className="formControlLabelStyle commonFontNormalStyle">
                          Publish story to What’s new
                        </span>
                      }
                      labelPlacement="end"
                    />
                  </div>
                </Grid>
                <Grid item md={5} xs={12}>
                  <DatePicker
                    // minDate={new Date().setDate(new Date().getDate() + 1)}
                    disabled={
                      publishOrSchedule === "publish" && !iswhatsnew
                        ? true
                        : false
                    }
                    label={<CommonLabel label="Expiry date" />}
                    selectedDate={expiryDate}
                    handleDateChange={(date) => {
                      formik.setFieldValue(
                        "expiryDate",
                        moment(date.toDate()).format("YYYY-MM-DD")
                      );
                    }}
                    name="expiryDate"
                    errorMsg={
                      formik.errors.expiryDate ? formik.errors.expiryDate : ""
                    }
                  />
                </Grid>
              </Grid>
            </RadioGroup>
          </Grid>
        </Grid>
      </div>
    </div>
  );
}
