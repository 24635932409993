import {
  GET_MODIFIED_BY_DATA_LOAD,
  GET_MODIFIED_BY_DATA_SUCCESS,
  GET_MODIFIED_BY_DATA_FAILED,
  LIKE_DATA_SUCCESS,
  LIKE_DATA_LOAD,
  LIKE_DATA_FAILED,
  ARTICLE_DATA_SUCCESS,
  ARTICLE_DATA_LOAD,
  ARTICLE_DATA_FAILED,
  DELETE_ARTICLE_SUCCESS,
  DELETE_ARTICLE_LOAD,
  DELETE_ARTICLE_FAILED,
  GET_OFFER_DATA_SUCCESS,
  GET_OFFER_DATA_LOAD,
  GET_OFFER_DATA_FAILED,
  SHARE_DATA_SUCCESS,
  SHARE_DATA_LOAD,
  SHARE_DATA_FAILED,
} from "../Contants/Constants";

const initialState = {
  lastModifiedData: {},
  isLoading: false,
  isError: "",
};

const getLastModifiedDataReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_MODIFIED_BY_DATA_LOAD:
      return {
        ...state,
        isLoading: true,
      };
    case GET_MODIFIED_BY_DATA_SUCCESS: {
      let finalState = {
        ...state,
        isLoading: false,
        moduleName: action.moduleName,
      };

      if (action.moduleName == "remoteworking") {
        finalState = {
          ...finalState,
          lastModifiedRemoteWork: action.payload,
        };
      }

      if (action.moduleName == "mahindracollective") {
        finalState = {
          ...finalState,
          lastModifiedCollective: action.payload,
        };
      }

      if (action.moduleName == "organnouncement") {
        finalState = {
          ...finalState,
          lastModifiedOrgAnnocement: action.payload,
        };
      }

      if (action.moduleName == "offerorcampaign") {
        finalState = {
          ...finalState,
          lastModifiedOfferCampaign: action.payload,
        };
      }

      if (action.moduleName == "whatsnew") {
        finalState = {
          ...finalState,
          lastModifiedWhatsNew: action.payload,
        };
      }

      if (action.moduleName == "emergency") {
        finalState = {
          ...finalState,
          lastModifiedEmergency: action.payload,
        };
      }

      if (action.moduleName == "entitlement") {
        finalState = {
          ...finalState,
          lastModifiedEntitlement: action.payload
        };
      }

      if (action.moduleName == "supportservice") {
        if (action.moduleSubType != "") {
          //For edit Support Category Services
          finalState = {
            ...finalState,
            lastModifiedSupportCategoryDetails: action.payload,
          };
        } else {
          //For Edit Categories dialogue
          finalState = {
            ...finalState,
            lastModifiedSupportCategory: action.payload,
          };
        }
      }

      if (action.moduleName == "mahindraexperience") {
        finalState = {
          ...finalState,
          lastModifiedExperience: action.payload,
        };
      }

      return finalState;
    }

    case GET_MODIFIED_BY_DATA_FAILED:
      return {
        ...state,
        isError: action.payload,
        isLoading: false,
      };
    default:
      return state;
  }
};

const initialLikeState = {
  likeStatus: false,
  isLoading: false,
  isError: "",
};

const likeDataReducer = (state = initialLikeState, action) => {
  switch (action.type) {
    case LIKE_DATA_LOAD:
      return {
        ...state,
        isLoading: true,
      };

    case LIKE_DATA_SUCCESS: {
      let finalState = {
        ...state,
        isLoading: false,
        moduleName: action.moduleName,
        likeStatus: action.payload,
      };
      return finalState;
    }

    case LIKE_DATA_FAILED:
      return {
        ...state,
        isError: action.payload,
        isLoading: false,
      };
    default:
      return state;
  }
};

const initialArticleState = {
  getData: {},
  articleID: "",
  previousID: "",
  nextID: "",
  isLoading: false,
  isError: "",
};

const getArticleDataReducer = (state = initialArticleState, action) => {
  switch (action.type) {
    case ARTICLE_DATA_LOAD:
      return {
        ...state,
        getData: {},
        articleID: "",
        isLoading: true,
      };

    case ARTICLE_DATA_SUCCESS: {
      return {
        ...state,
        getData: action.payload?.object,
        articleID: action.payload?.object?.id,
        previousID: action.payload?.previous,
        nextID: action.payload?.next,
        isLoading: false,
      };
    }

    case ARTICLE_DATA_FAILED:
      return {
        ...state,
        getData: {},
        articleID: "",
        isError: action.payload,
        isLoading: false,
      };
    default:
      return state;
  }
};

// delete article

const initialDeleteState = {
  deleteStatus: false,
  isLoading: false,
  isError: "",
  status: "",
};

const deleteArticleReducer = (state = initialDeleteState, action) => {
  switch (action.type) {
    case DELETE_ARTICLE_LOAD:
      return {
        ...state,
        isLoading: true,
        status: "loading",
      };

    case DELETE_ARTICLE_SUCCESS:
      return {
        ...state,
        isLoading: false,
        moduleName: action.moduleName,
        deleteStatus: action.payload,
        status: "success",
      };

    case DELETE_ARTICLE_FAILED:
      return {
        ...state,
        isError: action.payload,
        isLoading: false,
        status: "failed",
      };
    default:
      return state;
  }
};

const initialOfferState = {
  getData: {},
  isLoading: false,
  isError: "",
};

const getOfferDataReducer = (state = initialOfferState, action) => {
  switch (action.type) {
    case GET_OFFER_DATA_LOAD:
      return {
        ...state,
        getData: {},
        isLoading: true,
      };

    case GET_OFFER_DATA_SUCCESS: {
      return {
        ...state,
        getData: action.payload,
        isLoading: false,
      };
    }

    case GET_OFFER_DATA_FAILED:
      return {
        ...state,
        getData: {},
        isError: action.payload,
        isLoading: false,
      };
    default:
      return state;
  }
};

const initialShareState = {
  shareStatus: false,
  isLoading: false,
  isError: "",
};

const shareDataReducer = (state = initialShareState, action) => {
  switch (action.type) {
    case SHARE_DATA_LOAD:
      return {
        ...state,
        isLoading: true,
      };

    case SHARE_DATA_SUCCESS: {
      return {
        ...state,
        isLoading: false,
        shareStatus: action.payload,
      };
    }

    case SHARE_DATA_FAILED:
      return {
        ...state,
        isError: action.payload,
        isLoading: false,
      };
    default:
      return state;
  }
};

export {
  getLastModifiedDataReducer,
  likeDataReducer,
  getArticleDataReducer,
  deleteArticleReducer,
  getOfferDataReducer,
  shareDataReducer,
};
