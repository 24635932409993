import React, { useState, useEffect } from "react";
import "./RemoteFilters.css";

import { Grid, makeStyles } from "@material-ui/core";
import { useHistory } from "react-router-dom";
import SubmitButton from "../../../Common/Button/SubmitButton";
import CommonAutocomplete from "../../../Common/CommonAutocomplete/CommonAutocomplete";
import CommonMultiAutocomplete from "../../../Common/CommonMultiAutocomplete/CommonMultiAutocomplete";
import CommonLabel from "../../../Common/ComonLabel/CommonLabel";
import isAdmin from "../../../isAdmin";
import CommonToolTip from "../../../Common/CommonToolTip";
import services from "../../../../Services/index";
import { useDispatch, useSelector } from "react-redux";

import {
  currentDate,
  getAllYearsMonths,
  getAllYearsWithMonths,
  months,
} from "../../../../Assets/helperFunctions/helperFunctions";
import MultiSelect from "../../../Common/MultiSelect/MultiSelect";
import EditIcon from "../../../../Assets/Images/edit_red.png";
import { setRemoteWorkingFilterDateSuccess } from "../../../../Redux/Actions/RemoteWorkingAction";

export default function RemoteFilters({
  showSubmit,
  isShortBy,
  handleSortByChange,
  selectedSortBy,
  path,
  handleCalYearChange,
  handleMonthChange,
  calYearValue,
  monthValue,
  getDivisionList,
  buValue,
  getSectorList,
  onSubmitTapped,
}) {
  // const [getDivisionList, setGetDivisionList] = useState([]);
  const [getExsitingData, setExsitingData] = useState("");

  const calSelectYearOptions = getAllYearsWithMonths();
  let thisYear = new Date().getFullYear();

  const d = new Date();
  let monthName = months[d.getMonth()];
  let monthInedx = new Date().getMonth();

  const defaultDate = {
    title: `${monthName} ${thisYear}`.toUpperCase(),
    value: `${monthName} ${thisYear}`,
    year: `${thisYear}`,
    selectedMonth: monthInedx,
  };

  const remoteWorkingList = useSelector(
    (state) => state.getRemoteWorkingReducer
  );

  const sortBy = [
    {
      title: "Sort by latest",
      value: "desc",
    },
    {
      title: "Sort by old",
      value: "asc",
    },
  ];

  const history = useHistory();

  const handleBusinessDropdown = (getSectorList) => {
    // for (let i = 0; i < list.length; i++) {
    if (getDivisionList?.length > 0) {
      return (
        <Grid
          item
          xs={12}
          sm={showSubmit ? 4 : 3}
          className="businessTypeIcon noIcon"
        >
          <MultiSelect
            // disabled
            options={getDivisionList?.length && getDivisionList}
            label={<CommonLabel label="Business unit" />}
            handleSelectChange={(e, newValue) => {
              // handleBusinessChange(e, newValue, "bu");
            }}
            selectedValue={buValue}
            className="McShadowBox"
          />
        </Grid>
      );
      // }
      //  else {
      //   <></>;
      // }
    }
  };

  //picking the month dropdown according to selected year
  const handleMonthDropdown = (year, list) => {
    let cDate = new Date();
    let currentYear = cDate.getFullYear();
    let currentMonth = cDate.getMonth();

    for (let i = 0; i < list.length; i++) {
      if (year === list[i].value && list[i].child !== undefined) {
        // console.log("cal year balue", year, list[i].value);
        return (
          <Grid
            item
            xs={12}
            sm={showSubmit ? 4 : 3}
            className="calenderTypeIcon noIcon"
          >
            <CommonMultiAutocomplete
              options={list[i].child}
              label={<CommonLabel label={year} />}
              handleSelectChange={handleMonthChange}
              selectedSubcategory={monthValue}
              selectedValue={monthValue}
              className="McShadowBox"
            />
          </Grid>
        );
      } else {
        <></>;
      }
    }
  };

  const dispatch = useDispatch();
  useEffect(async () => {
    dispatch(setRemoteWorkingFilterDateSuccess(defaultDate));

    let userProfile = JSON.parse(
      localStorage.getItem("userProfile")
        ? localStorage.getItem("userProfile")
        : null
    );
    setExsitingData(userProfile);
  }, []);

  return (
    <div className="liveWireDropDownContainer">
      <Grid container direction="row">
        <Grid item xs={12} sm={showSubmit ? 9 : 12} className="zeroPad">
          <Grid container direction="row">
            {/* <CommonToolTip> */}
            <Grid
              item
              xs={12}
              sm={showSubmit ? 4 : 3}
              className="calenderTypeIcon"
            >
              <CommonAutocomplete
                // disabled
                options={calSelectYearOptions}
                label={<CommonLabel label="Calendar" />}
                handleSelectChange={handleCalYearChange}
                selectedValue={
                  remoteWorkingList?.remoteFilterDate
                    ? remoteWorkingList?.remoteFilterDate
                    : defaultDate
                }
                className="McShadowBox"
              />
            </Grid>
            {/* </CommonToolTip> */}
            {isShortBy ? (
              <Grid
                item
                xs={12}
                sm={showSubmit ? 4 : 3}
                className="shortingTypeIcon"
              >
                <CommonAutocomplete
                  options={sortBy}
                  label={<CommonLabel label={"Sort by"} />}
                  handleSelectChange={handleSortByChange}
                  selectedValue={selectedSortBy}
                  className="McShadowBox"
                  defaultValue={sortBy[0]}
                />
              </Grid>
            ) : null}
            {showSubmit && handleBusinessDropdown(getSectorList)}

            {handleMonthDropdown(calYearValue?.value, calSelectYearOptions)}
            {isAdmin() && (
              <Grid item xs={12} sm={4} className="ms-auto">
                {/* <SubmitButton
                    // disabled={!isAdmin()}
                    label="Edit"
                    className="MCsubmitbutton alignBtnRight"
                    onClick={() => onSubmitTapped()}
                  /> */}
                <div
                  className="headerContent1 ms-auto remoteMobileResponsiveHideAdmin"
                  onClick={() => onSubmitTapped()}
                >
                  {EditIcon && (
                    <img
                      src={EditIcon}
                      alt="icon"
                      className="modalTitleIcon1"
                      style={{ tintColor: "red", paddingBottom: "5px" }}
                    />
                  )}
                  <p className="modalHeaderText1">EDIT</p>
                </div>
              </Grid>
            )}
          </Grid>
        </Grid>
        <Grid
          item
          xs={12}
          sm={3}
          className="zeroPad posRight isMobileHideButton"
        >
          {showSubmit && isAdmin() && (
            <Grid item xs={12} sm={12}>
              <SubmitButton
                disabled={isAdmin()}
                label="Recognize"
                className="MCsubmitbutton floatRightAlign"
                onClick={() => history.push(path)}
              />
            </Grid>
          )}
          {showSubmit && !isAdmin() && (
            <CommonToolTip>
              <Grid item xs={12} sm={12}>
                <SubmitButton
                  // disabled={!isAdmin()}
                  label="Upload a story"
                  className="MCsubmitbutton floatRightAlign"
                  // onClick={() => history.push(path)}
                />
              </Grid>
            </CommonToolTip>
          )}
        </Grid>
      </Grid>
    </div>
  );
}
