import React from "react";
import { Grid } from "@material-ui/core";
import RowItem from "./RowItem";
import Availment from "./CTCVehicle/Availment";

const CTCVehicle = ({ data }) => {
  let fixUrl = "https://www.carandbike.com/leasing/sso";
  let applyURL = data?.applyURl ? data?.applyURl : fixUrl;

  return (
    <>
      <Grid
        container
        direction="row"
        justifyContent="flex-start"
        alignItems="center"
        spacing={2}
        className="alignCards"
      >
        <>
          <div>
            <h5>Entitlement</h5>
          </div>
          <Grid item xs={12} sm={12}>
            <Grid container direction="row" className="border2 py-2 ">
              {/* <Grid item xs={2} sm={2} md={2}>
                <div className="TableContent">Entitlement</div>
              </Grid> */}
              <Grid item xs={12} sm={12} md={12}>
                <div
                  className="TableContent fontboldnew"
                  style={{ fontWeight: 500 }}
                >
                  Vehicle Value {data?.values?.availedLimit} Lac - Ex showroom
                  price
                  {data?.values?.includingGST ? " Including" : " Excluding"} GST
                  &
                  {data?.values?.includingRTO ==
                  data?.values?.includingInsurance
                    ? data?.values?.includingRTO
                      ? " Including RTO & Insurance "
                      : " Excluding RTO & Insurance "
                    : (data?.values?.includingRTO
                        ? " Including RTO & "
                        : " Excluding RTO & ")(
                        data?.values?.includingInsurance
                          ? " Including Insurance"
                          : " Excluding Insurance"
                      )}
                </div>
              </Grid>
            </Grid>
            <Grid item xs={12} sm={12}>
              <Grid container direction="row" className="py-2">
                {" "}
              </Grid>
            </Grid>
            <Grid xs={12} sm={12}>
              <Availment />
            </Grid>

            <div className="notevalue">
              Please{" "}
              <a target="_blank" className="emailidfield" href={applyURL}>
                Click here
              </a>{" "}
              to avail CTC Vehicle
            </div>
          </Grid>
        </>
      </Grid>
    </>
  );
};

export default CTCVehicle;
