import React, { useState, useEffect } from "react";
import "../../../MahindraCollective/UploadStory/UploadStoryComponent.css";
import { Grid, FormControlLabel } from "@material-ui/core";
import Checkbox from "@mui/material/Checkbox";
import CharLeftField from "../../../../Common/CharLeftField/CharLeftField";
import CommonLabel from "../../../../Common/ComonLabel/CommonLabel";
import BenefitTitle from "./BenefitTitle";

const CTCVehicle = ({ data, setData }) => {
  const [includingGST, setIncludingGST] = useState(false);
  const [includingRTO, setIncludingRTO] = useState(false);
  const [includingInsurance, setIncludingInsurance] = useState(false);
  const [limit, setLimit] = useState("");
  const [isAllowed, setIsAllowed] = useState(false);

  useEffect(() => {
    if (data) {
      data?.map((val) => {
        if (val?.entitlementType == "CTC Vehicle") {
          setIsAllowed(true);
          setLimit(val?.values?.availedLimit);
          setIncludingGST(val?.values?.includingGST);
          setIncludingRTO(val?.values?.includingRTO);
          setIncludingInsurance(val?.values?.includingInsurance);
        }
      });
    }
  }, [data]);

  useEffect(() => {
    const val = {
      benefitsId: "62720e2c6bc86099de7d7bb3",
      availedLimit: limit,
      includingRTO: includingRTO,
      includingInsurance: includingInsurance,
      includingGST: includingGST
    };
    const isValid = CheckValidation();
    const data = {
      value: val,
      isAllowed: isAllowed,
      isValid: isValid
    };
    setData(data);
  }, [
    limit,
    includingRTO,
    includingInsurance,
    includingGST,
    isAllowed
  ]);
  
  useEffect(() => {
    if (!isAllowed) {
      setLimit("");
      setIncludingGST(false);
      setIncludingRTO(false);
      setIncludingInsurance(false);
    }
  }, [isAllowed]);

  function CheckValidation() {
    if (!isAllowed) {
      return true;
    } else {
        if (limit == "") {
          return false;
        } else {
          return true;
        }
    }
  };

  const handleGST = (e) => setIncludingGST(!includingGST);
  const handleRTO = (e) => setIncludingRTO(!includingRTO);
  const handleInsurance = (e) => setIncludingInsurance(!includingInsurance);
  const handleLimit = (e) => setLimit(e.target.value);

  return (
    <>
      <BenefitTitle title="CTC Vehicle" val={isAllowed} setVal={setIsAllowed} />
      <div className="divContainerOfferCampaign mb-5">
        <Grid container spacing={2} alignItems="top">
          <Grid
            item
            sm={6}
            md={3}
            xs={12}
            className="inputWidthTitle alignInputTextTitle boxwithlimit"
          >
            <CharLeftField
              label={<CommonLabel label="Max Limit ( in Lac )" mandatory />}
              value={limit}
              name="limit"
              type="number"
              disabled={!isAllowed}
              handleChange={handleLimit}
            />
          </Grid>
          <Grid item sm={6} md={3} xs={12}>
            <div className="radioBackground authorRadioButton">
              <FormControlLabel
                value="author"
                className="radioButtonForm"
                control={
                  <Checkbox
                    checked={includingGST}
                    disabled={!isAllowed}
                    onChange={handleGST}
                  />
                }
                label={
                  <span className="formControlLabelStyle commonFontNormalStyle">
                    Including GST
                  </span>
                }
                labelPlacement="end"
              />
            </div>
          </Grid>
          <Grid item sm={6} md={3} xs={12}>
            <div className="radioBackground authorRadioButton">
              <FormControlLabel
                value="author"
                className="radioButtonForm"
                control={
                  <Checkbox
                    checked={includingRTO}
                    disabled={!isAllowed}
                    onChange={handleRTO}
                  />
                }
                label={
                  <span className="formControlLabelStyle commonFontNormalStyle">
                    Including RTO
                  </span>
                }
                labelPlacement="end"
              />
            </div>
          </Grid>
          <Grid item sm={6} md={3} xs={12}>
            <div className="radioBackground authorRadioButton">
              <FormControlLabel
                value="author"
                className="radioButtonForm"
                control={
                  <Checkbox
                    checked={includingInsurance}
                    disabled={!isAllowed}
                    onChange={handleInsurance}
                  />
                }
                label={
                  <span className="formControlLabelStyle commonFontNormalStyle">
                    Including Insurance
                  </span>
                }
                labelPlacement="end"
              />
            </div>
          </Grid>
          {/* <Grid item sm={12} md={12} lg={12} xs={12}>

                  <span className="supportText commonFontNormalStyle m0">
                    <img
                      src={errorWarningIcon}
                      alt="error_warning"
                      className="errorWarningIcon"
                    />
                    Note: Entitlement Details - Vehicle Value - Ex showroom price including GST & Excluding RTO & Insurance
                  </span>
                </Grid> */}
        </Grid>
      </div>
    </>
  );
};;

export default CTCVehicle;
