import React from "react";
import { Checkbox } from "@material-ui/core";

function CommonCheckBox({ checked, disabled, handleChange, inputProps, name }) {
  return (
    <Checkbox
      checked={checked}
      onChange={handleChange}
      disabled={disabled}
      inputProps={inputProps}
      name={name}
      style={{ marginRight: "11px !important" }}
    />
  );
}

export default CommonCheckBox;
