import {
  GET_LOCALSTORAGE_STATUS_LOADING,
  GET_LOCALSTORAGE_STATUS_SUCCESS,
  GET_LOCALSTORAGE_STATUS_FAILED,
} from "../Contants/Constants";

const initialState = {
  isLoading: false,
  isLocalStorage: JSON.parse(localStorage.getItem("isLocalStorage"))
    ? JSON.parse(localStorage.getItem("isLocalStorage"))
    : false,
};

const getLocalStorageUpdationStatusReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_LOCALSTORAGE_STATUS_LOADING:
      return {
        ...state,
        isLoading: true,
      };
    case GET_LOCALSTORAGE_STATUS_SUCCESS:
      return {
        ...state,
        isLocalStorage: true,
        isLoading: false,
      };
    case GET_LOCALSTORAGE_STATUS_FAILED:
      return {
        ...state,
        isLoading: false,
      };
    default:
      return state;
  }
};

export default getLocalStorageUpdationStatusReducer;
