import React, { useEffect, useState, useRef } from "react";
import "./OrgAnnouncements.css";
import { Paper, Grid } from "@material-ui/core";
import Template from "../../Common/Template/Template";
import SearchBarButton from "../../Common/SearchBar/SearchBarButton";
// import { useMediaQuery } from "@material-ui/core";
import OrgSelectFeilds from "./OrgSelectFeilds";
import CommonModal from "../../Common/CommonModal/CommonModal";
import settingIcon from "../../../Assets/Images/settings_5_line.svg";
import filterIcon from "../../../Assets/Images/filter-line.svg";
import downloadLine from "../../../Assets/Images/download_line.svg";
import sendLine from "../../../Assets/Images/send_line.svg";
// import { menu } from "./menu";
import PdfViewerComponent from "../../DashBoard/WhatsNew/PdfViewer/PdfViewerComponent";
// import NestedSearchCheckBox from "../../Common/NestedSearchCheckBox/NestedSearchCheckBox";
import CommonAutocomplete from "../../Common/CommonAutocomplete/CommonAutocomplete";
import CommonMultiAutocomplete from "../../Common/CommonMultiAutocomplete/CommonMultiAutocomplete";
import CommonLabel from "../../Common/ComonLabel/CommonLabel";
import "../liveWirePages/LiveWireFilters/LiveWireFilters.css";
import { useMediaQuery, useTheme } from "@material-ui/core";
import Wheel from "../../../Assets/Images/Wheel.svg";
import OrgModal from "./OrgModal1";
//date picker
// import DatePicker from "../../Common/DatePicker/DatePicker";
import DatePickerOrg from "../../Common/DatePicker/DatePickerOrg";
import { useFormik } from "formik";
import Swal from "sweetalert2";
import moment from "moment";
import services from "../../../Services";

//checkboxauto
import Checkbox from "@mui/material/Checkbox";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import { Icon } from "@material-ui/core";
import CustomMultiSelect from "../../Common/CustomMultiSelect/CustomMultiSelect";
import {
  getAllYearsMonths,
  isAccesible,
} from "../../../Assets/helperFunctions/helperFunctions";
import PageTutorialComponent from "../../Common/PageTutorial/PageTutorialComponent";
import {
  addAnalayticsLog,
  addInterval,
} from "../../../Redux/Actions/Anaylatics";
//Redux
import { useDispatch, useSelector } from "react-redux";
import {
  getOrgData,
  getOrgCount,
  getOrgViewsData,
} from "../../../../src/Redux/Actions/OrgAction";

import {
  getAllSectors,
  getAllBusinessUnits,
  getAllFunctions,
} from "../../../../src/Redux/Actions/OrgAnnouncemenAction";
import platform from "platform";

//checkbox
const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = (
  <CheckBoxIcon fontSize="small" style={{ color: "#e31837" }} />
);

// export const ORG_PAGE_SIZE = 50;

export default function OrgAnnouncements() {
  const [showBusinessUnit, setShowBusinessUnit] = useState(true);
  const [link, setlink] = useState("");
  const dispatch = useDispatch();

  const [selectedSectors, setSelectedSectors] = useState([]);
  const [selectedBusinessUnits, setSelectedBusinessUnits] = useState([]);
  const [selectedFunctions, setSelectedFunctions] = useState([]);
  //For pagination
  const [currentUserPage, setCurrentUserPage] = useState(0);
  const loader = useRef(null);
  //calender
  const [calYearValue, setCalYearValue] = useState("");
  const [monthValue, setMonthValue] = useState("");
  const calSelectYearOptionss = getAllYearsMonths(2);

  const getOptionLabel = (option) => `${option.title}`;
  const getOptionDisabled = (option) => option.value === "foo";

  const orgDetails = useSelector((state) => state.getOrgDataReducer);

  const [openModal, setOpenModal] = useState(false);
  const [openFilterModal, setOpenFilterModal] = useState(false);
  const [openPDFModal, setOpenPDFModal] = useState(false);
  const { getData: orgData, isDataEnds: isDataEnds } = orgDetails;

  const adminData = useSelector((state) => state.getOrgAdminDataReducer);
  const { getAdminData: orgAdminData, isAdminDataEnds: isAdminDataEnds } =
    adminData || {};
  const [isFetching, setIsFetching] = useState(false);

  const [orgAnnouncements, setOrgAnnouncements] = useState(orgData);

  const allBusinessUnits = useSelector(
    (state) => state.getAllBusinessUnitsReducer
  );
  const { getData: allBusinessUnitsDetails } = allBusinessUnits;

  const allSectors = useSelector((state) => {
    return state.getAllSectorsReducer;
  });
  const { getData: allSectorsData } = allSectors;

  const allFunctions = useSelector((state) => state.getAllFunctionsReducer);
  const { getData: allFunctionsData } = allFunctions;

  const orgView = useSelector((state) => state.getOrgViewsReducer);

  const sectorDataList = useSelector(
    (state) => state.getOrgDataBySectorReducer
  );
  const { getData: orgDataBySector } = sectorDataList;

  const [allBusinessUnitsData, setAllBusinessUnitsData] = useState(
    allBusinessUnitsDetails
  );
  const [recentAnnouncements, setRecentAnnouncements] = useState([]);
  const [timeFrame, setTimeFrame] = useState("");

  const [boolPdf, setboolPdf] = useState(false);
  const [searchText, setSearchText] = useState("");
  const [isAPI, setIsAPI] = useState(false);

  const [selectedMonth, setSelectedMonth] = useState([]);
  const [selectedYear, setSelectedYear] = useState("");
  const [submitForApproval, setSubmitForApproval] = React.useState(false);

  //checkbox
  const focusStyle = { color: "#db233a" };
  const [focus, setFocus] = useState(false);

  const [openSettings, setOpenSettings] = useState(false);

  //breakPoint
  const Bp = useTheme();
  const isXs = useMediaQuery(Bp.breakpoints.down("xs"));

  const formik = useFormik({
    initialValues: {
      title: "",
      subTitle: "",
      thumbnail: null,
      videoUri: "",
      editorValue: "",
      docs: "",
      selectType: "",
      division: "",
      sector: "",
      name: "",
      email: "",
      phoneNumber: "",
      publishDate: null,
      radioValues: "colleague",
      hasTags: "",
      selectFileType: "",
    },
    enableReinitialize: true,
    onSubmit: (values, { resetForm }) => handleSubmit(values, resetForm),
  });

  const handleScroll = (event) => {
    const target = event?.target;
    if (target == undefined) {
      return;
    }

    if (isDataEnds) {
      setIsFetching(false);
    } else {
      if (
        target.scrollHeight - target.scrollTop == target.clientHeight + 1 ||
        target.scrollHeight - target.scrollTop == target.clientHeight - 1 ||
        target.scrollHeight - target.scrollTop == target.clientHeight
      ) {
        setCurrentUserPage(currentUserPage + 1);
        setIsFetching(true);
      } else {
        setIsFetching(false);
      }
    }
  };

  //----------------------------Calendar---------------------------------
  // const handleMonthDropdown = (year, list) => {
  //   for (let i = 0; i < list.length; i++) {
  //     if (year === list[i].value && list[i].child !== undefined) {
  //       return (
  //         <Grid
  //           item
  //           xs={12}
  //           sm={4}
  //           className="calenderTypeIcon noIcon calendarMaxWidth monthOrg"
  //         >

  //         </Grid>
  //       );
  //     } else {
  //       <></>;
  //     }
  //   }
  // };

  const handleCalYearChange = (e, selectType) => {
    setCalYearValue(selectType);
    setSelectedYear(selectType.value);

    if (monthValue.value !== selectType) {
      setMonthValue([]);
    }
    getOrgDataOnClear();
  };

  const handleMonthChange = (e, selectType) => {
    let selectedMonth = selectType.map((el) => el.value).join(",");
    setSelectedMonth(selectedMonth);
    getOrgDataOnClear();
    setMonthValue(selectType);
  };

  /* ==== Code for getting All Org Announcement Count ======== */
  const [totalRecords, setTotalRecords] = useState(0);
  const orgTotalCount = useSelector((state) => {
    return state?.getOrgCountReducer?.getTotalCount;
  });

  useEffect(() => {
    if (orgTotalCount) setTotalRecords(orgTotalCount);
  }, [orgTotalCount]);

  const countRef = useRef();
  //--------------------------------------------------------
  useEffect(() => {
    async function fetchData() {
      countRef.current = countRef.current + 1;

      // dispatch(getOrgData(currentUserPage));
      dispatch(getAllSectors());
      dispatch(getAllFunctions());
      if (isAccesible("OFFERS_AND_CAMPAIGNS")) {
        dispatch(getOrgCount());
      }
    }
    fetchData();
  }, []);

  //anaylatics log save
  //anaylatics log save
  useEffect(() => {
    dispatch(
      addAnalayticsLog({
        module: "org-announcement",

        browserDetails: `${platform.name} ${platform.os.architecture} ${platform.os.family} ${platform.os.version}`,

        deviceDetails: platform.product ? platform.product : "Desktop",

        isSearch: false,
      })
    );
    // you know what is this, don't you?
  }, []);

  //timer log...

  useEffect(() => {
    const interval = setInterval(() => {
      dispatch(addInterval(localStorage.getItem("logID")));
    }, 60000);
    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    if (allSectorsData.length > 0) {
      let sectorsArray = [
        ...new Set(
          allSectorsData.map((sector) => ({
            addsectorId: sector.value,
          }))
        ),
      ];
      dispatch(getAllBusinessUnits(sectorsArray));
    }
  }, [allSectorsData]);

  useEffect(() => {
    if (orgData && orgData.length > 0) {
      orgData[0].isSelected = true;
      getCurrentData(orgData[0]);
      setboolPdf(true);
    }
  }, [orgData]);

  useEffect(() => {
    setAllBusinessUnitsData(allBusinessUnitsDetails);
    // setRecentData();
  }, [allBusinessUnitsDetails]);

  useEffect(() => {
    // async function fetchData() {
    if (isFetching == true && !isDataEnds) {
      setTimeout(() => {
        callAPI();
      }, 2000);
    }
    // }
    // fetchData();
  }, [isFetching, isDataEnds]);

  async function callAPI() {
    orgData.map((item) => {
      item.isSelected = false;
      return item;
    });
    setboolPdf(false);

    let months = selectedMonth?.map((el) => el.value).join(",");
    let sectorsArray = [...new Set(selectedSectors.map((obj) => obj.value))];
    let buArray = [...new Set(selectedBusinessUnits.map((obj) => obj.value))];
    let fuArray = [...new Set(selectedFunctions.map((obj) => obj.value))];

    await dispatch(
      getOrgData(
        currentUserPage,
        sectorsArray,
        buArray,
        fuArray,
        selectedYear,
        months,
        searchText
      )
    );

    setOrgAnnouncements(orgData);
    // setRecentData();
  }

  useEffect(() => {
    setCurrentUserPage(0);
    callAPI();
  }, [
    selectedSectors,
    selectedBusinessUnits,
    selectedFunctions,
    selectedYear,
    selectedMonth,
    // openSettings,
    // isFetching,
  ]);

  useEffect(() => {
    if (isAPI) {
      setCurrentUserPage(0);
      callAPI();
      setIsAPI(false);
    }
  }, [isAPI]);

  const PdfBool = (id, bool) => {
    let list = orgData;
    list.map((item) =>
      id == item.contentID
        ? (item.isSelected = !item.isSelected)
        : (item.isSelected = false)
    );
    setRecentAnnouncements(list);
    setboolPdf(true);
  };

  const getCurrentData = (data) => {
    setlink(data.documentFileName);
    let userProfile = JSON.parse(
      localStorage.getItem("userProfile")
        ? localStorage.getItem("userProfile")
        : null
    );
    let isViewed = false;
    data?.viewedBy?.length > 0 &&
      data?.viewedBy?.map((id) => {
        id == userProfile?.employeeid && (isViewed = true);
      });
    !isViewed && dispatch(getOrgViewsData(data?.contentID));
  };

  //sorting the array based on date:
  // let sortedArray = orgData?.sort(function (a, b) {
  //   var dateA = new Date(a.date),
  //     dateB = new Date(b.date);
  //   //console.log( dateA - dateB)
  //   return dateA - dateB;
  // });
  const size = 10;

  //helper function for returning comp.
  const displayOrg = orgData?.map((item, index) => {
    let userProfile = JSON.parse(
      localStorage.getItem("userProfile")
        ? localStorage.getItem("userProfile")
        : null
    );
    let isViewed = false;
    item?.viewedBy?.length > 0
      ? item?.viewedBy?.map((id) => {
          id == userProfile?.employeeid && (isViewed = true);
        })
      : (isViewed = false);
    return (
      <OrgSelectFeilds
        key={index}
        currentData={getCurrentData}
        pdfBool={PdfBool}
        name={item.name}
        date={item.publishDate}
        index={index}
        data={item}
        isViewed={isViewed}
        searchText={searchText}
      />
    );
  });

  const modal = () => {
    setOpenSettings(!openSettings);
  };

  //formik
  const handleSubmit = async (values, resetForm) => {
    let formData = new FormData();
    const {
      title,
      subTitle,
      name,
      email,
      phoneNumber,
      // file,
      // pdf,
      // expiryDate,
      publishDate,
      radioValues,
      thumbnail,
      sector,
      division,
      selectType,
      hasTags,
      // textContent,
      editorValue,
      video,
      videoUri,
      doc,
    } = values;
    formData.append("bu", division.value);
    formData.append("sector", sector.value);
    formData.append("tags", hasTags);
    formData.append("story", editorValue);
    formData.append("storyType", selectType.value);
    formData.append("title", title);
    formData.append("videoUri", videoUri);
    formData.append("thumbnail", thumbnail);
    formData.append("subTitle", subTitle);
    formData.append(
      "publishDate",
      moment(publishDate.toDate()).format("YYYY-MM-DD")
    );
    formData.append("author", localStorage.getItem("token"));
    formData.append("name", name);
    formData.append("email", email);
    formData.append("phoneNumber", phoneNumber);
    formData.append("isAuthorPost", radioValues === "author" ? true : false);
    try {
      const data = await services.api(
        "POST",
        "/ui-assist/v1/mahindracollective",
        formData
      );
      if (data.status === 201) {
        resetForm();
        // setErrorSelectFileType("");
        setSubmitForApproval(true);
      }
    } catch (error) {
      Swal.fire("Something went wrong!", `${error.response.data}`, "error");
    }
    // else {
    //   setErrorSelectFileType("Select file is required");
    // }
  };

  const getOrgDataOnClear = async () => {};

  const handleToggleMonth = (selectedMonths) => {
    setSelectedMonth(selectedMonths);
  };

  const handleClearMonth = (selectedMonths) => {
    setSelectedMonth([]);
  };

  const handleSelectAllMonth = (isSelected) => {
    if (isSelected) {
      setSelectedMonth(calSelectYearOptionss[0].child);
    } else {
      setSelectedMonth([]);
    }
  };

  const handleSectorsToggleOption = (selectedOptions) => {
    setSelectedSectors(selectedOptions);
    formik.setFieldValue("publishDate", null);
    if (selectedOptions.length == 0) {
      setShowBusinessUnit(true);
      getOrgDataOnClear();
    } else {
      setShowBusinessUnit(false);
      let sectorsArray = [
        ...new Set(
          selectedOptions.map((sector) => ({ addsectorId: sector.value }))
        ),
      ];
      dispatch(getAllBusinessUnits(sectorsArray));
      // setAllBusinessUnitsData(allBusinessUnitsDetails);
    }
    setSelectedBusinessUnits([]);
    setSelectedFunctions([]);
    getOrgDataOnClear();
  };

  const handleBusinessUnitsToggleOption = (selectedOptions) => {
    setSelectedBusinessUnits(selectedOptions);
    formik.setFieldValue("publishDate", null);
    if (selectedOptions.length == 0) {
      getOrgDataOnClear();
    } else {
    }
    setSelectedFunctions([]);
    getOrgDataOnClear();
  };

  const handleFunctionsToggleOption = (selectedOptions) => {
    setSelectedFunctions(selectedOptions);
    formik.setFieldValue("publishDate", null);
    if (selectedOptions.length == 0) {
      getOrgDataOnClear();
    } else {
    }
    getOrgDataOnClear();
  };

  const handleClearSectors = () => {
    handleClearBusinessUnits();
    setShowBusinessUnit(true);
    setSelectedSectors([]);
    setSelectedBusinessUnits([]);
    setSelectedFunctions([]);
    getOrgDataOnClear();
  };

  const handleClearBusinessUnits = () => {
    // setAllBusinessUnitsData([]);
    setSelectedBusinessUnits([]);
    setSelectedFunctions([]);
    getOrgDataOnClear();
  };

  const handleClearFunctions = () => {
    setSelectedFunctions([]);
    getOrgDataOnClear();
  };

  const handleSelectAllSectors = (isSelected) => {
    if (isSelected) {
      let sectorsArray = [
        ...new Set(
          allSectorsData.map((sector) => ({ addsectorId: sector.value }))
        ),
      ];
      setSelectedSectors(allSectorsData);
      dispatch(getAllBusinessUnits(sectorsArray));
      // setAllBusinessUnitsData(allBusinessUnitsDetails);
      // handleSelectAllBusinessUnits(isSelected);
    } else {
      handleClearSectors();
      handleClearBusinessUnits();
    }
    setSelectedFunctions([]);
    setSelectedBusinessUnits([]);
    getOrgDataOnClear();
  };

  const handleSelectAllBusinessUnits = (isSelected) => {
    if (isSelected) {
      setSelectedBusinessUnits(allBusinessUnitsData);
    } else {
      handleClearBusinessUnits();
    }
    setSelectedFunctions([]);
    getOrgDataOnClear();
  };

  const handleSelectAllFunctions = (isSelected) => {
    if (isSelected) {
      setSelectedFunctions(allFunctionsData);
    } else {
      handleClearFunctions();
    }
    getOrgDataOnClear();
  };

  const handleSearch = (e) => {
    if (e.target.value) {
      setSearchText(e.target.value);
    } else {
      setSearchText("");
    }
  };

  //filters hide show in mobile
  const onFilterOrgAnnouncement = () => {
    setOpenFilterModal(true);
  };
  const onPDFOrgAnnouncement = () => {
    setOpenPDFModal(true);
  };

  const handleCloseModal = () => {
    setOpenModal(false);
    setOpenFilterModal(false);
    setOpenPDFModal(false);
  };
  const [display, setDisplay] = React.useState(false);

  const handleAPI = () => {
    setIsAPI(true);
  };

  const onKeyPress = (e) => {
    if (e.key === "Enter") {
      setIsAPI(true);
    }
  };

  return (
    <>
      <Template pageName={"Org announcements"}>
        <div>
          <Grid
            container
            direction="row"
            justifyContent="space-between"
            alignItems="flex-end"
          >
            <Grid item md={7} xs={12}>
              <div className="whatsNewHeader">
                <div className="containerTxt">
                  <div className="section-header-text"> Org announcements </div>

                  {/* <PageTutorialComponent
                    modelHeadtxt={"Livewire Tutorial"}
                    videoLink={
                      "https://newmrisedev.blob.core.windows.net/mrisestorage/1635246876564_Livewire%20V2.mp4"
                    }
                  /> */}
                  {isXs ? (
                    <> </>
                  ) : (
                    <>
                      {isAccesible("ORG_ANNOUNCEMNT") && (
                        <div className="editIcon" onClick={modal}>
                          <img
                            src={
                              process.env.PUBLIC_URL +
                              "/iconImages/MC_admin.svg"
                            }
                            alt=""
                            className="edit-line"
                          />
                          <div>Manage ({totalRecords ? totalRecords : 0})</div>
                        </div>
                      )}
                    </>
                  )}
                </div>
              </div>
            </Grid>
            <Grid item md={5} xs={12}>
              <SearchBarButton
                placeholder="Search people, tags, sectors or a story name"
                handleSearch={handleSearch}
                handleSearchIcon={handleAPI}
                onKeyPress={onKeyPress}
                searchText={searchText}
              />
            </Grid>
          </Grid>
        </div>

        <div className="MCMobileFilter">
          <Grid container direction="row" justifyContent="space-between">
            <Grid item onClick={onFilterOrgAnnouncement}>
              <div className="MCFilter" xs={6}>
                <img
                  src={process.env.PUBLIC_URL + "/iconImages/filter-line.svg"}
                  alt="filter icon"
                />
                Filter
              </div>
            </Grid>
          </Grid>
        </div>
        <Paper className="MClandingpageAlign mt-3 mb-0 mFilter Standardfilter">
          <div className="liveWireFilters">
            <div
              className={`${display ? "responsiveShowMC" : "responsiveHideMC"}`}
            >
              <Grid
                container
                direction="row"
                justifyContent={isXs ? `space-evenly` : `flex-start`}
                alignItems="left"
                // spacing={2}
                className="liveWireDropDownContainer"
              >
                <Grid
                  item
                  sm={3}
                  xs={12}
                  className=" calenderOrg monthCalender sector mtDrop "
                >
                  <CommonAutocomplete
                    options={calSelectYearOptionss}
                    label={<CommonLabel label="Year" />}
                    handleSelectChange={handleCalYearChange}
                    selectedValue={calYearValue}
                    className="McShadowBox"
                  />
                  <CustomMultiSelect
                    items={calSelectYearOptionss[0].child}
                    getOptionLabel={getOptionLabel}
                    getOptionDisabled={getOptionDisabled}
                    selectedValues={selectedMonth}
                    label="Month"
                    selectAllLabel="Select all"
                    onToggleOption={handleToggleMonth}
                    onClearOptions={handleClearMonth}
                    onSelectAll={handleSelectAllMonth}
                  />
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={3}
                  className="businessTypeIcon sector mtDrop "
                >
                  <CustomMultiSelect
                    items={allSectorsData}
                    getOptionLabel={getOptionLabel}
                    getOptionDisabled={getOptionDisabled}
                    selectedValues={selectedSectors}
                    label="Sector"
                    selectAllLabel="Select all"
                    onToggleOption={handleSectorsToggleOption}
                    onClearOptions={handleClearSectors}
                    onSelectAll={handleSelectAllSectors}
                  />
                  <CustomMultiSelect
                    items={allBusinessUnitsData}
                    getOptionLabel={getOptionLabel}
                    getOptionDisabled={getOptionDisabled}
                    selectedValues={selectedBusinessUnits}
                    // disabled={showBusinessUnit}
                    label="Business Unit"
                    selectAllLabel="Select all"
                    onToggleOption={handleBusinessUnitsToggleOption}
                    onClearOptions={handleClearBusinessUnits}
                    onSelectAll={handleSelectAllBusinessUnits}
                  />
                </Grid>

                <Grid
                  item
                  xs={12}
                  sm={3}
                  className="businessTypeIcon sector mtDrop "
                >
                  <CustomMultiSelect
                    items={allFunctionsData}
                    getOptionLabel={getOptionLabel}
                    getOptionDisabled={getOptionDisabled}
                    selectedValues={selectedFunctions}
                    label="Function"
                    selectAllLabel="Select all"
                    onToggleOption={handleFunctionsToggleOption}
                    onClearOptions={handleClearFunctions}
                    onSelectAll={handleSelectAllFunctions}
                  />
                </Grid>
              </Grid>
            </div>
          </div>
        </Paper>
        <Paper className="landingpageAlign orgPage  mt-0 mFilter">
          <Grid
            container
            direction="row"
            justifyContent="space-evenly"
            alignItems="flex-start"
            spacing={2}
            className="alignCardsItems2 positionOrg1"
          >
            <Grid
              item
              sm={4}
              xs={12}
              md={3}
              lg={3}
              className="fontFamily-Saira orgLeftContainer"
            >
              <p className="alignCardsItems orgLeftDivTitle">
                {orgData.length === 0
                  ? "No records available"
                  : `Latest announcements`}
              </p>
              <div
                // style={{ overflowY: "scroll", height: 700 }}
                onScroll={(e) => {
                  if (!isDataEnds) {
                    handleScroll(e);
                  }
                }}
                onClick={onPDFOrgAnnouncement}
                className="showMobileOrgAnn"
              >
                {displayOrg}
                {isFetching && !isDataEnds && "Fetching more list items..."}
              </div>
              <div
                // style={{ overflowY: "scroll", height: 700 }}
                onScroll={(e) => {
                  if (!isDataEnds) {
                    handleScroll(e);
                  }
                }}
                className="shoWInDesktopOrgAnn"
              >
                {displayOrg}
                {isFetching && !isDataEnds && "Fetching more list items..."}
              </div>
            </Grid>
            <Grid item md={9} sm={8} className="positionPdfContainer">
              <div className="alignCardsItems2 positionOrg1">
                <Grid
                  container
                  direction="row"
                  justifyContent="space-evenly"
                  alignItems="center"
                  spacing={2}
                >
                  <div className="positionOrg2">
                    {boolPdf ? (
                      <>
                        <PdfViewerComponent link={link}></PdfViewerComponent>
                        {/* <iframe src="/Sample.pdf#zoom=FitH" style={{position:"absolute",right:"0",top:"0",bottom:"0", width:"100%"}} className="iFrame" /> */}
                      </>
                    ) : (
                      <div style={{ height: 700 }}>
                        {/* <p className="selectTagPosition">
                          Select one announcement to preview
                        </p> */}
                      </div>
                    )}
                  </div>
                </Grid>
              </div>
            </Grid>
          </Grid>
        </Paper>
      </Template>
      <OrgModal
        open={openSettings}
        handleClose={() => setOpenSettings(false)}
        headerText="Manage org announcements"
        modalSize="lg"
        header={true}
        footer={true}
        fullWidth
      />
      <CommonModal
        open={openPDFModal}
        handleClose={handleCloseModal}
        headerText="PDF Content"
        icon={filterIcon}
        modalSize="lg"
        submitBtnText="Exit"
        onButtonClick={handleCloseModal}
        className="orgpdfmodal"
        fullWidth
        footerBannerCss
      >
        <div>
          {boolPdf ? (
            <>
              <PdfViewerComponent link={link}></PdfViewerComponent>
              {/* <iframe src="/Sample.pdf#zoom=FitH" style={{position:"absolute",right:"0",top:"0",bottom:"0", width:"100%"}} className="iFrame" /> */}
            </>
          ) : (
            <div style={{ height: 700 }}>
              {/* <p className="selectTagPosition">
                Select one announcement to preview
              </p> */}
            </div>
          )}
        </div>
      </CommonModal>
      <CommonModal
        open={openFilterModal}
        handleClose={handleCloseModal}
        headerText="Filter"
        icon={filterIcon}
        modalSize="lg"
        submitBtnText="Done"
        onButtonClick={handleCloseModal}
        fullWidth
        footerBannerCss
      >
        <div className="liveWireFilters Standardfilter ">
          <Grid
            container
            direction="row"
            justifyContent={isXs ? `space-evenly` : `flex-start`}
            alignItems="left"
            // spacing={2}
            className="liveWireDropDownContainer"
          >
            <Grid
              item
              sm={3}
              xs={12}
              className=" calenderOrg monthCalender sector mtDrop "
            >
              <CommonAutocomplete
                options={calSelectYearOptionss}
                label={<CommonLabel label="Year" />}
                handleSelectChange={handleCalYearChange}
                selectedValue={calYearValue}
                className="McShadowBox"
              />
              <CustomMultiSelect
                items={calSelectYearOptionss[0].child}
                getOptionLabel={getOptionLabel}
                getOptionDisabled={getOptionDisabled}
                selectedValues={selectedMonth}
                label="Month"
                selectAllLabel="Select all"
                onToggleOption={handleToggleMonth}
                onClearOptions={handleClearMonth}
                onSelectAll={handleSelectAllMonth}
              />
            </Grid>
            <Grid
              item
              xs={12}
              sm={3}
              className="businessTypeIcon sector mtDrop "
            >
              <CustomMultiSelect
                items={allSectorsData}
                getOptionLabel={getOptionLabel}
                getOptionDisabled={getOptionDisabled}
                selectedValues={selectedSectors}
                label="Sector"
                selectAllLabel="Select all"
                onToggleOption={handleSectorsToggleOption}
                onClearOptions={handleClearSectors}
                onSelectAll={handleSelectAllSectors}
              />
              <CustomMultiSelect
                items={allBusinessUnitsData}
                getOptionLabel={getOptionLabel}
                getOptionDisabled={getOptionDisabled}
                selectedValues={selectedBusinessUnits}
                // disabled={showBusinessUnit}
                label="Business Unit"
                selectAllLabel="Select all"
                onToggleOption={handleBusinessUnitsToggleOption}
                onClearOptions={handleClearBusinessUnits}
                onSelectAll={handleSelectAllBusinessUnits}
              />
            </Grid>

            <Grid
              item
              xs={12}
              sm={3}
              className="businessTypeIcon sector mtDrop "
            >
              <CustomMultiSelect
                items={allFunctionsData}
                getOptionLabel={getOptionLabel}
                getOptionDisabled={getOptionDisabled}
                selectedValues={selectedFunctions}
                label="Function"
                selectAllLabel="Select all"
                onToggleOption={handleFunctionsToggleOption}
                onClearOptions={handleClearFunctions}
                onSelectAll={handleSelectAllFunctions}
              />
            </Grid>
          </Grid>
        </div>
      </CommonModal>
    </>
  );
}
