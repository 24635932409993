import {
    GET_ALL_EMERGENCY_CONTACT_SUCCESS,
    GET_ALL_EMERGENCY_CONTACT_LOAD,
    GET_ALL_EMERGENCY_CONTACT_FAILED,
    PUT_EDIT_EMERGENCY_CONTACT_SUCCESS,
    PUT_EDIT_EMERGENCY_CONTACT_LOAD,
    PUT_EDIT_EMERGENCY_CONTACT_FAILED,
    DELETE_EMERGENCY_CONTACT_SUCCESS,
    DELETE_EMERGENCY_CONTACT_LOAD,
    DELETE_EMERGENCY_CONTACT_FAILED,
    ADD_EMERGENCY_CONTACT_SUCCESS,
    ADD_EMERGENCY_CONTACT_LOAD,
    ADD_EMERGENCY_CONTACT_FAILED,
} from "../Contants/Constants";

const initialState = {
  getAllData: [],
  editedData: {},
  addData: {},
  deleteData: "",
  isLoading: false,
  isError: "",
  status: "idle",
};

const getAllEmergencyContactReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_ALL_EMERGENCY_CONTACT_LOAD:
      return {
        ...state,
        isLoading: true,
        status: "pending",
      };
    case GET_ALL_EMERGENCY_CONTACT_SUCCESS:
      return {
        ...state,
        getAllData: action.payload,
        isLoading: false,
        status: "success",
      };
    case GET_ALL_EMERGENCY_CONTACT_FAILED:
      return {
        ...state,
        isError: action.payload,
        isLoading: false,
        status: "failed",
      };

      
    case PUT_EDIT_EMERGENCY_CONTACT_LOAD:
      return {
        ...state,
        isLoading: true,
        status: "pending",
      };
    case PUT_EDIT_EMERGENCY_CONTACT_SUCCESS:
      return {
        ...state,
        editedData: action.payload,
        isLoading: false,
        status: "success",
      };
    case PUT_EDIT_EMERGENCY_CONTACT_FAILED:
      return {
        ...state,
        isError: action.payload,
        isLoading: false,
        status: "failed",
      };

    
    case ADD_EMERGENCY_CONTACT_LOAD:
      return {
        ...state,
        isLoading: true,
        status: "pending",
      };
    case ADD_EMERGENCY_CONTACT_SUCCESS:
      return {
        ...state,
        addData: action.payload,
        isLoading: false,
        status: "success",
      };
    case ADD_EMERGENCY_CONTACT_FAILED:
      return {
        ...state,
        isError: action.payload,
        isLoading: false,
        status: "failed",
      };

      
    case DELETE_EMERGENCY_CONTACT_LOAD:
      return {
        ...state,
        isLoading: true,
        status: "pending",
      };
    case DELETE_EMERGENCY_CONTACT_SUCCESS:
      return {
        ...state,
        deleteData: [...state.deleteData, action.payload],
        isLoading: false,
        status: "success",
      };
    case DELETE_EMERGENCY_CONTACT_FAILED:
      return {
        ...state,
        isError: action.payload,
        isLoading: false,
        status: "failed",
      };
    default:
      return state;
  }
};

export default getAllEmergencyContactReducer;
