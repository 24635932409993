function isAdmin() {

  let userProfile = JSON.parse(localStorage.getItem("userProfile"));
  if (userProfile && userProfile.isAdmin === true) {
    return true;
  } else return false;
}
export  function isManager() {
  let userProfile = JSON.parse(localStorage.getItem("userProfile"));
  if (userProfile && userProfile.isManager === true) {
    return true;
  } else return false;
}
export default isAdmin;

