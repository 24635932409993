import services from "../../Services/index";
import Swal from "sweetalert2";
import {
  GET_OFFER_LIST_FAILED,
  GET_OFFER_LIST_LOAD,
  GET_OFFER_LIST_SUCCESS,
} from "../Contants/Constants";

export const OFFER_OFFSET = 18;

const getOffersListLoad = () => ({
  type: GET_OFFER_LIST_LOAD,
});

const getOffersListSuccess = (data, pageNo) => ({
  type: GET_OFFER_LIST_SUCCESS,
  payload: data,
  pageNo: pageNo,
});

const getOffersListFailed = (error) => ({
  type: GET_OFFER_LIST_FAILED,
  err: error,
});

export const getOffersList =
  (pageNo, storyType, searchText, offset = OFFER_OFFSET) =>
  async (dispatch) => {
    dispatch(getOffersListLoad());

    try {
      let baseUrl = "/ui-assist/v1/offerorcampaigns?";
      let i = 0;
      let params = [];
      if (pageNo >= 0) {
        params[i++] = `page=${pageNo}`;
      } else {
        params[i++] = `page=0`;
      }

      if (storyType && storyType === "mycontribution") {
        params[i++] = `type=${storyType}`;
      }

      if (searchText) {
        params[i++] = `q=${searchText}`;
      }

      if (offset) {
        params[i++] = `offset=${offset}`;
      } else {
        params[i++] = `offset=${OFFER_OFFSET}`;
      }

      let queryString = params.join("&");

      const res = await services.api("GET", baseUrl + queryString);

      if (res) {
        dispatch(getOffersListSuccess(res.data, pageNo));
      }
    } catch (err) {
      dispatch(getOffersListFailed(err?.response));
      Swal.fire("Something went wrong!", `${err?.response?.data}`, "error");
    }
  };
