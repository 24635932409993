import React, { useRef } from "react";
import { Grid } from "@material-ui/core";

import bgImg1 from "../../Assets/Images/ERDashboard/bgImg-1.png";
// import homeIcon from "../../Assets/Images/ERDashboard/ic_home.png";
import { ER_DATA } from "./ERDashboardData";
import ImageToggleOnHover from "../Common/ImageToggleOnHover/ImageToggleOnHover";

const ERDashboard = () => {
  // const imgRef = useRef(null);

  const handleClick = (url) => {
    console.log("Link clicked");
    window.open(url, "_blank", "noreferrer");
  };

  return (
    <>
      <Grid container spacing={0} alignItems="top" className="erPanel">
        <Grid item sm={6} md={8} xs={12}>
          <Grid container spacing={1}>
            {/* <Grid item sm={2} md={1}>
              <div
                style={{
                  background: "#e30101",
                  width: "1px",
                  height: "100vh",
                  margin: "auto",
                  marginTop: "30px",
                  position: "relative",
                }}
              >
                <div className="homeIcon">
                  <img src={homeIcon} />
                </div>
              </div>
            </Grid> */}

            <Grid item sm={12} md={12} xs={12}>
              <Grid container style={{ paddingTop: 20 }}>
                {ER_DATA ? (
                  ER_DATA.map((item, index) => {
                    return (
                      <Grid
                        item
                        key={index}
                        sm={2}
                        md={3}
                        xs={6}
                        style={{
                          justifyContent: "center",
                          alignContent: "center",
                          textAlign: "center",
                        }}
                        // onClick={handleClick(item?.url)}
                      >
                        <div className="erPanelBox">
                          <div className="erItem">
                            <a
                              href={item?.url}
                              target="_blank"
                              style={{
                                cursor: "pointer",
                                textDecoration: "none",
                              }}
                            >
                              <ImageToggleOnHover
                                source={item?.icon}
                                activeSource={item?.activeIcon}
                              />
                              <div
                                style={{
                                  color: "white",
                                  fontSize: "1rem",
                                  padding: "1rem",
                                }}
                              ></div>
                            </a>
                          </div>
                          <span className="erItemTitle">{item.title}</span>
                        </div>
                      </Grid>
                    );
                  })
                ) : (
                  <Grid item sm={12}>
                    No Records available
                  </Grid>
                )}
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid item sm={6} md={4} xs={12}>
          <div>
            <img
              src={bgImg1}
              alt="Mahindra Work portal ER Dashboard"
              style={{ height: "100%", width: "100%" }}
            />
          </div>
        </Grid>
      </Grid>
    </>
  );
};

export default ERDashboard;
