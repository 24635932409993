import services from "../../Services/index";
import {
  REMOVE_WHATSNEW_ITEM_LOAD,
  REMOVE_WHATSNEW_ITEM_FAILED,
  REMOVE_WHATSNEW_ITEM_SUCCESS,
  EDIT_WHATSNEW_ITEM_LOAD,
  EDIT_WHATSNEW_ITEM_FAILED,
  EDIT_WHATSNEW_ITEM_SUCCESS,
} from "../Contants/Constants";
import { getWhatsNewData } from "./WhatsAction";
const removeWhatsNewItemLoad = () => ({
  type: REMOVE_WHATSNEW_ITEM_LOAD,
});
const removeWhatsNewItemSuccess = (data) => ({
  type: REMOVE_WHATSNEW_ITEM_SUCCESS,
  payload: data,
});
const removeWhatsNewItemFailed = (error) => ({
  type: REMOVE_WHATSNEW_ITEM_FAILED,
  err: error,
});
export const removeWhatsNewItem = (id, sector) => async (dispatch) => {
  dispatch(removeWhatsNewItemLoad());
  try {
    const res = await services.api(
      "DELETE",
      `/ui-assist/v1/whatsnewsection/contentId/${id}/${sector}`
    );
    if (res) {
      dispatch(removeWhatsNewItemSuccess(res.data));
    }
  } catch (err) {
    dispatch(removeWhatsNewItemFailed(err.response));
  }
  const data = dispatch(getWhatsNewData());
  return data;
};

const editWhatsNewItemLoad = () => ({
  type: EDIT_WHATSNEW_ITEM_LOAD,
});
const editWhatsNewItemSuccess = (data) => ({
  type: EDIT_WHATSNEW_ITEM_SUCCESS,
  payload: data,
});
const editWhatsNewItemFailed = (error) => ({
  type: EDIT_WHATSNEW_ITEM_FAILED,
  err: error,
});
export const editWhatsNewItem = (data) => async (dispatch) => {
  dispatch(editWhatsNewItemLoad());
  console.log(data);
  try {
    const res = await services.api(
      "PUT",
      `/ui-assist/v1/whatsnewsection`,
      data
    );
    if (res) {
      dispatch(editWhatsNewItemSuccess(res.data));
    }
  } catch (err) {
    dispatch(editWhatsNewItemFailed(err.response));
  }
};
