import {
  Button,
  Input,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@material-ui/core";
import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { getPolicySubCategoryData } from "../../../../Redux/Actions/PolicyCategoryAction";
import axios from "axios";
import "./sub.css";
import TextField from "@mui/material/TextField";
import SubmitButton from "../../../Common/Button/SubmitButton";
import CharLeftField from "../../../Common/CharLeftField/CharLeftField";
import services from "../../../../Services";
import CommonLabel from "../../../Common/ComonLabel/CommonLabel";
import CommonToolTip from "../../../Common/CommonToolTip";
function NewCategoryTabPanel({ refresh, setrefresh }) {
  const dispatch = useDispatch();
  const [text, setText] = useState("");
  const [textError, setTextError] = useState("");

  const [id, setID] = useState("");

  useEffect(() => {
    dispatch(getPolicySubCategoryData(id));
  }, [id]);

  const handleAddCategoryApi = async (data) => {
    try {
      const res = await services.api(
        "POST",
        "/ui-assist/v1/policycategory",
        data
      );

      if (res.status === 200) {
        setID(res.data.categoryId);
        setrefresh(!refresh);
      }
    } catch (error) {}
  };

  const handleClickPolicy = () => {
    let send_data = {
      category: text,
      employeeId: localStorage.getItem("token"),
    };
    handleAddCategoryApi(send_data);
  };

  const handleCategoryChange = (e) => {
    const { value, name } = e.target;
    if (value.length < 3) {
      setTextError("too short");
      setText("");
    } else if (value) {
      setTextError("");
      setText(value);
    } else if (value === "") {
      setTextError("this field is required");
      setText("");
    }
  };

  return (
    <div>
      <div
        style={{
          margin: "16px 24px",
          justifyContent: "space-between",
          backgroundColor: "#f7f6f6",
          display: "flex",
          alignItems: "center",
          height: "84px",
        }}
      >
        <div
          style={{ marginLeft: "16px", display: "flex" }}
          className="categoryLabelGray"
        >
          {/* <Input type = 'text' placeholder = 'add category' /> */}
          {/*<TextField
            id="outlined-basic"
            label="Category name"
            variant="outlined"
            onChange={(e) => {
              setText(e.target.value);
            }}
            style={{ marginLeft: "8px" }}
          /> */}
          <CharLeftField
            label={<CommonLabel label="Category name" mandatory />}
            handleChange={(e) => handleCategoryChange(e)}
            name="Category_name"
            type="text"
            errorMsg={textError ? textError : ""}
          />
          {/* <Button onClick={handleClickPolicy}>save</Button> */}
          <SubmitButton
            label="SAVE"
            onClick={handleClickPolicy}
            className="newCategoryBtn"
            disabled={!Boolean(text)}
          />
        </div>
        <div className="Deletebtn">
          <img
            src={process.env.PUBLIC_URL + "/policies/delete-bin-6-line.svg"}
            alt="Add"
            style={{ marginRight: "4px", width: "20px", height: "20px" }}
          />
          <p style={{ color: "#e31837", fontSize: "12px", fontWeight: "600" }}>
            DELETE CATEGORY
          </p>
        </div>
        <div></div>
      </div>
      {/* <div style = {{display: 'flex', justifyContent: 'space-between'}}>
            <p>sub-categoris</p>
           <Button >add subcategories</Button> 
           </div> */}

      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          marginLeft: "24px",
          marginRight: "24px",
        }}
      >
        <p
          style={{
            fontSize: "18px",
            fontWeight: "600",
            paddingTop: "4px",
            marginBottom: "8px",
          }}
        >
          Sub-Categories
        </p>
        <div>
          <img
            src={process.env.PUBLIC_URL + "/policies/add-fill.svg"}
            alt="Add"
            style={{ marginRight: "-6px", color: "#a7a9ac" }}
          />
          <CommonToolTip title="add policy category first">
            <Button
              style={{
                
                fontSize: "12px",
                fontWeight: "600",
                color: "#a7a9ac",
                paddingRight: "0px",
              }}
            >
              ADD SUBCATEGORY
            </Button>
          </CommonToolTip>
        </div>
      </div>

      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>Sr. No.</TableCell>
              <TableCell align="right">Name</TableCell>
              <TableCell align="right">Description</TableCell>
              <TableCell align="right">Last modified by</TableCell>
              <TableCell align="right">Modified on</TableCell>
              <TableCell align="right">action</TableCell>
            </TableRow>
          </TableHead>
          <TableBody></TableBody>
        </Table>
      </TableContainer>
    </div>
  );
}

export default NewCategoryTabPanel;
