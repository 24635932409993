import React from "react";
import { Grid } from "@material-ui/core";
import TextArea from "../../Common/TextArea/TextArea";
// import CommonEditor from "../../Common/CommonEditor/CommonEditor";

import QuillEditor from "../../Common/QuillEditor/QuillEditor";
import uploadIcon from "../../../Assets/Images/collectiveIcons/upload-2-line.svg";
import CharLeftField from "../../Common/CharLeftField/CharLeftField";
import CommonLabel from "../../Common/ComonLabel/CommonLabel";
import CommonImageUpload from "../../Common/CommonImageUpload/CommonImageUpload";
import CommonPDFUpload from "../../Common/CommonPDFUpload/CommonPDFUpload";

import "./SubmitOfferCampaign.css";

function SelectFileTypeComponent({
  selectedValue,
  value,
  handleChangeTextArea,
  readOnly,
  textAreaCharLeft,
  formik,
  mediaType,
  media,
  story,
  disabled,
}) {
  const { file, pdf, textContent, editorValue } = formik.values;
  return (
    <Grid container>
      {selectedValue?.value === "image" || mediaType === "image" ? (
        <>
          <Grid item md={6} xs={12} className="uploadBtnStyle pe-2">
            <CommonImageUpload disabled={disabled} formik={formik} />
            <div className="compatiblityTxtImg">
              Compatible formats: - JPG, PNG (Max-5 MB) Recommended dimension -
              1010 X 670 PX
            </div>
          </Grid>
          <Grid item md={6} xs={12} className="ps-2 urlnamechange">
            <CharLeftField
              disabled={disabled}
              label={<CommonLabel label="Url" />}
              handleChange={formik.handleChange}
              value={value}
              name="url"
              handleBlur={formik.handleBlur}
              type="text"
              errorMsg={
                formik.errors.url && formik.touched.url ? formik.errors.url : ""
              }
            />
          </Grid>
        </>
      ) : (
        <></>
      )}
      {selectedValue?.value === "document" || mediaType === "document" ? (
        <>
          <Grid item md={6} xs={12} className="uploadBtnStyle">
            <CommonPDFUpload disabled={disabled} formik={formik} />
          </Grid>
          <Grid item md={4} xs={12}>
            <span className="compatiblityTxt">
              Compatible formats: PDF
              <br /> (Max size: 5MB)
            </span>
          </Grid>
        </>
      ) : (
        <></>
      )}
      {selectedValue?.value === "html" || mediaType == "html" ? (
        <Grid item md={12} xs={12}>
          <div style={{ position: "relative" }}>
            <TextArea
              name="textContent"
              placeholder="Paste your HTML code here..."
              handleChangeTextArea={handleChangeTextArea}
              value={story ? story : textContent}
              // maxLength={3500}
            />
            {/* <span className="textAreaCharLeft commonFontNormalStyle">
              {textAreaCharLeft} characters left
            </span> */}
          </div>
        </Grid>
      ) : (
        <></>
      )}
      {selectedValue?.value === "text" || mediaType == "text" ? (
        <Grid item md={12} xs={12}>
          <div style={{ position: "relative" }}>
            <QuillEditor
              handleChangeEditor={(e) => formik.setFieldValue("editorValue", e)}
              name="editorValue"
              value={story ? story : editorValue}
              readOnly={readOnly && readOnly}
            />
          </div>
        </Grid>
      ) : (
        <></>
      )}
    </Grid>
  );
}

export default SelectFileTypeComponent;
