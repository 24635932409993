import React from "react";
import "../EthicsHelpLine.css";
import { Grid } from "@material-ui/core";
import Template from "../../../Common/Template/Template";
import "../../Policies/PoliciesComponent.css";
import SearchBarButton from "../../../Common/SearchBar/SearchBarButton";
import EthicsWhatsNewCard from "../EthicsWhatsNewCard";
import { useMediaQuery, useTheme } from "@material-ui/core";
// import EthicsCollapse from "../EthicsMobileComponent/EthicsCollapse";
import EthicsMobileCard from "../EthicsMobileComponent/EthicsMobileCard";
import {cardDataLegal} from "../EthicsData";
// import EthicsHelpLineBanner from "../EthicsHelpLineBanner";

export default function LegalSupportt() {
    const [placeholder] = React.useState("Search people, tags, sectors or a story name");
    //breakPoint
    const Bp = useTheme();
    const isXs = useMediaQuery(Bp.breakpoints.down("xs"));
   
    return (
        <section>
            <Template>
                <Grid container direction="row" justifyContent="space-between" alignItems="center">
                    <Grid item xs={12} sm={7}>
                        <p className="offCHeaderTitle">Legal Support</p>
                    </Grid>
                    <Grid item xs={12} sm={5}>
                        <div className="policiesSearchWidth">
                            <SearchBarButton placeholder={placeholder} />
                        </div>
                    </Grid>
                </Grid>
                {/* <Grid
                    container
                    direction="row"
                    justifyContent="space-between"
                    alignItems="center"
                    className="fFSaira"
                >
                    <Grid item xs={12}>
                        <div className="EthicsSubTitlte">What’s new</div>
                    </Grid>
                    {isXs ? (
                        <>
                            <EthicsCollapse /> <EthicsCollapse /> <EthicsCollapse />
                        </>
                    ) : (
                        <>
                            <EthicsHelpLineBanner />
                        </>
                    )}
                </Grid> */}
                <Grid container direction="row">
                    <Grid item xs={12}>
                        <div className="EthicsSubTitlte mbEthics">Services</div>
                    </Grid>
                </Grid>

                <Grid container direction="row" spacing={2} justifyContent="flex-start" className="FooterCardDiv">

                            {cardDataLegal.map((item, index) => (
                                <Grid item xs={3}>
                                    <div key={index}>
                                        <EthicsWhatsNewCard EthicsCardData={item} />
                                    </div>
                                </Grid>
                            ))}
                </Grid>
            </Template>
        </section>
    );
}
