import React, { useState } from "react";

import Checkbox from "@mui/material/Checkbox";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import { Icon, makeStyles } from "@material-ui/core";
import textFieldStyle from "../Styles/TextFieldStyle";
import "./MultiSelect.css";

//checkbox

const useStyles = makeStyles({
  option: {
    
    fontSize: "14px",
    fontWeight: "normal",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: 1.57,
    letterSpacing: "normal",
    color: " #242424",
    backgroundColor: "transparent !important",
    "&:hover": {
      backgroundColor: "#f6eced !important",
      fontWeight: 600,
    },
  },
});

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;

const checkedIcon = (
  <CheckBoxIcon fontSize="small" style={{ color: "#e31837" }} />
);

// Top 100 films as rated by IMDb users. http://www.imdb.com/chart/top

const focusStyle = { color: "#db233a" };

const MultiSelect = ({
  disabled,
  selectedValue,
  defaultValue,
  options,
  errorMsg,
  //   handleBlurAutoComplete,
  handleSelectChange,
  label = "label",
}) => {
  const [focus, setFocus] = useState(false);
  const classes = useStyles();
  const commonTextFieldStyle = textFieldStyle();

  return (
    <div className="multiSelect">
      <Autocomplete
        limitTags={1}
        style={{ height: 54 }}
        onFocus={() => setFocus(true)}
        onBlur={() => setFocus(false)}
        disabled={disabled}
        value={selectedValue}
        defaultValue={defaultValue && defaultValue}
        onChange={handleSelectChange}
        multiple
        id="checkboxes-tags-demo"
        options={options}
        classes={{
          option: classes.option,
        }}
        popupIcon={<Icon style={focus ? focusStyle : null}>expand_more</Icon>}
        disableCloseOnSelect
        getOptionLabel={(option) => option.title}
        renderOption={(props, option, { selected }) => (
          <li {...props}>
            <Checkbox
              icon={icon}
              checkedIcon={checkedIcon}
              style={{ marginRight: 8 }}
              checked={selected}
            />
            {option.title}
          </li>
        )}
        style={{ width: 500 }}
        renderInput={(params) => (
          <TextField
            {...params}
            l
            error={errorMsg}
            helperText={errorMsg}
            // onBlur={handleBlurAutoComplete}
            label={label}
            variant="outlined"
            className={commonTextFieldStyle.root}
            style={{ backgroundColor: "#fbfbfb" }}
          />
        )}
      />
    </div>
  );
};
export default MultiSelect;
