import React, { useState, useEffect } from "react";
import "./LiveWireMcArticle2.css";
import "./commentCss.css";
import {
  Paper,
  Grid,
  Icon,
  Avatar,
  Card,
  CardActions,
  CardActionArea,
} from "@material-ui/core";
import Template from "../../Common/Template/Template";
// import { useMediaQuery } from "@material-ui/core";
import SubmitButton from "../../Common/Button/SubmitButton";
import shareIcon from "../../../Assets/Images/share_icon.svg";
import heartIcon from "../../../Assets/Images/heart_icon.svg";
import heartIconFill from "../../../Assets/Images/heartred-fill.svg";

import wheelWhite from "../../../Assets/Images/WheelWhite.svg";
import ZoomInIcon from "@material-ui/icons/ZoomIn";
import arrowIcon from "../../../Assets/Images/arrow_icon.svg";
import arrowIconUp from "../../../Assets/Images/arrow-up-down.svg";
import chatIcon from "../../../Assets/Images/chat_icon.svg";
import TextareaAutosize from "@material-ui/core/TextareaAutosize";
// import LiveWireArticleComment from "./LiveWireArticleComment";
import services from "../../../Services";
import CommonToolTip from "../../Common/CommonToolTip";
import ReactPlayer from "react-player/lazy";
import pdfIcon from "./../../../Assets/Images/pdf.png";
import IconButton from "@material-ui/core/IconButton";
import deleteIcon from "../../../Assets/Images/delete.svg";
import mailBoxIcon from "../../../Assets/Images/collectiveIcons/mail-line.svg";
import teamsIcon from "../../../Assets/Images/collectiveIcons/teamsIcon.jpg";
import yammerIcon from "../../../Assets/Images/collectiveIcons/Yammer.jpg";
import PdfViewerComponent from "../../../Components/DashBoard/WhatsNew/PdfViewer/PdfViewerComponent";
import Sharefiles from "../../Common/Shareoption/Sharefiles";
import arrowLeftIcon from "../../../Assets/Images/arrow-left-line.svg";
// import CommentForm from "./CommentForm";
import {
  getComments as getCommentsApi,
  createComment as createCommentApi,
  updateComment as updateCommentApi,
  deleteComment as deleteCommentApi,
} from "./api";

import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import { getCommentsData } from "../../../Redux/Actions/CommentsActions";

import { useLocation } from "react-router-dom";
import Swal from "sweetalert2";
import Loader from "../../Common/Loader/Loader";

import Comments from "../ArticleComment/Comments";
import CommonModal from "../../Common/CommonModal/CommonModal";
import { ClickAwayListener, Popper } from "@mui/material";
import {
  emptyArticle,
  likeArticle,
} from "../../../Redux/Actions/CommonActions";

import {
  getArticle,
  deleteArticle
} from "../../../Redux/Actions/CommonActions";
import {
  LIVEWIRE,
  LIVE_WIRE_ARTICLE2,
  LIVE_WIRE_ARTICLE3,
} from "../../../Routes/constant";
import { useHistory } from "react-router-dom";
import isAdmin from "../../../Components/isAdmin";
import { addAnalayticsLog, addInterval } from "../../../Redux/Actions/Anaylatics";
import platform from "platform";
import NotFound from "../../NotFound/NotFound";

export default function LiveWireMcArticle3({
  currentUserId = localStorage.getItem("token"),
  currentTab,
}) {
  const [locationKeys, setLocationKeys] = useState([]);
  const [refreshArticle, setRefreshArticle] = useState(false);
  const history = useHistory();
  const pathURL = window.location.pathname;
  const contentCategory = window.location.pathname.split("/")[4];
  // const [detailsData, setDetailsData] = useState([]);
  // const [articleID, setArticleID] = useState("");
  // console.log(detailsData, articleID, 'data from the server');

  //get selected article.
  useEffect(() => {
    dispatch(emptyArticle());
  }, []);
  useEffect(() => {
    dispatch(getArticle("offerorcampaignbyid", id, contentCategory));
  }, [refreshArticle]);

  const currentArticle = useSelector((state) => state.getArticleDataReducer);
  const deleteStatus = useSelector((state) => {
    return state?.deleteArticleReducer;
  });
  const {
    getData: detailsData,
    articleID: articleID,
    previousID: previousID,
    nextID: nextID,
  } = currentArticle;

  //     setDetailsData(presentArticle?.object);
  //      setArticleID(presentArticle?.object?.id);

  const [loading, setLoading] = useState(false);

  const [text, setText] = useState("");
  const [open, setOpen] = useState(false);
  const [contentType, setContentType] = useState("");
  const [mediaData, setMediaData] = useState({
    pdf: null,
    videoUri: null,
  });
  const [totalcommentCount, setTotalcommentCount] = useState(null);
  //const [commentCount, setCommentCount] = useState(0);
  const [refresh, setrefresh] = useState(true);
  
  const [isDelete, setIsDelete] = useState(false);
  const isDisabled = text.length === 0;

  const dispatch = useDispatch();
  const location = useLocation();
  const commentTrue = false;
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [backendComments, setBackendComments] = useState([]);
  const [activeComment, setActiveComment] = useState(null);

  //For Like/ unlike feature
  const [isLiked, setIsLiked] = useState(false);
  const [likesCount, setLikesCount] = useState(0);
  let empId = localStorage.getItem("token");
  //Selector
  const currentStatus = useSelector((state) => {
    return state?.likeDataReducer;
  });

  //fetch id from the url....
  const queryParams = new URLSearchParams(window.location.search);
  const id = queryParams.get("id");

  const handleLike = async () => {
    try {
      dispatch(likeArticle(contentType, articleID));

      if (!isLiked) {
        setLikesCount(likesCount + 1);
      } else {
        if (likesCount > 0) {
          setLikesCount(likesCount - 1);
        }
      }
      setIsLiked(!isLiked);
    } catch (error) {}
  };

  //Life cycle hook
  useEffect(() => {
    let likeBy = detailsData?.likeBy;
    if (likeBy) {
      let isExistinLike = likeBy.find((obj) => {
        return obj == empId;
      });
      setIsLiked(isExistinLike ? isExistinLike : false);
    }
    if (detailsData?.isDeleted) {
      history.push(`/not-found`);
    }
  }, [detailsData]);


//logs for offers and campaign...
    //anaylatics log save
    useEffect(() => {
 
      dispatch(addAnalayticsLog({
    
        module : "offer-campaigns",
    
        browserDetails : platform.name,
    
        deviceDetails : platform.product ? platform.product : 'Desktop',
    
        isSearch : false
    
    }));
      
    }, []);

    //timer log
    useEffect(() => {
      const interval = setInterval(() => {
        dispatch(addInterval(localStorage.getItem('logID')))
      }, 60000);
      return () => clearInterval(interval);
    }, []);

    useEffect(() => {
      if (deleteStatus.status == "success" && isDelete) {
        Swal.fire("Deleted successfully.", " ", "success");
        history.push("/connect/livewire/offer-campaigns");
        setIsDelete(false);
      } else if (deleteStatus.status == "failed" && !isDelete) {
        Swal.fire("Something went wrong.", " ", "error");
      }
    }, [deleteStatus]);


  // const getReplies = (commentId) =>
  //   backendComments
  //     .filter((backendComment) => backendComment.parentId === commentId)
  //     .sort(
  //       (a, b) =>
  //         new Date(a.createdAt).getTime() - new Date(b.createdAt).getTime()
  //     );
  // const addComment = (text, parentId) => {
  //   createCommentApi(text, parentId).then((comment) => {
  //     setBackendComments([comment, ...backendComments]);
  //     setActiveComment(null);
  //   });
  // };

  //handle share
  const handleAnchorEl = (event, id) => {
    event.stopPropagation();
    event.preventDefault();
    setAnchorEl(anchorEl ? null : event.currentTarget);
    // setAnchorEl(event.currentTarget);
  };

  const handleClickAway = () => {
    setAnchorEl(false);
  };

  //get rootComments.
  useEffect(() => {
    dispatch(getCommentsData(articleID));
  }, [articleID, refresh]);
  const RootComments = useSelector((state) => state.getCommentDataReducer);

  useEffect(() => {
    getCommentsApi().then((data) => {
      setBackendComments(data);
    });
    window.scrollTo(0, 0);
  }, []);

  const [header, setHeader] = useState("alignASocialIcon1");

  const listenScrollEvent = (event) => {
    if (window.scrollY < 100) {
      return setHeader("alignASocialIcon1");
    } else if (window.scrollY > 700) {
      return setHeader("alignASocialIcon2");
    }
  };

  useEffect(async () => {
    //try {
    //   setLoading(true);
    //   let res = await services.api(
    //     "GET",
    //     `/ui-assist/v1/${
    //       location.state.typeCheckForMahindra
    //         ? location.state.typeCheckForMahindra
    //         : location.state.list.type === "mahindraCollective"
    //         ? "mahindracollective"
    //         : "offerorcampaign"
    //     }/whatsnew?contentId=${
    //       location.state.list?.contents?._id
    //         ? location.state.list.contents._id
    //         : location.state.list.id
    //     }`
    //   );
    //   if (res.status === 200) {
    //     setLoading(false);
    //     setDetailsData(res.data);
    //     setArticleID(res.data.id);
    //   }
    // } catch (error) {
    //   setLoading(false);
    //   console.log("error for whats news id", error);
    // }
    window.addEventListener("scroll", listenScrollEvent);
    return () => window.removeEventListener("scroll", listenScrollEvent);
  }, []);

  //handle next click function.

  const handleNextClick = () => {
    setRefreshArticle(!refreshArticle);

    history.push({
      pathname: `${LIVEWIRE}${LIVE_WIRE_ARTICLE3}/${contentCategory}/?id=${nextID}`,
    });
  };

  const handlePreviousClick = () => {
    setRefreshArticle(!refreshArticle);

    history.push({
      pathname: `${LIVEWIRE}${LIVE_WIRE_ARTICLE3}/${contentCategory}/?id=${previousID}`,
    });
  };

  // useEffect(() => {
  //   (async () => {
  //     try {
  //       const res = await services.api(
  //         "GET",
  //         `/ui-assist/v1/commonlike/${articleID}`
  //       );
  //       setLikeCount(res.data);
  //     } catch (error) {
  //       console.log(error);
  //     }
  //   })();
  // }, [articleID]);

  useEffect(() => {
    return history.listen((location) => {
      if (history.action === "PUSH") {
        setLocationKeys([location.key]);
      }

      if (history.action === "POP") {
        if (locationKeys[1] === location.key) {
          setLocationKeys(([_, ...keys]) => keys);

          // Handle forward event
        } else {
          setLocationKeys((keys) => [location.key, ...keys]);

          // Handle back event
        }
      }
    });
  }, [locationKeys]);

  //imgZoomModal
  const [openZoom, setOpenZoom] = useState(false);
  const handleCloseImgModal = () => setOpenZoom(false);
  const imgModalZoom = () => setOpenZoom(true);

  // const handleCommentClick = () => {
  //   const data = {
  //     comment: text,
  //     type: "comment",
  //     employeeId: localStorage.getItem("token"),
  //     contentType: "MahindraCollective",
  //     contenId: articleID,
  //   };
  //   CommentSubmitApi(data);
  // };

  // const handleCancel = () => {
  //   setText("");
  // };

  const CommentSubmitApi = async (data) => {
    try {
      setLoading(true);
      const res = await services.api("POST", `/ui-assist/v1/comment`, data);
      if (res) {
        setLoading(false);
        Swal.fire({
          position: "center",
          icon: "success",
          title: "comment Added Successfully",
          showConfirmButton: false,
          timer: 1500,
        });

        setrefresh(!refresh);
        setText("");
      }
    } catch (err) {
      setLoading(false);
      if (err.response) {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Something went wrong!",
          footer: '<a href="">Why do I have this issue?</a>',
        });
      }
    }
  };

  const handleClickOpen = (contentType, detailsData) => {
    setOpen(true);
    setContentType(contentType);
    let values = { ...mediaData };
    values.pdf = detailsData.media;
    values.videoUri = detailsData.videoUri;
    setMediaData(values);
  };

  const openPDF = (docfLink) => {
    return <PdfViewerComponent link={docfLink}></PdfViewerComponent>;
  };
  
  function handleDelete() {
    try {
      dispatch(deleteArticle("offerorcampagin", detailsData?.id));
      setIsDelete(true);
    } catch (error) {
    console.log("In delete function =====");
    }
  }

  function handleEdit() {
    try {
      console.log("Edit clicked");
      history.push({
        pathname: `${LIVEWIRE}/submit-offer-campaign/${detailsData?.id}`,
        state: {
          details: detailsData,
        },
      });
    } catch (error) {
      console.log("Something went wrong");
    }
  }

  const handleClose = () => {
    setOpen(false);
    setContentType("");
    setMediaData({
      pdf: null,
      videoUri: null,
    });
  };
  const videoPlayer = (link) => {
    return (
      <ReactPlayer
        url={link}
        controls
        width="100%"
        height="100%"
        className="playerStyle"
        playing
        onError={(err) => console.log("Error", err)}
      />
    );
  };

  // //get all id's
  // useEffect(() => {
  //   (async () => {
  //     try {
  //       const res = await services.api("GET", `/ui-assist/v1/getallcontentid`);
  //     } catch (error) {}
  //   })();
  // }, []);

  console.log("details data ====> ", detailsData);

  return (
    <div className="fontFamilyArticle2">
      <CommonModal
        open={open}
        handleClose={handleClose}
        header={false}
        footer={false}
        modalSize="lg"
        addClassForVideo={true}
        contentType={contentType}
        submitBtnText="DONE"
      >
        <Card className="videoPlayer">
          <CardActionArea className="videoPlayerCardArea">
            {contentType === "pdf"
              ? openPDF(mediaData.pdf ? mediaData.pdf : null)
              : videoPlayer(mediaData.videoUri ? mediaData.videoUri : null)}
          </CardActionArea>
        </Card>
      </CommonModal>
      <Template pageName={detailsData.title}>
        {loading ? <Loader /> : null}
        <div className="commonTemplate">
          <Grid container spacing={5} className="alignMCArticals">
            <Grid item sm={12} className="articleRightP">
              <Paper className="landingpageAlign2">
                <Grid
                  container
                  direction="column"
                  style={{ position: "fixed" }}
                  className="iconWidth leftfixedbox"
                >
                  <Grid
                    item
                    style={{
                      display: "flex",
                      alignItems: "center",
                      marginTop: "8px",
                    }}
                  >
                    <img
                      src={shareIcon}
                      alt="downloadLine"
                      className="alignSocialIcons"
                      onClick={(event) => handleAnchorEl(event)}
                    />
                  </Grid>
                  <Popper
                    className="popperBlock"
                    open={Boolean(anchorEl)}
                    anchorEl={anchorEl}
                  >
                    <ClickAwayListener onClickAway={handleClickAway}>
                      <Popper
                        className="popperBlock"
                        open={Boolean(anchorEl)}
                        anchorEl={anchorEl}
                      >
                        <ClickAwayListener onClickAway={handleClickAway}>
                          <Paper className="papperClass shareoptpanel">
                            <Sharefiles pathURL={pathURL} id={articleID} contentType="offerAndCampaign" />
                          </Paper>
                        </ClickAwayListener>
                      </Popper>
                    </ClickAwayListener>
                  </Popper>

                  {/* <Grid
                item
                style={{
                  display: "flex",
                  alignItems: "center",
                  marginTop: "8px",
                }}
                onClick={handleLike}
              >
                {isLiked ? (
                  <img
                    src={heartIconFill}
                    alt="downloadLine"
                    className="alignSocialIcons"
                  />
                ) : (
                  <img
                    src={heartIcon}
                    alt="downloadLine"
                    className="alignSocialIcons"
                  />
                )}

                <span className="articleCount">{likesCount}</span>
              </Grid> */}

                  {!commentTrue ? (
                    <>
                      <Grid
                        item
                        style={{
                          display: "flex",
                          alignItems: "center",
                          marginTop: "8px",
                        }}
                      >
                        <img
                          src={chatIcon}
                          alt="downloadLine"
                          className="alignSocialIcons"
                        />
                        <span className="articleCount">
                          {totalcommentCount}
                        </span>
                      </Grid>
                    </>
                  ) : (
                    <> </>
                  )}

                  <Grid
                    item
                    style={{
                      display: "flex",
                      alignItems: "center",
                      marginTop: "8px",
                    }}
                  >
                    <img
                      src={arrowIcon}
                      onClick={handlePreviousClick}
                      alt="downloadLine"
                      className="alignSocialIcons"
                    />
                  </Grid>
                  <Grid
                    item
                    style={{
                      display: "flex",
                      alignItems: "center",
                      marginTop: "8px",
                    }}
                  >
                    <img
                      src={arrowLeftIcon}
                      onClick={handleNextClick}
                      alt="downloadLine"
                      className="alignSocialIcons"
                    />
                  </Grid>
                </Grid>
                <Grid
                  container
                  direction="column"
                  justifyContent="flex-start"
                  // alignItems="flex-start"
                >
                  <div className="Amtmb">
                    <Grid container direction="row">
                      {detailsData.tags?.map((item, index) => (
                        <>
                          <div className="tagStyle" key={index}>
                            <div className="tagText">{item}</div>
                          </div>
                        </>
                      ))}
                      {isAdmin() && (
                        <>
                          <Grid item xs="auto" className="ms-auto">
                            <span
                              onClick={handleEdit}
                              style={{
                                fontSize: "14px",
                                color: "#e31837",
                                fontWeight: "600",
                                marginRight: "1rem",
                                cursor: "pointer",
                              }}
                              className="ms-auto"
                            >
                              EDIT
                            </span>
                            <IconButton
                              aria-label="add to favorites"
                              onClick={() => handleDelete(id)}
                            >
                              <img src={deleteIcon} alt="delete" />
                            </IconButton>
                          </Grid>
                        </>
                      )}
                    </Grid>
                  </div>
                  <Grid item className="fullWidth" xs={12}>
                    <div className="article2ContentTitle">
                      {detailsData.title}
                    </div>
                    {!commentTrue ? (
                      <>
                        <div className="article2ContentSubTitle">
                          {detailsData.subTitle}
                        </div>
                      </>
                    ) : (
                      <>
                        <div className="article2ContentSubTitle">
                          {detailsData.subTitle}
                        </div>
                      </>
                    )}
                    {!commentTrue ? (
                      <>
                        <Grid
                          container
                          className="alignArticalTopDetails"
                          direction="row"
                        >
                          <Grid item sm={3}>
                            <div className="avatarDetailsAlignment">
                              {location?.state?.empPhotoUrl || detailsData?.empPhotoUrl ? (
                                <img
                                  src={detailsData?.empPhotoUrl ? detailsData?.empPhotoUrl : location?.state?.empPhotoUrl}
                                  alt=""
                                  className="avatar mc2Profle"
                                />
                              ) : (
                                <Avatar></Avatar>
                              )}

                              <div className="avatarNameDate">
                                <p className="articleAvatarName">
                                  {detailsData.name}
                                </p>
                                <p className="avatarDate">
                                  {moment(detailsData.publishDate).format(
                                    "DD/MM/YYYY"
                                  )}
                                </p>
                              </div>
                            </div>{" "}
                          </Grid>
                          {/* <Grid item sm={3}>
                          <div className="avatarDetailsAlignment">
                            {location?.state?.empPhotoUrl ? (
                              <img
                                src={location?.state?.empPhotoUrl}
                                alt=""
                                className="avatar mc2Profle"
                              />
                            ) : (
                              <Avatar></Avatar>
                            )}

                            <div className="avatarNameDate">
                              <p className="avatarDate">Author:</p>
                              <p className=" articleAvatarName">
                                {detailsData.name}
                              </p>
                            </div>
                          </div>{" "}
                        </Grid>
                        <Grid item sm={3}>
                          <div className="avatarDetailsAlignment">
                            {location?.state?.empPhotoUrl ? (
                              <img
                                src={location?.state?.empPhotoUrl}
                                alt=""
                                className="avatar mc2Profle"
                              />
                            ) : (
                              <Avatar></Avatar>
                            )}

                            <div className="avatarNameDate">
                              <p className="avatarDate">Submitted by:</p>
                              <p className="articleAvatarName">
                                {detailsData.name}
                              </p>
                            </div>
                          </div>{" "}
                        </Grid>
                        <Grid item sm={3}>
                          <div className="avatarDetailsAlignment">
                            <div className="avatarNameDate">
                              <p className="avatarDate">Published on:</p>
                              <p className="articleAvatarName">
                                {moment(detailsData.publishDate).format(
                                  "DD/MM/YYYY"
                                )}
                              </p>
                            </div>
                          </div>{" "}
                        </Grid> */}
                        </Grid>
                      </>
                    ) : (
                      <>
                        <div className="avatarNameDate">
                          <p className="avatarDate">21st July, 2021</p>
                        </div>{" "}
                      </>
                    )}
                    <div className="contentpanel">
                      <p></p>
                      <div className="articleImgDivContainer">
                        <div className="imgpanelleft oc-pic">
                          {detailsData?.url ? (
                            <img
                              src={detailsData.thumbnail}
                              alt="avatar"
                              className="articlePic"
                              onClick={() =>
                                window.open(`${detailsData.url}`, "_blank")
                              }
                            />
                          ) : (
                            <img
                              src={detailsData.thumbnail}
                              alt="avatar"
                              className="articlePic "
                            />
                          )}
                          {/* <ZoomInIcon
                        className="articleZoomInIconImg"
                        onClick={imgModalZoom}
                      /> */}
                        </div>

                        {/*detailsData.url != null && (
                      <span
                        className="ViewURL"
                        onClick={() =>
                          window.open(`${detailsData.url}`, "_blank")
                        }
                      >
                        VIEW
                      </span>
                    )*/}

                        <CommonModal
                          handleClose={handleCloseImgModal}
                          className="articleZoomImageModal"
                          modalSize="lg"
                          open={openZoom}
                          header={true}
                          footer={false}
                          headerText="Preview image"
                          icon={wheelWhite}
                        >
                          <div
                            style={{
                              padding: "20px",
                              backgroundColor: "black",
                            }}
                          >
                            <img
                              src={detailsData.thumbnail}
                              alt="articleImmage"
                              style={{ height: "100%", width: "100%" }}
                            />
                          </div>
                        </CommonModal>
                      </div>
                      <p>
                        {detailsData.story !== "null" &&
                        detailsData.story !== null ? (
                          <div
                            dangerouslySetInnerHTML={{
                              __html: detailsData.story,
                            }}
                          ></div>
                        ) : (
                          ""
                        )}
                      </p>
                    </div>

                    {detailsData.mediaType === "document" && (
                      <div className="attachmentMB">
                        <div className="attachmentTitle">Attachments</div>
                        <div>
                          <Icon
                            // className="pdfStyle pointer"
                            className="pointer"
                            title="show PDF"
                            onClick={() => handleClickOpen("pdf", detailsData)}
                          >
                            <img
                              className="pointer pdfIcon"
                              src={pdfIcon}
                              alt="no pdf icon"
                            />
                          </Icon>
                        </div>
                      </div>
                    )}
                  </Grid>
                </Grid>

                {!commentTrue ? (
                  <>
                    {" "}
                    <Grid
                      container
                      direction="column"
                      justifyContent="space-evenly"
                    >
                      <Grid item xs={12}>
                        <Paper className="commentPaper">
                          <Grid
                            container
                            direction="column"
                            justifyContent="flex-start"
                          >
                            <Grid
                              container
                              direction="column"
                              justifyContent="flex-start"
                              spacing={2}
                            >
                              <Grid item xs={12}>
                                {!commentTrue ? (
                                  <Comments
                                  contentType = "offerAndCampaign"
                                    articleID={articleID}
                                    setRootCommentCount={setTotalcommentCount}
                                    className="textAreaSize sda"
                                  />
                                ) : (
                                  <></>
                                )}
                                {/* <LiveWireArticleComment />
                          <LiveWireArticleComment />
                          <LiveWireArticleComment /> */}
                                {/* <div className="comments-container">
                                {rootComments.map((rootComment) => (
                                  <LiveWireArticleComment
                                    key={rootComment.id}
                                    comment={rootComment}
                                    replies={getReplies(rootComment.id)}
                                    activeComment={activeComment}
                                    setActiveComment={setActiveComment}
                                    addComment={addComment}
                                    deleteComment={deleteComment}
                                    updateComment={updateComment}
                                    currentUserId={currentUserId}
                                  />
                                ))}
                              </div> */}
                              </Grid>
                            </Grid>
                          </Grid>
                        </Paper>
                      </Grid>
                    </Grid>
                  </>
                ) : (
                  <> </>
                )}
              </Paper>
            </Grid>
          </Grid>
        </div>
      </Template>
    </div>
  );
}
