import React, { useState, useEffect } from "react";
import "./AddFavourite.css";
import CommonModal from "../../../../Common/CommonModal/CommonModal";
import { Tabs, Tab, Grid } from "@material-ui/core";
import FavouriteTab from "./FavouriteTab";
// import data from "./Favourite.json";
// import SortByDiv from "../../../../Common/SortByDiv/SortByDiv";
import SearchBarButton from "../../../../Common/SearchBar/SearchBarButton";
import { getAddFavData } from "../../../../../Redux/Actions/AddFavAction";
import { getFavouriteData } from "../../../../../Redux/Actions/DashboardAction";
import { useDispatch, useSelector } from "react-redux";
import services from "../../../../../Services/index";
import Swal from "sweetalert2";

const AddFavourite = ({
  open,
  handleClose,
  headerText,
  icon,
  modalSize,
  header,
  footer,
  fullWidth,
  getMyWorkLength,
  title,
  // getFavouriteData,
}) => {
  const dispatch = useDispatch();
  const [tab, setTab] = useState(0);
  const [getDataTitle, setGetDataTitle] = useState("myWork");
  const handleTabs = (e, value) => {
    if (value === 0) {
      setGetDataTitle("myWork");
    } else {
      setGetDataTitle("manageTeam");
    }
    setTab(value);
  };
  const [ascending, setAscending] = useState(true);
  const [searchText, setsearchText] = useState("");
  const [searchData, setSearchData] = useState(null);
  const [addMoreExist, setAddMoreExits] = useState("");
  const [getAddTiles, setGetAddTiles] = useState([]);

  const getTab = () => {
    if (tab === 0) {
      return "all";
    } else if (tab === 1) {
      return "work";
    } else if (tab === 2) {
      return "connect";
    } else if (tab === 3) {
      return "learnAndGrow";
    }
  };

  const handleAscending = () => {
    let getTabName = getTab();
    setAscending(!ascending);
    if (ascending) {
      data[getTabName].sort((a, b) => {
        if (a.myWork > b.myWork) {
          return 1;
        }
        if (a.myWork < b.myWork) {
          return -1;
        }
        return 0;
      });
    } else {
      data[getTabName].sort((a, b) => {
        if (a.myWork > b.myWork) {
          return -1;
        }
        if (a.myWork < b.myWork) {
          return 1;
        }
        return 0;
      });
    }
  };
  const handleFilterData = (dataToBeSearch) => {
    let getTabName = getTab();
    let searchData = data[getTabName].filter(
      (data) => data.myWork.toLowerCase() === dataToBeSearch.toLowerCase()
    );
    setSearchData(searchData);
  };

  const handleSearch = (e) => {
    if (e.target.value) {
      setsearchText(e.target.value);
      //handleFilterData(e.target.value);
    } else {
      setsearchText("");
    }
  };
const handleCloseModal = () => {
  setSearchData(null);
    setAddMoreExits("");
    handleClose();
  };
  
  const handleAddMoreExits = (msg) => {
    setAddMoreExits(msg);
  };
  
  const data = useSelector((state) => state.getaddFavDataReducer);
  const { getData: addFavData, isLoading, isError } = data;

  useEffect(() => {
    dispatch(getAddFavData());
  }, []);

  const handleAddTiles = async () => {
    try {
      let res = await services.api(
        "PUT",
        `/ui-assist/v1/favourite/addtiles/${localStorage.getItem("token")}`,
        getAddTiles
      );
      if (res) {
        Swal.fire(
          "Successfully added!",
          "Your tile(s) has been updated.",
          "success"
        );
        handleCloseModal();
        dispatch(getFavouriteData());
        // window.location.reload();
      }
    } catch (error) {
      Swal.fire("Something went wrong!", `${error.response.data}`, "error");
    }
  };

  const handleAddTiles2 = (tiles) => {
    setGetAddTiles(tiles);
  };

  return (
    <CommonModal
      open={open}
      handleClose={handleCloseModal}
      headerText={headerText}
      icon={icon}
      modalSize={modalSize}
      header={header}
      footer={footer}
      fullWidth={fullWidth}
      backgroundColor
      erroMsg={addMoreExist}
      submitBtnText="DONE"
      onButtonClick={handleAddTiles}
    >
      <div className="favTabModal">
        <Grid
          container
          direction="row"
          justifyContent="space-between"
          alignItems="center"
        >
          <Grid item xs={12} sm={7}>
            <span className="modalCommonHeadText commonFontNormalStyle">
              Select the Favourites you wish to add in{" "}
              {title === "myWork" ? "'My work'" : "'Manage team'"} Section
            </span>
          </Grid>
          <Grid item xs={12} sm={5}>
            <div>
              <SearchBarButton
                placeholder="Search for a Favourite"
                 handleSearch={handleSearch}
                 searchText={searchText}
              />
            </div>
          </Grid>
        </Grid>

        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <Tabs onChange={handleTabs} value={tab} className="alignFavModelTab">
            {title === "myWork" && <Tab label="My work" />}
            {title === "manageTeam" && <Tab label="Manage team" />}
          </Tabs>

          {/* <SortByDiv shortByText="A-Z" handleAscending={handleAscending} /> */}
        </div>
        <hr className="deviderP" />
        <FavouriteTab
          getMyWorkLength={getMyWorkLength}
          value={tab}
          index={0}
          data={addFavData ? addFavData : []}
          handleAddMoreExits={handleAddMoreExits}
          title={title}
          // handleAddTiles={handleAddTiles}
          handleAddTiles2={handleAddTiles2}
          getDataTitle={getDataTitle}
          searchTerm = {searchText}
        /> 
        <FavouriteTab
          value={tab}
          index={1}
          data={addFavData ? addFavData : ""}
          getMyWorkLength={getMyWorkLength}
          handleAddMoreExits={handleAddMoreExits}
          title={title}
          handleAddTiles2={handleAddTiles2}
          getDataTitle={getDataTitle}

          // handleAddTiles={handleAddTiles}

          // data={searchData?.length ? searchData : data.work}
        />
        {/* <FavouriteTab
          value={tab}
          index={2}
          data={searchData?.length ? searchData : data.connect}
        />
        <FavouriteTab
          value={tab}
          index={3}
          data={searchData?.length ? searchData : data.learnAndGrow}
        /> */}
      </div>
    </CommonModal>
  );
};

export default AddFavourite;
