import services from "../../Services/index";
import {
  GET_PRIORITY_DATA_LOAD,
  GET_PRIORITY_DATA_FAILED,
  GET_PRIORITY_DATA_SUCCESS,
  GET_FAVOURITE_DATA_LOAD,
  GET_FAVOURITE_DATA_SUCCESS,
  GET_FAVOURITE_DATA_FAILED,
} from "../Contants/Constants";
import axios from "axios";

const getPriorityDataLoad = () => ({
  type: GET_PRIORITY_DATA_LOAD,
});
const getPriorityDataSuccess = (data) => ({
  type: GET_PRIORITY_DATA_SUCCESS,
  payload: data,
});
const getPriorityDataFailed = (error) => ({
  type: GET_PRIORITY_DATA_FAILED,
  err: error,
});

export const getPriorityData = () => async (dispatch) => {
  dispatch(getPriorityDataLoad());
  try {
    const res = await axios(
      `${process.env.REACT_APP_API_BASE_URL}/ui-assist/v1/task/${localStorage.getItem(
        "token"
      )}`
    );
    if (res) {
      console.log(res, "response from task api");
      dispatch(getPriorityDataSuccess(res?.data?.d.results));
    }
  } catch (err) {
    dispatch(getPriorityDataFailed(err.response));
  }
};

const getFavouriteDataLoad = () => ({
  type: GET_FAVOURITE_DATA_LOAD,
});
const getFavouriteDataSuccess = (data) => ({
  type: GET_FAVOURITE_DATA_SUCCESS,
  payload: data,
});
const getFavouriteDataFailed = (error) => ({
  type: GET_FAVOURITE_DATA_FAILED,
  err: error,
});

export const getFavouriteData = () => async (dispatch) => {
  dispatch(getFavouriteDataLoad());
  try {
    const res = await services.api(
      "GET",
      `/ui-assist/v1/favourite/${localStorage.getItem("token")}`,
      {}
    );
    if (res) {
      dispatch(getFavouriteDataSuccess(res.data));
    }
  } catch (err) {
    dispatch(getFavouriteDataFailed(err.response));
  }
};
