import React, { useState, useEffect } from "react";
import "../Header/Header.css";
import {
  AppBar,
  Toolbar,
  useMediaQuery,
  Badge,
  Tooltip,
} from "@material-ui/core";
import DropDown from "../Common/DropDown/DropDown";
import HeaderMenu from "../Header/HeaderMenu/HeaderMenu";
import Notifications from "./Notifications/Notifications";

import {
  Support,
  EthicsHelpline,
  LegalSupport,
  RemoteSupportt,
  ITSupportt,
  InfrastructureSupportt,
  EMERGENCY_CONTACTS,
  SEARCH,
  DASHBOARD,
} from "../../Routes/constant";

import { useDispatch, useSelector } from "react-redux";
import { RBoolean } from "../../Redux/Actions/MicrofeedbackAction";
import { makeStyles } from "@material-ui/core/styles";

import { ConfigHeader } from "./Config";
import { useLocation, useHistory } from "react-router-dom";
import { PROFILE_PAGE } from "../../Routes/constant";
import { getProfileDetailsData } from "../../Redux/Actions/ProfileDetailsAction";
import CommonModal from "../Common/CommonModal/CommonModal";
import UniversalSearchComponent from "./UniversalSearch/UniversalSearchComponent";
import services from "../../Services";
import { browserName } from "react-device-detect";
import { NotificationStatus } from "../../Redux/Actions/NotificationStatus";

const options = [
  {
    title: "Mahindra collective",
    value: "mahindracollective",
  },
  {
    title: "Mahindra experience",
    value: "mahindraexperience",
  },
  {
    title: "People",
    value: "people",
  },
  {
    title: "Images",
    value: "image",
  },
  {
    title: "Videos",
    value: "video",
  },
  {
    title: "Document",
    value: "document",
  },
  {
    title: "Sites and sub sites",
    value: "site",
  },
  {
    title: "Tiles",
    value: "tile",
  },
  {
    title: "Content",
    value: "content",
  },
  /*{
    title: "Whats new",
    value: "whatsnew",
  },*/
  {
    title: "Org announcement",
    value: "organnouncement",
  },
  {
    title: "Policy",
    value: "policy",
  },

  {
    title: "Offers and campaign",
    value: "offerorcampaign",
  },
  {
    title: "Support services",
    value: "supportservices",
  },
  /* {
    title: "Remote services",
    value: "remoteservices",
  },*/
  {
    title: "Emergency Contact",
    value: "emergencycontact",
  },
];

const useStyles = makeStyles({
  customTooltip: {
    backgroundColor: "#fefdfd",

    fontSize: "12px",
    width: "250px",
    textAlign: "center",
    padding: "8px 8px 8px 8px",
    borderRadius: "0px",
    color: "#242424",
    fontWeight: "normal",
    position: "relative",
    top: "20px",
  },
  customArrow: {
    color: "#fefdfd",
  },
});

function Header() {
  const history = useHistory();
  const isMobile = useMediaQuery("(max-width:812px)");
  const [loading, setLoading] = useState(false);
  const [supportMenu, setSupportMenu] = useState([]);
  const [dashBoardMenu, setDashBoardMenu] = useState([]);
  const [openNotifications, setOpenNotifications] = useState(false);
  const [searchOptions, setSearchOptions] = useState([]);
  const [hidenavigationdropDown, setHidenavigationdropDown] = useState(false);
  const [helpConfigHover, setHelpConfigHover] = useState(false);
  const [profileHover, setProfileHover] = useState(false);
  // const [profileDetails, setprofileDetails] = useState({});
  const [hideTooltipRightNavigation, setHideToolTipRightNavigation] =
    useState(false);
  const [notificationSearchIconHover, setNotificationSearchIconHover] =
    useState({
      notification: false,
      search: false,
    });

  //redux variables
  // const myState = useSelector((state) => state.changeBoolean);
  const dispatch = useDispatch();

  //tooltip

  const classes = useStyles();

  //Universal search modal

  const [universalSearch, setUniversalSearch] = useState(false);
  const [searchText, setSearchText] = useState("");
  const [selectedOptions, setSelectedOptions] = useState(options);

  const universalSearchOpen = () => {
    history.push(`${DASHBOARD}`);
    setSelectedOptions(options);
    setUniversalSearch(true);
  };

  const handleCloseModal = () => {
    setSearchText("");
    // setSearchOptions([]);
    setSelectedOptions(options);
    setUniversalSearch(false);
  };

  // const [searchActive, setSearchActive] = useState(false);
  const [dashboardIconHover, setDashboardIconHover] = useState(false);
  const location = useLocation();
  const [leftMenu, setLeftMenu] = useState([]);

  const getUrlHead = location.pathname.split("/");
  const [profileDetails, setProfileDetails] = useState("");

  const handleNotificationsClose = () => {
    setOpenNotifications(false);
    dispatch(NotificationStatus({ type: "READNOTIFICATION" }));
  };

  const handleNotifications = () => {
    dispatch(NotificationStatus({ type: "READNOTIFICATION" }));
    setOpenNotifications(true);
    hitNotificationStatusApi();
  };

  const hitNotificationStatusApi = async () => {
    try {
      const res = await services.api("PUT", "/ui-assist/v1/notification/view");
      if (res.status === 200) {
        console.log("i was success");
      }
    } catch (error) {
      console.log("error", error.message);
    }
  };

  const handleNavigationDropDownHide = () => {
    if (browserName !== "Safari") setHidenavigationdropDown(true);
  };

  const handleNavigationDropDownShow = () => {
    setHidenavigationdropDown(false);
  };

  const handleMouseEnteHelpConfig = () => {
    setHelpConfigHover(true);
  };
  const handleMouseLeaveHelpConfig = () => {
    setHelpConfigHover(false);
  };

  const handleMouseEnteProfile = () => {
    setProfileHover(true);
  };
  const handleMouseLeaveProfile = () => {
    setProfileHover(false);
  };
  // const getProfile = useSelector((state) => state.getProfileDetailsData);

  //const { getData: profileDetails, isError, isLoading } = getProfile;
  console.log(profileDetails);
  const getRightHeaderMenu = async () => {
    try {
      setLoading(true);
      let res = await services.api(
        "GET",
        `/ui-assist/v1/tiles/menutiles/${localStorage.getItem("token")}`
      );
      if (res.status === 200) {
        setLoading(false);
        let getLeftMenus = res.data.filter(
          (result, index) =>
            // profileDetails && profileDetails?.groupCompanyId.includes(51)
            //   ? index <= 3
            //   : index <= 2
            result?.title !== "Help & support" &&
            result?.title !== "All portals & apps" &&
            result?.title !== "Profile"
        );
        setLeftMenu(getLeftMenus);
        let getSupport = res.data.filter((result, index) => {
          if (result && result?.title === "Help & support") {
            return (result.title = (
              <div className="activeRectangle headerDropdownIconActive">
                <img
                  src={
                    helpConfigHover || getUrlHead[1] === "helpdesk"
                      ? process.env.PUBLIC_URL +
                        "/iconImages/header/question-fill.svg"
                      : process.env.PUBLIC_URL +
                        "/iconImages/header/question.svg"
                  }
                  alt="helpdesk"
                />
              </div>
            ));
          }
        });
        let getDashBoard = res.data.filter((result, index) => {
          if (
            // profileDetails && profileDetails?.groupCompanyId.includes(51)
            //   ? index === 5
            //   : index === 4
            result &&
            result?.title === "All portals & apps"
          ) {
            return (result.title = (
              <div className="activeRectangle headerDropdownIconActive">
                <img
                  src={
                    dashboardIconHover || getUrlHead[1] === "dashboard-icon"
                      ? process.env.PUBLIC_URL +
                        "/iconImages/header/dashboard-fill.svg"
                      : process.env.PUBLIC_URL +
                        "/iconImages/header/dashboard.svg"
                  }
                  alt="dashboard-icon"
                />
              </div>
            ));
          }
        });
        setSupportMenu(getSupport);
        setDashBoardMenu(getDashBoard);
        // setConfig(getLeftMenus);
      }
    } catch (err) {
      setLoading(false);
      console.log("err from headerMenu", err);
    }
  };

  useEffect(() => {
    let userProfile = JSON.parse(
      localStorage.getItem("userProfile")
        ? localStorage.getItem("userProfile")
        : null
    );
    setProfileDetails(userProfile);
    getRightHeaderMenu();
    // dispatch(getProfileDetailsData());
  }, [dispatch]);

  // useEffect(() => {}, [getProfile]);
  const notificationsData = useSelector(
    (state) => state.getNotificationsDataReducer
  );

  const notificationsStatus = useSelector(
    (state) => state.NotificationStatusReducer
  );

  const { status } = notificationsStatus;

  const profile = [
    {
      title: (
        <img
          src={
            profileHover
              ? profileDetails.empPhotoUrl
                ? `${profileDetails.empPhotoUrl}`
                : process.env.PUBLIC_URL + "/header/profileIcon-fill.svg"
              : profileDetails.empPhotoUrl
              ? `${profileDetails.empPhotoUrl}`
              : process.env.PUBLIC_URL + "/header/profileIcon.svg"
          }
          className={
            profileDetails.empPhotoUrl ? "profileStyleHeader" : "profile"
          }
          // style={{
          //   height: "24px",
          //   width: "24px",
          //   cursor: "pointer",
          //   position: "relative",
          //   top: "6px",
          // }}
          alt="profile"
        />
      ),
      tooltip:
        "All key information about you is captured here, make sure you keep it updated!",
      subMenu: [
        // {
        //   title: "Profile",
        //   linkR: `${PROFILE_PAGE}`,
        // },
        // {
        //   title: "Profile",
        //   link: "https://hcm4preview.sapsf.com/xi/ui/peopleprofile/pages/index.xhtml?company=MMTest&loginMethod=SSO",
        // },
        {
          logout: "Logout",
        },
      ],
    },
  ];

  if (profileDetails?.isSf) {
    profile[0].subMenu = [
      {
        title: "Profile",
        //link: "https://hcm4preview.sapsf.com/xi/ui/peopleprofile/pages/index.xhtml?company=MMTest&loginMethod=SSO",
        link: "https://performancemanager4.successfactors.com/sf/liveprofile?company=Mahindra&loginMethod=SSO",
      },
      // {
      //   title: "My self generated letters",
      //   //link: "https://hcm4preview.sapsf.com/xi/ui/peopleprofile/pages/index.xhtml?company=MMTest&loginMethod=SSO",
      //   link: "https://emss.mahindra.com/sap/bc/webdynpro/sap/ZWDHR_A_CORR_LETTERS_ESS",
      // },
      {
        logout: "Logout",
      },
    ];
  } else {
    profile[0].subMenu = [
      {
        title: "Profile",
        linkR: `${PROFILE_PAGE}`,
      },
      {
        logout: "Logout",
      },
    ];
  }

  const helpConfig = [
    {
      title: (
        <div className="activeRectangle headerDropdownIconActive">
          <img
            src={
              helpConfigHover || getUrlHead[1] === "helpdesk"
                ? process.env.PUBLIC_URL +
                  "/iconImages/header/question-fill.svg"
                : process.env.PUBLIC_URL + "/iconImages/header/question.svg"
            }
            alt="helpdesk"
          />
        </div>
      ),
      tooltip: "If you need assistance, this is where you'll find help.",
      subMenu: [
        {
          title: "Emergency contacts",
          // link: "https://www.mahindrarise.com/hr_desk",
          linkR: `${EMERGENCY_CONTACTS}`,
        },
        {
          title: "Employee directory",
          // linkR: `${HELPDESK}`,
        },
        {
          title: "IT support",
          linkR: `${Support}${ITSupportt}`,
        },
        {
          title: "Legal support",
          linkR: `${Support}${LegalSupport}`,
        },
        {
          title: "Infrastructure & facilities support",
          linkR: `${Support}${InfrastructureSupportt}`,
        },
        {
          title: "Remote working support",
          linkR: `${Support}${RemoteSupportt}`,
        },
        {
          title: "Ethics helpline",
          linkR: `${Support}${EthicsHelpline}`,
        },
        {
          title: <div onClick={() => dispatch(RBoolean())}>Share Feedback</div>,
          link: "",
        },
      ],
    },
  ];
  const dashBoardConfig = [
    {
      title: (
        <div className="activeRectangle headerDropdownIconActive">
          <img
            src={
              dashboardIconHover || getUrlHead[1] === "dashboard-icon"
                ? process.env.PUBLIC_URL +
                  "/iconImages/header/dashboard-fill.svg"
                : process.env.PUBLIC_URL + "/iconImages/header/dashboard.svg"
            }
            alt="dashboard-icon"
          />
        </div>
      ),
      tooltip: "A department-wise listing of all portals and modules.",
      subMenu: [
        {
          title: "HR",
          subOptions: [
            // { linkName: "Diversity" },
            {
              linkName: "M Careers",
              link: "https://jobs.mahindracareers.com/",
            },
            {
              linkName: "RISE Awards",
              link: "https://riseaward.mahindra.com/",
            },
            {
              linkName: "MCARES",
              link: "https://samvaad.mahindra.com/MCares",
            },
            {
              linkName: "360 Degree Feedback",
              link: "https://samvaad360degreefeedback.mahindra.com",
            },
            // { linkName: "Alumni Portal" },
          ],
        },
        {
          title: "Corporate Infrastucture Services (CIS)",
          subOptions: [
            {
              linkName: "Ep portal",
              link: "https://ep.mahindra.com/irj/portal?spnego=disabled",
            },
            {
              linkName: "Group Security / Mitra",
              link: "https://www.mahindramitra.com/",
            },
            {
              linkName: "Accomodation",
              link: "https://igo.mahindra.com/accommodation/",
            },
            {
              linkName: "Projects and Technical Facilities Management",
              link: "https://emss.mahindra.com/sap/bc/webdynpro/sap/zemployee_application#",
            },
            {
              linkName: "Vehicle management system",
              link: "https://mapps.mahindra.com/ask-a-car/gco/index.html?session=gZdlmbDuwQmQLj6ApSUWC5xrajilERHn6VFG7vF2RR0=",
            },
            // { linkName: "COCP (compnany owned car portal)" },
          ],
        },
        {
          title: "Corporate Social Responsibility (CSR)",
          subOptions: [
            {
              linkName: "My Seva",
              link: "https://spiritofservice.mahindra.com/app/myseva",
            },
            {
              linkName: "Nanhi Kali",
              link: "https://emssq.mahindra.com/sap/bc/webdynpro/sap/zwda_as_payroll_giving?sap-theme=ZSAP_CORBU1#",
            },
            {
              linkName: "MGrant",
              link: "https://emssq.mahindra.com/sap/bc/webdynpro/sap/zwda_as_payroll_giving?sap-theme=ZSAP_CORBU1#",
            },
            // { linkName: "Mahindra Foundation USA" },
            // { linkName: "Mahindra Foundation UK" },
            // { linkName: "Covid releif fund for India (US site)" },
          ],
        },
        {
          title: "Innovation",
          subOptions: [
            {
              linkName: "MISCOUT",
              link: "https://innovation.mahindra.com/home",
            },
          ],
        },
        {
          title: "Gov, Risk & Comp (GRC)",
          link: "https://grc10prd.mahindra.com/sap/bc/webdynpro/sap/grac_uibb_end_user_login",
          // subOptions: [
          //   {
          //     linkName: "Ethics & governance portal",
          //     link: "https://innovation.mahindra.com/home",
          //   },
          // ],
        },
        {
          title: "Board",
          subOptions: [
            {
              linkName: "Board evaluation portal",
              link: "https://boardevaluation.mahindra.com/",
            },
            {
              linkName: "Board room portal",
              link: "https://agm.mahindra.com/BoardRoom/",
            },
            {
              linkName: "CEO/CFO portal",
              link: "https://ceocfocertificate.mahindra.com/CeoCfoPortal/",
            },
            {
              linkName: "Director Information portal",
              link: "https://dip.mahindra.com/",
            },
          ],
        },
        {
          title: "Legal",
          subOptions: [
            {
              linkName: "MLexOasis",
              link: "http://mlexoasis.com/legalportal/",
            },
            {
              linkName: "Power of Attorney",
              link: "https://group.mahindra.com/sites/poa",
            },
          ],
        },
        {
          title: "Tool",
          subOptions: [
            {
              linkName: "MDRISHTI",
              link: "https://qliksenseprod1.mahindra.com/hub/",
            },
            // { linkName: "AFS dashboards" },
          ],
        },
      ],
    },
  ];

  const handleNotificationSearchMouseEnter = (name) => {
    if (name === "notification") {
      setNotificationSearchIconHover({ notification: true });
    } else {
      setNotificationSearchIconHover({ search: true });
    }
  };

  const handleNotificationSearchMouseLeave = (name) => {
    if (name === "notification") {
      setNotificationSearchIconHover({ notification: false });
    } else {
      setNotificationSearchIconHover({ search: false });
    }
  };

  const handleMouseEnterDashBoard = () => {
    setDashboardIconHover(true);
  };
  const handleMouseLeaveDashBoard = () => {
    setDashboardIconHover(false);
  };
  // const handlesearchActive = () => {
  //   setSearchActive(true);
  // };
  // const handleSearchDisActive = () => {
  //   setSearchActive(false);
  //   console.log("coming in blur");
  // };

  const { notification, search } = notificationSearchIconHover;

  // const handleForRightNavigation = (value) => {
  //   setHideToolTipRightNavigation(value);
  // };
  // const hideTooltipRightNavigationFalse = () => {
  //   setHideToolTipRightNavigation(false);
  // };

  // const handleSelectChange = (e, newValue) => {
  //   setSearchOptions(newValue);
  // };
  const handleSearchIcon = async () => {
    let data = {};
    let getGlobal = false;
    selectedOptions.forEach((result) => {
      if (result.value === "all") {
        return (getGlobal = true);
      } else {
        return (data[result.value] = true);
      }
    });
    if (getGlobal) {
      try {
        let res = await services.api(
          "GET",
          `/ui-assist/v1/_all/_search?q=${searchText}&page=1&employeeId=${localStorage.getItem(
            "token"
          )}`
        );
        if (res.status === 200) {
          history.push({
            pathname: `${SEARCH}`,
            state: {
              searchText,
              selectedOptions,
              searchData: res.data,
            },
          });
          handleCloseModal();
        }
      } catch (err) {
        console.log("err from search:", search);
      }
    } else {
      try {
        let res = await services.api(
          "POST",
          `/ui-assist/v1/_search?q=${searchText}&page=1&employeeId=${localStorage.getItem(
            "token"
          )}`,
          data
        );
        if (res.status === 200) {
          history.push({
            pathname: `${SEARCH}`,
            state: {
              searchText,
              selectedOptions,
              searchData: res.data,
            },
          });
          handleCloseModal();
        }
      } catch (err) {
        console.log("err from search:", search);
      }
    }
  };
  const handleCloseSearch = () => {
    setSearchText("");
    // setSearchOptions([]);
    setSelectedOptions(options);

    setUniversalSearch(false);
  };
  const handleSearch = (e) => {
    setSearchText(e.target.value);
  };

  // const [selectedOptions, setSelectedOptions] = useState(options);
  const getOptionLabel = (option) => `${option.title}`;
  const getOptionDisabled = (option) => option.value === "foo";
  const handleToggleOption = (selectedOptions) =>
    setSelectedOptions(selectedOptions);
  const handleClearOptions = () => setSelectedOptions([]);
  const handleSelectAll = (isSelected) => {
    if (isSelected) {
      setSelectedOptions(options);
    } else {
      handleClearOptions();
    }
  };

  return (
    <div>
      <AppBar className="appbar">
        <Toolbar className="toolbar">
          <HeaderMenu
            isMobile={isMobile}
            leftMenu={leftMenu}
            position="right"
          />
          <img
            src={process.env.PUBLIC_URL + "/logo/header-strip2.png"}
            className="strip-width"
            alt="header strip"
          />
          <div className="nav-icons">
            <Tooltip
              title={
                hideTooltipRightNavigation
                  ? ""
                  : "A faster way for you to find people, news, org. announcements and other content easily."
              }
              arrow
              classes={{
                tooltip: classes.customTooltip,
                arrow: classes.customArrow,
              }}
            >
              <div
                className="activeRectangle"
                onMouseEnter={() => handleNotificationSearchMouseEnter()}
                onMouseLeave={() => handleNotificationSearchMouseLeave()}
                onClick={universalSearchOpen}
              >
                <img
                  src={
                    search
                      ? process.env.PUBLIC_URL +
                        "/iconImages/header/search-fill.svg"
                      : process.env.PUBLIC_URL + "/iconImages/header/search.svg"
                  }
                  className=""
                  alt="header strip"

                  // onClick={handlesearchActive}
                />
              </div>
            </Tooltip>
            {!isMobile && (
              <DropDown
                config={supportMenu ? supportMenu : []}
                handleMouseEnter={handleMouseEnteHelpConfig}
                handleMouseLeave={handleMouseLeaveHelpConfig}
                hidenavigationdropDown={hidenavigationdropDown}
                handleNavigationDropDownHide={handleNavigationDropDownHide}
                handleNavigationDropDownShow={handleNavigationDropDownShow}
                position="right"
                // handleForRightNavigation={handleForRightNavigation}
                // hideTooltipRightNavigation={hideTooltipRightNavigation}
                // hideTooltipRightNavigationFalse={
                //   hideTooltipRightNavigationFalse
                // }
              />
            )}
            <Badge
              // badgeContent={<div className="notificationBadge"></div>}
              onClick={handleNotifications}
              onMouseEnter={() =>
                handleNotificationSearchMouseEnter("notification")
              }
              onMouseLeave={() =>
                handleNotificationSearchMouseLeave("notification")
              }
            >
              <Tooltip
                title={
                  hideTooltipRightNavigation
                    ? ""
                    : "A personalised, curated section that highlights all critical items that need your attention."
                }
                arrow
                classes={{
                  tooltip: classes.customTooltip,
                  arrow: classes.customArrow,
                }}
              >
                <div
                  className={`${
                    openNotifications ? "showRectangle" : "activeRectangle"
                  }`}
                >
                  {status ? (
                    <>
                      <img
                        src={
                          notification || openNotifications
                            ? process.env.PUBLIC_URL +
                              "/iconImages/header/notification-fill.svg"
                            : process.env.PUBLIC_URL +
                              "/iconImages/header/notification.svg"
                        }
                        className="equalImageSize"
                        alt="header strip"
                        style={{ position: "absolute" }}
                      />
                      <div
                        style={{
                          position: "relative",
                          backgroundColor: "#ffcf01",
                          border: "solid 2px black",
                          borderRadius: "50%",
                          height: "10px",
                          width: "10px",
                          marginLeft: "10px",
                          marginBottom: "16px",
                        }}
                      ></div>
                    </>
                  ) : (
                    <>
                      <img
                        src={
                          notification || openNotifications
                            ? process.env.PUBLIC_URL +
                              "/iconImages/header/notification-fill.svg"
                            : process.env.PUBLIC_URL +
                              "/iconImages/header/notification.svg"
                        }
                        className="equalImageSize"
                        alt="header strip"
                      />
                    </>
                  )}
                </div>
              </Tooltip>
            </Badge>
            {!isMobile && (
              <>
                <DropDown
                  config={dashBoardMenu ? dashBoardMenu : []}
                  position="left"
                  hidenavigationdropDown={hidenavigationdropDown}
                  handleNavigationDropDownHide={handleNavigationDropDownHide}
                  handleNavigationDropDownShow={handleNavigationDropDownShow}
                  handleMouseEnter={handleMouseEnterDashBoard}
                  handleMouseLeave={handleMouseLeaveDashBoard}
                  // handleForRightNavigation={handleForRightNavigation}
                  // hideTooltipRightNavigation={hideTooltipRightNavigation}
                  // hideTooltipRightNavigationFalse={
                  //   hideTooltipRightNavigationFalse
                  // }
                />
                <DropDown
                  config={profile}
                  position="left"
                  hidenavigationdropDown={hidenavigationdropDown}
                  handleNavigationDropDownHide={handleNavigationDropDownHide}
                  handleNavigationDropDownShow={handleNavigationDropDownShow}
                  handleMouseEnter={handleMouseEnteProfile}
                  handleMouseLeave={handleMouseLeaveProfile}
                  // handleForRightNavigation={handleForRightNavigation}
                  // hideTooltipRightNavigation={hideTooltipRightNavigation}
                  // hideTooltipRightNavigationFalse={
                  //   hideTooltipRightNavigationFalse
                  // }
                />
              </>
            )}
          </div>
        </Toolbar>
        <Notifications
          openNotifications={openNotifications}
          handleNotificationsClose={handleNotificationsClose}
          isMobile={isMobile}
        />
      </AppBar>
      <div>
        <div className="dashboard-grey-clip"></div>
      </div>
      <div className="positionTop">
        <CommonModal
          open={universalSearch}
          handleClose={handleCloseModal}
          modalSize="md"
          fullWidth
          isUniversalSearchmodal={true}
          className="hideModalHeader"
          disableBackdropClick={true}
          // headerText="Coming Soon....."
        >
          <UniversalSearchComponent
            // handleSelectChange={handleSelectChange}
            // searchOptions={searchOptions}
            // handleSearchIcon={handleSearchIcon}
            // handleCloseSearch={handleCloseSearch}
            // handleSearch={handleSearch}
            // searchText={searchText}
            handleCloseSearch={handleCloseSearch}
            handleSearchIcon={handleSearchIcon}
            handleSearch={handleSearch}
            searchText={searchText}
            setSearchText={setSearchText}
            options={options}
            selectedOptions={selectedOptions}
            getOptionLabel={getOptionLabel}
            getOptionDisabled={getOptionDisabled}
            handleToggleOption={handleToggleOption}
            handleClearOptions={handleClearOptions}
            handleSelectAll={handleSelectAll}
          />
        </CommonModal>
      </div>
    </div>
  );
}

export default Header;
