import {
    Button,
    Grid,
    Paper,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
} from "@material-ui/core";
import React, { useState, useEffect } from "react";
import SearchBarButton from "../../../Common/SearchBar/SearchBarButton";
import Template from "../../../Common/Template/Template";
import { useDispatch, useSelector } from "react-redux";
import { getPolicyTableDataByID } from "../../../../Redux/Actions/PolicyCategoryAction";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import useTable from "../useTable/useTable";
import AddPoliciesModalComponent from "../AddPolicies/AddPoliciesModalComponent";
import CommonModal from "../../../Common/CommonModal/CommonModal";
import editIcon from "../../../../Assets/Images/edit-line.svg";
import services from "../../../../Services";
import SubmitButton from "../../../Common/Button/SubmitButton";
import UpdatePolicies from "../UpdatePolicies/UpdatePolicies";
import Swal from "sweetalert2";
import "../../Table/Table.css";
import edit from "../../../../Assets/Images/edit-line-red.svg";
import remove from "../../../../Assets/Images/delete.svg";

function EditPolicys() {
    const [openAddPoliciesModal, setOpenAddPoliciesModal] = useState(false);
    const [openEditPoliciesModal, setOpenEditPoliciesModal] = useState(false);

    const [submitData, setsubmitData] = useState({});
    const [refresh, setrefresh] = useState(false);

    //const [open, setopen] = useState(false)
    const dispatch = useDispatch();
    let subCatID = window.location.pathname.split("/")[4];
   

    useEffect(() => {
        dispatch(getPolicyTableDataByID(subCatID));
    }, [refresh]);
  

    const subCategoryPoliciesData = useSelector((state) => state.getPolicyTableDataReducer);
    const { getData: policies } = subCategoryPoliciesData;
    
    const headCells = [
        { id: "srNo", label: "Sr No." },
        { id: "policyName", label: "Policy Name" },
        { id: "policyDescription", label: "Description" },

        { id: "action", label: "Actions" },
    ];

    const handleCloseModal = () => {
        //setOpenModal(false);
        setOpenAddPoliciesModal(false);
    };
    const onAddPoliciesPolicies = () => {
        setOpenAddPoliciesModal(true);
    };
    const { TblContainer, TblHead, TblPagination } = useTable(policies, headCells);
    const handleDownload = (e, item) => {
        console.log(item);
    };

    const handleEditApi = async (data) => {
        try {
            const res = await services.api("PUT", `/ui-assist/v1/policy`, data);
            if (res) {
                setrefresh(!refresh);
            }
        } catch (err) {
            if (err.response) {
                console.log("erro", err.response);
            }
        }
    };
    const [currentData, setcurrentData] = useState({});
    const handleEdit = (item) => {
        setOpenEditPoliciesModal(true);
        setcurrentData(item);
       
    };

    const handleDelete = (item) => {
      
        let data = { policyId: item.policyId };
        handleDeleteApi(data);
    };

    const handleAddClick = () => {
        
    };

    //    const handleCloseModal = () => {
    //        setopen(false)
    //    }

    //   // const headerText = 'ADD POLICIES'
    //      const handleButtonClick = () => {console.log('handle click')}

    const [sample, setsample] = useState("");

    const handlePolicySubmitApi = async (data) => {
        try {
            const res = await services.api("POST", `/ui-assist/v1/policy/${subCatID}`, data);
            if (res) {
                alert("data added succesfully");

                // setOrgName("");
                // setOrgKey("");
                // setrefresh(!refresh);
                // setAccordianState(!accordianState);
            }
        } catch (err) {
            if (err.response) {
                console.log("erro", err.response);
            }
        }
    };

    const handleDeleteApi = (data) => {
        Swal.fire({
            title: "Are you sure?",
            text: "You won't be able to revert this!",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "Yes, delete it!",
        }).then((result) => {
            if (result.isConfirmed) {
                services
                    .api("DELETE", `/ui-assist/v1/policy/${data.policyId}`)
                    .then((res) => {
                        Swal.fire("Deleted!", "Policy has been deleted.", "success");
                        setrefresh(!refresh);
                    })
                    .catch((err) => {
                        console.log("error", err);
                    });
            }
        });
    };
    const handleFormSubmit = (cb) => {
       

        let result = cb();
        
        const formData = new FormData();
        formData.append("scope", "Gloable");
        formData.append("policyName", "Gloable");
        formData.append("scope", "Gloable");
        formData.append("scope", "Gloable");
        formData.append("scope", "Gloable");
        formData.append("scope", "Gloable");
        formData.append("scope", "Gloable");
    };
    const onBackButtonHandler = (data) => {
        
    };

    const callbackFunction = (childData, up) => {
     
        // setuPolicy(!uPolicy)
    };
    

    return (
        <>
            <Template>
                <Grid container direction="row" justifyContent="space-between" alignItems="center">
                    <Grid item xs={12} sm={7}>
                        <p className="offCHeaderTitle">Add Policies</p>
                        <SubmitButton
                            onClick={(e) => {
                                onAddPoliciesPolicies();
                            }}
                            className="offCHeaderTitle addPolicy"
                            label="ADD"
                        >
                            {/* <img
                                src={process.env.PUBLIC_URL + "/policies/add-fill.svg"}
                                alt="Download line"
                                
                            /> */}
                        </SubmitButton>
                    </Grid>
                    <Grid item xs={12} sm={5}>
                        <div className="policiesSearchWidth">
                            <SearchBarButton placeholder="hi mayu" />
                        </div>
                    </Grid>
                </Grid>

                <Paper className="MClandingpageAlign tableGutter policyTableCss">
                    <Grid
                        container
                        direction="row"
                        justifyContent="space-evenly"
                        alignItems="flex-start"
                        spacing={2}
                        className="MCContainer"
                    >
                        <Grid item sm={12}>
                            <div className="MCalignCardsItemstable1 padding16">
                                <TblContainer>
                                    <TblHead />
                                    <TableBody>
                                        {policies &&
                                            policies.map((item) => (
                                                <TableRow key={item.policyId}>
                                                    <TableCell>{item.srNo}</TableCell>
                                                    <TableCell>{item.policyName}</TableCell>
                                                    <TableCell>{item.description}</TableCell>

                                                    <TableCell>
                                                        <div style={{ display: "flex" }}>
                                                            <div
                                                                onClick={(e) => {
                                                                    handleEdit(item);
                                                                }}
                                                            >
                                                                <img src={edit} alt="" />
                                                            </div>{" "}
                                                            <div
                                                                onClick={(e) => {
                                                                    handleDelete(item);
                                                                }}
                                                                style={{ paddingLeft: "6px" }}
                                                            >
                                                                <img src={remove} alt="" />
                                                            </div>
                                                        </div>
                                                    </TableCell>
                                                </TableRow>
                                            ))}
                                    </TableBody>
                                </TblContainer>
                                <TblPagination />
                            </div>
                        </Grid>
                    </Grid>
                </Paper>

                <AddPoliciesModalComponent
                    parentCallback={callbackFunction}
                    x={openAddPoliciesModal}
                    y={setOpenAddPoliciesModal}
                />
                <UpdatePolicies
                    open={openEditPoliciesModal}
                    close={setOpenEditPoliciesModal}
                    data={currentData}
                />
            </Template>
        </>
    );
}

export default EditPolicys;
