import Swal from "sweetalert2";
import services from "../../Services/index";
import {
  GET_USER_BENEFITS_SUCCESS,
  GET_USER_BENEFITS_FAILED,
  GET_USER_BENEFITS_LOAD,
  GET_GRADE_SUCCESS,
  GET_GRADE_FAILED,
  GET_GRADE_LOAD,
  GET_BENEFITS_BY_GRADE_SUCCESS,
  GET_BENEFITS_BY_GRADE_FAILED,
  GET_BENEFITS_BY_GRADE_LOAD,
  ADD_BENEFITS_SUCCESS,
  ADD_BENEFITS_FAILED,
  ADD_BENEFITS_LOAD,
  EDIT_BENEFITS_SUCCESS,
  EDIT_BENEFITS_FAILED,
  EDIT_BENEFITS_LOAD
} from "../Contants/Constants";


//GET USER ENTITLEMENT BENEFITS

const getUserBenefitsLoad = () => ({
    type: GET_USER_BENEFITS_LOAD,
});
const getUserBenefitsSuccess = (data) => ({
    type: GET_USER_BENEFITS_SUCCESS,
    payload: data,
});
const getUserBenefitsFailed = (error) => ({
    type: GET_USER_BENEFITS_FAILED,
    err: error,
});

export const getUserBenefitsData = () => async (dispatch) => {
  dispatch(getUserBenefitsLoad());
  try {
    const res = await services.api(
      "GET",
      `/ui-assist/v1/entitlement/entitlementdetails`
    );
    if (res) {
      dispatch(getUserBenefitsSuccess(res.data));
    }
  } catch (err) {
    dispatch(getUserBenefitsFailed(err.response));
    Swal.fire("Something went wrong!", `${err?.response?.data}`, "error");
  }
};


//GET ALL GRADES

const getGradeLoad = () => ({
    type: GET_GRADE_LOAD,
});
const getGradeSuccess = (data) => ({
    type: GET_GRADE_SUCCESS,
    payload: data,
});
const getGradeFailed = (error) => ({
    type: GET_GRADE_FAILED,
    err: error,
});

export const getGradeData = () => async (dispatch) => {
  dispatch(getGradeLoad());
  try {
    const res = await services.api("GET", `/ui-assist/v1/entitlement/grade`);
    if (res) {
      dispatch(getGradeSuccess(res.data));
    }
  } catch (err) {
    dispatch(getGradeFailed(err.response));
    Swal.fire("Something went wrong!", `${err?.response?.data}`, "error");
  }
};


//GET BENEFITS BY GRADE

const getBenefitByGradeLoad = () => ({
    type: GET_BENEFITS_BY_GRADE_LOAD,
});
const getBenefitByGradeSuccess = (data) => ({
    type: GET_BENEFITS_BY_GRADE_SUCCESS,
    payload: data,
});
const getBenefitByGradeFailed = (error) => ({
    type: GET_BENEFITS_BY_GRADE_FAILED,
    err: error,
});

export const getBenefitByGradeData = (data) => async (dispatch) => {
  dispatch(getBenefitByGradeLoad());
  try {
    const res = await services.api(
      "GET",
      `/ui-assist/v1/entitlement/entitlementdetail/grade/${data}`
    );
    if (res) {
      dispatch(getBenefitByGradeSuccess(res.data));
    }
  } catch (err) {
    dispatch(getBenefitByGradeFailed(err.response));
    Swal.fire("Something went wrong!", `${err?.response?.data}`, "error");
  }
};


//ADD BENEFITS

const addBenefitLoad = () => ({
    type: ADD_BENEFITS_LOAD,
});
const addBenefitSuccess = (data) => ({
    type: ADD_BENEFITS_SUCCESS,
    payload: data,
});
const addBenefitFailed = (error) => ({
    type: ADD_BENEFITS_FAILED,
    err: error,
});

export const addBenefitData = (data) => async (dispatch) => {
  dispatch(addBenefitLoad());
  try {
    const res = await services.api(
      "POST",
      `/ui-assist/v1/entitlement/entitlementdetail`, data
    );
    if (res) {
      dispatch(addBenefitSuccess(res.data));
    }
  } catch (err) {
    dispatch(addBenefitFailed(err.response));
    Swal.fire("Something went wrong!", `${err?.response?.data}`, "error");
  }
};


//EDIT BENEFITS

const editBenefitLoad = () => ({
    type: EDIT_BENEFITS_LOAD,
});
const editBenefitSuccess = (data) => ({
  type: EDIT_BENEFITS_SUCCESS,
  payload: data
});
const editBenefitFailed = (error) => ({
  type: EDIT_BENEFITS_FAILED,
  err: error
});

export const editBenefitData = (data,code) => async (dispatch) => {
  dispatch(editBenefitLoad());
  try {
    const res = await services.api(
      "PUT",
      `/ui-assist/v1/entitlement/entitlementdetail/${code}`,
      data
    );
    if (res) {
      dispatch(editBenefitSuccess(res.data));
    }
  } catch (err) {
    dispatch(editBenefitFailed(err.response));
    Swal.fire("Something went wrong!", `${err?.response?.data}`, "error");
  }
};