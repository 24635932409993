import React, { useState, useEffect } from "react";
import "../../MahindraCollective/UploadStory/UploadStoryComponent.css";
import {
  Paper,
  Grid,
  Radio,
  FormControlLabel,
  RadioGroup,
  Button,
} from "@material-ui/core";
import { useFormik } from "formik";
import Template from "./../../../Common/Template/Template";
import editIconRed from "../../../../Assets/Images/edit-line-red.svg";
import CharLeftField from "../../../Common/CharLeftField/CharLeftField";
import DatePicker from "../../../Common/DatePicker/DatePicker";
import CommonLabel from "../../../Common/ComonLabel/CommonLabel";
import moment from "moment";
import services from "../../../../Services";
import CommonAutocomplete from "../../../Common/CommonAutocomplete/CommonAutocomplete";
import SubmitButton from "../../../Common/Button/SubmitButton";
import CommonModal from "../../../Common/CommonModal/CommonModal";
import offerCompaignApprovalIcon from "../../../../Assets/Images/offer-compaign-approval.svg";
import QuillEditor from "../../../Common/QuillEditor/QuillEditor";
import uploadIcon from "../../../../Assets/Images/collectiveIcons/upload-2-line.svg";
import previewArticle from "../../../../Assets/Images/search-eye-line.svg";
import {
  uploadStorySchema,
  editStorySchema,
} from "../../../../Assets/formikErrors/SubmitOfferCampaignSchema";
import Swal from "sweetalert2";
import { LIVEWIRE, MAHINDRA_EXPERIENCE } from "../../../../Routes/constant";
import { useHistory, useLocation, useParams } from "react-router-dom";
import Loader from "../../../Common/Loader/Loader";
import rejectArticle from "../../../../Assets/Images/file-forbid-line.svg";
import CustomMultiSelect from "../../../Common/CustomMultiSelect/CustomMultiSelect";
// import PreviewArticleComponent from "../../MahindraCollective/Admin/PreviewArticleModel/PreviewArticleComponent";
import ExperiencePreview from "../ExperiencePreview";
import RejectArticleComponent from "../../MahindraCollective/Admin/RejectArticleModel/RejectArticleComponent";
import PublishArticleComponent from "../Admin/PublishArticleModel/PublishArticleComponent";

//Added for Filters
import { useDispatch, useSelector } from "react-redux";
import {
  getSectors,
  getBusinessUnits,
} from "../../../../Redux/Actions/MastersAction";
import { emptyArticle, getArticle } from "../../../../Redux/Actions/ExperienceAction";

export default function ExperienceUpload() {
  const [loading, setLoading] = useState(false);
  const location = useLocation();
  const dispatch = useDispatch();
  let getUploadedData = location?.state?.details || {};
  const scheduleDataFlag = location?.state?.scheduleDataFlag || false;
  const [submitForPreview, setSubmitForPreview] = React.useState(false);

  const getEditValue =
    getUploadedData &&
    Object.keys(getUploadedData).length === 0 &&
    getUploadedData?.constructor === Object
      ? false
      : true;

  const history = useHistory();

  const typeOption = [
    { title: "Digitally Empowered", value: "digitally empowered" },
    { title: "Being Diverse", value: "being diverse" },
    { title: "Being Capable", value: "being capable" },
    { title: "Being Healthy", value: "being healthy" },
    { title: "Being Mahindra", value: "being mahindra" },
    { title: "Others", value: "Others" },
  ];

  const options = [
    {
      title: "Image",
      value: "Image",
    },
    {
      title: "Youtube URL",
      value: "Youtube",
    },
    // {
    //   title: "Video",
    //   value: "Video",
    // },
  ];

  const style = {
    width: 300,
    marginBottom: "1.5em",
  };

  const [titleCharLeft, setTitleCharLeft] = React.useState(100);
  const [getTitle, getSetTitle] = useState("");

  const [subtitleCharLeft, setSubtitleCharLeft] = React.useState(100);
  const [textAreaCharLeft, setTextAreaCharLeft] = React.useState(3500);
  const [hastagsLeft, setHastagsLeft] = React.useState(3);
  // const [getSectorList, setGetSectorList] = useState([]);
  // const [getDivisionList, setGetDivisionList] = useState([]);
  const [getExsitingData, setExsitingData] = useState("");
  const [edit, setEdit] = useState(getEditValue);
  const [submitForReject, setSubmitForReject] = React.useState(false);
  const [rejectionNote, setRejectionNote] = useState("");
  const [submitForPublish, setSubmitForPublish] = React.useState(false);
  const [getPublishErrorByAdmin, setGetPublishErrorByAdmin] = useState({});
  const [submitForApproval, setSubmitForApproval] = React.useState(false);
  const [getPublishDataByAdmin, setGetPublishDataByAdmin] = useState({});
  const [editApi, setEditApi] = useState(false);
  const [storyStatus, setStoryStatus] = useState(
    getUploadedData ? getUploadedData?.status : ""
  );

  //MARK: - SECTOR Business unit code----------------------------------
  // const [selectedSectors, setSelectedSectors] = useState([]);
  // const [selectedBusinessUnits, setSelectedBusinessUnits] = useState([]);
  //UseSelectors
  // const allSectorsData = useSelector((state) => {
  //   return state?.getSectorsReducer?.getData;
  // });
  // const allBusinessUnitsDetails = useSelector(
  //   (state) => state?.getBusinessUnitsReducer?.getData
  // );

  const handleCloseComfirmationSubmit = () => {
    setSubmitForApproval(false);
    setSubmitForPreview(false);
    setSubmitForReject(false);
    setSubmitForPublish(false);

    handleEdit();
    if (!getEditValue) {
      history.push(`${LIVEWIRE}${MAHINDRA_EXPERIENCE}`);
    }
  };

  // useEffect(() => {
  //   async function fetchData() {
  //     if (!allSectorsData || allSectorsData.length == 0) {
  //       dispatch(getSectors());
  //     }
  //     if (!allBusinessUnitsDetails || allBusinessUnitsDetails.length == 0) {
  //       dispatch(getBusinessUnits([]));
  //     }
  //   }
  //   fetchData();
  // }, []);

  let { id: articleId } = useParams();
  let isEdit = articleId;

  useEffect(() => {
    if (articleId && articleId !== ":id") {
      dispatch(getArticle("mahindra-experience", articleId));
    } else {
      dispatch(emptyArticle());
    }
  }, [articleId]);

  let articleData = useSelector((state) => state.getExperienceArticleDataReducer);
  getUploadedData = articleData?.getData;

  console.log("article data ====> ", articleData);

  const handleCloseModal = () => {
    setSubmitForApproval(false);
    setSubmitForPreview(false);
    setSubmitForReject(false);
    setSubmitForPublish(false);
  };

  const handleChange = (e) => {
    formik.handleChange(e);
    const { value, name } = e.target;

    if (name === "title") {
      getSetTitle(value);
      if (value.length <= 100) setTitleCharLeft(100 - value.length);
    } else if (name === "subTitle") {
      if (value.length <= 100) setSubtitleCharLeft(100 - value.length);
    }
  };

  useEffect(async () => {
    let userProfile = JSON.parse(
      localStorage.getItem("userProfile")
        ? localStorage.getItem("userProfile")
        : null
    );
    setExsitingData(userProfile);

    async function fetchData() {
      dispatch(getSectors());
    }
    fetchData();
  }, []);

  // useEffect(() => {
  //   if (allSectorsData) {
  //     if (getEditValue !== true) {
  //       setSelectedSectors(allSectorsData);
  //       // formik.setFieldValue("selectedSectors", allSectorsData);
  //     } else {
  //       let sectorsForExistRecord = getTitles(
  //         getUploadedData?.sector,
  //         allSectorsData
  //       );
  //       setSelectedSectors(sectorsForExistRecord);
  //       // formik.setFieldValue("selectedSectors", sectorsForExistRecord);
  //     }
  //   }
  // }, [allSectorsData]);

  // useEffect(() => {
  //   if (allBusinessUnitsDetails) {
  //     if (getEditValue !== true) {
  //       setSelectedBusinessUnits(allBusinessUnitsDetails);
  //       // formik.setFieldValue("selectedBusinessUnits", allBusinessUnitsDetails);
  //     } else {
  //       let buForExistRecord = getTitles(
  //         getUploadedData?.businessUnit,
  //         allBusinessUnitsDetails
  //       );
  //       setSelectedBusinessUnits(buForExistRecord);
  //       // formik.setFieldValue("selectedBusinessUnits", buForExistRecord);
  //     }
  //   }
  // }, [allBusinessUnitsDetails]);

  // const getDivsionBySector = async (sectorCode) => {
  //   const getSectors = sectorCode.map((result) => {
  //     return {
  //       addsectorId: result.sectorCode,
  //     };
  //   });

  //   try {
  //     setLoading(true);
  //     let res = await services.api(
  //       "POST",
  //       `/ui-assist/v1/businessunit`,
  //       getSectors
  //     );
  //     if (res.status === 200) {
  //       setLoading(false);
  //       let list = res.data.map((result) => {
  //         return {
  //           title: result.buName,
  //           value: result.buName,
  //           id: result.id,
  //         };
  //       });
  //       // setAllBusinessUnitsData(list);
  //     }
  //   } catch (err) {
  //     setLoading(false);
  //     Swal.fire("Oops!", `${err.response.data}`, "error");
  //     // setAllBusinessUnitsData([]);
  //   }
  // };

  const handleSubmit = async (values, resetForm) => {
    let formData = new FormData();
    const {
      title,
      subTitle,
      name,
      email,
      phoneNumber,
      publishDate,
      radioValues,
      thumbnail,
      sector,
      division,
      selectType,
      selectFileType,
      hasTags,
      editorValue,
      videoUri,
      docs,
    } = values;

    // let getAllIdBu = [
    //   ...new Set(selectedBusinessUnits.map((item) => item.value)),
    // ].toString();
    // let getAllIdSector = [
    //   ...new Set(selectedSectors.map((item) => item.value)),
    // ].toString();

    // formData.append("sector", getAllIdSector);
    // formData.append("businessUnit", getAllIdBu);
    formData.append("tags", hasTags);
    formData.append("description", editorValue);
    formData.append("categoryType", selectType.value);
    formData.append("mediaType", selectFileType.value);
    formData.append("title", title);
    formData.append("videoUri", selectFileType.value == "Image" ? "" : videoUri);
    // formData.append("isVideo", videoUri ? true : false);
    // if (thumbnail || videoUri) {
      formData.append("thumbnail", selectFileType.value == "Image" ? thumbnail : "");
    // }

    formData.append("subTitle", subTitle);
    formData.append(
      "publishDate",
      typeof publishDate === "string"
        ? moment(publishDate).format("YYYY-MM-DD")
        : moment(publishDate.toDate()).format("YYYY-MM-DD")
    );
    formData.append("author", localStorage.getItem("token"));
    formData.append(
      "name",
      radioValues === "author"
        ? getExsitingData?.firstname
          ? `${getExsitingData.firstname} ${getExsitingData.lastname}`
          : ""
        : getExsitingData?.firstname
          ? `${getExsitingData.firstname} ${getExsitingData.lastname}`
          : ""
    );
    formData.append(
      "submittedFor",
      radioValues === "author"
        ? ""
        : name
    );
    formData.append(
      "email",
      radioValues === "author"
        ? getExsitingData.emailid
          ? getExsitingData.emailid
          : ""
        : email
    );
    formData.append("phoneNumber", phoneNumber);
    formData.append("isAuthorPost", radioValues === "author" ? true : false);
    formData.append("media", docs);

    getSetTitle(title);

    try {
      setLoading(true);
      let data = undefined;
      let size = 0;
      if (docs && !getUploadedData?.media) {
        size = docs?.size;
      }
      if (size < 5243000) {
        data = await services.api(
          `${editApi ? "PATCH" : "POST"}`,
          `/ui-assist/v1/mahindra-experience/${
            editApi ? getUploadedData?.id : ""
          }`,
          // `/ui-assist/v1/mahindra-experience`,
          formData
        );
      } else {
        Swal.fire("PDF size should be less than 5MB", " ", "error");
      }
      if (data.status === 201) {
        setLoading(false);
        // resetForm();
        setSubmitForApproval(true);
      } else if (data.status === 200) {
        setLoading(false);
        // resetForm();

        // if (storyStatus !== "published") {
        setSubmitForApproval(true);
        // }
      }
    } catch (error) {
      setLoading(false);
      Swal.fire("Something went wrong!", `${error.response.data}`, "error");
    }
  };

  const getStoryType = (storyType) => {
    if (storyType === "digitally empowered") {
      return typeOption[0];
    } else if (storyType === "being diverse") {
      return typeOption[1];
    } else if (storyType === "being capable") {
      return typeOption[2];
    } else if (storyType === "being healthy") {
      return typeOption[3];
    } else if (storyType === "being mahindra") {
      return typeOption[4];
    } else if (storyType === "others") {
      return typeOption[5];
    }
  };

  // const getBu = (selBu) => {
  //   if (!selBu || !allBusinessUnitsDetails) return;
  //   var props = ["sectorCode", "title", "value"];

  //   var result = allBusinessUnitsDetails
  //     .filter(function (o1) {
  //       // filter out (!) items in result2
  //       return selBu?.some(function (o2) {
  //         return o1.id === o2; // assumes unique id
  //       });
  //     })
  //     .map(function (o) {
  //       return props.reduce(function (newo, name) {
  //         newo[name] = o[name];
  //         return newo;
  //       }, {});
  //     });

  //   return result;
  // };

  // const getSelectedBU = (selectedBu) => {
  //   if (!selectedBu || !allBusinessUnitsDetails) return;

  //   var props = ["id", "sector", "buName"];

  //   var result = allBusinessUnitsDetails
  //     .filter(function (o1) {
  //       // filter out (!) items in result2
  //       return selectedBu?.some(function (o2) {
  //         return o1.id === o2; // assumes unique id
  //       });
  //     })
  //     .map(function (o) {
  //       return props.reduce(function (newo, name) {
  //         newo[name] = o[name];
  //         return newo;
  //       }, {});
  //     });

  //   return result;
  // };

  // const getFilteredSectors = (selSectors) => {
  //   if (!selSectors || !allSectorsData) return;

  //   var props = ["sectorCode", "title", "value"];

  //   var result = allSectorsData
  //     .filter(function (o1) {
  //       return selSectors.some(function (o2) {
  //         return o1.value === o2; //assumes unique id
  //       });
  //     })
  //     .map(function (o) {
  //       return props.reduce(function (newo, name) {
  //         newo[name] = o[name];
  //         return newo;
  //       }, {});
  //     });
  //   return result;
  // };

  // useEffect(() => {
  //   if (selectedSectors && selectedSectors.length > 0) {
  //     formik.setFieldValue("sector", selectedSectors);
  //   }
  //   if (selectedBusinessUnits && selectedBusinessUnits.length > 0) {
  //     formik.setFieldValue("division", selectedBusinessUnits);
  //   }
  // }, [selectedSectors, selectedBusinessUnits]);

  const getIdArray = (arr) => {
    if (arr) {
      return [...new Set(arr.map((item) => item.value))].toString();
    } else {
      return [];
    }
  };

  const getTitles = (list1, list2) => {
    let selectItems = [];
    if (list1 && list2) {
      list1?.map((item1) =>
        list2?.map((item2) =>
          item2.value == item1 ? selectItems.push(item2) : null
        )
      );
    }
    return selectItems;
  };

  // const handleSectorsToggleOption = (selectedOptions) => {
  //   setSelectedSectors(selectedOptions);
  //   if (selectedOptions.length !== 0) {
  //     let data = getFilteredBUs(allBusinessUnitsDetails, selectedOptions, [
  //       "title",
  //       "value",
  //     ]);
  //     setSelectedBusinessUnits(data);
  //   }
  // };

  // const handleSelectAllSectors = (isSelected) => {
  //   if (isSelected) {
  //     setSelectedSectors(allSectorsData);
  //     let data = getFilteredBUs(allBusinessUnitsDetails, allSectorsData, [
  //       "title",
  //       "value",
  //     ]);
  //     setSelectedBusinessUnits(data);
  //   } else {
  //     handleClearSectors();
  //   }
  // };

  // const handleClearSectors = () => {
  //   setSelectedSectors([]);
  //   setSelectedBusinessUnits([]);
  // };

  // const handleBusinessUnitsToggleOption = (selectedOptions) => {
  //   setSelectedBusinessUnits(selectedOptions);
  // };

  // const handleSelectAllBusinessUnits = (isSelected) => {
  //   if (isSelected) {
  //     setSelectedBusinessUnits(allBusinessUnitsDetails);
  //   } else {
  //     handleClearBusinessUnits();
  //   }
  // };

  // const handleClearBusinessUnits = () => {
  //   setSelectedBusinessUnits([]);
  // };

  // const BuisenssSectorFunction = () => {
  //   return (
  //     <>
  //       <Grid item md={4} xs={12} className="uploadSector">
  //         <CustomMultiSelect
  //           items={allSectorsData}
  //           getOptionLabel={getOptionLabel}
  //           getOptionDisabled={getOptionDisabled}
  //           selectedValues={selectedSectors}
  //           label="Sector"
  //           selectAllLabel="Select all"
  //           onToggleOption={handleSectorsToggleOption}
  //           onClearOptions={handleClearSectors}
  //           onSelectAll={handleSelectAllSectors}
  //           className="width100 mb-4"
  //           errorMsg={formik?.errors?.sectors}
  //           disabled={edit}
  //         />
  //       </Grid>

  //       <Grid item md={4} xs={12} className="uploadSector">
  //         <CustomMultiSelect
  //           items={allBusinessUnitsDetails}
  //           getOptionLabel={getOptionLabel}
  //           getOptionDisabled={getOptionDisabled}
  //           selectedValues={selectedBusinessUnits}
  //           label="Business Unit"
  //           selectAllLabel="Select all"
  //           onToggleOption={handleBusinessUnitsToggleOption}
  //           onClearOptions={handleClearBusinessUnits}
  //           onSelectAll={handleSelectAllBusinessUnits}
  //           style={{ marginTop: "20px" }}
  //           errorMsg={formik?.errors?.division}
  //           disabled={edit}
  //         />
  //       </Grid>
  //     </>
  //   );
  // };

  //------------------------------------------------------------------
  const formik = useFormik({
    initialValues: {
      title: getUploadedData?.title ? getUploadedData?.title : "",
      subTitle: getUploadedData?.subTitle ? getUploadedData?.subTitle : "",
      // thumbnail: getUploadedData?.thumbnail ? getUploadedData?.thumbnail : null,
      imgThumbnail: getUploadedData?.thumbnail
        ? getUploadedData?.thumbnail
        : null,
      thumbnail: null,
      videoUri: getUploadedData?.videoUri ? getUploadedData?.videoUri : "",
      editorValue: getUploadedData?.description ? getUploadedData?.description : "",
      docs: getUploadedData?.media ? getUploadedData?.media : null,
      selectType: getUploadedData?.categoryType
        ? getStoryType(getUploadedData?.categoryType)
        : {},
      sector: [],
      division: [],
      name:
        getUploadedData?.isAuthorPost == false
          ? getUploadedData?.submittedFor
          : getUploadedData?.name
          ? getUploadedData?.name
          : "",
      empPhotoUrl: getUploadedData?.empPhotoUrl
        ? getUploadedData?.empPhotoUrl
        : "",
      submittedFor: getUploadedData?.submittedFor
        ? getUploadedData?.submittedFor
        : "",
      submittedBy: getUploadedData?.name,
      email: getUploadedData?.email ? getUploadedData?.email : "",
      phoneNumber: getUploadedData?.phoneNumber
        ? getUploadedData?.phoneNumber
        : "",
      publishDate: getUploadedData?.publishDate
        ? getUploadedData?.publishDate
        : null,
      radioValues: getUploadedData
        ? getUploadedData?.isAuthorPost ||
          getUploadedData?.isAuthorPost == undefined
          ? "author"
          : "colleague"
        : "author",
      hasTags: getUploadedData?.tags ? getUploadedData?.tags.toString() : "",
      selectFileType: getUploadedData?.mediaType == "Image" ? options[0] : options[1],
      isAuthorPost: getUploadedData?.isAuthorPost,
      // submittedFor:
      //   getUploadedData?.isAuthorPost == false
      //     ? getUploadedData?.submittedFor ? (getUploadedData?.submittedFor)
      //     : getUploadedData?.name
      //     ? getUploadedData?.name
      //     : "",
    },
    enableReinitialize: true,
    validationSchema:
      getEditValue == true ? uploadStorySchema : editStorySchema,
    onSubmit: (values, { resetForm }) => handleSubmit(values, resetForm),
  });

  const handleApproved = () => {
    setSubmitForApproval(false);
    history.push(`${LIVEWIRE}${MAHINDRA_EXPERIENCE}`);
  };
  const {
    video,
    videoUri,
    thumbnail,
    selectFileType,
    docs,
    videoThumbnail,
    imgThumbnail,
  } = formik.values;

  const validSlectType = () => {
    console.log("Selected File Type ", selectFileType.value, " ==============");
    if (selectFileType.value === "Image") {
      if (thumbnail || imgThumbnail != null) {
        return false;
      } else {
        return true;
      }
    } else if (selectFileType.value === "Video") {
      if (video && videoThumbnail) {
        return false;
      } else {
        return true;
      }
    } else if (selectFileType.value === "Youtube") {
      if (videoUri) {
        return false;
      } else {
        return true;
      }
    }
  };

  const handlevideoUri = (e, formik) => {
    formik.setFieldValue("videoThumbnail", "");
    formik.setFieldValue("thumbnail", "");
    formik.setFieldValue("video", "");
    formik.handleChange(e);
  };
  const getOptionDisabled = (option) => option.value === "foo";

  const getOptionLabel = (option) => `${option.title}`;
  // const handleClear = (name) => {
  //   if (name === "sector") {
  //     formik.setFieldValue("sector", []);
  //     formik.setFieldValue("division", []);
  //     setAllBusinessUnitsData([]);
  //   } else {
  //     formik.setFieldValue("division", []);
  //   }
  // };
  // const handleSelectAllSector = () => {
  //   getDivsionBySector(allSectorsData?.length && allSectorsData);
  //   formik.setFieldValue("sector", allSectorsData?.length && allSectorsData);
  // };

  const getRejectionNote = (data) => {
    setRejectionNote(data);
  };
  const getValue = (data, errors) => {
    setGetPublishErrorByAdmin(errors);
    setGetPublishDataByAdmin(data);
  };

  const handleSubmitForPreview = () => {
    setSubmitForPreview(true);
  };
  const handleSubmitForReject = () => {
    setSubmitForReject(true);
  };

  const handleEdit = () => {
    setEditApi(!editApi);
    setEdit(!edit);
  };

  const handleRejectStory = async () => {
    const { id } = getUploadedData;
    try {
      setLoading(true);
      let res = await services.api(
        "POST",
        `/ui-assist/v1/mahindra-experience/reject/${id}?admin=${localStorage.getItem("token")}`,
      );
      if (res.status === 200) {
        setLoading(false);
        setSubmitForReject(false);
        if (res) {
          Swal.fire(
            "Successfully updated!",
            "Story has been rejected successfully.",
            "success"
          ).then((result) => {
            if (result.isConfirmed) {
              history.push(`${LIVEWIRE}${MAHINDRA_EXPERIENCE}`);
            }
          });
        }
      } else {
        setLoading(false);
        setSubmitForReject(false);
        Swal.fire("Oops!", "Something went wrong.", "warning").then(
          (result) => {
            if (result.isConfirmed) {
              history.push(`${LIVEWIRE}${MAHINDRA_EXPERIENCE}`);
            }
          }
        );
      }
    } catch (err) {
      setLoading(false);
      setSubmitForReject(false);
      Swal.fire("Oops!", err.response.data, "warning").then((result) => {
        // if (result.isConfirmed) {
        //   history.push(DASHBOARD);
        // }
      });
    }
  };
  const handleEditForm = (values, resetForm) => {
    setSubmitForPublish(true);
    // history.goBack();
  };

  const handlePublishStory = async () => {
    const { id, author } = getUploadedData;
    const { publishOrSchedule, publishDate, expiryDate, iswhatsnew } =
      getPublishDataByAdmin;

    try {
      setLoading(true);
      let formData = new FormData();
      formData.append("admin", author);
      formData.append("iswhatsnew", iswhatsnew);
      formData.append(
        "publishDate",
        publishOrSchedule === "publish"
          ? moment(formik.values.publishDate).format("YYYY-MM-DD")
          : publishDate
      );
      formData.append("whatsnewexpiry", expiryDate);
      let res = await services.api(
        "POST",
        `/ui-assist/v1/mahindra-experience/approve/${id}`,
        formData
      );
      if (res.status === 200) {
        let q = new Date();
        let m = q.getMonth() + 1;
        let d = q.getDay();
        let y = q.getFullYear();

        let date = new Date(y, m, d);
        let myDate = new Date(res.data.publishDate);

        let sucessMsg =
          date == myDate
            ? "Your story has been published successfully"
            : `Story will be publish on ${moment(res.data.publishDate).format(
                "DD/MM/YYYY"
              )}`;
        setLoading(false);
        setSubmitForPublish(false);
        if (res) {
          Swal.fire("Successfully updated!", `${sucessMsg}`, "success").then(
            (result) => {
              if (result.isConfirmed) {
                history.push(`${LIVEWIRE}${MAHINDRA_EXPERIENCE}`);
              }
            }
          );
        }
      } else {
        setLoading(false);
        Swal.fire("Oops!", "Something went wrong.", "warning");
      }
    } catch (err) {
      setLoading(false);
      setSubmitForPublish(false);
      Swal.fire("Oops!", err.response.data, "warning");
    }
  };
  console.log("formik values ====>", formik.values);

  return (
    <Template pageName={"Upload a Story"}>
      {loading ? <Loader /> : null}
      <div className="submitOfferCampaignContainer">
        <Paper className="paperContainerOfferCampaign">
          <div className="innerContainersubmitOfferCampaign">
            {getUploadedData &&
            Object.keys(getUploadedData)?.length === 0 &&
            getUploadedData?.constructor === Object ? (
              <span className="submitOfferCampaignText commonFontNormalStyle">
                Upload a Story
              </span>
            ) : (
              <div className="inlineContent">
                <span className="submitOfferCampaignText commonFontNormalStyle m0">
                  Review article
                </span>
                <span className="previewMCData">
                  {edit && !scheduleDataFlag && (
                    <div onClick={handleEdit}>
                      <img src={editIconRed} alt="" className="edit-line" />
                      <span style={{ marginRight: "5px" }} onClick={handleEdit}>
                        Edit
                      </span>
                    </div>
                  )}
                  {articleId && articleId !== ":id" && (
                    <span onClick={handleSubmitForPreview}>Preview</span>
                  )}
                </span>
              </div>
            )}

            <div className="divContainerOfferCampaign">
              <Grid container spacing={2} alignItems="center">
                <Grid
                  item
                  md={12}
                  xs={12}
                  className="inputWidthTitle alignInputTextTitle boxwithlimit"
                >
                  <CharLeftField
                    disabled={edit}
                    label={<CommonLabel label="Title" mandatory />}
                    validate
                    handleChange={handleChange}
                    handleBlur={formik.handleBlur}
                    value={formik.values.title}
                    charLeft={titleCharLeft}
                    name="title"
                    type="text"
                    errorMsg={
                      formik.errors.title && formik.touched.title
                        ? formik.errors.title
                        : ""
                    }
                    inputProps={{
                      maxLength: 100,
                    }}
                  />
                </Grid>{" "}
                <Grid
                  item
                  md={12}
                  xs={12}
                  className="alignInputTextTitle boxwithlimit"
                >
                  <CharLeftField
                    disabled={edit}
                    label={<CommonLabel label="Sub Title" mandatory />}
                    validate
                    handleChange={handleChange}
                    handleBlur={formik.handleBlur}
                    value={formik.values.subTitle}
                    // charLeft={subtitleCharLeft}
                    name="subTitle"
                    type="text"
                    charLeft={subtitleCharLeft}
                    errorMsg={
                      formik.errors.subTitle && formik.touched.subTitle
                        ? formik.errors.subTitle
                        : ""
                    }
                    inputProps={{
                      maxLength: 100,
                    }}
                  />
                </Grid>
                <Grid item md={4} xs={12} className="uploadBtnStyle">
                  <CommonAutocomplete
                    disabled={edit}
                    handleSelectChange={(e, value) =>
                      formik.setFieldValue("selectFileType", value)
                    }
                    errorMsg={
                      formik.errors.selectFileType &&
                      formik.touched.selectFileType
                        ? formik.errors.selectFileType
                        : ""
                    }
                    handleBlur={formik.handleBlur}
                    name="selectFileType"
                    className="width100"
                    selectedValue={formik?.values?.selectFileType}
                    options={options}
                    style={style}
                    label={<CommonLabel label="Select file type" mandatory />}
                  />
                </Grid>
                {formik?.values?.selectFileType?.value === "Image" && (
                  <>
                    <Grid item md={4} xs={12} className="uploadBtnStyle">
                      <div className="uploadBtn">
                        <label for="thumb" className="btn uploadBtnLabel">
                          {thumbnail?.name
                            ? thumbnail.name
                            : thumbnail
                            ? thumbnail
                            : " Upload thumbnail image"}{" "}
                          <span className="colRed">*</span>
                        </label>
                        <img
                          for="thumb"
                          src={uploadIcon}
                          className="uploadIconStyl"
                          alt="Upload Button Icon"
                        />
                        <input
                          id="thumb"
                          style={{
                            visibility: "hidden",
                            padding: "19px 16px",
                            position: "absolute",
                          }}
                          type="file"
                          accept=".png, .jpg"
                          name="thumbnail"
                          disabled={edit}
                          onChange={(event) => {
                            formik.setFieldValue("video", "");
                            formik.setFieldValue("videoThumbnail", "");
                            formik.setFieldValue("videoUri", "");
                            formik.setFieldValue(
                              "thumbnail",
                              event.currentTarget.files[0]
                            );
                          }}
                        />
                      </div>
                      <span style={{ color: "#f44336" }}>
                        {formik?.errors?.thumbnail && formik.touched.thumbnail
                          ? formik.errors.thumbnail
                          : ""}
                      </span>
                    </Grid>
                    <Grid item md={1}>
                      <div>
                        {imgThumbnail && (
                          <img height={50} width={50} src={imgThumbnail} />
                        )}
                      </div>
                    </Grid>
                    <Grid item md={3} xs={12}>
                      <div className="compatiblityTxt">
                        Compatible formats: Image - JPG, PNG <br />
                        (Max size: 1010px * 560px)
                      </div>
                    </Grid>
                  </>
                )}
                {formik?.values?.selectFileType?.value === "Youtube" && (
                  <Grid item md={4} xs={12} className="uploadBtnStyle">
                    <CharLeftField
                      disabled={edit}
                      label={
                        <CommonLabel
                          label="Enter youtube link only"
                          mandatory
                        />
                      }
                      handleChange={(e) => handlevideoUri(e, formik)}
                      handleBlur={formik.handleBlur}
                      value={videoUri}
                      name="videoUri"
                      type="text"
                    />
                  </Grid>
                )}
                {formik?.values?.selectFileType?.value === "Video" && (
                  <>
                    <Grid item md={4} xs={12} className="uploadBtnStyle">
                      <div className="uploadBtn">
                        <label for="thumb" className="btn uploadBtnLabel">
                          {video ? video.name : " Upload video"}{" "}
                          <span className="colRed">*</span>
                        </label>
                        <img
                          for="thumb"
                          src={uploadIcon}
                          className="uploadIconStyl"
                          alt="Upload Button Icon"
                        />
                        <input
                          id="thumb"
                          style={{
                            visibility: "hidden",
                            padding: "19px 16px",
                            position: "absolute",
                          }}
                          disabled={edit}
                          type="file"
                          accept="video/mp4,video/x-m4v,video/*"
                          name="video"
                          onChange={(event) => {
                            formik.setFieldValue("videoUri", "");
                            formik.setFieldValue("thumbnail", "");
                            formik.setFieldValue(
                              "video",
                              event.currentTarget.files[0]
                            );
                          }}
                        />
                      </div>
                    </Grid>
                    <Grid item md={4} xs={12} className="uploadBtnStyle">
                      <div className="uploadBtn">
                        <label
                          for="videoThumbnail"
                          className="btn uploadBtnLabel"
                        >
                          {videoThumbnail
                            ? videoThumbnail.name
                            : " Upload video thumbnail"}{" "}
                          <span className="colRed">*</span>
                        </label>

                        <img
                          for="videoThumbnail"
                          src={uploadIcon}
                          className="uploadIconStyl"
                          alt="Upload Button Icon"
                        />
                        <input
                          id="videoThumbnail"
                          style={{
                            visibility: "hidden",
                            padding: "19px 16px",
                            position: "absolute",
                          }}
                          disabled={edit}
                          type="file"
                          accept=".png, .jpg"
                          name="videoThumbnail"
                          onChange={(event) => {
                            formik.setFieldValue("videoUri", "");
                            formik.setFieldValue("thumbnail", "");
                            formik.setFieldValue(
                              "videoThumbnail",
                              event.currentTarget.files[0]
                            );
                          }}
                        />
                      </div>
                    </Grid>
                  </>
                )}
                <Grid item md={12} xs={12}>
                  <div style={{ position: "relative" }}>
                    <QuillEditor
                      handleChangeEditor={(e) => {
                        formik.setFieldValue("editorValue", e);
                      }}
                      name="editorValue"
                      value={formik.values.editorValue}
                      readOnly={edit}
                    />
                  </div>
                </Grid>
                <Grid item md={4} xs={12} className="uploadBtnStyle">
                  <div className="uploadBtn">
                    <label for="files" className="btn uploadBtnLabel">
                      {docs?.name ? docs.name : docs ? docs : "Upload document"}{" "}
                    </label>
                    <img
                      src={uploadIcon}
                      className="uploadIconStyl"
                      alt="Upload Button Icon"
                    />
                    <input
                      id="files"
                      style={{
                        visibility: "hidden",
                        padding: "19px 16px",
                        position: "absolute",
                      }}
                      disabled={edit}
                      type="file"
                      accept="application/pdf"
                      name="docs"
                      onChange={(event) => {
                        formik.setFieldValue(
                          "docs",
                          event.currentTarget.files[0]
                        );
                      }}
                    />
                  </div>
                  <span style={{ color: "red" }}>{formik.errors.docs}</span>
                </Grid>
                <div className="compatiblityTxt">
                  Compatible formats: PDF
                  <br />
                  (Max size: 5MB)
                </div>
              </Grid>
            </div>
            <span className="additionalDetailsText commonFontNormalStyle">
              Additional details
            </span>
            <div className="additionalDetailsContainer">
              <Grid container spacing={2}>
                <Grid item md={4} xs={12}>
                  <CommonAutocomplete
                    disabled={edit}
                    className="width100"
                    handleSelectChange={(e, value) =>
                      formik.setFieldValue("selectType", value)
                    }
                    errorMsg={
                      formik.errors.selectType && formik.touched.selectType
                        ? formik.errors.selectType
                        : ""
                    }
                    handleBlur={formik.handleBlur}
                    name="selectType"
                    selectedValue={formik.values.selectType}
                    options={typeOption}
                    style={style}
                    label={<CommonLabel label="Select type" mandatory />}
                  />
                </Grid>
                {/* {BuisenssSectorFunction()} */}
                {/*console.log(formik.values.publishDate, "formik date edit")*/}

                <Grid item md={8} xs={12} className="righttextalign">
                  <CharLeftField
                    disabled={edit}
                    label={<CommonLabel label="Hashtags" mandatory />}
                    validate
                    handleChange={formik.handleChange}
                    value={formik.values.hasTags}
                    handleBlur={formik.handleBlur}
                    errorMsg={
                      formik.errors.hasTags && formik.touched.hasTags
                        ? formik.errors.hasTags
                        : ""
                    }
                    wordCount={hastagsLeft}
                    name="hasTags"
                    type="text"
                  />
                </Grid>
                <Grid item md={4} xs={12}>
                  <DatePicker
                    disabled={edit}
                    label={<CommonLabel label="Publish date" />}
                    selectedDate={formik.values.publishDate}
                    // maxDate={new Date().setDate(new Date().getDate())}
                    handleDateChange={(date) => {
                      formik.setFieldValue("publishDate", date);
                    }}
                    name="publishDate"
                    errorMsg={
                      formik.errors.publishDate ? formik.errors.publishDate : ""
                    }
                    disableToolbarr={true}
                  />
                </Grid>
                <Grid item md={12} xs={12}>
                  <RadioGroup
                    disabled={getEditValue}
                    aria-label="I am the author"
                    name="radioValues"
                    value={formik.values.radioValues}
                    onChange={formik.handleChange}
                  >
                    <Grid container spacing={2}>
                      <Grid item sm={6} md={6} lg={4} xs={12}>
                        <div
                          className={`${
                            formik.values.radioValues === "author"
                              ? "radioBackground"
                              : ""
                          } authorRadioButton`}
                        >
                          <FormControlLabel
                            value="author"
                            className="radioButtonForm"
                            control={<Radio />}
                            label={
                              <span className="formControlLabelStyle commonFontNormalStyle">
                                I am the author
                              </span>
                            }
                            labelPlacement="end"
                            disabled={getEditValue}
                          />
                        </div>
                      </Grid>
                      <Grid item sm={6} md={6} lg={4} xs={12}>
                        <div
                          className={`${
                            formik.values.radioValues === "colleague"
                              ? "radioBackground"
                              : ""
                          } authorRadioButton`}
                        >
                          <FormControlLabel
                            disabled={getEditValue}
                            value="colleague"
                            className="radioButtonForm"
                            control={<Radio />}
                            label={
                              <span className="formControlLabelStyle commonFontNormalStyle">
                                Submitting it for a colleague{" "}
                              </span>
                            }
                            labelPlacement="end"
                          />
                        </div>
                      </Grid>
                    </Grid>
                  </RadioGroup>
                </Grid>
                <>
                  <Grid item md={4} xs={12}>
                    <CharLeftField
                      inputType={true}
                      disabled={
                        getEditValue || formik.values.radioValues === "author"
                      }
                      label={<CommonLabel label="Name" mandatory />}
                      handleChange={formik.handleChange}
                      handleBlur={formik.handleBlur}
                      value={
                        formik.values.radioValues === "author"
                          ? getEditValue
                            ? formik.values.name
                            : getExsitingData.firstname
                            ? `${getExsitingData?.firstname} ${getExsitingData?.lastname}`
                            : ""
                          : formik.values.name
                      }
                      name="name"
                      type="text"
                      errorMsg={
                        formik.errors.name && formik.touched.name
                          ? formik.errors.name
                          : ""
                      }
                    />
                  </Grid>
                  <Grid item md={4} xs={12}>
                    <CharLeftField
                      inputType={true}
                      label={<CommonLabel label="Email" mandatory />}
                      handleChange={formik.handleChange}
                      handleBlur={formik.handleBlur}
                      disabled={
                        getEditValue || formik.values.radioValues === "author"
                      }
                      value={
                        formik.values.radioValues === "author"
                          ? getEditValue
                            ? formik.values.email
                            : getExsitingData.emailid
                            ? getExsitingData.emailid
                            : ""
                          : formik.values.email
                      }
                      name="email"
                      type="email"
                      errorMsg={
                        formik.errors.email && formik.touched.email
                          ? formik.errors.email
                          : ""
                      }
                    />
                  </Grid>
                  <Grid item md={4} xs={12}>
                    <CharLeftField
                      disabled={getEditValue}
                      inputType={true}
                      label={<CommonLabel label="Phone number" mandatory />}
                      handleChange={formik.handleChange}
                      handleBlur={formik.handleBlur}
                      value={formik.values.phoneNumber}
                      name="phoneNumber"
                      type="tel"
                      errorMsg={
                        formik.errors.phoneNumber && formik.touched.phoneNumber
                          ? formik.errors.phoneNumber
                          : ""
                      }
                    />
                  </Grid>
                </>
              </Grid>
            </div>
            {!scheduleDataFlag && (
              <div className="supportPart">
                <Grid
                  container
                  justifyContent="space-between"
                  alignItems="center"
                >
                  <span className="supportText commonFontNormalStyle">
                    {/* <img
                      src={errorWarningIcon}
                      alt="error_warning"
                      className="errorWarningIcon"
                    /> */}
                    For support and queries please reach out to &nbsp;
                    <span className="queriesEmail">
                      {" "}
                      {process.env.REACT_APP_SUPPORT_EMAIL_ID}
                    </span>
                  </span>
                  <div className="SubmitForApprovalButtonDiv">
                    {(getUploadedData &&
                      Object.keys(getUploadedData).length === 0 &&
                      getUploadedData?.constructor === Object) ||
                    !edit ? (
                      <SubmitButton
                        disabled={
                          Object.entries(formik.errors).length !== 0 &&
                          validSlectType(formik) !== true
                        }
                        label={
                          getUploadedData?.status === "published"
                            ? "Save Changes"
                            : "Submit for Approval"
                        }
                        type="submit"
                        variant="contained"
                        // className="enabledButton"
                        className={`${
                          Object.entries(formik.errors).length !== 0 &&
                          validSlectType(formik) !== true
                            ? "SubmitForApprovalButton"
                            : "enabledButton"
                        } commonFontNormalStyle`}
                        size="large"
                        onClick={formik.handleSubmit}
                      />
                    ) : (
                      getUploadedData?.status !== "published" && (
                        <div className="supportPart">
                          <Grid
                            container
                            justifyContent="flex-end"
                            alignItems="center"
                          >
                            <div className="SubmitForApprovalButtonDiv publishRejectBtn">
                              <Button
                                variant="outlined"
                                className="btnReject"
                                onClick={handleSubmitForReject}
                              >
                                Reject Story
                              </Button>
                              <SubmitButton
                                label="publish story"
                                type="button"
                                variant="contained"
                                className="commonFontNormalStyle"
                                size="large"
                                onClick={handleEditForm}
                              />
                            </div>
                          </Grid>
                        </div>
                      )
                    )}
                  </div>
                </Grid>
              </div>
            )}
          </div>
        </Paper>
      </div>
      <CommonModal
        open={submitForApproval}
        handleClose={handleCloseModal}
        headerText={
          storyStatus === "published"
            ? "Mahindra experience story edit"
            : "Mahindra experience submitted for approval"
        }
        icon={offerCompaignApprovalIcon}
        modalSize="md"
        submitBtnText="DONE"
        fullWidth
        onButtonClick={handleCloseComfirmationSubmit}
        // onButtonClick={() => handleApproved()}
      >
        <div className="Modalcontent">
          {storyStatus === "published" ? (
            <div className="offerCampaignSubmitted commonFontNormalStyle">
              Mahindra experience story <strong>‘{getTitle}’</strong> is updated
              succefully.
            </div>
          ) : (
            <div className="offerCampaignSubmitted commonFontNormalStyle">
              Your story <strong>‘{`${getTitle}`}’</strong> has been submitted
              to the admin for approval. For any queries please reach out to
              <strong>{" " + process.env.REACT_APP_SUPPORT_EMAIL_ID}</strong>
            </div>
          )}
        </div>
      </CommonModal>
      <CommonModal
        open={submitForPreview}
        handleClose={handleCloseModal}
        headerText="Preview"
        icon={previewArticle}
        modalSize="lg"
        submitBtnText="DONE"
        fullWidth
        onButtonClick={() => setSubmitForPreview(false)}
      >
        {/* <PreviewArticleComponent previewData={getUploadedData} /> */}
        <ExperiencePreview previewData={formik.values} />
      </CommonModal>
      <CommonModal
        open={submitForReject}
        handleClose={handleCloseModal}
        headerText="Reject article"
        icon={rejectArticle}
        modalSize="md"
        submitBtnText="SEND"
        fullWidth
        onButtonClick={handleRejectStory}
      >
        <RejectArticleComponent
          // handleRejectStory={handleRejectStory}
          getRejectionNote={getRejectionNote}
        />
      </CommonModal>
      <CommonModal
        open={submitForPublish}
        handleClose={handleCloseModal}
        headerText="Publish article"
        icon={offerCompaignApprovalIcon}
        modalSize="md"
        submitBtnText="SAVE CHANGES"
        fullWidth
        onButtonClick={handlePublishStory}
        disabledSubmitButton={
          Object.keys(getPublishErrorByAdmin).length ? true : false
        }
      >
        <PublishArticleComponent
          getValue={getValue}
          title={formik.values.title}
          existingPublishDate={formik.values.publishDate}
          existingExpiryDate={formik.values.expiryDate}
        />
      </CommonModal>
    </Template>
  );
}
