import React, { useState, useEffect } from "react";
import "./FavouriteTab.css";
import "../Favourites.css";
import fav_myprofile from "../../../../../Assets/Images/all-business.svg";
// import testIcon from "../../../../../../public/myWork/"

import { Paper, Grid } from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import { getFavouriteData } from "../../../../../Redux/Actions/DashboardAction";

function FavouriteTab({
  data,
  value,
  index,
  setSelectedData,
  getMyWorkLength,
  handleAddMoreExits,
  title,
  handleAddTiles2,
  getDataTitle,
  searchTerm
}) {
  const favDataState = useSelector((state) => state.getFavouriteDataReducer);
  const { getData: allFavData } = favDataState;
  const [getAllFavData, setGetAllFavData] = useState(allFavData);

  const [hoverImages, setHoverImages] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [activeMyWork, setActiveMyWork] = useState(null);
  const [myWorkData, setMyWorkData] = useState(data);
  const [dragId, setDragId] = useState();
  // const [indexOfAddFav, setIndexOfAddFav] = useState(null);
  const [countAddedCard, setCountAddedCard] = useState(0);
  const [addTilesMaster, setAddTilesMaster] = useState([]);
  const [indexArray, setIndexArray] = useState([]);
  const dispatch = useDispatch();

  const handleIcon = (result, iconName) => {
    // if (iconName === "fav_myprofile") {
    if (result.isSelected) {
      return process.env.PUBLIC_URL + `/myWork/${result.icon_class_hover}.svg`;
    } else {
      return process.env.PUBLIC_URL + `/myWork/${iconName}.svg`;
    }
    // }
  };

  const handleAnchorEl = (event, id) => {
    event.preventDefault();
    setAnchorEl(event.currentTarget);
    setActiveMyWork(id);
  };

  const handleSelectMyWork = (data) => {
    let arrayData = myWorkData.map((val) => {
      if (val.id === data.id) {
        val.isSelected = !val.isSelected;
      }
      return val;
    });
    let selectedArrayData = arrayData.filter((value) => value.isSelected);
    setMyWorkData(arrayData);
    setSelectedData(selectedArrayData);
  };

  const handleDelete = () => {
    let array = [...myWorkData];
    let filterData = array.filter(
      (filterData, i) => activeMyWork !== i && filterData
    );
    setMyWorkData(filterData);
    setAnchorEl(null);
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  const handleDrag = (ev) => {
    setDragId(ev.currentTarget.id);
  };

  const handleDrop = (ev) => {
    const dragBox = myWorkData.find((box) => box.id === dragId);
    const dropBox = myWorkData.find((box) => box.id === ev.currentTarget.id);

    const dragBoxOrder = dragBox.order;
    const dropBoxOrder = dropBox.order;

    const newBoxState = myWorkData.map((box) => {
      if (box.id === dragId) {
        box.order = dropBoxOrder;
      }
      if (box.id === ev.currentTarget.id) {
        box.order = dragBoxOrder;
      }
      return box;
    });

    setMyWorkData(newBoxState);
  };

  if (getAllFavData.myWork || getAllFavData.manageTeam) {
    let ids = getAllFavData[title].map((c) => c._id);
    const checkMyWorkData = myWorkData[title] ? myWorkData[title] : myWorkData;
    if (checkMyWorkData) {
      var limitedData =
        checkMyWorkData?.length &&
        checkMyWorkData.filter((result) => {
          return !ids.includes(result._id);
        });
    }
  }

  useEffect(() => {
    setMyWorkData(data);
  }, [data]);

  useEffect(() => {
    dispatch(getFavouriteData());
  }, []);

  useEffect(() => {
    setGetAllFavData(allFavData);
  }, [favDataState]);

  const handleClearAll = () => {
    let removeSelectedTiles = [...myWorkData];
    let getRemoveSelectedTiles = removeSelectedTiles[title]
      ? removeSelectedTiles[title]
      : removeSelectedTiles.map((result, index) => {
          if (result.isSelected === true) {
            return (removeSelectedTiles[index] = {
              ...result,
              isSelected: false,
            });
          } else {
            return removeSelectedTiles[index];
          }
        });
    setMyWorkData(getRemoveSelectedTiles);
    handleAddMoreExits("");
    setAddTilesMaster([]);
    setCountAddedCard(0);
    setIndexArray([])
  };

 
  



  const handleAddMoreCard = async (index, _id) => {
    setIndexArray([...indexArray ,index])
    

    let selectedAddMore = [...limitedData];
    // console.log(limitedData, 'limited data...')
    // console.log(getMyWorkLength, 'work length..')
    // console.log(countAddedCard, 'count added card..')
    

    if (getMyWorkLength + countAddedCard !== 6) {
      
      

      
      
      selectedAddMore[index] = { ...selectedAddMore[index], isSelected: true };
      setMyWorkData(selectedAddMore);
      
      if((!indexArray.includes(index))){
        setAddTilesMaster([...addTilesMaster, { addtileId: _id }]);
        setCountAddedCard((prevCount) => prevCount + 1);
      }else{
        setIndexArray([...indexArray])
      }
     
      
    } else {
      setIndexArray([])
      handleAddMoreExits(
        <span className="showErrorinLine">
          You have reached the maximum limit
          <div
            style={{
              width: "1px",
              height: "24px",
              backgroundColor: "#242424",
            }}
          ></div>
          <div className="fevClearAll" onClick={handleClearAll}>
            <img
              src={process.env.PUBLIC_URL + "/iconImages/close-fill.svg"}
              alt="close icon"
            />{" "}
            Clear All
          </div>
        </span>
      );
    }
  };

  useEffect(() => {
    handleAddTiles2(addTilesMaster);
  }, [addTilesMaster]);


  return (
    <>
      {value === index && (
        <div className={"alignFavModelBoxes"}>
          <Grid container spacing={2} className="heightFevTabScroll">
            {limitedData?.length > 0 &&
              limitedData
              .filter((result,index) => {
                if(searchTerm === ""){return result}else if(result._id.toLowerCase().includes(searchTerm.toLowerCase())){return result}
              })
              .map((result, index) => {
               
                return (
                  <Grid
                    item
                    xs={12}
                    md={4}
                    // draggable={true}
                    id={result._id}
                    // onDragOver={(ev) => ev.preventDefault()}
                    // onDragStart={handleDrag}
                    // onDrop={handleDrop}
                    key={result._id}
                    // onClick={(ev) => handleSelectMyWork(result)}
                    // className="cursorMove"
                  >
                    <Paper
                      key={index}
                      className={
                        result.isSelected
                          ? "alignFavModalBlocksSelected"
                          : "alignFavModalBlocks"
                      }
                      onClick={() => handleAddMoreCard(index, result._id)}
                    >
                      <div className="favBoxIconAlign">
                        <img
                          src={handleIcon(result, result.icon_class)}
                          className="iconImage"
                          alt="Icon_iamge"
                        />
                        <span className="favBox1">{result._id}</span>
                      </div>
                    </Paper>
                  </Grid>
                );
              })}
          </Grid>
        </div>
      )}
    </>
  );
}

export default FavouriteTab;
