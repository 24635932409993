import Swal from "sweetalert2";
import services from "../../Services/index";
import {
  GET_LOCALSTORAGE_STATUS_LOADING,
  GET_LOCALSTORAGE_STATUS_SUCCESS,
  GET_LOCALSTORAGE_STATUS_FAILED,
} from "../Contants/Constants";

const getLocalStorageUpdationStatusLoading = () => ({
  type: GET_LOCALSTORAGE_STATUS_LOADING,
});
const getLocalStorageUpdationStatusSuccess = () => ({
  type: GET_LOCALSTORAGE_STATUS_SUCCESS,
});
const getLocalStorageUpdationStatusFailed = () => ({
  type: GET_LOCALSTORAGE_STATUS_FAILED,
});

export const getLocalStorageUpdationStatus = () => async (dispatch) => {
  dispatch(getLocalStorageUpdationStatusLoading());
  try {
    dispatch(getLocalStorageUpdationStatusSuccess());
  } catch (err) {
    dispatch(getLocalStorageUpdationStatusFailed());
    Swal.fire("Something went wrong!");
  }
};
