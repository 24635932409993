import React from "react";
import { Grid } from "@material-ui/core";

const HashTags = ({ tags }) => {
  return (
    <Grid container direction="row">
      {tags?.length > 0 &&
        tags.map((tag, i) => (
          <div className="tagStyle" key={i}>
            <span className="tagText">{tag}</span>
          </div>
        ))}
    </Grid>
  );
};

HashTags.propTypes = {};

export default HashTags;
