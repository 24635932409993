import {
  GET_USER_BENEFITS_SUCCESS,
  GET_USER_BENEFITS_FAILED,
  GET_USER_BENEFITS_LOAD,
  GET_GRADE_SUCCESS,
  GET_GRADE_FAILED,
  GET_GRADE_LOAD,
  GET_BENEFITS_BY_GRADE_SUCCESS,
  GET_BENEFITS_BY_GRADE_FAILED,
  GET_BENEFITS_BY_GRADE_LOAD,
  ADD_BENEFITS_SUCCESS,
  ADD_BENEFITS_FAILED,
  ADD_BENEFITS_LOAD,
  EDIT_BENEFITS_SUCCESS,
  EDIT_BENEFITS_FAILED,
  EDIT_BENEFITS_LOAD
} from "../Contants/Constants";

const initialState = {
  userBenefits: undefined,
  allGrades: undefined,
  benefitsByGrade: undefined,
  addBenefits: [],
  editBenefits: [],
  isLoading: false,
  isError: "",
  status: "idle",
};

const getEntitlementReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_USER_BENEFITS_LOAD:
      return {
        ...state,
        isLoading: true,
        status: "pending"
      };
    case GET_USER_BENEFITS_SUCCESS:
      return {
        ...state,
        userBenefits: action.payload,
        isLoading: false,
        status: "success"
      };
    case GET_USER_BENEFITS_FAILED:
      return {
        ...state,
        isError: action.payload,
        isLoading: false,
        status: "failed"
      };

    case GET_GRADE_LOAD:
      return {
        ...state,
        isLoading: true,
        status: "pending"
      };
    case GET_GRADE_SUCCESS:
      return {
        ...state,
        allGrades: action.payload,
        isLoading: false,
        status: "success"
      };
    case GET_GRADE_FAILED:
      return {
        ...state,
        isError: action.payload,
        isLoading: false,
        status: "failed"
      };

    case GET_BENEFITS_BY_GRADE_LOAD:
      return {
        ...state,
        isLoading: true,
        status: "pending"
      };
    case GET_BENEFITS_BY_GRADE_SUCCESS:
      return {
        ...state,
        benefitsByGrade: action.payload,
        isLoading: false,
        status: "success"
      };
    case GET_BENEFITS_BY_GRADE_FAILED:
      return {
        ...state,
        isError: action.payload,
        isLoading: false,
        status: "failed"
      };

    case ADD_BENEFITS_LOAD:
      return {
        ...state,
        isLoading: true,
        status: "pending"
      };
    case ADD_BENEFITS_SUCCESS:
      return {
        ...state,
        addBenefits: [...state?.addBenefits, action.payload],
        isLoading: false,
        status: "success"
      };
    case ADD_BENEFITS_FAILED:
      return {
        ...state,
        isError: action.payload,
        isLoading: false,
        status: "failed"
      };

    case EDIT_BENEFITS_LOAD:
      return {
        ...state,
        isLoading: true,
        status: "pending"
      };
    case EDIT_BENEFITS_SUCCESS:
      return {
        ...state,
        editBenefits: [...state?.editBenefits, action.payload],
        isLoading: false,
        status: "success"
      };
    case EDIT_BENEFITS_FAILED:
      return {
        ...state,
        isError: action.payload,
        isLoading: false,
        status: "failed"
      };

    default:
      return state;
  }
};

export default getEntitlementReducer;
