import React, { useState, useEffect } from "react";
import "../../../MahindraCollective/UploadStory/UploadStoryComponent.css";
import { Grid } from "@material-ui/core";
import CharLeftField from "../../../../Common/CharLeftField/CharLeftField";
import CommonLabel from "../../../../Common/ComonLabel/CommonLabel";
import Title from "./Title";

const TimelineComponent = ({ data, setData }) => {
  const [lowerLimit, setLowerLimit] = useState("");
  const [upperLimit, setUpperLimit] = useState("");

  useEffect(() => {
    if (data) {
      setLowerLimit(data?.lowerLimit);
      setUpperLimit(data?.upperLimit);
    }
  }, [data]);

  useEffect(() => {
    const val = {
      lowerLimit: lowerLimit,
      upperLimit: upperLimit
    };
    const isValid = CheckValidation();
    const data = {
      value: val,
      isValid: isValid
    };
    setData(data);
  }, [lowerLimit, upperLimit]);

  function CheckValidation() {
    if (lowerLimit == "" || upperLimit == "") {
      return false;
    } else {
      return true;
    }
  }

  const handleLowerLimit = (e) => setLowerLimit(e.target.value);
  const handleUpperLimit = (e) => setUpperLimit(e.target.value);

//   console.log("name =====>", name, "email =====> ", email);

  return (
    <>
      <Title title="Timeline (in days)" />
      <div className="divContainerOfferCampaign mb-5">
        <Grid container spacing={2} alignItems="top">
          <Grid item sm={6} md={4} xs={12}>
            <CharLeftField
              label={<CommonLabel label="Minimum" mandatory />}
              value={lowerLimit}
              name="lowerLimit"
              type="number"
              handleChange={handleLowerLimit}
            />
          </Grid>
          <Grid
            item
            sm={6}
            md={4}
            xs={12}
            className="inputWidthTitle alignInputTextTitle boxwithlimit"
          >
            <CharLeftField
              label={<CommonLabel label="Maximum" mandatory />}
              value={upperLimit}
              name="upperLimit"
              type="number"
              handleChange={handleUpperLimit}
            />
          </Grid>
        </Grid>
      </div>
    </>
  );
};

export default TimelineComponent;
