import React from "react";
import Checkbox from "@material-ui/core/Checkbox";
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";
import CheckBoxOutlineBlankIcon from "@material-ui/icons/CheckBoxOutlineBlank";
import CheckBoxIcon from "@material-ui/icons/CheckBox";
import { Icon } from "@material-ui/core";
import { useState } from "react";

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

const focusStyle = { color: "#db233a" };

function CommonMultiAutocomplete({
  selectedValue,
  options,
  label,
  handleSelectChange,
  selectedSubcategory,
}) {
  const [focus, setFocus] = useState(false);

  // console.log("selectedSubcategory",selectedSubcategory,handleSelectChange);
  return (
    <Autocomplete
      multiple
      onFocus={() => setFocus(true)}
      onBlur={() => setFocus(false)}
      limitTags={1}
      selectOnFocus={true}
      id="checkboxes-tags-demo"
      getOptionSelected={(option, value) => option.value === value.value}
      options={options}
      value={selectedSubcategory || null}
      onChange={handleSelectChange}
      popupIcon={<Icon style={focus ? focusStyle : null}>expand_more</Icon>}
      disableCloseOnSelect
      getOptionLabel={(option) => option.title || ""}
      renderOption={(option, { selected }) => (
        <React.Fragment>
          {true ? (
            <Checkbox
              icon={icon}
              checkedIcon={checkedIcon}
              style={{ marginRight: 8 }}
              checked={selected}
            />
          ) : null}
          {option.title}
        </React.Fragment>
      )}
      style={{ width: 500 }}
      renderInput={(params) => (
        <TextField
          {...params}
          variant="outlined"
          label={label}
          className="autoCompleteOnFocus"
        />
      )}
    />
  );
}

export default CommonMultiAutocomplete;
