import React, { useState, useEffect } from "react";
import "../../../MahindraCollective/UploadStory/UploadStoryComponent.css";
import { Grid, FormControlLabel } from "@material-ui/core";
import Checkbox from "@mui/material/Checkbox";
import CharLeftField from "../../../../Common/CharLeftField/CharLeftField";
import CommonLabel from "../../../../Common/ComonLabel/CommonLabel";
import BenefitTitle from "./BenefitTitle";
import CommonAutocomplete from "../../../../Common/CommonAutocomplete/CommonAutocomplete";

const RoomType = [
  { title: "Bedroom", value: "Bedroom" },
  { title: "Studio", value: "Studio" },
];

const ClubMahindra = ({ data, setData }) => {
  const [includingGST, setIncludingGST] = useState(false);
  const [noOfNights, setNoOfNights] = useState("");
  const [noOfRooms, setNoOfRooms] = useState("");
  const [roomType, setRoomType] = useState("");
  const [charges, setCharges] = useState("");
  const [isAllowed, setIsAllowed] = useState(false);

  useEffect(() => {
    if (data) {
      data?.map((val) => {
        if (val?.entitlementType == "Club Mahindra") {
          setIsAllowed(true);
          setIncludingGST(val?.values?.includingGST);
          setNoOfNights(val?.values?.noOfNights);
          setNoOfRooms(val?.values?.noOfRooms);
          setRoomType(val?.values?.roomType ? val?.values?.roomType : "");
          setCharges(val?.values?.charges);
        }
      });
    }
  }, [data]);

  useEffect(() => {
    const val = {
      benefitsId: "62720e2c6bc86099de7d7bb2",
      noOfNights: noOfNights,
      noOfRooms: noOfRooms,
      roomType: roomType,
      charges: charges,
      includingGST: includingGST,
    };
    const isValid = CheckValidation();
    const data = {
      value: val,
      isAllowed: isAllowed,
      isValid: isValid,
    };
    setData(data);
  }, [noOfNights, noOfRooms, roomType, charges, includingGST, isAllowed]);

  useEffect(() => {
    if(!isAllowed)
    {
      setIncludingGST(false);
      setNoOfNights("");
      setNoOfRooms("");
      setRoomType("");
      setCharges("");
    }
  },[isAllowed]);

  function CheckValidation() {
    if (!isAllowed) {
      return true;
    } else {
      if (
        noOfNights == "" ||
        noOfRooms == "" ||
        charges == "" ||
        roomType == ""
      ) {
        return false;
      } else {
        return true;
      }
    }
  }

  const handleChange = (e) => setIncludingGST(!includingGST);
  const handleNights = (e) => setNoOfNights(e.target.value);
  const handleRooms = (e) => setNoOfRooms(e.target.value);
  const handleRoomType = (event, selectedValue) =>
    setRoomType(selectedValue.value);
  const handleCharges = (e) => setCharges(e.target.value);

  return (
    <>
      <BenefitTitle
        title="Club mahindra"
        val={isAllowed}
        setVal={setIsAllowed}
      />
      <div className="divContainerOfferCampaign mb-5">
        <Grid container spacing={2} alignItems="top">
          <Grid
            item
            sm={6}
            md={3}
            xs={12}
            className="inputWidthTitle alignInputTextTitle boxwithlimit"
          >
            <CharLeftField
              label={<CommonLabel label="No of Rooms" mandatory />}
              value={noOfRooms}
              name="rooms"
              type="number"
              handleChange={handleRooms}
              disabled={!isAllowed}
            />
          </Grid>
          <Grid item sm={6} md={3} xs={12}>
            <CommonAutocomplete
              handleSelectChange={handleRoomType}
              selectedValue={{ title: roomType, value: roomType }}
              name="roomtype"
              disabled={!isAllowed}
              options={RoomType}
              label={<CommonLabel label="Room Type" mandatory />}
            />
          </Grid>
          <Grid
            item
            sm={6}
            md={3}
            xs={12}
            className="inputWidthTitle alignInputTextTitle boxwithlimit"
          >
            <CharLeftField
              label={<CommonLabel label="No of Nights" mandatory />}
              value={noOfNights}
              name="nights"
              type="number"
              handleChange={handleNights}
              disabled={!isAllowed}
            />
          </Grid>
          <Grid
            item
            sm={6}
            md={3}
            xs={12}
            className="inputWidthTitle alignInputTextTitle boxwithlimit"
          >
            <CharLeftField
              label={<CommonLabel label="Charges per room" mandatory />}
              value={charges}
              name="charges"
              type="number"
              handleChange={handleCharges}
              disabled={!isAllowed}
            />
          </Grid>

          <Grid item sm={6} md={3} xs={12}>
            <div className="radioBackground authorRadioButton">
              <FormControlLabel
                value="author"
                className="radioButtonForm"
                control={
                  <Checkbox
                    checked={includingGST}
                    disabled={!isAllowed}
                    onChange={handleChange}
                  />
                }
                label={
                  <span className="formControlLabelStyle commonFontNormalStyle">
                    Including GST
                  </span>
                }
                labelPlacement="end"
              />
            </div>
          </Grid>
        </Grid>
      </div>
    </>
  );
};

export default ClubMahindra;
