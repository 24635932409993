import React from "react";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { TextField, Icon, makeStyles, IconButton } from "@material-ui/core";
import textFieldStyle from "../Styles/TextFieldStyle";

import { useState } from "react";
const focusStyle = { color: "#db233a" };

const useStyles = makeStyles({
  option: {
    
    fontSize: "14px",
    fontWeight: "normal",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: 1.57,
    letterSpacing: "normal",
    color: " #242424",
    backgroundColor: "transparent !important",
    "&:hover": {
      backgroundColor: "#f6eced !important",
      fontWeight: 600,
    },
  },
});

function CommonAutocomplete({
  selectedValue,
  options,
  label,
  handleSelectChange,
  handleBlurAutoComplete,
  errorMsg,
  disabled,
  defaultValue,
}) {
  const [focus, setFocus] = useState(false);
  const classes = useStyles();
  const commonTextFieldStyle = textFieldStyle();

  return (
    <Autocomplete
      disabled={disabled}
      onFocus={() => setFocus(true)}
      onBlur={() => setFocus(false)}
      value={selectedValue}
      defaultValue={defaultValue}
      classes={{
        option: classes.option,
      }}
      id="select-typt-file"
      popupIcon={<Icon style={focus ? focusStyle : null}>expand_more</Icon>}
      disableClearable
      options={options}
      getOptionLabel={(option) => option.title || ""}
      onChange={handleSelectChange}
      renderTags={(options) => {
        return options.map((option) => (
          <>
            <IconButton color="primary">
              <img src={option.icon} alt="icon" />
            </IconButton>
            {option.title}
          </>
        ));
      }}
      renderOption={(option) => {
        return (
          <>
            {option.icon && (
              <IconButton color="primary">
                <img src={option.icon} alt="icon" />
              </IconButton>
            )}
            {option.title}
          </>
        );
      }}
      renderInput={(params) => (
        <TextField
          error={errorMsg}
          helperText={errorMsg}
          {...params}
          onBlur={handleBlurAutoComplete}
          label={label}
          variant="outlined"
          className={commonTextFieldStyle.root}
          style={{ backgroundColor: "#fbfbfb" }}
        />
      )}
    />
  );
}

export default CommonAutocomplete;
