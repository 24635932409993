import React, { useState, useEffect } from "react";
import moment from "moment";
import SubmitButton from "../../../Common/Button/SubmitButton";
import editIconRed from "../../../../Assets/Images/edit-line-red.svg";
import delIcon from "../../../../Assets/Images/delete-bin-6-line-grey.svg";
import CommonModal from "../../../Common/CommonModal/CommonModal";

import { Grid, Tooltip, makeStyles } from "@material-ui/core";
import CharLeftField from "../../../Common/CharLeftField/CharLeftField";
import CommonLabel from "../../../Common/ComonLabel/CommonLabel";
import services from "../../../../Services";
import Loader from "../../../Common/Loader/Loader";
import Swal from "sweetalert2";

import { 
    getAllContactLocationData, 
    editContactLocationData, 
    deleteContactLocationData, 
    addContactLocationData 
} from "../../../../Redux/Actions/AllContactLocationAction";
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";

function EditContactLocations() {
    const history = useHistory();

    let dispatch = useDispatch();
    let allData = useSelector((state) => state.getAllContactLocationReducer);

    const [open, setOpen] = useState(false);
  const [edit, setEdit] = useState(false);
  const [location, setLocation] = useState("");
  const [locationList, setLocationList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [editItemId, setEditItemId] = useState("");

  useEffect(() => {
    dispatch(getAllContactLocationData());
  },[allData?.addData,allData?.editedData,allData?.deleteData]);

  useEffect(() => {
    setLocationList(allData?.getAllData);
  },[allData?.getAllData]);

  const checkAdd = () => {
    if(allData?.addData)
    {
        Swal.fire("Location added successfully.", " ", "success");
        handleClose();
    }
  };

  const checkEdit = () => {
    if(allData?.editedData)
    {
        Swal.fire("Location edited successfully.", " ", "success");
        handleClose();
    }
  };

  const checkDelete = () => {
    if(allData?.deleteData)
    {
        Swal.fire("Location deleted successfully.", " ", "success");
        handleClose();
    }
  };

  const handleAdd = () => {
    setOpen(true);
    setEdit(false);
    setLocation("");
  };

  const handleClose = () => setOpen(false);
  const handleChange = (e) => setLocation(e.target.value);

  const handleEditLocation = (location, id) => {
    setOpen(true);
    setEdit(true);
    setEditItemId(id);
    setLocation(location);
  };

  const handleSubmit = async (edit, id) => {
      let list = [...locationList];
    let data = { location: location };

    edit ? 
        dispatch(editContactLocationData(locationList[id]?.location,data))
        :
        dispatch(addContactLocationData(data));
    
    edit ?
        list[id] = data
        :
        list = [...list, data];

    setLocationList([...list]);
    edit ? checkEdit() : checkAdd();
  };

  const handleDeleteCategory = async (data, index) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You want to delete this location?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Yes, delete it!",
      cancelButtonText: "No, keep it",
    }).then(
         (result) => {
      if (result.isConfirmed) {
            dispatch(deleteContactLocationData(data));
            checkDelete();
            let list = [...locationList];
            const val = list?.splice(index,1);
            setLocationList([...list]);
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire("Cancelled", "Your location is safe :)", "error");
      }
    });
  };

console.log("all location data ====> ", allData);
console.log(" location list ====> ", locationList);
  return (
    <>
        <div className="mx-3 mt-3">
            <Grid container spacing={2}>
            <Grid item xs={12}>
                <div className="uploadBtn">
                <SubmitButton
                    onClick={handleAdd}
                    label="Add New Grade"
                    className="MCsubmitbutton floatRightAlign"
                />
                </div>
            </Grid>
            </Grid>
        </div>
        <div className="Modalcontent">
           
          
                <>
                <Grid container className="secrotCardAdmin">
                    <Grid item xs={12} sm={5} className="catCardTitle">
                    Executive Chairman
                    </Grid>
                    <Grid item xs={12} sm={3}>
                    
                    </Grid>
                    <Grid item xs={12} sm={2}>
                    <div className="catActionBtnContainer">
                        <img src={editIconRed} alt="" className="edit-line" />
                        <div className="catActionBtn">EDIT GRADE NAME</div>
                    </div>
                    </Grid>
                    {/* <Tooltip
                    // classes={{
                    //     tooltip: classes.customTooltip,
                    //     arrow: classes.customArrow,
                    // }}
                    title="Category can be deleted when all sub-categories and policies in it are deleted"
                    arrow
                    placement="bottom"
                    > */}
                    <Grid
                        item
                        xs={12}
                        sm={2}
                        
                    >
                        <div className="catActionBtnContainer">
                        <img src={delIcon} alt="" className="edit-line" />
                        <div className="catActionBtn delBtn">DELETE GRADE</div>
                        </div>
                    </Grid>
                    {/* </Tooltip> */} 
                </Grid>
                <Grid container className="secrotCardAdmin">
                    <Grid item xs={12} sm={5} className="catCardTitle">
                    Managing Director
                    </Grid>
                    <Grid item xs={12} sm={3}>
                    
                    </Grid>
                    <Grid item xs={12} sm={2}
                    >
                    <div className="catActionBtnContainer">
                        <img src={editIconRed} alt="" className="edit-line" />
                        <div className="catActionBtn">EDIT GRADE NAME</div>
                    </div>
                    </Grid>
                    {/* <Tooltip
                    // classes={{
                    //     tooltip: classes.customTooltip,
                    //     arrow: classes.customArrow,
                    // }}
                    title="Category can be deleted when all sub-categories and policies in it are deleted"
                    arrow
                    placement="bottom"
                    > */}
                    <Grid
                        item
                        xs={12}
                        sm={2}
                        
                    >
                        <div className="catActionBtnContainer">
                        <img src={delIcon} alt="" className="edit-line" />
                        <div className="catActionBtn delBtn">DELETE GRADE</div>
                        </div>
                    </Grid>
                    {/* </Tooltip> */}
                </Grid>
                </>
           
          

            <CommonModal
            open={open}
            handleClose={handleClose}
            headerText="Edit Grades"
            // icon={editIcon}
            modalSize="lg"
            fullWidth
            submitBtnText="Edit Grades"
            // onButtonClick={() => handleSubmit(edit, item.id)}
            // onButtonClick={() => handleSubmit(edit, editItemId)}
            disabledSubmitButton={!location ? true : false}
            >
            <Grid container alignItems="center">
                <Grid item md={12} xs={12} className="contentPadding">
                <span className="Complete-Tax-Declara">Grade</span>
                </Grid>
                <Grid item md={6} xs={12} className="contentPadding">
                <CharLeftField
                    label={
                    <CommonLabel
                        label="Add Grade"
                        mandatory
                    />
                    }
                    name="Grade"
                    type="text"
                    value={location}
                    handleChange={handleChange}
                />
                </Grid>
            </Grid>
            </CommonModal>
        </div>
    </>
  );
}

export default EditContactLocations;