import React from "react";
import { Switch } from "react-router-dom";
import SubmitOfferCampaign from "../../Components/Pages/SubmitOfferCampaign/SubmitOfferCampaign";
import LeadersSpeakComponent from "../../Components/Pages/liveWirePages/LeadersSpeak/LeadersSpeakComponent";
import OfferCampaigns from "../../Components/Pages/OfferCampaigns/OfferCampaigns";
import MaindraCollective from "../../Components/Pages/MahindraCollective/MahindraCollective";
import NotFound from "../../Components/NotFound/NotFound";
import {
  LIVEWIRE,
  OFFER_CAMPAIGNS,
  SUBMIT_OFFER_CAMPAIGN,
  LEADERS_SPEAK,
  ORG_ANNOUNCEMENT,
  MAHINDRA_COLLECTIVE,
  LIVE_WIRE_ARTICLE2,
  UPLOAD_A_STORY,
  Feedback_Table,
  UPLOAD_ARTICLE,
  OC_REVIEW_ARTICLE,
  LIVE_WIRE_ARTICLE3,
  WORK,
  ROLE_BASED,
  OUR_CORE,
  MAHINDRA_EXPERIENCE,
  MAHINDRA_EXPERIENCE_UPLOAD,
  MAHINDRA_EXPERIENCE_UPDATE,
  MAHINDRA_EXPERIENCE_DETAIL,
  MAHINDRA_EXPERIENCE_VIEW,
  MLDLLIFE,
  MLDLLIFE_DETAILS,
} from "../constant";
import OrgAnnouncements from "../../Components/Pages/OrgAnnouncements/OrgAnnouncements";
import LiveWireMcArticle2 from "../../Components/Pages/LiveWireMcArticle2/LiveWireMcArticle2";
import UploadStoryComponent from "../../Components/Pages/MahindraCollective/UploadStory/UploadStoryComponent";

import ViewArticleComponent from "../../Components/Pages/MahindraCollective/Admin/ViewArticle/ViewArticleComponent";
import ViewOfferCampaignsComponent from "../../Components/Pages/OfferCampaigns/Admin/ViewOfferCampaignsComponent";
import LiveWireMcArticle3 from "../../Components/Pages/LiveWireMcArticle2/LiveWireMcArticle3";
import ExperienceList from "../../Components/Pages/Experience/ExperienceList";
import ExperienceUpload from "../../Components/Pages/Experience/ExperienceUpload/ExperienceUpload";
import ExperienceDetails from "../../Components/Pages/Experience/ExperienceDetails";
import MLDLLifeComponent from "../../Components/Pages/MLDL Lifepost/MLDLLifeComponent";
import LifePostSearchComponent from "../../Components/Pages/MLDL Lifepost/LifePostSearchTemplate/LifePostSearchComponent";
import { ApmRoute } from "@elastic/apm-rum-react";

function LiveWireRoutes(props) {
  return (
    <div>
      <Switch>
        <ApmRoute exact path={`${LIVEWIRE}`} component={OfferCampaigns} />
        <ApmRoute
          exact
          path={`${LIVEWIRE}${OFFER_CAMPAIGNS}`}
          component={OfferCampaigns}
        />
        <ApmRoute
          exact
          path={`${LIVEWIRE}${MAHINDRA_COLLECTIVE}`}
          component={MaindraCollective}
        />
        <ApmRoute
          exact
          path={`${LIVEWIRE}${UPLOAD_ARTICLE}`}
          component={UploadStoryComponent}
          // component={ViewArticleComponent}
        />
        <ApmRoute
          exact
          path={`${LIVEWIRE}${OC_REVIEW_ARTICLE}`}
          component={ViewOfferCampaignsComponent}
        />
        <ApmRoute
          exact
          path={`${LIVEWIRE}${SUBMIT_OFFER_CAMPAIGN}`}
          component={SubmitOfferCampaign}
        />
        <ApmRoute
          exact
          path={`${LIVEWIRE}${LEADERS_SPEAK}`}
          component={LeadersSpeakComponent}
        />
        <ApmRoute
          path={`${LIVEWIRE}${ORG_ANNOUNCEMENT}`}
          component={OrgAnnouncements}
        />

        {/*<ApmRoute
          path={`${WORK}${ROLE_BASED}${Feedback_Table}`}
          component={FeedbackTable}
        /> */}
        <ApmRoute
          path={`${LIVEWIRE}${LIVE_WIRE_ARTICLE2}`}
          component={LiveWireMcArticle2}
        />
        <ApmRoute
          path={`${LIVEWIRE}${LIVE_WIRE_ARTICLE3}`}
          component={LiveWireMcArticle3}
        />
        <ApmRoute
          exact
          path={`${LIVEWIRE}${UPLOAD_A_STORY}`}
          component={UploadStoryComponent}
        />
        <ApmRoute
          exact
          path={`${LIVEWIRE}${MAHINDRA_EXPERIENCE}`}
          component={ExperienceList}
        />
        <ApmRoute
          exact
          path={`${LIVEWIRE}${MAHINDRA_EXPERIENCE_UPLOAD}`}
          component={ExperienceUpload}
        />
        <ApmRoute
          exact
          path={`${LIVEWIRE}${MAHINDRA_EXPERIENCE_UPDATE}`}
          component={ExperienceUpload}
        />
        <ApmRoute
          exact
          path={`${LIVEWIRE}${MAHINDRA_EXPERIENCE_VIEW}`}
          component={ExperienceUpload}
        />
        <ApmRoute
          exact
          path={`${LIVEWIRE}${MAHINDRA_EXPERIENCE_DETAIL}`}
          component={ExperienceDetails}
        />
        <ApmRoute
          exact
          path={`${LIVEWIRE}${MLDLLIFE}`}
          component={MLDLLifeComponent}
        />
        <ApmRoute
          exact
          path={`${LIVEWIRE}${MLDLLIFE}${MLDLLIFE_DETAILS}`}
          component={LifePostSearchComponent}
        />
        <ApmRoute component={NotFound} />
      </Switch>
    </div>
  );
}

export default LiveWireRoutes;
