import services from "../../Services/index";
import Swal from "sweetalert2";
import {
    GET_LOCATION_DATA_SUCCESS,
    GET_LOCATION_DATA_LOAD,
    GET_LOCATION_DATA_FAILED,
} from "../Contants/Constants";

const getLocationLoad = () => ({
    type: GET_LOCATION_DATA_LOAD,
});
const getLocationSuccess = (data) => ({
    type: GET_LOCATION_DATA_SUCCESS,
    payload: data,
});
const getLocationFailed = (error) => ({
    type: GET_LOCATION_DATA_FAILED,
    err: error,
});

export const getLocationData = () => async (dispatch) => {
  dispatch(getLocationLoad());
  try {
    const res = await services.api(
      "GET",
      `/ui-assist/v1/location`
    );
    if (res) {
      dispatch(getLocationSuccess(res.data));
    }
  } catch (err) {
    dispatch(getLocationFailed(err.response));
    Swal.fire("Something went wrong!", `${err?.response?.data}`, "error");
  }
};