import React, { useState, useEffect } from "react";
import "../../Pages/Policies/EditPolicies/CreatePoliciesModalComponent.css";
import {
  Tabs,
  Tab,
  Typography,
  Box,
  Grid,
  Checkbox,
  FormControlLabel,
} from "@material-ui/core";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";
import { getPolicySubCategoryData } from "../../../Redux/Actions/PolicyCategoryAction";
import "./ApprovalCenter.css";
import approve from "../../../Assets/Images/approve.svg";
import reject from "../../../Assets/Images/reject.svg";
import ApproveModal from "./ApproveModal";
import RejectModal from "./RejectModal";

function TabPanel(props) {
  const { children, value, index, ...other } = props;
  console.log(index, "index of current tab");
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

// function a11yProps(index) {
//   return {
//     id: `simple-tab-${index}`,
//     "aria-controls": `simple-tabpanel-${index}`,
//   };
// }
export default function ApprovalCard({ policyCategory }) {
  console.log("policy category inside edit daata..........", policyCategory);
  const [categoryID, setCategoryID] = useState("61738fcc0e61563bebe9390e");

  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  //   const classes = textFieldStyle();

  //   const [openModal, setOpenModal] = useState(false);
  //   const [openAddPoliciesModal, setOpenAddPoliciesModal] = useState(false);

  const dispatch = useDispatch();
  const getPolicySubCategoryDataState = useSelector(
    (state) => state.getPolicySubCategoryDataReducer
  );
  const { getData: policySubCategory } = getPolicySubCategoryDataState;
  console.log(
    "policy subcategory data inside edit...........",
    policySubCategory
  );

  useEffect(() => {
    dispatch(getPolicySubCategoryData(categoryID));
  }, [categoryID]);

  //   const onEditRowPolicies = () => {
  //     setOpenModal(true);
  //   };

  //   const handleCloseModal = () => {
  //     setOpenModal(false);
  //     setOpenAddPoliciesModal(false);
  //   };
  //   const onAddPoliciesPolicies = () => {
  //     setOpenAddPoliciesModal(true);
  //   };

  // const handleTabs = () => {
  //   return policyData.map((item,index) => {return 			<Tab label={item.category}  />})
  // }

  //click tab handlers
  //   const handleTabClick = (item, index, e) => {
  //     console.log(item, index, e);
  //     setCategoryID(item?.categoryId);
  //   };
  console.log("catid", categoryID);
  //modal 1
  const modal1 = () => {
    setOpenSettingssub1(!openSettingssub1);
  };
  const [openSettingssub1, setOpenSettingssub1] = useState(false);

  //modal 2
  const modal2 = () => {
    setOpenSettingssub2(!openSettingssub2);
  };
  const [openSettingssub2, setOpenSettingssub2] = useState(false);

  return (
    <div>
      <div className="policiesCreateModal">
        <Grid
          container
          direction="row"
          justifyContent="space-between"
          alignItems="center"
        >
          <Tabs
            value={value}
            onChange={handleChange}
            aria-label="basic tabs example"
            className="alignTabContent"
          >
            <Tab label="Stories Approval Request" />
            <Tab label="History" />
          </Tabs>
        </Grid>
      </div>
      <hr className="deviderP" />
      <TabPanel value={value} index={0} className="policiesTabModal">
        <div className="policiestable policiesModalTable">
          <div className="policiesTableHead setColumnGapPolicies">
            <div className="policyCheckbox">
              <FormControlLabel
                value="end"
                control={
                  <Checkbox
                    style={{
                      color: "#e31837",
                      width: "24px",
                      height: "24px",
                    }}
                  />
                }
              />
            </div>
            <div className="policiesTableNameheader">Sr. No.</div>
            <div className="policiesTableNameheader ">Name</div>
            <div className="policiesTableNameheader ">Date</div>
            <div className="policiesTableNameheader ">Published</div>
            <div className="policiesTableNameheader ">Type</div>
            <div className="policiesTableNameheader ">Sector</div>
            <div className="policiesTableNameheader ">Division</div>
            <div className="policiesTableNameheader">Action</div>
            <div className="policiesTableEdit"></div>
            <div className="policiesTableDelete"></div>
          </div>
          <div className="policiesModalTableContent ">
            <div className="policyCheckbox">
              <FormControlLabel
                value="end"
                control={
                  <Checkbox
                    style={{
                      color: "#e31837",
                      width: "24px",
                      height: "24px",
                    }}
                  />
                }
              />
            </div>
            <div className="policiesTableNameAs">1.1</div>
            <div className="policiesTableNameA">Pankaj bhai</div>
            <div className="policiesTableNameA">07-03-2021</div>

            <div className="policiesTableNameA">07-03-2021</div>
            <div className="policiesTableNameA">type1</div>
            <div className="policiesTableNameA">Sector</div>
            <div className="policiesTableNameA ">Division</div>
            <div className="policiesTableEdit">
              <img
                src={approve}
                alt="delete"
                onClick={modal1}
                className="imgAppRej"
              />
            </div>
            <div className="policiesTableDelete">
              <img
                src={reject}
                alt="delete"
                onClick={modal2}
                className="imgAppRej"
              />
            </div>
          </div>
          <div className="policiesModalTableContent ">
            <div className="policyCheckbox">
              <FormControlLabel
                value="end"
                control={
                  <Checkbox
                    style={{
                      color: "#e31837",
                      width: "24px",
                      height: "24px",
                    }}
                  />
                }
              />
            </div>
            <div className="policiesTableNameAs">1.1</div>
            <div className="policiesTableNameA">sarfaraz khan nisar</div>
            <div className="policiesTableNameA">07-03-2021</div>

            <div className="policiesTableNameA">07-03-2021</div>
            <div className="policiesTableNameA">type1</div>
            <div className="policiesTableNameA">Sector</div>
            <div className="policiesTableNameA">Division</div>
            <div className="policiesTableEdit">
              <img
                src={approve}
                alt="delete"
                onClick={modal1}
                className="imgAppRej"
              />
            </div>
            <div className="policiesTableDelete">
              <img
                src={reject}
                alt="delete"
                onClick={modal2}
                className="imgAppRej"
              />
            </div>
          </div>
          <div className="policiesModalTableContent ">
            <div className="policyCheckbox">
              <FormControlLabel
                value="end"
                control={
                  <Checkbox
                    style={{
                      color: "#e31837",
                      width: "24px",
                      height: "24px",
                    }}
                  />
                }
              />
            </div>
            <div className="policiesTableNameAs">1.1</div>
            <div className="policiesTableNameA">Deep</div>
            <div className="policiesTableNameA">07-03-2021</div>

            <div className="policiesTableNameA">07-03-2021</div>
            <div className="policiesTableNameA">type1</div>
            <div className="policiesTableNameA">Sector</div>
            <div className="policiesTableNameA">Division</div>
            <div className="policiesTableEdit">
              <img
                src={approve}
                alt="delete"
                onClick={modal1}
                className="imgAppRej"
              />
            </div>
            <div className="policiesTableDelete">
              <img
                src={reject}
                alt="delete"
                onClick={modal2}
                className="imgAppRej"
              />
            </div>
          </div>
          <div className="policiesModalTableContent ">
            <div className="policyCheckbox">
              <FormControlLabel
                value="end"
                control={
                  <Checkbox
                    style={{
                      color: "#e31837",
                      width: "24px",
                      height: "24px",
                    }}
                  />
                }
              />
            </div>
            <div className="policiesTableNameAs">1.1</div>
            <div className="policiesTableNameA">Rushikesh</div>
            <div className="policiesTableNameA">07-03-2021</div>

            <div className="policiesTableNameA">07-03-2021</div>
            <div className="policiesTableNameA">type1</div>
            <div className="policiesTableNameA">Sector</div>
            <div className="policiesTableNameA">Division</div>
            <div className="policiesTableEdit">
              <img
                src={approve}
                alt="delete"
                onClick={modal1}
                className="imgAppRej"
              />
            </div>
            <div className="policiesTableDelete">
              <img
                src={reject}
                alt="delete"
                onClick={modal2}
                className="imgAppRej"
              />
            </div>
          </div>

          <div className="policiesTableContent PDFTablePos">
            {/* <div className="PDFTablePosition">
                <SubmitButton label="DOWNLOAD PDF" className="downloadPDF" />
              </div> */}
          </div>
        </div>
      </TabPanel>
      <TabPanel value={value} index={1} className="policiesTabModal">
        <div className="policiestable policiesModalTable">
          <div className="policiesTableHead setColumnGapPolicies">
            <div className="policyCheckbox">
              <FormControlLabel
                value="end"
                control={
                  <Checkbox
                    style={{
                      color: "#e31837",
                      width: "24px",
                      height: "24px",
                    }}
                  />
                }
              />
            </div>
            <div className="policiesTableNameheader">Sr. No.</div>
            <div className="policiesTableNameheader ">Name</div>
            <div className="policiesTableNameheader ">Date</div>
            <div className="policiesTableNameheader ">Published</div>
            <div className="policiesTableNameheader ">Type</div>
            <div className="policiesTableNameheader ">Sector</div>
            <div className="policiesTableNameheader ">Division</div>
            <div className="policiesTableNameheader">Action</div>
            <div className="policiesTableEdit"></div>
            <div className="policiesTableDelete"></div>
          </div>
          <div className="policiesModalTableContent ">
            <div className="policyCheckbox">
              <FormControlLabel
                value="end"
                control={
                  <Checkbox
                    style={{
                      color: "#e31837",
                      width: "24px",
                      height: "24px",
                    }}
                  />
                }
              />
            </div>
            <div className="policiesTableNameAs">1.1</div>
            <div className="policiesTableNameA">pankaj</div>
            <div className="policiesTableNameA">07-03-2021</div>

            <div className="policiesTableNameA">07-03-2021</div>
            <div className="policiesTableNameA">type1</div>
            <div className="policiesTableNameA">Sector</div>
            <div className="policiesTableNameA ">Division</div>
            <div className="policiesTableEdit">
              <img
                src={approve}
                alt="delete"
                onClick={modal1}
                className="imgAppRej"
              />
            </div>
            <div className="policiesTableDelete">
              <img
                src={reject}
                alt="delete"
                onClick={modal2}
                className="imgAppRej"
              />
            </div>
          </div>
          <div className="policiesModalTableContent ">
            <div className="policyCheckbox">
              <FormControlLabel
                value="end"
                control={
                  <Checkbox
                    style={{
                      color: "#e31837",
                      width: "24px",
                      height: "24px",
                    }}
                  />
                }
              />
            </div>
            <div className="policiesTableNameAs">1.1</div>
            <div className="policiesTableNameA">sarfaraz</div>
            <div className="policiesTableNameA">07-03-2021</div>

            <div className="policiesTableNameA">07-03-2021</div>
            <div className="policiesTableNameA">type1</div>
            <div className="policiesTableNameA">Sector</div>
            <div className="policiesTableNameA">Division</div>
            <div className="policiesTableEdit">
              <img
                src={approve}
                alt="delete"
                onClick={modal1}
                className="imgAppRej"
              />
            </div>
            <div className="policiesTableDelete">
              <img
                src={reject}
                alt="delete"
                onClick={modal2}
                className="imgAppRej"
              />
            </div>
          </div>

          <div className="policiesTableContent PDFTablePos">
            {/* <div className="PDFTablePosition">
                <SubmitButton label="DOWNLOAD PDF" className="downloadPDF" />
              </div> */}
          </div>
        </div>
      </TabPanel>
      {/* <CommonModal
                open={openModal}
                handleClose={handleCloseModal}
                headerText="Edit employee benefits policies"
                icon={editIcon}
                modalSize="lg"
                policiesButtonSize
                // isBackBtn={true}
                // header={header}
                // footer={footer}
                submitBtnText="SAVE CHANGES"
                cancleBtnText="BACK"
                fullWidth
            >
                <EditRowPoliciesComponent />
            </CommonModal> */}
      {/* <CommonModal
                open={openAddPoliciesModal}
                handleClose={handleCloseModal}
                headerText="Add policy"
                icon={editIcon}
                modalSize="lg"
                submitBtnText="SAVE CHANGES"
                cancleBtnText="BACK"
                // isBackBtn={true}
                // header={header}
                // footer={footer}
                fullWidth
                policiesButtonSize
            >
                <AddPoliciesModalComponent />
            </CommonModal> */}
      <ApproveModal
        open={openSettingssub1}
        handleClose={() => setOpenSettingssub1(false)}
        headerText="Approve Stories"
        modalSize="lg"
        header={true}
        footer={true}
        fullWidth
      />
      <RejectModal
        open={openSettingssub2}
        handleClose={() => setOpenSettingssub2(false)}
        headerText="Approve Stories"
        modalSize="lg"
        header={true}
        footer={true}
        fullWidth
      />
    </div>
  );
}
