import React, { useState, useEffect } from "react";
import CommonModal from "../../../Common/CommonModal/CommonModal";
import textFieldStyle from "../../../Common/Styles/TextFieldStyle";
import editIcon from "../../../../Assets/Images/edit-line.svg";
import { Grid, TextField } from "@material-ui/core";
import { getPolicyCategoryData } from "../../../../Redux/Actions/PolicyCategoryAction";
import { useDispatch } from "react-redux";
import Swal from "sweetalert2";
import Services from "../../../../Services/index";

function AddCategories({ open, close, openEdit, closeEdit, data, nullItem }) {
  console.log(data, "data");
  const dispatch = useDispatch();
  const [addcategory, setcategory] = useState("");
  const [refresh, setrefresh] = useState(false);

  const classes = textFieldStyle();

  useEffect(() => {
    dispatch(getPolicyCategoryData());
  }, [refresh]);

  //handler
  const handleCloseModal = () => {
    close(false);
    closeEdit(false);
    nullItem(null);
  };

  const handleCategorySubmitApi = async (data) => {
    try {
      const res = await Services.api(
        "POST",
        `/ui-assist/v1/policycategory`,
        data
      );
      if (res) {
        close(false);
        Swal.fire({
          position: "center",
          icon: "success",
          title: "category added successfully",
          showConfirmButton: false,
          timer: 1500,
        });
        setrefresh(!refresh);
      }
    } catch (err) {
      if (err.response) {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Something went wrong!",

          footer: `${err.message}`,
        });
      }
    }
  };

  //edit category
  const handleCategoryEditApi = async (data) => {
    try {
      const res = await Services.api(
        "PUT",
        `/ui-assist/v1/policycategory`,
        data
      );
      if (res) {
        closeEdit(false);
        Swal.fire({
          position: "center",
          icon: "success",
          title: "category Edited successfully",
          showConfirmButton: false,
          timer: 1500,
        });
        setrefresh(!refresh);
        nullItem(null);
      }
    } catch (err) {
      if (err.response) {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Something went wrong!",

          footer: `${err.message}`,
        });
      }
    }
  };

  // const handleCategoryEditApi = async (data) => {
  //   try {
  //     const res = await Services.api(
  //       "POST",
  //       `/ui-assist/v1/policycategory`,
  //       data
  //     );
  //     if (res) {
  //       setrefresh(!refresh);
  //       Swal.fire({
  //           position: 'center',
  //           icon: 'success',
  //           title: 'category added successfully',
  //           showConfirmButton: false,
  //           timer: 1500
  //         })
  //     }
  //   } catch (err) {
  //     if (err.response) {
  //       console.log("erro", err.response);
  //     }
  //   }
  // };
  const handleCategoryDeleteApi = async (data) => {
    Swal.fire({
      title: "Are you sure?",
      text: "All Policies in this category will be deleted",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        Services.api("DELETE", `/ui-assist/v1/policycategory/${data.categorId}`, )
          .then((res) => {
            Swal.fire("Deleted!", "Policy has been deleted.", "success");
            setrefresh(!refresh);
          })
          .catch((err) => {
          
            Swal.fire({
              icon: "error",
              title: "Oops...",
              text: "Something went wrong!",

              footer: `${err.message}`,
            });
          });
      }
    });
  };

  const handleSubmit = () => {
    let data = {
      category: addcategory,
      employeeId: localStorage.getItem("token"),
    };
    handleCategorySubmitApi(data);
  };

  const onBackButtonHandler = () => {
    close(false);
    closeEdit(false);
  };
  const handleChange = (e, name) => {
    if (name === "Category") {
      setcategory(e.target.value);
    }
  };

  console.log(addcategory, "category...");

  const handleEditChange = (e) => {
    console.log("i was edit changed adsfadfadfadfadfad?????????????????");
    setcategory(e.target.value);
  };

  const handleEditSubmit = () => {
    console.log("i was edit submitted");
    let edit_data = {
      categoryId: data.categoryId,
      category: addcategory,
      employeeId: localStorage.getItem("token"),
    };
    handleCategoryEditApi(edit_data);
  };

  return (
    <>
      <CommonModal
        open={open || openEdit}
        handleClose={handleCloseModal}
        headerText={data ? "Edit Policy" : "Add Policy"}
        icon={editIcon}
        modalSize="lg"
        submitBtnText="SAVE CHANGES"
        cancleBtnText="BACK"
        // isBackBtn={true}
        // header={header}
        // footer={footer}
        onButtonClick={data ? handleEditSubmit : handleSubmit}
        fullWidth
        policiesButtonSize
        onCancleButtonClick={onBackButtonHandler}
      >
        <div className="leftRightAlign mb16">
          <Grid
            container
            direction="row"
            className="gridRowSpacing"
            spacing={3}
          >
            <Grid item sm={12} xs={12}>
              {data ? (
                <TextField
                  variant="outlined"
                  name="EditSubCategory"
                  label="edit Subcategory"
                  className={`${classes.root} fullWidth customTextfield`}
                  onChange={(e) => {
                    handleEditChange(e);
                  }}
                  defaultValue={data.category}
                />
              ) : (
                <TextField
                  variant="outlined"
                  name="Ds"
                  label="Category"
                  className={`${classes.root} fullWidth customTextfield`}
                  onChange={(e) => {
                    handleChange(e, "Category");
                  }}
                  defaultValue="add category"
                />
              )}
            </Grid>
          </Grid>
        </div>
      </CommonModal>
    </>
  );
}

export default AddCategories;
