import React, { useState, useEffect } from "react";
import SubmitButton from "../../../Common/Button/SubmitButton";
import moment from "moment";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Grid,
  Tab,
  Tabs,
  Typography,
} from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { Box } from "@mui/system";
import PropTypes from "prop-types";
import CharLeftField from "../../../Common/CharLeftField/CharLeftField";
import CommonLabel from "../../../Common/ComonLabel/CommonLabel";
import SearchBarButton from "../../../Common/SearchBar/SearchBarButton";
import EditEmergencyContact from "./EditEmergencyContact";
import EditContactLocations from "./EditContactLocations";
import CommonModal from "../../../Common/CommonModal/CommonModal";

import PLUS_ICON from "../../../../Assets/Images/Plus.svg";
import editIcon from "../../../../Assets/Images/edit-line.svg";
import editIconRed from "../../../../Assets/Images/edit-line-red.svg";
import BIN_ICON from "../../../../Assets/Images/delete-bin-line.svg";
import COMP_LINE from "../../../../Assets/Images/collectiveIcons/computer-line.svg";
import CONTACT_FILL from "../../../../Assets/Images/collectiveIcons/contacts-fill.svg";
import CONTACT_BOOK from "../../../../Assets/Images/collectiveIcons/contacts-book-2-line.svg";
import COUPON_LINE from "../../../../Assets/Images/collectiveIcons/coupon-2-line.svg";

import { getLastModifiedData } from "../../../../Redux/Actions/CommonActions";
import { getFormattedDate } from "../../../../Assets/helperFunctions/helperFunctions";
import { useSelector, useDispatch } from "react-redux";

const ECData = [
  { location: "MITC", details: [{ title: "service1" }, { title: "service2" }] },
  { location: "Sewri", details: [{ title: "service1" }] },
  {
    location: "Kandivali",
    details: [
      { title: "service1" },
      { title: "service2" },
      { title: "service3" },
    ],
  },
];

const iconOpt = [
  { icon: COMP_LINE, name: "Computer - line" },
  { icon: CONTACT_FILL, name: "Contact - book" },
  { icon: CONTACT_BOOK, name: "Contact - fill" },
  { icon: COUPON_LINE, name: "Coupon - line" },
  { icon: COMP_LINE, name: "Computer - line" },
];

export default function EmergencyContactAdmin({allEmergencyContacts}) {
  let dispatch = useDispatch();

  const lastModified = useSelector((state) => {
    return state?.getLastModifiedDataReducer?.lastModifiedEmergency;
  });

  useEffect(() => {
    dispatch(getLastModifiedData("emergency"));
  }, []);

  function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box p={3}>
            <Typography>{children}</Typography>
          </Box>
        )}
      </div>
    );
  }
  TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
  };
  const [tab, setTab] = useState(0);
  const [tab2, setTab2] = useState(0);
  const [addService, setAddService] = useState(false);
  const [open, setOpen] = useState(false);

  const EditLocation = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const handleChangeTab = (event, newValue) => {
    setTab(newValue);
  };

  const handleChangeTab2 = (event, newValue) => {
    setTab2(newValue);
  };

  const [modifiedDate, setModifiedDate] = React.useState(new Date());
  const [hideModelFlag, setHideModelFlag] = React.useState(false);
  const [phoneData, setPhoneData] = React.useState([
    {
      name: "",
      phoneNumber: "",
      extension: "",
    },
  ]);
  const [phoneDataAdd, setPhoneDataAdd] = React.useState([
    {
      name: "",
      phoneNumber: "",
      extension: "",
    },
  ]);

  return (
    <div>
      <div className="lastmodifiedpanel mb-0">
            <Grid container spacing={2}>
              <Grid item sm={2} xs={12}>
                <div className="displayBlockUserDetails mlP alignMCAdmin">
                  <p className="detailDivp">Last modified by</p>
                  <p className="detailsFontSize">
                  {lastModified?.name ? lastModified?.name : "Not Available"}
                  </p>
                </div>
              </Grid>
              <Grid item sm={2} xs={12}>
                <div className="displayBlockUserDetails alignMCAdmin">
                  <p className="detailDivp">Modified on</p>
                  <p className="detailsFontSize">
                  {lastModified?.date
                ? getFormattedDate(lastModified?.date)
                : "Not Available"}
                  </p>
                </div>
              </Grid>
              <Grid item sm={8} xs={12}>
              <div className="uploadBtn">
                <SubmitButton
                  label="Add Service"
                  className="MCsubmitbutton floatRightAlign"
                  onClick={() => setAddService(!addService)}
                />
            </div>
              </Grid>
            </Grid>
          </div>
      <div className="Modalcontent">
        <div className="setscrollItAdmin">
          <>
            <Grid container alignItems="center">
              <Grid item md={10} className="contentPadding">
                <Tabs onChange={handleChangeTab2} value={tab2}>
                  {allEmergencyContacts?.map((item, index) => (
                    <Tab
                      label={item.location}
                      key={index}
                      className="favTabs"
                    />
                  ))}
                </Tabs>
              </Grid>
              <Grid item md={2}>
                <div className="editIcon" onClick={EditLocation}>
                  <img src={editIconRed} alt="" className="edit-line" />
                  <div style={{fontSize:"14px"}}>Edit Location</div>
                </div>
              </Grid>
            </Grid>
            <TabPanel value={tab2} index={tab2}>
              {addService &&
                  <EditEmergencyContact type="add" loc={allEmergencyContacts[tab2]?.location} />
              }
              {allEmergencyContacts[tab2]?.contacts.map((item, index) => (
                <div key={index}>
                <EditEmergencyContact item={item} index={index} />
                </div>
              ))}
            </TabPanel>
            <CommonModal
              open={open}
              handleClose={handleClose}
              header={true}
              footer={false}
              headerText="Edit Location"
              icon={editIcon}
              modalSize="lg"
              fullWidth
              onButtonClick={handleClose}
              // buttonStyling={true}
            >
              <EditContactLocations />  
            </CommonModal>
          </>
        </div>
      </div>
    </div>
  );
}
