import React, { useEffect, useState } from "react";
import { Tab, Tabs, Divider } from "@material-ui/core";
import TabsPanel from "../TabsPanel/TabsPanel";
import medalIcon from "../../../../Assets/Images/medal.svg";

import AddFavourite from "../Favourites/AddFavourite/AddFavourite";
import { useDispatch, useSelector } from "react-redux";
import { getFavouriteData } from "../../../../Redux/Actions/DashboardAction";
import {isManager} from '../../../../Components/isAdmin'
function Favourites() {
  const getFavData = useSelector((state) => state.getFavouriteDataReducer);
  // const { getData } = getFavData;
  const dispatch = useDispatch();
  const [getData, setGetData] = useState(getFavData?.getData);
  const [tab, setTab] = useState(0);
  const [openSettings, setOpenSettings] = useState(false);
  const [title, setTitle] = useState("myWork");
  const [getMyWorkLength, setGetMyWorkLength] = useState(
    getData?.myWork?.length
  );

  const handleTabs = (e, value) => {
    setTab(value);
    if (value === 0) {
      setTitle("myWork");
    } else {
      setTitle("manageTeam");
    }
  };
  const handleAdd = (length) => {
    setOpenSettings(true);
    setGetMyWorkLength(length);
  };

  useEffect(() => {
    dispatch(getFavouriteData());
  }, []);

  useEffect(() => {
    setGetData(getFavData?.getData);
  }, [getFavData]);



  return (
    <div>
      <div>
        <Tabs onChange={handleTabs} value={tab}>
          <Tab label="My work" className="favTabs" />
          {isManager() ?<Tab label="Manage team" className="favTabs" /> : ""}
        </Tabs>
      </div>
      <Divider />
      {getData?.myWork && (
        <TabsPanel
          value={tab}
          index={0}
          data={getData.myWork}
          handleAdd={(length) => handleAdd(length)}
          getFavouriteData={getFavouriteData}
          title={title}
        />
      )}
      { getData?.manageTeam && (
        <TabsPanel
          value={tab}
          index={1}
          data={getData.manageTeam}
          getFavouriteData={getFavouriteData}
          handleAdd={(length) => handleAdd(length)}
          title={title}
        />
      )}

      <AddFavourite
        open={openSettings}
        handleClose={() => setOpenSettings(false)}
        headerText="Add Favourites"
        icon={medalIcon}
        modalSize="md"
        header={true}
        footer={true}
        fullWidth
        getMyWorkLength={getMyWorkLength}
        title={title}
        // getFavouriteData={getFavouriteData}
      />
    </div>
  );
}

export default Favourites;
