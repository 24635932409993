import React, { useEffect, useState } from "react";
import { Grid, Paper, Tabs, Tab, Typography, Box } from "@material-ui/core";
import PropTypes from "prop-types";
import Loader from "../../Common/Loader/Loader";
import Template from "../../Common/Template/Template";
import PageTutorialComponent from "../../Common/PageTutorial/PageTutorialComponent";
import SubmitButton from "../../Common/Button/SubmitButton";

import SearchBarButton from "../../Common/SearchBar/SearchBarButton";
import RecordsList from "./RecordsList";
import {
  LIVEWIRE,
  MAHINDRA_EXPERIENCE_UPLOAD,
} from "./../../../Routes/constant";
import { useHistory } from "react-router-dom";
import ExperienceFilters from "./ExperienceFilters";
import {
  getExperienceList,
  getPendingList,
  getScheduleList,
  EXP_OFFSET,
} from "../../../Redux/Actions/ExperienceAction";
import { useDispatch, useSelector } from "react-redux";
import Swal from "sweetalert2";
import {
  isAccesible,
  offsetCount,
} from "../../../Assets/helperFunctions/helperFunctions";
import { Pagination } from "@material-ui/lab";
import { Stack } from "@mui/material";
import CommonModal from "../../Common/CommonModal/CommonModal";
import AdminModalComponent from "./Admin/AdminModalComponent";
import settingIcon from "../../../Assets/Images/settings_5_line.svg";

// mobile responsive tabs
function TabPanel(props) {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const ExperienceList = () => {
  //Required
  const history = useHistory();
  const dispatch = useDispatch();

  //Local state declarations
  const [value, setValue] = useState(0);
  const [loading, setLoading] = useState(false);
  const [placeholder] = useState(
    "Search people, tags, sectors or a story name"
  );
  const [openModal, setOpenModal] = useState(false);
  const [manageDataLength, setManageDataLength] = useState("");
  const [searchText, setSearchText] = useState("");
  const [category, setCategory] = useState("");
  const [callAPI, setCallAPI] = useState(false);
  //filters hide show in mobile
  const [display, setDisplay] = React.useState(false);
  const [totalPages, setTotalPages] = useState(1);
  const [pageNo, setPageNo] = useState(0);
  const [highLightTab, setHighLightTab] = useState(1);
  const [openFilterModal, setOpenFilterModal] = useState(false);

  //Event triggers
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const onEditModal = () => {
    setOpenModal(true);
  };

  const handleSearch = (e) => {
    setSearchText(e.target.value);
  };

  const handlePageChange = (e, val) => {
    setPageNo(val - 1);
  };

  const handleCloseModal = () => {
    setOpenModal(false);
    setOpenFilterModal(false);
  };

  const handleDelete = () => {
    console.log("Delete button pressed");
  };

  const handleCategory = async (currentTab) => {
    if (currentTab) {
      setCategory(currentTab.toLowerCase().trim());
    } else {
      setCategory("");
    }

    if (category === "Being Digitally Empowered") {
      setHighLightTab(2);
    } else if (category === "Being Diverse") {
      setHighLightTab(3);
    } else if (category === "Being Capable") {
      setHighLightTab(4);
    } else if (category === "Being Healthy") {
      setHighLightTab(5);
    } else if (category === "Being Mahindra") {
      setHighLightTab(6);
    } else if (category === "myconstribution") {
      setHighLightTab(7);
    } else {
      setHighLightTab(1);
    }
  };

  const getAllRecords = async () => {
    try {
      setLoading(true);
      dispatch(getExperienceList(pageNo, category.toLowerCase(), searchText));
    } catch (error) {
      setLoading(false);
      console.log("Error ===> ", error);
      Swal.fire("Something went wrong!", `${error?.response?.data}`, "error");
    }
  };

  /* ============ Redux implementation Begins here ============ */
  //Selectors for selecting data from Redux
  let totalRecords = useSelector((state) => {
    return state?.getExperienceListReducer?.getTotalCount;
  });
  let getRecords = useSelector((state) => {
    return state?.getExperienceListReducer?.getData;
  });

  let pendingList = useSelector((state) => {
    return state?.getPendingReducer?.getData;
  });
  let scheduleList = useSelector((state) => {
    return state?.getPendingReducer?.scheduleData;
  });

  /* =========== useEffects begins here ================*/
  useEffect(() => {
    setPageNo(0);
  }, [category]);

  useEffect(() => {
    setLoading(true);
    getAllRecords();
  }, [pageNo, category]);

  useEffect(() => {
    if (callAPI) {
      setPageNo(0);
      setLoading(true);
      getAllRecords();
      setCallAPI(false);
    }
  }, [callAPI]);

  useEffect(() => {
    if (getRecords) {
      setLoading(false);
    }
  }, [getRecords]);

  useEffect(() => {
    if (pageNo == 0) {
      if (totalRecords) {
        setTotalPages(offsetCount(totalRecords, EXP_OFFSET));
      }
    }
  }, [totalRecords]);

  useEffect(() => {
    async function fetchdata() {
      dispatch(getPendingList());
      dispatch(getScheduleList());
    }
    fetchdata();
  }, []);

  useEffect(() => {
    setManageDataLength(pendingList?.length + scheduleList?.length);
  }, [pendingList, scheduleList]);

  /* =========== useEffects ends here ================*/

  const handleAPI = () => {
    setCallAPI(true);
  };

  const onKeyPress = (e) => {
    if (e.key === "Enter") {
      setCallAPI(true);
    }
  };

  console.log("schedule list =====> ", scheduleList);
  return (
    <Template pageName={"Mahindra Experience"}>
      {loading ? <Loader /> : null}
      <div>
        <Grid
          container
          direction="row"
          justifyContent="space-between"
          alignItems="flex-end"
        >
          <div className="mshow pagetuttop">
            <PageTutorialComponent
              modelHeadtxt={"Today Page Tutorial"}
              videoLink={
                "https://mahindraassetsp5t7ecs6.blob.core.windows.net/mrisestorage/Core%20Intranet%20V3.mp4_1680509459402.mp4"
              }
            />
          </div>
          <Grid item md={7} xs={12}>
            <div className="whatsNewHeader">
              <div className="containerTxt">
                <div className="section-header-text"> Mahindra Experience </div>
                <div className="mhide">
                  <PageTutorialComponent
                    modelHeadtxt={"Livewire Tutorial"}
                    videoLink={
                      "https://mahindraassetsp5t7ecs6.blob.core.windows.net/mrisestorage/Core%20Intranet%20V3.mp4_1680509459402.mp4"
                    }
                  />
                </div>
                {isAccesible("MAHINDRA_EXPERIENCE") && (
                  <div className="editIcon" onClick={onEditModal}>
                    <img
                      src={process.env.PUBLIC_URL + "/iconImages/MC_admin.svg"}
                      alt=""
                      className="edit-line"
                    />
                    <div>Manage {`(${manageDataLength})`}</div>
                  </div>
                )}
              </div>
            </div>
          </Grid>
          <Grid item md={4} xs={12}>
            <SearchBarButton
              placeholder={placeholder}
              searchText={searchText}
              handleSearch={handleSearch}
              handleSearchIcon={handleAPI}
              onKeyPress={onKeyPress}
              name="search"
            />
          </Grid>
        </Grid>
      </div>

      <Paper className="MClandingpageAlign mt-3 mb-0 pb-3 mFilter Standardfilter">
        <div className="liveWireFilters">
          <div className="responsiveMobFilter">
            <div
              className="MCFilter"
              xs={6}
              onClick={() => setDisplay(!display)}
            >
              <img
                src={process.env.PUBLIC_URL + "/iconImages/filter-line.svg"}
                alt="filter icon"
              />
              Filter
            </div>
            <SubmitButton
              label="Upload a story"
              className="MCsubmitbutton floatRightAlign"
              onClick={() =>
                history.push(`${LIVEWIRE}${MAHINDRA_EXPERIENCE_UPLOAD}`)
              }
            />
          </div>
          <div
            className={`${display ? "responsiveShowMC" : "responsiveHideMC"}`}
          >
            <ExperienceFilters
              path={`${LIVEWIRE}${MAHINDRA_EXPERIENCE_UPLOAD}`}
            />
          </div>
        </div>
      </Paper>

      <Paper className="MClandingpageAlign mt-0 pt-0 standardtabcontainer">
        <div className="responsiveTabs">
          <Box sx={{ width: "100%" }}>
            <Box
              sx={{ borderBottom: 1, borderColor: "divider" }}
              className="boxAlignPadMC standardlefttab"
            >
              <Tabs
                value={value}
                onChange={handleChange}
                variant="scrollable"
                scrollButtons={false}
                aria-label="basic tabs example"
                className="mahindraCollectiveTabsDM "
              >
                <Tab
                  label="All articles"
                  {...a11yProps(0)}
                  onClick={() => handleCategory("")}
                />
                <Tab
                  label="Being Digitally Empowered"
                  {...a11yProps(1)}
                  onClick={() => handleCategory("Being Digitally Empowered")}
                />
                <Tab
                  label="Being Diverse"
                  {...a11yProps(2)}
                  onClick={() => handleCategory("Being Diverse")}
                />
                <Tab
                  label="Being Capable"
                  {...a11yProps(3)}
                  onClick={() => handleCategory("Being Capable")}
                />
                <Tab
                  label="Being Healthy"
                  {...a11yProps(4)}
                  onClick={() => handleCategory("Being Healthy")}
                />
                <Tab
                  label="Being Mahindra"
                  {...a11yProps(5)}
                  onClick={() => handleCategory("Being Mahindra")}
                />
                <Tab
                  label="My contributions"
                  {...a11yProps(6)}
                  onClick={() => handleCategory("mycontribution")}
                />
              </Tabs>
            </Box>

            <div className="alignMCCOntentTabPanel standardrighttab">
              <TabPanel value={value} index={0}>
                <RecordsList
                  records={getRecords}
                  currentTab={category}
                  searchText={searchText}
                />
              </TabPanel>
              <TabPanel value={value} index={1}>
                <RecordsList
                  records={getRecords}
                  currentTab={category}
                  searchText={searchText}
                />
              </TabPanel>
              <TabPanel value={value} index={2}>
                <RecordsList
                  records={getRecords}
                  currentTab={category}
                  searchText={searchText}
                />
              </TabPanel>
              <TabPanel value={value} index={3}>
                <RecordsList
                  records={getRecords}
                  currentTab={category}
                  searchText={searchText}
                />
              </TabPanel>
              <TabPanel value={value} index={4}>
                <RecordsList
                  records={getRecords}
                  currentTab={category}
                  searchText={searchText}
                />
              </TabPanel>
              <TabPanel value={value} index={5}>
                <RecordsList
                  records={getRecords}
                  currentTab={category}
                  searchText={searchText}
                />
              </TabPanel>
              <TabPanel value={value} index={6}>
                <RecordsList
                  records={getRecords}
                  currentTab={category}
                  searchText={searchText}
                />
              </TabPanel>
            </div>
          </Box>
        </div>
      </Paper>
      <div style={{ margin: "2rem 0", float: "right" }}>
        <div className="paginationAlignFlex">
          <Stack spacing={2}>
            <Pagination
              count={totalPages}
              variant="outlined"
              shape="rounded"
              onChange={handlePageChange}
            />
          </Stack>
        </div>
      </div>
      <CommonModal
        open={openModal}
        handleClose={handleCloseModal}
        headerText="Manage Mahindra Experience"
        icon={settingIcon}
        modalSize="lg"
        submitBtnText="SAVE CHANGES"
        fullWidth
        policiesButtonSize
        onButtonClick={handleCloseModal}
      >
        <AdminModalComponent
          isOfferCampaigns={false}
          filterdList={pendingList}
          scheduleData={scheduleList}
        />
      </CommonModal>
    </Template>
  );
};

export default ExperienceList;
