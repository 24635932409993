import React, { useState, useEffect } from "react";
import "./Notifications.css";
import {
  Drawer,
  makeStyles,
  Typography,
  Tabs,
  Tab,
  Icon,
} from "@material-ui/core";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import CommonModal from "../../Common/CommonModal/CommonModal";
import CommonSwitch from "../../Common/CommonSwitch/CommonSwitch";
import NotificationsSetting from "./NotificationsSetting";
import PropTypes from "prop-types";
import settings_5_line from "../../../Assets/Images/settings_5_line.svg";
// import { notificationsContent } from "./NotificationsConfig";
//import taxDeclartionIcon from "../../../Assets/Images/tax_declaration.svg";
import taxDeclartionIcon from "../../../Assets/Images/file-list-line.svg";
import birthdayIcon from "../../../Assets/Images/cake-2-line.svg";
import surveyIcon from "../../../Assets/Images/survey.svg";
import Other from "../../../Assets/Images/file-list-line.svg";
import Claims from "../../../Assets/Images/file-copy-2-line.svg";
import NotificationStatusReducer from "../../../Redux/Reducers/NotificatoinStatusReducer";
import { NotificationStatus } from "../../../Redux/Actions/NotificationStatus";
import {
  getNotificationsData,
  getNotificationsFilterData,
} from "../../../Redux/Actions/NotificationsAction";
import cross from "../../../Assets/Images/cross.svg";
import settingsIcom from "../../../Assets/Images/red_settings-5-line.svg";
import { useDispatch, useSelector } from "react-redux";
import {
  currentDate,
  diffDays,
  tConv24,
} from "../../../Assets/helperFunctions/helperFunctions";
import moment from "moment";
import { registerSocket } from "../../RegisterComponent";
import CommonToolTip from "../../Common/CommonToolTip";
import services from "../../../Services";

const useStyles = makeStyles({
  drawerPaper: {
    marginTop: "70px",
    width: (isMobile) => (isMobile ? "100%" : "400px"),
  },
});

function Notifications({
  handleNotificationsClose,
  openNotifications,
  isMobile,
}) {
  const baseURL = process.env.REACT_APP_API_BASE_URL;
  const notificationsFilterData = useSelector(
    (state) => state.getNotificationsFilterDataReducer
  );
  const { getData: notiFilterData } = notificationsFilterData;
  
  const classes = useStyles(isMobile);
  const dispatch = useDispatch();
  const [doNotDistrub, setDoNotDistrub] = useState(false);
  const [openSettings, setOpenSettings] = useState(false);
  const [tab, setTab] = useState(0);
  const [mobileChecked, setMobileChecked] = useState({
   
    mesop: true,
    paySlip: true,
    approval: true,
    survey: true,
    polls: true,
    policy: true,
    course: true,
    birthday: true,
    workAnniversary: true,
    pointSystem: true,
    comment: true,
  });

  const [desktopChecked, setDesktopChecked] = useState({
    
    mesop: true,
    paySlip: true,
    approval: true,
    survey: true,
    polls: true,
    policy: true,
    course: true,
    birthday: true,
    workAnniversary: true,
    pointSystem: true,
    comment: true,
  });

  //sate management for the storing notifications
  useEffect(() => {
    setOpenSwitch({
      invoiceApproval: notiFilterData?.web?.status?.invoiceApproval,
      travelRequestApproval: notiFilterData?.web?.status?.travelRequestApproval,
      travelExpenseApproval: notiFilterData?.web?.status?.travelExpenseApproval,
      claimApproval: notiFilterData?.web?.status?.claimApproval,
      poApproval: notiFilterData?.web?.status?.poApproval,
      prApproval: notiFilterData?.web?.status?.prApproval,
      poaApproval: notiFilterData?.web?.status?.poaApproval,
      benefitRegistrationApproval:
        notiFilterData?.web?.status?.benefitRegistrationApproval,
      ceoCfoApproval: notiFilterData?.web?.status?.ceoCfoApproval,
      birthday: notiFilterData?.web?.status?.birthday,
      anniversary: notiFilterData?.web?.status?.anniversary,
      policy: notiFilterData?.web?.status?.policy,
     
      course: notiFilterData?.web?.status?.course,
      storyApproval: notiFilterData?.web?.status?.storyApproval,
      mesop: notiFilterData?.web?.status?.mesop
    });
  }, [notificationsFilterData]);

  //manage state for mobile vesion.
  useEffect(() => {
    setOpenSwitchMobile({
      invoiceApprovalMobile: notiFilterData?.mobile?.status?.invoiceApproval,
      travelRequestApprovalMobile:
        notiFilterData?.mobile?.status?.travelRequestApproval,
      travelExpenseApprovalMobile:
        notiFilterData?.mobile?.status?.travelExpenseApproval,
      claimApprovalMobile: notiFilterData?.mobile?.status?.claimApproval,
      poApprovalMobile: notiFilterData?.mobile?.status?.poApproval,
      prApprovalMobile: notiFilterData?.mobile?.status?.prApproval,
      poaApprovalMobile: notiFilterData?.mobile?.status?.poaApproval,
      benefitRegistrationApprovalMobile:
        notiFilterData?.mobile?.status?.benefitRegistrationApproval,
      ceoCfoApprovalMobile: notiFilterData?.mobile?.status?.ceoCfoApproval,
      birthdayMobile: notiFilterData?.mobile?.status?.birthday,
      anniversaryMobile: notiFilterData?.mobile?.status?.anniversary,
      policyMobile: notiFilterData?.mobile?.status?.policy,
      
      
      courseMobile: notiFilterData?.mobile?.status?.course,
      storyApprovalMobile: notiFilterData?.web?.status?.storyApproval,
      mesopMobile: notiFilterData?.web?.status?.mesop
    });
  }, [notificationsFilterData]);

  const [openSwitch, setOpenSwitch] = useState({
    invoiceApproval: null,
    travelRequestApproval: null,
    travelExpenseApproval: null,
    claimApproval: null,
    poApproval: null,
    prApproval: null,
    poaApproval: null,
    benefitRegistrationApproval: null,
    ceoCfoApproval: null,
    birthday: null,
    anniversary: null,
    policy: null,
    
    
    course: null,
    storyApproval: null,
    mesop: null

  });

  const [openSwitchMobile, setOpenSwitchMobile] = useState({
    invoiceApprovalMobile: null,
    travelRequestApprovalMobile: null,
    travelExpenseApprovalMobile: null,
    claimApprovalMobile: null,
    poApprovalMobile: null,
    prApprovalMobile: null,
    poaApprovalMobile: null,
    benefitRegistrationApprovalMobile: null,
    ceoCfoApprovalMobile: null,
    birthdayMobile: null,
    anniversaryMobile: null,
    policyMobile: null,
    
    approvalMobile: null,
    workAnniversaryMobile: null,
    courseMobile: null,
    storyApprovalMobile: null,
    mesopMobile: null
  });

  const {
    
    birthday,
    anniversary,
    policy,
    ceoCfoApproval,
    poaApproval,
    prApproval,
    poApproval,
    claimApproval,
    travelExpenseApproval,
    travelRequestApproval,
    invoiceApproval,
    benefitRegistrationApproval,

  
    course,
    timeOffAndAttendance,
    storyApproval,
    mesop
  } = openSwitch;

  //open switch for mobile.
  const {
   
    birthdayMobile,
    anniversaryMobile,
    policyMobile,
    ceoCfoApprovalMobile,
    poaApprovalMobile,
    prApprovalMobile,
    poApprovalMobile,
    claimApprovalMobile,
    travelExpenseApprovalMobile,
    travelRequestApprovalMobile,
    invoiceApprovalMobile,
    benefitRegistrationApprovalMobile,

   
    courseMobile,
    timeOffAndAttendanceMobile,
    storyApprovalMobile,
    mesopMobile
  } = openSwitchMobile;

  const [dndStatus, setDndStatus] = useState(null);
  //get dnd status for
  useEffect(() => {
    (async () => {
      try {
        let res = await services.api("GET", `/ui-assist/v1/dnd`);
        if (res.status === 200) {
          //console.log//(res, 'response@@')
          setDndStatus(res.data.status);
        }
      } catch (error) {
        console.log(error, 'error')
      }
    })();
  }, []);

  // console.log(dndStatus, 'dnd status')
  // useEffect(() => {
  //   (async () => {
  //     try {
  //       let res = await services.api(
  //         "POST",
  //         `/ui-assist/v1/dnd?status=${dndStatus}`
  //       );
  //     } catch (error) {
  //       console.log(error.message);
  //     }
  //   })();
  // }, [dndStatus]);


//notification icon status



  //handle dnd status 
  const handeDndStatus = (val) => {
    (async () => {
          try {
            let res = await services.api(
              "POST",
              `/ui-assist/v1/dnd?status=${val}`
            );
          } catch (error) {
            console.log(error.message);
          }
        })();
  };
  

  const handleChangeDoNotDistrub = (e) => {
    handeDndStatus(e.target.checked)
    setDndStatus(e.target.checked);
  };

  const handleSettings = () => {
    setOpenSettings(true);
    handleNotificationsClose();
  };

  const handleCloseSettings = () => {
    setOpenSettings(false);
  };

  const handleTabs = (e, value) => {
    setTab(value);
  };

  const handleChangeSwitch = (event) => {
    setOpenSwitch({ ...openSwitch, [event.target.name]: event.target.checked });
   
   
  };
 
  const handleChangeSwitch2 = (event) => {
    setOpenSwitchMobile({
      ...openSwitchMobile,
      [event.target.name]: event.target.checked,
    });
    //updateNotificationSetting()
   
  };

  const handleCheckedMobile = (event) => {
   
    setMobileChecked({
      ...mobileChecked,
      [event.target.name]: event.target.checked,
    });
   
  };

  const handleCheckedDesktop = (event) => {
    setDesktopChecked({
      ...desktopChecked,
      [event.target.name]: event.target.checked,
    });
  };

  const handleIcon = (res) => {
    // console.log('res...........', res.activity)
    let resActivity = res?.activity?.toLowerCase()?.split(" ")?.join("");
    // console.log('res acitvity....................', resActivity)
    if (resActivity === "birthday") {
      return birthdayIcon;
    } else if (resActivity === "incometaxdeclaration") {
      return taxDeclartionIcon;
    } else if (resActivity === "timeoff&attendance") {
      return surveyIcon;
    } else if (resActivity === "claimapproval") {
      return Claims;
    } else return Other;
  };

  const alertsData = [
    // {
    //   title: "Tax Declaration",
    //   note: "Note: Whenever I need to file my declaration",
    //   switchWeb: tax,
    //   switchMobile: tax,

    //   mobile: mobileChecked.tax,
    //   desktop: desktopChecked.tax,
    //   id: 0,
    //   name: "taxDeclaration",
    // },

    {
      title: "invoiceApproval",
      // note: "Note: Whenever a new payslip is generated",
      switchWeb: invoiceApproval,
      switchMobile: invoiceApprovalMobile,

      mobile: mobileChecked.paySlip,
      desktop: desktopChecked.paySlip,
      id: 1,
      name: "invoiceApproval",
      name_mobile: "invoiceApprovalMobile",
    },
    {
      title: "travelRequestApproval",
      // note: "Note: Whenever a new payslip is generated",
      switchWeb: travelRequestApproval,
      switchMobile: travelRequestApprovalMobile,

      mobile: mobileChecked.paySlip,
      desktop: desktopChecked.paySlip,
      id: 2,
      name: "travelRequestApproval",
      name_mobile: "travelRequestApprovalMobile",
    },

    {
      title: "travelExpenseApproval",
      // note: "Note: Whenever a new payslip is generated",
      switchWeb: travelExpenseApproval,
      switchMobile: travelExpenseApprovalMobile,

      mobile: mobileChecked.paySlip,
      desktop: desktopChecked.paySlip,
      id: 2,
      name: "travelExpenseApproval",
      name_mobile: "travelExpenseApprovalMobile",
    },
    {
      title: "claimApproval",
      // note: "Note: Whenever there is a change in the status",
      switchWeb: claimApproval,
      switchMobile: claimApprovalMobile,
      mobile: mobileChecked.approval,
      desktop: desktopChecked.approval,
      id: 3,
      name: "claimApproval",
      name_mobile: "claimApprovalMobile",
    },
    {
      title: "poApproval",
      // note: "Note: Whenever there is a change in the status",
      switchWeb: poApproval,
      switchMobile: poApprovalMobile,
      mobile: mobileChecked.approval,
      desktop: desktopChecked.approval,
      id: 3,
      name: "poApproval",
      name_mobile: "poApprovalMobile",
    },
    {
      title: "prApproval",
      // note: "Note: Whenever there is a change in the status",
      switchWeb: prApproval,
      switchMobile: prApprovalMobile,
      mobile: mobileChecked.approval,
      desktop: desktopChecked.approval,
      id: 3,
      name: "prApproval",
      name_mobile: "prApprovalMobile",
    },
    {
      title: "poaApproval",
      // note: "Note: Whenever there is a change in the status",
      switchWeb: poaApproval,
      switchMobile: poaApprovalMobile,
      mobile: mobileChecked.approval,
      desktop: desktopChecked.approval,
      id: 3,
      name: "poaApproval",
      name_mobile: "poaApprovalMobile",
    },
    {
      title: "benefitRegistrationApproval",
      // note: "Note: Whenever there is a change in the status",
      switchWeb: benefitRegistrationApproval,
      switchMobile: benefitRegistrationApprovalMobile,
      mobile: mobileChecked.approval,
      desktop: desktopChecked.approval,
      id: 3,
      name: "benefitRegistrationApproval",
      name_mobile: "benefitRegistrationApprovalMobile",
    },
    {
      title: "ceoCfoApproval",
      // note: "Note: Whenever there is a change in the status",
      switchWeb: ceoCfoApproval,
      switchMobile: ceoCfoApprovalMobile,
      mobile: mobileChecked.approval,
      desktop: desktopChecked.approval,
      id: 4,
      name: "ceoCfoApproval",
      name_mobile: "ceoCfoApprovalMobile",
    },
  ];

  const UpdatesData = [
    {
      title: "policy",
      // note: "Note: Whenever there is a change in the status",
      switchWeb: policy,
      switchMobile: policyMobile,
      mobile: mobileChecked.approval,
      desktop: desktopChecked.approval,
      id: 4,
      name: "policy",
      name_mobile: "policyMobile",
    },
    {
      title: "mesop",
      // note: "Note: Whenever there is a change in the status",
      switchWeb: mesop,
      switchMobile: mesopMobile,
      mobile: mobileChecked.approval,
      desktop: desktopChecked.approval,
      id: 4,
      name: "mesop",
      name_mobile: "mesopMobile",
    },
    {
      title: "course",
      // note: "Note: Whenever there is a change in the status",
      switchWeb: course,
      switchMobile: courseMobile,
      mobile: mobileChecked.approval,
      desktop: desktopChecked.approval,
      id: 4,
      name: "course",
      name_mobile: "courseMobile",
    },
    {
      title: "storyApproval",
      // note: "Note: Whenever there is a change in the status",
      switchWeb:storyApproval ,
      switchMobile: storyApprovalMobile,
      mobile: mobileChecked.approval,
      desktop: desktopChecked.approval,
      id: 4,
      name: "storyApproval",
      name_mobile: "storyApprovalMobile",
    },
  ];

  const suggestionsData = [
    {
      title: "birthday",
      // note: "Note: Whenever there is a change in the status",
      switchWeb: birthday,
      switchMobile: birthdayMobile,
      mobile: mobileChecked.approval,
      desktop: desktopChecked.approval,
      id: 4,
      name: "birthday",
      name_mobile: "birthdayMobile",
    },
    {
      title: "anniversary",
      // note: "Note: Whenever there is a change in the status",
      switchWeb: anniversary,
      switchMobile: anniversaryMobile,
      mobile: mobileChecked.approval,
      desktop: desktopChecked.approval,
      id: 4,
      name: "anniversary",
      name_mobile: "anniversaryMobile",
    },
  ];

  const notificationsData = useSelector(
    (state) => state.getNotificationsDataReducer
  );


  const { getData: notiData } = notificationsData;


  // console.log('noti...........', notiData);
  // let newArray = [...notiData];
  // console.log('newArray...', newArray);

  // const sortedNotificationData = sortingFunction(notiData, "date");
  // console.log('sorted data................',sortedNotificationData);
  //setNotifications(sortedNotificationData);

  const getFilteredNotifications = (list1, list2) => {
    let selectItems = [];
    if (list1 && list2) {
      list1?.map((item1) =>
        list2?.filter(
          (item2) => {
            if (item2.activity == item1) {
              selectItems.push(item2);
            } else {
              //selectItems.push(item2)
            }
          }

          //item2.activity != item1 ? selectItems.push(item2) :  null
        )
      );
    }
    //enabeling bullet point.
    //console.log(selectItems[0]?.viewStatus, 'selectedItems@@@@@s')
    
    
    return selectItems;
  };

  let filterdNotifications = getFilteredNotifications(
    notiFilterData?.web?.whiteList,
    notiData
  );

  //get notification data........
  useEffect(() => {
    dispatch(getNotificationsData());
    dispatch(getNotificationsFilterData());
  }, []);

  //console.log('notifaction state', notification)

  //use effect hook for loading the socket notifications;
  useEffect(() => {
    setEmployeeId(localStorage.getItem("token"));
  }, []);


 
  
  var functionCallBack = {
    handleMessage: function (message) {
      dispatch(getNotificationsData());
      dispatch(NotificationStatus({type: 'SOCKETNOTIFICATION'}))
    },
  };
  
  function setEmployeeId(employeeId) {
    let connectionString = `${baseURL}/notification/ws`;

    let queueType = "notify";

    registerSocket(employeeId, connectionString, queueType, functionCallBack);
   
  }

  //call back functions for the hitting the socket notifications

  //sorting the array based on date:
  // let sortedArray = newArray.sort(function (a, b) {
  //   console.log('a date', a.date, 'b date', b.date)
  //   var dateA = new Date(a.date), dateB = new Date(b.date)
  // //console.log( dateA - dateB)
  // return dateA - dateB
  // });
  // console.log('sorted array',sortedArray)

  useEffect(() => {
    setEmployeeId(localStorage.getItem("token"));
  }, []);

  // var functionCallBack = {
  //   handleMessage: function (message) {
  //     // console.log(message+' I handle message...........');
  //     dispatch(getNotificationsData());
  //   },
  // };
  // function setEmployeeId(employeeId) {
  //   var connectionString =
  //     "https://newmrise-dev.m-devsecops.com/notification/ws";
  //   var queueType = "notify";

  //   registerSocket(employeeId, connectionString, queueType, functionCallBack);
  // }

  const compareDate = (result) => {
    // console.log(moment(result.insertedDate).format("DD/MM/YYYY"), 'date@@@@')
    // console.log(moment(result.insertedDate).format("hh:mm a"), 'time@@@@')
    let getDateToComapre = moment(result.insertedDate).format("DD/MM/YYYY");
    let getCurrentDate = currentDate();
    let getDiffDays = diffDays(getDateToComapre, getCurrentDate);
    if (getDateToComapre === getCurrentDate) {
      return "Today";
    } else if (getDiffDays === 1) {
      return "Yesterday";
    } else {
      return moment(getDateToComapre, "DD-MM-YYYY").format("DD/MM/YYYY");
    }
  };


const updateNotificationSettingApi = async() =>{
  try {
    let data = {
      id: localStorage.getItem("token"),
      web: {
        invoiceApproval: invoiceApproval,
        travelRequestApproval: travelRequestApproval,
        travelExpenseApproval: travelExpenseApproval,
        claimApproval: claimApproval,
        poApproval: poApproval,
        prApproval: prApproval,
        poaApproval: poaApproval,
        benefitRegistrationApproval: benefitRegistrationApproval,
        ceoCfoApproval: ceoCfoApproval,
        birthday: birthday,
        anniversary: anniversary,
        policy: policy,
        course: course,
        storyApproval: storyApproval,
        mesop: mesop
      },
      mobile: {
        invoiceApproval: invoiceApprovalMobile,
        travelRequestApproval: travelRequestApprovalMobile,
        travelExpenseApproval: travelExpenseApprovalMobile,
        claimApproval: claimApprovalMobile,
        poApproval: poApprovalMobile,
        prApproval: prApprovalMobile,
        poaApproval: poaApprovalMobile,
        benefitRegistrationApproval: benefitRegistrationApprovalMobile,
        ceoCfoApproval: ceoCfoApprovalMobile,
        birthday: birthdayMobile,
        anniversary: anniversaryMobile,
        policy: policyMobile,
        course: courseMobile,
        storyApproval: storyApprovalMobile,
        mesop: mesopMobile
      },
    };
    let res = await services.api(
      "PUT",
      `/ui-assist/v1/notification/status`,
      data
    );
  } catch (error) {
    console.log(error.message);
  }
}


  //update the notification inner settings. 
  // useEffect(() => {
  //   (async () => {
  //     try {
  //       let data = {
  //         id: localStorage.getItem("token"),
  //         web: {
  //           invoiceApproval: invoiceApproval,
  //           travelRequestApproval: travelRequestApproval,
  //           travelExpenseApproval: travelExpenseApproval,
  //           claimApproval: claimApproval,
  //           poApproval: poApproval,
  //           prApproval: prApproval,
  //           poaApproval: poaApproval,
  //           benefitRegistrationApproval: benefitRegistrationApproval,
  //           ceoCfoApproval: ceoCfoApproval,
  //           birthday: birthday,
  //           anniversary: anniversary,
  //           policy: policy,
  //           course: course,
  //         },
  //         mobile: {
  //           invoiceApproval: invoiceApprovalMobile,
  //           travelRequestApproval: travelRequestApprovalMobile,
  //           travelExpenseApproval: travelExpenseApprovalMobile,
  //           claimApproval: claimApprovalMobile,
  //           poApproval: poApprovalMobile,
  //           prApproval: prApprovalMobile,
  //           poaApproval: poaApprovalMobile,
  //           benefitRegistrationApproval: benefitRegistrationApprovalMobile,
  //           ceoCfoApproval: ceoCfoApprovalMobile,
  //           birthday: birthdayMobile,
  //           anniversary: anniversaryMobile,
  //           policy: policyMobile,
  //           course: courseMobile,
  //         },
  //       };
  //       let res = await services.api(
  //         "PUT",
  //         `/ui-assist/v1/notification/status`,
  //         data
  //       );
  //     } catch (error) {
  //       console.log(error.message);
  //     }
  //   })();
  // }, [
  //   invoiceApproval,
  //   invoiceApprovalMobile,
  //   travelExpenseApproval,
  //   travelExpenseApprovalMobile,
  //   travelRequestApproval,
  //   travelRequestApprovalMobile,
  //   claimApproval,
  //   poApproval,
  //   prApproval,
  //   poaApproval,
  //   benefitRegistrationApproval,
  //   ceoCfoApproval,
  //   birthday,
  //   anniversary,
  //   policy,
    
  //   course,
  //   timeOffAndAttendance,
  //   claimApprovalMobile,
  //   poApprovalMobile,
  //   prApprovalMobile,
  //   poaApprovalMobile,
  //   benefitRegistrationApprovalMobile,
  //   ceoCfoApprovalMobile,
  //   birthdayMobile,
  //   anniversaryMobile,
  //   policyMobile,
    
  //   courseMobile,
  //   timeOffAndAttendanceMobile,
  // ]);

 

  return (
    <div>
      {/* <ClickAwayListener onClickAway={handleNotificationsClose}> */}
      <Drawer
        onClose={handleNotificationsClose}
        open={openNotifications}
        BackdropProps={{ invisible: true }}
        anchor="right"
        classes={{
          paper: classes.drawerPaper,
        }}
        className="scrollHide"
      >
        <ClickAwayListener onClickAway={handleNotificationsClose}>
          <div className="mainContainer">
            <div className="contentPadd autoScroll">
              <div className="responsiveAlign">
                <div className="notificationsHeader">
                  <Typography className="notificationTitle">
                    Notifications ({filterdNotifications?.length})
                  </Typography>
                  <img
                    onClick={handleNotificationsClose}
                    src={cross}
                    alt=""
                    className="notificationCloseIcon"
                  />
                </div>
              </div>

              {filterdNotifications.length > 0 &&
                filterdNotifications?.map((result, i) => {
                  return (
                    <div key={i}>
                      <div className="listI">
                        <div className="dayStyle">{compareDate(result)}</div>
                        {/* {result.content.map((content, index) => ( */}
                        {/* <div key={index}> */}
                        <div className="subTaskAndDateContainer">
                          <div
                            style={{
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                            }}
                          >
                            {/* {content.icon && ( */}
                            <img
                              src={handleIcon(result)}
                              alt="no_icon"
                              style={{
                                paddingRight: "0.4em",
                              }}
                            />

                            <div>{result.activity}</div>
                          </div>
                          <div className="dateTimeColor">
                            {/*tConv24(result?.time?.substring(0, 5)) */}
                            {moment(result.insertedDate).format("hh:mm a")}
                          </div>
                        </div>
                        <div className="notificationMessage">
                          <span
                            className="notificationsMsg"
                            title={result?.message}
                          >
                            {result?.message}
                          </span>
                        </div>
                        {result.viewUrl ? (
                          <div className="linkTextContainer">
                            <div className="linkButtonText">
                              {/* {result.viewUrl.toUpperCase()} */}
                              {result.rejectUrl && (
                                <a
                                  target="_blank"
                                  rel="noreferrer"
                                  href={result.rejectUrl}
                                  className="linkButtonText"
                                  style={{ textDecoration: "none" }}
                                >
                                  REJECT
                                </a>
                              )}
                              {result.approveUrl && (
                                <a
                                  target="_blank"
                                  rel="noreferrer"
                                  href={result.approveUrl}
                                  className="linkButtonText"
                                  style={{ textDecoration: "none" }}
                                >
                                  APPROVE
                                </a>
                              )}
                              <a
                                target="_blank"
                                rel="noreferrer"
                                href={result.viewUrl}
                                className="linkButtonText"
                                style={{ textDecoration: "none" }}
                              >
                                VIEW
                              </a>
                            </div>
                          </div>
                        ) : (
                          <hr className="listSaparator" />
                        )}
                        {/* {console.log(
                            "result",
                            index,
                            result.content.length - 1
                          )} */}
                        {/* {result.length - 1 === index ? null : (
                        <Divider className="rowSaparator" />
                      )} */}
                        {/* <hr className="rowSaparator" /> */}
                        {/* </div> */}
                        {/* // ))} */}
                      </div>
                      {result.viewUrl && <hr className="listSaparator" />}
                    </div>
                  );
                })}
            </div>
            <div className="doNotDistrubContainer">
              <CommonSwitch
                checked={dndStatus}
                handleChange={handleChangeDoNotDistrub}
                name="doNotDistrub"
                style={{ color: `${!doNotDistrub ? "#FFFFFF" : ""}` }}
                labelPlacement="start"
                label="Do Not Disturb"
                className="pLeft"
              />

              {dndStatus ? (
                <span className="Engage">
                  Turn off “Do Not Disturb” to start receiving notifications
                  again
                </span>
              ) : null}

              {dndStatus ? (
                ""
              ) : (
                <>
                <img
                  onClick={handleSettings}
                  src={settingsIcom}
                  alt=""
                  className="cursorP padRight notifyicon"
                />
                {/* <Icon onClick={handleSettings} className="cursorP">
                settings_outlined
                
              </Icon> */}
               </>

              )}

              
            </div>
          </div>
        </ClickAwayListener>
      </Drawer>
      {/* </ClickAwayListener> */}
      <CommonModal
        open={openSettings}
        handleClose={handleCloseSettings}
        headerText="Notifications Settings"
        icon={settings_5_line}
        submitBtnText="DONE"
        modalSize="lg"
        header={true}
        footer={true}
        fullWidth
        onButtonClick={() => {
          updateNotificationSettingApi()
          setOpenSettings(false)}
        }
      >
        <div className="Modalcontent">
          <span className="notificationsModalText commonFontNormalStyle">
            Select when and how you will be notified for the following cases
          </span>
          <div className="notificationsTabs">
            <Tabs onChange={handleTabs} value={tab}>
              <Tab label="Alerts" />
              <Tab label="Updates" />
              <Tab label="Suggestions" />
            </Tabs>
          </div>
          <NotificationsSetting
            value={tab}
            index={0}
            settingsData={
              tab === 0 ? alertsData : tab === 1 ? UpdatesData : suggestionsData
            }
            handleChangeSwitch={handleChangeSwitch}
            // handleCheckedMobile={handleCheckedMobile}
            // handleCheckedDesktop={handleCheckedDesktop}
            handleChangeSwitch2={handleChangeSwitch2}
          />
        </div>
      </CommonModal>
    </div>
  );
}

Notifications.propTypes = {
  handleNotificationsClose: PropTypes.func,
  openNotifications: PropTypes.bool,
  isMobile: PropTypes.bool,
};

Notifications.defaultProps = {
  handleNotificationsClose: () => {},
  openNotifications: false,
  isMobile: false,
};

export default Notifications;
