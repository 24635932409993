import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@material-ui/core";
import EditIcon from "@mui/icons-material/Edit";
import editIcon from "../../../../Assets/Images/edit-line.svg";
import DeleteIcon from "@mui/icons-material/Delete";
import React, { useEffect, useState } from "react";

import { getPolicyAdminSubCategoryData } from "../../../../Redux/Actions/PolicyCategoryAction";
import { useDispatch, useSelector } from "react-redux";
import Subcategory from "../AddSubCategory/Subcategory";
import Swal from "sweetalert2";
import services from "../../../../Services";
import moment from "moment";

function SubcategoryTable({ element, setopenEditCategory }) {
  const [open, setopen] = useState(false);
  const [refresh, setrefresh] = useState(false);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getPolicyAdminSubCategoryData(element.categoryId));
  }, [refresh]);

  const getPolicyAdminSubCategoryDataState = useSelector(
    (state) => state.getPolicyAdminSubCategoryDataReducer
  );
  const { getData: subcategoryData } = getPolicyAdminSubCategoryDataState;

  const [currentrow, setcurrentrow] = useState({});

  const handleSubCategoryEditClick = (row) => {
    setcurrentrow(row);
    setopen(true);
  };

  const handleSubCategoryDeleteApi = async (data) => {
    Swal.fire({
      title: "Are you sure?",
      text: "All Policies in this category will be deleted",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        services
          .api("DELETE", `/ui-assist/v1/policysubcategory/${data}`)
          .then((res) => {
            Swal.fire("Deleted!", "Policy has been deleted.", "success");
            setrefresh(!refresh);
          })
          .catch((err) => {
            Swal.fire({
              icon: "error",
              title: "Oops...",
              text: "Something went wrong!",

              footer: `${err.message}`,
            });
          });
      }
    });
  };

  const handleDelete = (e, row) => {
    handleSubCategoryDeleteApi(row.policySubCategoryId);
  };

  //  let subcategoryData =    [
  //         {
  //             "policySubCategoryId": "6180df6ab6e8156f9dc77963",
  //             "title": "Employee code of conduct policy ",
  //             "description": "this is my test desscription ",
  //             "categoryId": "6180db96b6e8156f9dc77962",
  //             "createdAt": "2021-11-02 06:49:14",
  //             "updatedAt": "2021-11-02 10:56:29",
  //             "employeeId": "210030",
  //             "modifiedBy": "Samir Bhor",
  //             "srNo": 1,
  //             "deleted": false
  //         },
  //         {
  //             "policySubCategoryId": "6180dfd5b6e8156f9dc77964",
  //             "title": "policy code of conduct ",
  //             "description": "Guidelines for employees of appropriate behaviour and code of conduct in office milieu",
  //             "categoryId": "6180db96b6e8156f9dc77962",
  //             "createdAt": "2021-11-02 06:51:01",
  //             "updatedAt": "2021-11-02 06:51:01",
  //             "employeeId": "210030",
  //             "modifiedBy": "Samir Bhor",
  //             "srNo": 2,
  //             "deleted": false
  //         },
  //         {
  //             "policySubCategoryId": "6180dfebb6e8156f9dc77965",
  //             "title": "Test code of conduct ",
  //             "description": "Guidelines for employees of appropriate behaviour and code of conduct in office milieu",
  //             "categoryId": "6180db96b6e8156f9dc77962",
  //             "createdAt": "2021-11-02 06:51:23",
  //             "updatedAt": "2021-11-02 06:51:23",
  //             "employeeId": "210030",
  //             "modifiedBy": "Samir Bhor",
  //             "srNo": 3,
  //             "deleted": false
  //         },
  //         {
  //             "policySubCategoryId": "6180f5354e44537613942e9c",
  //             "title": "Employee code of conduct policy",
  //             "description": "Guidelines for employees of appropriate behaviour and code of conduct in office milieu",
  //             "categoryId": "6180db96b6e8156f9dc77962",
  //             "createdAt": "2021-11-02 08:22:13",
  //             "updatedAt": "2021-11-02 08:22:13",
  //             "employeeId": "210030",
  //             "modifiedBy": "Samir Bhor",
  //             "srNo": 4,
  //             "deleted": false
  //         }
  //     ]
  const handleCloseModal = () => {
    setopen(false);
  };

  return (
    <>
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>Sr. No.</TableCell>
              <TableCell align="right">Name</TableCell>
              <TableCell align="right">Description</TableCell>
              <TableCell align="right">Last modified by</TableCell>
              <TableCell align="right">Modified on</TableCell>
              <TableCell align="right">
                <img
                  src={process.env.PUBLIC_URL + "/policies/delete-row.svg"}
                  alt="Add"
                />
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {subcategoryData &&
              subcategoryData.map((row, index) => (
                <TableRow
                  key={row.policySubCategoryId}
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                >
                  <TableCell component="th" scope="row">
                    {row.srNo}
                  </TableCell>
                  <TableCell align="right">{row.title}</TableCell>
                  <TableCell align="right">{row.description}</TableCell>
                  <TableCell align="right">{row.modifiedBy}</TableCell>
                  <TableCell align="right">
                    {moment(row.updatedAt).format("DD-MM-YYYY")}
                  </TableCell>
                  <TableCell align="right">
                    <TableCell align="right" style={{ borderBottom: "none" }}>
                      {/* <EditIcon
                      onClick={() => {
                        handleSubCategoryEditClick(row);
                      }}
                      style={{ cursor: "pointer" }}
                    /> */}
                      <img
                        src={process.env.PUBLIC_URL + "/policies/edit-row.svg"}
                        alt="Add"
                        onClick={() => {
                          handleSubCategoryEditClick(row);
                        }}
                        style={{ cursor: "pointer", marginLeft: "13px" }}
                      />

                      {/* <DeleteIcon
                      style={{ cursor: "pointer" }}
                      onClick={(e) => {
                        handleDelete(e,row);
                      }}
                    /> */}
                      <img
                        src={
                          process.env.PUBLIC_URL + "/policies/delete-row.svg"
                        }
                        alt="Add"
                        style={{ cursor: "pointer", marginLeft: "40px" }}
                        onClick={(e) => {
                          handleDelete(e, row);
                        }}
                      />
                    </TableCell>
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>

      <Subcategory
        open={open}
        handleClose={handleCloseModal}
        icon={editIcon}
        element={currentrow}
      />
    </>
  );
}

export default SubcategoryTable;
