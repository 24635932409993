import React, { useState, useEffect } from "react";
import RowItem from "../RowItem";
import { getFormattedDate } from "../../../../../Assets/helperFunctions/helperFunctions";
import axios from "axios";

const Availment = () => {
  const [data, setData] = useState();

  const empid = localStorage?.getItem("token");

  useEffect(() => {
    axios({
      url: `https://emss.mahindra.com/sap/bc/zzhr_chk_sf_emp?sap-client=500&empid=${empid}`,
      method: "GET",
    })
      .then((res) => {
        console.log("response =====>", res);
        setData(res?.data);
      })
      .catch((err) => {
        console.log("error =====>", err);
      });
  }, []);

  function formatDate(date) {
    let data = date;
    const year = data?.slice(0, 4);
    const month = data?.slice(4, 6);
    const day = data?.slice(6, 8);
    const newDate = year + "-" + month + "-" + day;
    return getFormattedDate(newDate, "DD MMM YYYY");
  }

  return (
    <>
      {data?.ctcCarConvType !== "No Vehicle" && (
        <>
          <div>
            <h5>Your current availment details</h5>
          </div>
          <RowItem
            label="Car Type"
            content={data?.ctcCar1CarDetails ? data?.ctcCar1CarDetails : "-"}
          />
          <RowItem
            label="Registration Date"
            content={
              !data?.ctcCar1RegDate || data?.ctcCar1RegDate == "00000000"
                ? "-"
                : formatDate(data?.ctcCar1RegDate)
            }
          />
          <RowItem
            label="Vehicle Price"
            content={
              data?.ctcCar1CarPrice ? "INR " + data?.ctcCar1CarPrice : "-"
            }
          />
          <RowItem
            label="Conveyance Type"
            content={data?.ctcCarConvType ? data?.ctcCarConvType : "-"}
          />
        </>
      )}
    </>
  );
};

export default Availment;
