import Swal from "sweetalert2";
import services from "../../Services/index";
import {
  GET_PODCAST_SUCCESS,
  GET_PODCAST_LOAD,
  GET_PODCAST_FAILED,
  DELETE_PODCAST_SUCCESS,
  DELETE_PODCAST_LOAD,
  DELETE_PODCAST_FAILED,
  ADD_PODCAST_SUCCESS,
  ADD_PODCAST_LOAD,
  ADD_PODCAST_FAILED
} from "../Contants/Constants";

//GET  PODCAST

const getPodcastLoad = () => ({
  type: GET_PODCAST_LOAD
});
const getPodcastSuccess = (data) => ({
  type: GET_PODCAST_SUCCESS,
  payload: data
});
const getPodcastFailed = (error) => ({
  type: GET_PODCAST_FAILED,
  err: error
});

export const getPodcastData = () => async (dispatch) => {
  dispatch(getPodcastLoad());
  try {
    const res = await services.api("GET", `/ui-assist/v1/podcast`);
    if (res) {
      dispatch(getPodcastSuccess(res.data));
    }
  } catch (err) {
    dispatch(getPodcastFailed(err.response));
    Swal.fire("Something went wrong!", `${err?.response?.data}`, "error");
  }
};

//DELETE PODCAST

const deletePodcastLoad = () => ({
  type: DELETE_PODCAST_LOAD
});
const deletePodcastSuccess = (data) => ({
  type: DELETE_PODCAST_SUCCESS,
  payload: data
});
const deletePodcastFailed = (error) => ({
  type: DELETE_PODCAST_FAILED,
  err: error
});

export const deletePodcastData = (id) => async (dispatch) => {
  dispatch(deletePodcastLoad());
  try {
    const res = await services.api(
      "DELETE",
      `/ui-assist/v1/podcast/${id}`
    );
    if (res) {
      dispatch(deletePodcastSuccess(res.data));
    }
  } catch (err) {
    dispatch(deletePodcastFailed(err.response));
    Swal.fire("Something went wrong!", `${err?.response?.data}`, "error");
  }
};

//ADD PODCAST

const addPodcastLoad = () => ({
  type: ADD_PODCAST_LOAD
});
const addPodcastSuccess = (data) => ({
  type: ADD_PODCAST_SUCCESS,
  payload: data
});
const addPodcastFailed = (error) => ({
  type: ADD_PODCAST_FAILED,
  err: error
});

export const addPodcastData = (data) => async (dispatch) => {
  dispatch(addPodcastLoad());
  try {
    const res = await services.api("POST", `/ui-assist/v1/podcast`, data);
    if (res) {
      dispatch(addPodcastSuccess(res.data));
    }
  } catch (err) {
    dispatch(addPodcastFailed(err.response));
    Swal.fire("Something went wrong!", `${err?.response?.data}`, "error");
  }
};
