import React, { useState } from "react";
import SubmitButton from "../../../Common/Button/SubmitButton";
import "./MLDLLifePostTableComponent.css";
import moment from "moment";
import { useHistory } from "react-router-dom";
import services from "../../../../Services/index";
import axios from "axios";
import isAdmin from "../../../isAdmin";
import {
  getFormattedDate,
  isAccesible,
} from "../../../../Assets/helperFunctions/helperFunctions";
import {
  LIVEWIRE,
  MLDLLIFE,
  MLDLLIFE_DETAILS,
} from "../../../../Routes/constant";
export default function MLDLLifePostTableComponent(props) {
  const history = useHistory();
  const handlePolicyDetail = (e, item) => {
    routeHandler(item);
  };

  const routeHandler = (item) => {
    history.push(
      `${LIVEWIRE}${MLDLLIFE}${MLDLLIFE_DETAILS}?id=${item.policyId}`
    );
  };
  return (
    <div className="policiestable HidePoliciesTableMbl">
      <div className="policiesdatascroll">
        <div className="Responsivescroll">
          <div className="policiesTableHead tableheadmhide">
            <div className="policyName">Lifepost Name</div>
            <div className="policyDescriptionHead">Effective Date</div>
            <div className="policyLastUpdatedOn">Last Updated On</div>
            <div className="policyDownload">Download</div>
          </div>
        </div>

        {props.policyTableData?.length > 0 &&
          props.policyTableData?.map((result, index) => (
            <div key={index} className="policiesTableContent">
              <div
                style={{ cursor: "pointer" }}
                className="policyName mpadd10"
                onClick={(e) => {
                  handlePolicyDetail(e, result);
                }}
              >
                <div className="policyheadm">Lifepost Name</div>
                {result.policyName}
              </div>
              <div
                style={{ cursor: "pointer" }}
                className="policyDescription mpadd10"
                onClick={(e) => {
                  handlePolicyDetail(e, result);
                }}
              >
                <div className="policyheadm mpadd10">Effective Date</div>
                <div className="policydate">
                  {result?.effectiveDate
                    ? getFormattedDate(result?.effectiveDate)
                    : "Not Available"}
                </div>
              </div>

              <div className="policyLastUpdatedOn">
                <div className="policyheadm mpadd10">Last Updated On</div>
                <div className="policydate">
                  {moment(result?.modificationDate).format("DD/MM/YYYY")}
                </div>
              </div>

              <div
                style={{ cursor: "pointer" }}
                className="policyDownload mpadd10"
                onClick={() =>
                  window.open(
                    `${process.env.REACT_APP_API_BASE_URL}/ui-assist/v1/download/true?fileName=${result.policy_pdf_link}`,
                    "_self"
                  )
                }
              >
                <div className="policyheadm">Download</div>
                <img
                  src={
                    process.env.PUBLIC_URL + "/policies/download-line-table.svg"
                  }
                  alt="Download line"
                />
              </div>
            </div>
          ))}
        {props?.hideButton && (
          <div className="policiesTableContent PDFTablePos">
            <div className="PDFTablePosition">
              <SubmitButton label="DOWNLOAD PDF" className="downloadPDF" />
            </div>
          </div>
        )}
      </div>
    </div>
  );
}
