import React, { useState, useEffect } from "react";
import "./UploadArticleComponent.css";

import {
  Paper,
  Grid,
  Radio,
  FormControlLabel,
  RadioGroup,
} from "@material-ui/core";
import { FormikConsumer, useFormik } from "formik";
import Template from "./../../../Common/Template/Template";
import CharLeftField from "../../../Common/CharLeftField/CharLeftField";
import CommonLabel from "../../../Common/ComonLabel/CommonLabel";
import moment from "moment";
import services from "../../../../Services";
// import CommonEditor from "../../../Common/CommonEditor/CommonEditor";
// import { EditorState } from "draft-js";
import CommonAutocomplete from "../../../Common/CommonAutocomplete/CommonAutocomplete";

import SubmitButton from "../../../Common/Button/SubmitButton";

import offerCompaignApprovalIcon from "../../../../Assets/Images/offer-compaign-approval.svg";
import QuillEditor from "../../../Common/QuillEditor/QuillEditor";
import uploadIcon from "../../../../Assets/Images/collectiveIcons/upload-2-line.svg";
import { uploadArticleSchema } from "../../../../Assets/formikErrors/SubmitOfferCampaignSchema";
import Swal from "sweetalert2";
import {
  LIVEWIRE,
  MAHINDRA_COLLECTIVE,
  Support,
  RemoteAArticle,
} from "../../../../Routes/constant";
import { useHistory, useLocation, useParams } from "react-router-dom";
import Loader from "../../../Common/Loader/Loader";
import {
  modifyRemoteWorkingData,
  emptiesRemoteWorkingData,
} from "../../../../Redux/Actions/RemoteWorkingAction";
import { useDispatch, useSelector } from "react-redux";
import DeleteIcon from "../../../../Assets/Images/delete-bin-line.svg";

import CommonModal from "../../../Common/CommonModal/CommonModal";
import PreviewArticleComponent from "../PreviewArticleModel/PreviewArticle";
import previewArticle from "../../../../Assets/Images/search-eye-line.svg";

export default function UploadStoryComponent() {
  const [loading, setLoading] = useState(false);

  const history = useHistory();
  const location = useLocation();
  let currParams = useParams();
  const urlParam = currParams.id !== "add" ? currParams : undefined;

  const remoteWorkingList = useSelector(
    (state) => state.getRemoteWorkingReducer
  );

  const { remoteWorkDetails } = remoteWorkingList;
  const [recordId, setRecordId] = useState(urlParam?.id);

  const typeOption = [
    {
      title: "Tips & Tutorials",
      value: "tips",
    },
    {
      title: "Policy & Guidelines",
      value: "policy",
    },
    {
      title: "Wellness & R&R",
      value: "wellness",
    },
  ];

  const options = [
    {
      title: "Image",
      value: "Image",
    },
    {
      title: "Youtube URL",
      value: "Youtube URL",
    },
  ];

  // const sectorOption = [{ title: "Brand", value: "Brand" }];
  // const divisionOption = [{ title: "Mahindra", value: "Mahindra" }];

  const style = {
    width: 300,
    marginBottom: "1.5em",
  };

  // const isExpiryDate = true;

  const [titleCharLeft, setTitleCharLeft] = React.useState(50);
  const [getTitle, getSetTitle] = useState("");

  const [subtitleCharLeft, setSubtitleCharLeft] = React.useState(50);
  // const [textAreaCharLeft, setTextAreaCharLeft] = React.useState(3500);
  const [hastagsLeft, setHastagsLeft] = React.useState(3);
  const [getSectorList, setGetSectorList] = useState([]);
  const [getDivisionList, setGetDivisionList] = useState([]);
  const [getExsitingData, setExsitingData] = useState("");

  const [submitForApproval, setSubmitForApproval] = React.useState(false);
  const [selectedValue, setSelectedValue] = useState("");
  const remoteWorkData = useSelector((state) => state.getRemoteWorkingReducer);

  //for Preview Modal
  const [submitForPublish, setSubmitForPublish] = React.useState(false);
  const [submitForReject, setSubmitForReject] = React.useState(false);
  const [submitForPreview, setSubmitForPreview] = React.useState(false);

  const handleCloseModal = () => {
    setSubmitForPreview(false);
  };
  const handleSelectChange = (event, selectType) => {
    setSelectedValue(selectType);
  };
  const dispatch = useDispatch();

  const handleChange = (e) => {
    formik.handleChange(e);
    const { value, name } = e.target;
    if (name === "title") {
      getSetTitle(value);
      if (value.length <= 50) setTitleCharLeft(50 - value.length);
    } else if (name === "subTitle") {
      if (value.length <= 50) setSubtitleCharLeft(50 - value.length);
    }
  };

  const handleSubmitForPreview = () => {
    setSubmitForPreview(true);
  };

  useEffect(() => {
    if (urlParam?.id) {
      dispatch(modifyRemoteWorkingData("GET", urlParam?.id));
    } else {
      dispatch(emptiesRemoteWorkingData());
    }
  }, [urlParam, dispatch]);

  const getDivsionBySector = async (sectorCode) => {
    // console.log("sectorCode=======>", sectorCode);
    const getSectors = sectorCode.map((result) => {
      return {
        addsectorId: result.sectorCode,
      };
    });
  };

  const onDeleteTapped = async () => {
    dispatch(modifyRemoteWorkingData("DELETE", urlParam?.id));
    if (remoteWorkingList.status == "success") {
      Swal.fire("Deleted successfully", ``, "success");
      history.goBack();
    }
  };

  const handleSubmit = async (values, resetForm) => {
    let formData = new FormData();
    const {
      title,
      subTitle,
      thumbnail,
      selectType,
      hasTags,
      editorValue,
      videoUri,
      docs,
    } = values;

    formData.append("tags", hasTags);
    formData.append("story", editorValue);
    formData.append("type", selectType.value);
    formData.append("title", title);
    formData.append("videoUri", videoUri);
    formData.append("isVideo", videoUri ? true : false);
    formData.append("thumbnail", videoUri ? videoUri : thumbnail);
    formData.append("subTitle", subTitle);
    formData.append("media", docs);
    formData.append("id", remoteWorkData?.remoteWorkDetails?.id);

    if (urlParam?.id) {
      await dispatch(modifyRemoteWorkingData("PUT", urlParam?.id, formData));
      if (remoteWorkingList.status == "success") {
        Swal.fire("Upated successfully", ``, "success");
        history.goBack();
      }
    } else {
      await dispatch(modifyRemoteWorkingData("POST", undefined, formData));
      if (remoteWorkingList.status == "success") {
        Swal.fire("Added successfully", ``, "success");
        history.goBack();
      }
    }
  };

  const { title, subTitle, story, type, tags, media } =
    remoteWorkData?.remoteWorkDetails || {};
  console.log(
    "Updated At ====> ",
    remoteWorkData?.remoteWorkDetails?.updatedAt
  );

  let updatedAt = remoteWorkData?.remoteWorkDetails?.updatedAt;
  let videoUriNew = remoteWorkData?.remoteWorkDetails?.videoUri;

  let thumbnailNew = remoteWorkData?.remoteWorkDetails?.thumbnail;
  var fileExtension = thumbnailNew?.split(".")?.pop();
  let fileType = options[0];
  if (
    fileExtension == "png" ||
    fileExtension == "jpeg" ||
    fileExtension == "svg"
  ) {
    fileType = options[0];
  }
  let selectedTypeIndex = typeOption.findIndex((x) => x.value === type);
  const formik = useFormik({
    initialValues: {
      title: title ? title : "",
      subTitle: subTitle ? subTitle : "",
      thumbnail: thumbnailNew ? thumbnailNew : null,
      videoUri: videoUriNew ? videoUriNew : "",
      editorValue: story ? story : "",
      docs: media ? media : null,
      selectType: typeOption[selectedTypeIndex],
      hasTags: tags?.join(","),
      selectFileType: fileType,
    },
    enableReinitialize: true,
    validationSchema: uploadArticleSchema,
    onSubmit: (values, { resetForm }) => handleSubmit(values, resetForm),
  });

  // const handleApproved = () => {
  //   setSubmitForApproval(false);
  //   history.push(`${LIVEWIRE}${MAHINDRA_COLLECTIVE}`);
  // };
  const { video, videoUri, thumbnail, selectFileType, docs, videoThumbnail } =
    formik.values;

  console.log("Formik Data =====> ", formik.values);

  const validSlectType = () => {
    if (selectFileType.value === "Image") {
      if (thumbnail) {
        return false;
      } else {
        return true;
      }
    } else if (selectFileType.value === "Video") {
      if (video && videoThumbnail) {
        return false;
      } else {
        return true;
      }
    } else if (selectFileType.value === "Youtube URL") {
      if (videoUri) {
        return false;
      } else {
        return true;
      }
    }
  };

  const handlevideoUri = (e, formik) => {
    formik.setFieldValue("videoThumbnail", "");
    formik.setFieldValue("thumbnail", "");
    formik.setFieldValue("video", "");
    // formik.setFieldValue("videoThumbnail", "");
    formik.handleChange(e);
  };

  return (
    <Template pageName={"Upload Article"}>
      {loading ? <Loader /> : null}
      <div className="submitOfferCampaignContainer">
        <Paper className="paperContainerOfferCampaign">
          <div className="innerContainersubmitOfferCampaign">
            <div style={{ display: "flex" }}>
              <div className="submitOfferCampaignText commonFontNormalStyle">
                Upload An Article
              </div>
              {urlParam?.id && (
                <div
                  className="queriesEmail ms-auto linkLabel"
                  style={{ marginLeft: "auto" }}
                  onClick={handleSubmitForPreview}
                  // onClick={() =>
                  //   history.push({
                  //     pathname: `${Support}${RemoteAArticle}`,
                  //     state: { id: urlParam?.id },
                  //   })
                  // }
                >
                  PREVIEW
                </div>
              )}
            </div>
            <div className="divContainerOfferCampaign">
              <Grid container spacing={2} alignItems="center">
                <Grid item md={12} xs={12}>
                  <CharLeftField
                    label={<CommonLabel label="Title" mandatory />}
                    validate
                    handleChange={handleChange}
                    handleBlur={formik.handleBlur}
                    value={formik.values.title}
                    charLeft={titleCharLeft}
                    name="title"
                    type="text"
                    errorMsg={
                      formik.errors.title && formik.touched.title
                        ? formik.errors.title
                        : ""
                    }
                    inputProps={{
                      maxLength: 50,
                    }}
                  />
                </Grid>
                <Grid item md={12} xs={12}>
                  <CharLeftField
                    label={<CommonLabel label="Sub Title" mandatory />}
                    validate
                    handleChange={handleChange}
                    handleBlur={formik.handleBlur}
                    value={formik.values.subTitle}
                    charLeft={subtitleCharLeft}
                    name="subTitle"
                    type="text"
                    errorMsg={
                      formik.errors.subTitle && formik.touched.subTitle
                        ? formik.errors.subTitle
                        : ""
                    }
                    inputProps={{
                      maxLength: 50,
                    }}
                  />
                </Grid>
                <Grid item md={4} xs={12} className="uploadBtnStyle">
                  <CommonAutocomplete
                    handleSelectChange={(e, value) =>
                      formik.setFieldValue("selectFileType", value)
                    }
                    errorMsg={
                      formik.errors.selectFileType &&
                      formik.touched.selectFileType
                        ? formik.errors.selectFileType
                        : ""
                    }
                    handleBlur={formik.handleBlur}
                    name="selectFileType"
                    className="width100"
                    // handleSelectChange={handleSelectChange}
                    selectedValue={formik.values.selectFileType}
                    options={options}
                    style={style}
                    // handleBlurAutoComplete={handleBlurAutoComplete}
                    label={<CommonLabel label="Select file type" mandatory />}
                    // errorMsg={errorSelectFileType}
                  />
                </Grid>
                {formik.values.selectFileType.value === "Image" && (
                  <>
                    <Grid item md={4} xs={12} className="uploadBtnStyle">
                      <div className="uploadBtn">
                        <label for="thumb" className="btn uploadBtnLabel">
                          {thumbnail
                            ? thumbnail.name
                            : " Upload thumbnail image"}{" "}
                          <span className="colRed">*</span>
                        </label>
                        <img
                          for="thumb"
                          src={uploadIcon}
                          className="uploadIconStyl"
                          alt="Upload Button Icon"
                        />
                        <input
                          id="thumb"
                          style={{
                            visibility: "hidden",
                            padding: "19px 16px",
                            position: "absolute",
                          }}
                          type="file"
                          accept=".png, .jpg"
                          name="thumbnail"
                          onChange={(event) => {
                            formik.setFieldValue("video", "");
                            formik.setFieldValue("videoThumbnail", "");
                            formik.setFieldValue("videoUri", "");
                            // formik.setFieldValue("videoThumbnail", "");
                            // setCheckThumbnail(event.currentTarget.files[0]);
                            formik.setFieldValue(
                              "thumbnail",
                              event.currentTarget.files[0]
                            );
                          }}
                        />
                      </div>
                      <span style={{ color: "#f44336" }}>
                        {formik.errors.thumbnail && formik.touched.thumbnail
                          ? formik.errors.thumbnail
                          : ""}
                      </span>
                    </Grid>
                    <div className="compatiblityTxt">
                      Compatible formats: Image - JPG, PNG <br />
                      (Max size: 1010px * 560px)
                    </div>
                  </>
                )}

                {formik.values.selectFileType.value === "Youtube URL" && (
                  <Grid item md={4} xs={12} className="uploadBtnStyle">
                    <CharLeftField
                      label={
                        <CommonLabel
                          label="Enter youtube link only"
                          mandatory
                        />
                      }
                      handleChange={(e) => handlevideoUri(e, formik)}
                      handleBlur={formik.handleBlur}
                      value={videoUri}
                      name="videoUri"
                      type="text"
                      // errorMsg={
                      //   formik.errors.videoUri && formik.touched.videoUri
                      //     ? formik.errors.videoUri
                      //     : ""
                      // }
                    />
                  </Grid>
                )}
                {formik.values.selectFileType.value === "Video" && (
                  <>
                    <Grid item md={4} xs={12} className="uploadBtnStyle">
                      <div className="uploadBtn">
                        <label for="thumb" className="btn uploadBtnLabel">
                          {video ? video.name : " Upload video"}{" "}
                          <span className="colRed">*</span>
                        </label>
                        <img
                          for="thumb"
                          src={uploadIcon}
                          className="uploadIconStyl"
                          alt="Upload Button Icon"
                        />
                        <input
                          id="thumb"
                          style={{
                            visibility: "hidden",
                            padding: "19px 16px",
                            position: "absolute",
                          }}
                          type="file"
                          accept="video/mp4,video/x-m4v,video/*"
                          name="video"
                          onChange={(event) => {
                            formik.setFieldValue("videoUri", "");
                            formik.setFieldValue("thumbnail", "");
                            // setCheckThumbnail(event.currentTarget.files[0]);
                            formik.setFieldValue(
                              "video",
                              event.currentTarget.files[0]
                            );
                          }}
                        />
                      </div>
                    </Grid>
                    <Grid item md={4} xs={12} className="uploadBtnStyle">
                      <div className="uploadBtn">
                        <label
                          for="videoThumbnail"
                          className="btn uploadBtnLabel"
                        >
                          {videoThumbnail
                            ? videoThumbnail.name
                            : " Upload video thumbnail"}{" "}
                          <span className="colRed">*</span>
                        </label>
                        <img
                          for="videoThumbnail"
                          src={uploadIcon}
                          className="uploadIconStyl"
                          alt="Upload Button Icon"
                        />
                        <input
                          id="videoThumbnail"
                          style={{
                            visibility: "hidden",
                            padding: "19px 16px",
                            position: "absolute",
                          }}
                          type="file"
                          accept=".png, .jpg"
                          name="videoThumbnail"
                          onChange={(event) => {
                            formik.setFieldValue("videoUri", "");
                            formik.setFieldValue("thumbnail", "");
                            // setCheckThumbnail(event.currentTarget.files[0]);
                            formik.setFieldValue(
                              "videoThumbnail",
                              event.currentTarget.files[0]
                            );
                          }}
                        />
                      </div>
                    </Grid>
                  </>
                )}

                <Grid item md={12} xs={12}>
                  <div style={{ position: "relative" }}>
                    {console.log("Editor ===> ", formik.values.editorValue)}
                    <QuillEditor
                      handleChangeEditor={(e) =>
                        formik.setFieldValue("editorValue", e)
                      }
                      name="editorValue"
                      value={
                        formik.values.editorValue
                          ? formik.values.editorValue
                          : undefined
                      }
                    />
                  </div>
                </Grid>
                <Grid item md={4} xs={12} className="uploadBtnStyle">
                  <div className="uploadBtn">
                    <label for="files" className="btn uploadBtnLabel">
                      {docs ? docs.name : "Upload document"}{" "}
                      {/* <span className="colRed">*</span> */}
                    </label>
                    <img
                      src={uploadIcon}
                      className="uploadIconStyl"
                      alt="Upload Button Icon"
                    />
                    <input
                      id="files"
                      style={{
                        visibility: "hidden",
                        padding: "19px 16px",
                        position: "absolute",
                      }}
                      placeholder={formik.values.docs}
                      type="file"
                      accept="application/pdf"
                      name="docs"
                      onChange={(event) => {
                        // setCheckDocs(event.currentTarget.files[0]);
                        formik.setFieldValue(
                          "docs",
                          event.currentTarget.files[0]
                        );
                      }}
                    />
                  </div>
                </Grid>
                <div className="compatiblityTxt">
                  Compatible formats: PDF<br /> (Max size: 5MB)
                </div>
                {/* {formik.values?.docs !== "" && (
                  <Grid item sm={12} xs={12}>
                    <a
                      className="fileName"
                      href={`${process.env.REACT_APP_API_BASE_URL}/ui-assist/v1/download/true?fileName=${formik.values.docs}`}
                      target="_blank"
                    >
                      {formik.values.docs}
                    </a>
                  </Grid>
                )} */}
              </Grid>
            </div>

            <span className="additionalDetailsText commonFontNormalStyle">
              Additional details
            </span>
            <div className="additionalDetailsContainer">
              <Grid container spacing={2}>
                <Grid item md={4} xs={12}>
                  <CommonAutocomplete
                    className="width100"
                    handleSelectChange={(e, value) =>
                      formik.setFieldValue("selectType", value)
                    }
                    errorMsg={
                      formik.errors.selectType && formik.touched.selectType
                        ? formik.errors.selectType
                        : ""
                    }
                    value={formik.values.selectType}
                    handleBlur={formik.handleBlur}
                    name="selectType"
                    selectedValue={formik.values.selectType}
                    options={typeOption}
                    style={style}
                    label={<CommonLabel label="Select type" mandatory />}
                  />
                </Grid>

                <Grid item md={8} xs={12}
                className="righttextalign"
                >
                  <CharLeftField
                    label={<CommonLabel label="Hashtags" mandatory />}
                    validate
                    handleChange={formik.handleChange}
                    value={formik.values.hasTags}
                    handleBlur={formik.handleBlur}
                    errorMsg={
                      formik.errors.hasTags && formik.touched.hasTags
                        ? formik.errors.hasTags
                        : ""
                    }
                    wordCount={hastagsLeft}
                    name="hasTags"
                    type="text"
                  />
                </Grid>
              </Grid>
            </div>
            <div className="supportPart">
              <Grid
                container
                justifyContent="space-between"
                alignItems="center"
              >
                <span className="supportText commonFontNormalStyle">
                  For support and queries please reach out to &nbsp;
                  <span className="queriesEmail"> {process.env.REACT_APP_SUPPORT_EMAIL_ID}</span>
                </span>
                <div className="SubmitForApprovalButtonDiv d-flex">
                  {urlParam?.id && (
                    <div className="pt-2 me-4">
                      <img
                        src={DeleteIcon}
                        alt="icon"
                        height="24px"
                        width="24px"
                        className="modalTitleIcon1"
                        style={{ tintColor: "red" }}
                      />
                      <span
                        className="queriesEmail linkLabel"
                        onClick={() => onDeleteTapped()}
                      >
                        Delete Article
                      </span>
                    </div>
                  )}

                  <SubmitButton
                    // disabled={
                    //   !(formik.isValid && formik.dirty) ||
                    //   validSlectType(formik)
                    // }
                    label="Submit"
                    type="submit"
                    variant="contained"
                    className={`${
                      !(formik.isValid && formik.dirty) ||
                      validSlectType(formik)
                        ? "Submit"
                        : "enabledButton"
                    } commonFontNormalStyle`}
                    size="large"
                    onClick={formik.handleSubmit}
                  />
                </div>
              </Grid>
            </div>
          </div>
        </Paper>
      </div>
      <CommonModal
        open={submitForPreview}
        handleClose={handleCloseModal}
        headerText="Preview"
        icon={previewArticle}
        modalSize="lg"
        submitBtnText="SAVE CHANGES"
        fullWidth
        onButtonClick={() => setSubmitForPreview(false)}
      >
        <PreviewArticleComponent previewData={formik} udpatedAt={updatedAt} />
      </CommonModal>
    </Template>
  );
}
