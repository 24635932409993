import React from "react";
import { Grid } from "@material-ui/core";
import ContactComponent from "./ContactComponent";
import TimelineComponent from "./TimelineComponent";

const CreditCard = ({data}) => {
  return (
    <>
      <Grid
        container
        direction="row"
        justifyContent="flex-start"
        alignItems="center"
        spacing={2}
        className="alignCards Econtent"
      >
        <>
          <Grid item xs={12} sm={12} className="mb-5">
            <Grid container direction="row" className="border2 py-2 ">
              <Grid item xs={2} sm={2} md={2}>
                <div className="TableContent">Entitlement</div>
              </Grid>
              <Grid item xs={8} sm={8} md={8}>
                <div className="TableContent fontboldnew">
                  {data?.values?.availedLimit} Lacs
                </div>
              </Grid>
            </Grid>

            <ContactComponent data={data?.contactPerson} title="Contact" />

            <TimelineComponent
              data={data?.timelines}
              title="Expected Availability Timelines"
              url={data?.applyURl}
            />

            <ContactComponent data={data?.escalationSPOC} title="Escalation" />

          </Grid>
        </>
      </Grid>
    </>
  );
};

export default CreditCard;
