import React, { useEffect, useState } from "react";
import "./ForexCenter.css";
import Template from "../../Common/Template/Template";
import DatePicker from "../../Common/DatePicker/DatePicker";
import moment from "moment";
import {
  Grid,
  Tabs,
  Tab,
  Typography,
  Box,
  Paper,
  Button,
} from "@material-ui/core";
import PropTypes from "prop-types";
import CommonLabel from "../../Common/ComonLabel/CommonLabel";
import services from "../../../Services";
import Swal from "sweetalert2";
import SubmitButton from "../../Common/Button/SubmitButton";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

export default function ForexCenterComponent() {
  const [forexToday, setForexToday] = useState([]);
  const [currentDate, setCurrentDate] = useState("");

  const [forexLastyear, setforexLastyear] = useState([]);
  const [forexLastyearDate, setForexLastyearDate] = useState([]);

  const [fromDate, setFromDate] = useState([]);
  const [toDate, setToDate] = useState([]);
  const [dateFilterData, setDateFilterData] = useState([]);

  const [value, setValue] = React.useState(0);

  const [loading, setLoading] = useState(false);
  const [refresh, setrefresh] = useState(false);

  const handleChange = (event, newValue) => {
    //tabs change
    setValue(newValue);
  };
  const handleFromDateChange = (date) => {
    setFromDate(date);
    setToDate(date);
  };
  const handleToDateChange = (date) => {
    setToDate(date);
  };

  useEffect(() => {
    setFromDate(new Date());
    setToDate(new Date());
    getData();
  }, []);

  const getData = async () => {
    setDateFilterData([]);
    // console.log('get');
    var fromDateSelected = moment(fromDate).format("yyyy-MM-DD");
    var fromToSelected = moment(toDate).format("yyyy-MM-DD");

    //service start for date filter
    try {
      setLoading(true);
      const data = await services.api(
        "GET",
        `/ui-assist/v1/forex?from=${fromDateSelected}&to=${fromToSelected}`
      );
      if (data.status === 200) {
        setLoading(false);

        var serverData = data.data;
        //console.log()
        const resultArray = {};
        var i = 0;
        serverData.forEach((index) => {
          if (resultArray[index.valid_from]) {
            i++;
            resultArray[index.valid_from][i] = index;
          } else {
            i = 0;
            resultArray[index.valid_from] = {
              0: index,
            };
          }
        });
        // console.log(resultArray);

        // setDateFilterData(data.data)
        setDateFilterData(resultArray);
      } else {
        setLoading(false);
        console.log("No data");
      }
    } catch (error) {
      setLoading(false);
      // Swal.fire("Oops", `${error.response.data}`, "error");
    }

    //service end
  };

  useEffect(() => {
    (async () => {
      try {
        setLoading(true);
        const res = await services.api("GET", `/ui-assist/v1/forex`);
        if (res.status === 200) {
          setLoading(false);
          setForexToday(res.data);

          if (res?.data) {
            setCurrentDate(res.data[0].valid_from); //set todays date
          }
        }
      } catch (err) {
        // setLoading(false);
        // Swal.fire("Oops!", `${err?.response?.data}`, "error");
        // setForexToday([]);
      }
    })();
  }, [refresh]);

  useEffect(() => {
    (async () => {
      try {
        setLoading(true);
        const res = await services.api("GET", `/ui-assist/v1/forex/lastYear`);
        if (res.status === 200) {
          setLoading(false);
          setforexLastyear(res.data);
          //   console.log(res.data);
          //   console.log('ggg')
          if (res?.data) {
            setForexLastyearDate(res.data[0].valid_from); //set todays date
          }
        }
      } catch (err) {
        // setLoading(false);
        // Swal.fire("Oops!", `${err?.response?.data}`, "error");
        // setforexLastyear([]);
      }
    })();
  }, [refresh]);

  return (
    <div>
      <Template>
        <Grid
          container
          direction="row"
          justifyContent="space-between"
          alignItems="center"
        >
          <Grid item xs={12} sm={12}>
            <p className="offCHeaderTitle">Forex Rates</p>
          </Grid>
        </Grid>
        <Box sx={{ width: "100%" }}>
          <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
            <Tabs
              value={value}
              onChange={handleChange}
              aria-label="basic tabs example"
              className="rollBasedTab"
            >
              <Tab label="Current Rates" />
              <Tab label="Past Rates" />
            </Tabs>
            <hr className="deviderP" />
          </Box>
          <TabPanel value={value} index={0} className="rollBasedTabPanel">
            <Paper>
              <>
                <Grid
                  container
                  direction="row"
                  alignItems="center"
                  spacing={2}
                  className="rollBasedPaper"
                >
                  <Grid
                    container
                    direction="row"
                    alignItems="center"
                    spacing={2}
                    className="rollBasedPaper"
                  >
                    <Grid item xs={12} sm={6}>
                      {" "}
                      {/*Mar 11,2022 */}
                      <div className="rollBasedTitle">
                        Latest rate {currentDate}
                      </div>
                      <Grid
                        container
                        direction="row"
                        className="border2 py-2 tableheader"
                      >
                        <Grid item xs={3} sm={3} md={3}>
                          <div className="TableContent">Currency</div>
                        </Grid>
                        <Grid item xs={3} sm={3} md={3}>
                          <div className="TableContent">TT Buy / Bid</div>
                        </Grid>
                        <Grid item xs={3} sm={3} md={3}>
                          <div className="TableContent">TT Sell/ Ask</div>
                        </Grid>
                        <Grid item xs={3} sm={3} md={3}>
                          <div className="TableContent">Mid Rate</div>
                        </Grid>
                      </Grid>
                      <div className="tablescroll">
                        {forexToday?.map((result) => (
                          <Grid
                            container
                            direction="row"
                            className="border2 py-2 "
                          >
                            <Grid item xs={3} sm={3} md={3}>
                              <div className="TableContent">
                                {result.base_currency} / {result.other_currency}
                              </div>
                            </Grid>
                            <Grid item xs={3} sm={3} md={3}>
                              <div className="TableContent">
                                {Math.round(result.bid_price * 10000) / 10000}
                              </div>
                            </Grid>
                            <Grid item xs={3} sm={3} md={3}>
                              <div className="TableContent">
                                {Math.round(result.ask_price * 10000) / 10000}
                              </div>
                            </Grid>
                            <Grid item xs={3} sm={3} md={3}>
                              <div className="TableContent">
                                {Math.round(result.exchange_rate * 10000) /
                                  10000}
                              </div>
                            </Grid>
                          </Grid>
                        ))}

                        {forexToday && forexToday.length == 0 && (
                          <>
                            <Grid
                              container
                              direction="row"
                              className="border2 py-2 norecordfound"
                            >
                              <Grid item xs={12} sm={12} md={12}>
                                <div className="TableContent">
                                  No data found{" "}
                                </div>
                              </Grid>
                            </Grid>
                          </>
                        )}
                      </div>
                    </Grid>

                    <Grid item xs={12} sm={6}>
                      {" "}
                      {/*Mar 11,2022 */}
                      <div className="rollBasedTitle">
                        Rate as on {forexLastyearDate}
                      </div>
                      <Grid
                        container
                        direction="row"
                        className="border2 py-2 tableheader"
                      >
                        <Grid item xs={3} sm={3} md={3}>
                          <div className="TableContent">Currency</div>
                        </Grid>
                        <Grid item xs={3} sm={3} md={3}>
                          <div className="TableContent">TT Buy / Bid</div>
                        </Grid>
                        <Grid item xs={3} sm={3} md={3}>
                          <div className="TableContent">TT Sell/ Ask</div>
                        </Grid>
                        <Grid item xs={3} sm={3} md={3}>
                          <div className="TableContent">Mid Rate</div>
                        </Grid>
                      </Grid>
                      <div className="tablescroll">
                        {forexLastyear?.map((result) => (
                          <Grid
                            container
                            direction="row"
                            className="border2 py-2"
                          >
                            <Grid item xs={3} sm={3} md={3}>
                              <div className="TableContent">
                                {result.base_currency} / {result.other_currency}
                              </div>
                            </Grid>
                            <Grid item xs={3} sm={3} md={3}>
                              <div className="TableContent">
                                {Math.round(result.bid_price * 10000) / 10000}
                              </div>
                            </Grid>
                            <Grid item xs={3} sm={3} md={3}>
                              <div className="TableContent">
                                {Math.round(result.ask_price * 10000) / 10000}
                              </div>
                            </Grid>
                            <Grid item xs={3} sm={3} md={3}>
                              <div className="TableContent">
                                {Math.round(result.exchange_rate * 10000) /
                                  10000}
                              </div>
                            </Grid>
                          </Grid>
                        ))}

                        {forexLastyear && forexLastyear.length == 0 && (
                          <>
                            <Grid
                              container
                              direction="row"
                              className="border2 py-2 norecordfound"
                            >
                              <Grid item xs={12} sm={12} md={12}>
                                <div className="TableContent">
                                  No data found{" "}
                                </div>
                              </Grid>
                            </Grid>
                          </>
                        )}
                      </div>
                    </Grid>

                    <div className="notepanel">
                      <ul>
                        <li>
                          Please Note : Forex rates updated everyday at 11.00 am
                          from Monday to Friday.
                        </li>
                        <li>* Mid Rate available from 17/05/2018 onwords.</li>
                        <li>
                          SBI all 4 rates are no longer available on mconnect
                        </li>
                      </ul>
                    </div>
                  </Grid>
                </Grid>
              </>
            </Paper>
            <div className="rollBasedTablePagination">
              <div className="paginationAlignFlex">
                {/* <div className="paginationNumbers">
                  <img
                    src={
                      process.env.PUBLIC_URL +
                      "/Images/universalSearch_Images/left-box-arrow.png"
                    }
                    alt="arrow image"
                    className="arrowPos"
                  />
                </div>
               <div className="paginationNumbers">1</div>
                <div className="paginationNumbers">2</div>
                <div className="paginationNumbers">...</div>
                <div className="paginationNumbers">5</div>
                  <div className="paginationNumbers"> 
                  <img
                    src={
                      process.env.PUBLIC_URL +
                      "/Images/universalSearch_Images/right-box-arrow.svg"
                    }
                    alt="arrow image"
                    className=""
                  />
                </div> */}
                {/* <Stack spacing={2}>
                  <Pagination
                    count={accessGroupPages}
                    variant="outlined"
                    shape="rounded"
                    onChange={handleAccessPaginationChange}
                  />
                </Stack> */}
              </div>
            </div>
          </TabPanel>
          <TabPanel value={value} index={1} className="rollBasedTabPanel">
            <Paper>
              <Grid
                container
                direction="row"
                alignItems="center"
                spacing={2}
                className="rollBasedPaper"
              >
                <Grid item xs={12} sm={5}>
                  <div className="rollBasedTitle">Past Rates</div>
                </Grid>
                <Grid item xs={12} sm={7}>
                  <Grid
                    container
                    direction="row"
                    alignItems="center"
                    spacing={2}
                    className="Forexdatepicker"
                  >
                    <Grid item sm={4} xs={12}>
                      <DatePicker
                        label={<CommonLabel label="From date" />}
                        id="fromdate"
                        name="fromDate"
                        selectedDate={fromDate}
                        selectsStart
                        startDate={fromDate}
                        endDate={toDate}
                        handleDateChange={(date) => handleFromDateChange(date)}
                      />
                    </Grid>
                    <Grid item md={4} xs={12}>
                      <DatePicker
                        label={<CommonLabel label="To date" />}
                        id="todate"
                        name="toDate"
                        selectsEnd
                        selectedDate={toDate}
                        startDate={fromDate}
                        endDate={toDate}
                        minDate={fromDate}
                        handleDateChange={(date) => handleToDateChange(date)}
                      />
                    </Grid>

                    <Grid item sm={4} xs={12}>
                      <SubmitButton
                        label="Filter"
                        className="MCsubmitbutton"
                        onClick={() => {
                          getData();
                        }}
                      />
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>

              <Grid
                container
                direction="row"
                alignItems="center"
                spacing={2}
                className="rollBasedPaper"
              >
                <Grid item xs={12} sm={12}>
                  {" "}
                  {/*Mar 11,2022 */}
                  <Grid
                    container
                    direction="row"
                    className="border2 py-2 tableheader"
                  >
                    <Grid item xs={3} sm={3} md={3}>
                      <div className="TableContent">Currency</div>
                    </Grid>
                    <Grid item xs={3} sm={3} md={3}>
                      <div className="TableContent">TT Buy / Bid</div>
                    </Grid>
                    <Grid item xs={3} sm={3} md={3}>
                      <div className="TableContent">TT Sell/ Ask</div>
                    </Grid>
                    <Grid item xs={3} sm={3} md={3}>
                      <div className="TableContent">Mid Rate</div>
                    </Grid>
                  </Grid>
                  <div className="tablescroll">
                    {Object.entries(dateFilterData).map(([k, v]) => (
                      <div key={k}>
                        <div className="rollBasedTable">
                          <div className="TableContent weekdata">
                            Week {moment(k, "YYYY-MM-DD").isoWeek()} |{" "}
                            {moment(k).format("MMM DD,YYYY")}
                          </div>
                        </div>
                        {Object.entries(v).map(([k1, result]) => (
                          <div key={k1}>
                            <Grid
                              container
                              direction="row"
                              className="border2 py-2"
                            >
                              <Grid item xs={3} sm={3} md={3}>
                                <div className="TableContent">
                                  {result.base_currency} /{" "}
                                  {result.other_currency}
                                </div>
                              </Grid>
                              <Grid item xs={3} sm={3} md={3}>
                                <div className="TableContent">
                                  {Math.round(result.bid_price * 10000) / 10000}
                                </div>
                              </Grid>
                              <Grid item xs={3} sm={3} md={3}>
                                <div className="TableContent">
                                  {Math.round(result.ask_price * 10000) / 10000}
                                </div>
                              </Grid>
                              <Grid item xs={3} sm={3} md={3}>
                                <div className="TableContent">
                                  {Math.round(result.exchange_rate * 10000) /
                                    10000}
                                </div>
                              </Grid>
                            </Grid>
                          </div>
                        ))}
                      </div>
                    ))}

                    {dateFilterData && dateFilterData.length == 0 && (
                      <>
                        <Grid
                          container
                          direction="row"
                          className="border2 py-2 norecordfound"
                        >
                          <Grid item xs={12} sm={12} md={12}>
                            <div className="TableContent">No data found </div>
                          </Grid>
                        </Grid>
                      </>
                    )}
                  </div>
                </Grid>
                <div className="notepanel">
                  <ul>
                    <li>
                      Please Note : Forex rates updated everyday at 11.00 am
                      from Monday to Friday.
                    </li>
                    <li>* Mid Rate available from 17/05/2018 onwords.</li>
                    <li>SBI all 4 rates are no longer available on mconnect</li>
                  </ul>
                </div>
              </Grid>
            </Paper>
            <div className="rollBasedTablePagination">
              <diva className="paginationAlignFlex">
                {/* <div className="paginationNumbers">
                  <img
                    src={
                      process.env.PUBLIC_URL +
                      "/Images/universalSearch_Images/left-box-arrow.png"
                    }
                    alt="arrow image"
                    className="arrowPos"
                  />
                </div>
                <div className="paginationNumbers">1</div>
                <div className="paginationNumbers">2</div>
                <div className="paginationNumbers">.....</div>
                <div className="paginationNumbers">5</div>
                <div className="paginationNumbers">
                  <img
                    src={
                      process.env.PUBLIC_URL +
                      "/Images/universalSearch_Images/right-box-arrow.svg"
                    }
                    alt="arrow image"
                    className=""
                  />
                </div>
                  </div> */}
              </diva>
            </div>
          </TabPanel>
        </Box>

        {/* <CommonModal
          open={openModalNewUserGroup}
          handleClose={handleCloseModal}
          headerText="Create new usergroup"
          icon={addIcon}
          modalSize="md"
          footerBannerCss
          fullWidth
          policiesButtonSize
          cancleBtnText="Cancel"
          onCancleButtonClick={handleCloseModal}
          setSelectedUserGroup={setSelectedUserGroup}
          SelectedSubMenuItemData={SelectedSubMenuItemData}
        >
          <AddNewUserGroupComponent
            updateUserGroupData={updateUserGroupData}
            setupdateUserGroupData={setupdateUserGroupData}
          />
       </CommonModal> */}
      </Template>
    </div>
  );
}
