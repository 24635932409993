import {
  GET_POLICY_CATEGORY_DATA_SUCCESS,
  GET_POLICY_CATEGORY_DATA_LOAD,
  GET_POLICY_CATEGORY_DATA_FAILED,
  GET_POLICY_SUBCATEGORY_DATA_SUCCESS,
  GET_POLICY_SUBCATEGORY_DATA_LOAD,
  GET_POLICY_SUBCATEGORY_DATA_FAILED,
  GET_POLICY_ADMIN_SUBCATEGORY_DATA_SUCCESS,
  GET_POLICY_ADMIN_SUBCATEGORY_DATA_LOAD,
  GET_POLICY_ADMIN_SUBCATEGORY_DATA_FAILED,
  GET_POLICY_TABLE_DATA_SUCCESS,
  GET_POLICY_TABLE_DATA_LOAD,
  GET_POLICY_TABLE_DATA_FAILED,
  GET_POLICY_CATEGORY_ID_DATA_FAILED,
  GET_POLICY_CATEGORY_ID_DATA_SUCCESS,
  GET_POLICY_CATEGORY_ID_DATA_LOAD,
} from "../Contants/Constants";

const initialState = {
  getData: [],
  isLoading: false,
  isError: "",
};
const initialStateSubCategory = {
  getData: [],
  isLoading: false,
  isError: "",
};
const initialStateTableData = {
  getData: [],
  isLoading: false,
  isError: "",
};
const initialStateCategoryIdData = {
  getData: [],
  isLoading: false,
  isError: "",
};

const getPolicyCategoryDataReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_POLICY_CATEGORY_DATA_LOAD:
      return {
        ...state,
        isLoading: true,
      };
    case GET_POLICY_CATEGORY_DATA_SUCCESS:
      return {
        ...state,
        getData: action.payload,
        isLoading: false,
      };
    case GET_POLICY_CATEGORY_DATA_FAILED:
      return {
        ...state,
        isError: action.payload,
        isLoading: false,
      };
    default:
      return state;
  }
};

const getPolicySubCategoryDataReducer = (
  state = initialStateSubCategory,
  action
) => {
  switch (action.type) {
    case GET_POLICY_SUBCATEGORY_DATA_LOAD:
      return {
        ...state,
        isLoading: true,
      };
    case GET_POLICY_SUBCATEGORY_DATA_SUCCESS:
      return {
        ...state,
        getData: action.payload,
        isLoading: false,
      };
    case GET_POLICY_SUBCATEGORY_DATA_FAILED:
      return {
        ...state,
        isError: action.payload,
        isLoading: false,
      };
    default:
      return state;
  }
};

const getPolicyAdminSubCategoryDataReducer = (
  state = initialStateSubCategory,
  action
) => {
  switch (action.type) {
    case GET_POLICY_ADMIN_SUBCATEGORY_DATA_LOAD:
      return {
        ...state,
        isLoading: true,
      };
    case GET_POLICY_ADMIN_SUBCATEGORY_DATA_SUCCESS:
      return {
        ...state,
        getData: action.payload,
        isLoading: false,
      };
    case GET_POLICY_ADMIN_SUBCATEGORY_DATA_FAILED:
      return {
        ...state,
        isError: action.payload,
        isLoading: false,
      };
    default:
      return state;
  }
};

const getPolicyTableDataReducer = (state = initialStateTableData, action) => {
  switch (action.type) {
    case GET_POLICY_TABLE_DATA_LOAD:
      return {
        ...state,
        isLoading: true,
      };
    case GET_POLICY_TABLE_DATA_SUCCESS:
      return {
        ...state,
        getData: action.payload,
        isLoading: false,
      };
    case GET_POLICY_TABLE_DATA_FAILED:
      return {
        ...state,
        isError: action.payload,
        isLoading: false,
      };
    default:
      return state;
  }
};

const getPolicyCategoryIdDataReducer = (
  state = initialStateCategoryIdData,
  action
) => {
  console.log("inside this reducer");
  switch (action.type) {
    case GET_POLICY_CATEGORY_ID_DATA_LOAD:
      return {
        ...state,
        isLoading: true,
      };
    case GET_POLICY_CATEGORY_ID_DATA_SUCCESS:
      return {
        ...state,
        getData: action.payload,
        isLoading: false,
      };
    case GET_POLICY_CATEGORY_ID_DATA_FAILED:
      return {
        ...state,
        isError: action.payload,
        isLoading: false,
      };
    default:
      return state;
  }
};

export {
  getPolicySubCategoryDataReducer,
  getPolicyAdminSubCategoryDataReducer,
  getPolicyCategoryDataReducer,
  getPolicyTableDataReducer,
  getPolicyCategoryIdDataReducer,
};
