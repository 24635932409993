import React, { useState, useEffect } from "react";
import "./ITSupportAdminComponent.css";
import moment from "moment";
import SubmitButton from "../../../../Common/Button/SubmitButton";
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { Grid } from "@material-ui/core";
import CharLeftField from "../../../../Common/CharLeftField/CharLeftField";
import CommonLabel from "../../../../Common/ComonLabel/CommonLabel";
import Loader from "../../../../Common/Loader/Loader";
import PLUS_ICON from "../../../../../Assets/Images/Plus.svg";
import BIN_ICON from "../../../../../Assets/Images/delete-bin-line.svg";
import COMP_LINE from "../../../../../Assets/Images/collectiveIcons/computer-line.svg";
import CONTACT_FILL from "../../../../../Assets/Images/collectiveIcons/contacts-fill.svg";
import CONTACT_BOOK from "../../../../../Assets/Images/collectiveIcons/contacts-book-2-line.svg";
import COUPON_LINE from "../../../../../Assets/Images/collectiveIcons/coupon-2-line.svg";

import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import PropTypes from "prop-types";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import SearchBarButton from "../../../../Common/SearchBar/SearchBarButton";
import ITSupportAdminForm from "./ITSupportAdminForm";
import services from "../../../../../Services/index";
import MultiSelect from "../../../../Common/MultiSelect/MultiSelect";
import {
  emailRegex,
  phoneRegExp,
  nameRegex,
} from "../../../../../Assets/helperFunctions/helperFunctions";
import CommonAutocomplete from "../../../../Common/CommonAutocomplete/CommonAutocomplete";
import Swal from "sweetalert2";
import CustomMultiSelect from "../../../../Common/CustomMultiSelect/CustomMultiSelect";
import ModifiedSection from "../../../../DashBoard/WhatsNew/ModifiedSection/ModifiedSection";

import { useSelector, useDispatch } from "react-redux";
import { getLastModifiedData } from "../../../../../Redux/Actions/CommonActions";

// const phoneRegExp = new RegExp(
//   /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/im
// );
const ItSupportData = [
  { location: "MITC", details: [{ title: "service1" }, { title: "service2" }] },
  { location: "Sewri", details: [{ title: "service1" }] },
  {
    location: "Kandivali",
    details: [
      { title: "service1" },
      { title: "service2" },
      { title: "service3" },
    ],
  },
  // { title: "service1" },
  // { title: "service2" },
  // { title: "service2" },
  // { title: "service2" },
];

const iconOpt = [
  { icon: COMP_LINE, name: "Computer - line" },
  { icon: CONTACT_FILL, name: "Contact - book" },
  { icon: CONTACT_BOOK, name: "Contact - fill" },
  { icon: COUPON_LINE, name: "Coupon - line" },
  { icon: COMP_LINE, name: "Computer - line" },
];

export default function ITSupportAdminComponent({
  categoryType,
  categoryWiseData,
  getServices,
  handleClose,
}) {
  let dispatch = useDispatch();
  const handleValid = (formErrors) => {
    let valid = false;
    Object.values(formErrors).forEach((val) => {
      if (Array.isArray(val)) {
        val.map((result) => {
          if (
            typeof result === "object" &&
            !Array.isArray(result) &&
            result !== null
          ) {
            Object.values(result).forEach((getValue) => {
              if (getValue.length) valid = true;
            });
          } else {
            if (result !== "") {
              valid = true;
            }
          }
        });
      } else {
        if (val.length) {
          valid = true;
        }
      }
    });
    return valid;
  };
  const handleValidEdit = (formErrors) => {
    let valid = false;
    Object.values(formErrors).forEach((val) => {
      if (Array.isArray(val)) {
        val.map((result) => {
          if (
            typeof result === "object" &&
            !Array.isArray(result) &&
            result !== null
          ) {
            Object.values(result).forEach((getValue) => {
              if (getValue.length) valid = true;
            });
          } else {
            if (result !== "") {
              valid = true;
            }
          }
        });
      } else {
        if (val.length) {
          valid = true;
        }
      }
    });
    return valid;
  };

  const getMandatoryFields = (values) => {
    const {
      title,
      description,
      sectorList,
      buList,
      actionUrl,
      actionName,
      emails,
      websites,
      phoneNumbers,
      category,
      image,
      icon,
    } = values;
    let checkEmails = emails.filter((result, index) => result === "");
    let checkWebsites = websites.filter((result, index) => result === "");
    let validPhoneData = true;
    // phoneNumbers.map((result) => {
    //   Object.values(result).forEach((data) => {
    //     if (data === "") {
    //       validPhoneData = false;
    //     } else {
    //       validPhoneData = true;
    //     }
    //   });
    // });

    if (
      title &&
      description &&
      sectorList.length > 0 &&
      category &&
      buList.length > 0 &&
      ///actionName &&
      //actionUrl &&
      validPhoneData &&
      checkEmails.length === 0 &&
      checkWebsites.length === 0 &&
      (image || icon)
    ) {
      return false;
    } else {
      return true;
    }
  };

  const getMandatoryFieldsEdit = (values) => {
    const {
      title,
      description,
      sectorList,
      buList,
      actionUrl,
      actionName,
      emails,
      websites,
      phoneNumbers,
      category,
      image,
      icon,
    } = values;
    let checkEmails = emails.filter((result, index) => result === "");
    let checkWebsites = websites.filter((result, index) => result === "");
    let validPhoneData = true;
    phoneNumbers.map((result) => {
      Object.values(result).forEach((data) => {
        if (data === "") {
          validPhoneData = false;
        } else {
          validPhoneData = true;
        }
      });
    });

    if (
      title &&
      description &&
      sectorList.length > 0 &&
      category &&
      buList.length > 0 &&
      actionName &&
      actionUrl &&
      validPhoneData &&
      checkEmails.length === 0 &&
      checkWebsites.length === 0 &&
      (image || icon)
    ) {
      return false;
    } else {
      return true;
    }
  };

  const [loading, setLoading] = useState(false);
  const [getSectorList, setGetSectorList] = useState([]);
  const [getDivisionList, setGetDivisionList] = useState([]);
  const [getExsitingData, setExsitingData] = useState("");
  const [getDivisionListEdit, setGetDivisionListEdit] = useState([]);
  function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box p={3}>
            <Typography>{children}</Typography>
          </Box>
        )}
      </div>
    );
  }
  TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
  };
  const [tab, setTab] = React.useState(0);
  const [tab2, setTab2] = React.useState(0);

  const handleChangeTab = (event, newValue, uniqueIndex, edit) => {
    if (edit === "edit") {
      setTab2(newValue);
    } else {
      setTab(newValue);
    }
  };

  const [hideModelFlag, setHideModelFlag] = React.useState(false);
  const [hideModelFlag2, setHideModelFlag2] = React.useState(false);

  // const [modifiedDate, setModifiedDate] = React.useState(new Date());
  const [hideAddServices, setHideAddServices] = useState(true);
  const [categoryList, setCategoryList] = useState([]);
  const [detailsActionAdd, setDetailsActionAdd] = useState({
    title: "",
    description: "",
    actionName: "",
    actionUrl: "",
    image: null,
    category: "",
    sectorList: [],
    buList: [],
    phoneNumbers: [],
    emails: [],
    websites: [],
    errorObj: {
      title: "",
      description: "",
      actionName: "",
      actionUrl: "",
      emails: [],
      websites: [],
      phoneNumbers: [],
    },
  });
  const [detailsAction, setDetailsAction] = useState([]);

  const handleSubmit = async (values, key) => {
    if (key === "edit") {
      const {
        title,
        description,
        actionName,
        actionUrl,
        phoneNumbers,
        emails,
        websites,
        image,
        category,
        sectorList,
        buList,
        id,
        icon,
      } = values;
      let getAllIdBu = buList.map((result) => {
        return {
          buId: result.buId,
          buName: result.title ? result.title : result.buName,
        };
      });

      const getAllIdSector = sectorList.map((result) => {
        return {
          sectorId: result.sectorCode ? result.sectorCode : result.sectorId,
          sectorName: result.title ? result.title : result.sectorName,
        };
      });

      let formData = new FormData();
      formData.append("title", title);
      formData.append("buList", JSON.stringify(getAllIdBu));
      formData.append("sectorList", JSON.stringify(getAllIdSector));
      formData.append("image", image);
      formData.append("category", category.value ? category.value : category);
      formData.append("description", description);
      formData.append("phoneNumbers", JSON.stringify(phoneNumbers));
      formData.append("emails", emails.toString().split(","));
      formData.append("websites", websites.toString().split(","));
      formData.append("actionName", actionName);
      formData.append("actionUrl", actionUrl);
      if (icon) {
        formData.append("icon", icon);
      }

      formData.append("employee", localStorage.getItem("token"));
      try {
        setLoading(true);
        let res = await services.api(
          "PUT",
          `/ui-assist/v1/supportservice/${id}`,
          formData
        );
        if (res.status === 200) {
          Swal.fire(
            "Successfully updated!",
            `Support service updated successfully`,
            "success"
          );
          getServices();
          setLoading(false);
          handleClose();
        }
      } catch (err) {
        setLoading(false);
        console.log("err from support services put", err);
      }
    } else {
      const {
        title,
        description,
        actionName,
        actionUrl,
        phoneNumbers,
        emails,
        websites,
        image,
        category,
        sectorList,
        buList,
      } = detailsActionAdd;

      let getAllIdBu = buList.map((result) => {
        return { buId: result.id, buName: result.title };
      });

      const getAllIdSector = sectorList.map((result) => {
        return { sectorId: result.sectorCode, sectorName: result.title };
      });

      let formData = new FormData();
      formData.append("category", category.value);
      formData.append("title", title);
      formData.append("image", image);
      formData.append("description", description);
      formData.append("phoneNumbers", JSON.stringify(phoneNumbers));
      formData.append("emails", emails.toString().split(","));
      formData.append("websites", websites.toString().split(","));
      formData.append("actionName", actionName);
      formData.append("actionUrl", actionUrl);
      formData.append("sectorList", JSON.stringify(getAllIdSector));
      formData.append("buList", JSON.stringify(getAllIdBu));

      formData.append("icon", icon);
      formData.append("employee", localStorage.getItem("token"));
      try {
        setLoading(true);
        let res = await services.api(
          "POST",
          `/ui-assist/v1/supportservice`,
          formData
        );
        if (res.status === 200) {
          Swal.fire(
            "Successfully added!",
            `Support service created successfully`,
            "success"
          );
          setDetailsActionAdd({
            title: "",
            description: "",
            actionName: "",
            actionUrl: "",
            image: null,
            category: "",
            sectorList: [],
            buList: [],
            icon: null,
            phoneNumbers: [],
            emails: [],
            websites: [],
            errorObj: {
              title: "",
              description: "",
              actionName: "",
              actionUrl: "",
              emails: [],
              websites: [],
              phoneNumbers: [],
            },
          });
          getServices();
          setLoading(false);
          handleClose();
        }
      } catch (err) {
        setLoading(false);
        console.log("err from support services", err);
      }
    }
  };

  const getSupportServices = () => {
    let values = [...categoryWiseData];
    values.map((result, index) => {
      let phoneData = [];
      let emailData = [];
      let websiteData = [];
      result.phoneNumbers.map(() => {
        phoneData.push({
          name: "",
          phoneNumber: "",
          extension: "",
        });
      });
      result.emails.map(() => {
        emailData.push("");
      });
      result.websites.map(() => {
        websiteData.push("");
      });

      return (result.errorObj = {
        title: "",
        description: "",
        actionName: "",
        actionUrl: "",
        emails: emailData,
        websites: websiteData,
        phoneNumbers: phoneData,
      });
    });
    setDetailsAction(values);
  };

  const getCategory = async () => {
    try {
      setLoading(true);
      let res = await services.api("GET", `/ui-assist/v1/categorys`);
      if (res.status === 200) {
        setLoading(false);
        let list = res.data.map((result) => {
          return { title: result.name, value: result.name, id: result.id };
        });
        setCategoryList(list);
      }
    } catch (err) {
      setLoading(false);
      console.log("get error from category:", err);
    }
  };

  const handleDeleteServices = async (values) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You want to delete this service?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Yes, delete it!",
      cancelButtonText: "No, keep it",
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          setLoading(true);
          let res = await services.api(
            "DELETE",
            `/ui-assist/v1/supportservice/${values.id}`
          );
          if (res.status === 200) {
            Swal.fire(
              "Successfully deleted!",
              `Support service deleted successfully`,
              "success"
            );
            setLoading(false);
            getServices();
            handleClose();
            // getSupportServices();
          }
        } catch (err) {
          setLoading(false);
          console.log("error for delete services:", err);
        }
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire("Cancelled", "Your srvice is safe :)", "error");
      }
    });
  };

  const lastModified = useSelector((state) => {
    return state?.getLastModifiedDataReducer
      ?.lastModifiedSupportCategoryDetails;
  });

  useEffect(() => {
    dispatch(
      getLastModifiedData(
        "supportservice",
        categoryType.toLowerCase().replace(/\s/g, "")
      )
    );
  }, []);

  useEffect(async () => {
    getSupportServices();

    getCategory();
    let userProfile = JSON.parse(
      localStorage.getItem("userProfile")
        ? localStorage.getItem("userProfile")
        : null
    );
    setExsitingData(userProfile);
    try {
      setLoading(true);
      let res = await services.api(
        "GET",
        `/ui-assist/v1/sectors?compcode=${userProfile.company}`
      );
      if (res.status === 200) {
        setLoading(false);
        let list = res.data.map((result) => {
          return {
            title: result.sectorName,
            value: result.sectorName,
            sectorCode: result.sectorCode,
          };
        });
        setGetSectorList(list);
      }
    } catch (err) {
      setLoading(false);
      // Swal.fire("Oops!", `${err.response.data}`, "error");
      setGetSectorList([]);
    }
  }, []);

  const handleAddPhoneData = (e, uniqueIndex, key) => {
    let values = [...detailsAction];
    if (key === "phoneNumbers") {
      values[uniqueIndex][key].push({
        name: "",
        phoneNumber: "",
        extension: "",
      });
      values[uniqueIndex].errorObj[key].push({
        name: "",
        phoneNumber: "",
        extension: "",
      });
      setDetailsAction(values);
    } else {
      values[uniqueIndex][key].push("");
      values[uniqueIndex].errorObj[key].push("");
      setDetailsAction(values);
    }
  };

  const handleDeletePhoneData = (index, uniqueIndex, key) => {
    let values = [...detailsAction];
    if (key) {
      values[uniqueIndex][key].splice(index, 1);
      values[uniqueIndex].errorObj[key].splice(index, 1);
      setDetailsAction(values);
    }
  };

  const handleAddServices = () => {
    setHideAddServices(!hideAddServices);
  };
  const handleChange = (e, index, uniqueIndex, detailsKey) => {
    const { value, name } = e.target;
    let values = [...detailsAction];
    if (name === "image") {
      values[uniqueIndex][name] = e.target.files[0];
      setHideModelFlag2(false);
    } else if (detailsKey === "phoneNumbers") {
      if (!value) {
        // values[uniqueIndex].errorObj[detailsKey][index][name] =
        //   "This field is required";
        values[uniqueIndex][detailsKey][index][name] = value;
      } else {
        if (name === "phoneNumber") {
          if (phoneRegExp.test(value)) {
            values[uniqueIndex].errorObj[detailsKey][index][name] = "";
            values[uniqueIndex][detailsKey][index][name] = value;
          } else {
            // values[uniqueIndex].errorObj[detailsKey][index][name] =
            //   "Enter valid phone number";
            values[uniqueIndex][detailsKey][index][name] = value;
          }
        } else if (name === "extension") {
          // values[uniqueIndex].errorObj[detailsKey][index][name] = "";
          values[uniqueIndex][detailsKey][index][name] = value;
        } else if (name === "name") {
          if (nameRegex.test(value)) {
            values[uniqueIndex].errorObj[detailsKey][index][name] = "";
            values[uniqueIndex][detailsKey][index][name] = value;
          } else {
            // values[uniqueIndex].errorObj[detailsKey][index][name] =
            //   "Enter valid name";
            values[uniqueIndex][detailsKey][index][name] = value;
          }
        } else {
          values[uniqueIndex].errorObj[detailsKey][index][name] = "";
        }
        // values[detailsKey][index][name] = value;
        // values.errorObj[detailsKey][index][name] = "";
      }
    } else if (detailsKey === "emails") {
      if (!value) {
        values[uniqueIndex].errorObj[detailsKey][index] =
          "This field is required";
        // values.errorObj[detailsKey][index] = `${detailsKey} is required`;
        values[uniqueIndex][detailsKey][index] = value;
      } else {
        // if (detailsKey === "emails") {
        if (emailRegex.test(value)) {
          values[uniqueIndex].errorObj[detailsKey][index] = "";
          values[uniqueIndex][detailsKey][index] = value;
        } else {
          values[uniqueIndex].errorObj[detailsKey][index] =
            "Please enter valid email id";
          values[uniqueIndex][detailsKey][index] = value;
        }
        // }
        // else {
        //   values.errorObj[detailsKey][index] = "";
        //   values[detailsKey][index] = value;
        // }
      }
    } else if (detailsKey === "websites") {
      if (!value) {
        values[uniqueIndex].errorObj[detailsKey][index] =
          "This field is required";
        // values.errorObj[detailsKey][index] = `${detailsKey} is required`;
        values[uniqueIndex][detailsKey][index] = value;
      } else {
        values[uniqueIndex].errorObj[detailsKey][index] = "";
        values[uniqueIndex][detailsKey][index] = value;
        // }
        // else {
        //   values.errorObj[detailsKey][index] = "";
        //   values[detailsKey][index] = value;
        // }
      }
    }
    //  else if (detailsKey === "phoneNumbers") {
    //   values[uniqueIndex][detailsKey][index][name] = value;
    // }
    // else if (detailsKey === "emails" || detailsKey === "websites") {
    //   values[uniqueIndex][detailsKey][index] = value;
    // }
    else {
      if (!value) {
        // values[uniqueIndex].errorObj[name] = `This field is required`;
        values[uniqueIndex][name] = value;
      } else {
        values[uniqueIndex].errorObj[name] = "";
        values[uniqueIndex][name] = value;
      }
    }
    setDetailsAction(values);
  };

  const handleSectorBuList = (e, newValue, uniqueIndex, key) => {
    let values = [...detailsAction];
    if (key === "sectorList") {
      getBuFromSector(newValue);
    }
    values[uniqueIndex][key] = newValue;
    setDetailsAction(values);
  };

  const handleStateChange = (e, index, detailsKey) => {
    const { value, name } = e.target;
    let values = { ...detailsActionAdd };
    if (name === "image") {
      values[name] = e.target.files[0];
      values.icon = null;
      setHideModelFlag(false);
    } else if (detailsKey === "phoneNumbers") {
      if (!value) {
        // values.errorObj[detailsKey][index][name] = "This field is required";
        values[detailsKey][index][name] = value;
      } else {
        if (name === "phoneNumber") {
          if (phoneRegExp.test(value)) {
            values.errorObj[detailsKey][index][name] = "";
            values[detailsKey][index][name] = value;
          } else {
            // values.errorObj[detailsKey][index][name] =
            //   "Enter valid phone number......";
            values[detailsKey][index][name] = value;
          }
        } else if (name === "extension") {
          // values.errorObj[detailsKey][index][name] = "";
          values[detailsKey][index][name] = value;
        } else if (name === "name") {
          if (nameRegex.test(value)) {
            values.errorObj[detailsKey][index][name] = "";
            values[detailsKey][index][name] = value;
          } else {
            // values.errorObj[detailsKey][index][name] = "Enter valid name";
            values[detailsKey][index][name] = value;
          }
        } else {
          values.errorObj[detailsKey][index][name] = "";
        }
        // values[detailsKey][index][name] = value;
        // values.errorObj[detailsKey][index][name] = "";
      }
    } else if (detailsKey === "emails") {
      if (!value) {
        values.errorObj[detailsKey][index] = "This field is required";
        // values.errorObj[detailsKey][index] = `${detailsKey} is required`;
        values[detailsKey][index] = value;
      } else {
        // if (detailsKey === "emails") {
        if (emailRegex.test(value)) {
          values.errorObj[detailsKey][index] = "";
          values[detailsKey][index] = value;
        } else {
          values.errorObj[detailsKey][index] = "Please enter valid email id";
          values[detailsKey][index] = value;
        }
        // }
        // else {
        //   values.errorObj[detailsKey][index] = "";
        //   values[detailsKey][index] = value;
        // }
      }
    } else if (detailsKey === "websites") {
      if (!value) {
        values.errorObj[detailsKey][index] = "This field is required";
        // values.errorObj[detailsKey][index] = `${detailsKey} is required`;
        values[detailsKey][index] = value;
      } else {
        values.errorObj[detailsKey][index] = "";
        values[detailsKey][index] = value;
        // }
        // else {
        //   values.errorObj[detailsKey][index] = "";
        //   values[detailsKey][index] = value;
        // }
      }
    } else {
      if (!value) {
        values.errorObj[name] = `This field is required`;
        values[name] = value;
      } else {
        values.errorObj[name] = "";
        values[name] = value;
      }
    }
    setDetailsActionAdd(values);
  };

  const getBuFromSector = async (sector, key) => {
    const getSectors = sector.map((result) => {
      return {
        addsectorId: result.sectorCode,
      };
    });

    try {
      setLoading(true);
      let res = await services.api(
        "POST",
        `/ui-assist/v1/businessunit`,
        getSectors
      );
      if (res.status === 200) {
        setLoading(false);
        let list = res.data.map((result) => {
          return {
            title: result.buName,
            value: result.buName,
            id: result.id,
          };
        });

        if (key === "edit") {
          setGetDivisionListEdit(list);
        } else {
          setGetDivisionList(list);
        }
      }
    } catch (err) {
      setLoading(false);
      // Swal.fire("Oops!", `${err.response.data}`, "error");
      setGetDivisionList([]);
    }
  };

  const handleSelectChange = (e, newValue, name) => {
    // if (name === "sectorList") {
    //   getBuFromSector(newValue);
    // }
    let values = { ...detailsActionAdd };
    values[name] = newValue;
    setDetailsActionAdd(values);
  };

  const handleAddFields = (e, key) => {
    let values = { ...detailsActionAdd };
    if (key === "phoneNumbers") {
      values[key].push({
        name: "",
        phoneNumber: "",
        extension: "",
      });

      values.errorObj[key].push({
        name: "",
        phoneNumber: "",
        extension: "",
      });
      setDetailsActionAdd(values);
    } else {
      values[key].push("");
      values.errorObj[key].push("");
      setDetailsActionAdd(values);
    }
  };

  const handleDeleteFields = (index, key) => {
    let values = { ...detailsActionAdd };
    // if (key !== "phoneNumbers") {
    values.errorObj[key].splice(index, 1);
    // }
    values[key].splice(index, 1);
    setDetailsActionAdd(values);
  };
  const {
    title,
    description,
    actionName,
    actionUrl,
    // phoneData,
    // emails,
    phoneNumbers,
    emails,
    image,
    icon,
    // websites,
    websites,
    sectorList,
    buList,
    errorObj,
    category,
  } = detailsActionAdd || {};

  const getOptionLabel = (option) => `${option.title}`;
  // const getOptionDisabled = (option) => option.value === "foo";
  const handleToggleOption = (selectedOptions, name, uniqueIndex, edit) => {
    if (edit === "edit") {
      let values = [...detailsAction];
      if (name === "sectorList") {
        values[uniqueIndex].buList = [];
        getBuFromSector(selectedOptions, "edit");
      }
      values[uniqueIndex][name] = selectedOptions;

      setDetailsAction(values);
    } else {
      let values = { ...detailsActionAdd };
      if (name === "sectorList") {
        values.buList = [];
        getBuFromSector(selectedOptions);
      }
      values[name] = selectedOptions;
      setDetailsActionAdd(values);
    }
  };
  const handleClearOptions = (name, uniqueIndex, edit) => {
    if (edit === "edit") {
      let values = [...detailsAction];
      if (name === "sectorList") {
        values[uniqueIndex][name] = [];
        values[uniqueIndex].buList = [];
        // setGetDivisionList([]);
      } else {
        values[uniqueIndex][name] = [];
      }
      setDetailsAction(values);
    } else {
      let values = { ...detailsActionAdd };
      if (name === "sectorList") {
        values[name] = [];
        values.buList = [];
        setGetDivisionList([]);
      } else {
        values[name] = [];
      }
      setDetailsActionAdd(values);
    }
  };
  const handleSelectAll = (isSelected, name, uniqueIndex, edit) => {
    if (isSelected) {
      if (edit === "edit") {
        let values = [...detailsAction];
        if (name === "sectorList") {
          getBuFromSector(getSectorList?.length && getSectorList, "edit");
          values[uniqueIndex][name] = getSectorList?.length && getSectorList;
        } else {
          values[uniqueIndex][name] =
            getDivisionListEdit?.length && getDivisionListEdit;
        }
        setDetailsAction(values);
      } else {
        let values = { ...detailsActionAdd };
        if (name === "sectorList") {
          getBuFromSector(getSectorList?.length && getSectorList);
          values[name] = getSectorList?.length && getSectorList;
        } else {
          values[name] = getDivisionList?.length && getDivisionList;
        }

        setDetailsActionAdd(values);
      }
    } else {
      handleClearOptions(name, uniqueIndex, edit);
    }
  };

  const handleAddIcon = (item, uniqueIndex, edit) => {
    if (edit === "edit") {
      let values = [...detailsAction];
      values[uniqueIndex].icon = item.name;
      values[uniqueIndex].image = null;
      setDetailsAction(values);
      setHideModelFlag2(false);
    } else {
      let values = { ...detailsActionAdd };
      values.icon = item.name;
      values.image = null;
      setDetailsActionAdd(values);
      setHideModelFlag(false);
    }
  };

  return (
    <div className="ITSupportAdminContainer">
      {loading ? <Loader /> : null}
      <div className="whatsNewAdminTopSectionContainer">
        {lastModified && (
          <ModifiedSection
            modifiedBy={lastModified?.name}
            modifiedDate={lastModified?.date}
          />
        )}

        <div className="uploadBtn">
          <SubmitButton
            label="Add Service"
            className="MCsubmitbutton floatRightAlign"
            onClick={handleAddServices}
          />
        </div>
      </div>
      <div className="ItSupportAreaContainer">
        {!hideAddServices && (
          <div className="addServicesBg">
            <Grid container alignItems="center">
              <Grid item md={12} xs={12} className="contentPadding">
                <span className="Complete-Tax-Declara">Details</span>
              </Grid>
              <Grid item md={4} xs={12} className="contentPadding">
                <CharLeftField
                  label={<CommonLabel label="Title" mandatory />}
                  name="title"
                  type="text"
                  value={title}
                  handleChange={handleStateChange}
                  errorMsg={errorObj.title ? errorObj.title : ""}
                />
              </Grid>
              <Grid item md={4} xs={12} className="contentPadding">
                <div
                  className="upperCharLeft"
                  onClick={() => {
                    setHideModelFlag(!hideModelFlag);
                  }}
                >
                  <CharLeftField
                    label={<CommonLabel label="Icon" mandatory />}
                    name="icon"
                    type="text"
                    value={image ? image.name : icon}
                  />
                </div>
                {hideModelFlag ? (
                  <div className="customSelectBox">
                    <Tabs onChange={handleChangeTab} value={tab}>
                      {/* <Tab label="Select an icon" className="favTabs" /> */}
                      <Tab label="Upload an image" className="favTabs" />
                    </Tabs>
                    {/* <TabPanel value={tab} index={0}>
                      <Grid container>
                       <Grid item md={12} xs={12}>
                          <SearchBarButton placeholder="Search article"></SearchBarButton>
                        </Grid> 
                        <Grid item md={12} className="mt25 addScrollCls">
                          {iconOpt.map((item, index) => (
                            <div
                              style={{ cursor: "pointer" }}
                              key={index}
                              className="optRoot"
                              onClick={() => handleAddIcon(item)}
                            >
                              <img src={item.icon} alt="" />
                              <div className="Tasks-that-need-your">
                                {item.name}
                              </div>
                            </div>
                          ))}
                        </Grid>
                      </Grid>
                    </TabPanel> */}
                    <TabPanel value={tab} index={0}>
                      <div className="uploadImg">
                        <label for="img" className="btn uploadBtnLabel">
                          <span className="colRed">CHOOSE AN IMAGE</span>
                        </label>
                        <input
                          id="img"
                          style={{
                            visibility: "hidden",
                            padding: "19px 16px",
                            position: "absolute",
                          }}
                          type="file"
                          accept=".png, .jpg, .svg"
                          name="image"
                          onChange={handleStateChange}
                        />
                      </div>
                      <div className="recommendatinTxt">
                        Recommended size is 280 x 280 pixels
                      </div>
                    </TabPanel>
                  </div>
                ) : null}
              </Grid>
              <Grid item md={4} xs={12} className="contentPadding">
                <CommonAutocomplete
                  disabled={!categoryList?.length && categoryList}
                  handleSelectChange={(e, newValue) => {
                    handleSelectChange(e, newValue, "category");
                  }}
                  name="category"
                  className="width100"
                  selectedValue={category}
                  options={categoryList?.length && categoryList}
                  label={<CommonLabel label="Category" mandatory />}
                />
              </Grid>
              <Grid item md={12} xs={12} className="contentPadding">
                <CharLeftField
                  label={<CommonLabel label="Description" mandatory />}
                  name="description"
                  type="text"
                  handleChange={handleStateChange}
                  value={description}
                  errorMsg={errorObj.description ? errorObj.description : ""}
                />
              </Grid>
              <Grid item md={6} xs={6} className="contentPadding iTMulti">
                {/* <MultiSelect
                  className="width100"
                  handleSelectChange={(e, newValue) => {
                    handleSelectChange(e, newValue, "sectorList");
                  }}
                  selectedValue={sectorList}
                  options={getSectorList?.length && getSectorList}
                  name="sectorList"
                  label={<CommonLabel label="Sector" mandatory />}
                /> */}
                <CustomMultiSelect
                  disabled={!getSectorList?.length}
                  items={getSectorList?.length && getSectorList}
                  getOptionLabel={getOptionLabel}
                  // getOptionDisabled={getOptionDisabled}
                  selectedValues={sectorList}
                  name="sectorList"
                  label={<CommonLabel label="Sector" mandatory />}
                  // placeholder="Select category"
                  selectAllLabel="Select all"
                  onToggleOption={(data) =>
                    handleToggleOption(data, "sectorList")
                  }
                  onClearOptions={() => handleClearOptions("sectorList")}
                  onSelectAll={(data) => handleSelectAll(data, "sectorList")}
                />
              </Grid>
              <Grid item md={6} xs={6} className="contentPadding iTMulti">
                {/* <MultiSelect
                  className="width100"
                  handleSelectChange={(e, newValue) =>
                    handleSelectChange(e, newValue, "buList")
                  }
                  // selectedValue={buList}
                  disabled={!getDivisionList?.length}
                  name="buList"
                  options={getDivisionList?.length && getDivisionList}
                  label={<CommonLabel label="Business unit" mandatory />}
                /> */}
                <CustomMultiSelect
                  disabled={!getDivisionList?.length}
                  items={getDivisionList?.length && getDivisionList}
                  getOptionLabel={getOptionLabel}
                  // getOptionDisabled={getOptionDisabled}
                  selectedValues={buList}
                  name="buList"
                  label={<CommonLabel label="Business unit" mandatory />}
                  // placeholder="Select category"
                  selectAllLabel="Select all"
                  onToggleOption={(data) => handleToggleOption(data, "buList")}
                  onClearOptions={() => handleClearOptions("buList")}
                  onSelectAll={(data) => handleSelectAll(data, "buList")}
                />
              </Grid>

              <Grid item md={12} xs={12} className="contentPadding">
                <span className="Complete-Tax-Declara">Contacts</span>
              </Grid>
              <Grid item md={12} xs={12} className="contentPadding">
                <div className="contactContainer">
                  <div className="contactsField">Phone number</div>
                  <span
                    style={{ display: "flex", cursor: "pointer" }}
                    onClick={(e) => handleAddFields(e, "phoneNumbers")}
                  >
                    <img src={PLUS_ICON} alt="" />
                    <div className="add">ADD</div>
                  </span>
                </div>
              </Grid>
              {detailsActionAdd.phoneNumbers?.length ? (
                <>
                  {detailsActionAdd.phoneNumbers.map((item, index) => (
                    <Grid container key={index}>
                      <Grid item md={4} xs={12} className="contentPadding">
                        <CharLeftField
                          label={<CommonLabel label="Name" />}
                          name="name"
                          value={item.name}
                          handleChange={(e) =>
                            handleStateChange(e, index, "phoneNumbers")
                          }
                          type="text"
                          errorMsg={
                            errorObj.phoneNumbers[index].name
                              ? errorObj.phoneNumbers[index].name
                              : ""
                          }
                        />
                      </Grid>
                      <Grid item md={4} xs={12} className="contentPadding">
                        <CharLeftField
                          label={<CommonLabel label="Phone number" />}
                          name="phoneNumber"
                          handleChange={(e) =>
                            handleStateChange(e, index, "phoneNumbers")
                          }
                          value={item.phoneNumber}
                          type="text"
                          errorMsg={
                            errorObj.phoneNumbers[index].phoneNumber
                              ? errorObj.phoneNumbers[index].phoneNumber
                              : ""
                          }
                        />
                      </Grid>
                      <Grid item md={3} xs={12} className="contentPadding">
                        <CharLeftField
                          label={<CommonLabel label="Extention" />}
                          name="extension"
                          handleChange={(e) =>
                            handleStateChange(e, index, "phoneNumbers")
                          }
                          value={item.extension}
                          type="text"
                          inputProps={{ maxLength: 10 }}
                          // errorMsg={
                          //   errorObj.phoneNumbers[index].extension
                          //     ? errorObj.phoneNumbers[index].extension
                          //     : ""
                          // }
                        />
                      </Grid>
                      <Grid
                        style={{ cursor: "pointer" }}
                        item
                        md={1}
                        xs={12}
                        className="contentPadding centerIcon"
                        onClick={() =>
                          handleDeleteFields(index, "phoneNumbers")
                        }
                      >
                        <img src={BIN_ICON} alt="Delete Button" />
                      </Grid>
                    </Grid>
                  ))}
                </>
              ) : null}

              <Grid item md={12} xs={12} className="contentPadding">
                <div className="contactContainer">
                  <div className="contactsField">Email ID</div>
                  <span
                    style={{ display: "flex", cursor: "pointer" }}
                    onClick={(e) => handleAddFields(e, "emails")}
                  >
                    <img src={PLUS_ICON} alt="" />
                    <div className="add">ADD</div>
                  </span>
                </div>
              </Grid>
              {detailsActionAdd.emails?.length ? (
                <>
                  {detailsActionAdd.emails.map((item, index) => (
                    <Grid container key={index}>
                      {/* <Grid item md={4} xs={12} className="contentPadding">
                      <CharLeftField
                        label={<CommonLabel label="Email title" />}
                        name="emailTitle"
                        // value={item.emailTitle}
                        handleChange={(e) =>
                          handleChange(e, index, "emailData")
                        }
                        type="text"
                      />
                    </Grid> */}
                      <Grid item md={4} xs={12} className="contentPadding">
                        <CharLeftField
                          label={<CommonLabel label="Email" />}
                          name="emailId"
                          handleChange={(e) =>
                            handleStateChange(e, index, "emails")
                          }
                          value={item !== [] ? item : ""}
                          type="email"
                          errorMsg={
                            errorObj.emails[index] ? errorObj.emails[index] : ""
                          }
                        />
                      </Grid>
                      <Grid
                        style={{ cursor: "pointer" }}
                        item
                        md={1}
                        xs={12}
                        className="contentPadding centerIcon"
                        onClick={() => handleDeleteFields(index, "emails")}
                      >
                        <img src={BIN_ICON} alt="Delete Button" />
                      </Grid>
                    </Grid>
                  ))}
                </>
              ) : null}
              <Grid item md={12} xs={12} className="contentPadding">
                <div className="contactContainer">
                  <div className="contactsField">Website</div>
                  <span
                    style={{ display: "flex", cursor: "pointer" }}
                    onClick={(e) => handleAddFields(e, "websites")}
                  >
                    <img src={PLUS_ICON} alt="" />
                    <div className="add">ADD</div>
                  </span>
                </div>
              </Grid>
              {detailsActionAdd.websites?.length ? (
                <>
                  {detailsActionAdd.websites.map((item, index) => (
                    <Grid container key={index}>
                      {/* <Grid item md={4} xs={12} className="contentPadding">
                      <CharLeftField
                        label={<CommonLabel label="Website name" />}
                        name="websiteName"
                        // value={item.websiteName}
                        handleChange={(e) =>
                          handleStateChange(e, index, "website")
                        }
                        type="text"
                      />
                    </Grid> */}
                      <Grid item md={4} xs={12} className="contentPadding">
                        <CharLeftField
                          label={<CommonLabel label="Url" />}
                          name="url"
                          handleChange={(e) =>
                            handleStateChange(e, index, "websites")
                          }
                          value={item !== [] ? item : ""}
                          type="text"
                          errorMsg={
                            errorObj.websites[index]
                              ? errorObj.websites[index]
                              : ""
                          }
                        />
                      </Grid>
                      <Grid
                        style={{ cursor: "pointer" }}
                        item
                        md={1}
                        xs={12}
                        className="contentPadding centerIcon"
                        onClick={(index) =>
                          handleDeleteFields(index, "websites")
                        }
                      >
                        <img src={BIN_ICON} alt="Delete Button" />
                      </Grid>
                    </Grid>
                  ))}
                </>
              ) : null}
              <Grid item md={12} xs={12} className="contentPadding">
                <span className="Complete-Tax-Declara">Action</span>
              </Grid>
              <Grid item md={6} xs={12} className="contentPadding">
                <CharLeftField
                  label={<CommonLabel label="Action Name" mandatory />}
                  name="actionName"
                  type="text"
                  value={actionName}
                  handleChange={handleStateChange}
                  errorMsg={errorObj.actionName ? errorObj.actionName : ""}
                />
              </Grid>
              <Grid item md={6} xs={12} className="contentPadding">
                <CharLeftField
                  label={<CommonLabel label="Action URL" mandatory />}
                  name="actionUrl"
                  value={actionUrl}
                  handleChange={handleStateChange}
                  errorMsg={errorObj.actionUrl ? errorObj.actionUrl : ""}
                  type="text"
                />
              </Grid>

              <Grid container alignItems="center" justifyContent="flex-end">
                <Grid item md={2} xs={12} className="contentPadding">
                  <SubmitButton
                    disabled={
                      getMandatoryFields(detailsActionAdd) ||
                      handleValid(detailsActionAdd.errorObj)
                    }
                    onClick={handleSubmit}
                    label="Add service"
                    className={`${
                      getMandatoryFields(detailsActionAdd) ||
                      handleValid(detailsActionAdd.errorObj)
                        ? ""
                        : "MCsubmitbutton"
                    } floatRightAlign`}
                  />
                </Grid>
              </Grid>
            </Grid>
          </div>
        )}
        <div className="setscrollItAdmin">
          {detailsAction.length > 0 &&
            detailsAction.map((item, index) => (
              <Accordion
                className="accordionContainer pl8 pr8 mb16"
                key={index}
              >
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                >
                  <div className="mainAccContainer">
                    <div className={"accordionStyle accTitletxt"}>
                      {item.title}
                    </div>
                    <div className={"lableClass accLabletxt"}>
                      {item.description}
                    </div>
                  </div>
                </AccordionSummary>
                <AccordionDetails>
                  <ITSupportAdminForm
                    handleAddIcon={(iconName, uniqueIndex, edit) =>
                      handleAddIcon(iconName, uniqueIndex, edit)
                    }
                    hideModelFlag2={hideModelFlag2}
                    setHideModelFlag2={setHideModelFlag2}
                    handleChangeTab={(event, newValue, uniqueIndex, edit) =>
                      handleChangeTab(event, newValue, uniqueIndex, edit)
                    }
                    TabPanel={TabPanel}
                    handleValidEdit={handleValidEdit}
                    getMandatoryFieldsEdit={getMandatoryFieldsEdit}
                    handleValid={handleValid}
                    getDivisionListEdit={getDivisionListEdit}
                    categoryList={categoryList}
                    getDivisionList={getDivisionList}
                    getSectorList={getSectorList}
                    tab={tab2}
                    // handleChangeTab={handleChangeTab}
                    handleDeleteServices={handleDeleteServices}
                    hideAddServices={hideAddServices}
                    uniqueIndex={index}
                    handleChange={(e, index, uniqueIndex, detailsKey, tabKey) =>
                      handleChange(e, index, uniqueIndex, detailsKey, tabKey)
                    }
                    handleAddPhoneData={(e, uniqueIndex, key) =>
                      handleAddPhoneData(e, uniqueIndex, key)
                    }
                    handleDeletePhoneData={(index, uniqueIndex, key) =>
                      handleDeletePhoneData(index, uniqueIndex, key)
                    }
                    // phoneData={phoneData}
                    iconOpt={iconOpt}
                    titleText="Details"
                    detailsAction={detailsAction}
                    handleSubmit={(data, key) => handleSubmit(data, key)}
                    handleSectorBuList={(e, newValue, uniqueIndex, key) =>
                      handleSectorBuList(e, newValue, uniqueIndex, key)
                    }
                    handleToggleOption={(data, name, uniqueIndex, edit) =>
                      handleToggleOption(data, name, uniqueIndex, edit)
                    }
                    handleClearOptions={(name, uniqueIndex, edit) =>
                      handleClearOptions(name, uniqueIndex, edit)
                    }
                    handleSelectAll={(data, name, uniqueIndex, edit) =>
                      handleSelectAll(data, name, uniqueIndex, edit)
                    }
                    getOptionLabel={getOptionLabel}
                  />
                </AccordionDetails>
              </Accordion>
            ))}
        </div>
      </div>
    </div>
  );
}
