import { Card, Grid, Tabs, Tab, Typography, Box } from "@material-ui/core";
import React, { useState, useEffect } from "react";

import PropTypes from "prop-types";

import { useHistory } from "react-router-dom";
// import {
//   LIVEWIRE,
//   UPLOAD_ARTICLE,
//   OC_REVIEW_ARTICLE,
//   UPLOAD_A_STORY,
//   SUBMIT_OFFER_CAMPAIGN,
// } from "../../../../Routes/constant";
import "./GradeListCardComponent.css";
import { getLastModifiedData } from "../../../../Redux/Actions/CommonActions";
import { getFormattedDate } from "../../../../Assets/helperFunctions/helperFunctions";
import { useSelector, useDispatch } from "react-redux";
import { getGradeData } from "../../../../Redux/Actions/AllEntitlementAction";
import  { getBenefitMasterData } from "../../../../Redux/Actions/EntitlementBenefitMasterAction";
import TruncateGradeLabel from "../TruncateGradeLabel";
import SubmitButton from "../../../Common/Button/SubmitButton";
import addBoxLine from "./../../../../Assets/Images/WhatsNewIcons/add-box-fill.svg";

import EditGradeList from "./EditGradeList";
import CommonModal from "../../../Common/CommonModal/CommonModal";

import PLUS_ICON from "../../../../Assets/Images/Plus.svg";
import editIcon from "../../../../Assets/Images/edit-line.svg";
import editIconRed from "../../../../Assets/Images/edit-line-red.svg";
import delIcon from "../../../../Assets/Images/delete-bin-6-line-grey.svg";

import CharLeftField from "../../../Common/CharLeftField/CharLeftField";
import CommonLabel from "../../../Common/ComonLabel/CommonLabel";




function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`
  };
}



export default function AdminComponent() {
  const history = useHistory();

  let dispatch = useDispatch();
  let gradeList = useSelector(
    (state) => state.getEntitlementReducer?.allGrades
  );
  let benefitsList = useSelector(
    (state) => state.getEntitlementBenefitReducer?.allBenefits
  );

  const lastModified = useSelector((state) => {
    return state?.getLastModifiedDataReducer?.lastModifiedEntitlement;
  });

  const [edit, setEdit] = useState(false);
  const [gradeName, setGradeName] = useState("");
  // const [locationList, setLocationList] = useState([]);
  // const [loading, setLoading] = useState(false);
  const [editItemId, setEditItemId] = useState("");
  const [open, setOpen] = useState(false);
    const [value, setValue] = useState(0);

  useEffect(() => {
    dispatch(getGradeData());
    dispatch(getBenefitMasterData());
    dispatch(getLastModifiedData("entitlement"));
  }, []);

  // const EditLocation = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const handleAdd = () => {
    setOpen(true);
    setEdit(false);
    setGradeName("");
  };

  const handleChange = (e) => setGradeName(e.target.value);

  const handleTabChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleEdit = (Name, id) => {
    setOpen(true);
    setEdit(true);
    setEditItemId(id);
    setGradeName(Name);
  };

  // console.log("grade list =====>", gradeList);
  // console.log("last modified data ====>", lastModified);
  console.log("benefits list =====>", benefitsList);

  return (
    <>
      <div className="lastmodifiedpanel mb-0">
        <Grid container spacing={2}>
          <Grid item sm={2} xs={12}>
            <div className="displayBlockUserDetails mlP alignMCAdmin">
              <p className="detailDivp">Last modified by</p>
              <p className="detailsFontSize">
                {lastModified?.name ? lastModified?.name : "Not Available"}
              </p>
            </div>
          </Grid>
          <Grid item sm={2} xs={12}>
            <div className="displayBlockUserDetails alignMCAdmin">
              <p className="detailDivp">Modified on</p>
              <p className="detailsFontSize">
                {lastModified?.date
                  ? getFormattedDate(lastModified?.date)
                  : "Not Available"}
              </p>
            </div>
          </Grid>
          {/* <Grid item sm={8} xs={12}>
            <div className="uploadBtn">
              { <SubmitButton
                label="Add Grades"
                onClick={handleAdd}
                className="MCsubmitbutton floatRightAlign"
                icon={addBoxLine}
               
              /> }
            </div>
          </Grid> */}
        </Grid>
      </div>
      <hr className="deviderP" />
      <Box sx={{ width: "100%" }} className="mb-3">
        <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
          <Tabs
            value={value}
            onChange={handleTabChange}
            aria-label="basic tabs example"
            className="rollBasedTab"
          >
            <Tab label="Grades" {...a11yProps(0)} />
            <Tab label="Benefits" {...a11yProps(1)} />
          </Tabs>
          <hr className="deviderP" />
        </Box>
      </Box>

      <TabPanel value={value} index={0} className="rollBasedTabPanel">
        {gradeList &&
          gradeList?.map((val, index) => {
            return (
              <div className="MCAdminTabAlign mt-3">
                <Card className="adminCardAlign">
                  <div className="adminCard">
                    <Grid
                      container
                      direction="row"
                      // justifyContent="flex-end"
                      // alignItems="center"
                    >
                      <div className="adminCardTitle">
                        <TruncateGradeLabel label={val?.employeegrade} />
                      </div>
                      {/* <div className="AdminCardDaysTogo">01 day ago</div> */}
                      {/* <Grid
                      item
                      xs={12}
                      sm="auto"
                      className="ms-auto me-4"
                      onClick={(e) => handleEdit(val?.employeegrade, "1")}
                    >
                      <div className="catActionBtnContainer">
                        <img src={editIconRed} alt="" className="edit-line" />
                        <div className="catActionBtn">EDIT GRADE NAME</div>
                      </div>
                    </Grid> */}
                      {/* <Grid item xs={12} sm="auto">
            <div className="catActionBtnContainer">
              <img src={delIcon} alt="" className="edit-line" />
              <div className="catActionBtn delBtn">DELETE GRADE</div>
            </div>
          </Grid> */}
                    </Grid>
                    <Grid
                      container
                      direction="row"
                      justifyContent="flex-start"
                      alignItems="center"
                    >
                      <Grid item xs={12} sm="auto">
                        <div
                          className="adminCardView"
                          onClick={() =>
                            history.push({
                              pathname: `/EntitlementAdmin/grade/${val?.employeegradecode}`,
                              state: { data: val }
                            })
                          }
                        >
                          <div>Edit</div>
                          <img
                            src={
                              process.env.PUBLIC_URL +
                              "/Images/adminProfile/arrow-down-s-line.svg"
                            }
                            alt="rightArrow"
                          />
                        </div>
                        {/* <div className="adminCardSubTitle">
            Club membership, Club Mahindra, CTC Vehicle, Credit Card, Mobile Handset Reimbursement
            </div> */}
                      </Grid>
                    </Grid>
                  </div>
                </Card>
              </div>
            );
          })}
      </TabPanel>

      <TabPanel value={value} index={1} className="rollBasedTabPanel">
        {benefitsList &&
          benefitsList?.map((val, index) => {
            return (
              <div className="MCAdminTabAlign mt-3">
                <Card className="adminCardAlign">
                  <div className="adminCard">
                    <Grid container direction="row">
                      <div className="adminCardTitle">
                        {val?.entitlementType}
                      </div>
                    </Grid>
                    <Grid
                      container
                      direction="row"
                      justifyContent="flex-start"
                      alignItems="center"
                    >
                      <Grid item xs={12} sm="auto">
                        <div
                          className="adminCardView"
                          onClick={() =>
                            history.push({
                              pathname: `/EntitlementAdmin/benefit/${val?.id}`,
                              state: { data: val }
                            })
                          }
                        >
                          <div>Edit</div>
                          <img
                            src={
                              process.env.PUBLIC_URL +
                              "/Images/adminProfile/arrow-down-s-line.svg"
                            }
                            alt="rightArrow"
                          />
                        </div>
                      </Grid>
                    </Grid>
                  </div>
                </Card>
              </div>
            );
          })}
      </TabPanel>

      <CommonModal
        open={open}
        handleClose={handleClose}
        headerText={edit ? "Edit Grade Name" : "Add Grade"}
        icon={edit && editIcon}
        modalSize="lg"
        fullWidth
        submitBtnText={edit ? "Edit Grade Name" : "Add Grade"}
        // onButtonClick={() => handleSubmit(edit, editItemId)}
        disabledSubmitButton={!gradeName ? true : false}
      >
        <Grid container alignItems="center" className="p-4">
          <Grid item md={12} xs={12} className="contentPadding">
            <span className="Complete-Tax-Declara">Grade</span>
          </Grid>
          <Grid item md={6} xs={12} className="contentPadding">
            <CharLeftField
              label={<CommonLabel label="Grade Name" mandatory />}
              name="Grade"
              type="text"
              value={gradeName}
              handleChange={handleChange}
            />
          </Grid>
        </Grid>
      </CommonModal>
    </>
  );
}
