import React, { useEffect, useState } from "react";
import "date-fns";
import { Grid, Paper, Tabs, Tab, Typography, Box } from "@material-ui/core";
import PropTypes from "prop-types";
import SubmitButton from "../../Common/Button/SubmitButton";
import "./WallpaperComponent.css";
import { getWallpaperRequestApprovalData } from "../../../Redux/Actions/WallpaperRequestApprovalAction";
import { getApproveWallpaperRequestData } from "../../../Redux/Actions/ApproveWallpaperRequestAction";
import { getRejectWallpaperRequestData } from "../../../Redux/Actions/RejectWallpaperRequestAction";
import { getLocationBySectorData } from "../../../Redux/Actions/LocationListBySectorAction";
import { useDispatch, useSelector } from "react-redux";
import CommonModal from "../../Common/CommonModal/CommonModal";
import Loader from "../../Common/Loader/Loader";
import Calender from "../../Common/CommonCalender/CommonCalender";
import CustomMultiSelect from "../../Common/CustomMultiSelect/CustomMultiSelect";
import CommonLabel from "../../Common/ComonLabel/CommonLabel";
import Swal from "sweetalert2";
import CommonAutocomplete from "../../Common/CommonAutocomplete/CommonAutocomplete";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }} style={{ padding: "0px" }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `vertical-tab-${index}`,
    "aria-controls": `vertical-tabpanel-${index}`,
  };
}

const hours = [
  { title: "1", value: "1" },
  { title: "2", value: "2" },
  { title: "3", value: "3" },
  { title: "4", value: "4" },
  { title: "5", value: "5" },
  { title: "6", value: "6" },
  { title: "7", value: "7" },
  { title: "8", value: "8" },
  { title: "9", value: "9" },
  { title: "10", value: "10" },
  { title: "11", value: "11" },
  { title: "12", value: "12" },
];

const minutes = [
  { title: "0", value: "0" },
  { title: "15", value: "15" },
  { title: "30", value: "30" },
  { title: "45", value: "45" },
];

const timeslot = [
  { title: "AM", value: "AM" },
  { title: "PM", value: "PM" },
];

export default function WallpaperComponent() {
  let baseURL = process.env.REACT_APP_API_BASE_URL;
  const dispatch = useDispatch();
  const requesterList = useSelector(
    (state) => state.getWallpaperRequestApprovalReducer
  );
  const approveRequest = useSelector(
    (state) => state.getApproveWallpaperRequestReducer
  );
  const rejectRequest = useSelector(
    (state) => state.getRejectWallpaperRequestReducer
  );
  const initialData = useSelector(
    (state) => state.getInitialWallpaperInfoReducer
  );
  const locationData = useSelector((state) => state.getLocationBySectorReducer);
  // console.log("locationList =====> ", locationData);

  const [value, setValue] = React.useState(0);
  const [list, setList] = useState([]);
  const [activeId, setActiveId] = useState();
  const [activeSector, setActiveSector] = useState();
  const [reset, setReset] = useState(true);
  const [open, setOpen] = useState(false);
  const [loader, setLoader] = useState(false);
  const [selectedStartDate, setSelectedStartDate] = useState(undefined);
  const [selectedEndDate, setSelectedEndDate] = useState(undefined);
  const [locationPreference, setLocationPreference] = useState([]);
  const [locationList, setLocationList] = useState([]);
  const [currentLocationList, setCurrentLocationList] = useState([]);

  const [startHour, setStartHour] = useState("1");
  const [startMinute, setStartMinute] = useState("0");
  const [startTimeSlot, setStartTimeSlot] = useState("AM");
  const [endHour, setEndHour] = useState("1");
  const [endMinute, setEndMinute] = useState("0");
  const [endTimeSlot, setEndTimeSlot] = useState("PM");

  useEffect(() => {
    const val = initialData.getData?.wallpaperSectorScope?.sectorScope;
    const data = val ? val : ["50071900", "50067878", "50067676"];
    // const data = ["50067878"];
    dispatch(getLocationBySectorData(data));
  }, []);
  useEffect(() => {
    // console.log("Initial list in approval ======>", initialData);
    const val = initialData.getData?.wallpaperSectorScope?.sectorScope;
    const data = val ? val : [];
    // const data = ["50067878"];
    // console.log("Initial data list in approval ======>", data);
    dispatch(getWallpaperRequestApprovalData(data));
  }, [approveRequest?.getData, rejectRequest?.getData]);
  useEffect(() => {
    let flist = [];
    locationData?.getData?.map((data) => {
      let locList = [];
      data?.locationName?.map((val) => {
        let obj = {
          title: val,
          value: val,
        };
        locList = [...locList, obj];
      });
      let obj1 = {
        id: data?.sectorId,
        list: locList,
      };
      flist = [...flist, obj1];
    });
    setLocationList([...flist]);
    // setLocationPreference(locList);
  }, [locationData]);
  useEffect(() => {
    const initId = requesterList?.getData[0]?.id;
    const initSector =
      requesterList?.getData[0]?.wallpaperTargetSectors[0]?.sectorId;

    const sDate =
      requesterList?.getData[0]?.wallpaperTargetSectors[0]?.proposedStartDate?.split(
        " "
      )[0];
    const eDate =
      requesterList?.getData[0]?.wallpaperTargetSectors[0]?.proposedEndDate?.split(
        " "
      )[0];
    const sHour1 =
      requesterList?.getData[0]?.wallpaperTargetSectors[0]?.proposedStartDate
        ?.split(" ")[1]
        .split(":")[0];
    const sMinute1 =
      requesterList?.getData[0]?.wallpaperTargetSectors[0]?.proposedStartDate
        ?.split(" ")[1]
        .split(":")[1];
    const eHour1 =
      requesterList?.getData[0]?.wallpaperTargetSectors[0]?.proposedEndDate
        ?.split(" ")[1]
        .split(":")[0];
    const eMinute1 =
      requesterList?.getData[0]?.wallpaperTargetSectors[0]?.proposedEndDate
        ?.split(" ")[1]
        .split(":")[1];
    const sTimeslot =
      requesterList?.getData[0]?.wallpaperTargetSectors[0]?.proposedStartDate?.split(
        " "
      )[2];
    const eTimeslot =
      requesterList?.getData[0]?.wallpaperTargetSectors[0]?.proposedEndDate?.split(
        " "
      )[2];
    // const sHour1 = sTime[1].split(":");
    setActiveId(initId);
    setActiveSector(initSector);
    setSelectedStartDate(sDate);
    setSelectedEndDate(eDate);
    setStartHour(sHour1);
    setStartMinute(sMinute1);
    setStartTimeSlot(sTimeslot);
    setEndHour(eHour1);
    setEndMinute(eMinute1);
    setEndTimeSlot(eTimeslot);
    // console.log("location list ====> ", locationList, "locationData ===> ", locationData?.getData);
  }, [requesterList]);
  useEffect(() => {
    if (
      requesterList.isLoading ||
      approveRequest.isLoading ||
      rejectRequest.isLoading
    ) {
      setLoader(true);
    } else {
      setLoader(false);
    }
  }, [requesterList, approveRequest, rejectRequest]);
  useEffect(() => {
    locationList?.map((data) => {
      if (
        data?.id ==
        requesterList?.getData[0]?.wallpaperTargetSectors[0]?.sectorId
      ) {
        setLocationPreference(data?.list);
        setCurrentLocationList(data?.list);
      }
    });
    console.log("location list 123====> ", locationList);
  }, [locationList, requesterList]);

  const imgModal = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const Approve = () => {
    let list = [];
    locationPreference?.map((data) => {
      list = [...list, data.value];
    });
    const StartDate =
      selectedStartDate +
      " " +
      startHour +
      ":" +
      startMinute +
      " " +
      startTimeSlot;
    const EndDate =
      selectedEndDate + " " + endHour + ":" + endMinute + " " + endTimeSlot;

    let sh = "";
    let eh = "";
    if (startTimeSlot == "PM") {
      sh = parseInt(startHour) + 12;
    } else {
      sh = parseInt(startHour);
    }
    if (endTimeSlot == "PM") {
      eh = parseInt(endHour) + 12;
    } else {
      eh = parseInt(endHour);
    }

    const sD = selectedStartDate.split("-");
    const eD = selectedEndDate.split("-");
    const d1 = new Date(
      parseInt(sD[0]),
      parseInt(sD[1]) - 1,
      parseInt(sD[2]),
      sh,
      parseInt(startMinute)
    );
    const yyyy = new Date().getFullYear();
    const mm = new Date().getMonth();
    const dd = new Date().getDate();
    const d2 = new Date(yyyy, mm, dd);
    const d3 = new Date(
      parseInt(eD[0]),
      parseInt(eD[1]) - 1,
      parseInt(eD[2]),
      eh,
      parseInt(endMinute)
    );
    if (d1 < d2) {
      Swal.fire("Please select valid date", " ", "error");
    } else if (d1 > d3) {
      Swal.fire(
        "Start date and time should not be greater than end date and time",
        " ",
        "error"
      );
    } else {
      dispatch(
        getApproveWallpaperRequestData(
          activeId,
          StartDate,
          EndDate,
          list,
          activeSector
        )
      );
      setValue(0);
      reset ? setReset(false) : setReset(true);
      console.log("======== Status Approved ========");
    }
  };

  const Reject = () => {
    const check = dispatch(
      getRejectWallpaperRequestData(activeId, activeSector)
    );
    console.log("check API call =======> ", check);
    setValue(0);
    reset ? setReset(false) : setReset(true);
    console.log("======== Status Rejected ========");
  };

  const getOptionLabel = (option) => `${option.title}`;
  const getOptionDisabled = (option) => option.value === "foo";

  const handleLocationToggleOption = (selectedOptions) => {
    setLocationPreference(selectedOptions);
  };

  const handleClearLocation = () => {
    setLocationPreference([]);
  };

  const handleSelectAllLocations = (isSelected) => {
    if (isSelected) {
      setLocationPreference(locationList);
    } else {
      handleClearLocation();
    }
  };

  const StartHour = (event, selectedValue) => setStartHour(selectedValue.value);
  const StartMinute = (event, selectedValue) =>
    setStartMinute(selectedValue.value);
  const StartTimeSlot = (event, selectedValue) =>
    setStartTimeSlot(selectedValue.value);
  const EndHour = (event, selectedValue) => setEndHour(selectedValue.value);
  const EndMinute = (event, selectedValue) => setEndMinute(selectedValue.value);
  const EndTimeSlot = (event, selectedValue) =>
    setEndTimeSlot(selectedValue.value);

  console.log("location preference ====> ", locationPreference);
  console.log(
    "dates ====> ",
    startHour,
    startMinute,
    startTimeSlot,
    endHour,
    endMinute,
    endTimeSlot
  );
  return (
    <>
      {loader && <Loader />}
      <Grid container direction="row" spacing={2} className=" ">
        <Grid item sm={3} className="pb-0">
          <Paper className=" mt-2" style={{ height: "100%" }}>
            <Grid
              container
              direction="row"
              spacing={2}
              className="rollBasedPaper"
            >
              <Grid item xs={12} sm={12}>
                <p
                  className="heading"
                  style={{ marginBottom: "8px", marginTop: "8px" }}
                >
                  Pending ({requesterList.getData?.length})
                </p>
              </Grid>
              <div className="pendingScroll">
                <Box sx={{ width: "100%" }}>
                  <Tabs
                    orientation="vertical"
                    variant="scrollable"
                    value={value}
                    onChange={handleChange}
                    aria-label="Vertical tabs example"
                    className="wallpaperTab"
                  >
                    {requesterList.getData
                      ?.sort((a, b) => {
                        if (
                          new Date(b.proposedStartDate) >
                          new Date(a.proposedStartDate)
                        ) {
                          return 1;
                        } else {
                          return -1;
                        }
                      })
                      ?.map((item, index) => {
                        let list = [];
                        locationList?.map((data) => {
                          if (
                            data?.id ==
                            item?.wallpaperTargetSectors[0]?.sectorId
                          ) {
                            list = [...data?.list];
                          }
                        });
                        const id = item?.wallpaperTargetSectors[0]?.sectorId;
                        const sDate =
                          item?.wallpaperTargetSectors[0]?.proposedStartDate.split(
                            " "
                          );
                        const eDate =
                          item?.wallpaperTargetSectors[0]?.proposedEndDate.split(
                            " "
                          );
                        const Format = (date) => {
                          const data = date.split("-");
                          const upDate =
                            data[2] + "-" + data[1] + "-" + data[0];
                          return upDate;
                        };
                        return (
                          <Tab
                            className="mb-2 shadow"
                            label={
                              <div
                                className=" p-2"
                                style={{ width: "inherit" }}
                              >
                                <Grid
                                  container
                                  direction="row"
                                  spacing={3}
                                  className="rollBasedAutoCompplete"
                                >
                                  <Grid item xs={12}>
                                    <span className="heading2">
                                      {item?.title +
                                        " ( " +
                                        item?.wallpaperTargetSectors[0]
                                          ?.sectorName +
                                        " ) "}
                                    </span>
                                  </Grid>
                                  <Grid item xs={6} sm={6}>
                                    <span className="content2">
                                      {Format(sDate[0])}
                                    </span>
                                    <p style={styles.title2}>Start date</p>
                                  </Grid>
                                  <Grid item xs={6} sm={6}>
                                    <span className="content2">
                                      {Format(eDate[0])}
                                    </span>
                                    <p style={styles.title2}>End date</p>
                                  </Grid>
                                </Grid>
                              </div>
                            }
                            {...a11yProps(index)}
                            onClick={() => {
                              setActiveId(item.id);
                              setActiveSector(id);
                              setSelectedStartDate(sDate[0]);
                              setSelectedEndDate(eDate[0]);
                              setLocationPreference([...list]);
                              setCurrentLocationList([...list]);
                              setStartHour(sDate[1].split(":")[0]);
                              setStartMinute(sDate[1].split(":")[1]);
                              setStartTimeSlot(sDate[2]);
                              setEndHour(eDate[1].split(":")[0]);
                              setEndMinute(eDate[1].split(":")[1]);
                              setEndTimeSlot(eDate[2]);
                            }}
                          />
                        );
                      })}
                  </Tabs>
                </Box>
              </div>
            </Grid>
          </Paper>
        </Grid>
        <Grid item sm={9}>
          {requesterList.getData?.map((item, index) => {
            {
              /* let slist = []; */
            }

            {
              /* setLocationPreference(locationList); */
            }
            {
              /* item?.targetSector?.map((data) => {
                            slist = [...slist, data.sectorName];
                        }) */
            }
            {
              /* console.log("target sector =====> ", slist); */
            }
            const sDate =
              item?.wallpaperTargetSectors[0]?.proposedStartDate.split(" ");
            const eDate =
              item?.wallpaperTargetSectors[0]?.proposedEndDate.split(" ");
            const sH = sDate[1]?.split(":")[0];
            const sM = sDate[1]?.split(":")[1];
            const eH = eDate[1]?.split(":")[0];
            const eM = eDate[1]?.split(":")[1];
            const wName = item.wallpaper;
            {
              /* console.log("sDate, eDate, sH, sM, eH, eM ====> ", sDate, eDate, sH, sM, eH, eM); */
            }
            return (
              <>
                <TabPanel value={value} index={index} className="wallpaperTab">
                  <Paper className=" mt-2">
                    <Grid
                      container
                      direction="row"
                      spacing={2}
                      className="rollBasedPaper"
                    >
                      <Grid item xs={12} sm={12}>
                        <p
                          className="heading"
                          style={{ marginBottom: "8px", marginTop: "8px" }}
                        >
                          {item.title}
                        </p>
                      </Grid>
                      <div className="border3 pad-desk24-mob0">
                        <Grid
                          container
                          direction="row"
                          spacing={3}
                          className="rollBasedAutoCompplete mb-3"
                        >
                          <Grid item xs={12} sm={4}>
                            <span style={styles.title}>
                              Proposed start date
                            </span>
                            <Calender
                              setDate={selectedStartDate}
                              dLocation="start"
                              setSelectedStartDate={setSelectedStartDate}
                              setSelectedEndDate={setSelectedEndDate}
                            />
                          </Grid>
                          <Grid
                            item
                            xs={4}
                            sm={2}
                            className="lmar-desk24-mob0 wallpaperDropdown"
                          >
                            <CommonAutocomplete
                              handleSelectChange={StartHour}
                              selectedValue={{
                                title: startHour,
                                value: startHour,
                              }}
                              name="hours"
                              options={hours}
                              label={<CommonLabel label="Hours" />}
                            />
                          </Grid>
                          <Grid
                            item
                            xs={4}
                            sm={2}
                            className="wallpaperDropdown"
                          >
                            <CommonAutocomplete
                              handleSelectChange={StartMinute}
                              selectedValue={{
                                title: startMinute,
                                value: startMinute,
                              }}
                              name="minutes"
                              options={minutes}
                              label={<CommonLabel label="Minutes" />}
                            />
                          </Grid>
                          <Grid
                            item
                            xs={4}
                            sm={2}
                            className="wallpaperDropdown"
                          >
                            <CommonAutocomplete
                              handleSelectChange={StartTimeSlot}
                              selectedValue={{
                                title: startTimeSlot,
                                value: startTimeSlot,
                              }}
                              name="timeslot"
                              options={timeslot}
                            />
                          </Grid>
                          <Grid item xs={12} sm={4}>
                            <span style={styles.title}>Proposed end date</span>
                            <Calender
                              setDate={selectedEndDate}
                              dLocation="end"
                              setSelectedStartDate={setSelectedStartDate}
                              setSelectedEndDate={setSelectedEndDate}
                            />
                          </Grid>
                          <Grid
                            item
                            xs={4}
                            sm={2}
                            className="lmar-desk24-mob0 wallpaperDropdown"
                          >
                            <CommonAutocomplete
                              handleSelectChange={EndHour}
                              selectedValue={{ title: endHour, value: endHour }}
                              name="hours"
                              options={hours}
                              label={<CommonLabel label="Hours" />}
                            />
                          </Grid>
                          <Grid
                            item
                            xs={4}
                            sm={2}
                            className="wallpaperDropdown"
                          >
                            <CommonAutocomplete
                              handleSelectChange={EndMinute}
                              selectedValue={{
                                title: endMinute,
                                value: endMinute,
                              }}
                              name="minutes"
                              options={minutes}
                              label={<CommonLabel label="Minutes" />}
                            />
                          </Grid>
                          <Grid
                            item
                            xs={4}
                            sm={2}
                            className="wallpaperDropdown"
                          >
                            <CommonAutocomplete
                              handleSelectChange={EndTimeSlot}
                              selectedValue={{
                                title: endTimeSlot,
                                value: endTimeSlot,
                              }}
                              name="timeslot"
                              options={timeslot}
                            />
                          </Grid>
                          {/* <Grid item xs={12} sm={4}>
                                                    <span style={styles.title}>Target sector</span>
                                                    <p style={styles.content}>{slist.join(", ")}</p>
                                                </Grid> */}
                          <Grid item xs={12} sm={4}>
                            <span style={styles.title}>Wallpaper image</span>
                            <br />
                            {/* {console.log(item.wallpaper, 'wallpaper@@')} */}
                            <img
                              src={`${baseURL}/ui-assist/v1/downloadwallpaper?fileName=${item.wallpaper}`}
                              alt="Wallpaper"
                              style={{ height: "56px", width: "56px" }}
                              onClick={imgModal}
                            />
                          </Grid>
                          <CommonModal
                            handleClose={handleClose}
                            className="approvalImage"
                            modalSize="lg"
                            open={open}
                            header={true}
                            footer={false}
                            headerText={wName}
                          >
                            <div
                              style={{
                                padding: "20px",
                                backgroundColor: "black",
                              }}
                            >
                              <img
                                src={`${baseURL}/ui-assist/v1/downloadwallpaper?fileName=${item.wallpaper}`}
                                alt="Wallpaper"
                                style={{ height: "100%", width: "100%" }}
                              />
                            </div>
                          </CommonModal>
                          <Grid
                            item
                            xs={12}
                            sm={4}
                            className="searchFilterMultiSelect"
                          >
                            <CustomMultiSelect
                              items={currentLocationList}
                              className="wallpaperLocation"
                              getOptionLabel={getOptionLabel}
                              getOptionDisabled={getOptionDisabled}
                              selectedValues={
                                locationPreference ? locationPreference : []
                              }
                              label={
                                <CommonLabel label="Location preference" />
                              }
                              selectAllLabel="Select all"
                              onToggleOption={handleLocationToggleOption}
                              onClearOptions={handleClearLocation}
                              onSelectAll={handleSelectAllLocations}
                            />
                          </Grid>
                        </Grid>
                      </div>
                      <Grid item xs={12} sm={12}>
                        <p
                          className="heading"
                          style={{ marginBottom: "16px", marginTop: "24px" }}
                        >
                          Additional details
                        </p>
                      </Grid>
                      <div className="border3 pad-desk24-mob0">
                        <Grid
                          container
                          direction="row"
                          spacing={3}
                          className="rollBasedAutoCompplete mb-3"
                        >
                          <Grid item xs={12} sm={4}>
                            <span style={styles.title}>
                              Name of the requester
                            </span>
                            <p style={styles.content}>
                              {item.requesterName && item.requesterName}
                            </p>
                          </Grid>
                          <Grid item xs={12} sm={4}>
                            <span style={styles.title}>Sector</span>
                            <p style={styles.content}>
                              {item.requestSector && item.requestSector}
                            </p>
                          </Grid>
                          <Grid item xs={12} sm={4}>
                            <span style={styles.title}>Location</span>
                            <p style={styles.content}>
                              {item.location && item.location}
                            </p>
                          </Grid>
                          <Grid item xs={12} sm={4}>
                            <span style={styles.title}>Department</span>
                            <p style={styles.content}>
                              {item.departMent && item.departMent}
                            </p>
                          </Grid>
                          <Grid item xs={12} sm={4}>
                            <span style={styles.title}>Contact details</span>
                            <p style={styles.content}>
                              {item.contactDetails == "null" ||
                              item.contactDetails == null
                                ? " "
                                : item.contactDetails}
                            </p>
                          </Grid>
                        </Grid>
                      </div>
                    </Grid>
                  </Paper>
                </TabPanel>
              </>
            );
          })}
        </Grid>
      </Grid>
      <Grid
        container
        direction="row"
        spacing={3}
        justifyContent="flex-end"
        className="rollBasedAutoCompplete mb-4"
        style={{ marginTop: "16px" }}
      >
        <Grid className="mb-0" item xs="auto" sm="auto">
          <SubmitButton
            label="Reject"
            className="modalBackButton"
            onClick={Reject}
          />
        </Grid>
        <Grid className="mb-0" item xs="auto" sm="auto">
          <SubmitButton
            label="Approve"
            className="rollBasedBtn"
            onClick={Approve}
          />
        </Grid>
      </Grid>
    </>
  );
}

const styles = {
  title: {
    fontSize: "12px",
    fontWeight: "400",
    color: "#6d6e71",
    lineHeight: "1.67",
  },
  content: {
    fontSize: "16px",
    fontWeight: "400",
    color: "black",
    lineHeight: "1.5",
  },
  heading: {
    fontSize: "14px",
    fontWeight: "600",
    color: "black",
    lineHeight: "1.57",
  },
  title2: {
    fontSize: "12px",
    fontWeight: "400",
    //color: "#6d6e71",
    lineHeight: "1.67",
    marginBottom: "0px",
  },
};
