import {
  GET_OFFER_LIST_FAILED,
  GET_OFFER_LIST_SUCCESS,
  GET_OFFER_LIST_LOAD,
} from "../Contants/Constants";

const initialState = {
  getData: [],
  getTotalCount: 0,
  isLoading: false,
  isError: "",
};

export const getOfferCampaignListReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_OFFER_LIST_LOAD:
      return {
        ...state,
        isLoading: true,
      };
    case GET_OFFER_LIST_SUCCESS:
      if (action.payload.record.length === 0) {
        return {
          ...state,
          getTotalCount: action?.payload?.totalCount,
          getData:
            action.pageNo === 1
              ? [...action.payload.record]
              : [...state.getData, action.payload.record],
        };
      } else {
        return {
          ...state,
          isLoading: false,
          getTotalCount: action?.payload?.totalCount,
          getData: action.payload.record,
        };
      }

    case GET_OFFER_LIST_FAILED:
      return {
        ...state,
        isLoading: false,
        isError: action.payload,
      };
    default:
      return state;
  }
};
