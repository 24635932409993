import services from "../../Services/index";
import {
    GET_APPROVED_WALLPAPER_LIST_SUCCESS,
    GET_APPROVED_WALLPAPER_LIST_LOAD,
    GET_APPROVED_WALLPAPER_LIST_FAILED,
} from "../Contants/Constants";

const getApprovedWallpaperListLoad = () => ({
    type: GET_APPROVED_WALLPAPER_LIST_LOAD,
});
const getApprovedWallpaperListSuccess = (data) => ({
    type: GET_APPROVED_WALLPAPER_LIST_SUCCESS,
    payload: data,
});
const getApprovedWallpaperListFailed = (error) => ({
    type: GET_APPROVED_WALLPAPER_LIST_FAILED,
    err: error,
});

export const getApprovedWallpaperListData = (data) => async (dispatch) => {
  dispatch(getApprovedWallpaperListLoad());
  try {
    const res = await services.api(
      "POST",
      `/ui-assist/v1/wallpaper/approve`,data
    );
    if (res) {
      dispatch(getApprovedWallpaperListSuccess(res.data));
    }
  } catch (err) {
    dispatch(getApprovedWallpaperListFailed(err.response));
  }
};