import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
} from "@material-ui/core";
import React, { useState } from "react";
import CommonModal from "../../../Common/CommonModal/CommonModal";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import moment from "moment";
import services from "../../../../Services";
import Swal from "sweetalert2";
import PolicyTable from "../AdminPanel/SopTable";
import AddPoliciesModalComponent from "../AddPolicies/AddPoliciesModalComponent";
import CreateSop from "../AdminPanel/CreateSop";
import { getPolicySubCategoryData } from "../../../../Redux/Actions/PolicyCategoryAction";
import { useDispatch } from "react-redux";

import "./sub.css";
import CharLeftField from "../../../Common/CharLeftField/CharLeftField";
import CommonLabel from "../../../Common/ComonLabel/CommonLabel";
import { getSopSubCategoryData } from "../../../../Redux/Actions/SOPsAction";
import SopTable from "../AdminPanel/SopTable";

function Subcategory({ open, handleClose, icon, policyTableData, element }) {
  const [state, setstate] = useState(element);
  const [refresh, setrefresh] = useState(false);
  const dispatch = useDispatch();
  React.useEffect(() => {
    setstate(element);
  }, [element]);

  React.useEffect(() => {
    dispatch(getSopSubCategoryData(element.categoryId));
  }, [refresh]);

  const [subcategoryDescription, setsubcategoryDescription] = useState(
    state?.description
  );
  const [subcategoryTitle, setsubcategoryTitle] = useState(state?.title);
  const [openAddSop, setopenAddSop] = useState(false);

  const handleAddChange = () => {};

  const [error, setError] = useState({
    subcategoryTitleError: "",
    subcatogoryDescriptionError: "",
  });
  const handleEditChange = (e, name) => {
    const { value } = e.target;
    if (value) {
      if (name === "subcategory_name") {
        setsubcategoryTitle(e.target.value);
        setError({ ...error, subcategoryTitleError: "" });
      } else if (name === "subcategory_description") {
        setsubcategoryDescription(e.target.value);
        setError({ ...error, subcatogoryDescriptionError: "" });
      }
    } else if (value === "") {
      if (name === "subcategory_name") {
        setsubcategoryTitle("");
        setError({ ...error, subcategoryTitleError: "this field is required" });
      } else if (name === "subcategory_description") {
        setsubcategoryDescription(e.target.value);
        setError({
          ...error,
          subcatogoryDescriptionError: "this field is required",
        });
      }
    }
  };

  //api edit function

  // const handleSubCategoryEditApi = async (data) => {
  //     try {
  //       const res = await Services.api(
  //         "PUT",
  //         `/ui-assist/v1/policysubcategory`,
  //         data
  //       );
  //       if (res) {
  //         close(false);
  //         Swal.fire({
  //           position: "center",
  //           icon: "success",
  //           title: "category added successfully",
  //           showConfirmButton: false,
  //           timer: 1500,
  //         });
  //         closeEdit(false);
  //         setrefresh(!refresh);
  //         nullItem(null);
  //       }
  //     } catch (err) {
  //       if (err.response) {
  //         Swal.fire({
  //           icon: "error",
  //           title: "Oops...",
  //           text: "Something went wrong!",

  //           footer: `${err.message}`,
  //         });
  //         setrefresh(!refresh);
  //       }
  //     }
  //   };

  const handleSubCategoryEditApi = async (edit_data) => {
    try {
      const res = await services.api(
        "PUT",
        `/ui-assist/v1/sopsubcategory`,
        edit_data
      );
      if (res.status === 200) {
        setsubcategoryTitle("");
        setsubcategoryDescription("");

        Swal.fire({
          position: "center",
          icon: "success",
          title: "subcategory edited successfully",
          showConfirmButton: false,
          timer: 1500,
        });

        setrefresh(!refresh);
      }
    } catch (err) {
      if (err.response) {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Something went wrong!",

          footer: `${err.message}`,
        });
        setrefresh(!refresh);
      }
    }
  };

  const handleEditSubmit = () => {
    let edit_data = {
      sopSubCategoryId: element.sopSubCategoryId,
      categoryId: element.categoryId,
      employeeId: localStorage.getItem("token"),
      description: subcategoryDescription,
      title: subcategoryTitle,
    };

    handleSubCategoryEditApi(edit_data);
  };

  const parentCallback = (a, b) => {};

  // handleSubCategoryEditApi(edit_data);

  // const handleAddPolicy = () => {
  //   setopenPolicyModel(true);
  // };

  const handleEditpolicy = (data) => {};

  const handleDeletepolicy = (data) => {
    Swal.fire("The Internet?", "That thing is still around?", "question");
  };

  const handleDeleteApi = (data) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        services
          .api("DELETE", `/ui-assist/v1/policy/${data.policyId}`)
          .then((res) => {
            Swal.fire("Deleted!", "Policy has been deleted.", "success");
            setrefresh(!refresh);
          })
          .catch((err) => {
            console.log("error", err);
          });
      }
    });
  };

  const { subcategoryTitleError, subcatogoryDescriptionError } = error;
  return (
    <>
      <CommonModal
        open={open}
        handleClose={handleClose}
        headerText="Edit subcategory"
        icon={icon}
        modalSize="lg"
        submitBtnText="SAVE CHANGES"
        cancleBtnText="BACK"
        onButtonClick={handleEditSubmit}
        onCancleButtonClick={handleClose}
        // isBackBtn={true}
        // header={header}
        // footer={footer}
        fullWidth
        policiesButtonSize
        className="editAddPolicyModal"
        disabledSubmitButton={
          !(Boolean(subcategoryTitle) && Boolean(subcategoryDescription))
        }
      >
        <div className="editSubCategory">
          {/*<div style={{display:"flex",alignItems:"center",marginLeft:"20px",marginBottom:"24px"}}>
                    <img
                        src={process.env.PUBLIC_URL + "/policies/arrowleft.svg"}
                        alt="Add"
                        style={{marginRight: "0px" }}
                    />
                    <span style={{fontSize:"12px",color:"#e31837",fontWeight:"600",lineHeight:"2"}}>EDIT CATEGORY</span>
  </div>*/}
          <div className="editSubCPHeader">
            {state && (
              <>
                {/*<TextField
                style={{ padding: "10px" }}
                variant="outlined"
                name="subcategory_name"
                label="Sub-category name"
                onChange={(e) => {
                  handleEditChange(e, "subcategory_name");
                }}
                defaultValue={state.title}
              />

              <TextField
                style={{ padding: "10px", width: "590px" }}
                variant="outlined"
                name="subcategory_description"
                label="Description"
                onChange={(e) => {
                  handleEditChange(e, "subcategory_description");
                }}
                defaultValue={state.description}
              /> */}
                <CharLeftField
                  style={{ padding: "10px" }}
                  label={<CommonLabel label="subcategory_name" mandatory />}
                  handleChange={(e) => {
                    handleEditChange(e, "subcategory_name");
                  }}
                  name="subcategory_name"
                  type="text"
                  errorMsg={subcategoryTitleError ? subcategoryTitleError : ""}
                  defValue={state.title}
                />
                <CharLeftField
                  style={{ padding: "10px", width: "590px" }}
                  label={
                    <CommonLabel label="subcategory_description" mandatory />
                  }
                  handleChange={(e) => {
                    handleEditChange(e, "subcategory_description");
                  }}
                  name="subcategory_description"
                  type="text"
                  errorMsg={
                    subcatogoryDescriptionError
                      ? subcatogoryDescriptionError
                      : ""
                  }
                  defValue={state.description}
                />
              </>
            )}
          </div>

          <div
            style={{
              padding: "0px",
              display: "flex",
              justifyContent: "space-between",
              marginLeft: "24px",
              marginRight: "24px",
            }}
          >
            <p
              style={{ fontSize: "18px", fontWeight: "600", marginTop: "16px" }}
            >
              SOPs
            </p>
            <div className="editPoliciesStructure">
              <img
                src={process.env.PUBLIC_URL + "/policies/add-fill.svg"}
                alt="Add"
                style={{ marginRight: "-3px" }}
              />
              <span
                className="editPolicies"
                onClick={() => {
                  setopenAddSop(true);
                }}
                style={{ padding: "0px", cursor: "pointer" }}
              >
                Add SOP
              </span>
            </div>
          </div>
          <SopTable element={state} />

          <CreateSop x={openAddSop} y={setopenAddSop} element={state} />
        </div>
      </CommonModal>
    </>
  );
}

export default Subcategory;
