import React from "react";
import { Avatar, Grid } from "@material-ui/core";

const AuthorItem = ({ label, userName, userPhoto = null }) => {
  return (
    <>
      <Grid item sm={4} md={3}>
        <div className="avatarDetailsAlignment">
          {userPhoto ? (
            <img
              src={userPhoto ? userPhoto : `/header/profile.png`}
              alt=""
              className={
                userPhoto ? "avatar mc2Profle" : "avatar mc2Profle defaulticon"
              }
            />
          ) : (
            <Avatar></Avatar>
          )}
          <div className="avatarNameDate">
            <p className="avatarDate">{label}:</p>
            <p className=" articleAvatarName">
              {userName ? userName : "Not Available"}
            </p>
          </div>
        </div>
      </Grid>
    </>
  );
};

export default AuthorItem;
