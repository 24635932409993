import {
    PUBLISH_TO_WHATS_NEW_SUCCESS,
    PUBLISH_TO_WHATS_NEW_LOAD,
    PUBLISH_TO_WHATS_NEW_FAILED,
} from "../Contants/Constants";

const initialState = {
  getData: [],
  isLoading: false,
  isError: "",
  status: "idle",
};

const getPublishToWhatsNewReducer = (state = initialState, action) => {
  switch (action.type) {
    case PUBLISH_TO_WHATS_NEW_LOAD:
      return {
        ...state,
        isLoading: true,
        status: "pending",
      };
    case PUBLISH_TO_WHATS_NEW_SUCCESS:
      return {
        ...state,
        getData: action.payload,
        isLoading: false,
        status: "success",
      };
    case PUBLISH_TO_WHATS_NEW_FAILED:
      return {
        ...state,
        isError: action.payload,
        isLoading: false,
        status: "failed",
      };
    default:
      return state;
  }
};

export default getPublishToWhatsNewReducer;
