import React, { useState, useEffect } from "react";
import "./CreatePolicy.css";
import leftArrow from "../../../../Assets/Images/WhatsNewIcons/arrow-right-s-line.svg";

import textFieldStyle from "../../../Common/Styles/TextFieldStyle";
import TextArea from "../../../Common/TextArea/TextArea";
import CommonMultiAutocomplete from "../../../Common/CommonMultiAutocomplete/CommonMultiAutocomplete";
import CommonLabel from "../../../Common/ComonLabel/CommonLabel";
import SubmitButton from "../../../Common/Button/SubmitButton";
import services from "../../../../Services";
import CommonModal from "../../../Common/CommonModal/CommonModal";
import editIcon from "../../../../Assets/Images/edit-line.svg";

import Swal from "sweetalert2";
import { useDispatch } from "react-redux";
import { getPolicyTableDataByID } from "../../../../Redux/Actions/PolicyCategoryAction";

//upload related
import { uploadStorySchema } from "../../../../Assets/formikErrors/SubmitOfferCampaignSchema";
import uploadIcon from "../../../../Assets/Images/collectiveIcons/upload-2-line.svg";

import moment from "moment";
import { Grid, TextField } from "@material-ui/core";
import MultiSelect from "../../../Common/MultiSelect/MultiSelect";
import CommonCheckBox from "../../../Common/CommonCheckBox/CommonCheckBox";
import Loader from "../../../Common/Loader/Loader";
import CustomMultiSelect from "../../../Common/CustomMultiSelect/CustomMultiSelect";
import CharLeftField from "../../../Common/CharLeftField/CharLeftField";
import DatePicker from "../../../Common/DatePicker/DatePicker";
import { getFormattedDate } from "../../../../Assets/helperFunctions/helperFunctions";
// import services from "../../../../Services";
//import CommonLabel from "../../../Common/ComonLabel/CommonLabel";
function CreatePolicy({ x, y, element }) {
  const dispatch = useDispatch();

  const classes = textFieldStyle();

  const [policyName, setpolicyName] = useState("");
  const [policyPdf, setpolicyPdf] = useState(null);
  const [policyDescription, setpolicyDescription] = useState("");
  const [policyObjective, setpolicyObjective] = useState("");
  const [policyEligibility, setpolicyEligibility] = useState("");
  const [policyGuidlines, setpolicyGuidlines] = useState("");
  const [policyKey, setpolicyKey] = useState("");
  //Added by Sagar for MR-982
  const [effectiveDate, setEffectiveDate] = useState(new Date());

  const [sector, setSector] = useState("");
  const [businessUnit, setBusinessUnit] = useState("");
  const [businessUnitData, setBusinessUnitData] = useState([]);
  const [hastagsLeft, setHastagsLeft] = React.useState(3);

  const [policyBuiseness, setpolicyBuiseness] = useState("");
  const [refresh, setrefresh] = useState(false);
  const [businessGroupValue, setbusinessGroupValue] = React.useState([]);

  const [checkThumbnail, setCheckThumbnail] = useState(null);
  const [isNotify, setIsNotify] = useState(false);
  const [confirmNotify, setConfirmNotify] = useState(false);

  const [sectorData, setSectorData] = useState([]);
  const [getDivisionList, setGetDivisionList] = useState([]);
  const [loading, setLoading] = useState(true);

  //error handeling for policies.
  const [error, setError] = useState({
    policyNameError: "",
    policyPdfError: "",
    policyDescriptionError: "",
    policyObjectiveError: "",
    policyEligibilityError: "",
    policyGuidlinesError: "",
    policySectorError: "",
    policyHashtagError: "",
    policyBusinessError: "",
    effectiveDateError: "",
  });

  //CUSTOM MULTISELECT.
  const getOptionLabel = (option) => `${option.title}`;

  const getOptionDisabled = (option) => option.value === "foo";
  const [selectedSectorOptions, setSelectedSectorOptions] = useState([]);
  const [selectedBusinessUnitOptions, setSelectedBusinessUnitOptions] =
    useState([]);
  const [sectorListData, setSectorListData] = useState([]);
  const [buisnessUnitList, setBuisnessUnitList] = useState([]);

  const [slectedBuRev, setSlectedBuRev] = useState([]);
  const [selectedSectorRev, setSelectedSectorRev] = useState([]);
  //to get selected sector list.
  useEffect(() => {
    let dataSectorList = selectedSectorOptions?.map((el) => {
      return el.value;
    });

    let sectorList = selectedSectorOptions?.map((el) => {
      return {
        sectorId: el.value,
        sectorName: el.title,
      };
    });
    setSelectedSectorRev(sectorList);
    setSectorListData(dataSectorList.join(",").toString());
  }, [selectedSectorOptions]);

  //to get selected bu list.
  useEffect(() => {
    let dataBuList = selectedBusinessUnitOptions?.map((el) => {
      return el.value;
    });
    let buList = selectedBusinessUnitOptions?.map((el) => {
      return {
        buId: el.value,
        buName: el.title,
      };
    });
    setSlectedBuRev(buList);
    setBuisnessUnitList(dataBuList.join(",").toString());
  }, [selectedBusinessUnitOptions]);
  //handle toggle for all the dropdowns
  const handleToggleOption = (selectedOptions, name) => {
    if (name === "sector") {
      setSelectedSectorOptions(selectedOptions);
      getSelectedSectorOptions(selectedOptions);
    } else if (name === "bu") {
      setSelectedBusinessUnitOptions(selectedOptions);
    }
  };

  //handle clear for all the dropdown's
  const handleClearOptions = (isSelected, name) => {
    if (name === "sector") {
      setSelectedSectorOptions([]);
      setSelectedBusinessUnitOptions([]);
      setBusinessUnitData([]);
    } else if (name === "bu") {
      setSelectedBusinessUnitOptions([]);
      //setSelectedSectorOptions([]);
      // setBusinessUnitData([]);
    }
  };

  //handle select all for dropdown's.
  const handleSelectAll = (isSelected, name) => {
    if (isSelected) {
      if (name === "sector") {
        setSelectedSectorOptions(sectorData);
        getSelectedSectorOptions(sectorData);
      } else if (name === "bu") {
        setSelectedBusinessUnitOptions(businessUnitData);
      } else {
        handleClearOptions();
      }
    }
  };

  useEffect(() => {
    // dispatch(getPolicyTableDataByID(element.policySubCategoryId));
  }, [refresh]);

  //get all sectors.
  useEffect(() => {
    (async () => {
      try {
        let moduleName = "POLICIES";
        setLoading(true);
        const res = await services.api(
          "GET",
          `/ui-assist/v1/rollmatrix/adminsectors/${localStorage.getItem(
            "token"
          )}?modulename=${moduleName}`
        );
        if (res.status === 200) {
          setLoading(false);
          const allSectorItem = res?.data?.map((el) => {
            return {
              title: el.sectorName,
              value: el.id,
            };
          });
          setSectorData(allSectorItem);
          //setBuisnessUnitList()
        }
      } catch (err) {
        setLoading(false);
        Swal.fire("Oops!", `${err.response.data}`, "error");
        setSectorData([]);
      }
    })();
  }, []);

  const getSelectedSectorOptions = (selectedOptions) => {
    const xyz = selectedOptions.map((el) => {
      return {
        addsectorId: el.value,
      };
    });
    const sectorList = selectedOptions.map((el) => el.value);
    getBusinessUnitBySector(sectorList.join(",").toString());
  };

  //get business unit on the sector params.
  // const getBusinessUnitBySector = async (data) => {
  //   try {
  //     setLoading(true);
  //     let res = await services.api("POST", `/ui-assist/v1/businessunit`, data);
  //     if (res.status === 200) {
  //       setLoading(false);
  //       let list = res.data.map((result) => {
  //         return {
  //           title: result.buName,
  //           value: result.id,
  //         };
  //       });
  //       let divisionList = res.data.map((result) => {
  //         return {
  //           addBuId: result.id,
  //         };
  //       });
  //       setSelectedBusinessUnitOptions(list);
  //       setBusinessUnitData(list);
  //     }
  //   } catch (err) {
  //     setLoading(false);
  //     Swal.fire("Oops!", `${err.response.data}`, "error");
  //   }
  // };

  const getBusinessUnitBySector = async (data) => {
    console.log(data, "data...");
    try {
      let moduleName = "POLICIES";
      setLoading(true);
      let res = await services.api(
        "POST",
        `/ui-assist/v1/rollmatrix/adminbu/${localStorage.getItem(
          "token"
        )}?modulename=${moduleName}&sectorid=${data}`
      );
      if (res.status === 200) {
        setLoading(false);
        let list = res.data.map((result) => {
          return {
            title: result.buName,
            value: result.id,
          };
        });
        let divisionList = res.data.map((result) => {
          return {
            addBuId: result.id,
          };
        });
        setSelectedBusinessUnitOptions(list);
        setBusinessUnitData(list);
      }
    } catch (err) {
      setLoading(false);
      Swal.fire("Oops!", `${err.response.data}`, "error");
    }
  };

  const handleSector = (value) => {
    let sectorArray = value.map((el) => el.value);
    setSector(sectorArray.join(",").toString());
  };

  const handleBusiness = (value) => {
    let buisnessArray = value.map((el) => el.value);
    setBusinessUnit(buisnessArray.join(",").toString());
  };

  //get division by sector id
  const getDivsionBySector = async (sectorCode) => {
    const getSectors = sectorCode.map((result) => {
      return {
        addsectorId: result.value,
      };
    });

    try {
      setLoading(true);
      let res = await services.api(
        "POST",
        `/ui-assist/v1/rollmatrix/adminbu/${localStorage.getItem("token")}`,
        getSectors
      );
      if (res.status === 200) {
        setLoading(false);
        let list = res.data.map((result) => {
          return {
            title: result.buName,
            value: result.id,
          };
        });
        setGetDivisionList(list);
      }
    } catch (err) {
      setLoading(false);
      Swal.fire("Oops!", `${err.response.data}`, "error");
      setGetDivisionList([]);
    }
  };

  const onDateChange = (x, y) => {
    console.log(x, y, "x and y");
    setEffectiveDate(x);
    setError({ ...error, effectiveDateError: "" });
  };

  const handleBusinessUnit = (bunit) => {};

  const handleNotificationChange = (event) => {
    if (isNotify) {
      setIsNotify(false);
      setConfirmNotify(false);
    } else {
      setIsNotify(true);
    }
  };
  const handleConfirmNotificationChange = (event) => {
    setConfirmNotify(!confirmNotify);
  };

  const handleSubmit = () => {
    const formData = new FormData();
    formData.append("scope", "gloable");
    formData.append("policyName", policyName);
    formData.append("subcategory", element.policySubCategoryId);
    formData.append("description", policyDescription);
    formData.append("employeeId", localStorage.getItem("token"));
    formData.append("policy_pdf_link", policyPdf);
    formData.append("objectives", policyObjective);
    formData.append("limits", "limits not assigned");
    formData.append("eligibility", policyEligibility);
    formData.append("generalGuidLines", policyGuidlines);
    formData.append("keys", policyKey.split(","));
    formData.append("sectorList", sectorListData);
    formData.append("buList", buisnessUnitList);
    formData.append("buListDropDown", JSON.stringify(slectedBuRev));
    formData.append("sectorListDropDown", JSON.stringify(selectedSectorRev));
    formData.append("isNotify", confirmNotify);
    formData.append(
      "effectiveDate",
      moment(effectiveDate).format("YYYY-MM-DD")
    );

    let data = formData;
    handlePolicySubmitApi(data);
  };
  const handleCloseModel = () => {
    y(false);
  };
  const onBackButtonHandler = () => {
    y(false);
  };
  const handleChange = (e) => {
    const { value, name } = e.target;
    let errorClone = { ...error };
    if (value) {
      if (name === "policy_name") {
        setpolicyName(e.target.value);
        setError({ ...error, policyNameError: "" });
      } else if (
        name === "pdf" &&
        value.match(/.pdf/gm)?.toString() === ".pdf"
      ) {
        setpolicyPdf(e.target.files[0]);
        setError({ ...error, policyPdfError: "" });
      } else if (
        name === "pdf" &&
        value.match(/.pdf/gm)?.toString() !== ".pdf"
      ) {
        setpolicyPdf(null);
        setError({ ...error, policyPdfError: "not a compatible format" });
      } else if (name === "description") {
        setpolicyDescription(value);
        setError({ ...error, policyDescriptionError: "" });
      } else if (name === "objective") {
        setpolicyObjective(value);
        setError({ ...error, policyObjectiveError: "" });
      } else if (name === "description") {
        setpolicyDescription(value);
        setError({ ...error, policyDescriptionError: "" });
      } else if (name === "eligibility") {
        setpolicyEligibility(value);
        setError({ ...error, policyEligibilityError: "" });
      } else if (name === "guidelines") {
        setpolicyGuidlines(value);
        setError({ ...error, policyGuidlinesError: "" });
      } else if (name === "key") {
        setpolicyKey(value);
        setError({ ...error, policyHashtagError: "" });
      }
    }
    if (value === "") {
      if (name === "policy_name") {
        setpolicyName("");
        setError({ ...error, policyNameError: "this field is required" });
      } else if (name === "pdf") {
        setpolicyPdf(null);
        setError({ ...error, policyPdfError: "this field is required" });
      } else if (name === "description") {
        setpolicyDescription("");
        setError({
          ...error,
          policyDescriptionError: "this field is required",
        });
      } else if (name === "objective") {
        setpolicyObjective("");
        setError({ ...error, policyObjectiveError: "this field is required" });
      } else if (name === "eligibility") {
        setpolicyEligibility("");
        setError({
          ...error,
          policyEligibilityError: "this field is required",
        });
      } else if (name === "guidelines") {
        setpolicyGuidlines("");
        setError({ ...error, policyGuidlinesError: "this field is required" });
      } else if (name === "key") {
        setpolicyKey("");
        setError({ ...error, policyHashtagError: "this field is required" });
      }
    }
    // if (name === "name") {
    //     setpolicyName(e.target.value);
    // }
    // if (name === "Description") {
    //     setpolicyDescription(e.target.value);
    // }
    // if (name === "Objective") {
    //     setpolicyObjective(e.target.value);
    // }
    // if (name === "Eligibility") {
    //     setpolicyEligibility(e.target.value);
    // }
    // if (name === "Guidlines") {
    //     setpolicyGuidlines(e.target.value);
    // }
    // if (name === "pdf") {
    //     setpolicyPdf(e.target.files[0]);
    // }
  };

  const handlePolicySubmitApi = async (data) => {
    try {
      setLoading(true);
      const res = await services.api("POST", `/ui-assist/v1/policy`, data);
      if (res.status === 201) {
        setLoading(false);
        // y(false);
        Swal.fire({
          position: "center",
          icon: "success",
          title: "Policy Added Successfully",
          showConfirmButton: false,
          timer: 1500,
        });
        dispatch(getPolicyTableDataByID(element.policySubCategoryId));
        setpolicyPdf(null);
        setrefresh(!refresh);
        y(false);
      }
    } catch (err) {
      setLoading(false);
      if (err.response) {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Something went wrong!",
          footer: '<a href="">Why do I have this issue?</a>',
        });
        y(false);
      }
    }
  };

  return (
    <>
      <CommonModal
        open={x}
        handleClose={handleCloseModel}
        headerText="Add policy"
        icon={editIcon}
        modalSize="lg"
        submitBtnText="SAVE CHANGES"
        cancleBtnText="BACK"
        onButtonClick={handleSubmit}
        fullWidth
        policiesButtonSize
        onCancleButtonClick={onBackButtonHandler}
        disabledSubmitButton={
          !(
            Boolean(policyName) &&
            Boolean(policyPdf) &&
            Boolean(policyDescription) &&
            Boolean(policyGuidlines) &&
            Boolean(policyObjective) &&
            Boolean(isNotify == confirmNotify) &&
            Boolean(policyKey) &&
            Boolean(sectorListData)
          )
        }
      >
        <div className="leftRightAlign mb16">
          <div
            style={{
              display: "flex",
              alignItems: "center",
              marginLeft: "0px",
              marginBottom: "24px",
            }}
          >
            {/*<img
              src={process.env.PUBLIC_URL + "/policies/arrowleft.svg"}
              alt="Add"
              style={{ marginRight: "0px" }}
            />
            <span
              style={{
                fontSize: "12px",
                color: "#e31837",
                fontWeight: "600",
                lineHeight: "2",
              }}
            >
              EDIT SUB-CATEGORY
            </span> */}
          </div>
          <Grid
            container
            direction="row"
            alignItems="center"
            spacing={3}
            className="editPolicyInnerHeader"
          >
            <Grid item sm={3} xs={12} className="addPolicyFeild1">
              {/*<TextField
                                variant="outlined"
                                name="name"
                                label="Policy name"
                                className={`${classes.root} fullWidth customTextfield`}
                                onChange={(e) => {
                                    handleChange(e, "name");
                                }}
                            /> */}
              <CharLeftField
                label={<CommonLabel label="Policy name" mandatory />}
                handleChange={(e) => handleChange(e)}
                name="policy_name"
                type="text"
                errorMsg={error.policyNameError ? error.policyNameError : ""}
              />
            </Grid>
          </Grid>
          <Grid container direction="row" alignItems="center" spacing={3}>
            <Grid item xs={12} md={3}>
              <DatePicker
                // disabled={edit}

                label={<CommonLabel label="Effective Date" />}
                selectedDate={effectiveDate}
                minDate={new Date().setDate(new Date().getDate())}
                handleDateChange={(e, x) => {
                  onDateChange(e, x);
                  console.log(e, x, "event>>>>>>>>>>>>>>>>>>>>");
                }}
                name="effectiveDate"
                disableToolbarr={true}
              />
            </Grid>
            <Grid item md={3} xs={12} className="uploadBtnStyle">
              {loading ? <Loader /> : ""}
              <div className="uploadBtn btnEditSub">
                <label for="thumb" className="btn uploadBtnLabel">
                  {policyPdf ? policyPdf.name : " Upload Pdf"}{" "}
                  <span className="colRed">*</span>
                </label>
                <img
                  for="thumb"
                  src={uploadIcon}
                  className="uploadIconStyl"
                  alt="Upload Button Icon"
                />
                <input
                  id="thumb"
                  style={{
                    visibility: "hidden",
                    padding: "19px 16px",
                    position: "absolute",
                  }}
                  type="file"
                  //accept=".pdf"
                  name="pdf"
                  onChange={(e) => {
                    handleChange(e);
                  }}
                  accept="application/pdf"
                />
              </div>
              {error.policyPdfError ? (
                <span style={{ color: "red", fontSize: "12px" }}>
                  {error.policyPdfError}
                </span>
              ) : (
                ""
              )}
            </Grid>
            <div className="compatiblityTxt">Compatible format: Pdf.</div>
            <div className="compatiblityTxt">Max size: 10mb</div>
          </Grid>

          <Grid container direction="row" alignItems="center" spacing={3}>
            <Grid item sm={12} xs={12}>
              {/*<TextField
                variant="outlined"
                name="Description"
                label="Description"
                className={`${classes.root} fullWidth customTextfield`}
                onChange={(e) => {
                  handleChange(e, "Description");
                }}
            /> */}
              <CharLeftField
                label={<CommonLabel label="Description" mandatory />}
                handleChange={(e) => handleChange(e)}
                name="description"
                type="text"
                errorMsg={
                  error.policyDescriptionError
                    ? error.policyDescriptionError
                    : ""
                }
              />
            </Grid>
            <Grid item sm={12} xs={12}>
              {/*<TextField
                variant="outlined"
                name="Objective"
                label="Objective"
                className={`${classes.root} fullWidth customTextfield`}
                onChange={(e) => {
                  handleChange(e, "Objective");
                }}
            />*/}
              <CharLeftField
                label={<CommonLabel label="Objective" mandatory />}
                handleChange={(e) => handleChange(e)}
                name="objective"
                type="text"
                errorMsg={
                  error.policyObjectiveError ? error.policyObjectiveError : ""
                }
              />
            </Grid>
            <Grid item sm={12} xs={12}>
              {/*<TextField
                variant="outlined"
                name="Eligibility"
                label="Eligibility"
                className={`${classes.root} fullWidth customTextfield`}
                onChange={(e) => {
                  handleChange(e, "Eligibility");
                }}
            /> */}
              <CharLeftField
                label={<CommonLabel label="Eligibility" mandatory />}
                handleChange={(e) => handleChange(e)}
                name="eligibility"
                type="text"
                errorMsg={
                  error.policyEligibilityError
                    ? error.policyEligibilityError
                    : ""
                }
              />
            </Grid>
            <Grid item sm={12} xs={12}>
              <CharLeftField
                label={<CommonLabel label="Key" mandatory />}
                handleChange={(e) => handleChange(e)}
                name="key"
                errorMsg={
                  error.policyHashtagError ? error.policyHashtagError : ""
                }
                wordCount={hastagsLeft}
                type="text"
              />
            </Grid>
            <Grid item sm={12} xs={12}>
              {/*<TextField
                label="Guidelines"
                name="Guidlines"
                multiline
                rows={8}
                placeholder="general guidelines"
                className={`${classes.root} fullWidth customTextfield sairaFp editSub`}
                onChange={(e) => {
                  handleChange(e, "Guidlines");
                }}
            /> */}

              <CharLeftField
                label={<CommonLabel label="Guidelines" mandatory />}
                handleChange={(e) => handleChange(e)}
                name="guidelines"
                type="text"
                errorMsg={
                  error.policyGuidlinesError ? error.policyGuidlinesError : ""
                }
                rows={8}
                multiline={true}
              />
            </Grid>
            <Grid container spacing={3} style={{ padding: "16px 12px" }}>
              <Grid
                item
                sm={3}
                xs={12}
                className="policiesMultiIcon policiesAlignMulti"
              >
                <CustomMultiSelect
                  items={sectorData}
                  getOptionLabel={getOptionLabel}
                  getOptionDisabled={getOptionDisabled}
                  selectedValues={selectedSectorOptions}
                  label={<CommonLabel label="Sector" mandatory />}
                  selectAllLabel="Select all"
                  onToggleOption={(isSelected) => {
                    handleToggleOption(isSelected, "sector");
                  }}
                  onClearOptions={(isSelected) => {
                    handleClearOptions(isSelected, "sector");
                  }}
                  onSelectAll={(isSelected) => {
                    handleSelectAll(isSelected, "sector");
                  }}
                />
              </Grid>
              <Grid item sm={3} xs={12} className="policyBU policiesAlignMulti">
                <CustomMultiSelect
                  items={businessUnitData}
                  getOptionLabel={getOptionLabel}
                  getOptionDisabled={getOptionDisabled}
                  selectedValues={selectedBusinessUnitOptions}
                  label={<CommonLabel label="Businesses" />}
                  selectAllLabel="Select all"
                  onToggleOption={(isSelected) => {
                    handleToggleOption(isSelected, "bu");
                  }}
                  onClearOptions={(isSelected) => {
                    handleClearOptions(isSelected, "bu");
                  }}
                  onSelectAll={(isSelected) => {
                    handleSelectAll(isSelected, "bu");
                  }}
                />
              </Grid>
            </Grid>
            {/* <Grid
              item
              // sm={3}
              xs={6}
              className="calenderIcon appPolicyContainer"
            ></Grid> */}
            <Grid item xs="auto" sm="auto" className="checkPad">
              <CommonCheckBox
                checked={isNotify}
                handleChange={handleNotificationChange}
              />
            </Grid>
            <Grid item xs="auto" sm="auto">
              <span
                style={{
                  fontSize: "16px",

                  fontWeight: "500",
                  color: "black",
                }}
              >
                Send notification to all users
              </span>
            </Grid>

            {isNotify && (
              <Grid container spacing={3} className="ps-1">
                <Grid item xs={2} sm="auto" className="checkPad1 ms-2">
                  <CommonCheckBox
                    checked={confirmNotify}
                    handleChange={handleConfirmNotificationChange}
                  />
                </Grid>
                <Grid item xs={10} sm="auto">
                  <span
                    style={{
                      fontSize: "15px",

                      fontWeight: "500",
                      color: "red",
                    }}
                  >
                    I agree to send notification to all users.
                  </span>
                </Grid>
              </Grid>
            )}
          </Grid>
        </div>
      </CommonModal>
    </>
  );
}

export default CreatePolicy;
