import React, { useState, useEffect } from "react";
import "../SiteMap/SiteMap.css";
import "./LegalDisclaimer.css";
import { Grid, Paper } from "@material-ui/core";
import Template from "../../Common/Template/Template";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";

export default function LegalDisclaimerr() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <section>
      <>
        <Template>
          <Grid
            container
            direction="row"
            justifyContent="space-between"
            alignItems="center"
          >
            <Grid item xs={12} sm={7}>
              <p className="offCHeaderTitle mbT">Legal Disclaimer</p>
            </Grid>
            <Grid
              item
              xs={12}
              sm={5}
              style={{
                display: "flex",
                justifyContent: "flex-end",
                alignItems: "center",
                marginBottom: "38px",
              }}
            >
              {/* <div className="siteCrump">
                VIEW POLICIES <KeyboardArrowRightIcon className="iconsC" />{" "}
              </div>
              <div className="siteCrump mlCB">
                MY DETAILS <KeyboardArrowRightIcon className="iconsC" />{" "}
              </div> */}
            </Grid>
          </Grid>
          <Paper style={{ marginBottom: "16px" }} className="sitepaper siteFF">
            <Grid
              container
              direction="row"
              justifyContent="space-between"
              alignItems="center"
            >
              <Grid item xs={12} sm={12} className="siteTitle">
                <p
                  style={{ marginLeft: "16px", marginTop: "16px" }}
                  className="cardTitleS"
                >
                  TERMS OF USE
                </p>
              </Grid>
              <Grid
                container
                direction="row"
                justifyContent="flex-start"
                alignItems="center"
                style={{ padding: "16px" }}
              >
                <Grid item xs={12} sm={12}>
                  <strong>
                    THIS DOCUMENT IS AN ELECTRONIC RECORD IN TERMS OF THE
                    INFORMATION TECHNOLOGY ACT, 2000 AND RULES MADE THEREUNDER.
                    THIS ELECTRONIC RECORD IS GENERATED BY A COMPUTER SYSTEM AND
                    DOES NOT REQUIRE ANY PHYSICAL OR DIGITAL SIGNATURES. PLEASE
                    CAREFULLY READ THESE TERMS OF USE. BY USING THIS WEBSITE,
                    YOU INDICATE YOUR UNDERSTANDING, CONSENT AND ACCEPTANCE OF
                    THESE TERMS IN ADDITION TO THE GENERAL TERMS OF USE OF THE
                    WEBSITE, IF ANY.
                  </strong>
                  <div style={{ marginTop: "16px" }}>
                    These Terms of Use govern your, i.e. the Authorizer User’s
                    (as defined below), access and use of the Rise@Work website
                    platform available at https://work.mahindra.com
                    and on the mobile app named MeConnect (the “Website”) and
                    also the 3rd party content available on the Website and the
                    Platform. Please read these Terms of Use (the "Agreement" or
                    "Terms of Use") carefully before using the services offered
                    or information displayed on this Website. This Agreement
                    sets forth the legally binding terms and conditions for your
                    use of the Website. If you do not agree to these terms, you
                    may not use this Website. “We”/ “M&M”/ “Company” refers to
                    Mahindra & Mahindra Limited, including its subsidiaries and
                    affiliates (also referred to as "we/our/us"). “You/ you”/
                    “Your/your”/ “User”/ “Authorised User” refers to the person
                    or entity accessing the Website and/or availing the content,
                    services or products offered through the Website. This
                    Website and the content, products and services offered
                    through the Website, if any are intended only for the
                    employees of the Mahindra Group and the contents of this
                    Website are subject to the matters set out below, which are
                    to be read in conjunction with the Privacy Policy and
                    Disclaimers appearing on this Website. By accessing and
                    browsing the Website or by using and/or downloading any
                    content from the same, you agree and accept the Terms of Use
                    as set forth below.
                  </div>
                  <br />
                  <strong style={{ marginTop: "16px" }}>
                    1. ACCEPTANCE OF TERMS OF USE
                  </strong>
                  <div>
                    Your use or viewing the Website is subject to your
                    acceptance of all of the terms and conditions contained in
                    these Terms of Use, including the Disclaimers and Privacy
                    Policy available on the Website, and all other operating
                    rules, policies, and procedures that may be published on the
                    Website by the Company, which are incorporated by reference.
                    These Terms of Use apply to every user of the Website. In
                    addition, some services offered through the Website may be
                    subject to additional terms and conditions adopted by the
                    Company or issued by a third party offering such service or
                    content through the Website. Your use of those services is
                    subject to those additional terms and conditions, which are
                    incorporated into these Terms of Use by this reference. We
                    reserve the right, at our sole discretion, to modify or
                    replace these Terms of Use by posting the updated terms on
                    the Website. It is your responsibility to check the Terms of
                    Use periodically for any changes. Your continued use of the
                    Website following the posting of any changes to the Terms of
                    Use constitutes automatic acceptance of those changes. We
                    reserve the right to change, suspend, or discontinue the
                    Website (including, but not limited to, the availability of
                    any feature, database, services or content) or any part
                    thereof, at any time for any reason. We may also impose
                    limits on certain features and services or restrict your
                    access to parts or all of the services of Website without
                    notice or liability. The Website and its contents are
                    available only to individuals who are employees of the
                    Mahindra Group. You represent and warrant that if you are an
                    individual, you are at least 18 years old and of legal age
                    in your jurisdiction to form a binding contract, and that
                    any personal information you may submit will be accurate and
                    truthful. We may, in our sole discretion, refuse to offer
                    the Website or a part thereof to any person and change the
                    eligibility criteria mentioned herein at any time. This
                    provision is void where prohibited by law and the right to
                    access the Website is revoked in those. jurisdictions.
                  </div>
                  <div style={{ marginTop: "16px" }}>
                    <strong>2. Purpose of the website</strong>
                    <div>
                      All the materials, information, services, features,
                      capabilities, etc (collectively referred to as the
                      “Materials”) contained in the Website are provided for the
                      consumption of the Authorised Users only as per the Terms
                      of Use and as such the Authorised Users agree that no
                      Materials will be copied, reproduced, downloaded,
                      disseminated, or used in a manner not permitted under
                      these Terms of Use.
                    </div>
                  </div>
                  <div style={{ marginTop: "16px" }}>
                    <strong>3. General Disclaimer</strong>
                    <div>
                      a. Your use of this Website (including, without
                      limitation, all other links appearing therein, all
                      content, software, functions, services, materials and
                      information made available or described on this Website or
                      accessed by means thereof), and any marketing or
                      promotional activities or any other item or service
                      provided via this Website (“Ancillary Service”), is at
                      your sole risk. The Website is provide on an “As is” and
                      “As available” basis. <br /> b. M&M shall use third-party
                      service providers to assist M&M in improving our Services
                      and Website and to monitor our Users’ interests and
                      activities. You hereby authorise M&M and/or third party
                      service providers engaged by M&M to collect, use, store,
                      analyse, reproduce, publish, and adapt (either on its own
                      or through third party service provider) the information
                      in relation to Your use of the Website for the purpose of
                      data analysis and for improving Your experience on the
                      Website. You acknowledge that this is solely undertaken by
                      M&M to improve Your experience in relation to the use of
                      the Website and the provision of such Services shall be
                      subject to such additional terms and conditions of M&M
                      and/or third party service providers. <br />
                      c. While we make every reasonable effort to ensure that
                      the information contained in the Website is correct.
                      However the information may be incomplete, inaccurate or
                      may have become outdated and hence the accuracy,
                      correctness and completeness of the information or
                      Materials available on the Website cannot be guaranteed or
                      warranted. We reserve the right to amend, delete, modify
                      or restrict the information/ Materials available to you on
                      the Website at any time. We also reserve the right to
                      restrict or terminate your access to the Website or any
                      feature or part thereof at any time. <br /> d. We have no
                      special relationship with or fiduciary duty to you. We do
                      not guarantee the authenticity of any data or information
                      that the users provide about themselves. You acknowledge
                      that we have no duty to take any action regarding any of
                      the following: • What effects the content on the Website
                      may have on the users • How the users of the Website may
                      interpret or use the content • What actions users may take
                      as a result of having been exposed to the content <br />{" "}
                      e. You release us from all liability for you having
                      acquired or not acquired information or content through
                      the Website. The Website may contain, or direct you to
                      other websites containing information that some people may
                      find offensive or inappropriate. We make no representation
                      concerning any content on the Website, and you agree to
                      hold us not liable for the accuracy, copyright violations,
                      trademark violations, legality or decency of the material
                      contained in the Website. <br /> f. Neither us nor any of
                      our officers, directors, employees, representatives,
                      agents, subsidiaries or affiliates make any representation
                      or warranty of any kind, express or implied, including but
                      not limited to, the operation or use of this Website, or
                      as to the information content, or materials included on
                      the Website. To the fullest extent permissible under
                      Applicable Law, we disclaim all warranties, express or
                      implied, including but not limited to, the express or
                      implied warranties of merchantability and fitness for a
                      particular purpose, title, compatibility, security of
                      information, accuracy, non-infringement, or arising in the
                      course of dealing or the course of performance relating to
                      the operation or use of this Website. <br /> g. In no
                      event shall we, our directors, employees, agents,
                      partners, suppliers, or content providers, be liable under
                      contract, tort, strict liability, negligence, or any other
                      legal or equitable theory with respect the services
                      available on the Website (i) for loss of profits, data
                      loss or direct, special, indirect, incidental, punitive or
                      consequential losses or damages of any kind whatsoever;
                      (ii) for any bugs, viruses, trojan horses, or the like
                      (regardless of the source of origin). <br /> h. No
                      information provided on this Website shall be considered a
                      substitute for your independent investigation. The
                      information available on the Website does not constitute a
                      professional advice, consultation, and/or service. <br />{" "}
                      i. YOU ACKNOWLEDGE AND AGREE THAT IN NO EVENT WILL WE
                      AND/OR OUR HOLDING COMPANY SUBSIDIARIES, AFFILIATES, OR
                      THIRD PARTY CONTENT PROVIDERS, LICENSORS AND/OR SUPPLIERS
                      BE LIABLE FOR ANY DIRECT, INDIRECT, INCIDENTAL,
                      CONSEQUENTIAL, SPECIAL, EXEMPLARY, PUNITIVE OR OTHER
                      DAMAGES RELATING TO THE USE, MISUSE OR INABILITY TO USE
                      THIS WEBSITE OR ANY MATERIALS CONTAINED THEREIN (WHETHER
                      BASED ON CONTRACT, TORT, NEGLIGENCE, STRICT LIABILITY OR
                      OTHERWISE, AND EVEN IF WE AND/OR OUR SUBSIDIARIES,
                      AFFILIATES, LICENSORS AND/OR SUPPLIERS HAS OR HAVE BEEN
                      ADVISED OF A POSSIBILITY OF DAMAGES); AND THAT IF YOU ARE
                      DISSATISFIED WITH, OR CLAIM ANY DAMAGES RELATING TO, ANY
                      PORTION OF THIS WEBSITE, ANY MATERIALS CONTAINED IN THE
                      WEBSITE OR THIS AGREEMENT, YOUR SOLE AND EXCLUSIVE REMEDY
                      IS TO DISCONTINUE USING THIS WEBSITE AND ALL MATERIALS
                      CONTAINED THEREIN. <br /> j. This Website may contain
                      materials in the form of user submissions such as opinions
                      polls, reviews, discussion forums, success stories, user
                      comments, user ratings, ‘Likes’, photos by registered /
                      unregistered Users of the Site and we accept no
                      responsibility for the content or accuracy, exactness,
                      validity, relevance of the content as available on the
                      Site. <br /> k. M&M does not guarantee that any certain
                      type of content will be made available through the
                      Website. We may, for any reason, (a) amend, modify,
                      delete, cancel, reject, interrupt, remove, suspend or
                      block or otherwise change any information, content,
                      component, page, contents or user submissions, including,
                      without limitation, any prices, product descriptions,
                      terms, warranties, or these terms of use, now or
                      hereinafter contained or displayed on this Website at any
                      time and from time to time without notice and the same
                      will be effective immediately upon posting on this Website
                      (except with respect to orders placed and accepted by us
                      prior to the time of posting, in which event the terms and
                      conditions in effect at the time the order was placed
                      shall apply)
                    </div>
                  </div>
                  <div style={{ marginTop: "16px" }}>
                    <strong>4. Services:</strong>
                    <div>
                      M&M has provided this Website as a platform to the Users
                      of this Website and all information and material available
                      on the Website is only to assist the Users of Website who
                      are employees of the Mahindra Group. Among other services
                      and information, M&M may provide any or all the below
                      listed services to the Users: <br /> A. Website may
                      provide the information on new Mahindra Products and
                      Services or upcoming Mahindra Products and Services
                      including without limitation price, specifications, dealer
                      locations, offers, promotions, etc. <br /> B. Website may
                      also provide information on the products of Mahindra Group
                      companies as mentioned earlier so as to generate awareness
                      on Mahindra Group products and services offered by M&M
                      Group. M&M takes the utmost care to ensure that the
                      information provided about the products of third parties
                      are up to date, authentic and accurate so as to avoid any
                      allegations of misrepresentation. The source and the date
                      as of which the information is valid and accurate is also
                      disclosed to the User, wherever information about a
                      product of a third party is provided. The User
                      acknowledges that, no information provided on this Website
                      shall be considered a substitute for your independent
                      investigation. The information provided on this website
                      may be related to products or services that are not
                      available in your country. <br /> C. The Website may
                      include information, data, text, software, sound,
                      photographs, graphics, video, messages, materials, news,
                      notices, articles, contracts, forms, documents or other
                      materials and information which may be viewed or
                      downloaded on or through this Website. Contents shall also
                      include any e-mail, messages, e-cards or any other
                      information furnished by a user to be displayed on the
                      Website by M&M. <br /> D. The Website may contain the
                      following sections; <br />
                      1)“Today” section which will contain the content and tasks
                      that needs employee’s immediate attention. <br /> 2)The
                      Website may contain Work related modules that the employee
                      use on an ongoing basis. <br /> 3)Connect - Two-way
                      communication between employee and company <br /> 4)Learn
                      & Grow - Modules that help employee in their career growth
                      and internal mobility <br />
                      5)Covid-19 – For latest updates and content regarding the
                      pandemic <br />
                      6)Search – To Search people, news, videos, org
                      announcements, etc <br />
                      7)Help and Support - To Help and support that employee may
                      need <br />
                      8)Notifications - Latest notifications that need employee
                      attention <br />
                      9)All portals - All portals and modules by the employee’s
                      respective department <br /> 10)Profile - employee profile
                      and relevant information <br />
                      11)What's New - Latest and the most important company news{" "}
                      <br /> 12)Task center - Tasks that need employee attention{" "}
                      <br /> 13)Favorites - employee shortcuts to the most
                      accessed pages All the above listed Services are provided
                      at the sole discretion of M&M, and You acknowledges and
                      agree that M&M can add or provide additional services not
                      listed above, or can remove / suspend / cancel any or all
                      services/products, listed above without any notice or
                      liability.
                    </div>
                  </div>
                  <div style={{ marginTop: "16px" }}>
                    <strong>5. Links to Third-Party Websites</strong>
                    <div>
                      {" "}
                      Links to third-party websites are provided for convenience
                      only and do not imply any approval or endorsement by the
                      Company of the linked sites, even if they may contain the
                      Company’s logo, as such sites are beyond the Company’s
                      control. Thus, the Company cannot be held responsible for
                      the content of any linked site or any link contained
                      therein. You acknowledge that framing the Company’s
                      website or any similar process is prohibited. If you visit
                      a website affiliated with us or linked to this Website or
                      any other hyperlink that maybe appearing on this Website,
                      your use of such other website/ hyperlink is at your own
                      risk and you will be subject to the terms and conditions
                      of use applicable to such other website/ hyperlink
                      (including, without limitation, such website's privacy
                      policy and disclaimers). Those other websites are not
                      under our control, and you acknowledge that we are not
                      liable for the content, functions, accuracy, legality,
                      appropriateness, or any other aspect of those other
                      Websites or resources. The inclusion on another website of
                      any link to the M&M Website does not imply endorsement by
                      or affiliation with us. We are not responsible for
                      examining or evaluating, and in no way make any
                      endorsement, warranty, or representation relating to, the
                      content of any such other website/ hyperlink, and will not
                      be responsible or assume any liability for the actions,
                      products, services, or content of any such other website
                      or its related businesses. You further acknowledge and
                      agree that we shall not be liable for any damage related
                      to the use of any content, goods, or services available
                      through any third-party website or resource.
                    </div>
                  </div>
                  <div style={{ marginTop: "16px" }}>
                    <strong>6. Software disclaimer: </strong>
                    <div>
                      We do not warrant or represent that this Website or any
                      e-mail sent from us, is or will be free of any viruses or
                      other harmful components, and we do not make any
                      representations or warranties of any kind regarding the
                      consequences of downloading any software, information, or
                      materials from this Website. You assume full
                      responsibility and risk of loss resulting from your
                      downloading or use of any software, information, or
                      materials accessed through or obtained by means of this
                      Website. You hereby accept that the Company will not
                      accept responsibility and will not be liable for any
                      disruption, damage and/or loss of data on your data or
                      computer system that may occur while using the software or
                      the Website.
                    </div>
                  </div>
                  <div style={{ marginTop: "16px" }}>
                    <strong>7. Registration, Account and Login</strong>
                    <strong>
                      You shall not use any other User’s user id or/and password
                      for any purpose under any circumstances.{" "}
                    </strong>
                    <div>
                      You hereby agree and confirm that you will: (i) Provide
                      accurate, correct, valid and subsisting
                      information/documents at the time of registration on the
                      Website and anytime thereafter, as may be required
                      ("Registration Data"). <br /> (ii) Maintain and promptly
                      update the Registration Data to keep it accurate, current
                      and complete always. We reserve the right to confirm and
                      to validate the information and other details you
                      provided. If you provide any information that is untrue,
                      inaccurate, or not current, or if we have reasonable
                      grounds to suspect that such information is not in
                      accordance with the Terms of Use (whether wholly or in
                      part thereof), we reserve the right to reject your
                      registration and/or indefinitely suspend or terminate your
                      membership and refuse to provide you access to the
                      Website. <br /> (iii) Indemnify and keep indemnified the
                      Company from and against all claims resulting from the
                      content or data that you post and/or supply on the
                      Website. The Company shall be entitled to remove any such
                      detail/information/ data you posted even without any prior
                      notification. Further, You hereby agree and confirm that:
                      1. These Terms between the Company and the User is an
                      electronic record in terms of Information Technology Act,
                      2000 (as amended by the Information Technology (Amendment)
                      Act, 2008) and rules made there under pertaining to
                      electronic records in various statutes. These Terms is
                      generated as an electronic record by a computer system and
                      does not require any physical or digital signatures. 2.
                      You are liable to ensure that your login id and password
                      is not disclosed or transferred to any other person and
                      that the Website are used only for your own purposes. 3.
                      Any deviation from the aforesaid will entitle the Company
                      to block\suspend the user’s registration and to take such
                      other action(s) as may be necessary.
                    </div>
                  </div>
                  <div style={{ marginTop: "16px" }}>
                    <strong>8. User Content & Rules of User conduct</strong>
                    <div>
                      a. The Company does not assume any obligation to monitor
                      the information that you may post on its Website. You
                      warrant that any and all information, files or other
                      attachments sent to us ('Material') or comments other than
                      personal data, that you may transmit to the Company
                      through the Website does not infringe intellectual
                      property rights or any other applicable law. Such
                      information, material or comments, will be treated as
                      non-confidential and non-proprietary. By submitting any
                      information or material, you give the Company an unlimited
                      and irrevocable license to use, execute, show, modify and
                      transmit such information, material or comments, including
                      any underlying idea, concept or know-how. The Company
                      reserves the right to use such information in any way it
                      chooses. All such Materials become the exclusive property
                      of the Company, which it is entitled to use for any
                      purpose without restriction or compensation. You are
                      prohibited from posting or transmitting to or from the
                      Website any unlawful, infringing, threatening, libelous,
                      defamatory, obscene or pornographic material or any other
                      material that would violate any law and the Company
                      reserves the right to delete such material without giving
                      any notice. The Company reserves the right to block such
                      user from entering the Website permanently. Views
                      expressed or comments offered by the users shall be
                      personal in nature of the users and the Company shall not
                      have any responsibility whatsoever in this regard. b. We
                      collect information from a variety of sources, directly
                      and indirectly [to specify the sources]. The term
                      “Personal Data” means information relating to an
                      identified or identifiable individual (i.e. a natural
                      person). All of the information we collect about you is
                      used to monitor the use of the site and develop
                      world-class website of superior quality. Such use does not
                      result in any personally identifiable information being
                      collected or stored for purposes other than for what the
                      Website is used. By submitting your Personal Data through
                      this Website, you shall be consenting to it being handled
                      in accordance with the site's online Privacy Policy. You
                      agree to provide true and complete information about
                      yourself by registering on this site and you are solely
                      responsible for protecting your password and all acts by
                      way of use of your password. If you have submitted
                      Personal Data through this Website and wish us to cease
                      using it for the purposes submitted, please contact us. c.
                      M&M may allow the registered User of Website to upload or
                      post certain content including but not limited to reviews,
                      feedback, data or information, message, files,
                      photographs, pictures, articles, feedback or any other
                      material of combination thereof (collectively referred to
                      "User Content") accessible to others or transmit
                      communications to others. While, using some of the
                      tools/features, you may find involvement of of/ by third
                      parties, and Your use of them is subject to both the terms
                      of this Agreement and to the policies of their third-party
                      providers. All reviews, comments, feedback, postcards,
                      suggestions, ideas, and other submissions disclosed,
                      submitted or offered to us or otherwise disclosed, or
                      offered in connection with your use of this Website shall
                      be and remain our property and we shall exclusively own
                      all intellectual property rights, titles and interests
                      over the User Content and shall not be limited in any way
                      in the use of the User Content, commercial or otherwise.
                      We will be under no obligation (i) to maintain any User
                      Content in confidence; (ii) to pay you any compensation
                      for any User Content; or (iii) to respond to any of the
                      User Content. We do not regularly review the posted User
                      Content , but reserve the right to monitor and edit or
                      remove any User Content submitted to the Website. You
                      grant the Company the right to use your name that you
                      submitted in connection with any User Content. We take no
                      responsibility and assume no liability for any User
                      Content you submitted. d. Our right to the User Content
                      will survive any termination of these Terms. e. Being the
                      originator of the User Content, You are solely responsible
                      for the User Content that You upload, post, publish,
                      transmit or otherwise make available on the Website. You
                      represent that you have obtained all relevant consents and
                      approvals in order to post any User Content. You further
                      represent that all such User Content will be in accordance
                      with applicable law. You acknowledges and agree that of
                      the User Content posted or transmitted through our Website
                      represents the views of the author, and not of the
                      Company. You also acknowledge that your use of or reliance
                      on such content is at your own risk. f. You acknowledge
                      that M&M does not endorse any User Content on the Website
                      and is not responsible or liable for any User Content. In
                      case the User Content violates/infringes any patent,
                      trademark, trade secret, copyright or any other
                      proprietary or privacy rights of any third party or in
                      contravention of any applicable law, then M&M at its sole
                      discretion may remove or disable the access to the User
                      Content or any part thereof, without any notice to User.
                      g. When You use the Website and/or the services You
                      specifically undertake not to display, upload, modify,
                      publish, transmit, update or share any information or User
                      Content that: 1) Belongs to another person and to which
                      the User does not have any right to; 2) Is grossly
                      harmful, harassing, blasphemous, defamatory, obscene,
                      pornographic, paedophilic, libelous, invasive of another's
                      privacy, hateful, or racially, ethnically objectionable,
                      disparaging, relating or encouraging money laundering or
                      gambling, or otherwise unlawful in any manner whatsoever;
                      3) Harms minors in any way; 4) Infringes any patent,
                      trademark, copyright or any other kind or form of
                      intellectual property or proprietary rights of third
                      parties; 5) Violates any applicable laws or regulations
                      for the time being in force within or outside India; 6)
                      Deceives or misleads the addressee about the origin of
                      such messages or communicates any information which is
                      grossly offensive or menacing in nature; 7) Impersonate
                      another person or entity; 8) Contains software viruses or
                      any other computer code, files or programs designed to
                      interrupt destroy or limit the functionality of any
                      computer resource; 9) Threatens the unity, integrity,
                      defence, security or sovereignty of India, friendly
                      relations with foreign states or public order or causes
                      incitement to the commission of any cognizable offence or
                      prevents investigation of any offence or is insulting of
                      any other nation; 10) Is misleading in any way or shall
                      not , directly or indirectly, offer, attempt to offer,
                      trade or attempt to trade in any item, the dealing of
                      which is prohibited or restricted in any manner under the
                      provisions of any applicable law, rule, regulation or
                      guideline for the time being in force; 11) Engage in any
                      activity that interferes with or disrupts access to the
                      Website or the services available through or at the
                      Website and shall not attempt or involve in the
                      transmission of "junk/phishing mail", "chain emails /
                      letters", "spamming" or "unsolicited mass mailing"; 12)
                      Attempt to gain unauthorized access to any other User's
                      account, Website any portion or feature of the Website,
                      any other systems or networks connected to the Website, or
                      any computer resource / servers, of M&M connected with the
                      Website to provide the Services. 13) You shall not access
                      the Website, features or any of the services offered on or
                      through the Website, by hacking, password mining or any
                      other illegitimate means or through automated means
                      including robot, deep link, page scrape; 14) You shall not
                      probe, scan or test the vulnerability of the Website or
                      any network connected to the Website, nor disrupt,
                      interfere, breach the security or authentication measures
                      on the Website or any network connected to the Website or
                      cause any harm to the Website, system resources, servers
                      of M&M connected to or accessible through the Website. You
                      may not reverse look-up, trace or seek to trace any
                      information on any other user, of or visitor to, the
                      Website, including any other Account on the Website not
                      owned by You, to its source, or exploit the Website or
                      service or information made available or offered by or
                      through the Website, in any way whether or not the purpose
                      is to reveal any information, including but not limited to
                      personal identification information, other than Your own
                      information, as provided for by the Website; 15) Use the
                      Website or content for any purpose that is unlawful or
                      prohibited by the Agreement, or to solicit the performance
                      of any illegal activity or other activity which infringes
                      the rights of M&M or other third parties; 16) Falsify or
                      delete any author attributions, legal or other proper
                      notices or proprietary designations or labels of the
                      origin or source of software or other material contained
                      in a file that is uploaded; 17) Violate any code of
                      conduct or other guidelines, terms of this Agreement which
                      may be applicable for or to any particular Service; 18)
                      You shall not make any negative, denigrating or defamatory
                      statement(s) or comment(s) about Us or the brand name,
                      trade name or domain name used by Us or otherwise engage
                      in any conduct or activity that might spoil the image or
                      reputation of M&M on Website or otherwise. h. Unless with
                      our prior permission, no part of the Website may be
                      reproduced or transmitted to or stored in any other form,
                      nor any of its pages or part thereof be disseminated in
                      any electronic or non-electronic form, nor included in any
                      public or private electronic retrieval system or service.
                      i. You have a limited permission to display, print or
                      download extracts from these pages of the M&M Website for
                      your personal, non-commercial and non-profit use only and
                      you shall not be entitled to commercialise any such
                      material or contents on the M&M Website in any way. Any
                      other use or modification of the content of the Company’s
                      Website without the Company’s prior written authorisation
                      is prohibited. Use of contact information provided on the
                      site for unauthorized purposes, including marketing is
                      prohibited. Any use intended to impede with the working of
                      the site or to intercept any information from the site is
                      prohibited. j. You may not (whether directly or indirectly
                      including through the use of any program) create a
                      database in an electronic or other form by downloading and
                      storing all or any part of the pages from the M&M Website
                      without our prior written consent save as expressly
                      authorised by an agreement in writing between us, provided
                      any copies of the pages of the M&M Website which you save
                      to disk or to any other storage system or medium may only
                      be used for subsequent viewing purposes or to print
                      extracts for personal non-commercial and non-profit use.
                      k. You may not amend, republish, distribute, reproduce,
                      adapt or modify any of the materials on the M&M Website or
                      use any of the materials for public performance or
                      otherwise make commercial use of the M&M Website or any
                      materials located on it without our prior written consent.
                      l. You cannot upload on the M&M Website or submit any
                      content or otherwise distribute or publish through the M&M
                      Website any matter or material which is or may be
                      considered abusive, pornographic, illegal, defamatory,
                      obscene, racist, threatening, harassing, deceptive,
                      fraudulent, tortious; or which is otherwise unlawful,
                      offensive, profane, or invasive of another's privacy or
                      designed to cause disruption to any computer systems or
                      network. We shall be entitled without liability to the
                      user and at our discretion to remove any such content from
                      our server immediately. m. You shall not directly or
                      indirectly: (i) decipher, decompile, disassemble, reverse
                      engineer, or otherwise attempt to derive any source code
                      or underlying ideas or algorithms of any part of the M&M
                      Website, except to the extent applicable laws specifically
                      prohibit such restriction; (ii) modify, translate, or
                      otherwise create derivative works of any part of the M&M
                      Website; or (iii) copy, rent, lease, distribute, or
                      otherwise transfer any of the rights that you receive
                      hereunder. You shall abide by all applicable local, state,
                      national, and international laws and regulations. n. You
                      shall not, and shall not permit any third party using your
                      account to, take any action, or submit any content, that
                      infringes any patent, trademark, trade secret, copyright,
                      right of publicity, or other right of any other person or
                      entity, or violates any law or contract; you know is
                      false, misleading, or inaccurate; constitutes unsolicited
                      or unauthorized advertising or promotional material or any
                      junk mail, spam, or chain letters; contains software
                      viruses or any other computer codes, files, or programs
                      that are designed or intended to disrupt, damage, limit,
                      or interfere with the proper function of any software,
                      hardware, or telecommunications equipment or to damage or
                      obtain unauthorized access to any system, data, password,
                      or other information of M&M or any third party; is made in
                      breach of any legal duty owed to a third party, such as a
                      contractual duty or a duty of confidence; or impersonates
                      any person or entity, including any employee or
                      representative of the Company. o. You shall not: (i) take
                      any action that imposes or may impose (as determined by
                      M&M in its sole discretion) an unreasonable or
                      disproportionately large load on M&M’s or its third-party
                      providers’ infrastructure; (ii) interfere or attempt to
                      interfere with the proper working of M&M Website or any
                      activities conducted on the same; (iii) bypass any
                      measures M&M may use to prevent or restrict access to the
                      M&M Website (or other accounts, computer systems, or
                      networks connected to the same); (iv) run maillist,
                      listserv, or any form of auto-responder or "spam" on the
                      M&M Website; or (v) use manual or automated software,
                      devices, or other processes to "crawl" or "spider" any
                      page of the M&M Website. p. M&M Website cannot under any
                      circumstances be used for commercial purposes by any user.
                      q. You grant us a royalty free irrevocable permission to
                      reproduce, download and print your user submissions / User
                      Content in any of the sections on the M&M Website. You
                      grant to the Company the worldwide, non-exclusive,
                      perpetual, irrevocable, royalty-free, sub-licensable,
                      transferable right to (and to allow others acting on its
                      behalf to) (i) use, edit, modify, prepare derivative works
                      of, reproduce, host, display, stream, transmit, playback,
                      transcode, copy, feature, market, sell, distribute, and
                      otherwise fully exploit your User Submissions (a) the
                      Company’s (and its successors' and assigns’) businesses,
                      (b) promoting, marketing, and redistributing part or all
                      of the Website (and derivative works thereof) or the
                      Services in any media formats and through any media
                      channels (including, without limitation, third-party
                      Websites); (c) take whatever other action is required to
                      perform. r. We reserve and retain the right or ability to
                      remove the availability or ability of participants to post
                      comments in the event of any breach of these acceptable
                      use provisions. s. We will enforce appropriate sanctions
                      against any of the users of the M&M Website who are
                      responsible for abuse of the M&M Website. Such sanctions
                      may include, but are not limited to (a) a formal warning,
                      (b) suspension of access through the M&M Website (d)
                      termination of any registration of the user with M&M
                      Website.
                    </div>
                  </div>
                  <div style={{ marginTop: "16px" }}>
                    <strong>9. Consent to electronic communications</strong>
                    <div>
                      You consent to receive communications from us
                      electronically or telephonically. We can communicate with
                      You by e-mail, SMS, App notifications, browser
                      notifications, chat platforms telephone, or by posting
                      notices on the Website for various purposes including but
                      not limited to marketing, taking feedbacks of the services
                      on the Website. You agree that all agreements, notices,
                      disclosures and other communications that we provide to
                      you electronically or telephonically shall be legally
                      binding on You.
                    </div>
                  </div>
                  <div style={{ marginTop: "16px" }}>
                    <strong>10. Errors, Inaccuracies and Omissions </strong>
                    <div>
                      Occasionally there may be information on the Website that
                      contains typographical errors, inaccuracies or omissions.
                      We reserve the right to correct any errors, inaccuracies
                      or omissions, and to change or update information or
                      cancel orders, if any information on the Website is
                      inaccurate at any time without prior notice.
                    </div>
                  </div>
                  <div style={{ marginTop: "16px" }}>
                    <strong>11. Report abuse & Feedback</strong>
                    <div>
                      M&M advises its Users, not to post or upload anything
                      which is abuse of the Website or violate any terms of this
                      Agreement. M&M also prohibits the use of language that is
                      racist, hateful, sexual or obscene in nature in a public
                      area. If You come across any such instance of abuse of
                      violation or any content which is objectionable content or
                      further if You believe that any
                      material/information/post/feedback is infringement of any
                      intellectual property right of You or any other person,
                      You shall inform the same to M&M and M&M is committed to
                      take appropriate action including validation of Your
                      information and remove/modify the content (if necessary),
                      which is in breach of the Terms of Use or any other
                      applicable law. Email Abuse and Threat Policy: Private
                      communication including email correspondence is not
                      regulated by the M&M. M&M advises its Users not to
                      use/send any such email. Any such reported instance will
                      be investigated and M&M will take appropriate measures
                      (including legal steps, if required) to stop such
                      practices. Your Feedback: When You share any feedback or
                      post any comment (including User Content) on Website, it
                      is deemed Non-Confidential. M&M is at liberty to use any
                      such feedback or User Content and You represent that:{" "}
                      <br /> (i) Your feedback does not contain confidential or
                      proprietary information of You or of third parties; <br />{" "}
                      (ii) M&M is not under any obligation of confidentiality,
                      express or implied, with respect to the feedback; <br />{" "}
                      (iii) M&M may have something similar to the feedback
                      already under consideration or in development; and <br />{" "}
                      (iv) You are not entitled to any compensation or
                      reimbursement of any kind from M&M for the feedback under
                      any circumstances.
                    </div>
                  </div>
                  <div style={{ marginTop: "16px" }}>
                    <strong>
                      12. Intellectual Property <br /> Copyright © 2020 Mahindra
                      & Mahindra Ltd. ALL RIGHTS RESERVED
                    </strong>
                    <div>
                      i. This Website is the exclusive property of the Company.
                      All the contents, documents, information and database
                      included on the Website, including without limitation such
                      text, graphics, images, logos, button icons, pictures,
                      music, sound files, ,animation, audio clips, video clips
                      and their arrangement, demos, digital downloads, data
                      compilations, source code, html codes, reprographics,
                      demos, patches, other files and software), is owned,
                      controlled or licensed by M&M or its affiliates and is
                      protected or covered by copyright, database rights, trade
                      mark, trade dress and other intellectual property laws and
                      other proprietary rights. These materials may not be
                      copied for commercial use, storage, alteration,
                      duplication, distribution, reproduction, transmission,
                      communication, nor should these objects be modified or
                      reposted to other web sites, apps or other digital content
                      without prior written consent from M&M. You may use this
                      material for personal and non-commercial purposes in
                      accordance with the principles governing intellectual
                      property laws. Any other use or modification of the
                      content of the Company's web site without the Company's
                      prior written authorisation is prohibited and is violation
                      of the copyright of M&M and/or its affiliates or
                      associates or of its third party information providers.
                      ii. The marks ‘Mahindra,’ 'Rise', ’the Corporate Logo’,
                      ‘Ridge Device’, model names, logos, emblems, device marks
                      and other marks developed by M&M in relation to its goods
                      and services are the trademarks owned by Mahindra &
                      Mahindra Limited , which also includes all other graphics,
                      logos, page headers, taglines, button icons, hyperlinks,
                      scripts meta tags and service names. The said trademarks
                      should not be used in connection with any product or
                      service that is not in any manner connected or provided by
                      M&M, or in any manner that is likely to cause confusion
                      among Users, or in any manner that disparages or
                      discredits M&M. iii. All other trademarks not owned by M&M
                      that appear on the Website, are the property of their
                      respective owners, who may or may not be affiliated with
                      or connected to M&M and we do not claim any ownership,
                      right, interest or affiliation with such parties. iv.
                      Nothing on the Website or your use of any of the services
                      shall be construed as conferring any licence or other
                      rights under the intellectual property or other
                      proprietary rights of M&M, or any third party, whether
                      implied or otherwise, save as expressly provided. v. Any
                      software, including codes or other materials that are made
                      available for download from the Website, copyright in the
                      pages and in the screens displaying the pages, in the
                      information and material therein and in their
                      arrangement.is the copyrighted work of M&M and/or its
                      suppliers, holding company or affiliates. If you download
                      software from the Website, use of the software is subject
                      to the license terms in the software license agreement
                      that accompanies or is provided with the software. You may
                      not download or install the software until you have read
                      and accepted the terms of the applicable software license
                      agreement. Without limiting the foregoing, copying or
                      reproduction of the software to any other server or
                      location for further reproduction or redistribution is
                      expressly prohibited unless otherwise provided for in the
                      applicable software license agreement in the case of
                      software, or the express written consent of M&M in the
                      case of codes or other downloadable materials. vi. You
                      acknowledge and agree that through your use of website or
                      by availing any product or services through or at website,
                      no right (empress or implied) is granted to you in respect
                      of such content. M&M reserves the right to change or
                      modify the content from time to time at its sole
                      discretion. vii. It is clarified that any use of the
                      Website, Content, Marks or other intellectual property
                      rights of M&M, in contravention of the terms of this
                      Agreement or any applicable law shall constitute the
                      infringement of such intellectual property right of M&M or
                      their respective third party, upon which M&M or third
                      party may initiate the appropriate legal proceedings
                      against the User. viii. All rights not expressly granted
                      hereunder to the user, are reserved exclusively to M&M.{" "}
                      <br />
                      <strong>Copyright Infringement </strong> <br />
                      If You believe that Your work or someone’s work from whom
                      You are authorized to represent, has been copied on the
                      Website in a way that constitutes copyright infringement,
                      please provide Our product team the written information
                      specified below. Please note that this procedure is
                      exclusively for the purpose of notifying Us only. It does
                      not guarantee, removal of the content from the website and
                      receipt of such notice will not amount to admission by M&M
                      of any infringement. Any communication received in
                      response to the notice will be without prejudice to our
                      rights under law. 1. An electronic or physical signature
                      of the owner of the copyright interest and of the person
                      authorized to act on behalf of the owner of the copyright
                      interest; 2. A description of the copyrighted work that
                      You claim has been infringed upon 3. A description
                      including the URL(s) of the Website of where the material
                      that You claim is infringing is located on the site; 4.
                      Your address, telephone number, and e-mail address; 5. A
                      statement by You that You have a good-faith belief that
                      the disputed use is not authorized by the copyright owner,
                      its agent, or the law; 6. A statement by You, that the
                      above information in Your notice is accurate and that You
                      are the copyright owner or authorized to act on the
                      copyright owner's behalf.
                    </div>
                  </div>
                  <div style={{ marginTop: "16px" }}>
                    <strong>13. Modifications to the Terms of Use</strong>
                    <div>
                      The Company reserves the right to change the disclaimers
                      and/or the Terms of Use under which this Website is
                      offered at any time and without notice. You will be
                      automatically bound by these modifications when you use
                      this site, and should periodically read the terms of use,
                      the privacy policy and the disclaimers appearing on the
                      Website. After having read and understood the disclaimer
                      and copyright notice, you hereby agree to accept and abide
                      by all the terms and conditions as mentioned therein.
                    </div>
                  </div>
                  <div style={{ marginTop: "16px" }}>
                    <strong>
                      14. Disclaimer of Warranty, Exclusion of Liability{" "}
                    </strong>
                    <div>
                      a. To the fullest extent permitted by applicable law, we
                      disclaim all warranties, whether express or implied,
                      including, without limitation, implied warranties of
                      merchantability, fitness for a particular purpose or
                      non-infringement in relation to any and all Material, User
                      Content or any other information available on the Website.
                      b. All materials, including downloadable software,
                      contained in the Company’s Website is provided on ‘as is’
                      basis and without warranty of any kind to the extent
                      allowed by the applicable law. While the Company will use
                      reasonable efforts to provide reliable information through
                      its Website, the Company does not warrant that this
                      website is free of inaccuracies, errors and/or omissions,
                      viruses, worms, Trojans and the like, or that its content
                      is appropriate for your particular use or up to date. The
                      Company reserves the right to change the information at
                      any time without notice. The Company does not warrant any
                      results derived from the use of any software available on
                      this site. You are solely responsible for any use of the
                      materials contained in this site. c. In no event will the
                      Company be liable for any damage or loss that may arise
                      from the use of any information contained in our Website
                      or products displayed on our Website. Notwithstanding
                      anything else in these Terms of Use, the Company will not
                      be liable for claims relating to the functionality or
                      availability of this Website. You agree to indemnify and
                      keep indemnified always and hold the Company and its
                      employees and agents harmless from and against all
                      liabilities, legal fees, damages, losses, costs and other
                      expenses in relation to any claims or actions brought
                      against the Company arising out of any breach by you of
                      these terms and conditions or other liabilities arising
                      out of your use of this Website. d. In no case shall
                      Company our directors, officers, employees, affiliates,
                      agents, contractors, interns, suppliers, service providers
                      or licensors be liable for any injury, loss, claim, or any
                      direct, indirect, incidental, punitive, special, or
                      consequential damages of any kind, including, without
                      limitation lost profits, lost revenue, lost savings, loss
                      of data, replacement costs, or any similar damages,
                      whether based in contract, tort (including negligence),
                      strict liability or otherwise, arising from Your use of
                      the Website, or for any other claim related in any way to
                      Your use of the Website, including, but not limited to,
                      any errors or omissions in any content, or any loss or
                      damage of any kind incurred as a result of the use the
                      Website or any Content (or service) posted, transmitted,
                      or otherwise made available via the Website, even if
                      advised of their possibility. e. We will not be liable for
                      any failure and/or delay on our part in performing any
                      obligation under this Agreement, and/or for any loss,
                      damages, costs, charges and expenses incurred that you
                      suffered if such failure and/or delay shall result of or
                      arising out of a Force Majeure Event. For the purposes of
                      this Website, a "Force Majeure Event" means any event due
                      to any cause beyond the reasonable control of the Company,
                      including, without limitation, unavailability of any
                      communication system, sabotage, fire, flood, earthquake,
                      explosion, acts of God, civil commotion, strikes, lockout,
                      hacking, data theft, and/or industrial action of any kind,
                      breakdown of transportation facilities, riots,
                      insurrection, hostilities whether war be declared or not,
                      acts of government including change in law, governmental
                      orders or restrictions breakdown, and/or hacking of the
                      Website and/or contents provided on the Website, such that
                      it is impossible to perform the obligations under the
                      Terms of Use or any other cause or circumstances beyond
                      the control of the Company hereto, which prevents timely
                      fulfillment of the obligation of the Company in accordance
                      with these Terms.
                    </div>
                  </div>
                  <div style={{ marginTop: "16px" }}>
                    <strong>15. Indemnification</strong>
                    <div>
                      a. You agree to indemnify, defend and hold harmless M&M,
                      its subsidiaries, affiliates, representatives, employees,
                      vendors, contractors, directors, suppliers, agents and
                      their respective directors, officers, employees,
                      contractors and agents (herein after individually and
                      collectively referred to as "Indemnified Parties") from
                      and against any and all losses, liabilities, claims,
                      suits, proceedings, penalties, interests, damages,
                      demands, costs and expenses (including legal and other
                      statutory fees and disbursements in connection therewith
                      and interest chargeable thereon) asserted against or
                      incurred by the Indemnified Parties that arise out of,
                      result from, or in connection with (i) Your breach of the
                      terms of this Agreement ; or (ii) any claims made by any
                      third party due to, or arising out of, or in connection
                      with, Your use or misuse of the Website; or (iii) any
                      claim that any Content / User Content / Third Party
                      Content, information or Materials provided by You caused
                      damage to a third party including but not limited to by
                      way of infringement of an intellectual property of the
                      third party; or (iv) Your violation of any rights of
                      another, including any intellectual property rights. (v)
                      any fraud or misconduct by You (vi) violation of any
                      applicable laws, regulations including but not limited to
                      that of intellectual property rights, payment of statutory
                      dues and taxes etc. We reserve the right to assume the
                      exclusive defence and control of any matter otherwise
                      subject to indemnification by you, in which event you will
                      assist and cooperate with M&M in asserting any available
                      defences. b. M&M may notify You of any claims which You
                      shall be liable to indemnify M&M against. You will then be
                      required to consult with M&M regarding the course of
                      action to be undertaken in defending such a claim.
                      Further, You shall not compromise or settle any claim or
                      admit any liability or wrongdoing on the part of M&M
                      without the express prior written consent of M&M which can
                      be withheld or denied or conditioned by M&M in its sole
                      discretion. c. Limitation of Liability: M&M assumes no
                      liability whatsoever for any monetary or other damage
                      suffered by You on account of: (a) The delay, failure,
                      interruption, or corruption of any data or other
                      information transmitted in connection with use of the
                      Website; (b) Any interruption or errors in the operation
                      of the Website. Notwithstanding anything to contrary in
                      the Agreement(s), in no event shall M&M, its subsidiaries
                      or affiliates and their respective officers, directors,
                      employees, partners or suppliers be liable to You for any
                      special, incidental, indirect, consequential, exemplary or
                      punitive damages whatsoever, including those resulting
                      from loss of use, data or profits, whether or not
                      foreseeable or whether or not M&M has been advised of the
                      possibility of such damages, or based on any theory of
                      liability, including breach of contract or warranty,
                      negligence or other tortious action, or any other claim
                      arising out of or in connection with Your use of or access
                      to the Website, services, content, User Content or third
                      party content. M&M’s entire and aggregate liability to You
                      under and in relation to these Terms of Use or otherwise
                      shall not exceed the amount of fees, if any, paid by You
                      to M&M under the relevant order to which the cause of
                      action for the liability relates.
                    </div>
                  </div>
                  <div style={{ marginTop: "16px" }}>
                    <strong>16. Severability</strong>
                    <div>
                      In the event that any provision of this Agreement is
                      determined to be unlawful, void or unenforceable, such
                      provision shall nonetheless be enforceable to the fullest
                      extent permitted by applicable law, and the unenforceable
                      portion shall be deemed to be severed from this Agreement,
                      such determination shall not affect the validity and
                      enforceability of any other remaining provisions.
                    </div>
                  </div>
                  <div style={{ marginTop: "16px" }}>
                    <strong>17. Governing Law and Dispute Resolution </strong>
                    <div>
                      The Terms shall be construed in accordance with the laws
                      of India. All the dispute or difference either in
                      interpretation or otherwise, of any provision of the Terms
                      of Use, shall be referred to a sole arbitrator who will be
                      appointed by the Company and his decision shall be final
                      and binding. The arbitration shall be conducted in
                      accordance with the Arbitration and Conciliation Act,
                      1996, as amended from time to time. The seat of
                      arbitration shall be Mumbai. The arbitration proceedings
                      shall be in the English language. Subject to the above,
                      the courts at Mumbai shall have exclusive jurisdiction in
                      any proceedings arising out of or in connection with these
                      Terms. Notwithstanding anything to the contrary contained
                      in the Terms, we reserve, at our sole discretion, our
                      right to seek equitable remedies including by way of
                      injunctive relief before any court of competent
                      jurisdiction.
                    </div>
                  </div>
                  <div style={{ marginTop: "16px" }}>
                    <strong>18. No Waiver</strong>
                    <div>
                      Our failure to act with respect to your breach of the
                      Terms of Use does not tantamount to a waiver of our right
                      to act with respect to subsequent or similar breaches,
                      unless we waived it through writing.
                    </div>
                  </div>
                  <div style={{ marginTop: "16px" }}>
                    <strong>19. Notice</strong>
                    <div>
                      a. Notice: All notices under the Terms of Use will be in
                      writing and will be deemed to have been duly given when
                      received, if personally delivered or sent by certified or
                      registered mail, return receipt requested; when receipt is
                      electronically confirmed, if transmitted by facsimile or
                      e-mail; or the day after it is sent, if sent for next day
                      delivery by recognized overnight delivery service to the
                      address appearing below; <br /> On behalf of M&M to:{" "}
                      <strong>Mahindra & Mahindra Limited</strong>
                      Attn: Technology Department Address: 3rd Floor, Mahindra
                      Towers, Mahindra & Mahindra Email: MSOLVE@mahindra.com
                      Working Days: Monday to Friday Working Hours: 10:30 am to
                      6:30 pm <br /> <strong>On behalf of User to</strong> All
                      notices with respect to these Terms of Use from M&M will
                      be served to You: By email (as provided by You at the time
                      of Registration or communicating with M&M) or by general
                      notification on the Website.
                    </div>
                  </div>
                  <div style={{ marginTop: "16px" }}>
                    <strong>20. Entire Agreement</strong>
                    <div>
                      These Terms of Use, Disclaimer, Privacy Policy and other
                      referenced material are the entire agreement between you
                      and M&M with respect to the M&M Website, and supersede all
                      prior or contemporaneous communications and proposals
                      (whether oral, written or electronic) between you and M&M
                      with respect to M&M Website and govern the future
                      relationship. The Terms of Use are personal to you, and
                      are not assignable, transferable, or sub-licensable by you
                      except with our prior written consent. We may assign,
                      transfer, or delegate any of its rights and obligations
                      hereunder without Your consent. No agency, partnership,
                      joint venture, or employment relationship is created as a
                      result of the Terms of Use and neither party has any
                      authority of any kind to bind the other in any respect. In
                      any action or proceeding to enforce rights under the Terms
                      of Use, the prevailing party will be entitled to recover
                      costs and attorneys' fees.
                    </div>
                  </div>
                </Grid>
              </Grid>
            </Grid>
          </Paper>
        </Template>
      </>
    </section>
  );
}
