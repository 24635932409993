import React from "react";
import {
  Paper,
  Grid,
  Icon,
  Avatar,
  Card,
  CardActions,
  CardActionArea,
} from "@material-ui/core";
import Template from "../../../Common/Template/Template";
import AuthorItem from "./AuthorItem";

const AuthorDetails = ({
  isAuthorPost,
  submittedByName,
  submittedByPhoto,
  submittedForName,
}) => {
  return (
    <>
      {isAuthorPost ? (
        <AuthorItem
          label="Author"
          userName={submittedByName}
          userPhoto={submittedByPhoto}
        />
      ) : (
        <>
          <AuthorItem
            label="Submitted by"
            userName={submittedByName}
            userPhoto={submittedByPhoto}
          />
          <AuthorItem label="Submitted for" userName={submittedForName} />
        </>
      )}
    </>
  );
};

export default AuthorDetails;
