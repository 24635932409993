import services from "../../Services/index";
//import logger from"../../Components/logger.js"
import {
  GET_SCOPE_DETAILS_DATA_LOAD,
  GET_SCOPE_DETAILS_DATA_FAILED,
  GET_SCOPE_DETAILS_DATA_SUCCESS,
} from "../Contants/Constants";

const getScopeDetailssDataLoad = () => ({
  type: GET_SCOPE_DETAILS_DATA_LOAD,
});
const getScopeDetailssDataSuccess = (data) => ({
  type: GET_SCOPE_DETAILS_DATA_SUCCESS,
  payload: data,
});
const getScopeDetailsDataFailed = (error) => ({
  type: GET_SCOPE_DETAILS_DATA_FAILED,
  err: error,
});

export const getScopeDetailsData = (id) => async (dispatch) => {
  dispatch(getScopeDetailssDataLoad());
  try {
    // console.log('redux id', id)
    const res = await services.api(
      "GET",
      `/ui-assist/v1/rollmatrix/adminrights/scope/${localStorage.getItem("token")}`,
      {}
    );
    console.log(res, 'response.............')
    if (res && res.status === 200 ) {
      console.log(res?.data, 'response data................')
      if(res?.data !== "" && res?.data?.admin){
        dispatch(getScopeDetailssDataSuccess(res.data));
      localStorage.setItem("scopeDetails", JSON.stringify(res?.data));

      }
    }else{
      console.log('i was false hence not added on application data....')
    }
  } catch (err) {
    dispatch(getScopeDetailsDataFailed(err.response));
    console.log('i was on scope catch block')
  }
};

