import React, { useState, useEffect } from "react";
import "./TaskCenter.css";
import { useDispatch, useSelector } from "react-redux";
import { Grid, Card, Typography, Icon, Tooltip } from "@material-ui/core";
import Favourites from "./Favourites/Favourites";
import PriorityTasks from "./PriorityTasks/PriorityTasks";
import { getPriorityData } from "../../../Redux/Actions/DashboardAction";
import { makeStyles } from "@material-ui/core/styles";
import { getTaskData } from "../../../Redux/Actions/TaskActions";
// const priorityData = [
//   {
//     id: 1,
//     task: "Tax Declaration",
//     daysToGo: "01",
//     status: "Day to go",
//   },
//   {
//     id: 2,
//     task: "Mandatory Courses",
//     daysToGo: " 03",
//     status: "Task pending",
//   },
//   {
//     id: 3,
//     task: "Review Performance",
//     daysToGo: " 07",
//     status: "Task pending",
//   },
//   {
//     id: 4,
//     task: "Check for 4 tasks",
//     daysToGo: " 02",
//     status: "Days to go",
//   },
//   {
//     id: 5,
//     task: "Check for 5 tasks",
//     daysToGo: " 05",
//     status: "pending",
//   },
// ];

const priorityData = [
  {
    employeeId: "210613",
    activityType: "TASK_CENTER",
    activity: "Income Tax Declaration",
    identifier: "00210613",
    status: "PENDING",
    viewUrl:
      "https://emss.mahindra.com/sap/bc/webdynpro/sap/zwdhr_c_tax_declaration_2?sap-accessibility=X&sap-accessibilitymode=CLASSIC",
    message: "Income Tax Declaration",
    dueDate: "2022-03-31",
    date: "2021-09-16",
    time: "20:01:51",
  },
  {
    employeeId: "210613",
    activityType: "TASK_CENTER",
    activity: "Evaluate Performance",
    identifier: "00210613",
    status: "PENDING",
    // viewUrl:
    //   "https://hcm4preview.sapsf.com/sf/pmreviews?bplte_company=MMTest&loginMethod=SSO",
    message: "Evaluate Performance",
    dueDate: "--",
    date: "2021-09-16",
    time: "20:01:51",
    count: "11",
  },
  {
    employeeId: "210613",
    activityType: "TASK_CENTER",
    activity: "Update Your Profile",
    identifier: "00210613-481",
    status: "PENDING",
    viewUrl:
      "https://hcm4preview.sapsf.com/sf/liveprofile?company=MMTest&loginMethod=SSO",
    message: "Update Your Profile",
    dueDate: "--",
    date: "2021-09-16",
    time: "20:01:51",
    count: "1",
  },
  {
    employeeId: "210613",
    activityType: "TASK_CENTER",
    activity: "Approval Center",
    //  "identifier": "00210613"+"w"+workflow,
    status: "PENDING",
    viewUrl:
      "https://qaapps.mahindra.com/sap/bc/ui5_ui5/ui2/ushell/shells/abap/fiorilaunchpad.html#ZHR_ALL_APRV-display",
    message: "Approval Center",
    dueDate: "--",
    date: "2021-09-16",
    time: "20:01:51",
    count: "13",
  },
  {
    employeeId: "210613",
    activityType: "TASK_CENTER",
    activity: "Flexi Window",
    identifier: "00210613",
    status: "PENDING",
    viewUrl: "https://emss.mahindra.com/sap/bc/webdynpro/sap/zwdhr_a_my_ctc",
    message: "Flexi Window",
    dueDate: "2021-12-31",
    date: "2021-09-16",
    time: "20:01:51",
    count: "20",
  },
];

const myWork = [
  {
    myWork: "Payslip",
    icon: "/iconImages/file-list-3-line.svg",
    hoverIcon: "/iconImages/file-list-3-fill.svg",
    order: 1,
    id: 0,
    link: "https://qaapps.mahindra.com/fiori/shells/abap/Fiorilaunchpad.html?sap-ushell-config=headerless#zhr_sf_payslip-display",
  },
  {
    myWork: "Digital learning world",
    icon: "/iconImages/book-open.svg",
    hoverIcon: "/iconImages/book-open-fill-white.svg",
    order: 2,
    id: 1,
    link: "https://mahindra.plateau.com/learning/user/personal/landOnPortalHome.do?OWASP_CSRFTOKEN=JHPM-E4V0-ZTZC-WAA9-5K9D-SOSO-S75X-TBZO&fromSF=Y&fromDeepLink=null&fromDeepLink=true&pageID=&pageID=",
  },
  {
    myWork: "Leaves and time-off",
    icon: "/iconImages/calendar.svg",
    hoverIcon: "iconImages/calendar-2-fill.svg",
    order: 3,
    id: 2,
    link: "https://hcm4preview.sapsf.com/sf/timeoff?company=MMTest&loginMethod=SSO",
  },
  {
    myWork: "M-ESOP",
    icon: "/iconImages/line-chart-line.svg",
    hoverIcon: "iconImages/line-chart-fill.svg",
    order: 4,
    id: 3,
    link: "https://www.mesop.in/SSO/Default.aspx",
  },
  {
    myWork: "Compensation",
    icon: "/iconImages/compansaction.svg",
    hoverIcon: "iconImages/compansaction-fill.svg",
    order: 5,
    id: 4,
    link: "",
  },
  {
    myWork: "My org chart",
    icon: "/iconImages/chart.svg",
    hoverIcon: "iconImages/chart-fill.svg",
    order: 6,
    id: 5,
    link: "",
  },
  // {
  //   myWork: "Emergency Call",
  //   icon: "/iconImages/phone.svg",
  //   hoverIcon: "iconImages/phone-fill.svg",
  //   order: 5,
  //   id: "Emergency Call",
  // },
];
const manageTeam = [
  {
    myWork: "Onboarding",
    icon: "/iconImages/file-list-3-line.svg",
    hoverIcon: "/iconImages/file-list-3-fill.svg",
    link: "https://hcm4preview.sapsf.com/xi/ui/onboarding/pages/dashboard.xhtml?bplte_company=MMTest&loginMethod=SSO",
    order: 1,
    id: "Onboarding",
  },
  {
    myWork: "Review performance",
    icon: "iconImages/book-open.svg",
    hoverIcon: "iconImages/book-open-fill.svg",
    order: 2,
    id: "Review_Performance",
    link: "https://hcm4preview.sapsf.com/sf/pmreviews?bplte_company=MMTest",
  },
  {
    myWork: "Approve requests",
    icon: "/iconImages/calendar.svg",
    hoverIcon: "iconImages/calendar-2-fill.svg",
    order: 3,
    id: "Approve_Requests",
    link: "https://qaapps.mahindra.com/sap/bc/ui5_ui5/ui2/ushell/shells/abap/fiorilaunchpad.html?sap-ushell-config=headerless#ZHR_ALL_APRV-display",
  },
  {
    myWork: "Send positive post-its",
    icon: "/iconImages/line-chart-line.svg",
    order: 4,
    id: "Send_Positive_Post-its",
    hoverIcon: "iconImages/line-chart-fill.svg",
    link: "https://hcm4preview.sapsf.com/sf/uxrFeedback/giveFeedback?company=MMTest&loginMethod=SSO",
  },
];

const useStyles = makeStyles({
  customTooltip: {
    backgroundColor: "#fefdfd",

    fontSize: "12px",
    width: "326px",
    padding: "8px 8px 8px 8px",
    borderRadius: "0px",
    color: "#242424",
    fontWeight: "normal",
    lineHeight: "1.67",
  },
  customArrow: {
    color: "#fefdfd",
  },
});
const activeArrowStyle = {
  color: "#e31837",
  backgroundColor: "rgba(219, 35, 58, 0.05)",
};

function TaskCenter(props) {
  const dispatch = useDispatch();
  const [m_data, setM_data] = useState([]);
  const data = useSelector((state) => state.getPriorityDataReducer);

  const { getData: priorityData2 } = data;

  //  {
  //   employeeId: "210613",
  //   activityType: "TASK_CENTER",
  //   activity: "Flexi Window",
  //   identifier: "00210613",
  //   status: "PENDING",
  //   viewUrl: "https://emss.mahindra.com/sap/bc/webdynpro/sap/zwdhr_a_my_ctc",
  //   message: "Flexi Window",
  //   dueDate: "2021-12-31",
  //   date: "2021-09-16",
  //   time: "20:01:51",
  //   count: "20",
  // }

  const filterMyPriorityData = () => {
    let x = [];
    for (let i = 0; i < priorityData2?.length; i++) {
      if (priorityData2 && priorityData2[i].Count === "") {
        priorityData2[i].Count = dateCount(priorityData2[i].DueDate);
      }
      x.push({
        employeeId: priorityData2[i].EmployeeId,
        activityType: priorityData2[i].ActivityType,
        activity: priorityData2[i].RiseTextDisplay,
        identifier: priorityData2[i].Identifier,
        status: priorityData2[i].Status,
        viewUrl: priorityData2[i].ViewUrl,
        message: priorityData2[i].Message,
        dueDate: priorityData2[i].DueDate,
        date: priorityData2[i].DueDate,
        time: priorityData2[i].Time,
        count: priorityData2[i].Count,
        RiseTextAdtl: priorityData2[i].RiseTextAdtl,
      });
    }
    return x;
  };

  //    if(priorityData2){

  //     let modifiedData = filterMyPriorityData();
  // //setM_data(modifiedData)
  //     console.log(modifiedData, 'dta modified.....')

  //    }

  useEffect(() => {
    let edcasteUser = JSON.parse(localStorage.getItem("userProfile"));

    if (edcasteUser?.edcastUser) {
      let modifiedData = filterMyPriorityData();
      let filteredData = modifiedData.filter(
        (el) => el.activity !== "Assigned Learning"
      );
      setM_data(filteredData);
    } else if (edcasteUser?.adfs) {
      let taskCenterForCont = [];
      setM_data(taskCenterForCont);
    } else {
      let modifiedData = filterMyPriorityData();
      setM_data(modifiedData);
    }
  }, [priorityData2]);

  //count
  function dateCount(date) {
    const today = new Date();
    const dueDate = new Date(date);
    const diffTime = Math.abs(dueDate - today);
    const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
    return diffDays.toString();
  }
  //get count:

  // function taskCenterData(res){
  // let results = res.d.results
  // for(let i in results)
  // {
  // if(results[i].Count === '' ){
  // var count = dateCount(results[i].DueDate);
  // results[i].Count = count.toString();
  // }
  // }
  // console.log(results)
  // }

  const [pagination, setPagination] = useState({
    startPage: 0,
    endPage: 4,
  });

  const { startPage, endPage } = pagination;
  const [activeNextArrow, setActiveNextArrow] = useState(false);
  const [activePreviousArrow, setActivePreviousArrow] = useState(false);

  let filterData = [...m_data];
  let filterDataLength = filterData.length;

  const handleNextPagination = () => {
    if (endPage >= filterDataLength || filterDataLength - endPage <= 4) {
      setActivePreviousArrow(true);
      setActiveNextArrow(false);
    } else {
      setActivePreviousArrow(true);
      setActiveNextArrow(true);
    }
    if (endPage < filterDataLength) {
      setPagination({
        startPage: endPage,
        endPage: endPage + 4,
      });
    }
  };

  const handlePreviousPage = () => {
    if (startPage <= 4) {
      setActivePreviousArrow(false);
      setActiveNextArrow(true);
    } else {
      setActivePreviousArrow(true);
      setActiveNextArrow(true);
    }
    setPagination({
      startPage: startPage - 4 < 0 ? 0 : startPage - 4,
      endPage: startPage < 4 ? 4 : startPage,
    });
  };

  //tooltip

  const classes = useStyles();

  useEffect(() => {
    let nextArrowStyle = m_data.length >= 5 ? true : false;
    setActiveNextArrow(nextArrowStyle);
  }, [m_data]);

  useEffect(() => {
    dispatch(getPriorityData());
  }, []);

  return (
    <Grid container spacing={3}>
      <Grid item md={5} sm={12} xs={12}>
        <Card className="onPriorityContainer">
          <div className="firstSection">
            <Tooltip
              classes={{
                tooltip: classes.customTooltip,
                arrow: classes.customArrow,
              }}
              title="A dedicated section on your pending tasks, so you can stay on top of everything."
              arrow
              placement="right"
            >
              <Typography className="taskSubTitle">Task center</Typography>
            </Tooltip>
            {filterData.length > 4 && (
              <div className="alignArrow">
                <div
                  className="paginationArrow"
                  style={activePreviousArrow ? activeArrowStyle : null}
                  onClick={handlePreviousPage}
                >
                  <Icon className="customAlignArrow">arrow_back_ios</Icon>
                </div>
                <div
                  className="paginationArrow"
                  onClick={handleNextPagination}
                  style={activeNextArrow ? activeArrowStyle : null}
                >
                  <Icon>arrow_forward_ios</Icon>
                </div>
              </div>
            )}
          </div>
          {/* {priorityData.length > 0 && ( */}
          <PriorityTasks
            priorityData={m_data}
            filterData={
              filterData.length > 3 && filterData.slice(startPage, endPage)
            }
          />
          {/* )} */}
        </Card>
      </Grid>
      <Grid item md={7} sm={12} sx={12}>
        <Card className="onPriorityContainer">
          <div className="firstSection alignTabsMB">
            <Tooltip
              classes={{
                tooltip: classes.customTooltip,
                arrow: classes.customArrow,
              }}
              title="Quick shortcuts to your most used pages. Pro tip: You can set what these should be. <Emergency contacts and Speak Up> is mandatory for all."
              arrow
              placement="right"
            >
              <Typography className="taskSubTitle">Favourites</Typography>
            </Tooltip>
          </div>
          <Favourites />
        </Card>
      </Grid>
    </Grid>
  );
}

export default TaskCenter;
