import React from "react";
import { Grid } from "@material-ui/core";
import ExperienceItem from "./ExperienceItem/ExperienceItem";

const RecordsList = ({ records = [], currentTab = "", searchText = "" }) => {
  const handleDelete = () => {
    console.log("Delete button pressed");
  };

  return (
    <Grid
      container
      direction="row"
      justifyContent="flex-start"
      alignItems="center"
      spacing={2}
      className="alignCards mycontributionblock"
    >
      {records.length ? (
        records.map((result, index) => {
          return (
            <Grid
              item
              className="padding12"
              sm={index >= 0 && index <= 1 ? 6 : 6}
              md={index >= 0 && index <= 1 ? 6 : 6}
              lg={index >= 0 && index <= 1 ? 4 : 4}
              xs={index >= 0 && index <= 1 ? 12 : 12}
            >
              <ExperienceItem
                currentTab={currentTab}
                list={result}
                footerIcon={true}
                handleDelete={handleDelete}
                searchText={searchText}
              />
            </Grid>
          );
        })
      ) : (
        <span
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100vh",
            width: "100%",
            
            color: "#e31837",
          }}
        >
          No Data Available
        </span>
      )}
    </Grid>
  );
};

export default RecordsList;
