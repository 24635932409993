import React, { useState, useEffect } from "react";
import "./SopTabComponent.css";
import { Tabs, Tab, Typography, Box, Grid } from "@material-ui/core";
import PropTypes from "prop-types";
import SopCardComponent from "../SopCard/SopCardComponent";
import CommonModal from "../../../Common/CommonModal/CommonModal";
import { useDispatch, useSelector } from "react-redux";
import {
  getPolicySubCategoryData,
  getPolicyTableDataByID,
} from "../../../../Redux/Actions/PolicyCategoryAction";
import CreatePoliciesModalComponent from "../EditPolicies/CreatePoliciesModalComponent";
import editIcon from "../../../../Assets/Images/edit-line.svg";
import arrowRight from "../../../../Assets/Images/collectiveIcons/arrow-right-s-line-white.svg";
import PoliciesTableComponent from "../SopTable/SopTableComponent";

import ManageSopCategories from "../AdminPanel/ManageSopCategories";
import { useHistory } from "react-router-dom";
import {
  POLICIES,
  POLICIES_SUBCATEGORY,
  POLICIES_EDIT,
} from "../../../../Routes/constant";
import isAdmin from "../../../isAdmin";
import { isAccesible } from "../../../../Assets/helperFunctions/helperFunctions";
import {
  getSopSubCategoryData,
  getSopTableDataByID,
} from "../../../../Redux/Actions/SOPsAction";
import SopTableComponent from "../SopTable/SopTableComponent";
function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export default function SopTabComponent({ SopCategory, policyID, admin }) {
  const [categoryID, setCategoryID] = useState("");

  let checkAdmin = isAdmin();

  let result = isAccesible("SOPs");

  const history = useHistory();
  const dispatch = useDispatch();
  const [value, setValue] = React.useState(0);
  const [currentIndex, setcurrentIndex] = useState(null);

  const [subCategoryID, setSubCategoryID] = useState("");

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const [openModal, setOpenModal] = useState(false);
  const onEditPolicies = () => {
    setOpenModal(true);
  };

  const handleCloseModal = () => {
    setOpenModal(false);
  };
  const [addCardSelectedClass, setaddCardSelectedClass] = React.useState(0);
  const handleCardItemClick = (event, item, index) => {
    setaddCardSelectedClass(index);
    setcurrentIndex(index);
    setSubCategoryID(item?.sopSubCategoryId);
  };

  //policies related data.

  useEffect(() => {
    if (subCategoryID) {
      dispatch(getSopTableDataByID(subCategoryID));
    }
  }, [subCategoryID]);

  const sopTableDataState = useSelector(
    (state) => state.getSOPTableDataReducer
  );
  const { getData: SopTableData } = sopTableDataState;

  const getSopSubCategoryDataState = useSelector(
    (state) => state.getSOPSubCategoryDataReducer
  );
  const { getData: SopSubCategory } = getSopSubCategoryDataState;
  console.log(SopSubCategory[addCardSelectedClass]);

  useEffect(() => {
    const dispatchSop = async () => {
      var data = await dispatch(getSopSubCategoryData(categoryID));
      console.log(data);
      // dispatch(
      //   getSopTableDataByID(data[addCardSelectedClass].sopSubCategoryId)
      // );
    };
    dispatchSop();
  }, [categoryID]);

  const handleTabClick = (item, index, e) => {
    setCategoryID(item?.categoryId);
    setSubCategoryID("");
  };

  const handleEditClick = () => {
    setOpenModal(true);
  };

  return (
    <>
      <div className="policiesTabAlign">
        <Grid
          container
          direction="row"
          justifyContent="space-between"
          alignItems="center"
        >
          <Tabs
            value={value}
            onChange={handleChange}
            aria-label="basic tabs example"
            className="alignTabContent"
          >
            {SopCategory &&
              SopCategory?.map((item, index) => (
                <Tab
                  key={item.categoryId}
                  onClick={(e) => {
                    handleTabClick(item, index, e);
                  }}
                  value={index}
                  label={item.category}
                  {...a11yProps(index)}
                />
              ))}

            {/* <Tab label="Employee Focused Policies" /> */}
          </Tabs>
          {isAccesible("SOPs") ? (
            <div className="editPoliciesStructure" onClick={onEditPolicies}>
              <img
                src={process.env.PUBLIC_URL + "/policies/edit-2-line.svg"}
                alt="Edit"
              />
              <span className="editPolicies">manage</span>
            </div>
          ) : (
            ""
          )}
        </Grid>
      </div>
      <hr className="deviderP" />
      <TabPanel value={value} index={value} className="policiesTab">
        <Grid
          container
          direction="row"
          justifyContent="flex-start"
          alignItems="center"
          spacing={3}
        >
          {SopSubCategory &&
            SopSubCategory.map((result, index) => (
              <Grid
                key={result.sopSubCategoryId}
                item
                SopSubCategory
                xs={12}
                sm={4}
                md={3}
                onClick={(event) => handleCardItemClick(event, result, index)}
                className={addCardSelectedClass === index ? "addRedBorder" : ""}
              >
                <SopCardComponent
                  date={result.updatedAt}
                  title={result.title}
                  description={result.description}
                  id={result.sopSubCategoryId}
                />
              </Grid>
            ))}
        </Grid>
      </TabPanel>
      {/* <TabPanel value={value} index={1} className="policiesTab">
        <Grid
          container
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          spacing={3}
        >
          {policiesData.cardData.map((item, index) => (
            <Grid
              key={index}
              item
              xs={12}
              sm={3}
              onClick={(event) => handleCardItemClick(event, item, index)}
              className={addCardSelectedClass === index ? "addRedBorder" : ""}
            >
              <PoliciesCardComponent
                policiesDate={item.date}
                title={item.title}
                description={item.description}
              />
            </Grid>
          ))}
        </Grid>
      </TabPanel> */}
      {/*<CommonModal
        open={openModal}
        handleClose={handleCloseModal}
        headerText="Edit policies"
        icon={editIcon}
        modalSize="lg"
        submitBtnText="SAVE CHANGES"
        cancleBtnText="BACK"
        // header={header}
        // footer={footer}
        fullWidth
        policiesButtonSize
      >
        <CreatePoliciesModalComponent policyCategory= {policyCategory} policyTableData={policyTableData} />
     </CommonModal> */}
      <ManageSopCategories open={openModal} handleClose={handleCloseModal} />

      <div className="totlaPoliciesCount HidePoliciesTableMbl">
        Showing{" "}
        <span className="highlightCount">
          {SopTableData?.length} {SopTableData?.length > 1 ? "SOPs" : "SOP"}{" "}
        </span>
      </div>
      <div className="policyCountTable">
        {/* <div className="slidingArrow">
          <span className="iconArrow">
            <img src={arrowRight} alt="Download line" />
          </span>
        </div> */}
        <SopTableComponent
          className="HidePoliciesTableMbl"
          // tableData={policiesData.policiesTableData}
          // hideButton={false}
          sopTableData={SopTableData}
        />
      </div>
    </>
  );
}
