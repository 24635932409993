import moment from "moment";
import React, { useEffect, useState } from "react";

//Ascending
export const sortingFunction = (dataToBeSort, sortBy) => {
  dataToBeSort.sort((a, b) => {
    if (a[sortBy] > b[sortBy]) {
      return -1;
    }
    if (a[sortBy] < b[sortBy]) {
      return 1;
    }
    return 0;
  });
  return dataToBeSort;
};

//Descending
export const sortingFunctionDescending = (dataToBeSort, sortBy) => {
  dataToBeSort.sort((a, b) => {
    if (a[sortBy] < b[sortBy]) {
      return -1;
    }
    if (a[sortBy] > b[sortBy]) {
      return 1;
    }
    return 0;
  });
  return dataToBeSort;
};

export const currentDate = () => {
  let dateObj = new Date();
  let month = dateObj.getUTCMonth() + 1;
  let day = dateObj.getUTCDate();
  let year = dateObj.getUTCFullYear();
  const current_date = moment(year + "/" + month + "/" + day).format(
    "DD/MM/YYYY"
  );
  return current_date;
};

export const diffDays = (date1, date2) => {
  let a = moment(date1, "D/M/YYYY");
  let b = moment(date2, "D/M/YYYY");
  let diff_days = b.diff(a, "days");
  return diff_days;
};

export const tConv24 = (time24) => {
  let ts = time24;
  let H = +ts?.substr(0, 2);
  let h = H % 12 || 12;
  h = h < 10 ? "0" + h : h;
  let ampm = H < 12 ? " AM" : " PM";
  ts = h + ts?.substr(2, 3) + ampm;
  return ts;
};

let allMonths = [
  {
    title: "Jan",
    value: "01",
  },
  {
    title: "Feb",
    value: "02",
  },
  {
    title: "Mar",
    value: "03",
  },
  {
    title: "Apr",
    value: "04",
  },
  {
    title: "May",
    value: "05",
  },
  {
    title: "Jun",
    value: "06",
  },
  {
    title: "July",
    value: "07",
  },
  {
    title: "Aug",
    value: "08",
  },
  {
    title: "Sep",
    value: "09",
  },
  {
    title: "Oct",
    value: "10",
  },
  {
    title: "Nov",
    value: "11",
  },
  {
    title: "Dec",
    value: "12",
  },
];

export const getAllYearsMonths = (uptoLast) => {
  let minOffset = 0,
    maxOffset = uptoLast ? uptoLast : 10;
  let thisYear = new Date().getFullYear();
  let allYears = [];
  for (let x = 0; x <= maxOffset; x++) {
    allYears.push({
      title: `${thisYear - x}`,
      value: `${thisYear - x}`,
      child: allMonths,
    });
  }
  return allYears;
};

export const months = [
  "Jan",
  "Feb",
  "Mar",
  "Apr",
  "May",
  "Jun",
  "July",
  "Aug",
  "Sep",
  "Oct",
  "Nov",
  "Dec",
];
export const getAllYearsWithMonths = () => {
  let minYear = 0,
    maxYear = 5;
  let thisYear = new Date().getFullYear();
  let allYears = [];
  for (let x = 0; x <= maxYear; x++) {
    months.map((cmonth, index) => {
      allYears.push({
        title: `${cmonth} ${thisYear - x}`.toUpperCase(),
        value: `${cmonth} ${thisYear - x}`,
        year: `${thisYear - x}`,
        child: allMonths,
        selectedMonth: index + 1,
      });
    });
  }
  return allYears;
};

export const emailRegex = new RegExp(
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
);
export const phoneRegExp = new RegExp(
  /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/im
);
export const nameRegex = new RegExp(/^[a-zA-Z ]{2,30}$/);

export function getFormattedDate(dateString, formatString = "DD/MM/YYYY") {
  if (dateString) {
    return moment(dateString).format(formatString);
  }
  return "";
}

export const getFilteredBUs = (businessUnit, selectedSectors, keysRequired) => {
  if (!selectedSectors) return;

  var result = businessUnit
    .filter(function (object1) {
      return selectedSectors.some(function (object2) {
        return object1.sectorId === object2.value; // assumes unique id
      });
    })
    .map(function (object) {
      return keysRequired.reduce(function (newObject, name) {
        newObject[name] = object[name];
        return newObject;
      }, {});
    });

  return result;
};

export const convertStringEscapedToFormatted = (string) => {
  if (string) {
    return string.split("\n").map((item, index) => {
      return index === 0 ? item : [<br key={index} />, item];
    });
  } else {
    return "";
  }
};
export const getFilteredFunctions = (
  functions,
  selectedBusinessUnits,
  keysRequired
) => {
  if (!selectedBusinessUnits) return;

  var result = functions
    .filter(function (object1) {
      return selectedBusinessUnits.some(function (object2) {
        return object1.sectorId === object2.value; // assumes unique id
      });
    })
    .map(function (object) {
      return keysRequired.reduce(function (newObject, name) {
        newObject[name] = object[name];
        return newObject;
      }, {});
    });

  return result;
};

export const getTitlesOfDropdown = (list1, list2) => {
  let selectItems = [];
  if (list1 && list2) {
    list1?.map((item1) =>
      list2?.map((item2) =>
        item2.value == item1 ? selectItems.push(item2) : null
      )
    );
  }
  return selectItems;
};

//set offset count.
export const offsetCount = (num, offset = 10) => {
  let quotient = Math.floor(num / offset);
  var remainder = num % offset;
  if (remainder) {
    return quotient + 1;
  } else {
    return quotient;
  }
};

export const isAccesible = (module_name) => {
  let scopeDetails = JSON.parse(localStorage.getItem("scopeDetails"));

  if (scopeDetails) {
    if (
      scopeDetails.adminType === "CENTRAL_ADMIN" ||
      scopeDetails.adminType === "IT_ADMIN"
    ) {
      return true;
    } else if (scopeDetails.adminType === "OTHERS") {
      let result = scopeDetails.moduleAssigneds.filter(
        (el) => el.module === module_name
      );
      //let result = scopeDetails.moduleAssigneds.includes(module_name)
      if (result.length > 0) {
        return true;
      } else {
        return false;
      }
    }
  } else {
    return false;
  }
};

export const checkPublishDatePassed = (publishDate) => {
  let todayDate = moment(new Date()).format("YYYY-MM-DD");
  let pDate = moment(publishDate).format("YYYY-MM-DD");
  // let pDate = moment("2022-04-28").format("YYYY-MM-DD");

  console.log(`pDate < today ====== ${pDate < todayDate}`);

  return pDate < todayDate;
};
