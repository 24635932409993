import React from "react";
import { Switch, Route } from "react-router-dom";
import Login from "../../Components/Pages/Auth/Login/Login";
import ForgotPassword from "../../Components/Pages/Auth/Login/ForgotPassword";
import OTPScreen from "../../Components/Pages/Auth/Login/OTPScreen";
import ResetLinkScreen from "../../Components/Pages/Auth/Login/ResetLinkScreen";
import NotFound from "../../Components/NotFound/NotFound";
import NotAuth from "../../Components/Pages/Auth/NotAuth";

import {
  AUTH,
  LOGIN,
  FORGOT_PASSWORD,
  OTP,
  RESET_LINK,
  NOT_AUTH,
} from "../constant";

function AuthRoutes(props) {
  return (
    <div>
      <Switch>
        <Route exact path={AUTH} component={Login} />
        <Route exact path={`${AUTH}${LOGIN}`} component={Login} />
        <Route exact path={`${AUTH}${NOT_AUTH}`} component={NotAuth} />
        <Route
          exact
          path={`${AUTH}${FORGOT_PASSWORD}`}
          component={ForgotPassword}
        />
        <Route exact path={`${AUTH}${OTP}`} component={OTPScreen} />
        <Route
          exact
          path={`${AUTH}${RESET_LINK}`}
          component={ResetLinkScreen}
        />
        <Route component={NotFound} />
      </Switch>
    </div>
  );
}

export default AuthRoutes;
