import React, { useState, useEffect } from "react";
import "./MahindraCollectiveFilters.css";

import { Grid, makeStyles } from "@material-ui/core";
import { useHistory } from "react-router-dom";
import SubmitButton from "../../../Common/Button/SubmitButton";
import CommonAutocomplete from "../../../Common/CommonAutocomplete/CommonAutocomplete";
import CommonMultiAutocomplete from "../../../Common/CommonMultiAutocomplete/CommonMultiAutocomplete";
import CommonLabel from "../../../Common/ComonLabel/CommonLabel";
import CommonToolTip from "../../../Common/CommonToolTip";
import services from "../../../../Services/index";
import {
  getAllYearsMonths,
  getFilteredBUs,
} from "../../../../Assets/helperFunctions/helperFunctions";
import MultiSelect from "../../../Common/MultiSelect/MultiSelect";
import CustomMultiSelect from "../../../Common/CustomMultiSelect/CustomMultiSelect";
import { useDispatch, useSelector } from "react-redux";

//Added for Filters
import {
  getSectors,
  getBusinessUnits,
} from "../../../../Redux/Actions/MastersAction";

export default function MahindraCollectiveFilters({
  showSubmit,
  isShortBy,
  handleSortByChange,
  selectedSortBy,
  path,
  handleCalYearChange,
  handleMonthChange,
  calYearValue,
  monthValue,
  handleBusinessChange,
  sectorValue,
  getDivisionList,
  buValue,
  setSectors,
  setLoading,
  getAllRecords,
  // getSectorList,
}) {
  const [getSectorList, setGetSectorList] = useState([]);
  // const [getDivisionList, setGetDivisionList] = useState([]);
  const [getExsitingData, setExsitingData] = useState("");
  const [allBusinessValue, setAllBusinessValue] = useState("");
  const [businessGroupValue, setbusinessGroupValue] = useState("");
  const dispatch = useDispatch();
  const history = useHistory();

  const calSelectYearOptions = getAllYearsMonths();

  const sortBy = [
    {
      title: "Sort by latest",
      value: "DESC",
    },
    {
      title: "Sort by old",
      value: "ASC",
    },
  ];

  /* ========= Code added for Custom multiselect =========== */
  const [selectedSectors, setSelectedSectors] = useState([]);
  const [selectedBusinessUnits, setSelectedBusinessUnits] = useState([]);
  const [showBusinessUnit, setShowBusinessUnit] = useState(true);
  const getOptionLabel = (option) => `${option.title}`;
  const getOptionDisabled = (option) => option.value === "foo";
  //UseSelectors
  const allSectorsData = useSelector((state) => {
    return state?.getSectorsReducer?.getData;
  });
  //fetching user data from localStorage
  const userFromlocalStorage = JSON.parse(localStorage.getItem("userProfile"));

  //Loggedin user's sector data
  const loggedInUserSectorData = allSectorsData?.filter(
    (item) => item.value === userFromlocalStorage?.sector
  );

  const allBusinessUnitsDetails = useSelector(
    (state) => state?.getBusinessUnitsReducer?.getData
  );
  const [allBusinessUnitsData, setAllBusinessUnitsData] = useState(
    allBusinessUnitsDetails
  );

  //Loggedin user's businessunit data
  const loggedInUserBusinessUnitData = allBusinessUnitsData.filter(
    (item) => item.value === userFromlocalStorage?.businessUnit
  );

  //MARK: - Event Handlers
  const handleSectorsToggleOption = (selectedOptions) => {
    setSelectedSectors(selectedOptions);
    if (selectedOptions.length !== 0) {
      let selSectorsArr = selectedOptions.map((result) => {
        return result.value;
      });
      setSectors(selSectorsArr);

      let data = getFilteredBUs(allBusinessUnitsDetails, selectedOptions, [
        "title",
        "value",
      ]);
      setAllBusinessUnitsData(data);
      setSelectedBusinessUnits(data);
    } else {
      setSectors([]);
    }
  };

  const handleSelectAllSectors = (isSelected) => {
    if (isSelected) {
      setSelectedSectors(allSectorsData);
      let data = getFilteredBUs(allBusinessUnitsDetails, allSectorsData, [
        "title",
        "value",
      ]);
      setSelectedBusinessUnits(data);
    } else {
      handleClearSectors();
      handleClearBusinessUnits();
    }
  };

  const handleClearSectors = () => {
    setSelectedSectors([]);
    setSelectedBusinessUnits([]);
  };

  const handleBusinessUnitsToggleOption = (selectedOptions) => {
    setSelectedBusinessUnits(selectedOptions);
    handleBusinessChange(selectedBusinessUnits);
  };

  const handleSelectAllBusinessUnits = (isSelected) => {
    if (isSelected) {
      let data = getFilteredBUs(allBusinessUnitsDetails, selectedSectors, [
        "title",
        "value",
      ]);
      setSelectedBusinessUnits(data);
    } else {
      handleClearBusinessUnits();
    }
    handleBusinessChange(selectedBusinessUnits);

    // setSelectedFunctions([]);
  };
  const handleClearBusinessUnits = () => {
    setSelectedBusinessUnits([]);
    handleBusinessChange(selectedBusinessUnits);
  };

  //MARK: - Life cycle
  useEffect(() => {
    async function fetchData() {
      dispatch(getSectors());
    }
    fetchData();
  }, []);

  useEffect(() => {
    setLoading(true);
    getAllRecords();
    setLoading(false);
  }, []);

  useEffect(() => {
    if (allSectorsData && allSectorsData.length > 0) {
      setSelectedSectors(
        userFromlocalStorage?.sector ? loggedInUserSectorData : allSectorsData
      );
      setSectors(() => loggedInUserSectorData?.map((item) => item.value));
      let sectorsArray = [
        ...new Set(
          allSectorsData.map((sector) => ({ addsectorId: sector.value }))
        ),
      ];
      dispatch(getBusinessUnits(sectorsArray));
    }
  }, [allSectorsData]);

  useEffect(() => {
    setAllBusinessUnitsData(allBusinessUnitsDetails);
  }, [allBusinessUnitsDetails]);

  useEffect(() => {
    if (allBusinessUnitsData && allBusinessUnitsData.length > 0) {
      setSelectedBusinessUnits(
        userFromlocalStorage?.businessUnit
          ? loggedInUserBusinessUnitData
          : allBusinessUnitsData
      );
    }
  }, [allBusinessUnitsData]);

  /* ========= Ends Code added for Custom multiselect =========== */

  // const handleBusinessDropdown = (getSectorList) => {
  //   // for (let i = 0; i < list.length; i++) {
  //   if (getDivisionList) {
  //     return (
  //       <Grid
  //         item
  //         xs={12}
  //         sm={showSubmit ? 4 : 3}
  //         className="businessTypeIcon noIcon mahindraCBU mahindraCollectiveSector"
  //       >
  //         <MultiSelect
  //           // disabled
  //           options={getDivisionList?.length && getDivisionList}
  //           label={<CommonLabel label="Business unit" />}
  //           handleSelectChange={(e, newValue) => {
  //             handleBusinessChange(e, newValue, "bu");
  //           }}
  //           // handleSelectChange={handleBusinessGroupChange}
  //           // selectedSubcategory={businessGroupValue}
  //           selectedValue={buValue}
  //           className="McShadowBox"
  //         />
  //       </Grid>
  //     );
  //     // }
  //     //  else {
  //     //   <></>;
  //     // }
  //   }
  // };

  //picking the month dropdown according to selected year
  const handleMonthDropdown = (year, list) => {
    for (let i = 0; i < list.length; i++) {
      if (year === list[i].value && list[i].child !== undefined) {
        return (
          <CommonMultiAutocomplete
            options={list[i].child}
            label={<CommonLabel label="Month" />}
            handleSelectChange={handleMonthChange}
            selectedSubcategory={monthValue}
            selectedValue={monthValue}
            className="McShadowBox"
          />
        );
      } else {
        <></>;
      }
    }
  };

  useEffect(async () => {
    let userProfile = JSON.parse(
      localStorage.getItem("userProfile")
        ? localStorage.getItem("userProfile")
        : null
    );
    setExsitingData(userProfile);
    // try {
    //   // setLoading(true);
    //   let res = await services.api(
    //     "GET",
    //     `/ui-assist/v1/sectors?compcode=${userProfile.company}`
    //   );
    //   if (res.status === 200) {
    //     // setLoading(false);
    //     let list = res.data.map((result) => {
    //       return {
    //         title: result.sectorName,
    //         value: result.sectorName,
    //         sectorCode: result.sectorCode,
    //       };
    //     });
    //     setGetSectorList(list);
    //   }
    // } catch (err) {
    //   // setLoading(false);
    //   // Swal.fire("Oops!", `${err.response.data}`, "error");
    //   setGetSectorList([]);
    // }
  }, []);

  return (
    <div className="liveWireDropDownContainer">
      <Grid container direction="row">
        <Grid item xs={12} sm={showSubmit ? 9 : 12} className="zeroPad">
          <Grid container direction="row">
            {showSubmit ? (
              <>
                {/* <CommonToolTip> */}
                <Grid
                  item
                  xs={12}
                  sm={showSubmit ? 4 : 3}
                  className="businessTypeIcon mahindraCollectiveSector standardfilter"
                >
                  <CustomMultiSelect
                    items={allSectorsData}
                    getOptionLabel={getOptionLabel}
                    getOptionDisabled={getOptionDisabled}
                    selectedValues={selectedSectors}
                    label="Sector"
                    selectAllLabel="Select all"
                    onToggleOption={handleSectorsToggleOption}
                    onClearOptions={handleClearSectors}
                    onSelectAll={handleSelectAllSectors}
                    className="width100 mb-4"
                  />

                  <CustomMultiSelect
                    items={allBusinessUnitsData}
                    getOptionLabel={getOptionLabel}
                    getOptionDisabled={getOptionDisabled}
                    selectedValues={selectedBusinessUnits}
                    label="Business Unit"
                    selectAllLabel="Select all"
                    onToggleOption={handleBusinessUnitsToggleOption}
                    onClearOptions={handleClearBusinessUnits}
                    onSelectAll={handleSelectAllBusinessUnits}
                    style={{ marginTop: "20px" }}
                  />
                </Grid>
                {/* <Grid
                  item
                  xs={12}
                  sm={showSubmit ? 4 : 3}
                  className="businessTypeIcon mahindraCollectiveSector"
                >
                  <MultiSelect
                    className="width100"
                    handleSelectChange={(e, newValue) => {
                      handleBusinessChange(e, newValue, "sector");
                    }}
                    selectedValue={sectorValue}
                    options={getSectorList?.length && getSectorList}
                    name="sector"
                    label={<CommonLabel label="Sector" mandatory />}
                  />
                </Grid> */}
                {/* </CommonToolTip> */}
              </>
            ) : (
              <> </>
            )}
            {/* <CommonToolTip> */}
            <Grid
              item
              xs={12}
              sm={showSubmit ? 4 : 3}
              className="calenderTypeIcon"
            >
              <CommonAutocomplete
                // disabled
                options={calSelectYearOptions}
                label={<CommonLabel label="Year" />}
                handleSelectChange={handleCalYearChange}
                selectedValue={calYearValue}
                className="McShadowBox"
              />

              {handleMonthDropdown(calYearValue.value, calSelectYearOptions)}
            </Grid>

            {isShortBy ? (
              <Grid
                item
                xs={12}
                sm={showSubmit ? 4 : 3}
                className="shortingTypeIcon"
              >
                <CommonAutocomplete
                  options={sortBy}
                  label={<CommonLabel label={"Sort by"} />}
                  handleSelectChange={handleSortByChange}
                  selectedValue={selectedSortBy}
                  className="McShadowBox"
                />
              </Grid>
            ) : null}
          </Grid>
        </Grid>
        <Grid
          item
          xs={12}
          sm={3}
          className="zeroPad posRight isMobileHideButton"
        >
          {showSubmit && (
            <Grid item xs={12} sm={12} className="pr-0">
              <SubmitButton
                label="Upload a story"
                className="MCsubmitbutton floatRightAlign"
                onClick={() => history.push(path)}
              />
            </Grid>
          )}
          {/* {showSubmit && (
            <CommonToolTip>
              <Grid item xs={12} sm={12}>
                <SubmitButton
                  // disabled={!isAdmin()}
                  label="Upload a story"
                  className="MCsubmitbutton floatRightAlign"
                  // onClick={() => history.push(path)}
                />
              </Grid>
            </CommonToolTip>
          )} */}
        </Grid>
      </Grid>
    </div>
  );
}
