import services from "../../Services/index";
import Swal from "sweetalert2";
import {
    GET_DISABLE_DONOR_STATUS_DATA_SUCCESS,
    GET_DISABLE_DONOR_STATUS_DATA_LOAD,
    GET_DISABLE_DONOR_STATUS_DATA_FAILED,
} from "../Contants/Constants";

const getDisableDonorStatusLoad = () => ({
    type: GET_DISABLE_DONOR_STATUS_DATA_LOAD,
});
const getDisableDonorStatusSuccess = (data) => ({
    type: GET_DISABLE_DONOR_STATUS_DATA_SUCCESS,
    payload: data,
});
const getDisableDonorStatusFailed = (error) => ({
    type: GET_DISABLE_DONOR_STATUS_DATA_FAILED,
    err: error,
});

export const getDisableDonorStatusData = (formData,id) => async (dispatch) => {
  dispatch(getDisableDonorStatusLoad());
  try {
    const res = await services.api(
      "PUT",
      `/ui-assist/v1/blooddonor/${id}`,formData
    );
    if (res) {
      dispatch(getDisableDonorStatusSuccess(res.data));
    }
    // if(res.status === 200)
    // {
    //   Swal.fire(
    //           "Successfully Registered!",
    //           " ",
    //           "success"
    //         );
    // }
  } catch (err) {
    dispatch(getDisableDonorStatusFailed(err.response));
    Swal.fire("Something went wrong!", `${err?.response?.data}`, "error");
  }
};