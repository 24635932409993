import React, { useState, useEffect } from "react";
import moment from "moment";
import SubmitButton from "../../../Common/Button/SubmitButton";
import editIconRed from "../../../../Assets/Images/edit-line-red.svg";
import delIcon from "../../../../Assets/Images/delete-bin-6-line-grey.svg";
import CommonModal from "../../../Common/CommonModal/CommonModal";

import { Grid, Tooltip, makeStyles } from "@material-ui/core";
import CharLeftField from "../../../Common/CharLeftField/CharLeftField";
import CommonLabel from "../../../Common/ComonLabel/CommonLabel";
import services from "../../../../Services";
import Loader from "../../../Common/Loader/Loader";
import Swal from "sweetalert2";

import {
  getAllContactLocationData,
  editContactLocationData,
  deleteContactLocationData,
  addContactLocationData,
} from "../../../../Redux/Actions/AllContactLocationAction";
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import CustomMultiSelect from "../../../Common/CustomMultiSelect/CustomMultiSelect";

function EditContactLocations() {
  const history = useHistory();

  let dispatch = useDispatch();
  let allData = useSelector((state) => state.getAllContactLocationReducer);

  const [open, setOpen] = useState(false);
  const [edit, setEdit] = useState(false);
  const [location, setLocation] = useState("");
  const [locationList, setLocationList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [editItemId, setEditItemId] = useState("");
  const [sector, setSector] = useState(null);
  const [sectorData, setSectorData] = useState([]);
  const getOptionLabel = (option) => `${option.title}`;

  const getOptionDisabled = (option) => option.value === "foo";
  const [selectedSectorOptions, setSelectedSectorOptions] = useState([]);
  const [selectedBusinessUnitOptions, setSelectedBusinessUnitOptions] =
    useState([]);
  const [sectorListData, setSectorListData] = useState([]);
  const [selectedSectorRev, setSelectedSectorRev] = useState([]);

  useEffect(() => {
    dispatch(getAllContactLocationData());
  }, [allData?.addData, allData?.editedData, allData?.deleteData]);

  useEffect(() => {
    setLocationList(allData?.getAllData);
  }, [allData?.getAllData]);

  useEffect(() => {
    (async () => {
      try {
        setLoading(true);
        const res = await services.api("GET", `/ui-assist/v1/sector`);
        if (res.status === 200) {
          setLoading(false);
          const allSectorItem = res?.data?.map((el) => {
            return {
              title: el.sectorName,
              value: el.id,
            };
          });
          setSectorData(allSectorItem);
          //setBuisnessUnitList()
        }
      } catch (err) {
        setLoading(false);
        Swal.fire("Oops!", `${err.response.data}`, "error");
        setSectorData([]);
      }
    })();
  }, []);

  useEffect(() => {
    (async () => {
      try {
        let moduleName = "EMERGENCY_CONTACTS";
        setLoading(true);
        const res = await services.api(
          "GET",
          `/ui-assist/v1/rollmatrix/adminsectors/${localStorage.getItem(
            "token"
          )}?modulename=${moduleName}`
        );
        if (res.status === 200) {
          setLoading(false);
          const allSectorItem = res?.data?.map((el) => {
            return {
              title: el.sectorName,
              value: el.id,
            };
          });
          setSectorData(allSectorItem);
          //setBuisnessUnitList()
        }
      } catch (err) {
        setLoading(false);
        Swal.fire("Oops!", `${err.response.data}`, "error");
        setSectorData([]);
      }
    })();
  }, []);
  useEffect(() => {
    let dataSectorList = selectedSectorOptions?.map((el) => {
      return el.value;
    });

    let sectorList = selectedSectorOptions?.map((el) => {
      return {
        sectorId: el.value,
        sectorName: el.title,
      };
    });
    setSelectedSectorRev(sectorList);
    setSectorListData(dataSectorList.join(",").toString());
  }, [selectedSectorOptions]);

  const checkAdd = () => {
    if (allData?.addData) {
      Swal.fire("Location added successfully.", " ", "success");
      handleClose();
    }
  };

  const checkEdit = () => {
    if (allData?.editedData) {
      Swal.fire("Location edited successfully.", " ", "success");
      handleClose();
    }
  };

  const checkDelete = () => {
    if (allData?.deleteData) {
      Swal.fire("Location deleted successfully.", " ", "success");
      handleClose();
    }
  };

  const handleAdd = () => {
    setOpen(true);
    setEdit(false);
    setLocation("");
  };

  const handleClose = () => setOpen(false);
  const handleChange = (e) => setLocation(e.target.value);

  const handleEditLocation = (location, sectorList, id) => {
    setOpen(true);
    setEdit(true);
    setEditItemId(id);
    setLocation(location);
    setSector(sectorList);
  };
  console.log(sector);
  const handleSubmit = async (edit, id) => {
    let list = [...locationList];
    setSector(sectorListData.split(","));
    let data = { location: location, sector };

    edit
      ? dispatch(editContactLocationData(locationList[id]?.location, data))
      : dispatch(addContactLocationData(data));

    edit ? (list[id] = data) : (list = [...list, data]);

    setLocationList([...list]);
    edit ? checkEdit() : checkAdd();
  };

  const handleDeleteCategory = async (data, index) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You want to delete this location?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Yes, delete it!",
      cancelButtonText: "No, keep it",
    }).then((result) => {
      if (result.isConfirmed) {
        dispatch(deleteContactLocationData(data));
        checkDelete();
        let list = [...locationList];
        const val = list?.splice(index, 1);
        setLocationList([...list]);
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire("Cancelled", "Your location is safe :)", "error");
      }
    });
  };
  useEffect(() => {
    let dataSectorList = selectedSectorOptions?.map((el) => {
      return el.value;
    });

    // setSectorListData(dataSectorList.join(",").toString());
  }, [selectedSectorOptions]);
  const handleClearOptions = (isSelected, name) => {
    if (name === "sector") {
      setSelectedSectorOptions([]);
      // setSelectedBusinessUnitOptions([]);
    } else if (name === "bu") {
      // setSelectedBusinessUnitOptions([]);
      //setSelectedSectorOptions([]);
      // setBusinessUnitData([]);
    }
  };
  const handleSelectAll = (isSelected, name) => {
    if (isSelected) {
      if (name === "sector") {
        setSelectedSectorOptions(sectorData);
        getSelectedSectorOptions(sectorData);
      } else if (name === "bu") {
        // setSelectedBusinessUnitOptions(businessUnitData);
      } else {
        handleClearOptions();
      }
    }
  };

  const getSelectedSectorOptions = (selectedOptions) => {
    const xyz = selectedOptions.map((el) => {
      return {
        addsectorId: el.value,
      };
    });
  };
  const handleToggleOption = (selectedOptions, name) => {
    if (name === "sector") {
      setSelectedSectorOptions(selectedOptions);
      getSelectedSectorOptions(selectedOptions);
      setSector(selectedOptions?.map((item) => item.value));
    } else if (name === "bu") {
      setSelectedBusinessUnitOptions(selectedOptions);
    }
  };

  console.log("all location data ====> ", allData);
  console.log(" location list ====> ", locationList);
  return (
    <>
      <div className="mx-3 mt-3">
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <div className="uploadBtn">
              <SubmitButton
                onClick={handleAdd}
                label="Add new Location"
                className="MCsubmitbutton floatRightAlign"
              />
            </div>
          </Grid>
        </Grid>
      </div>
      <div className="Modalcontent">
        {locationList?.length > 0 &&
          locationList?.map((item, index) => {
            return (
              <>
                <Grid container className="secrotCardAdmin">
                  <Grid item xs={12} sm={5} className="catCardTitle">
                    {item.location}
                  </Grid>
                  <Grid item xs={12} sm={3}></Grid>
                  <Grid
                    item
                    xs={12}
                    sm={2}
                    onClick={() =>
                      handleEditLocation(item.location, item?.sector, index)
                    }
                  >
                    <div className="catActionBtnContainer">
                      <img src={editIconRed} alt="" className="edit-line" />
                      <div className="catActionBtn">EDIT LOCATION NAME</div>
                    </div>
                  </Grid>

                  {/* <Tooltip
                    // classes={{
                    //     tooltip: classes.customTooltip,
                    //     arrow: classes.customArrow,
                    // }}
                    title="Category can be deleted when all sub-categories and policies in it are deleted"
                    arrow
                    placement="bottom"
                    > */}
                  <Grid
                    item
                    xs={12}
                    sm={2}
                    onClick={() => handleDeleteCategory(item.location, index)}
                  >
                    <div className="catActionBtnContainer">
                      <img src={delIcon} alt="" className="edit-line" />
                      <div className="catActionBtn delBtn">DELETE LOCATION</div>
                    </div>
                  </Grid>
                  {/* </Tooltip> */}
                </Grid>
              </>
            );
          })}

        <CommonModal
          open={open}
          handleClose={handleClose}
          headerText={edit ? "Edit location" : "Add location"}
          // icon={editIcon}
          modalSize="lg"
          fullWidth
          submitBtnText={edit ? "Edit Location" : "Add"}
          // onButtonClick={() => handleSubmit(edit, item.id)}
          onButtonClick={() => handleSubmit(edit, editItemId)}
          disabledSubmitButton={!location || !sector ? true : false}
        >
          <Grid container alignItems="center">
            <Grid item md={12} xs={12} className="contentPadding">
              <span className="Complete-Tax-Declara">Location</span>
            </Grid>
            <Grid item md={6} xs={12} className="contentPadding">
              <CharLeftField
                label={
                  <CommonLabel
                    label={edit ? "Edit Location" : "Add Location"}
                    mandatory
                  />
                }
                name="location"
                type="text"
                value={location}
                handleChange={handleChange}
              />
            </Grid>
            <Grid
              item
              sm={3}
              xs={12}
              className="policiesMultiIcon policiesAlignMulti editPModal"
            >
              <CustomMultiSelect
                items={sectorData}
                getOptionLabel={getOptionLabel}
                getOptionDisabled={getOptionDisabled}
                selectedValues={selectedSectorOptions}
                label={<CommonLabel label="Search sector" mandatory />}
                selectAllLabel="Select all"
                onToggleOption={(isSelected) => {
                  handleToggleOption(isSelected, "sector");
                }}
                onClearOptions={(isSelected) => {
                  handleClearOptions(isSelected, "sector");
                }}
                onSelectAll={(isSelected) => {
                  handleSelectAll(isSelected, "sector");
                }}
              />
            </Grid>
          </Grid>
        </CommonModal>
      </div>
    </>
  );
}

export default EditContactLocations;
