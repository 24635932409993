import React from "react";

const NotAuth = () => {
  const handleLogout = () => {
    localStorage.clear();
    window.location.href = `/ui-assist/logout`;
  };
  return (
    <>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh",
          width: "100%",
          color: "#e31837",
          fontSize: "1rem",
        }}
      >
        <div>
          <p>
            your login is not authenticated. Please contact
            newriseportal@mahindra.com for support.
          </p>
          <div
            onClick={handleLogout}
            style={{ width: "100%", textAlign: "center", cursor: "pointer" }}
          >
            <p>Logout</p>
          </div>
        </div>
      </div>
    </>
  );
};

export default NotAuth;
