import {
    GET_ALL_WHATSNEW_DATA_LOAD,
    GET_ALL_WHATSNEW_DATA_FAILED,
    GET_ALL_WHATSNEW_DATA_SUCCESS,
  GET_WHATS_NEW_SECTORS_LOAD,
  GET_WHATS_NEW_SECTORS_FAILED,
  GET_WHATS_NEW_SECTORS_SUCCESS,
  GET_WHATS_NEW_BY_SECTOR_LOAD,
  GET_WHATS_NEW_BY_SECTOR_FAILED,
  GET_WHATS_NEW_BY_SECTOR_SUCCESS,
  } from "../Contants/Constants";
  
  const initialState = {
    getData: [],
    sectors: [],
    whatsNewData: [],
    isLoading: false,
    isError: "",
  };
  
  const getWhatsNewDataReducer = (state = initialState, action) => {
    switch (action.type) {
      case GET_ALL_WHATSNEW_DATA_LOAD:
        return {
          ...state,
          isLoading: true,
        };
      case GET_ALL_WHATSNEW_DATA_SUCCESS:
        return {
          ...state,
          getData: action.payload,
          isLoading: false,
        };
      case GET_ALL_WHATSNEW_DATA_FAILED:
        return {
          ...state,
          isError: action.payload,
          isLoading: false,
        };


      case GET_WHATS_NEW_SECTORS_LOAD:
        return {
          ...state,
          isLoading: true,
        };
      case GET_WHATS_NEW_SECTORS_SUCCESS:
        return {
          ...state,
          sectors: action.payload,
          isLoading: false,
        };
      case GET_WHATS_NEW_SECTORS_FAILED:
        return {
          ...state,
          isError: action.payload,
          isLoading: false,
        };


        case GET_WHATS_NEW_BY_SECTOR_LOAD:
        return {
          ...state,
          isLoading: true,
        };
      case GET_WHATS_NEW_BY_SECTOR_SUCCESS:
        return {
          ...state,
          whatsNewData: action.payload,
          isLoading: false,
        };
      case GET_WHATS_NEW_BY_SECTOR_FAILED:
        return {
          ...state,
          isError: action.payload,
          isLoading: false,
        };


      default:
        return state;
    }
  };
  
  export default getWhatsNewDataReducer